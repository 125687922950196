import { PageActionsFooter } from 'page-actions-footer.react';

export function PageFooter(props) {
	const {
		left,
		right,
	} = props;

	return (
		<PageActionsFooter
			isOpen={ true }
			right={ right }
		>
			{ left }
		</PageActionsFooter>
	);
}
