import moment from 'moment.lib';

export function sumByKeyEarnableHoursInDays(daysArr, accessor = () => 0) {
	return daysArr.reduce((sum, day) => {
		if (accessor instanceof Function) {
			return sum + accessor(day);
		}
		return sum + day[accessor];
	}, 0);
}

export function sumAllEarnableHoursInDays(daysArr) {
	return daysArr.reduce((sum, day) => sum + day.hours + day.timeOffHours + day.paidHolidayHours, 0);
}

export function getPayPeriodText(focusedTimesheetId, latestTimesheetId, latestPreviousTimesheetId) {
	if (focusedTimesheetId === latestTimesheetId) {
		return $.__('This Pay Period');
	} else if (focusedTimesheetId === latestPreviousTimesheetId) {
		return $.__('Previous Pay Period');
	}
	return $.__('Pay Period');
}

export function countDaysLeft(end, now) {
	const diff = moment(end).diff(moment(now), 'days', true);
	return Math.ceil(diff);
}

export function getRangeText(start, end) {
	const startMoment = moment(start);
	const endMoment = moment(end);
	const startFormat = 'MMM D';
	const endFormat = (startMoment.isSame(endMoment, 'month')) ? 'D' : startFormat;
	return `${ startMoment.format(startFormat) } – ${ endMoment.format(endFormat) }`;
}

export function getSummaryRangeText(start, end, now) {
	const daysLeft = countDaysLeft(end, now);
	const daysLeftText = (daysLeft > 0 ? ` (${ $.__('%s days left', daysLeft) })` : '');
	return `${ getRangeText(start, end) }${ daysLeftText }`;
}
