import Ajax from '@utils/ajax';

import { FILE_INFO_ENDPOINT, SFS_ENDPOINT } from './constants';

// Request for File Info data
export function getFileInfo(fileId, fileType) {
	return Ajax.get(`${ FILE_INFO_ENDPOINT }/${ fileType }/${ fileId }`);
}

// Request for SFS data
export function getSfsData(fileId, fileType) {
	return Ajax.get(`${ SFS_ENDPOINT }/${ fileId }?fileType=${ fileType }`);
}

// Request to disable SFS link
export function disableSfsLink(id, callback) {
	return Ajax.put(`${ SFS_ENDPOINT }/${ id }`, { type: 'disabled' })
		.then(() => {
			if (callback) {
				callback();
			}
		})
		.catch((error) => {
			console.error(error);
			window.errorFallBack();
		});
}
