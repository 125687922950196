import {MOUSE_CLICK, MOUSE_ENTER, MOUSE_OVER, MOUSE_LEAVE} from './event-names';

const defaultEventBody = (data) => {
	return { 
		bubbles: true,
		detail: data
	};
}

function dispatch(element, event) {
	return element.dispatchEvent(event);
}

/**
 * Generic 'click' event
 * Used in d3 on the implementing chart
 * 
 * @param {object} data from d3
 * @param {int} index of the pressed element
 * @param {array} array of elements that this event is attatched to
 */
export function mouseClick(data, i, els) {
	let element = els[i];
	let event = new CustomEvent(MOUSE_CLICK, defaultEventBody(data));

	dispatch(element, event);
}

/**
 * Generic 'mouseenter' event
 * Used in d3 on the implementing chart
 * 
 * @param {object} data from d3
 * @param {int} index of the pressed element
 * @param {array} array of elements that this event is attatched to
 */
export function mouseEnter(data, i, els) {
	let element = els[i];
	let event = new CustomEvent(MOUSE_ENTER, defaultEventBody(data));

	dispatch(element, event);
}

/**
 * Generic 'mouseover' event
 * Used in d3 on the implementing chart
 * 
 * @param {object} data from d3
 * @param {int} index of the pressed element
 * @param {array} array of elements that this event is attatched to
 */
export function mouseOver(data, i, els) {
	let element = els[i];
	let event = new CustomEvent(MOUSE_OVER, defaultEventBody(data));

	dispatch(element, event);
}

/**
 * Generic 'mouseleave' event
 * Used in d3 on the implementing chart
 * 
 * @param {object} data from d3
 * @param {int} index of the pressed element
 * @param {array} array of elements that this event is attatched to
 */
export function mouseLeave(data, i, els) {
	let element = els[i];
	let event = new CustomEvent(MOUSE_LEAVE, defaultEventBody(data));

	dispatch(element, event);
}
