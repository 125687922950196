import {
	getAttrs,
	getCurrentScript,
	isJqueryElement,
} from 'BambooHR.util';
import {
	setupEvents,
} from 'ctrl.deco';
export * from 'ctrl.deco';

import {
	camelCase,
	isElement,
	mapKeys,
} from 'lodash';

// # Comp decorator

/**
 * @description
 *
 * The `Comp` decorator allows us to simply and easily
 * attach a component controller to an element.
 *
 * Simply create a js file in `ui-src/js/components`,
 * and include that js file in a `<script>` tag placed
 * immediately after your component's wrapper `<div>`
 * in the twig file.
 *
 * 	import Comp from 'comp.deco';
 *
 * 	@Comp()
 * 	class SomeComponent {
 * 		constructor($elem, elemId) {
 * 			console.log(this.$elem);
 * 		}
 * 	}
 *
 * By default, the `Comp` decorator will attach to
 * the previous div element in the DOM. If for
 * whatever reason, you need to do something differently,
 * there are a few options to override this behavior:
 *
 * - add a `data-elem-id` attribute to the `<script>` tag which contains the `id` of the component wrapper
 * - pass a selector to the `@Comp()` decorator
 *
 * The component's wrapper will automatically be assigned
 * to the component controller as `this.$elem`. The element,
 * and its id are also passed as arguments to the controller's
 * constructor.
 *
 * You can use event decorators just as you would with the
 * `Ctrl` decorator, but these events will be _scoped_ to the
 * component wrapper. In other words, `@onClick('.some-selector')`
 * will only apply to _descendants_ of the component which have
 * the class `'some-selector'`.
 *
 * The controller instance will be attached to the element's data
 * object, with the class name being the key. For the example above:
 *
 * 	$('.some-selector').data('SomeComponent');
 */
export default function Comp(selector) {
	return function(ctrl) {
		let $elem;
		let elemId;
		let attrs = {};

		if (isElement(selector) || isJqueryElement(selector)) {
			$elem = $(selector);
		} else {
			let $script = getCurrentScript();
			attrs = mapKeys(getAttrs($script, '^data-'), (val, key) => camelCase(key.replace(/^data-/, '')));
			elemId = attrs.elemId;
			$elem = $(
				$(`#${ elemId }`)[0] ||
				$script.siblings(selector)[0] ||
				$(selector)[0] ||
				$script.prev(selector || 'div')[0]
			);
		}

		if ($elem.length === 0) {
			return;
		}

		elemId = elemId || $elem.attr('id') || camelCase($elem.selector);

		ctrl.prototype.$elem = $elem;
		delete attrs.elemId;
		ctrl.prototype.compConfig = attrs;
		var controller = new ctrl($elem, elemId);

		$elem.data(ctrl.className, controller);

		controller::setupEvents(`Comp__${ elemId }`, $elem);

		return controller;
	}
}
