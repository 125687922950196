import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { SlideTransition } from 'slide-transition.react';
import { getValidationContext, getUsageContext } from '../../../../context';

const OTHER = 'other';

export const Radio = (props) => {
	const {
		validationRegistration,
		id,
		options,
		hasOther,
		isDisabled,
		isRequired,
		isValueOther,
		value,
		label,
		name,
	} = props;

	const {
		setSpecialErrors,
		specialErrors,
	} = getValidationContext();

	const shouldValidateOther = useRef(false);
	const isInitialRender = useRef(true);
	const otherInputRef = useRef();
	let initialValue = null;
	if (isValueOther) {
		initialValue = OTHER;
	} else {
		const initialOption = options.find(({ id }) => { return id === parseInt(value); });
		initialValue = initialOption ? initialOption.id : null;
	}

	const [selectedValue, setSelectedValue] = useState(initialValue);
	const [otherValue, setOtherValue] = useState(isValueOther ? value : '');

	useEffect(() => {
		if (!isInitialRender.current && selectedValue === OTHER) {
			setTimeout(() => {
				otherInputRef.current.focus();
			});
		}
		isInitialRender.current = false;
	}, [selectedValue]);

	const _handleRadioSelect = (selected) => {
		setSelectedValue(selected);
		setSpecialErrors(name, null);
		if (shouldValidateOther.current && !selected.length) {
			setSpecialErrors(name, { rule: 'required' });
			shouldValidateOther.current = true;
		} else {
			shouldValidateOther.current = false;
			setSpecialErrors(`${ name }[${ OTHER }]`, null);
		}
	};

	const _handleOtherOnChange = (event) => {
		const { target } = event;
		const rule = target.value ? null : { rule: 'required' };
		setSpecialErrors(`${ name }[${ OTHER }]`, rule);
		setOtherValue(target.value);
	};

	return (
		<div className="fab-InputWrapper CandidateField__multiGroup">
			<div className="fab-FormColumn CandidateField__multiGroupColumn">
				<legend
					className={ classNames('fab-RadioGroup__legend', {
						'fab-RadioGroup__legend--required': isRequired,
						'fab-RadioGroup__legend--error': specialErrors[name],
					}) }
					htmlFor={ name }
				>
					{ label }
				</legend>
				<div className="fab-RadioGroup" id={ name }>
					{ options.map((option) => {
						return (
							<div key={ `option_${ id }_${ option.id }` } className="fab-Radio">
								<input
									ref={ validationRegistration } // only need it on this one
									checked={ selectedValue == option.id }
									className="fab-Radio__input"
									disabled={ Boolean(isDisabled) }
									id={ `${ name }[${ option.id }]` }
									name={ name }
									onChange={ e => _handleRadioSelect(e.target.value) }
									type="radio"
									value={ option.id }
								/>
								<label className="fab-Radio__label CandidateField__multiGroup--label" htmlFor={ `${ name }[${ option.id }]` }>
									{ option.option }
								</label>
							</div>
						);
					}) }
					{
						hasOther === 'yes' ? (
							<div key={ `option_${ id }_other` } className="fab-Radio">
								<input
									checked={ selectedValue === OTHER }
									className="fab-Radio__input"
									disabled={ Boolean(isDisabled) }
									id={ `${ name }[option_${ OTHER }]` }
									name={ name }
									onChange={ e => _handleRadioSelect(e.target.value) }
									type="radio"
									value={ OTHER }
								/>
								<label className="fab-Radio__label" htmlFor={ `${ name }[option_${ OTHER }]` }>
									{ $.__('Other') }
								</label>
							</div>
						) : null
					}
				</div>
				<SlideTransition isOpen={ Boolean(selectedValue === OTHER) }>
					<div className="CandidateField__multi">
						<div className="CandidateField__multiOther">
							<div className="fab-InputWrapper CandidateField__multiInputWrapper">
								<input
									ref={ otherInputRef }
									className={ classNames('fab-TextInput fab-TextInput--width100', {
										'fab-TextInput--error': specialErrors[`${ name }[${ OTHER }]`],
									}) }
									disabled={ Boolean(selectedValue !== OTHER) }
									id={ `${ name }[${ OTHER }]` }
									name={ `${ name }[${ OTHER }]` }
									onChange={ e => _handleOtherOnChange(e) }
									placeholder={ $.__('Please Specify') }
									required={ true }
									type="text"
									value={ otherValue }
								/>
							</div>
						</div>
						{ specialErrors[`${ name }[${ OTHER }]`] && (
							<span className="fab-FormNote fab-FormNote--error">
								{ $.__('Response is required') }
							</span>
						) }
					</div>
				</SlideTransition>
			</div>
		</div>
	);
};
