import { Component, createRef } from 'react';
import { isEqual } from 'lodash';
import { default as PopoverModule } from 'Popover.mod';

export class Popover extends Component {
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.settings, this.props.settings)) {
			this._popover.destroy();
			this._createPopover();
		}
	}

	componentDidMount() {
		this._createPopover();
	}

	componentWillUnmount() {
		this._popover.destroy();
	}

	render() {
		const {children, wrapperClassName} = this.props;

		return <span className={wrapperClassName} ref={this._popoverElement}>{children}</span>;
	}

	_createPopover = () => {
		this._popover = PopoverModule.create(this._popoverElement.current, this.props.settings);
	};

	_popoverElement = createRef();
}
