import Ajax from '@utils/ajax';
import { extendURL } from '@utils/url';

export function postEmploymentStatus(fieldId, fieldName, formValues) {
	const data = Ajax.prepareData(
		{
			action: 'new-section',
			ajax: 1,
			field: fieldName,
			name: formValues.name,
			acaStatus: formValues.acaStatus === '' ? null : formValues.acaStatus,
		},
		Ajax.prepareDataTypes.FORM_DATA
	);

	const url = extendURL({
		fieldId,
	}, '/ajax/list_insert.php');

	return Ajax.post(url, data);
}

export function wasSaveSuccessful(response) {
	const {
		data,
	} = response;

	if (!data) {
		return false;
	}

	return data.ret === 'SUCCESS' || data.response === 'SUCCESS';
}
