import { FunctionComponent } from 'react';
import './note-employment-status-tax-type.styl';

const EmploymentStatusNote: FunctionComponent<DynamicForm.NoteElementProps> = ({ type }) => {

	const warningText = type === 'updatedTaxType' ? $.__('We updated the Employee Tax Type to a value that matches the Employment Status.') : $.__('Based on the Employment Status, the Employee Tax Type may need to be updated.');

	return (
		<div className="TaxTypeWarningTextWrapper DynamicForm--EmploymentStatusNote">
			<div className="TaxTypeWarningText">
				<div className="TaxTypeWarningText__text">
					{ warningText }
				</div>
				<div className="TaxTypeWarningText__doubleCheck">
					{ $.__('Double-check and make sure it looks right.') }
				</div>
			</div>
		</div>
	);
};

export default EmploymentStatusNote;
