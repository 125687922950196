import { ReactElement, useState, useCallback } from 'react';
import {
	StandardModal, Button, TextButton, Select,
	Divider, CardSize,
} from '@bamboohr/fabric';
import { FileList } from './components/file-list';
import {
	removeModalContainer,
} from 'show-modal.mod';
import { FileDetails, SelectedFiles } from './types';
import useStyles from './shared-files-manager-modal.styles';
import classNames from 'classnames';
import { ifFeature } from "@utils/feature";


interface Props {
	sections: Array<FileDetails>;
	initialSelections: SelectedFiles[]
	updateFilesInForm: (files: SelectedFiles[]) => void
	modalContainerId: string
	modalCallback: (files: SelectedFiles[]) => void | null
	tooltipScrollOffset: number
	filesEndpoint: string
}

interface selectOptions {
	text: string;
	value: string;
}

function generateSelectOptionsArray(sections: Array<FileDetails>): Array<selectOptions> {
	const selectOptions = [{ text: $.__('All Files'), value: 'all' }];

	sections.forEach((section) => {
		if (section.visibility !== 'hidden') {
			selectOptions.push({ text: section.name, value: section.id });
		}
	});

	return selectOptions;
}

function getNameFromSectionId(id: string, sections: Array<FileDetails>): string {

	const section = sections.find(currentSection => currentSection.id === id);

	return (section) ? section.name : '';
}

export function SharedFilesManagerModal(props: Props): ReactElement {
	const styles = useStyles();

	const {
		sections,
		updateFilesInForm,
		initialSelections = [],
		modalContainerId,
		modalCallback,
		tooltipScrollOffset,
		filesEndpoint
	} = props;

	const [selectedFiles, setSelectedFiles] = useState(initialSelections);
	const [selectedItem, setSelectedItem] = useState(['all']);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

	function handleCloseModal() {
		removeModalContainer(modalContainerId);
	}

	function handleFileSelection(file: SelectedFiles, checked: boolean) {
		setIsSubmitDisabled(false);
		if (checked) {
			setSelectedFiles(prev => [...prev, file]);
		} else {
			const selected = [...selectedFiles];
			selected.splice(selected.findIndex(each => each.fileId === file.fileId), 1);
			setSelectedFiles(selected);
		}
	}

	function handleToggleRequired(fileId: string, checked: boolean): void {
		const index = selectedFiles.findIndex(e => e.fileId === fileId);
		setSelectedFiles((prev) => {
			prev[index].required = checked ? 'yes' : 'no';
			return prev;
		});
	}

	function handleSubmitFiles(): void{
		updateFilesInForm(selectedFiles);
		if (modalCallback) {
			modalCallback(selectedFiles);
		}
		handleCloseModal();
	}

	function editFileProperty(id: string, property: string, value: string){
		const filesCopy = [...selectedFiles]
		const fileIndex = filesCopy.findIndex(e => e.fileId === id)
		if(fileIndex !== -1){
			const newFile = {...selectedFiles[fileIndex]}
			newFile[property] = value
			filesCopy.splice(fileIndex, 1, newFile)
			setSelectedFiles(filesCopy)
		}
	}

	const footerActions = ifFeature('encore', [
			<TextButton key="secondary" onClick={ handleCloseModal } type="button">
				Cancel
			</TextButton>,
			<Button disabled={ selectedFiles.length === 0 || isSubmitDisabled } key="primary" onClick={ handleSubmitFiles} type="button">
				{ $.__('Add Selected') }
			</Button>,
		],
		[
			<Button clickAction={ handleSubmitFiles } isDisabled={ selectedFiles.length === 0 || isSubmitDisabled } key="primary" type="button">
				{ $.__('Add Selected') }
			</Button>,
			<TextButton clickAction={ handleCloseModal } key="secondary" type="button">
				Cancel
			</TextButton>,
		]
	);

	return (

		<StandardModal
			isOpen={ true }
			onRequestClose={ handleCloseModal }
		>
			<StandardModal.Body
				renderFooter={ <StandardModal.Footer actions={ footerActions }/> }
				renderHeader={ <StandardModal.Header hasCloseButton={ true } title={ $.__('Add Files') } /> }
				size={ifFeature('encore', CardSize.MEDIUM)}
			>
				<StandardModal.Constraint>
					<div className={ classNames(styles.addFileModal) }>
						<form className={ classNames(styles.addFileForm) }>
							<div className={ classNames(styles.fileListModalSubheader) }>
								<div>
									{ifFeature(
										'encore',
										<Select
											isClearable={ selectedItem[0] !== 'all' }
											items={ generateSelectOptionsArray(sections) }
											onClear={ (): void => { setSelectedItem(['all']); } }
											onSelect={ (value): void => { setSelectedItem([value]); } }
											selectedValues={ selectedItem }
											width={ 6 }
											size="small"
										/>,
										<Select
											isClearable={ selectedItem[0] !== 'all' }
											items={ generateSelectOptionsArray(sections) }
											onClear={ (): void => { setSelectedItem(['all']); } }
											onSelect={ (value): void => { setSelectedItem([value]); } }
											selectedValues={ selectedItem }
											width={ 6 }
										/>
									)}
								</div>
								<div className={ classNames(styles.fileListCount) }>
									{ $.__n('%s File Selected', '%s Files Selected', selectedFiles.length) }
								</div>
							</div>
							{ ifFeature('encore', <Divider color="neutral-extra-weak" />) }
							<FileList
								editFileProperty={ editFileProperty }
								filesEndpoint={ filesEndpoint }
								handleFileSelection={ handleFileSelection }
								handleToggleRequired={ handleToggleRequired }
								key={ selectedItem[0] }
								sectionId={ selectedItem[0] }
								sectionName={ getNameFromSectionId(selectedItem[0], sections) }
								selectedFiles={ selectedFiles }
								tooltipScrollOffset={ tooltipScrollOffset }
							/>
						</form>
					</div>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
}
