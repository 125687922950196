import {
	getField,
	getSelectedValue,
	isSelectedPSSyncing,
	Fields,
} from 'dynamic-form';

/**
 *
 * Validates if Trax that Paid Per is:
 * 'Year' if Salary
 *
 * @param context
 * @param field
 * @param params
 */
export const validatePaidPerTrax = (context: DynamicForm.Context, field: DynamicForm.DataProps, params: any) => {
	return (value: string | undefined = ''): boolean => {
		if (isSelectedPSSyncing(context) && value) {
			const payTypeField = getField(context, Fields.payType) as DynamicForm.SelectElement;
			if (payTypeField) {
				const payTypeValue = getSelectedValue(payTypeField) as string | undefined;
				if (payTypeValue?.toLowerCase() === 'salary' && value?.toLowerCase() !== 'year') {
					return false;
				}
			}
		}
		return true;
	};
};
