import { cloneDeep, findIndex } from 'lodash';
import { FileDetails, SelectedFiles } from '../../types';

/**
 * Add meta data that relates to the file
 * @param {object} file          The file object that might need some meta data
 * @param {array}  selectedFiles Collection of currently selected files
 */
export function addFileMeta(file: FileDetails, selectedFiles: Array<SelectedFiles>): void {
	if (!file.meta) {
		file.meta = { disabled: false };
	}

	// Only apply this data if there are currently selected files
	if (selectedFiles.length) {
		const selectedIndex = findIndex(selectedFiles, (sfile) => { return sfile.fileId == file.id; });

		// Only add meta data to selected files
		if (selectedIndex > -1) {
			file.meta.checked = true;
			file.meta.required = selectedFiles[selectedIndex].required;

			selectedFiles.splice(selectedIndex, 1);
		}
	}
}

/**
 * Merge selected file data into the file data
 * @param {array} files
 * @param {array} selectedFiles
 * @return {array}
 */
export function mergeFileData(files: Array<FileDetails>, selectedFiles: Array<SelectedFiles>): Array<FileDetails> {
	const selectedFilesClone = cloneDeep(selectedFiles);
	const filesClone = cloneDeep(files);

	filesClone.forEach((file) => {
		addFileMeta(file, selectedFilesClone);
	});

	return filesClone;
}
