import { ReactElement } from 'react';
import { ItemType, MultiStepModalProps, MultiStepModal } from '@bamboohr/fabric';

export enum CancellationType {
	ACCOUNT = 'account',
	PAYROLL = 'payroll',
	PERFORMANCE = 'performance',
	TIME_TRACKING = 'time_tracking',
	JOB_OPENINGS = 'job_openings',
	BENEFIT_ADMIN = 'benefit_admin',
}

export enum ReasonOptionFeature {
	ACCOUNT = 'account',
	PAYROLL = 'payroll',
	PAYROLL_NEW_PROCESSING_METHODS = 'payroll_new_processing_methods',
	PAYROLL_NO_FED_ID_REASONS = 'payroll_no_use_fed_id_reasons',
	PAYROLL_ACK_1 = 'payroll_ack_1_option',
	PAYROLL_ACK_2 = 'payroll_ack_2_option',
	PERFORMANCE = 'performance',
	TIME_TRACKING = 'time_tracking',
	JOB_OPENINGS = 'job_openings',
	BENEFIT_ADMIN = 'benefit_admin',
}

export interface ReasonData {
	feature: ReasonOptionFeature;
	options: {
		label: string;
		value: string;
	}[];
}

export type CancellationReasons = {
	[key in ReasonOptionFeature]?: ItemType[];
};

export interface CancellationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmitSuccess?: () => void;
	types: CancellationType[];
}

export type CancellationFormsData = {
	[key in CancellationType]?: CancellationFormData;
} & {
	cancellation_date?: CancellationRequestData['cancellation_date'];
};

export interface CancellationFormData {
	cancellation_date?: CancellationRequestData['cancellation_date'];
	feature?: CancellationType;
	reason?: string;
	reason_details?: string;
	payroll_data?: PayrollFormData;
	job_opening_data?: {
		number_to_cancel: number;
	};
}

export interface CancellationRequestData {
	cancellation_date: string;
	cancellations: Omit<CancellationFormData, 'cancellation_date'>[];
}

export interface CancellationContentProps {
	biId?: string;
	formValues?: CancellationFormData;
	isLastStep: boolean;
	nextBillingDates: ItemType[];
	onClose: CancellationModalProps['onClose'];
	onNext: (data: CancellationFormData | CancellationFormsData) => void;
	onPrevious?: () => void;
	setIsLoading: (isLoading: boolean) => void;
	reasons: CancellationReasons;
	renderHeader: ReactElement;
	types?: CancellationType[];
}

export interface CancellationStatus {
	date_requested: string;
	feature?: CancellationType;
	pending_cancellation: boolean;
	user_who_requested_cancellation: string;
}

export interface PayrollFormData {
	final_pay_date?: string;
	use_federal_id_after_final_date?: 'yes' | 'no';
	pay_through_quarter?: string;
	pay_through_year?: string;
	new_processing_method?: string;
	new_processing_method_details?: string;
	reason_not_using_federal_id?: string;
	reason_not_using_federal_id_details?: string;
	ack_1_response?: boolean;
	ack_2_response?: boolean;
}
