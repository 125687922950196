import { 
	Flex,
	TextButton 
} from '@bamboohr/fabric';
import { classNameFromObject } from '@utils/dom';
import { showSlidedown } from 'Alerts.mod';
import './payee.styl'

export function validatePayeeForm(data) {
	const {
		name,
		street1,
		city,
		state,
		zip,
		phone
	} = data;
	let valid = true;

	if (!name.value) {
		name.error = true;
		valid = false;
	}
	if (!street1.value) {
		street1.error = true;
		valid = false;
	}
	if (!city.value) {
		city.error = true;
		valid = false;
	}
	if (!state.value) {
		state.error = true;
		valid = false;
	}
	if (!zip.value) {
		zip.error = true;
		valid = false;
	}
	if (!phone.value) {
		phone.error = true;
		valid = false;
	}

	if (!valid) {
		return {
			success: valid,
			errorData: {
				isProcessing: false,
				name,
				street1,
				city,
				state,
				zip,
				phone
			}
		};
	}

	return {success: valid};
}

export function getFieldBoxClasses(required, error) {
	return classNameFromObject({
		'fieldBox': true,
		'required': required,
		'error': error
	});
}

export function prepPayeeSaveData(data, employeeId) {
	if (
		data &&
		typeof data === 'object' &&
		data.hasOwnProperty('id') &&
		data.name &&
		data.street1 &&
		data.hasOwnProperty('street2') &&
		data.city &&
		data.state &&
		data.zip &&
		data.phone &&
		data.hasOwnProperty('email')
	) {
		return {
			success: true,
			data: {
				employeeId: employeeId,
				id: data.id,
				payeeName: data.name.value,
				addressLine1: data.street1.value,
				addressLine2: data.street2.value,
				city: data.city.value,
				stateId: data.state.value,
				zipcode: data.zip.value,
				phone: data.phone.value,
				email: data.email.value
			}
		};
	}
	return {success: false};
}

export function prepPayeeEditData(payee) {
	if (
		payee &&
		typeof payee === 'object' &&
		payee.hasOwnProperty('id') &&
		payee.hasOwnProperty('payeeName') &&
		payee.hasOwnProperty('addressLine1') &&
		payee.hasOwnProperty('addressLine2') &&
		payee.hasOwnProperty('city') &&
		payee.hasOwnProperty('stateId') &&
		payee.hasOwnProperty('zipcode') &&
		payee.hasOwnProperty('phone') &&
		payee.hasOwnProperty('email')
	) {
		return {
			success: true,
			data: {
				id: payee.id,
				name: {
					value: payee.payeeName ? payee.payeeName : ''
				},
				street1: {
					value: payee.addressLine1 ? payee.addressLine1 : ''
				},
				street2: {
					value: payee.addressLine2 ? payee.addressLine2 : ''
				},
				city: {
					value: payee.city ? payee.city : ''
				},
				state: {
					value: payee.stateId ? payee.stateId : ''
				},
				zip: {
					value: payee.zipcode ? payee.zipcode : ''
				},
				phone: {
					value: payee.phone ? payee.phone : ''
				},
				email: {
					value: payee.email ? payee.email : ''
				}
			}
		};
	}
	return {success: false};
}

export const showPayeeValidationError = (
	{
		errorOrigin,
		errorType,
		globalPayeeType
	}, 
	payee
) => {
	let globalPayeeGarnishmentType;
	if (globalPayeeType){
		if (globalPayeeType === "CS"){
			globalPayeeGarnishmentType = "Child Support"
		}
		else {
			globalPayeeGarnishmentType = "Tax Levy"
		}
	}
	// errorOrigin types: 1 = Global, 2 = Custom
	// errorTypes: 1 = name error, 2 = address error, 3 = name and address error
	if ( errorOrigin === 2 && errorType === 1){
		showSlidedown( $.__('Bummer! Looks like "%s" is already used. Please try a unique name', payee.payeeName), 'error');
	}
	if ( errorOrigin === 2 && errorType === 2){
		showSlidedown( $.__('Looks like this address is already being used. Please update, or use existing payee with this address.'), 'error');
	}
	if ( errorOrigin === 2 && errorType === 3){
		showSlidedown( $.__('Bummer! Looks like this Payee Name, and Address is already used. Please update below.'), 'error');
	}
	if ( errorOrigin === 1 && errorType === 1){
		showSlidedown( $.__('Bummer! Looks like this payee name is associated with an existing %s payee. Please update.', globalPayeeGarnishmentType), 'error');
	}
	if ( errorOrigin === 1 && errorType === 2){
		showSlidedown( $.__('Bummer! Looks like this address is associated with an existing %s payee. Please update.', globalPayeeGarnishmentType), 'error');
	}
	if ( errorOrigin === 1 && errorType === 3){
		showSlidedown( $.__('Bummer! Looks like this payee name, and address is associated with an existing %s payee. Please update.', globalPayeeGarnishmentType), 'error');
	}
}

const getCustomPayeeSubscript = (
	closeModal,
	customPayeeId,
	customPayees,
	errorLocation, // 'name' || 'address'
	setSelectedPayee,
	setSelectedState,
	setIsPayeeFromValidator,
	type,
) => {

	const onClick = () => {
		const payeeState = customPayees.find(payee => payee.id === customPayeeId.toString()).stateId;
		setIsPayeeFromValidator(true);
		setSelectedState([payeeState]);
		setSelectedPayee([customPayeeId.toString()]);
		closeModal();
	}

	return (
		<>
			<Flex flexDirection={ 'row' }>
				<span className={ `subscript__text ${ errorLocation }` }>
					{ $.__('This %s already exists. ', errorLocation) }
				</span>
				{ type !== 'edit' &&
					<TextButton
						className={ `subscript__button ${ errorLocation }` }
						onClick={ onClick }
						type='button'
					>
						{ $.__('Use existing payee') }
					</TextButton>
				}
			</Flex>
		</>
	)
}

export const showPayeeValidationSubscript = (
	setShowNameSubscript,
	setNameError,
	setShowAddressSubscript,
	setAddressError,
	setSubscriptContent, 
	setSelectedPayee,
	closeModal,
	customPayees,
	setSelectedState,
	setIsPayeeFromValidator,
	type,
	{
		errorOrigin,
		errorType,
		globalPayeeType,
		customPayeeId
	}
) => {
	if ( errorOrigin === 2 ){
		if (errorType === 1 || errorType === 3){
			setShowNameSubscript(true);
			setNameError(true);
			setSubscriptContent(getCustomPayeeSubscript(closeModal, customPayeeId, customPayees, 'name', setSelectedPayee, setSelectedState, setIsPayeeFromValidator, type));
		}
		if (errorType === 2){
			setShowAddressSubscript(true);
			setAddressError(true);
			setSubscriptContent(getCustomPayeeSubscript(closeModal, customPayeeId, customPayees, 'address', setSelectedPayee, setSelectedState, setIsPayeeFromValidator, type));
		}
	}
	if ( errorOrigin === 1 ){
		let globalPayeeGarnishmentType;
		if (globalPayeeType === "CS"){
			globalPayeeGarnishmentType = "Child Support"
		}
		else {
			globalPayeeGarnishmentType = "Tax Levy"
		}

		setSubscriptContent(<>{$.__('Please update, or set this deduction up as a %s deduction.', globalPayeeGarnishmentType)}</>)
		
		if (errorType === 1 || errorType === 3){
			setShowNameSubscript(true);
			setNameError(true);
		}
		if (errorType === 2){
			setShowAddressSubscript(true);
			setAddressError(true);
		}
	}
}

