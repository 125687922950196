import {sum} from 'd3-array';
import {
	cloneDeep,
	defaultsDeep,
	uniqueId,
} from 'lodash';

import Donut from './shared/donut';
import Legend from './shared/legend';

import EventRegistry from 'EventRegistry.util';
import {trimLines} from 'String.util';
import Popover from 'Popover.mod';

/*******************************************************************************
	Data contract for donut percentage chart

	{
		data: [
			{
				"label": "Marketing",
				"count": 5,
				"countDetailLink": "/employees/..."
			},
			{
				"label": "Human Resources",
				"count": 1,
				"countDetailLink": "/employees/..."
			}
			...
		],
		name: "Department"
	}

	NOTE: Be aware that if the countDetailLink is affected by permissions, those
	checks will need to be done in the BE. countDetailLink should return null if
	a user does not have perms to view the link

*******************************************************************************/

const CLASS_NAME = 'DonutPercentageChart';

const DEFAULT_OPTIONS = {
	chartClass: '',
	color: ['#8aba00', '#e8454d', '#f96422', '#7092eb', '#d05489', '#3bab38', '#ffcb15', '#11b9aa', '#9a6dc8', '#1b8ab4', '#6a6a6a'],
	getColor: null, // use external d3 scaleOrdinal to sync colors between multiple charts - candidate source report -101
	donut: {
		// See /shared/donut.js for options
	},
	groupedPopoverTemplate: '',
	legend: {
		// See /shared/legend.js for options
	},
	maxVisibleItems: 7,
	preserveOrder: true,
	boldLegendOnSliceHover: false
};

export default class DonutPercentageChart extends EventRegistry {
	constructor(selector, options = DEFAULT_OPTIONS) {
		let container;

		super();

		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);

		container = $('<div>')
			.attr('class', `${ CLASS_NAME } ${ this._options.chartClass }`)

		this.element = (typeof selector === 'string') ? document.querySelector(selector) : selector;

		container.appendTo(this.element);

		this._donut = new Donut(container[0], this._options.donut);
		this._donut.on('sliceEnter', this._sliceEnterHandler.bind(this));
		this._donut.on('sliceLeave', this._sliceLeaveHandler.bind(this));

		this._legend = new Legend(container[0], this._options.legend);

	}

	/**
	 * Draws a donut chart along with a legend that represents the model
	 * @param  {object} model The model
	 */
	draw(model) {
		const internalModel = this._createInternalModel(model);

		this._donut.draw(internalModel.visible);
		this._legend.draw(internalModel.visible.data);
	}

	/**
	 * Creates an model that is capable of restricting the number of items the
	 * donut chart and legend can represent. If the maximum number of items is
	 * reached, it will group the remaining items into a single item.
	 * @param  {object} model External model
	 * @return {object}       Internal model
	 */
	_createInternalModel(model) {
		let {maxVisibleItems} = this._options;
		let hiddenItems, visibleItems;
		let orderedItems = model.data;

		model = cloneDeep(model);
		if (model.data.length > this._options.maxVisibleItems) {
			maxVisibleItems--;
		}

		if (!this._options.preserveOrder) {
			orderedItems = orderedItems.sort((a, b) => b.count - a.count);
		}

		hiddenItems = orderedItems.slice(maxVisibleItems);
		visibleItems = orderedItems.slice(0, maxVisibleItems);

		if (hiddenItems.length) {
			visibleItems.push({
				label: $.__('Other'),
				count: sum(hiddenItems, d => d.count),
				items: hiddenItems
			});
		}

		model.data = visibleItems.map((val, i) => {
			val.color = this._options.color[i];
			if (typeof this._options.getColor === 'function') {
				val.color = this._options.getColor(val.label);
			}
			val.id = uniqueId('donut-percentage-item-');
			return val;
		});

		return {
			visible: model
		};
	}

	/**
	 * Event handler for when a slice of the donut chart is hovered
	 * @param  {object} data Data associated with the hovered slice
	 * @param  {object} path SVG path element
	 */
	_sliceEnterHandler(data, path) {
		this.trigger('sliceEnter', ...arguments);

		if (this._options.boldLegendOnSliceHover) {
			this._legend.sliceEnter(path.id);
		}

		if (!data.items) {
			return;
		}

		if (this._options.groupedPopoverTemplate) {
			this._groupedPopover = Popover.create(path, {
				closeX: false,
				html: microTemplate(this._options.groupedPopoverTemplate, {data})
			})
				.show();
		}
	}

	/**
	 * Event handler for when a slice of the donut chart is hovered
	 * @param  {object} data Data associated with the hovered slice
	 * @param  {object} path SVG path element
	 */
	_sliceLeaveHandler(data, path) {
		this.trigger('sliceLeave', ...arguments);

		if (this._options.boldLegendOnSliceHover) {
			this._legend.sliceLeave(path.id);
		}

		if (this._groupedPopover) {
			this._groupedPopover.destroy();
			this._groupedPopover = null;
		}
	}
}
