/* eslint-disable react/no-danger */
import classNames from 'classnames';
import { Icon, Loader } from '@bamboohr/fabric';
import React from 'react';
import ReactModal from 'react-modal';
import './styles.styl';
import { sanitizeDangerousContent } from './utils';

export const CustomModal = function CustomModal(props) {
	const {
		children,
		content,
		dangerousContent,
		handleClose,
		hasCloseButton,
		isLoading,
		isOpen,
		isProcessing,
		reactModalProps,
	} = props;

	const childPriorityContent = (children ? children : content);

	return (
		<ReactModal { ...reactModalProps }>
			{ isOpen && isLoading && (
				<div className={ 'legacyModal__modalLoader' }>
					<Loader />
				</div>
			) }
			<div
				className={ classNames('legacyModal__main', 'js-fabric-modal-main') }
				dangerouslySetInnerHTML={ dangerousContent ? {__html: sanitizeDangerousContent(dangerousContent)} : null }
				id="fabricModalContent"
				role="main"
			>
				{ dangerousContent ? null : childPriorityContent }
			</div>
			{ (hasCloseButton && !isProcessing) && (
				<button className={ 'legacyModal__closeButton' } onClick={ handleClose } type="button">
					<Icon name="fab-x-16x16" />
				</button>
			) }
		</ReactModal>
	);
};
