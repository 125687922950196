import './delete.styl';

import { handleStackableModalState, updateFileSectionCounts } from './util';

import { DeleteEmployeeFolderModal } from './components/delete-employee-folder-modal';
import { ajax } from '@utils/ajax';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { showModal } from 'show-modal.mod';
import { uniq } from 'lodash';

export function deleteCompanyFile(
	fileId,
	stacked,
	isEmployeeFile,
	onSuccessCallBack,
) {
	deleteCompanyFiles(
		$(`[data-file_id=${fileId}]`),
		stacked,
		isEmployeeFile,
		onSuccessCallBack,
	);
}

export function deleteCompanyFiles(
	selected,
	stacked,
	isEmployeeFile,
	onSuccessCallBack = false,
) {
	let confirmDelete = false;
	let nonVisibleFileID = null;
	let invalidFiles = 0;
	let hasImplementationFilesRemoved = false;

	// If there is a non-visible file, save the ID
	if (selected.selector.match(/(\d+)/)) {
		nonVisibleFileID = parseInt(selected.selector.match(/(\d+)/)[1]);
	}

	if (selected.length || nonVisibleFileID) {
		const fileIds = [];

		if (selected.length) {
			selected.each(function () {
				if (
					$(this).data('can_delete') === 'yes' ||
					$(this).data('can_delete') === undefined
				) {
					fileIds.push($(this).data('file_id'));
				} else {
					invalidFiles++;
					if ($(this).data('is-implementation') === 'yes') {
						hasImplementationFilesRemoved = true;
					}
				}
			});

			if (
				fileIds.length > 3 ||
				selected.hasClass('js-tasklist') ||
				selected.hasClass('js-esignature')
			) {
				confirmDelete = true;
			}
		} else if (nonVisibleFileID) {
			fileIds.push(nonVisibleFileID);
		}

		if (fileIds.length < 1) {
			return;
		}

		showDeleteModal(
			fileIds,
			stacked,
			isEmployeeFile,
			confirmDelete,
			onSuccessCallBack,
			invalidFiles,
			hasImplementationFilesRemoved,
		);
	}
}

export function deleteEmployeeFile(fileId, stacked) {
	deleteCompanyFiles($(`[data-file_id=${fileId}]`), stacked, true);
}

export function deleteEmployeeFiles(selected, stacked) {
	deleteCompanyFiles(selected, stacked, true);
}

// this will have to find out the number of files and which employees those files belong to
// TODO: during cleanup get rid of sectionLink parameter here and update calls in js_templates.html/twig and app/templates/twig/employees/files/index.html.twig
export function deleteEmployeeFolder(
	sectionLink,
	employeeId = null,
	sectionId,
	sectionName,
	deleteCallback,
) {
	// get the count of files for this folder across all employees
	$.get(
		`/${appPrefix}employees/files/getDeleteFolderCounts`,
		{ id: sectionId },
		function (response) {
			let count = 0;
			let empCount = 0;
			const employees = response.counts;

			if (employees) {
				for (const d in employees) {
					empCount++;
					count += parseInt(employees[d].count) || 0;
				}
			} else {
				for (const d in response) {
					empCount++;
					count += parseInt(response[d].count);
				}
			}

			const handleDeleteAction = async (empId, secId, fileCount) => {
				if (deleteCallback && typeof deleteCallback === 'function') {
					const response = await deleteCallback(secId);
					return handleDeleteFolderResponse(response, secId, fileCount);
				} else {
					return confirmDeleteFolder(empId, secId, fileCount);
				}
			};

			const hasTemplates = response.templates || false;
			showModal(
				<DeleteEmployeeFolderModal
					affectedEmployees={employees}
					deleteAction={handleDeleteAction}
					employeeCount={empCount || 0}
					employeeId={employeeId}
					fileCount={count || 0}
					hasTemplates={hasTemplates}
					sectionId={sectionId}
				/>,
			);
		},
		'json',
	);
}

function handleDeleteFolderResponse(response, sectionId, fileCount) {
	if (response.success || response.data.success) {
		const $folder = $(`[data-section_id=${sectionId}]`);
		if ($folder.hasClass('selected')) {
			loadSectionFiles('all', $('select[name=sort-files]').val());
			$('#file-sections .sectionLink.all')
				.addClass('selected')
				.find('.js-FileFolder')
				.addClass('fab-SideNavigation__link--active')
				.find('ba-icon[encore-name$="regular"]')
				.attr('encore-name', function (i, previousValue) {
					return previousValue.replace('regular', 'solid');
				});
		}
		$folder.remove();
		if (
			fileCount > 0 &&
			$('#file-sections .sectionLink.selected').hasClass('all')
		) {
			// reload all files section to get new counts and remove the files
			loadSectionFiles('all', $('select[name=sort-files]').val());
		}
		const successMessage =
			response.success_message ?? 'Successfully deleted folder.';
		setMessage(successMessage, 'success');
	} else {
		setMessage(response.error, 'error');
	}
}

function confirmDeleteFolder(employeeId, sectionId, fileCount) {
	return ajax
		.post(`/employees/${appPrefix}ajax/folder/delete/${employeeId}`, {
			id: sectionId,
			employeeId,
		})
		.then(function (response) {
			handleDeleteFolderResponse(response, sectionId, fileCount);
		})
		.catch(function (error) {
			setMessage(error, 'error');
		});
}

export function deleteFolder(sectionLink) {
	const count = sectionLink
		.find('span.count')
		.text()
		.replace(/[\(\)]/g, '');
	let removeMsg = $.__(
		'Are you sure you want to delete the folder "%1"?',
		sectionLink.data('foldername'),
	);
	if (count > 0) {
		removeMsg = $.__n(
			'Are you sure you want to delete the folder "%2" and its %1 file?',
			'Are you sure you want to delete the folder "%2" and all of its %1 files?',
			count,
			sectionLink.data('foldername'),
		);
	}

	window.BambooHR.Modal.setState({
		aggressiveConfirmationText:
			'Type <strong>“Delete”</strong> to permanently remove the folder and its files.',
		headline: removeMsg,
		onClose: () => {
			window.BambooHR.Modal.setState({
				isOpen: false,
			});
		},
		primaryAction: () => {
			window.BambooHR.Modal.setState({ isProcessing: true }, true);
			$.ajax({
				url: `/ajax/${appPrefix}files/edit_folder.php`,
				type: 'post',
				data: { action: 'delete', id: sectionLink.data('section_id') },
				success(data) {
					if (data.success) {
						// todo: reset selected folder if necessary
						if (sectionLink.parent().hasClass('selected')) {
							window.resetFiles();
							loadSectionFiles('all', $('select[name=sort-files]').val());
							$('.allFilesListItem').addClass('selected');
						}
						sectionLink.parent().remove();
						if (
							count > 0 &&
							$('#file-sections .sectionLink.selected').hasClass('all')
						) {
							// reload all files section to get new counts and remove the files
							window.resetFiles();
							loadSectionFiles('all', $('select[name=sort-files]').val());
						}
						setMessage(data.success_message, 'success');
						window.BambooHR.Modal.setState({ isOpen: false });
					} else {
						window.BambooHR.Modal.setState({ isProcessing: false }, true);
						setMessage(data.error, 'error');
					}
				},
				dataType: 'json',
			});
		},
		primaryActionText: $.__('Delete Folder'),
		isOpen: true,
		title: ifFeature('encore', '', $.__('Just Checking...')),
		type: 'aggressiveConfirmation',
	});
}

export function showDeleteModal(
	originalFileIds,
	stacked = false,
	isEmployeeFile = false,
	confirmDelete = false,
	onSuccessCallBack = false,
	filesRemoved = 0,
	hasImplementationFilesRemoved = false,
) {
	const url = isEmployeeFile
		? `/employees/files/delete_files`
		: `/files/delete_files`;
	const $filesWrapper = window.$filesTableWrapper || false;
	const fileIds = uniq(originalFileIds);

	const htmlUrl = `${url}?${$.param({
		fileIds,
		filesRemoved,
		hasImplementationFilesRemoved: hasImplementationFilesRemoved ? 1 : 0,
		sort: $('select[name="sort-files"]').val(),
	})}`;

	handleStackableModalState(stacked, true, { isLoading: true });
	ajax.get(htmlUrl).then((response) => {
		const html = document.createElement('div');
		html.innerHTML = response.data;

		const $html = $(html);

		if ($html.find('#js-confirmDelete').val()) {
			confirmDelete = true;
		}

		const headlineText = $html.find('.js-headLine').text();
		const contentHtml = $html.find('.js-content').html();
		const modalType = isEmployeeFile ? 'small' : 'smallSheet';
		const modalOptions = {
			alternativeAction: null,
			dangerousContent: contentHtml,
			contentHasPadding: true,
			headline: headlineText,
			footer: null,
			icon:
				confirmDelete === true
					? ''
					: ifFeature('encore', 'trash-can-regular', 'fab-trash-can-43x48'),
			iconColor: 'danger',
			iconV2Color: 'error-strong',
			isLoading: false,
			onClose: () => {
				handleStackableModalState(stacked, false, { sheetProps: null });
			},
			primaryAction: () => {
				$.ajax({
					url,
					type: 'post',
					data: { action: 'delete', fileIds },
					success(data) {
						if (data.success) {
							if (typeof onSuccessCallBack === 'function') {
								onSuccessCallBack(fileIds);
							}
							fileIds.forEach((fileId) => {
								let $fileElem = $(`tr[data-file_id="${fileId}"]`);
								const $fileWrappers = $(
									'.file-wrapper, .FileWrapper, .FileUploadsEmployee__File',
								);
								let $fileWrapper = $fileWrappers.filter(
									`[id="file-${fileId}"], [id="${fileId}"], [data-fileid="${fileId}"]`,
								);
								if ($fileWrapper.length === 0) {
									$fileWrapper = $fileWrappers.find(
										`[data-file-id="${fileId}"]`,
									);
								}
								$fileElem = $fileElem.add($fileWrapper);
								if ($fileElem.length) {
									const $filesList = $fileElem.closest('.EmployeeFiles');
									const $eeFileUploads = $fileElem.closest(
										'.FileUploadsEmployee',
									);
									$fileElem.remove();
									if (
										$filesList.length > 0 &&
										$filesList.find('.file-wrapper').length === 0
									) {
										$filesList
											.find('.EmployeeFiles__FilesList')
											.toggleClass('hidden', true);
										$('.MsgListing__taskEeFiles').toggleClass('hidden', true);
									}
									if (
										$eeFileUploads.length > 0 &&
										$eeFileUploads.find('.FileUploadsEmployee__File').length ===
											0
									) {
										$eeFileUploads.remove();
										$('.List__FilesEmployee').remove();
									}
								}
							});
							// todo on employee files only show the blank state if there are no folders
							if (
								!isEmployeeFile &&
								$filesWrapper &&
								$filesWrapper.find('tr').length <= 0
							) {
								$filesWrapper.find('.blankState').show();
							}
							const updateFileSectionCountsFn =
								window.updateFileSectionCounts || updateFileSectionCounts;
							updateFileSectionCountsFn(data.employeeId || false);
							setMessage(data.success_message, 'success');

							window.BambooHR.Modal.setState({ isOpen: false });
						} else {
							setMessage(data.error, 'error');
						}
					},
					dataType: 'json',
				});
			},
			primaryActionKey: 'Enter',
			primaryActionText: $.__n('Delete File', 'Delete Files', fileIds.length),
			secondaryAction: () => {
				handleStackableModalState(stacked, false, { sheetProps: null });
			},
			secondaryActionText: $.__n('Keep File', 'Keep Files', fileIds.length),
			isOpen: true,
			title: ifFeature('encore', '', $.__('Just Checking...')),
			type:
				confirmDelete || fileIds.length > 3
					? 'aggressiveConfirmation'
					: modalType,
		};
		if (stacked) {
			modalOptions.type =
				modalOptions.type === 'aggressiveConfirmation'
					? modalOptions.type
					: 'smallSheet';
			window.BambooHR.Modal.setState({ sheetProps: null }, true);
		}
		handleStackableModalState(stacked, true, modalOptions);
	});
}
