import { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';

import { TextArea } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	getClassBasedValidation,
	hasErrors,
	isRequired,
	handleControlledInput,
	fixValidationRegisterName,
} from 'dynamic-form';

const TextareaField: FunctionComponent<DynamicForm.TextareaProps> = (textareaProps) => {
	const {
		props,
		settings,
		context,
	} = textareaProps;
	const { id, name, className } = props;
	const {
		fieldBoxClasses,
		label,
		isObfuscated,
		type,
	} = settings;
	const {
		controls: { Label, FieldBox, FieldDiv, PendingRequest },
		validation: { formState: { errors }, register, setValue },
	} = context;

	const validationName = fixValidationRegisterName(name);
	const hasError = hasErrors(errors, validationName);
	const isFieldRequired = isRequired(context, textareaProps);
	register(validationName, { ...getClassBasedValidation(context, textareaProps), required: isFieldRequired });

	// switching to uncontrolled input
	const { value, ...propsWithoutValue } = props;
	const [encoreValue, setEncoreValue] = useState(value);

	useEffect(() => {
		setValue(validationName, value);
	},[]);

	const handleChange = (e) => {
		handleControlledInput('textarea', id, e.target.value, context);
		setValue(validationName, e.target.value, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	return (
		<FieldBox fieldBoxClasses={ fieldBoxClasses } isRequired={ isFieldRequired }>
			<Label error={ hasError } inputId={ id } label={ label } required={ isFieldRequired } />
			<FieldDiv isObfuscated={ isObfuscated } type={ type }>
				<PendingRequest context={ context } props={ props } settings={ settings } />
				{
					ifFeature(
						'encore',
						<TextArea
							{ ...propsWithoutValue }
							className="" // This is to override the values coming from propsWithoutValue
							onBlur={ handleChange }
							onChange={ (event) => {
								setEncoreValue(event.target.value)
							} }
							size='medium'
							value={ encoreValue }
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore - this is a valid value coming from the BE but fabric has it set as an enum
							width={propsWithoutValue?.width ?? 9}
						/>,
						<textarea
							{ ...propsWithoutValue }
							className={ classnames(className, {
								'fab-Textarea--error': hasError,
							}) }
							defaultValue={ value }
							onBlur={ handleChange }
						>
						</textarea>
					)
				}
			</FieldDiv>
		</FieldBox>
	);
};

export default TextareaField;
