import Comment from '../../containers/comment';
import {
	getConfig,
} from '../../../../constants';

const CommentList = ({ ids, timezones }) => (
	<ul className={ `feedListContainer ${ getConfig().inputPosition === 'bottom' ? 'feedListContainer--bottom' : '' }` }>
		{
			ids.map(id => (
				<Comment
					id={ id }
					key={ `comment${ id }` }
					timezones={ timezones }
				/>
			))
		}
	</ul>
);

export default CommentList;
