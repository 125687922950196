import {
	Select,
} from '@bamboohr/fabric';
import { Component, Fragment } from 'react';
import { noop } from 'lodash';
import Ajax from '@utils/ajax';
import { ifFeature } from '@utils/feature';

/* @startCleanup encore */
import OldSelect from 'select.react';
/* @endCleanup encore */
import { OptionalFieldHelp } from '../optional-field-help.react';

import './linked-selects.styl';

const FAILED_OPTIONS_GET = $.__('Uh Oh, We weren’t able to retrieve the occupation codes. Try refreshing the page.');

export class LinkedSelects extends Component {
	constructor(props) {
		super(props);
		const {
			data: {
				parentSelect,
				childSelect,
			}
		} = props;

		const parentSelection = parentSelect.options.find(option => option.selected === true);
		const childSelection = childSelect.options.length ? childSelect.options.find(option => option.selected === true) : null;

		this.state = {
			parentSelection: parentSelection ? parentSelection.val : null,
			childSelection: childSelection ? childSelection.val : null,
			childOptions: childSelect.options,
			disableChildSelect: false,
		};
	}

	_getUpdatedChildOptions = (parentSelectionId) => {
		const {
			data: {
				getChildOptionsUrl
			}
		} = this.props;

		// If the parent select was cleared out, this value will be falsy
		if (!parentSelectionId) {
			this.setState({
				childSelection: null,
				childOptions: []
			});

			return;
		}

		if (!getChildOptionsUrl) {
			this._triggerGenericError();
			return;
		}

		this.setState({
			disableChildSelect: true,
			childSelection: null,
		});

		Ajax.get(`${ getChildOptionsUrl }/${ parentSelectionId }`)
			.then((response) => {
				const {
					status,
					data,
				} = response;

				if (status === 200 && data && data.options) {
					this.setState({
						disableChildSelect: false,
						childOptions: data.options
					});
				} else {
					this._triggerGenericError();
				}
			})
			.catch(() => this._triggerGenericError());
	};

	_getSelectData = (data, isParent) => {
		const {
			parentSelection,
			childSelection,
			childOptions,
			disableChildSelect,
		} = this.state;
		const {
			viewOnly,
			isUnemploymentField,
		} = this.props;
		const {
			_handleSelectChange,
		} = this;
		const {
			id,
			options,
			placeholder,
		} = data;

		const selectedOption = isParent ? parentSelection : childSelection;
		const rawOptions = isParent ? options : childOptions;
		const namePrefix = isUnemploymentField ? 'uiState' : 'state';

		const formattedOptions = rawOptions.map(option => ({
			id: option.id,
			text: option.displayText,
			value: option.val,
		}));

		return {
			isDisabled: viewOnly || (!isParent && (!formattedOptions.length || disableChildSelect)),
			items: formattedOptions,
			name: `${ namePrefix }[optional][${ id }]`,
			onChange(selectedValues) {
				const [selectedValue = ''] = selectedValues;
				const option = formattedOptions.find((formattedOption) => {
					return formattedOption.value === selectedValue;
				});
				_handleSelectChange(selectedValues, isParent, option ? option.id : null);
			},
			placeholder: placeholder || `-- ${ $.__('Select') } --`,
			selectedValues: [selectedOption],
			width: 9,
		};
	};

	/* @startCleanup encore */
	_getLegacySelectData = (data, isParent) => {
		const {
			parentSelection,
			childSelection,
			childOptions,
			disableChildSelect,
		} = this.state;
		const {
			viewOnly,
			isUnemploymentField,
		} = this.props;
		const {
			id,
			options,
			placeholder,
		} = data;

		const selectedOption = isParent ? parentSelection : childSelection;
		const rawOptions = isParent ? options : childOptions;
		const namePrefix = isUnemploymentField ? 'uiState' : 'state';

		const formattedOptions = rawOptions.map(option => ({
			displayText: option.displayText,
			id: option.id,
			value: option.val,
			selected: option.val === selectedOption
		}));

		return {
			data: {
				items: formattedOptions
			},
			settings: {
				name: `${ namePrefix }[optional][${ id }]`,
				disabled: viewOnly || (!isParent && (!formattedOptions.length || disableChildSelect)),
				placeholder: placeholder || `-- ${ $.__('Select') } --`
			},
			width: 9,
			disabled: viewOnly || (!isParent && (!formattedOptions.length || disableChildSelect)),
			onChange: event => this._handleLegacySelectChange(event, isParent),
			className: 'LinkedSelects__select xlong'
		};
	};
	/* @endCleanup encore */

	_handleSelectChange = (selectedValues, isParent, id) => {
		const statePropToUpdate = isParent ? 'parentSelection' : 'childSelection';
		const [selectedValue = ''] = selectedValues;

		this.setState(
			{
				[statePropToUpdate]: selectedValue,
			},
			isParent ? () => this._getUpdatedChildOptions(id) : noop
		);
	};

	/* @startCleanup encore */
	_handleLegacySelectChange = (event, isParent) => {
		const statePropToUpdate = isParent ? 'parentSelection' : 'childSelection';

		this.setState(
			{ [statePropToUpdate]: event ? event.value : '' },
			isParent ? () => this._getUpdatedChildOptions(event ? event.id : null) : noop
		);
	};
	/* @endCleanup encore */

	_triggerGenericError = () => window.setMessage(FAILED_OPTIONS_GET, 'error');

	render() {
		const {
			data: {
				childSelect,
				help,
				label,
				parentSelect,
			},
			required,
		} = this.props;

		return (
			<Fragment>
				<div className="fieldRow">
					<div className="fieldBox">
						<div className="LinkedSelects__labelHelpContainer">
							<label className={ `fab-Label ${ required === false ? '' : 'fab-Label--required' }` } htmlFor={ `select${ parentSelect.id }` }>
								<span>{ label }</span>
							</label>
							<OptionalFieldHelp helpData={ help } />
						</div>
						<div className="fieldDiv">
							{
								ifFeature('encore',
									<Select
										id={ `select${ parentSelect.id }` }
										{ ...this._getSelectData(parentSelect, true) }
									/>,
									<OldSelect
										id={ `select${ parentSelect.id }` }
										{ ...this._getLegacySelectData(parentSelect, true) }
									/>
								)
							}
						</div>
					</div>
				</div>

				<div className="fieldRow">
					<div className="fieldBox">
						<div className="fieldDiv">
							{
								ifFeature('encore',
									<Select
										id={ `select${ childSelect.id }` }
										{ ...this._getSelectData(childSelect, false) }
									/>,
									<OldSelect
										id={ `select${ childSelect.id }` }
										{ ...this._getLegacySelectData(childSelect, false) }
									/>
								)
							}
						</div>

					</div>
				</div>
			</Fragment>
		);
	}
}
