import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { findChildren, getSelectedValue } from 'dynamic-form';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LayoutBox, Section } from '@bamboohr/fabric';

const PassportFieldSet: FunctionComponent<DynamicForm.FieldSetElementProps> = (fieldSetProps) => {
	const {
		props,
		children,
		context,
	} = fieldSetProps;
	const { setFormData, formData, validation: { trigger } } = context;
	const { className = '', id } = props;
	const [isRequired, setIsRequired] = useState(false);
	const childFieldKeysRef = useRef([]);
	const { current: childFieldKeys } = childFieldKeysRef;

	const PassportFieldTokens = {
		selectEmployeePassportIssuingCountry: 'selectEmployeePassportIssuingCountry',
		textEmployeePassportNumber: 'textEmployeePassportNumber',
		textEmployeePassportIssuedDate: 'textEmployeePassportIssuedDate',
		textEmployeePassportExpirationDate: 'textEmployeePassportExpirationDate',
	}

	// only get/calculate the children once - they aren't dynamic
	useEffect(() => {
		const childFields = findChildren(context, id, { selector: 'key', value: 'field' });
		childFieldKeysRef.current = Object.keys(childFields);
	}, []);

	const childFieldValues = childFieldKeys.map((key) => {
		const field = formData[key];
		return field?.props?.value || getSelectedValue(field as DynamicForm.SelectElement) || '';
	}).join('');

	useEffect(() => {
		if (childFieldValues.length) {
			setIsRequired(true);
		} else {
			setIsRequired(false);
		}
	}, [childFieldValues]);

	// dynamically make Passport fields required if one has a value
	useEffect(() => {
		if (childFieldKeys.length && context.form.isAddEmployeeForm) {
			const fieldNames = [];
			const updatedChildren = {} as DynamicForm.FormData;
			childFieldKeys.forEach((key) => {
				const field = formData[key];
				if (PassportFieldTokens[field.settings.token]) {
					if (!field.settings.validation) {
						field.settings.validation = {};
					}
					field.settings.validation.requiredWhenVisible = isRequired;
					fieldNames.push(field.props.name);
					updatedChildren[field.props.id] = field;
				}
			});
			setFormData(previousFormData => ({ ...previousFormData, ...updatedChildren }));
			if (!isRequired && fieldNames.length) {
				// in a timeout so that state will update the required and then clear the red boxes of angry-ness
				setTimeout(() => {
					trigger(fieldNames);
				}, 10);
			}
		}
	}, [isRequired]);

	return (
		<fieldset { ...props } className={ifFeature('encore', `${className}`, `fab-FormSection ${className}`)}>
			{ifFeature(
				'encore',
				<LayoutBox marginTop={4}>
					<Section>
						{children}
					</Section>
				</LayoutBox>,
				<>{ children }</>
			)}
		</fieldset>
	);
};

export default PassportFieldSet;
