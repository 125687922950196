import { Fragment, PureComponent, ReactElement } from 'react';
import { cloneDeep } from 'lodash';

import { Select } from '@bamboohr/fabric/';

import { Message } from '@bamboohr/utils/lib/message';

import { EmailFilterData, Recipients } from 'NHPTemplates.mod/Form/Interfaces';

export interface Props extends EmailFilterData{
	storeUpdater: (data: Record<string, Array<Recipients>>) => void;
}

export class EmailFilter extends PureComponent<Props, EmailFilterData> {
	constructor(props: Props) {
		super(props);

		const {
			selectData = [],
			selectedName = '',
			selectedParentId = '-1',
			selectedValues = [],
			storeUpdater = (arg0) => { return arg0; },
		} = this.props;

		let clonedSelectedData = cloneDeep(selectData);
		clonedSelectedData = this.disableRootItems(selectedParentId, clonedSelectedData);

		this.state = {
			selectData: clonedSelectedData,
			selectedName,
			selectedParentId,
			selectedValues: [...selectedValues],
		};

		const recipients = selectedValues.map((id): Recipients => {
			return {
				allEmployees: false,
				typeId: id,
				type: selectedName,
			};
		});

		storeUpdater({ gtkyRecipients: recipients });

		this._handleSelectChange = this._handleSelectChange.bind(this);
	}

	disableRootItems(selectedRootId: string, selectData: Array<any>): Array<any> {
		let foundKey = false;

		if (Number.parseInt(selectedRootId) === -1) {
			return selectData;
		}

		const tempSelectDataArray = cloneDeep(selectData);

		for (const item of tempSelectDataArray) {
			if (item.value === selectedRootId) {
				foundKey = true;
			} else {
				item.isDisabled = true;
			}
		}

		return (foundKey) ? tempSelectDataArray : selectData;
	}

	getToggleTranslation(name: string, count: number): ReactElement {
		let translatedString = null;

		switch (name.toLowerCase()) {
			case 'department':
				translatedString = <Message params={ [count] } text={ $._n('{1} Department', '{1} Departments', count) } />;
				break;
			case 'location':
				translatedString = <Message params={ [count] } text={ $._n('{1} Location', '{1} Locations', count) } />;
				break;
			case 'division':
				translatedString = <Message params={ [count] } text={ $._n('{1} Division', '{1} Divisions', count) } />;
				break;
			case 'status':
				translatedString = <Message params={ [count] } text={ $._n('{1} Status', '{1} Statuses', count) } />;
				break;
			default:
				translatedString = <Message params={ [count] } text={ $._n('{1} Item Selected', '{1} Items Selected', count) } />;
				break;
		}

		return translatedString;
	}

	_handleSelectChange(currentSelected = [], futureSelected = []): void {
		const {
			selectData,
			storeUpdater = (arg0) => { return arg0; },
		} = this.props;
		const {
			selectData: currentSelectData,
			selectedName,
			selectedParentId,
		} = this.state;
		const parentId = (futureSelected.length > 0) ? futureSelected[0].parentId : '-1';
		const newSelectData = (selectedParentId === parentId) ? currentSelectData : this.disableRootItems(parentId, selectData);

		let selectedParentName = selectedName;

		if (futureSelected.length === 0) {
			selectedParentName = '';
		} else if (selectedParentName === '') {
			const selectedData = selectData.find((data) => { return data.value === parentId; });
			selectedParentName = selectedData.name || '';
		}

		this.setState({
			selectData: newSelectData,
			selectedName: selectedParentName,
			selectedParentId: parentId,
			selectedValues: currentSelected,
		}, () => {
			const {
				selectedName,
				selectedValues,
			} = this.state;

			const recipients = selectedValues.map((id): Recipients => {
				return {
					allEmployees: false,
					typeId: id,
					type: selectedName,
				};
			});

			storeUpdater({ gtkyRecipients: recipients });
		});

	}

	render(): ReactElement {
		const {
			selectedName,
			selectData = [],
			selectedValues = [],
		} = this.state;

		return (
			<>
				{ selectedValues.map((id) => {
					return (
						<input
							key={ `${ selectedName }_${ id }` }
							name={ `${ selectedName }[]` }
							type="hidden"
							value={ id }
						/>
					);
				}) }
				<Select
					canSelectMultiple={ true }
					items={ selectData }
					menuWidth={ 7 }
					onChange={ this._handleSelectChange }
					placeholder={ $.__('All Employees') }
					renderToggleContent={ (selectedItems) => {
						const selectedItemCount = selectedItems.length;
						return (
							<Fragment>
								{ selectedItemCount > 0 ? (
									<div className="FilterDropdown__toggle">
										<span>{ this.getToggleTranslation(selectedName, selectedItemCount) }</span>
									</div>
								) : null }
							</Fragment>
						);
					} }
					selectedValues={ selectedValues }
					width={ 7 }
				/>
			</>
		);
	}
}
