import {extend} from 'lodash';
import Balloon from 'Balloon.mod/Balloon.mod';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default class Tooltip extends Balloon {
	constructor(anchor, options) {
		options = extend({
			themeClass: 'Balloon--tooltip',
			delay: 300,
			tail: true,
			tailSize: 8,
			minTailOnBalloon: 6,
			triggerEvent: 'hover',
			push: ifFeature('encore', 8, 10),
		}, options);

		super(anchor, options);
	}

	static createStandard(target, options) {
		if (options.template) {
			throw new Error(`You misunderstand the purpose of creating a standard tooltip. You don't need a template option using this creation method.`);
		}

		const {title, content, ...otherOptions} = options;

		otherOptions.template = {
			name: 'tooltip-standard',
			data: {title, content}
		};

		return super.create(target, otherOptions);
	}
}
