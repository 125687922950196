import {
	useState,
} from 'react';

import {
	Modal,
} from 'modal-legacy';

import {
	showModal,
	closeModal,
} from 'show-modal.mod';

import {
	BackupCodesContent,
	BACKUP_CODES_HEADLINE,
	BACKUP_CODE_TITLE,
	downloadCodes,
} from '../backup-codes-content.react';

export function BackupCodesModal(props) {
	const {
		userId,
	} = props;
	const [isProcessing, setIsProcessing] = useState(false);
	const [printRequestCount, setPrintRequestCount] = useState(0);

	return (
		<Modal
			additionalAction={ () => setPrintRequestCount(prevCount => prevCount + 1) }
			additionalActionText={ $.__('Print') }
			additionalActionButtonType="button"
			alternativeAction={ null }
			content={ (
				<BackupCodesContent
					onStartGeneratingNewCodes={ () => setIsProcessing(true) }
					onEndGeneratingNewCodes={ () => setIsProcessing(false) }
					printRequestCount={ printRequestCount }
					userId={ userId }
				/>
			) }
			headerType="text"
			headline={ BACKUP_CODES_HEADLINE }
			icon="fab-lock-person-18x18"
			iconColor="theme"
			isOpen={ true }
			isProcessing={ isProcessing }
			onClose={ () => closeModal() }
			primaryAction={ () => closeModal() }
			primaryActionText={ $.__('Done') }
			secondaryAction={ () => downloadCodes(userId) }
			secondaryActionText={ $.__('Download') }
			title={ BACKUP_CODE_TITLE }
		/>
	);
}

export const showBackupCodesModal = props => showModal(
	<BackupCodesModal {...props} />
);
