/* eslint-disable */
// @ts-nocheck
import { getParameterByName } from 'BambooHR.util';

const canDebug = () => {
	return !window.BambooHR.env.prod || getParameterByName('debugdf');
}

export const setupDynamicFormDebug = (context: DynamicForm.Context): void => {
	if (canDebug()) {
		window.debugDynamicForm = {
			downloadFormData: () => {
				const dataStr = `data:text/json;charset=utf-8,${ encodeURIComponent(JSON.stringify(context.formData)) }`;
				const downloadAnchorNode = document.createElement('a');
				downloadAnchorNode.setAttribute('href', dataStr);
				downloadAnchorNode.setAttribute('download', `dynamicFormData.json`);
				downloadAnchorNode.click();
				downloadAnchorNode.remove();
			},
			validationObjects: {},
			context,
		};

		const dragPrevent = (e) => {
			e.stopPropagation();
			e.preventDefault();
		};

		const drop = (e) => {
			dragPrevent(e);

			const dt = e.dataTransfer;
			const { files } = dt;

			handleUploadFile(files);
		};

		const handleUploadFile = async (files) => {
			const file = files[0];
			const reader = new FileReader(file);

			reader.readAsText(file);

			reader.onload = async (e) => {

				const fileData = e.target.result;

				try {
					const content = await JSON.parse(fileData);
					context.setFormData(content);
				} catch (e) {
					console.warn('unable to upload data', e);
				}


			};
		};

		// setup drag listener for upload
		const dropbox = document.querySelector('.PageHeader');
		if (dropbox) {
			dropbox.addEventListener('dragenter', dragPrevent, false);
			dropbox.addEventListener('dragover', dragPrevent, false);
			dropbox.addEventListener('drop', drop, false);
		}
	}
};

export const debugValidation = (field: DynamicForm.DataProps, rules: {[key: string]: any}) => {
	if (canDebug() && window.debugDynamicForm && field?.props?.id) {
		window.debugDynamicForm.validationObjects[field?.props?.id] = rules;
	}
}
