import './eeo-1-category.styl';

import { BodyText, LayoutBox } from '@bamboohr/fabric';

import { EEO1Description } from '../eeo-1-description';
import Select from 'select.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function EEO1Category(props) {
	const { category, description, example, jobTitleIds, jobTitles, onChange } = props;

	return (
		<div className='EEO1Category'>
			{ifFeature(
				'encore',
				<LayoutBox marginTop='24px' marginBottom='16px'>
					<BodyText color='neutral-extra-strong' size='large' weight='semibold'>
						{category}
					</BodyText>
				</LayoutBox>,
				<h3 className='EEO1Category__heading'>{category}</h3>
			)}
			<div className='EEO1Category__body'>
				<EEO1Description description={description} example={example} />
				<label className='EEO1Category__label fab-Label' htmlFor='jobTitles'>
					{$.__('Select Job Titles')}
				</label>
				<Select
					className='EEO1Category__select'
					data={_getSelectItems(jobTitleIds, jobTitles)}
					id='jobTitles'
					onChange={onChange}
					settings={{
						multiselect: true,
					}}
					width={ifFeature('encore', '9', '7')}
				/>
			</div>
		</div>
	);
}

function _getMappedJobTitlesIds(jobTitles, jobTitleIds) {
	const mappedJobTitleIds = [];

	jobTitles.allIds.filter((id) => {
		jobTitleIds.forEach((jobTitleId) => {
			const jobTitleMatch = jobTitles.allIds.find((jobTitle) => jobTitle === id && jobTitles.byId[jobTitle].id === jobTitleId);
			if (jobTitleMatch) {
				mappedJobTitleIds.push(jobTitleMatch);
			}
		});
	});

	return mappedJobTitleIds;
}

function _getUnmappedJobTitleIds(jobTitles) {
	return jobTitles.allIds.filter((id) => !jobTitles.byId[id].selected);
}

function _getSelectItems(jobTitleIds, jobTitles) {
	const mappedJobTitleIds = _getMappedJobTitlesIds(jobTitles, jobTitleIds);
	const unmappedJobTitleIds = _getUnmappedJobTitleIds(jobTitles);

	const mappedJobTitles = mappedJobTitleIds.map((id) => jobTitles.byId[id]);
	const unmappedJobTitles = unmappedJobTitleIds.map((id) => jobTitles.byId[id]);

	const items = mappedJobTitles.concat(unmappedJobTitles).map((item) => {
		return {
			displayText: item.name,
			value: item.id,
			selected: item.selected || false,
		};
	});

	return {
		items,
		placeholder: '--Select--',
	};
}
