import Ajax from '@utils/ajax';

export const createSecureShare = data => (
	Ajax.post(
		'/secure-file-share',
		data,
	)
);

export const getFileInfo = (type, id) => (
	Ajax.get(`/file-info/${ type }/${ id }`)
);
