import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import React from 'react';
import { ModalHead } from './modal-head';
import { ModalContent } from './modal-content';
import { ModalActions } from './modal-actions';
import './styles.styl';

export const ModalMain = function ModalMain(props) {
	const {
		actionNote,
		additionalAction,
		additionalActionButtonType,
		additionalActionText,
		aggressiveConfirmationText,
		alternativeAction,
		alternativeActionText,
		biId,
		children,
		content,
		contentHasMaxHeight,
		contentHasPadding,
		dangerousContent,
		footer,
		handleAggressiveConfirmationInput,
		header,
		headerType,
		headline,
		hideActions,
		iconColor,
		iconV2Color,
		iconName,
		isAggressivelyConfirmed,
		isConfirmation,
		isLoading,
		isMobileFriendly = false,
		isProcessing,
		isSheet,
		onClose,
		mobileFriendlyHeader,
		primaryAction,
		primaryActionText,
		secondaryAction,
		secondaryActionText,
		type,
	} = props;

	const modalActions = (
		<ModalActions
			{ ...{
				actionNote,
				additionalAction,
				additionalActionButtonType,
				additionalActionText,
				alternativeAction,
				alternativeActionText,
				biId,
				hideActions,
				isAggressivelyConfirmed,
				isConfirmation,
				isLoading,
				isMobileFriendly,
				isProcessing,
				isSheet,
				onClose,
				primaryAction,
				primaryActionText,
				secondaryAction,
				secondaryActionText,
				type,
			} }
		/>
	);

	return (
		<>
		<div className={ classNames('legacyModal__main', 'js-fabric-modal-main', (isLoading && 'legacyModal__main--hidden')) }>
			<ModalHead
				{ ...{
					header,
					headerType,
					headline,
					iconColor,
					iconV2Color,
					iconName,
					isMobileFriendly,
					mobileFriendlyHeader,
					type,
				} }
			/>
			<ModalContent
				{ ...{
					aggressiveConfirmationText,
					normalizedContent: children ? children : content,
					contentHasMaxHeight,
					contentHasPadding,
					dangerousContent,
					handleAggressiveConfirmationInput,
					isConfirmation,
				} }
			/>
			{ footer && <div className={ 'legacyModal__footer' }>{ footer }</div> }
			{ ifFeature('encore', null, modalActions) }
		</div>
			{ ifFeature('encore', modalActions, null) }
		</>
	);
}
