import { BodyText, Checkbox, Divider, LayoutBox } from '@bamboohr/fabric';
import { FileSection } from 'NHPTemplates.mod/Form/components/nhp-tasks/components/file-section';
import { PacketTask } from 'NHPTemplates.mod/Form/store/Interfaces';

export interface TaskItemProps {
	task: PacketTask;
	index: number;
}

export const TaskItem = ({ task, index }: TaskItemProps) => {
	const { completed, description, files, name } = task;
	const filesArray = Object.keys(files).map((key) => files[key]);
	return (
		<LayoutBox paddingTop={2.5}>
			{index > 0 ? <Divider marginBottom={2.5} /> : null}
			<LayoutBox>
				<Checkbox
					checked={completed}
					disabled={true}
					label={`${index + 1}. ${name}`}
					onChange={() => null}
					size='biggie'
					value=''
				/>
			</LayoutBox>
			<LayoutBox paddingLeft={4.5} paddingTop={1}>
				<LayoutBox marginBottom={description && filesArray.length ? 2 : 0} paddingLeft={2}>
					<BodyText size='medium' whiteSpace='pre-line'>{description}</BodyText>
				</LayoutBox>
				{filesArray.length > 0 && <FileSection files={filesArray} view='display' />}
			</LayoutBox>
		</LayoutBox>
	);
};
