import ajax from '@utils/ajax';
import {
	SSNInvalidSyncModal,
	SSNWarnSyncModal,
	SocialIdInvalidModal,
} from 'SsnValidation.mod';
import { hasTrax } from 'BambooHR.util';
import {
	currentEditingEmployeeId,
	isOnboardingUser,
	getField,
	getPayScheduleListValueId,
	Fields,
	validateWithBE,
} from 'dynamic-form';
import { RATE_LIMIT_ERROR } from './common';

// Only show warning once per changed value
let hasWarnedAbout001 = false;

/**
 * Validates SSN, NIN, SIN, National Id
 *
 * @param context
 * @param field
 */
export const validateSocialSecurity = (context: DynamicForm.Context, field: DynamicForm.Data, params: any) => {
	const { action, check_field, message } = params;

	return async (value): Promise<boolean | string> => {
		if (!value) {
			// don't make a BE request - field isn't filled in yet, let required or regex run
			return true;
		}
		const data = {
			id: currentEditingEmployeeId(),
			check_field,
			action,
			[check_field]: value,
			payScheduleListValueId: null,
		};

		if (hasTrax()) {
			const PSListValueId = getPayScheduleListValueId(context);
			if (PSListValueId) {
				data.payScheduleListValueId = PSListValueId;
			}
		}

		const { isValid, isRateLimited } = await validateWithBE(ajax.post, data);
		if (isRateLimited) { return RATE_LIMIT_ERROR; }

		if (value === '001-00-0000') {
			if (!hasWarnedAbout001 && !isOnboardingUser()) {
				hasWarnedAbout001 = true;
				SSNWarnSyncModal();
			}
		} else {
			hasWarnedAbout001 = false;
		}

		if (isValid && hasTrax()) {
			const PSField = getField(context, Fields.paySchedule) as DynamicForm.SelectElement;
			if (PSField) {
				const { props: { name } } = PSField;
				const { validation: { clearErrors } } = context;

				clearErrors(name);
			}
		}

		return isValid || message;
	};
};
