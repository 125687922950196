import {
	forwardRef,
	useImperativeHandle,
} from 'react';
import { showSlidedown } from 'Alerts.mod';
import {
	EmailFieldset,
	EmailInputHasDuplicates,
	EmailInputListIsValid,
	FileInfo,
	SecurityFieldset,
	updateFormClasses,
} from './components';
import { bem, ERROR_DATA } from '../../constants';
import { useCreate } from './hooks';
import './styles.styl';

export const Content = forwardRef((props, ref) => {
	const {
		id,
		setProcessing,
		type,
		onSfsCreation,
	} = props;

	const {
		formRef,
		create,
		$validator,
	} = useCreate(setProcessing, onSfsCreation);

	const handleSubmit = () => {
		$validator.form();
		updateFormClasses();
		if (EmailInputHasDuplicates()) {
			showSlidedown(ERROR_DATA.duplicateEmail.slidedownMessage, 'error');
		} else if (EmailInputListIsValid() && $validator.valid()) {
			create();
		}
	};

	useImperativeHandle(ref, () => ({
		submit: () => {
			handleSubmit();
		}
	}));

	return (
		<form
			ref={ formRef }
			className={ bem.elem('form') }
		>
			<FileInfo
				id={ id }
				type={ type }
			/>
			<div className={ bem.elem('content') } >
				<EmailFieldset />
				<SecurityFieldset />
			</div>
		</form>
	);

});
