import {
	TextButton,
} from '@fabric/button';

import { MAX_VISIBLE_RECIPIENTS } from '../../constants';

export function Recipients(props) {
	const {
		isExpandable,
		isExpanded,
		rowKey = '',
		onButtonClick,
		recipients,
	} = props;

	const list = !isExpanded ?
		recipients.splice(0, MAX_VISIBLE_RECIPIENTS - 1) : recipients;

	let buttonText = null;
	if (isExpandable) {
		buttonText = isExpanded ? $.__('View less') : $.__(`%1$s more`, recipients.length);
	}

	return (
		<div>
			<ul>
				{
					list.map((recipient, i) => {
						const recipientKey = `${ rowKey }-${ recipient }-${ i }`;
						return (
							<li key={ recipientKey }>
								{ recipient }
							</li>
						);
					})
				}
			</ul>

			{ isExpandable &&
				<TextButton
					clickAction={ onButtonClick }
					inline={ true }
					text={ buttonText }
				/>
			}

		</div>
	);
}
