import {
	EDIT_COMMENT_ERROR,
	EDIT_COMMENT_START,
	EDIT_COMMENT_SUCCESS,
} from '../../actions/constants';

export default { EDIT_COMMENT_START: (state, action) => {
	return state.hasIn(['entities', 'comments', 'byId', action.payload])
		? state.setIn(['entities', 'comments', 'byId', action.payload, 'pending'], true)
		: state.setIn(['entities', 'replies', 'byId', action.payload, 'pending'], true);
}, EDIT_COMMENT_ERROR: (state, action) => {
	return state.hasIn(['entities', 'comments', 'byId', action.payload])
		? state.setIn(['entities', 'comments', 'byId', action.payload, 'pending'], false)
		: state.setIn(['entities', 'replies', 'byId', action.payload, 'pending'], false);
}, EDIT_COMMENT_SUCCESS: (state, action) => {
	const {id, feed} = action.payload;
	
	const type = state.hasIn(['entities', 'comments', 'allIds', id]) ? 'comments' : 'replies';

	return state
		.mergeDeep(feed)
		.setIn(
			['entities', type, 'byId', id, 'files'],
			feed.getIn(['entities', type, 'byId', id, 'files'])
		)
		.set('id', state.id);
}, };
