// @startCleanup encore
import './styles.styl';
import { classNameFromObject } from '@utils/dom';
import { showSeparator } from './utils';

export default function(props) {
	const {
		side,
		showWeekends,
		previousPeriodEntries,
		nextPeriodEntries,
		nudges,
	} = props;

	if (!showSeparator(showWeekends, side === 'top' ? previousPeriodEntries : nextPeriodEntries)) {
		return null;
	}

	const text = (side === 'top') ? $.__('Pay Period Begins') : $.__('Pay Period Ends');

	const classes = classNameFromObject({
		[`TimesheetSeparator TimesheetSeparator--${ side }`]: true,
		'TimesheetSeparator--up': side === 'top' ? nudges.previousMoveUp : nudges.nextMoveUp,
		'TimesheetSeparator--down': side === 'top' ? false : nudges.nextMoveDown
	});

	return <div className={classes} data-text={text} />
}
// @endCleanup encore

export { PayPeriodIndicator } from './pay-period-indicator';
export { showSeparator } from './utils';
