import { FunctionComponent } from 'react';
import EducationRepeatContainer from './education';
import EmergencyContactRepeatContainer from './emergency-contact';
import DependentRepeatContainer from "./dependent";

const RepeatingContainer: FunctionComponent<DynamicForm.RepeatContainerElementProps> = (props) => {
	const { settings: { repeatType } } = props;
	let Container: React.ReactElement = null;
	if (repeatType === 'base') {
		// We only know how to render education, emergency contacts, and dependents
		return null;
	}
	if (repeatType === 'education') {
		Container = <EducationRepeatContainer { ...props } />;
	} else if (repeatType === 'contact') {
		Container = <EmergencyContactRepeatContainer { ...props } />;
	} else if (repeatType === 'dependent') {
		Container = <DependentRepeatContainer { ...props } />;
	}

	return Container;
};

export default RepeatingContainer;
