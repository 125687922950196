import { CustomQuestionTypes } from './types';

const validExtensionsList = window.GLOBAL_VALID_EXTENSIONS ? Array.from(window.GLOBAL_VALID_EXTENSIONS) : ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'jpg', 'jpeg', 'tif', 'tiff', 'gif', 'png', 'bmp', 'txt', 'rtf', 'zip'];
export const VALID_FILE_EXTENSIONS = validExtensionsList.map(ext => `.${ ext }`);

export const customQuestionInputTypes: Record<CustomQuestionTypes, string> = {
	short: 'TYPE_TEXT',
	long: 'TYPE_TEXTAREA',
	yes_no: 'TYPE_YES_NO',
	multi: 'TYPE_MULTIPLE_CHOICE',
	checkbox: 'TYPE_CHECKBOX',
	file: 'TYPE_FILE_UPLOAD',
};
