import SimpleModal from 'SimpleModal.mod';
import Ajax from '@utils/ajax';
import { isEnabled } from 'FeatureToggle.util';
import Clipboard from 'clipboard';

const jadeEnabled = isEnabled('jade');
const ICalRoute = '/feeds/ical';
const ICalHttpService = {
	createFeed: feedDefinition => $.post(ICalRoute, {definition: feedDefinition}),
	updateFeed: id => $.ajax({type: 'PUT', url: `${ ICalRoute }/${ id }`}),
	deleteFeed: id => $.ajax({type: 'DELETE', url: `${ ICalRoute }/${ id }`}),
};

let feeds = [];

function getFeedByDefinition(definition) {
	return feeds.filter(feed => feed.definition == definition)[0];
}

function getDomFeedLevelByDefinition(definition) {
	return document.querySelector(`.js-ical-feed[data-ical-definition="${ definition }"]`);
}

function createFeed(feedDefinition) {
	let original = getFeedByDefinition(feedDefinition);
	ICalHttpService.createFeed(feedDefinition)
		.done((data) => {
			Object.assign(original, data);
			renderFeeds();
		});
}

function deleteFeed(feedDefinition) {
	let original = getFeedByDefinition(feedDefinition);
	ICalHttpService.deleteFeed(original.id)
		.done(() => {
			original.publicId = null;
			renderFeeds();
		});
}

function updateFeed(feedDefinition) {
	let original = getFeedByDefinition(feedDefinition);

	let feed = getDomFeedLevelByDefinition(feedDefinition);
	feed.querySelector('.js-ical-modal-input').value = '';

	ICalHttpService.updateFeed(original.id)
		.done((data) => {
			Object.assign(original, data);
			renderFeeds();
		});
}

function copyUrl(trigger, definition) {
	let clipboard = new Clipboard(trigger, {
		target: () => getDomFeedLevelByDefinition(definition).querySelector('.js-ical-modal-input')
	});

	clipboard.on('success', (e) => {
		e.clearSelection();
		trigger.style.display = 'none';
		let copiedText = getDomFeedLevelByDefinition(definition).querySelector('.js-ical-modal-copied');
		copiedText.style.display = 'block';

		window.setTimeout(() => {
			trigger.style.display = 'block';
			copiedText.style.display = 'none';
		}, 1500);
	});

	clipboard.on('error', (e) => {
		// Just in case multiple clicks happen on the target before the tooltip is destroyed
		if (window.BambooHR.Components.Tooltip.selectTooltips(trigger).length) {
			return false;
		}
		let tooltip = window.BambooHR.Components.Tooltip
			.create(trigger, {content: $.__('Press ctrl+c or command+c to copy the link')})
			.show();
		window.setTimeout(() => tooltip.destroy(), 3000);
	});
}

function renderFeeds() {
	let template = document.querySelector('#js-ical-modal-feed-template').innerHTML;
	document.querySelector('#js-ical-modal-feeds').innerHTML = window.microTemplate(template, {feeds});
	attachEvents();
}

function attachEvents() {
	let feeds = document.querySelectorAll('.js-ical-feed');

	[].slice.call(feeds).forEach((feed) => {
		let definition = feed.getAttribute('data-ical-definition');

		let createButton = feed.querySelector('.js-ical-modal-create-feed');

		if (createButton) {
			createButton.addEventListener('click', createFeed.bind(null, definition));
		} else {
			// The only way the following could exist is if the button does not exist
			feed.querySelector('.js-ical-modal-update-feed')
				.addEventListener('click', updateFeed.bind(null, definition));

			feed.querySelector('.js-ical-modal-delete-feed')
				.addEventListener('click', deleteFeed.bind(null, definition));

			let copyLink = feed.querySelector('.js-ical-modal-copy');
			copyUrl(copyLink, definition);

			feed.querySelector('.js-ical-modal-input')
				.addEventListener('click', function () {
					this.blur();
					this.focus();
					this.select();
				});
		}
	});
}

function afterModalOpens() {
	feeds = JSON.parse(document.querySelector('#js-ical-modal-data').innerHTML);
	renderFeeds();
	SimpleModal.setPosition();
}

export function openModal() {
	const title = $.__('iCalendar Feeds');
	const primaryActionText = $.__('Done');
	if (jadeEnabled) {
		Ajax.get(ICalRoute)
			.then((response) => {
				window.BambooHR.Modal.setState({
					isOpen: true,
					content: (
						<div dangerouslySetInnerHTML={ { __html: response.data } } />
					),
					onOpen() {
						setTimeout(() => {
							afterModalOpens();
						});
					},
					primaryAction() {
						window.BambooHR.Modal.setState({isOpen: false});
					},
					primaryActionText,
					alternativeAction: null,
					title
				});
			});
	} else {
		SimpleModal.openModal({
			method: 'get',
			url: ICalRoute,
			title,
			footer: {
				buttons: {
					primary: {
						text: primaryActionText,
						action: () => SimpleModal.closeModal()
					},
					cancel: {
						show: false
					}
				}
			},
			callbacks: {
				open: {
					after: afterModalOpens
				}
			}
		});
	}
}

export default {
	open: openModal
}
