/**
 * The selectors for the title
 *
 * @type Object
 */
var selectors = {
	title: '.js-simplemodal-title'
};

/**
 * Simple Modal Header Title Object
 */
var Title = {
	/**
	 * Set the title for the modal
	 *
	 * @param {String}  title  The title for the modal
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 *
	 * @returns {undefined}
	 */
	setTitle(title, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.title).text(title);
	},

	/**
	 * Get the title for the modal
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 *
	 * @returns {String} The title for the modal
	 */
	getTitle($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		return $modal.find(selectors.title).text();
	},

	/**
	 * Hide the title
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 */
	hide($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.title).hide();
	},

	/**
	 * Show the title
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 */
	show($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.title).show();
	}
};

export default Title;
