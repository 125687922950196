import moment from 'moment';

export const formatDate = (date = '') => {
    const momentObj = moment(date);
    return momentObj.isValid() ? momentObj.format('L') : '';
};

export const getItems = (
    cancellationData,
    title
) => {

    return [
		{
			text: $.__('Cancel %1', title),
            value: 'cancelProducts',
            isDisabled: cancellationData?.pending_cancellation,
            note: cancellationData?.pending_cancellation ? $.__('Request sent %1 by %2', formatDate(cancellationData.date_requested), cancellationData.user_who_requested_cancellation) : null,
		},
	];

};
