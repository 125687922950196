import { camelCase, each, isNil } from 'lodash';
import { getIconNameFromFileName } from 'BambooHR.util';
import {
	canViewFinalized,
	isCancelled,
	isEsig,
	isCompleteOrEmployeeComplete,
	waitingOnSig,
} from './esig';

export function convertFileForList(fileObject) {
	const Esig = isEsig(fileObject);
	const icon = getIconNameFromFileName(fileObject.original_file_name, '16x20', Esig);

	return {
		isEsig: Esig,
		isComplete: isCompleteOrEmployeeComplete(fileObject),
		waitingOnSig: waitingOnSig(fileObject),
		file_id: getDownloadFileId(fileObject),
		icon: icon,
		name: fileObject.name
	};
}

export function getAction(fileObject, isAssumedUser) {
	let actionType;
	const {
		can_generate_preview,
		can_see_file,
		employee_file_data,
		esignatures,
		esignature_template_id,
		company_file_data,
		file_id,
		most_recent_file_data_id,
		name,
		workflow_instance_id,
		esignature_instance_id,
	} = fileObject;

	if (isEsig(fileObject)) {
		if (esignatures.cancelled) {
			return null;
		}
		switch (true) {
			case typeof employee_file_data === 'object' && employee_file_data.id && employee_file_data.can_see_file:
				actionType = 'previewEmployeeFile';
				break;
			case typeof company_file_data === 'object' && company_file_data.id && company_file_data.can_see_file:
				actionType = company_file_data.can_generate_preview ? 'companyPreviewFile' : 'companyShowFile';
				break;
			case esignatures.can_sign && !isAssumedUser:
				actionType = 'esigOpenModal';
				break;
			case !isNil(esignature_instance_id) && esignature_instance_id > 0 && can_see_file:
			case !isNil(workflow_instance_id) && workflow_instance_id > 0 && can_see_file:
				actionType = 'esigOpenPreviewModeModal';
				break;
			default:
				break;
		}
	}

	if (!actionType) {
		if (can_see_file) {
			return can_generate_preview ? 'previewFile' : 'showFile';
		}
		return 'showFileNoAccess';
	}

	return actionType;
}

export function getDataObject(fileObject) {
	const {
		company_file_data,
		employee_file_data,
		esignature_instance_id,
		esignature_template_id,
		esignatures,
		file_id,
		most_recent_file_data_id,
		name,
		workflow_instance_id
	} = fileObject;

	let actionData = {
		"companyFileId": checkForData(company_file_data, 'file_id'),
		"companyFileMostRecentId": checkForData(company_file_data, 'most_recent_file_data_id'),
		"companyFileName": checkForData(company_file_data, 'name'),
		"employeeFileMostRecentId": checkForData(employee_file_data, 'most_recent_employee_file_data_id'),
		"employeeFileId": checkForData(employee_file_data, 'id'),
		"employeeFileName": checkForData(employee_file_data, 'name'),
		esignature_instance_id,
		esignature_template_id,
		"esignatureWorkflowId": checkForData(esignatures, 'workflow_id'),
		file_id,
		most_recent_file_data_id,
		name,
		workflow_instance_id
	};

	return {
		"action-data": JSON.stringify(generateMergedDataForAction(actionData))
	};
}

export function getDownloadFileId(fileObject) {
	const {
		company_file_data,
		file_id
	} = fileObject;
	return company_file_data ? company_file_data.file_id : file_id;
}

export function isDisabled(fileObject) {
	//	If a user cannot see a file (no access), we disable it.
	const { can_see_file } = fileObject;

	if (!can_see_file) {
		return true;
	}
	// For Esig Documents, if file is cancelled or you cannot view the finalized
	// document, disable it.
	if (
		isEsig(fileObject) &&
		(
			isCancelled(fileObject) ||
			!canViewFinalized(fileObject)
		)
	) {
		return true;
	}

	return false
}

// Module functions

/**
 * Check if object and child attribute exist, if they do return value
 * @param  {Object} parent             The Object that might or might not exist
 * @param  {string} attr               The attr/key to access if the object exists
 * @return {string|number|null}        The value of the attr/key
 */
function checkForData(parent, attr) {
	if (parent && parent[attr]) {
		return parent[attr];
	}
	return null;
}

/**
 * Generate a new Object with only existing data
 * @param  {Object} data The Object to inspect
 * @return {Object}      A new object that only contains attr/keys that contain values
 */
function generateMergedDataForAction(data) {
	let newObject = {};

	each(data, (value, key) => {
		if (!isNil(value)) {
			newObject[camelCase(key)] = value;
		}
	});

	return newObject;
}

/**
 * Returns true if file extensions matches
 * @param  {string}  fileName The name of the file (including extension)
 * @return {Boolean}          Is an image file or not
 */
export const isImgFile = (fileName) => {
	if (isNil(fileName)) {
		return false;
	}

	const ext = fileName.split(".");
	const extToTest = ext[ext.length - 1];
	const okGo = [
		"png",
		"jpg",
		"jpeg",
		"gif",
		"bmp"
	];

	return okGo.indexOf(extToTest.toLocaleLowerCase()) !== -1;
}

/**
 * Preview a file in a modal
 *
 * @param {int}     fdId                   The file's file_data ID
 * @param {int}     fileId                 The file's ID
 * @param {String}  fileName               The name of the file
 * @param {boolean} employeeFile           Whether the file is an employee file or not
 * @param {String}  rowSelector            If the data-* info is on an element other than a <tr>, pass in the element name here (Example: "li")
 * @param {boolean} hideSidebar            Whether to hide the sidebar or not (default is to show)
 * @param {String}  fileMode               The preview mode of the file (e.g. img or previewer).
 * @param {boolean} isGH	               The preview mode of the file (e.g. img or previewer).
 * @param {int} 	esignatureTemplateId   The template id if the doc has been setup as an esignature
 * @param {boolean} shouldOpenNewPreviewer A flag to see if we should open the new file previewer
 *
 * @returns {void}
 */
export function previewFile(fdId, fileId, fileName, employeeFile, rowSelector, hideSidebar, fileMode, isGH = false, needsReRender = false, esignatureTemplateId = null, shouldOpenNewPreviewer = false) {
	// TODO: Pull the logic over from ui-src/public/files/files-tab/util/file.js so we can import this function without it being cross feature and get it off of the window
	window.previewFile(...arguments);
}

/**
 * Creates a temporary download button and downloads the Blob data
 * @param {Object} file Object containing the file name, file data and mime type
 * @param {Object} file.fileData File data to be converted to a blob
 * @param {string} file.fileName File name to save the file with
 * @param {string} file.mimeType Mime type to force for Blob (defaults to "text/plain;charset=utf-8")
 */
export function downloadFile(file) {
	const {
		fileData,
		fileName,
		mimeType = 'text/plain;charset=utf-8',
	} = file;
	if (!fileData || !fileName) {
		throw new Error('A file name and file data must be supplied');
	}
	const blob = new Blob([fileData], { type: mimeType });
	const blobURL = window.URL.createObjectURL(blob);
	const tempLink = document.createElement('a');
	tempLink.style.display = 'none';
	tempLink.href = blobURL;
	tempLink.setAttribute('download', fileName);
	if (typeof tempLink.download === 'undefined') {
		tempLink.setAttribute('target', '_blank');
	}

	document.body.appendChild(tempLink);
	tempLink.click();
	document.body.removeChild(tempLink);
	window.URL.revokeObjectURL(blobURL);
}

/**
 * This function is a copy/port of the function found in the fabric repo that is used by the
 * FileUpload component:
 * src/components/file-upload/util.ts
 * 
 * Hoping this gets rewritten and this copy of the function gets blown away. If for some reason
 * changes need to be made, also update the fabric version!
 * 
 * @param File file 
 * @returns 
 */
export function checkIfPdfFileIsEncrypted(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = function () {
			const fileBlob = new Blob([reader.result], {type: 'application/pdf'});
			fileBlob.text().then(x => {
				let isEncrypted = x.includes("Encrypt");
				isEncrypted |= x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt");
				resolve(isEncrypted);
			}).catch(error => {
				reject(new DOMException('Problem parsing input file: ', error));
			});
		};

		reader.onerror = () => {
			reader.abort();
			reject(new DOMException('Problem reading input file.'));
		};
	});
}
