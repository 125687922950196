import { createStyles, makeStyles } from '@bamboohr/fabric';

export const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		headerTitle: {
			fontWeight: typography.fontWeightMedium,
			fontSize: '32px',
		},
		headerDesc: {
			fontSize: typography.fabricFontSize('biggie'),
			color: palette.grey[700],
		},
		divider: {
			margin: '35px 0',
		},
		inputs: {
			padding: '0 15px',
			display: 'flex',
			flexDirection: 'column',
		},
		question: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: '15px',
		},
		questionInput: {
			maxHeight: '60px',
			maxWidth: '430px',
		},
	});
});
