export const ACA_TYPES = {
	contractor: {
		label: $.__('Contractor (1099)'),
		shortLabel: $.__('Contractor'),
		description: $.__('A person receiving a 1099 is not considered an employee and will not be included in the ACA reporting.'),
		url: 'https://www.irs.gov/businesses/small-businesses-self-employed/independent-contractor-defined',
	},
	'full-time': {
		label: $.__('Full-Time'),
		shortLabel: $.__('Full-Time'),
		description: $.__('A full-time employee is, for a calendar month, an employee employed on average at least 30 hours of service per week, or 130 hours of service per month.'),
		url: 'https://www.irs.gov/affordable-care-act/employers/identifying-full-time-employees',
	},
	'intern-compensated': {
		label: $.__('Intern (Eligible for benefits)'),
		shortLabel: $.__('Intern Eligible'),
		description: $.__('A temporary employee who receives compensation and therefore will be included in the ACA reporting.'),
		url: 'https://www.irs.gov/affordable-care-act/employers/questions-and-answers-on-employer-shared-responsibility-provisions-under-the-affordable-care-act',
	},
	'intern-not-compensated': {
		label: $.__('Intern (Ineligible for benefits)'),
		shortLabel: $.__('Intern Ineligible'),
		description: $.__('A temporary employee who receives no compensation and therefore will not be included in ACA reporting.'),
		url: 'https://www.irs.gov/affordable-care-act/employers/questions-and-answers-on-employer-shared-responsibility-provisions-under-the-affordable-care-act',
	},
	'part-time-eligible': {
		label: $.__('Part-Time/Variable Hour (eligible for benefits)'),
		shortLabel: $.__('Part-Time Eligible'),
		description: $.__('Any employee who was treated as a full-time employee for one or more months of the calendar year under the look-back measurement method.'),
		url: 'https://www.irs.gov/affordable-care-act/employers/questions-and-answers-about-information-reporting-by-employers-on-form-1094-c-and-form-1095-c',
	},
	'part-time-ineligible': {
		label: $.__('Part-Time/Variable Hour (ineligible for benefits)'),
		shortLabel: $.__('Part-Time Ineligible'),
		description: $.__('Any employee who was not treated as a full-time employee for one or more months of the calendar year under the look-back measurement method.'),
		url: 'https://www.irs.gov/affordable-care-act/employers/questions-and-answers-about-information-reporting-by-employers-on-form-1094-c-and-form-1095-c',
	},
	seasonal: {
		label: $.__('Seasonal'),
		shortLabel: $.__('Seasonal'),
		description: $.__('An employee who is hired to a position for which the annual employment is generally six months or less and begins approximately the same part of the year, such as summer or winter.'),
		url: 'https://www.irs.gov/affordable-care-act/employers/questions-and-answers-on-employer-shared-responsibility-provisions-under-the-affordable-care-act',
	},
};
