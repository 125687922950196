import moment from 'moment';
import 'moment-timezone';

var locale = moment.localeData(),
	_nextWeekFormat = locale._calendar.nextWeek,
	_longFormat = locale._longDateFormat.LL;

if (!window.BambooHR.env.dev) {
	moment.suppressDeprecationWarnings = true;
}

function isFn(obj) {
	return !!(obj && obj.constructor && obj.call && obj.apply);
}

function bhrDateFormat(now) {
	var format,
		nextWeekFormat = _nextWeekFormat,
		longFormat = _longFormat;

	if (isFn(nextWeekFormat)) {
		nextWeekFormat = nextWeekFormat.apply(this, arguments);
	}

	if (isFn(longFormat)) {
		longFormat = longFormat.apply(this, arguments);
	}

	format = longFormat.replace(/M+/, 'MMM');

	if (this.isSame(now, 'year')) {
		format = format.replace(/[^MD]+Y+[^MD]+/, '');
	}

	return nextWeekFormat.replace(/^[^d]*d+/, format);
}

moment.locale(locale._abbr, {
	calendar: $.extend(locale._calendar, {
		lastWeek: bhrDateFormat,
		nextWeek: bhrDateFormat,
		sameElse: bhrDateFormat
	})
});

$('[ba-date]').observe(function() {
	var $el = $(this),
		val = $el.text().trim(),
		format = $el.attr('ba-date'),
		formatType = $el.attr('ba-date-format-type'),
		timeZone = $el.attr('ba-date-tz'),
		mom = $el.data('ba-date') || moment.utc(val),
		date;


	mom = (timeZone && timeZone.toLowerCase() == 'utc' ? mom.utc() : mom.local());


	if (format && formatType) {
		Object.keys(moment.convert).some(function(key) {
			if (key.toLowerCase() === formatType.toLowerCase()) {
				format = moment.convert[key](format);
				return true;
			}
		});
	}

	date = (format) ? mom.format(format) : mom.calendar();

	$el
		.text(date)
		.data('ba-date', mom)
		.addClass('ba-date-loaded');
});

moment.fn.isWeekend = function() {
	return this.isoWeekday() >= 6;
};

moment.convert = {};

(function() {
	var formatMap = {
		jQuery: {
			'dd': 'DD',
			'd': 'D',
			'oo': 'DDDD',
			'o': 'DDD',
			'DD': 'dddd',
			'D': 'ddd',
			'mm': 'MM',
			'm': 'M',
			'MMM': 'MMM',
			'MM': 'MMMM',
			'M': 'MMM',
			'yyyy': 'YYYY',
			'yy': 'YYYY',
			'y': 'YY',
			'@': 'X',
			'!': '[!]' // Windows ticks
		},
		PHP: {
			d: 'DD',
			D: 'ddd',
			j: 'D',
			l: 'dddd',
			N: 'E',
			S: function() {
				return '[' + this.format('Do').replace(/\d*/g, '') + ']';
			},
			w: 'd',
			z: function() {
				return this.format('DDD') - 1;
			},
			W: 'W',
			F: 'MMMM',
			m: 'MM',
			M: 'MMM',
			n: 'M',
			t: function() {
				return this.daysInMonth();
			},
			L: function() {
				return this.isLeapYear() ? 1 : 0;
			},
			o: 'GGGG',
			Y: 'YYYY',
			y: 'YY',
			a: 'a',
			A: 'A',
			B: function() {
				var thisUTC = this.clone().utc(),
					swatch = ((thisUTC.hours() + 1) % 24) + (thisUTC.minutes() / 60) + (thisUTC.seconds() / 3600);
				return Math.floor(swatch * 1000 / 24);
			},
			g: 'h',
			G: 'H',
			h: 'hh',
			H: 'HH',
			i: 'mm',
			s: 'ss',
			u: '[u]', // nanoseconds
			e: '[e]', // timezone identifier
			I: function() {
				return this.isDST() ? 1 : 0;
			},
			O: 'ZZ',
			P: 'Z',
			T: '[T]', // timezone abbreviation
			Z: function() {
				return parseInt(this.format('ZZ'), 10) * 36;
			},
			c: 'YYYY-MM-DD[T]HH:mm:ssZ',
			r: 'ddd, DD MMM YYYY HH:mm:ss ZZ',
			U: 'X'
		}
	};

	Object.keys(formatMap).forEach(function(key) {
		var formatEx = new RegExp(Object.keys(formatMap[key]).join('|'), 'g');

		moment.convert[key] = function(format) {
			var $this = this;
			return (format || '').replace(formatEx, function(str) {
				return (typeof formatMap[key][str] === 'function')
					? formatMap[key][str].call($this)
					: formatMap[key][str];
			});
		};
	});
})();

//set default moment date format to global format
moment.defaultFormat = moment.convert.jQuery(window.GLOBAL_DATEPICKER_MASK) || 'MM/DD/YYYY';

function _getShortDateFormat(format) {
	switch (format) {
		case 'DD MMM YYYY':
			return 'DD MMM';

		case 'YYYY-MM-DD':
			return 'MM-DD';

		case 'DD/MM/YYYY':
			return 'DD/MM';

		case 'MM/DD/YYYY':
		default:
			return 'MM/DD';
	}
}

moment.shortFormat = _getShortDateFormat(moment.defaultFormat);

moment.$ = function(elem, strict = true) {
	elem = $(elem).first();

	const dateFormat = elem.datepicker('option', 'dateFormat');
	const format = dateFormat ? moment.convert.jQuery(dateFormat) : moment.defaultFormat;

	return moment(elem.val(), format, strict);
};

export default moment;
