import { colors } from '@bamboohr/fabric/dist/definitions/json/colors.json';
import { textSizes } from '@bamboohr/fabric/dist/definitions/json/text';

export default {
	view: {
		fontFamily: '"Lato", sans-serif',
		display: 'flex',
		marginTop: '200px',
		flexDirection: 'column',
		alignItems: 'center',
		fontSize: `${ textSizes.large.size }px`,
		lineHeight: `${ textSizes.large.line }px`,
		color: colors.gray8,
		textAlign: 'center',
	},
	image: {
		marginBottom: '16px',
	}
};
