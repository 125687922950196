import { Component, createRef } from 'react';
import { LineChart } from 'Charts.mod';
import { MOUSE_OVER } from 'Charts.mod/event-names';

import { isEqual } from 'lodash';

import './line-chart.styl';

type LineChartProps = {
	onMouseOver?: (event: MouseEvent) => void;
	data: ChartData1 | ChartData2 | ChartData3;
	settings?: LineChartSettings;
};

type LineChartSettings = {
	alternateLineStyle?: {
		pointRadius: number;
	};
	alternateLineStyleKey?: string;
	alternatePointStyle?: {
		pointFill?: string;
		pointRadius?: number;
		pointStrokeWidth?: number;
	};
	alternatePointStyles?: {
		[k: string]: {
			pointFill?: string;
			pointRadius?: number;
			pointStrokeWidth?: number;
		};
	};
	alternatePointStyleKey?: string;
	alternatePointStyleKeys?: string[];
	animate?: boolean;
	chartClass: string;
	colors?: string[];
	container?: {
		axis: {
			hasAxis: boolean;
		};
		height?: number;
		margin?: {
			bottom?: number;
			left?: number;
			right?: number;
			top?: number;
		};
		width?: number | 'auto';
	};
	drawDotsAlongLines?: boolean;
	legend?: {
		chartClass?: string;
		showCounts?: boolean;
		truncate?: boolean;
		onClick?: () => void;
		columnLayout: boolean;
	};
	fadedLineOpacity?: number;
	hasSelectableColumns?: boolean;
	lineToBringToFrontId?: number | null;
	numberOfYAxisTicks?: number;
	onSelectableAreaClick?: (index: number) => void;
	pathStrokeWidth?: number;
	partialValueColor?: string;
	partialValueLightColor?: string;
	pointColorClassName?: string;
	pointLightColor?: string;
	pointLightColorEnabled?: boolean;
	pointRadius?: number;
	pointStrokeWidth?: number;
	pointSubtitles?: {
		height?: number;
		formatter?: (xSecondaryTitles: string[]) => string[];
	};
	pointTitles?: {
		height?: number;
		formatter?: (xTitles: string[]) => string[];
	};
	yAxisLabelWidth?: number;
	yDomainRangeMax?: number;
	yDomainMin?: number;
	yDomainMax?: number;
};

type ChartDataPoint = {
	title?: string;
	subtitle?: string;
	displayType?: 'partial' | 'hollow';
	y: number;
	x?: unknown; // An optional attribute that will determine the location the point gets drawn along the x-axis, if an xAxisPositionMap is provided in the chart data
	/* AlternatePointStyle|AlternateLineStyle flag
		Key defined by settings object...
		example: 'useAlternateStyle: boolean` */
	[key: string]: any;
};

export type ChartData1 = {
	lines: Array<{
		id?: number | string;
		points: Array<ChartDataPoint | null>; // A null point will cause the chart to split the line into multiple segments at that period.
		color?: string;
		alternateColors?: {
			faded?: string;
		};
		displayType: 'default' | 'faded';
	}>;
	xAxisTitles: string[];
	xAxisSecondaryTitles: string[];
	xAxisSelectedTitle?: number;
	xAxisPositionMap?: Map<unknown, number>; // An optional attribute that is a mapping between the possible x attribute values in chart data points and xAxis index positions. When provided, it is used to determine where to position lines and dots in the x direction.
	legend?: Array<{
		color?: string;
		alternateColors?: {
			faded?: string;
		};
		count: number;
		countDetailLink?: string;
	}>;
};

export type ChartData2 = ChartDataPoint[];

export type ChartData3 = {
	points: ChartDataPoint[];
	color?: string;
	displayType: 'default' | 'faded';
};

export default class LineChartComponent extends Component<LineChartProps> {
	_setupListeners(): void {
		const { onMouseOver } = this.props;

		if (typeof onMouseOver === 'function') {
			this._lineChartElem.current.addEventListener(MOUSE_OVER, onMouseOver);
		}
	}

	_lineChartElem = createRef<HTMLDivElement>();
	_lineChart: LineChart;
	_settings: LineChartSettings;

	componentDidMount(): void {
		const { data, settings } = this.props;
		this._settings = settings;

		this._lineChart = new LineChart(this._lineChartElem.current, settings);
		this._lineChart.draw(data);

		this._setupListeners();
	}

	shouldComponentUpdate(nextProps: LineChartProps): boolean {
		const { data, settings } = this.props;
		return (
			!isEqual(data, nextProps.data) || !isEqual(settings, nextProps.settings)
		);
	}

	componentDidUpdate(): void {
		const { data, settings } = this.props;

		if (!isEqual(this._settings, settings)) {
			this._settings = settings;
			this._lineChart.updateSettings(settings);
		}

		this._lineChart.draw(data);
	}

	render(): React.ReactNode {
		return <div ref={this._lineChartElem}></div>;
	}
}
