/***** Begin Imports *****/

/**
 * Standard designs for floating elements as defined by UX (Popovers, Tooltips, etc)
 * Check the contract in the style guide
 */
import popoverStandard from './popover-standard/standard';
import tooltipStandard from './tooltip-standard/standard';

/***** End Imports *****/


export default {
	'popover-standard': popoverStandard,
	'tooltip-standard': tooltipStandard
}
