import { AxiosPromise } from 'axios';

import { startCase } from 'lodash';

import {
	AjaxStatic,
} from '@utils/ajax';

import {
	EmployeeData,
	File,
	UpdatedFile,
} from 'NHPTemplates.mod/Form/Interfaces';
import { TemplateSaveData, PacketSaveData } from 'NHPTemplates.mod/Form/store/Interfaces';
import { TASK_FILE_UPLOAD_OPTIONS } from 'NHPTemplates.mod/Form/constants';

export interface EmailTemplateData {
	body: string;
	subject: string;
}

export interface AddTaskData {
	employeeId: number | string;
	name: string;
	dueDate: string;
	allowEmployeeUploads: TASK_FILE_UPLOAD_OPTIONS;
	description: string;
	files: Array<File>;
}

export interface EditTaskData extends AddTaskData {
	taskId: number | string;
}

interface ImportableTasksOptions{
	deletedOriginalIds: Array<number | string>;
	excludedIds: Array<number | string>;
	managerId: number | string;
	hireDate: string;
	initialImport?: boolean;
}

export interface TaskDataResponse {
	allowEmployeeUploads: TASK_FILE_UPLOAD_OPTIONS;
	applicable: 'no' | 'yes';
	archived: 'no' | 'yes';
	assignedByUserId: number | string;
	assignedUserId: number | string;
	canDelete: 'no' | 'yes';
	categoryId: number | string;
	completed: 'no' | 'yes';
	completedDateTime: string;
	completedUserId: number | string;
	created: string;
	description: string;
	dueDate: string;
	employeeId: number | string;
	id: number | string;
	name: string;
	originalItemId: number | string;
	sortOrder: number | string;
	tasklistId: number | string;
}

interface ImportableTasksData {
	afterDelay: string;
	afterRelation: 'soon' | 'on' | 'before';
	afterType: string;
	allowEmployeeUploads: TASK_FILE_UPLOAD_OPTIONS;
	assignedByUserId: number;
	assignedTo: string;
	assignedUserId: number;
	category: {
		id: number;
		name: string;
		taskListId: number;
		sortOrder: number;
		archived: boolean
	}
	description: string;
	id: number;
	name: string;
	notificationDelayDays: null
	notificationDelayRelation: null
	sortOrder: number;
	tasklistId: number;
	files: Record<string, UpdatedFile>;
	type: string;
}

interface ImportableTasksResponse {
	applicableTasks: Record<string, ImportableTasksData>;
	files: Record<string, UpdatedFile>;
	hasImported: boolean;
	notApplicableTasks: Record<string, ImportableTasksData>;
	users: Record<string, { userId: string | number, employeeId: number | string, name: string }>;
}

interface TemplateDataResponse {
	arriveByTime: string;
	contactEmployeeId: number | string;
	default: boolean;
	gtkyQuestions: Array<Record<string, any>>;
	gtkyRecipients: Array<Record<string, any>>;
	id: number;
	location: string;
	name: string;
	otherInstructions: string;
	sendGetToKnowYouEmail: boolean;
}

class BaseAPI {
	Ajax;

	constructor(AjaxUtil: AjaxStatic) {
		this.Ajax = AjaxUtil;
	}
}

export class EditEmailAPI extends BaseAPI {
	DEFAULT_CURRENT_END_POINT = 'current';

	DEFAULT_PREVIEW_END_POINT = 'demo';

	DEFAULT_GET_DEFAULT_MESSAGE_END_POINT = 'default';

	DEFAULT_RESET_END_POINT = 'reset';

	DEFAULT_SAVE_END_POINT = 'create';

	constructor(AjaxUtil: AjaxStatic) {
		super(AjaxUtil);

		this.getCurrentEmailTemplate = this.getCurrentEmailTemplate.bind(this);
		this.getEmailDefaultTemplate = this.getEmailDefaultTemplate.bind(this);
		this.resetEmailTemplateToDefault = this.resetEmailTemplateToDefault.bind(this);
		this.saveEmailTemplate = this.saveEmailTemplate.bind(this);
	}

	getCurrentEmailTemplate(endpoint = this.DEFAULT_CURRENT_END_POINT): AxiosPromise<any> {
		return this.Ajax.get(endpoint).then((response) => {
			if (response.status === 200) {
				const {
					body = '',
					defaulted = true,
					subject = '',
				} = response.data;

				return {
					body,
					defaulted,
					subject,
				};
			}
		});
	}

	getEmailDefaultTemplate(endpoint = this.DEFAULT_GET_DEFAULT_MESSAGE_END_POINT): AxiosPromise<any> {
		return this.Ajax.get(endpoint)
			.then((response) => {
				const {
					error,
				} = response?.data || {};

				if (error) {
					window.setMessage($.__('An error has occurred getting the default message, please try again'), 'error');
					throw new Error('Reset default failed');
				}

				return response.data;
			});
	}

	previewEmailTemplate(endpoint = this.DEFAULT_PREVIEW_END_POINT, emailTemplateData: EmailTemplateData): AxiosPromise<any> {
		return this.Ajax.post(endpoint, emailTemplateData)
			.then((response) => {
				const {
					body = '',
					error = {},
					subject = '',
				} = response.data;
				return {
					body,
					error,
					errorCode: error.code,
					subject,
				};
			})
			.catch((e) => {
				window.setMessage($.__('An error has occurred while getting the preview, please try again later'), 'error');
				throw e;
			});
	}

	resetEmailTemplateToDefault(endpoint = this.DEFAULT_RESET_END_POINT): AxiosPromise<any> {
		return this.Ajax.put(endpoint);
	}

	saveEmailTemplate(endpoint = this.DEFAULT_SAVE_END_POINT, emailData: EmailTemplateData): AxiosPromise<any> {
		return this.Ajax.post(endpoint, emailData);
	}
}

export class NewHirePacketAPI extends BaseAPI {
	DEFAULT_CONTACT_MANAGER_ENDPOINT = 'new_hire_packet/people';

	DEFAULT_GTKY_FILTER_ENDPOINT = 'new_hire_packet/gtkyoptions';

	DEFAULT_TEMPLATE_ENDPOINT = '/employees/new_hire_packet.php';

	DEFAULT_TEMPLATE_DATA_ENDPOINT = '/onboarding/nhp/';

	DEFAULT_ONBOARDING_TASKS_DATA_ENDPOINT = '/onboarding/tasks/';

	DEFAULT_ONBOARDING_TASKS_IMPORT_ENDPOINT = '/onboarding/tasks/importable/';

	constructor(AjaxUtil: AjaxStatic) {
		super(AjaxUtil);

		this.getContactAndManagerInfo = this.getContactAndManagerInfo.bind(this);
	}

	getNewEmployeeInfoForm(employeeId: number): AxiosPromise<any> {
		return this.Ajax.get(`/onboarding/nhp_preview/${ employeeId }`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				window.setMessage('Uh Oh, something went wrong loading the new hire packet new employee info.', 'error');
				throw error;
			});
	}

	getOnboardingTasks(endpoint = this.DEFAULT_ONBOARDING_TASKS_DATA_ENDPOINT, nhpId: number): AxiosPromise<any> {
		return this.Ajax.get(`${ endpoint }${ nhpId }`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				window.setMessage('Uh Oh, something went wrong loading the new hire packet tasks.', 'error');
				throw error;
			});
	}

	getEmployeePacketDetails(endpoint = this.DEFAULT_TEMPLATE_DATA_ENDPOINT, employeeId: number): AxiosPromise<any> {
		return this.Ajax.get(`${ endpoint }${ employeeId }`)
			.then((response) => {
				const {
					employee,
					newHirePacket,
					payrollFields,
					questionsAndAnswers,
					recipientTypes,
				} = response.data;

				const employeeData = { ...employee } as EmployeeData;

				for (const key in employeeData) {
					if (employeeData.hasOwnProperty(key) && employeeData[key] === null) {
						switch (key) {
							case 'id':
							case 'managerId':
								employeeData[key] = undefined;
								break;
							case 'payrollSynced':
								employeeData[key] = false;
								break;
							default: employeeData[key] = '';
						}
					}
				}

				return {
					employee: employeeData,
					newHirePacket,
					payrollFields,
					questionsAndAnswers,
					recipientTypes,
				};
			})
			.catch((e) => {
				window.setMessage('An error occurred getting packet data, please try again', 'error');
				throw e;
			});
	}

	getContactAndManagerInfo(endpoint = this.DEFAULT_CONTACT_MANAGER_ENDPOINT): AxiosPromise<any> {
		return this.Ajax.get(endpoint)
			.then((response) => {
				const {
					data,
				} = response;

				const {
					employees,
					nhpUserId,
					orderedEmployees,
					orderedUsers,
					users,
				} = data;

				const options = [];
				const userOptions = [];

				for (let i = 0; i < orderedEmployees.length; i++) {
					options.push({
						text: employees[orderedEmployees[i]] as string,
						value: `${ orderedEmployees[i] }`,
					});
				}

				for (let i = 0; i < orderedUsers.length; i++) {
					userOptions.push({
						text: users[orderedUsers[i]].name as string,
						value: `${ orderedUsers[i] }`,
					});
				}

				return {
					employeeOptions: options,
					userOptions,
					nhpUserId,
				};
			});
	}

	getTeamContactInfo(employeeId: number): AxiosPromise<any> {
		return this.Ajax.get(`/onboarding/nhp_preview/${employeeId}/team`)
			.then((response) => {
				const {
					error,
				} = response?.data || {};

				if (error) {
					window.setMessage($.__('An error has occurred getting the team information, please try again'), 'error');
					throw new Error('Reset default failed');
				}

				return response.data;
			})
			.catch((e) => {
				window.setMessage($.__('An error occurred getting packet data, please try again'), 'error');
				throw e;
			});
	}

	getGTKYFilterData(endpoint = this.DEFAULT_GTKY_FILTER_ENDPOINT): AxiosPromise<any> {
		const SelectToggleNames = {
			locations: 'location',
			divisions: 'division',
			departments: 'department',
			employmentStates: 'status',
		};
		return this.Ajax.get(endpoint)
			.then((response) => {
				const {
					data,
				} = response;

				const parentKeys = Object.keys(data);

				return parentKeys.map((parentKey) => {

					const toggleName = SelectToggleNames[parentKey] as string || parentKey;

					const items = [];
					const currentFilterData = data[parentKey] as Array<{ id: number | string; value: string }>;
					for (let i = 0; i < currentFilterData.length; i++) {
						items.push({
							parentId: toggleName,
							text: currentFilterData[i].value,
							value: currentFilterData[i].id,
						});
					}
					// TODO: Revisit this after BE updates the import endpoint, this may no longer be needed
					items.sort((item1, item2) => {
						if (item1.text < item2.text) {
							return -1;
						}
						if (item1.text < item2.text) {
							return 1;
						}
						return 0;
					});

					return {
						items,
						name: toggleName,
						text: startCase(toggleName),
						value: toggleName,
					};
				});
			});
	}

	getImportableTasks(
		endpoint = this.DEFAULT_ONBOARDING_TASKS_IMPORT_ENDPOINT,
		data: ImportableTasksOptions
	): Promise<any> {
		const missingData = validateImportableTaskOptions(data);

		if (missingData.length > 0) {
			return new Promise<any>((resolve, reject) => {
				reject({ missingData });
			});
		}

		const {
			deletedOriginalIds = [],
			excludedIds = [],
			managerId,
			hireDate,
			initialImport = true,
		} = data;

		return this.Ajax.post(endpoint, { deletedOriginalIds: deletedOriginalIds.join(','), excludedIds: excludedIds.join(','), managerId, hireDate })
			.then((response): ImportableTasksResponse => {
				const {
					applicableToEmployee,
					files,
					hasImported,
					notApplicableToEmployee,
					semiApplicableToEmployee,
					users,
				} = response.data;

				const otherTasks = { ...semiApplicableToEmployee, ...((initialImport) ? {} : notApplicableToEmployee) } as Record<string, ImportableTasksData>;

				return {
					applicableTasks: applicableToEmployee as Record<string, ImportableTasksData>,
					files,
					hasImported: hasImported as boolean,
					notApplicableTasks: otherTasks,
					users,
				};
			})
			.catch((error) => {
				window.setMessage($.__('Uh oh, something went wrong. Please try again.'), 'error');
				throw error;
			});
	}

	getMissingData(employeeId: number | string): AxiosPromise<any> {
		return this.Ajax.get(`/onboarding/tasks/${ employeeId }/missing_fields`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				setMessage($.__('An error occurred while getting the template information, please try again later'), 'error');
				throw error;
			});
	}

	getTemplates(endpoint): AxiosPromise<any> {
		return this.Ajax.get(endpoint).then((response) => {
			return response.data;
		}).catch(() => {
			setMessage($.__('An error occurred while getting the template list, please try again later'), 'error');
			return false;
		});
	}

	getTemplateData(endpoint): AxiosPromise {
		return this.Ajax.get(endpoint).then((response) => {
			const templateData = { ...response.data } as TemplateDataResponse;

			templateData.contactEmployeeId = (templateData.contactEmployeeId) ? templateData.contactEmployeeId : '';

			return templateData;
		}).catch(() => {
			setMessage($.__('An error occurred while getting the template information, please try again later'), 'error');
			return false;
		});
	}

	saveTemplate(endpoint: string, data: TemplateSaveData, method: 'post' | 'put' = 'post'): AxiosPromise {
		return this.Ajax[method](endpoint, data).then((response) => {
			return {
				savedNHPTemplate: true,
				templateData: response.data,
			};
		}).catch(() => {
			return {
				savedNHPTemplate: false,
			};
		});
	}

	deleteTemplate(endpoint: string): AxiosPromise<any> {
		return this.Ajax.delete(endpoint).catch((e) => {
			setMessage($.__('An error occurred while deleting the template, please try again'), 'error');
			throw e;
		});
	}

	savePacket(endpoint = this.DEFAULT_TEMPLATE_DATA_ENDPOINT, formData: PacketSaveData): AxiosPromise {
		return this.Ajax.post(endpoint, formData)
			.then((response) => {
				return {
					packetSaved: true,
					response,
				};
			}).catch((e) => {
				throw e;
			});
	}
}

function validateImportableTaskOptions(options: ImportableTasksOptions): Array<string> {
	const {
		managerId = '',
		hireDate = '',
	} = options;

	const missingData = [];

	if (
		(typeof managerId === 'string' && managerId.length < 1) ||
		managerId && Number.isNaN(managerId)
	) {
		missingData.push('managerId');
	}

	if (hireDate.length < 1) {
		missingData.push('hireDate');
	}

	return missingData;
}
