import {scaleLinear, scaleOrdinal} from 'd3-scale';
import {select} from 'd3-selection';
import {stack, stackOrderReverse} from 'd3-shape';
import {defaultsDeep, throttle, inRange} from 'lodash';

import { isEnabled } from 'FeatureToggle.util';
import Popover from 'Popover.mod';


/******************************************************************************
	Data contract for bar chart

	{
		title: {
			text: "247 Candidates (51% of Total Candidates)"
			width: 234 // width of the title text
			fontFamily: "BhrHeaderFont,Trebuchet MS;",
			fontSize: "14px",
			fill: "#777",
			icon: `Raw SVG`
		},
		popover: {
			title: '',
			content: ''
		},
		colors: '#ddd', // grey
		container: {
			height: 52,
			width: 887
		},
		bar: {
			height: 46,
			width: 100 // calculated difference width between top value and itself.,
		}
	}

******************************************************************************/

const CLASS_NAME = 'BarChartSingle';
const CHART_MAX = 1140;
const CHART_MIN = 993;
const DEFAULT_OPTIONS = {
	chartClass: '',
	title: {
		fill: [
			'#777', // inside fill
			'#777', // outside fill
		],
		icon: {
			name: '',
			width: '',
			height: '',
			fill: ''
		}
	},
	defaultFill: '#ddd',
	container: {
		height: 52,
		width: 993,
	},
	bar: {
		height: 46,
		width: 993,
		leftMargin: 10,
		radius: 3
	}
};

let fillIndex = 0;

export default class BarChartSingle {
	constructor(selector, options = DEFAULT_OPTIONS) {

		this._selector = selector;
		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);
		this._createDom();

		const throttled = throttle(this.resize, 20);
		window.addEventListener('resize', throttled)
	}

	/**
	* position the title inside/outside of the bar
	*/
	titlePosition = (d, x) => {
		let position;
		// title width is the width of the title text, the icon and the margins between icon and text and the container
		let titleWidth = d.title.width + d.title.icon.width + d.bar.leftMargin + 10
		// if the title width is smller than the bar graph, shif t the bar details outside bar graph and update the fill colors
		if (titleWidth < x(d.bar.width)) {
			position = d.bar.leftMargin;
			fillIndex = 0;
		} else {
			position = x(d.bar.width) + d.bar.leftMargin;
			//set the fill index to the outside fill
			fillIndex = 1;
		}
		return 'translate(' + position + ', ' + d.bar.height / 2 + ')';
	}

	/**
	* Build the rect for the bar graph
	*/
	makeRect = (d, x) => {
		let width = x(d.bar.width || 1);
		let height = d.bar.height;
		let radius = d.bar.radius || 3;
		// the default for bar is rounded corners on the right side of the bar. Pass 0 no roundings.
		return `
			M 0, 0
			h ${ width - radius }
			a ${ radius }, ${ radius } 0 0 1 ${ radius }, ${ radius }
			v ${ height - 2 * radius }
			a ${ radius }, ${ radius } 0 0 1 -${ radius }, ${ radius }
			h ${ radius - width }
			z
		`;

	}

	resize = () => {
		let reportWidth = document.querySelector(".ReportBody").offsetWidth;
		let legendWidth = document.querySelector(".NotHiredChartSetLegend").offsetWidth;
		if (inRange(reportWidth, CHART_MIN, CHART_MAX)) {
			this._options.container.width = reportWidth - legendWidth;
			var bars = document.querySelectorAll('.BarChartSingle');
			for (var i = bars.length; i--;) { // reverse
				bars[i].style.width = reportWidth - legendWidth + 'px';
			}
			let x = scaleLinear()
				.range([0, this._options.container.width])
				.domain([0, 100]);

			this._updateBars([this._options], x);
		}
	}

	/**
	 * Creates the main SVG for this chart with the required definitions
	 */
	_createDom() {
		let container = select(this._selector).append('div')
			.attr('class', `${ CLASS_NAME } ${ this._options.chartClass }`)
			.style('height', `${ this._options.container.height }px`)
			.style('width', `${ this._options.container.width }px`);

		this._svg = container.append('svg')
			.attr('class', `${ CLASS_NAME }__bar`)
			.attr('viewBox', `0 0 ${ this._options.container.width } ${ this._options.container.height }`)
			.attr('preserveAspectRatio', 'xMaxYMin meet')
			.style('height', `${ this._options.container.height }px`)
			.style('width', `${ this._options.container.widthp }px`);

	}

	/**
	 * Draws each element in the order needed for the chart to display properlly
	 * @param {object} data Formatted Data ready to be displayed
	 */
	draw(data) {
		let x = scaleLinear()
			.range([0, data.container.width])
			.domain([0, 100]);

		this._drawBars([data], x);
	}

	/**
	 * updates the containers width and the titles position
	 * @param {object}   barData Formatted data
	 * @param {function} x d3 function for the x axis
	 */
	_updateBars(barData, x) {
		let updateBar = this._svg.select('g').data(barData)
		updateBar.select('path')
			.attr('d', (d) => {
				return this.makeRect(d, x);
			});
		updateBar.select('g')
			.attr('transform', (d) => {
				return this.titlePosition(d, x)
			})
	}

	/**
	 * Draws the containers, and the individual cells of the bar chart
	 * @param {object}   barData Formatted data
	 * @param {function} x d3 function for the x axis
	 */
	_drawBars(barData, x) {
		// bind a <g> tag for each layer
		let BAR = this._svg.selectAll('g.layer').data(barData)
			.enter()
			.append('g')
			.attr('fill', d => d.defaultFill);
			// Build the Rect
		BAR.append('path')
			.attr("d", (d) => {
				return this.makeRect(d, x);
			})
			.on('mouseenter', (d, index, node) => {
				if (d.hasOwnProperty('popover')) {
					this._onPathEnter(node[index].parentNode, d.popover);
				}
			});
		let title = BAR.append('g')
			.attr('transform', (d) => {
				return this.titlePosition(d, x)
			})
			.attr('fill', d => d.title.fill[fillIndex])
			.attr('height', d => d.bar.height)
			.attr('width', (d) => {
				return d.title.width + d.title.icon.width + d.bar.leftMargin;
			});

		let titleIcon = title.append('svg')
			.attr('width', d => d.title.icon.width)
			.attr('height', d => d.title.icon.height)
			.attr('viewBox', '0 0 18 18')
			.attr('x', 0)
			.attr('y', d => -d.title.icon.height / 2)
			.attr('xmlns:xlink', 'http://www.w3.org/1999/xlink');

		titleIcon.append('use')
			.attr('xlink:href', (d) => {
				return d.title.icon.name;
			});

		title.append('text')
			.text((d) => { return d.title.text })
			.attr('x', (d) => {
				return d.title.icon.width + (d.bar.leftMargin / 2);
			})
			.attr('y', '0')
			.attr('dy', '0.7ex')
	}

	/**
	 * Event handler for when the mouse has entered a bar path
	 * @param  {object} path  The path that triggered the event
	 * @param  {object} d  The data represented by the path
	 */
	_onPathEnter(path, d) {
		select(path).transition()
			.duration(150)
			.attr('y', 0)
			.attr('height', this._options.bar.height);
		if (!Popover.hasInstance(path)) {
			Popover.create(path, {
				template: {
					name: 'popover-standard',
					data: {
						title: d.title,
						content: d.content,
						rawContent: true,
						popoverClass: `${ CLASS_NAME }__popover ${ this._options.chartClass }__popover`,
						titleCssOverride: {
							color: '#222222',
							'text-align': 'center',
							'font-weight': '600',
							'font-size': '18px'
						},
						contentCssOverride: {
							'text-align': 'center'
						}
					}
				},
				position: 'top',
				triggerEvent: 'hover',
				showImmediately: true,
				closeX: false,
				push: 2,
				delay: 75
			});
		}
	}
}
