
import {
	Checkbox,
	Label,
} from '@bamboohr/fabric';
import {
	ifFeature,
} from '@utils/feature';
import {
	Component,
	Fragment,
} from 'react';
import './optional-checkbox.styl';

export class OptionalCheckbox extends Component {
	constructor(props) {
		super(props);
		const { data } = props;

		this.state = {
			checked: data.checked
		};
	}

	/* @startCleanup encore */
	_handleLegacyCheckBoxChange = event => this.setState({
		checked: event.target.checked
	});
	/* @endCleanup encore */

	_handleCheckBoxChange = (param) => {
		this.setState({
			checked: param.checked,
		});
	};

	/* @startCleanup encore */
	_renderLegacyCheckbox = (id, data, viewOnly, checked, isUnemploymentField) => {
		const namePrefix = isUnemploymentField ? 'uiState' : 'state';

		return (
			<div className="fab-Checkbox OptionalCheckbox__tempCheckbox">
				<input
					className="fab-Checkbox__input ignore"
					defaultChecked={ checked ? 'checked' : null }
					disabled={ viewOnly }
					id={ `checkbox${ id }` }
					onChange={ this._handleLegacyCheckBoxChange }
					type="checkbox"
				/>
				<span className="fab-Checkbox__box"></span>
				<label className="fab-Checkbox__label" htmlFor={ `checkbox${ id }` }>{ data.label }</label>
				<input defaultValue={ checked ? 'on' : 'off' } name={ `${ namePrefix }[optional][${ id }]` } type="hidden" />
			</div>
		);
	};
	/* @endCleanup encore */

	_renderCheckbox = () => {
		const {
			id,
			data,
			viewOnly,
			isUnemploymentField,
		} = this.props;
		const {
			checked,
		} = this.state;
		const namePrefix = isUnemploymentField ? 'uiState' : 'state';

		return (
			<Fragment>
				<Checkbox
					checked={ checked }
					disabled={ viewOnly }
					id={ `checkbox${ id }` }
					label={ data.label }
					name={ `${ namePrefix }[optional][${ id }]` }
					onChange={ this._handleCheckBoxChange }
					value={ checked ? 'on' : 'off' }
				/>
				<input
					name={ `${ namePrefix }[optional][${ id }]` }
					type="hidden"
					value={ checked ? 'on' : 'off' }
				/>
			</Fragment>
		);
	};


	render() {
		const {
			id,
			data,
			viewOnly,
			help,
			isUnemploymentField,
		} = this.props;
		const { checked } = this.state;

		return (
			<div className="fieldRow OptionalCheckbox">
				{ ifFeature('encore',
					data.firstCheckbox ? (
						<Label>
							{ $.__('Select all that apply') }
						</Label>
					) : null
					,
					(
						<label className="OptionalCheckbox__label" htmlFor="checkboxes">
							{ $.__('Select all that apply') }
						</label>
					)
				) }

				<fieldset className="fab-CheckboxGroup">
					{ ifFeature('encore', this._renderCheckbox(), this._renderLegacyCheckbox(id, data, viewOnly, checked, isUnemploymentField)) }
				</fieldset>
			</div>
		);
	}

}
