import {
	extendURL,
	extendURLDeep,
	deleteParams as deleteQueryParams } from '@utils/url';
/**
 * Updates the URL parameters with given keys and values
 * @param  {Object} params   Key/value pair whe key is the param key and value is the param value
 */
export function setParams(params, deep): void {
	const url = deep ? extendURLDeep(params) : extendURL(params);
	window.history.replaceState(null, null, url);
}

/**
 * Deletes browser query parameters that match the names or name/values supplied to this function
 * @param  {Object} params Array of names or name/values to delete
 */
export function deleteParams(params): void {
	const url = deleteQueryParams(params);
	window.history.replaceState(null, null, url);
}
