export interface ColumnsRes {
    name: string
    fieldId: number
}

export interface EmployeeResultItem<T = string> {
    type: T
    value: string | number
}

export interface EmployeeResult {
    id: EmployeeResultItem<'id'>
    [key: string]: EmployeeResultItem
}

export interface Pagination {
    currentPage: number;
    next: number;
    previous: number;
    totalPages: number;
}

export interface EmployeeListRes {
    columns: ColumnsRes [];
    employeeResults: EmployeeResult[];
    totalNumberOfEmployees: number;
    pagination: Pagination;
}

export enum FieldIdType {
    ID = 'id',
    FIRST_NAME = -34,
    LAST_NAME = 2,
    HIRE_DATE = 3,
    JOB_TITLE = 17
}
