import {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { closeModal } from 'show-modal.mod';
import { get } from 'lodash';
import { bem, listId, ERROR_DATA } from '../../constants';
import { createSecureShare } from '../../service';
import { showSlidedown } from 'Alerts.mod';

const addErrorState = (errorList, errorType) => {
	const employeeList = document.getElementById(listId);
	errorList.forEach((item) => {
		const matches = employeeList.querySelectorAll(`.${ bem.elem('item') }[data-entry-value="${ item }"]`);
		matches.forEach((el) => {
			el.classList.add(errorType.className);
			if (errorType.description) {
				const errorDescription = el.querySelector(`.${ bem.elem('itemErrorDescription') }`);
				errorDescription.innerHTML = errorType.description;
			}
		});
	});
};

export const useCreate = (setProcessing, onSfsCreation) => {

	const formRef = useRef(null);
	const [$validator, setValidator] = useState(null);
	const [pending, setPending] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		setValidator($(formRef.current).bhrValidate({}, (errorList, defaultMessage) => {
			return errorList[0].message || defaultMessage;
		}));
	}, []);

	const create = useCallback(async () => {
		setPending(true);
		setResponse(null);
		setError(null);

		try {
			setResponse(await createSecureShare(formRef.current));
			setPending(false);
		} catch (e) {
			setPending(false);
			setError(e);
		}

	}, []);

	useEffect(() => {
		setProcessing(pending);
	}, [pending]);

	useEffect(() => {
		if (!pending && response) {
			if (onSfsCreation) {
				onSfsCreation();
			}
			closeModal();
			showSlidedown($.__('Your file has been sent.'), 'success');
		}
	}, [pending, response]);

	useEffect(() => {
		if (!pending && error) {
			if (get(error, 'response.data.type') === 'invalid_email') {
				const data = get(error, 'response.data');
				const { emails, employees } = data;
				addErrorState(emails, ERROR_DATA.invalidEmail);
				addErrorState(employees, ERROR_DATA.missingEmail);
				showSlidedown(data.error, 'error');
			} else {
				window.errorFallBack();
			}
		}
	}, [pending, error]);

	return { formRef, create, $validator };

};
