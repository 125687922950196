import { BEM } from '@utils/dom';

export const bem = new BEM('SecureFileShareCreateModal');
export const inputSelector = '.js-EmailInputList';
export const listId = 'email-input-list';
export const labelId = 'email-input-label';

export const ERROR_DATA = {
	duplicateEmail: {
		description: `(${ $.__('Duplicate Email') })`,
		className: bem.elem('item--duplicateError'),
		slidedownMessage: $.__('Sorry, we can’t send emails to duplicate addresses. Remove the duplicates and give it another try.'),
	},
	invalidEmail: {
		description: `(${ $.__('Invalid Email') })`,
		className: bem.elem('item--invalidEmailError'),
	},
	missingEmail: {
		description: `(${ $.__('Missing Email') })`,
		className: bem.elem('item--error'),
	},
};
