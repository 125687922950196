export enum CustomQuestionTypes {
	short = 'short',
	long = 'long',
	yes_no = 'yes_no',
	multi = 'multi',
	checkbox = 'checkbox',
	file = 'file',
}

export interface EmailStatus {
	id?: number|string;
	// statuses are ISO dates
	sent?: string,
	delivered?: string,
	failed?: string,
	opened?: string,
}

export enum JobOpeningLocationTypes {
	IN_OFFICE = '0',
	REMOTE = '1',
	HYBRID = '2',
}
