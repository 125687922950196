import { createDecimal } from 'decimal-helpers';

import './hours-per-week-field.styl';
import './styles.styl';

import { appendDecimalIfNecessary, fixCurrencyValue } from 'Currency.util';
import {
	jqUpdatePaidPerBasedOnPayType,
	jqUpdatePayRateBasedOnPayType,
	jqUpdatePayTypeBasedOnPayType,
	jsUpdatePayPerTextBasedOnPayType,
} from 'Employees.mod/compensation-fields.domain';

import { UpdateOrCorrectHistoryTableSection } from './update-or-correct-history-table-section';
import { hasTrax } from 'BambooHR.util';
import { initializeHoursPerWeekField } from './hours-per-week-field.domain';
import { isEnabled } from 'FeatureToggle.util';
import { render } from 'base/wrapped-render';

const ZERO_WAGE_ENABLED = isEnabled('zeroWage');

const CompensationForm = {
	/**
	 * Show/hide the overtime rate input on load based on the pay type and overtime status values
	 *
	 * @return {undefined}
	 */
	initializeForm(formType) {
		const $payType = $('.js-payType ba-option:selected');
		const $percentChange = $('.payRateGroup .percentChange');
		const payTypeVal = $payType.val();

		// using the val() should keep it in English no matter the langage `value='Hourly'`
		this.selectedPayType = payTypeVal ? payTypeVal.toLowerCase() : '';
		this.initialPayType = payTypeVal ? payTypeVal.toLowerCase() : '';
		this.initialPayRate = $('.payRateGroup .js-rate').val();
		this.initialPaidPer = $('.payRateGroup .js-paidPer ba-option:selected')
			.text()
			.toLowerCase();
		this.initialCurrencyType = $(
			'.payRateGroup .js-currency-picker-type',
		).val();

		if (this.initialCurrencyType === undefined) {
			this.initialCurrencyType = $('.payRateGroup .currencyType').val();
		}

		// Wrap in a setTimeout so that the fabric select pieces have been rendered and we can get the initial values
		setTimeout(() => {
			if (formType) {
				syncFieldStates(formType);
			}

			if (!formType || formType === 'updateCompensation') {
				const isHourly = this.selectedPayType === 'hourly';
				handleOvertimeRateDisplay(isHourly);
				handleOvertimeRateChange(true);
				if (isSyncingPayScheduleEnabled()) {
					addFormNote();
					handlePaidPerValidation();
					handlePayTypeValidation();
					handleFormNoteDisplay();

					if (ZERO_WAGE_ENABLED) {
						addOvertimeStatusFormNote();
						handleUpdatedPayRate();
					}
				}
			}

			handleUpdateTraxRequiredFields();

			if (formType === 'updateCompensation') {
				initializeHoursPerWeekField();
			}

			if (this.initialPayType === 'hourly' && $percentChange.length) {
				$percentChange.addClass('nextLine');
			}

			const $defaultCurrency = $('.js-defaultCurrency').val();
			const $currencyWarning = $('.currencyChange-message');

			if (this.initialCurrencyType !== $defaultCurrency) {
				showElement($currencyWarning);
			}
		});
	},
	/**
	 * Handler for form changes inside the Compensation modal
	 * status is selected
	 * @return {undefined}
	 */
	formChangeHandler(e) {
		const elementName = e.target.name;

		if (elementName === 'payType') {
			this.selectedPayType = `${e.target.value}`.toLowerCase();
		}

		const isHourly = this.selectedPayType === 'hourly';

		handleOvertimeRateDisplay(isHourly);

		if (elementName === 'rate[0]') {
			this.updatePercentChange();
		}
		// Need to wrap in setTimeout so that all the select init stuff on the global side can finish before we start messing with options
		setTimeout(() => {
			syncFieldStates('updateCompensation');

			if (isSyncingPayScheduleEnabled()) {
				if (elementName === 'payScheduleId') {
					handlePaidPerValidation();
					handlePayTypeValidation();
				} else if (elementName === 'paidPer') {
					handlePaidPerValidation();
				} else if (elementName === 'payType') {
					handlePayTypeValidation();
				}
				handleFormNoteDisplay();
			} else {
				resetSelectOptions($('.js-paidPer'));
				resetSelectOptions($('.js-payType'));
			}
			handleUpdateTraxRequiredFields();

			if (elementName === 'overtimeRate[0]') {
				handleOvertimeRateChange(true);
			}

			if (elementName === 'rate[1]') {
				const $defaultCurrency = $('.js-defaultCurrency').val();
				const $currencyWarning = $('.currencyChange-message');
				const $selectedCurrencyType = $('.payRateGroup .currencyType').val();
				if ($selectedCurrencyType !== $defaultCurrency) {
					showElement($currencyWarning);
				} else {
					hideElement($currencyWarning);
				}
			}
			if (elementName === 'rate[0]' && ZERO_WAGE_ENABLED) {
				handleUpdatedPayRate();
			}

			this.checkShouldShowPercentChange();
		});
	},
	setupForm(formType) {
		let payRateInputSelector;
		let startDateInputSelector;
		let endDateInputSelector;
		const $percentChange = $('.payRateGroup .percentChange');
		const currencyType = $('.js-CurrencyPicker .currencyType').val();

		if (formType === 'updateCompensation') {
			payRateInputSelector = 'input.js-payRate';
			startDateInputSelector = 'input[name="ymd"]';
			endDateInputSelector = 'input[name="endYmd"]';
		} else if (
			formType === 'requestChangeApproval' ||
			formType === 'approvalPreviewForm'
		) {
			payRateInputSelector = 'input.js-rate';
			startDateInputSelector = 'input.js-startDate';
			endDateInputSelector = 'input.js-endDate';
		}

		// It appears we need to prevent LastPass from trying to autofill a few of the inputs in some compensation modal forms if the 'logged out' modal gets displayed while they are open.
		// Otherwise, when the 'logged out' modal gets displayed and a user tries to have LastPass autofill their credentials, the pay rate value gets changed as well as the form attempts to submit.
		// Explicitly setting the LastPass ignore attribute on the following inputs appears to resolve the issue.
		addLastPassIgnoreDataAttribute(payRateInputSelector);
		addLastPassIgnoreDataAttribute(startDateInputSelector);
		addLastPassIgnoreDataAttribute(endDateInputSelector);

		if (formType === 'updateCompensation') {
			// Related to the 'Update Compensation' modal form, BE cannot easily replace on the pay rate field the 'required' class with a 'requiredWhenVisible' class.
			// If not replaced, the 'required' class automatically sets up a jQuery validation required rule.
			// Since there are valid situations when the pay rate field is not required (ex. the pay type field value is 'Commission Only' and the pay rate field is not visible to the user),
			// we will replace the 'required' class with the 'requiredWhenVisible' class on the FE.
			replaceElementClass(
				payRateInputSelector,
				'required',
				'requiredWhenVisible',
			);
		}

		if (currencyType !== 'USD') {
			hideElement($percentChange);
			return;
		}

		if (formType === 'requestChangeApproval' && $percentChange.length) {
			showElement($percentChange);
		}
	},

	displayUpdateOrCorrectSection(
		rowDisplayType,
		updateFormData,
		selectedBoxDefault,
	) {
		const container = document.querySelector(
			'div#compensation-update-or-correct-section',
		);

		if (container && rowDisplayType) {
			render(
				<UpdateOrCorrectHistoryTableSection
					updateFormData={updateFormData}
					rowDisplayType={rowDisplayType}
					selectedBoxDefault={selectedBoxDefault}
				/>,
				container,
			);
		}
	},

	// The following functions are for reusing the code in the custom approvals modal and new employee form
	addFormNote() {
		setTimeout(() => {
			addFormNote();
		});
	},
	handlePayScheduleChange(formType) {
		handlePaidPerValidation();
		handlePayTypeValidation();
		setTimeout(() => {
			handleFormNoteDisplay();
		});

		if (formType === 'requestChangeApproval') {
			syncFieldStates(formType);
		}
	},
	handlePaidPerChange() {
		setTimeout(() => {
			if (isSyncingPayScheduleEnabled()) {
				handlePaidPerValidation();
				handleFormNoteDisplay();
			}
		});
		this.checkShouldShowPercentChange();
	},
	handlePayTypeChange(formType) {
		syncFieldStates(formType);
		if (
			!formType ||
			formType === 'updateCompensation' ||
			formType === 'requestChangeApproval'
		) {
			setTimeout(() => {
				if (isSyncingPayScheduleEnabled()) {
					handlePayTypeValidation();
					handleFormNoteDisplay();
				}
			});
			this.checkShouldShowPercentChange();
		}
	},
	handlePayRateChange() {
		this.updatePercentChange();
	},
	handleCurrencyChange() {
		this.checkShouldShowPercentChange();
	},
	paidPerAndPayTypeValid() {
		return paidPerAndPayTypeValid();
	},
	handleOvertimeRateChange() {
		setTimeout(() => {
			handleOvertimeRateChange();
		});
	},
	// Determine whether or not the percent change section should be visible
	checkShouldShowPercentChange() {
		const $percentChange = $('.payRateGroup .percentChange');
		const payTypeVal = $('.js-payType ba-option:selected').val();
		const currencyType = $('.js-CurrencyPicker .currencyType').val();

		if (currencyType !== 'USD') {
			hideElement($percentChange);
			return;
		}

		if (!$percentChange.length) {
			return;
		}

		if (
			this.initialPayType === (payTypeVal ? payTypeVal.toLowerCase() : '') &&
			this.initialPaidPer ===
				$('.js-paidPer ba-option:selected').text().toLowerCase() &&
			(this.initialCurrencyType ===
				$('.payRateGroup .js-currency-picker-type').val() ||
				this.initialCurrencyType === $('.payRateGroup .currencyType').val())
		) {
			showElement($percentChange);
			return;
		}

		hideElement($percentChange);
	},
	updatePercentChange() {
		// quick check if the percent change section is visible
		if (!$('.payRateGroup .percentChange').length) {
			return;
		}

		const $payRate = $('.js-rate');
		const $percent = $('.percentChange-percent');
		const $textNoChange = $('.percentChange-text-noChange');
		const $textIncrease = $('.percentChange-text-increase');
		const $textDecrease = $('.percentChange-text-decrease');
		const $iconIncrease = $('.percentChange-icon-increase');
		const $iconDecrease = $('.percentChange-icon-decrease');

		const parsedCurrentValue = fixCurrencyValue(
			appendDecimalIfNecessary($payRate.val()),
		);
		const parsedInitialValue = fixCurrencyValue(
			appendDecimalIfNecessary(this.initialPayRate),
		);
		const difference = parsedCurrentValue - parsedInitialValue;
		const percent = (difference / parsedInitialValue) * 100;
		// Math.abs to make it non-negative (percent determines whether it's a postiive or negative change)
		// Math.round(percent * 100) / 100 gets us the number rounded to the nearest hundredths
		const roundedHundrethPercent = Math.abs(Math.round(percent * 100) / 100);

		// set the percentage
		$percent.text(`${roundedHundrethPercent}%`);

		if (percent === 0) {
			// no change
			showElement($textNoChange);
			hideElement($textIncrease);
			hideElement($textDecrease);
			hideElement($iconIncrease);
			hideElement($iconDecrease);
			return;
		}
		if (percent > 0) {
			// increase
			showElement($textIncrease);
			showElement($iconIncrease);
			hideElement($textNoChange);
			hideElement($textDecrease);
			hideElement($iconDecrease);
			return;
		}
		// decrease
		showElement($textDecrease);
		showElement($iconDecrease);
		hideElement($textIncrease);
		hideElement($iconIncrease);
		hideElement($textNoChange);
	},
};

function addLastPassIgnoreDataAttribute(selector) {
	$(selector).attr('data-lpignore', true);
}

function $getForm(formType) {
	if (
		formType === 'requestChangeApproval' ||
		formType === 'approvalPreviewForm'
	) {
		return $('.CustomApprovalForm');
	}

	return $('#historyEdit');
}

function getPayTypeElements(formType) {
	const $form = $getForm(formType);
	return {
		$payTypeSelectElement: $form.find('ba-select.js-payType'),
	};
}

function getPayRateElements(formType) {
	const $form = $getForm(formType);
	let $payRateInputElement;
	let $payRateButtonElement;
	let $payRateElement;
	if (
		formType === 'requestChangeApproval' ||
		formType === 'approvalPreviewForm'
	) {
		$payRateInputElement = $form.find('input.js-rate');
		$payRateButtonElement = $payRateInputElement.siblings(
			'button.js-currency-picker-button',
		);
		$payRateElement = $payRateInputElement.closest('.fieldRow');
	} else {
		$payRateInputElement = $form.find('input.js-payRate');
		$payRateButtonElement = $payRateInputElement.siblings(
			'button.CurrencyPicker__button',
		);
		$payRateElement = $payRateInputElement.closest('.fieldGroup');
	}

	return {
		$payRateInputElement,
		$payRateButtonElement,
		$payRateElement,
	};
}

function getPaidPerTextElements(formType) {
	const $form = $getForm(formType);
	let $paidPerTextElement;
	if (
		formType === 'requestChangeApproval' ||
		formType === 'approvalPreviewForm'
	) {
		$paidPerTextElement = $form
			.find('input.js-rate')
			.closest('.fieldBox')
			.find('.fab-FormNote');
	} else {
		$paidPerTextElement = $form.find('.compensation-per');
	}

	return {
		$paidPerTextElement,
	};
}

function getPaidPerElements(formType) {
	const $form = $getForm(formType);
	let $paidPerSelectElement;
	if (
		formType === 'requestChangeApproval' ||
		formType === 'approvalPreviewForm'
	) {
		$paidPerSelectElement = $form.find('ba-select.js-paidPer');
	} else {
		$paidPerSelectElement = $form.find('ba-select[name="paidPer"]');
	}

	return {
		$paidPerSelectElement,
		$paidPerElement: $paidPerSelectElement.closest('.fieldBox'),
	};
}

function syncFieldStates(formType) {
	// Sync Pay Type state first since Pay Rate, Paid Per, and the pay rate 'per' text states rely on it.
	syncPayTypeState(formType);
	syncPayRateState(formType);
	syncPayRatePerText(formType);
	syncPaidPerState(formType);
}

function syncPaidPerState(formType) {
	const { $payTypeSelectElement } = getPayTypeElements(formType);
	const { $paidPerSelectElement, $paidPerElement } =
		getPaidPerElements(formType);
	jqUpdatePaidPerBasedOnPayType(
		$payTypeSelectElement,
		$paidPerElement,
		$paidPerSelectElement,
	);
}

function syncPayRatePerText(formType) {
	const { $payTypeSelectElement } = getPayTypeElements(formType);
	const { $paidPerTextElement } = getPaidPerTextElements(formType);
	jsUpdatePayPerTextBasedOnPayType($payTypeSelectElement, $paidPerTextElement);
}

function syncPayRateState(formType) {
	const { $payTypeSelectElement } = getPayTypeElements(formType);
	const { $payRateInputElement, $payRateButtonElement, $payRateElement } =
		getPayRateElements(formType);
	jqUpdatePayRateBasedOnPayType(
		$payTypeSelectElement,
		$payRateElement,
		$payRateInputElement,
		$payRateButtonElement,
	);
}

function syncPayTypeState(formType) {
	const { $payTypeSelectElement } = getPayTypeElements(formType);
	jqUpdatePayTypeBasedOnPayType($payTypeSelectElement);
}

function handleUpdatedPayRate() {
	const $payRate = $('.js-payRate');
	const $overtimeStatus = $('.js-overtimeStatus');
	const $overtimeStatusFormNote = $('.js-overtimeStatusFormNote');

	if (!$overtimeStatus.length || !$payRate.length) {
		return;
	}

	const parsedVal = fixCurrencyValue($payRate.val());

	if (parsedVal === 0) {
		$overtimeStatus.val('Exempt').disable();
		$overtimeStatusFormNote.show();
	} else {
		$overtimeStatus.enable();
		$overtimeStatusFormNote.hide();
	}
}

function replaceElementClass(
	elementSelector,
	classToReplace,
	replacementClass,
) {
	const $element = $(elementSelector);
	if ($element.hasClass(classToReplace)) {
		$element.removeClass(classToReplace);
		$element.addClass(replacementClass);
	}
}

function appendOvertimeRateNote($payRateElement) {
	const $paidPer = $('.js-paidPer, ba-select[name="paidPer"]');
	const name = $paidPer.attr('name');
	const paidPerValue = $(`select[name="${name}"]`).val();

	const $overtimeRateNote = $('.js-hourlyOvertimeNote');

	if (!hasTrax() || paidPerValue !== 'Hour') {
		// Only continue if the company is trax. Fixes bug with companies outside the US.
		// Another story will add better BE architecture for us to tell if this should be done based off of user/company location, accounting for companies that may be a mix of US and non-US locations
		// Right now we are restricted with custom permissions and getting the location information we need.

		// Also check to make sure the payPer is set to Hour, otherwise hide the message
		if ($overtimeRateNote) {
			$overtimeRateNote.remove();
		}
		return false;
	}

	const currencySymbol = $payRateElement.data('cursymbol') || '$';

	const overtimeRateValue = fixCurrencyValue(
		appendDecimalIfNecessary($payRateElement.val(), currencySymbol),
	);

	const overtimeRateDecimal = createDecimal(overtimeRateValue);

	const doubleOvertimeRate = overtimeRateDecimal
		? overtimeRateDecimal.times(2).toFixed(2)
		: NaN.toString();

	const overtimeRate = overtimeRateDecimal
		? overtimeRateDecimal.times(1.5).toFixed(2)
		: NaN.toString();

	const overtimeRateString =
		$payRateElement.data('currencysymbolposition') == 0
			? `${currencySymbol}${overtimeRate}`
			: `${overtimeRate}${currencySymbol}`;

	const doubleOvertimeRateString =
		$payRateElement.data('currencysymbolposition') == 0
			? `${currencySymbol}${doubleOvertimeRate}`
			: `${doubleOvertimeRate}${currencySymbol}`;

	const noteText = $.__(
		'Overtime rate is %1 (1.5x), double-time rate is %2',
		overtimeRateString,
		doubleOvertimeRateString,
	);

	if ($overtimeRateNote.length > 0) {
		$overtimeRateNote.html(
			`<span class="js-hourlyOvertimeNote fab-FormNote">${noteText}</span>`,
		);
	} else {
		$('.js-overtimeStatus, .js-exemptStatus')
			.closest('.fieldBox')
			.append(
				`<span class="js-hourlyOvertimeNote fab-FormNote">${noteText}</span>`,
			);
	}
}

function handleOvertimeRateDisplay(isHourly = false) {
	const $overtimeRate = $('.js-overtimeRate');
	const $overtimeStatus = $('select[name="exempt"]').val();
	toggleOvertimeRateDisplay($overtimeRate, $overtimeStatus, isHourly);

	if ($overtimeStatus === 'Non-exempt') {
		if (!isHourly) {
			$overtimeRate
				.find('.currency-field.js-overtimeRate')
				.setTraxRequired(isSyncingPayScheduleEnabled());
		}
	}
}

export function toggleOvertimeRateDisplay($overtimeRate, status, isHourly) {
	let $payRateElement = $('.js-payRate');

	if ($payRateElement.length === 0) {
		$payRateElement = $('.js-rate');
	}

	if (status === 'Non-exempt') {
		if (!hasTrax()) {
			// show overtime rate if not trax until we figure out a better way to handle user/company locations
			$overtimeRate.show();
		} else if (
			isHourly &&
			$payRateElement.data('currencysymbolposition') == 0
		) {
			appendOvertimeRateNote($payRateElement);
			hideOvertimeRate($overtimeRate);
		} else {
			const $overtimeRateNote = $('.js-hourlyOvertimeNote');
			if ($overtimeRateNote.length > 0) {
				$overtimeRateNote.remove();
			}
			$overtimeRate.show();
		}
	} else {
		hideOvertimeRate($overtimeRate);
	}
}

function hideOvertimeRate($overtimeRate) {
	$overtimeRate.hide();
	// OR must be cleared when hidden so previous values aren't saved!
	// Approvals - container, Table - container & input
	$overtimeRate.val('').find('input.currency-field').val('');
}

function syncingPayScheduleSelected() {
	const $paySchedule = $('.js-paySchedule');
	const $selectedOption = $paySchedule.find('ba-option[selected]');
	return $selectedOption.attr('data-syncing-pay-schedule') === 'true';
}

function isSyncingPayScheduleEnabled() {
	const $paySchedule = $('.js-paySchedule');
	const $selectedOption = $paySchedule.find('ba-option[selected]');
	return $selectedOption.attr('data-syncing-pay-schedule') === 'true';
}

function handlePaidPerValidation() {
	const $paidPer = $('.js-paidPer');
	const name = $paidPer.attr('name');
	const $paidPerValue = $(`select[name="${name}"]`).val();
	const syncingPaySchedule = syncingPayScheduleSelected();

	if (syncingPaySchedule) {
		removeInvalidOptions($paidPer);
		if ($paidPerValue === 'Piece') {
			$paidPer.attr('placeholder', $paidPerValue);
			$paidPer.attr('error', true);
		} else {
			removeErrorStates($paidPer);
		}
	} else if (!syncingPaySchedule) {
		resetSelectOptions($paidPer);
		removeErrorStates($paidPer);
	} else if ($paidPerValue !== '') {
		removeErrorStates($paidPer);
	}
}

function handlePayTypeValidation() {
	const $payType = $('.js-payType');
	const name = $payType.attr('name');
	const $payTypeValue = $(`select[name="${name}"]`).val();
	const syncingPaySchedule = syncingPayScheduleSelected();

	if (syncingPaySchedule) {
		removeInvalidOptions($payType);
		if ($payTypeValue === 'Contract') {
			$payType.attr('placeholder', $payTypeValue);
			$payType.closest('.fieldBox').find('label').addClass('fab-Label--error');
			$payType.attr('error', true);
		} else {
			removeErrorStates($payType);
		}
	} else if (!syncingPaySchedule) {
		resetSelectOptions($payType);
		removeErrorStates($payType);
	} else if ($payTypeValue !== '') {
		removeErrorStates($payType);
	}
}

function handleUpdateTraxRequiredFields() {
	const isSyncingPayShedule = isSyncingPayScheduleEnabled();
	const selectedPayType = $('.js-payType ba-option:selected').attr('value');
	const isRequiredWhenTraxSyncing =
		isSyncingPayScheduleEnabled(isSyncingPayShedule) &&
		selectedPayType !== 'Commission';
	$('.js-rate').setTraxRequired(isRequiredWhenTraxSyncing);
	$('.js-payRate__label').toggleClass(
		'fab-Label--required',
		isSyncingPayShedule,
	);
}

function removeInvalidOptions(select) {
	const options = select.find('ba-option');
	select.children().remove();
	const validOptions = options.map((i, option) => {
		if ($(option).attr('data-invalid-payroll-option') === 'true') {
			$(option).attr('hidden', true);
		}
		return option;
	});
	select.append(validOptions);
}

function removeErrorStates(select) {
	select.removeAttr('error');
	select.closest('.fieldBox').find('label').removeClass('fab-Label--error');
}

function resetSelectOptions(select) {
	const options = select.find('ba-option');
	const selectedOptions = select.find('ba-option[selected]');
	select.children().remove();
	const validOptions = options.map((i, option) => {
		if ($(option).attr('data-invalid-payroll-option') === 'true') {
			$(option).removeAttr('hidden');
			if (selectedOptions.length > 1) {
				$(option).removeAttr('selected');
			}
		}
		return option;
	});
	select.append(validOptions);
}

function addOvertimeStatusFormNote() {
	const $overtimeStatusFieldBox = $('.js-overtimeStatus').closest('.fieldBox');
	const $formNote = $(
		`<span class="fab-FormNote js-overtimeStatusFormNote">${$.__(
			'Non-exempt requires a Pay Rate higher than $0.00',
		)}</span>`,
	);
	$overtimeStatusFieldBox.append($formNote);
}

function addFormNote() {
	const $paySchedule = $('.js-paySchedule');
	const $noteParent = $(
		'<div id="js-pay-schedule-note" style="position: absolute; left: 224px; width: 230px; display: none;"></div>',
	);
	const noteWarningText = $.__(
		'This pay schedule isn’t compatible with a couple other fields.',
	);
	const noteText = $.__(
		'Make sure to also update the fields highlighted in red.',
	);
	$noteParent.append(
		`<div class="fab-FormNote CompensationForm__warningNote js-pay-schedule-warning-note">${noteWarningText}</div>`,
	);
	$noteParent.append(`<div class="fab-FormNote">${noteText}</div>`);
	$paySchedule.parent().append($noteParent);
}

function handleFormNoteDisplay() {
	const $payType = $('.js-payType');
	const $paidPer = $('.js-paidPer');

	if ($payType.attr('error') || $paidPer.attr('error')) {
		$('#js-pay-schedule-note').show();
	} else {
		$('#js-pay-schedule-note').hide();
	}
}

function paidPerAndPayTypeValid() {
	const $payType = $('.js-payType');
	const $paidPer = $('.js-paidPer');

	if ($payType.attr('error') || $paidPer.attr('error')) {
		return false;
	}

	return true;
}

function handleOvertimeRateChange(historyTableModal = false) {
	if (!hasTrax()) {
		// Only continue if the company is trax. Fixes bug with companies outside the US.
		// Another story will add better BE architecture for us to tell if this should be done based off of user/company location accounting for companies that may be a mix of US and non-US locations
		// Right now we are restricted with custom permissions and getting the location information we need.
		return false;
	}
	const $overtimeRate = $('.js-overtimeRate');
	const $overtimeRateInput = historyTableModal
		? $('.overtimeRate')
		: $overtimeRate;
	$overtimeRateInput.blur();
	const overtimeRateValue = fixCurrencyValue($overtimeRateInput.val());
	const $doubleOvertimeNote = $('.js-double-overtime-note');
	const doubleOvertimeNoteExists = $doubleOvertimeNote.length > 0;
	const doubleOvertimeRate = calculateDoubleOvertime(overtimeRateValue);
	const noteText = $.__('The double time rate is $%1.', doubleOvertimeRate);

	if (doubleOvertimeNoteExists) {
		$doubleOvertimeNote.text(noteText);
		$doubleOvertimeNote.toggle(overtimeRateValue !== 0);
	} else if (overtimeRateValue !== 0) {
		const noteElement = `<span class="fab-FormNote js-double-overtime-note">${noteText}</span>`;
		if (historyTableModal) {
			$overtimeRate.append(noteElement);
		} else {
			$overtimeRate.closest('.fieldDiv').append(noteElement);
		}
	}
}

export function calculateDoubleOvertime(overtimeRate) {
	return ((Number(overtimeRate) / 1.5) * 2).toFixed(2);
}

function showElement(jquerySelector) {
	jquerySelector.removeClass('hidden');
}

function hideElement(jquerySelector) {
	jquerySelector.addClass('hidden');
}

export default CompensationForm;
