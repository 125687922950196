import { Button, CardSize, DatePicker, FormFieldDefinition, ItemType, makeStyles, RadioGroup, RadioGroupProps, SelectField, SelectFieldProps, SheetModal, StandardModal, TextArea, TextButton, Typography, useForm } from '@bamboohr/fabric';
import { AddItemModal } from 'add-item.mod';
import { showSlidedown } from 'Alerts.mod';
import { ReactElement, useState } from 'react';
import { EmployeeAvatar } from '../../employee-avatar';
import { StatusMismatchEndEmploymentData, StatusMismatchEndEmploymentStepProps } from '../../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ spacing }) => ({
	benetracStatus: {
		gridColumn: '1 / -1',
	},
	comment: {
		gridColumn: '1 / -1',
	},
	effectiveDate: {
		gridColumn: '1 / -1',
	},
	fields: {
		display: 'grid',
		gap: spacing(2),
		gridTemplateColumns: 'min-content min-content 1fr',
		marginTop: ifFeature('encore', spacing(2)),
	},
	finalPayDate: {
		gridColumn: '1 / -1',
	},
	runSpecialPayroll: {
		gridColumn: '1 / -2',
	},
	heading: {
		marginTop: spacing(3.5),
	},
	reason: {
		gridColumn: '2 / 3',
	},
	rehire: {
		gridColumn: '1 / -1',
	},
	type: {
		gridColumn: '1 / 2',
	},
}));

export function EndEmploymentStep({
	employee,
	fields,
	hasGusto,
	onCancel,
	onNext,
	onPrevious,
	renderHeader,
	showFinalPayDate
}: StatusMismatchEndEmploymentStepProps): ReactElement {
	const classes = useStyles();
	const [addingReason, setAddingReason] = useState(false);
	const [addedReasons, setAddedReasons] = useState<Array<ItemType>>([]);

	const top = StandardModal.Constraint.Spacing.LARGE;
	const bottom = StandardModal.Constraint.Spacing.LARGE;

	const fieldDefinitions: Array<FormFieldDefinition<StatusMismatchEndEmploymentData>> = [
		{ name: 'employmentStatusComment', maxLength: 255 },
		{ name: 'rehire' },
		{ name: 'termDate', required: true },
		{ name: 'termReason' },
		{ name: 'termType', required: true },
	];

	if (fields.benetracStatus) {
		fieldDefinitions.push({ name: 'benetracStatus', required: true });
	}
	if (showFinalPayDate) {
		fieldDefinitions.push({ name: 'finalPayDate', required: true });
	}
	if (hasGusto) {
		fieldDefinitions.push({ name: 'runSpecialPayroll', defaultValue: '0' });
	}

	const {
		controlled,
		formState: { errors },
		fields: formFields,
		handleSubmit,
		setValue,
	} = useForm<StatusMismatchEndEmploymentData>(fieldDefinitions);

	const onInvalid = () => {
		showSlidedown($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
	};

	const onValid = (formValues: StatusMismatchEndEmploymentData) => {
		onNext({ id: employee.id, ...formValues });
	};

	return (
		<StandardModal.Body
			renderFooter={
				<StandardModal.Footer
					actions={
						ifFeature(
							'encore',
							[
								<TextButton key="cancel" onClick={ onCancel }>{ $.__('Cancel') }</TextButton>,
								<Button color="secondary" key="previous" onClick={ () => onPrevious() } variant="outlined">{ $.__('Previous') }</Button>,
								<Button key="next" onClick={ handleSubmit(onValid, onInvalid) }>{ $.__('Save') }</Button>,
							],
							[
								<Button key="next" onClick={ handleSubmit(onValid, onInvalid) }>{ $.__('Save') }</Button>,
								<Button color="secondary" key="previous" onClick={ () => onPrevious() } variant="outlined">{ $.__('Previous') }</Button>,
								<TextButton key="cancel" onClick={ onCancel }>{ $.__('Cancel') }</TextButton>
							]
						)
					}
				/>
			}
			renderHeader={ renderHeader }
			size={ CardSize.MEDIUM }
		>
			<StandardModal.Constraint spacingOverrides={ { top, bottom } }>
				<StandardModal.HeroHeadline
					hasSeparator={ true }
					icon={ ifFeature('encore', 'person-circle-question-regular', <EmployeeAvatar employee={ employee }/>) }
					subtext={ $.__(`This applies even if %1 resigned voluntarily, and wasn't terminated for cause.`, employee.preferredName) }
					text={ $.__('Okay! In that case %1 should have their employment ended in BambooHR.', employee.preferredName) }
				/>

				<Typography className={ classes.heading } color="primary" variant="h5">{ $.__('Add End of Employment Info') }</Typography>

				<div className={ classes.fields }>
					{
						controlled<'termDate'>(f => (
							<DatePicker { ...f } className={ classes.effectiveDate } label={ $.__('Effective Date') } status={ errors.termDate ? 'error' : undefined } />
						), formFields.termDate)
					}

					{
						showFinalPayDate ? (
							controlled<'finalPayDate'>(f => (
								<DatePicker { ...f } className={ classes.finalPayDate } label={ $.__('Final Pay Date') } status={ errors.finalPayDate ? 'error' : undefined } />
							), formFields.finalPayDate)
						) : null
					}

					{
						controlled<'termType', SelectFieldProps>(f => (
							<SelectField { ...f } className={ classes.type } id="endEmploymentType" items={ fields.termType.items } label={ $.__('Type') } status={ errors.termType ? 'error' : undefined } width={ 7 } />
						), formFields.termType)
					}

					{
						controlled<'termReason', SelectFieldProps>(f => {
							return (
								<SelectField
									{ ...f }
									className={ classes.reason }
									id="endEmploymentReason"
									items={ [...fields.termReason.items, ...addedReasons] }
									label={ $.__('Reason') }
									onAdd={
										() => {
											setAddingReason(true);
											return Promise.resolve();
										}
									}
									width={ 7 }
								/>
							);
						}, formFields.termReason)
					}

					{
						controlled<'rehire', SelectFieldProps>(f => (
							<SelectField { ...f } className={ classes.rehire } id="endEmploymentRehire" items={ fields.rehire.items } label={ $.__('Eligible for Rehire') } width={ 5 } />
						), formFields.rehire)
					}

					{
						fields.benetracStatus ? (
							controlled<'benetracStatus', SelectFieldProps>(f => (
								<SelectField { ...f } className={ classes.benetracStatus } id="benetracStatus" items={ fields.benetracStatus.items } label={ $.__('BeneTrac Status') } status={ errors.benetracStatus ? 'error' : undefined } width={ 7 } />
							), formFields.benetracStatus)
						) : null
					}
					{
						hasGusto ? (
							controlled<'runSpecialPayroll'>((f) => {
								return (
									<RadioGroup
										{...f}
										className={classes.runSpecialPayroll}
										items={[
											{
												label: $.__('Yes, I want to run a special termination payroll in order to pay the employee their last check on the spot.'),
												value: '1',
											},
											{
												label: $.__(`No, the employee agrees to be paid the rest of their wages on the company's normal pay schedule.`),
												value: '0',
											},
										]}
										label={$.__('Gusto')} />
								);
							}, formFields.runSpecialPayroll)
						) : null
					}
					{
						controlled<'employmentStatusComment'>(f => (
							<TextArea { ...f }
								className={ classes.comment}
								label={ $.__('Comment') }
								note={ errors.employmentStatusComment ? $.__('Your comment needs to be less than 255 characters in length.') : undefined }
								status={ errors.employmentStatusComment ? 'error' : undefined }
								width={ 10 }
							/>
						), formFields.employmentStatusComment)
					}
				</div>
			</StandardModal.Constraint>

			<AddItemModal
				component={ SheetModal }
				field={ { label: $.__('Termination Reason Name'), id: fields.termReason.fieldId }}
				onCancel={ () => setAddingReason(false) }
				onSave={
					(item: ItemType) => {
						setAddingReason(false);
						setAddedReasons(reasons => [...reasons, item ]);
						setValue('termReason', item.value);
					}
				}
				open={ addingReason }
				title={ $.__('Add Termination Reason') }
			/>
		</StandardModal.Body>
	)
}
