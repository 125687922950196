import {FunctionComponent, useEffect, useState} from "react";
import {getCovidDocuments, uploadCovidDocuments, deleteFile} from "./api";
import {FileDocument} from "./interfaces";
import {FileUpload, Typography} from "@bamboohr/fabric";
import {useStyles} from './styles';
import {DocumentsProps} from "dynamic-form/types/dynamic-form";
import {AxiosPromise} from "axios";
import {InitialFile} from './components';

const Documents: FunctionComponent<DynamicForm.DocumentsElement> = ({props, settings, context}) => {
    const {
        accessLevel
    } = props as DocumentsProps;

    const {
        type
    } = settings;

    const canView = accessLevel.includes("view");
    const canEdit = accessLevel.includes("edit");

    const [uploadFiles, setUploadFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const classes = useStyles();

    function onFileRemove(fileMetadata) {
        deleteFile(fileMetadata.id).then(function ({data = {}}) {
            setLoaded(false);
            if (data.success) {
                setMessage(data.success_message, 'success');
            } else {
                setMessage(data.error, 'error');
            }
        })
    }

    function onFileUpload(fileMetadata) {
        const employeeId = window.Employee.id;
        let upload: AxiosPromise | null;

        switch (type) {
            case "upload_covid_documents":
                upload = uploadCovidDocuments(fileMetadata.id, employeeId);
                break;
            default:
                upload = null;
                break;
        }

        if (upload !== null) {
            upload.then(function ({data = {}}) {
                if (data.success) {
                    setMessage(data.success_message, 'success');
                } else {
                    setMessage(data.error, 'error');
                }
            })
        }
    }

    function loadFiles() {
        getCovidDocuments(window.Employee.id).then(function (response) {
            if (response.data && Array.isArray(response.data)) {
                setUploadFiles(response.data);
                setIsLoading(false);
                setLoaded(true);
            }
        });
    }

    useEffect(function () {
        if (!loaded && !isLoading) {
            setIsLoading(true);
            loadFiles();
        }
    }, [loaded, isLoading, canEdit])

    return (
        <div>
            {canView && (
                <div>
                    <div>
                        <Typography className={classes.header} variant="small">{$.__(`Attached File(s)`)}</Typography>
                    </div>
                    {uploadFiles.length > 0 && uploadFiles.map((file: FileDocument) => <InitialFile canEdit={canEdit && !isLoading} file={file} key={file.id} onDelete={onFileRemove} />)}
                    {canEdit && <FileUpload
                        canSelectMultiple={true}
                        isDisabled={isLoading}
                        onRemove={onFileRemove}
                        onUpload={onFileUpload}/>}
                </div>
            )}
        </div>
    );
}

export default Documents;
