/**
 * Gets the neighboring arc to a data item
 *
 * NOTE: Found on https://bl.ocks.org/mbostock/5682158
 *
 * @param  {number} i     The item index
 * @param  {object} data0 The previous data collection
 * @param  {object} data1 The next data collection
 * @param  {function} key The key function
 * @return {object}       The neighboring arc
 */
function findNeighborArc(i, data0, data1, key) {
	let d = findPreceding(i, data0, data1, key) || findFollowing(i, data0, data1, key);
	if (d) {
		return {
			startAngle: d.endAngle,
			endAngle: d.endAngle
		};
	}

	return null;
}

/**
 * Find the element in data0 that joins the highest preceding element in data1
 *
 * NOTE: Found on https://bl.ocks.org/mbostock/5682158
 *
 * @param  {number} i     The item index
 * @param  {object} data0 The previous data collection
 * @param  {object} data1 The next data collection
 * @param  {function} key The key function
 * @return {object}       The neighboring arc
 */
function findPreceding(i, data0, data1, key) {
	var m = data0.length;
	while (--i >= 0) {
		var k = key(data1[i]);
		for (var j = 0; j < m; ++j) {
			if (key(data0[j]) === k) {
				return data0[j];
			}
		}
	}
}


/**
 * Find the element in data0 that joins the lowest following element in data1
 *
 * NOTE: Found on https://bl.ocks.org/mbostock/5682158
 *
 * @param  {number} i     The item index
 * @param  {object} data0 The previous data collection
 * @param  {object} data1 The next data collection
 * @param  {function} key The key function
 * @return {object}       The neighboring arc
 */
function findFollowing(i, data0, data1, key) {
	var n = data1.length;
	var m = data0.length;
	while (++i < n) {
		var k = key(data1[i]);
		for (var j = 0; j < m; ++j) {
			if (key(data0[j]) === k) {
				return data0[j];
			}
		}
	}
}

export {findNeighborArc};
