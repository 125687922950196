import {
	useEffect,
	useRef,
} from 'react';

import Ajax from '@utils/ajax';
import { initialFormState } from './constants';

export function getInitialFormValues(initialValues) {
	if (!initialValues) {
		return initialFormState;
	}

	return {
		name: prepareInitialValue(initialValues.name),
		acaStatus: prepareInitialValue(initialValues.acaStatus),
		fte: prepareInitialValue(initialValues.fte),
		changeHistory: 'history',
	};
}

function prepareInitialValue(initialValue) {
	return (initialValue === null || initialValue === undefined) ? '' : initialValue;
}

export function postEmploymentStatus(isEdit, listId, listValueId, formValues) {
	let formData;

	if (isEdit) {
		formData = Ajax.prepareData(
			{
				action: 'Update',
				ajax: 1,
				changeHistory: formValues.changeHistory,
				listValueId,
				id: listId,
				acaStatus: formValues.acaStatus === '' ? null : formValues.acaStatus,
				value: formValues.name,
				FTEValue: formValues.fte,
			},
			Ajax.prepareDataTypes.FORM_DATA
		);
	} else {
		formData = Ajax.prepareData(
			{
				action: 'Add',
				ajax: 1,
				id: listId,
				acaStatus: formValues.acaStatus === '' ? null : formValues.acaStatus,
				label: formValues.name,
			},
			Ajax.prepareDataTypes.FORM_DATA
		);
	}

	return Ajax.post('/settings/list.php', formData);
}

export function reloadPage() {
	window.location.reload();
}

export function wasSaveSuccessful(response) {
	return response.data && response.data.ret === 'SAVED';
}

export function getFormErrors(formValues, validateAca) {
	return {
		fte: !isFTEValid(formValues.fte),
		name: !isRequiredFieldValid(formValues.name),
		acaStatus: validateAca ? !isRequiredFieldValid(formValues.acaStatus) : false,
	};
}

export function formHasErrors(formErrors) {
	return formErrors.name || formErrors.acaStatus || formErrors.fte;
}

function isRequiredFieldValid(value) {
	return value !== '';
}

function isFTEValid(fteValue) {
	if (!fteValue) {
		return true;
	}

	const fteFloat = window.getFloatFromStringJS(fteValue);

	return !isNaN(fteFloat) && fteFloat <= 1 && fteFloat >= 0;
}

export function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
}
