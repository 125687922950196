import './styles.styl';

/**
 * MinimalDonut - a minimal donut that shows percent done vs percent left with options to animate and include text
 *
 * @props  {number} percentDone   a number representing the percent done
 * @props  {number} radius        the radius of the donut, i.e. how big do you want the donut to be
 * @props  {number} width         the width of the svg
 * @props  {number} height        the height of the svg
 * @props  {string} displayText   optional. if you want text inside of your donut this should provided
 * @props  {string} strokeWidth   stroke width of donut. e.g. 3px
 * @props  {boolean} animate      should percent done value animate
 * @props  {string} stroke        hex color of the svg. If this is not provided, stroke will be baseStrokeColor
 * @return {JSX}                  completed svg minimal donut
 */
export default function MinimalDonut({ percentDone, radius, width, height, displayText, strokeWidth, animate, stroke }) {
	const percentLeft = 100 - percentDone;
	const defaultStrokeArray = 2 * Math.PI * radius + 1;
	const strokeDashoffset = defaultStrokeArray * (1 - (percentDone / 100));
	const leftoverStroke = defaultStrokeArray * (1 - (percentLeft / 100));
	const percentDoneStyle = { strokeDasharray: defaultStrokeArray, strokeDashoffset, strokeWidth };
	const percentLeftStyle = { strokeDasharray: `${ leftoverStroke }, 2, ${ strokeDashoffset - 5 }, 2`, strokeWidth };
	const circleColorClass = 'MinimalDonut__circle--themeStrokeColor';
	const strokeColor = stroke;
	return (
		<svg className="MinimalDonut" height={ height } width={ width }>
			<g transform={ `translate(${ width / 2 },${ height / 2 })` }>
				<circle
					className="MinimalDonut__circle MinimalDonut__circle--percentLeft"
					r={ radius }
					style={ percentLeftStyle }
					transform="rotate(270.1)"
				/>
				<circle
					className={ `MinimalDonut__circle ${ strokeColor ? null : circleColorClass } ${ animate ? 'MinimalDonut__circle--animate' : '' }` }
					r={ radius }
					stroke={ strokeColor }
					style={ percentDoneStyle }
					transform="rotate(270.1)"
				/>
				{ displayText && (
					<text
						className="MinimalDonut__text fontSemibold MinimalDonut__circle--themeFillColor"
						dy=".3em"
						textAnchor="middle"
					>
						{ displayText }%
					</text>
				) }
			</g>
		</svg>
	);
}
