import moment from 'moment';
import { isEmpty } from 'lodash';

import UserName from '../../../containers/user-name';
import { getUsersTimezone } from './../../../../../../../hiring/shared/utils';

import './style.styl';


const getFormattedTime = (item, timezones) => {
	const createdAt = item.createdDate || item.createdAt;
	const isIso8601 = moment(createdAt, moment.ISO_8601, true).isValid();
	const timezone = !isEmpty(timezones) ? getUsersTimezone(timezones)?.timezone : '';

	if (isIso8601) {
		const isDateOnly = moment(createdAt, 'YYYY-MM-DD', true).isValid();
		if (isDateOnly) {
			return moment(createdAt).format();
		}
		return timezone ? moment.utc(createdAt).tz(timezone).calendar() : moment.utc(createdAt).local().calendar();
	}
	return timezone ? moment.unix(createdAt).tz(timezone).calendar() : moment.unix(item.createdAt).calendar();

};

const Header = ({ item, timezones }) => (
	<div className="FeedListItem__info">
		<div className="FeedListItem__userName">
			<UserName id={ item.author } />
		</div>
		{ item.categoryName && (
			<div className="FeedListItem__category">
				{ item.categoryName }
			</div>
		) }
		<div className="FeedListItem__timeStamp" data-date={ item.createdAt }>
			{ getFormattedTime(item, timezones) }
			{ item.edited && ` - ${ $.__('Edited') }` }
		</div>
	</div>
);

export default Header;

