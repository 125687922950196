// /**
//  * Used to create a mask dynamically based on input from user
//  * or return a static value based on a string passed in
//  */

import createDynamicMask from './create-dynamic-mask';

export default function createNumericMask({
	maxLength = null,
	minLength = null,
	replaceInvalidCharacters = true,
} = {}): (string?: string) => Array<RegExp> {
	return createDynamicMask({
		maxLength,
		minLength,
		replaceInvalidCharacters,
		nonMatchingRegExp: /\D+/g,
		matchingRegularExpression: /\d/,
	});
}
