import classNames from 'classnames';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {Icon, IconV2, Flex, Headline, IconTile, StyledBox, BadgeV2} from '@bamboohr/fabric';
import React from 'react';
import './styles.styl';

export const ModalHead = function ModalHead(props) {
	const {
		header,
		headerType,
		headline,
		iconColor,
		iconName,
		isMobileFriendly = false,
		mobileFriendlyHeader = '',
		type,
		iconV2Color = null,
	} = props;

	const modalHeader = (header || headline);

	const headerClasses = classNames('legacyModal__header', {
		[`legacyModal__header--${ headerType }`]: !!headerType,
	});

	const headerContent = classNames('legacyModal__headerContent', {
		[`legacyModal__header--${ type }--mobileHide`]: !!headerType && !!isMobileFriendly,
	});

	const headerContentMobile = classNames('legacyModal__headerContent', {
		[`legacyModal__header--${ type }--mobileShow`]: !!headerType && !!isMobileFriendly,
	});

	const iconClasses = classNames('legacyModal__headerIcon', {
		[`legacyModal__headerIcon--${ iconColor }`]: !!iconColor,
		[`legacyModal__headerIcon--${ type }--mobileHide`]: !!headerType && !!isMobileFriendly,
	});

	if (headerType === 'filled') {
		return (
			<div className={ headerClasses }>
				<div className="legacyModal__headerBox">
					{ modalHeader }
				</div>
			</div>
		);
	}

	const headerSize = (headerType === 'stacked') ? 'small' : 'medium';

	const encoreHeadline = modalHeader ? React.createElement(
		Headline,
		{
			id: 'fabricModalHeadline',
			size: (type === 'fullScreen') ? 'large' : headerSize,
			color: (headerType === 'stacked' && type !== 'fullScreen') ? 'neutral-strong' : 'primary',
		},
		modalHeader
	) : null;

	// @startCleanup encore
	const jadeHeadline = modalHeader ? React.createElement(
		(type === 'fullScreen') ? 'h2' : 'h4',
		{ className: headerContent, id: 'fabricModalHeadline' },
		modalHeader
	) : null
	// @endCleanup encore

	const renderContent = () => (
		<>
			{iconName && (
				headerType === 'text' ? (
					<StyledBox marginBottom='8px'>
					<BadgeV2
						icon={iconName}
						title={modalHeader}
					/>
					</StyledBox>
				) : (
					<>
						<Flex alignItems='center' justifyContent='center'>
							<StyledBox marginBottom='24px'>
								<IconTile icon={<IconV2 color={iconV2Color} name={iconName} size={32} />} size={64} />
							</StyledBox>
						</Flex>
						{encoreHeadline}
					</>
				)
			)}

		</>
	);
	// @startCleanup encore
	const renderJadeContent = () => (
		<>
			{iconName && (
				<div className={iconClasses}>
					<Icon name={iconName} />
				</div>
			)}
			{jadeHeadline}
		</>
	);
	// @endCleanup encore

	if (
		iconName ||
		(modalHeader && typeof modalHeader === 'string') ||
		(mobileFriendlyHeader && typeof mobileFriendlyHeader === 'string')
	) {
		return (
		<div className={headerClasses} role="banner">
			{ifFeature('encore', renderContent(), renderJadeContent())}
			{isMobileFriendly && mobileFriendlyHeader && type !== 'fullScreen' && (
				<h2 className={headerContentMobile} id='fabricModalHeadlineMobile'>
					{mobileFriendlyHeader}
				</h2>
			)}
		</div>
	)
	}

	return modalHeader;
}
