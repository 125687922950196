import { get, merge } from 'lodash';
import ajax from '@utils/ajax';
import { validationClassMap } from './css-class-map';
import { hasTrax } from 'BambooHR.util';
import { isSelectedPSSyncing, customRules, findChildren, filterFieldsMemoized, getField, newEmployeeForm } from 'dynamic-form';
import { AxiosResponse } from 'axios';
import { debugValidation } from '../debug';

// This is the response from the validation url when the rate limiter has been met
export const RATE_LIMIT_RESPONSE = null;

// These are the rules supported by the tool, everything else needs to be handled in validate
const SUPPORTED_RULES = ['maxLength', 'minLength', 'max', 'min', 'pattern'];

/**
 * Returns dynamically whether the field should be required
 *
 * @param context
 * @param field
 * @returns boolean
 */
export const isRequired = (context: DynamicForm.Context, field: DynamicForm.DataProps): boolean => {
	let isRequired = true;

	// is the element visible?
	const el = document.getElementById(field.props.id);
	if (!isVisible(el)) {
		// Element is not visible and is not required
		return false;
	}

	// is the element disabled? or readonly? -> Pending or Calculated
	if (field?.props?.disabled === true || field?.props?.readOnly !== undefined) {
		// the field is disabled and un-editable by the user from pending, permissions, calculated, etc. thus, not required
		return false;
	}

	// normal required check
	if (field?.settings?.validation?.requiredWhenVisible === true) {
		isRequired = true;
	} else {
		isRequired = false;
	}

	// Self Service Validation - only on new employee form, if it has a specific class and not already marked required
	if (currentEditingEmployeeId() === 0 && field?.props?.className?.includes('emailRequiredIfSelfService') && !isRequired) {
		const employeeAccessField = getField(context, newEmployeeForm.employeeAccess);
		const emailFields = filterFieldsMemoized(context, { selector: 'props.className', value: 'oneEmailRequired' });
		const emailKeys = Object.keys(emailFields);
		if (employeeAccessField?.props?.value === 'enabled' && emailKeys.length === 1) {
			return true;
		}
	}

	// Trax - Overrides required/requiredWhenVisible
	if (field?.settings?.validation?.requiredTrax && hasTrax()) {
		const isSyncing = isSelectedPSSyncing(context);
		// Only override if syncingPS is true. Otherwise fallback to BE Business logic required (requiredWhenVisible) -> Ex. First/Last names are required
		if (isSyncing) {
			isRequired = true;
		}
	}

	return isRequired;
};

/**
 * Gets the current editing employee id
 *
 * @returns number
 */
export const currentEditingEmployeeId = (): number => {
	return window.currentlyEditingEmployeeId || 0;
};

/**
 * Returns boolean whether the page is New Employee or not
 * Broken into its own function in case additional logic needs to be added
 */
export const isNewEmployeePage = (): boolean => {
	let returnValue = false;
	if (currentEditingEmployeeId() === 0) {
		returnValue = true;
	}
	return returnValue;
};

/**
 * The BE repeating fields cause problems when they are like table_5[1234][4069]
 * The validator creates huge arrays to match the numbers - We still need to maintain the numbers for BE save
 * So, only use this to register the input, not as the name attribute of a component
 *
 * @param name
 */
export const fixValidationRegisterName = (name: string): string => {
	if (typeof name !== 'string') {
		console.error('Cannot validate field without name');
		return '';
	}
	// we only want to mess with the funky table ones (emergency contact, education, etc)
	if (name.includes('table_')) {
		return name.replaceAll('[', '_group').replaceAll('][', '_field').replaceAll(']', '');
	}
	return name;
};

/**
 * Returns a matching error object
 *
 * @param errors
 * @param name string
 */
export const getError = (errors: DynamicForm.Context['validation']['errors'], name: string): {
	message: string,
	type: string
} | null => {
	return get(errors, name, null);
};

/**
 * Standardizes react-hook-form nested error object so our QF names work
 *
 * @param errors
 * @param name
 * @returns boolean
 */
export const hasErrors = (errors: DynamicForm.Context['validation']['errors'], name: string): boolean => {
	return !!getError(errors, name);
};

/**
 * Gets error object for errors on the FieldRow (only if a truthy message is on the object)
 *
 * @param context
 * @param errors
 * @param id string
 */
export const getFieldRowError = (context: DynamicForm.Context, errors: DynamicForm.Context['validation']['errors'], id: string): {
	message: string,
	type: string
} | null => {

	if (id && Object.keys(errors).length) {
		const fieldRowChildren = findChildren(context, id, { selector: 'type', value: 'FieldRow' });
		// this filters out nested field rows -> we just want the lowest one
		if (Object.keys(fieldRowChildren).length === 0) {
			const fieldChildren = findChildren(context, id, { selector: 'key', value: 'field' });
			const fieldNames = Object.keys(fieldChildren).map(key => fieldChildren[key]?.props?.name);
			// find first error that has a message
			const firstFieldError = fieldNames.find(name => (hasErrors(errors, name) && getError(errors, name)?.message));
			if (firstFieldError) {
				return get(errors, firstFieldError, null);
			}
		}
	}

	return null;
};

/**
 * Returns whether the user is onboarding (not fully logged in)
 *
 * @returns boolean
 */
export const isOnboardingUser = (): boolean => {
	return window.isOnboardingUser || (window.SESSION_USER && window.SESSION_USER.isOnboardingUser);
};

/**
 * Validation url for different application locations and states
 *
 * @returns string
 */
export const getValidationUrl = (): string => {
	if (isOnboardingUser()) {
		return '/self_onboarding/packet/validate';
	}
	return '/employees/validate';
};

/**
 * Returns whether the field is visually hidden or not
 * @param {element} element
 * @returns {boolean}
 */
export function isVisible(element): boolean {
	// this is difficult to do in vanilla JS. But, in future we hopefully can replace jQuery
	return $(element).is(':visible');
}

/**
 * Adds validation rules that match what we get back from the BE
 * Slowly moving away from class based validation to field based validation
 *
 * @param context
 * @param field
 */
export function mapValidationObject(context: DynamicForm.Context, field: DynamicForm.DataProps) {
	const rules: {[key: string]: any} = {
		validate: {},
	};
	if (field?.settings?.validation && typeof field.settings.validation === 'object' && Object.keys(field.settings.validation).length) {

		// we need the whole field. We may only get some props passed in with field so we will get the full field from the context
		const contextField = context.formData[field?.props?.id];
		if (!contextField) {
			return rules;
		}

		const BEValidationKeys = Object.keys(field.settings.validation);
		if (BEValidationKeys.includes('maxLength')) {
			let { maxLength } = field.settings.validation;
			if (hasTrax() && BEValidationKeys.includes('maxLengthTrax') && isSelectedPSSyncing(context)) {
				maxLength = field.settings.validation.maxLengthTrax;
			}
			if (contextField.type.toLowerCase().includes('select')) {
				rules.validate.maxLengthSelect = customRules.maxLengthSelect(context, field, {
					maxLength,
					message: $.__('Please select an option with no more than %1 characters.', maxLength),
				});
			} else {
				rules.maxLength = {
					value: maxLength,
					message: $.__('Please enter no more than %1 characters.', maxLength),
				};
			}
		}
	}
	return rules;
}

/**
 * Dynamically creates an object with all validations from classes on the field
 * (similar to how the old jQuery validation worked)
 * It returns the first so make sure all rules for an input are grouped together and class is unique
 *
 * @param {DynamicForm.Data} field
 * @returns {object}
 */
export function getClassBasedValidation(context: DynamicForm.Context, field: DynamicForm.DataProps): object {
	const rules: {[key: string]: any} = {
		validate: {},
	};
	const cssClasses = (field.props.className || '').split(' ');
	cssClasses.forEach((cssClass) => {
		const matchedRules = validationClassMap[cssClass];
		if (matchedRules && field.props.disabled === false) {
			Object.keys(matchedRules).forEach((rule) => {
				if (SUPPORTED_RULES.includes(rule)) {
					rules[rule] = matchedRules[rule];
				} else {
					const customRule = customRules[rule];
					if (customRule) {
						rules.validate[rule] = customRule(context, field, matchedRules[rule]);
					}
				}
			});
		}
	});

	const validationRules = mapValidationObject(context, field);
	const mergedRules = merge(rules, validationRules);

	// debug
	debugValidation(field, mergedRules);

	return mergedRules;
}

/**
 * we aren't using lodash memoize because it will memoize the promise and that can cause issues if the promise is prematurely rejected
 * since we don't use axios cache adapters or other async tools we are just doing it manually
 * Only caches for current page/instance. Will be reset on refresh/page-change unless using React Router
 */
const BEValidationCache = {};

/**
 * Will allow validation requests to be cached, if applicable.
 * @param ajaxFunction
 * @param data
 * @param url
 * @param cacheKey
 */
export const validateWithBE = async (ajaxFunction: typeof ajax.post | typeof ajax.get, data: any, url: string = undefined, cacheKey: string = undefined):
Promise<{response: null | AxiosResponse, isValid: boolean, isRateLimited: boolean}> => {
	if (typeof ajaxFunction !== 'function') {
		console.warn('validateWithBE - unsupported ajax function!');
	}
	if (cacheKey && cacheKey !== '') {
		const cache = BEValidationCache[cacheKey];
		if (cache) return cache;
	}
	let response: AxiosResponse = null;
	let isValid = true;
	let isRateLimited = false;
	try {
		response = await ajaxFunction.call(ajax, url || getValidationUrl(), data);
		// true=valid false=invalid null=rate-limited
		isValid = response.data === true;
		isRateLimited = response.data === RATE_LIMIT_RESPONSE;

		if (isRateLimited) {
			// Not used to report data mining, but used to alert us the FE will fail!
			// Rate limiter has been met, FE validation will not work for 2 min
			window.Rollbar.info('EE Validation: limit has been met!', { action: (data || {})?.action });
		}
	} catch (e) {
		console.warn(e);
		// Something is wrong with the request. Treat the field as valid and fallback on BE validation
		// return here so it isn't cached!
		return { response: null, isValid: true, isRateLimited: false };
	}

	const returnObj = {
		response,
		isValid,
		isRateLimited,
	};

	// cache the response if not rate-limited or empty
	if (cacheKey && cacheKey !== '' && !isRateLimited) {
		BEValidationCache[cacheKey] = returnObj;
	}

	return returnObj;
};
