/**
 * Footer Selectors
 *
 * @type Object
 */
var selectors = {
	text: '.js-simplemodal-footer-text'
};

/**
 * Simple Modal Footer Text Object
 */
var Text = {
	/**
	 * Hide the footer text
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	hide($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.text).hide();
	},

	/**
	 * Set the html for the right side of the footer.
	 *
	 * @param {string}  text   The text you want to set
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	setHtml(text, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.text).html(text);
	},

	/**
	 * Set the text for the right side of the footer. Note that it uses .text() -- use setHtml to set html content
	 *
	 * @param {string}  text   The text you want to set
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	setText(text, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.text).text(text);
	},

	/**
	 * Show the footer text
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	show($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.text).show();
	}
};


export default Text;
