import { TimesheetShiftDifferential } from "../types";

export function getShiftDifferentialText(shiftDifferential: TimesheetShiftDifferential) {
	const hours = Math.floor(shiftDifferential.hours);
	const minutes = Math.round((shiftDifferential.hours - hours) * 60);

	const hoursFormatted = $.__('%sh', window.formatNumberJS(hours, 0), { note: "'h' is for hours"})
	const minutesFormatted = minutes !== 0 && $.__('%sm', window.formatNumberJS(minutes, 0), { note: "'m' is for minutes"})
	
	if (hoursFormatted && minutesFormatted) {
		return `${hoursFormatted} ${minutesFormatted} ${shiftDifferential.name}`;
	} else if (hoursFormatted) {
		return `${hoursFormatted} ${shiftDifferential.name}`;
	} else if (minutesFormatted) {
		return `${minutesFormatted} ${shiftDifferential.name}`;
	}

	return '';
}
