module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push(''); var uploadedFiles = newUploadedFiles || false 
p.push('  '); files.forEach(function (file) {  var esigIcon = (file.isEsig || (file.info && file.info.icon && typeof file.info.icon.indexOf === 'function' && file.info.icon.indexOf('esignature') > -1));  var iconName = window.BambooHR.Utils.getIconNameFromFileName(file.originalName || file.name, '16x20', esigIcon); 
p.push(' <div class="FileCard js-file  '); if(file.disabled) { 
p.push('  FileCard--disabled  '); } 
p.push('  '); if(file.mode === 'display' || theme ==='display') { 
p.push('  FileCard--display  '); } 
p.push('  '); if(file.theme === 'light' || theme ==='light') { 
p.push('  FileCard--light  '); } 
p.push('"  '); if (file.data) { 
p.push('   '); Object.keys(file.data).filter(function(key){return file.data[key]}).forEach(function(key) { 
p.push('    data-',window.microTemplate.encodeHtml(key),'="',window.microTemplate.encodeHtml(file.data[key]),'"   '); }); 
p.push('  '); } 
p.push('  '); if (file.action) { 
p.push('   data-action="',window.microTemplate.encodeHtml(file.action),'"  '); } 
p.push('  data-file-id="',window.microTemplate.encodeHtml(file.fileId),'"  data-id="',window.microTemplate.encodeHtml(file.id),'"     data-key="',window.microTemplate.encodeHtml(file.key),'" >  <span class="FileCard__detail">   <span class="FileCard__title dot-ellipsis" title="',window.microTemplate.encodeHtml(BambooHR.Utils.sanitizeHtml(file.name)),'">    ',window.microTemplate.encodeHtml(BambooHR.Utils.sanitizeHtml(file.name)),'   </span>   '); if (file.status) {
p.push('    <span class="FileCard__status dot-ellipsis" title="',window.microTemplate.encodeHtml(BambooHR.Utils.sanitizeHtml(file.status)),'">     ',window.microTemplate.encodeHtml(BambooHR.Utils.sanitizeHtml(file.status)),'    </span>   '); } 
p.push('  </span>  <span class="FileCard__info">   '); if (iconName) { 
p.push('    <span class="FileCard__icon">     <ba-file-icon       esig="'); if(esigIcon){
p.push('true');} else {
p.push('false');} 
p.push('"       name="',window.microTemplate.encodeHtml(file.originalName),'"       alt="'); if(file.isSafe){
p.push('',window.microTemplate.encodeHtml(file.name),'');} else {
p.push('\'Virus Detected\'');}
p.push('">     </ba-file-icon>    </span>   '); } 
p.push('   '); if (file.info.className && file.mode === 'display') {
p.push('    <span class="FileCard__btn FileCard__btn--',window.microTemplate.encodeHtml(file.info.className),'">     ',window.microTemplate.encodeHtml(BambooHR.Utils.sanitizeHtml(file.info.text)),'    </span>   '); } else { 
p.push('    ',window.microTemplate.encodeHtml(BambooHR.Utils.sanitizeHtml(file.info.text)),'   '); } 
p.push('  </span> '); if (file.mode !== 'display') {
p.push('  '); if (file.data.isComplete === null) { 
p.push('   <span class="FileCard__close js-fc-close"></span>  '); } else { 
p.push('   <span class="FileCard__close js-fc-modal" data-is-complete="',window.microTemplate.encodeHtml(file.data.isComplete),'"></span>  '); } 
p.push('   '); if (file.info.text === 'Esignature') { 
p.push('   <label class="bhrToggle bhrToggle--require"      data-tip-class="bhrTooltip--autoWidth"      '); if (file.required === 'yes') { 
p.push('     data-tip-content="',window.microTemplate.encodeHtml($.__('Click to make the signature optional.')),'"     data-tip-lead="',window.microTemplate.encodeHtml($.__('Signature Required')),'"    '); } else { 
p.push('     data-tip-content="',window.microTemplate.encodeHtml($.__('Click to make the signature required.')),'"     data-tip-lead="',window.microTemplate.encodeHtml($.__('Signature Optional')),'"    '); } 
p.push('     data-tip-delay="800"   >    <input     type="checkbox"     class="bhrToggle__input js-fc-toggle-required"     name="fc-toggle-',window.microTemplate.encodeHtml(file.fileId),'"     data-file-id="',window.microTemplate.encodeHtml(file.fileId),'"     '); if (file.required === 'yes') { 
p.push('     checked     '); } 
p.push('    >    <span class="bhrToggle__label"></span>    <span class="bhrToggle__handle"></span>    <span class="bhrToggle__icon">*</span>   </label>  '); } 
p.push('  <input type="hidden" class="js-file-ids" '); if (uploadedFiles) { 
p.push(' name="temporary_file_ids[]" '); } else { 
p.push(' name="file_ids[]" '); } 
p.push(' id="',window.microTemplate.encodeHtml('filesinput-' + file.fileId),'" value="',window.microTemplate.encodeHtml(file.id),'" /> '); } 
p.push(' '); if (file.isEsig && file.esignatureInstanceId > 0) { 
p.push('  <span id="',window.microTemplate.encodeHtml('esignature-signing-root-' + file.esignatureInstanceId),'"></span> '); } 
p.push(' </div> '); }); 
p.push(' ');}return p.join('');
}