import {ACTION_TYPES, State,} from './Definitions';

import {updateErrorFields, updateHighlightedField, updateHireDate, updateManager,} from './actions';

function taskSectionReducer(currentState: State, action: { payload: any, type: ACTION_TYPES }): State {
	const {
		payload,
		type,
	} = action;

	const updatedState = { ...currentState };

	switch (type) {
		case (ACTION_TYPES.SET_INITIAL):
			return { ...updatedState, ...payload };
		case (ACTION_TYPES.UPDATE_ERROR_FIELDS):
			return updateErrorFields(updatedState, payload);
		case (ACTION_TYPES.UPDATE_HIGHLIGHTED_FIELDS):
			return updateHighlightedField(updatedState, payload);
		case (ACTION_TYPES.UPDATE_MANAGER_ID):
			return updateManager(updatedState, payload);
		case (ACTION_TYPES.UPDATE_HIRE_DATE):
			return updateHireDate(updatedState, payload);
		default: {
			throw new Error(`Unhandled action type: ${ action.type }`);
		}
	}
}

export {
	State,
	ACTION_TYPES,
	taskSectionReducer
};
