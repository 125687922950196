import {
	ajax,
} from '@utils/ajax';

import {
	isLocationGreenhouse,
} from './domain';
import {
	transformValidateRoutingNumberApiResponse,
} from './serializers';
import {
	TransformedValidateRoutingNumberApiResponse,
} from './types';

const clientRoutingAjax = ajax.create('client-routing-ajax', {
	baseURL: '/payroll/direct_deposit/routing/validate',
});

const ghRoutingAjax = ajax.create('gh-routing-ajax', {
	baseURL: '/greenhouse/bamboohr_payroll/banking/ajax/routing_number/validate',
});

export function validateRoutingNumberApi(routingNumber: string): Promise<TransformedValidateRoutingNumberApiResponse> {
	return (isLocationGreenhouse() ? ghRoutingAjax : clientRoutingAjax).request({
		method: 'get',
		url: `/${ routingNumber }`,
	}).then(transformValidateRoutingNumberApiResponse);
}
