import './style.styl';
import ActionDropdown from '../action-dropdown';
import Editor from '../editor';
import FileList from '../../containers/file-list';
import Header from './header';
import UserAvatar from '../../containers/user-avatar';


const Item = ({item, config, timezones, ...action}) => {
	let dom = null;

	if (item.editing) {
		dom = (
			<Editor
				config={ config }
				defaultValue={ item.text }
				editing={ item.editing }
				focusOnMount={ true }
				itemId={ item.id }
				onReset={ () => action.cancelEditing(item.id) }
				onSubmit={ (e, text, tempFiles, textArea, deleteFiles) => {
					e.preventDefault();
					action.editComment(
						item.id,
						text,
						tempFiles.valueSeq().map(v => v.remoteId).toArray(),
						deleteFiles.keySeq().toArray()
					);
				} }
				pending={ item.pending }
				showEditText={ true }
			/>
		);
	} else {
		dom = (
			<div className="FeedListItemWrapper">
				<Header item={ item } timezones={ timezones } />
				<div className="FeedListItem__text">
					{ item.text }
				</div>
				<FileList
					id={ item.id }
					showRemoveIcon={ false }
				/>
				{ !config.readOnly && !item.pending && (
					<ActionDropdown
						item={ item }
						{ ...action }
					/>
				) }
			</div>);
	}

	return (
		<div className="FeedListItemParent">
			<UserAvatar id={ item.author } />
			{ dom }
		</div>
	);
};

export default Item;

