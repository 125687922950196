import {kebabCase} from 'lodash';


/**
 * Takes an object and returns a style string
 * Example:
 * Given:
 * {
 *     backgroundColor: red,
 *     fontSize: 20px
 * }
 *
 * Returns:
 * "background-color: red; font-size: 20px"
 *
 * @param cssObject
 * @returns {string}
 */
export function convertObjectIntoInlineStylesString(cssObject) {
	return Object.keys(cssObject).map(key => `${ kebabCase(key) }: ${ cssObject[key] }`).join('; ');
}

export default {
	convertObjectIntoInlineStylesString
}
