import { Flex, Icon, IconV2, LayoutBox, StyledBox } from '@bamboohr/fabric';
import {
	Ambulance20x16,
	BadgePerson20x16,
	Bank20x19,
	Briefcase20x19,
	ChatBubbles20x20,
	Checkbook20x16,
	GovBuilding18x20,
	HeartAdd20x18,
	HiBubble24x24,
	Home20x20,
	LockPerson20x20,
	MobilePhone14x20,
	MoneyCircle20x20,
	MoneyShield17x20,
	PersonCircle20x20,
	PersonIndexCard20x16,
	StarOfLife18x20,
	SportsTimer14x16,
} from '@bamboohr/grim';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const iconMap = ifFeature(
	'encore',
	{
		address: <IconV2 color='primary-strong' name='house-solid' size={16} />,
		// benefits: <IconV2 color='primary-strong' name='' size={0} />,
		compensation: <IconV2 color='primary-strong' name='money-check-solid' size={16} />,
		contact: <IconV2 color='primary-strong' name='address-book-solid' size={16} />,
		default: <IconV2 color='primary-strong' name='id-card-solid' size={16} />,
		dependents: <IconV2 color='primary-strong' name='users-solid' size={16} />,
		education: <IconV2 color='primary-strong' name='book-solid' size={16} />,
		directDeposit: <IconV2 color='primary-strong' name='building-columns-solid' size={16} />,
		emergency:<IconV2 color='primary-strong' name='truck-medical-solid' size={20} />,
		emergencyContacts:<IconV2 color='primary-strong' name='truck-medical-solid' size={20} />,
		employmentStatus: <IconV2 color='primary-strong' name='id-card-solid' size={16} />,
		job: <IconV2 color='primary-strong' name='briefcase-solid' size={20} />,
		jobInformation: <IconV2 color='primary-strong' name='briefcase-solid' size={16} />,
		// payrollCheckbox: <IconV2 color='primary-strong' name='' size={0} />,
		// payrollInformation: <IconV2 color='primary-strong' name='' size={0} />,
		personal: <IconV2 color='primary-strong' name='id-card-solid' size={16} />,
		selfService: <IconV2 color='primary-strong' name='user-lock-solid' size={24} />,
		socialLinks: <IconV2 color='primary-strong' name='comments-solid' size={16} />,
		// stateUnemploymentInsurance: <IconV2 color='primary-strong' name='' size={0} />,
		// taxWithholding: <IconV2 color='primary-strong' name='' size={0} />,
		// vaccinationStatus: <IconV2 color='primary-strong' name='' size={0} />,
		projectPayRates: <IconV2 color='primary-strong' name='stopwatch-solid' size={16} />,
	},
	{
		address: <Home20x20 />,
		benefits: <HeartAdd20x18 />,
		compensation: <Checkbook20x16 />,
		contact: <MobilePhone14x20 />,
		default: <PersonIndexCard20x16 />,
		directDeposit: <Bank20x19 />,
		emergency: <Ambulance20x16 />,
		emergencyContacts: <Ambulance20x16 />,
		employmentStatus: <BadgePerson20x16 />,
		job: <Briefcase20x19 />,
		jobInformation: <Briefcase20x19 />,
		payrollCheckbox: <HiBubble24x24 />,
		payrollInformation: <MoneyCircle20x20 />,
		personal: <PersonCircle20x20 />,
		selfService: <LockPerson20x20 />,
		socialLinks: <ChatBubbles20x20 />,
		stateUnemploymentInsurance: <MoneyShield17x20 />,
		taxWithholding: <GovBuilding18x20 />,
		vaccinationStatus: <StarOfLife18x20 />,
		projectPayRates: <SportsTimer14x16 />,
	}
);

import './legend.styl';

const Legend: FunctionComponent<DynamicForm.LegendElementProps> = ({ settings, props }) => {
	const { label, icon, fabIcon } = settings;
	const { className = '' } = props;
	let legendLabel = label;

	if (icon === 'emergency' || icon === 'emergencyContacts') {
		 legendLabel = $.__("Emergency Contact");
	}

	if (!label) {
		console.error('Label is required for Legend component');
		return null;
	}

	// fabIcon takes precedence, then mapped icon, default: false
	const mapIcon = typeof icon === 'undefined' ? false : iconMap[icon] || iconMap.default;

	const legendIcon = typeof fabIcon === 'undefined' ? mapIcon : <Icon brand={true} name={fabIcon} />;

	const classes = classnames(
		{
			DynamicForm__Legend: true,
			'fab-FormSection__legend': !className.includes('fab-CheckboxGroup__legend'),
		},
		className
	);

	const biId = `quickform-form-section-for-${label?.toLowerCase().replace(' ', '-')}-legend`;

	return (
		<legend {...props} className={classes} data-bi-id={biId}>
			{ifFeature(
				'encore',
				!!mapIcon &&
					(icon === 'selfService' ? (
						<LayoutBox marginRight={'12px'}>
							<Flex alignItems={'center'}>{mapIcon}</Flex>
						</LayoutBox>
					) : (
						<StyledBox backgroundColor='neutral-medium' borderRadius='medium' marginRight={2} padding={1.5}>
							<Flex alignItems={'center'}>{mapIcon}</Flex>
						</StyledBox>
					)),
				!!legendIcon && <div className='DynamicForm__LegendIcon'>{legendIcon}</div>
			)}
			<div className='DynamicForm__LegendText'>{legendLabel}</div>
		</legend>
	);
};

export default Legend;
