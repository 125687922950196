import { render } from 'base/wrapped-render';
import AddEditEntry from './add-edit-entry.react';
import './styles.styl';


export default function (data) {
	return new Promise((resolve) => {
		let title = $.__('Time Worked');
		if (data.canEdit) {
			title = data.type === 'edit' ? $.__('Edit Time Worked') : $.__('Enter Time Worked');
		}
		const addEditEntryDiv = document.createElement('div');
		render(<AddEditEntry { ...data } afterAction={ resolve } modalTitle={ title } />, addEditEntryDiv);
	});
}
