import {
	Fragment,
	useMemo,
	useState,
} from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';

import { Menu } from '@fabric/menu';

import './controlled-currency-picker-menu.styl';

/**
 * This currency menu component requires a parent to manage the state of its input
 *
 * @param {string|null} props.name
 * @param {function} props.onCurrencyCodeSelect
 * @param {boolean|null} props.disabled
 * @param {array} props.currencyCollection
 * @param {string} props.value
 * @param {function} [props.onMenuOpen]
 * @param {function} [props.onMenuClose]
 * @param {function} [props.renderToggle]
 */
export function ControlledCurrencyPickerMenu(props) {
	const {
		currencyCollection,
		disabled,
		name,
		onCurrencyCodeSelect,
		value,
		onMenuOpen = noop,
		onMenuClose = noop,
		renderToggle: customRenderToggle,
	} = props;
	const [isCurrencyPickerOpen, setIsCurrencyPickerOpen] = useState(false);

	const handleButtonClick = () => {
		setIsCurrencyPickerOpen(true);
		if (onMenuOpen && typeof onMenuOpen === 'function') {
			onMenuOpen.call();
		}
	};

	const handleCloseClick = () => {
		setIsCurrencyPickerOpen(false);
		if (onMenuClose && typeof onMenuClose === 'function') {
			onMenuClose.call();
		}
	};

	const renderToggle = ({ isFocused }, buttonProps) => {
		if (customRenderToggle && typeof customRenderToggle === 'function') {
			return customRenderToggle({ isFocused }, buttonProps);
		}

		return (
			<button
				{...buttonProps}
				className={ classNames(
					'fab-TextInput__button fab-TextInput__button--text fab-TextInput__button--right',
					{ 'fab-TextInput__button--focused': isFocused }
				) }
				disabled={ disabled }
				tabIndex="-1"
				type="button"
			>
				{ value }
			</button>
		);
	};

	const mostCommonCurrencyCount = 4;
	const currencyMenuItems = useMemo(() => {
		if (!isCurrencyPickerOpen) {
			// This is an attempt to speed up slow Menu renders, but the problem should really be
			// addressed inside of the Menu component
			return [];
		}

		const menuItems = [
			{ items: [], type: 'group', key: 'most_common' },
			{ items: [], type: 'group', key: 'rest' },
		];
		currencyCollection.forEach((currencyItem, index) => {
			const menuItemIndex = index < mostCommonCurrencyCount ? 0 : 1;
			menuItems[menuItemIndex].items.push({
				key: currencyItem.code,
				text: currencyItem.text || `${ currencyItem.code } - ${ currencyItem.name }`,
				value: currencyItem.code,
				isDisabled: currencyItem.isDisabled || false,
				data: currencyItem });
		});

		return menuItems;
	}, [currencyCollection, isCurrencyPickerOpen]);

	return (
		<Fragment>
			<div className="ControlledCurrency__Menu--wrapper" data-currency-code={ value }>
				<Menu
					isDisabled={ disabled }
					isOpen={ isCurrencyPickerOpen }
					items={ currencyMenuItems }
					onClose={ handleCloseClick }
					onOpen={ handleButtonClick }
					onSelect={ onCurrencyCodeSelect }
					placement={
						{
							align: 'start',
							side: 'bottom',
						}
					}
					renderToggle={ renderToggle }
					showSearch="never"
				/>
			</div>
			{ name && (
				<input
					className="currencyType js-currency-picker-type"
					name={ name }
					type="hidden"
					value={ value }
				/>
			) }
		</Fragment>
	);
}
