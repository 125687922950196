export function formatStringFromNumber(number: undefined | number, decimalPointChar?: string, thousandsSeparatorChar?: string): string {
	decimalPointChar = decimalPointChar || window.GLOBAL_DECIMAL_CHAR || '.';
	thousandsSeparatorChar = thousandsSeparatorChar || window.GLOBAL_THOUSANDS_SEPARATOR || ',';

	if (decimalPointChar === thousandsSeparatorChar) {
		throw new Error('The decimal point and thousands separator characters must be different.');
	}

	if (number === undefined) {
		return '';
	}

	const numberString = number.toString();
	const [wholeNumber, decimals] = numberString.split('.');

	// format whole number part
	const wholeNumberParts = [];

	let remainingWholeNumber = wholeNumber;
	while (remainingWholeNumber.length) {
		const slice = remainingWholeNumber.slice(-3);
		wholeNumberParts.unshift(slice);
		remainingWholeNumber = remainingWholeNumber.slice(0, -slice.length);
	}

	const formattedWholeNumberPart =  wholeNumberParts.join(thousandsSeparatorChar);

	// format decimal part
	const formattedDecimalPart = decimals ? `${decimalPointChar}${decimals}` : '';
	
	const formattedString = `${formattedWholeNumberPart}${formattedDecimalPart}`;
	return formattedString;
}
