import Badge from '../';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '@bamboohr/fabric';

export default function CompanyBadge(props) {
	const {
		SESSION_COMPANY: {
			id: companyId,
			name: companyName,
		}
	} = window;

	return (
		<Badge
			/* @startCleanup encore */
			imageIcon={ifFeature('encore', undefined, "fab-company-circle-30x30")}
			/* @endCleanup encore */
			imageJSX={ifFeature('encore', <IconV2 color="neutral-extra-strong" name="building-regular" size={30} />)}
			isModalHeader={ false }
			subTitle={ $.__(`Company ID: %1$s`, companyId) }
			title={ companyName }
			{ ...props }
		/>
	);
}
