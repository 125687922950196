import { ifFeature } from "@bamboohr/utils/lib/feature";

export const ACCEPTED_SUCCESS_MESSAGE = $.__(`Got it! Thank you for accepting our Data Processing Agreement.`);

export const DECLINED_SUCCESS_MESSAGE = $.__(`Got it! Thank you for responding to our Data Processing Agreement question.`);

export const MODAL_HEADLINE_CONTENT = 
    ifFeature('encore', 
        $.__(`If you have employees in certain countries, you'll need to agree to something called a Data Processing Agreement.`),
        $.__(`If you have employees in certain countries,\n you'll need to agree to something called a Data\n Processing Agreement.`));

export const MODAL_TITLE = $.__('GDPR Compliance');

