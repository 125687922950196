import ajax from '@utils/ajax';
import {
	SocialIdInvalidModal,
} from 'SsnValidation.mod';
import {
	currentEditingEmployeeId,
	validateWithBE,
} from 'dynamic-form';
import { RATE_LIMIT_ERROR } from './common';

// so the modal doesn't show or BE request made every time validation is run
const overriddenValueSocialId = {};

/**
 * Validates NIN, SIN, National Id
 *
 * @param context
 * @param field
 */
export const validateSocialId = (context: DynamicForm.Context, field: DynamicForm.Data, params: any) => {
	const { action, check_field, friendly_name, message } = params;
	const field_name = (friendly_name || check_field).toUpperCase();

	return async (value): Promise<boolean | string> => {
		if (!value || value === field?.settings?.initialValue) {
			// don't make a BE request - field isn't filled in yet, let required or regex run
			// don't make a BE request if it matches the initial value
			return true;
		}
		if (overriddenValueSocialId?.[check_field]?.value === value) {
			// We already asked and they told us whether it should be valid
			return overriddenValueSocialId?.[check_field].valid;
		}
		const data = {
			id: currentEditingEmployeeId(),
			check_field,
			action,
			[check_field]: value,
		};

		// eslint-disable-next-line prefer-const
		let { isValid, isRateLimited } = await validateWithBE(ajax.post, data);
		if (isRateLimited) { return RATE_LIMIT_ERROR; }

		if (!isValid) {
			let resolve;
			const modalPromise = new Promise<boolean>((res, rej) => {
				resolve = res;
			});

			const msg = $.__('There is already an employee with this %1 in the system. Are you sure you want to create another one?', field_name);
			const title = $.__('Duplicate %1', field_name);
			SocialIdInvalidModal(title, msg, resolve);
			isValid = await modalPromise;
		}

		// So we don't ask the user again when they told us to make it valid or invalid
		// good values override the previous so the user can change their mind
		overriddenValueSocialId[check_field] = {
			value,
			valid: isValid,
		};

		return isValid || message;
	};
};
