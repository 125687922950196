import { Checkbox } from './checkbox';
import { Date } from './date';
import { FileUpload } from './file-upload';
import { SelectInput as Select } from './select';
import { Text } from './text';
import { TextArea } from './textArea';
import { YesNo } from './yes-no';
import { Multi } from './multi/';

const Types = {
	TYPE_CHECKBOX: Checkbox,
	TYPE_DATE: Date,
	TYPE_FILE_UPLOAD: FileUpload,
	TYPE_SELECT: Select,
	TYPE_TEXT: Text,
	TYPE_TEXTAREA: TextArea,
	TYPE_YES_NO: YesNo,
	TYPE_MULTIPLE_CHOICE: Multi,
};

export default Types;
