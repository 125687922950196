import './styles.styl';

$(function () {
	const $editForm = $('form#edit_form.js-email-alerts');

	if ($editForm.length) {
		window.filterSettings = {
			postFunc: window.setHiddenVar,
			listOnly: true,
			updateLink: false,
		};

		const input_list = $('.input-list').autoInputList({
			autocompleteUrl: '/ajax/quicksearch.php',
			description: $.__('Name, Department, Location, or Email...'),
			extraParams: {
				l: 1,
				active: 1,
			},
			formatAutoItem([label, value, classes]) {
				return `
					<span class="qs_status icon ${classes}"></span>
					<span class="qs_status ${classes}">
						${label}
					</span>
				`;
			},
			startingItems: window.startingItems,
			formatTagItem(event, data) {
				if (data) {
					if (typeof data === 'string') {
						data = data.split('|');
					}

					const [label, value, classes] = data;

					$(`
						<span class="item">
							${label}
							<input type="hidden" name="ids[]" value="${value}" />
							<ba-icon class="remove" name="fab-x-8x8" encore-name="xmark-regular" encore-size="10" encore-class="fab-marginLeft--4 remove"></ba-icon>
						</span>
					`).insertBefore('.input-list span.input');

					$('.free').val('');
					$('#input-list-check').prop('checked', true);
					$(document).on('click', '.item .remove', function () {
						$(this).closest('span.item').remove();
						return false;
					});
				}
			},
		});

		if ($('#input-list-check').is(':checked')) {
			input_list.activate();
		}

		$('#input-list-check').change(function () {
			const check = $(this);
			if (check.is(':checked')) {
				input_list.activate();
			} else {
				input_list.deactivate();
			}
		});

		const $timepicker = $('.timepicker');
		$timepicker.timepicker({ step: 60, useSelect: true });
		$timepicker.on('timeFormatError', function () {
			setMessage($.__('Please enter a valid time of day, of the form 12:00am, or select a time from the dropdown.'), 'error');
			$(this).val('12:00am');
		});

		$('#edit_form input:first').focus();
		window.updateFilter();
		$(document).on('click', 'a.bicon-remove', function () {
			$(this).parents('.reminder-row:first').remove();
		});

		window.addReminder = function () {
			$('.reminder-row:first')
				.clone()
				.show()
				.find('.chzn-container')
				.remove()
				.end()
				.append('<a class="bicon-remove" title="remove" href="javascript:void(0)"></a>')
				.find('input')
				.val('')
				.end()
				.find('select')
				.each(function (i, it) {
					it.selectedIndex = 0;
				})
				.end()
				.insertAfter('.reminder-row:last')
				.find('select')
				.removeClass('chzn-done')
				.removeAttr('id')
				.show()
				.chosen(bhrChosenOptions);
		};
	}
});
