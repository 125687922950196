import { Component, createRef } from 'react';

import { Select } from '@fabric/select';
import CalendarPicker from 'calendar-picker.react';
import {
	formatTime,
	getTimeDuration,
	padZerosOnTime,
	timeOfDayInputEnforcer,
	getTwentyFourHourTime,
	getMeridiem
} from 'time-tracking/utils';

import './styles.styl';

export default class ChooseDate extends Component {
	constructor(props) {
		super(props);

		const suggestedClockOutTime = moment().tz(props.lastClockEntry.timezone);
		const clockOutDate = suggestedClockOutTime.format('YYYY-MM-DD');
		const clockOutMeridiem = getMeridiem(suggestedClockOutTime);
		const clockOutTime = suggestedClockOutTime.format('h:mm');
		const totalTime = this.calculateTotalTime(clockOutDate, clockOutMeridiem, clockOutTime);

		this.state = {
			clockOutDate: clockOutDate,
			clockOutMeridiem: clockOutMeridiem,
			clockOutTime: clockOutTime,
			totalTime: totalTime,
		};

		this.timeInputRef = createRef();

		props.onDateChange(suggestedClockOutTime, totalTime);
	}

	_handleDateChange = (date) => {
		const { clockOutMeridiem, clockOutTime } = this.state;
		const { lastClockEntry } = this.props;
		const lastClockDate = this.getLastCLockDate(lastClockEntry);
		const selectedDateTime = this.getSelectedDateTime(date, clockOutMeridiem, clockOutTime);
		const selectedIsBeforeClockIn = selectedDateTime.isBefore(lastClockDate);

		let newMeridiem = clockOutMeridiem;
		if (selectedIsBeforeClockIn) {
			newMeridiem = 'PM';
		}

		this.setState({
			clockOutDate: date,
			clockOutMeridiem: newMeridiem
		});

		this.setTotalTime();
	}

	_handleOnInput = (event) => {
		const { clockOutDate, clockOutMeridiem, clockOutTime } = this.state;
		const { lastClockEntry } = this.props;
		const value = event.target.value;
		const lastClockDate = this.getLastCLockDate(lastClockEntry);
		const selectedDateTime = this.getSelectedDateTime(clockOutDate, clockOutMeridiem, padZerosOnTime(value));
		const selectedIsBeforeClockIn = selectedDateTime.isBefore(lastClockDate);
		this.timeInputRef.current.value = timeOfDayInputEnforcer(value, clockOutTime);

		let newMeridiem = clockOutMeridiem;
		if (selectedIsBeforeClockIn) {
			newMeridiem = 'PM';
		}

		this.setState({
			clockOutTime: this.timeInputRef.current.value,
			clockOutMeridiem: newMeridiem
		});

		this.setTotalTime();
	}

	calculateTotalTime = (clockOutDate, clockOutMeridiem, clockOutTime) => {
		const { lastClockEntry } = this.props;

		const selectedDateTime = this.getSelectedDateTime(clockOutDate, clockOutMeridiem, clockOutTime);
		const lastClockDate = this.getLastCLockDate(lastClockEntry);

		return getTimeDuration(lastClockDate, selectedDateTime, 'hours');
	}

	setTotalTime = () => {
		const { onDateChange } = this.props;

		this.setState((state) => {
			const { clockOutDate, clockOutMeridiem, clockOutTime } = state;
			const totalTime = this.calculateTotalTime(clockOutDate, clockOutMeridiem, clockOutTime);
			let selectedDateTime = this.getSelectedDateTime(clockOutDate, clockOutMeridiem, clockOutTime);

			if (totalTime < 0 || this.timeInputRef.current.value === '') {
				selectedDateTime._isValid = false;
			}

			onDateChange(selectedDateTime, totalTime);

			return {
				totalTime: totalTime
			};
		});
	}

	getLastCLockDate = (lastClockEntry) => {
		return moment.tz(lastClockEntry.start, lastClockEntry.timezone);
	}

	getSelectedDateTime = (clockOutDate, clockOutMeridiem, clockOutTime) => {
		const { lastClockEntry } = this.props;
		const format = 'YYYY-MM-DD h:mm A';
		return moment.tz(`${ clockOutDate } ${ getTwentyFourHourTime(clockOutTime, clockOutMeridiem) }`, format, lastClockEntry.timezone);
	}

	render() {
		const { clockOutDate, clockOutMeridiem, clockOutTime, totalTime } = this.state;

		const { lastClockEntry, showTotal } = this.props;

		const lastClockDate = this.getLastCLockDate(lastClockEntry);
		const clockInDate = lastClockDate.format('YYYY-MM-DD');
		const clockInMeridiem = getMeridiem(lastClockDate);
		const clockInTime = lastClockDate.format('h:mm');

		const clockOutPickerSettings = {
			min: clockInDate,
			max: moment.tz(moment(), lastClockEntry.timezone).format('YYYY-MM-DD'),
			start: clockOutDate
		};

		const selectProps = {
			isClearable: false,
			items: [
				{
					text: 'AM',
					value: 'AM'
				},
				{
					text: 'PM',
					value: 'PM'
				}
			],
			selectedValues: [
				clockOutMeridiem
			],
			onSelect: (value) => {
				const { clockOutDate, clockOutTime } = this.state;
				const { lastClockEntry } = this.props;
				const lastClockDate = this.getLastCLockDate(lastClockEntry);
				const selectedDateTime = this.getSelectedDateTime(clockOutDate, event.value, clockOutTime);
				const selectedIsBeforeClockIn = selectedDateTime.isBefore(lastClockDate);

				let newMeridiem = value;
				if (selectedIsBeforeClockIn && clockOutTime !== '') {
					newMeridiem = 'PM';
				}

				this.setState({
					clockOutMeridiem: newMeridiem
				});

				this.setTotalTime();
			}
		};

		return (
			<div className="ChooseDate fab-FormRow">
				<div>
					<label className="fab-Label" htmlFor="clockInDate">{ $.__('Clocked in') }</label>
					<div className="fab-InputWrapper">
						<input className="ChooseDate__clockInDate fab-TextInput fab-TextInput--width4" disabled={ true } type="text" value={ lastClockDate.format() } />
						<input
							className="ChooseDate__clockInTime fab-TextInput fab-TextInput--width3"
							defaultValue={ `${ clockInTime } ${ clockInMeridiem }` }
							disabled={ true }
							placeholder="hh:mm"
							type="text"
						/>
					</div>
				</div>
				<span className="ChooseDate__formDash">–</span>
				<div>
					<label className="fab-Label" htmlFor="clockOutDate">{ $.__('Clocked out at...') }</label>
					<div className="fab-InputWrapper">
						<CalendarPicker
							cssClasses={ {single: 'fab-TextInput--width5'} }
							name="clockOutDate"
							onChange={ this._handleDateChange }
							required={ true }
							settings={ clockOutPickerSettings }
							type="date"
						/>
						<input
							className="ChooseDate__timeInput fab-TextInput fab-TextInput--width2"
							defaultValue={ clockOutTime }
							onInput={ this._handleOnInput }
							placeholder="hh:mm"
							ref={ this.timeInputRef }
							required={ true }
							type="text"
						/>
						<Select { ...selectProps } />
					</div>
				</div>
				{ showTotal && (
					<div className="ChooseDate__totalTimeContainer">
						<p className="ChooseDate__totalTime">{ formatTime(totalTime) }</p>
					</div>
				) }
			</div>
		);
	}
}
