import './styles.styl';

export const banner = {
	name: 'banner',
	caret: `
			<svg width="37" height="17"
					viewBox="0 0 37 17" xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink">
				<defs>
					<path d="M1 2h35L19.565 14.637c-.623.48-1.578.478-2.198 0" id="BannerArrow"/>
					<filter y="-19.2%" height="153.9%" filterUnits="objectBoundingBox" id="BannerArrowShadow">
						<feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
						<feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
						<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" in="shadowBlurOuter1"/>
					</filter>
				</defs>
				<use filter="url(#BannerArrowShadow)" xlink:href="#BannerArrow"/>
				<use xlink:href="#BannerArrow"/>
				<path d="M17.978 13.845c.26.201.714.202.976 0L37 0H0l17.978 13.845z" fill="#FFF"/>
			</svg>
		`,
	extraClasses: {
		tab: ({active}) => ({
			baseBorderColor: active,
		}),
		tabIcon: ({active}) => ({
			baseFillColor: active,
		}),
		tabCaret: ({active}) => ({
			baseFillColor: active,
		}),
	},
};
