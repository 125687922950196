import { Modal } from 'modal-legacy';

export default function ClearNoteWarningModal(props) {
	const { getChangedEntries, isOpen, isProcessing, onClose, primaryAction } = props;

	const changedEntriesCount = getChangedEntries().length;
	const header = changedEntriesCount === 1 ?
		$.__('This time entry has a note associated with it. You will lose the note if you continue.') :
		$.__('You’re clearing out at least one time entry with a note. You will lose the note(s) associated with these entries if you continue.');

	return (
		<Modal
			header={ header }
			icon="fab-trash-can-43x48"
			iconColor="danger"
			isOpen={ isOpen }
			isProcessing={ isProcessing }
			onClose={ onClose }
			primaryAction={ primaryAction }
			primaryActionText={ changedEntriesCount === 1 ? $.__('Clear Time Entry + Note') : $.__('Clear Time Entries + Notes') }
			title={ $.__('Just Checking...') }
		/>
	);
}
