/**
 * Rectangles!
 */
interface Rectangle {
	height?: number;
	width?: number;
	left?: number;
	right?: number;
	top?: number;
	bottom?: number;
	[key: string]: any;
}

export default class Rect {
	/**
	 * Do two rectangles intersect?
	 *
	 * @param ClientRect a
	 * @param ClientRect b
	 *
	 * @return bool
	 */
	static intersects(a, b): boolean {
		return (a.left <= b.left + b.width && b.left <= a.left + a.width &&
				a.top <= b.top + b.height && b.top <= a.top + a.height);
	}

	/**
	 * Does rect a fully contain rect b?
	 *
	 * @param ClientRect a
	 * @param ClientRect b
	 *
	 * @return bool
	 */
	static contains(a, b): boolean {
		return a.left <= b.left &&
			a.left + a.width >= b.left + b.width &&
			a.top <= b.top &&
			a.top + a.height >= b.top + b.height;
	}

	/**
	 * Distance between a point and the closest point in a rectangle
	 *
	 * @param Rect  rect  le rectangle
	 * @param Point point le point
	 */
	static distance(rect: Rectangle, point): number {
		const dx = point.x < rect.left ? rect.left - point.x : Math.max(point.x - (rect.left + rect.width), 0);
		const dy = point.y < rect.top ? rect.top - point.y : Math.max(point.y - (rect.top + rect.height), 0);
		return Math.sqrt(dx * dx + dy * dy);
	}

	/**
	 * grow a rectangle from its center
	 *
	 * @param rect|partial rect object
	 * @param px pixels to grow from center
	 * @return rect
	 */
	static grow(rect: Rectangle, px: number): Rectangle {
		const newRect: Rectangle = {};
		const half = px / 2;
		if ('height' in rect) {
			newRect.height = rect.height + px;
		}
		if ('width' in rect) {
			newRect.width = rect.width + px;
		}
		if ('left' in rect) {
			newRect.left = rect.left - half;
		}
		if ('right' in rect) {
			newRect.right = rect.right + half;
		}
		if ('top' in rect) {
			newRect.top = rect.top - half;
		}
		if ('bottom' in rect) {
			newRect.bottom = rect.bottom + half;
		}
		return newRect;
	}
}
