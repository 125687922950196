import React, {
	Component,
	Fragment,
} from 'react';
import {
	BEM,
} from '@utils/dom';
import {splitOnCondition} from 'Array.util';
import * as FeatureToggle from 'FeatureToggle.util';
import {
	camelCase,
	clamp,
	debounce,
	isEmpty,
	isFunction,
	isPlainObject,
	isString,
	isUndefined,
	trimStart,
} from 'lodash';

import Tab from './tab';
import { TabDropdown } from './tab-dropdown';
import { Dropdown } from '@fabric/dropdown';

import './styles.styl';
import * as themes from './themes';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const _tabs: WeakMap = new WeakMap();
const _overflowOnlyTabs: WeakMap = new WeakMap();
const _theme: WeakMap = new WeakMap();
const _bem: WeakMap = new WeakMap();
const _path: WeakMap = new WeakMap();
const _extraClasses: WeakMap = new WeakMap();
const _overflowTab: WeakMap = new WeakMap();
const _tabWrapper: WeakMap = new WeakMap();
const _tabWrapperWidth: WeakMap = new WeakMap();
const _tabGroups = new WeakMap();

/**
 * BaTabs
 */
export default class BaTabs extends Component {

	/**
	 * The current BaTabsTheme instance
	 */
	get Theme(): themes.BaTabsTheme {
		return _theme.get(this);
	}

	/**
	 * Sets the current Theme to a new BaTabsTheme instance
	 * @param theme
	 */
	set theme(theme: string): void {
		if (this.Theme) {
			return;
		}

		if (theme && !isString(theme)) {
			throw new Error(`Invalid theme (${ theme })`);
		}

		let themeVariants;
		[theme, ...themeVariants] = (theme || themes.DEFAULT).split(':');

		theme = themes[camelCase(theme)] || themes[themes.DEFAULT];

		const Theme = new themes.BaTabsTheme(theme);

		_theme.set(this, Theme);

		const bem = new BEM(
			[
				this.constructor.className,
				...(this.props.id ? [this.props.id] : []),
			],
			[
				`${ this.Theme.name }-theme`,
				() => ({ loading: this.loading }),
				...themeVariants
					.filter(variant => Theme.variants.includes(variant))
					.map(variant => `${ this.Theme.name }-theme_${ variant }`),
			],
			[
				...(FeatureToggle.isEnabled('jade') ? [
					{
						tab: ({ active, disabled, loading}) => ({
							baseColor: active,
							baseColorHover: !(active || disabled || loading),
							baseFillColor: active,
							baseFillColorHover: !(active || disabled || loading),
						}),
					},
				] : []),
				Theme.extraClasses,
				this.extraClasses,
			],
		);

		_bem.set(this, bem);
	}

	/**
	 * The array of tabs
	 */
	get tabs(): Array<Tab> {
		return _tabs.get(this);
	}

	/**
	 * The array of tabs
	 */
	get overflowOnlyTabs(): Array<Tab> {
		return _overflowOnlyTabs.get(this);
	}

	/**
	 * The number of tabs
	 */
	get tabLength(): number {
		return this.tabs.length;
	}

	/**
	 * Sets the tabs.
	 * Can only be set ONCE.
	 * @param tabs
	 */
	set tabs(tabs: Array<Object>): void {
		if (this.tabs) {
			return;
		}

		if (!Array.isArray(tabs)) {
			throw new Error('"tabs" prop is required for BaTabs');
		}

		const [overflowOnlyTabs, standardTabs] = splitOnCondition(tabs, t => t.showOnlyInOverflow);
		let count = 0;
		_tabs.set(this, standardTabs.map(tab => new Tab(tab, count++, this)));
		_overflowOnlyTabs.set(this, overflowOnlyTabs.map(tab => new Tab(tab, count++, this)));
	}

	/**
	 * Sets any extraClasses passed in via props
	 */
	set extraClasses(extraClasses: Object) {
		_extraClasses.set(this, extraClasses);
	}

	/**
	 * gets any extraClasses passed in via props
	 */
	get extraClasses() {
		return _extraClasses.get(this) || {}
	}

	get hasPath() {
		return !!(
			this.path.pathname ||
			this.tabs.map(tab => tab.hasPath).includes(true)
		);
	}

	get path() {
		const {
			pathname = null,
			query = null,
			sync,
		} = _path.get(this) || {};

		return {
			pathname,
			query,
			sync,
		};
	}

	/* @startCleanup encore */
	/**
	 * The currently visible tabs
	 * (a subset of this.tabs if using collapseTabs)
	 */
	get visibleTabs(): Array<Tab> {
		return this.tabs.slice(0, this.state.overflowIndex);
	}

	/**
	 * The tabs to be collapsed into the
	 * overflow dropdown if using collapseTabs
	 */
	get overflowTabs(): Array<Tab> {
		const {overflowIndex} = this.state;
		return this.tabs
			.slice(overflowIndex)
			.concat(_overflowOnlyTabs.get(this) || []);
	}

	/**
	 * Whether or not the overflow dropdown
	 * should be visible
	 */
	get hasOverflowTabs(): boolean {
		return this.state.overflowIndex >= 0;
	}
	/* @endCleanup encore */

	/**
	 * @param {Element} elem
	 */
	set tabWrapper(elem) {
		if (
			elem instanceof Element &&
			elem !== this.tabWrapper
		) {
			_tabWrapper.set(this, elem);
			this._recalcOverflowIndex();
		}
	}

	/**
	 * @type {Element}
	 */
	get tabWrapper() {
		return _tabWrapper.get(this);
	}

	
	/* @startCleanup encore */
	/**
	 * The calculated width of the tabWrapper
	 *
	 * @type {number}
	 */
	get _tabWrapperWidth() {
		const tabWrapper = _tabWrapper.get(this);

		if (
			!tabWrapper ||
			!tabWrapper.offsetParent
		) {
			return 0;
		}

		const { width } = window.getComputedStyle(tabWrapper);

		return parseInt(width || '0');
	}

	/**
	 * The cached width of the tabWrapper
	 *
	 * @type {number}
	 */
	get tabWrapperWidth() {
		return _tabWrapperWidth.get(this);
	}

	/**
	 * Sets the cached width of the tabWrapper,
	 * recalculates overflowIndex, and sets the
	 * state with the new values.
	 *
	 * @param {number} wrapperWidth
	 */
	set tabWrapperWidth(wrapperWidth) {
		if (
			!wrapperWidth ||
			wrapperWidth === this.tabWrapperWidth ||
			ifFeature('encore', true)
		) {
			return;
		}

		_tabWrapperWidth.set(this, wrapperWidth);

		const { overflowIndex } = this;

		if (
			overflowIndex === this.state.overflowIndex &&
			this.state.hasInitCollapseTabs
		) {
			return;
		}

		this.setState({
			overflowIndex,
			hasInitCollapseTabs: true,
		});
	}

	/**
	 * The lowest width allowed before tabs begin
	 * to overflow when using collapseTabs
	 *
	 * @type {number}
	 */
	get tabWrapperMinWidth() {
		return parseInt(this.tabs
			.reduce((minWidth, { width }) => minWidth + width, 0));
	}

	/**
	 * @type {Element}
	 */
	get overflowTab() {
		return _overflowTab.get(this);
	}

	/**
	 * @param {Element} elem
	 */
	set overflowTab(elem) {
		if (
			elem instanceof Element &&
			elem !== this.overflowTab
		) {
			_overflowTab.set(this, elem);
			this._recalcOverflowIndex();
		}
	}

	/**
	 * The width of the overflow dropdown
	 *
	 * @type {number}
	 */
	get overflowTabWidth() {
		const overflowTab = _overflowTab.get(this);

		if (
			!overflowTab ||
			!overflowTab.offsetParent
		) {
			return 0;
		}

		return parseInt(overflowTab.getBoundingClientRect().width);
	}
	/* @endCleanup encore */

	/**
	 * Whether or not collapseTabs should be used
	 */
	get collapseTabs(): boolean {
		return !!(!this.hasGroups && this.props.collapseTabs || this.Theme.collapseTabs);
	}

	/* @startCleanup encore */
	/**
	 * The index of the first tab in this.tabs
	 * which should be put into the overflow
	 * dropdown
	 *
	 * Returns undefined if no tabs should overflow
	 */
	get overflowIndex(): number | void {
		if (ifFeature('encore', true)) {
			return;
		}

		const wrapperWidth = this._tabWrapperWidth;

		let currentWidth = 0;
		let overflowIndex;

		if (wrapperWidth < this.tabWrapperMinWidth) {
			currentWidth += this.overflowTabWidth;

			this.tabs.some(({width}, i) => {
				currentWidth += width;
				overflowIndex = i;

				return currentWidth > wrapperWidth;
			});
		}

		return overflowIndex;
	}
	/* @endCleanup encore */

	/**
	 * The text to be displayed in the
	 * overflow dropdown
	 */
	get overflowText(): string {
		return this.props.overflowText || this.Theme.overflowText;
	}

	/**
	 * The first tab in this.tabs
	 */
	get firstTab(): Tab {
		return this.tabs[0];
	}

	/**
	 * Whether or not the active tab
	 * is the first tab
	 */
	get isFirstTab(): boolean {
		return this.activeTab === this.firstTab;
	}

	/**
	 * The index of the last tab in
	 * this.tabs
	 */
	get lastTabIndex(): number {
		return this.tabs.length - 1;
	}

	/**
	 * The last tab in this.tabs
	 */
	get lastTab(): Tab {
		return this.tabs[this.lastTabIndex];
	}

	/**
	 * Whether or not the active tab
	 * is the last tab
	 */
	get isLastTab(): boolean {
		return this.activeTab === this.lastTab;
	}

	/**
	 * The index of the tab previous
	 * to the active tab
	 */
	get prevTabIndex(): number {
		return clamp(this.activeTabIndex - 1, 0, this.lastTabIndex);
	}

	/**
	 * The tab previous to the active tab
	 *
	 * Or the current tab, if the first tab
	 * is active
	 */
	get prevTab(): Tab {
		return this.tabs[this.prevTabIndex];
	}

	/**
	 * The index of the next tab following
	 * the active tab
	 */
	get nextTabIndex(): number {
		return clamp(this.activeTabIndex + 1, 0, this.lastTabIndex);
	}

	/**
	 * The next tab following the active
	 * tab
	 *
	 * Or the current tab, if the last tab
	 * is active
	 */
	get nextTab(): Tab {
		return this.tabs[this.nextTabIndex];
	}

	/**
	 * Whether or not the "Loading" state
	 * currently active
	 */
	get loading(): boolean {
		return this.state.loading;
	}

	/**
	 * The loading strategy to be used for
	 * resolving and rendering tabs
	 */
	get load(): string {
		return this.props.load || this.Theme.load;
	}

	/**
	 * The currently active tab
	 * @type {Tab}
	 */
	get activeTab() {
		return this.state.activeTab;
	}

	/**
	 * Sets the current tab
	 *
	 * Can be:
	 * * The tab's `key`
	 * * The tab's `number` (starts at 1)
	 * * The `Tab` itself
	 * * One of:
	 *  * `'next'`
	 *  * `'prev'`
	 *  * `'first'`
	 *  * `'last'`
	 *
	 * @param {'next' | 'prev' | 'first' | 'last' | string | number | Tab} activeTab
	 */
	set activeTab(activeTab) {
		if (!isNaN(activeTab)) {
			const index = parseInt(activeTab) - 1;
			activeTab = this.tabs[clamp(index, 0, this.lastTabIndex)];
		} else if (isString(activeTab)) {
			activeTab = this[`${ activeTab }Tab`] || this.tabs.find(({key} = {}) => key === activeTab);
		}

		if (!(activeTab instanceof Tab)) {
			throw new Error(`Invalid value for activeTab (${ activeTab })`);
		}

		if (activeTab.data && typeof activeTab.data.tabType === 'string' && activeTab.data.tabType === 'reorder') {
			// Override tab being sent in and use the current activeTab
			activeTab = this.activeTab;
		}

		if (activeTab === this.activeTab) {
			return;
		}

		if (this.hasPath && this.path.sync.up) {
			const {
				pathname,
			} = activeTab;

			if (pathname) {
				window.history.pushState({}, null, pathname);
				return;
			}
		}

		this.setState({
			activeTab,
			loading: activeTab.shouldResolveContent,
		}, this._resolveTabs);
	}

	/**
	 * The index of the active tab
	 */
	get activeTabIndex(): number {
		return this.tabs.indexOf(this.activeTab);
	}

	/**
	 * The number of the active tab
	 * (starts at 1)
	 */
	get activeTabNumber(): number {
		return this.activeTabIndex + 1;
	}

	get hasGroups(): boolean {
		const {
			groups,
		} = this;

		return (
			Array.isArray(groups) &&
			groups.length > 0
		);
	}

	get groups(): Array<Object> | boolean {
		return _tabGroups.get(this);
	}

	set groups(tabGroups) {
		if (Array.isArray(_tabGroups.get(this))) {
			return;
		}

		if (isPlainObject(tabGroups)) {
			Object.keys(tabGroups)
				.forEach((name) => {
					let group = tabGroups[name];

					if (!isPlainObject(group)) {
						group = {name};
					} else {
						group.name = name;
					}

					tabGroups[name] = group;
				});
		}

		if (!Array.isArray(tabGroups)) {
			tabGroups = [...this.tabs.reduce((groups, {group}) => {
				if (!isUndefined(group)) {
					groups.add(group);
				}

				return groups;
			}, new Set())]
				.sort();
		}

		tabGroups = tabGroups.map((group) => {
			if (!isPlainObject(group)) {
				group = {
					name: group,
				};
			}

			return group;
		});

		_tabGroups.set(this, tabGroups);
	}

	get _subcomponents() {
		return Object.keys(themes.subcomponents)
			.reduce((comps, name) => ({
				...comps,
				[name]: (
					this[name] ||
					this.props[name] ||
					this.Theme[name]
				),
			}), {});
	}

	/**
	 * @constructor
	 * @param props
	 */
	constructor(props: Object): void {
		super(props);

		const {
			tabs,
			activeTab,
			theme,
			extraClasses,
			tabGroups,
		} = props;
		let {
			path,
			pathSync,
		} = props;

		let pathname, query;

		if (isString(path)) {
			([pathname, ...query] = path.split('?'));
			pathname = `/${  trimStart(pathname, '/')}`;
			query = query.join('?') || null;

			path = {
				pathname,
				query,
			};
		}

		if (isPlainObject(path)) {
			pathSync = path.sync || pathSync;

			if (isString(pathSync)) {
				pathSync = pathSync
					.toLowerCase()
					.replace(/\s+/g, '')
					.split(/[^a-z!]+/g);
			}

			if (
				!Array.isArray(pathSync) ||
				isEmpty(pathSync)
			) {
				pathSync = ['all'];
			}

			path.sync = {
				get all() {
					return (
						this.load &&
						this.up &&
						this.down
					);
				},
				get none() {
					return (
						!this.load &&
						!this.up &&
						!this.down
					);
				},
				load: (
					!pathSync.includes('none') &&
					!pathSync.includes('!all') &&
					!pathSync.includes('!load') &&
					pathSync.includes('all') ||
					pathSync.includes('load')
				),
				up: (
					!pathSync.includes('none') &&
					!pathSync.includes('!all') &&
					!pathSync.includes('!up') &&
					pathSync.includes('all') ||
					pathSync.includes('up')
				),
				down: (
					!pathSync.includes('none') &&
					!pathSync.includes('!all') &&
					!pathSync.includes('!down') &&
					pathSync.includes('all') ||
					pathSync.includes('down')
				),
			};

			_path.set(this, path);
		}

		this.tabs = tabs;

		this.groups = tabGroups;

		if (extraClasses) {
			this.extraClasses = extraClasses;
		}

		if (this.hasPath && this.path.sync.down) {
			window.addEventListener('history:load', debounce(this._onHistoryChange, 100));
			window.addEventListener('history:change', debounce(this._onHistoryChange, 100));
		}

		this.state = {
			activeTab,
			hasInitCollapseTabs: false,
		};

		this.theme = theme;

	}

	/**
	 * Internal React method
	 */
	componentDidMount(): void {
		document.addEventListener('click', this._onNavBtnClick);

		if (this.hasPath && this.path.sync.load && this.path.sync.down) {
			this.activeTab = this.state.activeTab || this._findTabFromPath() || this.firstTab;
		} else {
			this.activeTab = this.state.activeTab || this.firstTab;
		}

		this._initCollapseTabs();
	}

	componentWillUnmount(): void {
		window.removeEventListener('history:load', this._onHistoryChange);
		window.removeEventListener('history:change', this._onHistoryChange);
	}

	/**
	 * Internal React method
	 */
	shouldComponentUpdate(props: Object, state: Object): boolean {
		return (
			state.activeTab !== this.state.activeTab ||
			state.overflowIndex !== this.state.overflowIndex ||
			state.hasInitCollapseTabs !== this.state.hasInitCollapseTabs ||
			state.loading !== this.state.loading ||
			state.loaded !== this.state.loaded
		);
	}

	/**
	 * Internal React method
	 */
	render(): React.Node {
		return (
			<div className={this._createBEM(null)}>
				{this._renderTabs()}
				{this._renderBody()}
			</div>
		);
	}

	next = () => {
		this.activeTab = 'next';
		return this;
	}

	prev = () => {
		this.activeTab = 'prev';
		return this;
	}

	/**
	 * Renders the tabWrapper with all of the
	 * tabs for navigation
	 */
	_renderTabs = (): React.Node => {
		const _this = this;
		/* @startCleanup encore */
		const {
			hasInitCollapseTabs,
		} = this.state;
		const hidden = this.collapseTabs && !hasInitCollapseTabs;
		/* @endCleanup encore */
		const {
			_createBEM,
			hasGroups,
			groups,
			_subcomponents,
		} = this;
		const {
			title,
			icon,
		} = this.props;
		const {
			TabWrapper,
			TabWrapperHeader,
			TabWrapperHeaderActions,
			TabWrapperFooter,
			TabWrapperFooterActions,
			TabGroup,
			TabGroupLabel,
			TabGroupIcon,
			TabGroupHeader,
			TabGroupFooter,
			Tab,
		} = _subcomponents;

		const TabWrapperComp = p => (
			<div
				className={ _createBEM('tabWrapper', 
					/* @startCleanup encore */
					ifFeature('encore', undefined, { hidden })
					/* @endCleanup encore */
				) }
				ref={ (elem) => { this.tabWrapper = elem; } }
			>
				{ (TabWrapperHeader || TabWrapperHeaderActions) && (
					<div className={ _createBEM('tabWrapperHeader') }>
						{ TabWrapperHeader && (
							<TabWrapperHeader { ...p }>{ null }</TabWrapperHeader>
						) }
						{ TabWrapperHeaderActions && (
							<div className={ _createBEM('tabWrapperHeaderActions') }>
								<TabWrapperHeaderActions { ...p }>{ null }</TabWrapperHeaderActions>
							</div>
						) }
					</div>
				) }
				<TabWrapper { ...p } />
				{ (TabWrapperFooter || TabWrapperFooterActions) && (
					<div className={ _createBEM('tabWrapperFooter') }>
						{ TabWrapperFooter && (
							<TabWrapperFooter { ...p }>{ null }</TabWrapperFooter>
						) }
						{ TabWrapperFooterActions && (
							<div className={ _createBEM('tabWrapperFooterActions') }>
								<TabWrapperFooterActions { ...p }>{ null }</TabWrapperFooterActions>
							</div>
						) }
					</div>
				) }
			</div>
		);

		const TabGroupComp = p => (
			<div className={ _createBEM('tabGroup' ,[name, { empty: p.tabs.length < 1 }]) }>
				{ TabGroupHeader && (
					<div className={ _createBEM('tabGroupHeader') }>
						<TabGroupHeader { ...p } />
					</div>
				) }
				<TabGroup { ...p } />
				{ TabGroupFooter && (
					<div className={ _createBEM('tabGroupFooter') }>
						<TabGroupFooter { ...p } />
					</div>
				) }
			</div>
		);

		const collapseTabs = () => {
			const items = [];
			this.overflowTabs.forEach((tab) => {
				const newTab = { tab, key: tab.key };
				if (typeof tab.group === 'string') {
					const group = items.find(tab => tab.key === tab.group);
					if (group) {
						group.items.push(newTab);
					} else {
						items.push({
							key: tab.group,
							type: 'group',
							items: [newTab],
						});
					}
				} else if (tab.key === 'employeeTab-customizeTabs') {
					items.push({
						key: 'customize-tabs',
						type: 'group',
						items: [newTab],
						anchor: 'bottom',
					});
				} else {
					items.push(newTab);
				}
			});

			return (
				<Dropdown
					buttonSettings={{
						className: _createBEM('textButton'),
					}}
					items={ items }
					renderOptionContent={ ({ key }) => {
						const findItem = tab => (tab.items ? tab.items.find(findItem) : tab.key === key);
						const item = items.find(findItem);
						const { tab } = item.tab ? item : item.items.find(findItem);
						return tab.renderTab({ overflow: true });
					} }
					type="text"
				>
					<span className={ _createBEM('tabLabel', ['primary']) }>{ this.overflowText }</span>
				</Dropdown>
			);
		};

		return (
			<TabWrapperComp
				{
					...{
						_createBEM,
						get tabs() { return _this.tabs; },
						_subcomponents,
						title,
						icon,
					}
				}
			>
				{ hasGroups &&
					groups.map((group) => {
						const { name } = group;
						const tabs = _this.tabs.filter(tab => tab.group == name);

						return (
							<TabGroupComp key={ name } { ...{ _createBEM, group, tabs, _subcomponents } } />
						);
					})
				}
				{!hasGroups &&
					ifFeature(
						'encore',
						<Fragment>
							{this.tabs.map((tab) => tab.renderTab())}
							{this.collapseTabs ? (
								<TabDropdown createBEM={_createBEM} overflowText={this.overflowText || ''} tabs={this.tabs || []} />
							) : null}
						</Fragment>,
						<Fragment>
							{this.visibleTabs.map((tab) => tab.renderTab())}
							{this.collapseTabs &&
								(this.hasOverflowTabs || !hasInitCollapseTabs) && (
									<div
										className={_createBEM('tab', 'overflow')}
										ref={(elem) => {
											this.overflowTab = elem;
										}}
									>
										{collapseTabs()}
									</div>
								)}
						</Fragment>,
					)}
			</TabWrapperComp>
		);
	}

	/**
	 * Renders the tabContentWrapper, with
	 * the content of the tabs (based on the load
	 * strategy)
	 * @private
	 */
	_renderBody = () => {
		const {
			_createBEM,
			_subcomponents,
		} = this;
		const {
			TabContentWrapper,
		} = _subcomponents;

		return (
			<div className={_createBEM('tabContentWrapper')}>
				<TabContentWrapper { ...{ _createBEM, _subcomponents } }>
					{this.tabs.map(tab => tab.renderBody())}
				</TabContentWrapper>
			</div>
		);
	}

	_findTabFromPath = () => {
		return this.tabs.find(tab => tab.testURL());
	}

	_onHistoryChange = (e) => {
		const activeTab = this._findTabFromPath();

		if (!activeTab) {
			return;
		}

		this.setState({
			activeTab,
			loading: activeTab.shouldResolveContent,
		}, this._resolveTabs);
	}

	/**
	 * Click event handler for external
	 * navigation buttons
	 * @private
	 * @param {Event} e the Click event
	 */
	_onNavBtnClick = ({ target }) => {
		if (!target.matches(`
			[data-tab-action*=":"],
			[data-tab-action*=":"] *
		`)) {
			return;
		}

		const {
			tabAction = '',
		} = target.dataset || {};

		const [
			id,
			tab = 1,
		] = tabAction.split(':');

		if (id === this.props.id) {
			this.activeTab = tab;
		}
	}

	/**
	 * Disables external nav buttons which
	 * would go to the already active tab
	 * @private
	 * @param {boolean} [disableAll] whether to disable all nav buttons
	 */
	_updateNavBtns = (disableAll = false) => {
		const {
			activeTab,
			tabLength,
			isLastTab,
			isFirstTab,
		} = this;

		$(`[data-tab-action^="${ this.props.id }:"]`).each(function () {
			let [id, tab = 1] = this.dataset.tabAction.split(':');

			switch (tab) {
				case 'next':
				case 'last':
					$(this).toggleDisabled(disableAll || isLastTab);
					break;
				case 'prev':
				case 'first':
					$(this).toggleDisabled(disableAll || isFirstTab);
					break;
				default:
					tab = isNaN(tab) ? tab : clamp(parseInt(tab), 1, tabLength);

					$(this).toggleDisabled(disableAll || [activeTab.key, activeTab.number].indexOf(tab) >= 0);
					break;
			}
		});
	}

	/**
	 * Resolve the active tab (if it's a
	 * Promise) and start to resolve other
	 * tabs, based on the load strategy
	 * @private
	 */
	_resolveTabs = () => {
		const {
			activeTab,
		} = this;

		return activeTab.resolveContent()
			.then(() => {
				this._onTabChange(activeTab);

				this._updateNavBtns(true);

				this.setState({
					loading: false,
				}, () => {
					if (this.props.id) {
						$(() => {
							setTimeout(() => {
								this._updateNavBtns();
							}, 0);
						});
					}

					if (
						activeTab.load.preload === 'next' &&
						!this.isLastTab
					) {
						const {nextTab} = this;

						if (!nextTab.hasBody || !nextTab.hasData) {
							nextTab.resolveContent()
								.then(() => {
									this.setState({
										loaded: nextTab.key,
									});
								});
						}
					}

					if (
						!this.state.loaded &&
						activeTab.load.preload === 'all'
					) {
						this.tabs.reduce((promise, tab) => promise.then(() => tab.resolveContent()), Promise.resolve())
							.then(() => {
								this.setState({
									loaded: true,
								});
							});
					}
				});
			});
	}

	/**
	 * Call the `onTabChange` function passed
	 * as a prop
	 * @private
	 * @param {Tab} newTab the newly activated tab
	 */
	_onTabChange = (newTab) => {
		const fn = this.props.onTabChange;

		if (isFunction(fn)) {
			fn.call(this, newTab);
		}

		document.dispatchEvent(new CustomEvent('BaTabs:change', {
			detail: newTab,
		}));
	}

	/**
	 * Generate the class list, using the
	 * current instance of the BEM utility
	 * @private
	 * @param {string} elem
	 * @param {*[]} modifiers
	 */
	_createBEM = (...args): string => {
		return _bem.get(this).elem(...args);
	}

	/* @startCleanup encore */
	/**
	 * Calculate initial values for collapseTabs
	 * and set up the resize event listener
	 * @private
	 */
	_initCollapseTabs = () => {
		if (!this.collapseTabs || ifFeature('encore', true)) {
			return;
		}

		window.addEventListener('resize', debounce(this._recalcOverflowIndex, 100));
		document.addEventListener('BaTabs:change', this._recalcOverflowIndex);

		this._recalcOverflowIndex();
	}

	/**
	 * Force recalculation for collapseTabs
	 * @private
	 */
	_recalcOverflowIndex = debounce(() => {
		if (!this.collapseTabs || ifFeature('encore', true)) {
			return;
		}

		this.tabWrapperWidth = this._tabWrapperWidth;
	}, 0);
	/* @endCleanup encore */
}
