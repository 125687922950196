import { Icon } from '@bamboohr/fabric';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import {ifFeature, isEnabled} from "@bamboohr/utils/lib/feature"

// Areas to regression test this ActionFooter:
// - Employee Pages
// -Add Employee
// -NHP
// -Company Add Holidays
// -Create Time Tracking Project

const element = isEnabled('encore') ? document.getElementById('encore-site-footer-js') :  document.createElement('div');

const rootFooterClasses = ['SiteFooter', 'SiteFooter--dark-theme', 'SiteFooter--large-theme'];

const getAppType = () => {
	return window.location.pathname.indexOf('/greenhouse') > -1 ? 'greenhouse' : 'main';
};

const logoDataMap = {
	greenhouse: {
		alt: 'BambooHR Greenhouse',
		href: '/greenhouse',
		iconName: 'fab-greenhouse-logo-150x28',
	},
	main: {
		alt: 'BambooHR',
		href: 'https://www.bamboohr.com',
		iconName: 'fab-bamboohr-logo-115x17',
	},
};

export function PageActionsFooter(props) {
	const { children, isOpen = false, right } = props;

	const appType = getAppType();
	let footer = document.querySelector('footer');
	useEffect(() => {
		if (!footer) {
			footer = document.createElement('footer');
			footer.classList.add(...rootFooterClasses);

			document.body.appendChild(footer);
		}
		footer.appendChild(element);

	}, []);

	// Force hide the Legacy action footer
	// This is a temporary solution until we can remove the legacy action footer
	// This is needed because the legacy action footer is not being hidden in some cases (EE pages)
	useEffect(() => {
		if (isEnabled('encore')) {
			const actionFooter = document.getElementById('encore-action-footer-js');
			if (actionFooter) {
				actionFooter.style.display = 'none';
			}
		}

		return () => {
			if (isEnabled('encore')) {
				const actionFooter = document.getElementById('encore-action-footer-js');
				if (actionFooter) {
					actionFooter.style.display = '';
				}
			}
		}
	}, []);

	const toggleOtherFooters = () => {
		Array.from(footer.children).forEach((child) => {
			if (child !== element) {
				if (child.classList.contains('SiteFooter__container--actions')) {
					child.classList.toggle('hidden', true);
				} else {
					child.classList.toggle('hidden', !!isOpen);
				}
			}
		});
	}


	useEffect(() => {
		// Timeouts are present so that the animations have time to complete before footers are hidden
		const toggleFooterClasses = () => {
			footer.classList.toggle('SiteFooter--open', !!isOpen);
		};

		const handleToggle = () => {
			return new Promise((resolve) => {
				setTimeout(() => {
					toggleFooterClasses();
					resolve();
				}, 0);
			});
		};

		if (isOpen) {
			handleToggle().then(() => {
				setTimeout(toggleOtherFooters, 300);
			});
		} else {
			toggleFooterClasses();
			setTimeout(toggleOtherFooters, 300);
		}
	}, [isOpen]);


	return createPortal(
		<div className="SiteFooter__container SiteFooter__container--actions PageCapsule__footer--borderTop" id='encore-page-actions-js'>
			<div className="SiteFooter__wrapper SiteFooter__wrapper--outerReverse SiteContent">
				<div className="SiteFooter__wrapper SiteFooter__wrapper--left">{ children }</div>
				<div className="SiteFooter__wrapper SiteFooter__wrapper--right">
					{ifFeature('encore', right, right || (
						<a
							className="SiteFooter__link SiteFooter__link--logo"
							href={ logoDataMap[appType].href }
							rel="noopener external nofollow noreferrer"
							target="_blank"
						>
							<span className="SiteFooter__logo">
								<Icon alt={ logoDataMap[appType].alt } name={ logoDataMap[appType].iconName } />
							</span>
						</a>
					) )}
				</div>
			</div>
		</div>,
		element
	);
}
