import { Fragment, Component } from 'react';
import {
	groupBy,
} from 'lodash';

import './styles.styl';

export const settingsSubnav = {
	name: 'settings-subnav',
	extraClasses: {
		tabWrapper: 'branded-border',
		tabWrapperTitle: 'baseColor',
		tabWrapperIcon: 'baseFillColor',
		tabIcon: ({ active }) => ({
			baseFillColor: active,
		}),
	},
	TabWrapperTitle(props) {
		const {
			className,
			title,
		} = props;

		return (
			<h3 { ...{ className } }>{ title }</h3>
		);
	},
	TabContentHeader(props) {
		const {
			_createBEM,
			primaryText,
			secondaryText,
		} = props;

		return (
			<h3 className={ _createBEM('tabContentTitle') }>
				{ primaryText }
				{ secondaryText && (
					<span className={ _createBEM('tabContentSubtitle') }>
						{ secondaryText }
					</span>
				) }
			</h3>
		);
	},
};
