import Sticky from 'Sticky.mod';

export default class StickyTableHeader extends Sticky {
	/**
	* Constructor for StickyTableHeader - a module that causes a table's header
	* to stick to a fixed location when a condition is met on upon scroll
	*
	* @param  {object} element Table containing header to make sticky
	* @param  {object} options Options object (see Sticky.mod.js)
	* @return {object}         StickyTableHeader class instance
	*/
	constructor(tableElement, options) {
		if (!tableElement.tHead) {
			throw new Error(`${ tableElement } must contain a <thead>. No <thead> was found.`);
		}

		super(tableElement.tHead.firstElementChild, options);

		this._tableElement = tableElement;

		this._positionAtSpacer();
		this._updateStickiness();
	}

	get _hasPassedBottomOfParent() {
		const parentRect = this._tableElement.getBoundingClientRect();
		return this._elementRect.bottom > parentRect.bottom;
	}

	/*----------------------------------
	* Private/Protected Methods
	* ----------------------------------*/

	/**
	 * Fixes the table header to a set location on the page so that it doesn't move
	 * with its sibling elements
	 */
	stick() {
		this._element.style.position = 'fixed';

		if (this._settings.anchor) {
			this._positionAtAnchor();
		}

		this._stuck = true;
	}

	/**
	 * Allows the table header to again move with its sibling elements
	 */
	unStick() {
		this._positionAtSpacer();
		this._stuck = false;
	}

	/**
	 * Updates the fixed header to match changes made to the table header
	 */
	update() {
		this._addSpacerElement();
		this._updateStickiness();
	}

	/*----------------------------------
	* Private/Protected Methods
	* ----------------------------------*/

	/**
	 * Absolutely positions the visible header above the spacer when it doesn't need to stick
	 */
	_positionAtSpacer() {
		const tableStyle = window.getComputedStyle(this._tableElement);
		const position = tableStyle.getPropertyValue('position');

		if (position === 'static') {
			this._tableElement.style.position = 'relative';
		}

		this._element.style.position = 'absolute';
		this._element.style.left = 0;
		this._element.style.top = 0;
		this._element.style.width = '100%';
	}

	/**
	 * Positions the table header at the same location as the anchor element
	 */
	_positionAtAnchor() {
		super._positionAtAnchor();

		this._element.style.left = `${ this._spacerElementRect.left }px`;
		this._element.style.width = `${ this._spacerElementRect.width }px`;
		this._element.style['z-index'] = 1;
	}

	/**
	 * Adds event handlers bound by this instance
	 */
	_wireEvents() {
		super._wireEvents();

		this._boundResizeHandler = this._resizeHandler.bind(this);
		window.addEventListener('resize', this._boundResizeHandler);
	}

	/**
	 * Removes event handlers bound by this instance
	 */
	_unwireEvents() {
		super._unwireEvents();

		window.removeEventListener('resize', this._boundResizeHandler);
		this._boundResizeHandler = null;
	}

	/**
	 * Resets the width of the table header cells and removes original width
	 * attrs so columns won't recieve the wrong width on additional react renders.
	 */
	_resetCells() {
		const cellElements = [].slice.call(this._element.children);
		const spacerCellElements = [].slice.call(this._spacerElement.children);

		spacerCellElements.forEach((spacerCellElement, index) => {
			const cell = cellElements[index];

			// Remove any widths that StickyTableHeader has applied
			spacerCellElement.style.width = cell.dataset.sthOriginalWidthStyle;
			cell.style.width = cell.dataset.sthOriginalWidthStyle;

			// Remove any sthOriginalWidthStyle attrs that were left over
			delete spacerCellElement.dataset.sthOriginalWidthStyle;
			delete cell.dataset.sthOriginalWidthStyle;
		});
	}

	/**
	 * Adds a spacer element to the same window location as the main element.
	 * This prevents subsequent elements from being swallowed when the main element
	 * is position: fixed.
	 */
	_addSpacerElement() {
		const oldSpacerElement = this._spacerElement;

		this._spacerElement = this._element.cloneNode(true);
		this._spacerElement.style.visibility = 'hidden';
		this._spacerElement.style.position = 'static';

		const { spacerCSSClass } = this._settings;
		if (spacerCSSClass) {
			this._spacerElement.classList.add(spacerCSSClass);
		}

		this._resetCells();

		if (oldSpacerElement && oldSpacerElement.parentNode) {
			this._element.parentNode.replaceChild(this._spacerElement, oldSpacerElement);
		} else {
			this._element.parentNode.appendChild(this._spacerElement);
		}
	}

	/**
	 * Updates the width of each column to match computed widths that are determined by
	 * the cell content or external styling.
	 */
	_updateColumnWidths() {
		const cellElements = [].slice.call(this._element.children);
		const spacerCellElements = [].slice.call(this._spacerElement.children);

		this._resetCells();

		cellElements.forEach((cellElement, index) => {
			const spacerCell = spacerCellElements[index];
			const spacerCellWidth = `${ spacerCell.offsetWidth }px`;

			cellElement.dataset.sthOriginalWidthStyle = spacerCell.style.width;
			cellElement.style.width = spacerCellWidth;
		});
	}

	/**
	 * Updates the position of the table header to be relative to an anchor element or
	 * the original table. Also adjusts the column dimensions to match the table values.
	 */
	_updateStickiness() {
		super._updateStickiness();
		this._updateColumnWidths();
	}

	/*----------------------------------
	* Event Handlers
	* ----------------------------------*/

	/**
	 * Resize event listener responsible for updating the dimensions of the fixed header
	 */
	_resizeHandler() {
		this._positionAtSpacer();
		this._updateStickiness();
	}

	/**
	 * Adjusts visibility of always fixed table header in addition to the checks the parent class runs.
	 * If the bottom of the table is above the fixed header then the header needs to be hidden.
	 * If the the bottom of the table is below the fixed header then the header needs to be visible.
	 */
	_scrollHandler() {
		super._scrollHandler();

		this._element.style.visibility = this._hasPassedBottomOfParent ? 'hidden' : 'visible';
	}


	setTableElement(element) {
		if (element) {
			this._removeSpacerElement();
			this._tableElement = element;
			this._element = element?.tHead?.firstElementChild;
			this._addSpacerElement();
		}
	}
}
