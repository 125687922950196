import { PropsWithChildren, ReactNode} from 'react';

type PanelClassNames = 'header' | 'headerText' | 'content' | 'icon'| 'headerOpen' | 'headerTextOpen' | 'contentOpen' | 'iconOpen'| 'root' | 'rootOpen' | 'headerTextDisabled' | 'headerDisabled'

export type PanelClasses = Partial<Record<PanelClassNames, string>>

export interface PanelProps {
    title: string
    panelKey: string
    icon?: string | ReactNode
	classes?: PanelClasses
	endElement?: ReactNode
	disabled?: boolean
	onOpen?: (value: string) => void
	onClose?: (value: string) => void
}

export interface RuntimePanelProps extends PropsWithChildren<PanelProps> {
    handlePanelChange: (key: string) => void
    openKeys: string[]
}

const arePropsApplied = (obj: Record<string, any>): obj is RuntimePanelProps => {
	if (obj.openKeys && obj.handlePanelChange) {
		return true;
	} 
	return false;
    
};

export const panelPropCheck = (obj: Record<string, any>): RuntimePanelProps | null => {
	if (arePropsApplied(obj)) {
		return obj;
	} 
	return null;
    
};
