import {
	useEffect,
	useState,
} from 'react';
import {
	Message,
} from '@bamboohr/utils/lib/message';

import { BEM } from '@utils/dom';

import './styles.styl';

const bem = new BEM('BackupCodes');

export function BackupCodes(props) {
	const {
		codes: unformattedCodes,
		date,
		shouldChunk = true,
	} = props;

	const [codes, setCodes] = useState([]);
	const [formattedDate, setFormattedDate] = useState(null);

	useEffect(() => {
		if (shouldChunk) {
			const formattedCodes = unformattedCodes.map((codeData, i) => {
				const {
					backupCode,
					...rest
				} = codeData;

				let code = backupCode;
				if (backupCode.length >= 2) {
					const middle = Math.floor(backupCode.length / 2);
					code = backupCode.split('');
					code.splice(middle, 0, ' ');
					code = code.join('');
				}
				return { backupCode: code, ...rest };
			});

			setCodes(formattedCodes);
		}
	}, [shouldChunk, unformattedCodes]);

	useEffect(() => {
		if (date) {
			const formattedDate = moment(date).format('MMM D, YYYY');
			setFormattedDate(formattedDate || null);
		}
	}, [date]);

	return (
		<div className={ bem.elem('wrapper') }>
			<ul className={ bem.elem('list') }>
				{
					codes.map((code) => {
						const {
							backupCode,
							dateUsed,
							id,
						} = code;

						return (
							<li
								className={ bem.elem('code', { 'used': !!dateUsed }) }
								key={ `backupCode-${ id }` }
							>
								{ backupCode }
							</li>
						)
					})
				}
			</ul>

			{
				formattedDate && (
					<span className={ bem.elem('date') }>
						<Message
							text={ $._('Generated on {1}') }
							params={ [formattedDate] }
						/>
					</span>
				)
			}
		</div>
	);
}
