import {
	escape,
	isNil,
	isString,
	unescape,
} from 'lodash';

/**
 * Transforms Number into formatted number string
 * @param {string|number} amount Amount in for formatting
 */
export function formatAmount(amount: string | number = 0): string {
	if (isString(amount)) {
		amount = stripNonNumericCharacters(amount);
	}
	amount = Number(amount);
	if (isNil(amount) || isNaN(amount)) {
		throw new Error('Invalid value supplied');
	}

	return amount.toLocaleString(undefined, { minimumFractionDigits: 2 });
}

/**
 * Strip out the non digit characters while preserving the decimal
 * @param {string} value Value to be stripped of all non numeric characters while preserving decimal
 */
export function stripNonNumericCharacters(value): string {
	if (!isString(value)) {
		throw new Error('Invalid value supplied');
	}
	const isNegative = value.startsWith('-');
	let returnValue = value.replace(/[^\d\.]/g, ''); // removes all non-digit/non-decimal characters
	returnValue = `${ isNegative ? '-' : '' }${ returnValue }`; // adds negative sign if first character is a negative
	return returnValue;
}

/**
 * Trims extra whitespace surrounding each line of text in a string
 * @param  {string} text Text to trim
 * @return {string}      Trimmed text
 */
export function trimLines(text: string): string {
	return text.replace(/(^\s+|\s+$)/gm, '');
}

/**
 * Encode characters to html entities
 *
 * @param string string
 *
 * @return string encoded string
 */
export function htmlentities(string: string): string {
	return escape(string);
}

/**
 * Decode html entities
 *
 * @param string string
 *
 * @return string decoded string
 */
export function htmldecode(string: string): string {
	return unescape(string);
}

/**
 * Replace xml entities (without double encoding)
 * @param  {string} string
 * @return {string}
 */
export function replaceXmlEntities(string: string): string {
	const regex1 = /&(?!(?:apos|quot|[gl]t|amp);|#)/g;

	string = string.replace(regex1, '&amp;')
		.replace(/\"/g, '&quot;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/'/g, '&#x27;');

	return string;
}
export function decodeEscapeSequence(string: string): string {
	return string.replace(/\\(u00|x)([0-9A-F]{2})/gi, (_, $, hexCode) => {
		return String.fromCharCode(parseInt(hexCode, 16));
	});
}

export function convertHTMLEntitiesInString(string: string): string {
	const tempElement = document.createElement('div');
	const regex = /&.{1,7}\b;/g;
	const matches = string.matchAll(regex);
	let result = matches.next();
	let newString = string;

	while (!result.done) {
		const value = result.value[0];
		tempElement.innerHTML = value;
		newString = newString.replace(value, tempElement.textContent);
		result = matches.next();
	}
	return newString;
}

export function joinStringsWithDelimiter(arr: Array<string>, delimiter: string, finalDelimiter: string = delimiter): string {
	if (arr.length < 2) {
		return arr[0];
	}
	if (arr.length === 2) {
		return arr.join(finalDelimiter);
	}
	const firstPart = arr.slice(0, -1).join(delimiter);
	const lastPart = arr.slice(-1);
	return [firstPart, lastPart].join(finalDelimiter);
}

export function stringContainsAllWords(string: string, arr: Array<string>): boolean {
	if (typeof string !== 'string' || !Array.isArray(arr)) {
		return false;
	}

	for (const word of arr) {
		if (!string.includes(word)) {
			return false;
		}
	}

	return true;
}

export function isValidUSZipCode(zip: string): boolean {
	const zipCodeRegEx = /^\d{5}(-\d{4})?$/;

	return zipCodeRegEx.exec(zip) !== null;
}
