import { createStyles, makeStyles } from '@bamboohr/fabric';

export const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		contactContainer: {
			marginBottom: '30px',
		},
		header: {

		},
		headerTitle: {
			fontWeight: typography.fontWeightMedium,
			fontSize: '32px',
		},
		headerDesc: {
			fontSize: typography.fabricFontSize('biggie'),
			color: palette.grey[700],
		},
		divider: {
			margin: '35px 0',
		},
		boxContent: {
			display: 'flex',
			'& h2': {
				fontWeight: typography.fontWeightMedium,
			},
		},
		leftContent: {
			flex: '50%',
		},
		firstDayList: {
			listStyle: 'none',
			margin: '0',
			padding: 0,
			'& li': {
				display: 'flex',
				fontSize: typography.fabricFontSize('biggie'),
				alignItems: 'center',
				marginTop: '20px',
				flexWrap: 'wrap',
				flexDirection: 'row',
				'& div': {
					display: 'flex',
					'& .spacer': {
						flex: 'row',
						marginLeft: '10px',
					},
				},
			},
		},
		firstDayIcon: {
			width: '24px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		directionsLink: {
			display: 'flex',
			flexBasis: '100%',
			marginLeft: '36px',
			'& a': {
				fill: palette.info.main,
				color: palette.info.main,
				'&:hover': {
					fill: palette.info.dark,
					color: palette.info.dark,
				},
				'& span': {
					marginLeft: '5px',
				}
			}
		},
		firstDayIconLink: {
			marginRight: '5px',
		},
		rightContent: {
			flex: '50%',
		},
		contactInfo: {
			marginTop: '20px',
			display: 'flex',
			lineHeight: typography.fabricLineHeight('small'),
		},
		contactImg: {
			marginRight: '20px',
		},
		contactHead: {
			fontSize: typography.fabricFontSize('biggie'),
			fontWeight: typography.fontWeightMedium,
		},
		contactSub: {
			fontSize: typography.fabricFontSize('small'),
			color: palette.gray[700],
		},
		otherInstructions: {
			fontSize: typography.fabricFontSize('large'),
			lineHeight: typography.fabricLineHeight('large'),
			color: palette.gray[700],
			'& p': {
				marginTop: '10px',
			},
		},
	});
});
