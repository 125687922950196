import {scaleBand, scaleLinear} from 'd3-scale';
import {select} from 'd3-selection';
import {max} from 'd3-array';
import {easeCubicInOut} from 'd3-ease';
import {interpolate} from 'd3-interpolate';
import {defaultsDeep} from 'lodash';

import { isEnabled } from 'FeatureToggle.util';

import './bar-chart.styl';

const DEFAULT_OPTIONS = {
	bars: {
		classNameModifier: '',
		mouseEvents: {},
	},
	barTitles: {
		classNameModifier: '',
		padding: 7,
		height: 17,
		uppercase: true
	},
	barSubtitles: {
		show: false,
		height: 14
	},
	barValues: {
		show: true,
		suffix: ''
	},
	barTooltips: {
		className: ''
	},
	className: '',
	height: 310,
	paddingTop: 57,
	responsive: true,
	width: 658,
	useTransitions: false
};

const CLASS_NAME = 'ba-BarChart';
const MIN_BAR_HEIGHT = 2;
const DISABLED_COLOR = '#E8E8E8';

export default class BarChart {
	constructor(selector, options = DEFAULT_OPTIONS) {
		this._selector = selector;

		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);

		this._maxBarHeight = this._options.height - this._options.barTitles.height - this._options.barTitles.padding - this._options.paddingTop;
		if (this._options.barSubtitles.show) {
			this._maxBarHeight -= this._options.barSubtitles.height;
		}

		this._svg = this._createSvg();
		this._configure();
	}

	/**
	 * Creates the main SVG for this chart with the required definitions
	 */
	_createSvg() {
		let root = select(this._selector)
			.classed(CLASS_NAME, true)
			.classed(this._options.className, this._options.className);

		root.selectAll('*').remove();

		let container = root.append('div')
			.classed(`${ CLASS_NAME }__container`, true)
			.classed(`${ this._options.className }__container`, this._options.className);

		let svg = container.append('svg')
			.classed(`${ CLASS_NAME }__svg`, true)
			.classed(`${ this._options.className }__svg`, this._options.className)
			.attr('viewBox', '0 0 ' + this._options.width + ' ' + this._options.height);

		if (this._options.responsive) {
			container.classed('container', true)
				.attr('style', 'display:inline-block;position:relative;width:100%;padding-bottom:47.11%;vertical-align:top;overflow:hidden;')

			svg.classed('responsive', true)
				.attr('style', 'display:inline-block;position:absolute;left:0;top:0;')
				.attr('preserveAspectRatio', 'xMinYMin meet');
		} else {
			svg.style('height', this._options.height + 'px')
				.style('width', this._options.width + 'px');
		}

		this._addDefs(svg);

		return svg;
	}

	/**
	 * Adds chart definitions such as patterns to the SVG
	 */
	_addDefs(svg, color) {
		const barsAreaFillColorClass = isEnabled('jade') ? 'BarChart--fillThemeColor' : 'baseFillColor';

		let colorStr = color ? color.replace('#', '') : '';

		let pattern = svg.append('defs')
			.append('pattern')
			.attr('height', 6)
			.attr('id', 'diagonalHatchPattern' + colorStr)
			.attr('patternUnits', 'userSpaceOnUse')
			.attr('patternTransform', 'rotate(-45)')
			.attr('width', 6);

		let lightStripe = pattern.append('rect')
			.attr('height', '100%')
			.attr('fill', 'rgb(255, 255, 255)')
			.attr('fill-opacity', '0.6')
			.attr('width', '100%');

		let darkStripe = pattern.append('rect')
			.attr('height', 3)
			.attr('width', 6);

		if (color) {
			lightStripe.attr('fill', color);
			darkStripe.attr('fill', color);
		} else {
			lightStripe.attr('class', barsAreaFillColorClass);
			darkStripe.attr('class', barsAreaFillColorClass);
		}
	}

	_configure() {
		const barsAreaFillColorClass = isEnabled('jade') ? 'BarChart--fillThemeColor' : 'baseFillColor';
		const fontFamily = isEnabled('jade') ? 'Lato' : 'Source Sans Pro';
		this._barsArea = this._svg.append('g')
			.attr('class', `${ CLASS_NAME }__barsArea ${ barsAreaFillColorClass }`)
			.classed(`${ this._options.className }__barsArea`, this._options.className)
			.attr('transform', `translate(0, ${ this._options.paddingTop })`)
			.attr('opacity', 0.8);

		this._valuesArea = this._svg.append('g')
			.attr('class', `${ CLASS_NAME }__valuesArea ${ barsAreaFillColorClass }`)
			.classed(`${ this._options.className }__valuesArea`, this._options.className)
			.attr('transform', `translate(0, ${ this._options.paddingTop })`)
			.attr('font-family', fontFamily)
			.attr('font-size', '13')
			.attr('text-anchor', 'middle');

		let titleVerticalOffset = (this._options.height - this._options.barTitles.height);
		if (this._options.barSubtitles.show) {
			titleVerticalOffset -= this._options.barSubtitles.height;
		}

		this._titlesArea = this._svg.append('g')
			.classed(`${ CLASS_NAME }__titlesArea`, true)
			.classed(`${ this._options.className }__titlesArea`, this._options.className)
			.attr('transform', 'translate(0, ' + titleVerticalOffset + ')')
			.attr('font-family', fontFamily)
			.attr('font-size', '13')
			.attr('fill', '#777')
			.attr('text-anchor', 'middle');

		if (this._options.barTitles.uppercase) {
			this._titlesArea.style('text-transform', 'uppercase');
		}

		if (this._options.barSubtitles.show) {
			this._subtitlesArea = this._svg.append('g')
				.classed(`${ CLASS_NAME }__subtitlesArea`, true)
				.classed(`${ this._options.className }__subtitlesArea`, this._options.className)
				.attr('transform', 'translate(0, ' + (this._options.height - this._options.barSubtitles.height) + ')')
				.attr('font-family', fontFamily)
				.attr('font-size', '10px')
				.attr('fill', '#777')
				.attr('text-anchor', 'middle');
		}

		this._groupLabels = this._svg.append('g')
			.classed(`${ CLASS_NAME }__groupLabelsArea`, true)
			.classed(`${ this._options.className }__groupLabelsArea`, this._options.className)
			.attr('transform', 'translate(0, ' + 16 + ')')
			.attr('font-family', fontFamily)
			.attr('font-size', '19');

		this._groupDividers = this._svg.append('g')
			.classed(`${ CLASS_NAME }__groupDividersArea`, true)
			.classed(`${ this._options.className }__groupDividersArea`, this._options.className)
			.attr('stroke', '#d7d7d7')
			.attr('stroke-width', 1)
			.attr('opacity', 0.9);
	}

	draw(data) {
		// Scale to data
		let x = scaleBand()
			.range([0, this._options.width])
			.round(true)
			.paddingInner(0.42)
			.domain(data.map((d, i) => i));

		let y = scaleLinear()
			.rangeRound([this._maxBarHeight - MIN_BAR_HEIGHT, 0])
			.domain([0, max(data, (d) => (Array.isArray(d) ? max(d, (dItem) => Number(dItem.value)) : Number(d.value)))]);

		// Data points where groups change
		const GROUP_DATA = data.reduce((memo, d, i) => {
			if (i === 0 || data[i - 1].groupName !== d.groupName) {
				memo.push(Object.assign(d, {originalIndex: i}));
			}
			return memo;
		}, []);

		const BAR_AND_VALUE_TRANSITION = this._svg.transition()
			.delay(1000)
			.duration(600)
			.ease(easeCubicInOut);

		this._drawBars(data, x, y, BAR_AND_VALUE_TRANSITION);

		if (this._options.barValues.show) {
			this._drawBarValues(data, x, y, BAR_AND_VALUE_TRANSITION);
		}

		const setTitleFill = (d) => {
			let displayType = Array.isArray(d) ? d[d.length - 1].displayType : d.displayType;
			let color;
			if (Array.isArray(d)) {
				color = d[d.length - 1].titleColor || d[d.length - 1].color;
			} else {
				color = d.titleColor || d.color;
			}
			return (displayType === 'unselected') ? DISABLED_COLOR : color;
		};

		const TITLE = this._titlesArea.selectAll(`.${ CLASS_NAME }__title`).data(data);
		const TITLE_ENTER = TITLE.enter()
			.append('text')
			.classed(`${ CLASS_NAME }__title`, true)
			.classed(`${ this._options.className }__title`, this._options.className)
			.attr('y', 14) // Have to adjust the vertical position of the using y (IE doesn't support dominant-basline)
			.merge(TITLE)
			.attr('x', (d, i) => x(i) + x.bandwidth() / 2)
			.text(d => (Array.isArray(d) ? d[d.length - 1].title : d.title))
			.attr('fill', setTitleFill);

		if (this._options.barTitles.classNameModifier) {
			TITLE_ENTER.classed(`${ this._options.className }__title--${ this._options.barTitles.classNameModifier }`, d => (Array.isArray(d) ? d[d.length - 1].modifyTitle : d.modifyTitle))
		}

		TITLE.exit().remove();

		if (this._options.barSubtitles.show) {
			const SUBTITLE = this._subtitlesArea.selectAll(`.${ CLASS_NAME }__subtitle`).data(data);
			SUBTITLE.enter()
				.append('text')
				.merge(SUBTITLE)
				.classed(`${ CLASS_NAME }__subtitle`, true)
				.classed(`${ this._options.className }__subtitle`, this._options.className)
				.attr('y', 8) // Have to adjust the vertical position of the using y (IE doesn't support dominant-basline)
				.attr('x', (d, i) => x(i) + x.bandwidth() / 2)
				.filter(d => (Array.isArray(d) ? d[d.length - 1].subtitle && d[d.length - 1].subtitle.length : d.subtitle && d.subtitle.length))
				.text(d => (Array.isArray(d) ? d[d.length - 1].subtitle : d.subtitle))
				.attr('fill', setTitleFill);
		}

		const GROUP_LABEL = this._groupLabels.selectAll(`.${ CLASS_NAME }__groupLabel`).data(GROUP_DATA);
		GROUP_LABEL.enter()
			.append('text')
			.classed(`${ CLASS_NAME }__groupLabel`, true)
			.classed(`${ this._options.className }__groupLabel`, this._options.className)
			.attr('y', 0)
			.merge(GROUP_LABEL)
			.attr('x', d => x(d.originalIndex))
			.text(d => d.groupName);
		GROUP_LABEL.exit().remove();

		const GROUP_DIVIDER = this._groupDividers.selectAll(`.${ CLASS_NAME }__groupDivider`).data(GROUP_DATA);
		GROUP_DIVIDER.enter()
			.append('line')
			.classed(`${ CLASS_NAME }__groupDivider`, true)
			.classed(`${ this._options.className }__groupDivider`, this._options.className)
			.attr('y1', 0)
			.attr('y2', this._options.height)
			.merge(GROUP_DIVIDER)
			.attr('visibility', d => (d.originalIndex === 0 ? 'hidden' : 'visible'))
			.attr('x1', d => Math.round(x(d.originalIndex) - (x.step() - x.bandwidth()) / 2))
			.attr('x2', d => Math.round(x(d.originalIndex) - (x.step() - x.bandwidth()) / 2));
		GROUP_DIVIDER.exit().remove();

		BambooHR.Components.Tooltip.init();
	}

	/**
	 * Draws the bars that are sized to represent data points
	 * @param  {object} data       The dataset used to size the bar
	 * @param  {object} x          The x scale
	 * @param  {object} y          The y scale
	 * @param  {object} transition The transition to use for bars
	 */
	_drawBars(data, x, y, transition) {
		let heightFunction = d => this._maxBarHeight - y(d.value);
		let yFunction = d => y(d.value);

		let barGroupsUpdate = this._barsArea
			.selectAll(`.${ CLASS_NAME }__barGroup`)
			.data(data);

		let barGroupsEnter = barGroupsUpdate
			.enter()
			.append('g')
			.classed(`${ CLASS_NAME }__barGroup`, true)
			.classed(`${ this._options.className }__barGroup`, this._options.className)
			.attr('data-index', (d, i) => i);

		barGroupsEnter = barGroupsUpdate
			.merge(barGroupsEnter)
			.selectAll(`.${ CLASS_NAME }__bar`)
			.data(d => (Array.isArray(d) ? d : [d]))
			.enter()
			.append('rect')
			.classed(`${ CLASS_NAME }__bar js-${ CLASS_NAME }__bar`, true)
			.classed(`${ this._options.className }__bar`, this._options.className)
			.attr('height', heightFunction)
			.attr('width', x.bandwidth())
			.attr('x', function() { return x(this.parentNode.getAttribute('data-index')) })
			.attr('y', yFunction)
			.attr('rx', '2')
			.attr('ry', '2')
			.attr('data-tip-class', this._options.barTooltips.className)
			.attr('data-tip-z-index', 'auto')
			.attr('data-tip-content', d => d.tooltip)
			.attr('data-tip-lead', d => d.tooltipLead)
			.on('mouseenter', this._options.bars.mouseEvents.mouseenter || function() { select(this).style('opacity', 0.85); })
			.on('mouseleave', this._options.bars.mouseEvents.mouseleave || function() { select(this).style('opacity', 1); })
			.on('click', function(data) {
				this.dispatchEvent(new CustomEvent('ba:barClick',
					{
						bubbles: true,
						detail: {data}
					}
				));
			});

		if (this._options.useTransitions) {
			barGroupsEnter.attr('height', 0)
				.attr('y', this._maxBarHeight);

			barGroupsEnter.transition(transition)
				.attr('height', heightFunction)
				.attr('y', yFunction);
		}

		if (this._options.bars.classNameModifier) {
			barGroupsEnter.classed(`${ this._options.className }__bar--${ this._options.bars.classNameModifier }`, d => d.modifyBar)
		}

		barGroupsEnter.attr('fill', (d) => {
			if (d.displayType === 'unselected') {
				return DISABLED_COLOR;
			} else if (d.displayType === 'partial') {
				if (d.color) {
					const colorStr = d.color ? d.color.replace('#', '') : '';
					const id = '#diagonalHatchPattern' + colorStr;
					if (this._svg.select(id).empty()) {
						this._addDefs(this._svg, d.color);
					}
					return `url(${ id })`;
				}
				return 'url(#diagonalHatchPattern)';
			}
			return d.color;
		});

		barGroupsEnter.exit().remove();
	}

	/**
	 * Draws the values that are displayed on top of each bar
	 * @param  {object} data       The dataset containing bar values
	 * @param  {object} x          The x scale
	 * @param  {object} y          The y scale
	 * @param  {object} transition The transition to use for values
	 */
	_drawBarValues(data, x, y, transition) {
		let {suffix} = this._options.barValues;

		let yFunction = d => y(d.value) - 5;

		let valueGroupsUpdate = this._valuesArea
			.selectAll(`.${ CLASS_NAME }__valueGroup`)
			.data(data);

		let valueGroupsEnter = valueGroupsUpdate
			.enter()
			.append('g')
			.classed(`${ CLASS_NAME }__valueGroup`, true)
			.classed(`${ this._options.className }__valueGroup`, this._options.className)
			.attr('data-index', (d, i) => i);

		valueGroupsEnter = valueGroupsUpdate
			.merge(valueGroupsEnter)
			.selectAll(`.${ CLASS_NAME }__value`)
			.data(d => (Array.isArray(d) ? d : [d]))
			.enter()
			.append('text')
			.classed(`${ CLASS_NAME }__value`, true)
			.classed(`${ this._options.className }__value`, this._options.className)
			.attr('x', function() { return x(this.parentNode.getAttribute('data-index')) + x.bandwidth() / 2 })
			.attr('y', yFunction);

		if (this._options.useTransitions) {
			valueGroupsEnter.attr('y', this._maxBarHeight);
			valueGroupsEnter.transition(transition)
				.attr('y', yFunction)
				.tween('text', function (d) {
					var i = interpolate(0, d.value);
					var element = select(this);

					return function (t) {
						var text = Math.ceil(i(t) * 100) / 100;
						element.text((suffix) ? text + suffix : text);
					};
				});
		} else {
			valueGroupsEnter.text(d => ((suffix) ? d.value + suffix : d.value));
		}

		valueGroupsEnter.attr('fill', d => (d.displayType === 'unselected' ? DISABLED_COLOR : d.color));

		valueGroupsEnter.exit().remove();
	}
}
