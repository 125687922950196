import { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';
import { handleControlledInput, getClassBasedValidation, hasErrors, fixValidationRegisterName } from 'dynamic-form';
import './checkbox.styl';

const CheckboxField: FunctionComponent<DynamicForm.CheckboxElementProps> = (checkboxFieldProps) => {
	const {
		props,
		settings,
		context,
	} = checkboxFieldProps;
	const { id, className, 'data-checkbox_id': dataCheckboxId, name, checked } = props;
	const { label, fieldBoxClasses, withContainer = false, inCheckboxGroup, labelProps = {} } = settings;
	const {
		controls: { Label, FieldBox, FieldDiv, PendingRequest },
		validation: { setValue, getValues, register, formState: { errors } },
	} = context;
	const validationName = fixValidationRegisterName(name);
	const hasError = hasErrors(errors, validationName);
	register(validationName, { ...getClassBasedValidation(context, checkboxFieldProps) });

	useEffect(() => {
		setValue(validationName, checked);
	}, []);

	const updateValidationValue = (isChecked: boolean) => {
		// only call if this is in a checkbox group and has a valid dataCheckboxId
		// just a list of the input names.
		// Will error on required if no items in the group are checked and validation value ends up being ''.
		// Grouped checkboxes are treated as a group (See checkbox-list.tsx), not individually!
		let currentValue: string = getValues(dataCheckboxId) as string || '';
		currentValue = currentValue.replace(name, '');
		if (isChecked) {
			currentValue += name;
		}
		setValue(dataCheckboxId, currentValue, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const handleOnChange = (e): void => {
		handleControlledInput('checkbox', id, e.target.checked, context);
		if (inCheckboxGroup && dataCheckboxId) {
			updateValidationValue(e.target.checked);
		}
		setValue(validationName, e.target.checked);
	};

	const checkboxInputClasses = classNames(className, 'fab-Checkbox__input', {
		'fab-Checkbox__input--error': hasError,
	});

	const checkboxLabelClasses = classNames('fab-Checkbox__label', {
		'fab-Checkbox__label--error': hasError,
	});

	const checkboxElement = (
		<div className="fab-Checkbox js-formField DynamicForm__Checkbox">
			<PendingRequest context={ context } props={ props } settings={ settings } />
			<input
				type="checkbox"
				{ ...props }
				className={ checkboxInputClasses }
				onChange={ handleOnChange }
			/>
			<Label inputId={ id } label={ label } labelClasses={ checkboxLabelClasses } labelProps={ labelProps } />
		</div>
	);

	const checkboxElementWithContainer = (
		<FieldBox fieldBoxClasses={ fieldBoxClasses }>
			<FieldDiv>{ checkboxElement }</FieldDiv>
		</FieldBox>
	);

	const getCheckbox = (): React.ReactElement => {
		if (withContainer) {
			return checkboxElementWithContainer;
		}
		if (inCheckboxGroup) {
			return checkboxElement;
		}
		return <FieldBox fieldBoxClasses={ fieldBoxClasses }>{ checkboxElement }</FieldBox>;
	};

	return getCheckbox();
};

export default CheckboxField;
