import { isOdiHost } from './is-odi-host';
import { removeVersionFromPath } from './remove-version-from-path';
import { trimSlashes } from './trim-slashes';

export const resolveResourceUrl = (hostUrl = '', resourcePath = '') => {
	const url = new URL(hostUrl);
	const basePath = isOdiHost(url.host) ? url.pathname : removeVersionFromPath(url.pathname);

	return `${url.protocol}//${url.host}/${[trimSlashes(basePath), trimSlashes(resourcePath)].filter(Boolean).join('/')}`;
};
