export const getId = state => state.id;

export const getAllCommentIds = (state) => {
	const comments = state.getIn(['entities', 'comments', 'byId']);
	return state.getIn(['entities', 'comments', 'allIds']).filter((k) => {
		return !(comments.get(k)).excludeFromComments;
	});
};

export const getCommentById = (state, id) => (
	state.getIn(['entities', 'comments', 'byId', id])
);

export const getReplyById = (state, id) => (
	state.getIn(['entities', 'replies', 'byId', id])
);

export const getUserById = (state, id) => (
	state.getIn(['entities', 'users', 'byId', id])
);

export const getFilesByParentId = (state, id) => {
	const fileIds = state.getIn(['entities', 'comments', 'byId', id, 'files']) ||
		state.getIn(['entities', 'replies', 'byId', id, 'files']);
	return state.getIn(['entities', 'files', 'byId'])
		.filter((v, k) => fileIds.has(k));
};

export const getAllEventIds = state => (
	state.getIn(['entities', 'events', 'allIds'])
);

export const getEventById = (state, id) => (
	state.getIn(['entities', 'events', 'byId', id])
);

export const getLatestEventByType = (state, type) => {
	const types = state.getIn(['entities', 'events', 'byId']).filter(v => v.type === type);
	return types.reduce((v, c) => {
		return (v.createdAt > c.createdAt) ? v : c;
	}); 
};
