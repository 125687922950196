import {Component} from 'react';
import {startsWith} from 'lodash';
import moment from 'moment';
import { Icon, StandardModal } from '@bamboohr/fabric';
import { Modal } from 'modal-legacy';
import Ajax from '@utils/ajax';
import {
	getEndDate,
	getTimeDuration,
	getTwentyFourHourTime,
	padZerosOnTime,
	validateClockOutDates
} from 'time-tracking/utils';
import {INCORRECT_TIME_ENTRIES_MSG} from 'time-tracking/constants';
import ClockOutSlat from './clock-out-slat.react';
import launchClockedOutErrorModal from 'time-tracking/modals/clocked-out-error';
import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default class ApproverClockOut extends Component {
	constructor(props) {
		super(props);

		const employees = props.employees.map((employee) => {
			const preppedEmployee = {};
			const startMoment = moment.tz(employee.start.date, employee.start.timezone);

			preppedEmployee.endTime = '';
			preppedEmployee.endMeridiem = startMoment.format('A');
			preppedEmployee.startTime = startMoment.format('h:mm');
			preppedEmployee.start = employee.start.date;
			preppedEmployee.today = employee.today.date;
			preppedEmployee.timezone = employee.today.timezone;
			preppedEmployee.firstName = employee.firstName;
			preppedEmployee.lastName = employee.lastName;
			preppedEmployee.id = employee.id;
			preppedEmployee.employeeId = employee.employeeId;
			preppedEmployee.clockOutDateTime = null;

			return preppedEmployee;
		});

		this.state = {
			employees,
			isModalOpen: false,
			isModalProcessing: false,
			canSave: true
		};

		this._handleInputBlur = this._handleInputBlur.bind(this);
		this._handleInputChange = this._handleInputChange.bind(this);
		this._handleSelectChange = this._handleSelectChange.bind(this);
		this._handleDateChange = this._handleDateChange.bind(this);
	}

	_clockOutEmployees = () => {
		const { afterAction } = this.props;
		let { url } = this.props;
		this.setState({isModalProcessing: true});

		const postData = {
			clockOuts: this._handleFormSave()
		};

		if (url === '/timesheet/clock/out/approval') {
			url = '/timesheet/clock/out/datetime';
		}

		if (postData) {
			Ajax.post(url, JSON.stringify(postData), {
				headers: {
					'Content-Type': 'application/json;charset=utf-8'
				}
			})
				.then((res) => {
					this.setState({isModalOpen: false});
					afterAction(res.data);
				})
				.catch((error) => {
					let timesheets;
					if (error.response.data.data) {
						timesheets = error.response.data.data.timesheets;
					} else {
						timesheets = error.response.data.timesheets;
					}

					this.setState({isModalProcessing: false});
					this._handleSaveError(timesheets);
				});
		}
	};

	componentDidMount() {
		this._handleValidation();

		setTimeout(() => {
			this.setState({isModalOpen: true});
		});
	}

	componentDidUpdate() {
		this._handleValidation();
	}

	render() {
		const { canSave, isModalOpen, isModalProcessing } = this.state;
		const { title } = this.props;

		return (
			<Modal
				isOpen={ isModalOpen }
				isProcessing={ isModalProcessing }
				onClose={ () => this.setState({isModalOpen: false}) }
				primaryAction={ canSave ? this._clockOutEmployees : null }
				primaryActionText={ $.__('Clock Out & Approve') }
				title={ title }
				type="medium"
			>
				<div className="ApproverClockOut">
					{ this._renderWarning() }
					{ this._renderClockOutSlats() }
				</div>
			</Modal>
		);
	}

	_getIndividualEntryTotal(entry) {
		const {start, endMeridiem, endTime, today} = entry;
		const endDate = getEndDate(endTime, endMeridiem, moment(today).format('YYYY-MM-DD'));
		const paddedValue = padZerosOnTime(endTime);
		const formattedValue = paddedValue ? moment(paddedValue, 'h:mm').format('h:mm') : null;

		let endDateTime;

		if (endTime && formattedValue) {
			endDateTime = `${ endDate } ${ getTwentyFourHourTime(formattedValue, endMeridiem) }`;
		} else {
			return 0;
		}

		const startMoment = moment(start);
		const endMoment = moment(endDateTime);

		return getTimeDuration(startMoment, endMoment, 'hours');
	}

	_handleValidation() {
		const { employees } = this.state;

		return validateClockOutDates(employees);
	}

	_handleFormSave() {
		const { employees } = this.state;
		return employees.map((employee) => {
			return {
				employeeId: employee.employeeId,
				datetime: employee.clockOutDateTime.toISOString(true),
				timezone: employee.timezone,
			};
		});
	}

	_handleSaveError(errorEmployees) {
		let employees = this.state.employees.slice();
		let clockedOutErrorPresent = false;
		let generalErrorPresent = false;

		employees = employees.filter((employee) => {
			const errorEmployee = errorEmployees.find(errorEmployee => errorEmployee.employeeId === employee.employeeId);
			if (errorEmployee.status === 'failure' && errorEmployee.error === 'CLOCKED_OUT') {
				clockedOutErrorPresent = true;
			} else if (errorEmployee.status === 'failure') {
				generalErrorPresent = true;
				return true;
			} else {
				return true;
			}

			return false;
		});

		if (clockedOutErrorPresent) {
			launchClockedOutErrorModal(generalErrorPresent, () => {
				// Close the approver clock out modal since there are no employees left clocked in for the approver to clock out
				if (employees.length === 0) {
					window.SimpleModal.closeModal();
				}
			});
		} else if (generalErrorPresent) {
			window.setMessage(INCORRECT_TIME_ENTRIES_MSG, 'error');
		}

		if (employees.length > 0) {
			this.setState({employees});
		}
	}

	_handleInputBlur(value, id) {
		if (value) {
			const formattedValue = padZerosOnTime(value);

			this._handleInputChange(formattedValue, id);
		}

	}

	_handleInputChange(value, id) {
		const { canSave } = this.state;
		const employees = this.state.employees.slice();
		const entry = employees.find(employee => employee.id === id);
		entry.endTime = value;

		const paddedEndTime = padZerosOnTime(value);
		const endHourNum = Number(paddedEndTime.split(':')[0]);
		const startMoment = moment(entry.start);
		const startMeridiem = startMoment.format('A');

		if (
			startMeridiem === 'AM' &&
			value !== '1' &&
			endHourNum &&
			endHourNum < startMoment.hour()
		) {
			entry.endMeridiem = 'PM';
		} else if (startMeridiem === 'AM' && startsWith(paddedEndTime, '12')) {
			entry.endMeridiem = 'PM';
		} else if (startMeridiem === 'PM' && paddedEndTime === '12:00') {
			entry.endMeridiem = 'AM';
		}

		entry.total = this._getIndividualEntryTotal(entry);

		const validInput = this._handleValidation();
		this.setState({employees, canSave: validInput});
	}

	_handleDateChange(date, id) {
		const { canSave, employees } = this.state;
		const employeesArray = employees.slice();
		const employee = employeesArray.find(employee => employee.id === id);
		employee.clockOutDateTime = date;

		const validInput = this._handleValidation();
		this.setState({ employees, canSave: validInput });
	}

	_handleSelectChange(value, id) {
		const { canSave } = this.state;
		const employees = this.state.employees.slice();
		const entry = employees.find(employee => employee.id === id);
		entry.endMeridiem = value;
		entry.total = this._getIndividualEntryTotal(entry);

		const validInput = this._handleValidation();
		this.setState({employees, canSave: validInput});
	}

	_renderClockOutSlats() {
		const { employees } = this.state;
		return employees.map((employee) => {
			return (
				<ClockOutSlat
					{ ...employee }
					handleDateChange={ this._handleDateChange }
					handleInputBlur={ this._handleInputBlur }
					handleInputChange={ this._handleInputChange }
					handleSelectChange={ this._handleSelectChange }
					key={ employee.id.toString() }
				/>
			);
		});
	}

	_renderWarning() {
		const { employees } = this.props;
		const pluralTextPartOne = $.__('Some employees are still clocked in.');
		const pluralTextPartTwo = $.__('Enter clock out times below before approving.');
		const singularTextPartOne = $.__('%1$s might have forgotten to clock out.', employees[0].firstName);
		const singularTextPartTwo = $.__('Enter a clock out time below before approving hours.');
		const multipleEmployees = employees.length > 1;

		/* @startCleanup encore */
		const warningText = (
			<div>
				<h4 className="ApproverClockOut__warningHeader">{ multipleEmployees ? pluralTextPartOne : singularTextPartOne }</h4>
				<p className="ApproverClockOut__warningText">{ multipleEmployees ? pluralTextPartTwo : singularTextPartTwo }</p>
			</div>
		);
		const iconName = 'fab-stopwatch-exclamation-51x54';
		/* @endCleanup encore */

		return ifFeature(
			'encore',
			<StandardModal.HeroHeadline
				hasSeparator={true}
				icon="triangle-exclamation-regular"
				iconColor='warning-strong'
				subtext={multipleEmployees ? pluralTextPartTwo : singularTextPartTwo}
				text={multipleEmployees ? pluralTextPartOne : singularTextPartOne}
			/>,
			<div className='ApproverClockOut__warning'>
				<div className='ApproverClockOut__warningIcon'>
					<Icon name={iconName} />
				</div>
				{warningText}
			</div>
		);
	}
}
