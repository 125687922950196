import { FileIcon } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { bem } from '../../../../constants';
import { useFileInfo } from './hooks';
import './styles.styl';

export const FileInfo = (props) => {

	const {
		id,
		type,
	} = props;

	const {
		addedAt,
		addedBy,
		fileName,
		fileSize,
		isEsignature,
		loading,
	} = useFileInfo(type, id);

	return (
		<div className={ bem.elem('header') }>
			<fieldset className={ bem.elem('fileSection', ifFeature('encore', null, ['^fab-FormSection'])) }>
				<legend className={ bem.elem('fileLegend', ifFeature('encore', null, ['^fab-FormSection__legend'])) }>
					<div className={ bem.elem('fileIcon') }>
						<FileIcon filename={fileName} size="small" type={isEsignature ? 'esig' : undefined} />
					</div>
					<div className={ bem.elem('fileInfo') }>
						<div className={ bem.elem('fileName') }>{ fileName }</div>
						<div className={ bem.elem('fileMeta', { loading }) }>
							<Message
								params={ [addedAt, addedBy, fileSize] }
								text={ $.__('Added {1} by {2} ({3})', addedAt, addedBy, fileSize) }
							/>
						</div>
					</div>
					<input type="hidden" name="files[0][fileId]" value={ id } />
					<input type="hidden" name="files[0][fileType]" value={ type } />
				</legend>
			</fieldset>
		</div>
	);

}
