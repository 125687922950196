import { isEnabled } from 'FeatureToggle.util';

export const ENPS_SENTIMENT_TAGS_ENABLED = isEnabled('enpsSentimentTags');
export const ENPS_SENTIMENT_UI_ENABLED = isEnabled('enpsSentimentUI');
export const ENPS_SENTIMENT_SCORE_TREND_ENABLED = isEnabled('enpsSentimentScoreTrend');
export const ENPS_SENTIMENT_TOP5_ENABLED = isEnabled('enpsSentimentTop5');
export const ENPS_SENTIMENT_MOST_MENTIONED_ENABLED = isEnabled('enpsSentimentMostMentioned');
export const ENPS_SENTIMENT_ALL_TAGS_ENABLED = isEnabled('enpsSentimentAllTags');
export const ENPS_SENTIMENT_RESPONSES_LESS_THAN_SIX_ENABLED = isEnabled('enpsSentimentResponsesLessThanSix');
export const ENPS_ON_MOBILE_ANONYMITY_BANNER_ENABLED = isEnabled('enpsOnMobileAnonymityModal');
