import './styles.styl';

export const small = {
	name: 'fab-small',
	variants: ['light', 'large', 'icons', 'tight'],
	extraClasses: {
		tab: ({ active }) => ({
			'branded-border-bottom': active,
		}),
	},
	hiddenParts: ['secondaryText'],
};
