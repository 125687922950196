import { defaultsDeep, endsWith, isFunction, uniqueId, isEqual } from 'lodash';
import { Component, Fragment, createRef } from 'react';

import classNames from 'classnames';

import { classNameFromObject } from '@utils/dom';
import { createCalendarPickers } from 'CalendarPicker.mod';
import { replaceXmlEntities } from 'String.util';
import { ifFeature } from '@utils/feature';
import { LayoutBox } from '@bamboohr/fabric';

/**
 * @deprecated - use fabric components instead
 */
export default class CalendarPicker extends Component {
	static defaultProps = {
		cssClasses: {
			component: '',
			range: '',
			rangeEnd: 'ReportPageFilter__input',
			rangeStart: 'ReportPageFilter__input',
			single: '',
		},
		disabled: false,
		noFacade: false,
		settings: {},
		type: 'date-range',
		unsafeProps: {},
	};

	_isRange() {
		const { type = '' } = this.props;

		return endsWith(type, '-range');
	}

	_getApi(element) {
		const selector = '[calendar-picker]';
		const calendarPickerElement = element.querySelector(selector) || element.closest(selector);
		return calendarPickerElement.__calendarPicker;
	}

	/**
	 * Gets the placeholder for a calendar range. If the placeholder isn't specified and labels
	 * are not specified for the component we will use "Start" and "End" for the placeholder text
	 * @param  {object} settings Settings object
	 * @return {object}          Placeholder object
	 */
	_getRangePlaceholder(settings) {
		const { placeholder = {}, label = {} } = settings;

		return {
			end: label.end ? '' : $.__('End'),
			start: label.start ? '' : $.__('Start'),
			...placeholder,
		};
	}

	/**
	 * Generates a unique name for the calendar picker or uses the name passed in
	 * @param  {string} [name] Optional name to use
	 * @return {string}        Unique name
	 */
	_getUniqueName(name) {
		return name || uniqueId('calendarPicker');
	}

	/**
	 * Calls the onChange callback when a value has changed
	 * @param  {object} value The selection
	 */
	_triggerChange(value) {
		const { onChange } = this.props;

		if (isFunction(onChange)) {
			onChange(value);
		}
	}

	/**
	 * Calls the onComplete callback when a masked value has changed
	 * @param  {string} rawValue The direct value of the text input
	 * @param  {string} isoValue The selection form field value
	 */
	_triggerComplete(rawValue, isoValue) {
		const { onComplete } = this.props;

		if (isFunction(onComplete)) {
			onComplete(rawValue, isoValue);
		}
	}

	/**
	 * Calls the onIncomplete callback when a masked value has changed
	 * @param  {string} rawValue The direct value of the text input
	 */
	_triggerIncomplete(rawValue) {
		const { onIncomplete } = this.props;

		if (isFunction(onIncomplete)) {
			onIncomplete(rawValue);
		}
	}

	/**
	 * Renders labels above the specific inputs
	 * @param  {string} name        Name of label the value is accociated with
	 * @param  {string} displayText Text to display
	 * @return {jsx}                Completed label
	 */
	_renderLabel(name, displayText) {
		const { required } = this.props;

		return (
			<label className={classNameFromObject({ 'fab-Label': true, 'fab-Label--required': required })} htmlFor={name}>
				{displayText}
			</label>
		);
	}

	/**
	 * Renders two bound inputs that indicate a time frame and passes settings to Calendar Picker
	 * @param  {object} cssClasses CSS classes to apply
	 * @param  {object} settings    Settings to pass to the calendar picker
	 * @return {object}            JSX
	 */
	_renderRange(cssClasses, settings) {
		const placeholder = this._getRangePlaceholder(settings);
		let endName, startName;
		let endId, startId;

		const { id, name, noFacade, required, type } = this.props;

		if (typeof name === 'object') {
			startName = name.start;
			endName = name.end;
		} else {
			const uniqueName = this._getUniqueName(name);

			startName = `${uniqueName}Start`;
			endName = `${uniqueName}End`;
		}

		if (typeof id === 'object') {
			startId = id.start;
			endId = id.end;
		}

		const attributes = {};
		if (required) {
			attributes.required = true;
		}

		if (noFacade) {
			attributes['no-facade'] = '';
		}

		return (
			<div
				{...attributes}
				calendar-picker={type}
				calendar-picker-options={JSON.stringify(settings)}
				className={`${cssClasses.range} CalendarPicker__rangeRow`}
				data-no-jquery-aria-required={true}
			>
				<div className='CalendarPicker__rangeColumn'>
					{!!settings.label && !!settings.label.start && this._renderLabel(startName, settings.label.start)}
					<div className='fab-InputWrapper'>
						<input
							className={`${cssClasses.rangeStart} fab-TextInput CalendarPicker CalendarPicker__input`}
							disabled={!!settings.disabled}
							id={startId}
							name={startName}
							onFocus={this._handleFocus}
							placeholder={placeholder.start}
							range-start=''
							required={required}
							type='text'
						/>
					</div>
				</div>
				<div className='CalendarPicker__rangeColumn'>
					{!!settings.label && !!settings.label.end && this._renderLabel(endName, settings.label.end)}
					<div className='fab-InputWrapper'>
						{ifFeature(
							'encore',
							<>
								<div className='CalendarPicker__rangeSeparator'>&ndash;</div>
								<input
									className={`${cssClasses.rangeEnd} fab-TextInput CalendarPicker CalendarPicker__input`}
									disabled={!!settings.disabled}
									id={endId}
									name={endName}
									onFocus={this._handleFocus}
									placeholder={placeholder.end}
									range-end=''
									required={required}
									type='text'
								/>
							</>,
							<>
								<div className='CalendarPicker__rangeSeparator'>&ndash;</div>
								<input
									className={`${cssClasses.rangeEnd} fab-TextInput CalendarPicker CalendarPicker__input`}
									disabled={!!settings.disabled}
									id={endId}
									name={endName}
									onFocus={this._handleFocus}
									placeholder={placeholder.end}
									range-end=''
									required={required}
									type='text'
								/>
							</>
						)}
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Renders a single input and passes settings to Calendar Picker
	 * @param  {object} cssClasses CSS classes to apply
	 * @param  {object} settings    Settings to pass to the calendar picker
	 * @return {object}            JSX
	 */
	_renderSingle(cssClasses, settings) {
		const { id, name, noFacade, required, type, unsafeProps = {}, biId } = this.props;

		const uniqueName = this._getUniqueName(name);
		const { placeholder = '' } = settings;
		const attributes = {};

		if (required) {
			attributes.required = true;
		}

		if (placeholder) {
			settings.placeholder = replaceXmlEntities(placeholder);
			attributes['data-placeholder-trigger'] = 'focus';
		}

		if (noFacade) {
			attributes['no-facade'] = '';
		}

		const calendarPickerClasses = `${cssClasses.single} fab-TextInput CalendarPicker CalendarPicker__input`;

		if (unsafeProps.className) {
			unsafeProps.className = `${calendarPickerClasses} ${unsafeProps.className}`;
		}

		return (
			<input
				{...attributes}
				aria-required={required}
				data-bi-id={biId}
				data-no-jquery-aria-required={true}
				calendar-picker={type}
				calendar-picker-options={JSON.stringify(settings)}
				className={calendarPickerClasses}
				disabled={!!settings.disabled}
				id={id}
				name={uniqueName}
				onFocus={this._handleFocus}
				placeholder={placeholder}
				required={required}
				type='text'
				{...unsafeProps}
			/>
		);
	}

	/**
	 * Event handler to proxy the "focus" event to other React components
	 */
	_handleFocus = () => {
		const { onFocus } = this.props;

		if (isFunction(onFocus)) {
			onFocus();
		}
	};

	_ref = createRef();

	componentDidMount() {
		const $element = $(this._ref.current);
		const $calendarPicker = $element.find('[calendar-picker]');

		createCalendarPickers($calendarPicker);

		$($element)
			.on('inputmask:complete', 'input[type="text"]', (event) => {
				const api = this._getApi(event.currentTarget);

				if (api) {
					const isoValue = api.getValue();
					const rawValue = event.currentTarget.value;
					this._triggerChange(isoValue);
					this._triggerComplete(rawValue, isoValue);
				}
			})
			.on('inputmask:incomplete', 'input[type="text"]', (event) => {
				const api = this._getApi(event.currentTarget);

				if (api) {
					const rawValue = event.currentTarget.value;
					this._triggerIncomplete(rawValue);
				}
			})
			.on('calendarPicker:rangeSet', (event, value) => this._triggerChange(value));
	}

	componentDidUpdate(prevProps) {
		const { disabled, settings } = this.props;

		const element = this._ref.current;
		const calendarApi = this._getApi(element);

		if (!isEqual(settings, prevProps.settings) || disabled !== prevProps.disabled) {
			calendarApi.setOptions({ disabled, ...settings });
		}
	}

	componentWillUnmount() {
		const element = this._ref.current;
		const calendarApi = this._getApi(element);

		calendarApi._hidePopoverImmediately();
	}

	render() {
		const { cssClasses, disabled, name, settings, type } = this.props;

		const extendedSettings = { ...(settings || {}), disabled: disabled };
		const extendedCssClasses = defaultsDeep({}, CalendarPicker.defaultProps, cssClasses);
		const uniqueName = this._getUniqueName(name);
		let renderSingleContent = null;

		if (!endsWith(type, '-range')) {
			renderSingleContent = !!settings.label && this._renderLabel(uniqueName, settings.label);
		}

		return (
			<Fragment>
				{renderSingleContent}
				{ifFeature(
					'encore',
					<div className='fab-InputWrapper' ref={this._ref}>
						{endsWith(type, '-range')
							? this._renderRange(extendedCssClasses, extendedSettings)
							: this._renderSingle(extendedCssClasses, extendedSettings)}
					</div>,
					<div
						className={
							extendedCssClasses &&
							classNames({
								[extendedCssClasses.component]: extendedCssClasses?.component,
								'fab-InputWrapper': !endsWith(type, '-range'),
								'fab-FormSection': endsWith(type, '-range'),
								'fab-FormSection--noBorder': endsWith(type, '-range'),
							})
						}
						ref={this._ref}
					>
						{endsWith(type, '-range')
							? this._renderRange(extendedCssClasses, extendedSettings)
							: this._renderSingle(extendedCssClasses, extendedSettings)}
					</div>
				)}
			</Fragment>
		);
	}
}
