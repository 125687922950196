import {
	Agreement,
} from '../../../agreement.react';
import {
	Message,
} from '@bamboohr/utils/lib/message';

import './styles.styl';

import {
	BEM,
} from '@utils/dom';
import { SlideTransition } from 'slide-transition.react';

const bem = new BEM('DataProcessingAgreementConditionalAgreement');

export function ConditionalAgreement(props) {
	const {
		hasEmployees,
		isAccepted,
		onHasEmployeesChange,
		onIsAcceptedChange,
	} = props;

	const handleRadioChange = (event) => {
		onHasEmployeesChange(event.target.value !== 'no');
	};

	return (
		<div className={ bem.elem() }>
			<Message
				text={ $.__('Does your company have employees in the **European Union**, **EEA**, the **United Kingdom** or **Switzerland**?') }
			/>
			<fieldset className={ `${ bem.elem('answers') } fab-RadioGroup` }>
				<label className="fab-Radio">
					<input
						checked={ !hasEmployees }
						className="fab-Radio__input"
						name="hasEmployees"
						onChange={ handleRadioChange }
						type="radio"
						value="no"
					/>
					<span className="fab-Radio__label">
						{ $.__(`No, we don't`) }
					</span>
				</label>
				<label className="fab-Radio">
					<input
						checked={ hasEmployees }
						className="fab-Radio__input"
						name="hasEmployees"
						onChange={ handleRadioChange }
						type="radio"
						value="yes"
					/>
					<span className="fab-Radio__label">
						{ $.__(`Yes, we do`) }
					</span>
				</label>
			</fieldset>

			<SlideTransition isOpen={ hasEmployees }>
				<div className={ bem.elem('agreement') }>
					<p className={ bem.elem('requiredMessage') }>
						{ $.__(`In order to use BambooHR, you’ll need to agree to the following.`) }
					</p>

					<Agreement
						isAccepted={ isAccepted }
						onChange={ onIsAcceptedChange }
					/>
				</div>
			</SlideTransition>
		</div>
	);
}
