import { cloneDeep, omit, isEmpty } from 'lodash';
import { getCopyOfFieldAndChildren, fixValidationRegisterName } from 'dynamic-form';

export function generateIndex(templateId: string): string {
	let index: string;

	do {
		// 10000 will be enough for everybody!
		index = `add${ Math.round(Math.random() * 10000) }`;
	} while (document.getElementById(templateId.replace(':idx:', index)));
	return index;
}

export function addRepeatableField(
	containerId: string,
	templateId: string,
	context: DynamicForm.Context
): string | boolean {
	const container = cloneDeep(context.formData[containerId]);
	const templateItem = getCopyOfFieldAndChildren(templateId, context);
	let template;
	try {
		template = JSON.stringify(templateItem);
	} catch (e) {
		console.error(`Dyanmic Form: unable to stringify template with container ${ containerId } and template ${ templateId }`);
		return false;
	}
	const index = generateIndex(templateId);
	template = template.replace(/:idx:/gi, index).replace('repeatPrototype', '');

	try {
		template = JSON.parse(template);
	} catch (e) {
		console.error(`Dyanmic Form: unable to parse template with container ${ containerId } and template ${ templateId }`);
		return false;
	}

	const newTemplateId = templateId.replace(':idx:', index);
	container.children.push(newTemplateId);

	context.setFormData({
		...context.formData,
		...template,
		...{ [container.props.id]: container },
	});

	return newTemplateId;
}

export function removeRepeatableField(
	containerId: string,
	repeatGroupId: string,
	context: DynamicForm.Context
): void {
	const container = cloneDeep(context.formData[containerId]);
	const groupAndChildren = getCopyOfFieldAndChildren(repeatGroupId, context);
	const newFormData = omit(context.formData, Object.keys(groupAndChildren));

	container.children = container.children.filter(id => id !== repeatGroupId);

	context.setFormData({
		...newFormData,
		...{ [container.props.id]: container },
	});

	// Remove group fields from form validation
	const fieldsToRemoveValidation = [];
	for (const fieldKey in groupAndChildren) {
		if (groupAndChildren[fieldKey].props.name) {
			const fixedFieldNameToRemoveValidation = fixValidationRegisterName(groupAndChildren[fieldKey].props.name);
			fieldsToRemoveValidation.push(fixedFieldNameToRemoveValidation);
		}
	}
	context.validation.unregister(fieldsToRemoveValidation);
}

export function doTraxCheckForAddressGroups() {
	return !window.location.href.includes('contacts');
}
