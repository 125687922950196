// 🤦
import {clamp, extend, isEmpty} from 'lodash';
import {getRelativePosition} from 'BambooHR.util';
import {isElementInViewport} from '@utils/dom';
import { isEnabled } from '@bamboohr/utils/lib/feature';

// This is how far to try to keep the balloon in the window (perpendicular to the position)
const slidePad = 22;

export function position() {
	const anchorPosition = getRelativePosition(this.anchor, undefined, this.settings.correctBodyPosition);
	const balloonPosition = this.balloonElement.getBoundingClientRect();
	const isAnchorVisible = isElementInViewport(this.anchor);
	const isLocked = !isEmpty(this._positionLock);
	const position = isLocked ? this._positionLock.position : this.settings.position;
	const isPositionTopOrBottom = position === 'top' || position === 'bottom';
	const lockCalculatingFunction = isPositionTopOrBottom ? calculateTopBottomLock : calculateLeftRightLock;
	const lock = lockCalculatingFunction.call(this, position, anchorPosition, balloonPosition, isAnchorVisible, this._positionLock, isLocked);

	if (!isLocked) {
		this._positionLock = Object.assign({}, lock);

		this.anchor.setAttribute('balloon-position', lock.position);
		this.balloonElement.setAttribute('position', lock.position);

		// If the position is top or bottom, the tail is positioned from the "left"
		// If the position is left or right, the tail is positioned from the "top"
		const topOrLeft = isPositionTopOrBottom ? 'left' : 'top';
		// @startCleanup encore
		if (!isEnabled('encore')) {
			this.balloonElement.querySelector('.js-balloon-tail').style[topOrLeft] = lock.tail + 'px';
		}
		// @endCleanup encore

		Object.assign(this.balloonElement.style, {top: lock.top + 'px', left: lock.left + 'px'});
	}

	// This will not happen the first time it is positioned because the values will always be the same
	// This is wrapped to avoid reaching into the DOM on every requestAnimationFrame to update values
	// that never change
	if (lock.top !== this._positionLock.top || lock.left !== this._positionLock.left) {
		this._positionLock.top = lock.top;
		this._positionLock.left = lock.left;
		Object.assign(this.balloonElement.style, {top: lock.top + 'px', left: lock.left + 'px'});
	}
}

function calculateTopBottomLock(position, anchorPosition, balloonPosition, isAnchorVisible, lock, isLocked) {
	let {
		tailOnAnchor,
		flipTailOnAnchor,
		tailOnBalloon,
		flipTailOnBalloon,
		minTailOnBalloon,
		invertOnCollision,
	} = this.settings;

	flipTailOnAnchor = isLocked ? lock.flipTailOnAnchor : flipTailOnAnchor;
	flipTailOnBalloon = isLocked ? lock.flipTailOnBalloon : flipTailOnBalloon;

	const topOfTopPositioning = anchorPosition.top - balloonPosition.height - this.settings.push;
	const topOfBottomPositioning = anchorPosition.bottom + this.settings.push;
	const hasCollidedWithTopOfWindow = topOfTopPositioning < window.pageYOffset;
	const hasCollidedWithBottomOfWindow = topOfBottomPositioning + balloonPosition.height > window.innerHeight + window.pageYOffset;

	const tailOnAnchorPixels = calculatePixelValue(anchorPosition.width, tailOnAnchor, flipTailOnAnchor);
	const tailOnBalloonPixels = calculatePixelValue(balloonPosition.width, tailOnBalloon, flipTailOnBalloon);

	let tailLeft = isLocked ? lock.tail : clamp(tailOnBalloonPixels, minTailOnBalloon, balloonPosition.width - minTailOnBalloon);
	let balloonLeft = anchorPosition.left + tailOnAnchorPixels - tailLeft;

	let inverted = isLocked ? lock.inverted : false;

	if (!isLocked && isAnchorVisible) {
		const leftOverhang = balloonLeft - (slidePad + window.pageXOffset);
		const rightOverhang = (window.innerWidth + window.pageXOffset - slidePad) - (balloonLeft + balloonPosition.width);

		if (leftOverhang < 0 || rightOverhang < 0) {
			// If we invert the balloon, then we will not slide it
			if (invertOnCollision) {
				inverted = true;
				flipTailOnAnchor = !flipTailOnAnchor;
				flipTailOnBalloon = !flipTailOnBalloon;
				const tailOnAnchorPixels = calculatePixelValue(anchorPosition.width, tailOnAnchor, flipTailOnAnchor);
				const tailOnBalloonPixels = calculatePixelValue(balloonPosition.width, tailOnBalloon, flipTailOnBalloon);
				tailLeft = clamp(tailOnBalloonPixels, minTailOnBalloon, balloonPosition.width - minTailOnBalloon);
				balloonLeft = anchorPosition.left + tailOnAnchorPixels - tailLeft;
			} else {
				const shift = Math.min(rightOverhang, Math.abs(leftOverhang));
				tailLeft = clamp(tailLeft - shift, minTailOnBalloon, balloonPosition.width - minTailOnBalloon);
				balloonLeft = anchorPosition.left + tailOnAnchorPixels - tailLeft;
			}
		}

		if (position === 'top' && hasCollidedWithTopOfWindow && !hasCollidedWithBottomOfWindow) {
			position = 'bottom';
		}

		if (position === 'bottom' && hasCollidedWithBottomOfWindow && !hasCollidedWithTopOfWindow) {
			position = 'top';
		}
	}

	return {
		position,
		inverted,
		top: position === 'top' ? topOfTopPositioning : topOfBottomPositioning,
		left: balloonLeft,
		tail: tailLeft,
		flipTailOnAnchor,
		flipTailOnBalloon,
	};
}

function calculateLeftRightLock(position, anchorPosition, balloonPosition, isAnchorVisible, lock, isLocked) {
	let {
		tailOnAnchor,
		flipTailOnAnchor,
		tailOnBalloon,
		flipTailOnBalloon,
		minTailOnBalloon,
		invertOnCollision,
	} = this.settings;

	flipTailOnAnchor = isLocked ? lock.flipTailOnAnchor : flipTailOnAnchor;
	flipTailOnBalloon = isLocked ? lock.flipTailOnBalloon : flipTailOnBalloon;

	const leftOfLeftPositioning = anchorPosition.left - balloonPosition.width - this.settings.push;
	const leftOfRightPositioning = anchorPosition.right + this.settings.push;
	const hasCollidedWithLeftOfWindow = leftOfLeftPositioning < window.pageXOffset;
	const hasCollidedWithRightOfWindow = leftOfRightPositioning + balloonPosition.width > window.innerWidth + window.pageXOffset;

	const tailOnAnchorPixels = calculatePixelValue(anchorPosition.height, tailOnAnchor, flipTailOnAnchor);
	const tailOnBalloonPixels = calculatePixelValue(balloonPosition.height, tailOnBalloon, flipTailOnBalloon);

	let tailTop = isLocked ? lock.tail : clamp(tailOnBalloonPixels, minTailOnBalloon, balloonPosition.height - minTailOnBalloon);
	let balloonTop = anchorPosition.top + tailOnAnchorPixels - tailTop;

	let inverted = isLocked ? lock.inverted : false;

	if (!isLocked && isAnchorVisible) {
		const topOverhang = balloonTop - (slidePad + window.pageYOffset);
		const bottomOverhang = (window.innerHeight + window.pageYOffset - slidePad) - (balloonTop + balloonPosition.height);

		if (topOverhang < 0 || bottomOverhang < 0) {
			// If we invert the balloon, then we will not slide it
			if (invertOnCollision) {
				inverted = true;
				flipTailOnAnchor = !flipTailOnAnchor;
				flipTailOnBalloon = !flipTailOnBalloon;
				const tailOnAnchorPixels = calculatePixelValue(anchorPosition.height, tailOnAnchor, flipTailOnAnchor);
				const tailOnBalloonPixels = calculatePixelValue(balloonPosition.height, tailOnBalloon, flipTailOnBalloon);
				tailTop = clamp(tailOnBalloonPixels, minTailOnBalloon, balloonPosition.height - minTailOnBalloon);
				balloonTop = anchorPosition.top + tailOnAnchorPixels - tailTop;
			} else {
				const shift = Math.min(bottomOverhang, Math.abs(topOverhang));
				tailTop = clamp(tailTop - shift, minTailOnBalloon, balloonPosition.height - minTailOnBalloon);
				balloonTop = anchorPosition.top + tailOnAnchorPixels - tailTop;
			}
		}

		if (position === 'left' && hasCollidedWithLeftOfWindow && !hasCollidedWithRightOfWindow) {
			position = 'right';
		}

		if (position === 'right' && hasCollidedWithRightOfWindow && !hasCollidedWithLeftOfWindow) {
			position = 'left';
		}
	}

	return {
		position,
		inverted,
		top: balloonTop,
		left: position === 'left' ? leftOfLeftPositioning : leftOfRightPositioning,
		tail: tailTop,
		flipTailOnAnchor,
		flipTailOnBalloon,
	};
}

export function calculatePixelValue(length, percentOrPixels, opposite = false) {
	let pixels = 0;

	if (!isNaN(percentOrPixels)) {
		pixels = Number(percentOrPixels);
	} else if (percentOrPixels.indexOf('px') > 0) {
		pixels = parseInt(percentOrPixels);
	} else if ((/^[0-9]+$/).test(percentOrPixels)) {
		pixels = Number(percentOrPixels);
	} else if (percentOrPixels.indexOf('%') > 0) {
		const percent = parseInt(percentOrPixels);
		pixels = length * (percent / 100);
	}

	if (opposite) {
		pixels = length - pixels;
	}

	return pixels;
}
