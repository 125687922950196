import { Avatar, makeStyles } from '@bamboohr/fabric';
import { ReactElement } from 'react';
import { StatusMismatchEmployee } from './types';

const useStyles = makeStyles(({ spacing }) => ({
	avatar: {
		display: 'inline-flex',
		width: 54,
		height: 54,
	},
}));

export function EmployeeAvatar({ employee }: { employee: StatusMismatchEmployee }): ReactElement {
	const classes = useStyles();

	return (
		<Avatar
			alt={ $.__('Image of %1 %2', employee.preferredName, employee.lastName) }
			className={ classes.avatar }
			src={ employee.photoUrl }
		/>
	);
}
