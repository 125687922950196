import Ajax from '@utils/ajax';
import {
	isEnabled,
	isSetEnabled
} from 'FeatureToggle.util';

import {
	getFormPayRollDetails
} from 'Form.util';

import { isValidUSZipCode } from 'String.util';

import { hasTrax } from 'BambooHR.util';

import {
	fixCurrencyValue,
} from 'Currency.util';

import {
	debounce,
	isFunction,
	isPlainObject,
	isString
} from 'lodash';
import moment from 'moment';
import SimpleModal from 'SimpleModal.mod';
import {
	SSNInvalidSyncModal,
	SSNWarnSyncModal
} from 'SsnValidation.mod';
import {
	jadeErrorPlacement,
	jadeHighlight,
	jadeUnhighlight
} from './jade-form-validation';
import {
	isSalariedHoursPerWeekValueValid,
} from '../../_utils/form-validation-helpers';

const SELECTORS_TO_VALIDATE = [
	'input', 'select', 'textarea'
];

const JADE_ENABLED = isEnabled('jade');

if (!JADE_ENABLED) {
	SELECTORS_TO_VALIDATE.push('ba-select');
}

var defaultErrorMessage = $.__('Whoops... No worries. Please fix any missing or incorrect information and try again.');

function getEmailExistsValidationUrl() {
	const url = getValidationUrl();
	return url === '' ? '/ajax/employee_emails.php' : url;
}

function getValidationUrl() {
	if (typeof window.SESSION_USER === 'undefined') {
		// logged out form
		return '';
	}
	else if (window.isOnboardingUser || (window.SESSION_USER && window.SESSION_USER.isOnboardingUser)) {
		return '/self_onboarding/packet/validate';
	}
	return '/employees/validate';
}

$.fn.isFieldRequired = function() {
	return !!$(this).rules().required;
};

$.fn.syncingPayScheduleSelected = function() {
	const $payScheduleSelect =$(this).closest('form').find('ba-select.js-paySchedule');
	const $option = ($payScheduleSelect.length > 0) ? $payScheduleSelect.find('ba-option:selected') : [];
	if ($option.length > 0) {
		return isSelectedPSSyncing($payScheduleSelect, $option[0].value);
	}

	return $(this).hasClass('syncingScheduleSelected') || false;
};

$.fn.toggleRequired = function(newVal) {
	$(this).each(function() {
		var $this = $(this);

		var $fieldBox = $this.closest('.fieldBox');
		var currentVal = !!$this.rules().required;

		if (_.isUndefined(newVal)) {
			newVal = !currentVal;
		}

		$fieldBox.toggleClass('required', newVal);

		if (!newVal) {
			$fieldBox
				.removeAttr('aria-required')
				.removeClass('error');

			$this.rules('remove', 'required');
		} else {
			$fieldBox.attr('aria-required', 'true');

			if (!_.isPlainObject(newVal)) {
				newVal = {
					required: newVal
				};
			}

			if (_.isUndefined(newVal.required)) {
				newVal.required = true;
			}

			$this.rules('add', newVal);
		}
	});
};

// This is NOT a toggle -> Must explicity pass whether it should be required or not. Default: true
$.fn.setTraxRequired = function(isRequired = true) {
	$(this).each(function() {
		// only continue if company is Trax
		if (!hasTrax()) {
			console.warn('Cannot set fields required when company isn\'t Trax');
			return;
		}

		const $this = this.tagName === 'SELECT' ? $(this).closest('ba-select') : $(this);
		const $fieldBox = $this.closest('.fieldBox,.fab-FormRow');

		/*
		* Aria
		*/
		if (isRequired) {
			$fieldBox.attr('aria-required', isRequired);
			$this.attr('aria-required', isRequired);
		} else {
			$fieldBox.removeAttr('aria-required');
			$this.removeAttr('aria-required');
		}
		/*
		* Required Classes
		*/
		$fieldBox.toggleClass('required', isRequired);
		$fieldBox.find('label').toggleClass('fab-Label--required', isRequired);
		/*
		* Validation Class
		*/
		$this.toggleClass('requiredWhenTraxSyncing', isRequired).find('select').toggleClass('requiredWhenTraxSyncing', isRequired);
		// When PS is already syncing, fields are already marked `requiredWhenVisible` from BE QF
		// TRAX validation overrides `requiredWhenVisible` -> Remove it so there isn't conflicting required classes
		$this.removeClass('requiredWhenVisible').find('select').removeClass('requiredWhenVisible').closest('.fieldBox').removeClass('requiredWhenVisible');
		/*
		* Errors - Cleanup when removing required (when adding required labels are updated -> we don't want to trigger red borders on everything)
		*/
		const $ruleElement = $this[0].tagName === 'BA-SELECT' ? $this.find('select') : $this;
		if (!isRequired) {
			$ruleElement.valid(); // re-run valid to correctly remove errors or keep errors not related to required
		}
	});
};

$.fn.forceValidateField = function () {
	const $this = $(this);
	const isFieldVisible = $this.is(':visible') || $this.closest('ba-select').is(':visible');
	if ($this.length !== 1) {
		console.warn('Invalid data for forcing validation check -> Must match element & Must not match multiple elements');
		return false;
	} else if ($this.closest('form').attr('bhrvalidate-initiated') != 'true') {
		console.warn('forceValidateField can only be used on initiated bhrValidate form');
		return false;
	} else if ($this.is('[readonly]') || $this.is(':disabled')) {
		console.warn('forceValidateField cannot validate readonly or disabled field');
		return false;
	} else if (!isFieldVisible) {
		// Don't validate a hidden field
		return false;
	}

	$this.valid();
}

$.fn.initialTraxValidation = function () {

	// only validate if trax and not a new employee
	if (!hasTrax() || window.currentlyEditingEmployeeId === 0) {
		return false;
	}

	const $this = $(this);
	// Form can be focused multiple times. We only want to do it initially (ONCE)
	if ($this.get(0).hasSSNBeenValidatedInitially) {
		return false;
	} else {
		$this.get(0).hasSSNBeenValidatedInitially = true;
	}

	if (!$this.is('form')) {
		console.warn('initialTraxValidation can only be called on a form element');
		return false;
	}

	/**
	 * If Primary SSN is in the form, Do an initial validation BE request for it.
	 * Prevents bad data being saved and catches validation race errors if PS is also in the form
	 */
	const PrimarySSNElement = $this.find('.primary-ssn-field');
	if (PrimarySSNElement.length === 1) {
		PrimarySSNElement.data('isInitialValidation', true);
		PrimarySSNElement.forceValidateField();
	}

	/**
	 * If Pay Schedule is part of the form:
	 * Handle whether fields should be marked required or not
	 */
	const PayScheduleElement = $this.find('ba-select.js-paySchedule');
	if (PayScheduleElement.length === 1) {
		handleTraxRequiredFields(PayScheduleElement, null);
	}
}

function addClosestForm() {
	if (!this.form) {
		this.form = $(this).closest('form')[0];
	}
}

function getEmployeeId(element) {
	// NOTE: Do not use $(element).data('userid'); It is ONLY on/effects email fields when converting ATS user.
	// User Personal Tab & NHP - $(element).data('employeeid') || $(element).data('userid')
	// New Employee - ^ returns non-id, use window.currentlyEditingEmployeeId
	// Fallback - 0
	const elEmpId = $(element).data('employeeid');
	const elementHasEmployeeId = elEmpId != 'data-employeeid' && elEmpId != undefined;
	const fallbackId = window.currentlyEditingEmployeeId || '0';
	return elementHasEmployeeId ? elEmpId : fallbackId;
}

function setValidState(element, value, validator, previous, valid) {
	if (!validator.settings.messages[element.name]) {
		validator.settings.messages[element.name] = {};
	}
	validator.settings.messages[element.name].remote = previous.originalMessage;
	if (valid) {
		let submitted = validator.formSubmitted;
		validator.prepareElement(element);
		validator.formSubmitted = submitted;
		validator.successList.push(element);
		delete validator.invalid[element.name];
		validator.showErrors();
		validator.settings.unhighlight.call(validator, element);
	} else {
		let errors = validator.errorMap || {};
		let message = validator.defaultMessage(element, 'remote');
		errors[element.name] = previous.message = $.isFunction(message) ? message(value) : message;
		validator.invalid[element.name] = true;
		validator.showErrors(errors);
		validator.settings.highlight.call(validator, element);
	}
	previous.valid = valid;
	validator.stopRequest(element, valid);
}

function setValidStateIfInForm(element, selector, validator, valid) {
	let $field = isFieldInForm(element, selector);
	if ($field && $field.length === 1) {
		let field = $field.get(0);
		setValidState(field, field.value, validator, validator.previousValue(field), valid);
	}
}

function isFieldInForm (element, selector) {
	let $field = $(element).closest('form').find(selector);
	let isFieldVisible = $field.is(':visible') || $field.closest('ba-select').is(':visible');
	return isFieldVisible ? $field : false;
}

function isSelectedPSSyncing(element, value) {
	const $PSElement = isFieldInForm(element, 'select.js-paySchedule');
	if ($PSElement) {
		const valueToCompare = value || $PSElement.val();
		const payScheduleIds = $PSElement[0].tagName === 'SELECT' ? $PSElement.closest('ba-select').data('payScheduleIds') : $PSElement.data('payScheduleIds');
		if (payScheduleIds) {
			return payScheduleIds && valueToCompare.length && String(payScheduleIds).includes(valueToCompare);
		} else {
			return $(element).closest('ba-select').find('ba-option:selected').data('syncing-pay-schedule') === true;
		}
	}
	return false;
}

function handleTraxRequiredFields(element, value) {
	// .trax-required can select elements outside of the form, scope selector to current form
	const $form = $(element).closest('form');
	if (isSelectedPSSyncing(element, value)) {
		$('.trax-required', $form).not('.requiredWhenTraxSyncing, .workflow-additional-field-required, .requiredWhenVisible').setTraxRequired(true);
	} else {
		// If user is already in Syncing PS, those fields will be marked `requiredWhenVisible`. Ignore them and go off of the workflow classes.
		$('.trax-required.requiredWhenTraxSyncing, .trax-required.workflow-additional-field', $form).not('.workflow-additional-field-required').setTraxRequired(false);
	}
}

$(function() {
	jQuery.validator.addClassRules({
		requiredWhenVisible: {
			required: function(element) {
				const $element = $(element);

				switch (true) {
					case !JADE_ENABLED && $element.is('select') :
						return $element.next('.chzn-container').is(':visible');
					case !JADE_ENABLED && $(element).hasClass('chzn-container'):
						return false;
					case JADE_ENABLED && $element.is('select:not(visible)'):
						return $element.closest('.fab-Select').is(':visible');
					default:
						return $(element).is(':visible');
				}
			}
		},
		requiredWhenTraxSyncing: {
			required: function(element) {
				const $element = $(element);

				// Only require if visible
				if ($element.is('select:not(visible)')) {
					return $element.closest('.fab-Select').is(':visible');
				}
				return $(element).is(':visible');
			}
		},
		validateUrl: {
			urlHttpOptional: true
		},
		'validateUrl-http': {
			url: true
		},
		'validateUrl-https': {
			urlHttpsRequired: true
		},
		validateNumber: {
			number: true
		},
		'validateEmployeeNumber': {
			employeeNumber: true
		},
		'validatePayrollEmployeeNumber': {
			digits: true,
			maxLengthDigits: 6,
			noLeadingZeros: true
		},
		creditCardLast4Digits: {
			digits: true,
			maxLengthDigits: 4
		},
		checkEmailExists: {
			bambooEmailValidator: true,
			// onkeyup doesn't work here, needs to be put in the .bhrValidate({}) function on the page for each field needed.
			// onkeyup: false, //so it doesn't call the ajax request to check the emails)with each keystroke (greedy validation - only after first submit)
			check_email_exists: {
				url: getEmailExistsValidationUrl(),
				data: {'id': getEmployeeId(this), 'userId': $(this).data('userid')} // userid is used/set when converting ATS to Emp. Only used on Email fields because ATS created user in the DB.
			}
		},
		validateEmail: {
			bambooEmailValidator: true
		},
		'ssn-field': {
			regex: '^[0-9]{3}-[0-9]{2}-[0-9]{4}$'
		},
		'primary-ssn-field': {
			'check_ssn': {
				url: getValidationUrl(),
				data: { 'id': getEmployeeId(this), check_field: 'ssn', check_action: 'checkSSN' }
			}
		},
		'sin-field': {
			regex: '^[0-9]{3}-[0-9]{3}-[0-9]{3}$'
		},
		'primary-sin-field': {
			check_sin_exists: {
				url: getValidationUrl(),
				data: { 'id': getEmployeeId(this), check_field: 'sin', check_action: 'checkSIN' }
			}
		},
		'js-validate-nin': {
			regex: /^([0-9a-z]{2}\s?){4}\s?[0-9a-z]{1}$/i
		},
		'js-validate-overtimeRate-trax': {
			valueGreaterThanZeroTrax: true,
			currencyTypeMatchTrax: 'USD',
		},
		'js-validate-primary-nin': {
			check_nin_exists: {
				url: getValidationUrl(),
				data: { 'id': getEmployeeId(this), check_field: 'nin', check_action: 'checkNIN' }
			}
		},
		'js-validate-national-id': {
			regex: /^[0-9a-z]{6,16}$/i
		},
		'js-validate-primary-national-id': {
			check_national_id_exists: {
				url: getValidationUrl(),
				data: { 'id': getEmployeeId(this), check_field: 'national_id', friendly_name: 'National ID', check_action: 'checkNationalID' }
			}
		},
		'js-validate-passport-number': {
			regex: /^[A-Z0-9]{1,20}$/i,
			maxlength: 20
		},
		essRequiredFields: {
			essRequiredFields: true
		},
		'js-validate-inlineEdit': {
			validateInlineEdit: true
		},
		'js-validate-ccNumber': {
			minlength: 13,
			maxlength: 16
		},
		'js-validate-todayAndFuture': {
			todayAndFuture: true
		},
		'js-multiSelect-quantityCheck': {
			multiSelectQuantityCheck: true
		},
		'js-validate-ss-required': {
			validateSelfServiceEmailRequired: true,
			validateTraxEmailRequired: true,
		},
		'js-validate-date-field': {
			validateDateField: true
		},
		'js-validate-to-date': {
			validateDateFrom_to: true
		},
		'js-validate-mindate': {
			validateMinDate: true
		},
		'js-validate-maxdate': {
			validateMaxDate: true
		},
		'js-validate-hire-date': {
			validateDateBetween: {
				from: '1900-01-01',
				to: moment().add(3, 'y'),
			}
		},
		'js-validate-birth-date': {
			validateDateBetween: {
				from: '1900-01-01',
				to: moment(),
			}
		},
		'js-validate-smalldate': {
			validateDateBetween: {
				from: '1900-01-01',
				to: '2079-06-06',
			}
		},
		'js-ToggleButton__validate': {
			validateToggleButton: true
		},
		'js-validate-payRate': {
			validatePayRate: true
		},
		'js-validate-payRate-trax': {
			currencyTypeMatchTrax: 'USD'

		},
		'js-validate-noEmptyWhitespace': {
			trimIsNotEmpty: true
		},
		'js-validate-directDeposit': {
			directDepositValidator: true,
			directDeposit100Validator: true
		},
		'js-validate-range-1-5': {
			smartRange: [1, 5],
		},
		'js-validate-range-0-24': {
			smartRange: [0, 24],
		},
		'js-paySchedule': {
			check_pay_schedule: {
				url: getValidationUrl(),
				data: { action: 'checkPayScheduleForEmployee' }
			}
		},
		'js-validate-zip-trax': {
			validateUSZipTrax: true
		},
		'js-validate-salaried-hours-per-week': {
			validateSalariedHoursPerWeek: true
		},
	});

	//initiate the form for all .BhrForms forms that haven't been initiated already
	$('form.BhrForms:not([bhrvalidate-initiated=true]):not(".js-noValidate"):not(".dynamic-form")').each(function() {
		$(this).bhrValidate({});
	});
});
(function(jQuery) {
	const originalResetForm = jQuery.validator.prototype.resetForm;

	var $ = jQuery;

	jQuery.fn.bhrValidOnClick = function() {
		if ($(this).valid() && !$('#footer-buttons .btn').hasClass('disabled')) {
			if (JADE_ENABLED) {
				document.dispatchEvent(new CustomEvent('SiteFooterActions:toggleProcessing'));
			} else {
				$('#footer-buttons .btn').addClass('disabled processing');
			}
			$(this).submit();
		}
	};

	// Temporary override that will unhighlight elements (this is the behavior in the latest version: 1.17)
	jQuery.validator.prototype.resetForm = function() {
		originalResetForm.call(this);

		this.elements().each((index, element) => {
			jQuery.validator.prototype.resetElement.call(this, element);
		});
	};

	jQuery.validator.prototype.elements = function() {
		var validator = this,
			rulesCache = {};

		// select all valid inputs inside the form (no submit or reset buttons)
		return $(this.currentForm)
			.find(SELECTORS_TO_VALIDATE.join(', '))
			.not(':submit, :reset, :image, [disabled]')
			.not(this.settings.ignore)
			.filter(function() {
				if (!this.name && validator.settings.debug && window.console) {
					console.error('%o has no name assigned', this);
				}

				// select only the first element for each name, and only those with rules specified
				if (this.name in rulesCache || !validator.objectLength($(this).rules())) {
					return false;
				}
				rulesCache[this.name] = true;
				return true;
			}).each(addClosestForm);
	};

	jQuery.fn.bhrValidate = function(options, defaultError) {
		var defaultOptions, customMessages;

		if (defaultError == undefined) {
			defaultError = defaultErrorMessage;
		}
		// add a flag to this form that the validator has already been initiated
		if ($(this).attr('bhrvalidate-initiated') != 'true') {
			$(this).attr('bhrvalidate-initiated','true');
		} else {
			$(this).each((i, form) => {
				if (window.BambooHR.env.dev && form instanceof HTMLFormElement) {
					console.warn('.bhrValidate() called multiple times', options);
				}
			});
		}

		if (options == undefined) {
			options = { };
		}

		$(this).find('.requiredWhenVisible').find(':input:not(.notRequired), ba-select:not(.notRequired)').addClass('requiredWhenVisible');

		// Get a list of the classRules
		const classRules = Object.keys($.validator.classRuleSettings) || [];

		// Send classRules to select elements when JADE is enabled
		$(this).find('ba-select').each(function () {
			if (JADE_ENABLED) {
				const classList = Array.from(this.classList);
				const classSelectRules = classRules.filter(value => classList.includes(value));

				if (classSelectRules.length) {
					this.querySelector('select').classList.add(...classSelectRules);
				}
			}
			addClosestForm.call(this);
		});

		// set custom error messages for the remote check_email_exists method
		if (typeof options.customMessages == 'undefined') {
			customMessages = {};
		} else {
			customMessages = options.customMessages;
		}
		$(this).find('.checkEmailExists').each(function() {
			customMessages[$(this).attr('name')] = {remote: $.__('This email is already being used.')};
		});
		$(this).find('.ssn-field').each(function() {
			customMessages[$(this).attr('name')] = { remote: jQuery.validator.messages.check_ssn };
			$(this).attr('data-errorclass', 'ssn-field--error');
		});
		$(this).find('.sin-field').each(function() {
			customMessages[$(this).attr('name')] = {remote: $.__('There is already an employee with this SIN in the system.')};
		});
		$(this).find('.creditCardLast4Digits').each(function () {
			customMessages[$(this).attr('name')] = {remote: $.__('The field has too many characters.')};
		});
		defaultOptions = {
			/*
			 * any of these options can be overwritten on a page specific basis - be aware of this when modifying
			*/
			// debug: true, //will stop submission of form and log stuff to console
			// onfocusout: function(element) { console.log("onfocusout fired"); $(element).valid(); },
			// onkeyup: true,
			ignore: '.ignore',
			messages: customMessages,
			errorPlacement: function(error, element) {
				if (JADE_ENABLED) {
					// Call jadeHighlight
					jadeErrorPlacement(error, element);
					return;
				}

				var fieldBox;
				//if it's an email field or it has a cass of .inline-error, and it's not the default required error msg
				var isEmail = element.attr('type') == 'email' || element.hasClass('validateEmail');
				var hasInlineErrorClass = element.hasClass('inline-error') || $('label[for="' + element.attr('id') + '"]').hasClass('inline-error');
				if ((isEmail || hasInlineErrorClass) && error.html() != jQuery.validator.messages.required) {
					if (element.closest('form').hasClass('newFormDesign')) { //** DEPRECATED CLASS **//
						if (element.siblings('.fieldInfo').length > 0) {
							element.siblings('.fieldInfo').addClass('error').html(error.html());
						} else {
							element.closest('.fieldDiv').append('<span class="fieldInfo error">' + error.html() + '</span>');
						}

					} else if (element.closest('form').hasClass('BhrForms')) {
						fieldBox = null;
						//checks if inline-error is part of fieldRow with multiple fields, appends to last fieldbox
						if (element.closest('.fieldRow').find('.fieldBox:visible').length > 1) {
							fieldBox = element.closest('.fieldRow').find('.fieldBox:last-child');
						} else {
							fieldBox = element.closest('.fieldBox:visible');
						}
						//checks for an existing .formNote.error, and appends the error to the fieldBox
						if (fieldBox.find('.formNote').length > 0) {
							fieldBox.find('.formNote').text(error.html()).addClass('error');
						} else if (fieldBox.length === 1) {
							fieldBox.append('<span class="formNote error">' + error.html() + '</span>');
						}
					}
				}
			},
			highlight: function(element) {
				if (element) {
					element.dispatchEvent(new CustomEvent('validation', {
						bubbles: true,
						cancelable: true,
						detail: { isValid: false }
					}));
				}
				if (JADE_ENABLED) {
					// Call jadeHighlight
					jadeHighlight(element);
					return;
				}

				var $element = $(element);
				var $fieldBox = $element.closest('.fieldBox');
				var $form = $element.closest('form');
				var $mceWrapper = $element.closest('.mceWrapper--editor');
				if ($element.is('ba-select')) {
					$element.attr('error', '');
				}

				if ($mceWrapper.length == 1) {
					$mceWrapper.addClass('mceWrapper--error');
				} else {
					$fieldBox.addClass('error');
					$element.closest('.fieldGroup').addClass('validate-group-error');

					// checks for .inline-error class on the element or on the label
					if ($element.hasClass('inline-error') || $('label[for="' + $element.attr('id') + '"]').hasClass('inline-error')) {
						// check for custom error msg on the element or on the label
						var msg = $element.attr('data-error-msg') == undefined ? $('label[for="' + $element.attr('id') + '"]').attr('data-error-msg') : $element.attr('data-error-msg');
						if (msg == undefined) {
							return;
						}
						if ($form.hasClass('newFormDesign')) { //** DEPRECATED CLASS **//
							//checks for an existing .fieldInfo.error, and appends the error to the fieldDiv if it can find one, else adds a .fieldDiv with the error and appends it to the fieldBox
							if ($fieldBox.find('.fieldInfo').length > 0) {
								$fieldBox.find('.fieldInfo').text(msg).addClass('error');
							} else if ($element.closest('.fieldDiv').length == 1) {
								$element.closest('.fieldDiv').append('<span class="fieldInfo error">' + msg + '</span>');
							} else {
								$fieldBox.append('<div class="fieldDiv"><span class="fieldInfo error">' + msg + '</span></div>');
							}
						} else if ($form.hasClass('BhrForms')) {
							//checks for an existing .formNote.error, and appends the error to the fieldBox
							if ($fieldBox.find('.formNote').length > 0) {
								$fieldBox.find('.formNote').text(msg).addClass('error');
							} else if ($fieldBox.length == 1) {
								$fieldBox.append('<span class="formNote error">' + msg + '</span>');
							}
						}
					}
				}
			},
			unhighlight: function(element) {
				if (element) {
					element.dispatchEvent(new CustomEvent('validation', {
						bubbles: true,
						cancelable: true,
						detail: { isValid: true }
					}));
				}

				if (JADE_ENABLED) {
					// Call jadeUnhighlight
					jadeUnhighlight(element);
					return;
				}

				var $element = $(element);
				var $fieldBox = $element.closest('.fieldBox');
				var $fieldRow = $element.closest('.fieldRow');
				var $mceWrapper = $element.closest('.mceWrapper--editor');

				if (element && element.matches('ba-select[error]')) {
					element.removeAttribute('error', '');
				}

				if ($mceWrapper.length == 1) {
					$mceWrapper.removeClass('mceWrapper--error');
				} else {
					$fieldBox.removeClass('error');
					//only remove error note if all errors on row are resolved
					if ($fieldRow.find('.fieldBox.error').length == 0) {
						$fieldRow.find('.fieldInfo.error').removeClass('error').empty();
						$fieldRow.find('.fieldBox:last-child .formNote.error').removeClass('error').empty();
						$fieldBox.find('.formNote.error').removeClass('error').empty();
					}
					//remove error from fieldGroup if no other .error classes exist within the fieldGroup
					if ($element.closest('.fieldGroup.validate-group-error').find('.error').length == 0) {
						$element.closest('.fieldGroup').removeClass('validate-group-error');
					}
				}
			},
			invalidHandler: function(event, validator) {
				let errorList = validator.errorList;
				let errorMap = validator.errorMap;

				if (options.debug) {
					console.log(errorList);
				}

				if (errorList.length > 0) {
					const message = (typeof defaultError === 'function') ? defaultError(errorList, defaultErrorMessage) : defaultError;
					let summary = $('<div></div>');

					if (options.debug) {
						console.log(errorMap);
						errorList.forEach(error => console.log(error));
					}

					summary.html(message);
					setMessage(summary.html(),'error');
					validator.errorList[0].element.focus();

					// make sure we re-enable submit button
					$('.btn.processing').toggleProcessing(false);
				}
			},
			focusInvalid: false,
			onkeyup: function(element) {
				//by default, we can't set "onkeyup: false" as a rule on a per field basis. This modification allows for that.
				//if you want to turn onkeyup off for the whole form, passing it to bhrValidate should overwrite this function.
				var element_id = $(element).attr('id');
				var element_name = $(element).attr('name');
				var rule = this.settings.rules[element_id] == undefined ? this.settings.rules[element_name] : this.settings.rules[element_id];
				if (rule != undefined && rule.onkeyup !== false) {
					$.validator.defaults.onkeyup.apply(this, arguments);
				}
			},
			/**
			 * Use the submitHandler to automatically submit via
			 * ajax when the form has a data-ajax-method attribute
			 */
			submitHandler: function(form, ...args) {
				if (
					isFunction(options.submitHandler) &&
					options.submitHandler(form, ...args) === false
				) {
					// if there's a one-off submitHandler passed in options,
					// and it returns false, the stop here
					return;
				}

				const {
					ajaxMethod,
				} = form.dataset;
				const $form = $(form);
				const $buttons = $form.find('.btn.btnAction');

				// disable the action button(s)
				$buttons.toggleProcessing(true);

				// submit the form via ajax
				Ajax.request({
					url: form.action,
					method: ajaxMethod,
					data: $form.serialize(),
				})
					.then((response) => {
						const {
							success,
							successMessage,
						} = response.data || {};

						if (!success) {
							throw Ajax.createError(response);
						}

						// trigger a custom event
						const isCancelled = !form.dispatchEvent(new CustomEvent(
							'ajaxSubmit',
							{
								bubbles: true,
								cancelable: true,
								detail: response,
							}
						));

						// don't do the other stuff if the custom
						// event has been cancelled
						if (isCancelled) {
							return;
						}

						// re-enable action button(s)
						$buttons.toggleProcessing(false);
						// show the successMessage from the BE
						if (isString(successMessage)) {
							setMessage(successMessage, 'success');
						}
					})
					.catch((e) => {
						// trigger a custom event
						const isCancelled = !form.dispatchEvent(new CustomEvent(
							'ajaxSubmitFail',
							{
								bubbles: true,
								cancelable: true,
								detail: e.response,
							}
						));

						// don't do the other stuff if the custom
						// event has been cancelled
						if (isCancelled) {
							return;
						}

						let {
							error,
							errorMessage,
							validationErrors,
						} = e.response.data;

						// show the errors
						error = error || e.message || e.response.statusText;
						console.error(error, e);

						errorMessage = e.response.errorMessage || errorMessage || defaultErrorMessage;
						if (isString(errorMessage)) {
							setMessage(errorMessage, 'error');
						}

						// re-enable the action button(s)
						$buttons.toggleProcessing(false);

						// if the BE passes back validationErrors,
						// show them with the validator
						if (isPlainObject(validationErrors)) {
							this.showErrors(validationErrors);
						}
					});
			},
		};

		$(this).closest('form')
			.on('submit', function() {
				$(this).data('validation-submitting', true);
			});

		let validator;

		$(this).each((i, form) => {
			const $form = $(form);
			let ajaxMethod = $form.attr('data-ajax-method');

			validator = $form.validate({
				...defaultOptions,
				...options,

				// only use default submitHandler if data-ajax-method is present
				submitHandler: ajaxMethod ? defaultOptions.submitHandler : (options.submitHandler || false),
			});
		});

		//add required rules to items with .required classes
		$('.required :input:not(.requiredWhenVisible), .required ba-select:not(.requiredWhenVisible)',$(this)).each(function() { /*console.log('adding required to: ' + $(this));*/ $(this).rules('add',{ required: true }); });

		//by default, the validator considers <option value="0"></option> to be valid for selects because of the 0.
		//this rule ignores zero for all selects, making the required select not valid for a 0 value
		$('.required select:not(.requiredWhenVisible)',$(this)).each(function() { /*console.log('adding required to: ' + $(this));*/ $(this).rules('add',{ excludeZero: true }); });

		$('.required.noZeroAllowed input',$(this)).each(function() { $(this).rules('add',{ excludeZero: true }); });
		//add fileUploader method to the hidden input for required file uploaders
		//**note: this method currently doesn't handle multiple required file uploaders on a single page as each hidden input would need a unique name. But I don't think we're doing that anywhere.
		$('input[name=uploadValidatorHiddenInput]',$(this)).each(function() { $(this).rules('add',{ fileUploader: true }); });

		// Add GroupedCheckboxes rules on init of BHRValidate (was previously on page load, but some forms are dynamic and not available on load)
		$('.visuallyRequired.multipleCheckbox', $(this)).each(function() {
			var rule = $(this).find('input[type=checkbox]').attr('data-checkbox_id') || false;
			if (rule) {
				$('.' + rule).each(function() {
					$(this).rules('add', {
						require_from_group_when_visible: [1, '.' + rule]
					});
				});
			}
		});

		//Setup conditionally required
		$('select.conditionallyRequired, input.conditionallyRequired', $(this)).each(function() {
			let $this = $(this);
			let elementName = this.name; //grab the name and use it below in case the field is dynamically changed and added/removed from the DOM
			let $requiredByElement = $($this.data('conditionally-required-selector'));
			$this.rules('add', { conditionallyRequired: true });

			$requiredByElement.change(function() {
				conditionallyRequired($('[name="' + elementName + '"]'), $(this));
			});

			//Run now to see if the field should be required.
			conditionallyRequired($this, $requiredByElement);
		});

		$('ba-select.conditionallyRequired', $(this)).each(function() {
			let $this = $(this);
			//grab the name and use it below in case the field is dynamically changed and added/removed from the DOM
			let elementName = this.getAttribute('name');
			let $requiredByElement = $(this.dataset.conditionallyRequiredSelector);

			let $selectElem = $this.find(`select[name='${ elementName }']`);

			$selectElem.rules('add', { conditionallyRequired: true });

			//Run now to see if the field should be required.
			conditionallyRequired($this, $requiredByElement);
		});

		//This is a band-aid fix for primary contacts (BUGS-10678) - we should consider having a rule for one in group required but that probably depends on demand for such things
		if ($('.primaryContact:checked.requiredWhenVisible').length > 0) {
			const $selectedElement = $('.primaryContact:checked.requiredWhenVisible');
			$('.primaryContact').removeClass('requiredWhenVisible');
			$selectedElement.addClass('requiredWhenVisible');
		}

		/**
		 * For Trax Companies
		 * We need to initially validate SSN and Pay Schedule so the form is in sync with whether the user is in a Syncing PS or not
		 * Done globally for all FE validation instead of by form for consistency and better user experience
		 *
		 * Don't run on view forms, wait for click -> done in global/forms.js
		 */
		if (!$(this).hasClass('BhrForms--view')) {
			// timeout ensures ba-selects and rule application are finished processing
			setTimeout(() => {
				$(this).initialTraxValidation();
			});
		}

		/**
		 * this revalidates the chosen selects on change so it takes away the .error class
		 * when a select has a custom on change, it will get overridden unless you add the .customOnChange class to it
		 * then manually call $(this).valid() in the custom change method.
		 * UPDATE - changed this to also validate on blur for all :input fields, the onfocusout is supposed to
		 * greedy validate out of the box for the jquery validator, but it quit working - possibly due to a jquery update?
		 * the onfocusout method in jquery.validate.1.12.0.js doesn't get called on blur either.
		 */
		//
		$(this)
			.on('change keyup paste', '.checkEmailExists', debounce(function() {
				$(this).valid();
			}, 600))
			.on('change', '.required .chzn-done:not(.customOnChange),.required select[multiple], .required textarea, :input:not(.custom-mce-validation), ba-select:not(.custom-mce-validation)' , function(e) {
				if (!$(this).is('ba-select')) {
					$(this).valid(); //re-validates chosen selects on blur
				}
			})
			.on('ba:selectChange', function(e) {
				if (JADE_ENABLED) {
					const nativeSelect = e.target.querySelector('select');

					if (nativeSelect) {
						window.setTimeout(() => { $(nativeSelect).valid(); });
					}

					return;
				}
				window.setTimeout(() => { $(e.target).valid(); });
			})
			.on('invalid-form.bhrValidate', function() {
				$(document).trigger(...arguments);
			});

		return validator;
	};
})(jQuery);
jQuery.extend(jQuery.validator.messages, {
	required: $.__('This field is required.'),
	remote: $.__('Please fix this field.'),
	email: $.__('Invalid email address.'),
	url: $.__('Please enter a valid URL.'),
	urlHttpsRequired: $.__('Please enter a valid HTTPS URL.'),
	date: $.__('Please enter a valid date.'),
	dateField: $.__('Please enter a valid date.'),
	dateISO: $.__('Please enter a valid date (ISO).'),
	number: $.__('Please enter a valid number or remove any leading zeros.'),
	employeeNumber: $.__('Please enter a valid number.'),
	noLeadingZeros: $.__('Value must not start with a 0.'),
	digits: $.__('Please enter only digits.'),
	creditcard: $.__('Please enter a valid credit card number.'),
	equalTo: $.__('Please enter the same value again.'),
	accept: $.__('Please enter a value with a valid extension.'),
	textOnly: $.__('Please enter a value with only alphabetic characters.'),
	maxLengthDigits: jQuery.validator.format($.__('Please enter no more than %1 digits.','{0}')),
	maxlength: jQuery.validator.format($.__('Please enter no more than %1 characters.','{0}')),
	minlength: jQuery.validator.format($.__('Please enter at least %1 characters.','{0}')),
	rangelength: jQuery.validator.format($.__('Value must be between %1 and %2 characters long.','{0}','{1}')),
	range: jQuery.validator.format($.__('Value must be between %1 and %2.','{0}','{1}')),
	max: jQuery.validator.format($.__('Value must be less than or equal to %1.','{0}')),
	min: jQuery.validator.format($.__('Value must be greater than or equal to %1.','{0}')),
	require_from_group: jQuery.validator.format($.__('At least %1 of these fields is required.','{0}')),
	require_from_group_when_visible: jQuery.validator.format($.__('At least %1 of these fields is required.','{0}')),
	check_email_exists: jQuery.validator.format($.__('This email is already being used.')),
	check_ssn: jQuery.validator.format(
		window.SESSION_USER && window.SESSION_USER.isAdmin ?
		$.__('Oops, this is not a valid SSN or it is a duplicate of another SSN in your account.') :
		$.__('Oops! Double check the numbers you entered. If everything is correct please contact your administrator.')
	),
	check_ssn_exists: jQuery.validator.format($.__('This SSN is already being used.')),
	check_sin_exists: jQuery.validator.format($.__('This SIN is already being used.')),
	check_nin_exists: jQuery.validator.format($.__('This NIN is already being used.')),
	check_national_id_exists: jQuery.validator.format($.__('This National ID is already being used.')),
	greaterThan: jQuery.validator.format($.__('Value must be greater than %1.','{0}')),
	valueGreaterThanZero: jQuery.validator.format($.__('Value must be greater than 0')),
	currencyTypeMatch: jQuery.validator.format($.__('Currency must be %1', '{0}')),
	lessThan: jQuery.validator.format($.__('Value must be less than %1.','{0}')),
	regex: jQuery.validator.format($.__('This value is not valid.')),
	sameSiblingValue: jQuery.validator.format($.__('These two values cannot be the same.')),
	sameRowValue: jQuery.validator.format($.__('These values cannot be the same.')),
	futureDate: jQuery.validator.format($.__('This must be a future date.')),
	todayAndFuture: jQuery.validator.format($.__('This must be today or a future date.')),
	fromToDatesOrder: jQuery.validator.format($.__('Beginning date must be before end date.')),
	minDate: jQuery.validator.format($.__('Please enter a valid date.')),
	maxDate: jQuery.validator.format($.__('Please enter a valid date.')),
	isCurrency: jQuery.validator.format($.__('This currency must be %1.', '{0}')),
	isCountryCode: jQuery.validator.format($.__('Only US employees can be included in payroll.', '{0}')),
	isPayType: jQuery.validator.format($.__('Pay type must be hourly or salary to include this employee in payroll.')),
	USZip: jQuery.validator.format($.__('Please enter a valid US Zip code.'))
});

jQuery.validator.addMethod('bambooEmailValidator', function (value, element) {
	// "this.optional" is a method call in the jquery validator. This method allows the validation of non-required
	// email fields that are empty or have empty strings. Otherwise, use our email validation logic
	return this.optional(element) || BambooHR.Utils.validateEmail(value);
}, jQuery.validator.messages.email);

jQuery.validator.addMethod('check_email_exists', function(value, element, param) {
	if (value == '') {
		return true;
	}

	if ($(element).hasClass('requiredWhenVisible') && !$(element).is(':visible')) {
		return true;
	}

	param.data.id = getEmployeeId(element);
	param.data.userId = $(element).data('userid'); // userid is used/set when converting ATS to Emp. Only used on Email fields because ATS created user in the DB.
	param.data.email = value;
	param.data.action = 'checkUserEmail';
	param.data.validate = true;
	param.type = 'POST';
	return jQuery.validator.methods.remote.call(this, value, element, param);
});

jQuery.validator.addMethod('check_ssn', function(value, element, param) {
	// do not check defaultValue -> BE validation needs to run always to catch PS changes on other pages or previous bad data before ssnValidation toggle.
	if (value == '') {
		return true;
	}
	if ($(element).hasClass('requiredWhenVisible') && !$(element).is(':visible')) {
		return true;
	}
	let validator = this;
	if (this.settings.ignoreSSNField) {
		return true;
	}
	let newparam = {},
		PSField = isFieldInForm(element, 'select.js-paySchedule');

	newparam.url = getValidationUrl();
	newparam.data = {};
	newparam.data.id = getEmployeeId(element);
	newparam.data[param.data.check_field] = value;
	newparam.data.action = param.data.check_action;
	newparam.type = 'POST';
	newparam.data.validate = true;

	if (PSField) {
		// if Pay Schedule is also part of the form, validate SSN with current PS Value not DB
		newparam.data.payScheduleListValueId = PSField.val();
		// SSN changes validity based on PS. If also in the form force this validator to always run
		element.defaultValue = `nomatch-${window.BambooHR.Utils.makeUid()}`;
	}

	newparam.complete = function (data) {
		// prevents warning modal showing on initial validation -> also, cleanup data
		const isInitialValidation = $(element).data('isInitialValidation');
		$(element).removeData('isInitialValidation');
		// force validation is set for EE Personal. But only show the modal if 001 is new, not previous.
		// BE will still catch if mis-match on syncing PS
		if (!isInitialValidation && value === '001-00-0000' && !validator.settings.isNHPPage && value !== element.defaultValue) {
			SSNWarnSyncModal();
		}

		if (hasTrax() && isSelectedPSSyncing(element)) {
			// set valid state on PaySchedule if it is also in the form and syncing
			const isValid = data.responseText == true || data.responseText === 'true';
			setValidStateIfInForm(element, 'select.js-paySchedule', validator, isValid);
		}
	};
	return jQuery.validator.methods.remote.call(this, value, element, newparam);
}, jQuery.validator.messages.check_ssn);

jQuery.validator.addMethod('check_ssn_exists', function(value, element, param) {
	if (value == '' || value == element.defaultValue) {
		return true;
	}
	if ($(element).hasClass('requiredWhenVisible') && !$(element).is(':visible')) {
		return true;
	}
	let previous = this.previousValue(element),
		validator = this;
	if (this.settings.ignoreField) {
		return true;
	}
	let newparam = {};
	newparam.url = param.url || getValidationUrl();
	newparam.data = {};
	newparam.data.id = $(element).data('employeeid');
	newparam.data[param.data.check_field] = value;
	newparam.data.action = param.data.check_action;
	newparam.data.validate = true;
	newparam.type = 'POST';
	newparam.complete = function () {
		if (validator.invalid[element.name]) {
			validator.startRequest(element);
			let field_name = (param.data.friendly_name || param.data.check_field).toUpperCase();
			let msg = $.__('There is already an employee with this %1 in the system. Are you sure you want to create another one?', field_name);

			if (JADE_ENABLED) {

				const modalOpen = window.BambooHR.Modal.isOpen();

				let globalModalOptions = {};
				let modalOptions = {
					dangerousContent:
						`<div className="ValidationModal">
							<span className="ValidationModal__alertIcon">
								<ba-icon name="triangle-exclamation-12x11"></ba-icon>
							</span>
							<p className="ValidationModal__message">
								${ msg }
							</p>
						</div>`,
					primaryAction: () => {
						let submitted = validator.formSubmitted;
						validator.prepareElement(element);
						validator.formSubmitted = submitted;
						validator.successList.push(element);
						delete validator.invalid[element.name];
						previous.valid = true;
						validator.showErrors();
						validator.hideErrors();
						//this call shouldn't be necessary because of the two calls above, but seems to be anyway.
						validator.settings.unhighlight.call(validator, element, validator.settings.errorClass, validator.settings.validClass);
						validator.stopRequest(element, true);

						if (modalOpen) {
							window.BambooHR.Modal.setState({ sheetProps: { isOpen: false } }, true);
						} else {
							window.BambooHR.Modal.setState({ isOpen: false });
						}
					},
					primaryActionText: $.__('Yes'),
					isOpen: true,
					title: $.__('Duplicate %1', field_name)
				};

				if (modalOpen) {
					globalModalOptions = {
						isOpen: true,
						sheetProps: modalOptions
					};
				} else {
					globalModalOptions = modalOptions;
				}

				window.BambooHR.Modal.setState(globalModalOptions, modalOpen);
			} else {
				SimpleModal.openModal({
					footer: {
						buttons: {
							primary: {
								action: function () {
									let submitted = validator.formSubmitted;
									validator.prepareElement(element);
									validator.formSubmitted = submitted;
									validator.successList.push(element);
									delete validator.invalid[element.name];
									previous.valid = true;
									validator.showErrors();
									validator.hideErrors();
									//this call shouldn't be necessary because of the two calls above, but seems to be anyway.
									validator.settings.unhighlight.call(validator, element, validator.settings.errorClass, validator.settings.validClass);
									validator.stopRequest(element, true);
									SimpleModal.closeModal();
								},
								text: $.__('Yes'),
							}
						},
						nostack: false,
						show: true
					},
					html: `
						<div class="ValidationModal">
							<span class="ValidationModal__alertIcon">
								<ba-icon name="triangle-exclamation-12x11"></ba-icon>
							</span>
							<p class="ValidationModal__message">
								${ msg }
							</p>
						</div>
				`,
					title: $.__('Duplicate %1', field_name)
				});
			}
		}
	};
	return jQuery.validator.methods.remote.call(this, value, element, newparam);
});

let addedSelectedSyncClasses = false;

jQuery.validator.addMethod('check_pay_schedule', function(value, element, param) {
	/*
	*	Before any validation, mark fields required if syncing
	*/
	handleTraxRequiredFields(element, value);

	/**
	 * Validate Pay Rate and Overtime Rate based on PS
	 */
	const $rateElements = $('.js-validate-payRate-trax, .js-validate-overtimeRate-trax');
	if (isSelectedPSSyncing(element, value) && $rateElements.length > 0) {
		$rateElements.addClass('syncingScheduleSelected');
		if (!addedSelectedSyncClasses) {
			addedSelectedSyncClasses = true;
			$rateElements.valid();
		}
	} else {
		$rateElements.removeClass('syncingScheduleSelected');
		if (addedSelectedSyncClasses) {
			$rateElements.valid();
		}
		addedSelectedSyncClasses = false;
	}

	/*
	*	The BE endpoint returns true or false
	*	Pay Schedule is valid if the SSN is valid (EXCEPTION: 001-00-0000 is valid ssn, but fails check_pay_schedule validation!!! <- Beware and take care)
	*	Pay Schedule cannot be set to a syncing Pay Schedule and have an invalid SSN
	*	This works closely with check_ssn
	*	It also works differently if ssn is also in the form
	*	It also works differently for internal and subscription accounts
	*/

	if (hasTrax()) {
		// Only continue if not editing a new employee
		if (window.currentlyEditingEmployeeId === 0) {
			return true;
		}

		if (value == '') {
			return true;
		}

		if ($(element).hasClass('requiredWhenVisible') && !$(element).parents('ba-select').is(':visible')) {
			return true;
		}

		let previous = this.previousValue(element),
			validator = this,
			ssnField = isFieldInForm(element, '.primary-ssn-field');

		if (ssnField) {
			// if ssn is also part of the form, validate PS with current SSN Value not DB
			param.data.ssn = ssnField.val();
		}

		param.data.payScheduleId = null;
		param.data.payScheduleListValueId = value;
		param.data.id = getEmployeeId(element);
		param.data.userId = getEmployeeId(element);
		param.data.validate = true;
		param.type = 'POST';
		param.complete = function (data) {
			const isValid = data.responseText == true || data.responseText === 'true';
			// ba-select and validator aren't in sync - Do manual validation based on the return data
			setTimeout(() => {
				setValidState(element, value, validator, previous, isValid);
			},10); // ensure it is after all other events being fired by ba-select
			if (!isValid) {
				const selectedPaySchedule = $(element).parents('ba-select').find('ba-option[selected]').text();
				SSNInvalidSyncModal(selectedPaySchedule);
			}

			// Internal Account: Syncing/non-syncing 001-00-0000 is valid
			// Non Internal Account: Syncing 001 is invalid. Non-syncing Valid.
			setValidStateIfInForm(element, '.primary-ssn-field', validator, isValid);
		};
		return jQuery.validator.methods.remote.call(this, value, element, param);
	}
	return true;
});

jQuery.validator.addMethod('check_sin_exists', function(value, element, param) {
	return jQuery.validator.methods.check_ssn_exists.call(this, value, element, param);
});

jQuery.validator.addMethod('check_nin_exists', function(value, element, param) {
	return jQuery.validator.methods.check_ssn_exists.call(this, value, element, param);
});

jQuery.validator.addMethod('check_national_id_exists', function(value, element, param) {
	return jQuery.validator.methods.check_ssn_exists.call(this, value, element, param);
});

if (JADE_ENABLED) {
	jQuery.validator.addMethod('bambooRichText', function(value, element) {
		const $editorElement = $(element).closest('.js-bamboo-rich-text').find('.js-bamboo-rich-text-editor');
		if ($editorElement[0]) {
			const editorInstance = window.tinyMCE.get($editorElement.attr('id')); // tinyMCE.get requires retrieval by id
			if (editorInstance) {
				return !!editorInstance.getContent();
			}
		}
	},jQuery.validator.messages.required);
} else {
	jQuery.validator.addMethod('mceEditor', function(value, element, param) {
		if (param && typeof tinyMCE != 'undefined' && $(element).attr('id') && tinyMCE.get($(element).attr('id'))) {
			return tinyMCE.get($(element).attr('id')).getContent() != '';
		}
	},jQuery.validator.messages.required);
}

jQuery.validator.addMethod('maxLengthDigits', function(value, element, param) {
	return value.length <= param;
},jQuery.validator.messages.maxLengthDigits);

jQuery.validator.addMethod('textOnly', function (value, element, param) {
	return value.match(/^[A-Za-z .]+$/);
}, jQuery.validator.messages.textOnly);

jQuery.validator.addMethod('validateUSZip', function(value, element, param) {
	return isValidUSZipCode(value);
}, jQuery.validator.messages.USZip);

jQuery.validator.addMethod('validateUSZipTrax', function(value, element, param) {
	const self = this;
	if (!hasTrax() || element.classList.contains('contact_zip') || !$(element).is(':visible')) {
		return true;
	}

	const $payScheduleSelect = $(element).closest('form').find('ba-select.js-paySchedule');
	const selectedValue = ($payScheduleSelect.length > 0) ? $payScheduleSelect[0].value : null;
	if ($payScheduleSelect.length > 0) {
		return (isSelectedPSSyncing($payScheduleSelect, selectedValue)) ?
			jQuery.validator.methods.validateUSZip.call(self, value, element, param) : true;
	}

	if (window.Employee && window.Employee.payrollSynced) {
		return jQuery.validator.methods.validateUSZip.call(self, value, element, param);
	}

	return true;

}, jQuery.validator.messages.USZip);

jQuery.validator.addMethod('currencyTypeMatchTrax', function(value, element, param) {
	if ($(element).syncingPayScheduleSelected() && hasTrax()) {
		return jQuery.validator.methods.currencyTypeMatch.call(this, value, element, param)
	}

	return true;
}, jQuery.validator.messages.currencyTypeMatch);

jQuery.validator.addMethod('currencyTypeMatch', function(value, element, param) {

	if (!$(element).is(':visible')) {
		return true;
	}

	let $currencySelect = $(element).siblings('input.currencyType');

	if ($currencySelect.length === 0) {
		$currencySelect = $(element).closest('.fieldRow, .fab-FormRow').find('.js-CurrencyPicker ').find('input.currencyType');
	}
	const selectedCurrencyCode = ($currencySelect.length > 0) ? $currencySelect.val() : '';

	const valid = selectedCurrencyCode === param;
	const {
		Employee = {}
	} = window;

	const name = Employee.firstName || 'an employee';

	if (!valid) {
		window.setMessage($.__('Oops, please make sure the pay rate is in %1 before adding %2 to a pay schedule that syncs with BambooHR Payroll.', param, name), 'error')
	}

	return valid;
}, jQuery.validator.messages.currencyTypeMatch);

jQuery.validator.addMethod('valueGreaterThanZeroTrax', function (value, element, param) {
	if ($(element).syncingPayScheduleSelected() && hasTrax()) {
		return jQuery.validator.methods.valueGreaterThanZero.call(this, value, element, param)
	}

	return true;
}, jQuery.validator.messages.valueGreaterThanZero);

jQuery.validator.addMethod('valueGreaterThanZero', function (value, element, param) {
	if (!$(element).is(':visible')) {
		return true;
	}

	return fixCurrencyValue(value) > 0;
}, jQuery.validator.messages.valueGreaterThanZero);

jQuery.validator.addMethod('noLeadingZeros', function(value, element, param) {
	return value == '' || value.charAt(0) != 0;
},jQuery.validator.messages.noLeadingZeros);

/**
* For the file uploader. See if there are any files in the .files wrapper with .uploadDone status
*/
jQuery.validator.addMethod('fileUploader', function(value, element, param) {
	return $(element).closest('.attach-wrapper').siblings('.files').find('.file-wrapper.uploadDone').length > 0;
},$.__('Please upload a file.'));

jQuery.validator.addMethod('passwordValidate', function(value, element, param) {
	return (element.value).match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
});
jQuery.validator.addMethod('excludeZero', function(value, element, param) {
	return element.value != 0;
});
jQuery.validator.addMethod('greaterThan', function(value, element, param) {
	return element.value > param;
},jQuery.validator.messages.greaterThan);
jQuery.validator.addMethod('lessThan', function(value, element, param) {
	return element.value < param;
},jQuery.validator.messages.lessThan);
jQuery.validator.addMethod('regex', function(value, element, param) {
	var re = new RegExp(param);
	return this.optional(element) || re.test(value);
},jQuery.validator.messages.regex);
/**
 * this over-writes the one in jquery.validate.js to check for the different number
 * formats we allow. GLOBAL_NUMBER_REGEX is set in the header and comes from BLocale->getNumberRegEx()
 */
jQuery.validator.addMethod('number', function(value, element, param) {
	var re = new RegExp(GLOBAL_NUMBER_REGEX);
	return this.optional(element) || re.test(value);
},jQuery.validator.messages.number);
/**
 * Allow employee numbers to have leading zeros and validate differently than regular
 * number fields because they do act and behave differently.
 */
var employeeNumberRegex = GLOBAL_NUMBER_REGEX.replace('(?!0[0-9])', '');
jQuery.validator.addMethod('employeeNumber', function(value, element, param) {
	return this.optional(element) || (new RegExp(employeeNumberRegex)).test(value);
},jQuery.validator.messages.employeeNumber);
/**
 * Overwriting the min and max functions so it follows our locale schemes.
 */
jQuery.validator.addMethod('min', function(value, element, param) {
	return this.optional(element) || getFloatFromStringJS(value) >= param;
},jQuery.validator.messages.min);
jQuery.validator.addMethod('max', function(value, element, param) {
	return this.optional(element) || getFloatFromStringJS(value) <= param;
},jQuery.validator.messages.max);
/**
 * this differs from the one in jquery.validate.js in that it makes the http:// optional
 */
jQuery.validator.addMethod('urlHttpOptional',function(value,element,param) {
	return this.optional(element) || (/^((https?|s?ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i).test(value);
},jQuery.validator.messages.url);
jQuery.validator.addMethod('urlHttpsRequired',function(value,element,param) {
	return this.optional(element) || (/^((https):\/\/)(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i).test(value);
},jQuery.validator.messages.urlHttpsRequired);
jQuery.validator.addMethod('essRequiredFields', function(value, element, param) {
	var hasEmail = false;
	if ($('.checkEmailExists').length > 0) {
		$('.checkEmailExists').each(function(index, el) {
			if ($(el).val() !== '' && validateEmail($(el).val())) {
				hasEmail = true;
			}
		});
	}
	var status;
	if (typeof status == 'undefined') {
		// default to active for new employees
		status = 'Active';
	}
	var employeeStatus = $('.employee-status-field').val() || status;
	return (hasEmail && employeeStatus === 'Active') || !element.checked;

});
jQuery.validator.addMethod('sameSiblingValue', function(value, element, param) {
	let $element = $(element);
	let $siblings = $element.siblings(param);

	if ($element.closest('.fieldBox').is(':hidden') && $siblings.closest('.fieldBox').is(':hidden')) {
		return true;
	}

	let siblingValue = $siblings.val();
	return value !== siblingValue;
}, jQuery.validator.messages.sameSiblingValue);


jQuery.validator.addMethod('sameRowValue', function(value, element, selector) {
	const rowSelector = '.formRow, .fab-FormRow';
	const fieldSelector = '.fieldBox, fab-FormField';
	const $element = $(element);
	const $closestRow = $element.closest(rowSelector);
	const $otherElement = $closestRow.find(selector);

	if ($element.closest(fieldSelector).is(':hidden') && $otherElement.closest(fieldSelector).is(':hidden')) {
		return true;
	}

	return $element.val() !== $otherElement.val();
}, jQuery.validator.messages.sameRowValue);

/**
 * A method to validate whether a field is valid or not depending on the state of another field. Will be called when the form is initialized as well
 * as when the conditionally required field is validated.
 *
 * Requires the 2 following data attributes to be present on the conditionally required field:
 * - data-conditionally-required-selector (a jQuery selector for the field that will be relied upon)
 * - data-conditionally-required-values   (a comma separated list of values on the data-conditionally-required-selector to require the $element)
 *
 * @param $element           The element to be validated.
 * @param $requiredByElement The element that $element is reliant upon.
 *
 * @returns {boolean}
 */
function conditionallyRequired($element, $requiredByElement) {
	if ($requiredByElement[0] && $requiredByElement[0].tagName === 'BA-SELECT') {
		const element = $element[0];
		const elementSelect = element.querySelector('select');
		const elementValue = elementSelect ? elementSelect.value : '';

		const requiredByValue = $requiredByElement[0].querySelector('select').value;
		const requiredByFieldHasRequiredValue = $element.data('conditionally-required-values') ? $element.data('conditionally-required-values').split(',').indexOf(requiredByValue) >= 0 : false;

		return !$element.is(':visible') || //Has to be visible to check conditionally required. Use the fieldbox since chosen selects are hidden.
			!requiredByFieldHasRequiredValue || //not in the required list so it doesn't need a value
			elementValue.length > 0 //In the required list so it needs a value
		;

	}
	let $fieldBox = $element.closest('.fieldBox');
	let requiredByFieldHasRequiredValue = $element.data('conditionally-required-values') ? $element.data('conditionally-required-values').split(',').indexOf($requiredByElement.val()) >= 0 : false;

	$fieldBox.removeClass('error');
	if (requiredByFieldHasRequiredValue) {
		$fieldBox.addClass('required');
	} else {
		$fieldBox.removeClass('required');
	}

	return !$fieldBox.is(':visible') || //Has to be visible to check conditionally required. Use the fieldbox since chosen selects are hidden.
			!requiredByFieldHasRequiredValue || //not in the required list so it doesn't need a value
			$element.val().length > 0 //In the required list so it needs a value
	;


}
jQuery.validator.addMethod('conditionallyRequired', function(value, element) {
	const $element = JADE_ENABLED && element.tagName === 'SELECT' ? $(element).closest('ba-select') : $(element);

	return conditionallyRequired($element, $($element.data('conditionally-required-selector')));
});

jQuery.validator.addMethod('futureDate', function(value, element, param) {
	if (!$(element).is(':visible')) {
		return true;
	}

	var today = new Date();
	var thisYear = today.getFullYear();
	//    var expMonth = +value.substr(0, 2);
	//    var expYear = +value.substr(3, 4);
	var expMonth = $(param.monthSelector).val();
	var expYear = $(param.yearSelector).val();

	var valid = (expMonth >= 1 &&
			expMonth <= 12 &&
			(expYear >= thisYear && expYear < thisYear + 20) &&
			(expYear == thisYear ? expMonth >= (today.getMonth() + 1) : true));
	if (valid) {
		/*if($(element).attr('name') == $(param.yearSelector).attr('name')){
			$(element).find('.fieldBox').removeClass('error');
			$(param.monthSelector).valid();
		} else {
//			$(param.yearSelector).valid();
		}*/
	}
	return valid;
},jQuery.validator.messages.futureDate);
jQuery.validator.addMethod('todayAndFuture', function(value, element, param) {
	if (value == $(element).attr('placeholder')) {
		return true;
	}
	var today = new Date();
	var selectedDate = new Date($(element).val());
	today.setHours(0, 0, 0, 0);
	return this.optional(element) || selectedDate >= today;
},jQuery.validator.messages.todayAndFuture);
jQuery.validator.addMethod('multiSelectQuantityCheck', function(value, element, param) {
	return value.length >= $(element).attr('data-quantity-required');
});

jQuery.validator.addMethod('validateSelfServiceEmailRequired',
	function(value, element, param) {
		var isRequired = ($('.ess-field').length >= 1 && $('.ess-field:checked').val() == 'enabled');
		if (isRequired) {
			var nonEmpties = 0;
			$('.js-validate-ss-required').each(function(idx,element) {
				if (element.value != '') {
					nonEmpties++;
				}
			});
			// It's still valid if at least one field is filled.
			return nonEmpties > 0;
		}
		// If it isn't required then we're valid.
		return true;
	}, '');

jQuery.validator.addMethod('validateTraxEmailRequired',
function(value, element, param) {
	var isRequired = hasTrax();
	if (isRequired) {
		var nonEmpties = 0;
		$('.js-validate-ss-required').each(function(idx,element) {
			if (element.value != '') {
				nonEmpties++;
			}
		});
		// It's still valid if at least one field is filled.
		return nonEmpties > 0;
	}
	// If it isn't required then we're valid.
	return true;
}, '');

/**
 * From and To dates must have data-calendar-id set to the same number
 * The from field has the class js-validate-from-date
 * The to field has the class js-validate-to-date
 */
jQuery.validator.addMethod('validateDateFrom_to', function (value, element) {
	var $elem = $(element);

	if ($elem.val() === '') {
		return true;
	}

	var toDate = moment.$($elem),
		fromDate = moment.$('input.js-validate-from-date[data-calendar-id=' + $elem.data('calendarId') + ']');

	return (
		toDate.isValid() &&
		fromDate.isValid() &&
		toDate.isAfter(fromDate, 'day')
	);

}, jQuery.validator.messages.fromToDatesOrder);

jQuery.validator.addMethod('validateToggleButton', function (value, element) {
	var parentDiv = $(element).closest('.fieldDiv');
	if (parentDiv.find('.currency-field').val() == '' && parentDiv.find('.percent-field').val() == '') {
		return false;
	}
	return true;
});

jQuery.validator.addMethod('validateInlineEdit', function (value, element) {
	if (!$(element).closest('[saving-inline="true"]').length) {
		return false;
	}
	return jQuery.validator.methods.required.apply(this, arguments);
});

//validate using moment.js to verify its a real date
jQuery.validator.addMethod('validateDateField', function(value, element, param) {
	return value === '' || moment.$(element).isValid();
},jQuery.validator.messages.dateField);

/**
 * validate entered date is on or after min date when typed in
 * required data-mindate attr on input field
 *
 * Possible false positive! Make sure you are checking validateDateField on your input
 */
jQuery.validator.addMethod('validateMinDate', function (value, element) {
	var $elem = $(element),
		edate = moment.$(element),
		mindate = moment($elem.data('mindate') || $elem.datepicker('option', 'minDate'));

	return edate.isValid() && edate.isSameOrAfter(mindate, 'day');
},jQuery.validator.messages.minDate);

/**
 * validate entered date is on or before max date when typed in
 * required data-maxdate attr on input field
 *
 * Possible false positive! Make sure you are checking validateDateField on your input
 */
jQuery.validator.addMethod('validateMaxDate', function (value, element) {
	var $elem = $(element),
		edate = moment.$(element),
		maxdate = moment($elem.data('maxdate') || $elem.datepicker('option', 'maxDate'));

	return edate.isValid() && edate.isSameOrBefore(maxdate, 'day');
},jQuery.validator.messages.maxDate);

/**
 * ensure locale-aware date is between two `moment-like` values (inclusive)
 */
jQuery.validator.addMethod('validateDateBetween', function (value, element, options) {
	return this.optional(element) ||
		moment.$(element).isBetween(options.from, options.to, null, '[]');
});

jQuery.validator.addMethod('validateCalendarPicker',
	function (value, element) {
		var $form = $(this.currentForm);
		var $element = $(element);
		var $otherElement;

		var valid = true;
		var api = $element
			.closest('[calendar-picker]')
			.data('calendarPicker');

		// Check to see if this element is part of the Calendar Picker
		if (!api || !($element.is(api.$start) || $element.is(api.$end))) {
			throw new Error('You\'re trying to validate an input that is not part of a Calendar Picker');
		}

		// Set to invalid if the form is being submitted or the input previously had a value
		if (!$element.val().trim() && !api.isValid() && (api.isDirty() || $form.data('validation-submitting'))) {
			valid = false;
		}

		valid = valid && api.isValid(element);
		$element.data('cp-invalid', !valid);

		/*
			We have to check to see if the other input field is now valid.
			The validator forces us to do this outside of this call stack.
		 */
		$otherElement = $element.is(api.$start) ? api.$end : api.$start;
		if (valid && $otherElement && $otherElement.data('cp-invalid')) {
			setTimeout(
				function() {
					this.element($otherElement);
				}.bind(this)
			);
		}

		return valid;
	},
	function(ruleParams, element) {
		var $element = $(element);
		var api = $element.closest('[calendar-picker]')
			.data('calendarPicker');

		var error = api.validate(element)[0];
		var message = '';

		if (!error) {
			return;
		}

		switch (error.code) {
			case 'BEFORE_MIN':
				message = $.__('%s is before the earliest allowed date', element.value);
				break;
			case 'AFTER_MAX':
				message = $.__('%s is after the last allowed date', element.value);
				break;
			case 'OUT_OF_BOUNDS':
				message = $.__('%s is outside of the allowed date range', element.value);
				break;
		}

		if (!error.code && api.isDirty() && !$element.val().trim()) {
			message = $.__('A calendar field is missing');
		}
		return message;
	}
);

/**
 * use 'require_from_group' only when the checkbox is actually visible
 */
jQuery.validator.addMethod('require_from_group_when_visible', function(value, element, options) {
	var checkElem = $(element).closest(options[2])[0] || element;
	return (
		!$(checkElem).is(':visible') ||
		jQuery.validator.methods.require_from_group.call(this, value, element, options)
	);
},jQuery.validator.messages.require_from_group);

jQuery.validator.addMethod('Compensation_TraxConditionalRequire', function(value, element, options) {
	var $form = $(element).closest('form');
	var compensationFieldNames = [
		'rate[0]',
		'paidPer',
		'payGroup',
		'payPeriod',
		'payType',
		'exempt'
	];

	var selectors = compensationFieldNames.map(function(name) {
		return '[name="' + name + '"]';
	});

	var compensationFieldHasValue = false;
	$form
		.find(selectors.join(', '))
		.each(function(index, element) {
			if ($(element).val()) {
				compensationFieldHasValue = true;
			}
		});

	return !!value || !compensationFieldHasValue;
}, jQuery.validator.messages.require_from_group);

jQuery.validator.addMethod('validatePayRate', function(value, element, options) {
	var $form = $(element).closest('form');
	var payRate = $form.find('[name="rate[0]"]').val();
	var paidPer = $form.find('[name="paidPer"]').val();

	// This field is always good if it has a value
	return value || (!payRate && !paidPer);
}, jQuery.validator.messages.require_from_group);

jQuery.validator.addMethod('isCurrency', function(value, element, validCurrencies) {
	var $currency = $(element).closest('.CurrencyPicker')
		.find('input.js-currency-picker-type');

	if (_.isString(validCurrencies)) {
		validCurrencies = [validCurrencies];
	}

	return _.includes(validCurrencies, $currency.val());
}, jQuery.validator.messages.isCurrency);

jQuery.validator.addMethod('isPayType', function(value, element) {
	var $el = $(element);

	return ($el.val() == 'Hourly' || $el.val() == 'Salary');
}, jQuery.validator.messages.isPayType);

jQuery.validator.addMethod('isCountryCode', function(value, element, validCountry) {
	var $el = $(element);

	if (_.isString(validCountry)) {
		validCountry = [validCountry];
	}

	return _.includes(validCountry, $el.val());
}, jQuery.validator.messages.isCountryCode);

jQuery.validator.addMethod('trimIsNotEmpty', function(value) {
	if (value.length > 0) {
		return value.trim().length !== 0;
	}
	return true;
}, jQuery.validator.messages.remote);


/**
 * Validates a range that conforms to the company's chosen number format (e.g. 1.123,45)
 */
jQuery.validator.addMethod('smartRange', function(value, element, options) {
	const [min, max] = options;
	const number = window.getFloatFromStringJS(value);

	return number >= min && number <= max;
}, jQuery.validator.messages.range);

jQuery.validator.addMethod('directDepositValidator', function(value, element, options) {
	if (value.indexOf('%') >= 0 && value.match(/\d+/)[0] >= 100) {
		return $(element).closest('.js-DirectDepositCard').is(':last-child');
	}
	return true;
});
jQuery.validator.addMethod('directDeposit100Validator', function(value, element, options) {
	return !(value.indexOf('%') >= 0 && value.match(/\d+/)[0] > 100);
});

jQuery.validator.addMethod('timeOffTypeNameValidator', function(value, element, param) {
	return !param.error;
});

jQuery.validator.addMethod('validateSalariedHoursPerWeek', (value, element) => {
	const allowEmpty = element.dataset.validationAllowEmpty === 'true';
	return isSalariedHoursPerWeekValueValid(value, allowEmpty);
});
