import {
	BodyText,
	Flex,
	/* @startCleanup encore */
	Icon,
	/* @endCleanup encore */
	IconV2,
	LayoutBox,
	TextButton,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { TextButton as OldTextButton } from '@fabric/button';
/* @endCleanup encore */
import { ifFeature } from '@utils/feature';

/* @startCleanup encore */
import './fed-message-2020.styl';
/* @endCleanup encore */

export function FedMessage2020(props) {
	const { handleToggleClick } = props;

	return ifFeature('encore',
		(
			<Flex
				alignItems="flex-start"
				marginBottom={ 1 }
				marginRight={ 1.5 }
				marginTop={ 1 }
			>
				<LayoutBox
					marginRight={ 0.5 }
				>
					<IconV2
						color="neutral-strong"
						name="circle-info-regular"
						size={ 20 }
					/>
				</LayoutBox>

				<BodyText
					size="small"
				>
					<span>{ $.__('The IRS W4 withholding form has changed') }. </span>
					<TextButton
						inline={ true }
						onClick={ handleToggleClick }
						text={ $.__('Make changes using the new form fields.') }
						type="button"
					/>
				</BodyText>
			</Flex>
		),
		(
			<div className="FedMessage2020">
				<span className="FedMessage2020__icon">
					<Icon name="fab-info-circle-15x15" />
				</span>

				<p className="FedMessage2020__text">
					<span>{ $.__('The IRS W4 withholding form has changed') }. </span>
					<OldTextButton
						clickAction={ handleToggleClick }
						inline={ true }
						text={ $.__('Make changes using the new form fields.') }
						type="button"
					/>
				</p>
			</div>
		)
	);
}
