import { Flex, IconButton, Popover } from '@bamboohr/fabric';
import { Question14x14 } from '@bamboohr/grim';
import { ifFeature } from '@utils/feature';
import classNames from 'classnames';
import { Fragment, useRef, useState, type ReactElement } from 'react';

import { useStyles } from './question-popover.styles';
import { QuestionPopoverProps } from './question-popover.types';

export function QuestionPopover(props: QuestionPopoverProps): ReactElement {
	const { content, title } = props;
	const styles = useStyles();

	/* @startCleanup encore */
	const refQuestionIcon = useRef<HTMLButtonElement>(null);
	/* @endCleanup encore */
	const inputRef = useRef<HTMLDivElement>(null);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	return ifFeature('encore',
		<Flex ref={ inputRef }>
			<IconButton
				// className here is necessary as this class is looked for to prevent the sticky footer from showing when it shouldn't
				className="js-no-sticky-footer"
				color="secondary"
				icon="circle-question-regular"
				noBoundingBox={ true }
				onClick={ (event) => {
					event.preventDefault();
					event.stopPropagation();
					setIsPopoverOpen(true);
				} }
				type="button"
			/>
			<Popover
				anchorEl={ inputRef.current }
				onClose={ () => setIsPopoverOpen(false) }
				open={ isPopoverOpen }
				placement="bottom"
					title={ title }
			>
				{ content }
			</Popover>
		</Flex>,
		<Fragment>
			<button
				className={ classNames('js-no-sticky-footer', styles.questionButton) }
				onClick={ () => setIsPopoverOpen(true) }
				ref={ refQuestionIcon }
				type="button"
			>
				{/* eslint-disable-next-line react/forbid-component-props */ }
				<Question14x14 className={ styles.questionIcon } />
			</button>
			{ refQuestionIcon.current && (
				<Popover
					anchorEl={ refQuestionIcon.current }
					classes={ {
						popover: styles.popover,
					} }
					onClose={ () => setIsPopoverOpen(false) }
					open={ isPopoverOpen }
					title={ title }
				>
					{ content }
				</Popover>
			) }
		</Fragment>
	);
}
