import { CardPerson20x16, MovingBox20x18 } from '@bamboohr/grim';
import { Button, CardSize, makeStyles, SelectableBox, SelectableBoxGroup, StandardModal, TextButton } from '@bamboohr/fabric';
import { ReactElement } from 'react';
import { EmployeeAvatar } from '../employee-avatar';
import { StatusMismatchEmployedStepProps } from '../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ spacing }) => ({
	boxes: {
		alignItems: 'flex-start',
		display: 'flex',
		gap: spacing(2),
		justifyContent: 'center',
		marginTop: spacing(2),
	},
	boxIcon: {
		marginTop: -1,
	}
}));

export function EmployedStep({ companyName, employed, employee, onCancel, onChange, onNext, renderHeader }: StatusMismatchEmployedStepProps): ReactElement {
	const classes = useStyles();

	return (
		<StandardModal.Body
			renderFooter={
				<StandardModal.Footer actions={
					ifFeature(
						'encore',
						[
							<TextButton key="cancel" onClick={ onCancel }>{ $.__('Cancel') }</TextButton>,
							<Button key="next" onClick={ () => onNext() }>{ $.__('Next') }</Button>,
						],
						[
							<Button key="next" onClick={ () => onNext() }>{ $.__('Next') }</Button>,
							<TextButton key="cancel" onClick={ onCancel }>{ $.__('Cancel') }</TextButton>
						],
					)
				} />
			}
			renderHeader={ renderHeader }
			size={ CardSize.MEDIUM }
		>
			<StandardModal.Constraint spacingOverrides={ { top: StandardModal.Constraint.Spacing.LARGE } }>
				<StandardModal.HeroHeadline
					icon={ ifFeature('encore', 'briefcase-regular', <EmployeeAvatar employee={ employee } />) }
					subtext={ $.__('Either way, we should update some things to make sure your data is sparkling clean.') }
					text={ $.__('Is %1 %2 still employed by %3?', employee.preferredName, employee.lastName, companyName)}
				/>
				<SelectableBoxGroup type="radio">
					<div className={ classes.boxes }>
						<SelectableBox
							boxWidth={ifFeature('encore', '334px', "300px")}
							description={ $.__('%1 is actively working here and being paid.', employee.preferredName) }
							icon={ ifFeature('encore', 'circle-check-solid', <CardPerson20x16 />) }
							inputId="statusMismatchYes"
							isChecked={ employed === true }
							name="isStillEmployed"
							onChange={ () => onChange(true) }
							title={ $.__('%1 Is Still Employed', employee.preferredName)}
							type="radio"
							value="yes"
						/>
						<SelectableBox
							boxWidth={ifFeature('encore', '334px', "300px")}
							description={ $.__('%1 no longer works here, and is not currently on our payroll.', employee.preferredName) }
							icon={ ifFeature('encore', 'circle-x-solid', <span className={ classes.boxIcon }><MovingBox20x18 /></span>) }
							inputId="statusMismatchNo"
							isChecked={ employed === false }
							name="isStillEmployed"
							onChange={ () => onChange(false) }
							title={ $.__('%1 Is Not Employed', employee.preferredName)}
							type="radio"
							value="no"
						/>
					</div>
				</SelectableBoxGroup>
			</StandardModal.Constraint>
		</StandardModal.Body>
	)
}
