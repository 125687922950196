/**
 * This file is for globally overriding fields or field sets for all forms
 * Not everything can be done in the JSONRenderer
 */
import { createContext, findClosest, findOne, updateFieldWithType, filterFields, findChildren } from 'dynamic-form';
import { makeUid } from 'BambooHR.util';

export const addOneEmailRequired = (data: DynamicForm.FormData): DynamicForm.FormData => {
	let newData = data;
	const context = createContext({ formData: data });

	const emailField = findOne(context, { selector: 'props.className', value: 'oneEmailRequired' });
	if (emailField) {
		const closestFieldSet = findClosest(context, emailField.props.id, { selector: 'type', value: 'FieldGroup' });
		if (closestFieldSet) {
			newData = updateFieldWithType(closestFieldSet.props.id, 'OneEmailRequiredFieldGroup', newData);
		}
	}
	return newData;
};

/**
 * Repeating templates (Emergency Contact) Don't add the attributes needed to tie the state and zip field to the country selector
 * This maps those and adds them to the json data since it isn't mapped on the BE -> jQuery has been doing it dynamically
 * This should only be run on the raw data and not on data in active state -> Direct object is mutated
 *
 * @param data DynamicForm.FormData
 */
export const fixCountryStateZipMappingOnRepeatingFields = (data: DynamicForm.FormData): DynamicForm.FormData => {
	const newData = data;
	const context = createContext({formData: data});

	const repeatCountryFields = filterFields(context,
		{ selector: 'key', value: ':idx:', match: 'includes' },
		{ selector: 'type', value: 'SelectStandardCountryField', match: 'exact' },
		{ selector: 'settings.isCustomField', value: false, match: 'exact' }
	);

	const countryFieldKeys = Object.keys(repeatCountryFields);

	if (countryFieldKeys.length) {
		countryFieldKeys.forEach((countryKey) => {
			const countryField = newData[countryKey];
			const closestFieldGroup = findClosest(context, countryKey, {selector: 'type', value: 'FieldGroup'});
			if (closestFieldGroup) {
				const connectedStateField = findChildren(context, closestFieldGroup?.props?.id,
					{ selector: 'props.data-csz', value: 'state' },
					{ selector: 'settings.isCustomField', value: false, match: 'exact' },
				);
				const connectedZipField = findChildren(context, closestFieldGroup?.props?.id,
					{ selector: 'props.data-csz', value: 'postal_code' },
					{ selector: 'settings.isCustomField', value: false, match: 'exact' },
				);

				const stateFieldKeys = Object.keys(connectedStateField);
				if (stateFieldKeys.length) {
					// assume only 1, we don't support multiple state fields tied to a country field
					const stateFieldId = stateFieldKeys[0];
					countryField.props['data-state-field-id'] = stateFieldId;
				}

				const zipFieldKeys = Object.keys(connectedZipField);
				if (zipFieldKeys.length) {
					// assume only 1, we don't support multiple zip fields tied to a country field
					const zipFieldId = zipFieldKeys[0];
					countryField.props['data-zip-field-id'] = zipFieldId;
				}
			}
		});
	}

	return newData;
};

export const overrideFieldData = (data: DynamicForm.FormData): DynamicForm.FormData => {
	let newData = data;
	newData = addOneEmailRequired(newData);
	newData = fixCountryStateZipMappingOnRepeatingFields(newData);

	// so we can cache fields based on a unique form (form id is not unique in QF)
	newData.memoizeKey = makeUid();
	return newData;
};
