import {
	isString,
} from 'lodash';
import {
	useLayoutEffect,
	useRef,
	useState,
} from 'react';

import {
	useCancellableAsyncMethod,
} from 'use-cancellable-async-method';

import {
	getRoutingNumberError,
	RoutingNumberValidationState,
} from './routing-number-input-field.domain';
import {
	validateRoutingNumberApi,
} from '../../service';

interface RoutingNumberValidationData {
	bankName?: string;
}

interface UseRoutingNumberValidatorResult {
	data: RoutingNumberValidationData;
	error: string;
	status: RoutingNumberValidationState;
	validateRoutingNumber: (routingNumber: string, debounceMilliseconds?: number) => void;
}

function clearTimeoutIdRef(timeoutIdRef): void {
	if (timeoutIdRef.current) {
		clearTimeout(timeoutIdRef.current);
		timeoutIdRef.current = null;
	}
}

export function useRoutingNumberValidator(): UseRoutingNumberValidatorResult {
	const timeoutIdRef = useRef(null);
	const lastValidatedRoutingNumber = useRef(null);
	const [requestData, setRequestData] = useState(null);
	const [isPending, setIsPending] = useState(false);

	useLayoutEffect(() => {
		return (): void => {
			clearTimeoutIdRef(timeoutIdRef);
		};
	}, []);

	const {
		data,
		error,
		isError,
		isComplete,
		isProcessing,
		isSuccess,
	} = useCancellableAsyncMethod(
		() => {
			if (requestData) {
				const {
					routingNumber,
				} = requestData;

				setIsPending(false);
				if (lastValidatedRoutingNumber.current !== routingNumber) {
					lastValidatedRoutingNumber.current = routingNumber;

					const synchronousRoutingNumberError = getRoutingNumberError(routingNumber);
					if (synchronousRoutingNumberError) {
						return Promise.reject(synchronousRoutingNumberError);
					}

					return validateRoutingNumberApi(routingNumber);
				}
			}
		},
		[requestData]
	);

	const dataResult = {} as RoutingNumberValidationData;
	if (data) {
		dataResult.bankName = data.name;
	}

	let errorResult = null;
	if (!isPending && isComplete) {
		if (error) {
			errorResult = 'Error';
			if (isString(error)) {
				errorResult = error;
			} else if (error.response.status === 400) {
				errorResult = 'Invalid';
			}
		}
	} else {
		errorResult = 'Required';
	}

	let statusResult = 'initial' as RoutingNumberValidationState;
	if (isPending) {
		statusResult = 'pending';
	} else if (isProcessing) {
		statusResult = 'processing';
	} else if (isError) {
		statusResult = 'error';
	} else if (isSuccess) {
		statusResult = 'success';
	}

	return {
		data: dataResult,
		error: errorResult,
		status: statusResult,
		validateRoutingNumber,
	};

	function validateRoutingNumber(routingNumber: string, debounceMilliseconds = 0): void {
		setIsPending(true);
		clearTimeoutIdRef(timeoutIdRef);
		if (debounceMilliseconds <= 0) {
			setRequestData({
				routingNumber,
			});
		} else {
			timeoutIdRef.current = setTimeout(() => {
				setRequestData({
					routingNumber,
				});
			}, debounceMilliseconds);
		}
	}
}
