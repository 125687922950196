import { Ref, useEffect, useState } from 'react';

import { ControlledCurrencyPickerMenu } from 'currency-picker-field.react';
import { TextField } from '@bamboohr/fabric';

import {
	Currency,
	convertStringToNumber,
	getCurrencyPosition,
	getCurrencySymbol,
	getFormattedAmountFromNumber,
	getFormattedAmountFromString,
} from '../utils';

import './currency-selector-field.styl';

interface CurrencySelectorFieldProps {
	amount?: number;
	currencyCode: string;
	currencies: Currency[];
	currencyEditingDisabled?: boolean;
	currencyWarning?: boolean;
	error?: string;
	inputDisabled?: boolean;
	handleCurrencyCodeChange: (currencyCode: string) => void;
	handleAmountReformat: (formattedValue: string, numericValue: number) => void;
	hasError?: boolean;
	id?: string;
	inputRef?: Ref<HTMLInputElement>;
	name?: string;
	text?: string;
	required?: boolean;
}

export const CurrencySelectorField = (props: CurrencySelectorFieldProps): JSX.Element => {
	const {
		amount,
		currencyCode,
		currencies,
		currencyEditingDisabled,
		currencyWarning = false,
		inputDisabled,
		error,
		handleAmountReformat,
		handleCurrencyCodeChange,
		hasError,
		id,
		inputRef,
		name,
		text,
		required,
	} = props;

	const currencySymbol = getCurrencySymbol(currencies, currencyCode);
	const currencyPosition = getCurrencyPosition(currencies, currencyCode);

	const [inputValue, setInputValue] = useState<string>(amount === null ? '' : getFormattedAmountFromNumber(
		amount,
		currencySymbol,
		currencyPosition
	));

	// Reformat the displayed value when the currency code or provided amount changes
	useEffect(() => {
		setInputValue(getFormattedAmountFromNumber(amount, currencySymbol, currencyPosition));
	}, [currencySymbol, amount, currencyPosition]);


	const handleCurrencyCodeSelect = (selectedItem) => {
		handleCurrencyCodeChange(selectedItem.value);
	};

	const handleAmountBlur = (event) => {
		const newFormattedValue = getFormattedAmountFromString(
			event.target.value,
			currencySymbol,
			currencyPosition
		);
		const numericValue = convertStringToNumber(newFormattedValue, currencySymbol);

		setInputValue(newFormattedValue);

		if (amount !== numericValue) {
			handleAmountReformat(newFormattedValue, numericValue);
		}
	};

	const handleAmountChange = (event) => {
		setInputValue(event.target.value);
	};

	let status;

	if (currencyWarning) {
		status = 'warning';
	}

	if (hasError || Boolean(error)) {
		status = 'error';
	}

	return (
		<div className="CurrencySelectorField">
			<div className="fab-FormField">
				<TextField
					disabled={ inputDisabled }
					id={ id }
					inputProps={ {
						name,
					}}
					onBlur={ handleAmountBlur }
					onChange={ handleAmountChange }
					ref={ inputRef }
					required={ required }
					size="medium"
					status={ status }
					value={ inputValue }
					width={ 4 }
				/>
				<ControlledCurrencyPickerMenu
					currencyCollection={ currencies }
					disabled={ currencyEditingDisabled }
					onCurrencyCodeSelect={ handleCurrencyCodeSelect }
					value={ currencyCode }
				/>
			</div>
			{ text && <div className="fab-FormField">{ text }</div> }
			{ error && (
				<span className="fab-FormNote fab-FormNote--error">
					{ error }
				</span>
			) }
		</div>
	);
};
