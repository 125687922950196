import FileList from 'file-list.react';
import { connect } from 'react-redux';
import { getFilesByParentId } from '../../../store/selectors';

const mapStateToProps = (state, ownProps) => {
	const files = getFilesByParentId(state, ownProps.id)
		.toList()
		.filterNot(file => ownProps.filesToDelete.has(file.get('id')));

	return {
		files
	}
};

export default connect(
	mapStateToProps
)(FileList);

