import { sortBy, toArray, isNull } from 'lodash';
import { isCompleteOrEmployeeComplete, isEsig, waitingOnSig } from 'file.util';
import fileCardTemplate from './templates/file-card.micro.html';
import { isEnabled, ifFeature } from '@bamboohr/utils/lib/feature';

export * from './filecard-converter';

const FileCard = {
	/**
	 * Build a File Meta Object from element data attributes
	 * @param  {node} element The element with the data attributes
	 * @return {void}
	 */
	fileCardFromElement(element) {
		return buildFileCardObject(
			element.dataset.canSee ? '1' : '0',
			element.dataset.ext,
			element.dataset.fileId,
			element.dataset.icon,
			element.id.replace('check', ''),
			element.dataset.name,
			element.dataset.required,
			null,
			element.dataset.originalName,
			element.dataset.fileDataId
		);
	},
	/**
	 * Build a File Meta Object from the array of file objects
	 * @param  {array} files The element with the data attributes
	 * @return {array}  files
	 */
	fileCardFromArray(files) {
		const fileCards = [];
		files.forEach((file) => {
			if (file.hasOwnProperty('fileId')) {
				const {
					canSeeFile,
					ext,
					fileId,
					icon,
					id,
					name,
					required,
					original_file_name,
					fileDataId
				} = file;
				fileCards.push(buildFileCardObject(canSeeFile || file.can_see_file, file.is_esignature_template === 'yes' ? 'Esignature' : ext, fileId, file.is_esignature_template ? file.img_sizes.small : icon, id, name, required, null, original_file_name, fileDataId));
			} else {
				fileCards.push(this.fileCardFromFile(file));
			}
		});
		return fileCards;
	},
	/**
	 * Build a File Meta Object from a file object
	 * @param  {object} file A file object
	 * @return {void}
	 */
	fileCardFromFile(file) {
		let extension = file.ext;
		let isEsignature = 'no';
		let isComplete = null;

		if (isEsig(file)) {
			extension = $.__('Esignature');
			isEsignature = 'yes';
			isComplete = fileStatus(file);
		}

		return buildFileCardObject(
			file.can_see_file,
			extension,
			file.file_id,
			file.img_sizes.small,
			`${ file.file_id }-${ isEsignature }-${ file.required || 'no' }`,
			file.name,
			file.required,
			isComplete,
			file.original_file_name
		);
	},
	/**
	 * Render the files as FileCard Components
	 * @param  {string} fileWrapper The selector where the filecards should render
	 * @param  {array}  files       Array of file objects to render
	 * @param  {string} type        The file card display type
	 * @param  {string} theme       The file card style theme
	 * @param  {boolean} newUploadedFiles Passed files are newly uploaded
	 */
	renderFileCards(fileWrapper, files, type = 'display', theme = 'dark', newUploadedFiles = false) {
		const hasFiles = files && files.length > 0;
		const list = document.querySelector(fileWrapper);

		if (hasFiles === false) {
			console.warn('No files passed to renderFileCards: exiting function early.');
			return;
		}

		if (type !== 'display') {
			files.map((file) => {
				file.mode = type;
			});
		}

		if (list) {
			files = sortBy(files, [file => file.name.toLowerCase()]);
			// TODO: The files object should be populated with an isEsig flag instead of having to rely on the back icon name string in the micro template to get icon names
			list.innerHTML = window.microTemplate(fileCardTemplate, { files, theme, newUploadedFiles });

			addEventListeners();
		}
	},
};

export default FileCard;

function addEventListeners() {
	const attachCards = toArray(document.querySelectorAll('.FileCard:not(.FileCard--display)'));
	const displayCards = toArray(document.querySelectorAll('.FileCard.FileCard--display'));

	attachCards.forEach((card) => {
		card.addEventListener('change', toggleRequired, false);
		card.addEventListener('click', closeFileCard, false);
	});

	displayCards.forEach((card) => {
		card.addEventListener('click', clickFileCard, false);
	});
}

function clickFileCard() {
	performAction(this.dataset);
}

/**
 * Check if file has been completed or is waiting on signatures
 * NOTE: Remove check for OfferLetter-form when ready for e-sig chickenswitch to go global.
 * @param  {object} files The selector where the filecards should render
 * @param  {bool} boolean
 */
function fileStatus(file) {
	if (!isNull(document.getElementById('OfferLetter-form'))) {
		if (waitingOnSig(file)) {
			return false;
		}
		if (isCompleteOrEmployeeComplete(file)) {
			return true;
		}
	}
}

function closeFileCard(e) {
	// display a chicken switch modal before removing the file.
	if (e.target.classList.contains('js-fc-modal')) {
		showChickenSwitch(this, e.target.dataset.isComplete);

	} else if (e.target.classList.contains('js-fc-close')) {
		const evt = new CustomEvent('fileCard:remove', { detail: { fileId: this.dataset.fileId, key: this.dataset.key } });
		document.dispatchEvent(evt);
		$(this).remove();
	}
}

export function performAction(data) {
	let vars = null;

	try {
		vars = JSON.parse(data.actionData);
	} catch (error) {
		console.error(error);
	}

	if (vars) {
		switch (data.action) {
			case 'companyPreviewFile':
				window.previewFile(vars.companyFileMostRecentId, vars.companyFileId, vars.companyFileName, false, 'div', true);
				break;
			case 'companyShowFile':
				window.showFile(vars.companyFileMostRecentId, vars.companyFileId, vars.companyFileName, false, 'div', true);
				break;
			case 'esigOpenModal':
				if (isEnabled('ESIGNATURE_INSTANCE_TOGGLE') && parseInt(vars.esignatureInstanceId) > 0) {
					window.openEsignatureSigningModal(vars.esignatureInstanceId);
				} else {
					window.lazySigs(function(Esignatures) { Esignatures.Signing.openModal(vars.esignatureTemplateId, vars.esignatureWorkflowId, vars.workflowInstanceId, 'Esignatures.init(Esignatures.TYPE_SIGNING)', vars.name); });
				}
				break;
			case 'esigOpenPreviewModeModal':
				if (isEnabled('ESIGNATURE_INSTANCE_TOGGLE') && parseInt(vars.esignatureInstanceId) > 0) {
					window.openEsignatureSigningModal(vars.esignatureInstanceId, true);
				} else {
					window.lazySigs(function(Esignatures) { Esignatures.Signing.openPreviewModeModal(vars.esignatureTemplateId, vars.workflowInstanceId, vars.name); });
				}
				break;
			case 'previewFile':
				window[data.action](vars.mostRecentFileDataId, vars.fileId, vars.name, false, 'div');
				break;
			case 'previewEmployeeFile':
				window[data.action](vars.employeeFileMostRecentId, vars.employeeFileId, vars.employeeFileName, 'li');
				break;
			case 'showFile':
				window[data.action](vars.mostRecentFileDataId, vars.fileId, vars.name, false, 'div');
				break;
			case 'showFileNoAccess':
				window[data.action](vars.name);
				break;
			default:
				break;
		}
	}
}

function toggleRequired(e) {
	if (e.target.classList.contains('js-fc-toggle-required')) {
		const { fileId } = e.target.dataset;
		const input = document.querySelector(`#filesinput-${ fileId }`);
		const label = e.target.parentNode;
		const req = e.target.checked ? 'yes' : 'no';
		updateFileCardTooltip(label, e.target.checked);
		input.value = input.value.replace(/[^-]+$/, req);
	}
}


function showChickenSwitch(e, isComplete) {

	let data = {
		navHeader: $.__('Are you sure you want to remove this signed document?'),
		navMessage: $.__('This document is already signed. Removing it will cancel the request and discard the signed document.'),
		actionBtn: $.__('Yes, Remove Document'),
		cancelBtn: $.__('No, Don\'t remove it'),
	};

	if (isComplete === 'false') {
		data = {
			navHeader: $.__('Are you sure you want to cancel this signature request?'),
			navMessage: $.__('This signature request has been partially completed and is waiting on other signers. Removing it will cancel the request and discard the partially-signed document.'),
			actionBtn: $.__('Yes, Cancel Signature Request'),
			cancelBtn: $.__('No, Don\'t Cancel it'),
		};

	}

	window.BambooHR.Modal.setState({
		header: data.navHeader,
		dangerousContent: `<p style="text-align: center;">${ data.navMessage } </p>`,
		icon: ifFeature('encore', 'triangle-exclamation-solid', 'fab-triangle-exclamation-48x48'),
		iconColor: ifFeature('encore', 'warning-strong', 'attention'),
		iconV2Color: 'warning-strong',
		isOpen: true,
		sheetProps: null,
		primaryAction: () => {
			const evt = new CustomEvent('fileCard:remove', { detail: { fileId: e.dataset.fileId, key: e.dataset.key } });
			document.dispatchEvent(evt);
			$(e).remove();
			window.BambooHR.Modal.setState({ isOpen: false });
		},
		primaryActionText: data.actionBtn,
		alternativeActionText: data.cancelBtn,
		title: $.__('Just Checking...'),
	});
}

/**
 * Update a FileCard toggle tooltip according to checked state
 * @param  {object} triggerEl The element that the tooltip is associated with
 * @param  {boolean} checked  Whether or not the file is required
 */
function updateFileCardTooltip(triggerEl, checked) {
	let content = $.__('Click to make the signature required.');
	let lead = $.__('Signature Optional');
	const tip = BambooHR.Components.Tooltip.selectTooltips(triggerEl)[0];

	if (checked) {
		lead = $.__('Signature Required');
		content = $.__('Click to make the signature optional.');
	}

	if (tip.tooltipEl) {
		tip.hide();
		tip.tooltipEl.innerHTML = `<p class="lead">${ lead }</p><br />${ content }`;
	} else {
		tip.options.content = content;
		tip.options.lead = lead;
	}
}

// Utility functions
function buildFileCardObject(canSeeFile, ext, fileId, icon, id, name, required, isComplete = null, originalName = null, fileDataId) {
	return {
		action: null,
		classNames: [],
		data: {
			canSeeFile,
			isComplete,
		},
		disabled: false,
		fileId,
		fileDataId,
		id,
		info: {
			icon,
			text: ext,
		},
		isSafe: true,
		name,
		originalName,
		status: '',
		required,
		theme: 'dark',
	};
}
