import { isUndefined } from 'lodash';
import { canUseLocalStorage } from '@utils/localstorage';

/**
 * Set up all existing forms on the page to have a CSRFToken. For future ajax
 * requests, append a CSRF_TOKEN header.
 */
function attachTokens(): void {
	// attach csrf hidden inputs to all forms currently on the page
	if (!isUndefined(window.CSRF_TOKEN)) {
		updateInput();
		// Alternative, it's not recommended, but we're doing it anyway.
		$.ajaxSetup({
			beforeSend(jqxhr, settings) {
				// @ts-ignore
				if (settings.crossdomain) {
					return;
				}
				if (settings.type != 'GET') {
					jqxhr.setRequestHeader('X-CSRF-Token', window.CSRF_TOKEN);
				}
			},
		});
	}
}

/**
 * Adds or removes the CSRF token as hidden inputs for elements matching the selector
 * @param  {string} selector [CSS selector string]
 */
function updateInput(selector = 'form'): void {
	if (!isUndefined(window.CSRF_TOKEN)) {
		$(selector).each((index, item) => {
			const $item = $(item);
			const method = $item.attr('method') || '';
			const $csrfInput = $item.find(':input[name=CSRFToken]');
			if ($csrfInput.length === 0 && method.toLowerCase() === 'post') {
				$item.append(`<input type="hidden" name="CSRFToken" value="${ window.CSRF_TOKEN }">`);
			} else if ($csrfInput.length && method.toLowerCase() !== 'post') {
				$csrfInput.remove();
			}
		});
	}
}

/**
 * For a string return a hash (simple version, not for security purposes, just for
 * content matching)
 * @param {string} text [String to hash]
 * @returns {String.prototype.hashCode.chr|String.prototype.hashCode@call;charCodeAt|Number}
 */
function getHashCode(text: string): number {
	let hash = 0;
	let i; let
		len;

	if (text.length === 0) {
		return hash;
	}
	for (i = 0, len = text.length; i < len; i++) {
		hash = ((hash << 5) - hash) + text.charCodeAt(i);
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

/**
 * Returns the current CSRF token
 * @return {string} The CSRF token
 */
function getToken(): string {
	return window.CSRF_TOKEN;
}

/**
 * Updates the global CSRF token variable
 * @param  {string} token The CSRF token
 */
function setToken(token: string, isOnboardingUser = false): void {
	const existingToken = window.CSRF_TOKEN;
	window.CSRF_TOKEN = token;
	if (canUseLocalStorage()) {
		setHashedToken(token, isOnboardingUser);
	}
	$('input[name="CSRFToken"]').val(window.CSRF_TOKEN);
	if(token != existingToken) {
		document.dispatchEvent(new CustomEvent('AppSession:csrfChanged'));
	}
}

/**
 * Sets the hashed token in local storage
 */
function setHashedToken(token: string, isOnboardingUser = false): void {
	if (!canUseLocalStorage()) {
		throw new Error('Cannot set hashed token. Local Storage is unavailable.');
	}

	const storageKey = `hashed_token${ isOnboardingUser ? '_onboarding' : '' }`;

	window.localStorage.setItem(storageKey, getHashCode(token).toString());
}

export {
	attachTokens,
	getHashCode,
	getToken,
	setToken,
	setHashedToken,
	updateInput,
};
