import { JobOpeningLocationTypes } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const EXTERNAL_URL_PATTERN = /^https?:\/\//i;

/**
 * Assumes that provided url is intended to be external to the given site if it begins with http(s)
 */
export function isUrlExternal(url: string): boolean {
	return EXTERNAL_URL_PATTERN.test(url);
}

export function makeUrlExternal(url: string): string {
	return isUrlExternal(url) ? url : `http://${url}`;
}

function getLocationSuffix(locationType?: JobOpeningLocationTypes) {
	switch (String(locationType)) {
		case JobOpeningLocationTypes.IN_OFFICE:
			return '';
		case JobOpeningLocationTypes.REMOTE:
			return $.__('Remote');
		case JobOpeningLocationTypes.HYBRID:
			return $.__('Hybrid');
		default:
			return '';
	}
}

/**
 * format location for display
 * location                   Standard Location object
 * atsLocation                Ats specific location object
 *
 * return formatted location string for display
 */

export function getLocationFromObjectForDisplay(
	locationType: JobOpeningLocationTypes,
	location?: any,
	department?: string,
	separator?: string
) {
	const { city, province, state, country } = location || {};

	let countryName: string;
	let stateName: string;
	if (typeof country === 'object') {
		countryName = country?.name;
	} else if (typeof country === 'string') {
		countryName = country;
	}
	if (typeof state === 'object') {
		stateName = country?.iso_code === 'US' && city ? state?.abbrev : state?.name;
	} else if (typeof state === 'string') {
		stateName = state;
	}

	return getLocationDisplay(locationType, city, province || stateName, countryName, department || '', separator);
}

function appendIf(condition: boolean, originalString: string, appendedValue: string, useSpace = true) {
	if (condition) {
		return `${originalString}${useSpace ? ' ' : ''}${appendedValue}`;
	}
	return originalString;
}

/*
 * Returns a properly formatted string for display - see https://bamboohr.slite.com/app/docs/N-RJbfe00k_mSy
 * city
 * state | province
 * country
 * department
 * separator
 *
 * Returns string
 */
export function getLocationDisplay(
	locationType: JobOpeningLocationTypes,
	city?: string,
	state?: string,
	country?: string,
	department?: string | null,
	delimiter = ifFeature('encore', '-', String.fromCharCode(183))
): string {
	let locationString: string = department ?? '';

	const shouldAddDelimiter =
		!!city || !!state || !!country || (locationType && String(locationType) === JobOpeningLocationTypes.REMOTE);

	locationString = appendIf(locationString.length && shouldAddDelimiter, locationString, delimiter);

	locationString = appendIf(!!city, locationString, city);
	locationString = appendIf(!!city && !!state, locationString, ',', false);
	locationString = appendIf(!!state, locationString, state || '');

	locationString = appendIf(!state && !city && !!country, locationString, country || '');

	if (String(locationType) !== JobOpeningLocationTypes.IN_OFFICE) {
		const locationNote = getLocationSuffix(locationType);
		const tail = (city || state || country) && locationNote ? `(${locationNote})` : locationNote;

		locationString = locationString?.trim().length ? `${locationString} ${tail}` : tail;
	}

	return locationString.trim();
}
