import {htmlentities} from 'String.util';
import warningTemplate from '../shared/templates/tt-warning';
import { enableTimeTracking as _enableTimeTracking } from '../../../../employees/gear-box/time-tracking-toggle';

export function openMissingDataModal(missingData, preferredName) {
	if (missingData === 'no-pay-schedule') {
		openNoPayScheduleModal();
	} else {
		openMissingLocationInformation(missingData, preferredName);
	}
}

export function openNoPayScheduleModal() {
	window.BambooHR.Modal.setState({
		isOpen: true,
		dangerousContent: `
			<div>
				${ $.__(`You can select a pay schedule by editing the compensation information on this employee's Job tab.`) }
			</div>
		`,
		icon: 'fab-timesheet-exclamation-84x57',
		iconColor: 'danger',
		headline: $.__('To enable time tracking you must first select a pay schedule for this employee.'),
		primaryAction: () => {
			window.BambooHR.Modal.setState({ isOpen: false});
		},
		alternativeAction: null,
		primaryActionText: $.__('Got it, Thanks'),
		title: $.__(`Oops, sorry that won't work...`)
	});
}

export function openMissingLocationInformation(missingData, preferredName, shouldEnableTimeTracking = true) {
	const html = microTemplate(warningTemplate, {
		header: getHeader(missingData, htmlentities(preferredName)),
		description: getDescription(missingData, htmlentities(preferredName))
	});

	window.BambooHR.Modal.setState({
		isOpen: true,
		dangerousContent: html,
		primaryAction: () => {
			if (shouldEnableTimeTracking) {
				_enableTimeTracking();
			} else {
				window.BambooHR.Modal.setState({isOpen: false});
			}
		},
		primaryActionText: $.__('Got it, Thanks'),
		title: $.__(`Overtime Won't Be Calculated`)
	});
}

function getHeader(missingData, preferredName) {
	switch (missingData) {
		case 'no-location':
			return $.__(`We can't calculate overtime for %1$s because a location isn't set.`, preferredName);
		case 'no-state':
			return $.__(`We can't calculate overtime for %1$s because a state isn't set.`, preferredName);
		case 'no-state-remote':
			return $.__(`We can't calculate overtime for %1$s because a home address state isn't set.`, preferredName);
		case 'non-overtime-country':
			return $.__(`Overtime cannot be calculated for %1$s because their address does not include a US state.`, preferredName);
		default:
			throw new Error('no missing data');
	}
}

function getDescription(missingData, preferredName) {
	let string = $.__(`%1$s will still be able to track time, but we won't calculate overtime.`, preferredName);
	switch (missingData) {
		case 'no-location':
			return string + ' ' + $.__(`Add %1$s to a location with a US address to automatically calculate overtime based on the state overtime laws.`, preferredName);
		case 'no-state':
			return string + ' ' + $.__(`Add a US state to %1$s's location to automatically calculate overtime based on the state overtime laws.`, preferredName);
		case 'no-state-remote':
			return string + ' ' + $.__(`Add a US state to %1$s's home address to automatically calculate overtime based on the state overtime laws.`, preferredName);
		case 'non-overtime-country':
			return string + ' ' + $.__(`Add a US state to %1$s's location to automatically calculate overtime based on the state overtime laws.`, preferredName);
		default:
			throw new Error('no missing data');
	}
}
