/**
 * The selectors for the title
 *
 * @type Object
 */
var selectors = {
	header: '.js-simplemodal-header',
	close: '.js-simplemodal-close'
};

/**
 * Simple Modal Header Object
 */
var Header = {
	/**
	 * Show the header
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 */
	show($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.header).show();
	},

	/**
	 * Hide the header
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 */
	hide($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.header).hide();
	},

	/**
	 * Hide the close button in the header
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 */
	hideCloseButton($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.close).hide();
	}
};


import Title from './Header/Title';
Header.Title = Title;
export {Title};


export default Header;
