import EditableFileList from '../../containers/editable-file-list';
import TempFileList, { TemporaryFile } from 'file-list.react';
import classNames from 'classnames';
import { Icon, IconV2, LayoutBox, TextField } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Button, TextButton } from '@fabric/button';
import { Map, OrderedMap } from 'immutable';
import { createRef, PureComponent } from 'react';
import { noop, uniqueId, debounce } from 'lodash';
import Tooltip from 'Tooltip.mod';
import { deleteTempFile, uploadTempFile } from '../../../../utils/files';
import { VALID_EXTENSIONS } from '../../../../constants';

import './styles.styl';

const resetText = $.__('Cancel');
const preventDrag = function (e) {
	e.preventDefault();
};

class Editor extends PureComponent {
	static defaultProps = {
		defaultValue: '',
		editing: false,
		focusOnMount: false,
		pending: false,
		placeholder: '',
		onFocus: noop,
		onReset: noop,
		onSubmit: noop,
		submitText: '',
		itemId: null,
		showEditText: false,
	};

	get isAssumedUser() {
		return window.GLOBAL_IS_ASSUMED_USER;
	}

	get disabledForPermsTest() {
		return window.disabledForPermsTest;
	}

	_handleReset = (e) => {
		this.state.tempFiles.forEach((file) => this.deleteTempFile(file.id));
		this.showErrorState(false);
		return this.props.onReset(e, this.getValue(), this.state.tempFiles);
	};

	_handleSubmit = (e) => {
		e.preventDefault();

		if (this.isAssumedUser) {
			return this.disabledForPermsTest();
		}
		if (this.getValue().trim().length === 0) {
			this.showErrorState(true, $.__("Share what's on your mind! Make sure you add a comment."));
		} else {
			this.showErrorState(false);
			return this.props.onSubmit(e, this.getValue(), this.state.tempFiles, this.textArea.current, this.state.filesToDelete);
		}
	};

	_handleAttach = (e) => {
		const files = e.target.files;
		if (files.length >= 1) {
			this.addTempFiles(files);
		}
		e.target.value = '';
	};

	_handleDrop = (e) => {
		const files = e.dataTransfer.files;
		if (files.length >= 1) {
			this.addTempFiles(files);
		}
		this.setState({ dragging: false });
		e.preventDefault();
	};

	_handleDrag = (e) => {
		e.preventDefault();
		const dragging = e.type === 'dragenter' || false;
		this._handleFocus(e);
		this.setState({ dragging });
	};

	_handleFocus = (e) => this.props.onFocus(e, this.getValue(), this.state.tempFiles);

	showErrorState = (error = false, msg) => {
		if (error && msg.length) {
			window.setMessage(msg, 'error');
		} else {
			window.closeMessage();
		}
		this.setState({ error });
	};

	markFileForDeletion = (id) => {
		this.setState({
			filesToDelete: this.state.filesToDelete.set(id, id),
		});
	};

	deleteTempFile = (id) => {
		deleteTempFile(this.state.tempFiles.get(id));
		this.setState((prevState) => ({
			tempFiles: prevState.tempFiles.delete(id),
		}));
	};

	addTempFiles = (files) => {
		const uploadPendingTrue = debounce(
			() =>
				this.setState({
					uploadPending: true,
				}),
			500
		);
		uploadPendingTrue();
		Array.from(files).forEach((file) => {
			const uuid = uniqueId();
			this.setState((prevState) => ({
				filesPending: prevState.filesPending + 1,
				tempFiles: prevState.tempFiles.set(
					uuid,
					TemporaryFile({
						id: uuid,
						name: file.name,
					})
				),
			}));
			uploadTempFile(
				file,
				(xhr) =>
					this.setState((prevState) => ({
						tempFiles: prevState.tempFiles.setIn([uuid, 'xhr'], xhr),
					})),
				(percent) =>
					this.setState((prevState) => ({
						tempFiles: prevState.tempFiles.setIn([uuid, 'progress'], percent),
					})),
				(uploadedFile) => {
					uploadPendingTrue.cancel();
					this.setState((prevState) => ({
						uploadPending: prevState.filesPending !== 1,
						filesPending: prevState.filesPending - 1,
						tempFiles: prevState.tempFiles.update(uuid, (tempFile) => tempFile.mergeWith((p, n) => n || p, uploadedFile)),
					}));
				},
				() => {
					uploadPendingTrue.cancel();
					this.setState((prevState) => ({
						uploadPending: prevState.filesPending !== 1,
						filesPending: prevState.filesPending - 1,
						tempFiles: prevState.tempFiles.remove(uuid),
					}));
				}
			);
		});
	};

	getValue = () => this.textArea.current.value;

	openFileSelector = (e) => {
		this._handleFocus(e);
		this.fileInput.click();
	};

	textArea = createRef();
	attachIcon = createRef();

	state = {
		tempFiles: OrderedMap(),
		filesToDelete: Map(),
		error: false,
		uploadPending: false,
		filesPending: 0,
		dragging: false,
	};

	componentWillReceiveProps = (nextProps) => {
		if (!nextProps.editing) {
			this.textArea.current.value = '';
			this.setState({
				tempFiles: Map(),
				filesToDelete: Map(),
			});
		}
	};

	componentDidMount = () => {
		window.addEventListener('dragover', preventDrag);
		window.addEventListener('drop', preventDrag);

		if (this.props.focusOnMount) {
			this.textArea.current.focus();
		}

		if (this.attachIcon.current) {
			this.Tooltip = Tooltip.create(this.attachIcon.current, {
				template: {
					name: 'tooltip-standard',
					data: {
						content: $.__('Drag and drop or click to browse files.'),
					},
				},
				tailOnBalloon: 15,
				delay: 500,
			});
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('dragover', preventDrag);
		window.removeEventListener('drop', preventDrag);
		if (this.attachIcon.current) {
			this.Tooltip.destroy();
		}
	};

	render() {
		const { itemId, config, showEditText, editing, pending, defaultValue } = this.props;

		const { error, dragging, uploadPending } = this.state;

		const textareaClassNames = classNames({
			'fab-Textarea': true,
			'fab-Textarea--error': error,
			'fab-Textarea--width100': true,
			FeedInput__textarea: true,
			'FeedInput__textarea--dragging': dragging,
		});

		return (
			<div className='FeedListItemEdit'>
				<form
					method='post'
					onKeyDown={(event) => {
						if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
							this._handleSubmit(event);
						}
					}}
					onReset={this._handleReset}
					onSubmit={this._handleSubmit}
				>
					{config.enableAttach ? (
						<div
							className='FeedListItem__input'
							onDragEnter={this._handleDrag}
							onDragLeave={this._handleDrag}
							onDrop={this._handleDrop}
						>
							<TextField
								defaultValue={defaultValue}
								disabled={pending}
								multiline={true}
								onFocus={this._handleFocus}
								placeholder={config.placeholder}
								ref={this.textArea}
								InputProps={{
									endAdornment: (
										<LayoutBox
											alignSelf={'start'}
											paddingRight={ifFeature('encore', 2, 1)}
											paddingY={ifFeature('encore', 2, 1)}
											onClick={this.openFileSelector}
										>
											<span className='FeedInput__attachIcon' ref={this.attachIcon}>
												{ifFeature(
													'encore',
													<IconV2 color='neutral-extra-strong' name='link-regular' size={16} />,
													<Icon name='fab-paperclip-16x16' />
												)}
											</span>
										</LayoutBox>
									),
								}}
							/>
							<span className={pending ? 'hidden' : 'FeedInput__attach'} onClick={this.openFileSelector}>
								<input
									accept={VALID_EXTENSIONS}
									aria-label='File upload'
									multiple={true}
									onChange={this._handleAttach}
									ref={(el) => {
										this.fileInput = el;
									}}
									style={{ display: 'none' }}
									type='file'
								/>
							</span>
						</div>
					) : (
						<div className='FeedListItem__input'>
							<TextField
								defaultValue={defaultValue}
								disabled={pending}
								multiline={true}
								onFocus={this._handleFocus}
								placeholder={config.placeholder}
								ref={this.textArea}
							/>
						</div>
					)}
					{itemId && (
						<EditableFileList
							filesToDelete={this.state.filesToDelete}
							id={itemId}
							removeOnClick={this.markFileForDeletion}
							showRemoveIcon={true}
						/>
					)}
					<TempFileList files={this.state.tempFiles} removeOnClick={this.deleteTempFile} showLoader={true} showRemoveIcon={!pending} />
					{editing && (
						<div className='FeedInput__actions'>
							<Button
								isProcessing={pending || uploadPending}
								text={showEditText ? config.submitEditText : config.submitNewText}
								type='submit'
							/>
							<LayoutBox paddingLeft={1}>
								<TextButton isDisabled={pending} text={resetText} type='reset' />
							</LayoutBox>
						</div>
					)}
				</form>
			</div>
		);
	}
}

export default Editor;
