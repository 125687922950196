import {
	IconV2,
} from '@bamboohr/fabric';
import { Popover } from 'popover.react';
import './help-popover.styl';

import {
	ifFeature,
} from '@utils/feature';

export function HelpPopover(props) {
	const { title, content, addTop } = props;
	const settings = {
		onShow: popoverShowCallback,
		position: 'bottom',
		template: {
			name: 'popover-standard',
			data: {
				rawContent: true,
				content,
				title,
				popoverClass: 'js-taxHelpPopover'
			}
		}
	};

	const wrapperClass = addTop ? 'HelpPopover HelpPopover--addTop' : 'HelpPopover';
	const encoreWrapperClass = addTop ? 'EncoreHelpPopover EncoreHelpPopover--addTop' : 'EncoreHelpPopover';

	return (
		<Popover settings={settings} wrapperClassName={ifFeature('encore', encoreWrapperClass, wrapperClass)}>
			{ifFeature('encore',
			<IconV2 // className here is necessary as this class is looked for to prevent the sticky footer from showing when it shouldn't
				className="js-no-sticky-footer" color='neutral-strong' name='circle-question-regular' size={16} />
			, <>?</>)}
		</Popover>
	);
}

function popoverShowCallback() {
	// Get the link(s) included in the help text
	const popoverLinks = document.querySelectorAll('.js-taxHelpPopover a');

	if (popoverLinks) {
		const linksArray = Array.from(popoverLinks);

		linksArray.forEach((link) => {
			// Grab the 'rel' attribute from the link
			const relAttribute = link.getAttribute('rel');

			// if rel="noopener noreferrer" (necessary to prevent XSS vulnerabilities)
			if ((relAttribute.indexOf('noopener') !== -1) && (relAttribute.indexOf('noreferrer') !== -1)) {
				link.setAttribute('target', '_blank');
			}
		});

	}
}
