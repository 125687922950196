import { FileUpload as FabricFileUpload } from '@fabric/file-upload';

// TODO this constant should be able to be a global config once that ability is added to Fabric FileUpload
import { VALID_FILE_EXTENSIONS } from 'hiring/constants';
import { getValidationContext } from '../../context';


export function FileUpload(props) {
	const { data, errorState, label, name, typeProps = {}, validationRegistration } = props;
	const { setSpecialErrors } = getValidationContext();

	if (!data) {
		return null;
	}

	let initialFiles = [];
	const files = data.files || data;
	if (files.length || (files.fileName && files.value)) {
		initialFiles = initialFiles.concat(files).map((fileInfo) => ({
			id: `${fileInfo.value}`,
			name: `${fileInfo.fileName}`,
		}));
	}

	const _handleAdd = () => {
		setSpecialErrors(name, null);
		setSpecialErrors(`${name}Timestamp`, { timestamp: Date.now() });
	};

	const _handleChange = () => {
		// see footer actions for how this relates to submit handling
		setSpecialErrors(name, { rule: 'fileUploadPending' });
	};

	const _handleRemove = () => {
		setSpecialErrors(name, null);
		setSpecialErrors(`${name}Timestamp`, null);
	}

	return (
		<div className={`fab-FormColumn CandidateField CandidateField--${name}`}>
			<label
				className={`fab-Label ${data.isRequired ? 'fab-Label--required' : ''} ${errorState ? 'fab-Label--error' : ''}`}
				htmlFor={name}
			>
				{label}
			</label>
			<FabricFileUpload
				acceptedTypes={VALID_FILE_EXTENSIONS}
				canSelectMultiple={typeProps.canSelectMultiple}
				disabled={Boolean(data.isDisabled)}
				endpoint={typeProps.uploadUrl}
				initialFiles={initialFiles}
				inputRef={validationRegistration}
				isRequired={data.isRequired}
				name={name}
				onChange={_handleChange}
				onRemove={_handleRemove}
				onUpload={_handleAdd}
			/>
		</div>
	);
}

