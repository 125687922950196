import {select, selectAll} from 'd3-selection';
import {sum} from 'd3-array';
import 'd3-selection-multi';

import classNames from 'classnames';
import {defaultsDeep} from 'lodash';
import {isEnabled} from 'FeatureToggle.util';

const isDeepLinkEnabled = isEnabled('ElfDeepLinking');

const CLASS_NAME = "BhrLegend";

const DEFAULT_OPTIONS = {
	chartClass: '',
	showCounts: true,
	truncate: true,
	onClick: null
};

export default class Legend {

	constructor(selector, options) {
		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);

		this._setUpDom(selector);
	}

	/**
	 * Creates the base DOM tree for this component
	 * @param  {object} selector The parent selector
	 */
	_setUpDom(selector) {
		const container = select(selector).append('div')
			.attr('class', classNames(`${ CLASS_NAME }`, {
				[`${ this._options.chartClass }`]: this._options.chartClass
			}));

		this._legendList = container.append('ul')
			.attr('class', classNames(`${ CLASS_NAME }__legendList`, {
				[`${ this._options.chartClass }__legendList`]: this._options.chartClass,
				[`${ CLASS_NAME }__legendList--columnLayout`]: this._options.columnLayout
			}));

		this._total = container.append('div')
			.attr('class', classNames(`${ CLASS_NAME }__total`, {
				[`${ this._options.chartClass }__total`]: this._options.chartClass
			}));

		this._total.append('span')
			.style('flex-grow', 1)
			.text($.__('Total'));

		this._totalCount = this._total.append('span');
	}

	/**
	 * Draws a legend representative of the data supplied to this method
	 * @param  {object} data Data required to draw the legend
	 */
	draw(data) {
		let legendItem, legendItemEnter;

		const total = sum(data, d => d.count);

		// Copy so we don't modify the original data array
		const legendData = data.slice();

		legendItem = this._legendList.selectAll('.js-BhrPercentDonutChart-legendItem').data(legendData);

		legendItem.exit().remove();

		legendItemEnter = legendItem.enter()
			.append('li')
			.attr('class', classNames({
				[`${ CLASS_NAME }__legendItem`]: true,
				[`${ this._options.chartClass }__legendItem`]: this._options.chartClass,
				'js-BhrPercentDonutChart-legendItem': true
			}));

		if (this._options.onClick) {
			legendItemEnter
				.attr('style', 'cursor: pointer')
				.on('click', this._options.onClick);
		}

		legendItemEnter.append('span')
			.attr('class', classNames({
				[`${ CLASS_NAME }__swatch`]: true,
				[`${ this._options.chartClass }__swatch`]: this._options.chartClass,
				'js-BhrPercentDonutChart-swatch': true
			}));

		const truncateClass = this._options.truncate ? 'truncate' : '';
		legendItemEnter.append('span')
			.attr('class', classNames({
				[`${ CLASS_NAME }__legendText`]: true,
				[`${ this._options.chartClass }__legendText`]: this._options.chartClass,
				'js-BhrPercentDonutChart-legendText': true,
				[`${ truncateClass }`]: truncateClass
			}));

		legendItem = legendItemEnter.merge(legendItem)
		legendItem
			.filter(function(d) { return d.isTotal })
			.styles({
				'border-top': '1px solid #d8d8d8',
				'padding-top': '8px'
			});

		legendItem.select('.js-BhrPercentDonutChart-legendText')
			.attr('id', d => d.id)
			.style('font-weight', d => (d.color === null ? '600' : 'normal'))
			.text(d => d.label)
			.filter(d => d.subLabel)
			.append('span')
			.attr('class', classNames(`${ CLASS_NAME }__legendSubLabel`, {
				[`${ this._options.chartClass }__legendSubLabel`]: this._options.chartClass
			}))
			.text(d => d.subLabel)
			.style('font-weight', 'normal');

		legendItem.select('.js-BhrPercentDonutChart-swatch')
			.style('background-color', d => d.color);

		if (this._options.showCounts) {
			this._total.style('display', 'flex');
			this._totalCount.text(total);

			this._drawLegendItemCounts(legendItem);
		} else {
			this._total.style('display', 'none');
		}
	}

	/**
	 * Draws the count next to each item in the legend
	 * @param  {object} legendItem D3 selection for a legend item
	 */
	_drawLegendItemCounts(legendItem) {
		const count = legendItem.selectAll('.js-BhrPercentDonutChart-legendCount').data(d => [d]);
		count.enter()
			.append('span')
			.attr('class', classNames(`${ CLASS_NAME }__legendCount`, {
				[`${ this._options.chartClass }__legendCount`]: this._options.chartClass,
				'js-BhrPercentDonutChart-legendCount': true
			}))
			.merge(count)
			.text((d) => {
				// Return count without a link if showDetailLink is false or countDetailLink is undefined
				if (!d.hasOwnProperty('countDetailLink') || d.countDetailLink === null) {
					return d.count;
				}
				return null;
			})
			// Filter out counts that don't need links and append a link to the counts that do need a link
			.filter(d => d.hasOwnProperty('countDetailLink') && d.countDetailLink !== null)
			.append('a')
			.attr('href', (isDeepLinkEnabled && this._options.onClick) ? null : d => d.countDetailLink)
			.text(d => d.count);
		count.exit().remove();
	}

	sliceEnter(id) {
		this._legendList.selectAll('.js-BhrPercentDonutChart-legendText')
			.filter(d => d.id === id)
			.style('font-weight', '600');
	}

	sliceLeave(id) {
		this._legendList.selectAll('.js-BhrPercentDonutChart-legendText')
			.filter(d => d.id === id)
			.style('font-weight', 'normal');
	}
}
