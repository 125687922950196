export const DATA_INITIAL_VALUE = 'data-initial-value';
export const DATA_INITIAL_VALUE_IS_SET = 'data-initial-value-is-set';
export const DATA_TEMPORARY_VALUE = 'data-temporary-value';
export const DATA_TEMPORARY_VALUE_IS_SET = 'data-temporary-value-is-set';

export function getZeroFormattedCurrencyValue($referenceCurrencyInputElement: JQuery): string {
	const currencySymbol = $referenceCurrencyInputElement.data('cursymbol') || window.GLOBAL_CURRENCY_SYMBOL || '$';
	const currencySymbolPosition = $referenceCurrencyInputElement.data('currencysymbolposition') || 0;
	const decimalChar = $referenceCurrencyInputElement.data('decimal-char') || window.GLOBAL_DECIMAL_CHAR || '.';
	const thousandsSeparator = $referenceCurrencyInputElement.data('thousands-separator') || window.GLOBAL_THOUSANDS_SEPARATOR || ',';

	const $tempInputElement = $('<input />');
	$tempInputElement.attr('value', '0');

	// @ts-ignore
	if ($tempInputElement.formatCurrency) {
		// @ts-ignore
		$tempInputElement.formatCurrency({
			decimal: decimalChar,
			position: currencySymbolPosition,
			symbol: currencySymbol,
			thousands: thousandsSeparator,
		});
		return $tempInputElement.attr('value');
	}

	return '$0.00';
}

export function jqGetDataInitialValue($element: JQuery): string {
	return $element.attr(DATA_INITIAL_VALUE);
}

export function jqGetDataTemporaryValue($element: JQuery): string {
	return $element.attr(DATA_TEMPORARY_VALUE);
}

export function jqGetInputValue($inputElement: JQuery): string {
	return $inputElement.attr('value');
}

export function jqGetSelectedValue($selectElement: JQuery): string {
	return $selectElement.find('ba-option[selected]').attr('value');
}

export function jqHasDataInitialValue($element: JQuery): boolean {
	return $element.attr(DATA_INITIAL_VALUE_IS_SET) === 'true';
}

export function jqHasDataTemporaryValue($element: JQuery): boolean {
	return $element.attr(DATA_TEMPORARY_VALUE_IS_SET) === 'true';
}

export function jqRemoveDataTemporaryValue($element: JQuery): void {
	$element.removeAttr(DATA_TEMPORARY_VALUE);
	$element.removeAttr(DATA_TEMPORARY_VALUE_IS_SET);
}

export function jqSetDataInitialValue($element: JQuery, value: string): void {
	$element.attr(DATA_INITIAL_VALUE, value);
	$element.attr(DATA_INITIAL_VALUE_IS_SET, 'true');
}

export function jqSetDataTemporaryValue($element: JQuery, value: string): void {
	$element.attr(DATA_TEMPORARY_VALUE, value);
	$element.attr(DATA_TEMPORARY_VALUE_IS_SET, 'true');
}

export function jqSetInputValue($inputElement: JQuery, value: string): void {
	$inputElement.attr('value', value);
}

export function jqSetSelectedValue($selectElement: JQuery, value: string): void {
	$selectElement.find('ba-option[selected]').removeAttr('selected');
	$selectElement.find(`ba-option[value="${value}"]`).attr('selected', 'true');
}

export function jqTrySetInputDataInitialValue($inputElement: JQuery): void {
	if (!jqHasDataInitialValue($inputElement)) {
		jqSetDataInitialValue($inputElement, jqGetInputValue($inputElement));
	}
}

export function jqTrySetSelectDataInitialValue($selectElement: JQuery): void {
	if (!jqHasDataInitialValue($selectElement)) {
		jqSetDataInitialValue($selectElement, jqGetSelectedValue($selectElement));
	}
}

export function jqTryRestoreInputDataTemporaryValue($inputElement: JQuery): void {
	if (jqHasDataTemporaryValue($inputElement)) {
		$inputElement.attr('value', jqGetDataTemporaryValue($inputElement));
		jqRemoveDataTemporaryValue($inputElement);
	}
}

export function jqTryRestoreSelectDataTemporaryValue($selectElement: JQuery): void {
	if (jqHasDataTemporaryValue($selectElement)) {
		jqSetSelectedValue($selectElement, jqGetDataTemporaryValue($selectElement));
		jqRemoveDataTemporaryValue($selectElement);
	}
}

export function jqUpdatePaidPerBasedOnPayType(
	$payTypeSelectElement: JQuery,
	$paidPerElement: JQuery,
	$paidPerSelectElement: JQuery,
): void {
	jqTrySetSelectDataInitialValue($payTypeSelectElement);
	jqTrySetSelectDataInitialValue($paidPerSelectElement);

	const payTypeValue = jqGetSelectedValue($payTypeSelectElement);

	$paidPerSelectElement.find('ba-option[value="Hour"]').prop('hidden', true);

	if (!payTypeValue) {
		$paidPerElement.removeClass('hidden');
		$paidPerSelectElement.attr('disabled', 'true');

		// Try and restore any temporary paidPerValue before reading it.
		jqTryRestoreSelectDataTemporaryValue($paidPerSelectElement);
		const paidPerValue = jqGetSelectedValue($paidPerSelectElement);
		if (paidPerValue === 'Hour') {
			$paidPerSelectElement.find('ba-option[selected]').removeAttr('selected');
		}
	} else if (payTypeValue === 'Salary') {
		$paidPerElement.removeClass('hidden');
		$paidPerSelectElement.removeAttr('disabled');

		// Try and restore any temporary paidPerValue before reading it.
		jqTryRestoreSelectDataTemporaryValue($paidPerSelectElement);
		const paidPerValue = jqGetSelectedValue($paidPerSelectElement);
		const initialPayTypeValue = jqGetDataInitialValue($payTypeSelectElement);
		const initialPaidPerValue = jqGetDataInitialValue($paidPerSelectElement);
		if (initialPayTypeValue === 'Salary' && initialPaidPerValue === 'Hour') {
			$paidPerSelectElement.find('ba-option[value="Hour"]').prop('hidden', false);
		} else if (paidPerValue === 'Hour') {
			$paidPerSelectElement.find('ba-option[selected]').removeAttr('selected');
		}
	} else if (payTypeValue === 'Hourly') {
		$paidPerElement.addClass('hidden');
		$paidPerSelectElement.removeAttr('disabled');

		// Store the paidPerValue as a temporary value before selecting 'Hour' so it can be restored later as needed.
		const paidPerValue = jqGetSelectedValue($paidPerSelectElement);
		if (!jqHasDataTemporaryValue($paidPerSelectElement)) {
			jqSetDataTemporaryValue($paidPerSelectElement, paidPerValue);
		}
		$paidPerSelectElement.find('ba-option[value="Hour"]').prop('hidden', false);
		jqSetSelectedValue($paidPerSelectElement, 'Hour');
	} else if (payTypeValue === 'Commission') {
		$paidPerElement.addClass('hidden');
		$paidPerSelectElement.removeAttr('disabled');
		$paidPerSelectElement.find('ba-option[value="Hour"]').prop('hidden', false);

		// Store the paidPerValue as a temporary value before changing it so it can be restored later as needed.
		const paidPerValue = jqGetSelectedValue($paidPerSelectElement);
		if (!jqHasDataTemporaryValue($paidPerSelectElement)) {
			jqSetDataTemporaryValue($paidPerSelectElement, paidPerValue);
		}

		const initialPayTypeValue = jqGetDataInitialValue($payTypeSelectElement);
		const initialPaidPerValue = jqGetDataInitialValue($paidPerSelectElement);
		if (initialPayTypeValue === 'Commission') {
			jqSetSelectedValue($paidPerSelectElement, initialPaidPerValue);
		} else {
			jqSetSelectedValue($paidPerSelectElement, 'Hour');
		}
	} else if (payTypeValue === 'Contract') {
		$paidPerElement.removeClass('hidden');
		$paidPerSelectElement.removeAttr('disabled');

		// Try and restore any temporary paidPerValue before reading it.
		jqTryRestoreSelectDataTemporaryValue($paidPerSelectElement);
		const paidPerValue = jqGetSelectedValue($paidPerSelectElement);
		const initialPayTypeValue = jqGetDataInitialValue($payTypeSelectElement);
		const initialPaidPerValue = jqGetDataInitialValue($paidPerSelectElement);
		if (initialPayTypeValue === 'Contract' && initialPaidPerValue === 'Hour') {
			$paidPerSelectElement.find('ba-option[value="Hour"]').prop('hidden', false);
		} else if (paidPerValue === 'Hour') {
			$paidPerSelectElement.find('ba-option[selected]').removeAttr('selected');
		}
	} else {
		$paidPerElement.addClass('hidden');
		$paidPerSelectElement.removeAttr('disabled');

		// Try and restore any temporary paidPerValue before reading it.
		jqTryRestoreSelectDataTemporaryValue($paidPerSelectElement);
		const paidPerValue = jqGetSelectedValue($paidPerSelectElement);
		if (paidPerValue === 'Hour') {
			$paidPerSelectElement.find('ba-option[selected]').removeAttr('selected');
		}
	}
}

export function jqUpdatePayRateBasedOnPayType(
	$payTypeSelectElement: JQuery,
	$payRateElement: JQuery,
	$payRateInputElement: JQuery,
	$payRateButtonElement: JQuery,
): void {
	jqTrySetSelectDataInitialValue($payTypeSelectElement);
	jqTrySetInputDataInitialValue($payRateInputElement);

	const payTypeValue = jqGetSelectedValue($payTypeSelectElement);

	if (!payTypeValue) {
		$payRateElement.removeClass('hidden');
		$payRateInputElement.prop('disabled', true);
		$payRateButtonElement.prop('disabled', true);
		jqTryRestoreInputDataTemporaryValue($payRateInputElement);
	} else if (payTypeValue === 'Commission') {
		$payRateElement.addClass('hidden');
		$payRateInputElement.prop('disabled', false);
		$payRateButtonElement.prop('disabled', false);

		const payRateValue = jqGetInputValue($payRateInputElement);
		// Store the payRateValue as a temporary value before changing it so it can be restored later as needed.
		if (!jqHasDataTemporaryValue($payRateInputElement)) {
			jqSetDataTemporaryValue($payRateInputElement, payRateValue);
		}

		const initialPayTypeValue = jqGetDataInitialValue($payTypeSelectElement);
		const initialPayRateValue = jqGetDataInitialValue($payRateInputElement);
		if (initialPayTypeValue === 'Commission') {
			jqSetInputValue($payRateInputElement, initialPayRateValue);
		} else {
			const zeroFormattedCurrencyValue = getZeroFormattedCurrencyValue($payRateInputElement);
			jqSetInputValue($payRateInputElement, zeroFormattedCurrencyValue);
		}
	} else {
		$payRateElement.removeClass('hidden');
		$payRateInputElement.prop('disabled', false);
		$payRateButtonElement.prop('disabled', false);
		jqTryRestoreInputDataTemporaryValue($payRateInputElement);
	}
}

export function jqUpdatePayTypeBasedOnPayType($payTypeSelectElement: JQuery): void {
	jqTrySetSelectDataInitialValue($payTypeSelectElement);

	const initialPayTypeValue = jqGetDataInitialValue($payTypeSelectElement);
	const payTypeValue = jqGetSelectedValue($payTypeSelectElement);
	if (initialPayTypeValue === 'Contract') {
		$payTypeSelectElement.find('ba-option[value="Contract"]').prop('hidden', false);
	} else {
		$payTypeSelectElement.find('ba-option[value="Contract"]').prop('hidden', true);
		if (payTypeValue === 'Contract') {
			$payTypeSelectElement.find('ba-option[selected]').removeAttr('selected');
		}
	}
}

export function jsUpdatePayPerTextBasedOnPayType($payTypeSelectElement: JQuery, $paidPerTextElement: JQuery): void {
	const payTypeValue = jqGetSelectedValue($payTypeSelectElement);

	if (!payTypeValue || payTypeValue === 'Salary' || payTypeValue === 'Contract') {
		$paidPerTextElement.text($.__('per'));
		$paidPerTextElement.removeClass('hidden');
	} else if (payTypeValue === 'Hourly') {
		$paidPerTextElement.text($.__('per hour'));
		$paidPerTextElement.removeClass('hidden');
	} else {
		$paidPerTextElement.addClass('hidden');
	}
}
