import {
	Avatar,
	BodyText,
	createStyles,
	Flex,
	IconV2,
	LayoutBox,
	Link,
	makeStyles,
} from '@bamboohr/fabric';
import { ReactElement } from 'react';

import {
	Aim14x15,
	Envelope20x16,
	Facebook20x20,
	Instagram20x20,
	Linkedin20x20,
	Pinterest20x20,
	Skype14x14,
	Twitter20x20,
} from '@bamboohr/grim';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	Employee,
	SocialItem,
} from 'NHPTemplates.mod/Previewer/Components/EmployeeInfo/employeeInterface';

interface Props {
	employee: Employee;
	avatarSize?: 32 | 40 | 48 | 56 | 64 | 72 | 80 | 96 | 128 | 160 | 224;
}

const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		contactInfo: {
			marginTop: '20px',
			display: 'flex',
			flexGrow: 1,
			lineHeight: typography.fabricLineHeight('small'),
		},
		contactImgWrapper: {
			marginRight: '20px',
		},
		contactImg: {
			borderRadius: '100%',
			width: '86px',
			height: '86px',
		},
		contactHead: {
			fontSize: typography.fabricFontSize('biggie'),
			lineHeight: typography.fabricLineHeight('biggie'),
			fontWeight: typography.fontWeightMedium,
		},
		contactSub: {
			fontSize: typography.fabricFontSize('small'),
			lineHeight: typography.fabricLineHeight('small'),
			color: palette.gray[700],
		},
		contactPronoun: {
			fontSize: typography.fabricFontSize('small'),
			color: palette.gray[700],
		},
		socials: {
			marginTop: '10px',
			display: 'flex',
			alignItems: 'center',
			'& a': {
				fill: palette.grey[500],
				marginRight: '10px',
				'&:hover': {
					fill: palette.primary.main,
				},
			},
		},
	});
});

const SOCIAL_ICONS = {
	Aol: ifFeature(
		'encore',
		<IconV2 name="globe-solid" size={16} />,
		<Aim14x15 />,
	),
	Facebook: ifFeature(
		'encore',
		<IconV2 name="facebook" size={16} />,
		<Facebook20x20 />,
	),
	Instagram: ifFeature(
		'encore',
		<IconV2 name="square-instagram" size={16} />,
		<Instagram20x20 />,
	),
	LinkedIn: ifFeature(
		'encore',
		<IconV2 name="linkedin" size={16} />,
		<Linkedin20x20 />,
	),
	Pinterest: ifFeature(
		'encore',
		<IconV2 name="pinterest-p" size={16} />,
		<Pinterest20x20 />,
	),
	Skype: ifFeature('encore', <IconV2 name="skype" size={16} />, <Skype14x14 />),
	Twitter: ifFeature(
		'encore',
		<IconV2 name="square-x-twitter" size={16} />,
		<Twitter20x20 />,
	),
};

export function EmployeeInfo(props: Props): ReactElement {
	const { employee, avatarSize } = props;
	const {
		email,
		extension,
		firstName,
		lastName,
		phone,
		position,
		profileImageSrc,
		pronounsLabel,
		socials,
	} = employee;
	const classes = styles();
	const defaultAvatarSize = 64;
	const shouldSetMarginBottom =
		Array.isArray(socials) && !email && socials.length === 0;

	return ifFeature(
		'encore',
		<Flex gap={2} marginBottom={shouldSetMarginBottom ? '20px' : undefined}>
			<Avatar
				alt={`${firstName} ${lastName}`}
				size={avatarSize || defaultAvatarSize}
				src={profileImageSrc}
			/>
			<LayoutBox>
				<BodyText color="neutral-extra-strong" weight="regular" size="medium">
					{firstName} {lastName}
					{pronounsLabel && (
						<BodyText
							color="neutral-extra-strong"
							inline={true}
							size="extra-small"
						>
							({pronounsLabel})
						</BodyText>
					)}
				</BodyText>
				<BodyText
					color="neutral-extra-strong"
					size="extra-small"
					weight="regular"
				>
					{position}
				</BodyText>
				<BodyText color="neutral-extra-strong" size="extra-small">
					{phone} {extension && <span>ext. {extension}</span>}
				</BodyText>
				{email && (
					<Link
						href={`mailto:${email}`}
						rel="noopener noreferrer"
						size="extra-small"
						target="_blank"
					>
						{email}
					</Link>
				)}
				{(email || socials) && (
					<Flex gap="10px">
						{email && (
							<Link
								color="muted"
								href={`mailto:${email}`}
								rel="noopener noreferrer"
								target="_blank"
							>
								<IconV2 name="envelope-regular" size={16} />
							</Link>
						)}
						{socials &&
							socials.map((socialItem: SocialItem) => {
								return (
									<Link
										color="muted"
										href={socialItem.href}
										key={socialItem.type}
										rel="noopener noreferrer"
										target="_blank"
									>
										{SOCIAL_ICONS[socialItem.type]}
									</Link>
								);
							})}
					</Flex>
				)}
			</LayoutBox>
		</Flex>,
		<div className={classes.contactInfo}>
			<div className={classes.contactImgWrapper}>
				<img
					alt={`${firstName} ${lastName}`}
					className={classes.contactImg}
					src={profileImageSrc}
				/>
			</div>
			<div>
				<p className={classes.contactHead}>
					{firstName} {lastName}
					{pronounsLabel && (
						<span className={classes.contactPronoun}> ({pronounsLabel})</span>
					)}
				</p>
				<p className={classes.contactSub}>{position}</p>
				<p className={classes.contactSub}>
					{phone} {extension && <span>ext. {extension}</span>}
				</p>
				{email && (
					<a href={`mailto:${email}`} rel="noopener noreferrer" target="_blank">
						{email}
					</a>
				)}
				{(email || socials) && (
					<div className={classes.socials}>
						{email && (
							<a
								href={`mailto:${email}`}
								rel="noopener noreferrer"
								target="_blank"
							>
								<Envelope20x16 />
							</a>
						)}
						{socials &&
							socials.map((socialItem: SocialItem) => {
								return (
									<a
										href={socialItem.href}
										key={socialItem.type}
										rel="noopener noreferrer"
										target="_blank"
									>
										{SOCIAL_ICONS[socialItem.type]}
									</a>
								);
							})}
					</div>
				)}
			</div>
		</div>,
	);
}
