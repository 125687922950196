import { Component, Fragment } from 'react';
import { throttle } from 'lodash';

import { SMALL_SCREEN_WIDTH } from './constants';

export class ScreenSize extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSmallScreen: window.innerWidth <= this._getSmallScreenSize(),
		};
	}

	_getSmallScreenSize() {
		const { smallScreenSize } = this.props;
		return smallScreenSize ? smallScreenSize : SMALL_SCREEN_WIDTH;
	}

	_throttleHandleWindowResize = () => {
		this.setState({ 
			isSmallScreen: window.innerWidth <= this._getSmallScreenSize(),
		});
	}

	_handleWindowResize = throttle(() => {
		this._throttleHandleWindowResize();
	}, 200);

	componentDidMount() {
		window.addEventListener('resize', this._handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._handleWindowResize);
	}
	
	render() {
		const { render } = this.props;
		return (
			<Fragment>{ render(this.state) }</Fragment>
		);
	}
}
