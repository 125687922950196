import { BodyText, Flex } from '@bamboohr/fabric';
import { TimesheetShiftDifferential } from './types';
import { getShiftDifferentialText } from './utils';

export interface ShiftDifferentialsListProps {
	shiftDifferentials: TimesheetShiftDifferential[];
}

export function ShiftDifferentialsList({
	shiftDifferentials,
}: ShiftDifferentialsListProps) {
	return (
		<Flex flexDirection="column" gap={0.75}>
			{shiftDifferentials.map((shiftDifferential) => (
				<BodyText color="neutral-strong" icon="badge-dollar-regular" key={shiftDifferential.id} size='small'>
					{getShiftDifferentialText(shiftDifferential)}
				</BodyText>
			))}
		</Flex>
	);
}
