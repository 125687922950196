import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FunctionComponent } from 'react';

const HoverMap: FunctionComponent<DynamicForm.Hover> = ({
	hover,
	isEditable,
	context,
	children,
}) => {
	const {
		controls: { HoverBase },
	} = context;

	if (!hover.link) {
		// Map link is only created if all fields are entered
		return <> { children } </>;
	}

	return (
		<HoverBase hoverChild={ children } isEditable={ isEditable }>
			<>
				<span className="HoverHint__icon">
					{ifFeature(
						'encore',
						<IconV2 color='info-strong' name="location-dot-solid" size={ 12 } />,
						<Icon brand={ true } name="fab-location-9x12" />
					)}
				</span>
				<a
					className="HoverHint__link"
					href={ hover.link }
					rel="noopener noreferrer"
					target="_blank"
				>
					{ $.__('Map') }
				</a>
			</>
		</HoverBase>
	);
};

export default HoverMap;
