import { Dropdown } from '@fabric/dropdown';
import { PureComponent, Fragment } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { getConfig, DeleteMessageForComments} from '../../../../constants';
import { deleteModal } from './delete-modal';

import './style.styl';

const DEFAULT_TITLE = $.__('Delete Comment?');

export default class ActionDropdown extends PureComponent {
	constructor(props) {
		super(props);

		const {item, ...actions} = props;
		const menu = this._buildMenu(item);
		this._isMounted = false;
		this.state = {
			actions,
			item,
			menu,
			title: DEFAULT_TITLE,	
			dropdownState: '',			
			visible: false
		};	
	}0

	/**
	 * Checks for perms and build's out the dropdown menu
	 * @param  {Object} item   The comment object
	 */
	_buildMenu = (item) => {
		const data = {
			items: [],
		};
	
		const canEdit = {
			items: [{
				text: $.__('Edit Comment'),
				value: 'edit'				
			}],
			type: 'group',
			key: `edit-${ item.id }`
			
		};
	
		const canDelete = {
			items: [{
				text: $.__('Delete Comment'),
				value: 'delete',
			}],
			type: 'group',
			key: `delete-${ item.id }`	
		};
		
		if (item.canEdit) {
			data.items.push(canEdit);
		}
	
		if (item.canDelete) {
			data.items.push(canDelete);
		}

		return data;
	}
	

	/**
	 * When selection occurs, check the value and run corresponding action
	 * @param  {String} value   The value of the selection (edit, delete, pin)
	 */
	_handleSelect = (value) => {

		if (window.GLOBAL_IS_ASSUMED_USER) {
			return window.disabledForPermsTest();
		}

		const { item, actions } = this.state;

		switch (value) {
			case 'edit':
				actions.beginEditing(item.id);				
				break;
			case 'delete':
				this.toggleModal(true);
				break;
			default:
				break;
		}
	}

	/**
	 * dropdown open
	 */
	_handleOpen = () => {
		this.setState({
			dropdownState: 'FeedActions--open'
		});
	}

	/**
	 * dropdown close
	 */
	_handleClose = (e) => {
		const { visible } = this.state;
		if (!visible && this._isMounted) {
			this.setState({
				dropdownState: ''
			});
		}
		
	}

	/*
	**
	* Hide/Show the modal
	* @param  {bool} show State to set the modal visible or hidden
	*/
	toggleModal = (visible) => {
		if (visible) {
			const { item } = this.state;
			this.setState({
				visible,
				title: getTitle(item),
				message: getMessage(item),
				dropdownState: ''
			});			
		} else {
			this.setState({
				visible
			});
		}		
	};

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	// Only display dropdown if actions exist
	render() {
		const { dropdownState, menu, visible } = this.state;
		if (menu.items.length) {
			return (
				<Fragment>
					<div className={ `FeedActions ${ dropdownState }` }>
						<Dropdown
							buttonSettings={ifFeature(
								'encore',
								{},
								{
									outline: true,
									secondary: true,
									size: 'teenie'
								}
							)}
							ButtonProps={ifFeature(
								'encore',
								{
									outline: true,
									secondary: true,
									size: 'small'
								}
							)}
							items={ menu.items }
							onClose={ this._handleClose }
							onOpen={ this._handleOpen }
							onSelect={ this._handleSelect }
							type="icon"
						/>
					</div>
					{ visible ? deleteModal({ ...this.state }, this.toggleModal) : null }
				</Fragment>
			);
		}
		return null;
	}
}

/**
 * get the correct modal message for the comment
 * @param  {object} item       The Comment Object
 */
const getMessage = (item) => {
	const FEED_CONFIG = getConfig();
	return (
		<div className="FeedModalContent">
			{
				item.eventId
					? FEED_CONFIG.externalRenderByType(item, 'delete')
					: DeleteMessageForComments(item)
			}
		</div>
	);
		
};

/**
 * get the correct modal title
 * @param  {object} item      The Comment Object
 */
const getTitle = (item) => {
	const FEED_CONFIG = getConfig();
	return item.eventId
		? FEED_CONFIG.externalRenderByType(item, 'title')
		: DEFAULT_TITLE;
};
