import {
	Modal,
} from 'modal-legacy';
import {
	uniqueId,
} from 'lodash';
import {
	useEffect,
	useRef,
	useState,
} from 'react';

import {
	showSlidedown,
} from 'Alerts.mod';

import {
	addItem,
} from './service';

/**
 * @deprecated Use the other AddItemModal component instead. This one uses the old modal.
 */
export function AddItemModal(props) {
	const {
		fieldId,
		isOpen,
		label = $.__('Item'),
		onCancel,
		onSave,
		name,
		url = '/ajax/list_insert.php',
	} = props;

	const formRef = useRef();
	const inputId = uniqueId('add-item');
	const [value, setValue] = useState('');

	const _handlePrimaryAction = async () => {
		if (!formRef.current || !$(formRef.current).valid()) {
			return;
		}

		try {
			const id = await addItem(
				url,
				fieldId,
				name,
				value,
			);

			onSave(value, id);

			showSlidedown($.__('The %s was added successfully.', label), 'success');
		} catch (e) {
			const message = e.data && (e.data.message || e.data.error) ? e.data.message || e.data.error : $.__('Uh oh. Something went wrong. Please try again.');

			showSlidedown(message, 'error');
		}
	};

	const _handleOpen = () => {
		if (formRef.current) {
			$(formRef.current).bhrValidate();
		}
	};

	const modalProps = 	{
		isOpen,
		onClose: onCancel,
		onOpen: _handleOpen,
		primaryAction: _handlePrimaryAction,
		primaryActionText: $.__('Save'),
		title: $.__('Add %s', label),
	};

	useEffect(() => {
		if (!isOpen) {
			setValue('');
		}
	}, [isOpen]);

	return (
		<Modal { ...modalProps }>
			<form className="BhrForms" ref={ formRef }>
				<div className="fieldBox required">
					<label
						className="fab-Label fab-Label--required"
						htmlFor={ inputId }
					>
						{ $.__('%s Name', label) }
					</label>
					<input
						className="fab-TextInput fab-TextInput--width7"
						id={ inputId }
						name={ name }
						onChange={ e => setValue(e.currentTarget.value) }
						required={ true }
						type="text"
						value={ value }
					/>
				</div>
			</form>
		</Modal>
	);
}
