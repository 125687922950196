import { times } from 'lodash';
import moment from 'moment';
import { PayrollFormData } from '../../types';

export const FEDERAL_ID_OPTIONS = [
	{
		label: $.__('Yes'),
		value: 'yes' as PayrollFormData['use_federal_id_after_final_date'],
	},
	{
		label: $.__('No'),
		value: 'no' as PayrollFormData['use_federal_id_after_final_date'],
	},
];

export const QUARTER_SELECT_OPTIONS = times(4, (i) => {
	const value = `${i + 1}`;
	return { text: value, value };
});

const POSSIBLE_YEARS = [moment().subtract(1, 'years').format('YYYY'), moment().format('YYYY')];

export const YEAR_SELECT_OPTIONS = POSSIBLE_YEARS.map((value) => ({ text: value, value }));
