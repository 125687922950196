import { Currency } from './types';

const THOUSANDS_SEPARATOR = window.GLOBAL_THOUSANDS_SEPARATOR ? window.GLOBAL_THOUSANDS_SEPARATOR : ',';
const DECIMAL_CHAR = window.GLOBAL_DECIMAL_CHAR ? window.GLOBAL_DECIMAL_CHAR : '.';

const generateCents = (amount: number): string => {
	const cents = amount % 100;
	if (cents < 10) {
		return `0${cents}`;
	}

	return String(cents);
};

const addThousandsSeparators = (amount: string): string => {
	for (let i = 0; i < Math.floor((amount.length - (1 + i)) / 3); i++) {
		amount = amount.substring(0, amount.length - (4 * i + 3)) + THOUSANDS_SEPARATOR + amount.substring(amount.length - (4 * i + 3));
	}

	return amount;
};

const formatAmountWithCurrency = (amount: number, currencySymbol: string, symbolPosition?: number): string => {
	const sign = amount === (amount = Math.abs(amount));
	amount = Math.floor(amount * 100 + 0.50000000001);
	const cents = generateCents(amount);
	amount = Math.floor(amount / 100);

	let amountString = amount.toString();
	amountString = addThousandsSeparators(amountString);

	return symbolPosition
		? (sign ? '' : '-') + amountString + DECIMAL_CHAR + cents + currencySymbol
		: (sign ? '' : '-') + currencySymbol + amountString + DECIMAL_CHAR + cents;
};

export const getFormattedAmountFromNumber = (amount: number | null, currencySymbol: string, currencyPosition?: number): string => {
	if (amount === null) {
		return '';
	}

	return formatAmountWithCurrency(amount, currencySymbol, currencyPosition);
};

export const convertStringToNumber = (value: string, currencySymbol?: string): number | null => {
	if (currencySymbol) {
		value = value.replace(currencySymbol, '');
	}

	if (value === '') {
		return null;
	}

	// Remove any leading characters besides numeric values and separators
	const regex = new RegExp('[^-0-9.,]+', 'gi');
	value = value.replace(regex, '');

	// Remove thousands character
	const thousandsRegex = new RegExp(`\\${THOUSANDS_SEPARATOR}`, 'gi');
	value = value.replace(thousandsRegex, '');

	if (DECIMAL_CHAR === value[0]) {
		value = `0${value}`;
	}

	let sanitizedNumber = Number(value.replace(',', '.').replace(/^(-?)([^0-9-]+)/, '$1'));

	if (isNaN(sanitizedNumber)) {
		sanitizedNumber = 0;
	}

	return sanitizedNumber;
};

export const getFormattedAmountFromString = (cost: string, currencySymbol: string, currencyPosition?: number): string => {
	if (cost === '') {
		return '';
	}

	const cleanedNumber = convertStringToNumber(cost, currencySymbol);
	return getFormattedAmountFromNumber(cleanedNumber, currencySymbol, currencyPosition);
};

export const getCurrencySymbol = (currencies: Currency[], currencyCode: string): string => {
	let currencySymbol = '';

	const currencyItem = currencies.find((item) => item.code === currencyCode);

	if (currencyItem) {
		currencySymbol = currencyItem.symbol;
	}

	return currencySymbol;
};

export const getCurrencyPosition = (currencies: Currency[], currencyCode: string): number => {
	let currencyPosition = 0;

	const currencyItem = currencies.find((item) => item.code === currencyCode);

	if (currencyItem) {
		currencyPosition = currencyItem.symbolPosition;
	}

	return currencyPosition;
};
