import { includes } from 'lodash';

export function buildFilesEndpoint(
	url: string, 
	sectionId: number | string, 
	offset: number, 
	limit: number
): string {
	const hasParams = includes(url, '\?');
	const { excludeEsignatures } = window.sharedFilesManager;
	return `${ url }${ hasParams ? '&' : '?' }sectionId=${ sectionId }&offset=${ offset }&limit=${ limit }${ excludeEsignatures ? '&excludeEsignatures=true' : '' }`;
		
}
