import {
	useEffect,
	useRef,
} from 'react';
import {
	isFunction,
} from 'lodash';

import {
	sanitize,
} from '@utils/dom';
import {
	createLogger,
} from '@utils/dev-logger';


const {
	warn,
} = createLogger('static-html.react');

/**
 * Inject an HTML string into a `<div>` as DOM Elements
 *
 * ## DO NOT USE WITH HTML THAT IS NOT 100% SAFE!!!
 *
 * By default, it will sanitize the html,
 * but by passing `dangerous={ true }` it
 * will inject the HTML as-is, which will
 * execute any `<script>` tags.
 *
 * @param {object} props
 * @param {string} props.html The HTML string to inject
 * @param {boolean} [props.dangerous] Injects the html without sanitization. **WARNING: WILL EXECUTE SCRIPT TAGS!!!**
 * @param {Parameters<typeof sanitize>[1]} [props.sanitizeOptions] Options to pass to the sanitize function
 * @param {(ref: HTMLDivElement) => void} [props.onRender] An optional callback function called when HTML is injected
 * @returns {JSX.Element} A `<div>` with the injected DOM elements as children
 */
export default function StaticHtml({
	html,
	dangerous = false,
	sanitizeOptions = {},
	onRender,
}) {
	const ref = useRef(null);

	useEffect(() => {
		if (
			!(ref.current instanceof HTMLDivElement) ||
			typeof html !== 'string' ||
			html.trim() === ''
		) {
			return;
		}

		const frag = document.createRange()
			.createContextualFragment(dangerous ? html : sanitize(html, sanitizeOptions));

		if (ref.current.innerHTML.trim() !== '') {
			warn('DOM will be replaced');
		}

		ref.current.innerHTML = '';
		ref.current.appendChild(frag);

		if (isFunction(onRender)) {
			onRender(ref.current);
		}
	}, [html, ref]);

	useEffect(() => {
		if (dangerous) {
			warn(`DANGEROUS: HTML will NOT be sanitized! BE CAREFUL!!!`);
		}
	}, []);

	return (
		<div ref={ ref } />
	);
}
