import { Fragment, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Icon, IconV2 } from '@bamboohr/fabric';
import './style.styl';

export const AvatarImage = ({ img, size = '45x45' }) => {
	const [onError, handleOnError] = useState(false);
	return (
		<Fragment>
			{img && !onError ? (
				<img alt='Employee Avatar' className='FeedListItemAvatar Avatar' onError={() => handleOnError(true)} src={img} />
			) : (
				<span className='FeedListItemAvatar Avatar'>
					{ifFeature('encore', <IconV2 name='user-solid' size='20' color='white' />, <Icon name='fab-person-circle-45x45' />)}
				</span>
			)}
		</Fragment>
	);
};
