import {scaleLinear, scaleOrdinal} from 'd3-scale';
import {select} from 'd3-selection';
import {stack, stackOrderReverse} from 'd3-shape';
import Popover from 'Popover.mod';

import {defaultsDeep} from 'lodash';

/******************************************************************************
	Data contract for slice chart

	{
		title: 'Creative Services',
		count: 12,
		values:
			{'I feel I am valued': 0.37,
			'I sometimes feel valued': 0.20,
			'I\m not sure others value what I do': 0.17,
			'I don\'t feel valued at BambooHR': 0.26
		},
		keys: ['I feel I am valued', 'I sometimes feel valued', 'I\m not sure others value what I do', 'I don\'t feel valued at BambooHR']
	}

******************************************************************************/

const CLASS_NAME = 'SliceChart';
const DEFAULT_OPTIONS = {
	chartClass: '',
	colors: ['#8db812', '#5b9fb2', '#f19d11', '#db6021'], // Green - Blue - Orange - Red
	container: {
		height: 57,
		width: 887
	},
	slice: {
		height: 17,
		hoverMargin: 4, // 2px for top and bottom
		width: 700
	}
};

export default class SliceChart {
	constructor(selector, options = DEFAULT_OPTIONS) {
		this._selector = selector;

		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);
	}

	/**
	 * Creates the main SVG for this chart with the required definitions
	 * @param {object} data Formatted Data ready to be sliced and displayed
	 */
	_createDom(data) {
		const container = select(this._selector).append('div', 'hr')
			.attr('class', `${ CLASS_NAME } ${ this._options.chartClass }`)
			.style('height', `${ this._options.container.height }px`);

		if(data.title) {
			this._title = container.append('div')
			.attr('class', `${ CLASS_NAME }__title ${ this._options.chartClass }__title`)
			.text(data.title)
			.append('span')
			.attr('class', `${ CLASS_NAME }__subtitle ${ this._options.chartClass }__subtitle`)
			.text(`(${ data.count })`);
		}

		this._svg = container.append('svg')
			.attr('class', `${ CLASS_NAME }__bar ${ this._options.chartClass }__bar`)
			.attr('viewBox', `0 0 ${ this._options.slice.width } ${ this._options.slice.height + this._options.slice.hoverMargin }`)
			.style('height', `${ this._options.slice.height + this._options.slice.hoverMargin }px`)
			.style('width', `${ this._options.slice.width }px`);
	}

	/**
	 * Draws each element in the order needed for the chart to display properlly
	 * @param {object} data Formatted Data ready to be sliced and displayed
	 */
	draw(data) {
		this._createDom(data);

		const that = this;
		that.totals = data.totals;

		const localStack = stack()
			.keys(data.keys)
			.order(stackOrderReverse);

		const stackedData = localStack([data.values]);

		const y = scaleLinear()
			.range([this._options.slice.height + this._options.slice.hoverMargin, 0])
			.domain([0, 1]);

		const x = scaleLinear()
			.range([0, this._options.slice.width])
			.domain([0, 1]);

		this.barColors = scaleOrdinal(this._options.colors);
		if (typeof this._options.getColor === 'function') {
			this.barColors = this._options.getColor;
		}
		this._drawBars(stackedData, x, that);


	}

	/**
	 * Draws the containers, and the individual cells of the bar chart
	 * @param {object}   stackedData Formatted data from d3.stack
	 * @param {function} x d3 function for the x axis
	 * @param {function} that instance of SliceChart
	 */
	_drawBars(stackedData, x, that) {
		// bind a <g> tag for each layer
		const LAYER_GROUP = this._svg.selectAll('g.layer').data(stackedData)
			.enter()
			.append('g')
			.attr('fill', d => this.barColors(d.key));

		// bind a <rect> to each value inside the layer
		const LAYERS = LAYER_GROUP.selectAll('rect').data(d => d);
		LAYERS.enter()
			.append('rect')
			.merge(LAYERS)
			.attr('x', d => x(d[0]))
			.attr('width', (d) => {
				const value = x(d[1]) - x(d[0]) - 2;
				return value > 0 ? value : 0;
			})
			.attr('y', this._options.slice.hoverMargin / 2)
			.attr('height', this._options.slice.height)
			.attr('class', `${ CLASS_NAME }__cell ${ this._options.chartClass }__cell`)
			.on('mouseenter', function(d) {
				that._onPathEnter(this, d, select(this.parentNode).datum(), that?.totals);
			})
			.on('mouseleave', function(d) {
				that._onPathExit(this);
			});
	}

	/**
	 * Event handler for when the mouse has entered a bar path
	 * @param  {object} path  The path that triggered the event
	 * @param  {object} d  The data represented by the path
	 * @param  {object} groupData  The data represented by group containing the path
	 * @param  {integer} totalCount  The total count of elements in the data Group
	 */
	_onPathEnter(path, d, groupData, totalCount) {
		select(path).transition()
			.duration(150)
			.attr('y', 0)
			.attr('height', this._options.slice.height + this._options.slice.hoverMargin);
		if(typeof this._options.getHoveredValue === 'function') {
			this._options.getHoveredValue(groupData.key)
		}
		if (!Popover.hasInstance(path)) {
			Popover.create(path, {
				template: {
					name: 'popover-standard',
					data: {
						title: this._options?.popover?.title ? this._options.popover.title(groupData) : `${ Math.round((d[1] - d[0]) * 100) }%`,
						content: this._options?.popover?.content ? this._options?.popover.content(groupData, totalCount) : groupData.key,
						popoverClass: `${ CLASS_NAME }__popover ${ this._options.chartClass }__popover `,
						titleCssOverride: {
							color: '#222222',
							'text-align': 'center',
							'font-weight': '600'
						},
						contentCssOverride: {
							'text-align': 'center',
						},
						rawContent: this._options?.popover?.rawContent ? this._options.popover.rawContent: false,
					}
				},
				position: 'top',
				triggerEvent: 'hover',
				showImmediately: true,
				closeX: false,
				push: 6,
				delay: 75
			});
		}
	}

	/**
	 * Event handler for when the mouse has left a bar path
	 * @param  {object} path The path that triggered the event
	 */
	_onPathExit(path) {
		if(typeof this._options.getHoveredValue === 'function') {
			this._options.getHoveredValue('')
		}
		select(path).transition()
			.duration(150)
			.attr('y', this._options.slice.hoverMargin / 2)
			.attr('height', this._options.slice.height);
	}

}
