import {
	EmailFilterData,
	SelectOptions,
	TemplateData,
	TemplateOptions,
	Question,
} from 'NHPTemplates.mod/Form/Interfaces';
import {updateFormState} from "NHPTemplates.mod/Form/utils";

interface RecipientType {
	id?: string;
	type: string;
	typeId?: number;
}

interface GTKYEmailFilterData {
	selectedParentId: string;
	selectedFilters: Array<string>;
}

interface ResponseTemplateData {
	arriveByTime: string;
	contactEmployeeId: number;
	createdUserId: number;
	default: boolean | number;
	getToKnowYouEmailTemplateId: number;
	gtkyQuestions: Array<Record<string, any>>;
	gtkyRecipients: Array<RecipientType>;
	id: number;
	location: string;
	name: string;
	otherInstructions: string;
	sendGetToKnowYouEmail: boolean;
	nhpTemplateId?: number;
}

interface ParsedTemplateData {
	newHirePacket: TemplateData,
	gtkyQuestions: Record<string, Question>;
	emailFilter: EmailFilterData;
}

export function parseEmailFilterData(emailFilter: Array<RecipientType>): GTKYEmailFilterData {
	let selectedParentId = '';
	const selectedFilters = emailFilter.map((filter): string => {
		selectedParentId = filter.type;
		return `${ filter?.typeId || filter?.id }`;
	});

	return {
		selectedParentId,
		selectedFilters,
	};
}

export function parseTemplateData(templateData: ResponseTemplateData): ParsedTemplateData {
	const gtkyQuestionObject = {};
	const packetData = { ...templateData };

	const {
		gtkyQuestions,
		gtkyRecipients,
	} = packetData;

	const {
		selectedParentId = '',
		selectedFilters,
	} = parseEmailFilterData(gtkyRecipients);

	packetData.nhpTemplateId = packetData.id;
	delete packetData.id;

	gtkyQuestions.map((question: Question, index) => {
		gtkyQuestionObject[index] = question;
	});

	updateFormState({
		gtkyRecipients: templateData.gtkyRecipients.map(recipient => {
			return {
				allEmployees: false,
				type: recipient.type,
				typeId: recipient.typeId,
			};
		})
	});

	return {
		newHirePacket: packetData as unknown as TemplateData,
		gtkyQuestions: gtkyQuestionObject,
		emailFilter: {
			selectData: [],
			selectedName: selectedParentId,
			selectedParentId,
			selectedValues: selectedFilters,
		},
	};
}

export function transformTemplateListToSelectOptions(templateOptions = []): Array<SelectOptions> {
	if (Array.isArray(templateOptions)) {
		return templateOptions.map((option: TemplateOptions) => {
			return {
				text: option.name,
				value: option.id,
			};
		});
	}

	return [];
}
