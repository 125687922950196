import { FunctionComponent } from 'react';
import { getCompensationStates, Fields } from 'dynamic-form';

const SelectOvertimeStatusField: FunctionComponent<DynamicForm.SelectElementProps> = (selectProps) => {
	const { props, settings, context } = selectProps;
	const { disabled = false, selectedValues = [] } = props;
	const {
		controls: { SelectField },
	} = context;
	const { [Fields.overtimeStatus]: {
		nonExemptEmptyPay,
		note,
	} } = getCompensationStates(context);

	const selectChildSettings: typeof settings = {
		...settings,
		note: {
			text: note,
			type: 'base',
		},
	};

	const selectChildProps: typeof props = {
		...props,
		isDisabled: nonExemptEmptyPay || disabled,
		selectedValues: nonExemptEmptyPay ? ['Exempt'] : selectedValues,
	};

	return (
		<SelectField
			context={ context }
			props={ selectChildProps }
			settings={ selectChildSettings }
		/>
	);
};

export default SelectOvertimeStatusField;
