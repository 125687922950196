import c from 'classnames';
import {
	BodyText,
	Flex,
	// @startCleanup encore
	Icon,
	// @endCleanup encore
	IconV2,
	LayoutBox,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';

import './deposit-account.styl';

import { ifFeature } from "@bamboohr/utils/lib/feature";

export function DepositAccount(props) {
	const {
		amount,
		errorMessage = '',
		imageUrl,
		isBankImageUrl,
		name,
		number,
		order,
		type,
		first,
		last,
		single,
	} = props;

	const boldClass = 'DepositAccount__text--bold';

	const text = first || single
		? (
			<Message
				em={ {tag: 'span', className: boldClass} }
				params={ [amount, name, type, number] }
				text={ $._(`*{1} of each check* goes to {2} {3} {4}`) }
			/>
		)
		: (
			<Message
				em={ {tag: 'span', className: boldClass} }
				params={ [amount, name, type, number] }
				text={ $._(`*{1} of the remaining amount* goes to {2} {3} {4}`) }
			/>
		);

	const accountDataClasses = c(
		'DepositAccount__data',
		{ 'DepositAccount__data--bordered': !single && !last }
	);

	return ifFeature(
		'encore',
		(
			<Flex alignItems="center" marginBottom={4}>
				{isBankImageUrl ? (
					<img alt="bank logo" className="DepositAccount__logo" src={ imageUrl } />
				) : (
					<LayoutBox marginRight={1.25} textAlign="center" width="81px">
						<IconV2
							color="neutral-weak"
							name="building-columns-regular"
							size={24}
						/>
					</LayoutBox>
				)}
				<BodyText>{ text }</BodyText>
				{errorMessage && (
					<BodyText color="error-medium">
						{ errorMessage }
					</BodyText>
				)}
			</Flex>
		),
		(
			<div className="DepositAccount">
				{!single && (
					<div className="DepositAccount__order">
						{order}
					</div>
				)}

				<div className={accountDataClasses}>
					{isBankImageUrl ?
						<img alt="bank logo" className="DepositAccount__logo" src={imageUrl}/> :
						<span className="DepositAccount__icon"><Icon name="fab-bank-24x24"/></span>
					}
					<div>
						<p className="DepositAccount__text">{text}</p>
						{errorMessage && <p className="DepositAccount__errorMessage">{errorMessage}</p>}
					</div>
				</div>
			</div>
		)
	);
}
