import { hasTrax } from 'BambooHR.util';
import { isValidUSZipCode } from 'String.util';
import {
	isVisible,
	isSelectedPSSyncing,
} from 'dynamic-form';

const message = $.__('Please enter a valid US Zip code.');

export const validateUSZipTrax = (context: DynamicForm.Context, field: DynamicForm.Data, params: any) => {
	return (value): boolean | string => {
		const {
			className,
			id,
		} = field.props;

		if (!value) {
			// field isn't filled in yet, let required or regex run
			return true;
		}

		if (!hasTrax() || className.includes('contact_zip') || !isVisible(document.getElementById(id))) {
			return true;
		}

		const isBambooPayroll = isSelectedPSSyncing(context);

		if (isBambooPayroll) {
			const isValid = isValidUSZipCode(value as string);
			return isValid || message;
		}

		return true;
	};
};
