import { render } from 'base/wrapped-render';

import AddNote from './add-note.react';
import './styles.styl';

export default function(data) {
	return new Promise((resolve) => {
		const addNoteDiv = document.createElement('div');
		render(<AddNote afterSave={ resolve } { ...data } />, addNoteDiv);
	});
}
