import classNames from 'classnames';
import './styles.styl';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Icon, IconV2 } from '@bamboohr/fabric';

export function Headline(props) {
	const {
		hasSeparator = true,
		icon,
		children,
	} = props;

	return (
		<div
			className={
				classNames('DataProcessingAgreementModalHeadline', {
					'DataProcessingAgreementModalHeadline--hasSeparator': hasSeparator,
				})
			}
		>
			{ifFeature(
				'encore',
				<IconV2
					color='primary-strong'
					name={ icon }
					size={60}
				/>,
				<Icon
					brand={ true }
					name={ icon }
				/>
			)}

			{
				children ? (
					<h3>{ children }</h3>
				) : null
			}
		</div>
	);
}
