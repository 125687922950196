/**
 * Functions needed for the HTML tables that are just dumped onto the page. Once those tables are converted to JSON on the BE we can get rid of these
 */

// $BhrTable__scroll horizontal scrolling pulled from BhrTable.js to get it exportable
export function initializeScrollableTable(): void {

	let resizeTimeoutId;
	findScrollableTables();
	$(window).resize(function(){
		clearTimeout(resizeTimeoutId);
		resizeTimeoutId = setTimeout(findScrollableTables, 400);
	});

	function findScrollableTables() {
		const scrollableTables = document.getElementsByClassName('js-BhrTable-scroll');
		for(let i = 0; i < scrollableTables.length; i++) {
			const currentTableWrapper = scrollableTables[i];
			const currentTable = currentTableWrapper.querySelector('.BhrTable__scrollWrapper-inner');
			if (currentTable.scrollWidth > currentTable.clientWidth) {
				currentTableWrapper.classList.add('scrollable');
				currentTable.addEventListener('scroll', function() {
					if (currentTable.scrollLeft > (currentTable.scrollWidth - (currentTable.clientWidth + 50))) {
						currentTableWrapper.classList.remove('scrollable');
					} else {
						currentTableWrapper.classList.add('scrollable');
					}
				});
			} else {
				currentTableWrapper.classList.remove('scrollable');
			}
		}
	}

	$('.js-BhrTable-scroll').on('mouseover', '.fab-Table__row', handleFloatActionsCell);

	function handleFloatActionsCell() {
		const tableRow = $(this)[0];
		const table = tableRow.closest('.scrollable .BhrTable__scrollWrapper-inner');
		const rowActionCell = $(this).find(".BhrTable__actions:visible").parent('.fab-Table__cell')[0];

		if(table && rowActionCell) {
			const cellWidth = rowActionCell.offsetWidth;
			if (table.scrollLeft < (tableRow.scrollWidth - (table.clientWidth + cellWidth))) {
				let actionCellHeight = '';
				$(this).find(".BhrTable__actions:visible").parent('.fab-Table__cell').each(function(i) {
					const styles = window.getComputedStyle(this);
					const floatingActionCellCSS = {
						left: table.clientWidth - parseInt(styles.width) + 'px',
						height: parseInt(styles.height) - parseInt(styles.borderBottomWidth) + 'px',
						display: 'flex',
						'flex-direction': 'column',
						'justify-content': 'center',
						'border-bottom': 'none',
					};
					actionCellHeight = styles.height;
					this.classList.add('BhrTable__actions--floating');
					$(this).css(floatingActionCellCSS);
				});
				$(this).css({ height: actionCellHeight });
			}
		}
	}

	$('.js-BhrTable-scroll').on('mouseout', '.fab-Table__row', function() {
		$(this).find(".BhrTable__actions:visible").parent('.fab-Table__cell').each(function(i) {
			$(this).removeAttr('style');
			this.classList.remove('BhrTable__actions--floating');
		});
	});
}
