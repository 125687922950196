import React, { lazy, ReactElement, Suspense } from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';

import { MainTechnicalDebtProps } from './main-technical-debt.types';

const MainTechnicalDebtPayrollRoutes = lazy(() => import(/* webpackChunkName: "payroll.routes" */ './subroutes/payroll/payroll.routes'));

export function MainTechnicalDebt(props: MainTechnicalDebtProps): ReactElement {
	const {route} = props;
	
	return (
		<MemoryRouter initialEntries={[route]} initialIndex={0}>
			<Suspense fallback={null}>
				<Switch>
					<Route path='payroll' render={() => <MainTechnicalDebtPayrollRoutes {...props} />} />
				</Switch>
			</Suspense>
		</MemoryRouter>
	);
}
