import { Ajax } from '@bamboohr/utils';
import { noop } from 'lodash';
import { Component } from 'react';
import c from 'classnames';
import {
	BodyText,
	Button,
	Flex,
	// @startCleanup encore
	Icon,
	// @endCleanup encore
	IconV2,
	LayoutBox,
	StyledBox,
	TextButton,
	Timeline,
	Tooltip,
} from '@bamboohr/fabric';
import { DirectDepositModal } from 'payroll/modals/direct-deposit-modal.react';

import { DepositBlankState } from './components/deposit-blank-state';
import { DepositAccount } from './components/deposit-account';

import {
	PERM_LEVEL,
	togglePaperCheckMsg,
} from './utils';

import './direct-deposit.styl';

import { ifFeature, isEnabled } from '@bamboohr/utils/lib/feature';

export class DirectDeposit extends Component {
	constructor(props) {
		super(props);

		const {
			deposits,
		} = props;

		this.state = {
			deposits: deposits || [],
			editPermissionData: {},
			showModal: false,
		};
	}

	componentDidMount = () => {
		const { employeeId, isEmployeeNewHirePacket } = this.props;

		if (!getIsDirectDepositInternalPermissionsEnabled()) {
			return;
		}

		// The below endpoint triggers a 401 unauthorized response currently for the employee's new hire packet.
		// An employee logged in and filling out the new hire packet should have access to edit direct deposit so we won't make this call.
		if (isEmployeeNewHirePacket) {
			return;
		}

		const directDepositKey = employeeId ? `direct_deposit.${employeeId}` : 'direct_deposit';

		Ajax.get(`/api/perms?keys=${directDepositKey}`).then((response) => {
			this.setState((prevState) => {
				const prevEditPermissionData = prevState.editPermissionData;

				return {
					editPermissionData: {
						...prevEditPermissionData,
						[employeeId]: !!response?.data?.[directDepositKey]?.edit,
					}
				}
			});
		});
	}

	_handleHideModal = () => this.setState({showModal: false});

	_handleShowModal = () => {
		if (window.ASSUMED_USER) {
			window.disabledForPermsTest();
		} else {
			this.setState({showModal: true});
		}
	};

	_handleSaveSuccess = (response) => {
		const {
			onSaveSuccess,
		} = this.props;
		const {
			data: {
				direct_deposits: {
					BANK
				}
			}
		} = response;

		this.setState({deposits: BANK || [], showModal: false}, () => {
			if (typeof onSaveSuccess === 'function') {
				onSaveSuccess(BANK);
			}
		});
	};

	getHasEditPermission = () => {
		const {
			employeeId,
			isEmployeeNewHirePacket,
		} = this.props;
		const { editPermissionData } = this.state;

		if (!getIsDirectDepositInternalPermissionsEnabled()) {
			return true;
		}

		// An employee logged in and filling out the new hire packet is assumed to have access to edit direct deposit.
		if (isEmployeeNewHirePacket) {
			return true;
		}

		return editPermissionData[employeeId];
	}

	getIsAddButtonDisabled = () => {
		const { isDisabled } = this.props;

		return !!isDisabled || !this.getHasEditPermission();
	}

	getIsUpdateButtonDisabled = () => {
		const { isDisabled } = this.props;

		return !!isDisabled;
	}

	createTimelineItems = (deposits) => {
		return deposits.map((deposit, index) => ({
			primaryText: (
				<DepositAccount
					first={index === 0}
					key={deposit.order}
					last={index === deposits.length - 1}
					single={deposits.length === 1}
					{...deposit}
				/>
			),
			isInverted: false,
		}));
	};

	_renderMainContent = (deposits, hasTrax, canEdit) => {
		const { useTextButton } = this.props;
		const depositsLength = deposits.length;

		const isAddButtonDisabled = this.getIsAddButtonDisabled();

		if (ifFeature('encore', true)) {
			if (depositsLength > 1) {
				return (
					<Timeline
						color="secondary"
						items={this.createTimelineItems(deposits)}
						orientation="vertical"
						size="small"
					/>
				);
			}

			if (depositsLength === 1) {
				return deposits.map((deposit, index) => (
					<DepositAccount
						key={deposit.order}
						first={index === 0}
						last={index === depositsLength - 1}
						single
						{...deposit}
					/>
				));
			}

			return !useTextButton && (
				<DepositBlankState
					buttonAction={this._handleShowModal}
					canEdit={canEdit}
					hasTrax={hasTrax}
					isAddButtonDisabled={isAddButtonDisabled}
				/>
			);
		}

		return deposits.length ? (
			deposits.map((deposit, index) => (
				<DepositAccount
					key={deposit.order}
					first={index === 0}
					last={index === depositsLength - 1}
					single={depositsLength === 1}
					{...deposit}
				/>
			))
		) : (
			!useTextButton && (
				<DepositBlankState
					buttonAction={this._handleShowModal}
					canEdit={canEdit}
					hasTrax={hasTrax}
					isAddButtonDisabled={isAddButtonDisabled}
				/>
			)
		);
	};

	render() {
		const {
			deposits,
			showModal,
		} = this.state;
		const {
			hasTrax,
			permissionLevel,
			employeeId,
			customSave,
			customRoutingValidationUrl,
			customAccountUnmaskUrl,
			useTextButton,
		} = this.props;
		const hasCustomSave = customSave ? customSave : null;
		const canEdit = permissionLevel === PERM_LEVEL.VIEW_EDIT;
		const depositsLength = deposits.length;
		const showPaperCheckMsg = depositsLength ? !togglePaperCheckMsg(deposits[depositsLength - 1]) : false;
		const directDepositClasses = c(
			'DirectDeposit'
		);
		const mainContentClasses = c(
			'DirectDeposit__accounts',
			{
				'DirectDeposit__accounts--blank': !useTextButton && !depositsLength,
				'DirectDeposit__accounts--borderBottom': showPaperCheckMsg && depositsLength
			}
		);

		const isAddButtonDisabled = this.getIsAddButtonDisabled();
		const isUpdateButtonDisabled = this.getIsUpdateButtonDisabled();

		return (
			<div className={directDepositClasses}>

				<div className={mainContentClasses}>
					{ifFeature(
						'encore',
						this._renderMainContent(deposits, hasTrax, canEdit),
						<>
							{depositsLength > 1 && <div className="DirectDeposit__vertLine"></div>}
							{this._renderMainContent(deposits, hasTrax, canEdit)}
						</>
					)}
				</div>

				{showPaperCheckMsg && (
					<>
						{ifFeature(
							'encore',
							<Flex gap={1.25} marginTop={2}>
								<IconV2
									color="neutral-medium"
									name="money-check-regular"
									size={20}
								/>
								<BodyText color="neutral-medium" size="small">
									{$.__(`You'll receive a paper check with any remaining balance`)}
								</BodyText>
							</Flex>,
							<div className="DirectDeposit__paperCheck">
								<span className="DirectDeposit__paperCheckIcon">
									<Icon name="fab-checkbook-20x16" />
								</span>
								<p className="DirectDeposit__paperCheckText">{$.__("You'll receive a paper check with any remaining balance")}</p>
							</div>
						)}
					</>
				)}

				{depositsLength === 0 && canEdit && useTextButton && (
					<>
						{isAddButtonDisabled ? (
							<Tooltip
								content={$.__("You don't have access to edit Direct Deposit.")}
								enterDelay={300}
							>
								<LayoutBox component="span" tabIndex={0}>
									<TextButton
										disabled={true}
										onClick={noop}
										type="button"
									>
										+ {$.__('Add Direct Deposit Account(s)')}
									</TextButton>
								</LayoutBox>
							</Tooltip>
						) : (
							<TextButton
								onClick={this._handleShowModal}
								type="button"
							>
								+ {$.__('Add Direct Deposit Account(s)')}
							</TextButton>
						)}
					</>
				)}
				{depositsLength > 0 && canEdit && !useTextButton && (
					<>
						{ifFeature(
							'encore',
							<LayoutBox marginTop={3}>
								<Button
									disabled={isUpdateButtonDisabled}
									onClick={this._handleShowModal}
									startIcon="pencil-regular"
									type="button"
								>
									{$.__('Update Direct Deposit')}
								</Button>
							</LayoutBox>,
							<div className="DirectDeposit__updateButton">
								<Button
									clickAction={this._handleShowModal}
									disabled={isUpdateButtonDisabled}
									type="button"
								>
									<span className="DirectDeposit__updateButtonIcon phoneHide">
										<Icon name="fab-pencil-12x12" />
									</span>
									<span className="DirectDeposit__updateButtonIcon hidden phoneOnly phoneOnly--block">
										<Icon name="fab-pencil-20x20" />
									</span>
									{$.__('Update Direct Deposit')}
								</Button>
							</div>
						)}
					</>
				)}
				{depositsLength > 0 && canEdit && useTextButton && (
					<TextButton
						clickAction={this._handleShowModal}
						disabled={isUpdateButtonDisabled}
						type="button"
					>
						{$.__('Update Direct Deposit Account(s)')}
					</TextButton>
				)}

				{!isUpdateButtonDisabled && (
					<DirectDepositModal
						accounts={deposits}
						customAccountUnmaskUrl={customAccountUnmaskUrl}
						customRoutingValidationUrl={customRoutingValidationUrl || null}
						customSave={hasCustomSave}
						employeeId={employeeId}
						hasEditPermission={this.getHasEditPermission()}
						hasTrax={hasTrax}
						onCancelClick={this._handleHideModal}
						onSaveSuccess={this._handleSaveSuccess}
						visible={showModal}
					/>
				)}
			</div>
		);
	}
}

function getIsDirectDepositInternalPermissionsEnabled() {
	return isEnabled('DIRECT_DEPOSIT_INTERNAL_PERMISSIONS');
}
