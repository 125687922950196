import {startsWith} from 'lodash';
import * as timeTrackingService from './service';
import {
	AUTH_FAIL_MSG,
	SAVE_ENTRY_SUCCES_MSG,
	SAVE_FAIL_MSG,
	APPROVE_FAIL_MSG,
	CLOCK_IN_FAIL_MSG,
	CLOCK_OUT_FAIL_MSG,
	DELETE_ENTRY_SUCCESS_MSG,
	DELETE_ENTRY_FAIL_MSG,
	CONFLICTING_ENTRIES_MSG,
	FUTURE_CLOCK_IN_TIME_MSG,
	SAVE_NOTE_FAIL_MSG
} from '../constants';
import staleDataClockModal from '../modals/stale-data-clock';
import launchStaleDataModal from '../modals/stale-data';
import launchClockOutModal from '../modals/approver-clock-out';


/*
 * This is where we make http calls and specify promise-chained side-effects (mainly
 * error handling), which behaviors are common to the home widget, timesheet tab,
 * inbox, report, etc.
 */


export function updateViewOption(option, newValue) {
	return timeTrackingService.updateViewOption({[option]: newValue});
}

export function saveSingleDailyHours(entries) {
	return timeTrackingService.saveSingleDailyHours({hours: entries})
		.then((response) => {
			if (response.data && startsWith(response.data, 'Error')) { // TODO is this still necessary?
				window.setMessage(SAVE_FAIL_MSG, 'error');
				throw response.data;
			}
		})
		.catch((error) => {
			if (error.response.status === 403) {
				// permission denied: already approved
				launchStaleDataModal('save');
			} else {
				window.setMessage(SAVE_FAIL_MSG, 'error');
			}

			throw error.response;
		});
}

export function refreshTimesheet(timesheetId) {
	return timeTrackingService.refreshTimesheet(timesheetId);
}

export function approveTimesheet(id, hoursLastChangedAt, afterClockOut) {
	return timeTrackingService.approveTimesheet({id, hoursLastChangedAt})
		.catch((error) => {
			const errorData = error.response.data;

			if (errorData.status === 'staleData') {
				launchStaleDataModal('approve');
			} else if (errorData.status === 'clockOut') {
				launchClockOutModal(errorData.timesheets, (response) => {
					approveTimesheet(id, response.hoursLastChangedAt, afterClockOut)
						.then(afterClockOut);
				});
			} else {
				window.setMessage(APPROVE_FAIL_MSG, 'error');
			}

			throw error;
		});
}

export function clockIn(employeeId, projectId, taskId) {
	const payload = projectId ? { projectId, taskId } : null;

	return timeTrackingService.clockIn(employeeId, payload)
		.catch((error) => {
			const code = error.response.data?.error?.code;
			if (code === 'CLOCKED_IN') {
				// EE was clocked in elsewhere by other means
				staleDataClockModal('clockIn', error.response.data);
			} else if (code === 'FUTURE_ENTRY') {
				// unacceptable to clock in because an entry exists in the future
				staleDataClockModal('clockInFutureEntry');
			} else if (code === 'APPROVED') {
				// already approved, meaning EE can no longer edit
				staleDataClockModal('alreadyApproved');
			} else {
				window.Rollbar.error('clock in', error);
				window.setMessage(CLOCK_IN_FAIL_MSG, 'error');
			}
			throw error;
		});
}

export function clockOut(employeeId) {
	return timeTrackingService.clockOut(employeeId)
		.catch((error) => {
			const errorResponse = error.response;
			// 403: already approved, meaning EE can no longer edit
			// 409: EE was clocked out elsewhere by other means
			if (errorResponse.status === 403 || errorResponse.status === 409) {
				staleDataClockModal('clockOut', errorResponse.data);
			} else {
				window.Rollbar.error('clock out', error);
				window.setMessage(CLOCK_OUT_FAIL_MSG, 'error');
			}
			throw error;
		});
}

export function deleteEntry(type, id) {
	return timeTrackingService.deleteEntry(type, {entries: [id]})
		.then(() => {
			window.setMessage(DELETE_ENTRY_SUCCESS_MSG, 'success');
		})
		.catch((error) => {
			window.setMessage(DELETE_ENTRY_FAIL_MSG, 'error');
			throw error.response;
		});
}

export function addEdit(type, formData) {
	const data = type === 'hour' ? { hours: formData } : { entries: formData };
	return timeTrackingService.addEdit(type, data)
		.then((response) => {
			if (response.status === 200) {
				window.setMessage(SAVE_ENTRY_SUCCES_MSG, 'success');
			} else {
				window.setMessage(AUTH_FAIL_MSG, 'error');
			}
		})
		.catch((error) => {
			if (error.response.status === 403) {
				launchStaleDataModal('save');
			} else {
				window.setMessage(SAVE_FAIL_MSG, 'error');
			}
		});
}

export function getClockStatus(employeeId) {
	return timeTrackingService.getClockStatus(employeeId)
		.then(response => response.data)
		.catch((error) => {
			throw error.response;
		});
}

export function saveClockInData(employeeId, data) {
	return timeTrackingService.saveClockInData(employeeId, data)
		.catch((error) => {
			if (error.response.status === 409) {
				window.setMessage(CONFLICTING_ENTRIES_MSG, 'error');
			} else if (error.response.data.error.code === 'FUTURE_CLOCK_IN_TIME') {
				window.setMessage(FUTURE_CLOCK_IN_TIME_MSG, 'error');
			} else {
				window.setMessage(SAVE_NOTE_FAIL_MSG, 'error');
			}

			throw error.response;
		});
}

export function requestTimeTrackingSubscription(hasRedirect = true) {
	return timeTrackingService.requestTimeTrackingSubscription()
		.finally(() => {
			if (hasRedirect) {
				window.location.href = '/settings/time_tracking';
			}
		});
}

export function cancelTimeTrackingTrial(data, hasRedirect = true) {
	return timeTrackingService.cancelTimeTrackingTrial(data)
		.finally(() => {
			if (hasRedirect) {
				window.location.href = '/settings/time_tracking';
			}
		});
}

export function postNewActivationTrialValues(data) {
	return timeTrackingService.postNewActivationTrialValues(data);
}

export function setTrialStarted() {
	return timeTrackingService.setTrialStarted();
}
