export const DEFAULT_DIGIT_LENGTH = 6;
export const DIGIT_CHUNK_MARGIN = 30;
export const DIGIT_MARGIN = 10;
export const DIGIT_WIDTH = 50;
export const DIGIT_OUTER_WIDTH = DIGIT_MARGIN + DIGIT_WIDTH;

export const INPUT_NAME = 'oneTimeCode';

export const MARKER_HEIGHT = 2;
export const MARKER_CHUNK_OFFSET = DIGIT_CHUNK_MARGIN - DIGIT_MARGIN;

export const MASK_ID = 'otc-mask';

