import {ColumnsRes, EmployeeResult, FieldIdType} from "./types";
import { TableColumn } from "@bamboohr/fabric";

export function buildColumns(res: ColumnsRes[]): TableColumn<EmployeeResult>[] {
    return res.map(e => ({
            header: e.name,
            fieldId: e.fieldId,
            key: e.fieldId,
            sortBy: (cell) => {
                if (e.fieldId === FieldIdType.HIRE_DATE && cell[e.fieldId].value) {
                    return (new Date(cell[e.fieldId].value)).toISOString();
                }
                return cell[e.fieldId].value?.toString()?.toLowerCase() ?? '';
            },
            cell: (cell) => {
                let ret = <span>{cell[e.fieldId].value}</span>;
                if(cell[e.fieldId].type === 'name') {
                    ret = <a href={`/employees/employee.php?id=${cell.id.value}`}>{cell[e.fieldId].value}</a>
                }
                return ret;
            }
        }
    ))
}
