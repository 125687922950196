import { Fragment } from 'react';

import {
	isPlainObject,
	isFunction,
	isString,
} from 'lodash';

import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import Loader from 'loader.react';

export function TabIcon(props) {
	const {
		active,
		activeIcon,
		icon,
		className,
	} = props;

	return icon ? (
		<span className={ className }>
			{ifFeature('encore', typeof icon.name === 'string' ? <IconV2 name={active ? activeIcon?.name || icon.name : icon.name} size={16} /> : icon, typeof icon.name === 'string' ? <Icon { ...icon } /> : icon)}
		</span>
	) : null;
}

export function TabPrimaryText(props) {
	const {
		primaryText,
		className,
	} = props;

	return primaryText ? (
		<span { ...{className} }>{ primaryText }</span>
	) : null;
}

export function TabSecondaryText(props) {
	const {
		secondaryText,
		className,
	} = props;

	return secondaryText ? (
		<span { ...{className} }>{ secondaryText }</span>
	) : null;
}

export function TabCaret(props) {
	const {
		caret,
		className,
	} = props;

	if (!caret) {
		return null;
	}

	if (isFunction(caret)) {
		const Caret = caret;
		return <Caret { ...{className} } { ...props } />;
	} else if (isString(caret)) {
		return <div { ...{className} } dangerouslySetInnerHTML={ {__html: caret} } />;
	} else if (caret === true) {
		return <div { ...{className} } />;
	}

	return caret;
}

export function NotifyCaret(props) {
	let {
		_createBEM,
		active,
		disabled,
		notifyText,
	} = props;

	if (
		!notifyText ||
		(!isNaN(notifyText) && parseFloat(notifyText) === 0)
	) {
		notifyText = null;
	}

	return notifyText && (
		<span className={ _createBEM('tabCaret', ['notify', {active, disabled}], props) }>{ notifyText }</span>
	);
}

export function Tab(props) {
	const {
		_createBEM,
		_subcomponents: {
			TabIcon,
			TabPrimaryText,
			TabSecondaryText,
			TabCaret,
		},
		hiddenParts,
		active,
		disabled,
	} = props;

	return (
		<Fragment>
			{ !hiddenParts.icon && <TabIcon className={ _createBEM('tabIcon', {active, disabled}, props) } { ...props } /> }
			{ !hiddenParts.primaryText && <TabPrimaryText className={ _createBEM('tabLabel', ['primary', {active, disabled}], props) } { ...props } /> }
			{ !hiddenParts.secondaryText && <TabSecondaryText className={ _createBEM('tabLabel', ['secondary', {active, disabled}], props) } { ...props } /> }
			{ !hiddenParts.caret && <TabCaret className={ _createBEM('tabCaret', {active, disabled}, props) } { ...props } /> }
		</Fragment>
	);
}

export function TabGroup(props) {
	const {
		_createBEM,
		group,
		tabs,
		className,
	} = props;

	if (group.TabGroup) {
		return group.TabGroup(props);
	}

	if (tabs.length < 1) {
		return null;
	}

	return (
		<Fragment>
			{ tabs.map(tab => tab.renderTab()) }
		</Fragment>
	);
}

export function TabGroupHeader(props) {
	const {
		_createBEM,
		group,
		_subcomponents: {
			TabGroupIcon,
			TabGroupLabel,
		},
	} = props;
	let { icon } = group;

	if (icon && !isPlainObject(icon)) {
		icon = {
			name: icon,
		};
	}

	return (
		<Fragment>
			{ icon && TabGroupIcon && (
				<TabGroupIcon
					className={ _createBEM('tabGroupIcon', group) }
					icon={ icon }
				/>
			) }
			{ group.label &&
				<TabGroupLabel { ...group } className={ _createBEM('tabGroupLabel', group) } />
			}
		</Fragment>
	);
}

export function TabGroupIcon(props) {
	return (
		<TabIcon { ...props } />
	);
}

export function TabGroupLabel(props) {
	const {
		label,
		className,
	} = props;

	return (
		<span { ...{ className } }>
			{ label }
		</span>
	);
}

export const TabGroupFooter = null;

export function TabWrapper({ children }) {
	return (
		<Fragment>
			{ children }
		</Fragment>
	);
}

export function TabWrapperHeader(props) {
	const {
		_createBEM,
		title,
		icon,
		_subcomponents: {
			TabWrapperTitle,
			TabWrapperIcon,
		},
	} = props;

	if (!title && !icon) {
		return null;
	}

	return (
		<div className={ _createBEM('tabWrapperTitleWrapper') }>
			{ icon && TabWrapperIcon && (
				<TabWrapperIcon
					{ ...props }
					className={ _createBEM('tabWrapperIcon') }
					icon={ isPlainObject(icon) ? icon : { name: icon } }
				/>
			) }
			{ title && TabWrapperTitle && (
				<TabWrapperTitle
					{ ...props }
					className={ _createBEM('tabWrapperTitle') }
				/>
			) }
		</div>
	);
}

export const TabWrapperHeaderActions = null;

export function TabWrapperTitle(props) {
	const {
		title,
		className
	} = props;

	return (
		<span className={ className }>
			{ title }
		</span>
	);
}

export function TabWrapperIcon(props) {
	return (
		<TabIcon { ...props } />
	);
}

export const TabWrapperFooter = null;
export const TabWrapperFooterActions = null;

export const TabContent = null;
export const TabContentHeader = null;
export const TabContentHeaderActions = null;
export const TabContentFooter = null;
export const TabContentFooterActions = null;

export function TabContentWrapper(props) {
	const {
		children,
	} = props;

	return children;
}

export function TabLoadingContent(props) {
	return (
		<Loader blockName="BaTabs" type="dot" />
	);
}
