import ajax from '@utils/ajax';
import {
	currentEditingEmployeeId,
	validateWithBE,
} from 'dynamic-form';
import { RATE_LIMIT_ERROR } from './common';

const message = $.__('This employee number is already being used.');

/**
 * Verifies Unique Employee Number
 * @param context
 * @param field
 * @param params
 * @returns promise
 */
export const uniqueEmployeeNumber = (context: DynamicForm.Context, field: DynamicForm.Data, params: any) => {
	return async (value): Promise<boolean | string> => {
		if (!value) {
			// don't make a BE request - field isn't filled in yet, let required or regex run
			return true;
		}
		const data = {
			eeid: currentEditingEmployeeId(),
			value,
		};

		const { isValid, isRateLimited } = await validateWithBE(ajax.get, data, '/ajax/check_unique_ee_number.php');
		if (isRateLimited) { return RATE_LIMIT_ERROR; }

		return isValid || message;
	};
};
