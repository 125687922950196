import { SlidedownPortal, TextButton } from '@bamboohr/fabric';
import { ReactElement, useState } from 'react';
import { StatusMismatchModal } from './status-mismatch-modal';
import { StatusMismatchEmployee } from './types';

export function StatusMismatch({ employee }: { employee: StatusMismatchEmployee }): ReactElement {
	const [showSlidedown, setShowSlidedown] = useState(true);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	return (
		<>
			<SlidedownPortal
				action={
					<TextButton
						dark={ true }
						onClick={
							() => {
								setShowSlidedown(false);
								setModalIsOpen(true);
							}
						}
						size="small"
					>{ $.__('View and Fix »') }</TextButton>
				}
				message={ $.__(`%1 is an inactive employee, but isn't terminated. This could cause problems.`, employee.preferredName) }
				onDismiss={ () => setShowSlidedown(false) }
				show={ showSlidedown }
			/>

			<StatusMismatchModal
				employeeId={ employee.id }
				onCancel={ () => setModalIsOpen(false) }
				onSave={ () => window.location.reload() }
				open={ modalIsOpen }
			/>
		</>
	);
}
