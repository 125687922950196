import { getSelectedItem } from "dynamic-form";

export const maxLengthSelect = (context: DynamicForm.Context, field: DynamicForm.DataProps, params) => {
	return (value: string): boolean | string => {
		if (value) {
			const item = getSelectedItem((field as DynamicForm.SelectElement));
			if (item && item.text) {
				const { maxLength = 255 } = params;
				const textLength = item.text.length;
				return textLength > maxLength ? params.message : true;
			}
		}
		return true;
	};
};
