import {
	getField,
	getSelectedValue,
	Fields,
} from 'dynamic-form';
import { ReactElement } from 'react';

export function SelectPaidPerField(selectProps: DynamicForm.SelectElementProps): ReactElement {
	const { props, settings, context } = selectProps;
	const {
		controls: { SelectField },
	} = context;

	const payTypeField = getField(context, Fields.payType) as DynamicForm.SelectElement;
	const payTypeValue = getSelectedValue(payTypeField);

	if (payTypeValue && (payTypeValue === 'Commission' || payTypeValue === 'Hourly')) {
		return (
			<SelectField
				context={ context }
				props={ {
					...props,
					className: `${props.className} hidden`,
					disabled: false,
					selectedValues: ['Hour'],
				} }
				settings={ settings }
			/>
		);
	}

	const selectValue = props?.selectedValues?.[0];
	const items = props.items.filter(item => item.value !== 'Hour');

	return (
		<>
			<SelectField
				context={ context }
				props={ {
					...props,
					disabled: !payTypeValue,
					items,
				} }
				settings={ settings }
			/>
			{/* If the paid per field was selected and disabled it didn't get included in the form.
				Need this hidden input to include the selected value. */}
			{ selectValue && !payTypeValue ? <input type="hidden" name={props?.name} value={selectValue} /> : null}
		</>
	);
}
