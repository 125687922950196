import {
	getMaxZIndex,
} from '@utils/dom';
import SimpleModal from 'SimpleModal.mod';

/**********************************************
* Modal Code for App - JQuery UI Dialog
**********************************************/
export const Modal = {

	init: function() {
		this._ScrollBarWidth = this.getScrollBarWidth();
		this.addUIElements();
	},

	// Ids of elements to store
	_ids: [
		'navMain',
		'content',
	],

	_elements: [],

	_ScrollBarWidth: 0,

	/*
	 * Add ui elements to store
	 *
	 * @returns {undefined}
	 */
	addUIElements: function() {

		this._elements = [];

		if (document.getElementsByTagName('header')[0]) {
			this._elements.push(document.getElementsByTagName('header')[0]);
		}

		// Loop through ids array. if they exists add to _elements
		for (let i = 0; i < this._ids.length; i++) {
			if (document.getElementById(this._ids[i])) {
				this._elements.push(document.getElementById(this._ids[i]));
			}
		}

		if (document.getElementsByTagName('footer')[0]) {
			this._elements.push(document.getElementsByTagName('footer')[0]);
		}
	},

	/*
	 * Diables the noscroll class and any additional noscroll functionality
	 *
	 * @returns {undefined}
	 */
	disableNoScroll: function() {
		var modal;

		$('html').removeClass('noscroll');

		if (this._ScrollBarWidth > 0) {

			if (document.getElementsByClassName('ui-dialog')[0]) {
				modal = document.getElementsByClassName('ui-dialog')[0];
				if (modal !== undefined) {
					modal.style.marginLeft = '-' + modal.offsetWidth / 2 + 'px';
				}
			} else {
				var $modals = $('.SimpleModal');
				$modals.each(function() {
					var $this = $(this);
					if ($this.hasClass('full-screen')) {
						return;
					}
					$this.css('left', 'calc(50% - ' + $this[0].offsetWidth / 2 + 'px)');
				});
			}

			for (var i = 0; i < this._elements.length; i++) {
				this._elements[i].style.paddingRight = null;
			}
		}
	},

	/*
	 * Enables the noscroll class and any additional noscroll functionality
	 *
	 * @returns {undefined}
	 */
	enableNoScroll: function() {
		var modal;
		var modalWidth;
		var that = this;

		if (this._ScrollBarWidth > 0) {

			if (document.getElementsByClassName('ui-dialog')[0]) {
				modal = document.getElementsByClassName('ui-dialog')[0];
				modalWidth = modal.offsetWidth / 2 + this._ScrollBarWidth / 2;
				modal.style.marginLeft = '-' + modalWidth + 'px';
			} else {
				var $modals = $('.SimpleModal');
				$modals.each(function() {
					var $this = $(this);
					if ($this.hasClass('full-screen')) {
						return;
					}
					modalWidth = $this[0].offsetWidth / 2 + that._ScrollBarWidth / 2;
					$this.css('left', 'calc(50% - ' + modalWidth + 'px)');
				});
			}

			for (var i = 0; i < this._elements.length; i++) {
				this._elements[i].style.paddingRight = this._ScrollBarWidth + 'px';
			}
		}

		$('html').addClass('noscroll');
	},

	/*
	 * Gets the width of the scrollbar on the page
	 *
	 * @returns {Number} This is the width of the scrollBar (can be 0)
	 */
	getScrollBarWidth: function() {
		var inner = document.createElement('p');
		inner.style.width = "100%";
		inner.style.height = "200px";

		var outer = document.createElement('div');
		outer.style.position = "absolute";
		outer.style.top = "0px";
		outer.style.left = "0px";
		outer.style.visibility = "hidden";
		outer.style.width = "200px";
		outer.style.height = "150px";
		outer.style.overflow = "hidden";
		outer.appendChild(inner);

		document.body.appendChild(outer);
		var w1 = inner.offsetWidth;
		outer.style.overflow = 'scroll';
		var w2 = inner.offsetWidth;

		if (w1 == w2) {
			w2 = outer.clientWidth;
		}

		document.body.removeChild(outer);

		return (w1 - w2);
	}

};

/** jQuery UI modal dialog alert. Use this instead of confirm() **/
export function modalDialogAlert(dialog_title, dialog_message, accept_message, accept_action, cancel_action, options) {
	var settings = {
		modal: true
	};
	if (typeof options != "undefined") {
		$.extend(settings, options);
	}
	var html = '';
	if (dialog_message.jquery) {
		html = dialog_message.html();
	} else {
		html = '<span class="ui-icon ui-icon-alert"></span><p class="alert">' + dialog_message + '</p>';
	}
	showModalDialog(dialog_title, html, accept_message, accept_action, cancel_action, settings);
	return false;
}

/** Use this to show a modal from content that already exists on the page **/
export function showInlineModal(dialog_title, inline_modal_id, accept_message, accept_action, cancel_action, options) {
	var settings = {
		'inline_modal_id': inline_modal_id
	};
	if (typeof options != "undefined") {
		$.extend(settings, options);
	}
	showModalDialog(dialog_title, undefined, accept_message, accept_action, cancel_action, settings);
}

var modalIdSelector;
var modalDialogAccept = false;
var modalDialogCancel = false;
var modalcount = 0;
var bottomModalIdSelector;

// Other places in the app need to define "modalIdSelector"
// so we need to use a getter and setter to retrieve and
// update this local variable
Object.defineProperties(window, {
	modalIdSelector: {
		get() {
			return modalIdSelector;
		},
		set(val) {
			modalIdSelector = val;
		}
	}
});

/**
 * jQuery UI modal dialog.
 *
 * Title / message parameters are mostly self-explanatory.
 * @param accept_action - Can be a function or a URL to be triggered after user accepts.
 * @param cancel_action - Optional. If defined it needs to be either a function or boolean false. Triggered if user cancels.
 * @param options - Any additional jQuery UI modal dialog options
 * options parameter can have the following custom (non jQuery UI) options:
 *  'cancel_message' - Text to use instead of the standard cancel button
 *  'open_callback' - Callback function to trigger after the modal opens
 *  'close_callback' - Callback function to trigger after the modal closes
 *  'inline_modal_id' - An alternate id to use for the modal if the element already exists on the page
 *  'privacy_link' - set to true if you want to show the privacy link in the modal footer
 *
 * example for cancel message showModalDialog(title, html, 'Save', function() { modalSubmit(reloadPage)}, undefined, {cancel_message: 'Cancel'});
 */
export function showModalDialog(dialog_title, dialog_html, accept_message, accept_action, cancel_action, options) {
	modalIdSelector = '#modal-dialog';
	modalDialogAccept = accept_action;
	var modalDialogCancel = cancel_action;
	if (typeof cancel_action == "undefined") {
		modalDialogCancel = false;
	}

	if (typeof options != "undefined" && typeof options.inline_modal_id != "undefined") {
		modalIdSelector = '#' + options.inline_modal_id;
	}
	if (typeof options != "undefined" && typeof options.stacked_modal_id != "undefined") {
		modalIdSelector = '#' + options.stacked_modal_id;
	}

	// Note: Button options need to be created as an object to allow for variable button titles
	var buttonOpts = {};
	var i = modalcount++;
	var dialogSelector = modalIdSelector;
	if (!options || !options.omit_cancel_button) {
		buttonOpts[$.__('Cancel')] = function() {
			var tmp = modalIdSelector;
			modalIdSelector = dialogSelector;
			closeMessage();
			if (typeof modalDialogCancel == "function") {
				if (modalDialogCancel()) {
					closeModalDialog();
				}
			} else {
				closeModalDialog();
			}
			modalIdSelector = tmp;
			return false;
		};
	}
	buttonOpts[accept_message] = function() {
		//run bhrValidate on initiated forms
		if ($('form[bhrvalidate-initiated]', modalIdSelector).length > 0) {
			if (!$('form[bhrvalidate-initiated]', modalIdSelector).valid()) {
				return false;
			}
		}

		var tmp = modalIdSelector;
		modalIdSelector = dialogSelector;
		if (typeof modalDialogAccept == "function") {
			if (modalDialogAccept()) {
				closeModalDialog();
			}
		} else {
			document.location.href = modalDialogAccept;
			closeModalDialog();
		}
		modalIdSelector = tmp;
	};

	var settings = {};
	$.extend(settings, options);
	settings.open = function(event, ui) {
		$(this).parents('.ui-dialog-buttonpane button:eq(0)').blur();
		$(this).parents('.ui-dialog-buttonpane button:eq(1)').focus();
		$(modalIdSelector + ' form').submit(function() {
			return false;
		});

		// Note: This is a bit hacky as it relies on the buttons existing and being in a certain order
		const $buttons = $('.ui-dialog-buttonpane button', $(modalIdSelector).parent());

		$buttons.addClass('btn btnAction');
		
		if (options && options.biId) {
			$buttons.attr('data-bi-id', `${ options.biId }-action-primary`);
		}
		if (typeof options != "undefined" && typeof options.stacked_modal_id != "undefined") {
			$('.ui-dialog-buttonpane button', $(modalIdSelector).parent()).addClass('btnSmall');
		}
		if (typeof settings.cancel_message != "undefined" && !settings.omit_cancel_button) {
			$('.ui-dialog-buttonset button:first', $(modalIdSelector).parent()).remove();
			$('.ui-dialog-buttonset', $(modalIdSelector).parent()).append('<span class="cancel-option"><a href="#" class="' + settings.cancel_class + '">' + settings.cancel_message + '</a></span>');
			$('.ui-dialog-buttonset .cancel-option a', $(modalIdSelector).parent()).click(buttonOpts[$.__("Cancel")]);
		}

		if (typeof settings.additional_footer_html != "undefined") {
			$('.ui-dialog-buttonset', $(modalIdSelector).parent()).prepend(settings.additional_footer_html);
		}

		if (typeof settings.open_callback == "function") {
			settings.open_callback(event, ui);
		}

		// If we're loading html dynamically we need to attach the events
		if (typeof settings.inline_modal_id == "undefined") {
			attachModalEvents(modalIdSelector);
		}
		// moving this to below the attachmodalevents so that it will focus properly on date fields (masked)
		var first = $(modalIdSelector).find(':text:visible:enabled:first');
		if (first.length == 0) {
			first = $(modalIdSelector).find('textarea');
		}
		//added to not focus on 'first' field if first is select
		if (!first.hasClass("chzn_selected")) {
			first.focus();
		}

		if (settings.disable_buttons == true) {
			$('.ui-dialog-buttonset button', $(modalIdSelector).parent()).not('.cancel').button('disable');
			$('body').on('keyup click', '.ui-dialog input,.ui-dialog textarea', function() {
				if ($(this).val() !== '') {
					$(".ui-button").button('enable');
				}
			});
		}
	};

	settings.close = function(event, ui) {

		if (typeof settings.close_callback == "function") {
			settings.close_callback();
		}
		$('div.ui-dialog', $(modalIdSelector)).addClass('displaynone');
		if (typeof settings.inline_modal_id == "undefined") {
			$(modalIdSelector).remove();
		}
		Modal.disableNoScroll();
	};
	if (settings.buttons !== undefined && settings.buttons === "hide") {
		settings.buttons = null;
	} else {
		settings.buttons = buttonOpts;
	}

	if (typeof settings.cancel_message == "undefined") {
		settings.cancel_message = $.__("Cancel");
	}

	doModalDialog(dialog_title, dialog_html, settings);

	// Submit when enter is pressed (when focused and not inside a textarea) or auto complete box
	if (settings.disableCloseOnEnter != true) {
		// remove any previous events
		$(modalIdSelector + ':not(.keyup-bound)').off('keyup');
		$(modalIdSelector + ':not(.keyup-bound)').keyup(function(e) {
			$(modalIdSelector).addClass('keyup-bound');
			if (e.keyCode == 13) {
				var focused = $.focused();
				if (focused !== null && $(focused).parents(modalIdSelector).length > 0 && !$(focused).is('textarea') && !$(focused).hasClass('ac_input') && !$(focused).hasClass('chzn_selected')) {
					buttonOpts[accept_message]();
				}
			}
		});
	}

	/** Force modal destruction on close (instead of reposition in case where modal is partly offscreen) **/
	$('.ui-dialog-titlebar-close').on('mousedown', function titleBarClose() {
		if (typeof settings.close_callback == "function") {
			settings.close_callback();
		}
		$(modalIdSelector).dialog('destroy');
		Modal.disableNoScroll();
		// This event is fired when the modal dialog has been fully closed.
		// This event is specific to the "x" icon being used to close the modal
		$(document).trigger('modalDialog:closed');
	});

	if (!settings.omit_validation && $.isFunction($.fn.bhrValidate)) {
		$('.BhrForms:not(".js-noValidate")', modalIdSelector).bhrValidate({});
	}

	return false;
}
export function setBackgroundModalIdSelector(id) {
	bottomModalIdSelector = id;
}
export function showStackedModalDialog(dialog_title, dialog_html, accept_message, accept_action, cancel_action, options) {
	var settings = {
		'stacked_modal_id': "stacked-modal-dialog"
	};
	if (typeof options != "undefined") {
		$.extend(settings, options);
	}

	// setting this to modal-dialog by default, but if your base modal has a different id
	// call setBackgroundModalIdSelector
	if (!bottomModalIdSelector) {
		bottomModalIdSelector = "#modal-dialog";
	}
	var cancelAction = cancel_action || function(event, ui) {
		$(modalIdSelector).dialog('close');
	};

	settings.dialogClass += " stacked-dialog";
	return showModalDialog(dialog_title, dialog_html, accept_message, accept_action, cancelAction, settings);
}
// Do a simple modal to show messages with an "ok" button
export function showModalPopup(dialog_title, dialog_html, options) {
	modalIdSelector = '#modal-dialog';
	var settings = {
		modal: true
	};

	var okString = $.__("Ok");
	if (
		typeof options != "undefined" &&
		typeof options.okString != "undefined" &&
		options.okString.length > 0
	) {
		okString = options.okString;
	}
	settings.buttons = [{
		text: okString,
		click: function() {
			closeModalDialog();
		},
		"class": "btn btnAction"
	}];
	if (typeof options != "undefined") {
		$.extend(settings, options);
	}
	doModalDialog(dialog_title, dialog_html, settings);

	return false;
}

// A helper function. Use showModalDialog or showModalPopup for more standard dialogs
// You could use this function for a more specialized one-time modal
export function doModalDialog(dialog_title, dialog_html, options) {
	// Close any existing modals
	closeModalDialog();

	$(window).scrollLeft(0);
	var theWidth = 'auto';
	var settings = {
		resizable: false,
		draggable: false,
		modal: true,
		width: theWidth,
		height: 'auto'
	};
	settings.close = function(event, ui) {
		$('div.ui-dialog').addClass('displaynone');
		if (typeof settings.inline_modal_id == "undefined") {
			$(modalIdSelector).remove();
		}
	};

	settings.overlay = {
		backgroundColor: '#000'
	};

	settings.position = {
		using: function(a, b) {
			var newTop = ((b.target.height - b.element.height) / 3) + b.target.top;
			var marginLeft = '-' + Math.floor(b.element.width / 2) + 'px';
			$(this).css({
				top: (newTop > 0 ? newTop : 0),
				left: '50%',
				'margin-left': marginLeft
			});
		}
	};

	$.extend(settings, options);

	// Dynamic modals use the #modal-dialog div so we create / remove it
	// Inline modals use the targetted div so we show / hide it
	// Stacked modals use #stacked-modal-dialog div so we create/remove it
	if (typeof settings.stacked_modal_id != "undefined") {
		var selector = settings.stacked_modal_id;
		if (!$('#' + selector).length) {
			$("body").append('<div id="' + selector + '" title=""></div>');
		}
		// figure the height of the slide out modal
		var ht = $(bottomModalIdSelector).position().top + $(bottomModalIdSelector).parent().position().top + 5;
		var marginLeft;
		settings.position = {
			using: function(a,b) {
				marginLeft = '-' + Math.floor(b.element.width / 2);
				$(this).css({
					top: ht,
					left: a.left,
					minWidth: $(this).css('width'),
					borderTop: 'none'
				});
				$(this).parent().css({
					top: $(bottomModalIdSelector).parent().position().top
				});
			}
		};

		settings.show = {
			effect: "slideDown",
			complete: function() {
				$('#stacked-modal-dialog').parent().css({
					'left': '50%',
					'margin-left': marginLeft + 'px'
				});
			},
			duration: 350
		};
		settings.hide = {
			effect: "slideUp",
			duration: 350
		};
	} else if (typeof settings.inline_modal_id == "undefined") {
		if (!$('#modal-dialog').length) {
			$("body").append('<div id="modal-dialog" title=""></div>');
		}
	} else {
		$('div.ui-dialog').removeClass('displaynone');
	}

	if (typeof settings.inline_modal_id == "undefined") {
		$(modalIdSelector).html(dialog_html);
	}
	settings.title = dialog_title;

	// workaround for jQuery bug where maxWidth is ignored if width is set to auto
	if (!settings.create && settings.maxWidth) {
		settings.create = function() {
			$(this).css('maxWidth', settings.maxWidth);
		}
	}
	$(modalIdSelector).dialog(settings);

	$('.ui-widget-overlay').zIndex(getMaxZIndex() + 10);
	$(modalIdSelector)
		.closest('.ui-dialog')
		.zIndex(getMaxZIndex() + 10);

	if (options.privacy_link == true) {
		$(".ui-dialog-buttonpane").append('<a href="http://www.bamboohr.com/privacy.php" class="privacyLink" target="_blank" rel="nofollow noopener noreferrer">' + $.__('Privacy Policy') + '</a>')
	}
	if (typeof options.button_pane_link != 'undefined') {
		$(".ui-dialog-buttonpane").append('<a href="' + options.button_pane_link.url + '" class="privacyLink" target="_blank" rel="nofollow noopener noreferrer">' + options.button_pane_link.text + '</a>')
	}

	Modal.enableNoScroll();

	// This event is fired when the modal dialog has been opened.
	// You can watch for this in order to fire off other events after the modal window has been opened
	$(document).trigger('modalDialog:opened');
}

export function recenterDialog() {
	var width = $('.ui-dialog').width();
	$('.ui-dialog').css("margin-left", -Math.floor(width / 2));
}

export function closeModalDialog(selector) {
	var modalSelector;

	$('html').removeClass('noscroll');

	if (selector === undefined) {
		modalSelector = modalIdSelector;
	} else {
		modalSelector = selector;
	}

	if ($(modalSelector + '.ui-dialog-content').length == 0) {
		return;
	}

	$(modalSelector + '.ui-dialog-content').dialog('destroy');
	$(modalSelector + ' form').removeAttr('submitting');
	$(modalSelector).hide();
	Modal.disableNoScroll();

	// This event is fired when the modal dialog has been fully closed.
	// This event is specific to the cancel button being used to close the modal
	$(document).trigger('modalDialog:closed');
}

// It would be ideal if these events were activated in a manner similar to jQuery .live
// For now we need re-initialize whenever we show a modal in case it has these controls
export function attachModalEvents(selector) {
	$(selector).initializeElements();

	//On modal open or any other initialization, add the limitCount to the textarea
	$("textarea[maxlength]").each(function() {
		$(this).limitCount($(this).attr("maxlength"));
	});
}

/** Do a modal dialog that pulls the html from a url **/
export function ajaxModalDialog(dialog_title, url, accept_message, accept_action, cancel_action, options) {
	if (typeof options == "undefined") {
		options = {};
	}
	$.get(url, options.url_data, function(html) {
		showModalDialog(dialog_title, html, accept_message, accept_action, cancel_action, options);
	});

	return false;
}

/**
 * Used with modal dialogs.
 * NOTE - Response must be json formatted or saveHandler may not be triggered
 * NOTE - Exclusions are added for checkboxes and radio buttons to make sure they are set correctly
 *
 * Return true to close the dialog, return false to keep it open.
 * @param saveHandler - The funciton to call after the form is submitted.
 * @param presubmit (optional) - A function used to validate before allowing submittal.
 */
export function modalSubmit(saveHandler, presubmit, options) {
	var $form = $(modalIdSelector + ' form');

	if (typeof presubmit != 'undefined' && !presubmit($form[0])) {
		return false;
	}

	window.BambooHR.Modal.setState({ isProcessing: true }, true);

	var submitPromise = BambooHR.Utils.Form.ajaxSubmit($form, options);
	if (submitPromise) {
		submitPromise
			.then(function(...args) {
				const resultData = args[0];
				saveHandler.apply(this, args);
				if (resultData.result.toLowerCase() === 'success') {
					window.BambooHR.Modal.setState({ isOpen: false });
				} else {
					window.BambooHR.Modal.setState({ isProcessing: false }, true);
				}
			})
			.fail(function () {
				window.BambooHR.Modal.setState({ isProcessing: false }, true);
			});
	}

	return true;
}

/**
 * Note: historically some forms had an input named "action".  A field named "action"
 * is incompatible with this function because it inspects the form's "action" property
 * to determine what URL to submit to.
 **/
function registerModalForm(saveHandler, formId, presubmit) {
	var selector = "#modal-popup .save-button";
	if (typeof formId != "undefined") {
		selector = "form#" + formId + " .save-button";
	}

	$(selector).click(function() {
		return modalFormSubmit($(this).closest('form')[0], saveHandler, presubmit);
	});
	$("form#" + formId).submit(function () {
		return modalFormSubmit($(this)[0], saveHandler, presubmit);
	});
}

export function modalFormSubmit(form, saveHandler, presubmit) {
	var action = form.action;
	//var params = { };
	//for(let i=0;i<form.elements.length;++i) {
	//	params[ form.elements[i].name ]=$(form.elements[i]).val();
	//}
	var params = $(form).serializeArray();
	if (typeof presubmit != "undefined") {
		if (presubmit(form)) {
			$.post(action, params, saveHandler,"json");
		}
	} else {
		$.post(action, params, saveHandler, "json");
	}
	return false;
}

/**
 * This is part of a patch to address a jQueryUI bug.  The bug is responsible
 * for the inability to scroll a page when a modal dialog is active. If the content
 * of the dialog extends beyond the bottom of the viewport, the user is only able
 * to scroll with a mousewheel or up/down keyboard keys.
 *
 * @see http://bugs.jqueryui.com/ticket/4671
 * @see https://bugs.webkit.org/show_bug.cgi?id=19033
 * @see /views_ui.module
 * @see /js/jquery.ui.dialog.min.js
 *
 * This javascript patch overwrites the $.ui.dialog.overlay.events object to remove
 * the mousedown, mouseup and click events from the list of events that are bound
 * in $.ui.dialog.overlay.create
 *
 * The original code for this object:
 * $.ui.dialog.overlay.events: $.map('focus,mousedown,mouseup,keydown,keypress,click'.split(','),
 *  function(event) { return event + '.dialog-overlay'; }).join(' '),
 *
 */

/* JQuery 1.10.4+ resolves this bug :) */

(function() {
	if ($.ui && $.ui.dialog) {
		$.ui.dialog.overlay.events = $.map('focus,keydown,keypress'.split(','), function(event) {
			return event + '.dialog-overlay';
		}).join(' ');
	}
})();
