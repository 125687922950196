import Popover from 'Popover.mod';


/**
 * Creates helper popover for the timesheet or home widget.
 *
 * @param {selector} anchorNodeOrSelector
 * @returns {Popover|null}
 */
export default function createEntryHintPopover(anchorNodeOrSelector = document.head) {
	return Popover.create(anchorNodeOrSelector, {
		triggerEvent: 'none',
		position: 'right',
		push: 15,
		toggleOnClick: false,
		template: {
			name: 'popover-standard',
			data: {
				title: $.__(`Entering time shouldn't be hard.`),
				content: $.__(
					'Enter your time like %1$s or %2$s—whatever feels most natural.',
					`<b class="fontSemibold">1${ window.GLOBAL_DECIMAL_CHAR || '.' }5</b>`,
					'<b class="fontSemibold">1:30</b>',
				),
				rawContent: true,
			},
		},
	});
}
