import { createStyles, makeStyles } from '@bamboohr/fabric';

import { BreakPoints } from 'NHPTemplates.mod/style-constants';

export const useStyles = makeStyles(({ palette, typography }) => {
	const {
		COMPUTER,
		TABLET,
	} = BreakPoints;

	return createStyles({
		navWrapper: {
			[TABLET]: {
				height: '0',
			}
		},
		nav: {
			padding: '15px 20px',
			width: '100%',
			maxWidth: '1348px',
			display: 'flex',
			justifyContent: 'space-around',
			[TABLET]: {
				display: 'flex',
				justifyContent: 'end',
				maxWidth: '100%',
				padding: '0',
				paddingRight: '30px',
				width: '10%',
				minWidth: '300px',
			}
		},
		navContainer: {
			background: palette.gray[900],
			display: 'flex',
			justifyContent: 'center',
			paddingLeft: '3%',
			[TABLET]: {
				background: 'transparent',
				justifyContent: 'end',
				position: 'relative',
				height: 'auto',
				minWidth: 0,
				top: '-20px',
				zIndex: '2',
				'&:before': {
					top: '20px'
				}
			}
		},
		navItem: {
			position: 'relative',
			display: 'inline',
			fontSize: typography.fabricFontSize('biggie'),
			fontWeight: typography.fontWeightRegular,
			color: palette.gray[600],
			marginRight: '85px',
			[TABLET]: {
				float: 'none',
				marginLeft: '40px',
				marginRight: '0',
				padding: '0',
				height: '40px',
				width: 'auto',
			},
		},
		navItemActive: {
			color: palette.gray[100],
			fontWeight: typography.fontWeightHeavy,
			[COMPUTER]: {
				'&::before': {
					position: 'absolute',
					content: '""',
					width: 0,
					height: 0,
					top: '44px',
					left: '50%',
					borderLeft: '20px solid transparent',
					borderRight: '20px solid transparent',
					borderTop: `20px solid ${ palette.gray[900] }`,
				},
			},
		},
		navNumber: {
			display: 'inline-block',
			borderRadius: '50%',
			width: '25px',
			height: '25px',
			padding: '2px',
			background: palette.gray[600],
			color: palette.gray[900],
			textAlign: 'center',
			fontSize: typography.fabricFontSize('large'),
			fontWeight: typography.fontWeightRegular,
			[TABLET]: {
				color: palette.gray[900],
				backgroundColor: palette.gray[300],
				border: `3px solid white`,
				display: 'flex',
				width: '27px',
				height: '27px',
				alignItems: 'center',
				justifyContent: 'center',
			}
		},
		navName: {
			paddingLeft: '10px',
			display: 'inline-block',
			[TABLET]: {
				display: 'none',
			}
		},
		navNumberActive: {
			background: palette.gray[100],
			fontWeight: typography.fontWeightHeavy,
			lineHeight: typography.fabricLineHeight('large'),
			[TABLET]: {
				backgroundColor: palette.primary.main,
				color: 'white',
			}
		},
		navSpacer: {
			flex: 2,
		}
	});
});
