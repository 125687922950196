import { Dropdown } from '@bamboohr/fabric';
import { type Tab } from './tab';
import { useTabDropdown } from './use-tab-dropdown';

export interface TabDropdownProps {
	createBEM: (block: string, modifiers?: string | string[]) => string;
	overflowText: string;
	tabs: Tab[];
}

export const TabDropdown = (props: TabDropdownProps) => {
	const { createBEM, overflowText, tabs } = props;

	const { overflowIndex, setDropdownRef } = useTabDropdown();

	const items = [];
	tabs.slice(overflowIndex).forEach((tab) => {
		const newTab = { tab, key: tab.key };
		if (typeof tab.group === 'string') {
			const group = items.find((tab) => tab.key === tab.group);
			if (group) {
				group.items.push(newTab);
			} else {
				items.push({
					key: tab.group,
					type: 'group',
					items: [newTab],
				});
			}
		} else if (tab.key === 'employeeTab-customizeTabs') {
			items.push({
				key: 'customize-tabs',
				type: 'group',
				items: [newTab],
				anchor: 'bottom',
			});
		} else {
			items.push(newTab);
		}
	});

	return (
		<div className={createBEM('tab', 'overflow')} ref={setDropdownRef}>
			<Dropdown
				items={items}
				renderOptionContent={({ key }) => {
					const findItem = (tab) => (tab.items ? tab.items.find(findItem) : tab.key === key);
					const item = items.find(findItem);
					const { tab } = item.tab ? item : item.items.find(findItem);
					return tab.renderTab({ overflow: true });
				}}
				type="text"
			>
				<span className={createBEM('tabLabel', ['primary'])}>
					{overflowText}
				</span>
			</Dropdown>
		</div>
	);
};
