export const initialErrorState = {
	name: false,
	acaStatus: false,
	fte: false,
};

export const initialFormState = {
	name: '',
	acaStatus: '',
	fte: '',
};
