import ItemCreator from '../components/item-creator';
import { connect } from 'react-redux';
import { getConfig } from '../../../constants';
import { getId } from '../../../store/selectors';

const mapStateToProps = state => ({
	config: getConfig(getId(state))
});

export default connect(
	mapStateToProps,
)(ItemCreator);

