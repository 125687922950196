
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Fragment, useEffect } from 'react';
import classnames from 'classnames';
import { 
	validateEmail,
	makeUid,
} from 'BambooHR.util';
import {
	bem,
	inputSelector,
	listId,
	labelId,
	ERROR_DATA,
} from '../../../../constants';
import './styles.styl';

const errorClass = s => `fab-${ s[0] }--error`;

let autoInputList = null;

export const isValid = () => document.body.querySelector(`#${ listId } .item`) !== null;
export const hasDuplicates = () => document.querySelector(`.${ ERROR_DATA.duplicateEmail.className }`) !== null;
export const hasInvalidEmail = () => document.querySelector(`.${ ERROR_DATA.invalidEmail.className }`) !== null;

export const updateFormClasses = () => {
	const labelClassList = document.body.querySelector(`#${ labelId }`).classList;
	const inputClassList = document.body.querySelector(inputSelector).classList;
	const placeholderClassList = document.body.querySelector(`#${ listId } .description`).classList;

	if (isValid() && !hasDuplicates() && !hasInvalidEmail()) {
		labelClassList.remove(errorClass`Label`);
		inputClassList.remove(errorClass`TextInput`);
		placeholderClassList.remove(errorClass`TextInput`);
	} else {
		labelClassList.add(errorClass`Label`);
		inputClassList.add(errorClass`TextInput`);
		placeholderClassList.add(errorClass`TextInput`);
		autoInputList.activate();
	}
}

export const EmailInputList = () => {

	useEffect(() => {
		// prevents lastpass and chrome auto-fill which causes the input to be wonky
		const inputUnique = `secureSendTo-${ makeUid() }`;
		autoInputList = $(`#${ listId }`).autoInputList({
			autocompleteUrl: '/ajax/files/quicksearch-email.php',
			description: $.__('Enter email addresses...'),
			formatAutoItem: window.activeIndicator,
			formatTagItem: function (event, data, formatted) {
				if (data) {
					const text = xmlEntities(data[0]);
					const value = xmlEntities(data[1]);

					const isInvalidEmail = isNaN(parseInt(value, 10)) && !validateEmail(value);
					const isDuplicate = document.querySelector(`#${ listId } .item[data-entry-value="${ value }"]`) !== null;

					let errorDescription = '';
					if (isInvalidEmail) {
						errorDescription = ERROR_DATA.invalidEmail.description;
					} else if (isDuplicate) {
						errorDescription = ERROR_DATA.duplicateEmail.description;
					}

					const classes = classnames(
						bem.elem('item'),
						'item',
						{
							[`${ ERROR_DATA.invalidEmail.className }`]: isInvalidEmail,
							[`${ ERROR_DATA.duplicateEmail.className }`]: isDuplicate,
						});

					$(`
						<span class="${ classes }" data-entry-value="${ value }">
							${ text } <span class="${ bem.elem('itemErrorDescription') }">${ errorDescription }</span>
							<input type="hidden" name="recipients[]" value='${ value }' />
							<button class="${ bem.elem('remove') } remove fab-FloatingIconButton fab-FloatingIconButton--teenie fab-FloatingIconButton--secondary" type="button">
								<span class="${ bem.elem('removeIcon') } fab-FloatingIconButton__icon">
									<ba-icon name="fab-x-10x10" encore-name="circle-xmark-solid" encore-size="16"></ba-icon>
								</span>
							</button>
						</span>
					`)
						.insertBefore(`#${ listId } span.input`);
					$(this).val('');
					updateFormClasses();
				}
			},
			// prevents chrome and lastpass auto-complete! Name and ID can be dynamic for secure file share
			inputAttributes: {
				name: inputUnique,
				id: inputUnique,
				'data-lpignore': 'true',
			},
			inputClass: inputSelector,
			done: function() {
				$(`#${ labelId }`).addClass('fab-Label fab-Label--required');

				$(inputSelector).addClass(`${ bem.elem('acInput') } ac_input free fab-TextInput fab-TextInput--width100`);
				$(`#${ listId }`).find('.description').addClass(`${ bem.elem('description') }${ifFeature('encore', '', ' fab-TextInput fab-TextInput--width100')}`);
				$(`#${ listId }`).on('click', '.item .remove', function () {
					const $item = $(this).closest('span.item');
					const value = $item.data('entry-value');
					const hasDuplicateErrorClass = $item.hasClass(bem.elem('item--duplicateError'));

					$item.remove();

					const duplicates = $(`#${ listId }`).find(`.item[data-entry-value="${ value }"]`);
					if (!hasDuplicateErrorClass && duplicates.length > 0) {
						const $firstDuplicate = $(duplicates).first();
						$firstDuplicate.removeClass(bem.elem('item--duplicateError'));

						const $errorDescription = $firstDuplicate.find(`.${ bem.elem('itemErrorDescription') }`);
						if ($errorDescription.text() === ERROR_DATA.duplicateEmail.description) {
							$errorDescription.empty();
						}
					}

					updateFormClasses();
					return false;
				});
			}
		});

		const emailPlaceholderVal = $.__("Enter email addresses...");
		$(".free").click().focus().attr("placeholder", emailPlaceholderVal);
	}, []);

	return (
		<Fragment>
			<label id={ labelId } htmlFor={ listId }>Send To</label>
			<div id={ listId } className="emailListInput input-list fab-TextInput fab-TextInput--width100 js-autoInputListContainer" />
		</Fragment>
	);

};
