import moment from 'moment.lib';

import { BaseFormData } from './baseFormData';
import { TemplateSaveData } from './Interfaces';

export interface NewHirePacketSaveData extends TemplateSaveData{
	hireDate: string;
	managerId: string;
}

export class NHPTemplateFormData extends BaseFormData {
	getFormData(): TemplateSaveData {
		const gtkyQuestions = this.assignQuestionSortOrderByIndex();

		return {
			arriveByTime: this.selectedArrivalTime[0],
			contactEmployeeId: this.selectedContactOptions[0],
			id: this.id,
			location: this.location,
			name: this.name,
			otherInstructions: this.otherInstructions,
			gtkyQuestions: gtkyQuestions,
			gtkyRecipients: this.gtkyRecipients,
			// @ts-ignore
			sendGetToKnowYouEmail: (this.sendGetToKnowYouEmail === true || this.sendGetToKnowYouEmail === 'yes'),
		};
	}

	// TODO: Remove this during cleanup epic
	getPacketFormData(): NewHirePacketSaveData {
		const formData = this.getFormData();
		const hireDate = (this.hireDate) ? moment(this.hireDate).format('YYYY-MM-DD') as string : '';

		return {
			...formData,
			hireDate,
			managerId: this.selectedManagerOptions[0],
		};
	}
}
