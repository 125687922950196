import { useEffect, useState } from 'react';
import { IS_ADMIN } from './constants';
import { getLatestDataProcessingAgreement } from './service';

export function useCurrentDpa() {
	const [currentDpa, setCurrentDpa] = useState();

	const getData = async () => {
		try {
			const latestDpa = await getLatestDataProcessingAgreement();

			if (!latestDpa) {
				return;
			}

			setCurrentDpa(latestDpa);

			return latestDpa;
		} catch (e) {
			console.error('Failed to get the DPA agreement!');
		}
	};

	useEffect(() => {
		if (IS_ADMIN) {
			getData();
		}
	}, []);

	return { currentDpa, refresh: getData };
}
