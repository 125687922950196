import { Fragment } from 'react';
import { TabIcon } from '../../subcomponents';

import './styles.styl';

export const sidebar = {
	name: 'sidebar',
	caret: true,
	hiddenParts: ['icon', 'secondaryText'],
	extraClasses: {
		tabIcon: 'baseFillColor',
		tabLabel: ({active}) => ({
			linkColor: !active,
		}),
	},
	TabContent: (props) => {
		const {
			_createBEM,
			primaryText,
			children,
		} = props;

		return (
			<Fragment>
				<h4 className={ _createBEM('tabHeading', null, props) }>
					<TabIcon className={ _createBEM('tabIcon', null, props) } { ...props } />
					{ primaryText }
				</h4>
				{ children }
			</Fragment>
		);
	},
};
