import { MutableRefObject } from 'react';

/**
 * Validates the fields inside of the Tax Information Component (stolen from PayPals)
 * It is meant to be non-intrusive and if fields or names change non-breaking
 * It will need to be manually updated until BE validation or better labeling of required fields from the other team.
 *
 * @param ref Ref to a DOM element that wraps the tax information
 */
export const validateTaxInformation = (ref: MutableRefObject<HTMLElement>): boolean | string => {
	// labels aren't properly linked to the inputs so we have to manually check fields (which means we will have to manually update with any changes... ugh)
	// If they were properly linked, we could just pull labels with required classes and get their matching input

	let isValid = true;
	const message = $.__('There is missing information in Tax Withholding. Please check that all required fields are entered and all State Unemployment Insurance fields are filled out (if applicable).');

	const requiredInputSelectors = [
		'[name^="fed[status"]', // Federal Filing Status
		'[name="state[optional][13]"]', // State - Dependent Exemptions
		'[name="state[optional][48]"]', // State - Principal Employment
		'[name="state[location]"]', // State - Location
		'[name="uiState[location]"]', // State Unemployment - Location
		'[name="uiState[optional][1]"]', // State Unemployment - SOC Category
		'[name="uiState[optional][2]"]', // State Unemployment - SOC Occupation
		'[name="uiState[optional][3]"]', // State Unemployment - Geographic Code - Alaska
	];

	// if the ref is not set, assume true
	if (!ref?.current) { return isValid; }

	// only search for elements within local ref (multiple instances of form may be on the page in a modal)
	const elements = ref.current.querySelectorAll<HTMLInputElement | HTMLSelectElement>(requiredInputSelectors.join(', '));

	// if there are no elements the user hasn't chosen to add Tax Information. Assume True
	if (!elements || !elements.length) { return isValid; }

	elements.forEach((element) => {
		// don't use !element.value -> 0 can be a valid value ;)
		if (element && !element.value.length) {
			isValid = false;
		}
	});

	return isValid || message;
};
