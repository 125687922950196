import {
	DIGIT_OUTER_WIDTH,
	MARKER_CHUNK_OFFSET,
} from './constants';

export const getMiddleIndex = count => Math.max(0, Math.floor(count / 2) - 1);

export const getMarkerPosition = (middleIndex, index, shouldChunk) => {
	const offset = (shouldChunk && index > middleIndex) ?
		MARKER_CHUNK_OFFSET : 0;
	const x = (index * DIGIT_OUTER_WIDTH) + offset;
	return x;
};
