import { ReactElement } from 'react';
import {
	FileCard
} from '@bamboohr/fabric/';
import { isImgFile, previewFile } from 'file.util';
import { EsignatureInstanceStatus, File, UpdatedFile } from 'NHPTemplates.mod/Form/Interfaces';
import './styles.styl';
import { useStyles } from './styles';
import { ESIG_TYPE } from './constants';
import { openEsignatureSigningModal } from '../../../../../../../../public/esignature/util';

interface Props {
	files: Array<File>;
	handleRemove: (data: Record<string, any>) => void;
	handleToggle: (data: Record<string, any>) => void;
	view: 'attachment' | 'display' | 'empty';
}

interface EsignatureData {
	currentSignerUserId?: number | string;
	esignatureInstanceId: number | string;
	esignatureStatus: EsignatureInstanceStatus | null;
	esignatureTemplateId: number | string;
	esignatureWorkflowId: number | string;
	workflowInstanceId: number | string;
	isCompletedEsignature: boolean;
}

interface FileMetaData {
	eSigMetaData?: EsignatureData;
	fdId: string;
	fileId: string;
	mode: string;
	name: string;
	required: boolean;
	safe: boolean;
	type: string;
}

export function FileSection(props: Props): ReactElement {
	const {
		files = Array<UpdatedFile>(),
		handleRemove,
		handleToggle,
		view = 'display',
	} = props;
	const styles = useStyles();

	function getFileMetaData(file: UpdatedFile): FileMetaData {
		const {
			currentSignerUserId,
			esignatureInstanceId,
			esignatureRequired,
			esignatureStatus,
			esignatureTemplateId,
			esignatureWorkflowId,
			esignatureWorkflowInstanceId,
			extension,
			fileDataId,
			fileName,
			fileId,
			isCompletedEsignature,
			isEsignature,
			scanInfo,
		} = file;

		const safe = (scanInfo === null || scanInfo === 'skipped');
		const type = (isEsignature) ? ESIG_TYPE : extension;
		const mode = (isImgFile(extension.toLowerCase())) ? 'img' : '';

		return {
			eSigMetaData: {
				currentSignerUserId,
				esignatureStatus,
				esignatureInstanceId,
				esignatureTemplateId,
				esignatureWorkflowId,
				workflowInstanceId: esignatureWorkflowInstanceId,
				isCompletedEsignature
			},
			fdId: fileDataId as string || '',
			fileId: fileId as string || '',
			mode,
			name: fileName,
			required: esignatureRequired === true,
			safe,
			type,
		};
	}

	//need to sort here!!!
	if (files.length > 1) {
		files.sort((a, b) => {
			return a.fileName.localeCompare(b.fileName);
		});
	}

	return (
		<div className="FileCardContainer FileCards NewHirePacketFiles">
			{
				files.map((file, index) => {
					if (!file) {
						return null;
					}
					const fileData = getFileMetaData(file);

					const {
						eSigMetaData,
						fdId,
						fileId,
						mode,
						name,
						required,
						safe,
						type,
					} = fileData;

					const {
						esignatureInstanceId,
						esignatureStatus,
						esignatureTemplateId,
						esignatureWorkflowId,
					} = eSigMetaData;

					const pendingWorkflowCreation = (type === ESIG_TYPE && !esignatureTemplateId && !esignatureWorkflowId);

					function handlePreviewClick(): void {
						const previewableStatuses = ['Completed', 'InProgress', 'Pending'];
						if (type === ESIG_TYPE) {
							if (previewableStatuses.indexOf(esignatureStatus) > -1 && esignatureInstanceId) {
								openEsignatureSigningModal(esignatureInstanceId, true);
							}
						} else {
							previewFile(fdId || fileId, fileId, name, null, null, true, mode);
						}
					}

					let signatureState = null;

					if (view === 'display') {
						if (esignatureInstanceId) {
							switch (esignatureStatus) {
								case 'InProgress':
								case 'Pending':
									signatureState = 'pending';
									break;
								case 'Completed':
									signatureState = 'signed';
									break;
								case 'Declined':
									signatureState = 'declined'
									break;
								default:
									signatureState = 'canceled';
									break;
							}
						} else {
							// setting up a nhp page will want to display this as if it is in progress.
							signatureState = 'pending';
						}
					}
					const fileName = pendingWorkflowCreation ? <span className={ styles.pendingWorkflow }>{ name }</span> as unknown as string : name;

					return (
						<div key={ `${ index }_${ fileId }` } className={ styles.fileCard } data-file-id={ fileId }>
							<FileCard
								fileId={ Number.parseInt(fileId) }
								fileName={ fileName }
								fileType={ type }
								isSafe={ safe }
								onClick={ (view === 'display' && !pendingWorkflowCreation) ? handlePreviewClick : null }
								onRemove={ (view === 'attachment') ? (fileId) => {
									const fileIndex = files.findIndex((searchedFile) => {
										//@ts-ignore
										return Number.parseInt(searchedFile.fileId) == fileId;
									});

									if (typeof handleToggle === 'function') {
										handleRemove({ fileId, fileIndex });
									}
								} : null }
								onToggle={ (fileId) => {
									const fileIndex = files.findIndex((searchedFile) => {
										//@ts-ignore
										return Number.parseInt(searchedFile.fileId) === fileId;
									});
									//@ts-ignore
									const required = (files[fileIndex].esignatureRequired === false) ? 'yes' : 'no';
									if (typeof handleToggle === 'function') {
										handleToggle({ fileId, fileIndex, required });
									}
								} }
								signatureState={ signatureState }
								toggleState={ required }
								view={ view }
							/>
							{ signatureState === 'pending' ? <span id={`esignature-signing-root-${esignatureInstanceId}`}></span> : null }
						</div>
					);
				})
			}
		</div>
	);
}
