import { FunctionComponent } from 'react';
import classNames from 'classnames';
import './field-box.styl';

type Props = {
	fieldBoxClasses?: string;
	id?: string;
	isRequired?: boolean
}

const FieldBox: FunctionComponent<Props> = ({
	fieldBoxClasses = '',
	children,
	id = null,
	isRequired = null,
}) => {
	const fixedFieldBoxClasses = isRequired === null ? fieldBoxClasses : fieldBoxClasses.replace(/(?:^|\s)required(?!\S)/, '');

	const classes = classNames(
		'fieldBox',
		'DynamicForm__fieldBox',
		{
			required: isRequired,
		},
		fixedFieldBoxClasses
	);

	return (
		<div className={ classes } id={ id }>
			{ children }
		</div>
	);
};

export default FieldBox;
