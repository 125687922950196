import { values as getValues, sum, map } from 'lodash';
import { max } from 'd3-array';
import { axisTop } from 'd3-axis';
import { selectAll, select } from 'd3-selection';
import { scaleLinear, scaleOrdinal } from 'd3-scale';
import { stack as d3Stack, stackOffsetNone } from 'd3-shape';

import { truncateLabel } from '../shared/chart-functions';
import { mouseEnter, mouseOver, mouseLeave, mouseClick } from 'Charts.mod/event-functions';

/**
 * Ideal Default Settings
{
	chartClass: '',
	needsAxis: true,
	container: {
		flexHeight: false,
		height: 120,
		width: 900,
		margin: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		},
		axis: {
			showAxis: true,
			label: 'Responses',
			labelLength: 28
		}
	},
	labelText: {
		fill: '#222',
		color: '#777',
		width: 100,
		labelPosition: left | top,
		maxLabelLength: 30, // Represents the max chars a label can contain before we trim with ...
		alignmentLeft: false
	},
	valueText: {
		color: '#777',
		spacing: 4,
		valueEdgePadding: 3,
		showTotalValueType: 'stacked', // none, total, stacked
		showZero: false
	},
	bar: {
		colors: ['#8db812', '#db6021', '#f19d11'], // Green - Orange - Red
		cursor = 'pointer',
		height: 25,
		padding: 8,
		formatTextFunction: null
	}
};
 */

/**
 * Expected data structure
{
	'type': 'stacked',
	'barKeys': [
		'sales',
		'engineering',
		'marketing'
	],
	'bars': [
		{
			'title': 'ocucb',
			'value': {
				'sales': 27,
				'engineering': 14,
				'marketing': 28
			}
		},
		{
			'title': 'tvaph',
			'value': {
				'sales': 7,
				'engineering': 15,
				'marketing': 19
			}
		},
		{
			'title': 'eojfs',
			'value': {
				'sales': 14,
				'engineering': 15,
				'marketing': 19
			}
		}
	]
}
 */

/**
 * Creates the top aligned labels bar chart
 *
 * @param {object} data Formatted Data array containing objects
 */
export function drawChart(data) {
	const settings = this.getSettings();
	const { container: containerSettings } = settings;
	const { showAxis, label: axisLabel, labelLength } = containerSettings.axis;
	const { top, right, bottom, left } = containerSettings.margin;
	const { width: labelWidth, fill: labelFill, labelPosition } = settings.labelText;
	const { spacing: valueSpace, showTotalValueType, color: valueColor, valueEdgePadding, showZero } = settings.valueText;
	const { padding: barPadding, height: barHeight, colors, cursor = 'default' } = settings.bar;
	const width = this._getContainerWidth() - left - right;
	const height = containerSettings.height - top - bottom;

	const getValuesFromObject = (obj) => {
		const valuesArray = [];
		// using the keys to pull only those values into a 'values' array
		map(obj, function(value, property) {

			// iterate over barKeys to pull out only those values
			data.barKeys.forEach((key) => {
				if (key == property) {
					valuesArray.push(+value);
				}
			});
		});

		return valuesArray;
	};

	const formatValueLength = function(data) {
		switch (showTotalValueType) {
			case 'total':
				return data.reduce((prev, value) => {
					return prev + value;
				}, 0);

			case 'stacked':
				return data.join('/');

			case 'none':
			case 'default':
				return '';
		}
	};

	const adjustTickLines = (selection) => {
		selection.selectAll('.tick line')
			.attr('transform', 'translate(-2 ,0)');
	};

	const adjustTickLabels = (selection) => {
		selection.selectAll('.tick text')
			.each(function(d, i) {
				if (i === 0) {
					return;
				}

				select(this).attr('transform', 'translate(-2, 0)');
			});
	};

	const findHighestSumArray = (arr) => {
		let highestSumArray = [];

		arr.forEach((item) => {
			if (sum(item) > sum(highestSumArray)) {
				highestSumArray = item;
			}
		});

		return highestSumArray;
	};

	const formatAndFillValues = function(d) {
		switch (showTotalValueType) {
			case 'total': {
				const node = select(this)
					.text('');
				node
					.append('tspan')
					.style('fill', valueColor)
					.text(formatValue);
				break;
			}

			case 'stacked': {
				if (d.values) {
					const node = select(this)
						.text('');
					d.values.forEach((value, i) => {
						node
							.append('tspan')
							.style('fill', zScale(i))
							.text(() => {
								if (value === 0) {
									if (showZero === true && i === 0) {
										return 0;
									}
									return '';
								} else if ((d.values.length - 1) === i && d.values[i - 1]) {
									return `/${ value }`;
								}
								return value;
							});
					});
				} else {
					select(this)
						.append('tspan')
						.style('fill', zScale(d))
						.text(formatValue);
				}
				break;
			}

			case 'none':
			case 'default':
				break;
		}
	};

	const barValuesArray = data.bars.map(item => getValuesFromObject(item.value));

	const largestArray = findHighestSumArray(barValuesArray);

	// Convert incoming data to stackable data structure
	const stackableData = data.bars.map((item) => {
		item.value.title = item.title;
		item.value.titleId = item.titleId;
		item.value.popoverValue = item.popoverValue;

		return item.value;
	});

	// Create an array of each bars total value
	const totalValues = stackableData.map((item) => {
		const valuesArray = getValuesFromObject(item);
		const total = sum(valuesArray);

		return {
			values: valuesArray,
			total,
		};
	});

	// Add up each bars worth of values into an array of totals
	const highestValues = stackableData.map((item) => {
		const valuesArray = getValuesFromObject(item);
		const sums = sum(valuesArray);

		return sums;
	});

	// Get the highest total value from each bar
	const maxValue = max(highestValues);

	// Replicate what the value string will look like
	const valueString = formatValueLength(largestArray);

	// Convert that value string into pixel space
	const maxTextLength = this._convertValueLengthToPixelSpace(valueString);

	const stack = d3Stack().keys(data.barKeys).offset(stackOffsetNone);
	const series = stack(stackableData);

	let zScale = scaleOrdinal()
		.domain(stackableData)
		.range(colors);

	const calculatedRangeEnd = width - labelWidth - maxTextLength - valueEdgePadding; // container width minus the labelWidth and value length in pixels
	const xScale = scaleLinear()
		.domain([0, maxValue]) // 0 and max bar length value
		.rangeRound([0, calculatedRangeEnd]) // container width minus the labelWidth and value length in pixels
		.nice()
		.clamp(true);

	let formatValue = function(d) {
		switch (showTotalValueType) {
			case 'total':
				return d.total;

			case 'stacked':
				// Removes any zeros from the array to prevent showing any in the display
				d.values.forEach(function(value, i) {
					if (value == 0) {
						d.values.splice(i, 1);
					}
				});

				return d.values.join('/');

			case 'none':
			case 'default':
				return '';
		}
	};

	if (this.hasAxis()) {
		const tickHeight = height + top + bottom;
		const xAxis = axisTop().scale(xScale);
		xAxis
			.tickSizeOuter(0)
			.ticks(6)
			.tickFormat((d, i, a) => {
				const tick = select(a[i]);

				// Reset all ticks with visibility
				tick.style('opacity', 1);

				// The first tick we want to be the axisLabel
				if (i == 0) {
					tick.attr('dx', labelLength); // To ensure the start of our label lines up with the bars.
					return axisLabel;
				}

				return d;
			});

		// If axis current exists just update it, otherwise create it
		const isAxisEmpty = this._g.select(this._getClassList('__xAxis')).empty();
		if (isAxisEmpty) {

			// Add and position axis to compensate the label width
			this._g.append('g')
				.attr('class', this._buildClassList('__xAxis'))
				.attr('transform', `translate(${ labelWidth + 1 }, 0)`) // offset so the marks start lined up with the chart
				.call(xAxis)
				.call(adjustTickLabels)
				.call(adjustTickLines);

			// Required to move the chart and labels down to make room for the axis
			this._g
				.attr('transform', 'translate(0, 18)');

			// Show axis if `showAxis` is true
			select(this._getClassList('__xAxis'))
				.attr('opacity', 1)
				.style('opacity', 1);

		} else {

			select(this._getClassList('__xAxis'))
				.attr('opacity', 1)
				.transition()
				.duration(500)
				.call(xAxis)
				.call(adjustTickLabels)
				.call(adjustTickLines);
		}
	}

	// DATA BIND (update selector)
	const layer = this._g.selectAll(this._getClassList('__series'))
		.data(series);

	const layerEnter = layer
		.enter()
		.append('g')
		.attr('id', d => d.key)
		.attr('fill', (d, i) => zScale(i))
		.attr('class', d => this._buildClassList('__series'));

	const layerMerged = layerEnter.merge(layer);

	const barsUpdate = layerMerged.selectAll(this._getClassList('__bar'))
		.data(d => d);

	const labelsUpdate = this._g.selectAll(this._getClassList('__label'))
		.data(data.bars);

	const valuesUpdate = this._g.selectAll(this._getClassList('__value'))
		.data(totalValues);

	// EXIT
	barsUpdate
		.exit()
		.transition()
		.duration(500)
		.style('opacity', 0)
		.remove();

	labelsUpdate
		.exit()
		.transition()
		.duration(500)
		.style('opacity', 0)
		.remove();

	valuesUpdate
		.exit()
		.transition()
		.duration(500)
		.style('opacity', 0)
		.remove();

	// UPDATE
	let transitions = 0;
	barsUpdate
		.data(d => d)
		.transition()
		.delay(function(d, i) {
			return i * 50;
		})
		.duration(500)
		.attr('x', function(d, i) { return xScale(d[0]) + 1; })
		.attr('width', function(d) {
			const value = xScale(d[1]) - xScale(d[0]) - 1;
			return value < 0 ? 0 : value;
		})
		.on('start', () => {
			transitions++;
		})
		.on('end', () => {
			if (--transitions === 0) {
				this._dynamicallyAdjustSVGHeight();
			}
		});

	labelsUpdate
		.transition()
		.duration(500)
		.text((d) => {
			const value = d.title;
			const maxLength = this.getSettings().labelText.maxLabelLength;

			return truncateLabel(maxLength, value);
		});

	valuesUpdate
		.transition()
		.delay(function(d, i) {
			return i * 50;
		})
		.duration(500)
		.attr('x', function(d, i, itemsArray) {
			return xScale(d.total) + labelWidth + 1;
		})
		.each(formatAndFillValues);

	// ENTER
	barsUpdate.enter()
		.append('rect')
		.attr('class', this._buildClassList('__bar'))
		.attr('cursor', cursor)
		.attr('transform', `translate(${ labelWidth }, 0)`)
		.attr('shape-rendering', 'crispEdges')
		.attr('height', barHeight)
		.attr('y', function(d, i) {
			return (i * (barHeight + barPadding) + barPadding);
		})
		.attr('x', function(d, i) { return xScale(d[0]) + 1; })
		.attr('width', function(d) {
			const value = xScale(d[1]) - xScale(d[0]) - 1;
			return value < 0 ? 0 : value;
		})
		.on('mouseenter', mouseEnter)
		.on('click', mouseClick)
		.on('mouseover', mouseOver)
		.on('mouseleave', mouseLeave);

	labelsUpdate.enter()
		.append('text')
		.attr('class', this._buildClassList('__label'))
		.attr('y', function(d, i) {
			if (labelPosition === 'top') {
				return (i * (barHeight + barPadding)) + 5;
			}
			return (i * (barHeight + barPadding) + barPadding);
		})
		.attr('x', 0)
		.attr('dx', valueSpace)
		.attr('dy', '1.3em')
		.attr('fill', labelFill)
		.attr('width', labelWidth)
		.text((d) => {
			const value = d.title;
			const maxLength = this.getSettings().labelText.maxLabelLength;

			return truncateLabel(maxLength, value);
		});

	valuesUpdate.enter()
		.append('text')
		.attr('class', this._buildClassList('__value'))
		.attr('y', barHeight / 2)
		.attr('dx', valueSpace)
		.attr('dy', '1.3em')
		.attr('y', function(d, i) { return (i * (barHeight + barPadding) + barPadding); })
		.attr('x', function(d, i, itemsArray) {
			return xScale(d.total) + labelWidth + 1;
		})
		.each(formatAndFillValues);
}
