import React, { useState, ReactElement } from 'react';
import Ajax from '@utils/ajax';
import { StandardModal, Button, CardSize, Loader } from '@bamboohr/fabric';
import { EmployeeProfileModalProps, EmployeeData } from './types';
import { EmployeeProfileInfo } from './employee-profile-info';

export const EmployeeProfileModal = (props: EmployeeProfileModalProps): ReactElement => {
	const { isOpen, onClose, employeeId } = props;
	const [employee, setEmployee] = useState<EmployeeData>();

	const handleModalOpen = () => {
		return Ajax.get(`/employee_directory/employee/${employeeId}`).then(({data}) => {
			setEmployee(data);
		}).catch(() => {
			window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
			onClose();
		})
	};

	const handleClose = () => {
		setEmployee(null);
		onClose();
	}

	return (
		<StandardModal
			isOpen={isOpen}
			onOpenComplete={handleModalOpen}
			onRequestClose={handleClose}
		>
			<StandardModal.Body
				renderFooter={() => (
					<StandardModal.Footer
						actions={ [
							<Button key="close" onClick={onClose} type="button">{ $.__('Close') }</Button>,
						] }
					/>
				)}
				renderHeader={
					employee ? <StandardModal.Header hasCloseButton={ true } title={ $.__('All About %1', employee.prefName || employee.firstName) } /> : undefined
				}
				size={ CardSize.MEDIUM }
			>
				<StandardModal.Constraint spacingOverrides={{
					bottom: StandardModal.Constraint.Spacing.BIGGIE, 
					top: StandardModal.Constraint.Spacing.BIGGIE,
					left: StandardModal.Constraint.Spacing.LARGE,
					right: StandardModal.Constraint.Spacing.LARGE,
				}}
				>
					{
						employee
						? <EmployeeProfileInfo employee={employee} /> : <Loader />
					}
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	)
}