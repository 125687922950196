import classNames from 'classnames';
import { Icon, IconV2 } from '@bamboohr/fabric';
import { camelCase, capitalize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { getValidationContext } from '../../context';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const Text = (props) => {
	const {
		errorState,
		label,
		name,
		data,
		validationRegistration,
		rules,
		showErrorMessage,
		typeProps = {},
	} = props;
	const { setErrors, validate } = getValidationContext();

	if (!data) {
		return null;
	}

	const didMount = useRef(false);
	const [value, setValue] = useState(data.value);
	const [previouslyChangedValue, setPreviouslyChangedValue] = useState(
		data.value,
	);
	useEffect(() => {
		setValue(data.value);
	}, [data.value]);
	useEffect(() => {
		if (didMount.current) {
			validate([name], false);
		} else {
			didMount.current = true;
		}
	}, [previouslyChangedValue]);

	function handleBlur(event) {
		const formattedValue = handleUrlFormat(
			event.target,
			previouslyChangedValue,
			setErrors,
		);
		setValue(formattedValue);
		setPreviouslyChangedValue(formattedValue); // only changes here, rather than keypress like `value` can
	}

	return (
		<div className={`fab-FormColumn CandidateField CandidateField--${name}`}>
			<label
				className={classNames('fab-Label', {
					'fab-Label--error': errorState,
					'fab-Label--required': data.isRequired,
				})}
				htmlFor={name}
			>
				{label}
			</label>
			<div
				className={classNames(
					`fab-InputWrapper CandidateField__inputWrapper CandidateField__inputWrapper--${name}`,
					{
						[`CandidateField__inputWrapper--cq${capitalize(
							camelCase(typeProps.cqType),
						)}`]: typeProps.cqType,
					},
				)}
			>
				<input
					ref={validationRegistration}
					className={classNames('fab-TextInput', {
						'fab-TextInput--width100': true,
						'fab-TextInput--error': errorState,
						'fab-TextInput--withNub': typeProps.nubIconName,
					})}
					disabled={Boolean(data.isDisabled)}
					id={name}
					maxLength={rules && rules.maxLength ? rules.maxLength : 255}
					name={name}
					onBlur={typeProps.hasCustomUrlHandling ? handleBlur : null}
					onChange={(e) => setValue(e.target.value)}
					required={data.isRequired}
					type="text"
					value={value}
				/>
				{typeProps.nubIconName && (
					<span className="fab-TextInputNub">
						<span className="fab-TextInputNub__icon">
							{ifFeature(
								'encore',
								<IconV2 name={typeProps.nubIconName} size={16} />,
								<Icon name={typeProps.nubIconName} />,
							)}
						</span>
					</span>
				)}
			</div>
			{errorState && showErrorMessage && (
				<span className="fab-FormNote fab-FormNote--error">
					{errorState.message}
				</span>
			)}
		</div>
	);
};

/**
 * If there has been a change that needs to be validated, also ensure that the url begins with http
 * @param inputElement
 * @param previousValue
 * @param setErrors
 * @returns {string}
 */
export function handleUrlFormat(inputElement, previousValue, setErrors) {
	const { name, value } = inputElement;
	let newValue = value;

	if (newValue !== previousValue) {
		const isEmpty = !newValue;
		const hasHttp = value.startsWith('http://') || value.startsWith('https://');
		if (!isEmpty && !hasHttp) {
			newValue = `http://${value}`;
		}
		if (name === 'linkedinUrl') {
			handleLinkedinFormat(newValue, name, setErrors);
		}
	}

	return newValue;
}

export function handleLinkedinFormat(newValue, inputName, setErrors) {
	const isEmpty = !newValue;
	if (isEmpty || /(linkedin\.com)/gi.test(newValue)) {
		setErrors({ [inputName]: null });
		return true;
	}

	setErrors({
		[inputName]: {
			rule: inputName,
			message: $.__('Invalid LinkedIn profile URL.'),
		},
	});
	return false;
}
