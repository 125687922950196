import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	isString,
	defaults,
} from 'lodash';

import {
	getExtFromFileName,
} from 'BambooHR.util';

const _$eventWrapper = new WeakMap();
const _config = new WeakMap();
const DEFAULT_CONFIG = {
	uploadDialogTitle: $.__('Upload Files'),
	uploaderId: 'multiUpload',
	uploadLinkHTML: `
		<span class="attach-link">
			<button class="fab-Button fab-Button--secondary fab-TextButton--noSpace" type="button js-chooseFile">
				${ifFeature(
					'encore',
					'<ba-icon encore-class="fab-Button__icon fab-Button__icon--left" encore-name="up-from-line-regular" encore-size="16"></ba-icon>',
					'',
				)}
				${$.__('Choose File(s)')}
			</button>
			<button class="fab-TextButton fab-link" type="button" style="display: none;">${$.__('Add files')}</button>
		</span>
	`,
	fileHiddenInput: ({
		name,
		type,
		size,
		image,
		fid,
	}) => (`
			<input
				type="hidden"
				name="attachId[]"
				data-name="${ name }"
				data-type="${ type }"
				data-size="${ size }"
				data-image="${ image }"
				value="${ fid }"
			/>
		`),
};

export const uploadModalContent = (
	<div id="uploadFilesDialog">
		<form name="fileUploadForm" id="fileUploadForm" className="shareForm">
			<div className="fileField multiUpload">
				<div className="uploadDesc">
					<div className="uploadDescText">
						<span className={ifFeature('encore', '', 'uploadModalIcon')}>
							{ifFeature(
								'encore',
								<IconV2 name="upload-regular" size={32} />,
								<Icon name="fab-upload-20x24" />)}
						</span>
						{ifFeature('encore', (
							<h4>{ $.__("Drop Files Here to Upload") }</h4>
						), (
							<span className="upload-msg">{ $.__("Drop Files Here to Upload") }</span>
						))}
					</div>
					<span class="uploadDesc--strong">{ $.__("or") }</span>
				</div>
				<div className="FileUploadActions">
					<div className="files" style={ifFeature('encore', {width: '100%', marginBottom: '16px'})}></div>
					<span className="attach-wrapper centerThis" data-id="resume"></span>
				</div>
			</div>
		</form>
	</div>
);

let openedAsSheet = false;

let $modal;
const fabricModalSelector = '#uploadFilesDialog';

function _trigger (...args) {
	_$eventWrapper.get(this)
		.triggerHandler(...args);

	return this;
}

function handleSheetOrModalUpdate(props, updateModal = false) {
	if (openedAsSheet) {
		window.BambooHR.Modal.setState({ sheetProps: props }, true);
	} else {
		window.BambooHR.Modal.setState(props, updateModal);
	}
}

function _setupUploader ($context) {
	const _this = this;
	const $attachWrapper = $('.attach-wrapper', $context);
	const {
		uploadLinkHTML,
		fileHiddenInput,
		uploaderId,
	} = this::_getConfig();


	const primaryAction = () => {
		let newFiles = _this::_getNewFiles();
		handleSheetOrModalUpdate({ isOpen: false });
		_this::_trigger('attach', [newFiles]);
	};

	const options = {
			linkHTML: uploadLinkHTML,
			success(data, elem) {
				$('.noFile', $context).hide();
				let $form = $attachWrapper.closest('form');

				$form.append(fileHiddenInput(data));
				_this::_trigger('upload', [data, elem]);
				_this::_updateModalFooter(true, primaryAction);
			},
			removeFile($elem) {
				let fileId = $elem.attr('id').replace('file-', '');

				if ($elem.hasClass('uploadDone')) {
					$(`:input[name^="attachId"][value="${ fileId }"], ba-select[name^="attachId"][value="${ fileId }"]`)
						.remove();
				}

				if (updateFileUploadActions($context)) {
					_this::_updateModalFooter(false, null);
				}

				_this::_trigger('remove');

				return true;
			},
			onRemove() {
				if (updateFileUploadActions($context)) {
					_this::_updateModalFooter(false, null);
				}
			},
			createFileWrapper(name, progress) {
				$('.noFile', $context)
					.hide();

				if ($('.files > *', $context).length === 0) {
					$('.attach-link button, .uploadDesc', $context).toggle();

					centerFileUploadActions($context, false);
				}

				return $.fileUploader.createFileWrapper(name, progress);
			},
			setDragOverValues($wrapper) {

			},
			setDragOffValues() {

			},
			uploaderId
		};

	$attachWrapper.closest(fabricModalSelector).fileUploader(options);
	$('.uploadDesc', fabricModalSelector)
		.show();

	$('input[name^="attachId"]', fabricModalSelector)
		.remove();
}

function _getNewFiles() {
		return $(':input[name^="attachId"], ba-select[name^="attachId"]', fabricModalSelector)
			.toArray()
			.map((input, index) => {
				const name = input.getAttribute('data-name');
				return {
					data: { isComplete: null },
					fileId: input.getAttribute('value'),
					id: input.getAttribute('value'),
					image: input.getAttribute('data-image'),
					info: {
						icon: input.getAttribute('data-image'),
						text: getExtFromFileName(input.getAttribute('data-name')).toUpperCase(),
					},
					key: name + '-' + index,
					name: name,
					type: input.getAttribute('data-type'),
					value: input.getAttribute('value'),
					size: input.getAttribute('data-size'),
				};
			});
}

function _getConfig(name) {
	let config = _config.get(this);

	if (name) {
		return config[name];
	}

	return config;
}

export default class FileManager {
	constructor(config = {}) {
		_config.set(this, defaults(config, DEFAULT_CONFIG));
		_$eventWrapper.set(this, $('<div />'));
	}

	on(event, ...args) {
		if (isString(event)) {
			event += '.FileManager';
		}

		_$eventWrapper.get(this)
			.on(event, ...args);

		return this;
	}

	openUploadModal() {
		const _this = this;
		const { uploadDialogTitle } = _this::_getConfig();

		openedAsSheet = window.BambooHR.Modal.isOpen();
		const modalProps = {
			content: uploadModalContent,
			onClose: () => {
				handleSheetOrModalUpdate({ isOpen: false });
			},
			onOpen: () => {
				let $multiUpload = $(fabricModalSelector).find('.multiUpload');
				_this::_setupUploader($multiUpload);
			},
			primaryAction: null,
			primaryActionText: $.__('Attach'),
			isOpen: true,
			title: uploadDialogTitle
		};
		handleSheetOrModalUpdate(modalProps);
		_updateModalFooter(false);

		return this;
	}
}

export function updateFileUploadActions(context) {
	if ($('.files > *', context).length === 0) {
		$('.noFile', context).show();
		if (!$('.attach-link .js-chooseFile', context).is(':visible')) {
			$('.attach-link > *, .uploadDesc', context).toggle();
			centerFileUploadActions(context);
		}
		return true;
	}
	return false;
}

export function centerFileUploadActions(context, isCenter = true) {
	if (isCenter) {
		$('.attach-wrapper', context).addClass('centerThis');
		$('.FileUploadActions', context).addClass('centerContent');
	} else {
		$('.attach-wrapper', context).removeClass('centerThis');
		$('.FileUploadActions', context).removeClass('centerContent');
	}
}

function _updateModalFooter(enable, primaryAction) {
	if (enable) {
		handleSheetOrModalUpdate({ primaryAction: primaryAction }, true);
	} else {
		handleSheetOrModalUpdate({ primaryAction: null }, true);
	}
}
