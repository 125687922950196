import { BaseInputField } from './base-input-field';
import { TextFieldProps } from './types';

export const TextField = (props: TextFieldProps): JSX.Element => {
	return (
		<BaseInputField
			{ ...props }
			type="text"
		/>
	);
};
