import Name from '../components/item/header/name';
import { connect } from 'react-redux';
import { getUserById } from '../../../store/selectors';

const mapStateToProps = (state, ownProps) => ({
	user: getUserById(state, ownProps.id),
});

export default connect(
	mapStateToProps,
	{}
)(Name);

