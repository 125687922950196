import {pick} from 'lodash';
import {createContext} from 'react';

const {Provider, Consumer} = createContext();
const consume = makeConnectedConsumer(Consumer);

export {Provider, consume};


function makeConnectedConsumer(Consumer) {
	return function consume(pickList, Component) {
		return function Consume(outerProps) {
			return (
				<Consumer>
					{context => <Component {...{...pick(context, pickList), ...outerProps}}>{outerProps.children}</Component>}
				</Consumer>
			)
		}
	}
}
