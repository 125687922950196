import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FunctionComponent } from 'react';

export const HoverEmail: FunctionComponent<DynamicForm.Hover> = ({
	value,
	isEditable,
	context,
	children,
}) => {
	const {
		controls: { HoverBase },
	} = context;

	return (
		<HoverBase hoverChild={ children } isEditable={ isEditable }>
			<>
				<span className="HoverHint__icon">
					{ifFeature(
						'encore',
						<IconV2 color='info-strong' name="envelope-solid" size={ 16 } />,
						<Icon brand={ true } name="fab-envelope-16x12" />
					)}
				</span>
				<a className="HoverHint__link" href={ `mailto:${ value }` }>
					{ $.__('Email') }
				</a>
			</>
		</HoverBase>
	);
};

export default HoverEmail;
