import {
	on,
	onResized,
	onReady,
	onFocus,
	onBlur
} from 'comp.deco';
import {
	getJsonScriptVar,
	htmlentities,
} from 'BambooHR.util';
import {
	defaultsDeep,
	each,
	includes,
	isArray,
	isFunction,
	isPlainObject,
	isString,
	isUndefined,
	uniq,
} from 'lodash';

import 'datatables.net';
import 'datatables.net-fixedcolumns';

import './styles.styl';
import {ifFeature} from "@utils/feature";

const _config = new WeakMap(),
	_defaultFilters = new WeakMap(),
	_defaultOrder = new WeakMap();
let chosenTimeout;


function _displayValue(data, type, row, meta) {
	const column = meta.settings.aoColumns[meta.col];

	// If column type not specified or its escaped, escape the column data
	if (column.type === undefined || column.type === 'escape') {
		data = htmlentities(data);
	}

	if (column.type === 'ba-html') {
		data = data.html;
	}

	if (column.data === 'employeeName') {
		return `<span class="js-employeeName" data-employeeId="${ row.employeeId }">${ data }</span>`;
	}

	return data;
}

function _filterValue(data, type, row, meta) {
	const column = meta.settings.aoColumns[meta.col];

	if (column.type === 'ba-html') {
		data = data.value;
	}

	return data;
}

function _sortValue(data, type, row, meta) {
	const column = meta.settings.aoColumns[meta.col];

	data = this::_filterValue(data, 'filter', row, meta);

	if (
		isString(column.data) &&
		!isUndefined(row[column.data + '__sort'])
	) {
		return row[column.data + '__sort'];
	}

	return data;
}

export default class DataTable {

	@on('liszt:ready', 'td select')
	onChosenReady(e, ctrl) {
		clearTimeout(chosenTimeout);
		chosenTimeout = setTimeout(function() {
			ctrl.adjustColumns();
		}, 10);
	}

	get config() {
		return _config.get(this);
	}

	get DataTable() {
		return this.$container.DataTable();
	}

	$filters = this.$elem.find('.DataTable__filters');
	$filterButtons = this.$filters.find('.DataTable__filter-buttons');
	$container = this.$elem.find('.DataTable__container');

	constructor($elem, elemId) {
		this.$elem.trigger('DataTable:config', this);
		const customConfig = this.$elem.data('DataTable:config') || {};

		var config = defaultsDeep({
			headerCallback: (thead, ...args) => {
				$('th', thead).each((index, th) => {
					const $th = $(th);
					const col = this.config.columns[index];
					const sortable = col.orderable !== false;

					$th
						.addClass('fab-Table__header')
						.toggleClass('fab-Table__header--sortable', sortable)
						.removeClass('fab-Table__cell')
						.removeAttr('style');

					if (sortable) {
						setTimeout(() => {
							const asc = $th.hasClass('sorting_asc');
							const desc = $th.hasClass('sorting_desc');

							$th.removeClass('sorting sorting_asc sorting_desc');

							const $sortWrap = $(
								th.querySelector('.js-DataTable__sortWrap') ||
								$th.wrapInner('<span class="js-DataTable__sortWrap" />')[0].firstChild
							);

							$sortWrap
								.toggleClass(`fab-Table__sorted`, asc || desc)
								.toggleClass(`fab-Table__sorted--asc`, asc);
						}, 0);
					}
				});

				$(thead).removeAttr('style');

				if (typeof customConfig.headerCellCallback === 'function') {
					Array.from(thead.querySelectorAll('th'))
						.forEach((cell, i) => {
							const column = this.config.columns[i];
							customConfig.headerCellCallback(cell, column, this.config);
						});
				}

				if (typeof customConfig.headerCallback === 'function') {
					return customConfig.headerCallback(thead, ...args, this.config);
				}
			},
		}, customConfig, {
			paging: false,
			pagingType: 'simple_numbers',
			pageLength: 250,
			data: this.$elem.data('DataTable:data') || getJsonScriptVar('data', elemId),
			columns: this.$elem.data('DataTable:columns') || getJsonScriptVar('columns', elemId),
			ajax: null,
			scrollX: true,
			scrollCollapse: true,
			searching: true,
			info: false,
			ordering: false,
			orderClasses: false,
			autoWidth: false,
			dom: 'tr<"DataTable__pagination"ip>',
			columnDefs: [],
			cellPlaceholder: '-',
			deferRender: true,
			infoCallback: (settings, start, end, max, total, pre) => $.__(`%1$s-%2$s of %3$s`, start, end, total),
			language: {
				paginate: {
					previous: '&laquo; ' + $.__('Prev'),
					next: $.__('Next') + ' &raquo;',
				}
			},
		});
		var initComplete = config.initComplete;
		var ajax = config.ajax;
		var rowCallback = config.rowCallback;

		config.columnDefs.push({
			targets: '_all',
			render: {
				display: this::_displayValue,
				filter: this::_filterValue,
				sort: this::_sortValue,
			}
		});

		config.initComplete = () => {
			_defaultOrder.set(this, this.DataTable.order());

			if (
				isFunction(initComplete) &&
				this::initComplete() === false
			) {
				return;
			}

			let $scrollBody = this.$elem.find('.dataTables_scrollBody');

			$scrollBody
				.on('scroll', (...args) => {
					let scrollX = $scrollBody[0].scrollLeft,
						scrollY = $scrollBody[0].scrollTop,

						scrollXpercent = scrollX / ($scrollBody[0].scrollWidth - $scrollBody[0].clientWidth),
						scrollYpercent = scrollY / ($scrollBody[0].scrollHeight - $scrollBody[0].clientHeight);

					this.$elem.toggleClass('DataTable--scrolled-left', scrollX > 0);
					this.$elem.toggleClass('DataTable--scrolled-right', scrollXpercent < 1);

					this.$elem.trigger('DataTable:scroll', args);
				});

			let defaultFilters = [];

			this.$filters.find(':input, ba-select').each(function() {
				let $input = $(this);
				defaultFilters.push({
					$elem: $input,
					val: $input.val()
				});
			});

			_defaultFilters.set(this, defaultFilters);
		};

		config.columns = config.columns.map((col, i) => {

			if (isUndefined(col.title) && isString(col.label)) {
				col.title = col.label;
			}

			if (isUndefined(col.name) && isString(col.data)) {
				col.name = col.data;
			} else if (isString(col.name) && isUndefined(col.data)) {
				col.data = col.name;
			}

			if (isFunction(config.processColumn)) {
				col = config.processColumn.call(this, col, i, config.columns) || col;
			}

			col.className = uniq((col.className || '')
				.split(' ')
				.map(className => 'DataTable__cell--' + className.replace(/^DataTable__cell--/, ''))
				.concat(['DataTable__cell fab-Table__cell'])
				.concat(isString(col.name) ? [`DataTable__cell--${ col.name }`] : [])
				.concat(isString(col.data) ? [`DataTable__cell--${ col.data }`] : [])
				.concat(isString(col.type) ? [`DataTable__cell--${ col.type }`] : [])
				.concat(isString(col.extraClasses) ? [`DataTable__cell--${ col.extraClasses }`] : [])
				.filter((className => className !== 'DataTable__cell--'))
			).join(' ');

			return col;
		});

		config.ajax = {};

		if (isString(ajax)) {
			config.ajax.url = ajax;
		} else if (isPlainObject(ajax)) {
			config.ajax.url = ajax.url;
		}

		if (isString(config.ajax.url)) {
			config.ajax.dataSrc = (result) => {
				if (isFunction(ajax.dataSrc)) {
					result = ajax.dataSrc(result);
				}

				if (isArray(result.activeColumns)) {
					this.DataTable
						.columns()
						.every(function() {
							this.visible(includes(result.activeColumns, this.dataSrc()));
						});

					this.toggleProcessing(false);
				}

				return result.data;
			};
		} else {
			config.ajax = null;
		}

		config.rowCallback = (row, data, index) => {
			$(row).addClass('fab-Table__row').removeClass('even odd').removeAttr('style');
			if (
				isFunction(rowCallback) &&
				this::rowCallback(row, data, index) === false
			) {
				return;
			}

			let rowApi = this.DataTable.row(row);
			let children = (data.children || []).map((child) => {
				let $row = $('<tr class="DataTable__row--child"/>'),
					placeholder = this.config.cellPlaceholder;

				this.DataTable.columns().every(function() {
					if (this.visible()) {
						$row.append(`<td>${ child[this.dataSrc()] || placeholder }</td>`)
					}
				});

				return $row;
			});

			rowApi.child(children).show();
		};

		if (!config.scrollX && !config.scrollY) {
			this.$elem.addClass('DataTable--noScroll');
		}

		_config.set(this, config);

		this.$elem.data('DataTable:config', this.config);

		[
			'draw',
			'error',
			'init',
			'order',
			'preInit',
			'column-sizing',
			'length',
		].forEach((event) => {
			this.$container.on(`${ event }.dt`, (e, ...args) => {
				this.$elem.trigger(`DataTable:${ event }`, args);
			});
		});

		this.$filters
			.on('click.DataTable:activateFilters', (e, ...args) => {
				if ($(e.target).closest('.DataTable__filter-buttons').length < 1) {
					this.$elem.trigger('DataTable:activateFilters', args);
				}
			})
			.on('change.DataTable:changeFilters', ':input, ba-select', (e, ...args) => {
				this.$elem.trigger('DataTable:changeFilters', args);
			})
			.on('click.DataTable:applyFilters', '.DataTable__apply-filters', (e, ...args) => {
				this.$elem.trigger('DataTable:applyFilters', args);
				this.draw();
			})
			.on('click.DataTable:resetFilters', '.DataTable__reset-filters', (e, ...args) => {
				this.$elem.trigger('DataTable:resetFilters', args);
			})
			.on('EmployeeFilters:update', (e, ...args) => {
				this.$elem.trigger('DataTable:changeFilters', args);
			});

		this.$elem.on('DataTable:preInit DataTable:length', () => {
			let {
				pages,
			} = this.DataTable.page.info();

			this.$elem.toggleClass('DataTable--singlePage', pages <= 1);
		});

		this.$container.dataTable(this.config);

		$('.fab-Table--zebra').removeAttr('style');
	}

	@on('DataTable:draw')
	onDrawTable(e, ctrl, config) {
		var hasLeftColumns = (
			config._oFixedColumns &&
			config._oFixedColumns.s &&
			config._oFixedColumns.s.iLeftColumns > 0
		);
		var hasRightColumns = (
			config._oFixedColumns &&
			config._oFixedColumns.s &&
			config._oFixedColumns.s.iRightColumns > 0
		);

		ctrl.$elem.toggleClass('DataTable--hasLeftColumns', hasLeftColumns);
		ctrl.$elem.toggleClass('DataTable--hasRightColumns', hasRightColumns);

		if (!hasRightColumns) {
			ctrl.$elem.find('.DTFC_ScrollWrapper .DTFC_RightHeadBlocker').css({
				height:
					ctrl.$elem
						.find(ifFeature('encore', '.DTFC_LeftHeadWrapper .DataTable__container', '.dataTables_scrollHead thead'))
						.height()
						/* @startCleanup encore */
						- ifFeature('encore', 0, 2)
						/* @endCleanup encore */
			});

			var $scrollBody = ctrl.$elem.find('.dataTables_scrollBody');

			if (
				$scrollBody.length > 0 &&
				$scrollBody[0].scrollHeight <= $scrollBody[0].clientHeight
			) {
				ctrl.$elem.find('.DTFC_RightWrapper').css({
					width: 0,
					'max-width': 0
				});
			}
		}
	}

	@on('DataTable:activateFilters')
	onActivateFilters(e, DataTable) {
		DataTable.$filters.addClass('DataTable__filters--active');
	}

	@on('DataTable:changeFilters')
	onChangeFilters(e, DataTable) {
		DataTable.$filters.addClass('DataTable__filters--active');
	}

	@on('DataTable:applyFilters')
	onApplyFilters(e, DataTable) {
		DataTable.toggleProcessing(true);

		DataTable.$filters.find(':input[name^="DataTable:"], ba-select[name^="DataTable:"]')
			.each((i, input) => {
				let $input = $(input),
					column = DataTable.getColumn($input.attr('name')),
					val = $.fn.dataTable.util.escapeRegex($input.val());

				column.search(val ? `^${ val }$` : '', true, false);
			});

		DataTable.$filters.removeClass('DataTable__filters--active');
	}

	@on('DataTable:resetFilters')
	onResetFilters(e, DataTable) {
		DataTable.toggleProcessing(true);

		each(_defaultFilters.get(DataTable), (obj) => {
			obj.$elem
				.val(obj.val)
				.trigger('liszt:updated');
		});

		DataTable.$filters.find('.EmployeeFilters')
			.trigger('EmployeeFilters:clear');

		DataTable.$elem.trigger('DataTable:applyFilters', DataTable);
	}

	@on('DataTable:showChildren')
	onShowChildren(e, DataTable) {
		DataTable.disableSort();
		DataTable.$elem.addClass('DataTable--show-children');
	}

	@on('DataTable:hideChildren')
	onHideChildren(e, DataTable) {
		DataTable.enableSort();
		DataTable.$elem.removeClass('DataTable--show-children');
	}

	@onResized()
	windowResized(e, DataTable) {
		DataTable.adjustColumns();
	}

	@onReady()
	onReady(e, DataTable) {
		DataTable.adjustColumns();
	}

	@onFocus('tbody td :input')
	onFieldFocus(e, ctrl) {
		var index = $(this).closest('tr').index();
		ctrl.$elem.find('tbody tr').each((i, tr) => {
			$(tr).toggleClass('DataTable__row--active', $(tr).index() == index);
		});
	}

	@onBlur('tbody td :input')
	onFieldBlur(e, ctrl) {
		ctrl.$elem.find('tbody tr').removeClass('DataTable__row--active');
	}

	getColumns() {
		return this.DataTable.columns();
	}

	getColumn(col) {
		if (
			isString(col) &&
			!includes(col, ':')
		) {
			col += ':name';
		}

		return this.DataTable.column(col);
	}

	getRowDataFromElem(elem) {
		var $tr = $(elem).closest('tr'),
			row = this.DataTable.row($tr);

		return row.data();
	}

	adjustColumns() {
		this.DataTable.columns.adjust();
		this.draw();
		return this;
	}

	enableSort() {
		this.resetSort();
		this.$elem.removeClass('DataTable--no-sort');

		return this;
	}

	disableSort() {
		let sort = this.getColumns().toArray()[0].map(i => [i, 'asc']);

		this.DataTable.order.fixed({
			pre: sort,
			post: sort
		});

		this.draw();

		this.$elem.addClass('DataTable--no-sort');

		return this;
	}

	resetSort() {
		this.DataTable.order.fixed({});
		this.DataTable.order(_defaultOrder.get(this));
		this.draw();

		return this;
	}

	toggleProcessing(state) {
		var processingClass = 'DataTable--processing',
			oldState = this.$elem.hasClass(processingClass),
			newState;

		this.$elem.toggleClass(processingClass, state);

		newState = this.$elem.hasClass(processingClass);

		if (newState !== oldState) {
			this.$elem.trigger(`DataTable:process${ newState ? 'ing' : 'ed' }`);
		}
	}

	draw() {
		this.DataTable.draw();
		return this;
	}

	reloadData(url, cb, resetPaging) {
		this.DataTable.ajax.url(url || this.DataTable.ajax.url()).load(cb, resetPaging);
		return this;
	}

	order() {
		if (arguments.length < 1) {
			return this.DataTable.order().map(order => this.config.columns[order[0]].name + ':' + order[1]);
		}

		this.DataTable.order(...arguments);
	}
}
