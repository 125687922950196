import { classNameFromObject } from '@utils/dom';
import { Icon, IconV2 } from '@bamboohr/fabric';
import Tooltip from 'tooltip.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getTooltipText, getNoteIcon } from './utils';
import './styles.styl';


export default function(props) {
	const { customCSS, disabled, hasNote, onNoteIconClick } = props;
	const noteIconWrapperClassesObj = {
		'TTnoteIconWrapper': true
	};
	const noteIconWrapperClasses = classNameFromObject({...noteIconWrapperClassesObj, ...customCSS});
	const noteIconClasses = classNameFromObject({
		'TTnoteIcon': true,
		'TTnoteIcon--add': !hasNote,
		'TTnoteIcon--hasNote': hasNote,
		'TTnoteIcon--disabled': disabled
	});
	const noteTooltipText = getTooltipText(hasNote);
	// @startCleanup encore
	const noteIconName = getNoteIcon(hasNote);
	// @endCleanup encore
	const noteIconClick = disabled ? null : onNoteIconClick;

	return (
		<div className={ noteIconWrapperClasses } onClick={ noteIconClick }>
			<Tooltip settings={ {content: noteTooltipText, suppressed: disabled} }>
				<div className={ noteIconClasses }>
					{ifFeature('encore', <IconV2 name={ hasNote ? 'memo-solid' : 'file-plus-regular' } size={20} />, <Icon name={ noteIconName } />)}
				</div>
			</Tooltip>
		</div>
	);
}
