import { FunctionComponent } from 'react';
import { getSelectedValue, handleControlledInput, getField, Fields } from 'dynamic-form';

const GenderIdentity: FunctionComponent<DynamicForm.SelectElementProps> = (selectProps) => {
	const { props, settings, context, children } = selectProps;
	const {
		id,
		items,
	} = props;

	const preferNotToSayId = props['data-prefer-not-to-say-id'];
	const customGenderIdentityId = props['data-custom-gender-identity-id'];

	const genderField = getField(context, Fields.genderIdentity) as DynamicForm.SelectElement;
	const selectedValue = getSelectedValue(genderField) as string | undefined;
	const isCustomGenderIdentitySelected = selectedValue === customGenderIdentityId;

	const {
		controls: { SelectField },
	} = context;

	const itemsWithAnchor = [
		{
			items: items.filter(item => item.value !== preferNotToSayId && item.value !== customGenderIdentityId),
			type: 'group',
			value: 1,
		},
		{
			anchor: 'bottom',
			items: items.filter(item => {
				if (item.value === preferNotToSayId || item.value === customGenderIdentityId) {
					item.exclusive = true;
					return item;
				}
			}),
			type: 'group',
			value: 2,
		}
	];

	const selectChildProps: typeof props = {
		...props,
		canSelectMultiple: true,
		onChange: (items) => {
			return handleControlledInput('select', id, items, context);
		},
		// @ts-ignore
		items: itemsWithAnchor,
	};

	return (
		<>
			<SelectField
				context={ context }
				props={ selectChildProps }
				settings={ settings }
			/>
			{
				isCustomGenderIdentitySelected && (
					children
				)
			}
		</>
	);
};

export default GenderIdentity;
