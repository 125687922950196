import Ajax from '@utils/ajax';


/**
 * @param   data        shape: {showWeekends: false}
 * @returns {Promise}
 */
export function updateViewOption(data) {
	return Ajax.post('/timesheet/options/save', data);
}

/**
 * @param   data        shape: {hours: [{date: '2017-10-13', hours: 7.5, employeeId: 40340}]}
 * @returns {Promise}
 */
export function saveSingleDailyHours(data) {
	return Ajax.post('/timesheet/hours', JSON.stringify(data), {
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		}
	});
}

/**
 * @param   {string}    timesheetId
 * @returns {Promise}
 */
export function refreshTimesheet(timesheetId) {
	return Ajax.get(`/timesheet/${ timesheetId }`);
}

/**
 * @param   data        shape: {id: 423, hoursLastChangedAt: 1530280372}
 * @returns {Promise}
 */
export function approveTimesheet(data) {
	return Ajax.post('/timesheet/approve', data);
}

/**
 * @returns {Promise}
 */
export function clockIn(employeeId, payload) {
	return Ajax.post(`/timesheet/clock/in/${ employeeId }`, payload);
}

/**
 * @returns {Promise}
 */
export function clockOut(employeeId) {
	return Ajax.post(`/timesheet/clock/out/${ employeeId }`);
}

/**
 * @param   type        'clock' or 'hour'
 * @param   data        shape: {entries: [{id: 78, trackingId: 78, employeeId: 40340, date: '2017-11-17', start: '11:30', end: '14:45'}]}
 * @returns {Promise}
 */
export function addEdit(type, data) {
	return Ajax.post(`/timesheet/${ type }/entries`, JSON.stringify(data), {
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		}
	});
}

/**
 * @param   data        shape: list of ids, e.g. {entries: [91]}
 * @returns {Promise}
 */
export function deleteEntry(type, data) {
	return Ajax.delete(`/timesheet/${ type }/entries`, JSON.stringify(data), {
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		}
	});
}

/**
 * @param   {string}    employeeId
 * @returns {Promise}
 */
export function getClockStatus(employeeId) {
	return Ajax.get(`/timesheet/employee/${ employeeId }/is_clocked_in`);
}

/**
 * @param   {string}    employeeId
 * @param   data        shape: {start: 2019-11-04, note: 'My note', timezone: 'America/Denver'}
 * @returns {Promise}
 */
export function saveClockInData(employeeId, data) {
	return Ajax.post(`/timesheet/employee/${ employeeId }/clock/in/data`, data);
}

export function requestTimeTrackingSubscription() {
	return Ajax.post('/settings/time_tracking/subscribe');
}

export function cancelTimeTrackingTrial(data) {
	return Ajax.post('/settings/time_tracking/cancelTrial', data);
}

export function postNewActivationTrialValues(data) {
	return Ajax.post(`/settings/time_tracking/startTrial`, data);
}

export function setTrialStarted() {
	return Ajax.post('/settings/time_tracking/trialStarted');
}
