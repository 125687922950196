(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([], factory);
	} else if (typeof exports === 'object') {
		module.exports = factory();
	} else {
		root.returnExports = factory();
	}
}(this, function () {

	var _tmplCache = {};

	function compiler(str) {
		var func = _tmplCache[str];

		if (!func) {
			/*
			 General idea is to build a series of p.push('String',expr,'String') calls interspersed with any
			 !* if() *! type of logic and then do a p.join('') at the end to produce the template output.
			 To prep the template, each 'String' needs to be escaped as a javascript string.
			 */
			var strFunc =
				"var p=[],print=function(){p.push.apply(p,arguments);};" +
				"with(obj){p.push('" +

				str.replace(/[\r\t\n]/g, " ")
				// These 3 lines add a \ in front of any \ that is outside of a !* *! block
					.replace(/\\(?=[^\*]*\*!)/g, "\t")
					.split("\\").join("\\\\")
					.split("\t").join("\\")

				// These 3 lines add a \ in front of any ' that is outside of a !* *! block
					.replace(/'(?=[^\*]*\*!)/g, "\t")
					.split("'").join("\\'")
					.split("\t").join("'")

					.replace(/!\*=raw *(.+?);* *\*!/g, "',$1,'")
					.replace(/!\*=html *(.+?);* *\*!/g, "',window.BambooHR.Utils.sanitizeHtml($1),'")
					.replace(/!\*= *(.+?);* *\*!/g, "',window.microTemplate.encodeHtml($1),'")
					.split("!*").join("');")
					.split("*!").join("\np.push('")
				+ "');}return p.join('');";

			func = new Function("obj", "microTemplate", strFunc);
			_tmplCache[str] = func;
		}

		return func;
	}

	/**
	 * Summary:
	 * Client side template parser that uses !*= variable *! and !* code *! expressions.
	 *
	 * NOTE: chokes on single quotes in the document in some situations use &amp;rsquo; for literals in text and avoid
	 * any single quote attribute delimiters.
	 *
	 * @param template {string|function} can be a string template or pre-compiled template function
	 * @param data
	 * @returns string
	 */
	function microTemplate(template, data = {}) {
		if (typeof template === 'function') {
			return template(data, microTemplate);
		}

		return compiler(template)(data, microTemplate);
	}

	microTemplate.encodeHtml = function (input) {
		// Even though we're adding some more regex, this simple regex allows this function to be more efficient
		// on large data sets
		if (/[<>'"&]/.test(input)) {
			// This method is faster instead of creating text nodes
			return String(input)
				.replace(/&/g, '&amp;')
				.replace(/"/g, '&quot;')
				.replace(/'/g, '&#39;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;');
		}

		return input;
	};

	microTemplate.decodeHtml = function (input) {
		var div = document.createElement('div');
		div.innerHTML = String(input);
		var child = div.childNodes[0];
		return child ? child.nodeValue : '';
	};

	microTemplate.escapeQuotes = function (input) {
		return String(input)
			.replace(/[\\"']/g, '\\$&')
			.replace(/\u0000/g, '\\0');
	};

	microTemplate.compiler = compiler;

	return microTemplate;
}));
