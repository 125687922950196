import { Icon, IconV2 } from '@bamboohr/fabric';
import classNames from 'classnames';
import { ifFeature } from "@utils/feature";

import './tab-nav.styl';

export function TabNav(props) {
	const { activeIndex, tabs } = props;

	return (
		<div className="TabNav">
			{ tabs.map((tab, index) => (
				<a
					className={ classNames('TabNav__item', { 'TabNav__item--active': activeIndex === index }) }
					data-bi-id={ `employees-${ tab.key }` }
					href={ tab.url }
					key={ tab.key }
				>
					{ tab.iconName && (
						<span className={ classNames('TabNav__itemIcon', { 'TabNav__item--active': activeIndex === index }) }>
							{ifFeature('encore',
								<IconV2 name={tab.iconName} size={20} />,
								<Icon name={ tab.iconName } />)
							}
						</span>
					) }
					<span className="TabNav__itemLabel">{ tab.label }</span>
				</a>
			)) }
		</div>
	);
}
