import {
	Component,
} from 'react';
import { render } from 'base/wrapped-render';
import {
	GlobalModal,
} from './global-modal.react';

class GlobalModalErrorBoundary extends Component {
	componentDidCatch(error, info) {
		Rollbar.error('Global Modal', {error, info});
	}

	render() {
		return (
			<GlobalModal { ...this.props } />
		);
	}
}

export function initGlobalModal() {
	const element = document.createElement('div');
	render(<GlobalModalErrorBoundary />, element);
}

export function initLockModal() {
	const element = document.createElement('div');
	render(<GlobalModalErrorBoundary namespace="LockModal"/>, element);
}
