/* eslint-disable no-undef */ // webpack knows how to handle and expects the reference without a declarative keyword

/**
 * Sets the Webpack public path dynamically and must be imported
 *
 * The following warning is present on the Webpack public path documentation:
 *
 * Warning
 * Be aware that if you use ES6 module imports in your entry file the __webpack_public_path__ assignment will be done after the imports.
 * In such cases, you'll have to move the public path assignment to its own dedicated module and then import it on top of your entry.js:
 * This file is imported into multiple entry files which allows for this to be moved directly into common file (e.g. common.dist.js).
 */

__webpack_public_path__ = window.__staticResourceUrlFeMain || '/js/';

export const manifestHostPath = __webpack_public_path__;

export const resourceHostPath = removeVersionFromManifestHostPath(manifestHostPath);

export function removeVersionFromManifestHostPath(path) {
	if (!path) {
		return '';
	}
	if (path === '/js/') {
		return path;
	}

	if (path.includes('fe-main')) {
		path = path.replace(/fe-main\/[^/]+\/js\/?$/, 'fe-main');
	}
	return path.replace(/\/js\/?$/, '');
}
