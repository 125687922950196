import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import ItemCreator from '../../containers/item-creator';
import creator from '../../hocs/creator';

import './styles.styl';

const ReplyCreator = ({ ...props }) => {
	let dom = null;
	if (props.editing) {
		dom = (
			<ItemCreator
				{...props}
				focusOnMount={true}
				onError={props.handleError}
				onReset={props.cancelEditing}
				onSubmit={props.beginSaving}
				onSuccess={props.handleSuccess}
				placeholder={props.config.replyPlaceholder}
			/>
		);
	} else {
		dom = (
			<button
				className='fab-TextButton fab-TextButton--teenie fab-link FeedReplyCreator__button'
				onClick={props.beginEditing}
				type='button'
			>
				<span className='fab-TextButton__icon FeedReplyCreator__buttonIcon'>
					{ifFeature(
						'encore',
						<IconV2 color='primary-medium' name='reply-regular' size={16} />,
						<Icon name='fab-comment-bubble-plus-13x14' />
					)}
				</span>
				{$.__('Reply')}
			</button>
		);
	}

	return <div className='FeedReplyCreator'>{dom}</div>;
};

export default creator(ReplyCreator);
