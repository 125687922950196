import ajax from '@utils/ajax';
import { hasTrax } from 'BambooHR.util';
import {
	currentEditingEmployeeId,
	getField,
	Fields,
	validateWithBE,
} from 'dynamic-form';
import { RATE_LIMIT_ERROR } from './common';

const message = $.__('SSN is not valid for the Selected Pay Schedule.');

export const validatePaySchedule = (context: DynamicForm.Context, field: DynamicForm.DataProps, params: any) => {
	return async (value): Promise<boolean | string> => {
		const { validation: { trigger }, formData } = context;
		if (!value) {
			// don't make a BE request - field isn't filled in yet, let required or regex run
			return true;
		}

		// only continue with validation if trax
		if (!hasTrax()) {
			return true;
		}

		const data = {
			id: currentEditingEmployeeId(),
			payScheduleId: null,
			payScheduleListValueId: value,
			action: 'checkPayScheduleForEmployee',
			ssn: null,
		};

		const SSNField = getField(context, Fields.ssn);
		if (SSNField && SSNField.props && SSNField.props.value) {
			data.ssn = SSNField.props.value;
		}

		const { isValid, isRateLimited } = await validateWithBE(ajax.post, data);
		if (isRateLimited) { return RATE_LIMIT_ERROR; }

		if (!isValid) {
			// if this field is not valid, it is really SSN that is the problem - revalidate SSN if in form

			if (SSNField && SSNField.props && SSNField.props.name) {
				trigger(SSNField.props.name);
			}
		}


		return isValid || message;
	};
};
