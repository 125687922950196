import { FIELD_TYPES } from 'NHPTemplates.mod/Form/constants';

import {
	State,
} from './Definitions';

function parseFields(fieldsToHighlight: Record<FIELD_TYPES, boolean>) {
	const updatedHighlightedState = {};
	const keys = Object.keys(fieldsToHighlight);
	keys.forEach((key) => {
		if (fieldsToHighlight[key]) {
			updatedHighlightedState[key] = true;
		}
	});

	return updatedHighlightedState;
}

function updateErrorFields(state: State, errorsStates: Record<FIELD_TYPES, boolean>): State {
	const updatedState = { ...state };

	updatedState.errorFields = parseFields(errorsStates);

	return updatedState;
}

function updateHighlightedField(state: State, highlightedStates: Record<FIELD_TYPES, boolean>): State {
	const updatedState = { ...state };

	updatedState.infoHighlightFields = parseFields(highlightedStates);
	return updatedState;
}

function updateManager(state: State, managerId: number | string): State {
	const updatedState = { ...state };

	updatedState.selectedManagerId = parseInt(managerId as string);

	if (managerId) {
		delete updatedState.infoHighlightFields.manager;
		delete updatedState.errorFields.manager;
	}

	return updatedState;
}

function updateHireDate(state: State, hireDate: string): State {
	const updatedState = { ...state };
	updatedState.hireDate = hireDate;
	if (!hireDate || hireDate.length === 0) {
		updatedState.errorFields[FIELD_TYPES.HIRE_DATE] = true;
	}
	return updatedState;
}

export {
	updateErrorFields,
	updateHighlightedField,
	updateManager,
	updateHireDate,
};
