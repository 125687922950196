import { useState, useEffect } from 'react';

import config from 'micro-frontend.config';

import { getProductionUrl } from '../get-production-url';

// stringifies value to prepare it for local or session storage
const prepareValue = (value) => {
	if (!value) return value;
	if (typeof value === 'string') return value;

	return JSON.stringify(value);
};

// parses value when getting item from local or session storage
const extractValue = (value) => {
	if (value && typeof value === 'string') {
		return JSON.parse(value);
	}
	return value;
};

export const getPoHost = () => {
	const name = 'po';

	const key = `${name.toLowerCase()}_micro_frontend_resource`;

	const defaultValue = {
		url: getProductionUrl(name),
	};

	const defaultStoredValue = extractValue(window.localStorage.getItem(key));
	const sessionStoredValue = extractValue(window.sessionStorage.getItem(key));

	return (sessionStoredValue || defaultStoredValue || defaultValue).url;
};

const useResourceVersion = (key, { defaultValue }) => {
	const defaultStoredValue = extractValue(window.localStorage.getItem(key));
	const sessionStoredValue = extractValue(window.sessionStorage.getItem(key));

	const value = sessionStoredValue || defaultStoredValue || defaultValue;

	const [stateValue, setStateValue] = useState(value);

	const setValue = (value) => {
		if (value && value.sessionOnly) {
			window.sessionStorage.setItem(key, prepareValue(value));
			setStateValue(value);
		} else {
			window.localStorage.setItem(key, prepareValue(value));
			sessionStorage.removeItem(key);
			setStateValue(value);
		}
	};

	useEffect(() => {
		const listener = (event) => {
			if (key === event.key) {
				if (event.newValue) {
					setStateValue(extractValue(event.newValue));
				}
			}
		};
		window.addEventListener('storage', listener);
		return () => {
			if (listener) {
				window.removeEventListener('storage', listener);
			}
		};
	}, [setValue]);

	return [stateValue || defaultValue, setValue];
};

export const useResource = (name = '') => {
	const version = config.versions[name];

	const defaultValue = {
		name,
		type: 'committed',
		url: getProductionUrl(name),
		version,
		sessionOnly: false,
	};

	return useResourceVersion(`${name.toLowerCase()}_micro_frontend_resource`, { defaultValue });
};
