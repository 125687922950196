import { ifFeature } from '@bamboohr/utils/lib/feature/if-feature.js';
import baseConfig from './base.js';

const font_formats = ifFeature(
	'encore',
	//Encore Fonts
	'Inter="Inter",sans-serif;' +
		'Arial=arial,helvetica,sans-serif;' +
		'Book Antiqua=book antiqua,palatino;' +
		'Georgia=georgia,palatino;' +
		'Helvetica=helvetica;' +
		'Tahoma=tahoma,arial,helvetica,sans-serif;',
	//Jade Fonts
	'Lato="Lato",sans-serif;' +
		'Arial=arial,helvetica,sans-serif;' +
		'Book Antiqua=book antiqua,palatino;' +
		'Georgia=georgia,palatino;' +
		'Helvetica=helvetica;' +
		'Tahoma=tahoma,arial,helvetica,sans-serif;'
);

const config = {
	convert_urls: false,
	default_link_target: '_blank',
	fix_list_elements: true,
	font_formats,
	formats: {
		bold: { inline: 'span', styles: { fontWeight: '700' } },
	},
	inline_styles: true,
	keep_styles: true,
	link_assume_external_targets: true,
	link_title: false,
	mode: 'exact',
	paste_data_images: true,
	paste_retain_style_properties: 'color font-family font-size font-style font-weight text-decoration',
	relative_urls: false,
	remove_script_host: false,
	remove_trailing_brs: false,
	schema: 'html5',
	target_list: false,
	theme: 'modern',
	verify_html: true,
	valid_children: '-li[p]', // Libre Office does this: <li><p></p></li>
	valid_styles: {
		'*': 'color,font-family,font-size,font-style,font-weight,text-decoration',
		a: '',
		p: 'margin,margin-bottom,margin-top',
		ol: 'margin,margin-bottom,margin-top',
		ul: 'margin,margin-bottom,margin-top',
	},
	paste_preprocess: function (pl, o) {
		// remove ids from pasted code
		const stripped = jQuery('<div>' + o.content + '</div>');
		stripped.find('*').removeAttr('id');
		o.content = stripped.html();
	},
};

export default { ...baseConfig, ...config };
