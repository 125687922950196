import { extent, min, max } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { area, line } from 'd3-shape';
import { select } from 'd3-selection';
import { defaultsDeep } from 'lodash';

import BaseChart from '../base-chart';

import './simple-multi-line-area-chart.styl';

const CLASS_NAME = 'SimpleMultiLineAreaChart';
const DEFAULT_SETTINGS = {
	chartClass: '',
	container: {
		height: 180,
		width: 'auto',
		margin: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
	},
	yAxisMin: null,
	yAxisMax: null,
	pathStrokeWidth: 3,
};

export default class SimpleMultiLineAreaChart extends BaseChart {
	constructor(selector, settings = DEFAULT_SETTINGS, onMouseOver, onFocus) {
		super();

		this._selector = selector;
		this._CLASS_NAME = CLASS_NAME;
		this._settings = defaultsDeep({}, settings, DEFAULT_SETTINGS);
		this._onMouseOver = onMouseOver;
		this._onFocus = onFocus;

		this._validateRequirements();
		this._createRoot();
	}

	_minYDrawnValue = 0;

	_destroyPopover;

	_data;

	draw(data) {
		this._data = data;
		const { areas, xAxisExtraInfo } = data;

		this._areas = areas;
		this._xAxisExtraInfo = xAxisExtraInfo;

		const countOfXValues = areas[0].points.length;

		const allLikeYPoints = areas[0].points.map(point => point.y);
		const allDislikeYPoints = areas[1].points.map(point => point.y);

		const settings = this._settings;

		const minYPadded = settings.yAxisMin === null ? Math.floor(min(allDislikeYPoints)) : settings.yAxisMin;
		const maxYPadded = settings.yAxisMax === null ? Math.ceil(max(allLikeYPoints)) : settings.yAxisMax;

		this._minYDrawnValue = minYPadded;

		this._xScale = this._getXScale(countOfXValues);
		this._yScale = this._getYScale(minYPadded, maxYPadded);

		this._svg
			.style('overflow', 'visible');

		this._svg.select('defs').remove();
		this._defs = this._svg.append('defs');
		this._addLinearGradients();
		this._drawAreas();
	}

	_getXScale(countOfXValues) {
		const { container: { margin } } = this._settings;
		return scaleLinear()
			.domain([0, countOfXValues - 1])
			.range([margin.left, this._getContainerWidth() - margin.right]);
	}

	_getYScale(minY, maxY) {
		const greatest = Math.max(Math.abs(minY), maxY);
		const adjusted = greatest + greatest * 0.1;
		minY = adjusted * -1;
		maxY = adjusted;

		const { container: { height, margin } } = this._settings;
		return scaleLinear()
			.domain([minY, maxY]).nice()
			.range([height, margin.top]);
	}

	_addLinearGradients() {
		const likesGradient = this._defs.append('linearGradient')
			.attr('id', 'likesGradient')
			.attr('x1', '0%')
			.attr('x2', '0%')
			.attr('y1', '0%')
			.attr('y2', '100%');
		likesGradient.append('stop')
			.attr('offset', '0%')
			.attr('style', `stop-color: ${ this._settings.colors.likes.gradientStart }`);
		likesGradient.append('stop')
			.attr('offset', '100%')
			.attr('style', `stop-color: ${ this._settings.colors.likes.gradientEnd }`);
		const dislikesGradient = this._defs.append('linearGradient')
			.attr('id', 'dislikesGradient')
			.attr('x1', '0%')
			.attr('x2', '0%')
			.attr('y1', '100%')
			.attr('y2', '0%');
		dislikesGradient.append('stop')
			.attr('offset', '0%')
			.attr('style', `stop-color: ${ this._settings.colors.dislikes.gradientStart }`);
		dislikesGradient.append('stop')
			.attr('offset', '100%')
			.attr('style', `stop-color: ${ this._settings.colors.dislikes.gradientEnd }`);
	}

	_drawAreas() {
		this._drawAreaGroups();
		this._drawDivider();
		this._drawLineGroups();
		this._drawMouseOverAreas();
		this._addLastPointLabels();
	}

	_drawDivider() {
		const svg = this._svg;

		const dividerUpdate = svg
			.selectAll(this._getClassList('dividerLine'))
			.data([this._areas[0].points]);

		const dividerEnter = dividerUpdate
			.enter()
			.append('line')
			.attr('class', this._buildClassList('dividerLine'))
			.attr('y1', this._yScale(0))
			.attr('y2', this._yScale(0))
			.style('stroke', 'white')
			.style('stroke-width', 3);

		const dividerMerge = dividerUpdate.merge(dividerEnter);

		dividerMerge
			.attr('x1', d => this._xScale(0))
			.attr('x2', d => this._xScale(d.length - 1));

		dividerUpdate
			.exit()
			.remove();
	}

	_drawAreaGroups() {
		const svg = this._svg;

		const areaGroupUpdate = svg
			.selectAll(this._getClassList('__areaGroup'))
			.style('display', 'initial')
			.data(this._areas);

		const areaGroupEnter = areaGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__areaGroup') }`)
			.attr('id', (d, i) => {
				const { colors } = this._settings;
				const color = i === 0 ? colors.likes.area : colors.dislikes.area;
				return color ? `tag${ color.slice(1) }_${ i }` : '';
			});

		const areaGroupMerge = areaGroupUpdate.merge(areaGroupEnter);

		const areaElem = area()
			.x((d, i) => this._xScale(i))
			.y0(d => this._yScale(0))
			.y1(d => this._yScale(d.y));

		areaGroupEnter
			.append('path')
			.attr('class', `AreaChart--themeFill ${ this._buildClassList('__area') }`)
			.style('fill', (d, i) => {
				return i === 0 ? 'url(#likesGradient)' : 'url(#dislikesGradient)';
			});

		areaGroupMerge
			.select(this._getClassList('__area'))
			.attr('d', d => areaElem(d.points));

		areaGroupUpdate
			.exit()
			.remove();
	}

	_drawMouseOverAreas() {
		const svg = this._svg;
		const areaGroupUpdate = svg
			.selectAll(this._getClassList('__hoverAreaGroup'))
			.style('display', 'initial')
			.data(this._areas);
		const areaGroupEnter = areaGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__hoverAreaGroup') }`)
			.attr('id', (d, i) => {
				return `hover${ i }`;
			});

		const areaGroupMerge = areaGroupUpdate.merge(areaGroupEnter);

		const areaElem = area()
			.x(d => d.x)
			.y0(d => this._yScale(0))
			.y1(d => d.y);

		const pathsUpdate = areaGroupMerge
			.selectAll(this._getClassList('__hoverArea'))
			.data(d => d.points);

		const pathsEnter = pathsUpdate
			.enter()
			.append('path')
			.classed(this._buildClassList('__hoverArea'), true)
			.style('fill', 'transparent');

		const pathsMerge = pathsUpdate.merge(pathsEnter);

		pathsMerge
			.attr('d', (d, i) => {
				const currentArea = this._areas.find(a => a.points.includes(d));
				const xPoints = [];
				const scaledX0 = currentArea.points[i - 1] ?
					this._xScale(i - 1) :
					null;
				const scaledX1 = this._xScale(i);
				const scaledX2 = currentArea.points[i + 1] ?
					this._xScale(i + 1) :
					null;
				if (scaledX0 !== null) {
					xPoints.push((scaledX1 + scaledX0) / 2);
				}
				xPoints.push(scaledX1);
				if (scaledX2 !== null) {
					xPoints.push((scaledX2 + scaledX1) / 2);
				}
				const y1Points = [];
				const scaledY0 = currentArea.points[i - 1] ?
					this._yScale(currentArea.points[i - 1].y) :
					null;
				const scaledY1 = this._yScale(d.y);
				const scaledY2 = currentArea.points[i + 1] ?
					this._yScale(currentArea.points[i + 1].y) :
					null;
				if (scaledY0 !== null) {
					if (scaledY1 > scaledY0) {
						y1Points.push((scaledY1 + scaledY0) / 2);
					} else {
						y1Points.push((scaledY0 + scaledY1) / 2);
					}
				}
				y1Points.push(scaledY1);
				if (scaledY2 !== null) {
					if (scaledY2 > scaledY1) {
						y1Points.push((scaledY2 + scaledY1) / 2);
					} else {
						y1Points.push((scaledY1 + scaledY2) / 2);
					}
				}
				return areaElem(xPoints.map((x, i) => ({ x, y: y1Points[i] })));
			})
			.on('mouseover', (event) => {
				const currentArea = this._areas.find(a => a.points.includes(event));
				const isLikesArea = this._areas.indexOf(currentArea) === 0;

				const hoverPoint = this._addHoverPoint(event, currentArea.points.indexOf(event), isLikesArea);
				this._destroyPopover = this._onMouseOver({
					date: event.x,
					count: event.y,
					target: hoverPoint.node(),
					isPositive: isLikesArea,
				});
			})
			.on('mouseleave', (event) => {
				this._removeHoverPoint(event);
				this._destroyPopover();
			});

		pathsUpdate
			.exit()
			.remove();

		areaGroupUpdate
			.exit()
			.remove();
	}

	_addHoverPoint(event, index, isLikesArea) {
		return this._svg.append('circle')
			.style('pointer-events', 'none')
			.attr('id', 'hoverPoint')
			.attr('cx', this._xScale(index))
			.attr('cy', this._yScale(event.y))
			.attr('r', 4)
			.style('fill', isLikesArea ? this._settings.colors.likes.line : this._settings.colors.dislikes.line);
	}

	_removeHoverPoint() {
		this._svg
			.select('#hoverPoint')
			.remove('circle');
	}

	_getCurrentPeriod(points) {
		return points.find((point, i) => {
			return i === points.length - 1;
		});
	}

	_drawLineGroups() {
		const svg = this._svg;
		const settings = this._settings;

		const lineGroupUpdate = svg
			.selectAll(this._getClassList('__lineGroup'))
			.style('display', 'initial')
			.data(this._areas);

		const lineGroupEnter = lineGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__lineGroup') }`)
			.attr('id', (d, i) => {
				const { colors } = this._settings;
				const color = i === 0 ? colors.likes.line : colors.dislikes.line;
				return color ? `tag${ color.slice(1) }_${ i }` : '';
			});

		const lineGroupMerge = lineGroupUpdate.merge(lineGroupEnter);

		const lineElem = line()
			.x((d, i) => this._xScale(i))
			.y(d => this._yScale(d.y));

		lineGroupEnter
			.append('path')
			.attr('class', `AreaChart--themeStroke ${ this._buildClassList('__line') }`)
			.style('fill', 'none')
			.style('stroke', (d, i) => {
				const { colors } = this._settings;
				return i === 0 ? colors.likes.line : colors.dislikes.line;
			})
			.style('stroke-width', `${ settings.pathStrokeWidth }px`);

		lineGroupEnter
			.append('circle')
			.attr('class', `${ this._buildClassList('__circle0') }`)
			.attr('cx', d => this._xScale(0))
			.attr('cy', d => this._yScale(d.points[0].y))
			.attr('r', 4)
			.style('fill', (d, i) => {
				const { colors } = this._settings;
				return i === 0 ? colors.likes.line : colors.dislikes.line;
			});

		lineGroupEnter
			.append('circle')
			.attr('class', `${ this._buildClassList('__circle1') }`)
			.attr('cx', d => this._xScale(d.points.length - 1))
			.attr('cy', d => this._yScale(this._getCurrentPeriod(d.points).y))
			.attr('r', 6)
			.style('fill', (d, i) => {
				const { colors } = this._settings;
				return i === 0 ? colors.likes.line : colors.dislikes.line;
			});

		lineGroupEnter
			.append('circle')
			.attr('class', `${ this._buildClassList('__circle1--fill') }`)
			.attr('cx', d => this._xScale(d.points.length - 1))
			.attr('cy', d => this._yScale(this._getCurrentPeriod(d.points).y))
			.attr('r', 3)
			.style('fill', '#fff');

		lineGroupMerge
			.select(this._getClassList('__line'))
			.attr('d', d => lineElem(d.points));

		lineGroupMerge
			.select(this._getClassList('__circle0'))
			.attr('cx', d => this._xScale(0))
			.attr('cy', d => this._yScale(d.points[0].y));

		lineGroupMerge
			.select(this._getClassList('__circle1'))
			.attr('cx', d => this._xScale(d.points.length - 1))
			.attr('cy', d => this._yScale(this._getCurrentPeriod(d.points).y));

		lineGroupMerge
			.select(this._getClassList('__circle1--fill'))
			.attr('cx', d => this._xScale(d.points.length - 1))
			.attr('cy', d => this._yScale(this._getCurrentPeriod(d.points).y));

		lineGroupUpdate
			.exit()
			.remove();
	}

	_getAreaIndex(area) {
		return this._areas.indexOf(area);
	}

	_addLastPointLabels() {
		const svg = this._svg;
		const { colors } = this._settings;

		const floatingLabelUpdate = svg
			.selectAll(this._getClassList('__floatingLabel'))
			.data(this._areas);

		const floatingLabelEnter = floatingLabelUpdate
			.enter()
			.append('text')
			.attr('class', `${ this._buildClassList('__floatingLabel') }`)
			.style('font-size', '13px')
			.style('line-height', '16px')
			.style('text-anchor', 'middle');

		const floatingLabelMerge = floatingLabelUpdate.merge(floatingLabelEnter);

		floatingLabelMerge
			.text((d, i) => this._getCurrentPeriod(d.points).y * (i === 0 ? 1 : -1))
			.style('fill', (d, i) => { return i === 0 ? colors.likes.floatingLabel : colors.dislikes.floatingLabel; })
			.attr('x', d => this._xScale(d.points.length - 1))
			.attr('y', (d, i) => this._yScale(this._getCurrentPeriod(d.points).y) + (i === 0 ? -11 : 22));

		floatingLabelUpdate
			.exit()
			.remove();
	}

	_resetTimeValues(date) {
		const dateObj = new Date(date);
		return dateObj.setHours(0, 0, 0, 0);
	}

	resize() {
		select(this._selector).select('div').remove();
		this._createRoot();
		this.draw(this._data);
	}

	destroy() {
		select(this._selector).select('div').remove();
	}
}
