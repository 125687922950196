import {cancelableRequestObject} from 'Cancelable.mod';

const BASE_URL = '/calendar';


export function getCompanyTimeOff(rangeStart, rangeEnd) {
	const $request = $.get(`${ BASE_URL }/time_off/${ rangeStart }/${ rangeEnd }`);
	return cancelableRequestObject($.when($request), $request);
}

export function getCompanyHolidays(rangeStart, rangeEnd) {
	const $request = $.get(`${ BASE_URL }/holidays/${ rangeStart }/${ rangeEnd }`);
	return cancelableRequestObject($.when($request), $request);
}

export function getCompanyBirthdays(rangeStart, rangeEnd) {
	const $request = $.get(`${ BASE_URL }/birthdays/${ rangeStart }/${ rangeEnd }`);
	return cancelableRequestObject($.when($request), $request);
}

export function getCompanyAnniversaries(rangeStart, rangeEnd) {
	const $request = $.get(`${ BASE_URL }/anniversaries/${ rangeStart }/${ rangeEnd }`);
	return cancelableRequestObject($.when($request), $request);
}

export function getTimeOffRequestBalanceDetails(timeOffRequestId) {
	return $.get(`/time_off/requests/${timeOffRequestId}/balance_details`);
}

export function postEmployeeFilters(filterKey, postVars = {}) {
	const body = {option: filterKey, ...postVars};
	const $request = $.post(`${ BASE_URL }/employee/filter`, body);
	return cancelableRequestObject($.when($request), $request);
}

export function postPeriod(period) {
	const $request = $.post(`${ BASE_URL }/period`, {period});
	return cancelableRequestObject($.when($request), $request);
}

export function postFilters(filters = []) {
	const body = filters.reduce((acc, f) => ({...acc, [f.filterKey]: f.selected}), {});
	const $request = $.post(`${ BASE_URL }/menu/filters`, body);
	return cancelableRequestObject($.when($request), $request);
}

export function postApproveOrDenyRequest(id, action) {
	const body = {id, action, name: 'time_off_request', ajax: true};
	return $.post('/inbox/details.php', body);
}

export default {
	getCompanyTimeOff,
	getCompanyHolidays,
	getCompanyBirthdays,
	getCompanyAnniversaries,
	getTimeOffRequestBalanceDetails,
	postEmployeeFilters,
	postPeriod,
	postFilters,
	postApproveOrDenyRequest,
}
