import { useEffect, useState } from 'react';
import { CancellationContentProps } from '../../types';
import { ModalContentWrapper } from '../modal-content-wrapper';
import { SelectField, ItemType, SelectChangeArgs, StandardModal, Badge } from '@bamboohr/fabric';
import { useStyles } from './styles';
import { Services } from 'in-app-cancellation.mod';

export const JobOpeningsCancellationForm = ({
	nextBillingDates,
	onNext,
	setIsLoading,
	...rest
}: CancellationContentProps): JSX.Element => {
	const classes = useStyles();

	const [selectedDateValue, setSelectedDateValue] = useState<ItemType['value']>(nextBillingDates[1]?.value);
	const [selectedAmountValue, setSelectedAmountValue] = useState<ItemType['value']>();
	const [extraJobOpenings, setExtraJobOpenings] = useState([]);
	const [totalJobOpenings, setTotalJobOpenings] = useState(0);
	const [unusedJobOpenings, setUnusedJobOpenings] = useState(0);

	const defaultJobOpeningsNote = [
		{
			isDisabled: true,
			text: $.__('Job Openings must be cancelled in groups of 10.'),
			value: 'note',
		},
	];

	const _onChangeDate = (e: SelectChangeArgs): void => {
		setSelectedDateValue(e.target.value as unknown[]);
	};

	const _onChangeAmount = (e: SelectChangeArgs): void => {
		setSelectedAmountValue(e.target.value as unknown[]);
	};

	const handleOnNext = () => {
		onNext({
			cancellation_date: selectedDateValue,
			job_opening_data: {
				number_to_cancel: selectedAmountValue,
			},
		});
	};

	useEffect(() => {
		setIsLoading(true);
		Services.getExtraJobOpenings()
			.then((resp) => {
				setTotalJobOpenings(resp.data.extraJobPostings + 25); // 25 is the default amount before buying extra. customers will always have these 25
				const extras = Math.round(resp.data.extraJobPostings / 10);
				const extrasItems = [];
				for (let i = 1; i <= extras; i++) {
					extrasItems.push({
						text: i * 10,
						value: i * 10,
					});
				}
				setExtraJobOpenings(extrasItems);
			})
			.catch(() => {
				window.setMessage($.__('Oops! Looks like we had some trouble loading the form. Refresh the page and try again!'), 'error');
			})
			.finally(() => {
				setIsLoading(false);
			});

		Services.getRemainingJobOpenings()
			.then((resp) => {
				setUnusedJobOpenings(resp.data.remainingJobOpenings);
			})
			.catch(() => {
				window.setMessage($.__('Oops! Looks like we had some trouble loading the form. Refresh the page and try again!'), 'error');
			});
	}, []);

	const disableNextButton = !selectedDateValue || !selectedAmountValue;

	return (
		<ModalContentWrapper {...rest} disablePrimaryButton={disableNextButton} onNext={handleOnNext}>
			<StandardModal.UpperContent>
				<Badge
					iconName='fab-ats-38x46'
					iconColor='#555555'
					imageHeight='auto'
					imageWidth='auto'
					title={$.__('You currently have %s total job openings', totalJobOpenings)}
					TitleProps={{ color: 'textPrimary', variant: 'h5' }}
				>
					<p className={classes.subText}>{$.__('%s extra are not being used', unusedJobOpenings)}</p>
				</Badge>
			</StandardModal.UpperContent>
			<div className={classes.form}>
				<p className={classes.contentText}>
					{$.__(
						'We hope you found the candidates you were looking for! Please fill out the information below to cancel your job openings.'
					)}
				</p>
				<SelectField
					className={classes.selectField}
					items={nextBillingDates}
					label={$.__('Desired Cancellation Date')}
					onChange={_onChangeDate}
					width={6}
					placeholder={'mm/dd/yyyy'}
					id={'job-openings-cancellation-date'}
					required
					value={selectedDateValue}
				/>
				<SelectField
					className={classes.selectField}
					items={[...defaultJobOpeningsNote, ...extraJobOpenings]}
					label={$.__('Number of Job Openings to Cancel')}
					onChange={_onChangeAmount}
					width={6}
					id={'job-openings-number-to-cancel'}
					required
					value={selectedAmountValue}
				/>
			</div>
		</ModalContentWrapper>
	);
};
