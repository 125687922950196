const modalDirectory = {
	terminateEmployee: {
		url: '/modals/terminate-employee',
		title: 'Terminate Employee'
	},
	rehireEmployee: {
		url: '/modals/rehire-employee',
		title: 'Rehire Employee'
	},
	addEmploymentStatus: {
		url: '/modals/employment-status/add',
		title: 'Employment Status - Add'
	},
	editEmploymentStatus: {
		url: '/modals/employment-status/edit',
		title: 'Employment Status - Edit'
	},
	deleteEmploymentStatus: {
		url: '/modals/employment-status/delete',
		title: 'Employment Status - Delete'
	},
	addCompensation: {
		url: '/modals/compensation/add',
		title: 'Compensation - Add'
	},
	editCompensation: {
		url: '/modals/compensation/edit',
		title: 'Compensation - Edit'
	},
	deleteCompensation: {
		url: '/modals/compensation/delete',
		title: 'Compensation - Delete'
	},
	addJobInformation: {
		url: '/modals/job-information/add',
		title: 'Job Information - Add'
	},
	editJobInformation: {
		url: '/modals/job-information/edit',
		title: 'Job Information - Edit'
	},
	deleteJobInformation: {
		url: '/modals/job-information/delete',
		title: 'Job Information - Delete'
	},
	addBonus: {
		url: '/modals/bonus/add',
		title: 'Bonus - Add'
	},
	editBonus: {
		url: '/modals/bonus/edit',
		title: 'Bonus - Edit'
	},
	deleteBonus: {
		url: '/modals/bonus/delete',
		title: 'Bonus - Delete'
	},
	addCommission: {
		url: '/modals/commission/add',
		title: 'Commission - Add'
	},
	editCommission: {
		url: '/modals/commission/edit',
		title: 'Commission - Edit'
	},
	deleteCommission: {
		url: '/modals/commission/delete',
		title: 'Commission - Delete'
	},
	addDependent: {
		url: '/modals/dependent/add',
		title: 'Dependent - Add'
	},
	editDependent: {
		url: '/modals/dependent/edit',
		title: 'Dependent - Edit'
	},
	deleteDependent: {
		url: '/modals/dependent/delete',
		title: 'Dependent - Delete'
	},
	addBenefitClass: {
		url: '/modals/benefit-class/add',
		title: 'Benefit Class - Add'
	},
	editBenefitClass: {
		url: '/modals/benefit-class/edit',
		title: 'Benefit Class - Edit'
	},
	deleteBenefitClass: {
		url: '/modals/benefit-class/delete',
		title: 'Benefit Class - Delete'
	},
	addEarnings: {
		url: '/modals/earnings/add',
		title: 'Earnings - Add'
	},
	editEarnings: {
		url: '/modals/earnings/edit',
		title: 'Earnings - Edit'
	},
	deleteEarnings: {
		url: '/modals/earnings/delete',
		title: 'Earnings - Delete'
	},
	addVisa: {
		url: '/modals/visa/add',
		title: 'Visa - Add'
	},
	editVisa: {
		url: '/modals/visa/edit',
		title: 'Visa - Edit'
	},
	deleteVisa: {
		url: '/modals/visa/delete',
		title: 'Visa - Delete'
	},
	addCard: {
		url: '/modals/credit-card/add',
		title: 'Credit Card - Add'
	},
	editCard: {
		url: '/modals/credit-card/edit',
		title: 'Credit Card - Edit'
	},
	deleteCard: {
		url: '/modals/credit-card/delete',
		title: 'Credit Card - Delete'
	},
	addPassport: {
		url: '/modals/passport/add',
		title: 'Passport - Add'
	},
	editPassport: {
		url: '/modals/passport/edit',
		title: 'Passport - Edit'
	},
	deletePassport: {
		url: '/modals/passport/delete',
		title: 'Passport - Delete'
	},
	addDriverLicense: {
		url: '/modals/driver-license/add',
		title: 'Driver License - Add'
	},
	editDriverLicense: {
		url: '/modals/driver-license/edit',
		title: 'Driver License - Edit'
	},
	deleteDriverLicense: {
		url: '/modals/driver-license/delete',
		title: 'Driver License - Delete'
	},
	addAsset: {
		url: '/modals/asset/add',
		title: 'Assets - Add'
	},
	editAsset: {
		url: '/modals/asset/edit',
		title: 'Assets - Edit'
	},
	deleteAsset: {
		url: '/modals/asset/delete',
		title: 'Assets - Delete'
	},
	addStockOption: {
		url: '/modals/stock-option/add',
		title: 'Stock Options - Add'
	},
	editStockOption: {
		url: '/modals/stock-option/edit',
		title: 'Stock Options - Edit'
	},
	deleteStockOption: {
		url: '/modals/stock-option/delete',
		title: 'Stock Options - Delete'
	},
	addCertification: {
		url: '/modals/certification/add',
		title: 'Certification - Add'
	},
	editCertification: {
		url: '/modals/certification/edit',
		title: 'Certification - Edit'
	},
	deleteCertification: {
		url: '/modals/certification/delete',
		title: 'Certification - Delete'
	}
};

function sendToAnalytics(obj) {
	let gtmDataLater = window.dataLayer;

	if (!gtmDataLater) {
		console.error('The virtual page viewer does not have access to the Google Tag Manager dataLayer variable.');
		return false;
	}

	gtmDataLater.push({
		event: 'Virtual Pageview',
		virtualPageURL: `/virtual${ obj.url }`.replace(/\/{2,}/, '/'),
		virtualPageTitle: obj.title
	});
}

function modal(type) {
	let pageData = modalDirectory[type];

	if (!pageData) {
		console.error(`No virtual modal page view with a type of ${ type } was found.`);
		return false;
	}

	sendToAnalytics(pageData);
}

let VirtualPageViewer = {
	modal: modal
};

export default VirtualPageViewer;
