import Ajax from '@utils/ajax';
import { DateTime } from 'luxon';

import { DpaStatus, DPA_UPDATE_POSTPONED_ON } from './constants';

export function acceptDataProcessingAgreement(id, options) {
	return updateDataProcessingAgreement(id, { status: DpaStatus.SIGNED }, options);
}

export function completeDataProcessingAgreementLater(id, options) {
	return updateDataProcessingAgreement(id, { status: DpaStatus.COMPLETE_LATER }, options);
}

export function declineDataProcessingAgreement(id, options) {
	return updateDataProcessingAgreement(id, { status: DpaStatus.DECLINED }, options);
}

export function getLatestDataProcessingAgreement() {
	return getDataProcessingAgreements().then((agreements = []) => {
		return agreements[0];
	});
}

export function getDataProcessingAgreements() {
	return Ajax.get('/data_processing_agreements', { latest: true }).then(({ data = [] } = {}) => data);
}

export function updateDataProcessingAgreement(id, data, { setSessionMessage = false } = {}) {
	return Ajax.put(`/data_processing_agreements/${id}?setSessionMessage=${setSessionMessage ? 'true' : 'false'}`, data);
}

export function postponeUpdateDataProcessingAgreement(id, options) {
	localStorage.setItem(DPA_UPDATE_POSTPONED_ON, DateTime.local().toISO());
	return updateDataProcessingAgreement(id, { status: DpaStatus.UPDATE_PENDING }, options);
}
