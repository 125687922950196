import { FunctionComponent } from 'react';

const HoverAction: FunctionComponent<DynamicForm.Hover> = ({
	hover,
	isEditable,
	context,
	children,
}) => {
	const {
		controls: { HoverBase },
	} = context;

	return (
		<HoverBase hoverChild={ children } isEditable={ isEditable }>
			<button
				className="HoverHint__link fab-TextButton fab-link"
				onClick={ hover.handleHoverAction }
				type="button"
			>
				{ hover.text }
			</button>
		</HoverBase>
	);
};

export default HoverAction;
