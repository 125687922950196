import {
	BodyText,
	Card,
	CardContent,
	CardSize,
	Divider,
	Flex,
	Headline,
	Icon,
	LayoutBox,
	Section,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { Fragment, ReactElement } from 'react';
import { EmployeeInfo } from '../EmployeeInfo';
import { styles } from './styles';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Team } from 'NHPTemplates.mod/Previewer/Components/Finished/teamInterface';

interface Props {
	employeeName: string;
	companyName: string;
	children: Array<ReactElement>;
	team?: Team;
	isNHPTemplate?: boolean;
}

export function Finished(props: Props): ReactElement {
	const {
		employeeName,
		companyName,
		children,
		team,
		isNHPTemplate = false,
	} = props;

	const { manager, members = [] } = team;

	const classes = styles();
	const homeUrl = `${window.location.protocol}//${window.location.hostname}`;

	return ifFeature(
		'encore',
		<Fragment>
			<Section>
				<Flex flexDirection={'column'} height={'100%'}>
					<Headline size="medium">
						{$.__(`%1$s, You're All Ready To Go.`, employeeName)}
					</Headline>
					<BodyText size="extra-small">
						{$.__(
							`We're looking forward to having you here at %1$s.`,
							companyName,
						)}
					</BodyText>
					<Divider color="neutral-extra-weak" marginTop={'17px'} />
				</Flex>
				<LayoutBox marginBottom={4} marginTop={4}>{children}</LayoutBox>
				{(members?.length > 0 || manager) && (
					<LayoutBox>
						<Divider color="neutral-extra-weak" />
						<LayoutBox margin={'32px 0px 20px'}>
							<Headline size="small">{$._('Get to Know the Team')}</Headline>
						</LayoutBox>
						{manager && (
							<LayoutBox marginBottom={'15px'}>
								<LayoutBox marginBottom={'20px'}>
									<Headline size="extra-small" color="neutral-strong">
										{$._('Your Manager')}
									</Headline>
								</LayoutBox>
								<EmployeeInfo employee={manager} avatarSize={32} />
							</LayoutBox>
						)}
						{members && members.length > 0 && (
							<>
								<LayoutBox marginBottom={'15px'}>
									<Headline size="extra-small" color="neutral-strong">
										{$._('Your Team')}
									</Headline>
								</LayoutBox>
								<Flex gap={5} flexWrap={'wrap'}>
									{members.map((employee) => (
										<EmployeeInfo
											employee={employee}
											key={`${employee.firstName}_${employee.lastName}`}
											avatarSize={32}
										/>
									))}
								</Flex>
							</>
						)}
					</LayoutBox>
				)}
			</Section>
		</Fragment>,
		<>
			<Card hasBottomGradient={false} size={CardSize.FULL}>
				<CardContent>
					<div className={classes.finishedPage}>
						<div>
							<h1 className={classes.headerTitle}>
								<Message
									params={[employeeName]}
									text={$._(`{1}, You're All Ready To Go.`)}
								/>
							</h1>
							<p className={classes.headerDesc}>
								<Message
									params={[companyName]}
									text={$._(`We're looking forward to having you here at {1}.`)}
								/>
							</p>
							<hr className={classes.headerDivider} />
						</div>
						{children}
						{(members?.length > 0 || manager) && (
							<div>
								<hr className={classes.divider} />
								<h2>{$._('Get to Know the Team')}</h2>
								{manager && (
									<>
										<h4>Your Manager</h4>
										<EmployeeInfo employee={manager} />
									</>
								)}
								{members && members.length > 0 && (
									<div className={classes.membersWrapper}>
										<h4>Your Team</h4>
										<div className={classes.members}>
											{members.map((employee) => (
												<EmployeeInfo
													employee={employee}
													key={`${employee.firstName}_${employee.lastName}`}
												/>
											))}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</CardContent>
			</Card>
			{!isNHPTemplate && (
				<div className={classes.footer}>
					<div className={classes.bamboohrLogo}>
						<Icon name="fab-bamboohr-logo-255x38" />
					</div>
					<h3>
						<Message params={[companyName]} text={$.__(`{1} uses BambooHR`)} />
					</h3>
					<p className={classes.footerText}>
						<Message text={$.__(`You can access your company's HR site at:`)} />
					</p>
					<a href="#" rel="noopener noreferrer" target="_blank">
						{homeUrl}
					</a>
					<p className={classes.footerDisclaimer}>
						<Message text={$.__(`(You may want to bookmark this baby.)`)} />
					</p>
				</div>
			)}
		</>,
	);
}
