import { FunctionComponent, RefObject, useState } from 'react';
import { Icon, Popover, TextButton, IconButton, IconV2 } from '@bamboohr/fabric';
import { findChildren, getSelectedValue, getField, Fields } from 'dynamic-form';

import { getStyles } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const GenderSelect: FunctionComponent<DynamicForm.SelectElementProps> = ({ props, settings, context, children }) => {
	const { disabled, id } = props;
	const { fieldBoxClasses = '' } = settings;
	const {
		controls: { FieldBox, SelectField },
		formData,
	} = context;

	const isFieldVisible = !fieldBoxClasses.includes('hidden');

	const genderField = getField(context, Fields.gender) as DynamicForm.SelectElement;
	const selectedValue = getSelectedValue(genderField) as string | undefined;

	const childFieldKeys = Object.keys(findChildren(context, id, { selector: 'key', value: 'field' }) || []);
	const childFieldValues = childFieldKeys
		.map((key) => {
			const field = formData[key];
			return getSelectedValue(field as DynamicForm.SelectElement) || '';
		})
		.join('');

	const isNonBinarySelected = selectedValue === 'Non-binary';
	// @ts-ignore
	const useMobileFriendlyIcon = (window.SESSION_USER && window.SESSION_USER.isOnboardingUser && window.isMobile()) as boolean;

	const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [showGenderInformation, setShowGenderInformation] = useState((!!childFieldValues || isNonBinarySelected) && isFieldVisible);

	const selectChildProps: typeof props = {
		...props,
		width: '5',
	};

	const useStyles = getStyles();
	const styles = useStyles();
	const hasChildren = !!(children as React.ReactNode[]).length;
	const inputWrapperClasses = hasChildren ? 'fieldRow' : 'fieldBox DynamicForm__fieldBox';

	let placement = 'top' as 'top' | 'top-end' | 'top-start';

	if (useMobileFriendlyIcon) {
		if (window.outerWidth <= 780) {
			placement = 'top-start';
		} else if (window.outerWidth > 780 && window.outerWidth < 1024) {
			placement = 'top-end';
		}
	}

	return (
		<div className={inputWrapperClasses}>
			<SelectField context={context} props={selectChildProps} settings={settings} />

			{(isNonBinarySelected || showGenderInformation) && isFieldVisible && children}

			{hasChildren && isFieldVisible && !disabled && (
				<>
					<FieldBox fieldBoxClasses={fieldBoxClasses}>
						<IconButton
							ariaLabel={$.__('Show expanded gender option details')}
							className={styles.infoIcon}
							floatingIcon={true}
							icon={ifFeature(
								'encore',
								'circle-question-solid',
								<Icon brand={true} name={useMobileFriendlyIcon ? 'fab-question-20x20' : 'fab-question-14x14'} />
							)}
							onClick={() => {
								setIsOpen(true);
							}}
							// @ts-ignore
							onMouseEnter={() => {
								setIsOpen(true);
							}}
							ref={setAnchorRef as unknown as RefObject<HTMLSpanElement>}
							type='button'
						/>
						<Popover
							anchorEl={anchorRef}
							disableOuterClickOnClose={useMobileFriendlyIcon}
							disablePortal={true}
							onClose={() => setIsOpen(false)}
							open={isOpen}
							placement={placement}
							title={$.__('Gender Information')}
						>
							<div className={styles.popoverContainer}>
								{$.__(
									`Government reporting currently requires reporting on employee gender. If you'd like, we encourage you to add gender information below and share more about your gender identity and pronouns.`
								)}
								{!(isNonBinarySelected || showGenderInformation) && (
									<TextButton onClick={() => setShowGenderInformation(true)}>{$.__('+ Add Gender Information')}</TextButton>
								)}
							</div>
						</Popover>
					</FieldBox>
				</>
			)}
		</div>
	);
};

export default GenderSelect;
