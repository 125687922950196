import {
	compact,
	isElement,
	isNumber,
	isPlainObject,
	uniq,
} from 'lodash';
import {
	makeUid,
} from 'BambooHR.util';
import {
	isEnabled,
} from 'FeatureToggle.util';

const ON_RESIZED = makeUid();
const ON_ACTION = makeUid();

// # Event decorators

/*
 * @description
 *
 * This module exports several decorators to help with event listeners.
 *
 * 	import Ctrl, {
 * 		onClick,
 * 	} from 'ctrl.deco';
 *
 * 	@Ctrl('/some/path')
 * 	class SomePathCtrl {
 * 		constructor() {
 * 			// some code...
 * 		}
 *
 * 		@onClick('.someSpecialButton')
 * 		specialButtonClick(e) {
 * 			e.preventDefault();
 * 			// click event handler code...
 * 		}
 * 	}
 *
 * You can "namespace" your event selectors by adding a static
 * property called `"rootSelector"` to your controller class.
 * This selector will be prepended to all of your event selectors.
 *
 * 	@Ctrl('/some/path')
 * 	class SomePathCtrl {
 * 		static context = '.somePathSelector';
 *
 * 		constructor() {
 * 			// some code...
 * 		}
 *
 * 		@onClick('.someSpecialButton')
 * 		specialButtonClick(e) {
 * 			e.preventDefault();
 * 			// will listen to click events on:
 * 			// '.somePathSelector .someSpecialButton'
 * 		}
 * 	}
 */
export function on(event,sel,context) { return registerEvent(event,sel,context); }
export function onBlur(sel,context) { return registerEvent('blur',sel,context); }
export function onChange(sel,context) { return registerEvent('change',sel,context); }
export function onClick(sel,context) { return registerEvent('click',sel,context); }
export function onCopy(sel,context) { return registerEvent('copy',sel,context); }
export function onCut(sel,context) { return registerEvent('cut',sel,context); }
export function onDblClick(sel,context) { return registerEvent('dblclick',sel,context); }
export function onFocus(sel,context) { return registerEvent('focus',sel,context); }
export function onInput(sel,context) { return registerEvent('input',sel,context); }
export function onKeyDown(sel,context) { return registerEvent('keydown',sel,context); }
export function onKeyPress(sel,context) { return registerEvent('keypress',sel,context); }
export function onKeyUp(sel,context) { return registerEvent('keyup',sel,context); }
export function onMouseDown(sel,context) { return registerEvent('mousedown',sel,context); }
export function onMouseEnter(sel,context) { return registerEvent('mouseenter',sel,context); }
export function onMouseLeave(sel,context) { return registerEvent('mouseleave',sel,context); }
export function onMouseMove(sel,context) { return registerEvent('mousemove',sel,context); }
export function onMouseOut(sel,context) { return registerEvent('mouseout',sel,context); }
export function onMouseOver(sel,context) { return registerEvent('mouseover',sel,context); }
export function onMouseUp(sel,context) { return registerEvent('mouseup',sel,context); }
export function onMouseWheel(sel,context) { return registerEvent('mousewheel',sel,context); }
export function onPaste(sel,context) { return registerEvent('paste',sel,context); }
export function onReady() { return registerEvent('ready',null, document); }
export function onResize() { return registerEvent('resize',null, window); }
export function onResized() { return registerEvent(ON_RESIZED); }
export function onOrientationChange() { return registerEvent('orientationchange',null, window); }
export function onScroll(sel,context) { return registerEvent('scroll',sel,context); }
export function onSubmit(sel,context) { return registerEvent('submit',sel,context); }
export function onWheel(sel,context) { return registerEvent('wheel',sel,context); }

export function onFooterBtnClick(sel = '.btnAction') { return registerEvent('click','#footer-buttons ' + sel); }
export function onFooterCancelClick(sel = '.footerCancel') { return registerEvent('click','#footer-buttons ' + sel); }

export function onInvalidForm(sel = 'form.BhrForms',context) { return registerEvent('invalid-form',sel,context); }
export function onAjaxSubmit(sel = 'form.BhrForms[data-ajax-method]',context) { return registerEvent('ajaxSubmit',sel,context); }
export function onAjaxSubmitFail(sel = 'form.BhrForms[data-ajax-method]',context) { return registerEvent('ajaxSubmitFail',sel,context); }

export function onStateChange() { return registerEvent('pushState replaceState popState refreshState',null,window); }
export function onPjaxEnd() { return registerEvent('pjax:end', null, document); }

export const onAction = (action, context) => registerEvent(ON_ACTION, `
		[data-action="${ action }"],
		[data-action^="${ action }:"]
	`, context);

export const onFooterAction = (...args) => {
	if (args.length > 0) {
		if (isNumber(args[0])) {
			if (args[0] == 0) {
				args.unshift('primary');
			} else {
				args.unshift('secondary');
			}
		}

		args = compact(args);
	}

	return onAction(['SiteFooterAction', ...args].join(':'), 'footer.SiteFooter');
};

export const onDropdownSelect = (sel = 'ba-dropdown', context) => registerEvent('ba:dropdownSelect', sel, context);
export const onSelectChange = (sel = 'ba-select', context) => registerEvent('ba:selectChange', sel, context);

const _toggles = new WeakMap();
export function feature(key: string, value: boolean = true): Function {
	return function(controller: Function, fn: string): void {
		const func = controller[fn];

		_toggles.set(func, {
			...(_toggles.get(func)),
			[key]: value,
		});
	};
}

export const featureOn = key => feature(key, true);
export const featureOff = key => feature(key, false);

export const jade = () => featureOn('jade');
export const legacy = () => featureOff('jade');

function checkToggles(func) {
	const toggles = _toggles.get(func);

	return !toggles || !Object.entries(toggles)
		.some(([key, enabled]) => (
			(enabled && !isEnabled(key)) ||
			(!enabled && isEnabled(key))
		));
}

const _events = new WeakMap();
function registerEvent(event, selector, context) {
	return function(controller, fn) {
		const func = controller[fn];
		const ctrl = controller.constructor;
		const events = _events.get(ctrl) || [];
		let eventList = event.split(' ');
		let handler = func;

		if (event === ON_ACTION) {
			eventList = ['click'];
			handler = function() {
				let [action, ...args] = this.dataset.action.split(':');

				args = args.map(arg => (isNaN(arg) ? arg : parseFloat(arg)));

				return controller[fn](...arguments, ...args);
			};
		}

		eventList.forEach((event) => {
			events.push({
				event,
				selector,
				context,
				handler,
				func,
			});
		});

		_events.set(ctrl, events);
	};
}


let _rootElem = new WeakMap();
function getRootElem() {
	var ctrl = this;

	if (ctrl.constructor !== Function) {
		ctrl = ctrl.constructor;
	}

	return (
		_rootElem.get(ctrl) ||
		$(ctrl.context)[0] ||
		document
	);
}

function _callHandler(handler, e, controller, ...args) {
	if (
		e.originalEvent instanceof CustomEvent &&
		isPlainObject(e.originalEvent.detail)
	) {
		e.detail = e.originalEvent.detail;
	}

	return this::handler(e, controller, ...args);
}

export function setupEvents(eventNS, rootElem) {
	var controller = this;
	var ctrl = controller.constructor;

	if (ctrl === Function) {
		console.error('::setupEvents() must be called on a class instance, not the class itself');
	}

	var events = _events.get(ctrl);

	if (!Array.isArray(events)) {
		return;
	}

	rootElem = $(rootElem)[0] || ctrl::getRootElem();

	_rootElem.set(ctrl, rootElem);

	//set up events
	if (typeof events !== 'undefined') {
		events.forEach((cfg) => {
			let {
				context,
				event,
				func,
				handler,
				selector,
			} = cfg;

			if (!checkToggles(func)) {
				return;
			}

			let $context = $(context || rootElem);

			if (selector) {
				$context.on(`${ event }.${ eventNS }`, selector, function(e, ...args) {
					return this::_callHandler(handler, e, controller, ...args);
				});

				return;
			}

			let resizeTimer;
			switch (event) {
				case ON_RESIZED:
					$(window).on(`resize.${ eventNS }`, function(e, ...args) {
						clearTimeout(resizeTimer);
						resizeTimer = setTimeout(() => this::_callHandler(handler, e, controller, ...args), 100);
					});
					break;
				default:
					$context.on(`${ event }.${ eventNS }`, function(e, ...args) {
						return this::_callHandler(handler, e, controller, ...args);
					});
					break;
			}
		});
	}
}

export function removeEvents(eventNS) {
	var ctrl = this;

	if (ctrl.constructor !== Function) {
		ctrl = ctrl.constructor;
	}

	var rootElem = ctrl::getRootElem();
	var contexts = uniq((_events.get(ctrl) || [])
		.map(cfg => $(cfg.context || rootElem)[0])
		.filter(context => (
			isElement(context) ||
				context === document ||
				context === window
		))
		.concat([window, document, rootElem])
	);

	if (contexts.length > 0) {
		$(contexts).off(`.${ eventNS }`);
	}
}
