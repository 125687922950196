import createNumericMask from './numeric-mask';
import createAlphanumericMask from './alphanumeric-mask';
import createDynamicMask from './create-dynamic-mask';

const numericMask = createNumericMask({ replaceInvalidCharacters: false });
const alphanumericMask = createAlphanumericMask({ replaceInvalidCharacters: false });

// for more masks, search for: `$.registerElementInitializer('`
const commonInputMasks = {
	'ssn-field': numericMask('999-99-9999'),
	'ssn-field-sensitive': alphanumericMask('999-99-9999'),
	'sin-field': numericMask('999-999-999'),
	'sin-field-sensitive': alphanumericMask('999-999-999'),
	'nin-field': alphanumericMask('ss ss ss ss s'), // '** ** ** ** *'
	'national-id-field': createAlphanumericMask({ minLength: 6, maxLength: 16 }), // '******[*]{0,10}'
	validatePassportNumber: createAlphanumericMask({ maxLength: 20 }), // '[*]{0,20}'
	'cc-expiration-field': numericMask('99/99'),
};

export {
	createAlphanumericMask,
	createNumericMask,
	createDynamicMask,
	commonInputMasks,
};
