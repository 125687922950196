import ItemCreator from '../../containers/item-creator';
import creator from '../../hocs/creator';

const CommentCreator = ({ ...props }) => (
	<ItemCreator
		{ ...props }
		onError={ props.handleError }
		onFocus={ props.beginEditing }
		onReset={ () => {
			let event = new CustomEvent('feeds:cancelEditing', {bubbles: true, detail: props.config.parentId });

			props.cancelEditing();
			document.dispatchEvent(event);
		} }
		onSubmit={ props.beginSaving }
		onSuccess={ () => {
			let event = new CustomEvent('feeds:commentAdded', {bubbles: true, detail: props.config.parentId });

			props.handleSuccess();
			document.dispatchEvent(event);
		} }
	/>
);

export default creator(CommentCreator);
