import Comment from '../components/comment';
import { connect } from 'react-redux';
import { getConfig } from '../../../constants';
import { getCommentById, getId } from '../../../store/selectors';

import {
	beginEditing,
	cancelEditing,
	deleteComment,
	editComment,
} from '../../../actions';

const mapStateToProps = (state, ownProps) => ({
	item: getCommentById(state, ownProps.id),
	config: getConfig(getId(state))
});

export default connect(
	mapStateToProps,
	{
		beginEditing,
		cancelEditing,
		deleteComment,
		editComment,
	}
)(Comment);

