import {
	useCallback,
	useEffect,
	useRef,
} from 'react';

export function SlideTransition(props) {
	const {
		children,
		isOpen,
	} = props;

	const wrapperRef = useRef();
	const lastHeightRef = useRef();

	const style = {
		height: 0,
		overflow: 'hidden',
		transition: 'height 200ms ease-in-out',
	};

	const _onTransitionEnd = useCallback(() => {
		const wrapperElement = wrapperRef.current;

		if (isOpen) {
			lastHeightRef.current = wrapperElement.scrollHeight;
			wrapperElement.style.height = '';
		} else {
			wrapperElement.style.display = 'none';
		}
	}, [isOpen]);

	useEffect(() => {
		const wrapperElement = wrapperRef.current;

		if (lastHeightRef.current === undefined) {
			lastHeightRef.current = wrapperElement.scrollHeight;
		}

		if (isOpen) {
			wrapperElement.style.display = '';
			wrapperElement.style.height = `${ wrapperElement.scrollHeight }px`;
			return;
		}

		window.requestAnimationFrame(() => {
			wrapperElement.style.height = `${ lastHeightRef.current }px`;

			window.requestAnimationFrame(() => {
				wrapperElement.style.height = 0;
			});
		});
	}, [isOpen]);

	// If isOpen is true when first rendered, we need display in order to calculate scrollHeight
	// otherwise, we can go ahead and hide, which helps with things like drag-and-drop
	useEffect(() => {
		const wrapperElement = wrapperRef.current;

		if (!isOpen) {
			wrapperElement.style.display = 'none';
		}
	}, []);

	return (
		<div ref={ wrapperRef } onTransitionEnd={ _onTransitionEnd } style={ style }>
			{ children }
		</div>
	);
}
