import { LayoutBox, Section } from '@bamboohr/fabric';

import { FunctionComponent } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const FieldSet: FunctionComponent<DynamicForm.FieldSetElementProps> = (fieldSetProps) => {
	const { props, children } = fieldSetProps;
	const { className = '' } = props;
	const noSectionClassName = 'js-noSection';

	return (
		<fieldset {...props} className={ifFeature('encore', `${className}`, `fab-FormSection ${className}`)}>
			{ifFeature('encore', 
				className.includes(noSectionClassName) ? 
					<LayoutBox marginTop='20px'>
						{children}
					</LayoutBox> 
					: 
					<LayoutBox marginTop={4}>
						<Section>
							{children}
						</Section>
					</LayoutBox>
				,
				children)
			}
		</fieldset>
	);
};

export default FieldSet;
