import React from 'react';

export interface Props {
	handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	id: string;
	isChecked: boolean;
	isDisabled?: boolean;
	label?: string;
	name: string;
	value?: string;
}

export function FabInputRadio(props: Props): JSX.Element {
	const {
		handleChange,
		id,
		isChecked,
		isDisabled,
		label,
		name,
		value,
	} = props;

	return (
		<div className="fab-Radio">
			<input
				checked={ isChecked }
				className="fab-Radio__input"
				disabled={ isDisabled }
				id={ id }
				name={ name }
				onChange={ handleChange }
				type="radio"
				value={ value }
			/>
			<label
				className="fab-Radio__label"
				htmlFor={ id }
			>
				{ label }
			</label>
		</div>
	);
}
