import Pusher from 'pusher-js';
import {createLogger} from '@utils/dev-logger';
import Ajax from '@utils/ajax';

const nudgeDevLogger = createLogger('Nudge');
let socketConnection = null;

function getSocketConnection() {
	return new Promise((resolve, reject) => {
		if (socketConnection && socketConnection.connection.state === 'connected') {
			resolve(socketConnection);
			return;
		}

		socketConnection = new Pusher(window.PUSHER_AUTH_KEY, {
			cluster: window.PUSHER_CLUSTER,
			forceTLS: true,
			authEndpoint: '/pusher/auth',
			auth: {
				headers: {
					'X-CSRF-Token': window.CSRF_TOKEN,
				}
			}
		});

		socketConnection.connection.bind('connected', () => {
			resolve(socketConnection);
		});

		// The connection is temporarily unavailable.
		// In most cases this means that there is no internet connection. It could also mean that Channels is down
		socketConnection.connection.bind('unavailable', reject);

		// Channels is not supported by the browser.
		// This implies that WebSockets are not natively available and an HTTP-based transport could not be found.
		socketConnection.connection.bind('failed', reject);

		// The Channels connection was previously connected and has now intentionally been closed.
		socketConnection.connection.bind('disconnected', reject);
	})
		.catch(reason => Promise.reject('Connection to external service failed...'));
}


function getChannels(validConnectionObjects) {
	return Ajax
		.get('/broadcast/channels?' + createQueryString(validConnectionObjects))
		.then(response => response.data)
		.catch(reason => Promise.reject('Getting channels failed...'));
}

function isValidConnectionObject(connectionObject) {
	if (typeof connectionObject === 'object') {
		const {channel, events} = connectionObject;

		return (
			typeof channel === 'string' &&
			channel.trim().length > 0 &&
			typeof events === 'object' &&
			Object.keys(events).length > 0
		);
	}
	return false;
}

function createQueryString(validConnectionObjects) {
	const formData = new FormData();
	validConnectionObjects.forEach(({channel, subjectId}, index) => {
		formData.append(`channels[${ index }]`, channel);
		formData.append(`subjectIds[${ index }]`, subjectId);
	});
	return new URLSearchParams(formData).toString();
}


function splitByCondition(array, condition) {
	const metCondition = [];
	const failedCondition = [];

	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		(condition(item) ? metCondition : failedCondition).push(item);
	}

	return [metCondition, failedCondition];
}


function createConnectionObject(socketConnection, pusherChannel, channelDetails) {
	const {channel, originalChannel, subjectId} = channelDetails;
	return {
		channel: originalChannel,
		subjectId,
		unsubscribe: () => socketConnection.unsubscribe(channel),
		on(event, fn) {
			if (!event || !fn) {
				return;
			}
			// Ideally... we shouldn't be allowing arguments to be passed to the callback, unfortunately,
			// it has to stay a reference other wise it can't be removed
			pusherChannel.bind(event, fn);
		},
		off(event, fn) {
			pusherChannel.unbind(event, fn);
		}
	};
}

function setupExpiredListener(socketConnection) {
	document.addEventListener('AppSession:expired', () => {
		socketConnection.disconnect();
	}, { once: true });
}

function setupLoggedInListener(connectionObjects) {
	document.addEventListener('AppSession:csrfChanged', () => {
		socketConnection.config.auth.headers['X-CSRF-Token'] = window.CSRF_TOKEN;
		connectionObjects.forEach((connectionObject) => {
			subscribe(connectionObject);
		});
	}, { once: true });
}

export async function subscribe(connectionObjects) {
	if (!Array.isArray(connectionObjects)) {
		connectionObjects = [connectionObjects];
	}

	const [validConnectionObjects, invalidConnectionObjects] = splitByCondition(connectionObjects, isValidConnectionObject);

	if (invalidConnectionObjects.length) {
		nudgeDevLogger.warn('Invalid connection objects were passed in. They have been ignored:', ...invalidConnectionObjects);
	}

	if (!validConnectionObjects) {
		return Promise.reject('No valid connection objects were used...');
	}

	const mapOfChannelTypes = validConnectionObjects.reduce((obj, next) => {
		if (!obj[next.channel]) {
			obj[next.channel] = {};
		}
		obj[next.channel][next.subjectId] = next;

		return obj;
	}, {});

	const socketConnection = await getSocketConnection();
	const channelsObjects = await getChannels(validConnectionObjects);

	const connections = channelsObjects.map((channelObject) => {
		const { originalChannel, subjectId } = channelObject;
		const channelEvents = mapOfChannelTypes[originalChannel][subjectId].events;
		const channel = socketConnection.subscribe(channelObject.channel);

		Object
			.keys(channelEvents)
			.forEach((event) => {
				channel.bind(event, () => channelEvents[event]());
			});

		return createConnectionObject(socketConnection, channel, channelObject);
	});

	setupExpiredListener(socketConnection);
	setupLoggedInListener(validConnectionObjects);

	return {
		connections: connections,
		find(channel, subjectId) {
			return connections.find(conn => conn.channel === channel && conn.subjectId === subjectId);
		},
	};
}


export default {
	subscribe,
};
