import isPlainObject from 'lodash/isPlainObject';
/**
 * objectMapUtil takes an object and an object map and converts keys and values from the object to a new object using the object map
 *
 * @export 
 * @param {*} [obj={}]
 * @param {*} [objMap={}]
 * @returns {}
 */
export function objectMapUtil(obj = {}, objMap = {}) {
	const newObj = {};

	Object.keys(obj).forEach((key) => {
		if (objMap[key]) {
			if (isPlainObject(objMap[key])) {
				const { name, convert } = objMap[key];

				newObj[name] = convert(obj[key]);
			} else {
				newObj[objMap[key]] = obj[key];
			}
			
		} else {
			newObj[key] = obj[key];
		}
	});
	return newObj;
}
/**
 * convertOldItemToNew is a util that converts old select.react & dropdown.react item objects to the
 * new @fabric/select & @fabric/dropdown formats using objectMapUtil
 * 
 * @export
 * @param {*} item
 * @returns {}
 */
export function convertOldItemToNew(item) {
	const itemMap = {
		actionOnly: 'isActionOnly',
		displayText: 'text',
		selected: 'isSelected',
		disabled: 'isDisabled',
		items: {
			name: 'items',
			convert: items => items.filter(item => item).map(convertOldItemToNew)
		}
	};
	return objectMapUtil(item, itemMap);
}
/**
 * convertNewItemToOld is a util that converts the new s@fabric/select & @fabric/dropdown item objects to the
 * old select.react & dropdown.react formats using objectMapUtil
 *
 * @export
 * @param {*} item
 * @returns {}
 */
export function convertNewItemToOld(item) {
	const itemMap = {
		text: 'displayText',
		isSelected: 'selected',
		isActionOnly: 'actionOnly',
		isDisabled: 'disabled',
		items: {
			name: 'items',
			convert: items => items.filter(item => item).map(convertNewItemToOld)
		}
	};
	return objectMapUtil(item, itemMap);
}
/**
 * Maps the old listPosition attribute to the new @fabric/dropdown and @fabric/selet placement prop
 * @param  {string} listPosition Old position of the dropdown menu: 'bottom right'
 * @return {object}              New placement object
 */

export function placementOldToNew(listPosition = '') {
	const positionArray = listPosition.split(' ');
	let align = 'end';
	let side = positionArray[0] ? positionArray[0] : 'bottom';

	switch (positionArray[1]) {
		case 'right':
		case 'bottom':
			align = 'start';
			break;
		case 'left':
		case 'top':
			align = 'end';
			break;
	}

	return {
		align,
		side
	};
}
/**
 * Maps the new placement prop to the old <ba-dropdown> and <ba-select> listPosition attribute
 * @param  {[type]} placement New placement of the dropdown menu
 * @return {[type]}           Old listPosition string
 */
export function placementNewToOld(placement) {
	const side = placement && placement.side ? placement.side : 'bottom';
	let align = 'right';

	if (placement && placement.align && placement.align === 'end') {
		align = 'left';
	}

	return `${ side } ${ align }`;
}
