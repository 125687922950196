/**
 * ScreenshotReady - a utility function to signal to the BE that the page is screenshot ready
 * @argument {optional} ...events takes all arguments and converts them to an array.
 * Each argument should be a function that should return a promise that when it resolves returns an object or
 * a function that just returns an object. This object will be used to compose the metaData that the BE can read
 * before taking a screenshot
 */

export default function ScreenshotReady(...events) {
	return Promise.all(events).then((list) => {
		let metaData = {
			ready: true,
		};

		list.forEach((config) => {
			metaData = { ...metaData, ...config };
		});
		implementation(metaData);

		return metaData;
	});
}

/**
 * implementation - the implementation details, this is subject to change
 *
 * @param  {object} metaData the data we need to pass to the BE
 */
function implementation(metaData): void {
	const body = document.querySelector('body');
	body.dataset.screenshot = JSON.stringify(metaData);
}
