import { BodyText } from '@bamboohr/fabric';

export interface PayPeriodIndicatorProps {
	type: 'begins' | 'ends';
}

export function PayPeriodIndicator(props: PayPeriodIndicatorProps) {
	const { type } = props;

	const text = type === 'begins' ? $.__('Pay Period Begins') : $.__('Pay Period Ends');

	return (
		<BodyText color='neutral-medium' icon='calendar-regular' size='small'>
			{text}
		</BodyText>
	);
}
