import {select, selectAll} from 'd3-selection';

export default class BaseChart {
	constructor() {
		this._setupResizeEventListener();
	}

	/* To be overriden by implementation */
	draw(data) {
		console.error('BaseChart.draw() Must be implemented via the extending class');
	}

	/* To be overriden by implementation */
	resize() {
		console.error('BaseChart.resize() Must be implemented via the extending class');
	}

	/** 
	 * Returns the settings object
	 * 
	 * @return {object} _settings
	*/
	getSettings() {
		return this._settings;
	}

	hasAxis() {
		let settings = this.getSettings();

		if (typeof settings.container.axis === 'undefined') {
			return false;
		}

		return (typeof settings.container.axis.hasAxis !== 'undefined') &&
			(settings.container.axis.hasAxis);
	}

	/**
	 * Creates the main div + svg for this chart with the required definitions
	 */
	_createRoot() {
		let {height, flexHeight, margin} = this.getSettings().container;

		let totalHeight = height + margin.top + margin.bottom;
		let totalWidth = this._getContainerWidth() + margin.left + margin.right;
		
		this._svg = select(this._selector)
			.append('div')
			.attr('class', this._buildClassList('Container'))
			.style('height', (d) => {

				// Hard codes a height to the container if flexHeight is false
				if (!flexHeight) {
					return `${ totalHeight }px`;
				}

				return `auto`;
			})
			.append('svg')
			.attr('class', this._buildClassList())
			.style('width', `${ totalWidth }px`)
			.style('height', `${ totalHeight }px`)
			.attr('transform', `translate(${ margin.left }, ${ margin.top })`);

		// Shield your eyes...
		// Used for when a chart needs an existing group to adjust translation without re-appending each update
		this._g = this._svg
			.append('g');
	}

	/**
	 * Used to signal the develper if key properties are missing from settings implementation
	 */
	_validateRequirements() {
		let {chartClass} = this.getSettings();

		if (!chartClass) {
			console.error(`${ this._CLASS_NAME } requires 'chartClass' settings object to be populated`);
		}
	}

	/**
	 * Constructs a string of class names based on CLASS_NAME and value from `this._settings.chartClass`
	 * 
	 * @param {string} value 
	 * @return {string} classString
	 */
	_buildClassList(value = '') {
		let classString = `${ this._CLASS_NAME }${ value }`;
		let settings = this.getSettings();

		if (settings.chartClass !== '') {
			classString += ` ${ settings.chartClass }${ value }`;
		}

		return classString;
	}

	/**
	 * A class getter method, pass in the unique class string to get the fully namespaced class 
	 * 
	 * ex: settings.chartClass: MyNameSpace
	 *     getClassList('__Axis') returns .MyNameSpace__Axis'
	 * 
	 * @param {string} value 
	 * @return {string} classString
	 */
	_getClassList(value = '') {
		let {chartClass} = this.getSettings();

		return `.${ chartClass }${ value }`;
	}

	/**
	 *  Setups a resize event to automatically rescale chart based on window size
	 */
	_setupResizeEventListener() {
		window.addEventListener('resize', () => {
			this._updateSVGWidth();
			this.resize();
		});
	}

	/**
	 *  Gets the container width
	 */
	_getContainerWidth() {
		const settings = this.getSettings();

		let containerWidth = settings.container.width;

		if (containerWidth === 'auto' && this._selector) {
			containerWidth = this._selector.clientWidth;
		}

		return containerWidth;
	}

	/**
	 * Gets the SVG bounding box object
	 * 
	 * @return {object} bb
	 */
	_getSVGDimensionsObject() {
		let svgElement = this._svg.node();

		let bb = svgElement.getBBox();

		return bb;
	}

	/** 
	 * Updates the SVG element's width
	*/
	_updateSVGWidth() {
		const svgElement = this._svg.node();

		if (!svgElement) { return; }

		const width = this._getContainerWidth();

		select(svgElement).style('width', `${ width }px`);
	}

	/**
	 * Method that applies animation methods to selected elements, if animation is turned on
	 * @param {*} elements selected elements
	 * @param {*} animationApplier method that takes selection and applies animation
	 * @returns If animation is turned on, will return elements with animations applied, else it will simply return the elements
	 */
	_animateElements(elements, animationApplier) {
		if (this.getSettings().animate) {
			return animationApplier(elements);
		}
		return elements;
	}
	
	/** 
	 * Adjusts SVG element's height based on content if including an unknown number of items
	*/
	_dynamicallyAdjustSVGHeight() {
		let svgElement = this._svg.node();

		if (!svgElement) { return; }

		let bb = this._getSVGDimensionsObject();

		let growSpacing = 15;
		let newHeight = bb.y + bb.height + growSpacing;

		let transitions = 0;
		this._animateElements(select(svgElement), elements => elements
			.transition()
			.duration(500)
			.style('height', `${ newHeight }px`)
			.on('start', () => { 
				transitions++;
			})
			.on('end', () => {
				if (--transitions === 0 && document.contains && document.contains(svgElement)) {
					this._dynamicallyAdjustAxisHeight();
				}
			})
		).style('height', `${ newHeight }px`);
	}

	/** 
	 * Adjusts axis height based on container height
	*/
	_dynamicallyAdjustAxisHeight() {
		if (this.hasAxis()) {
			const svgElement = this._svg.node();

			select(this._getClassList('__xAxis')).selectAll('line')
				.transition()
				.delay(1000)
				.filter(function () {
					return document.contains(svgElement);
				})
				.attr('y2', () => {
					const bb = this._getSVGDimensionsObject();

					return bb.height;
				});
		}
	}

	/** 
	 * Attempts to convert a text string into pixel space
	 * 
	 * @param {string} text
	 * @return {int} width
	*/
	_convertValueLengthToPixelSpace(text) {
		this._svg.append('g')
			.attr('translate', 'transition(10000, 0')
			.attr('class', 'text-to-pixel-group')
			.append('text')
			.text(text);

		let groupSelection = select('.text-to-pixel-group');

		let pixelLength = groupSelection.select('text').node().getComputedTextLength();
		groupSelection.remove();

		return pixelLength;
	}
}
