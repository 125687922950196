import { render } from 'base/wrapped-render';

import ChooseClockOutDate from './choose-clock-out-date.react';
import './styles.styl';

export default function (employeeId, lastClockEntry) {
	return new Promise((resolve) => {
		const chooseClockOutDateDiv = document.createElement('div');
		render(<ChooseClockOutDate afterClockOut={ resolve } employeeId={ employeeId } lastClockEntry={ lastClockEntry } />, chooseClockOutDateDiv);
	});
}
