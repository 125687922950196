import { FunctionComponent, ReactElement } from 'react';
import { Props } from './text';
import {
	Aim14x15,
	CommentBubble15x15,
	Company14x16,
	Facebook8x14,
	GraduationCap14x12,
	Home14x14,
	InstagramIcon14x14,
	Linkedin12x11,
	MobilePhone10x16,
	Pinterest14x14,
	Skype14x14,
	Twitter14x11,
} from '@bamboohr/grim';
import { IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const iconMap = ifFeature('encore',
	{
		default: <IconV2 color="neutral-strong" name="comment-regular" size={16}/>,
		inputAolIcon: <IconV2 color="neutral-strong" name="globe-solid" size={16}/>,
		inputSkypeIcon: <IconV2 color="neutral-strong" name="skype" size={16}/>,
		inputEducationIcon: <IconV2 color="neutral-strong" name="diploma-solid" size={16}/>,
		inputHomeIcon: <IconV2 color="neutral-strong" name="house-regular" size={16}/>,
		inputMobileIcon: <IconV2 color="neutral-strong" name="mobile-regular" size={16}/>,
		inputFacebookIcon: <IconV2 color="neutral-strong" name="facebook" size={16}/>,
		inputLinkedinIcon: <IconV2 color="neutral-strong" name="linkedin" size={16}/>,
		inputPinterestIcon: <IconV2 color="neutral-strong" name="pinterest" size={16}/>,
		inputTwitterIcon: <IconV2 color="neutral-strong" name="square-x-twitter" size={16}/>,
		inputOfficeIcon: <IconV2 color="neutral-strong" name="building-regular" size={16}/>,
		inputInstagramIcon: <IconV2 color="neutral-strong" name="square-instagram" size={16}/>,
	}, {
		default: <CommentBubble15x15 className="fab-TextInputNub__icon" />,
		inputAolIcon: <Aim14x15 className="fab-TextInputNub__icon" />,
		inputSkypeIcon: <Skype14x14 className="fab-TextInputNub__icon" />,
		inputEducationIcon: <GraduationCap14x12 className="fab-TextInputNub__icon" />,
		inputHomeIcon: <Home14x14 className="fab-TextInputNub__icon" />,
		inputMobileIcon: <MobilePhone10x16 className="fab-TextInputNub__icon" />,
		inputFacebookIcon: <Facebook8x14 className="fab-TextInputNub__icon" />,
		inputLinkedinIcon: <Linkedin12x11 className="fab-TextInputNub__icon" />,
		inputPinterestIcon: <Pinterest14x14 className="fab-TextInputNub__icon" />,
		inputTwitterIcon: <Twitter14x11 className="fab-TextInputNub__icon" />,
		inputOfficeIcon: <Company14x16 className="fab-TextInputNub__icon" />,
		inputInstagramIcon: <InstagramIcon14x14 className="fab-TextInputNub__icon" />,
	}
);

const TextIconField: FunctionComponent<Props> = ({ props, input, settings, context }) => {
	const { icon } = settings;
	const inputIcon = (iconMap[icon] || iconMap.default) as ReactElement;

	return (
		<span className="fab-TextInputNub">
			{ inputIcon }
		</span>
	);
};

export default TextIconField;
