import React from "react";
import { InPageMessaging } from '@fabric/in-page-messaging';
import styles from './styles';

const Error = ({ customMessage, style }) => {
	return (
		<div style={ { ...styles.view, ...style } }>
			<span style={ { ...styles.image } }>
				<img src="/images/explorer-pudgy-232x270.png" />
			</span>
			<div>
				<InPageMessaging
					body="We tried to get that for you, but couldn't find it. Maybe try refreshing the page?"
					header="Oh snap!"
					error
				/>
				{ customMessage && <div style={ { marginTop: '16px' } }>{ customMessage }</div> }
			</div>
		</div>
	);
}

Error.defaultProps = {
	customMessage: '',
	style: {}
}

export default Error;
