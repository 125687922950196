import {select} from 'd3-selection';
import {arc, pie} from 'd3-shape';
import {sum} from 'd3-array';
import {easeCircleInOut} from 'd3-ease';
import {interpolate} from 'd3-interpolate';

import 'd3-selection-multi';

import {defaultsDeep, startsWith} from 'lodash';

import EventRegistry from 'EventRegistry.util';
import {trimLines} from 'String.util';

import {findNeighborArc} from './arcUtils';

const CLASS_NAME = 'BhrDonut';

// Default options for Donut
const DEFAULT_OPTIONS = {
	chartClass: '',
	icon: {
		visible: true
	},
	size: 260,
	valueLabel: {
		visible: true
	},
	useRelativeSizes: {
		label: true,
		percentage: true,
		percentageSign: true
	}
};

// These sizes relative to total chart size as defined in the options
const RELATIVE_SIZES = {
	placeholderLabelFontSize: 0.063,
	valueFontSize: 0.194,
	valueLabelFontSize: 0.067,
	valuePercentageSignFontSize: 0.109
};

const MINIMUM_SIZES = {
	placeholderLabelFontSize: 13,
}

const SLIDE_TRANSITION_DURATION = 750;

/**
 * Key function that returns the index as the key
 * @param  {object} d The data object
 * @param  {number} i The index in array
 * @return {number}   The key
 */
function keyAsIndex(d, i) {
	return i;
}

class Donut extends EventRegistry {
	constructor(selector, options) {
		super();

		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);

		this._size = this._options.size;
		this._radius = this._size * 0.5;

		this._setUpDom(selector);
	}

	/**
	 * Creates the base DOM tree for this component
	 * @param  {object} selector The parent selector
	 */
	_setUpDom(selector) {
		const donutSize = this._options.size;

		const donutThickness = donutSize * 0.125;
		const shadowCircleThickness = donutThickness * 0.3;

		const donutMaxRadius = donutSize / 2;
		const donutMinRadius = donutMaxRadius - (donutThickness * 1.5);

		const donutContainer = select(selector).append('div')
			.attr('class', `${ CLASS_NAME } ${ this._options.chartClass }`)
			.styles({
				'height': `${ donutSize }px`,
				'width': `${ donutSize }px`
			});

		const svg = donutContainer.append('svg')
			.attr('preserveAspectRatio', 'xMidYMid meet')
			.attr('width', '100%')
			.attr('height', '100%')
			.attr('style', 'box-sizing: border-box;')
			.attr('viewBox', `0 0 ${ donutSize } ${ donutSize }`);

		this._donutGroup = svg.append('g')
			.attr('transform', `translate(${ donutMaxRadius }, ${ donutMaxRadius })`);

		this._donutPathGroup = this._donutGroup.append('g');

		this._chartArc = arc()
			.innerRadius(donutMinRadius)
			.outerRadius(donutMaxRadius - (donutThickness / 2));

		this._chartArcOver = arc()
			.innerRadius(donutMinRadius)
			.outerRadius(donutMaxRadius);

		this._donutGroup.append('circle')
			.attr('r', donutMinRadius + (shadowCircleThickness / 2))
			.attr('style', 'stroke-opacity: 0.1; fill: none; stroke: #000000; pointer-events: none;')
			.style('stroke-width', shadowCircleThickness);

		this._setUpContentDom(donutContainer, donutMinRadius);
	}

	/**
	 * Creates the base DOM tree for this component
	 * @param {object} donutContainer D3 selector for the widget's container
	 */
	_setUpContentDom(donutContainer, radius) {
		// Fit box inside of inner circle. Go Pythagoras!
		let maxSize = Math.sqrt(Math.pow(radius, 2) * 2);

		let contentContainer = donutContainer.append('div')
			.attr('class', `${ CLASS_NAME }__container ${ this._options.chartClass }__container`)
			.attr('style',
				trimLines(`
					height: ${ maxSize }px;
					margin-left: ${ -maxSize / 2 }px;
					margin-top: ${ -maxSize / 2 }px;
					width: ${ maxSize }px
				`)
			);

		this._setUpPlaceholderDom(contentContainer);
		this._setUpValueDom(contentContainer);
	}

	/**
	 * Creates the DOM for content that displays inside the donut when
	 * no slices are currently hovered
	 * @param {object} contentContainer D3 selector for the content container
	 */
	_setUpPlaceholderDom(contentContainer) {
		this._placeholderContainer = contentContainer.append('div')
			.attr('class', `${ CLASS_NAME }__content ${ this._options.chartClass }__content`);

		if (this._options.icon.visible) {
			this._placeholderIcon = this._placeholderContainer.append('svg')
				.attr('class', `${ CLASS_NAME }__icon ${ this._options.chartClass }__icon js-BhrPercentDonutChart-icon-svg`)
				.attr('xmlns:xlink', 'http://www.w3.org/1999/xlink');

			this._placeholderIcon.append('use');
		}

		this._placeholderTitle = this._placeholderContainer.append('div')
			.attr('class', `${ CLASS_NAME }__title ${ this._options.chartClass }__title`);

		this._placeholderLabel = this._placeholderContainer.append('div')
			.attr('class', `${ CLASS_NAME }__label ${ this._options.chartClass }__label`);
	}

	/**
	 * Creates the DOM for content that displays inside the donut when a
	 * slice is hovered
	 * @param {object} contentContainer D3 selector for the content container
	 */
	_setUpValueDom(contentContainer) {
		this._value = contentContainer.append('div')
			.attr('class', `${ CLASS_NAME }__value ${ this._options.chartClass }__value`);

		this._valuePercentage = this._value.append('span');

		this._valuePercentageSign = this._value.append('span')
			.text('%');

		if (this._options.valueLabel.visible) {
			this._valueLabel = this._value.append('div')
				.attr('class', `${ CLASS_NAME }__valueLabel ${ this._options.chartClass }__valueLabel`);
		}
	}

	/**
	 * Creates an icon object from an icon name
	 * @param  {string} iconName The name of your icon
	 * @return {object}          The object representing your icon
	 */
	_getIconObject(iconName) {
		const pattern = /-(\d+)x(\d+)$/;

		let path = iconName;
		let matches;
		let height, width;

		if (typeof iconName !== 'string' || iconName.trim() === '') {
			throw new Error('Icon name is missing a value');
		}

		matches = pattern.exec(iconName);

		if (matches) {
			width = Number(matches[1]);
			height = Number(matches[2]);
			if (startsWith(iconName, 'fab-')) {
				path = window.Res.get('', null, false) + '#' + iconName;
			} 
		} else if (iconName.indexOf('#') !== 0) {
			throw new Error(`${ iconName } is an invalid icon name. Icon names must end with dimensions. Example: "-1x1".`);
		}

		return {
			height,
			path,
			width
		}
	}

	/**
	 * Draws a DOM representation of the data that is passed to it
	 * @param  {object} model The model containing data required to render
	 */
	draw(model) {
		const icon = typeof model.icon === 'string' ? this._getIconObject(model.icon) : model.icon;

		this._sizes = this._getSizes();
		this._trigger = false;

		this._drawPaths(model.data);

		if (this._options.useRelativeSizes.label) {
			this._placeholderLabel
				.style('font-size', this._sizes.placeholderLabelFontSize + 'px')
				.style('line-height', this._sizes.placeholderLabelFontSize + 2 + 'px');
		}
		if (!this._options.hidePlaceholderLabel) {
			this._placeholderLabel
				.text(model.name);
		}

		if (icon && this._options.icon.visible) {
			this._placeholderIcon
				.attr('height', (icon.height || 0) + 'px')
				.attr('width', (icon.width || 0) + 'px');

			this._placeholderIcon.select('use')
				.attr("xlink:href", icon.path);
		} else if (this._options.hidePlaceholderTitle) {
			this._placeholderTitle
				.text('');
		} else {
			this._placeholderTitle
				.text(model.totalCount);
		}
	}

	/**
	 * Draws the paths that display chart arcs
	 * @param  {object} pathData Data required to render the arcs
	 */
	_drawPaths(pathData) {
		const pieGenerator = pie()
			.padAngle(0.015)
			.value(d => d.count)
			.sort(null);

		const path = this._donutPathGroup.selectAll('path');

		const data0 = path.data();
		const data1 = pieGenerator(pathData.filter(item => !!item.count));

		const total = sum(pathData, d => d.count);

		let pathUpdate = path.data(data1, keyAsIndex);
		let pathEnter, pathMerge, pathExit;

		pathEnter = pathUpdate.enter()
			.append('path')
			.each(function(d, i) {
				this._current = findNeighborArc(i, data0, data1, keyAsIndex) || d;
			});

		pathMerge = pathEnter.merge(pathUpdate)
			.attr('id', d => d.data.id)
			.attr('fill', d => d.data.color);

		this._addSlideTransition(pathMerge, total);

		pathExit = pathUpdate.exit()
			.datum(function(d, i) { return findNeighborArc(i, data1, data0, keyAsIndex) || d; })

		this._addSlideTransition(pathExit, total).remove();
	}

	/**
	 * Returns pixel sizes relative to the overall chart size
	 * @return {object} Relative sizes in pixels
	 */
	_getSizes() {
		return Object.keys(RELATIVE_SIZES).reduce((memo, key) => {
			let size = Math.round(RELATIVE_SIZES[key] * this._options.size)
			let min = MINIMUM_SIZES[key] || 0;

			memo[key] = Math.max(size, min);

			return memo;
		}, {});
	}

	/**
	 * Adds a slide transition to a selection
	 * @param {object} selection The selection that receives the transition
	 * @param  {number} total The chart total
	 * @return {object} The transition
	 */
	_addSlideTransition(selection, total) {
		const that = this;

		const arcTween = function(a) {
			const i = interpolate(this._current, a);

			this._current = i(0);

			return function(transitionPercentage) {
				var interpolated = i(transitionPercentage);
				return that._chartArc(interpolated);
			};
		}

		return selection
			.on('mouseenter', null) // Remove the event handlers until the transition completes
			.on('mouseleave', null) // Remove the event handlers until the transition completes
			.transition()
			.duration(SLIDE_TRANSITION_DURATION)
			.attrTween('d', arcTween)
			.on('end', function() {
				select(this)
					.on('mouseenter', function(d) {
						that._onPathEnter(select(this), d, total);
					})
					.on('mouseleave', function(d) {
						that._onPathLeave(select(this), d, total);
					});
			});
	}

	/**
	 * Event handler for when the mouse has entered a donut path
	 * @param  {object} path  The path that triggered the event
	 * @param  {object} d     The data associated with the path
	 * @param  {number} total The chart total
	 */
	_onPathEnter(path, d, total) {
		let sizes = this._getSizes();

		this._trigger = true;

		setTimeout(() => {
			if (this._trigger) {
				path.node().dispatchEvent(
					new CustomEvent('ba:donutSliceEnter', {
						bubbles: true,
						detail: d.data
					})
				);

				// TODO: Remove this event as part of https://bamboohr.atlassian.net/browse/SD-3249
				this.trigger('sliceEnter', d.data, path.node());

				this._value
					.styles({
						'color': d.data.color,
						'display': 'block'
					});

				this._valuePercentage
					.text(Math.round((d.data.count / total) * 100));

				if (this._options.useRelativeSizes.percentage) {
					this._valuePercentage
						.style('font-size', sizes.valueFontSize + 'px');
				}

				if (this._options.useRelativeSizes.percentageSign) {
					this._valuePercentageSign
						.style('font-size', sizes.valuePercentageSignFontSize + 'px');
				}

				if (this._options.valueLabel.visible) {
					this._valueLabel
						.text(d.data.label)
						.styles({
							'color': d.data.color,
							'font-size': sizes.valueLabelFontSize + 'px'
						});
				}

				this._placeholderContainer.style('display', 'none');

				path.transition()
					.duration(150)
					.ease(easeCircleInOut)
					.attr('d', this._chartArcOver);
			}
		}, 150);
	}

	/**
	 * Event handler for when the mouse has left a donut path
	 * @param  {object} path The path that triggered the event
	 * @param  {object} d    The data associated with the path
	 * @param  {number} total The chart total
	 */
	_onPathLeave(path, d, total) {
		this._trigger = false;

		setTimeout(() => {
			path.node().dispatchEvent(
				new CustomEvent('ba:donutSliceLeave', {
					bubbles: true,
					detail: d.data
				})
			);

			// TODO: Remove this event as part of https://bamboohr.atlassian.net/browse/SD-3249
			this.trigger('sliceLeave', d.data, path.node());

			if (!this._trigger) {
				this._value.style('display', 'none');
				this._placeholderContainer.style('display', 'block')
			}

			path.transition()
				.duration(150)
				.ease(easeCircleInOut)
				.attr('d', this._chartArc);
		}, 150);
	}
}

export default Donut;
