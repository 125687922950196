import {
	BlankState,
	Button,
	LayoutBox,
	Tooltip,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import {
	BankOutlined72x72,
	MoneyWaving72x72,
} from '@bamboohr/grim';
/* @endCleanup encore */
import {
	noop,
} from 'lodash';

/* @startCleanup encore */
import './deposit-blank-state.styl';
/* @endCleanup encore */

import {ifFeature} from "@bamboohr/utils/lib/feature";

/**
 * Direct Deposit Blank State Component
 * @param {Object} props [description]
 *
 * Expected props:
 *
 * {Boolean}  hasTrax        whether TRAX integration is active
 * {Boolean}  canEdit        whether the user can edit Direct Deposit
 * {Function} buttonAction   callback for blank state call-to-action
 */
export function DepositBlankState(props) {
	const {
		hasTrax,
		buttonAction,
		canEdit,
		isAddButtonDisabled,
	} = props;

	const buttonText = ifFeature('encore', $.__('Add Direct Deposit'), `+ ${ $.__('Add Direct Deposit') }`);
	const action = typeof buttonAction === 'function' ? buttonAction : noop;
	const title = hasTrax ? $.__('You will receive a paper check until you add direct deposit info.') : $.__(`You don't have any Direct Deposit accounts set up.`);

	return (ifFeature('encore',
		<>
			<BlankState
				icon="bs-coin"
				level="widget"
				title={title}
			/>
			{canEdit && (
				<LayoutBox marginTop={2}>
					{isAddButtonDisabled ? (
						<Tooltip
							content={$.__("You don't have access to edit Direct Deposit.")}
							enterDelay= {300}
						>
							<LayoutBox tabIndex={0}>
								<Button
									color="secondary"
									disabled={true}
									onClick={noop}
									startIcon="circle-plus-regular"
									type="button"
									size="small"
								>
									{buttonText}
								</Button>
							</LayoutBox>
						</Tooltip>
					) : (
						<Button
							color="secondary"
							onClick={action}
							startIcon="circle-plus-regular"
							type="button"
							size="small"
						>
							{buttonText}
						</Button>
					)}
				</LayoutBox>
			)}
		</>,
		<div className="DepositBlankState">
			<span className="DepositBlankState__icon">
				{ hasTrax ? (
					<MoneyWaving72x72 />
				) : (
					<BankOutlined72x72 />
				) }
			</span>

			<h3 className="DepositBlankState__title">{ title }</h3>

			{ canEdit && (
				<div className="DepositBlankState__button">
					{isAddButtonDisabled ? (
						<Tooltip
							content={ $.__("You don't have access to edit Direct Deposit.") }
							enterDelay= { 300 }
						>
							<LayoutBox tabIndex={ 0 }>
								<Button
									disabled={ true }
									onClick={ action }
									type="button"
									variant="outlined"
								>
									{ buttonText }
								</Button>
							</LayoutBox>
						</Tooltip>
					) : (
						<Button
							onClick={ action }
							type="button"
							variant="outlined"
						>
							{ buttonText }
						</Button>
					)}
				</div>
			) }
		</div>
		)
	);
}
