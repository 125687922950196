import {
	merge,
} from 'lodash';
import React, {
	Component
} from 'react';
import {
	Modal
} from 'modal-legacy';
import {
	createLogger
} from '@utils/dev-logger';

import { convertHTMLEntitiesInString } from 'String.util';

const globalModalLogger = createLogger('GlobalModal');
const getDefaultModalOptions = () => ({
	autoClose: true,
	mergeModalProps: false,
	mergeSheetProps: true,
});
const getDefaultModalProps = () => ({
	isOpen: false,
	isProcessing: false,
});

Modal.setAppElement(document.querySelector('.BhrPage-wrap'));

/**
 * For use ONLY outside React. Within a React tree, simply use modal-legacy directly.
 * This modal instance is initialized/rendered on page load, but not open/visible.
 * From there, simply call the exposed setState method to toggle the isOpen value,
 * along with setting any other modal-legacy configuration properties you need.
 *
 * !!! USE FROM window.BambooHR, DO NOT TRY TO IMPORT THIS CLASS DIRECTLY !!!
 */
export class GlobalModal extends Component {
	constructor(props) {
		super(props);

		const { namespace = 'Modal' } = props;
		this.namespace = namespace;

		this.state = {
			modalOptions: getDefaultModalOptions(),
			modalProps: getDefaultModalProps(),
		};

		this._handleClose = this._handleClose.bind(this);

		window.BambooHR = (window.BambooHR || {});
		window.BambooHR[namespace] = {
			setState: this._setState.bind(this),
			isOpen: this._isOpen,
			isProcessing: this._isProcessing,
		};
	}

	_handleClose() {
		const { modalOptions, modalProps } = this.state;
		const { autoClose } = modalOptions;
		const { onClose } = modalProps;

		if (autoClose && this.namespace !== 'LockModal') {
			this._setState({isOpen: false}, true);
		}

		if (typeof onClose === 'function') {
			onClose();
		}
	}

	/**
	 * @param   {object}    newModalProps
	 * @private
	 */
	_handleOpen(newModalProps) {
		const { modalProps: currentModalProps } = this.state;

		if (currentModalProps.isOpen && newModalProps.isOpen) {
			globalModalLogger.warn('window.BambooHR.Modal seems to have been toggled to open while already open! Please ensure your logic is correct, and that the page is not trying to open two modals at the same time.');
		}
	}

	/**
	 * @param   {object}    newModalProps
	 * @param   {object}    newModalOptions
	 * @private
	 */
	_setState(newModalProps = {}, newModalOptions = {}, callback = () => {}) {
		const {
			modalOptions: currentModalOptions,
			modalProps: currentModalProps,
		} = this.state;

		if (typeof callback !== 'function') {
			console.warn('Global Modal react.js -> You didn\'t pass a function for callback');
			callback = () => {};
		}

		let modalOptions = newModalOptions;
		if (typeof newModalOptions === 'object') {
			modalOptions = Object.assign(getDefaultModalOptions(), newModalOptions); // for backward compatibility to boolean only argument
		}

		const {
			mergeModalProps = false,
			mergeSheetProps = true,
		} = modalOptions;

		const shouldMergeModalProps = (newModalOptions === true || mergeModalProps); // for backward compatibility to boolean only argument
		const currentSheetProps = (currentModalProps.sheetProps || null);
		const newSheetProps = (newModalProps.sheetProps || null);

		if (typeof newModalProps === 'object') {
			this._handleOpen(newModalProps);

			const newState = {
				modalOptions: merge({}, currentModalOptions, newModalOptions),
				modalProps: merge({}, (shouldMergeModalProps && currentModalProps), newModalProps),
			};

			if (mergeSheetProps && newSheetProps) {
				newState.modalProps.sheetProps = merge({}, currentSheetProps, newSheetProps);
			} else {
				newState.modalProps.sheetProps = newSheetProps;
			}

			if (currentModalProps.isOpen && !newState.modalProps.isOpen) {
				// closing time
				this.setState({modalProps: {...currentModalProps, isOpen: false}});
				// begin closing but then wait for animation to finish before setting other state
				window.setTimeout(() => {
					this.setState(newState, callback);
				}, 250);
			} else {
				this.setState(newState, callback);
			}
		} else {
			globalModalLogger.warn(`window.BambooHR.Modal.setState not called with an object. Got ${ typeof newModalProps } instead.`);
		}
	}

	_isOpen = () => {
		const { modalProps: {
			isOpen
		} } = this.state;

		return isOpen;
	}

	_isProcessing = () => {
		const { modalProps: {
			isProcessing
		} } = this.state;

		return isProcessing;
	}

	render() {
		const { modalProps } = this.state;
		if (typeof modalProps.title === 'string') {
			// type check to allow for JSX
			modalProps.title = convertHTMLEntitiesInString(modalProps.title);
		}

		return (
			<Modal
				{ ...modalProps }
				onClose={ this._handleClose }
			/>
		);
	}
}
