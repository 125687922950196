import { FC } from 'react';
import { BlankState, Button, makeStyles } from '@bamboohr/fabric';
/* @startCleanup encore */
import { CirclePlus16x16, PersonIndexCard120x92 } from '@bamboohr/grim';
/* @endCleanup encore */
import { DEPENDENT_BLANK_TITLE, ADD_DEPENDENT, DependentBlankStateProps } from './constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useBlankStateStyles = makeStyles((theme) => ({
	icon: {
		fill: theme.palette.gray[600],
	},
	root: {
		marginTop: '72px',
	},
	subtitle: {
		color: theme.palette.gray[600],
	},
	title: {
		color: theme.palette.gray[600],
	},
}));

export const DependentBlankDefault: FC<DependentBlankStateProps> = ({ onClick, canAdd }) => {
	const blankStateClasses = useBlankStateStyles();
	return (
		<BlankState
			classes={blankStateClasses}
			icon={ifFeature('encore', 'bs-doc-missing-field', <PersonIndexCard120x92 />)}
			title={DEPENDENT_BLANK_TITLE}
			actions={ifFeature(
				'encore',
				canAdd && [
					<Button
						color='secondary'
						data-bi-id='employees-dependents-add-dependent'
						onClick={onClick}
						startIcon='plus-regular'
						type='button'
					>
						{ADD_DEPENDENT}
					</Button>,
				]
			)}
		>
			{/* @startCleanup encore */}
			{ifFeature(
				'encore',
				undefined,
				canAdd && (
					<Button
						data-bi-id='employees-dependents-add-dependent'
						onClick={onClick}
						outline={true}
						startIcon={<CirclePlus16x16 />}
						text={`${ADD_DEPENDENT}`}
						type='button'
					/>
				)
			)}
			{/* @endCleanup encore */}
		</BlankState>
	);
};
