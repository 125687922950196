import {
	ReactElement,
	createContext,
	useReducer,
	useContext,
} from 'react';

import {
	State,
	ACTION_TYPES,
	taskSectionReducer
} from './reducers';

type Action = {
	payload: State,
	type: ACTION_TYPES,
}
type Dispatch = (action: Action) => void;

const NewHirePacketFormContext = createContext<{ state: State, dispatch: Dispatch} | undefined>(undefined);
let warningDisplayed = false;

function NewHirePacketFormProvider({ children }): ReactElement {

	const initialState = {
		errorFields: {},
		hireDate: undefined,
		infoHighlightFields: {},
		selectedManagerId: undefined,
	} as State;

	const [state, dispatch] = useReducer(taskSectionReducer, initialState);
	return (
		<NewHirePacketFormContext.Provider value={ { state: state as State, dispatch: dispatch as Dispatch } }>
			{ children }
		</NewHirePacketFormContext.Provider>
	);
}

function useNewHirePacketForm(): any {
	const context = useContext(NewHirePacketFormContext);
	if (warningDisplayed === false && context === undefined) {
		warningDisplayed = true;
		console.warn('useNewHirePacketForm must be used within a NewHirePacketFormContext');
	}
	return context;
}

export {
	ACTION_TYPES,
	NewHirePacketFormProvider,
	State,
	useNewHirePacketForm
};
