import {
	unmountComponentAtNode,
} from 'react-dom';
import { render } from 'base/wrapped-render';

let modalContainer;
let modalIsClosed;

/**
 * @function - Your modal really should handled the isOpen state. This works for now but if classes change it will lose the intended modal functionality
 */
export function closeModal(): void {
	if (modalContainer) {
		if (!modalIsClosed && window.ENV !== 'prod') {
			console.warn('You closed the modal before setting the modal isOpen to false, this will cause issues with the intended functionality. You should handle the isOpen state of the modal before calling this function');
		}

		// Be sure to only add these classes to modals within this context
		// Otherwise it hides other modals (window.BambooHR.Modal) and causes the page to be un-clickable
		$('.ReactModal__Content', modalContainer).addClass('ReactModal__Content--before-close');
		$('.ReactModal__Overlay', modalContainer).addClass('ReactModal__Overlay--before-close');
		setTimeout(() => {
			unmountComponentAtNode(modalContainer);
			document.body.removeChild(modalContainer);
			modalContainer = undefined;
		}, 250);
	}
}

export function showModal(element: JSX.Element): void {
	if (!modalContainer) {
		modalContainer = createModalContainer();
	}
	modalIsClosed = element.props.isOpen === false;
	render(element, modalContainer);
}

export function createModalContainer(id?: string): HTMLDivElement {
	const container = document.createElement('div');
	if (id) container.setAttribute('id', id);
	document.body.appendChild(container);
	return container;
}

export function removeModalContainer(id: string): void{
	const container = document.getElementById(id);
	if (container) {
		unmountComponentAtNode(container);
		document.body.removeChild(container);
	}
}
