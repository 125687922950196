import Ajax from '@utils/ajax';

export function saveTimeOffRequest(params) {
	const dataString = new URLSearchParams(params).toString();
	return Ajax.post('/inbox/details.php', dataString, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
		}
	});
}

export function postDeniedRequestComment(params) {
	return Ajax.post('/ajax/employee_comments.php', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
		}
	});
}

export function getDeniedTimeOffRequestCommentModalData(params) {
	return Ajax.get('/inbox/details.php', params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
		}
	});
}