/**
 * Returns a URL for an employee's detail page
 * @param  {number} employeeId The employee's ID
 * @return {string}            A URL that can be used to access the employee's page
 */
export function getEmployeeUrl(employeeId) {
	return `/employees/employee.php?id=${ employeeId }`;
}

/**
 * Returns the correct url for the image
 * @param  {object} employeeData Employees data
 * @return {string}              URL for the avatar image
 */
export const getAvatarUrl = (employeeData) => {
	if (employeeData.image) {
		employeeData.avatarUrl = employeeData.image;
	}

	// Return user's avatar
	if (employeeData && employeeData.avatarUrl) {
		return employeeData.avatarUrl;
	}

	// Return fancy initial
	if (employeeData && employeeData.name) {
		let initials = employeeData.name.match(/\b(\w)/g);

		if (initials) {
			initials = initials.join('');

			return `/employees/photos/initials.php?initials=${ initials }`;
		}
	}
};
