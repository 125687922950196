/**
 * Weird fix to fix currency values
 * @param  {string} value The currency value -> ie. $10,000.58
 * @return {number}       10000.58
 */
export function fixCurrencyValue(value: any): number {
	if (value) {
		return value.toString().replace(/\D/g, '') / Math.pow(10, 2);
	}
	return 0;
}

/**
 * Formats the pay rate string so that fixCurrencyValue can make proper calculation
 * USD only
 * @param {string} value
 * @param {string} currencySymbol
 * @return {string}
 */
export function appendDecimalIfNecessary(value: string, currencySymbol = '$'): string {
	if (currencySymbol !== '$') {
		return value;
	}

	const match = (/^(\d*,*\d*)(\.(\d*)){0,1}$/).exec(value.replace(currencySymbol, ''));
	if (match) {
		if (match[3] && match[3].length === 2) {
			return value;
		}
		if (!match[2]) {
			return `${ value }.00`;
		}
		if (match[2].length === 1) {
			return `${ value }00`;
		}
		if (match[3].length === 1) {
			return `${ value }0`;
		}
	}
	return value;
}
