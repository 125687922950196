export enum FIELD_TYPES {
	EMAIL = 'email',
	HIRE_DATE = 'hireDate',
	MANAGER = 'manager'
}

export enum TASK_FILE_UPLOAD_OPTIONS {
	NO = 'no',
	YES = 'yes',
	REQUIRED = 'required',
}
