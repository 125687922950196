import { defaultsDeep } from 'lodash';

import BaseChart from './base-chart';


import { drawChart as drawChartTopLabels } from './bar-charts/horizontal-bar-chart-top-labels.js';
import { drawChart as drawChartLeftLabels } from './bar-charts/horizontal-bar-chart-left-labels.js';
import { drawChart as drawChartLeftLabelsStacked } from './bar-charts/horizontal-bar-chart-stacked.js';
import { drawChart as drawChartLeftLabelsStackedSummary } from './bar-charts/horizontal-bar-chart-stacked-summary.js';
import { drawChart as drawChartLeftLabelsHybrid } from './bar-charts/horizontal-bar-chart-hybrid.js';
import { drawChart as drawChartLeftLabelsHybridDouble } from './bar-charts/horizontal-bar-chart-hybrid-double.js';

/** ****************************************************************************
 *
	Data contract

		bars: [
			{
				'value': 43,           // required
				'label': '2017'        // required
				'displayText': '158',  // optional; when provided will be displayed at the 'top' of the bar (Not Currently Implemented)
			},
			{
				'value': 43,           // required
				'label': '2017'        // required
				'displayText': '158',  // optional; when provided will be displayed at the 'top' of the bar (Not Currently Implemented)
			}
		]

	Settings contract

		{
			chartClass: '',
			container: {
				flexHeight: false,
				height: 126,
				width: 270,
				margin: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0
				}
			},
			labelText: {
				color: "#777",
				spacing: 12
			},
			valueText: {
				color: "#777",
				spacing: 4
			},
			bar: {
				colors: ['#8db812', '#db6021', '#f19d11'], // Green - Orange - Red Used for stacked charts
				height: 16,
				padding: 28,
				formatTextFunction: (value) return value
			}
		}

***************************************************************************** */

const CLASS_NAME = 'HorizontalBarChart';
const DEFAULT_SETTINGS = {
	chartClass: '',
	needsAxis: false,
	container: {
		flexHeight: false, // This allows for the chart height to be sized dynamically based on the number of bars provided
		height: 210,
		width: 70,
		margin: {
			top: 0,
			right: 0,
			bottom: 10,
			left: 0,
		},
	},
	labelText: {
		color: '#777',
		spacing: 12,
		alignmentLeft: false,
	},
	valueText: {
		color: '#777',
		spacing: 4,
		showZero: false,
	},
	bar: {
		colors: ['#ccc'], // Green: '#8db812' - Orange: '#db6021' - Red: '#f19d11'
		height: 16,
		padding: 28,
		formatTextFunction: null,
	},
	animate: !window.navigator.webdriver,
};

export default class HorizontalBarChart extends BaseChart {
	constructor(selector, settings = DEFAULT_SETTINGS) {
		super();

		this._selector = selector;
		this._CLASS_NAME = CLASS_NAME;
		this._settings = defaultsDeep({}, settings, DEFAULT_SETTINGS);

		this._validateRequirements();
		this._createRoot();
	}

	/**
	 * Draws each element in the order needed for the chart to display properly
	 *
	 * @param {object} data Formatted Data ready to be displayed
	 */
	draw(data) {
		this._data = data;

		if (data.type === 'hybrid') {
			drawChartLeftLabelsHybrid.call(this, data);
		}

		if (data.type === 'double') {
			drawChartLeftLabelsHybridDouble.call(this, data);
		}

		if (data.type === 'stacked') {
			drawChartLeftLabelsStacked.call(this, data);
		}

		if (data.type === 'stacked-summary') {
			drawChartLeftLabelsStackedSummary.call(this, data);
		}

		if (data.type === 'simple') {
			if (this.getSettings().labelText.alignmentLeft) {
				drawChartLeftLabels.call(this, data);
			} else {
				drawChartTopLabels.call(this, data);
			}
		}

		this._dynamicallyAdjustSVGHeight();
	}

	/**
	 * Redraws the chart on browser resize
	 */
	resize() {
		this.draw(this._data);
	}
}
