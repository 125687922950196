import Decimal from 'decimal.js-light';

export function createDecimal(value: any): undefined | Decimal {
	// This function attempts to mimic the JavaScript Number(value) constructor
	// by returning an equivalent Decimal when possible.
	// It differs though by returning undefined when the Number(value) constructor would otherwise return NaN.

	if (value === undefined) {
		return undefined; // Number constructor returns NaN when the value is undefined
	}

	if (value === null) {
		return new Decimal(0); // Number constructor returns 0 when the value is null
	}

	if (Number.isNaN(value)) {
		return undefined; // Number constructor returns NaN when the value is NaN
	}

	if (Array.isArray(value)) {
		if (value.length === 0) {
			return new Decimal(0); // Number constructor returns 0 when the value is an empty array
		}

		if (value.length > 1) {
			return undefined; // Number constructor returns NaN when the value is an array with more than one item
		}

		const itemValue = value[0];

		if (itemValue === undefined) {
			return new Decimal(0); // Number constructor returns 0 when the array item value is undefined
		}

		// Otherwise, the Number constructor typically treats the array item as the value to convert
		return createDecimal(itemValue);
	}

	if (!Array.isArray(value) && value !== null && typeof value === 'object') {
		return undefined; // Number constructor returns NaN when the value is an object that isn't an array or null
	}

	if (value === false) {
		return new Decimal(0); // Number constructor returns 0 when the value is false
	}

	if (value === true) {
		return new Decimal(1); // Number constructor returns 1 when the value is true
	}

	if (typeof value === 'string' && value.trim() === '') {
		return new Decimal(0); // Number constructor returns 0 when the value is a string which trims to ''
	}

	try {
		return new Decimal(value);
	} catch {
		return undefined; // Number constructor returns NaN when the value cannot be converted
	}
}
