import showSettingsModal from './settings-modal';


export default {
	showSettingsModal,
};

export {
	showSettingsModal,
};
