import {
	noop,
} from 'lodash';
import {
	useEffect,
	useState,
} from 'react';

import {
	SelectField as OldSelectField,
} from 'form-fields.react';

import { SelectField } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	BankAccountType,
} from '../../types';
import {
	ACCOUNT_TYPES,
	getAccountTypesError,
} from './account-type-input-field.domain';

export interface AccountTypeInputFieldProps {
	isDisabled: boolean;
	onChange: (accountType: BankAccountType, error: string) => void;
}

export function AccountTypeInputField(props: AccountTypeInputFieldProps): JSX.Element {
	const {
		isDisabled,
		onChange = noop,
	} = props;

	const [accountTypes, setAccountTypes] = useState([]);
	const [error, setError] = useState('Required');
	const [canShowError, setCanShowError] = useState(false);

	useEffect(() => {
		const accountType = accountTypes.length ? accountTypes[0] : null;

		onChange(accountType, error);
	}, [accountTypes, error]);

	const shouldShowError = canShowError && !!error;

	return ifFeature('encore',
		<SelectField
			disabled={ isDisabled }
			id="collections-and-refunds-account-type-input-field"
			items={ ACCOUNT_TYPES }
			label={ $.__('Account Type') }
			note={ shouldShowError && error }
			onChange={ (e): void => {
				const selectedTypes = e.target.value;
				setAccountTypes(selectedTypes);
				setError(getAccountTypesError(selectedTypes as Array<BankAccountType>));
				setCanShowError(true);
			} }
			onClose={ (): void => setCanShowError(true) }
			required={ true }
			status={ shouldShowError ? 'error' : undefined }
			value={ accountTypes }
			width={ 8 }	
		/>,
		<OldSelectField
			disabled={ isDisabled }
			error={ shouldShowError && error }
			hasError={ shouldShowError }
			id="collections-and-refunds-account-type-input-field"
			items={ ACCOUNT_TYPES }
			label={ $.__('Account Type') }
			onChange={ (selectedTypes): void => {
				setAccountTypes(selectedTypes);
				setError(getAccountTypesError(selectedTypes as Array<BankAccountType>));
				setCanShowError(true);
			} }
			onClose={ (): void => setCanShowError(true) }
			required={ true }
			selectedValues={ accountTypes }
			width={ 8 }
		/>
	);
}
