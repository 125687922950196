import Ajax from '@utils/ajax';
import SavesToDisclaimer from 'Esignatures.mod/util/saves-to-disclaimer-call';

/**
 * JS for 'Send Signature Request' modal on documents tab
 */
var SendSignatureRequest = {
	_additionalSignersUri: '/ajax/employees/files/update_additional_signers.php?id=',
	_selectors: {
		additionalSigners: '.js-additional-signers',
		templates: '.js-esignature-template-list'
	},

	init() {
		this.$ = BambooHR.Utils.setupWrappers(this._selectors);
		this._attachHandlers();
	},
	primaryActionFunction() {
		window.BambooHR.Modal.setState({ isProcessing: true }, true);
		this.send(this.employeeId).then(({ success }) => {
			if (success) {
				window.BambooHR.Modal.setState({ isOpen: false });
			} else {
				window.BambooHR.Modal.setState({ isProcessing: false }, true);
			}
		});
	},
	/**
	 * Open the stacked modal for selection of document and initiation of
	 * signature request
	 *
	 * @param int employeeId the employee's id
	 *
	 * @return {undefined}
	 */
	openModal(employeeId) {
		const url = `/ajax/employees/files/send_signature_request.php?employeeId=${ employeeId }`;

		window.BambooHR.Modal.setState({
			isLoading: true,
			isOpen: true
		});
		this.employeeId = employeeId;
		Ajax.get(url).then(({ data }) => {
			if (data) {
				const { html } = data;

				window.BambooHR.Modal.setState({
					content: (<div dangerouslySetInnerHTML={ { __html: html } } />),
					isLoading: false,
					title: $.__('Request Signature'),
					primaryAction: null, //default is disabled,
					primaryActionText: $.__('Send Request')
				}, true);

				this.init();
			}
		});

	},

	/**
	 * Send selected employees/groups/locations to server for intitialization.
	 *
	 * @param int workflowId esignature's workflow.id
	 *
	 * @return {undefined}
	 */
	send(employeeId) {
		return $.post(
			'/ajax/employees/files/send_signature_request.php?employeeId=' + employeeId,
			$('.js-send-signature-request-form').serialize(),
			function(response) {
				setMessage(response.error, response.success === true ? 'success' : 'error');
				if (response.success === true) {
					SimpleModal.closeModal();
				}
				SimpleModal.Footer.Buttons.stopProcessing();
			},
			'json'
		);
	},

	_attachHandlers() {
		document.querySelector(this._selectors.templates).addEventListener('ba:selectChange', ({ detail }) => {
			const { value } = detail;
			if (value) {
				SavesToDisclaimer.fetchDisclaimer(value);
				this.$.additionalSigners.load(`${ this._additionalSignersUri }${ value }`);
				window.BambooHR.Modal.setState({
					primaryAction: () => this.primaryActionFunction()
				}, true);
			} else {
				SavesToDisclaimer.hideDisclaimer();
				this.$.additionalSigners.html('');
				window.BambooHR.Modal.setState({ primaryAction: null }, true);
			}
		});
	}
};


export default SendSignatureRequest;
