import ItemErrorBoundary from '../error-boundary';
import { AvatarImage } from './avatar-img';

const Avatar = ({ src, size }) => (
	<ItemErrorBoundary>
		<AvatarImage img={ src } size={ size } />
	</ItemErrorBoundary>
);

export default Avatar;
