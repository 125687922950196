import { ChangeEvent } from 'react';
import classNames from 'classnames';

export type CheckboxItemValue = string | number;

export interface CheckboxItem {
	note?: string | JSX.Element;
	text: string;
	value: CheckboxItemValue;
}

interface CheckboxGroupProps {
	id: string;
	disabled?: boolean;
	items: CheckboxItem[];
	label?: string;
	name?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	required?: boolean;
	values: CheckboxItemValue[];
}

export const CheckboxGroup = (props: CheckboxGroupProps): JSX.Element => {
	const {
		id,
		disabled,
		items,
		label,
		name,
		onChange,
		required,
		values,
	} = props;

	const generateIdForItem = (index: number): string => {
		return `${ id }_${ index }`;
	};

	return (
		<fieldset className="fab-CheckboxGroup">
			{ label && (
				<legend
					className={ classNames(
						'fab-CheckboxGroup__legend',
						{ 'fab-CheckboxGroup__legend--required': required }
					) }
				>
					{ label }
				</legend>
			) }
			{ items.map((item, index) => {
				return (
					<div
						key={ item.value }
						className="fab-Checkbox"
					>
						<input
							checked={ values.some(value => value === item.value) }
							className="fab-Checkbox__input"
							disabled={ disabled }
							id={ generateIdForItem(index) }
							name={ name }
							onChange={ onChange }
							type="checkbox"
							value={ item.value }
						/>
						<label
							className="fab-Checkbox__label"
							htmlFor={ generateIdForItem(index) }
						>
							{ item.text }
						</label>
						{ item.note && (
							<div className="fab-Checkbox__note">{ item.note }</div>
						) }
					</div>
				);
			}) }
		</fieldset>
	);
};
