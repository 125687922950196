import { Ref } from 'react';
import classNames from 'classnames';

import { Select } from '@fabric/select';
import { SelectProps } from '@bamboohr/fabric';

export type SelectItemValue = string | number | boolean;

export interface SelectItem {
	text: string;
	value: SelectItemValue;
}

interface SelectFieldProps {
	disabled?: boolean;
	error?: string;
	hasError?: boolean;
	id?: string;
	biId?: string;
	inputRef?: Ref<HTMLSelectElement>;
	items: SelectItem[];
	isClearable?: boolean;
	label?: string;
	menuWidth?: SelectProps['menuWidth'];
	name?: string;
	note?: string;
	onChange: (newValues: SelectItemValue[], newItems: SelectItem[]) => void;
	onClose?: () => void | Promise<void>;
	renderOptionContent?: (item: SelectItem) => JSX.Element | null;
	required?: boolean;
	selectedValues: SelectItemValue[];
	showSearch?: ('always' | 'auto' | 'never');
	width?: number;
}

export const SelectField = (props: SelectFieldProps): JSX.Element => {
	const {
		disabled,
		error,
		hasError = false,
		id,
		inputRef,
		isClearable = false,
		items,
		label,
		menuWidth,
		name,
		note,
		onChange,
		onClose,
		renderOptionContent,
		required = false,
		selectedValues,
		showSearch = 'auto',
		width = 9,
		biId,
	} = props;

	const isErrored = hasError || Boolean(error);

	return (
		<div className="fab-FormColumn">
			{ label && (
				<label
					className={ classNames(
						'fab-Label',
						{ 'fab-Label--required': required },
						{ 'fab-Label--error': isErrored }
					) }
					htmlFor={ id }
				>
					{ label }
				</label>
			) }
			{/* @ts-expect-error TODO: constrain type of width to SelectProps['width'] */}
			<Select
				biId={ biId }
				condition={ isErrored ? 'error' : null }
				id={ id }
				inputRef={ inputRef }
				isClearable={ isClearable }
				isDisabled={ Boolean(disabled) }
				items={ items }
				menuWidth={menuWidth}
				name={ name }
				onChange={ onChange }
				onClose={ onClose }
				renderOptionContent={ renderOptionContent }
				required={ required }
				selectedValues={ selectedValues }
				showSearch={ showSearch }
				width={ width }
			/>
			{ note && (
				<span className="fab-FormNote">
					{ note }
				</span>
			) }
			{ error && (
				<span className="fab-FormNote fab-FormNote--error">
					{ error }
				</span>
			) }
		</div>
	);
};
