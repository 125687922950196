import { LOCAL_STORAGE_KEY_NAME, UPDATE_BANNER_DATA_EVENT } from "./constants"
import { BannerData } from './types';

export const removeBannerLocalStorage = () => {
	window.localStorage.removeItem(LOCAL_STORAGE_KEY_NAME);
};

export const updateBannerData = (stepKey: BannerData['stepKey']) => {
	document.dispatchEvent(new CustomEvent(UPDATE_BANNER_DATA_EVENT, { detail: { stepKey } }));
}
