import { IconV2Name } from '@bamboohr/fabric';
import { TimeOffIcon } from '../types';

export type TimeOffIconMap = Record<TimeOffIcon, IconV2Name>;

/**
 * For use with `IconV2`
 *
 * @example <IconV2 name={timeOffIconV2['time-off-luggage']} size={16} />;
 */
export const TIME_OFF_ICON: TimeOffIconMap = {
	'time-off-luggage': 'briefcase-regular',
	'time-off-calendar': 'calendar-clock-regular',
	'happy-calendar': 'calendar-heart-regular',
	'palm-trees': 'island-tropical-regular',
	'camping-trailer': 'rv-regular',
	airplane: 'plane-regular',
	'band-aid': 'bandage-regular',
	'first-aid-kit': 'clipboard-medical-regular',
	'joining-hands': 'handshake-regular',
	selfie: 'circle-user-regular',
	graduation: 'graduation-cap-regular',
	family: 'family-regular',
	home: 'house-regular',
	'teddy-bear': 'teddy-bear-regular',
	'baby-carriage': 'baby-carriage-regular',
	'birthday-cake': 'cake-candles-regular',
	gavel: 'gavel-regular',
	medal: 'medal-regular',
};
