import {
	stack as d3Stack,
	stackOffsetNone
} from 'd3-shape';
import {
	values as getValues,
	map,
	sum
} from 'lodash';
import {
	mouseClick,
	mouseEnter,
	mouseLeave,
	mouseOver
} from 'Charts.mod/event-functions';
import {
	scaleLinear,
	scaleOrdinal
} from 'd3-scale';
import { max } from 'd3-array';
import { selectAll } from 'd3-selection';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/**
 * Ideal Default Settings
{
	bar: {
		colors: ['#C0C0C0', '#499E2A', '#939393'], // Green - Gray - Lighter Gray
		height: 60,
		padding: 12
	},
	chartClass: REPORT_BEM_BLOCK,
	container: {
		flexHeight: true,
		height: 0,
		width: 900
	}
};
 */

/**
 * Expected data structure
{
	"type": "stacked-summary",
	"barKeys": [
		"completed",
		"inProgress",
		"notStarted"
	],
	"bars": [
		{
			"value": {
				"completed": 48,
				"inProgress": 20,
				"notStarted": 32
			},
			"summary": {}
		},
		{
			"value": {
				"completed": 55,
				"inProgress": 15,
				"notStarted": 30
			},
			"summary": {
				"value": 29,
				"displayText": "29 of 55 Follow Up Notes",
				"icon": {
					"height": 14,
					"width": 19,
					"name": "#notes-speech-bubble-19x14"
				}
			}
		}
	]
}
 */

/**
 * Creates the top aligned labels bar chart
 *
 * @param {object} data Formatted Data array containing objects
 */
export function drawChart(data) {
	const settings = this.getSettings();

	const {
		container: containerSettings,
		emptyCheckCallback,
		emptyLabelCallback
	} = settings;
	const {
		bottom = 0,
		left = 0,
		right = 0,
		top = 0
	} = containerSettings.margin;
	const {
		colors,
		cursor = 'default',
		height: barHeight,
		iconPath = '/images/bicons/notes-speech-bubble.png',
		padding: barPadding,
		summaryBarHeight = 25
	} = settings.bar;

	const width = this._getContainerWidth() - left - right;
	const height = containerSettings.height - top - bottom;
	const isEmpty = emptyCheckCallback(data);

	const getValuesFromObject = (obj) => {
		const valuesArray = [];
		// using the keys to pull only those values into a 'values' array
		map(obj, function(value, property) {

			// iterate over barKeys to pull out only those values
			data.barKeys.forEach((key) => {
				if (key == property) {
					valuesArray.push(+value);
				}
			});
		});

		return valuesArray;
	};

	const formatValueLength = (data) => {
		return data.reduce((prev, value) => {
			return prev + value;
		}, 0);
	};

	const findHighestSumArray = (arr) => {
		let highestSumArray = [];

		arr.forEach((item) => {
			if (sum(item) > sum(highestSumArray)) {
				highestSumArray = item;
			}
		});

		return highestSumArray;
	};

	const barValuesArray = data.bars.map(item => getValuesFromObject(item.value));

	const largestArray = findHighestSumArray(barValuesArray);

	// Convert incoming data to stackable data structure
	const stackableData = data.bars.map((item, index) => {
		item.value.summary = item.summary;

		return item.value;
	});

	// Replicate what the value string will look like
	const valueString = formatValueLength(largestArray);

	const stack = d3Stack().keys(data.barKeys).offset(stackOffsetNone);
	const series = stack(stackableData);

	const zScale = scaleOrdinal()
		.domain(stackableData)
		.range(colors);

	// Add up each bars worth of values into an array of totals
	const highestValues = stackableData.map((item) => {
		const valuesArray = getValuesFromObject(item);
		const sums = sum(valuesArray);

		return sums;
	});

	// Get the highest total value from each bar
	const maxValue = max(highestValues);

	const xScale = scaleLinear()
		.domain([0, maxValue])
		.rangeRound([0, width]);

	const getBarWidth = (d) => {
		const value = xScale(d[1]) - xScale(d[0]) - 1;

		return value < 0 ? 0 : value;
	};

	const getInsideLabelY = (d, i) => {
		const { data: { summary: { displayText } } } = d;

		let summaryBarOffset = 0;

		if (displayText && d[0] === 0) {
			summaryBarOffset = summaryBarHeight / 2;
		}
		const yPosition = ((i * (barHeight + barPadding) + barPadding) + (barHeight / 2) + 6) - ifFeature('encore', 0, summaryBarOffset);

		return yPosition;
	};

	const getInsideLabelX = (d, i) => {
		if (isEmpty[i]) {
			return 15;
		}

		return (xScale(d[1]) - xScale(d[0])) / 2 + xScale(d[0]) - 6;
	};

	const getInsideLabelText = (d, i) => {
		if (isEmpty[i]) {
			const value = d[1] - d[0];
			return emptyLabelCallback(value);
		}

		const value = Math.round(((d[1] - d[0] || 0) / valueString) * 100);

		return value && value > 5 ? `${ value }%` : '';
	};

	const getSummaryWidth = (d, i) => {
		if (d.summary && d.summary.displayText) {
			const value = d.summary.value;

			return value ? xScale(value) - 1 : 5;
		}

		return 0;
	};

	const getSummaryText = (d) => {
		if (d.summary && d.summary.displayText) {
			return d.summary.displayText;
		}
	};

	// DATA BIND (update selector)
	const layer = this._g.selectAll(this._getClassList('__series'))
		.data(series);

	const layerEnter = layer
		.enter()
		.append('g')
		.attr('id', d => d.key)
		.attr('fill', (d, i) => zScale(i))
		.attr('class', d => this._buildClassList('__series'));

	const layerMerged = layerEnter.merge(layer);

	const barsUpdate = layerMerged.selectAll(this._getClassList('__bar'))
		.data(d => d);

	const insideLabelUpdate = layerMerged.selectAll(this._getClassList('__insideLabel'))
		.data(d => d);

	const summaryUpdate = this._g.selectAll(this._getClassList('__summaryBar'))
		.data(data.bars);

	const summaryLabelUpdate = this._g.selectAll(this._getClassList('__summaryLabel'))
		.data(data.bars);

	// EXIT
	barsUpdate
		.exit()
		.transition()
		.duration(750)
		.style('opacity', 0)
		.remove();

	insideLabelUpdate
		.exit()
		.transition()
		.duration(750)
		.style('opacity', 0)
		.remove();

	summaryUpdate
		.exit()
		.transition()
		.duration(750)
		.style('opacity', 0)
		.remove();

	summaryLabelUpdate
		.exit()
		.transition()
		.duration(750)
		.style('opacity', 0)
		.remove();

	// UPDATE
	let transitions = 0;
	barsUpdate
		.data(d => d)
		.transition()
		.delay(function(d, i) {
			return i * 50;
		})
		.duration(750)
		.attr('x', function(d, i) { return xScale(d[0]) + 1; })
		.attr('width', getBarWidth)
		.on('start', () => {
			transitions++;
		})
		.on('end', () => {
			if (--transitions === 0) {
				this._dynamicallyAdjustSVGHeight();
			}
		});

	insideLabelUpdate
		.transition()
		.duration(750)
		.attr('y', function(d, i) {
			return getInsideLabelY(d, i);
		})
		.attr('x', getInsideLabelX)
		.text(getInsideLabelText);

	summaryUpdate
		.data(data.bars)
		.transition()
		.delay(function(d, i) {
			return i * 50;
		})
		.duration(750)
		.attr('x', 1)
		.attr('width', getSummaryWidth)
		.on('start', () => {
			transitions++;
		})
		.on('end', () => {
			if (--transitions === 0) {
				this._dynamicallyAdjustSVGHeight();
			}
		});

	summaryLabelUpdate
		.transition()
		.delay(function(d, i) {
			return i * 50;
		})
		.duration(750)
		.text(getSummaryText);

	// ENTER
	barsUpdate.enter()
		.append('rect')
		.attr('class', this._buildClassList('__bar'))
		.attr('cursor', cursor)
		.attr('shape-rendering', 'crispEdges')
		.attr('height', barHeight)
		.attr('y', function(d, i) {
			return (i * (barHeight + barPadding) + barPadding);
		})
		.attr('x', function(d, i) { return xScale(d[0]) + 1; })
		.attr('width', getBarWidth)
		.on('mouseenter', mouseEnter)
		.on('click', mouseClick)
		.on('mouseover', mouseOver)
		.on('mouseleave', mouseLeave);

	insideLabelUpdate.enter()
		.append('text')
		.attr('class', this._buildClassList('__insideLabel'))
		.attr('cursor', cursor)
		.attr('y', getInsideLabelY)
		.attr('x', getInsideLabelX)
		.style('fill', ifFeature('encore', undefined, '#fff'))
		.style('pointer-events', 'none')
		.text(getInsideLabelText);

	summaryUpdate.enter()
		.append(ifFeature('encore', undefined, 'rect'))
		.attr('class', this._buildClassList('__summaryBar'))
		.attr('fill', 'rgba(0,0,0,0.2)')
		.attr('cursor', cursor)
		.attr('shape-rendering', 'crispEdges')
		.attr('height', summaryBarHeight)
		.attr('y', function(d, i) {
			return (i * (barHeight + barPadding) + barPadding + (barHeight - summaryBarHeight));
		})
		.attr('x', 1)
		.style('pointer-events', 'none')
		.attr('width', getSummaryWidth);

	summaryUpdate.enter()
		.append(ifFeature('encore', undefined, 'svg:image'))
		.attr('class', this._buildClassList('__summaryIcon'))
		.attr('x', 15)
		.attr('y', function(d, i) {
			return (i * (barHeight + barPadding) + barPadding + (barHeight - summaryBarHeight)) + 5;
		})
		.attr('height', (d) => {
			if (d.summary && d.summary.icon) {
				return d.summary.icon.height;
			}
		})
		.attr('width', (d) => {
			if (d.summary && d.summary.icon) {
				return d.summary.icon.width;
			}
		})
		.attr('xlink:href', (d) => {
			if (d.summary && d.summary.displayText) {
				return iconPath;
			}
		});

	summaryLabelUpdate.enter()
		.append(ifFeature('encore', undefined, 'text'))
		.attr('class', this._buildClassList('__summaryLabel'))
		.attr('cursor', cursor)
		.attr('y', function(d, i) {
			return (i * (barHeight + barPadding) + barPadding + (barHeight - summaryBarHeight));
		})
		.attr('x', 40) // start text after icon
		.attr('dy', '1.3em')
		.attr('fill', 'rgba(255, 255, 255, 0.7)')
		.style('pointer-events', 'none')
		.text(getSummaryText);

}
