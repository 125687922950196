/*
	Return the global vars for the company
	Use this in lieu of using the globals directly
	to make it easier to switch to calling an api
	for this information in the future.
*/

export default function getCompanyInfo() {
	return Promise.resolve({
		companyCountryId: global.GLOBAL_COMPANY_COUNTRY_ID,
		companyLogo: global.GLOBAL_COMPANY_LOGO,
		isTrial: global.GLOBAL_IS_TRIAL === 'true',
		...global.SESSION_COMPANY,
	});
}
