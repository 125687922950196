import classNames from 'classnames';
import { Icon, IconV2 } from '@bamboohr/fabric';
import { useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { Message } from '@bamboohr/utils/lib/message';

import './eeo-1-description.styl';

export function EEO1Description(props) {
	const { description, example } = props;
	const [isVisible, setIsVisible] = useState(false);

	return (
		<div className="EEO1Description">
			<button
				className="EEO1Description__toggle fab-TextButton fab-link"
				onClick={ () => _handleToggle(isVisible, setIsVisible) }
				type="button"
			>
				<span
					className={ classNames('EEO1Description__caret', {
						'EEO1Description__caret--isRotatedDown': isVisible,
					}) }
				>
					{ifFeature('encore', 
						<IconV2 
							name="caret-right-solid"
							color="neutral-strong"
							size={14}
						/>, 
						<Icon name="fab-caret-down-5x9" />
					)}
					
				</span>
				{ $.__('Description and examples') }
			</button>
			<div
				className={ classNames('EEO1Description__body', {
					'EEO1Description__body--isVisible': isVisible,
				}) }
			>
				<p className="EEO1Description__paragraph">{ description }</p>
				<p className="EEO1Description__paragraph">
					<Message strong={ { tag: 'strong' } } text={ $._('**Includes:**') } />
					&nbsp;
					{ example }
				</p>
			</div>
		</div>
	);
}

function _handleToggle(isVisible, setIsVisible) {
	return setIsVisible(!isVisible);
}
