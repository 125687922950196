import {
	isEqual,
} from 'lodash';
import {
	Component,
	createRef,
} from 'react';

import {
	AreaChart,
} from 'Charts.mod';
import {
	MOUSE_OVER,
} from 'Charts.mod/event-names';

import './area-chart.styl';

export default class AreaChartComponent extends Component {
	
	_setupListeners() {
		const {onMouseOver} = this.props;

		if (typeof onMouseOver === 'function') {
			this._element.current.addEventListener(MOUSE_OVER, (event) => {
				onMouseOver(event);
			});
		}
	}

	_element = createRef();

	componentDidMount() {
		const { data, settings } = this.props;

		this._settings = settings;

		this._areaChart = new AreaChart(this._element.current, settings);
		this._areaChart.draw(data);

		this._setupListeners();
	}

	shouldComponentUpdate(nextProps) {
		const { data } = this.props;
		return !isEqual(data, nextProps.data);
	}

	componentDidUpdate() {
		const { data } = this.props;
		this._areaChart.draw(data);
	}

	render() {
		return (
			<div ref={ this._element }></div>
		);
	}

}
