import { makeUid } from 'BambooHR.util';

/**
 * This js module includes functions for Social sharing of urls, with a few utility functions
 * (ie. facebook, linkedin and twitter)
 *
 * The share functions all require a url, with some of them requiring an additional options object.
 *
 * Currently they are being use in applicant tracking and jobs
 */


/**
 * Click handler for Facebook Icon
 *
 * @param  {String} url The url to share
 */
export function shareFacebook(url) {
	window.open(
		`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(getProtocol(url))}`,
		'facebook-share-dialog',
		centerDialog(626, 436)
	);
}

/**
 * Click handler for LinkedIn Icon
 *
 * @param  {String} url     The url to share
 * @param  {Object} options An options object
 */
export function shareLinkedIn(url, options) {
	url += `?uid=${makeUid()}`;

	window.open(
		`http://www.linkedin.com/shareArticle?mini=true&url=${ encodeURIComponent(getProtocol(url)) }` +
		`&title=${ encodeURIComponent(options.shareText) }` +
		'&summary=' +
		'&source=BambooHR',
		'linkedin-share-dialog',
		centerDialog(570, 476)
	);
}

/**
 * Click handler for Twitter Icon
 *
 * @param  {String} url     The url to share
 * @param  {object} options An options object
 */
export function shareTwitter(url, options) {
	window.open(
		`${'https://twitter.com/intent/tweet' + '?text='}${encodeURIComponent(options.shareText)}&original_referer=${encodeURIComponent(
			document.location.href
		)}&related=${options.related}&source=tweetbutton` + `&url=${encodeURIComponent(getProtocol(url))}`,
		'twitter-share-dialog',
		centerDialog(500, 442)
	);
}

/**
 * Simple function to make sure correct protocol (http or https is included)
 * 
 * @param  {String} url The url to check
 * @return {String}     The url with protocol (added if wasn't included)
 */
function getProtocol(url) {
	if (url.indexOf('http') < 0 || url.indexOf('https') < 0) {
		return `${location.protocol}//${url}`;
	}
	return url;
}

/**
 * Center Social Dialogs
 * 
 * @param  {Number} width  The width of the new window
 * @param  {Number} height The height of the new window
 * 
 * @return {String}        A config string for window.open
 */
export function centerDialog(width, height) {
	
	const left = (document.body.getBoundingClientRect().width - width) / 2;
	const top = (document.body.getBoundingClientRect().height - height) / 2;
	
	return `height=${height}, width=${width}, top=${top}, left=${left}`;
}
