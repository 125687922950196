import { Message } from '@bamboohr/utils/lib/message';

export function createReadableHistory(eventDetails) {
	return eventDetails.map((detail) => {
		const { detailType, from, to } = detail;

		switch (detailType) {
			case 'clockedIn': {
				return $.__('Clocked in');
			}
			case 'clockedInProject': {
				return (
					<Message
						params={ [to] }
						text={ $._('Clocked in to **{1}**') }
					/>
				);
			}
			case 'clockInUpdated': {
				return (
					<Message
						params={ [from, to] }
						text={ $._('Clock in updated from **{1}** to **{2}**') }
					/>
				);
			}
			case 'clockedOut': {
				return $.__('Clocked out');
			}
			case 'projectSet': {
				return (
					<Message
						params={ [to] }
						text={ $._('Project set to **{1}**') }
					/>
				);
			}
			case 'projectUpdated': {
				return (
					<Message
						params={ [to] }
						text={ $._('Project updated to **{1}**') }
					/>
				);
			}
			case 'projectRemoved': {
				return (
					<Message
						params={ [from] }
						text={ $._('Removed project **{1}**') }
					/>
				);
			}
			case 'timeEntryAdded': {
				return (
					<Message
						params={ [to] }
						text={ $._('Time entry added for **{1}**') }
					/>
				);
			}
			case 'timeEntryUpdated': {
				return (
					<Message
						params={ [from, to] }
						text={ $._('Time entry updated from **{1}** to **{2}**') }
					/>
				);
			}
			case 'timeEntryDeleted': {
				return (
					<Message
						params={ [from] }
						text={ $._('Deleted time entry: **{1}**') }
					/>
				);
			}
			case 'dailyTotalAdded': {
				return (
					<Message
						params={ [to] }
						text={ $._('Daily total added for **{1}**') }
					/>
				);
			}
			case 'dailyTotalUpdated': {
				return (
					<Message
						params={ [from, to] }
						text={ $._('Daily total updated from **{1}** to **{2}**') }
					/>
				);
			}
			case 'dailyTotalDeleted': {
				return (
					<Message
						params={ [from] }
						text={ $._('Deleted daily total: **{1}**') }
					/>
				);
			}
			case 'noteAdded': {
				return $.__('Note added');
			}
			case 'noteUpdated': {
				return $.__('Note updated');
			}
			case 'noteRemoved': {
				return $.__('Note Removed');
			}
			case 'timesheetSentForApproval': {
				return $.__('Timesheet sent for approval');
			}
			default: {
				// Just in case (lol) we get a type we don't know, still output the details to the user
				return JSON.stringify(detail);
			}
		}
	});
}
