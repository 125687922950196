import {scaleLinear, scaleOrdinal} from 'd3-scale';
import {select} from 'd3-selection';
import {stack, stackOrderReverse} from 'd3-shape';
import {defaultsDeep, throttle, inRange} from 'lodash';

import { getBrandColor } from 'BambooHR.util';
import { isEnabled } from 'FeatureToggle.util';
import Popover from 'Popover.mod';

/******************************************************************************
	Data contract for bar chart

	{
		title: {
			text: "247 Candidates (51% of Total Candidates)"
			width: 234 // width of the title text
			fontFamily: "BhrHeaderFont,Trebuchet MS;",
			fontSize: "14px",
			fill: "#777",
			icon: `Raw SVG`
		},
		popover: {
			title: '',
			content: ''
		},
		colors: '#ddd', // grey
		container: {
			height: 52,
			width: 887
		},
		bar: {
			height: 46,
			width: 100 // calculated difference width between top value and itself.,
		}
	}

******************************************************************************/

const CLASS_NAME = 'BarChartStacked';
const CHART_MAX = 1140;
const CHART_MIN = 993;
const DEFAULT_OPTIONS = {
	color: '#ddd',
	container: {
		height: 62,
		width: 993
	},
	bars: [
		{
			title: {
				fontFamily: 'BhrHeaderFont, \'Trebuchet MS\'',
				fontSize: '14px',
				fill: [
					'#fff', // inside fill
					getBrandColor() // outside fill
				],
			},
			bar: {
				height: 62,
				leftMargin: 15
			}
		},
		{
			fill: "rgba(0, 0, 0, 0.2)",
			title: {
				fontFamily: 'BhrHeaderFont, \'Trebuchet MS\'',
				fontSize: '14px',
				fill: [
					'#fff', // inside fill
					getBrandColor() // outside fill
				],
			},
			bar: {
				height: 23,
				leftMargin: 15
			}
		}
	]
};

let fillIndex = 0;

export default class BarChartStacked {
	constructor(selector, options = DEFAULT_OPTIONS) {

		this._selector = selector;
		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);
		this._createDom(this._options);

		const throttled = throttle(this.resize, 20);
		window.addEventListener('resize', throttled)
	}

	/**
	 * Creates the main SVG for this chart with the required definitions
	 * @param {object} data Formatted Data ready to be displayed
	 */
	_createDom(data) {
		let container = select(this._selector).append('div')
			.attr('class', `${ CLASS_NAME }`)
			.style('height', `${ this._options.container.height }px`);


		this._svg = container.append('svg')
			.attr('class', `${ CLASS_NAME }__bar`)
			.attr('viewBox', `0 0 ${ this._options.container.width } ${ this._options.container.height }`)
			.style('height', `${ this._options.container.height }px`)
			.style('width', `${ this._options.container.width }px`);
	}

	/**
	* position the title inside/outside of the bar
	*/
	titlePosition = (d, i, node, x) => {
		let topBar = d.bar;
		let y = (d.bar.height / 3) - 1;
		if (i > 0) {
			topBar = node[0].__data__.bar;
			y = topBar.height - (d.bar.height / 2);
		}
		let position;

		// title width is the width of the title text, the icon and the margins between icon and text and the container
		let titleWidth = d.title.width + topBar.icon.width + topBar.leftMargin + 20
		// if the title width is smller than the bar graph, shif t the bar details outside bar graph and update the fill colors
		if (titleWidth < x(topBar.width)) {
			position = topBar.leftMargin;
			fillIndex = 0;
		} else {
			position = x(topBar.width) + topBar.leftMargin;
			//set the fill index to the outside fill
			fillIndex = 1;
		}
		return 'translate(' + position + ', ' + y + ')';
	}

	/**
	* Build the rect for the bar graph
	*/
	makeRect = (d, i, node, x) => {
		let height = d.bar.height;
		let radius = d.bar.radius || 3;
		// the default for bar is rounding corners on the right side of the bar. Pass 0 no rounding
		let width;
		if (i === 0) {
			width = x(d.bar.width || 1); // Set a minimum value, UX always wants to display at a minimum a sliver of the bar graph
		} else {
			// set the range to the first bar's width

			let barWidth = x(node[0].__data__.bar.width || 1);
			x.range([0, barWidth])
			width = x(d.bar.width);

			if (width <= 9) {
				width = 9;
			}

		}

		return `
			M 0, ${ d.bar.y }
			h ${ width - radius }
			a ${ radius }, ${ radius } 0 0 1 ${ radius }, ${ radius }
			v ${ height - 2 * radius }
			a ${ radius }, ${ radius } 0 0 1 -${ radius }, ${ radius }
			h ${ radius - width }
			z
		`;

	}

	resize = () => {
		let reportWidth = document.querySelector(".ReportBody").offsetWidth;
		let legendWidth = document.querySelector(".ActiveStatusChartSetLegend").offsetWidth;
		if (inRange(reportWidth, CHART_MIN, CHART_MAX)) {
			this._options.container.width = reportWidth - legendWidth;
			var bars = document.querySelectorAll('.BarChartStacked');
			for (var i = bars.length; i--;) { // reverse
				bars[i].style.width = reportWidth - legendWidth + 'px';
			}
			let x = scaleLinear().range([0, this._options.container.width]).domain([0, 100]);
			this._updateBars(this._options.bars, x);
		}
	}


	/**
	 * Draws each element in the order needed for the chart to display properlly
	 * @param {object} data Formatted Data ready to be bard and displayed
	 */
	draw(data) {

		let x = scaleLinear()
			.range([0, data.container.width])
			.domain([0, 100]);

		this._drawBars(data.bars, x);

	}

	/**
	 * updates the containers width and the titles position
	 * @param {object}   barData Formatted data
	 * @param {function} x d3 function for the x axis
	 */
	_updateBars(barData, x) {
		let updateBar = this._svg.selectAll('.bar').data(barData)
		updateBar.select('path')
			.attr('d', (d, i, node) => {
				return this.makeRect(d, i, node, x)
			});
		updateBar.select('g')
			.attr('transform', (d, i, node) => {
				x.range([0, this._options.container.width]);
				return this.titlePosition(d, i, node, x)
			})
			.attr('fill', d => d.title.fill[fillIndex])
		updateBar.select('text')
			.styles({
				'fill': d => d.title.fill[fillIndex]
			})
	}

	/**
	 * Draws the containers, and the individual cells of the bar chart
	 * @param {object}   barData Formatted data
	 * @param {function} x d3 function for the x axis
	 */
	_drawBars(barData, x) {
		let BAR = this._svg.selectAll('g.layer').data(barData)
			.enter()
			.append('g')
			.attr('class', 'bar')
			.attr('fill', d => d.fill);
		BAR.append('path')
		// the default for bar is raunded corners on the right side of the bar.
			.attr("d", (d, i, node) => {
				return this.makeRect(d, i, node, x)
			})
			.on('mouseenter', (d, index, nodes) => {
				this._onPathEnter(nodes[index].parentNode, d);
			});

		// Append new svg element to contain the bar details
		let title = BAR.append('g')
			.attr('transform', (d, i, node) => {
				x.range([0, this._options.container.width]);
				return this.titlePosition(d, i, node, x)
			})
			.attr('fill', d => d.title.fill[fillIndex])
			.attr('height', d => d.bar.height)
			.attr('width', (d) => {
				return d.title.width + d.bar.icon.width + d.bar.leftMargin;
			});
			// append anothr SVG element to contain the icon
		let titleIcon = title.append('svg')
			.attr('width', d => d.bar.icon.width)
			.attr('height', d => d.bar.icon.height)
			.attr('viewBox', '0 0 18 18')
			.attr('x', 0)
			.attr('y', d => -d.bar.icon.height / 2)
			.attr('xmlns:xlink', 'http://www.w3.org/1999/xlink');
			// for reasons, it will not render the icon if this append is chained to the above append.
		titleIcon.append('use')
			.attr('xlink:href', (d) => {
				return d.bar.icon.name;
			});
		// append the bar details text
		title.append('text')
			.text(d => d.title.text)
			.attr('x', (d) => {
				return d.bar.icon.width + 5;
			})
			.attr('y', 0)
			.attr('dy', '0.7ex')
			.styles({
				'font-size': (d, i) => { return this._options.bars[i].bar.fontSize },
				'fill': d => d.title.fill[fillIndex]
			})
	}

	/**
	 * Event handler for when the mouse has entered a bar path
	 * @param  {object} path  The path that triggered the event
	 * @param  {object} d  The data represented by the path
	 */
	_onPathEnter(path, d) {
		select(path).transition()
			.duration(150)
			.attr('y', 0)
			.attr('height', d.bar.height);
		if (!Popover.hasInstance(path)) {
			Popover.create(path, {
				template: {
					name: 'popover-standard',
					data: {
						title: d.popover.title,
						content: d.popover.content,
						rawContent: true,
						popoverClass: `${ CLASS_NAME }__popover ${ this._options.chartClass }__popover`,
						titleCssOverride: {
							color: '#222222',
							'text-align': 'center',
							'font-weight': '600',
							'font-size': '18px'
						},
						contentCssOverride: {
							'text-align': 'center'
						}
					}
				},
				position: 'top',
				triggerEvent: 'hover',
				showImmediately: true,
				closeX: false,
				push: 2,
				delay: 75
			});
		}
	}
}
