import {
	BankAccountType,
} from '../../types';

interface AccountTypeOption {
	text: string;
	value: BankAccountType;
}

export const ACCOUNT_TYPES: Array<AccountTypeOption> = [
	{
		text: 'Checking',
		value: 'checking',
	},
	{
		text: 'Savings',
		value: 'savings',
	},
];

export function getAccountTypesError(accountTypes: Array<BankAccountType>): string {
	try {
		if (!accountTypes.length) {
			throw new Error('Required');
		}

		return null;
	} catch (error) {
		return error.message;
	}
}
