import React, { Fragment } from 'react';
import BaTabs from './index';
import { BaTabsTheme, subcomponents } from './themes';
import {
	find,
	isEmpty,
	isFunction,
	isPlainObject,
	isString,
	isUndefined,
	noop,
} from 'lodash';
import {
	compileURL,
	resolveURL, // @todo: The util package will need to be updated to 0.3.1
	testURL,
} from '@utils/url/history';
import Ajax from '@utils/ajax';
import { Box, Divider } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const _data: WeakMap = new WeakMap();
const _tabsInstance: WeakMap = new WeakMap();
const _tabElem: WeakMap = new WeakMap();
const _tabContentElem: WeakMap = new WeakMap();
const _historyListeners = new WeakMap();

/**
 * Check if the `_data` `WeakMap` has a property
 * with the given `name`
 *
 * @param {Tab} tab
 * @param {string} name
 * @returns {boolean}
 */
function _hasDataVal(tab, name) {
	const data = _data.get(tab);

	return (
		!isUndefined(data) &&
		!isUndefined(data[name])
	);
}

/**
 * Get a value out of the `_data` `WeakMap`,
 * with a default
 *
 * @private
 * @param {Tab} tab
 * @param {string} name
 * @param {any} [defaultValue]
 * @returns {any}
 */
function _getDataVal(tab, name, defaultValue) {
	const data = _data.get(tab);

	if (!_hasDataVal(tab, name)) {
		return defaultValue;
	}

	return data[name];
}

/**
 * Set a value in the `_data` `WeakMap`
 *
 * @private
 * @param {Tab} tab
 * @param {string} name
 * @param {any} val
 * @returns {any}
 */
function _setDataVal(tab, name, val) {
	_data.get(tab)[name] = val;

	return val;
}

/**
 * Remove a value from the `_data` `WeakMap`
 * @private
 * @param {Tab} tab
 * @param {string} name
 */
function _deleteDataVal(tab, name) {
	delete _data.get(tab)[name];
}

/**
 * Get a value from:
 * 1. The _data WeakMap
 * 2. The BaTabs instance
 * 3. The Theme instance
 *
 * @private
 * @param {Tab} tab
 * @param {string} name
 * @param {any} [defaultValue]
 * @returns {any}
 */
function _getThemeVal(tab, name, defaultValue) {
	const value = find([
		_getDataVal(tab, name),
		tab.BaTabs[name],
		tab.BaTabs.props[name],
		tab.Theme[name],
	], val => !isUndefined(val));

	return isUndefined(value) ? defaultValue : value;
}

/**
 * Tab
 */
export default class Tab {

	/**
	 * The current BaTabs instance
	 */
	get BaTabs(): BaTabs {
		return _tabsInstance.get(this);
	}

	/**
	 * The current BaTabsTheme instance
	 */
	get Theme(): BaTabsTheme {
		return this.BaTabs.Theme;
	}

	/**
	 * The index (starts at 0)
	 */
	get index(): number {
		return _getDataVal(this, 'index');
	}

	/**
	 * The number (starts at 1)
	 */
	get number(): number {
		return _getDataVal(this, 'number');
	}

	/**
	 * The key
	 */
	get key(): string {
		return _getDataVal(this, 'key');
	}

	/**
	 * The group (for tab grouping)
	 */
	get group(): string {
		return _getDataVal(this, 'group');
	}

	get href() {
		return _getDataVal(this, 'href');
	}

	get includeDivider() {
		return !!_getDataVal(this, 'includeDivider');
	}

	get isLink() {
		return !!this.href;
	}

	get hasPath(): boolean {
		return !!this._pathname;
	}

	get path(): Object {
		const {
			pathname = null,
			query = null,
			hash = null,
		} = _getDataVal(this, 'path') || {};

		return { pathname, query, hash };
	}

	get _pathname(): string | void {
		const rootPath = this.BaTabs.path;
		const tabPath = this.path;

		if (
			!rootPath.pathname &&
			!tabPath.pathname
		) {
			return;
		}

		let {
			pathname,
		} = tabPath;
		let {
			pathname: rootPathname,
		} = rootPath;

		if (isFunction(rootPathname)) {
			rootPathname = rootPathname.call(this, {
				...this.props,
				...this.data,
			});
		}

		if (isFunction(pathname)) {
			pathname = pathname.call(this, {
				...this.props,
				...this.data,
			});
		}

		if (isString(rootPathname)) {
			pathname = resolveURL(rootPathname, pathname);
		}

		if (!isEmpty(pathname)) {
			return pathname;
		}
	}

	get _query(): Object | void {
		const rootPath = this.BaTabs.path;
		const tabPath = this.path;

		let {
			query,
		} = tabPath;
		let {
			query: rootQuery,
		} = rootPath;

		if (isFunction(rootQuery)) {
			rootQuery = rootQuery.call(this, {
				...this.props,
				...this.data,
			});
		}

		if (isFunction(query)) {
			query = query.call(this, {
				...this.props,
				...this.data,
			});
		}

		if (isPlainObject(rootQuery)) {
			query = {
				...rootQuery,
				...(query || {}),
			};
		}

		if (!isEmpty(query)) {
			return query;
		}
	}

	get pathname(): string | void {
		const {
			data,
			props,
		} = this;
		const {
			_pathname,
			_query,
		} = this;

		if (isEmpty(_pathname)) {
			return;
		}

		return compileURL(_pathname, { ...props, ...data }, _query || {});
	}

	/**
	 * Whether the tab is active
	 */
	get active(): boolean {
		return this.number === this.BaTabs.activeTabNumber;
	}

	/**
	 * Whether the tab is disabled
	 */
	get disabled(): boolean {
		return !!_getDataVal(this, 'disabled');
	}

	/**
	 * @type {Element}
	 */
	get tabElem() {
		return _tabElem.get(this);
	}

	/**
	 * @param {Element} elem
	 */
	set tabElem(elem) {
		if (
			elem instanceof Element &&
			elem !== this.tabElem
		) {
			_tabElem.set(this, elem);
			_deleteDataVal(this, 'width');
		}
	}

	/**
	 * Calculates the width of the tab element
	 *
	 * @type {number}
	 */
	get width() {
		if (_hasDataVal(this, 'width')) {
			return _getDataVal(this, 'width');
		}

		const {
			tabElem,
		} = this;

		if (
			!tabElem ||
			!tabElem.offsetParent
		) {
			return 0;
		}

		const {
			marginLeft,
			marginRight,
		} = window.getComputedStyle(tabElem);
		const { width } = tabElem.getBoundingClientRect();

		return _setDataVal(this, 'width', width + parseInt(marginLeft || '0') + parseInt(marginRight || '0'));
	}

	/**
	 * @type {Element}
	 */
	get tabContentElem() {
		return _tabContentElem.get(this);
	}

	/**
	 * @param {Element} elem
	 */
	set tabContentElem(elem) {
		if (
			elem instanceof Element &&
			elem !== this.tabContentElem
		) {
			_tabContentElem.set(this, elem);
		}
	}

	/**
	 * The primary text in the tab
	 */
	get primaryText(): string | React.Node {
		return _getDataVal(this, 'primaryText');
	}

	/**
	 * Optional secondary text in the tab
	 */
	get secondaryText(): string | React.Node {
		return _getDataVal(this, 'secondaryText');
	}

	/**
	 * An object that gets spread into <ba-icon/>
	 */
	get icon(): Object {
		return _getDataVal(this, 'icon');
	}

	/**
	 * An icon name that gets rendered in encore when the tab is active
	 */
	get activeIcon(): Object {
		return _getDataVal(this, 'activeIcon');
	}

	/**
	 * Whether the tab is being resolved
	 */
	get loading(): boolean {
		return this.BaTabs.loading;
	}

	/**
	 * The load strategy
	 */
	get load(): string {
		let load = _getDataVal(this, 'load', this.BaTabs.load);

		if (isString(load)) {
			switch (load) {
				case 'once':
					load = {
						render: 'all',
					};
					break;
				case 'preload':
					load = {
						render: 'all',
						preload: 'all',
					};
					break;
				case 'sequential':
					load = {
						render: 'all',
						preload: 'next',
					};
					break;
				case 'always':
					load = {
						render: 'all',
						resolve: 'always',
					};
					break;
			}
		}

		if (!isPlainObject(load)) {
			load = {};
		}

		return {
			// default "active" config
			render: 'active',
			resolve: 'once',
			preload: 'none',
			...load,
		};
	}

	/**
	 * Whether the tab should load asynchronously
	 */
	get isAsync(): boolean {
		const getData = _getThemeVal(this, 'getData');
		const getBody = _getThemeVal(this, 'getBody');

		return (
			!isUndefined(getData) ||
			!isUndefined(getBody)
		);
	}

	/**
	 * The cached data object for the tab content
	 */
	get data(): Object {
		return _getDataVal(this, 'data', {});
	}

	/**
	 * Caches the data object for the tab content
	 */
	set data(val: Object): void {
		_setDataVal(this, 'data', val);
	}

	/**
	 * Whether the tab has cached data
	 */
	get hasData(): boolean {
		return !!_getDataVal(this, 'data');
	}

	/**
	 * The tab content to be displayed
	 */
	get body(): React.Node | void {
		if (
			!this.active &&
			this.load.render === 'active'
		) {
			return null;
		}

		const {
			props: { children },
		} = _tabsInstance.get(this);

		return _getDataVal(this, 'body') || children || _getThemeVal(this, 'body');
	}

	/**
	 * Caches the tab content
	 */
	set body(val: React.Node): void {
		_setDataVal(this, 'body', val);
	}

	/**
	 * Whether the tab has cached content
	 */
	get hasBody(): boolean {
		return !!_getDataVal(this, 'body');
	}

	/**
	 * Whether to show a caret in the tab
	 */
	get caret(): boolean | React.Node {
		return _getThemeVal(this, 'caret', false);
	}

	/**
	 * An object with part names as keys,
	 * and whether to hide them as values
	 */
	get hiddenParts(): Object {
		return _getThemeVal(this, 'hiddenParts');
	}

	/**
	 * Whether the tab needs to be resolved
	 * when it becomes active
	 */
	get shouldResolveContent(): boolean {
		return (
			this.isAsync &&
			(
				this.load.resolve === 'always' ||
				!this.isResolved
			)
		);
	}

	get isResolved() {
		return (
			!this.loading &&
			_getDataVal(this, 'resolved', false)
		);
	}

	/**
	 * An object to be spread as props to
	 * all subcomponents
	 */
	get props(): Object {
		const _this = this;

		return {
			get tab() {
				return _this;
			},
			get index() {
				return _this.index;
			},
			get number() {
				return _this.number;
			},
			get key() {
				return _this.key;
			},
			get active() {
				return _this.active;
			},
			get activeIcon() {
				return _this.activeIcon;
			},
			get disabled() {
				return _this.disabled;
			},
			get caret() {
				return _this.caret;
			},
			get primaryText() {
				return _this.primaryText;
			},
			get secondaryText() {
				return _this.secondaryText;
			},
			get icon() {
				return _this.icon;
			},
			get body() {
				return _this.body;
			},
			get onTabClick() {
				return _this.onTabClick;
			},
			get Theme() {
				return _this.Theme;
			},
			get hiddenParts() {
				return _this.hiddenParts;
			},
			get _createBEM() {
				return _this._createBEM;
			},
			get _subcomponents() {
				return _this._subcomponents;
			},
		};
	}

	/**
	 * @constructor
	 * @param tab
	 * @param index
	 * @param tabsInstance
	 */
	constructor(tab: Object, index: number, tabsInstance: BaTabs): void {
		if (tab.body && tab.getBody) {
			throw new Error('A tab cannot have both body and getBody defined');
		}

		if (tab.data && tab.getData) {
			throw new Error('A tab cannot have both data and getData defined');
		}

		let {
			path,
		} = tab;
		let pathname;
		let query;

		if (isString(path)) {
			([pathname, ...query] = path.split('?'));
			query = query.join('?') || null;

			path = {
				pathname,
				query,
			};
		}

		_data.set(this, {
			key: `tab${ index + 1 }`,
			...tab,
			index,
			number: index + 1,
			primaryText: isPlainObject(tab.label) ? tab.label.primary : tab.label,
			secondaryText: isPlainObject(tab.label) ? tab.label.secondary || null : null,
			icon: isString(tab.icon) ? { name: tab.icon } : tab.icon,
			activeIcon: isString(tab.activeIcon) ? { name: tab.activeIcon } : tab.activeIcon,
			path: isPlainObject(path) ? path : null,
		});
		_tabsInstance.set(this, tabsInstance);

		this._subcomponents = {};

		Object.defineProperties(
			this._subcomponents,
			Object.keys(subcomponents)
				.reduce((comps, key) => ({
					...comps,
					[key]: {
						get: () => _getThemeVal(this, key),
					},
				}), {})
		);
	}

	/**
	 * An alias for this.BaTabs._createBEM()
	 * @private
	 */
	_createBEM = (...args) => {
		return this.BaTabs._createBEM(...args);
	}

	/**
	 * Renders the tab
	 */
	renderTab({ overflow = false } = {}): React.Node {
		const tab = this;
		const {
			key,
		} = this;

		return (
			<BaTab { ...{ key, tab, overflow } } />
		);
	}

	/**
	 * Renders the body
	 */
	renderBody(): React.Node {
		const tab = this;
		const {
			key,
		} = this;

		return (
			<BaTabContent { ...{ key, tab } } />
		);
	}

	/**
	 * Calls getBody() or returns this.body
	 */
	getBody(): string | React.DOM | Promise {
		if (this.shouldResolveContent) {
			const fn = _getThemeVal(this, 'getBody');

			if (isString(fn)) {
				return Ajax.get(compileURL(fn, this.props))
					.then(({ data }) => data);
			}

			if (isFunction(fn)) {
				return fn.call(this.BaTabs, this) || this.body;
			}
		}

		return this.body;
	}

	/**
	 * Calls getData() or returns this.data
	 */
	getData(): Object | Promise {
		if (this.shouldResolveContent) {
			const fn = _getThemeVal(this, 'getData');

			if (isString(fn)) {
				return Ajax.get(compileURL(fn, this.props))
					.then(({ data }) => data);
			}

			if (isFunction(fn)) {
				return fn.call(this.BaTabs, this) || this.data;
			}
		}

		return this.data;
	}

	/**
	 * Resolve this.getData() and this.getBody()
	 */
	resolveContent(): Promise {
		return Promise.all([this.getData(), this.getBody()])
			.then(([newData, newBody]) => {
				this.data = newData || this.data;
				this.body = newBody || this.body;

				_setDataVal(this, 'resolved', true);

				if (typeof this.BaTabs.props.onTabResolve === 'function') {
					setTimeout(() => {
						this.BaTabs.props.onTabResolve(this);
					}, 0);
				}

				return this;
			});
	}

	/**
	 * Click event handler for the tabs
	 */
	onTabClick = (...args) => {
		const {
			Theme,
			props,
		} = this.BaTabs;

		if (
			this.loading ||
			this.disabled ||
			_getThemeVal(this, 'disableTabClick') ||
			this.active ||
			_getDataVal(this, 'onClick', noop)(this, ...args) === false ||
			(props.onTabClick || noop)(this, ...args) === false ||
			(Theme.onTabClick || noop)(this, ...args) === false
		) {
			return;
		}

		this.BaTabs.activeTab = this;
	}

	testURL() {
		const {
			_pathname,
			_query,
		} = this;

		if (isEmpty(_pathname)) {
			return;
		}

		const {
			params = null,
			query = null,
		} = testURL(_pathname, _query || {}) || {};

		const data = {
			...this.props,
			...this.data,
		};

		return (
			params !== null &&
			query !== null &&
			![
				...Object.keys(params || {}).map(key => (
					isUndefined(data[key]) ||
					data[key] == params[key]
				)),
				...Object.keys(_query || {}).map(key => (
					isUndefined(query[key]) ||
					_query[key] == query[key]
				)),
			].includes(false)
		);
	}
}

/**
 * Ensures Content is a React Node
 *
 * @private
 * @param {Tab} tab
 * @param {React.Component | string} Content
 * @param {object} props
 * @returns {JSX.Element}
 */
function _makeJSX(tab, Content, props = {}) {
	if (isFunction(Content)) {
		Content = <Content tab={ tab } { ...props } />;
	} else if (isString(Content)) {
		Content = <div dangerouslySetInnerHTML={ { __html: Content } } />;
	}

	return Content;
}

function BaTab({ tab, overflow }) {
	const {
		data,
		data: {
			trackingAction,
		},
		index,
    includeDivider,
		isLink,
		href,
		number,
		props,
		_subcomponents: {
			Tab,
		},
	} = tab;

	const {
		_createBEM,
		active,
		disabled,
		key,
		onTabClick,
	} = props;

	const content = _makeJSX(tab, Tab, { ...props, ...data });

	const classes = _createBEM(overflow ? 'overflowTab' : 'tab', [key, {
		active,
		disabled,
		'^fab-Link--inherit': isLink && overflow,
		link: isLink,
	}], tab);

	/* @startCleanup encore */
	const TabWrapper = p => (isLink ? (
		<a
			{ ...p }
			href={ href }
			ref={ (elem) => { tab.tabElem = elem; } }
		/>
	) : (
		<div
			{ ...p }
			onClick={ onTabClick }
			ref={ (elem) => { tab.tabElem = elem; } }
		/>
	));
	/* @endCleanup encore */

	return (
    <>
      {includeDivider && <Divider marginTop={1.5} marginBottom={1.5} />}
      {ifFeature('encore',
				<Box
					className={ classes }
					component={href ? 'a' : 'div'}
					data-bi-id={ `access-level-${ trackingAction }-header-step` }
					data-tab-index={ index }
					data-tab-number={ number }
					// @ts-expect-error href is only used when component is an anchor
					href={href}
					onClick={ href ? undefined : onTabClick}
					ref={elem => { tab.tabElem = elem }}
				>
					{ content }
				</Box>,
				<TabWrapper
					className={ classes }
					data-bi-id={ `access-level-${ trackingAction }-header-step` }
					data-tab-index={ index }
					data-tab-number={ number }
				>
					{ content }
				</TabWrapper>
			)}
    </>
	);
}

function BaTabContent({ tab }) {
	const {
		props: {
			key, // discard the "key" to prevent duplicates
			...props
		},
		loading,
		load,
		data,
		isAsync,
		isResolved,
	} = tab;
	const {
		_createBEM,
		active,
		disabled,
		_subcomponents: {
			TabContentHeader,
			TabContentHeaderActions,
			TabContentFooter,
			TabContentFooterActions,
			TabContent,
			TabLoadingContent,
		},
	} = props;

	if (
		disabled ||
		(
			!active &&
			load.render === 'active'
		)
	) {
		return null;
	}

	let body = null;

	if (
		active &&
		loading
	) {
		body = _makeJSX(tab, TabLoadingContent, { ...props, ...data });
	} else if (
		!isAsync ||
		!loading &&
		isResolved
	) {
		body = _makeJSX(tab, tab.body, { ...props, ...data });

		if (TabContent) {
			body = (
				<TabContent { ...props } { ...data }>
					{ body }
				</TabContent>
			);
		}

		body = (
			<Fragment>
				{ (TabContentHeader || TabContentHeaderActions) && (
					<div className={ _createBEM('tabContentHeader') }>
						{ TabContentHeader && (
							<TabContentHeader { ...props } { ...data }>{ null }</TabContentHeader>
						) }
						{ TabContentHeaderActions && (
							<div className={ _createBEM('tabContentHeaderActions') }>
								<TabContentHeaderActions { ...props } { ...data }>{ null }</TabContentHeaderActions>
							</div>
						) }
					</div>
				) }
				{ body }
				{ (TabContentFooter || TabContentFooterActions) && (
					<div className={ _createBEM('tabContentFooter') }>
						{ TabContentFooter && (
							<TabContentFooter { ...props } { ...data }>{ null }</TabContentFooter>
						) }
						{ TabContentFooterActions && (
							<div className={ _createBEM('tabContentFooterActions') }>
								<TabContentFooterActions { ...props } { ...data }>{ null }</TabContentFooterActions>
							</div>
						) }
					</div>
				) }
			</Fragment>
		);
	}

	return (
		<div
			className={ _createBEM('tabContent', [key, { active }], tab) }
			ref={ (elem) => { tab.tabContentElem = elem; } }
		>
			{ body }
		</div>
	);
}
