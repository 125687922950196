import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useState } from 'react';

import { BlankState, Table } from '@bamboohr/fabric';

import { BEM } from '../../constants';
import { getColumnsData, getRowsData } from '../../utils';

export function Content(props) {
	const { openDisableLinkConfirmation, sfsItems } = props;

	const [expandedIndices, updateExpandedIndices] = useState([]);
	const columns = getColumnsData(openDisableLinkConfirmation);
	const rows = getRowsData(sfsItems, expandedIndices, updateExpandedIndices);

	return (
		<div className={ BEM.elem('content') }>
			{ rows.length ?
				<Table
					caption={ $.__('Active secured download links') }
					columns={ columns }
					rowKey={ row => row.key }
					rows={ rows }
					stickyHeader={ true }
				/>
				:
				<div className={ BEM.elem('blankState') }>
					<BlankState
						icon={ifFeature('encore', 'link-regular', 'fab-link-72x72')}
						title={ $.__(`There aren't any active links for this document.`) }
					/>
				</div>
			}
		</div>
	);
}
