import { Ref } from 'react';
import classNames from 'classnames';

import CalendarPicker from 'calendar-picker.react';
import { ErrorPosition } from './types';

import './date-field.styl';

interface DateFieldProps {
	disabled?: boolean;
	error?: string;
	errorPosition?: ErrorPosition;
	hasError?: boolean;
	id: string;
	label: string;
	max?: string;
	min?: string;
	name?: string;
	onChange: (newDate: string) => void;
	required?: boolean;
	inputRef?: Ref<HTMLInputElement>;
	value: string;
	warning?: string;
	biId?: string;
	width?: number;
}

export const DateField = (props: DateFieldProps): JSX.Element => {
	const {
		disabled,
		error,
		hasError,
		id,
		label,
		name,
		max,
		min,
		onChange,
		required,
		inputRef,
		value,
		warning,
		biId,
		errorPosition = ErrorPosition.right,
		width = 4,
	} = props;

	const handleDateChange = (date: string): void => {
		if (date !== value) {
			onChange(date);
		}
	};

	const widthClass = `fab-TextInput--width${ width }`;
	const showError = hasError || Boolean(error);
	const showWarning = !showError && Boolean(warning);

	return (
		<div className="fab-FormColumn">
			{ label && (
				<label
					className={ classNames(
						'fab-Label',
						{ 'fab-Label--required': required },
						{ 'fab-Label--error': showError },
						{ 'fab-Label--warning': showWarning }
					) }
					htmlFor={ id }
				>
					{ label }
				</label>
			) }
			<CalendarPicker
				biId={ biId }
				cssClasses={ {
					single: classNames(
						widthClass,
						{ 'fab-TextInput--error': showError },
						{ 'fab-TextInput--warning': showWarning }
					),
				} }
				disabled={ Boolean(disabled) }
				id={ id }
				inputRef={ inputRef }
				name={ name }
				onChange={ (date): void => handleDateChange(date) }
				onIncomplete={ (date): void => handleDateChange(date) }
				required={ required }
				settings={ {
					start: value,
					max,
					min,
				} }
				type="date"
			/>
			{ (error || showWarning) && (
				<span
					className={ classNames(
						'fab-FormNote',
						`DateField__note--${ errorPosition }`,
						{ 'fab-FormNote--error': showError },
						{ 'fab-FormNote--warning': showWarning }
					) }
				>
					{ error || warning }
				</span>
			) }
		</div>
	);
};
