import FileCard from 'file-card.mod';
import { cloneDeep, findIndex, includes, union } from 'lodash';

import { createModalContainer } from 'show-modal.mod';
import { render } from 'base/wrapped-render';
import { SharedFilesManagerModal } from './shared-files-manager-modal.react';
import { fileSectionsAPI } from './shared-files-manager-modal.react/API';

const FILE_WRAPPER = '.js-FilesWrapper__files';

let tip = null;

export default class SharedFilesManager {
	constructor(existingFiles) {
		this.sections = [];
		try {
			this.sections = JSON.parse(document.getElementById('FileSections').textContent);
		} catch (e) {
			console.warn('File sections have not been loaded. If you want this to load by default, please include in view template.');
		}

		// File Caches
		this.currentFiles = [];
		this.editFiles = [];
		this.modalFiles = [];
		this.changedFiles = [];

		// Endpoints
		this.filesEndPoint = '/ajax/dialog/files/load_files.php';
		this.sectionEndpoint = '/ajax/files/loadSections';

		this.excludeEsignatures = false;
		this.selectedSection = 'all';
		this.submitButton = null;
		this.theme = 'dark';

		// Call back for returning the files on modal close
		this.modalCallBack = null;

		// If there are existing files past to the manager, show them
		if (existingFiles && existingFiles.length) {
			this.populateFiles(existingFiles);
		}
		this._addListeners();
	}

	// Add event listeners for the addfiles modal
	_addListeners() {
		const that = this;

		document.addEventListener('fileCard:remove', function(e) {
			that.currentFiles = that.currentFiles.filter(file => file.fileId !== e.detail.fileId);
			that._pushToChangedFiles(e.detail.fileId);
		}, false);

		$(document).on('change', '.js-fc-toggle-required', function() {
			const { fileId } = this.dataset;
			const req = this.checked ? 'yes' : 'no';

			that._updateRequired(fileId, req, that.currentFiles);
		});
	}

	/**
	 * Merge selected tasklist data into the file data
	 * @param {array} files
	 * @return {array}
	 */
	_mergeFileData(files) {
		let selectedFiles = cloneDeep(this.modalFiles);

		files.forEach((file) => {
			this._addFileMeta(file, selectedFiles);
		});

		selectedFiles = null;

		return files;
	}

	/**
	 * Add meta data that relates to the file
	 * @param {object} file          The file object that might need some meta data
	 * @param {array}  selectedFiles Collection of currently selected files
	 */
	_addFileMeta(file, selectedFiles) {
		file.meta = {};

		// Only apply this data if there are currently selected files
		if (selectedFiles.length) {
			const selectedIndex = findIndex(selectedFiles, (sfile) => { return sfile.fileId == file.id; });
			// Only add meta data to selected files
			if (selectedIndex > -1) {
				file.meta.checked = true;
				file.meta.required = selectedFiles[selectedIndex].required;

				selectedFiles.splice(selectedIndex, 1);
			}
		}
	}

	/**
	 * return the specified array
	 * @param {object} array	the name of the array to return
	 * @return {int}			return if the array has any contents
	 */
	getFilesFromArray = (array) => {
		return this[array];
	}

	/**
	 * Add a added/deleted file to the changedFiles array
	 * @param {object} fileId	The file id for the file being added/removed
	 */
	_pushToChangedFiles(fileId) {
		if (this.changedFiles.indexOf(fileId) === -1) {
			this.changedFiles.push(fileId);
		}
	}

	/**
	 * Merge a specified field from one array into another
	 * @param {array} array  	to be merged into
	 * @param {array} values 	the array to be merged from
	 * @param {array} key		the element that should be merged into
	 */
	mergeFieldIntoArray = (array, values, key) => {
		if (this[values].length) {
			this[array] = union(this[array], Object.keys(this[values]).map(k => this[values][k][key]));
		}
	}

	/**
	 * Update required attribute of specified file
	 * @param  {number}  fileId     The files Id -> '391' not '391-no-no'
	 * @param  {boolean} isrequired Boolean representing required
	 * @param  {array}   data
	 */
	_updateRequired(fileId, isRequired, data) {
		const entry = findIndex(data, function(file) {
			return file.fileId == fileId;
		});

		const input = document.querySelector(`[data-file-id="${ data[entry].fileId }"]`);
		const res = data[entry].id.replace(/[^-]+$/, isRequired);

		data[entry].id = res;
		data[entry].required = isRequired;
		input.id = res;
	}

	// Update the UI representing files
	_updateFilesInForm(files = this.modalFiles) {
		$('input[name="file_ids[]"]').remove();
		this.currentFiles = cloneDeep(files);
		FileCard.renderFileCards(FILE_WRAPPER, this.currentFiles, 'attachment', this.theme);
		$('.dot-ellipsis').dotdotdot({ wrap: 'letter' });
		// This is necessary to re-enable any tooltips
		window.BambooHR.Components.Tooltip.init();
	}

	// Public FilesWapper methods

	addModalCallBack(callback) {
		if (typeof callback === 'function') {
			this.modalCallBack = callback;
		}
	}

	// Open the Add Company Files Modal
	addCompanyFiles(theme = 'dark', excludeEsignatures = false) {
		let sectionEndPoint = this.sectionEndpoint;
		this.excludeEsignatures = excludeEsignatures;

		if (excludeEsignatures) {
			sectionEndPoint += '?esignatures=false';
		}

		if (this.sections.length === 0) {
			const that = this;
			fileSectionsAPI(sectionEndPoint)
				.then((response) => {
					that.sections = response.data.data.sections.records;

					if (that.sections.length) {
						that.openModal(theme);
					}
				})
				.catch(() => {
					window.setMessage('We weren\'t able to get some information for you. Perhaps a page refresh would help?', 'error');
				});
			return;
		}

		if (this.sections.length) {
			this.openModal(theme);
		}
	}

	openModal(theme = 'dark') {
		const that = this;
		this.theme = theme;
		this.modalFiles = cloneDeep(this.currentFiles);
		const modalContainerId = 'js-SharedFilesManagerMod';
		const modalContainer = createModalContainer(modalContainerId);
		const scrollOffset = window.scrollY;
		render(<SharedFilesManagerModal
			filesEndpoint={ this.filesEndPoint }
			initialSelections={ this.modalFiles }
			modalCallback={ this.modalCallBack }
			modalContainerId={ modalContainerId }
			sections={ this.sections }
			tooltipScrollOffset={ scrollOffset }
			updateFilesInForm={ files => that._updateFilesInForm(files) }
		/>, modalContainer);
	}

	/**
	 * Create a tooltip for required/optional docs
	 * @param  {node}   elem The element to create the tooltip on
	 */
	createTooltip(elem) {
		const input = elem.querySelector('input');
		let curState = $.__('Optional');
		let altState = $.__('required');

		if (input === null || tip) {
			return null;
		}

		if (input.checked) {
			curState = $.__('Required');
			altState = $.__('optional');
		}

		tip = BambooHR.Components.Tooltip.create(elem, {
			className: 'bhrTooltip--autoWidth',
			content: $.__('Click to make the signature %s.', altState),
			delay: 800,
			lead: $.__('Signature %s', curState),
			placement: 'top',
		}).show();
	}

	// Destroy existing tooltip if it exists
	destroyTooltip() {
		if (tip) {
			tip.destroy();
			tip = null;
		}
	}

	// Empty the selected files array
	emptySelectedFiles() {
		this.currentFiles = [];
	}

	/**
	 * Update files list in FilesWrapper with selected files
	 * @param  {array} files A collection of files
	 * @param {string} theme light or dark file list. Defaults to dark
	 */
	populateFiles(files, theme = 'dark') {
		this.currentFiles = [];

		if (this._filesDataIsValid(files)) {
			files = FileCard.fileCardFromArray(files);
			this.currentFiles = cloneDeep(files);
			FileCard.renderFileCards(FILE_WRAPPER, files, 'attachment', theme);
		}

		this.resetCache();
	}

	_filesDataIsValid(files) {
		if (!files || !files.length) {
			return false;
		}
		
		for (let i = 0; i < files.length; i++) {
			const item = files[i];
			if (!item) {
				return false;
			}
		}

		return true;
	}

	resetCache() {
		this.editFiles = cloneDeep(this.currentFiles);
	}
}

let instance = null;

export function initSharedFilesManager(existingFiles = []) {
	if (instance) {
		return instance;
	}
	instance = new SharedFilesManager(existingFiles);
	return instance;
}
