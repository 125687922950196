import {
	noop,
} from 'lodash';
import {
	useEffect,
	useState,
} from 'react';

import {
	TextField as OldTextField,
} from 'form-fields.react';

import { TextField } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	getAccountNumberError,
} from './account-number-input-field.domain';

export interface AccountNumberInputFieldProps {
	isDisabled: boolean;
	onChange: (accountNumber: string, error: string) => void;
}

export function AccountNumberInputField(props: AccountNumberInputFieldProps): JSX.Element {
	const {
		isDisabled,
		onChange = noop,
	} = props;

	const [accountNumber, setAccountNumber] = useState('');
	const [error, setError] = useState('Required');
	const [canShowError, setCanShowError] = useState(false);

	useEffect(() => {
		onChange(accountNumber, error);
	}, [accountNumber, error]);

	const shouldShowError = canShowError && !!error;

	return ifFeature('encore',
		<TextField
			disabled={ isDisabled }
			id="collections-and-refunds-account-number-input-field"
			label={ $.__('Account Number') }
			note={ shouldShowError && error }
			onBlur={ (): void => setCanShowError(true) }
			onChange={ (e): void => {
				setAccountNumber(e.target.value);
				setError(getAccountNumberError(e.target.value));
				setCanShowError(true);
			} }
			required={ true }
			status={ shouldShowError ? 'error' : undefined }
			value={ accountNumber }
			width={ 8 }
		/>,
		<OldTextField
			autoComplete="disable-auto-complete"
			disabled={ isDisabled }
			error={ shouldShowError && error }
			hasError={ shouldShowError }
			id="collections-and-refunds-account-number-input-field"
			label={ $.__('Account Number') }
			onBlur={ (): void => setCanShowError(true) }
			onChange={ (e): void => {
				setAccountNumber(e.target.value);
				setError(getAccountNumberError(e.target.value));
				setCanShowError(true);
			} }
			required={ true }
			value={ accountNumber }
			width={ 8 }
		/>
	);
}
