import { render } from 'base/wrapped-render';
import ApproverClockOut from './approver-clock-out.react';
import {noop} from 'lodash';
import './styles.styl';

export default function (data, callback, url = '/timesheet/clock/out/approval') {
	const title = data.length > 1 ? $.__('People are still clocked in') : $.__('%1$s is still clocked in', data[0].firstName);
	const onSuccess = typeof callback === 'function' ? callback : noop;

	const approverClockOutDiv = document.createElement('div');
	render(<ApproverClockOut afterAction={ onSuccess } employees={ data } title={ title } url={ url } />, approverClockOutDiv);
}
