import React, { useCallback, useEffect, useState } from 'react';
import { PhotoUploadModal, PhotoUploadModalProps } from '@bamboohr/fabric';
import ajax from '@utils/ajax';
import { GetPhotoResponse, PhotoUploadProps, SavePhotoResponse, DeletePhotoResponse } from './photo-upload.types';

export function PhotoUpload({
	biId,
	closeModal,
	deleteUrl,
	employeeId,
	getUrl,
	imageAlt,
	postUrl,
	open,
	updateDisplayedPhoto,
}: PhotoUploadProps) {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [initialImageSrc, setInitialImageSrc] = useState<string>();
	const [initialCropBoxCoords, setInitialCropBoxCoords] = useState<PhotoUploadModalProps['initialCropBoxCoords']>();

	useEffect(() => {
		if (open) {
			ajax
				.get(getUrl)
				.then(({ data }: GetPhotoResponse) => {
					if (data.dimensions) {
						setInitialCropBoxCoords({
							height: data.dimensions.h,
							width: data.dimensions.w,
							x: data.dimensions.x,
							y: data.dimensions.y,
						});
					}
					if (data.data) {
						setInitialImageSrc(`data:image/png;base64,${data.data}`);
					}
					setIsLoading(false);
				})
				.catch(() => {
					window.setMessage($.__('Oops! There was a problem getting the profile photo'), 'error');
				});
		} else {
			setIsLoading(true);
			setIsProcessing(false);
			setInitialImageSrc(undefined);
			setInitialCropBoxCoords(undefined);
		}
	}, [open]);

	const saveImage = useCallback(
		(imageSrc: string, cropBoxCoords: PhotoUploadModalProps['initialCropBoxCoords']) => {
			setIsProcessing(true);
			const formData = new FormData();
			formData.append('employeeid', employeeId);
			formData.append('method', 'uploadImage');
			formData.append('dimensions[]', `${cropBoxCoords.x}`);
			formData.append('dimensions[]', `${cropBoxCoords.y}`);
			formData.append('dimensions[]', `${cropBoxCoords.x + cropBoxCoords.width}`);
			formData.append('dimensions[]', `${cropBoxCoords.y + cropBoxCoords.height}`);
			formData.append('dimensions[]', `${cropBoxCoords.width}`);
			formData.append('dimensions[]', `${cropBoxCoords.height}`);
			// remove the data:image/png;base64, prefix
			formData.append('data', imageSrc.slice(imageSrc.indexOf(';base64,') + 8));

			ajax
				.post(postUrl, formData)
				.then(({ data }: SavePhotoResponse) => {
					closeModal();
					updateDisplayedPhoto(`data:image/png;base64,${data.data}`, true);
					window.setMessage($.__('The profile photo has been saved.'), 'success');
				})
				.catch(() => {
					setIsProcessing(false);
					window.setMessage($.__('There was a problem saving the profile image'), 'error');
				});
		},
		[postUrl]
	);

	const deleteImage = useCallback(() => {
		setIsProcessing(true);
		const formData = new FormData();
		formData.append('id', employeeId);
		formData.append('action', 'delete');
		ajax
			.post(deleteUrl, formData)
			.then(({ data }: DeletePhotoResponse) => {
				closeModal();
				updateDisplayedPhoto(data.placeholder , false);
				window.setMessage($.__('The profile photo has been successfully deleted.'), 'success');
			})
			.catch(() => {
				setIsProcessing(false);
				window.setMessage($.__('There was a problem deleting the profile image'), 'error');
			});
	}, [deleteUrl]);

	return (
		<PhotoUploadModal
			biId={biId}
			imageAlt={imageAlt}
			imageSrc={initialImageSrc}
			initialCropBoxCoords={initialCropBoxCoords}
			loading={isLoading}
			onCancel={closeModal}
			onDelete={deleteImage}
			onSave={saveImage}
			open={open}
			processing={isProcessing}
		/>
	);
}
