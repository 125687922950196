import { Map, OrderedSet, Record } from 'immutable';

export const Entity = Record({
	allIds: new OrderedSet(),
	byId: new Map(),
});

export const Entities = Record({
	comments: new Entity(),
	files: new Entity(),
	replies: new Entity(),
	users: new Entity(),
	events: new Entity()
});

export const State = Record({
	id: null,
	entities: new Entities()
});

export const Comment = Record({
	author: null,
	canDelete: false,
	canEdit: false,
	categoryName: null,
	createdAt: null,
	createdDate: null,
	edited: false,
	editing: false,
	excludeFromComments: false,
	files: [],
	id: null,
	isPinned: false,
	pending: false,
	replies: [],
	text: '',
	eventId: null
});

export const File = Record({
	fileType: '',
	id: null,
	mostRecentEmployeeFileDataId: null,
	name: '',
});

export const Reply = Record({
	author: null,
	canDelete: false,
	canEdit: false,
	createdAt: null,
	edited: false,
	editing: false,
	files: [],
	id: null,
	inReplyTo: null,
	pending: false,
	text: '',
});

export const User = Record({
	avatar: '',
	canView: false,
	employeeId: null,
	id: null,
	name: '',
});

export class Event extends Record({
	createdAt: null,
	canDelete: false,
	data: {},
	id: null,
	pending: false,
	type: null,
	userId: null,
}) {
	/**
	 * get id of associated comment (if exists)
	 *
	 * @return int|null
	 */
	getCommentId() {
		return this.getIn(['data', 'commentId'], null);
	}
	/**
	 * get id of server resource (if exists)
	 *
	 * @return int|null
	 */
	getRemoteId() {
		return this.getIn(['data', 'remoteId'], null);
	}
}
