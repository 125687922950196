import { Modal } from 'modal-legacy';
import './confirm-delete-modal.styl';
import {ifFeature} from "@bamboohr/utils/lib/feature";

export function ConfirmDeleteModal(props) {
	const {
		id,
		message,
		title,
		onClose,
		visible,
		onDelete,
		primaryActionText,
		secondaryActionText
	} = props;

	return (
		<Modal { ...getModalData(visible, onClose, id, onDelete, title, message, primaryActionText, secondaryActionText) } />
	);
}

function getModalData(visible, onClose, id, onDelete, title, message, primaryActionText, secondaryActionText) {
	return {
		alternativeAction: null,
		headline: message,
		icon: ifFeature('encore', 'trash-can-regular','fab-trash-can-43x48'),
		iconColor: 'danger',
		iconV2Color: 'error-strong',
		isOpen: visible,
		onClose,
		primaryAction: () => onDelete(id),
		secondaryAction: onClose,
		primaryActionText,
		secondaryActionText,
		title,
	};
}
