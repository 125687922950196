export const PLAN_TYPES = {
	HEALTH: 'health',
	DENTAL: 'dental',
	VISION: 'vision',
	SUPPLEMENTAL: 'supplemental',
	RETIREMENT: 'retirement',
	REIMBURSEMENT: 'reimbursement',
	HSA: 'hsa',
	FLEX: 'flex',
	LIFE: 'life',
	DISABILITY: 'disability',
	OTHER: 'other',
};
