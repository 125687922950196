import { getSubEntityLabel } from './utils';

export const COUNTRY_ID_VOID = 0;
export const COUNTRY_ID_USA = 1;
export const COUNTRY_ID_AUS = 3;
export const COUNTRY_ID_UK = 222;
export const COUNTRY_ID_NG = 157;

export const ENTITY_LABEL_STATE = $.__('State');
export const ENTITY_LABEL_COUNTY = $.__('County');
export const ENTITY_LABEL_PROVINCE = $.__('Province');

export const POST_LABEL_ZIP = $.__('ZIP');
export const POST_LABEL_POSTCODE = $.__('Postcode'); // https://en.wikipedia.org/wiki/Postal_code
export const POST_LABEL_POSTAL_CODE = $.__('Postal Code');

export const DEFAULT_OPTIONS = {
	endpoint: '/ajax/get_states?country=',
	initialCountryId: COUNTRY_ID_USA,
	initialSubEntityLabel: getSubEntityLabel(COUNTRY_ID_USA),
	initialSubEntityOptions: [], // default state list is probably too much to store in FE code
};
