import React, {
	useEffect,
	useRef,
} from 'react';

import './styles.styl';

/**
 *
 * Map component to display a map with markers placed on the map using the Google Maps API
 *
 * @param {*} props Array markers, String height, String width
 *
 * Props examples
 *
 * markers={ [
 *		{ coordinates: { lat: 40.332702, lng: -111.729843 }, label: 'Clock In' },
 *		{ coordinates: { lat: 40.332619, lng: -111.729440 }, label: 'Clock Out' }
 *	] }
 *	height="291px"
 *	width="396px"
 *  zoom={ 17 }
 */
export function Map(props) {
	const {
		markers,
		height,
		width,
	} = props;
	let { zoom } = props;

	const mapRef = useRef(null);

	if (markers.length === 1 && !zoom) {
		zoom = 17;
	}

	useEffect(() => {
		let addedMarkers = [];
		let addedCircles = [];
		let map = null;
		let markerIcon = null;
		let bounds = null;

		const googleMapScript = document.createElement('script');
		googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${ window.GOOGLE_MAPS_API_KEY }`;
		window.document.head.appendChild(googleMapScript);

		// Once the google maps api has loaded create the map and add markers
		const loadListener = googleMapScript.addEventListener('load', () => {
			map = new window.google.maps.Map(mapRef.current, {
				disableDefaultUI: true,
			});

			markerIcon = {
				path: 'M9.001 0C3 0 0 4.5 0 9s9.001 15 9.001 15S18 13.5 18 9s-3-9-8.999-9zM9 12.857c-1.808 0-3.273-1.535-3.273-3.428C5.727 7.535 7.192 6 9 6s3.273 1.535 3.273 3.429c0 1.893-1.465 3.428-3.273 3.428z',
				anchor: new window.google.maps.Point(8, 11),
				fillColor: '#EA4336',
				fillOpacity: 1,
				strokeColor: '#EA4336',
				strokeWeight: 1,
				labelOrigin: new window.google.maps.Point(43, 12),
			};

			// Add marker and set the bounds of the map based on the markers added
			bounds = new window.google.maps.LatLngBounds();
			for (const marker of markers) {
				if (marker.coordinates.lat && marker.coordinates.lng) {
					const newMarker = new window.google.maps.Marker({
						position: marker.coordinates,
						map,
						icon: markerIcon,
						label: {
							text: marker.label,
							color: '#D1502A',
							fontSize: '8px',
						},
					});
					addedMarkers.push(newMarker);

					const cityCircle = new window.google.maps.Circle({
						strokeColor: '#EA4336',
						strokeOpacity: 0,
						strokeWeight: 0,
						fillColor: '#EA4336',
						fillOpacity: 0.2,
						map,
						center: marker.coordinates,
						radius: marker.accuracy
					});
					addedCircles.push(cityCircle);

					bounds.extend(newMarker.position);
				}
			}
			map.fitBounds(bounds);

			window.google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
				if (zoom) {
					map.setZoom(zoom);
				}
			});
		});

		// Cleanup and remove all Google maps variables and the script tags
		return () => {
			for (const i in addedMarkers) {
				delete addedMarkers[i];
			}

			for (const i in addedCircles) {
				delete addedCircles[i];
			}
			addedMarkers = null;
			addedCircles = null;
			markerIcon = null;
			bounds = null;
			map = null;
			window.google = null;

			googleMapScript.removeEventListener('load', loadListener);
			window.document.head.removeChild(googleMapScript);

			const scripts = document.querySelectorAll(`script[src*='maps.googleapis.com']`);
			for (let i = 0; i < scripts.length; i++) {
				scripts[i].parentNode.removeChild(scripts[i]);
			}
		};
	}, []);

	return (
		<div className="Map" ref={ mapRef } id="map" style={ { width, height } }></div>
	);
}
