import FilesManager from './index';

class OfferLetterFilesManager extends FilesManager {
	constructor(existingFiles) {
		super(existingFiles);

		// Endpoint for OfferLetter files
		this.filesEndPoint = '/applicant_tracking/offer_letter/load_files';
	}

	_addFileMeta(file, curState) {
		super._addFileMeta(file, curState);

		file.meta.disabled = !file.attachableToOffer;
		if (file.meta.disabled) {
			file.meta.disabledText = $.__("Signature templates need to have the employee as the first signer and can only have specific people as additional signers in order to be added to an offer.");
		}
	}
}

let instance = null;

export function initFilesManager(existingFiles = []) {
	if (instance) {
		// update the instance with any existingFiles
		instance.populateFiles(existingFiles);
		return instance;
	}
	instance = new OfferLetterFilesManager(existingFiles);
	return instance;
}
