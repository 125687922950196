import { max } from 'd3-array';
import { scaleLinear, scaleOrdinal } from 'd3-scale';

import { mouseEnter, mouseOver, mouseLeave, mouseClick } from 'Charts.mod/event-functions';

/**
 * Ideal settings object
{
	chartClass: 'MyNamespacedName',
	needsAxis: false,
	container: {
		flexHeight: false,
		height: 136,
		width: 230,
		margin: {
			top: 0,
			right: 10,
			bottom: 0,
			left: 10
		} 
	},
	labelText: {
		fill: '#fff',
		color: '#777',
		width: 150,
		maxLabelLength: 30, // Represents the max chars a label can contain before we trim with ...
		alignmentLeft: false
	},
	valueText: {
		color: '#777',
		spacing: 4,
		valueEdgePadding: 3
	},
	bar: {
		colors: ['#ccc'],
		cursor = 'pointer',
		height: 18,
		padding: 28, 
		formatTextFunction: null
	}
}
 */

/**
 * Creates the top aligned labels bar chart
 *
 * @param {object} data Formatted Data array containing objects
 */
export function drawChart(data) {
	const { bars: barsData } = data;

	const settings = this.getSettings();
	const { container } = settings;
	const { top, right, left } = container.margin;
	const { spacing: labelSpace } = settings.labelText;
	const { spacing: valueSpace } = settings.valueText;
	const { padding: barPadding, height: barHeight, colors, cursor = 'default' } = settings.bar;

	// This is a small buffer from the end of the value text and the bounding container
	const valueEdgePadding = 25;
	const maxResult = max(barsData.map((i) => parseInt(i.value)));
	const longestValueInPixels = this._convertValueLengthToPixelSpace(maxResult);

	const width = this._getContainerWidth() - left - right;
	const widthAdjustedForText = width - longestValueInPixels - valueEdgePadding;

	const scaleX = scaleLinear().domain([0, maxResult]).range([0, widthAdjustedForText]);

	const zScale = scaleOrdinal().domain(barsData).range(colors);

	const groupsUpdate = this._svg.selectAll(`g${this._getClassList('Group')}`).data(barsData);

	const groupsEnter = groupsUpdate
		.enter()
		.append('g')
		.attr('class', this._buildClassList('Group'))
		.attr('transform', function (d, i) {
			const x = top;
			const y = i * (barHeight + barPadding) + barPadding;
			return `translate(${x}, ${y})`;
		});

	// Update
	groupsUpdate
		.select(this._getClassList('__bar'))
		.transition()
		.duration(1000)
		.attr('width', (d) => scaleX(d.value));

	groupsUpdate
		.select(this._getClassList('__label'))
		.transition()
		.duration(1000)
		.style('opacity', 0)
		.transition()
		.duration(500)
		.style('opacity', 1)
		.text((d) => d.title);

	groupsUpdate
		.select(this._getClassList('__value'))
		.transition()
		.duration(1000)
		.style('opacity', 0)
		.attr('x', function (d) {
			const { width } = this.getBBox();
			return Math.max(width, scaleX(d.value));
		})
		.transition()
		.duration(500)
		.style('opacity', 1)
		.text((d) => d.value);

	// Exit
	groupsUpdate.exit().transition().duration(1000).style('opacity', 0).remove();

	// Enter
	groupsEnter
		.append('rect')
		.attr('class', this._buildClassList('__bar'))
		.attr('cursor', cursor)
		.attr('fill', (d) => zScale(d))
		.attr('height', barHeight)
		.attr('width', (d) => scaleX(d.value))
		.attr('transform', `translate(0, 0)`)
		.on('mouseenter', mouseEnter)
		.on('click', mouseClick)
		.on('mouseover', mouseOver)
		.on('mouseleave', mouseLeave);

	groupsEnter
		.append('text')
		.attr('class', this._buildClassList('__label'))
		.attr('y', barHeight - barHeight - labelSpace)
		.attr('x', 0)
		.attr('dy', '.65em')
		.style('fill', (d) => this._settings.labelText.color)
		.text((d) => d.title);

	groupsEnter
		.append('text')
		.attr('class', this._buildClassList('__value'))
		.attr('y', barHeight / 2)
		.attr('dx', valueSpace)
		.attr('dy', '.35em')
		.attr('x', function (d) {
			const { width } = this.getBBox();
			return Math.max(width, scaleX(d.value));
		})
		.style('fill', (d) => this._settings.valueText.color)
		.text((d) => {
			if (typeof this._settings.bar.formatTextFunction === 'function') {
				return this._settings.bar.formatTextFunction(d.value);
			}

			return d.value;
		});
}
