import {
	canUseLocalStorage,
} from '@utils/localstorage';

import {
	validateRememberToken,
} from './service';

export const REMEMBER_TOKEN_KEY = 'remember_token';

export function getStoredRememberToken(): string {
	if (canUseLocalStorage()) {
		return localStorage.getItem(REMEMBER_TOKEN_KEY);
	}

	return null;
}

export async function isRememberTokenValid(userId: number, rememberToken: string): Promise<boolean> {
	if (!rememberToken) {
		return false;
	}

	try {
		const {
			status,
		} = await validateRememberToken(userId, rememberToken);

		return status === 200;
	} catch {
		return false;
	}
}

export function removeStoredRememberToken(): void {
	if (canUseLocalStorage()) {
		localStorage.removeItem(REMEMBER_TOKEN_KEY);
	}
}

export function setStoredRememberToken(value: string): void {
	if (canUseLocalStorage()) {
		localStorage.setItem(REMEMBER_TOKEN_KEY, value);
	}
}
