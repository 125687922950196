import { each } from 'lodash';

export function toggleViewOption(optionKey) {
	$(`.js-timesheet-${ optionKey }`).slideToggle(200);
}

export function setupDomOnPageLoad(relevantState) {
	const {
		isApprovalShowing,
		isOwnTimesheet,
		timesheetOptions,
		getChangedEntries
	} = relevantState;

	if (isOwnTimesheet && !isApprovalShowing) {
		each(timesheetOptions, (optionValue, optionKey) => {
			if (!optionValue) {
				$(`.js-timesheet-${ optionKey }`).hide();
			}
		});
	}

	attachGlobalAnchorHandler(getChangedEntries);
}

function attachGlobalAnchorHandler(getChangedEntries) {
	// mimicking public/global/forms/forms.js
	$(document).on('click', 'a:not(.history-modal):not(.no-form-changed-popup)', function checkForChanges(e) {
		const href = $(e.target).closest('a').attr('href');

		if (
			href &&
			href !== '' &&
			// eslint-disable-next-line no-script-url
			href !== `javascript:void(0)` &&
			href.indexOf('#') === -1 &&
			getChangedEntries().length
		) {
			e.preventDefault();
			showFormChangedModal(href);
		}
	});
}

function showFormChangedModal(url) {
	window.BambooHR.Modal.setState({
		headline: $.__(`You haven't saved your changes`),
		content: (
			<p>
				{ $.__('Your changes will be lost if you navigate away from this page without saving.') }
			</p>
		),
		icon: 'fab-triangle-exclamation-48x48',
		iconColor: 'attention',
		isOpen: true,
		primaryAction: function() {
			window.BambooHR.Modal.setState({isOpen: false}, true);
		},
		primaryActionText: $.__('Continue Working'),
		secondaryAction: function() {
			window.BambooHR.Modal.setState({isProcessing: true}, true);
			window.location = url;
		},
		secondaryActionText: $.__(`Don't Save`),
		alternativeAction: null,
		title: $.__('Just Checking...'),
	});
}
