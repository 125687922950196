import moment from 'moment';

const message = $.__('Please enter a valid date.');

export const validateDateField = (context: DynamicForm.Context, field: DynamicForm.Data, params: any) => {
	return (value: string): boolean | string => {
		const isValid = value === '' || moment(value, moment.defaultFormat).isValid();
		return isValid || message;
	};
};
