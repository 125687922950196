import Ajax from '@utils/ajax';
import { showModal, closeModal } from 'show-modal.mod';

import moment from 'moment.lib';

import BadgeComponent from 'badge.react';
import { EmploymentSubtitles } from 'badge.react/sub-components/employment-subtitles';

import { WelcomeEmailPreviewer } from '../WelcomeEmailPreviewer';
import { createStore, FORM_TYPES } from './store';
import { PacketData, PacketSaveData, TemplateData, TemplateSaveData } from './store/Interfaces';
import { SelectOptions } from './Interfaces';

interface EmployeeData {
	fullName: string;
	firstName: string;
	jobLocation: string;
	jobTitle: string;
	photoUrl: string;
}

interface DateItem {
	text: string;
	value: string;
}

type FormData = TemplateData | PacketData;
type FormSaveData = TemplateSaveData | PacketSaveData;

let formData = createStore(FORM_TYPES.TEMPLATE, {});
let selectableEmployees = [];
let nhpUserId = null;

export function previewNewHireEmail(employeeId: number|string, employeeData: EmployeeData, questions: Array<string>): boolean {

	Ajax.get(`/onboarding/nhp/gtky_email_preview/${ employeeId }`)
		.then((response) => {
			if (response) {
				const {
					data,
					status,
				} = response;

				if (status === 200) {
					const {
						body = '',
					} = data;
					const badge = (
						<BadgeComponent
							imageAlt="Image of employee"
							imageSrc={ `/employees/photos/?id=${ employeeId }` }
							isModalHeader={ false }
							theme="newHire"
							title={ employeeData.fullName }
						>
							<EmploymentSubtitles
								jobTitle={ employeeData.jobTitle }
								location={ employeeData.jobLocation }
							/>
						</BadgeComponent>
					);

					showModal(<WelcomeEmailPreviewer
						body={ body as string }
						employeeBadgeComponent={ badge }
						employeeData={ employeeData }
						getToKnowYouQuestions={ questions }
						isOpen={ true }
						primaryAction={ closeModal }
						showQuestionsPlaceholder={ false }
					/>);
				} else {
					window.setMessage($.__('An error has occurred while getting the preview, please try again later'), 'error');
				}
			}
		});
	return false;
}

export function dateOptions(): Array<DateItem> {
	const timeArray = [];
	const timeFormat = 'HH:mm:ss';
	const midnight = moment('00:00:00', timeFormat);
	const midnightUnix = midnight.unix() as number;
	const nextMidnight = midnightUnix + 60 * 60 * 24;

	let value = midnightUnix;

	while (value < nextMidnight) {
		const time = moment.unix(value, 'HH:mm:ss');
		timeArray.push({
			text: time.format('LT') as string,
			value: time.format(timeFormat) as string,
		});
		value += 60 * 15;
	}

	return timeArray;
}

export function registerTemplateFormData(initialState: TemplateData): void {
	formData = createStore(FORM_TYPES.TEMPLATE, initialState);
}

export function registerPacketFormData(initialState: PacketData): void {
	formData = createStore(FORM_TYPES.PACKET, initialState);
}

export function setInitialFormState(initialState: FormData): void {
	formData.updateData(initialState, true);
}

export function updateFormState(updatedState: FormData): void {
	formData.updateData(updatedState);
}

export function getFormSaveData(): FormSaveData {
	return formData.getFormData();
}

export function getSelectableEmployees(): Array<SelectOptions> {
	return selectableEmployees;
}

export function getNHPUserId(): number {
	return nhpUserId;
}

export function hideFooter(): void {
	document.dispatchEvent(new CustomEvent('SiteFooter:close'));
}

export function setSelectableEmployees(options: Array<SelectOptions>): void {
	selectableEmployees = options;
}

export function setNHPUserId(id: number) {
	nhpUserId = id;
}

export function showFooter(): void {
	document.dispatchEvent(new CustomEvent('SiteFooter:open'));
}
