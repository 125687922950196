import Decimal from 'decimal.js-light';

export function getNumberFromString(value: string, decimalPointChar?: string, maxDecimalPlaces?: number): undefined | number {
	decimalPointChar = decimalPointChar || window.GLOBAL_DECIMAL_CHAR || '.';

	// remove everything but digits, negative sign, and 'decimal point' characters
	const stripRegex = new RegExp(`[^\\d\\${decimalPointChar}-]`, 'gi');
	value = value.replace(stripRegex, '');
	
	// remove any negative sign characters that aren't at the beginning
	value = value.replace(/-/g, (match, offset) => {
		return offset === 0 ? match : '';
	});

	// replace 'decimal point' characters with '.'
	const decimalRegex = new RegExp(`\\${decimalPointChar}`, 'gi');
	value = value.replace(decimalRegex, '.');
	
	// remove any decimal points beyond the first one encountered
	let isFirstDecimal = true;
	value = value.replace(/\./g, (match) => {
		if (!isFirstDecimal) {
			return '';
		}

		isFirstDecimal = false;
		return match;
	});

	if (!value) {
		return;
	}

	try {
		const valueDecimal = new Decimal(value);
		const valueFixed = valueDecimal.toFixed(maxDecimalPlaces);
		const valueNumber = Number(valueFixed);

		if (Number.isNaN(valueNumber)) {
			return;
		}
	
		return valueNumber;
	} catch {
		return undefined;
	}
}
