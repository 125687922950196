import { Component } from 'react';
import { classNameFromObject } from '@utils/dom';

export default class ListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: false
		};
	}

	_handleClick = () => {
		this.setState(prevState => ({
			selected: !prevState.selected
		}));

		this.props.onItemClick(this.props.item);
	}

	render() {
		const classes = classNameFromObject({
			'EmployeeSelectorReact__list__item': !this.state.selected,
			'EmployeeSelectorReact__list__item--selected': this.state.selected,
			'baseBgColor': this.state.selected,
		});

		const textRightClasses = classNameFromObject({
			'EmployeeSelectorReact__list__item__textRight': true,
			'EmployeeSelectorReact__list__item__textRight--selected': this.state.selected
		});

		return (
			<li className={ classes } onClick={ this._handleClick }>{ this.props.displayText } <span className={ textRightClasses }>{ this.props.displayTextRight }</span></li>
		);
	}
}
