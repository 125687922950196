import { useState } from 'react';

import { Modal } from 'modal-legacy';

import { EmploymentStatusModalContent } from 'employment-status-modal.react';
import {
	postEmploymentStatus,
	wasSaveSuccessful,
} from './utils';

interface AddEmploymentStatusModalProps {
	acaStatuses: string[];
	fieldId: string;
	fieldName: string;
	onClose: () => void;
	onSave: (newValue: string, newId: number) => void;
	showAcaStatus: boolean;
}

export function AddEmploymentStatusModal(props: AddEmploymentStatusModalProps): JSX.Element {
	const {
		acaStatuses,
		fieldId,
		fieldName,
		onClose,
		onSave,
		showAcaStatus,
	} = props;

	const [isProcessing, setIsProcessing] = useState(false);

	const postFormData = (formValues): void => {
		postEmploymentStatus(fieldId, fieldName, formValues)
			.then((response) => {
				setIsProcessing(false);
				if (wasSaveSuccessful(response)) {
					window.setMessage($.__('The Employment Status was added successfully.'), 'success');

					onSave(formValues.name, response.data.id || response.data.data.id);
				} else {
					const message = response.data && (response.data.message || response.data.error) ? response.data.message || response.data.error : '';
					window.setMessage(message || window.DEFAULT_ERROR_MESSAGE, 'error');
				}
			})
			.catch((err) => {
				window.Rollbar.error('Failed to save employment status', err);
				setIsProcessing(false);
			});
	};

	const handleSave = (): void => {
		setIsProcessing(true);
	};

	const handleClose = (): void => {
		onClose();
	};

	return (
		<Modal
			isOpen={ true }
			isProcessing={ isProcessing }
			onClose={ handleClose }
			primaryAction={ handleSave }
			primaryActionText={ $.__('Save') }
			title={ $.__('Add Employment Status') }
		>
			<EmploymentStatusModalContent
				acaStatuses={ acaStatuses || [] }
				isProcessing={ isProcessing }
				postFormData={ postFormData }
				setIsProcessing={ setIsProcessing }
				showAcaStatus={ showAcaStatus }
			/>
		</Modal>
	);
}
