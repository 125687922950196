import {
	makeStyles,
	SelectableBox,
	SelectableBoxGroup,
	Tooltip,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import React from 'react';

const useStyles = makeStyles(() => ({
	boxes: {
		display: 'grid',
		gap: 10,
		gridTemplateColumns: 'repeat(auto-fill, minmax(280px, max-content))',
	},
}));

export function ResourceTypePicker({ disabledTypes = [], onChange, value }) {
	const classes = useStyles();

	const _handleChange = (name, value) => onChange(value);

	const getTypeProps = type => ({
		boxWidth: 280,
		inputId: `version-${ type }`,
		isChecked: value === type,
		isDisabled: disabledTypes.includes(type),
		name: 'resource',
		onChange: _handleChange,
		value: type,
	});

	return (
		<SelectableBoxGroup type="radio">
			<div className={ classes.boxes }>
				<div className={ classes.box }>
					<SelectableBox
						{ ...getTypeProps('committed') }
						description="Use version defined in this branch."
						icon={ifFeature('encore', 'circle-check-regular', "fab-check-circle-outline-20x20")}
						title="Committed"
					/>
				</div>
				<Tooltip
					content="This option is only available when used outside of an ODI"
					open={ disabledTypes.includes('development') ? undefined : false }
				>
					<div className={ classes.box }>
						<SelectableBox
							{ ...getTypeProps('development') }
							description="Use a version from your machine."
							icon={ifFeature('encore', 'wrench-regular',  "fab-wrench-20x20")}
							title="Development"
						/>
					</div>
				</Tooltip>
				<Tooltip
					content="This option is only available when using an ODI"
					open={ disabledTypes.includes('odi') ? undefined : false }
				>
					<div className={ classes.box }>
						<SelectableBox
							{ ...getTypeProps('odi') }
							description="Use an ODI version."
							icon={ifFeature('encore', 'clock-regular', "fab-clock-20x20")}
							title="ODI"
						/>
					</div>
				</Tooltip>
				<div className={ classes.box }>
					<SelectableBox
						{ ...getTypeProps('custom') }
						description="Use a specific production version."
						icon={ifFeature('encore', 'pen-regular' ,"fab-pencil-20x20")}
						title="Custom"
					/>
				</div>
			</div>
		</SelectableBoxGroup>
	);
}
