import {
	noop,
} from 'lodash';
import {
	useCallback,
	useLayoutEffect,
	useReducer,
	useRef,
} from 'react';

interface State {
	data: any;
	error: any;
	isComplete: boolean;
	isError: boolean;
	isProcessing: boolean;
	isSuccess: boolean;
}

interface Options {
	onError?: Function;
	onSuccess?: Function;
}

type Action = {
	payload?: any;
	type: 'async_begin' | 'async_error' | 'async_success';
}

const INITIAL_STATE: State = {
	data: null,
	error: null,
	isComplete: false,
	isError: false,
	isProcessing: false,
	isSuccess: false,
};

function reducer(state: State, action: Action): State {
	const {
		payload,
		type,
	} = action;

	switch (type) {
		case 'async_begin':
			return {
				...INITIAL_STATE,
				isProcessing: true,
			};
		case 'async_error':
			return {
				...state,
				error: payload,
				isComplete: true,
				isError: true,
				isProcessing: false,
			};
		case 'async_success':
			return {
				...state,
				data: payload,
				isComplete: true,
				isProcessing: false,
				isSuccess: true,
			};
		default:
			return state;
	}
}

export function useCancellableAsyncMethod(asyncMethod: Function, asyncMethodDeps: Array<any>, options: Options = {}): State {
	if (!asyncMethod) {
		throw new Error(`useCancellableAsyncMethod 'asyncMethod' is required.`);
	}

	if (typeof asyncMethod !== 'function') {
		throw new Error(`useCancellableAsyncMethod 'asyncMethod' must be a function.`);
	}

	if (!asyncMethodDeps) {
		throw new Error(`useCancellableAsyncMethod 'asyncMethodDeps' is required.`);
	}

	if (!Array.isArray(asyncMethodDeps)) {
		throw new Error(`useCancellableAsyncMethod 'asyncMethodDeps' must be an array.`);
	}

	if (typeof options !== 'object') {
		throw new Error(`useCancellableAsyncMethod 'options' must be an object.`);
	}

	const latestPromise = useRef(null);
	const handlers = useRef({
		onError: undefined,
		onSuccess: undefined,
	});
	handlers.current.onError = options.onError || noop;
	handlers.current.onSuccess = options.onSuccess || noop;

	if (typeof handlers.current.onError !== 'function') {
		throw new Error(`useCancellableAsyncMethod 'onError' must be a function.`);
	}

	if (typeof handlers.current.onSuccess !== 'function') {
		throw new Error(`useCancellableAsyncMethod 'onSuccess' must be a function.`);
	}

	const isUnmounted = useRef(false);

	// Purposefully excluding asyncMethod from the asyncCallback dependencies array as
	// asyncMethodDeps is intended to be the decider for when the asyncCallback needs updated,
	// irregardless of whether or not the asyncMethod itself is changing.
	const asyncCallback = useCallback(() => asyncMethod(), asyncMethodDeps);
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

	useLayoutEffect(() => {
		return (): void => {
			isUnmounted.current = true;
		};
	}, []);

	useLayoutEffect(() => {
		const promise = asyncCallback();

		if (promise) {
			latestPromise.current = promise;
			dispatch({
				type: 'async_begin',
			});

			promise.then((response) => {
				if (!isUnmounted.current) {
					if (promise === latestPromise.current) {
						handlers.current.onSuccess(response);

						dispatch({
							type: 'async_success',
							payload: response,
						});
					}
				}
			}).catch((err) => {
				if (!isUnmounted.current) {
					if (promise === latestPromise.current) {
						handlers.current.onError(err);

						dispatch({
							type: 'async_error',
							payload: err,
						});
					}
				}
			});
		}
	}, [asyncCallback]);

	return state;
}
