import { get, cloneDeep, memoize } from 'lodash';
import { createContext, DataToFormData } from 'dynamic-form';


export type filterFields = {
	selector: 'key' | string;
	match?: 'includes' | 'exact' | 'notIncludes'; // default 'includes'
	value: any; // should usualy be a string or boolean -> but gives flexibility
};
export function filterFields(
	context: DynamicForm.Context,
	...params: filterFields[]
): DynamicForm.FormData {
	let filteredFormData = context.formData;
	params.forEach(({ selector, match = 'includes', value }) => {
		// for performance, if the selector is key and exact, we can directly access that from the object
		if (selector === 'key' && match === 'exact') {
			const formField = filteredFormData[value];
			filteredFormData = formField ? DataToFormData(formField) : {};
		} else {
			filteredFormData = Object.keys(filteredFormData).reduce((object, key) => {
				let matched = false;
				if (selector === 'key') {
					if (match === 'includes' && key.includes(value)) {
						matched = true;
					} else if (match === 'notIncludes' && !key.includes(value)) {
						matched = true;
					} else if (match === 'exact' && key === value) {
						matched = true;
					}
				} else {
					const fieldMatchedSelector = get(filteredFormData[key], selector);
					if (fieldMatchedSelector !== undefined) {
						if (
							match === 'includes' &&
							(typeof fieldMatchedSelector === 'string' ||
							Array.isArray(fieldMatchedSelector)) &&
							fieldMatchedSelector.includes(value)
						) {
							matched = true;
						} else if (
							match === 'notIncludes' &&
							(typeof fieldMatchedSelector === 'string' ||
							Array.isArray(fieldMatchedSelector)) &&
							!fieldMatchedSelector.includes(value)
						) {
							matched = true;
						} else if (match === 'exact' && fieldMatchedSelector === value) {
							matched = true;
						}
					}
				}
				if (matched) {
					object[key] = filteredFormData[key];
				}

				return object;
			}, {});
		}
	});
	return cloneDeep(filteredFormData);
}

const filterFieldsMemoizeWrapper = memoize((
	context: DynamicForm.Context,
	...params: filterFields[]
): string[] => {
	return Object.keys(filterFields(context, ...params));
}, (context, ...params) => `${ context?.formData?.memoizeKey }_${ params.map(i => Object.values(i).join('')).join('_') }`);

/**
 * memoized wrapper around filterFields
 * ONLY USE IF YOU KNOW FIELDS/CHILDREN WON'T CHANGE!
 */
export const filterFieldsMemoized = (
	context: DynamicForm.Context,
	...params: filterFields[]
): DynamicForm.FormData => {
	if (context.formData?.memoizeKey?.length && params?.length) {
		const fieldIds = filterFieldsMemoizeWrapper(context, ...params);
		if (fieldIds?.length) {
			// only return if memoize actually returns something. Otherwise fallback to normal filterFields
			const newData: DynamicForm.FormData = {};
			fieldIds.forEach((key) => {
				newData[key] = context.formData[key];
			});
			return cloneDeep(newData);
		}
	}
	return filterFields(context, ...params);
};

export function findOne(context: DynamicForm.Context, ...params: filterFields[]): DynamicForm.Data | null {
	const fields = filterFields(context, ...params);
	const first = Object.keys(fields)[0];
	if (typeof first !== 'undefined') {
		return fields[first];
	}
	return null;
}


export function findParent(context: DynamicForm.Context, idOfChild: string): DynamicForm.Data | null {
	const parent = Object.keys(context.formData).find(key => context.formData[key]?.children?.includes(idOfChild));
	return parent ? context.formData[parent] : null;
}

export function findClosest(context: DynamicForm.Context, idOfChild: string, ...params: filterFields[]): DynamicForm.Data | null {
	const parent = findParent(context, idOfChild);
	if (parent) {
		const findMatch = findOne(context, { selector: 'key', value: parent.props.id, match: 'exact' }, ...params);
		if (!findMatch) {
			return findClosest(context, parent.props.id, ...params);
		}
		return findMatch;
	}
	return null;
}

export function findChildren(context: DynamicForm.Context, idOfParent: string, ...params: filterFields[]): DynamicForm.FormData {
	const parent = context.formData[idOfParent];
	if (parent && parent.children && parent.children.length) {
		let children = {} as DynamicForm.FormData;
		parent.children.forEach((id) => {
			const field = context.formData[id];
			if (field.children && field.children.length) {
				const nestedChildren = findChildren(context, id, ...params);
				children = { ...children, ...nestedChildren };
			}
			if (field) {
				children[id] = field;
			}
		});

		const dummyContext = createContext({ formData: cloneDeep(children) });

		return filterFields(dummyContext, ...params);
	}
	return null;
}
