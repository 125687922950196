import React, { useEffect, useState } from 'react';
import { formHasErrors, getFormErrors, getInitialFormValues, usePrevious } from './utils';

import { AcaStatusSelect } from 'aca-status-select.react';
import { Flex } from '@bamboohr/fabric';
import { RadioButton } from './radio-button';
import { TextField } from 'form-fields.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { initialErrorState } from './constants';

/**
 * @param {array} props.acaStatuses
 * @param {object} props.initialValues
 * @param {boolean} props.isEdit
 * @param {boolean} props.isProcessing
 * @param {function} props.postFormData
 * @param {function} props.setIsProcessing
 * @param {boolean} props.showAcaStatus
 * @param {boolean} props.showChangeHistory
 */
export function EmploymentStatusModalContent(props) {
	const { acaStatuses, initialValues, isEdit, isProcessing, postFormData, setIsProcessing, showAcaStatus, showChangeHistory } = props;

	const [formValues, setFormValues] = useState(() => getInitialFormValues(initialValues));
	const [formErrors, setFormErrors] = useState(initialErrorState);
	const prevIsProcessing = usePrevious(isProcessing);

	// Run validation only when isProcessing switches to true
	useEffect(() => {
		if (!prevIsProcessing && isProcessing) {
			// Validate
			const newFormErrors = getFormErrors(formValues, showAcaStatus);
			setFormErrors({ ...newFormErrors });

			if (formHasErrors(newFormErrors)) {
				setIsProcessing(false);
			} else {
				postFormData(formValues);
			}
		}
	}, [prevIsProcessing, isProcessing, formValues, postFormData, setIsProcessing, showAcaStatus]);

	const handleNameChange = (event) => {
		setFormValues({
			...formValues,
			name: event.target.value,
		});
	};

	const handleAcaStatusSelect = (newValue) => {
		setFormValues({
			...formValues,
			acaStatus: newValue || '',
		});
	};

	const handleFteChange = (event) => {
		setFormValues({
			...formValues,
			fte: event.target.value,
		});
	};

	const handleChangeHistoryChange = (event) => {
		setFormValues({
			...formValues,
			changeHistory: event.target.value,
		});
	};

	return (
		<form>
			<div className='fab-FormRow'>
				<TextField
					hasError={formErrors.name}
					id='employmentStatusName'
					label={$.__('Name')}
					onChange={handleNameChange}
					required={true}
					value={formValues.name}
				/>
			</div>
			{showAcaStatus && (
				<div className='fab-FormRow'>
					<div>
						<AcaStatusSelect
							allowedAcaStatuses={acaStatuses}
							hasError={formErrors.acaStatus}
							id='employmentStatusAca'
							onSelect={handleAcaStatusSelect}
							selectedValue={formValues.acaStatus}
							showLabel={true}
						/>
					</div>
				</div>
			)}
			{isEdit && (
				<div className='fab-FormRow'>
					<TextField
						hasError={formErrors.fte}
						id='employmentStatusFTE'
						label={$.__('FTE (Full Time Equivalent)')}
						maxLength='4'
						note={$.__('1.0 is equal to one full-time worker')}
						onChange={handleFteChange}
						value={formValues.fte}
						width='4'
					/>
				</div>
			)}
			{showChangeHistory && (
				<div className={`fab-FormRow ${ifFeature('encore', 'fab-marginTop--8', '')}`}>
					<fieldset className='fab-RadioGroup check-radio'>
						<Flex flexDirection='column' gap={1}>
							<legend className={`fab-RadioGroup__legend ${ifFeature('encore', 'fab-fontWeight--medium', '')}`}>
								{$.__('How should this change occur?')}
							</legend>
							<RadioButton
								id='changeHistory1'
								label={$.__('Change for future but leave history alone.')}
								onChange={handleChangeHistoryChange}
								selectedValue={formValues.changeHistory}
								value='noHistory'
							/>
							<RadioButton
								id='changeHistory2'
								label={$.__('Change anywhere it is used, including history.')}
								onChange={handleChangeHistoryChange}
								selectedValue={formValues.changeHistory}
								value='history'
							/>
						</Flex>
					</fieldset>
				</div>
			)}
		</form>
	);
}
