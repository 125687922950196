/**
 * Used to create a mask dynamically based on input from user dynamically
 * or return a static value based on a string passed in
 */

const emptyString = '';

export default function createDynamicMask({
	maxLength = null,
	minLength = null,
	replaceInvalidCharacters = true,
	nonMatchingRegExp = /\W/g,
	matchingRegularExpression = /\w/,
} = {}): (string?: string) => Array<RegExp> {

	function alphanumericMask(rawValue = emptyString): Array<RegExp> {

		if (rawValue === emptyString && minLength === null) {
			return [matchingRegularExpression]; // dynamically create a single mask for now
		}

		let stringBuilder = rawValue;

		if (maxLength && typeof maxLength === 'number') {
			stringBuilder = stringBuilder.slice(0, maxLength);
		}

		stringBuilder = replaceInvalidCharacters ?
			stringBuilder.replace(nonMatchingRegExp, emptyString) :
			stringBuilder;

		if (minLength && typeof minLength === 'number') {
			const currentLength = stringBuilder.length;
			if (currentLength < minLength) {
				for (let x = currentLength; x < minLength; x++) {
					stringBuilder += '9';
				}
			}
		}

		return convertToMask(stringBuilder);
	}

	function convertToMask(strNumber): Array<RegExp> {
		return strNumber
			.split(emptyString)
			.map(char => (matchingRegularExpression.test(char) ? matchingRegularExpression : char));
	}

	alphanumericMask.instanceOf = 'createDynamicMask';

	return alphanumericMask;
}

