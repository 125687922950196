import {
	useEffect,
	useState,
} from 'react';
import { closeModal } from 'show-modal.mod';
import { getFileInfo } from '../../../../service';
import { showSlidedown } from 'Alerts.mod';

export const useFileInfo = (type, id) => {

	const [info, setInfo] = useState({
		addedAt: '',
		addedBy: '',
		fileExtension: '',
		fileName: '',
		fileSize: '',
		isEsignature: false,
		loading: true,
	});

	useEffect(() => {
		(async () => {
			try {
				const result = await getFileInfo(type, id);
				setInfo({
					...result.data,
					loading: false
				});
			} catch (e) {
				closeModal();
				window.errorFallBack();
			}
		})();
	}, []);

	return info;

};
