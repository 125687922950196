import React from 'react';

/**
 * This component wraps the inputs and appends an optional note
 *
 * Optional prop
 * @prop {string} [props.note] // The note to append to the end of the currency picker field
 */
export function CurrencyPickerWrapper(props) {
	const { children, note } = props;

	return (
		<div className="fieldDiv">
			<div className="fab-InputWrapper">
				{ children }
			</div>
			{ note && <span className="fab-FormNote">{ note }</span> }
		</div>
	);
}
