import { defaultProps } from './modal';
import { ModalMain } from './modal-main';
import { ModalTitle } from './modal-title';

export const DOCS_ONLY = {
	defaultProps,
	ModalMain,
	ModalTitle,
};

export { Modal } from './modal';
export { resetBodyStyles } from './utils';
