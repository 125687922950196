module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push(''); if (workWeekStartsOn.count > 0) { 
p.push('  <div class="TimeDetail__workWeekWarning">   <ba-icon class="TimeDetail__workWeekWarning__icon" name="fab-triangle-exclamation-20x20"></ba-icon>   <p class="TimeDetail__workWeekWarning__text">    ',window.microTemplate.encodeHtml(workWeekWarningMessage),'   </p>  </div> '); } 
p.push('  <div class="TimeDetail">   <div class="TimeDetailSlats">    '); entries.forEach(function (entry, index) { 
p.push('   <div class="TimeDetailSlat">    <div class="TimeDetailSlat__day">     '); if (entry.hasWorkWeekStartsOnChanged) { 
p.push('      <div class="TimeDetailSlat__day__workWeekWarning js-time-details-work-week-warning-icon">       <ba-icon class="TimeDetailSlat__day__workWeekWarning__icon" name="fab-triangle-exclamation-16x16"></ba-icon>      </div>     '); } 
p.push('     <div class="TimeDetailSlat__dayOfWeek">',window.microTemplate.encodeHtml(entry.dayOfWeek),'</div>     <div class="TimeDetailSlat__dayDate">',window.microTemplate.encodeHtml(entry.dayDate),'</div>    </div>    <div class="TimeDetailSlat__data">     <div class="TimeDetailSlat__hours">',window.microTemplate.encodeHtml(formatTime(entry.hours || 0)),'</div>      '); if (entry.note) { 
p.push('      <div class="TimeDetailSlat__note js-time-details-modal-note" data-tooltip-index="',window.microTemplate.encodeHtml(index),'">       <ba-icon class="baseFillColor TimeDetailSlat__noteIcon" name="fab-page-9x11"></ba-icon>      </div>     '); } 
p.push('      <div class="TimeDetailSlat__extraInfo">       '); entry.overtimeSummary.forEach(function (overtime) { 
p.push('       <div class="TimeDetailSlat__extraInfoItem TimeDetailSlat__extraInfoItem--overtime">        <ba-icon class="TimeDetailSlat__infoIcon--overtime" name="fab-stopwatch-alert-16x16"></ba-icon>        ',window.microTemplate.encodeHtml(formatTime(overtime.hours, true)),' ',window.microTemplate.encodeHtml($.__('Overtime')),' '); if (entry.overtimeSummary.length > 1) { 
p.push(' (',window.microTemplate.encodeHtml(overtime.multiplier),'x) '); } 
p.push('       </div>      '); })
p.push('       '); entry.extras.forEach(function (extra) { 
p.push('      <div class="TimeDetailSlat__extraInfoItem">       <ba-icon class="TimeDetailSlat__infoIcon" name="',window.microTemplate.encodeHtml(extra.iconName),'"></ba-icon>       ',window.microTemplate.encodeHtml(extra.info),'      </div>      '); })
p.push('      </div>    </div>   </div>   '); }); 
p.push('   </div>    <div class="TimeDetailSummaryPreserve">   <div class="TimeDetailSummary">    <img src="',window.microTemplate.encodeHtml(employeePhotoUrl),'" class="TimeDetailSummary__photo">    <div class="TimeDetailSummary__name">',window.microTemplate.encodeHtml(employeeName),'</div>    <div class="TimeDetailSummary__dateSpan">',window.microTemplate.encodeHtml(dateSpan),'</div>     <div class="TimeDetailBreakdown">     <div class="baseColor TimeDetailBreakdown__total">',window.microTemplate.encodeHtml(formatTime(totalTime)),'</div>      '); overtimeSummary.forEach(function (overtime) { 
p.push('      <div class="TimeDetailBreakdown__item TimeDetailBreakdown__alert">       ',window.microTemplate.encodeHtml(formatTime(overtime.hours, true)),' ',window.microTemplate.encodeHtml($.__('Overtime')),' '); if (overtimeSummary.length > 1) { 
p.push(' (',window.microTemplate.encodeHtml(overtime.multiplier),'x) '); } 
p.push('      </div>     '); })
p.push('      '); if (totalTimeOff) { 
p.push('     <div class="TimeDetailBreakdown__item">      ',window.microTemplate.encodeHtml(formatTime(totalTimeOff, true)),' ',window.microTemplate.encodeHtml($.__('Time Off')),'     </div>     '); } 
p.push('      '); if (totalHoliday) { 
p.push('     <div class="TimeDetailBreakdown__item">      ',window.microTemplate.encodeHtml(formatTime(totalHoliday, true)),' ',window.microTemplate.encodeHtml($.__('Holiday')),'     </div>     '); } 
p.push('    </div>     '); if (timeSheetLink) { 
p.push('    <a href="',window.microTemplate.encodeHtml(timeSheetLink),'" class="TimeDetailSummary__link fab-TextButton fab-TextButton--muted fab-TextButton--small fab-link">     ',window.microTemplate.encodeHtml($.__('Go to Timesheet')),' &raquo;    </a>    '); } 
p.push('    </div>  </div>  </div> ');}return p.join('');
}