import { ifFeature } from '@bamboohr/utils/lib/feature';
import { htmlentities } from 'String.util';

import './styles.styl';

function disableEmployee(employeeId, preferredName, afterDisableCallback) {
	$.post('/time_tracking/employees/disable', { employeeIds: [employeeId] })
		.done(res => {
			if (res.error) {
				setMessage($.__('Whoops! Something went wrong with disabling time tracking. Please refresh and try again.'), 'error');
				return;
			}
			window.BambooHR.Modal.setState({ isOpen: false });
			afterDisableCallback();
		})
		.fail(onErrorResponse);
}

function onErrorResponse() {
	setMessage($.__('Sorry, something went wrong. Try giving it another shot?'), 'error');
	window.SimpleModal.Footer.Buttons.Primary.stopProcessing();
}

export function openModal(employeeId, preferredName, lastName, afterDisableCallback = Function.prototype) {
	const title = $.__('Just Checking...');
	const headline = $.__('Are you sure you want to disable time tracking for %1$s?', `${ preferredName } ${ lastName }`);
	const description = $.__('%1$s will not get any new timesheets, but will still be able to log time on their current timesheet and it will still be sent for approval.', htmlentities(preferredName));
	const primaryActionText = $.__('Yes, Disable Time Tracking');

	window.BambooHR.Modal.setState({
		title,
		headline: headline,
		content: (
			<div className="DisableTimeTrackingModal__content">{ description }</div>
		),
		icon: ifFeature('encore', 'stopwatch-regular', 'fab-stopwatch-exclamation-51x54'),
		iconColor: 'attention',
		iconV2Color: 'primary-strong',
		isOpen: true,
		primaryAction: function() {
			window.BambooHR.Modal.setState({ isProcessing: true }, true);
			disableEmployee(employeeId, preferredName, afterDisableCallback);
		},
		primaryActionText,
	});
}

export default {
	openModal
};
