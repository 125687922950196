import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import i18nextLocalStorageCache from 'i18next-localstorage-cache';
import i18nextSprintfPostProcessor from 'i18next-sprintf-postprocessor';

let _process = i18nextSprintfPostProcessor.process;
i18nextSprintfPostProcessor.process = function process(value, key, options) {
	value = value.replace(/(%\d+)(\$\w)?/g, '$1\$s');
	return _process(value, key, options);
};

function sprintf(value, ...args) {
	return i18nextSprintfPostProcessor.process(value, value, {
			sprintf: args
		});
}

window.sprintf = sprintf;

i18next
	.use(i18nextLocalStorageCache)
	.use(i18nextXHRBackend)
	.use(i18nextSprintfPostProcessor);

export default i18next;
export { sprintf };
