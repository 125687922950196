import { cloneDeep, debounce } from 'lodash';
import {
	findOne,
	filterFields,
	findClosest,
	findChildren,
	createContext,
	DataToFormData,
} from 'dynamic-form';
import { makeUid } from 'BambooHR.util';
import DynamicForm from '../types/dynamic-form';

export function addChildToField(
	data: DynamicForm.FormData,
	child: Partial<DynamicForm.Data>,
	startOrEnd: 'start' | 'end',
	...params: filterFields[]
): DynamicForm.FormData {
	const newData = cloneDeep(data);
	const field = findOne(createContext({ formData: newData }), ...params);
	if (!field) {
		console.error(`Dynamic Form - Unable to add child, field not found`);
		return newData;
	}
	const children = Array.isArray(field.children) ? field.children : [];

	if (startOrEnd === 'start') {
		children.unshift(child.props.id);
	} else {
		children.push(child.props.id);
	}
	field.children = children;

	// @ts-ignore
	newData[child.props.id] = child;
	newData[field.props.id] = field;

	return newData;
}

export function toggleFields(
	context: DynamicForm.Context,
	idsToHide: string[] | DynamicForm.FormData, // this can contain a * to change between exact and includes
	toggle: boolean,
	hideClass = 'hidden'
): DynamicForm.FormData {
	const data = cloneDeep(context.formData);
	const dataContext = createContext({ formData: data });
	const updatedChildren = {} as DynamicForm.FormData;
	const fieldRows = {} as DynamicForm.FormData;
	const fieldSets = {} as DynamicForm.FormData;
	const hideClassString = ` ${ hideClass }`;

	const toggleClasses = (idOrField: string | DynamicForm.Data): void => {
		let fields: DynamicForm.FormData;

		if (typeof idOrField === 'string') {
			const value = idOrField.replace('*', '');
			const match = idOrField.includes('*') ? 'includes' : 'exact';
			fields = filterFields(dataContext, { selector: 'key', value, match });
		} else {
			fields = DataToFormData(idOrField);
		}

		Object.keys(fields).forEach((fieldId) => {
			const field = fields[fieldId];
			if (field.props.id.includes(':idx:')) {
				return false; // don't do anything for template field
			}
			if (field) {
				field.settings.fieldBoxClasses = field.settings.fieldBoxClasses || '';
				field.props.className = field.props.className || '';
				if (toggle) {
					if (fieldId.startsWith('field')) {
						field.settings.fieldBoxClasses += hideClassString;
						field.props.className += hideClassString;
					} else {
						field.props.className += hideClassString;
					}
				} else {
					// eslint-disable-next-line no-lonely-if
					if (fieldId.startsWith('field')) {
						field.settings.fieldBoxClasses = field.settings.fieldBoxClasses.replaceAll(
							hideClassString,
							''
						);
						field.props.className = field.props.className.replaceAll(hideClassString, '');
					} else {
						field.props.className = field.props.className.replaceAll(
							hideClassString,
							''
						);
					}
				}
				updatedChildren[field.props.id] = field;

				const closestFieldRow = findClosest(context, fieldId, {
					selector: 'type',
					value: 'FieldRow',
				});
				if (closestFieldRow) {
					fieldRows[closestFieldRow.props.id] = closestFieldRow;
				}
			}
		});
	};

	const checkChildren = (
		parentGroup: DynamicForm.FormData,
		param?: filterFields,
		nextParentGroup?: DynamicForm.FormData
	): void => {
		Object.keys(parentGroup).forEach((parentId) => {
			const fieldChildren = findChildren(context, parentId, { selector: 'key', value: 'field' });
			let fieldChildrenNotUpdated = 0;
			Object.keys(fieldChildren).forEach((id) => {
				if (!updatedChildren[id]) {
					fieldChildrenNotUpdated++;
				}
			});

			if (fieldChildrenNotUpdated === 0) {
				toggleClasses(parentId);
				if (param && nextParentGroup) {
					const closestParentGroup = findClosest(context, parentId, param);
					if (closestParentGroup) {
						fieldSets[closestParentGroup.props.id] = closestParentGroup;
					}
				}
			}
		});
	};

	if (Array.isArray(idsToHide)) {
		idsToHide.forEach(toggleClasses);
	} else {
		Object.keys(idsToHide).forEach(toggleClasses);
	}

	checkChildren(fieldRows, { selector: 'type', value: 'FieldSet' }, fieldSets);
	checkChildren(fieldSets);

	return updatedChildren;
}

/* Clickable forms */
const ALLOWED_EDITABLE_TAGS = ['INPUT', 'BUTTON', 'TEXTAREA', 'BA-SELECT', 'DIV'];

export const determineValidEditableField = (event): boolean => {
	const { tagName = '' } = event.target;

	if (!ALLOWED_EDITABLE_TAGS.includes(tagName)) {
		return false;
	}

	const isDisabled = (element): boolean => {
		if (element.querySelector('.fab-SelectToggle')?.classList?.contains('fab-SelectToggle--disabled')) {
			return true;
		}
		return element.getAttribute('disabled') !== null;
	};

	if (isDisabled(event.target)) {
		return false;
	}

	if (tagName === 'DIV') {
		const select = event.target.closest('ba-select, .fab-Select');
		if (select) {
			if (isDisabled(select)) {
				return false;
			}
		} else {
			return false;
		}
	}
	if (tagName === 'LABEL') {
		const input = document.getElementById(event.target.getAttribute('for'));
		if (isDisabled(input)) {
			return false;
		}
	}

	if (tagName === 'A') {
		return false;
	}

	if (tagName === 'BUTTON') {
		const hover = event.target.closest('.DynamicForm--HoverBase__hint');
		if (hover) {
			return false;
		}
	}

	return true;
};

export function resetForm(context: DynamicForm.Context): void {
	const newFormData: DynamicForm.FormData = {};
	Object.keys(context.formData).forEach((key) => {
		let field = context.formData[key];
		if (
			field.settings &&
			Object.prototype.hasOwnProperty.call(field.settings, 'initialValue') &&
			!field.props.id.includes(':idx:')
		) {
			field = cloneDeep(field);
			const { initialValue } = field.settings;
			if (Object.prototype.hasOwnProperty.call(field.props, 'checked')) {
				// checkbox or radio
				field.props.checked = !!initialValue;
			} else if (Object.prototype.hasOwnProperty.call(field.props, 'selectedValues')) {
				// select
				(field as DynamicForm.SelectElement).props.selectedValues = initialValue as string[];
			} else {
				// input or textarea
				field.props.value = `${ initialValue }`;
			}
			newFormData[field.props.id] = field;
		}
	});
	context.setFormData(previousFormData => ({ ...previousFormData, ...newFormData }));
}

type ControlledInputType =
	| 'text'
	| 'checkbox'
	| 'radio'
	| 'textarea'
	| 'select'
	| 'currency-object'
	| 'select-items';
type currencyObject = DynamicForm.TextElement['settings']['currency'];

export function handleControlledInput(
	type: ControlledInputType,
	fieldId: string,
	newValue: string | number | boolean | currencyObject | DynamicForm.SelectElementItems | string[],
	context: DynamicForm.Context,
	updateState = true
): void | DynamicForm.Data {
	const field = cloneDeep(context.formData[fieldId]);
	if (!field) {
		console.error(`Dynamic Form - Unable to update value for field id: ${ fieldId }`);
		return;
	}
	if (type === 'text' || type === 'textarea') {
		field.props.value = `${ newValue }`;
	} else if (type === 'checkbox' || type === 'radio') {
		field.props.checked = !!newValue;
	} else if (type === 'select') {
		let fixedNewValue: string[] = [];
		if (Array.isArray(newValue)) fixedNewValue = newValue as string[];
		if (typeof newValue === 'string') fixedNewValue = [newValue];
		(field as DynamicForm.SelectElement).props.selectedValues = fixedNewValue;
	} else if (type === 'currency-object') {
		(field as DynamicForm.TextElement).settings.currency = cloneDeep(newValue) as currencyObject;
	} else if (type === 'select-items' && Array.isArray(newValue)) {
		const newItems = cloneDeep(newValue);
		const currentField = field as DynamicForm.SelectElement;
		currentField.props.items = newItems as DynamicForm.SelectElementItems;
		const currentValueIsInNewItemList = newItems.some(item => item.value === currentField.props.selectedValues[0]);
		if (!currentValueIsInNewItemList) {
			currentField.props.selectedValues = []; // clear out the value
		}
	}

	if (updateState) {
		context.setFormData(previousFormData => ({ ...previousFormData, ...{ [field.props.id]: field } }));
	} else {
		return field;
	}
}

type countrySwitcherObject = {
	countryId: string | number;
	postCodeLabel: string;
	subEntityLabel: string;
	subEntityOptions: Array<{
		id: string;
		name: string;
		full_name: string;
		// Initial load
		text?: string;
		value?: string;
	}>;
}
export function handleCountrySwitcher(
	fieldId: string,
	zipFieldId: string,
	newValue: countrySwitcherObject,
	context: DynamicForm.Context,
	useIntialStateValue: boolean,
): void {
	const field = cloneDeep(context.formData[fieldId]);
	const newState: DynamicForm.FormData = {};
	if (!field) {
		console.error(`Dynamic Form - unable to update state field: ${ fieldId }`);
		return;
	}
	enum INPUT_TYPES {
		SELECT_FIELD,
		TEXT_FIELD
	}
	let element = document.getElementById(fieldId) as HTMLInputElement;
	let currentSelectedValue = '';
	let currentType;
	if (element?.value) {
		currentSelectedValue = element.value;
		currentType = INPUT_TYPES.TEXT_FIELD;
	} else {
		element = document.querySelector(`#${fieldId} select`);
		currentSelectedValue = element?.value;
		currentType = INPUT_TYPES.SELECT_FIELD;
	}
	if (newValue.subEntityOptions.length > 0) {
		field.type = 'SelectField';
		const newItems = newValue.subEntityOptions.map(item => ({ text: item.name || item.text, value: item.value || item.id }));
		(field as DynamicForm.SelectElement).props.items = newItems;
		field.props.className = field.props.className.replaceAll('fab-TextInput', '');
		(field as DynamicForm.SelectElement).props.isDisabled = !!field.props.disabled;
		if (useIntialStateValue) {
			(field as DynamicForm.SelectElement).props.selectedValues = Array.isArray(field.settings.initialValue) ? field.settings.initialValue : [field.settings.initialValue as string];
		} else {
			(field as DynamicForm.SelectElement).props.selectedValues = (currentType === INPUT_TYPES.SELECT_FIELD) ? [`${currentSelectedValue}`] : [];
		}
		field.settings.type = 'select';
		field.props.type = 'select';
		field.props.placeholder = newValue.subEntityLabel;
	} else {
		// if no items are returned change to a text field
		field.type = 'TextField';
		field.props.value = (typeof field.settings.initialValue === 'string' && useIntialStateValue) ? field.settings.initialValue : '';
		field.props.className += ' fab-TextInput';
		field.props.value = (currentType === INPUT_TYPES.TEXT_FIELD) ? currentSelectedValue : '';
		field.settings.type = 'text';
		field.props.type = 'text';
		delete (field as DynamicForm.SelectElement).props.isDisabled;
		delete (field as DynamicForm.SelectElement).props.selectedValues;
		delete (field as DynamicForm.SelectElement).props.items;
		delete (field as DynamicForm.SelectElement).props.placeholder;
	}
	field.settings.label = newValue.subEntityLabel;
	field.props['aria-label'] = newValue.subEntityLabel;

	const zipField = cloneDeep(context.formData[zipFieldId]);
	if (zipField) {
		// update if found
		zipField.settings.label = newValue.postCodeLabel;
		zipField.props['aria-label'] = newValue.postCodeLabel;
		newState[zipField.props.id] = zipField;
	}
	newState[field.props.id] = field;

	context.setFormData(previousFormData => ({ ...previousFormData, ...newState }));
}

export function handleAddSelectItem(
	fieldId: string,
	newItem: DynamicForm.addSelectItem,
	context: DynamicForm.Context
): void {
	const field = cloneDeep(context.formData[fieldId]) as DynamicForm.SelectElement;
	if (!field || !field.props || !field.props.items) {
		console.error(`Dynamic Form - Unable to add item for field id: ${ fieldId }`);
		return;
	}
	field.props.items.push({ text: newItem.text, value: newItem.value.toString() });
	if (newItem.selected) {
		field.props.selectedValues = [newItem.value.toString()];
	}

	context.setFormData(previousFormData => ({ ...previousFormData, ...{ [field.props.id]: field } }));
}

export function updateFieldWithType(
	fieldId: string,
	replacementType: string,
	data: DynamicForm.FormData
): DynamicForm.FormData {
	const updatedData = cloneDeep(data);
	const field = updatedData[fieldId];
	if (field) {
		field.type = replacementType;
	}
	return updatedData;
}

type Customize = {
	// either the id of the field to change or an array of data to change (by using `filterFields` or `findOne`)
	filter: string | DynamicForm.FormData;
	replacementType: string;
};

export function customizeFields(
	customize: Customize[],
	data: DynamicForm.FormData
): DynamicForm.FormData {
	if (Array.isArray(customize)) {
		const updatedData = cloneDeep(data);
		customize.forEach((custom) => {
			if (typeof custom.filter === 'string') {
				const fieldFromData = updatedData[custom.filter];
				if (fieldFromData) {
					fieldFromData.type = custom.replacementType;
				}
			} else if (typeof custom.filter === 'object') {
				const fields = custom.filter;
				Object.keys(fields).forEach((key) => {
					const field = fields[key];
					const fieldFromData = updatedData[field && field.props && field.props.id];
					if (fieldFromData) {
						fieldFromData.type = custom.replacementType;
					}
				});
			}
		});
		return updatedData;
	}
	return data;
}

export function updateChildrenWithType(
	parentId: string,
	replacementType: string,
	context: DynamicForm.Context
): void {
	const parent = context.formData[parentId];
	const children = (parent && parent.children) || [];
	const updatedChildren = {} as DynamicForm.FormData;
	children.map((child) => {
		const clonedChild = cloneDeep(context.formData[child]);
		if (clonedChild.type !== replacementType) {
			clonedChild.type = replacementType;
			updatedChildren[clonedChild.props.id] = clonedChild;
		}
		return clonedChild;
	});
	if (Object.keys(updatedChildren).length) {
		// only update state if there are children that actually need updated
		// prevents infinite updates and react tree checks
		context.setFormData(previousFormData => ({ ...previousFormData, ...updatedChildren }));
	}
}

/**
 * DEBOUNCED: Forces the form to do a full re-render. Use wisely!
 * helps for when hide/show toggling fields is done en-mass and isVisible and isRequired need to be force re-evaluated after all that is done
 *
 * @param context
 */
export const forceFormRender = debounce((context: DynamicForm.Context): void => {
	const { formData, setFormData } = context;
	// We are committing multiple unforgivable sins... This is not the droid you are looking for... move along!
	// @ts-ignore
	setFormData(previousFormData => ({ ...previousFormData, forceRender: makeUid() }));
}, 200);

/**
 * Tries to scroll the user to the first error
 */
export const scrollToError = () => {
	// TODO: in future this needs to be tied to a specific form so forms in modals don't conflict
	const errorClassFindList = [
		'.fab-Label--error', // try to get the label first
		'.fab-CheckboxGroup__legend--error', // checkbox group label
		'.fab-Textarea--error', // fallback to input if no label
		'.fab-TextInput--error', // fallback to input if no label
		'.fab-SelectToggle__innerFacade--errorCondition', // fallback to select with error
		'.fab-FormNote--error', // we've run out of options, try a note
		'.DynamicForm--error', // Custom errors in Dynamic Form (currently used for Tax Information)
	];
	const element = document.querySelector(errorClassFindList.join(','));
	if (element) {
		element.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}
};
