import {find} from 'lodash';

/**
 * Gets item with 'selected' attribute
 * @param  {object} select Select Data
 * @return {object}        Selected item
 */
export function getSelectedItem(select) {
	if (!select || !select.items) {
		return null;
	}

	return select.items.reduce((memo, item) => {
		if (item.anchor || memo) {
			return memo;
		}

		if (item.items) {
			return getSelectedItem(item);
		}

		if (item.selected === true) {
			return item;
		}
	}, null);
}

/**
 * Gets first item
 * @param  {object} select Select Data
 * @return {object}        First item
 */
export function getFirstItem(select) {
	if (!select || !select.items) {
		return null;
	}

	return select.items.reduce((memo, item, index) => {
		if (item.anchor || memo) {
			return memo;
		}

		if (item.items) {
			return getFirstItem(item);
		}

		if (index === 0) {
			return item;
		}
	}, null);
}
