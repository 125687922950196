import { Icon } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { EmailInputList } from '../email-input-list';
import { bem } from '../../../../constants';
import './styles.styl';

export const EmailFieldset = () => {

	return (
		<fieldset className={ bem.elem('emailSection', ifFeature('encore', null, ['^fab-FormSection'])) }>
			<legend className={ bem.elem('emailLegend', ifFeature('encore', null, ['^fab-FormSection__legend'])) }>
				{ifFeature('encore', null, (
				<div className={ bem.elem('emailIcon') }>
					<Icon name="fab-envelope-18x14" />
				</div>
				))}
				{ifFeature('encore', (
					<h4 className={ bem.elem('legendText') }>{$.__('Email')}</h4>
				), (
					<span className="fab-FormSection__legendText">{$.__('Email')}</span>
				))}
			</legend>
			<div className={ bem.elem('formRow', ['^fab-FormRow']) }>
				<EmailInputList />
			</div>
			<div className={ bem.elem('formRow', ['^fab-FormRow']) }>
				<label className="fab-Label" htmlFor="subject">Subject</label>
				<input id="subject" name="subject" type="text" className="fab-TextInput fab-TextInput--width100" />
			</div>
			<div className={ bem.elem('emailMessageRow', ['^fab-FormRow']) }>
				<label className="fab-Label" htmlFor="message">Message</label>
				<textarea
					className="fab-Textarea fab-Textarea--width100"
					id="message"
					name="message"
				/>
			</div>
			<div className={ bem.elem('formRow', ['^fab-FormRow']) }>
				<div className="fab-Checkbox">
					<input
						className="fab-Checkbox__input"
						defaultChecked
						id="signature"
						name="signature"
						type="checkbox"
					/>
					<label
						className={ bem.elem('signatureLabel', ['^fab-Checkbox__label']) }
						htmlFor="signature"
					>
						{ $._('Include your name and email in signature ') }
					</label>
				</div>
			</div>
		</fieldset>
	);

}
