import { BaseInputField } from './base-input-field';
import { NumberFieldProps } from './types';

import './number-field.styl';

export const NumberField = (props: NumberFieldProps): JSX.Element => {
	return (
		<BaseInputField
			{ ...props }
			type="number"
		/>
	);
};
