import {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	times,
} from 'lodash';

import {
	BEM,
} from '@utils/dom';

import {
	DEFAULT_DIGIT_LENGTH,
	INPUT_NAME,
} from './constants';
import {
	Indicator,
} from './indicator.react';

import './styles.styl';

const bem = new BEM('OneTimeCode');

export function OneTimeCodeInput(props) {
	const {
		digitLength = DEFAULT_DIGIT_LENGTH,
		isInvalid,
		shouldChunk = true,
		shouldAutoFocus = true,
		onChange,
	} = props;

	const inputRef = useRef();
	const [inputValue, updateInputValue] = useState('');

	useEffect(() => {
		if (shouldAutoFocus) {
			document.addEventListener('keydown', _onKeyDown);
		}
		return () => {
			document.removeEventListener('keydown', _onKeyDown);
		}
	}, [shouldAutoFocus]);

	useEffect(() => {
		if (onChange) {
			onChange(inputValue);
		}
	}, [inputValue]);

	const _focusInput = () => {
		inputRef.current.focus();
	};

	const _onKeyDown = (e) => {
		const {
			key,
			keyCode
		} = e;

		// Should set focus if key pressed equates to a number, a letter (for pasting), backspace, or delete.
		let shouldFocus = false;
		if (key) {
			shouldFocus = (key.length === 1 && key !== ' ') || key === 'Backspace' || key === 'Delete';
		} else if (keyCode) {
			shouldFocus = (keyCode >= 48 && keyCode <= 90) || keyCode === 8 || keyCode === 46;
		}

		if (shouldFocus) {
			_focusInput();
		}
	}

	const _onInputChange = (e) => {
		const val = e.target.value;
		// Only match numbers
		const pattern = /\d+/g;
		const newVal = val.match(pattern)?.join('') || '';
		updateInputValue(newVal);
	};

	return (
		<div className={ bem.elem('wrapper') }>
			<input
				autoCapitalize="off"
				autoCorrect="off"
				autoFocus="autofocus"
				className={ bem.elem('input') }
				inputMode="numeric"
				aria-label="one-time-code"
				maxLength={ digitLength }
				name={ INPUT_NAME }
				onChange={ _onInputChange }
				onKeyDown={ (e) => {
					// Make sure we fire callback when pressing Enter.
					const {
						key,
						keyCode,
					} = e;
					if ((key && key !== 'Enter') || (keyCode && keyCode !== 13)) {
						return;
					}

					if (onChange) {
						onChange(inputValue);
					}
				} }
				ref={ inputRef }
				spellCheck="false"
				type="text"
				value={ inputValue }
			/>

			<div
				className={ bem.elem('codeWrapper') }
				onClick={ _focusInput }
			>
				<div className={ bem.elem('code', {
					'error': isInvalid,
				}) }>
					{
						times(digitLength, (i) => {
							const key = `otc-digit-${ i }`;
							const hasChunkMargin = shouldChunk &&
								(i + 1 === Math.floor(digitLength / 2));

							return (
								<div
									className={ bem.elem('digit', {
										'chunkMargin': hasChunkMargin
									}) }
									key={ key }
								>
									<span>{ inputValue.charAt(i) }</span>
								</div>
							);
						})
					}
				</div>

				<Indicator
					count={ digitLength }
					currentIndex={ inputValue.length }
					isInvalid={ isInvalid }
					shouldChunk={ shouldChunk }
				/>

			</div>
		</div>
	);
}
