// /**
//  * Used to create a mask dynamically based on input from user
//  * or return a static value based on a string passed in
//  */

import createDynamicMask from './create-dynamic-mask';

export default function createAlphanumericMask({
	maxLength = null,
	minLength = null,
	replaceInvalidCharacters = true,
} = {}): (string?: string) => Array<RegExp> {
	return createDynamicMask({
		maxLength,
		minLength,
		replaceInvalidCharacters,
		nonMatchingRegExp: /[^A-Za-z0-9]+/g,
		matchingRegularExpression: /[A-Za-z0-9]/,
	});
}
