import { useEffect, useState } from 'react';
import { Select } from '@fabric/select';

import { AddItemModal } from 'add-item.mod/add-item-modal.react';


export const SelectInput = (props) => {
	const {
		data,
		label,
		name,
		typeProps = {},
		errorState,
		validationRegistration,
	} = props;

	if (!data) {
		return null;
	}

	const [options, setOptions] = useState(data.options);
	const [selectedValues, setSelectedValues] = useState([data.value]);
	const [addItemModalData, setAddItemModalData] = useState({ isOpen: false });

	useEffect(() => setOptions(data.options), [data.options]); // derive state from props
	useEffect(() => setSelectedValues([data.value]), [data.value]); // derive state from props

	let _addResolver;
	let _addRejecter;

	const _handleClose = () => {
		setAddItemModalData({
			isOpen: false,
		});
		_addRejecter();
	};

	const _handleSave = (newOption, id) => {
		const newOptions = options.concat({
			text: newOption,
			value: id,
		});

		setOptions(newOptions);
		setSelectedValues([id]);
		setAddItemModalData({
			isOpen: false,
		});

		_addResolver();
	};

	const _handleAdd = () => {
		setAddItemModalData({
			isOpen: true,
			label: typeProps.label,
			onCancel: _handleClose,
			onSave: _handleSave,
			name: typeProps.name,
			url: typeProps.url,
		});

		return new Promise((resolve, reject) => {
			_addResolver = resolve;
			_addRejecter = reject;
		});
	};

	const _handleSelect = (id, selected) => {
		setSelectedValues(selected);
		if (typeof typeProps.onSelect === 'function') {
			typeProps.onSelect(selected);
		}
	};

	const _handleClear = () => {
		setSelectedValues([]);
	};

	return (
		<div className={ `fab-FormColumn CandidateField CandidateField--${ name }` }>
			<label
				className={ `fab-Label ${ data.isRequired ? 'fab-Label--required' : '' } ${ errorState ? 'fab-Label--error' : '' }` }
				htmlFor={ name }
			>
				{ label }
			</label>
			<div className={ `fab-InputWrapper CandidateField__inputWrapper CandidateField__inputWrapper--${ name }` }>
				<Select
					canSelectMultiple={ typeProps.canSelectMultiple }
					condition={ errorState ? 'error' : '' }
					inputRef={ validationRegistration }
					isDisabled={ Boolean(data.isDisabled) }
					isRequired={ data.isRequired }
					items={ normalizeOptions(options) }
					name={ name }
					onAdd={ typeProps.perms ? _handleAdd : null }
					onClear={ _handleClear }
					onSelect={ _handleSelect }
					selectedValues={ selectedValues }
					width={ 100 }
				/>

			</div>
			{ typeProps.perms && <AddItemModal { ...addItemModalData } /> }
		</div>
	);
};


export function normalizeOptions(optionsList) {
	return optionsList.map((option) => {
		return {
			...option,
			text: option.text || option.displayText || option.value || option.option || '',
			value: option.value || option.id || option.text || '',
		};
	});
}
