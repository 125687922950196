import { Icon } from '@bamboohr/fabric';
import { colors } from '@bamboohr/fabric/dist/definitions/json/colors.json';
import { ReactElement } from 'react';

interface Props {
	classes?: string;
	fabricColorName: string;
	iconName: string;
}

export function ColoredIcon(props: Props): ReactElement {
	const {
		classes = '',
		fabricColorName = '',
		iconName = '',
	} = props;

	const fillColor = colors[fabricColorName] || colors.gray10;

	const style = {
		fill: fillColor,
	};

	return (
		<span className={ classes } style={ style }>
			<Icon name={ iconName } />
		</span>
	);
}
