import { Button, SheetModal } from '@bamboohr/fabric';
import { useState } from 'react';

export interface ClockedOutErrorModalProps {
	onClose: () => void;
}

export function ClockedOutErrorModal({ onClose }: ClockedOutErrorModalProps) {
	const [isOpen, setIsOpen] = useState(true);

	const handleClose = () => {
		setIsOpen(false);
		onClose();
	};

	return (
		<SheetModal isOpen={isOpen} onRequestClose={handleClose}>
			<SheetModal.Body
				renderFooter={
					<SheetModal.Footer
						actions={[
							<Button key="primary" onClick={handleClose} type='button'>
								{$.__('Got it')}
							</Button>,
						]}
					/>
				}
				renderHeader={
					<SheetModal.Header title={$.__('Whoa, something changed')} />
				}
			>
				<SheetModal.Constraint>
					<SheetModal.HeroHeadline
						icon="triangle-exclamation-regular"
						iconColor="warning-strong"
						subtext={$.__(
							'Before you clicked approve, one or more employees clocked out. Please go back and review and approve any potential changes.',
						)}
						text={$.__('At least one employee just clocked out')}
					/>
				</SheetModal.Constraint>
			</SheetModal.Body>
		</SheetModal>
	);
}
