import {isEqual} from 'lodash';
import {Component} from 'react';
import {DonutPercentageChart} from 'Charts.mod';

export default class DonutPercentage extends Component {
	_onSliceEnter = (event) => {
		this.props.onSliceEnter(event.detail);
	}

	_onSliceLeave = (event) => {
		this.props.onSliceLeave(event.detail);
	}

	componentDidMount() {
		const {
			data,
			settings
		} = this.props;

		this._donutChart = new DonutPercentageChart(this._element, settings);
		this._donutChart.draw(data);

		if (typeof this.props.onSliceEnter === 'function') {
			this._element.addEventListener('ba:donutSliceEnter', this._onSliceEnter);
		}

		if (typeof this.props.onSliceLeave === 'function') {
			this._element.addEventListener('ba:donutSliceLeave', this._onSliceLeave);
		}
	}

	componentDidUpdate() {
		const {data} = this.props;

		this._donutChart.draw(data);
	}

	componentWillUnmount() {
		if (typeof this.props.onSliceEnter === 'function') {
			this._element.removeEventListener('ba:donutSliceEnter', this._onSliceEnter);
		}

		if (typeof this.props.onSliceLeave === 'function') {
			this._element.removeEventListener('ba:donutSliceLeave', this._onSliceLeave);
		}
	}

	render() {
		return (
			<div ref={element => (this._element = element)}></div>
		);
	}

	shouldComponentUpdate(nextProps) {
		return (
			!isEqual(this.props.settings, nextProps.settings) ||
			!isEqual(this.props.data, nextProps.data)
		);
	}
}
