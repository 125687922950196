import { showMfaResetModal } from './components/mfa-reset-modal.react';
import { showBackupCodesModal } from './components/backup-codes-modal.react';

$('body').off('click.manage-nav-reset-mfa').on('click.manage-nav-reset-mfa', '.js-manage-nav-reset-mfa', (e) => {
	const { userId } = e.target.dataset;
	showMfaResetModal({ userId, isResettingOwnMfa: true });
});

$('body').off('click.manage-nav-backup-codes').on('click.manage-nav-backup-codes', '.js-manage-nav-backup-codes', (e) => {
	const { userId } = e.target.dataset;
	showBackupCodesModal({ userId });
});

export function hideManageNavMfaOptions() {
	const globalMfaOptions = document.querySelectorAll('.js-manage-nav-mfa-option');
	if (globalMfaOptions.length) {
		globalMfaOptions.forEach(el => el.classList.add('hidden'));
	}
}

export function showManageNavMfaOptions() {
	const globalMfaOptions = document.querySelectorAll('.js-manage-nav-mfa-option');
	if (globalMfaOptions.length) {
		globalMfaOptions.forEach(el => el.classList.remove('hidden'));
	}
}
