import { FunctionComponent } from 'react';
import { 
	Flex,
	SelectableBox,
	SelectableBoxGroup,
	styled,
} from '@bamboohr/fabric';
import { Message, InternalLink } from '@bamboohr/utils/lib/message';
import './employee-access.styl';
import { ifFeature} from '@bamboohr/utils/lib/feature';

const SpacedSelectableBox = styled(SelectableBox)(({ theme }) => ({
	marginRight: theme.spacing(2),
	maxWidth: 350,
}));

const EmployeeAccessRegular: FunctionComponent<DynamicForm.EmployeeAccessModeProps> = ({ access, accessLevelText, context, emptyEmail, handleSelectChange, hasEmailFields, props, renderAccessLevelOptions, renderEmailReminderText }) => {
	const { id, name } = props;
	const {
		controls: { FieldRow },
	} = context;	
	return (
			<>
				{hasEmailFields && (
					<>
						<FieldRow context={context} props={{}} settings={{}}>
							{ifFeature(
								'encore',
								<Flex data-bi-id='employees-add-employee-form-employee-access'>
									<SelectableBoxGroup ariaLabel={$.__('Self-Service Access')} type='radio'>
										<Flex gap={'24px'} flexDirection={'row'} height={'121px'}>
											<SelectableBox
												boxWidth='380px'
												description={accessLevelText}
												icon='user-check-solid'
												inputId={`${id}-enabled`}
												isChecked={access === 'enabled'}
												name={name}
												onChange={handleSelectChange}
												title={$.__('Allow Access to BambooHR')}
												type='radio'
												value='enabled'
											/>
											<SelectableBox
												boxWidth='380px'
												description={$.__(`They won't have access and will not be able to login to BambooHR.`)}
												icon='ban-regular'
												inputId={`${id}-disabled`}
												isChecked={access !== 'enabled'}
												name={name}
												onChange={handleSelectChange}
												title={$.__('No Access')}
												type='radio'
												value='disabled'
											/>
										</Flex>
									</SelectableBoxGroup>
								</Flex>,
								<div className='fieldBox fab-FormField' data-bi-id='employees-add-employee-form-employee-access'>
									<SelectableBoxGroup ariaLabel={$.__('Self-service access')} type='radio'>
										<SpacedSelectableBox
											description={accessLevelText}
											icon='fab-check-circle-20x20'
											inputId={`${id}-enabled`}
											isChecked={access === 'enabled'}
											name={name}
											onChange={handleSelectChange}
											title={$.__('Allow Access to BambooHR')}
											type='radio'
											value='enabled'
										/>
										<SpacedSelectableBox
											description={$.__(`They won't have access and will not be able to login to BambooHR.`)}
											icon='fab-block-20x20'
											inputId={`${id}-disabled`}
											isChecked={access !== 'enabled'}
											name={name}
											onChange={handleSelectChange}
											title={$.__('No Access')}
											type='radio'
											value='disabled'
										/>
									</SelectableBoxGroup>
								</div>
							)}
						</FieldRow>
						{renderAccessLevelOptions()}
					</>
				)}
				<FieldRow context={context} props={{}} settings={{}}>
					<div className='fieldBox fab-FormField'>
						{!hasEmailFields && (
							<span className='fab-FormNote DynamicForm--employeeAccess__noMargin'>
								<Message
									link={InternalLink('new_fields.php')}
									text={$._(
										`To turn this on the employee needs an email and we don't detect an email on the page. Click [here] to add.`
									)}
								/>
							</span>
						)}
						{ifFeature(
							'encore',
							renderEmailReminderText(),
							emptyEmail && access === 'enabled' && (
								<span className='fab-FormNote fab-FormNote--info DynamicForm--employeeAccess__noMargin'>
									{$.__('To enable access, this employee needs to be active and have a valid work or home email.')}
								</span>
							)
						)}
					</div>
				</FieldRow>
			</>
		);
};

export default EmployeeAccessRegular;
