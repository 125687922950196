import { BEM as Bem } from '@utils/dom';
import { Modal } from 'modal-legacy';
import { showModal, closeModal } from 'show-modal.mod';

import './styles.styl';

const bem = new Bem('AccessLevelChangeModal');

export function AdminAccessLevelChangeModal(props) {
	const { clickAction } = props;
	return (
		<Modal
			content={
				<p className={ bem.elem('copy') }>
					{ $.__('Changing your access level could cause you to lose access to BambooHR features and functionality. You may not be able to undo this action.') }
				</p>
			}
			headline={ $.__('Are you sure you want to change your access level?') }
			icon="fab-triangle-exclamation-54x54"
			iconColor="attention"
			isOpen={ true }
			onClose={ () => closeModal() }
			primaryAction={ () => {
				if (clickAction) {
					clickAction();
				}
				closeModal();
			} }
			primaryActionText={ $.__('Yes, Change My Access Level') }
			title={ $.__('Are You Sure? You May Lose Access...') }
		/>
	);
}

export const showAdminAccessLevelChangeModal = props => showModal(
	<AdminAccessLevelChangeModal {...props} />
);
