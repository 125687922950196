export default function spinnerLoader(blockName) {
	return (
		<div className={`circleDotLoader${ blockName ? ` ${ blockName }__loader` : '' }`}>
			<span className="loaderCircle1 loaderCircle"/>
			<span className="loaderCircle2 loaderCircle"/>
			<span className="loaderCircle3 loaderCircle"/>
			<span className="loaderCircle4 loaderCircle"/>
			<span className="loaderCircle5 loaderCircle"/>
			<span className="loaderCircle6 loaderCircle"/>
			<span className="loaderCircle7 loaderCircle"/>
			<span className="loaderCircle8 loaderCircle"/>
			<span className="loaderCircle9 loaderCircle"/>
		</div>
	);
}
