import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	MODAL_HEADLINE_CONTENT,
} from '../constants';
import {
	Headline,
} from '../../headline.react';
import {
	DPA_HEADLINE_ICON,
} from '../../../constants';

export function ConditionalHeadline() {
	return (
		<Headline
			icon={ DPA_HEADLINE_ICON }
		>
			<Message
				text={ MODAL_HEADLINE_CONTENT }
			/>
		</Headline>
	);
}
