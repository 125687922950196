import { createRef, useEffect } from 'react';
import { Icon, LayoutBox, IconV2 } from '@bamboohr/fabric';
import { classNameFromObject as co } from '@utils/dom';
import {
	INVALID_FORMAT,
	OVER_24,
	parseTime
} from 'time-tracking/utils';
import { INVALID_FORMAT_MSG, OVER_24_MSG } from 'time-tracking/constants';
import { EntryProjectSelector } from '../entry-project-selector.react';
import createErrorPopover, {updateErrorPopover} from 'time-tracking/popovers/input-error';
import createEntryHintPopover from 'time-tracking/popovers/entry-hint';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './hour-entry-slat.styles';

// Used for the 'hour' timesheet type input
let errorPopover = null;
let entryHintPopover = null;

export function HourEntrySlat(props) {
	const styles = useStyles();
	const inputRef = createRef();

	const {
		canEdit,
		showRemoveButton,
		hours,
		hoursError,
		id,
		note,
		onHintDismiss,
		onInputChange,
		onInputFocus,
		onNoteChange,
		onRemoveButtonClick,
		projectsWithTasks,
		showNoteInput,
		showTimeEntryHint
	} = props;

	useEffect(() => {
		if (!errorPopover) {
			errorPopover = createErrorPopover();
		}

		if (showTimeEntryHint && !entryHintPopover) {
			entryHintPopover = createEntryHintPopover();
			entryHintPopover.onClose(() => {
				entryHintPopover.destroy();
				entryHintPopover = null;

				onHintDismiss();
			});
		}
	}, []);

	const onBlur = () => {
		setTimeout(() => {
			if (entryHintPopover) {
				entryHintPopover.hide();
			}
		}, 200);

		errorPopover.hide();
	};

	const onChange = (e) => {
		const { value } = e.target;
		const parsedValue = parseTime(value);
		const isValid = (parsedValue !== OVER_24 && parsedValue !== INVALID_FORMAT);

		if (isValid) {
			if (entryHintPopover) {
				entryHintPopover.show();
			}

			errorPopover.hide();
		} else {
			const errorMessage = (parsedValue === INVALID_FORMAT ? INVALID_FORMAT_MSG : OVER_24_MSG);

			if (entryHintPopover) {
				entryHintPopover.hide();
			}

			updateErrorPopover(errorMessage, inputRef.current);
			errorPopover.show();
		}

		onInputChange(e, id);
	};

	const onFocus = (e) => {
		if (entryHintPopover) {
			entryHintPopover.setAnchor(inputRef.current);
			if (entryHintPopover) {
				entryHintPopover.show();
			}
		}
	
		if (hoursError) {
			const { value } = e.target;
			const parsedValue = parseTime(value);
			const errorMessage = (parsedValue === INVALID_FORMAT ? INVALID_FORMAT_MSG : OVER_24_MSG);
			updateErrorPopover(errorMessage, inputRef.current);
			errorPopover.show();
			return;
		}

		onInputFocus(id);
	};

	return (
		<div className={ styles.root }>
			<div className={ styles.entryRow }>
				{ showRemoveButton && (
					<span className={ styles.removeButton } onClick={ () => { onRemoveButtonClick(id); } }>
						{ifFeature('encore', <IconV2 name="xmark-solid" size={16} />, <Icon name="fab-x-10x10" />)}
					</span>
				) }

				{ifFeature('encore',
					<LayoutBox paddingRight={2}>
						<div>
							<label className={ co('fab-Label', {'fab-Label--error': hoursError}) } htmlFor="hoursWorked">{ $.__('Hours Worked') }</label>
							<div>
								<input
									className={ co('fab-TextInput fab-TextInput--width3', {'fab-TextInput--error': hoursError}) }
									disabled={ !canEdit }
									id="hoursWorked"
									onBlur={ onBlur }
									onChange={ e => onChange(e) }
									onFocus={ onFocus }
									ref={ inputRef }
									type="text"
									value={ hours }
								/>
							</div>
						</div>
					</LayoutBox>,
					<div>
						<label className={ co('fab-Label', {'fab-Label--error': hoursError}) } htmlFor="hoursWorked">{ $.__('Hours Worked') }</label>
						<div className="fab-InputWrapper">
							<input
								className={ co('fab-TextInput fab-TextInput--width3', {'fab-TextInput--error': hoursError}) }
								disabled={ !canEdit }
								id="hoursWorked"
								onBlur={ onBlur }
								onChange={ e => onChange(e) }
								onFocus={ onFocus }
								ref={ inputRef }
								type="text"
								value={ hours }
							/>
						</div>
					</div>
				)}
				{/* @startCleanup encore */}
				{ ifFeature('encore', null, projectsWithTasks.allIds.length > 0 && <span className={ styles.dash }>–</span>)}
				{/* @endCleanup encore */}
				<EntryProjectSelector { ...props } projectTaskSelectStyles={ styles.projectTaskSelect } />
			</div>
			
			{ showNoteInput && (
				<textarea
					autoFocus={ showNoteInput }
					className="AddEditEntry__noteInput fab-Textarea"
					cols="55"
					defaultValue={ note }
					disabled={ !canEdit }
					maxLength="200"
					onChange={ (event) => { onNoteChange(event, id); } }
					placeholder={ $.__('Add note...') }
					rows="2"
					type="text"
				>
				</textarea>
			) }
		</div>
	);
}
