import { Select } from '@bamboohr/fabric';
import { startCase } from 'lodash';
import { Fragment, forwardRef } from 'react';
import classnames from 'classnames';

import {
	toOdiItem,
} from './etc';

export const OdiVersionPicker = forwardRef(({ errors, name, odiResources, onChange, value }, ref) => {
	return (
		<Fragment>
			{ /* eslint-disable jsx-a11y/label-has-associated-control */ }
			{ /* eslint-disable jsx-a11y/label-has-for */ }
			<label
				className={
					classnames(
						'fab-Label',
						'fab-Label--required',
						{ 'fab-Label--error': errors?.odiVersion }
					)
				}
			>
				Select an ODI version of { startCase(name) }
			</label>
			{ /* eslint-enable jsx-a11y/label-has-associated-control */ }
			{ /* eslint-enable jsx-a11y/label-has-for */ }
			<Select
				condition={ errors?.odiVersion ? 'error' : 'default' }
				inputRef={ ref }
				items={ odiResources.map(toOdiItem) }
				name="odiVersion"
				onChange={ selection => onChange(selection[0]) }
				selectedValues={ value ? [value] : [] }
				width="9"
			/>
		</Fragment>
	);
});
