import { Fields, findOne, newEmployeeForm } from 'dynamic-form';
import { cloneDeep, memoize } from 'lodash';

import { FieldLookupValues } from '../types/enum';
import { controls } from '../dynamic-form';

/**
 * Can create a Dynamic Form context object with partial data if DynamicForm hasn't been initialized yet.
 *
 * @param partialContext
 */
export function createContext(partialContext: Partial<DynamicForm.Context>): DynamicForm.Context {
	return {
		controls,
		form: {
			isEditable: true,
			setIsEditable(isEditable) {
				this.isEditable = isEditable;
			},
		},
		formData: {},
		setFormData(funcOrData): void {
			if (typeof funcOrData === 'function') {
				this.formData = funcOrData(this.formData);
			} else {
				this.formData = funcOrData;
			}
		},
		validation: {},
		...partialContext,
	};
}

/**
 * Converts a field into a compatible object for FormData
 *
 * @param field
 */
export function DataToFormData(field: DynamicForm.Data): DynamicForm.FormData {
	return {
		[field.props.id]: field,
	};
}

/**
 * Returns a copy of the field and it's children
 *
 * @param id
 * @param context
 */
export function getCopyOfFieldAndChildren(id: string, context: DynamicForm.Context): DynamicForm.FormData {
	const field = cloneDeep(context.formData[id]);

	if (!field) {
		console.error(`Dynamic Form: Unable to find field with id: ${id}`);
		return;
	}

	let children = {};
	if (Array.isArray(field.children)) {
		const fields = field.children.map((child) => getCopyOfFieldAndChildren(child, context));
		fields.forEach((child) => {
			children = { ...children, ...child };
		});
	}
	return { [field.props.id]: field, ...children };
}

/**
 * Returns the selected value from a Select Element
 *
 * @param field
 * @returns string | undefined
 */
export const getSelectedValue = (field: DynamicForm.SelectElement | DynamicForm.SelectElementProps): string | string[] | undefined => {
	if (!field) {
		return undefined;
	}
	const {
		props: { selectedValues = [] },
	} = field;

	if (selectedValues.length === 0) return '';

	return selectedValues.length === 1 ? selectedValues[0] : selectedValues;
};

/**
 * Returns the selected item from a Select Element
 *
 * @param field
 * @returns Select Field Item | undefined
 */
export const getSelectedItem = (
	field: DynamicForm.SelectElement | DynamicForm.SelectElementProps
): DynamicForm.SelectElementItems[0] | undefined => {
	const value = getSelectedValue(field);
	if (value !== undefined) {
		return field.props.items.find((item) => item.value == value);
	}
	return undefined;
};

/**
 * Internal method to memoize the getField method
 */
const memoizedFindOneId = memoize(
	(context: DynamicForm.Context, value: Fields | newEmployeeForm, findByValue: FieldLookupValues = FieldLookupValues.className) => {
		const field = findOne(context, { selector: findByValue, value });
		return field?.props?.id;
	},
	(context, value) => `${context?.formData?.memoizeKey}_${value}`
);

/**
 * Returns the field matching the enum passed in
 * Memoizes if it can
 *
 * @param context DynamicForm.Context
 * @param value The enum field
 */
export const getField = (
	context: DynamicForm.Context,
	value: Fields | newEmployeeForm,
	findByValue: FieldLookupValues = FieldLookupValues.className
): DynamicForm.Data | null => {
	if (Object.values(newEmployeeForm).includes(value as any)) {
		return cloneDeep(context.formData[value]);
	}

	if (context.formData?.memoizeKey?.length && value?.length) {
		// we can use the memoized version of findOne. It will return the id because we don't want the object to be stuck in time
		const fieldId = memoizedFindOneId(context, value, findByValue);
		if (fieldId) {
			// only return if the field is truthy. Otherwise we will fallback to normal findOne
			return cloneDeep(context.formData[fieldId]);
		}
	}

	return findOne(context, { selector: findByValue, value });
};
