import classNames from 'classnames';
import { Icon } from '@bamboohr/fabric';
import { useState } from 'react';


export const Checkbox = (props) => {
	const {
		errorState,
		label,
		name,
		data,
		validationRegistration,
	} = props;

	if (!data) {
		return null;
	}

	const [isChecked, setIsChecked] = useState(data.value === 'yes');

	return (
		<fieldset className={ `fab-FormColumn CandidateField CandidateField--${ name }` }>
			<div className="fab-InputWrapper CandidateField__inputWrapper CandidateField__inputWrapper--cqCheckbox">
				<div className="fab-Checkbox CandidateField__checkboxWrapper">
					<input
						ref={ validationRegistration }
						checked={ isChecked }
						className={ classNames('fab-Checkbox__input', {
							'fab-Checkbox__input--error': errorState,
						}) }
						disabled={ Boolean(data.isDisabled) }
						id={ name }
						name={ name }
						onChange={ () => setIsChecked(!isChecked) }
						required={ data.isRequired }
						type="checkbox"
						value="yes"
					/>
					<label
						className={ classNames('fab-Checkbox__label', {
							'fab-Checkbox__label--error': errorState,
						}) }
						htmlFor={ name }
					>
						{ errorState && (
							<div className="CandidateField__checkboxErrorIcon">
								<Icon name="fab-circle-exclamation-14x14" />
							</div>
						) }
						<div>
							{ label }
							{ data.isRequired && (
								<span className="CandidateField__checkboxRequiredIndicator">*</span>
							) }
						</div>
					</label>
					{ !isChecked && (
						<input
							name={ name }
							type="hidden"
							value="no"
						/>
					) }
				</div>
			</div>
		</fieldset>
	);
};
