export function isSalariedHoursPerWeekNumberValid(number: number): boolean {
	if (typeof number !== 'number') {
		return false;
	}
	
	if (Number.isNaN(number)) {
		return false;
	}

	if (number <= 0) {
		return false;
	}

	if (number > 168) {
		return false;
	}

	return true;
}
