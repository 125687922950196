import { Icon, IconV2 } from '@bamboohr/fabric';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';


export const wizard = {
	name: 'fab-wizard',
	load: 'sequential',
	disableTabClick: true,
	hiddenParts: ['icon', 'secondaryText'],
	extraClasses: {
		tab({
			index,
			BaTabs: {
				activeTabIndex,
			},
		}) {
			return {
				'--done': index < activeTabIndex,
			};
		},
	},
	TabCaret({
		className,
		tab: {
			index,
			number,
			BaTabs: {
				activeTabIndex,
			},
		},
	}) {
		const isCurrentTab = index === activeTabIndex;
		return ifFeature('encore',
			<div className={ className }>
			{ index < activeTabIndex ? (
				<IconV2 color='primary-strong' name='circle-check-solid' size={24} />
			) : (
				<IconV2 color={isCurrentTab ? 'primary-strong' : 'neutral-extra-strong'} name={`circle-${number}-${isCurrentTab ? 'solid' : 'regular'}`} size={24} />
			) }
			</div>,
			<div className={ className }>
				{ index < activeTabIndex ? (
					<Icon name="fab-check-14x14" />
				) : (
					<span>
						{ number }
					</span>
				) }
			</div>
		);
	},
};
