/*
	This is a bare minimum implementation to get validation (form-validation.js) to work with React.

	NOTE: The `isValid` argument will not change until `validate()` is invoked. This matches the
	implementation of the jQuery Validate plugin.

	Support for supplying custom rules hasn't been added yet because it isn't currently being used
	in React. If you have the need to add custom validation rules, the recommended implementation
	is to add a "rules" prop to forward the rules into the validator (when invoking bhrValidate()).

	Usage:

	<Validator render={
		({isValid, validate}) => (
			<Fragment>
				<input type="text" required />
				<button type="button" disabled={isValid} onClick={validate}>Save</button>
			</Fragment>
		)
	} />
*/

import { Flex } from '@bamboohr/fabric';
import {
	Component,
	createRef
} from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

class Validator extends Component {
	_formRef = createRef();

	state = {isValid: true};

	componentDidMount() {
		this._validator = $(this._formRef.current).bhrValidate();
	}

	render() {
		return (
			<>
			{ifFeature('encore',
				<form ref={this._formRef}>
					<Flex alignItems="center" flexWrap="wrap">
						{
							this.props.render({
								isValid: this.state.isValid,
								reset: ::this._reset,
								validate: ::this._validate
							})
						}
					</Flex>
				</form>, 
				<form ref={this._formRef}>
					{
						this.props.render({
							isValid: this.state.isValid,
							reset: ::this._reset,
							validate: ::this._validate
						})
					}
				</form>)}
			</>
		);
	}

	_validate() {
		const isValid = this._validator.form();
		this.setState({isValid});
		return isValid;
	}

	_reset() {
		this._validator.resetForm();
	}
}

export default Validator;
