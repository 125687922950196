import { File, NewHirePacketTask, Task, UpdatedFile, User } from 'NHPTemplates.mod/Form/Interfaces';
import { PacketTask } from 'NHPTemplates.mod/Form/store/Interfaces';


type State = {
	errorFields?: Record<string, boolean>
	hireDate?: string;
	infoHighlightFields?: Record<string, boolean>;
	selectedManagerId?: number;
}

enum ACTION_TYPES {
	SET_INITIAL= 'setInitial',
	UPDATE_ERROR_FIELDS = 'updateErrorFields',
	UPDATE_HIGHLIGHTED_FIELDS = 'updateHighlightedField',
	UPDATE_MANAGER_ID = 'updateManager',
	UPDATE_HIRE_DATE = 'updateHireDate'
}

export {
	ACTION_TYPES,
	File,
	NewHirePacketTask,
	PacketTask,
	State,
	Task,
	UpdatedFile,
	User,
};
