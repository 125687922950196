import './styles.styl';

export const large = {
	name: 'fab-large',
	variants: ['light'],
	extraClasses: {
		tab: ({icon}) => ({
			'--withIcon': icon,
		}),
		tabIcon: ({active}) => ({
			baseFillColor: active,
		}),
	},
};
