import { InPageMessagingProps } from "@bamboohr/fabric";

export enum CancellationType {
	ACCOUNT = 'account',
	PAYROLL = 'payroll',
	PERFORMANCE = 'performance',
	TIME_TRACKING = 'time_tracking',
	BENEFIT_ADMIN = 'benefit_admin',
}

export const ProductSelectionNamesMap = {
	[CancellationType.ACCOUNT]: $.__('Full BambooHR Account'),
	[CancellationType.PAYROLL]: $.__('Payroll'),
	[CancellationType.PERFORMANCE]: $.__('Performance Management'),
	[CancellationType.TIME_TRACKING]: $.__('Time Tracking'),
	[CancellationType.BENEFIT_ADMIN]: $.__('Benefits Administration'),
};

export interface SadToGoBoxProps {
    cancellationData: Array<{
        feature?: CancellationType;
        pending_cancellation: boolean;
        date_requested: string;
        user_who_requested_cancellation: string;
        cancellation_date?: string;
        cancellation_details?: {
            final_pay_date?: string;
        };
    }>;
    updateCancellationData?: () => void;
    isAddon?: boolean;
		render?: (props: InPageMessagingProps) => JSX.Element
}
