import createFeedStore from './store';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initializeFeedConfig, getConfig } from './constants';
import { render } from 'base/wrapped-render';

import * as actions from './actions';
import * as selectors from './store/selectors';
export {
	actions,
	selectors,
};
export { default as Comments } from './views/comments/components/comments';
export { default as UserName } from './views/comments/containers/user-name';

let boundActions = {};

export const getBoundActions = () => boundActions;

export const configureFeed = (state = {}, config) => {
	const FEED_CONFIG = initializeFeedConfig(config);
	const feedState = {...state};

	const Feed = FEED_CONFIG.rootComponent;
	feedState.id = FEED_CONFIG.parentId;
	const store = createFeedStore(feedState);
	const {
		addComment,
		addEvent,
	} = actions;

	boundActions = bindActionCreators(
		{
			addComment,
			addEvent,
		},
		store.dispatch
	);

	return (
		<Provider store={ store }>
			<Feed />
		</Provider>
	);
};

/**
 * [loadFeed description]
 * @param  {String}     elemId                    The element to render to
 * @param  {Object}     [state={}]                Initial data for the feed store
 * @param  {FeedConfig} [config=new FeedConfig()] Configuration Record/Object for feeds
 */
export const loadFeed = (elemId, state = {}, config) => {
	render(
		configureFeed(state, config),
		document.getElementById(elemId)
	);
};

