/*
 * Hide content with the class of 'ba-privateContent'
 * css: css/_modules/Masks.mod.styl
 */
class PrivateContent {
	constructor() {
		// Add privateContent click event handler
		$(document).on("click", ".ba-privateContent", function(e) {
			$(this).removeClass('ba-privateContent');
		});
	}
}

export default new PrivateContent();
