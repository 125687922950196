import { useEffect, useState } from 'react';

import { FileIcon } from '@bamboohr/fabric';
import { Modal } from 'modal-legacy';

import { Message } from '@bamboohr/utils/lib/message';
import { showModal, closeModal } from 'show-modal.mod';
import { Content } from './components/content.react';

import { BEM, SHEET_PROPS } from './constants';
import { disableSfsLink } from './service';
import './styles.styl';

export function SecureDownloadManager(props) {
	const {
		file: {
			addedAt,
			addedBy,
			fileName,
			fileSize,
			isEsignature,
		},
		sfsItems,
		onDisableLink,
	} = props;

	const [sfsDisableId, setSfsDisableId] = useState(null);
	const [sfsActiveItems, setSfsActiveItems] = useState(sfsItems);

	useEffect(() => {
		if (onDisableLink) {
			onDisableLink(sfsActiveItems.length);
		}
	}, [sfsActiveItems])

	const sheetProps = sfsDisableId && {
		...SHEET_PROPS,
		onClose: () => setSfsDisableId(null),
		primaryAction: () => {
			const callback = () => {
				// Remove disabled link from active item list.
				const items = sfsActiveItems.filter(item => item.sfsId !== sfsDisableId);
				setSfsActiveItems(items);
				setSfsDisableId(null);
			};
			disableSfsLink(sfsDisableId, callback);
		},
	};

	return (
		<Modal
			alternativeActionText={ false }
			contentHasMaxHeight={ false }
			contentHasPadding={ false }
			isOpen={ true }
			onClose={ () => closeModal() }
			primaryAction={ () => closeModal() }
			primaryActionText={ $.__('Done') }
			sheetProps={ sheetProps }
			title={ $.__('Manage Secure Download Links') }
			type="medium"
		>

			<div className={ BEM.elem('header') }>
				<div className={ BEM.elem('headerIcon') }>
					<FileIcon filename={fileName} size="small" type={isEsignature ? 'esig' : undefined} />
				</div>
				<div>
					<h4 className={ BEM.elem('headerFileName') }>{ fileName }</h4>
					<div className={ BEM.elem('headerFileMeta') }>
						<Message
							text={ $._("Added {1} by {2} ({3})") }
							params={ [addedAt, addedBy, fileSize] }
						/>
					</div>
				</div>
			</div>

			<Content
				openDisableLinkConfirmation={ id => setSfsDisableId(id) }
				sfsItems={ sfsActiveItems }
			/>
		</Modal>
	)
}

export const showSecureDownloadManager = props => showModal(
	<SecureDownloadManager {...props} />
);
