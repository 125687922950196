import { FunctionComponent, useEffect, useState } from 'react';
import { useCountrySwitcher } from 'country-switcher.hook';
import { hasTrax } from 'BambooHR.util';
import ajax from '@utils/ajax';
import {
	getSelectedValue,
	handleControlledInput,
	handleCountrySwitcher,
	doTraxCheckForAddressGroups,
	isSelectedPSSyncing,
} from 'dynamic-form';

const SelectStandardCountryField: FunctionComponent<DynamicForm.SelectElementProps> = (selectProps) => {
	const { props, settings, context } = selectProps;
	const { id, 'data-state-field-id': stateFieldId, 'data-zip-field-id': zipFieldId, items, selectedValues } = props;
	const { initialValue } = settings;
	const {
		controls: { SelectField },
		formData,
	} = context;
	const currentSelectedValue = getSelectedValue(selectProps);
	// repeat fields have the full id, standard QF from BE only has the digit ID
	const stateField = (stateFieldId?.includes('field') ? formData[stateFieldId] : formData[`field_${ stateFieldId }-0`]) as DynamicForm.SelectElement;
	const zipFieldIdCalculated = zipFieldId?.includes('field') ? zipFieldId : `field_${ zipFieldId }-0`;
	const isBambooPayroll = isSelectedPSSyncing(context);
	const checkTrax = hasTrax() && doTraxCheckForAddressGroups();
	const statesEndpoint = `/ajax/get_states?${ checkTrax ? `payrollCheckbox=${ isBambooPayroll }&` : '' }country=`;

	const [listItems, setListItems] = useState<DynamicForm.SelectElementItems>(items);
	const [currentCountryId, setCurrentCountryId] = useState<string>(initialValue[0])
	const {
		countryId,
		postCodeLabel,
		subEntityLabel,
		subEntityOptions,
		switchCountry,
	} = useCountrySwitcher({
		initialCountryId: initialValue[0] ? ~~currentCountryId : 1,
		initialSubEntityOptions: stateField ? stateField.props.items : [],
		endpoint: statesEndpoint,
	});

	const updateCountryListItems = () => {
		// form state must be updated here. If updated in the ajax callback, context is old because of the closure
		handleControlledInput('select-items', id, listItems, context);
	};

	const updateCountryStateZip = () => {
		// form state must be updated here. If updated in the ajax callback or nested effects, context is old because of the closures
		handleCountrySwitcher(stateField.props.id, zipFieldIdCalculated, { countryId, postCodeLabel, subEntityLabel, subEntityOptions }, context, initialValue[0] === selectedValues[0]);
	};

	useEffect(() => {
		updateCountryListItems();
	}, [listItems]);

	useEffect(() => {
		// default to United States if value is unselected -> 0 (unknown) returns error
		const countryCode = selectedValues[0];

		if (countryCode && +countryCode !== +currentCountryId) {
			setCurrentCountryId(countryCode)
			switchCountry(countryCode);
		}
	}, [currentSelectedValue]);

	useEffect(() => {
		// hook ajax complete, update form state
		if (stateField) {
			updateCountryStateZip();
		}
	}, [countryId, currentSelectedValue]);


	useEffect(() => {
		if (selectedValues[0]) {
			switchCountry(selectedValues[0]);
		}
	}, []);

	const updateCountrySelector = (): void => {
		let params = '';

		if (checkTrax) {
			params += `payrollChecked=${ isBambooPayroll }`;
		}

		// only make the BE request if we need to!
		if (params.length !== 0) {
			ajax.get(`/ajax/get_countries?${ params }`)
				.then((response) => {
					if (response.data.success) {
						const list = Object.keys(response.data.data)
							.map(key => ({ text: response.data.data[key], value: key })) as DynamicForm.SelectElementItems;
						setListItems(list);
					}
				})
				.catch((e) => {
					console.error('Dynamic Form - Select Standard Country - Ajax request failed to update country selector', e);
				});
		}
	};

	useEffect(() => {
		updateCountrySelector();
	}, [isBambooPayroll]);

	return (
		<SelectField { ...selectProps } />
	);
};

export default SelectStandardCountryField;
