import {
	Flex,
	Label,
	IconButton,
	LayoutBox,
	Popover,
	Select,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import { Message, ExternalLink } from '@utils/message';
import { Component } from 'react';
import c from 'classnames';

/* @startCleanup encore */
import OldSelect from 'select.react';
/* @endCleanup encore */
import { numberWithinRange } from '../../utils';
import { AdditionalWithholding } from '../additional-withholding.react';
import { SectionStyledBox } from '../section-styled-box';
import { SectionStyledHeader } from '../section-styled-header';
import { HelpPopover } from '../help-popover.react';
import { FedMessage2020 } from '../fed-message-2020.react';
import './fed-withholding.styl';

export class FedWithholding extends Component {
	constructor(props) {
		super(props);
		const { fields } = props;
		const selectedFilingStatus = fields.filingStatus ? fields.filingStatus.find(option => option.selected) : null;
		const exemptionsAmt = fields.exemptions ? fields.exemptions.amount : null;
		const exemptions = exemptionsAmt ? exemptionsAmt : 0;
		const ignoreStatus = fields.ignoreStatusExemptions ? fields.ignoreStatusExemptions.checked : null;

		this.state = {
			filingStatus: selectedFilingStatus ? selectedFilingStatus.val : null,
			ignoreStatus,
			exemptions,
		};
	}

	render() {
		const {
			fields,
			viewOnly,
			isAdminUser,
			isPayrollAdminUser,
			handle2020ToggleClick,
			headerHeadlineSize,
			show2020Message,
			usesSectionComponent,
		} = this.props;

		const fieldsetClasses = 'fab-FormSection';
		const legendClasses = 'fab-FormSection__legend';


		return ifFeature('encore',
			(
				<SectionStyledBox
					usesSectionComponent={ usesSectionComponent }
				>
					<fieldset>
						<SectionStyledHeader
							hasPopover={ true }
							headlineSize={ headerHeadlineSize }
							popoverContent={ (
								<Message
									// eslint-disable-next-line new-cap
									link={ ExternalLink('https://apps.irs.gov/app/withholdingcalculator/') }
									text={ $._('This determines how much you will withhold from your paycheck for federal taxes. The IRS has provided a handy [withholding calculator] to help you figure out what to elect.') }
								/>
							) }
							popoverIcon="circle-question-regular"
							popoverPlacement="bottom"
							popoverTitle={ $.__('Federal Tax Withholding') }
						>
							{ $.__('Federal Tax Withholding') }
						</SectionStyledHeader>

						{ show2020Message && <FedMessage2020 handleToggleClick={ handle2020ToggleClick } /> }

						<div className="fieldRow fab-FormRow">
							{ fields.filingStatus === null ? null : (
								<Flex
									flexDirection="column"
								>
									<Label
										htmlFor="fed[status]"
									>
										{ $.__('Filing Status') }
									</Label>
									<Select
										isClearable={ true }
										isDisabled={ this.state.ignoreStatus || this.props.viewOnly }
										items={ fields.filingStatus.map((status) => {
											return {
												text: status.displayText,
												value: status.val,
											};
										}) }
										name="fed[status]"
										onChange={ (selectedValues) => {
											const [selectedValue = null] = selectedValues;
											this.setState({
												filingStatus: selectedValue,
											});
										} }
										placeholder={ this.state.ignoreStatus ? 'NA' : `-${ $.__('Select') }-` }
										selectedValues={ this.state.ignoreStatus ? [] : [this.state.filingStatus] }
										width={ 5 }
									/>
								</Flex>
							) }
						</div>

						<div className="fieldRow fab-FormRow">
							{ fields.exemptions !== null && this._renderExemptionsInput(fields.exemptions) }
						</div>

						{ fields.additionalWithholding !== null && <AdditionalWithholding data={ fields.additionalWithholding } type="fed" viewOnly={ viewOnly } /> }

						{ (isAdminUser || isPayrollAdminUser) && this._renderExemptionCheckboxes(fields) }

					</fieldset>
				</SectionStyledBox>
			),
			(
				<fieldset className={ fieldsetClasses }>
					<legend className={ legendClasses }>
						{ $.__('Federal Tax Withholding') }
						<HelpPopover { ...this._getHelpPopoverProps() } />
					</legend>

					{ show2020Message && <FedMessage2020 handleToggleClick={ handle2020ToggleClick } /> }

					<div className="fieldRow fab-FormRow">
						{ fields.filingStatus !== null && this._renderFilingStatus() }
					</div>

					<div className="fieldRow fab-FormRow">
						{ fields.exemptions !== null && this._renderExemptionsInput(fields.exemptions) }
					</div>

					{ fields.additionalWithholding !== null && <AdditionalWithholding data={ fields.additionalWithholding } type="fed" viewOnly={ viewOnly } /> }

					{ (isAdminUser || isPayrollAdminUser) && this._renderExemptionCheckboxes(fields) }

				</fieldset>
			)
		);
	}

	/**
	 * Get data object to be passed to HelpPopover component
	 * @return {object}
	 */
	_getHelpPopoverProps = () => {
		const contentFirst = $.__('This determines how much you will withhold from your paycheck for federal taxes.');
		const contentSecond = $.__('The IRS has provided a handy %1$swithholding calculator%2$s to help you figure out what to elect.', '<a href="https://apps.irs.gov/app/withholdingcalculator/" target="_blank" rel="noopener noreferrer">', '</a>');
		const content = `${ contentFirst } ${ contentSecond }`;
		return {
			title: $.__('Federal Tax Withholding'),
			content,
			addTop: this.props.newHirePacket
		};
	};

	/**
	 * Get data object to be passed to Select component
	 * @return {object}
	 */
	_getSelectData = () => {
		const options = this.props.fields.filingStatus.map((status) => {
			const option = {};
			option.displayText = status.displayText;
			option.selected = this.state.ignoreStatus ? false : status.val === this.state.filingStatus;
			option.value = status.val;
			return option;
		});

		return {
			data: {
				items: options,
			},
			onChange: this._handleFilingStatusChange,
			settings: {
				name: 'fed[status]',
				notClearable: true,
				placeholder: this.state.ignoreStatus ? 'NA' : `-${ $.__('Select') }-`,
			},
			disabled: this.state.ignoreStatus || this.props.viewOnly,
			width: 5,
			className: 'xlong FedWithholding__status'
		};
	};

	// Event handlers
	_handleExemptionsChange = (event, range) => {
		if (numberWithinRange(event.target.value, range.min, range.max)) {
			this.setState({
				exemptions: event.target.value
			});
		}
	};

	/* @startCleanup encore */
	_handleFilingStatusChange = event => this.setState({
		filingStatus: event.value ? event.value : null
	});
	/* @endCleanup encore */

	_handleIgnoreStatusChange = event => this.setState({
		ignoreStatus: event.target.checked
	});

	/* @startCleanup encore */
	// Render different fieldboxes/rows
	_renderFilingStatus = () => {
		const {
			ignoreStatus
		} = this.state;
		const {
			viewOnly,
		} = this.props;
		const disable = viewOnly || ignoreStatus;

		const labelClasses = disable ? 'fab-Label fab-Label--disabled' : 'fab-Label';

		return (
			<div className="fieldBox">
				<label className={ labelClasses } htmlFor="fedStatus">{ $.__('Filing Status') }</label>
				<div className="fieldDiv">
					<OldSelect id="fedStatus" { ...this._getSelectData() } />
				</div>
			</div>
		);
	};
	/* @endCleanup encore */

	_renderExemptionsInput = (exemptions) => {
		const {
			ignoreStatus,
			exemptions: exemptionsState,
		} = this.state;
		const {
			viewOnly,
		} = this.props;
		const disable = viewOnly || ignoreStatus;

		const labelClasses = disable ? 'fab-Label fab-Label--disabled' : 'fab-Label';
		const inputClasses = 'fab-TextInput fab-TextInput--width2';

		return (
			<div className="fieldBox">
				<label className={ labelClasses } htmlFor="fedExemptions">{ $.__('Exemptions') }</label>
				<div className="fieldDiv">
					<input
						className={ inputClasses }
						disabled={ disable }
						id="fedExemptions"
						name="fed[dependents]"
						onChange={ event => this._handleExemptionsChange(event, exemptions.range) }
						type="text"
						value={ ignoreStatus ? 'NA' : exemptionsState }
					/>
				</div>
			</div>
		);
	};

	_renderExemptionCheckboxes = (fields) => {
		const {
			ignoreStatus,
		} = this.state;
		const {
			viewOnly,
		} = this.props;
		const {
			ficaExempt,
			futaExempt,
		} = fields;
		const checkboxGroupLabelClasses = c(
			'fab-Label FedWithholding__checkboxGroupLabel',
			{ 'fab-Label--disabled': viewOnly }
		);

		return (
			<div className="FedWithholding__checkboxGroup">
				<label className={ checkboxGroupLabelClasses } htmlFor="checkboxGroup">{ $.__('Exemption Options') }</label>
				<div className="fieldRow fab-FormRow">
					<div className="fieldBox fab-Checkbox FedWithholding__checkbox">
						<input
							className="fab-Checkbox__input"
							defaultChecked={ ignoreStatus ? 'checked' : null }
							disabled={ viewOnly }
							id="fedIgnore"
							name="fed[taxExempt]"
							onChange={ this._handleIgnoreStatusChange }
							type="checkbox"
						/>
						<label className="fab-Checkbox__label" htmlFor="fedIgnore">{ $.__('Ignore filing status/other adjustments') }</label>
					</div>
					<div className="fieldBox fab-Checkbox FedWithholding__checkbox">
						<input
							className="fab-Checkbox__input"
							defaultChecked={ ficaExempt.checked ? 'checked' : null }
							disabled={ viewOnly }
							id="ficaExempt"
							name="fed[ficaExempt]"
							type="checkbox"
						/>
						<label className="fab-Checkbox__label" htmlFor="ficaExempt">{ $.__('FICA exempt') }</label>
					</div>
					<div className="fieldBox fab-Checkbox FedWithholding__checkbox">
						<input
							className="fab-Checkbox__input"
							defaultChecked={ futaExempt.checked ? 'checked' : null }
							disabled={ viewOnly }
							id="futaExempt"
							name="fed[futaExempt]"
							type="checkbox"
						/>
						<label className="fab-Checkbox__label" htmlFor="futaExempt">{ $.__('FUTA exempt') }</label>
					</div>
				</div>
			</div>
		);
	};
}
