import {
	renderToStaticMarkup,
} from 'react-dom/server';

import BEM from '@utils/dom/bem';

import emailModalTmpl from './email-modal.micro.html';
import inputWrapperTmpl from './input-wrapper.micro.html';

export const emailModal = data => microTemplate(emailModalTmpl, data);
export const inputWrapper = data => microTemplate(inputWrapperTmpl, data);

import Wrapper from './wrapper';
import DownList from './down-list';

const bem = new BEM('AccessLevelSelector', null, {
	'menu': () => ([
		'dropdown-menu',
	]),
	'btn': ({ isButton, icon }) => ([
		'btn',
		{
			'btnSmall': isButton,
			'btnIcon': isButton && icon !== 'none',
		},
	]),
	'label': ({ isOption }) => ([
		'js-AccessLevelSelector__label',
		{
			'fab-MenuOption__note': isOption,
		},
	]),
	'down-list': () => ([
		'js-AccessLevelSelector__down-list',
		'down-list',
	]),
	'inputWrapper': () => ([
		'js-AccessLevelSelector__inputWrapper',
	]),
	'item': () => ([
		'branded-border-before',
	]),
});

function render(Tmpl, data) {
	const {
		fieldType,
	} = data;

	const _bem = (elem, mods, extraClasses) => (
		new BEM(bem, fieldType, extraClasses)
			.className(elem, mods, data)
	);

	return renderToStaticMarkup(<Tmpl { ...{ _bem, ...data } } />);
}

export const wrapper = data => render(Wrapper, data);
export const downList = data => render(DownList, data);
