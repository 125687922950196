import './styles.styl';

export const horizontal = {
	name: 'horizontal',
	collapseTabs: true,
	hiddenParts: ['icon','secondaryText'],
	extraClasses: {
		tabLabel: ({active}) => ({
			baseColor: active,
		}),
	},
};
