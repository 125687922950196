import Reply from '../components/reply';
import { connect } from 'react-redux';
import { getReplyById, getId } from '../../../store/selectors';
import { getConfig } from '../../../constants';
import {
	beginEditing,
	cancelEditing,
	deleteComment,
	editComment,
} from '../../../actions';

const mapStateToProps = (state, ownProps) => ({
	item: getReplyById(state, ownProps.id),
	config: getConfig(getId(state)),
});

export default connect(
	mapStateToProps,
	{
		beginEditing,
		cancelEditing,
		deleteComment,
		editComment,
	}
)(Reply);

