import { createStyles, makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ spacing }) => {
	return createStyles({
		form: {
			display: 'grid',
			paddingBottom: spacing(3),
		},
		selectField: {
			marginTop: spacing(2),
		},
		subText: {
			marginTop: spacing(-1),
			color: '#686868',
		},
		contentText: {
			marginTop: spacing(2),
		},
	});
});
