import { getNumberFromString } from 'string-helpers';

import { isSalariedHoursPerWeekNumberValid } from '../index';

export function isSalariedHoursPerWeekValueValid(value: string, allowEmpty = false): boolean {
	if (value === '') {
		return allowEmpty;
	}

	const number = getNumberFromString(value);
	return isSalariedHoursPerWeekNumberValid(number);
}
