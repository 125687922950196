import { ajax } from '@utils/ajax';
import { convertHTMLEntitiesInString } from 'String.util';

const EmploymentStatusModal = {
	/**
	 * This function will update the tax type select with the initial options
	 *
	 * @param {string} taxTypeValue - will a string version of the selected tax type's value
	 * @param {string} historyItemIdParam - history item id -> falls back to hidden element
	 */
	updateTaxTypeInitialOptions(taxTypeValue, historyItemIdParam) {
		if (EmploymentStatusModal.checkIfPayrollCustomer()) {
			const historyItemId = historyItemIdParam || document.querySelector('[name="employeeStatusHistoryItemId"]').value;
			ajax.get(`/ajax/employee_status_history_popup/get_allowable_employee_tax_types/${ historyItemId }/${ taxTypeValue }`).then((result) => {
				this.updateBaSelect($('.js-employeeTaxTypeFieldSelect'), result.data.options);
			});
		}
	},

	init() {
		// do this first so any `checkIfPayrollCustomer` is triggered it will handle if TT was hidden
		EmploymentStatusModal.hideShowTaxTypeField();
		EmploymentStatusModal.toggleInactiveNote();
	},

	/**
	 * Handler for form changes inside the Employment Status modal
	 * status is selected
	 * @return {undefined}
	 */
	formChangeHandler(e) {
		const targetEl = e.target;

		if (targetEl.name === 'employmentStatusId') {
			if (EmploymentStatusModal.checkIfPayrollCustomer()) {
				EmploymentStatusModal.employmentStatusChangeHandler(e);
			}
		}

		const $form = $(targetEl).closest('form');
		const $employmentStatusSelectValue = $('ba-select.js-employmentStatusSelect').val();
		const selectedOption = $form.find(`.js-employmentStatusSelect ba-option[value='${ $employmentStatusSelectValue }']`);
		const hasTerminatedOption = selectedOption && selectedOption[0] && selectedOption[0].hasAttribute('data-terminatedoption');
		$form.toggleClass('EmploymentStatusForm--terminated', hasTerminatedOption === true);

		const terminationTypeSelectValue = $('ba-select.js-terminationTypeSelect').val();
		const terminationTypeOption = $form.find(`.js-terminationTypeSelect ba-option[value='${ terminationTypeSelectValue }']`);
		const hasVoluntaryTerminationOption = hasTerminatedOption && terminationTypeOption && terminationTypeOption[0] && terminationTypeOption[0].hasAttribute('data-regrettableoption');
		$form.toggleClass('EmploymentStatusForm--voluntaryTermination', hasVoluntaryTerminationOption === true);

		this.toggleInactiveNote();
	},

	/**
	 * Specifc handler to handle a change to the employment status select
	 *
	 */
	employmentStatusChangeHandler(e, historyItemIdParam, taxTypeSelector, callback) {
		const historyItemId = historyItemIdParam || document.querySelector('[name="employeeStatusHistoryItemId"]').value;
		const employmentStatusId = e.target.value ? e.target.value : '0'; // Handles when select is cleared and we need to get default values
		this.getAllowableEmployeeTaxTypes(historyItemId, employmentStatusId, taxTypeSelector)
			.then(({ result, forceNewSelection, warningType }) => {
				this.updateBaSelect($('.js-employeeTaxTypeFieldSelect'), result.data.options, forceNewSelection);
				this.addTaxTypePromptWarnings(warningType);

				if (callback && typeof callback === 'function') {
					callback();
				}
			});
	},

	/**
	 * Wrapps the ajax request so it can be invoked from multiple
	 * locations and the logic is the same, but different outcomes
	 * can be specific to location. All the logic and enforcement
	 * still happens but warnings and selects can be updated manually
	 *
	 * @param {string} historyItemId
	 * @param {string} employmentStatusId
	 * @param {string | jQuery element} taxTypeSelector - (optional) the tax type select element used to determine forceNewSelection and warningType
	 * @return {promise} - Returns a promise with augmented data
	 */
	getAllowableEmployeeTaxTypes(historyItemId, employmentStatusId, taxTypeSelector) {
		if (historyItemId && employmentStatusId) {
			return ajax.get(`/ajax/employee_status_history_popup/get_allowable_employee_tax_types/${ historyItemId }/${ employmentStatusId }`).then((result) => {
				// Check if we are force updating
				const valueOptions = result.data.options.map(option => option.optionValue);
				const currentSelectionValue = parseInt($(taxTypeSelector || 'select[name="taxEmployeeTypeId"]').val());
				const forceNewSelection = valueOptions.indexOf(currentSelectionValue) === -1;
				const warningType = forceNewSelection ? 'updatedTaxType' : 'verifyTaxType';

				return {
					result,
					valueOptions,
					currentSelectionValue,
					forceNewSelection,
					warningType,
				};
			});
		}

		console.warn(`Tried to request tax types with invalid parameters. historyItemId ${ historyItemId } employmentStatusId ${ employmentStatusId }`);
		return Promise.reject('Missing Required Parameters');
	},

	/**
	 * This method will add the correct warnings to the modal.
	 * Correct warnings include the message that appears to the
	 * right of the employment status and the blue underline,
	 * text change and info icon added to the tax type select
	 *
	 * @param {string} warningType - this will tell this function what type of message to show.
	 */
	addTaxTypePromptWarnings(warningType) {
		$('.js-employeeTaxTypeFieldSelect').siblings('.TaxTypeWarningTextWrapper').remove();
		$('.js-employeeTaxTypeFieldSelect').after('<div class="TaxTypeWarningTextWrapper"></div>');

		const warningText = this.createTaxTypePromptText(warningType);

		this.addInfoStylesToTaxTypeSelect();
		$('.TaxTypeWarningTextWrapper').append(warningText);
	},

	/**
	 * This method will create the tax type warning.
	 *
	 * @param {string} warningType - this will tell this function what type of message to show.
	 */
	createTaxTypePromptText(warningType) {
		if (!warningType) {
			return `<div></div>`;
		}

		const warningText = warningType === 'updatedTaxType' ? $.__('We updated the Employee Tax Type to a value that matches the Employment Status.') : $.__('Based on the Employment Status, the Employee Tax Type may need to be updated.');

		return (
			`<div class="TaxTypeWarningText">
				<div class="TaxTypeWarningText__text">
					${ warningText }
				</div>
				<div class="TaxTypeWarningText__doubleCheck">
					${ $.__('Double-check and make sure it looks right.') }
				</div>
			</div>`
		);
	},

	/**
	 *
	 * @param {object} options (optional) - allows customization of how the label styles are modified
	 */
	addInfoStylesToTaxTypeSelect(options = {}) {
		const mergedOptions = {
			...{
				taxTypeSelector: '.js-employeeTaxTypeFieldSelect',
				fieldRowSelector: '.fieldRow.EmploymentStatusForm__employeeTaxTypeFieldRow',
				rowClass: 'EmploymentStatusForm__employeeTaxTypeFieldRow--info',
				labelSelector: '.fab-Label',
				selectFacadeClass: 'fab-SelectToggle__innerFacade--infoCondition',
				infoIconClass: 'iconWrapper',
			},
			...options,
		};

		const $taxTypeSelector = $(mergedOptions.taxTypeSelector);
		const $fieldRow = $taxTypeSelector.closest(mergedOptions.fieldRowSelector);
		const $fieldLabel = $fieldRow.find(mergedOptions.labelSelector);

		// BA-SELECT sometimes updates and removes styles after updating options
		// Wrap in timeout so BA-SELECT styles are augmented after all changes
		setTimeout(() => {
			$fieldRow.addClass(mergedOptions.rowClass);
			$taxTypeSelector.find('.fab-SelectToggle__innerFacade').addClass(mergedOptions.selectFacadeClass);

			// only add icon if a label is found and if it doesn't already exist
			if ($fieldLabel.length && !$fieldLabel.find(`.${ mergedOptions.infoIconClass }`).length) {
				$fieldLabel.prepend(`<ba-icon class="${ mergedOptions.infoIconClass }" name="fab-info-circle-13x13" encore-class="${ mergedOptions.infoIconClass }" encore-name="circle-info-solid" encore-size="12"></ba-icon>`);
			}
		}, 100);
	},

	/**
	 * This function will take in a select and clear out
	 * the existing options and repopualte with new ba-options
	 *
	 * @param {jquery selector} $targetSelect
	 * @param {Array} newOptions
	 */
	updateBaSelect($targetSelect, newOptions, forceNewSelection) {
		// use select from context and fallback to general selector
		const currentSelectionValue = $targetSelect.find('select').length
			? parseInt($targetSelect.find('select').val())
			: parseInt($('select[name="taxEmployeeTypeId"]').val());

		$targetSelect.empty();

		newOptions.forEach((option) => {
			const {
				optionValue,
				optionText,
				selected,
			} = option;

			let selectedOption = selected;

			if (!forceNewSelection) {
				if (currentSelectionValue === optionValue) {
					selectedOption = true;
				} else {
					selectedOption = false;
				}
			}

			let newOption;
			if (selectedOption) {
				newOption = `<ba-option value="${ optionValue }" selected>${ optionText }</ba-option>`;
			} else {
				newOption = `<ba-option value="${ optionValue }">${ optionText }</ba-option>`;
			}

			$targetSelect.append(newOption);
		});
	},

	toggleInactiveNote() {
		const preferredName = window.Employee && window.Employee.preferredName? convertHTMLEntitiesInString(window.Employee.preferredName) : '';
		const wrapperClass = 'EmploymentStatusModal__statusSelectWrapper';
		const wrapperInactiveClass = `${ wrapperClass }--inactive`;
		const noteHookClass = 'js-employmentStatusInactiveNote';

		const $employmentStatusSelect = $('ba-select.js-employmentStatusSelect');
		const $employmentStatusWrapper = $employmentStatusSelect.parent();
		const $selectedOption = $employmentStatusSelect.find(`ba-option[value='${ $employmentStatusSelect.val() }']`);

		const hasInactiveOption = !!($selectedOption && $selectedOption[0] && $selectedOption[0].hasAttribute('data-inactiveoption'));

		$employmentStatusWrapper
			.addClass(wrapperClass)
			.toggleClass(wrapperInactiveClass, hasInactiveOption)

		if ($employmentStatusWrapper.find(`.${ noteHookClass }`).length === 0) {
			const $note = $(`<div class="${ noteHookClass } EmploymentStatusForm__inactiveStatusNote">`).text(
				$.__('%1 will not be able to access their BambooHR account past the effective date while in this employment status.', preferredName)
			);

			$employmentStatusWrapper.append($note);
		}
	},

	getTaxTypeField() {
		return $('.js-employeeTaxTypeFieldSelect');
	},

	/**
	 * This function will test if the user is a payroll customer based on TaxType
	 * Tax Type is now sent in multiple scenarios and the FE must hide it if
	 * The employee isn't in a us location or have a us address
	 */
	checkIfPayrollCustomer() {
		const TTField = EmploymentStatusModal.getTaxTypeField();
		return TTField.length > 0 && TTField.is(':visible');
	},

	/**
	 * Hides and Shows the TT field
	 */
	hideShowTaxTypeField() {
		const TTField = EmploymentStatusModal.getTaxTypeField();
		if (TTField.length > 0) {
			const usBasedAddress = TTField.data('usBasedAddress');
			const usBasedLocation = TTField.data('usBasedLocation');

			// if either are not US, hide the TT field
			const parentFieldBox = TTField.closest('.fieldBox');
			if (!usBasedAddress || !usBasedLocation) {
				parentFieldBox.addClass('hidden');
			} else {
				parentFieldBox.removeClass('hidden');
			}
		}
	}
};

export default EmploymentStatusModal;
