import {AxiosPromise} from "axios";
import Ajax from "@utils/ajax";

export function getCovidDocuments(employeeId: string): AxiosPromise {
    return Ajax.get(`/employees/files/covid_documents/${employeeId}`)
}

export function uploadCovidDocuments(fileId: string, employeeId: string): AxiosPromise {
    const fd = new FormData();
    fd.append('attachId[]', fileId);
    fd.append('action', 'upload');
    fd.append('employeeId', employeeId);
    fd.append('covidDocumentUpload', 'true');
    fd.append('share', 'true');

    return Ajax.post(`/ajax/employees/files/edit_file.php`, fd);
}

export function deleteFile(fileId: string): AxiosPromise {
    return Ajax.post('/employees/files/delete_files', {action: 'delete', fileIds: [fileId]});
}
