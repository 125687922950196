/**
 * Pub/sub through a mediator.
 *
 * e.g.
 * BambooHR.Utils.mediator.installTo(esignatures)
 *
 * then..
 * esignatures.subscribe('event', function(args) {})
 * esignatures.publish('event', 'arg')
 *
 * @return mediator module
 */
class Mediator {
	channels = {};

	subscribe(channel, fn, context) {
		let alreadyAdded = false;
		if (!this.channels[channel]) {
			this.channels[channel] = [];
		}
		// disallow duplicate subscriptions
		this.channels[channel].forEach((subscription) => {
			if (subscription.callback.toString() === fn.toString()) {
				alreadyAdded = true;
				return false;
			}
		});
		if (!alreadyAdded) {
			this.channels[channel].push({
				context: context || window,
				callback: fn,
			});
		}
		return this;
	}

	publish(channel) {
		if (!this.channels[channel]) {
			return false;
		}
		// eslint-disable-next-line prefer-rest-params
		const args = Array.prototype.slice.call(arguments, 1);
		for (let i = 0, l = this.channels[channel].length; i < l; i++) {
			const subscription = this.channels[channel][i];
			try {
				subscription.callback.apply(subscription.context, args);
			} catch (error) {
				console.log(`subscription error when calling: BambooHR.Utils.Mediator.publish('${ channel }')`);
				console.log({ subscription });
				throw error;
			}
		}
		return this;
	}

	installTo(obj: any) {
		obj.channels = {};
		obj.subscribe = this.subscribe;
		obj.publish = this.publish;
	}
}

export default new Mediator();
