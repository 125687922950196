import {
	showSlidedown,
} from 'Alerts.mod';
import {
	downloadFile,
} from 'file.util';
import {
	doGetNewBackupCodes,
} from '../../service';
import {
	ERROR_MESSAGE,
} from './constants';

export const downloadCodes = (id = window.SESSION_USER?.id) => {
	const request =  doGetNewBackupCodes
	request(id)
		.then((resp) => {
			const fileData = resp.data.reduce((acc, codeData) => {
				const {
					backupCode,
					dateUsed,
				} = codeData;

				let text = backupCode;
				if (dateUsed) {
					text = text.concat($.__(' (used)'));
				}
				return acc.concat(text, '\n');
			}, '');
			downloadFile({ fileName: 'backup-codes.txt', fileData });
		})
		.catch(() => {
			showSlidedown(ERROR_MESSAGE, 'error');
		});
}
