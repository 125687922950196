import { ReactElement } from 'react';

import { ColoredIcon } from 'colored-icon';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex, IconV2, LayoutBox } from '@bamboohr/fabric';

export function EmploymentSubtitles(props): ReactElement {
	const { jobTitle = '', location = '' } = props;
	const locationSubTitle = (
		<div className='BadgeText__subTitle'>
			{ifFeature(
				'encore',
				<Flex display='flex' alignItems={'center'}>
					<LayoutBox marginRight={'8px'}>
						<IconV2 name='location-dot-solid' color='neutral-extra-strong' size={12} />
					</LayoutBox>
					<span className='EmploymentSubtitles__subTitleText'>{location}</span>
				</Flex>,
				<div>
					<ColoredIcon classes='EmploymentSubtitles__subTitleIcon' fabricColorName='gray7' iconName='fab-location-11x14' />
					<span className='EmploymentSubtitles__subTitleText'>{location}</span>
				</div>
			)}
		</div>
	);
	const jobSubTitle = (
		<div className='BadgeText__subTitle'>
			{ifFeature(
				'encore',
				<Flex marginRight={'8px'} alignItems={'center'}>
					<LayoutBox marginRight={'8px'}>
						<IconV2 name='wrench-solid' color='neutral-extra-strong' size={12} />
					</LayoutBox>
					<span className='EmploymentSubtitles__subTitleText'>{jobTitle}</span>
				</Flex>,
				<div>
					<ColoredIcon classes='EmploymentSubtitles__subTitleIcon' fabricColorName='gray7' iconName='fab-employees-14x12' />
					<span className='EmploymentSubtitles__subTitleText'>{jobTitle}</span>
				</div>
			)}
		</div>
	);

	return (
		<div>
			{jobTitle !== '' && jobSubTitle}
			{location !== '' && locationSubTitle}
		</div>
	);
}
