
const APP_NAMESPACE = 'ba';

const getEventName = (eventName) => {
	return `${ APP_NAMESPACE }:${ eventName }`;
}

/** 
 * D3 Mouse related chart event names, namespaced with the APP_NAMESPACE
 * ex: MOUSE_CLICK == 'ba:chartMouseClick'
*/
export const MOUSE_CLICK = getEventName('chartMouseClick');
export const MOUSE_ENTER = getEventName('chartMouseEnter');
export const MOUSE_OVER = getEventName('chartMouseOver');
export const MOUSE_LEAVE = getEventName('chartMouseLeave');
