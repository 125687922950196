import { useState, Dispatch, SetStateAction, DragEvent } from 'react';
import { cloneDeep } from 'lodash';

type dragStartMethod = (e: DragEvent<HTMLElement>, id: number | string) => void;
type dragOverCallback<U> = (orderedItems: Array<U>, overItem: U) => unknown;
type dragOverMethod<U> = (e: DragEvent<HTMLElement>, id: number | string, callback?: dragOverCallback<U>) => void;
type dragEndMethod = () => void;
type mustHaveId = { id: string|number };
type dragAndDropReturn<U> = [
	Array<U>,
	Dispatch<SetStateAction<Array<U>>>,
	U,
	{
		handleDragStart: dragStartMethod;
		handleDragOver: dragOverMethod<U>;
		handleDragEnd: dragEndMethod;
	}
]

function useDragAndDrop<U>(draggableItems: Array<U>, accessProperty: keyof U): dragAndDropReturn<U> ;
function useDragAndDrop<U extends mustHaveId>(draggableItems: Array<U>, accessProperty?: keyof U): dragAndDropReturn<U>
function useDragAndDrop<U>(draggableItems: Array<U>, accessProperty = 'id'): dragAndDropReturn<U> {
	const [items, setItems] = useState(draggableItems);
	const [draggingItem, setDraggingItem] = useState<U>();

	const findItem = (id: string | number): U | undefined => {
		return items.find(i => i[accessProperty].toString() === id.toString());
	};

	const handleDragStart: dragStartMethod = (e, id) => {
		setDraggingItem(findItem(id));
		e.dataTransfer.effectAllowed = 'move';
		try {
			e.dataTransfer.dropEffect = 'move';
		} catch (err) {
			// Do nothing -> Handle IE11 not supporting dropEffect
		}
	};

	// Can also be used with onDragEnter
	const handleDragOver: dragOverMethod<U> = (e, id, cb) => {
		e.preventDefault(); // Removed end animation when it goes back to where it started dragging from
		if (draggingItem) {
			// We may be responding to an event that is not tied to this instance of Draggable.
			// only continue if draggingItem is set
			const draggedOverItem = findItem(id);
			const draggedOverIndex = items.findIndex(i => i[accessProperty].toString() === id.toString());

			// if the item is dragged over itself, ignore
			if (draggingItem[accessProperty] === draggedOverItem[accessProperty]) {
				return;
			}

			// filter out the currently dragged item
			const filteredItems = items.filter(item => item[accessProperty] !== draggingItem[accessProperty]);

			// add the dragged item after the dragged over item
			filteredItems.splice(draggedOverIndex, 0, draggingItem);

			if (cb && typeof cb === 'function') {
				// Return deep clones so they can be mutated without causing side effects
				cb(cloneDeep(filteredItems), cloneDeep(draggedOverItem));
			} else {
				setItems(filteredItems);
			}
		}
	};

	const handleDragEnd: dragEndMethod = () => {
		// onDragEnd sometimes will not fire in React (especially if dragging to disconnected element).
		// You can use onDrop on the container or item to catch the end event
		setDraggingItem(undefined);
	};

	return [
		items,
		setItems,
		draggingItem,
		{
			handleDragStart,
			handleDragOver,
			handleDragEnd,
		},
	];
}

export default useDragAndDrop;
