import {axisTop} from 'd3-axis';
import {scaleBand, scaleLinear, scaleOrdinal} from 'd3-scale';
import {select} from 'd3-selection';
import {stack, stackOrderReverse, stackOrderAscending} from 'd3-shape';

import {defaultsDeep} from 'lodash';
import Popover from 'Popover.mod';

/******************************************************************************
	Data contract for grouped slice chart

	{
		hasData:true, // Check if any values exist to draw a chart in the first place
		keys:[ // Required for d3.stack()
			"I feel I am highly valued.",
			"I sometimes feel valued.",
			"I'm not sure others value what I do.",
			"I don’t feel valued at [Company]."
		],
		values:[
			{
				keyOrder: 3,
				label: "Sep 15.2016",
				title: "2 Times Ago",
				totalCount: 6,
				"I feel I am highly valued.": 0.17,
				"I sometimes feel valued.": 0,
				"I'm not sure others value what I do.": 0.5,
				"I don’t feel valued at cp2.": 0.33,
				xPosition: 0
			} // ... and so on ...
		]
	}

******************************************************************************/

const CLASS_NAME = 'GroupedSliceChart';
const DEFAULT_OPTIONS = {
	bar: {
		margin: 20,
		width: 60,
		height: 162,
		minCount: 3
	},
	chartClass: '',
	colors: ['#8db812', '#5b9fb2', '#f19d11', '#db6021'], // Green - Blue - Orange - Red
	height: 200,
	reverseCellOrder: true,
	showEmptyBar: true,
	width: 330
};

/**
 * TODO
 * Scale bars when 'showEmptyBar' is false
 * 'useCount' option - allow to toggle graph between using percentage or count
 * Popovers - modify popover to add class name on parent (Popover root)
 * Popovers - ability to disable pointer-events
 *	Talk to Kevin to just add 'pointer-events: none' if 'triggerEvent: hover'
 */
export default class GroupedSliceChart {

	constructor(selector, options = DEFAULT_OPTIONS) {
		this._selector = selector;

		this._options = defaultsDeep({}, options, DEFAULT_OPTIONS);

		this._svg = this._createSvg();
	}

	/**
	 * Creates div and svg for charts to fit into
	 */
	_createSvg() {
		let container = select(this._selector).append('div');

		let svg = container.append('svg')
			.attr('viewBox', `0 0 ${ this._options.width } ${ this._options.height }`);

		svg.style('height', `${ this._options.height }px`)
			.style('width', `${ this._options.width }px`);

		return svg;
	}

	/**
	 * Draws each element in the order needed for the chart to display properlly
	 * @param {object} data Formatted Data ready to be sliced and displayed
	 */
	draw(data) {

		let stackedData = [];

		if (data.hasData) {
			let order = this._options.reverseCellOrder ? stackOrderReverse : stackOrderAscending;

			let localStack = stack()
				.keys(data.keys)
				.order(order);

			stackedData = localStack(data.values);
		}

		let y = scaleLinear()
			.range([this._options.bar.height, 0])
			.domain([0, 1]);

		let x = scaleBand()
			.range([0, this._options.width])
			.round(true)
			.paddingInner(0.445) // Percentage of total width
			.paddingOuter(0.2225) // Percentage of total width
			.domain(data.values.map((d, i) => i));

		let barColors = scaleOrdinal(this._options.colors);

		this._drawTitles(data.values, x);
		if (this._options.showEmptyBar) {
			this._drawEmptyBars(data.values, x);
		}
		this._drawAxis(x);
		if (data.hasData) {
			this._drawBars(stackedData, x, y, barColors);
		}
		this._drawLabels(data.values, x);
	}

	/**
	 * Draws the title above each bar
	 * @param {object}   data Formatted data that contains the title
	 * @param {function} x d3 function for the x axis
	 */
	_drawTitles(data, x) {
		const TITLES = this._svg.append("g").selectAll("text").data(data);
		TITLES.enter()
			.append('text')
			.attr('class', `${ CLASS_NAME }__title ${ this._options.chartClass }__title`)
			.merge(TITLES)
			.attr('x', (d, i) => x(i) + x.bandwidth() / 2)
			.attr('text-anchor', 'middle')
			.attr('y', this._options.bar.margin / 2)
			.text(d => this._setText(d, d.title));
	}

	/**
	 * Checks to see when text should be displayed on the bar
	 * @param {data}   data Formatted data
	 * @param {string} text string to display
	 */
	_setText(data, text) {
		let string = '';
		if (data.isEmpty) {
			string = this._options.showEmptyBar ? text : '';
		} else {
			string = text;
		}
		return string;
	}

	/**
	 * When no data for that bar exists, we display an empty bar instead
	 * @param {object}   data Formatted data that contains the title
	 * @param {function} x d3 function for the x axis
	 */
	_drawEmptyBars(data, x) {
		const EMPTY_BAR = this._svg.selectAll(`${ CLASS_NAME }__emptyBar ${ this._options.chartClass }__emptyBar`).data(data);
		EMPTY_BAR.enter()
			.filter(d => d.isEmpty)
			.append('rect')
		// .attr('rx', 2) // add radius to container
			.attr('transform', `translate(0, ${ this._options.bar.margin })`)
			.attr('class', `${ CLASS_NAME }__emptyBar ${ this._options.chartClass }__emptyBar`)
			.attr('x', d => x(d.xPosition))
			.attr('width', () => x.bandwidth())
			.attr('y', 0)
			.attr('height', this._options.bar.height - 2);
	}

	/**
	 * Draws the three x axis lines
	 * @param {function} x d3 function for the x axis
	 */
	_drawAxis(x) {
		let xAxis = axisTop()
			.tickSize(0)
			.tickValues([])
			.scale(x);

		for (var i = 1; i < 4; i++) {
			this._svg.append('g')
				.attr('class', `${ CLASS_NAME }__axis ${ this._options.chartClass }__axis`)
				.attr('transform', `translate(0,${ Math.round(this._options.bar.margin + (this._options.bar.height / 4) * i) - 2 })`)
				.call(xAxis);
		}
	}

	/**
	 * Draws the containers, and the individual cells of the bar chart
	 * @param {object}   stackedData Formatted data from d3.stack
	 * @param {function} x d3 function for the x axis
	 * @param {function} y d3 function for the y axis
	 * @param {function} barColors d3 function for the cell colors
	 */
	_drawBars(stackedData, x, y, barColors) {
		let that = this;
		const LAYER_GROUP = this._svg.selectAll('g.layer').data(stackedData)
			.enter()
			.append('g')
			.attr('transform', `translate(0, ${ this._options.bar.margin })`)
			.attr('class', `${ CLASS_NAME }__stackedBar ${ this._options.chartClass }__stackedBar`)
			.attr('fill', d => barColors(d.key));

		const LAYERS = LAYER_GROUP.selectAll('rect').data(d => d);
		LAYERS.enter()
			.append('rect')
			.merge(LAYERS)
			.attr('x', (d, i) => x(i))
			.attr('width', () => x.bandwidth())
			.attr('y', d => (d.data.isEmpty ? 0 : y(d[1])))
			.attr('height', (d) => {
				let value = y(d[0]) - y(d[1]) - 2;
				value = value > 0 ? value : 0;
				return d.data.isEmpty ? 0 : value;
			})
			.attr('class', `${ CLASS_NAME }__cell ${ this._options.chartClass }__cell`)
			.on('mouseenter', function(d) {
				if (!Popover.hasInstance(this)) {
					let color = this.parentNode.getAttribute('fill');
					Popover.create(this, {
						html: `<div class="${ CLASS_NAME }__percentage ${ that._options.chartClass }__percentage" style="color: ${ color }">${ Math.round((d[1] - d[0]) * 100) }%</div>`,
						position: 'left',
						triggerEvent: 'hover',
						showImmediately: true,
						closeX: false,
						push: -14,
						tailSize: 8
					});
				}
			});
	}

	/**
	 * Draws the label below each bar
	 * @param {data}   data Formatted data
	 * @param {string} text string to display
	 */
	_drawLabels(data, x) {
		const LABELS = this._svg.append("g").selectAll("text").data(data);
		LABELS.enter()
			.append('text')
			.attr('class', `${ CLASS_NAME }__label ${ this._options.chartClass }__label`)
			.merge(LABELS)
			.attr('x', (d, i) => x(i) + x.bandwidth() / 2)
			.attr('text-anchor', 'middle')
			.attr('y', this._options.height - this._options.bar.margin / 2 + 6)
			.text(d => this._setText(d, d.label));
	}

}
