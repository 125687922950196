import FilesManager from './index';

class TrainingFilesManager extends FilesManager {
	constructor(existingFiles) {
		super(existingFiles);
		this.filesEndPoint = `${this.filesEndPoint}?excludeEsignatures=true`;
		this.sectionEndpoint = `${this.sectionEndpoint}?esignatures=false`;
	}
}

let instance = null;

export function initFilesManager(existingFiles = []) {
	if (instance) {
		return instance;
	}
	instance = new TrainingFilesManager(existingFiles);
	return instance;
}
