import {isEqual} from 'lodash';
import {Component, createRef} from 'react';

import {HorizontalBarChart} from 'Charts.mod';

import {MOUSE_CLICK, MOUSE_OVER, MOUSE_LEAVE} from 'Charts.mod/event-names';

export default class HorizontalBarChartComponent extends Component {

	/******************************************************************************
	Data contract (this.props.data)

		bars: [
			{
				'value': 43,           // required
				'label': '2017'        // required 
				'displayText': '158',  // optional; when provided will be displayed at the 'top' of the bar (Not Currently Implemented)
			},
			{
				'value': 43,           // required
				'label': '2017'        // required 
				'displayText': '158',  // optional; when provided will be displayed at the 'top' of the bar (Not Currently Implemented)
			}
		]

	Settings contract (this.props.settings)

		{
			chartClass: '',
			container: {
				flexHeight: true,
				height: 126,
				width: 270,
				margin: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0
				} 
			},
			labelText: {
				fill: '#222',
				color: "#777",
				spacing: 12,
				alignmentLeft: false
			},
			valueText: {
				color: "#777",
				spacing: 4
			},
			bar: {
				height: 16,
				fill: '#ccc',
				padding: 28 
			}

			An example event definition:
			onMouseClick: function(e) {
				select(e.target)
					.attr('fill', 'red');
			}
		}

******************************************************************************/

	constructor() {
		super();
		this._chart = null;
	}

	/**
	 * Establish event listeners dispatched from BaseChart
	 */
	_setupListeners() {
		const {onMouseClick, onMouseOver, onMouseLeave} = this.props;

		if (typeof onMouseClick === 'function') {
			this._barChartElem.current.addEventListener(MOUSE_CLICK, onMouseClick);
		}
		
		if (typeof onMouseOver === 'function') {
			this._barChartElem.current.addEventListener(MOUSE_OVER, onMouseOver);
		}
		
		if (typeof onMouseLeave === 'function') {
			this._barChartElem.current.addEventListener(MOUSE_LEAVE, onMouseLeave);
		}
	}

	_barChartElem = createRef();

	/**
	 * Renders the barGraph
	 */
	componentDidMount() {
		let {data, settings} = this.props;

		this._chart = new HorizontalBarChart(this._barChartElem.current, settings);
		this._chart.draw(data);

		this._setupListeners();
	}

	shouldComponentUpdate(nextProps) {
		const {data} = this.props;
		return !isEqual(data, nextProps.data);
	}

	componentDidUpdate() {
		const {data} = this.props;
		this._chart.draw(data);
	}

	/**
	 * Renders the widget
	 */
	render() {
		return <div ref={ this._barChartElem }></div>;
	}

}
