import Ajax from '@utils/ajax';
import { AxiosPromise } from 'axios';

type DeleteEmployeeEducationPostResponse = {
	result: 'error' | 'OK';
	message: string;
};

export function deleteEmployeeEducation(
	educationFieldId: string,
	employeeId: string
): AxiosPromise<DeleteEmployeeEducationPostResponse> {
	const formData = new FormData();
	formData.append('id', educationFieldId);
	formData.append('employeeId', employeeId);
	return Ajax.post('/ajax/employees/delete_employee_education_item.php', formData);
}

type DeleteEmergencyContactDeleteResponse = {
	message: string;
	newPrimaryContactId: string;
    success: boolean;
    error?: string;
};

export function deleteEmergencyContact(
	employeeId: string,
	emergencyContactId: string
): AxiosPromise<DeleteEmergencyContactDeleteResponse> {
	return Ajax.delete(`/employees/contacts.php?id=${ employeeId }&e=${ employeeId }&c=${ emergencyContactId }`);
}

type DeleteEmployeeDependentResponse = {
	success: boolean;
	error?: string;
};

export function deleteEmployeeDependent(
	employeeId: string,
	dependentId: string
): AxiosPromise<DeleteEmployeeDependentResponse> {
	const formData = new FormData();
	formData.append('id', employeeId);
	formData.append('d', dependentId);
	return Ajax.post('/ajax/dialog/delete_dependent.php', formData);
}
