import { ModeOptions, ThemeNameOptions } from '@bamboohr/fabric';

export function getThemeName() {
	const modeAttr = document.body.dataset.fabricMode;
	const themeNameAttr = document.body.dataset.fabricTheme;

	const mode = Object.values(ModeOptions).includes(modeAttr as ModeOptions) ? modeAttr : undefined;
	const themeName = Object.values(ThemeNameOptions).includes(themeNameAttr as ThemeNameOptions) ? themeNameAttr : undefined;

	return { mode, themeName };
}
