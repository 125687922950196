import { createStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const styles = ({ palette, typography }) => {
	return createStyles({
		containerWrapper: {
			display: 'flex',
			justifyContent: 'center',
			'& .fab-Card': {},
		},
		logoContainer: {
			maxWidth: ifFeature('encore', undefined, '1328px'),
			width: '100%',
			display: 'flex',
		},
		contentSpacer: {
			paddingLeft: ifFeature('encore', undefined, '20px'),
		},
		container: {
			marginTop: ifFeature('encore', '40px'),
			maxWidth: ifFeature('encore', undefined, '1348px'),
			flex: 1,
		},
		header: {
			position: 'relative',
			margin: '-32px  -16px 0 -16px',
			backgroundColor: palette.grey[100] as string,
			zIndex: 100,
		},
		subHeader: {
			display: 'flex',
			alignItems: 'center',
			borderBottom: `4px solid ${palette.primary.main}`,
			justifyContent: 'center',
			padding: '13px 0',
		},
		spacer: {
			flexGrow: 2,
		},
		baseFooter: {
			margin: '0 -16px',
			backgroundColor: 'white',
			'&--templatePreviewer': {
				marginTop: '-25px',
				position: 'relative',
				zIndex: 2,
			},
		},
		baseFooterContent: {
			padding: '0 32px 25px 32px',
			marginBottom: '-25px',
			color: palette.grey[600] as string,
			display: 'flex',
			fontSize: typography.fabricFontSize('teenie') as string,
			'& span': {
				width: 'auto',
			},
			'& a': {
				textDecoration: 'none',
				color: palette.grey[600] as string,
				'&:hover': {
					color: palette.primary.main as string,
				},
			},
		},
		baseFooterLogo: {
			width: 'auto',
			marginLeft: 'auto',
			fill: palette.grey[700] as string,
			'&:hover': {
				cursor: 'finger',
				fill: palette.grey[800] as string,
			},
		},
		footerDivider: {
			margin: '25px 0',
			height: '1px',
			borderColor: palette.grey[300] as string,
		},
		previewerContent: {
			display: 'inline',
			width: ifFeature('encore', '100%'),
			/* @startCleanup encore */
			'& > div > div': {
				backgroundColor: ifFeature('encore', undefined, palette.grey[100] as string),
			},
			/* @endCleanup encore */
			'& #fabricModalContent': ifFeature('encore', {
				overflow: 'hidden',
			})
		},
	});
};
