import Ajax from '@utils/ajax';

function fetchDisclaimer(templateId) {
	if (templateId) {
		const disclaimer = document.getElementById('js-saves-to-folder');
		const url = `/esignatures/template_save_location/${ templateId }`;
		Ajax.get(url).then((response) => {
			const {
				savesToEmployeeFileSectionName
			} = response.data;
			if (savesToEmployeeFileSectionName) {
				disclaimer.classList.remove('hidden');
				document.getElementById('js-saves-to-folder-name').innerText = savesToEmployeeFileSectionName;
			} else {
				disclaimer.classList.add('hidden');
			}
		}, () => window.errorFallBack());
	}
}

function showDisclaimer() {
	document.getElementById('js-saves-to-folder').classList.remove('hidden');
}

function hideDisclaimer() {
	document.getElementById('js-saves-to-folder').classList.add('hidden');
}

export default {
	fetchDisclaimer,
	showDisclaimer,
	hideDisclaimer,
};
