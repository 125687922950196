import { FunctionComponent } from 'react';
import classNames from 'classnames';

const Note: FunctionComponent<DynamicForm.NoteElementProps> = ({ type, text, context }) => {
	const { controls: { EmploymentStatusNote, PayScheduleNote } } = context;

	if (type === 'updatedTaxType' || type === 'verifyTaxType') {
		return <EmploymentStatusNote context={ context } text={ text } type={ type } />;
	}

	if (type === 'PayScheduleWarning') {
		return <PayScheduleNote context={ context } text={ text } type={ type } />;
	}

	const classes = classNames(
		'fab-FormNote',
		{
			'fab-FormNote--info': type === 'info',
		}
	);

	return (
		<span className={ classes }>
			{ text }
		</span>
	);
};

export default Note;
