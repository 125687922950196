import {
	Fragment,
} from 'react';

import CompanyBadge from 'badge.react/company';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './styles.styl';
import { Divider } from '@bamboohr/fabric';

export const confirm = ({
	isSheet = false,
	...modalProps
} = {}) => new Promise((resolve) => {
	if (!window.IS_TICKET_LOGIN) {
		return resolve();
	}

	modalProps = {
		isOpen: true,
		title: $.__(`Hold on. Not so fast...`),
		headline: $.__(`Are you sure?`),
		icon: ifFeature('encore', 'building-circle-arrow-right-solid', 'fab-triangle-exclamation-48x48'),
		iconV2Color: ifFeature('encore', 'primary-strong'),
		iconColor: 'attention',
		primaryActionText: $.__(`Yes`),
		content: (
			<Fragment>
				<p className="TicketLoginModal__message">
					{ [
						$.__(`Please, double check that you are uploading the right file to the right company.`),
						$.__(`This action has serious data security implications.`)
					].join(' ') }
				</p>
				{ifFeature('encore', <Divider marginTop={2.5} />)}
				<div className="TicketLoginModal__badgeWrapper">
					<CompanyBadge />
				</div>
			</Fragment>
		),
		primaryAction() {
			closeModal(isSheet);

			setTimeout(resolve, 0);
		},
		onClose() {
			closeModal(isSheet);
		},
		...modalProps,
	};

	if (isSheet) {
		modalProps = {
			sheetProps: modalProps,
		};
	}

	window.BambooHR.Modal.setState(modalProps, isSheet);
});

export const confirmImport = (modalProps = {}) => confirm({
	headline: $.__(`Are you sure you want to import this file for this company?`),
	primaryActionText: $.__(`Yes, Upload The File`),
	...modalProps,
});

export const confirmUpload = (modalProps = {}) => confirmImport({
	headline: $.__(`Are you sure you want to upload this file for this company?`),
	...modalProps,
});

const closeModal = isSheet => window.BambooHR.Modal.setState({
	sheetProps: null,
}, isSheet);
