import { ReactElement } from 'react';

export function SelectPayTypeField(selectProps: DynamicForm.SelectElementProps): ReactElement {
	const { props, settings, context } = selectProps;
	const {
		controls: { SelectField },
	} = context;

	const items = props.items.filter(item => item.value !== 'Contract');

	return (
		<SelectField
			context={ context }
			props={ {
				...props,
				items,
			} }
			settings={ settings }
		/>
	);
}
