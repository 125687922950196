import { makeStyles, Icon, Tooltip, IconV2 } from '@bamboohr/fabric';
import classnames from 'classnames';
import { upperFirst } from 'lodash';
import React, { Fragment, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { getResourceUrl, ResourcePicker } from '../resources';

const useStyles = makeStyles(({ zIndex, palette }) => ({
	root: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		zIndex: zIndex.snackbar,
		width: 40,
		height: 40,
		overflow: 'hidden',

		'&:hover $icon': {
			fill: palette.primary.main,
		},

		'&:hover $nameIcon': {
			display: 'inline-block',
		},

		'&:hover $typeIcon': {
			display: 'none',
		},

		'&:hover $bubble': {
			borderColor: palette.primary.main,
			boxShadow: '0 0 0px 2px white',
		},
	},
	bubble: {
		position: 'absolute',
		backgroundColor: palette.common.white,
		border: `1px solid ${ palette.grey[200] }`,
		borderRadius: '50% 50% 0 0',
		cursor: 'pointer',
		height: 75,
		left: 0,
		outline: 'none',
		top: 0,
		width: 75,
	},
	children: {
		height: '100%',
		width: '100%'
	},
	icon: {
		display: 'flex',
		bottom: 8,
		height: 16,
		justifyContent: 'center',
		lineHeight: 0,
		pointerEvents: 'none',
		position: 'absolute',
		right: 8,
		width: 16,
	},
	nameIcon: {
		display: 'none',
		fill: palette.primary.main,
	},
	pulse: {
		animation: `$pulse 1500ms infinite alternate`,
	},
	typeIcon: {
		fill: palette.grey[600],
	},
	indicator: {
		position: 'relative',
		'&:after': {
			backgroundColor: 'rgba(151, 191, 229, 0.75)',
			bottom: 0,
			content: '""',
			left: 0,
			position: 'absolute',
			right: 0,
			top: 0,
		},
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 1,
		},
		'100%': {
			opacity: 0.25,
		},
	},
}));

const jadeIcons = {
	committed: 'fab-check-circle-outline-16x16',
	development: 'fab-wrench-16x16',
	odi: 'fab-clock-14x14',
	custom: 'fab-pencil-16x16',
};

const icons = {
	committed: 'circle-check-regular',
	development: 'wrench-regular',
	odi: 'clock-regular',
	custom: 'pen-regular',
}

export const VersionManager = (props) => {
	const { children, getUrl, icon, resource, onChange } = props;

	const {
		name,
		type,
		version,
	} = resource;

	const classes = useStyles(props);

	const [isHovering, setIsHovering] = useState(false);
	const [isPickingResource, setIsPickingResource] = useState(false);
	// @startCleanup encore
	const jadeTypeIcon = <Icon name={ jadeIcons[type] } />;
	// @endCleanup encore
	const typeIcon = <IconV2 name={ icons[type] } size={16} />;


	return window.ENV === 'dev' ? (
		<Fragment>
			<div className={ classnames(classes.children, { [classes.indicator]: isHovering }) } id="versionmanager">
				{ children }
			</div>

			<div className={ classes.root } onMouseEnter={ () => setIsHovering(true) } onMouseLeave={ () => setIsHovering(false) }>
				<Tooltip
					content={ version }
					title={ `Using the ${ type } version of ${ upperFirst(name) }.` }
				>
					{ /* eslint-disable jsx-a11y/control-has-associated-label */ }
					<button
						aria-label="version picker"
						className={classes.bubble}
						onClick={ () => setIsPickingResource(true) }
						type="button"
					>
					</button>
					{ /* eslint-enable jsx-a11y/control-has-associated-label */ }
				</Tooltip>

				<span
					className={
						classnames(classes.icon, {
							[classes.pulse]: !isHovering && type !== 'committed',
						})
					}>
					<span className={ classes.typeIcon }>
						{ ifFeature('encore', typeIcon, jadeTypeIcon) }
					</span>
					<span className={ classes.nameIcon }>
						{ icon || ifFeature('encore', typeIcon, jadeTypeIcon) }
					</span>
				</span>
			</div>

			<ResourcePicker
				getUrl={ getUrl || getResourceUrl(name) }
				isOpen={ isPickingResource }
				onCancel={ () => setIsPickingResource(false) }
				onSave={
					(_resource) => {
						setIsPickingResource(false);
						onChange(_resource);
					}
				}
				value={ resource }
			/>
		</Fragment>
	) : children;
};
