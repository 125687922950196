import {
	useState,
	useEffect,
	Fragment,
} from 'react';

import Ajax from '@utils/ajax';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex, Headline, BodyText, Divider } from '@bamboohr/fabric';

import EmployeeAccessLevelSelect from './employee-access-level-select.react';


export default function ModalContent({
	onEmployeeAccessLevelChange,
	url,
}) {
	const [data, setData] = useState(null);
	const {
		user,
		employeeAccessLevels = [],
		employeeAccessLevel,
	} = data || {};

	useEffect(() => {
		if (data) {
			return setData(null);
		}

		if (
			url &&
			typeof url === 'string'
		) {
			Ajax.get(url)
				.then((result) => {
					setData({
						...result.data,
						employeeAccessLevels: result.data.employeeAccessLevels
							.map(({ value, label: text }) => ({ text, value })),
					});
				});
		}
	}, [url]);

	useEffect(() => {
		if (data) {
			window.BambooHR.Modal.setState({
				isLoading: false,
			}, true);
		}
	}, [data]);

	return data && (
		<Fragment>
			<fieldset className="fab-FormSection no-margin">
				<legend>
					<Flex flexDirection="column">
						<Headline color={ifFeature('encore', "primary", "neutral-strong")} size="extra-small">
							{ user ? (
								<Fragment>
									{ user === 'true' ? (
										<Fragment>
											{ $.__(`Hold on!`) }
											&nbsp;
											{ $.__(`Before you add a Payroll Admin, you need to set a default Employee Access Level that will apply to all Payroll admins.`) }
										</Fragment>
									) : (
										<Fragment>
											{ $.__(`Before %1$s can become a Payroll Admin, you need to set a default Employee Access Level for Payroll admins.`, user.firstName) }
										</Fragment>
									) }
									&nbsp;
									{ $.__(`You'll only need to do this once, and it can be changed later in Payroll settings.`) }
								</Fragment>
							) : (
								<Fragment>
									{ $.__(`Set what Payroll Admins can see about themselves.`) }
								</Fragment>
							) }
						</Headline>
						<BodyText size="medium">
							{ user ? (
								<Fragment>
									{ $.__(`This determines what information they will be able to see about themselves.`) }
									&nbsp;
									{ $.__(`If they are also in a Custom Access Level, their permissions will be combined.`) }
								</Fragment>
							) : (
								<Fragment>
									{ $.__(`This is done by choosing the Employee Access Level.`) }
									&nbsp;
									{ $.__(`Note that if they are also in a Custom Access Level, their permissions will be combined.`) }
								</Fragment>
							) }
						</BodyText>
					</Flex>
				</legend>
			</fieldset>
			{ifFeature('encore', <Divider color="neutral-weak" /> )}
			<fieldset className="fab-FormSection no-padding">
				<div className="fab-FormRow no-margin">
					<div className="fab-FormColumn">
						<div className="fab-FormColumn">
							<label
								className="fab-Label"
								htmlFor="AccessLevelsSettingsModal__employeeAccessLevel"
							>
								{ $.__(`Which Employee Access Level should Payroll Admins have?`) }
							</label>
							<div className="fab-InputWrapper">
								<EmployeeAccessLevelSelect
									onChange={ onEmployeeAccessLevelChange }
									{ ...{
										employeeAccessLevels,
										employeeAccessLevel,
									} }
								/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</Fragment>
	);
}
