import Ajax from '@bamboohr/utils/lib/ajax';
import { AxiosPromise } from 'axios';
import moment from 'moment.lib';
import { StatusMismatchData, StatusMismatchEmployee, StatusMismatchEmploymentStatusFields, StatusMismatchEndEmploymentData } from './types';

export function setEmployeeActive(employeeId: string): AxiosPromise {
	return Ajax.post(`/employees/inactiveTerminated/status/${employeeId}`, { employeeStatus: 'Active' });
}

export function endEmployment(data: StatusMismatchEndEmploymentData): AxiosPromise {
	const _data = { ...data, termDate: moment(data.termDate).format(), finalPayDate: moment(data.finalPayDate).format() };

	return Ajax.post(`/employees/${data.id}/terminate`, Ajax.prepareData(_data, Ajax.prepareDataTypes.FORM_DATA));
}

export async function getMismatchData(employeeId: string): Promise<StatusMismatchData> {
	const { data } = await Ajax.get<Record<string, unknown>>(`/employees/inactiveTerminated/${ employeeId }`);

	return {
		employee: data.employee as StatusMismatchEmployee,
		fields: data.fields as StatusMismatchEmploymentStatusFields,
		hasGusto: !!data.hasGusto,
		showFinalPayDate: !!data.showFinalPayDate,
	};
}
