const version = '4.9.11';
// The default (and minimum required) configuration object for TinyMCE
export default {
	browser_spellcheck: true,
	cache_suffix: '?v=016',
	content_css: `/js/tinymce_v${ version }/css/bamboo-flat-content.css`,
	inline: true,
	language: getLanguage(),
	menubar: false,
	skin: 'bamboo-flat',
	statusbar: false,
	baseURL: `/js/tinymce_v${ version }`,
	suffix: '.min',
};

/**
 * Get currently set i18next language
 * @return {string} language The current i18next language
 */
function getLanguage() {
	let language = '';

	if (window.i18next) {
		const i18nextLanguage = window.i18next.language;

		switch (i18nextLanguage) {
			case 'fr_CA':
				language = 'fr';
				break;
			case 'de_DE':
				language = 'de';
				break;
			case 'nl_NL':
				language = 'nl';
				break;
			case 'es_AR':
				language = 'es';
				break;
			case 'ja_JP':
				language = 'ja';
				break;
			case 'pt_BR':
			case 'zh_CN':
				language = i18nextLanguage;
				break;
		}
	}

	return language;
}
