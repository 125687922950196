import { ChangeEvent, Ref } from 'react';
import classNames from 'classnames';

export type RadioItemValue = string | number;

export interface RadioItem {
	note?: string | JSX.Element;
	text: string;
	value: RadioItemValue;
	isMostCommon?: boolean;
}

interface RadioGroupProps {
	createRef?: () => Ref<HTMLInputElement> | null;
	id: string;
	biId?: string;
	disabled?: boolean;
	hasError?: boolean;
	items: RadioItem[];
	label?: string;
	name?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	required?: boolean;
	value: RadioItemValue;
}

export const RadioGroup = (props: RadioGroupProps): JSX.Element => {
	const {
		createRef,
		id,
		biId,
		disabled,
		hasError,
		items,
		label,
		name,
		onChange,
		required,
		value,
	} = props;

	const generateIdForItem = (index: number): string => {
		return `${ id }_${ index }`;
	};
	
	const generateBiIdForItem = (index: number): string => {
		if (biId) {
			return `${ biId }_${ index }`;
		}
		return '';
	};

	return (
		<fieldset className="fab-RadioGroup">
			{ label && (
				<legend
					className={ classNames(
						'fab-RadioGroup__legend',
						{ 'fab-RadioGroup__legend--required': required },
						{ 'fab-RadioGroup__legend--error': hasError }
					) }
				>
					{ label }
				</legend>
			) }
			{ items.map((item, index) => {
				return (
					<div
						key={ item.value }
						className="fab-Radio"
					>
						<input
							ref={ createRef ? createRef() : null }
							checked={ item.value === value }
							className="fab-Radio__input"
							disabled={ disabled }
							id={ generateIdForItem(index) }
							data-bi-id={ generateBiIdForItem(index) }
							name={ name }
							onChange={ onChange }
							type="radio"
							value={ item.value }
						/>
						<label
							className="fab-Radio__label"
							htmlFor={ generateIdForItem(index) }
						>
							{ item.text }
						</label>
						{ item.note && (
							<div className="fab-Radio__note">{ item.note }</div>
						) }
					</div>
				);
			}) }
		</fieldset>
	);
};
