import { createDecimal } from 'decimal-helpers';

import { appendDecimalIfNecessary, fixCurrencyValue } from 'Currency.util';
import { hasTrax } from 'BambooHR.util';
import { Fields, getField, getSelectedValue } from 'dynamic-form';

const createOvertimeRateNote = (overtimeRateValue) => {
	// only create the note if the value is not 0
	// disable note if not trax until we figure out a better way to handle user/company locations
	if (overtimeRateValue === 0 || !hasTrax()) {
		return null;
	}

	const overtimeRateDecimal = createDecimal(overtimeRateValue);

	const doubleOvertimeRate = overtimeRateDecimal
		? overtimeRateDecimal.times(1.33333333).toFixed(2)
		: NaN.toString();

	const noteText = $.__('The double time rate is $%1.', doubleOvertimeRate);
	return noteText;
};

const createOvertimeStatusNote = (PayRateField) => {
	// disable note if not trax until we figure out a better way to handle user/company locations
	if (PayRateField && hasTrax()) {
		const { value = 0 } = PayRateField.props;
		const { currency: {
			symbol = '$',
			symbolPosition = 0,
		} } = PayRateField.settings;

		const overtimeRateValue = fixCurrencyValue(appendDecimalIfNecessary(value, symbol));
		
		const overtimeRateDecimal = createDecimal(overtimeRateValue);

		const doubleOvertimeRate = overtimeRateDecimal
			? overtimeRateDecimal.times(2).toFixed(2)
			: NaN.toString();

		const overtimeRate = overtimeRateDecimal
			? overtimeRateDecimal.times(1.5).toFixed(2)
			: NaN.toString();

		const overtimeRateString = (symbolPosition == 0)
			? `${ symbol }${ overtimeRate }`
			: `${ overtimeRate }${ symbol }`;

		const doubleOvertimeRateString = (symbolPosition == 0)
			? `${ symbol }${ doubleOvertimeRate }`
			: `${ doubleOvertimeRate }${ symbol }`;

		const noteText = $.__('Overtime rate is %1 (1.5x), double-time rate is %2', overtimeRateString, doubleOvertimeRateString);
		return noteText;
	}
	return null;
};

export function getCompensationStates(context: DynamicForm.Context) {
	const data = {
		[Fields.overtimeRate]: {
			show: false,
			note: null,
		},
		[Fields.overtimeStatus]: {
			note: null,
			nonExemptEmptyPay: false,
		},
	};

	const PayRateField = getField(context, Fields.payRate) as DynamicForm.TextElement;
	const OvertimeStatusField = getField(context, Fields.overtimeStatus) as DynamicForm.SelectElement;
	const PayTypeField = getField(context, Fields.payType) as DynamicForm.SelectElement;
	const OvertimeRateField = getField(context, Fields.overtimeRate) as DynamicForm.TextElement;
	const PaidPerField = getField(context, Fields.paidPer) as DynamicForm.SelectElement;
	const paidPerValue = getSelectedValue(PaidPerField);

	const OvertimeStatusValue = OvertimeStatusField && (getSelectedValue(OvertimeStatusField) || '') as string | undefined;
	const PayTypeValue = PayTypeField && (getSelectedValue(PayTypeField) || '') as string | undefined;
	const OvertimeRateValue = fixCurrencyValue(OvertimeRateField?.props?.value);

	data[Fields.overtimeRate].note = createOvertimeRateNote(OvertimeRateValue);

	if (OvertimeStatusValue?.toLowerCase() === 'non-exempt') {
		if (!hasTrax()) {
			// show overtime rate if not trax until we figure out a better way to handle user/company locations
			data[Fields.overtimeRate].show = true;
		} else if (PayTypeValue?.toLowerCase() === 'hourly' && paidPerValue === 'Hour' && PayRateField?.settings?.currency?.symbolPosition == 0) {
			data[Fields.overtimeRate].show = false;
			data[Fields.overtimeStatus].note = createOvertimeStatusNote(PayRateField);
		} else if (PayTypeValue === 'Hourly') {
			data[Fields.overtimeRate].show = false;
		} else {
			data[Fields.overtimeRate].show = true;

		}

		if (PayRateField) {
			const PayRateValue = fixCurrencyValue(PayRateField?.props?.value);
			if (PayRateValue === 0) {
				data[Fields.overtimeRate].show = false;
				data[Fields.overtimeStatus].note = $.__('Non-exempt requires a Pay Rate higher than $0.00');
				data[Fields.overtimeStatus].nonExemptEmptyPay = true;
			}
		}
	} else {
		data[Fields.overtimeRate].show = false;
	}

	return data;
}

