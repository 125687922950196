export const TIME_FORMAT = 'h:mm A';

export const INVALID_FORMAT_MSG = $.__('Whoa. Try entering time like %s or 1:30.', `1${ window.GLOBAL_DECIMAL_CHAR || '.' }5`);
export const OVER_24_MSG = $.__('Where are we, Venus? Please enter 24 hours or fewer.');
export const OVER_24_TOTAL_MSG = $.__('Where are we, Venus? Please enter 24 total hours or fewer.');
export const SAVE_SUCCESS_MSG = $.__('Done! Your time has been saved.');
export const SAVE_FAIL_MSG = $.__("Uh oh...something went wrong, and your time wasn't saved. Try it again?");
export const CLOCK_IN_FAIL_MSG = $.__("Sorry, something went awry and we weren't able to clock you in. Try again?");
export const CLOCK_OUT_FAIL_MSG = $.__("Sorry, something went awry and we weren't able to clock you out. Try again?");
export const APPROVE_FAIL_MSG = $.__('Oh no... Sorry, there was a problem approving hours. Please try again.');
export const SAVE_ENTRY_SUCCES_MSG = $.__('Your hours worked have been successfully recorded.');
export const DELETE_ENTRY_SUCCESS_MSG = $.__('Success! The time entry has been deleted.');
export const DELETE_ENTRY_FAIL_MSG = $.__('There seems to be a problem deleting that entry. Please try again.');
export const INCORRECT_TIME_ENTRIES_MSG = $.__('Oops. Please fix any incorrect time entries and try again.');
export const ALREADY_APPROVED = $.__(`Your timesheet has already been approved.`);
export const CONTACT_HR_ADMIN = $.__(`If your time needs to be adjusted, contact your manager or HR administrator.`);
export const CLOCK_DISABLED_FUTURE_ENTRY = $.__('Clocking is disabled because you have a time entry saved for sometime in the future.');
export const CONFLICTING_ENTRIES_MSG = $.__('There are conflicting entries that already exist.');
export const FUTURE_CLOCK_IN_TIME_MSG = $.__("You can't clock in in the future.");
export const SAVE_NOTE_FAIL_MSG = $.__("Uh oh...something went wrong, and your note wasn't saved. Try it again?");
export const AUTH_FAIL_MSG = $.__('Uh oh...something went wrong. Please refresh the page and try again.');

// @startCleanup encore
export const JADE_TIME_OFF_ICONS = {
	'time-off-luggage': 'fab-briefcase-clock-14x12',
	'time-off-calendar': 'fab-calendar-clock-14x14',
	'happy-calendar': 'fab-calendar-smiling-14x14',
	'palm-trees': 'fab-palm-tree-14x13',
	'camping-trailer': 'fab-trailer-14x14',
	'airplane': 'fab-airplane-13x14',
	'band-aid': 'fab-band-aid-14x14',
	'first-aid-kit': 'fab-medical-clipboard-11x14',
	'joining-hands': 'fab-hands-joined-14x13',
	'selfie': 'fab-person-circle-14x14',
	'graduation': 'fab-graduation-cap-14x12',
	'family': 'fab-family-14x13',
	'home': 'fab-home-14x14',
	'teddy-bear': 'fab-teddy-bear-14x14',
	'baby-carriage': 'fab-stroller-14x14',
	'birthday-cake': 'fab-cake-13x14',
	'gavel': 'fab-gavel-14x14',
	'medal': 'fab-medal-13x14',
};
// @endCleanup encore
// @startCleanup encore
export const JADE_TIME_OFF_ICON_DEFAULT = 'fab-briefcase-clock-14x12';
// @endCleanup encore

export const BLANK_STATE_DETAILS = {
	title: $.__('There is no timesheet for this pay period.'),
	subtitle: $.__('A pay schedule is required to enable time tracking.'),
	icon: 'fab-tumbleweed-96x120',
	className: 'TimesheetBlankState',
};
