// @ts-nocheck
import { cloneDeep } from 'lodash';
import { createContext } from 'dynamic-form';

export const testData: DynamicForm.FormData = {
	'f-0': {
		type: 'TextField',
		props: {
			id: 'f-0',
			value: 'blue',
			className: '',
		},
		settings: {
			initialValue: 'blue',
		},
		children: ['1-2'],
	},
	'1-2': {
		type: 'TextField',
		props: {
			id: '1-2',
		},
		settings: {},
	},
};

export const nestedTestData: DynamicForm.FormData = {
	...cloneDeep(testData),
	'1-2': {
		type: 'TextField',
		props: {
			id: '1-2',
		},
		settings: {},
		children: ['1-3'],
	},
	'1-3': {
		type: 'SelectField',
		props: {
			id: '1-3',
		},
		children: ['1-4'],
		settings: {},
	},
	'1-4': {
		type: 'StaticTemplate',
		props: {
			id: '1-4',
		},
		settings: {},
	},
};

let nestedTestDataTemp = JSON.stringify(cloneDeep(nestedTestData));
nestedTestDataTemp = nestedTestDataTemp
	.replace(/1-2/gi, '1-2:idx:')
	.replace(/1-3/gi, '1-3:idx:')
	.replace(/1-4/gi, '1-4:idx:');
export const nestedTestDataRepeatable = JSON.parse(nestedTestDataTemp) as DynamicForm.FormData;

export const testCheckboxData = {
	test_01: {
		type: 'CheckboxField',
		props: {
			id: 'test_01',
			checked: true,
		},
		settings: {
			initialValue: true,
		},
		children: [],
	},
};

export const testSelectData = {
	test_02: {
		type: 'SelectField',
		props: {
			id: 'test_02',
			className: '',
			selectedValues: ['2'],
			items: [
				{
					text: 'test 2',
					value: '2',
				},
			],
		},
		settings: {
			initialValue: ['2'],
		},
		children: [],
	},
};

export const testFieldRowSetData: DynamicForm.FormData = {
	'0-0': {
		type: 'FieldSet',
		props: {
			id: '0-0',
		},
		settings: {},
		children: [
			'0-1',
		],
	},
	'0-1': {
		type: 'FieldRow',
		props: {
			id: '0-1',
		},
		settings: {},
		children: [
			'field_1-2',
			'field_1-3',
		],
	},
	'field_1-2': {
		type: 'TextField',
		props: {
			id: 'field_1-2',
		},
		settings: {},
	},
	'field_1-3': {
		type: 'TextField',
		props: {
			id: 'field_1-3',
		},
		settings: {},
	},
};

export const currencyField = {
	field_4204: {
		type: 'CurrencyField',
		props: {
			type: 'text',
			name: 'field_4204[0]',
			id: 'field_4204',
			className: 'dynamic-field-react  fab-TextInput fab-TextInput--width5 currency-field  js-overtimeRate',
			value: '',
			disabled: false,
		},
		settings: {
			initialValue: '',
			fieldBoxClasses: ' spaced',
			currency: {
				code: 'USD',
				symbol: '$',
				symbolPosition: 0,
			},
			label: 'Overtime Rate',
			type: 'currency',
			isCustomField: false,
		},
	},
};

export function createDummyContext(partialContext: Partial<DynamicForm.Context> = {}): DynamicForm.Context {
	return createContext(partialContext);
}
