import ReplyCreator from '../components/reply-creator';
import { addComment } from '../../../actions';
import { connect } from 'react-redux';
import { getConfig } from '../../../constants';
import { getId } from '../../../store/selectors';

const mapStateToProps = state => ({
	config: getConfig(getId(state))
});

export default connect(
	mapStateToProps,
	{ addComment }
)(ReplyCreator);

