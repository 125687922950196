import { getProductionUrl } from './get-production-url';

export function getResourceUrl(name = '', overrides = {}) {
	const ports = {
		greenhouse: '3000',
		po: '3010',
		developerportal: '3020',
	};

	name = name.toLowerCase();

	return ({ type, version }) => {
		if (overrides[type]) {
			return overrides[type];
		}
		if (type === 'odi') {
			return `https://staticfe.ci.pandasandbox.com/${name}/${version}`;
		}
		if (type === 'committed') {
			return getProductionUrl(name);
		}
		if (type === 'development') {
			return window.location.host.includes('.bamboolocal.com')
				? `https://staticfe.bamboolocal.com:${ports[name]}`
				: `https://resources.local.bamboohr.com/js/${name}`;
		}
		if (type === 'custom') {
			return getProductionUrl(name, version);
		}
		return version;
	};
}
