import {
    createStyles,
    makeStyles,
} from '@bamboohr/fabric/';

export const useStyles = makeStyles(({ palette }) => {
    return createStyles({
        header: {
            color: palette.grey[800],
            marginBottom: '5px',
        },
    })
});
