/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */
import { SelectField } from '@bamboohr/fabric';
import { 
	ItemUtil 
} from '@fabric/menu';
import { 
	Select as FabricSelect 
} from '@fabric/select';
import {
	Component,
} from 'react';

import { 
	objectMapUtil, 
	convertOldItemToNew, 
	convertNewItemToOld,
	placementOldToNew,
	placementNewToOld
} from 'shared/utils';

class Select extends Component {
	_convertOldSettings(settings) {
		const settingsMap = {
			disabled: 'isDisabled',
			multiselect: 'canSelectMultiple',
			notClearable: {
				name: 'isClearable',
				convert: value => !value
			},
			listPosition: {
				name: 'placement',
				convert: value => placementOldToNew(value)
			}
		};
		return objectMapUtil(settings, settingsMap);
	}

	_convertOldProps = () => {
		const propMap = {
			disabled: 'isDisabled',
			multiselect: 'canSelectMultiple',
			notClearable: {
				name: 'isClearable',
				convert: value => !value
			},
			required: 'isRequired', 
			data: {
				name: 'items',
				convert: data => data.items.filter(item => item).map(convertOldItemToNew)
			},
			onChange: {
				name: 'onChange',
				convert: (legacyOnChange) => {
					return (selectedValue, selectedItems) => {
						const { data } = this.props;

						try {
							const _selectedItem = selectedItems.length ? convertNewItemToOld(selectedItems[0]) : undefined;
								
							return legacyOnChange(_selectedItem, {
								parentItem: ItemUtil.getParent(data.items.map(convertOldItemToNew), convertOldItemToNew(selectedItems[0])), 
							});
						} catch (error) {
							console.log(error);
						}
						
					};
				}
			},
			optionContentCallback: {
				name: 'renderOptionContent',
				convert: callback => item => callback(convertNewItemToOld(item))
			},
			toggleContentCallback: {
				name: 'renderToggleContent',
				convert: callback => (items) => {
					const { data } = this.props;
					const _parentItem = ItemUtil.getParent(data.items.map(convertOldItemToNew), items[0]);
					const _value = _parentItem ? [convertNewItemToOld(_parentItem)] : items.map(convertNewItemToOld);
					
					return callback(_value);
				}
			},
			onShow: 'onOpen',
			onHide: 'onClose'

		};
		let _convertedProps = objectMapUtil(this.props, propMap);
		const { settings } = _convertedProps;

		if (settings) {
			_convertedProps = {
				..._convertedProps,
				settings: null,
				...this._convertOldSettings(settings)
			};
		}
		
		const { canSelectMultiple } = _convertedProps;

		const getCurrentlySelectedItems = (items) => {
			return items.reduce((memo, item) => {
				let selected = memo;

				if (item.items) {
					selected = [...memo, getCurrentlySelectedItems(item.items)];
				} else if (item.selected) {
					selected = [...memo, item];
				}
				return selected;
			}, []);
		};

		if (canSelectMultiple) {
			const multiselectFunc = (selectedItemValue, selectedValues = [], details = {}) => {
				const { item, parentItem } = details;
				const { data, onChange } = this.props;
				const { items } = data;

				if (onChange) {
					const oldItem = selectedItemValue ? convertNewItemToOld(item) : undefined;
					const oldParentItem = parentItem ? convertNewItemToOld(parentItem) : parentItem;
					const selected = selectedValues.includes(selectedItemValue);

					const currentlySelected = getCurrentlySelectedItems(items);

					const selectedItems = selected ? (
						[
							...currentlySelected,
							oldItem,
						]
					) : (
						currentlySelected.filter(item => oldItem && oldItem.value !== item.value)
					);


					onChange(oldItem, { 
						parentItem: oldParentItem, 
						selectedItems,
						selected
					});
				}
			};

			_convertedProps.onChange = undefined;
			_convertedProps.onSelect = multiselectFunc;
			_convertedProps.onDeselect = multiselectFunc;
			_convertedProps.onClear = multiselectFunc;
		}

		return _convertedProps;
	}

	_convertNewSettings = (settings) => {
		const map = {
			canSelectMultiple: 'multiselect',
			isClearable: {
				name: 'notClearable',
				convert: value => !value
			},
			placeholder: 'placeholder',
			required: 'required'
		};
		const settingsKeys = Object.keys(settings);
		const settingsObj = {};

		settingsKeys.forEach((key) => {
			if (map[key]) {
				settingsObj[key] = settings[key];
			}
		});
		return objectMapUtil(settingsObj, map);
	}

	_convertNewProps = () => {
		const { fabricSettings } = this.props;
		const map = {
			canSelectMultiple: 'multiselect',
			isClearable: {
				name: 'notClearable',
				convert: value => !value
			},
			isDisabled: 'disabled',
			items: {
				name: 'data',
				convert: items => ({ items: items.filter(item => item).map(convertNewItemToOld) })
			},
			placement: {
				name: 'listPosition',
				convert: value => placementNewToOld(value)
			},
			renderOptionContent: {
				name: 'optionContentCallback',
				convert: callback => item => callback(convertOldItemToNew(item))
			}
		};
		const settings = this._convertNewSettings(fabricSettings);
		const _convertedProps = objectMapUtil(fabricSettings, map);

		return {
			..._convertedProps,
			settings
		};
	}

	render() {
		const { 
			fabricSettings,
		} = this.props;

		const props = fabricSettings ? fabricSettings : this._convertOldProps();

		return ifFeature(
			'encore',
			props.label ? (
				<SelectField
					{...props}
					// SelectField behaves differently than Select, so we need to pass the value as an array
					listenToItemsOnChange
					onChange={({ target: { value } }) => props.onChange(null, Array.isArray(value) ? value : [value])}
				/>
			) : (
				<FabricSelect {...props} />
			),
			<FabricSelect {...props} />
		);
	}
}

export default Select;

export {
	getFirstItem, 
	getSelectedItem,
} from './utils';
