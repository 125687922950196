/**
 * reduce multiple reducers into a single reducer
 *
 * the result of each reduction is passed as state to the following reducer.
 * this will allow for convenient feature-based updates with state-wide merging
 * of immutable structures.
 *
 * @see {@link https://redux.js.org/recipes/structuring-reducers/updating-normalized-data#task-based-updates|task-based updates}
 * @see {@link https://github.com/redux-utilities/reduce-reducers|util source}
 *
 * @param {Function} ...reducers reducer functions
 * @return {Function} reducer
 */
export const reduce = (...reducers) => (prev, curr) => (
	reducers.reduce((p, r) => r(p, curr), prev)
);

/**
 * create reducer as mappings from actions -> handlers
 *
 * @example create({}, { ADD_COMMENT: [Function: ADD_COMMENT], });
 * @see {@link https://redux.js.org/recipes/reducing-boilerplate#generating-reducers|generating reducers}
 *
 * @param {mixed} initialState initial state
 * @param {object} handlers mapping of actions -> handlers
 * @return {Function} reducer
 */
export const create = (initialState, handlers) => (state = initialState, action) => {
	if (handlers.hasOwnProperty(action.type)) {
		return handlers[action.type](state, action);
	}
	return state;
};
