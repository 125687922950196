import { FunctionComponent, useState } from 'react';
import { Typography, FileIcon, IconButton, makeStyles, createStyles } from '@bamboohr/fabric';
import { CircleX14x14 } from '@bamboohr/grim';
import { FileDocument } from '../interfaces';
import { DeleteFileModal } from '../modals';
import {ifFeature} from "@bamboohr/utils/lib/feature";

interface InitialFileProps {
    file: FileDocument;
    onDelete: (FileDocument) => void;
    canEdit: boolean;
}

export const useStyles = makeStyles(({ palette }) => {
    return createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
        },
        file: {
            display: 'flex',
            alignItems: 'center',
            '&:hover $name': {
                cursor: 'pointer',
                color: palette.action.active,
            },
        },
        name: {
            margin: '0px 8px',
            color: palette.grey[800],
        },
    });
});

export const InitialFile: FunctionComponent<InitialFileProps> = (props) => {
    const { file, onDelete, canEdit } = props;
    const classes = useStyles();
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const openModal = () => {
        setDeleteModalIsOpen(true);
    };

    const closeModal = () => {
        setDeleteModalIsOpen(false);
    };

    const deleteFile = () => {
        onDelete(file);
        closeModal();
    };

    const previewFile = () => {
        window.previewFile(
            String(file.most_recent_employee_file_data_id),
            file.id,
            file.name,
            true,
            null,
            false,
            file.mode,
            false,
            false
        );
    };

    return (
        <div>
            <div className={classes.wrapper}>
                <div className={classes.file} onClick={previewFile}>
                    <FileIcon filename={file.name} />
                    <Typography className={classes.name}>{file.name}</Typography>
                </div>
                {canEdit && <IconButton
                    icon="circle-xmark-solid"
                    noBoundingBox={true}
                    onClick={openModal}
                    type="button"
                />}
            </div>
            <DeleteFileModal file={file} handleClose={closeModal} handleConfirm={deleteFile} isOpen={deleteModalIsOpen} />
        </div>
    );
};
