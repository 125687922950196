import React, { useState, useEffect } from 'react';
import { EmployeeProfileProps } from './types';
import {Avatar, Divider, AdornedText, BodyText, IconV2, LayoutBox} from '@bamboohr/fabric';
import { Envelope14x12, Company12x14, MobilePhone10x16, People14x12, OrgChart12x13 } from '@bamboohr/grim';
import { useStyles } from './employee-profile-info.styles';
import { DateTime } from 'luxon';
import { ifFeature } from "@utils/feature";

export const EmployeeProfileInfo = (props: EmployeeProfileProps): JSX.Element => {
	const { employee } = props;
	const [ dateTime, setDateTime ] = useState(DateTime.local());
	const styles = useStyles();

	useEffect(() => {
	  const timerID = setInterval(
		() => tick(),
		1000
	  );
	  const tick = () => setDateTime(DateTime.local());
	
	  return () => clearInterval(timerID);
	}, [])
	

	const titleDepartment = (): string => {
		if (employee.jobTitle && employee.department) {
			return $.__('%1 in %2', employee.jobTitle, employee.department);
		} else if (employee.jobTitle && !employee.department) {
			return employee.jobTitle;
		} else if (!employee.jobTitle && employee.department) {
			return employee.department;
		}
	};

	const getWorkPhone = (): string => {
		if (!employee.ext) {
			return employee.workPhone;
		}

		return `${employee.workPhone} Ext ${employee.ext}`;
	}

	const adornedTextClasses = { root: styles.secondaryInfoContainer, adornment: styles.icons };
	const typographyProps = { className: styles.secondaryInfo };

	return (
		<div className={styles.container}>
			{ifFeature('encore',
				<Avatar alt="profile" size={160} srcSet={employee.imgUrl} />,
				<Avatar alt="profile" srcSet={employee.imgUrl} sx={{ height: 244, width: 244 }} />
			)}
			<div className={styles.contentContainer}>
				<div className={styles.primaryContentContainer}>
					<div className={styles.infoContainer}>
						<div className={styles.employeeName}>
							{employee.prefName ? `${ employee.prefName } ${ employee.lastName }` : `${ employee.firstName } ${ employee.lastName }`}
						</div>
						{employee.pronouns && <div className={styles.pronouns}>
							{`(${employee.pronouns})`}
						</div>}
					</div>
					{ (employee.jobTitle || employee.department) && <div className={styles.jobInfo}>{titleDepartment()}</div>}
					{employee.location && <div className={styles.jobInfo}>{employee.location}
						{ employee.timezone && <span> | { dateTime.setZone(employee.timezone).toLocaleString(DateTime.TIME_SIMPLE) } local time</span>}
					</div>}
					{employee.division && <div className={styles.jobInfo}>{employee.division}</div>}
				</div>
				<Divider classes={{ root: styles.divider }} />
				<div className={styles.secondaryContentContainer}>
					{employee.email &&
						ifFeature('encore',
							<LayoutBox paddingBottom="10px">
								<BodyText color='neutral-weak' icon={<IconV2 color='neutral-strong' name='envelope-regular' size={16} />}>{employee.email}</BodyText>
							</LayoutBox>,
							<AdornedText adornment={<Envelope14x12 />} classes={adornedTextClasses} typographyProps={typographyProps} >{employee.email}</AdornedText>
						)
					}
					{employee.workPhone &&
						ifFeature('encore',
							<LayoutBox paddingBottom="10px">
								<BodyText color='neutral-weak' icon={<IconV2 color='neutral-strong' name='buildings-regular' size={16} />}>{getWorkPhone()}</BodyText>
							</LayoutBox>,
							<AdornedText adornment={<Company12x14 />} classes={adornedTextClasses} typographyProps={typographyProps} >{getWorkPhone()}</AdornedText>
						)
					}
					{employee.personalPhone &&
						ifFeature('encore',
							<LayoutBox paddingBottom="10px">
								<BodyText color='neutral-weak' icon={<IconV2 color='neutral-strong' name='mobile-regular' size={16} />}>{employee.personalPhone}</BodyText>
							</LayoutBox>,
							<AdornedText adornment={<MobilePhone10x16 />} classes={adornedTextClasses} typographyProps={typographyProps} >{employee.personalPhone}</AdornedText>
						)
					}
					{employee.reportsTo &&
						ifFeature('encore',
							<LayoutBox paddingBottom="10px">
								<BodyText color='neutral-weak' icon={<IconV2 color='neutral-strong' name='circle-user-regular' size={16} />}>{`Reports to ${employee.reportsTo}`}</BodyText>
							</LayoutBox>,
							<AdornedText adornment={<People14x12 />} classes={adornedTextClasses} typographyProps={typographyProps} >{`Reports to ${employee.reportsTo}`}</AdornedText>
						)
					}
					{employee.directReports.length > 0 &&
						ifFeature('encore',
							<LayoutBox paddingBottom="10px">
								<BodyText color='neutral-weak' icon={<IconV2 color='neutral-strong' name='sitemap-regular' size={16} />}>{`${employee.directReports.length} direct reports`}</BodyText>
							</LayoutBox>,
							<AdornedText adornment={<OrgChart12x13 />} classes={adornedTextClasses} typographyProps={typographyProps} >{`${employee.directReports.length} direct reports`}</AdornedText>
						)
					}
				</div>
			</div> 
		</div>
	)
}