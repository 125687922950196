/**
 * Return true or false, based on whether or not the value is
 * equal to or between the min and max numbers provided
 * @param value {number|string} The value to be tested
 * @param min {number}          The minimum of the range
 * @param max {number}          The maximum of the range
 * @returns {boolean}
 */
export function numberWithinRange(value, min, max) {
	return (typeof value === 'number' || typeof value === 'string') && (value >= Number(min) && value <= Number(max));
}

/**
 * Return formatted currency or percentage
 * @param value  {number|string}          The value to be formatted
 * @param format {'percent'|'currency'}  'percent' or 'currency'
 * @returns      {string}                '40.5%' or '$40.50'
 */
export function formatCurrencyPercent(value, format) {
	if (typeof value === 'number' || typeof value === 'string') {
		if (format === 'percent') {
			return formatPercent(value);
		} else if (format === 'currency') {
			return formatCurrency(value);
		}
	}
	return value;
}

/**
 * Return formatted currency or percentage
 * @param value {number|string} The value to be formatted
 * @returns     {string}        '$40.50'
 */
export function formatCurrency(value) {
	return `$${ Number(value).toFixed(2) }`;
}

/**
 * Return formatted currency or percentage
 * @param value {number|string} The value to be formatted
 * @returns     {string}        '40.5%'
 */
export function formatPercent(value) {
	return `${ parseFloat(value) }%`;
}

/**
 * Validates tax data object supplied from the back-end
 * @param taxData {object} Object that represents federal tax data
 * @returns       {boolean}
 */
export function validateFederalTaxData(taxData) {
	return (
		!!taxData &&
		taxData.hasOwnProperty('federal') &&
		!!taxData.federal.fields
	);
}

/**
 * Validate tax data object supplied from the back-end
 * @param taxData {object} Object that represents state tax data
 * @returns       {boolean}
 */
export function validateStateTaxData(taxData) {
	return (
		!!taxData &&
		taxData.hasOwnProperty('state') &&
		!!taxData.state.locations &&
		Array.isArray(taxData.state.locations.allIds) &&
		taxData.state.locations.allIds.length > 0 &&
		!!taxData.state.locations.byId
	);
}
