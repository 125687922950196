import { ReactElement } from 'react';
import { createStyles, makeStyles, Checkbox, DisplayFileCard } from '@bamboohr/fabric';
import { FileSection } from 'NHPTemplates.mod/Form/components/nhp-tasks/components/file-section';

export const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		task: {
			padding: '20px 22px',
			paddingBottom: '36px',
			display: 'flex',
		},
		taskIndex: {
			display: 'flex',
			paddingTop: '24px',
		},
		taskNumber: {
			paddingLeft: '10px',
			margin: '0',
			color: palette.grey[600],
		},
		taskInfo: {
			paddingLeft: '20px',
		},
		taskTitle: {
			paddingTop: '22px',
			margin: '0',
		},
		taskDescription: {
			color: palette.grey[700],
			marginTop: '5px',
			marginBottom: '10px',
			fontSize: typography.fabricFontSize('small'),
		}
	});
});

interface Props {
    task: any,
	index: number,
}

export function Task(props: Props): ReactElement {

	const classes = styles();

	const {
		task,
		index,
	} = props;

	const {
		files
	} = task;

	const filesArray = Object.keys(files).map(key => files[key]);
    
	return (
		<div className={ classes.task }>
			<div className={ classes.taskIndex }>
				<Checkbox checked={ task.completed } disabled={ true } onChange={ null } size="biggie" value="" />
				<h4 className={ classes.taskNumber }>{ index + 1 }.</h4>
			</div>
			<div className={ classes.taskInfo }>
				<h3 className={ classes.taskTitle }>{ task.name }</h3>
				<p className={ classes.taskDescription }>{ task.description }</p>
				{ filesArray.length > 0 && (
					<FileSection
						files={ filesArray }
						view="display"
					/>
				)}
			</div>
		</div>
	);
}
