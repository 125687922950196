import {
	flow,
	padEnd,
	padStart,
	round,
	values,
} from 'lodash';
import {
	filter,
	sortBy,
} from 'lodash/fp';
import moment from 'moment.lib';

const RE_DECIMAL = /^(\d*)(?:[.,](\d*))?$/; // 12.5  6.50  6,5  12,50  12.
const RE_COLON = /^(\d{0,2}):(\d{0,2})$/; // 6:30  06:30  12:30 6:3 :3 6:
const RE_H_M = /(?=.*[hm])^(?:(\d{1,2})h)?\s*(?:(\d{1,2})m?)?$/i; // 6h30m, 6H30M, 12h5m, 6h 30m, 6h 3m, 12h, 12h10, 10m
export const INVALID_FORMAT = 'INVALID_FORMAT';
export const OVER_24 = 'OVER_24';


/**
 * Changes type of possible number to a number including undefined.
 * Because we are dealing with captures from RegExp, we may receive
 * an undefined but that should map to a number. This normalizes those matches
 * @param possibleNumber
 * @returns {number}
 */
function toNumber(possibleNumber) {
	if (!possibleNumber) {
		return 0;
	}

	return Number(possibleNumber);
}


/**
 * Takes text input and normalizes it into a positive number in hours between
 * 0 and 24 inclusive, or `null` if the format is not recognized
 *
 * @param {string|number|null} timeInput This is formatted like "6.5", "6:30" or "6h 30m"
 * @returns {number|string|null}
 */
export function parseTime(timeInput) {
	const value = String(timeInput).trim();

	if (!value) {
		return null;
	}

	let time = null;
	let match = value.match(RE_DECIMAL);

	if (match) {
		time = toNumber(match[1]) + (match[2] ? toNumber(`.${ match[2] }`) : 0);
	} else if (value.match(RE_COLON)) {
		match = value.match(RE_COLON);
		time = toNumber(match[1]) + toNumber(match[2].length < 2 ? `${ match[2] }0` : match[2]) / 60;
	} else if (value.match(RE_H_M)) {
		match = value.match(RE_H_M);
		time = toNumber(match[1]) + toNumber(match[2]) / 60;
	}

	if (typeof time !== 'number') {
		return INVALID_FORMAT;
	}

	return time <= 24 ? round(time, 4) : OVER_24;
}


/**
 * Takes a number representing an amount of hours and returns a string in
 * display format, e.g. 6.5 => "6h 30m", rounding to the nearest minute
 *
 * @param {number|string}   hoursValue
 * @param {boolean}         short       '35m' instead of '0h 35m'; '1h' instead of '1h 00m'
 * @returns {string}
 */
export function formatTime(hoursValue, short = false) {
	const totalHours = parseFloat(hoursValue);
	if (isNaN(totalHours)) {
		return '';
	}

	let hours = parseInt(totalHours);
	let minutes = Math.round(totalHours * 60 % 60);

	// to account for edge case of Math rounding up x.992 or greater to x
	if (minutes === 60) {
		hours++;
		minutes = 0;
	}

	if (short) {
		if (minutes && !hours) {
			return `${ minutes }m`;
		} else if (hours && !minutes) {
			return `${ hours }h`;
		}
		// otherwise standard return below is shortest format
	}

	return `${ hours }h ${ padStart(minutes, 2, 0) }m`;
}

/**
 * Converts twelve-hour time into twenty-four hour time format
 * Example: "2:30 PM" => "14:30:00"
 *
 * @param {number|string}  time      '2:30'
 * @param {string}         meridiem  'AM' or 'PM'
 * @returns {string}                 '14:30:00'
 */
export function getTwentyFourHourTime (time, meridiem) {
	return moment(`${ time } ${ meridiem }`, 'h:mm A', 'en').format('HH:mm:ss');
}

/**
 * Gets the duration of time between two moments
 *
 * @param {object}   start        moment object, start date-time
 * @param {object}   end          moment object, end date-time
 * @param {string}   measurement  'hours', 'minutes', 'seconds', etc.
 * @returns {number}              5.75
 */
export function getTimeDuration(start, end, measurement) {
	if (start && start.isValid() && end && end.isValid() && measurement) {
		return moment.duration(end.diff(start)).as(measurement);
	}

	return 0;
}

/**
 *
 * @param {string}  endTime         '1:30'
 * @param {string}  endMeridiem     'PM'
 * @param {string}  originalDate    '2018-05-05'
 * @returns {string}                '2018-05-06'
 */
export function getEndDate(endTime, endMeridiem, originalDate) {
	const endTimeIsMidnight = (endTime && endMeridiem === 'AM' && padZerosOnTime(endTime) === '12:00');
	return (endTimeIsMidnight ? moment(originalDate).add(1, 'day').format('YYYY-MM-DD') : originalDate);
}


const CLOCK_IN = $.__('Clock In');
const CLOCKING_IN = $.__('Clocking In');
const CLOCK_OUT = $.__('Clock Out');
const CLOCKING_OUT = $.__('Clocking Out');
const FORGOT_CLOCK_OUT = $.__('Verify Time & Clock Out');
/**
 * Generates text for display within clock punch buttons on home widget, timesheet, etc.
 *
 * @param {boolean}    clockedIn
 * @param {boolean}    actionPending
 */
export function clockButtonText(clockedIn, actionPending, forgotClockOut, userCanEdit) {
	if (clockedIn && !forgotClockOut) {
		return (actionPending ? CLOCKING_OUT : CLOCK_OUT);
	} else if (forgotClockOut) {
		return (userCanEdit ? FORGOT_CLOCK_OUT : CLOCK_OUT);
	}
	return (actionPending ? CLOCKING_IN : CLOCK_IN);
}


/**
 * Generates text for display next to clock action button in the form of "Clocked In: Today at 10:34 AM"
 *
 * @param {object}      lastAction
 * @param {object}      todayMoment
 * @returns {string}
 */
export function lastActionText(lastAction, todayMoment) {
	const lastDate = (lastAction.end ? lastAction.end : lastAction.start);
	const formattedDate = moment.tz(lastDate, lastAction.timezone).calendar(todayMoment);
	const { projectName, taskName } = lastAction;

	if (!lastAction.end) {
		if (lastAction.projectName && lastAction.taskName) {
			return `${ projectName } \u00BB ${ taskName }`;
		}
		if (lastAction.projectName) {
			return projectName;
		}
		return $.__('Clocked In: %1$s', formattedDate);
	}
	return $.__('Clocked Out: %1$s', formattedDate);
}

const HOURS_BEFORE_COLON = /^(1[0-2]|0[1-9]|[2-9])$/;
const THIRTEEN_FIFTEEN = /^1[3-5]$/;
const HOURS_WITH_COLON = /^(?:1[0-2]|0?[1-9]):?$/;
const ALMOST_OR_COMPLETE = /^(?:1[0-2]|0?[1-9]):(?:[0-5][0-9]?)$/;
const TEST_COLON = /:/;
const REPLACE_BEFORE_COLON_TO_END = /.?:.*/;
const COLON_SIX_NINE = /:[6-9]$/;
const REPLACE_LAST = /.$/;
const STARTS_WITH_ZERO_OR_COLON = /^0?:/;

export function timeOfDayInputEnforcer(value, previousValue) {
	const length = value.length;
	const pLength = previousValue.length;
	const totalChanged = Math.abs(pLength - length);

	// If the input is being added to

	if (length > pLength) {
		if (HOURS_BEFORE_COLON.test(value)) {
			return (value.startsWith('0') ? value.slice(1) : value) + ':';
		}

		if (previousValue === '1' && THIRTEEN_FIFTEEN.test(value)) {
			return value.split('').join(':');
		}

		if (value === '' || value === '0' || HOURS_WITH_COLON.test(value) || ALMOST_OR_COMPLETE.test(value)) {
			return value;
		}

		return previousValue;
	}

	// You made it here... and the input is shortening

	if (totalChanged > 1) {
		return timeOfDayInputEnforcer(value, '');
	}

	if (totalChanged === 1 && TEST_COLON.test(previousValue) && !TEST_COLON.test(value) && length !== 0) {
		return previousValue.replace(REPLACE_BEFORE_COLON_TO_END, '');
	}

	if (COLON_SIX_NINE.test(value)) {
		return value.replace(REPLACE_LAST, '');
	}

	if (STARTS_WITH_ZERO_OR_COLON.test(value)) {
		return '';
	}

	return value;
}

export function padZerosOnTime(value) {
	if (typeof value === 'string') {
		if ((/:/).test(value)) {
			return value.replace(/\d*$/, match => padEnd(match, 2, '0'));
		}

		return value ? value + ':00' : '';
	}

	return '';

}

const VALID_TIME_FORMAT = /^(?:1[0-2]|[1-9]):[0-5][0-9]$/;
/**
 * Validate the time format of a given string
 *
 * @param {string}      time
 * @returns {boolean}
 */
export function validateTimeFormat(time) {
	return VALID_TIME_FORMAT.test(time);
}

/**
 * Validate the hour format of a given string
 *
 * @param {string}      time
 * @returns {boolean}
 */
export function validateHourFormat(time) {
	const parsedTime = parseTime(time);
	return (parsedTime !== OVER_24 && parsedTime !== INVALID_FORMAT);
}

/**
 * Adds or removes 'disabled' attribute on element depending on validity of start and end times
 *
 * @param {array}     entries       array of entry objects: [{startTime: '1:00', endTime: '2:00'}]
 * @returns {boolean}
 */
export function validateTimeEntries(entries) {
	let valid = true;

	if (!Array.isArray(entries)) {
		return false;
	}

	entries.forEach((entry) => {
		let paddedStart = padZerosOnTime(entry.startTime);
		let paddedEnd = padZerosOnTime(entry.endTime);

		if (
			!validateTimeFormat(paddedStart) ||
			!validateTimeFormat(paddedEnd) ||
			(entry.total < 0)
		) {
			valid = false;
		}
	});

	return valid;
}

export function validateHourEntries(entries) {
	let valid = true;

	if (!Array.isArray(entries)) {
		return false;
	}

	entries.forEach((entry) => {
		if (
			!validateHourFormat(entry.hours) ||
			(entry.total < 0)
		) {
			valid = false;
		}
	});

	return valid;
}

export function validateClockOutDates(entries) {
	const validEntries = [];

	if (!Array.isArray(entries)) {
		return false;
	}

	entries.forEach((entry) => {
		const isValid = entry.clockOutDateTime !== null && entry.clockOutDateTime._isValid;
		validEntries.push(isValid);
	});

	return !validEntries.includes(false);
}

/**
 * Returns true if there is overlap, false if there is no overlap between two entries
 *
 * @param {object}    entry1   object that contains startMoment and endMoment properties
 * @param {object}    entry2   object that contains startMoment and endMoment properties
 * @returns {boolean}
 */
export function checkEntriesForOverlap(entry1, entry2) {
	return (
		entry1.startMoment.isBetween(entry2.startMoment, entry2.endMoment) ||
		entry1.endMoment.isBetween(entry2.startMoment, entry2.endMoment) ||
		entry2.startMoment.isBetween(entry1.startMoment, entry1.endMoment) ||
		entry2.endMoment.isBetween(entry1.startMoment, entry1.endMoment) ||
		(entry1.startMoment.isSame(entry2.startMoment) && entry1.endMoment.isSame(entry2.endMoment))
	);
}

export function calculateTimeEarned(startTime, serverTime) {
	let startMoment = moment(startTime);
	let serverMoment = moment(serverTime);

	startMoment.startOf('minute');
	serverMoment.startOf('minute');

	return round(serverMoment.diff(startMoment, 'hours', true), 4);
}

export function getDaysFromMap(start, end, daysMap) {
	return flow(
		values,
		filter(day => day.date >= start && day.date <= end),
		sortBy('date')
	)(daysMap);
}

export function sumOvertimeHours(daysArr) {
	const overtimeTotals = daysArr.reduce((totals, day) => {
		if (day.overtimeSummary !== undefined && day.overtimeSummary.length > 0) {
			day.overtimeSummary.forEach((overtime) => {
				// On inital setup of the overtimeTotals object set the multiplier's hours to 0.0 so we don't end up with NaN
				if (totals[overtime.multiplier] === undefined) {
					totals[overtime.multiplier] = 0.0;
				}

				totals[overtime.multiplier] += overtime.hours;
			});
		}
		return totals;
	}, {});

	let overtimeSummary = [];
	for (let key in overtimeTotals) {
		if (overtimeTotals[key] > 0) {
			overtimeSummary.push({
				multiplier: parseFloat(key),
				hours: overtimeTotals[key]
			});
		}
	}

	return overtimeSummary.sort((a, b) => a.multiplier - b.multiplier);
}

export function getPaidHolidayText(holiday) {
	let holidayText;
	const hasMinutes = holiday.paidHours % 1 != 0;
	if (holiday.paidHours === null) {
		holidayText = holiday.name;
	} else if (hasMinutes) {
		holidayText = $.__('%1$s for %2$s', formatTime(holiday.paidHours), holiday.name, {note: 'e.g. 7h 30m for New Years'});
	} else {
		holidayText = $.__('%1$s Hours for %2$s', holiday.paidHours, holiday.name, {note: '8 Hours for New Years'});
	}

	return holidayText;
}

export function forgotToClockOut(lastClockEntry) {
	if (lastClockEntry === null) {
		return false;
	}

	const now = moment();
	const lastClock = moment(lastClockEntry.start);
	const hoursSinceLastClockIn = moment(now).diff(moment(lastClock), 'hours', true);
	const lastClockYesterday = lastClock.isBefore(now, 'day');

	return lastClockEntry.end === null && lastClockYesterday && hoursSinceLastClockIn > 14;
}

export function clockStatesDiffer(localClockedIn, localLatestEntry, remoteClockedIn, remoteLatestEntry) {
	const clockStatusChanged = remoteClockedIn !== Boolean(localClockedIn);
	let clockTimeChanged = false;

	if (clockStatusChanged) {
		return true;
	}

	if (localLatestEntry === null) {
		clockTimeChanged = remoteLatestEntry !== null;
	} else {
		clockTimeChanged = remoteLatestEntry !== (remoteClockedIn ? localLatestEntry.start : localLatestEntry.end);
	}

	return clockTimeChanged;
}

export function getWorkWeekStartsOnObject(timesheetDailyDetails) {
	const workWeekStartsOnChangedCount = timesheetDailyDetails.reduce((changedCount, day) => {
		if (day.hasWorkWeekStartsOnChanged === true) {
			return changedCount + 1;
		}

		return changedCount;
	}, 0);

	return {
		changed: workWeekStartsOnChangedCount > 0,
		count: workWeekStartsOnChangedCount
	};
}

export function getMeridiem(momentObj) {
	return momentObj.clone().locale('en').format('A');
}

export function convertNormalizedProjectsToDropdownItems(normalizedData) {
	const { allIds, byId } = normalizedData;
	const projects = allIds.map((id) => {
		let tasks = null;
		if (byId[id].tasks.allIds.length > 0) {
			const tasksById = byId[id].tasks.byId;
			tasks = byId[id].tasks.allIds.map((taskId) => {
				return {
					text: tasksById[taskId].name,
					value: `${ byId[id].id }-${ tasksById[taskId].id }`
				};
			});
		}

		if (tasks !== null) {
			return {
				items: tasks,
				text: byId[id].name,
				value: byId[id].id,
			};
		}

		return {
			text: byId[id].name,
			value: byId[id].id,
		};
	});

	return [{
		items: projects,
		text: $.__('All Projects'),
		type: 'group',
		key: 'projects',
	}];
}

export function getHasFutureEntry(latestClockEntry, serverTime) {
	return !!latestClockEntry && moment.tz(latestClockEntry.end, latestClockEntry.timezone).isAfter(serverTime);
}

export function getProjectAndTaskId(id) {
	if (typeof id === 'string' && id.indexOf('-') > -1) {
		const projectAndTask = id.split('-');
		return projectAndTask;
	}

	return [id, null];
}

export function getProjectDataFromId(id, normalizedData) {
	if (id === null) {
		return {
			projectId: null,
			projectName: null,
			taskId: null,
			taskName: null
		};
	}

	const { byId } = normalizedData;
	const [projectId, taskId] = getProjectAndTaskId(id);

	if (projectId && taskId) {
		return {
			projectId: byId[projectId].id,
			projectName: byId[projectId].name,
			taskId: byId[projectId].tasks.byId[taskId].id,
			taskName: byId[projectId].tasks.byId[taskId].name
		};
	}

	return {
		projectId: byId[id].id,
		projectName: byId[id].name,
		taskId: null,
		taskName: null
	};
}

export function getProjectSelectToggleText(selectedItem, projectName, taskName) {
	if (projectName && taskName) {
		return `${ projectName } \u00BB ${ taskName }`;
	}

	if (projectName) {
		return projectName;
	}

	return selectedItem[0].text;
}

export function getProjectTaskText(projectName, taskName) {
	let projectTaskText = null;
	if (projectName && taskName) {
		projectTaskText = `${ projectName } \u00BB ${ taskName }`;
	} else if (projectName) {
		projectTaskText = projectName;
	}

	return projectTaskText;
}

export function getFocusedSheetDateSpanText(start, end) {

	// What about year spans, and periods in a previous year. HMMMM? WE NEED MORE DEETS!!! 😡

	const focusedStartMoment = moment(start, 'YYYY-MM-DD');
	const focusedEndMoment = moment(end, 'YYYY-MM-DD');
	const isSameDay = focusedStartMoment.isSame(focusedEndMoment, 'day');
	const isSameMonth = focusedStartMoment.isSame(focusedEndMoment, 'month');
	const focusedStartFormat = focusedStartMoment.format('MMM D');
	const focusedEndFormat = focusedEndMoment.format(isSameMonth ? 'D' : 'MMM D');
	return isSameDay ? focusedStartFormat : `${ focusedStartFormat }–${ focusedEndFormat }`;
}

/**
 * Uses the Haversine formula to calculate the distance between two points. Returns in meters instead of kilometers
 *
 * @param {array} coordinates1 array of latitude and longitude points
 * @param {array} coordinates2 array of latitude and longitude points
 * @returns {number} distance in meters
 */
export function calculateDistanceBetweenPoints([lat1, lon1], [lat2, lon2]) {
	const toRadian = angle => (Math.PI / 180) * angle;
	const distance = (a, b) => (Math.PI / 180) * (a - b);
	const RADIUS_OF_EARTH_IN_KM = 6371;

	const dLat = distance(lat2, lat1);
	const dLon = distance(lon2, lon1);

	lat1 = toRadian(lat1);
	lat2 = toRadian(lat2);

	// Haversine Formula
	const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
	const c = 2 * Math.asin(Math.sqrt(a));

	let finalDistance = RADIUS_OF_EARTH_IN_KM * c;
	finalDistance *= 1000; // We want the distance in meters rather than kilometers

	return finalDistance;
}
