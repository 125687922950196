import { CancellationType } from 'in-app-cancellation.mod';

export const biId = 'in-app-cancellation-product-selection-modal';
export const ProductSelectionNamesMap = {
	[CancellationType.ACCOUNT]: $.__('Full BambooHR Account'),
	[CancellationType.PERFORMANCE]: $.__('Performance Management'),
	[CancellationType.TIME_TRACKING]: $.__('Time Tracking'),
	[CancellationType.PAYROLL]: $.__('Payroll'),
	[CancellationType.BENEFIT_ADMIN]: $.__('Benefits Administration'),
};
