/* @startCleanup encore */
import { createStyles, makeStyles, getTheme } from '@bamboohr/fabric';
export const styles = makeStyles(({ palette, typography }) => {
	const theme = getTheme('lime1');

	return createStyles({
		finishedPage: {
			margin: '0',
			paddingTop: '29px',
			'& h2': {
				fontWeight: typography.fontWeightMedium,
			},
		},
		headerTitle: {
			fontWeight: typography.fontWeightMedium,
			textAlign: 'center',
			fontSize: '32px',
		},
		headerDesc: {
			fontSize: typography.fabricFontSize('biggie'),
			textAlign: 'center',
			color: palette.grey[700],
		},
		headerDivider: {
			marginTop: '45px',
			marginBottom: '35px',
		},
		divider: {
			margin: '35px 0',
			height: '1px',
			borderColor: palette.grey[300],
		},
		membersWrapper: {
			margin: '60px 0',
		},
		members: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
		},
		footer: {
			background: palette.grey[100],
			margin: '0 -170px',
			padding: '45px',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			'& > *': {
				margin: '5px 0',
			},
		},
		bamboohrLogo: {
			fill: theme.palette.primary.lighter,
		},
		footerText: {
			color: palette.grey[800],
			fontSize: typography.fabricFontSize('medium'),
		},
		footerDisclaimer: {
			color: palette.grey[600],
			fontSize: typography.fabricFontSize('small'),
		},
	});
});
/* @endCleanup encore */
