import FeedReducer from '../reducers';
import Immutable, { Map } from 'immutable';
import thunk from 'redux-thunk';
import { RECORD_MAP } from '../constants';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { mapImmutableRecordToChildren, setImmutableTypes } from 'immutable.util';
import { Entity, Entities, State } from '../models';

const middleware = composeWithDevTools({
	serialize: { immutable: Immutable },
})(applyMiddleware(thunk));

/**
 * Convert js object into immutable feed
 * @param  {Object}   data Initial data
 * @return {Map|null}      Immutable data or null, depending on if data was passed
 */
export const immutifyFeed = (data) => {
	let immData = Immutable.fromJS(data, (k, v) => {
		return Immutable.Iterable.isIndexed(v) ? v.toOrderedSet() : v.toMap();
	});

	if (immData !== null && immData?.size > 0) {
		return mapImmutableRecordToChildren(immData.update(((state) => {
			return new State(state.updateIn(['entities'], (entities) => {
				return new Entities(entities.map((entity) => {
					return new Entity(entity.updateIn(['byId'], byId => new Map(byId)));
				}));
			}));
		})), RECORD_MAP);
	}

	return new State();
};

const getCreatedAt = (state, type, id) => (
	state.getIn(['entities', type, 'byId', id, 'createdAt'])
);

export const sortComments = state => (
	state
		.updateIn(['entities', 'comments', 'allIds'], allIds => (
			allIds.sort((a, b) => getCreatedAt(state, 'comments', a) - getCreatedAt(state, 'comments', b))
		))
);

export const sortEvents = state => (
	state
		.updateIn(['entities', 'events', 'allIds'], allIds => (
			allIds.sort((a, b) => getCreatedAt(state, 'events', b) - getCreatedAt(state, 'events', a))
		))
);

export const sortReplies = state => (
	state
		.updateIn(['entities', 'comments', 'byId'], byId => (
			byId.map(comment => (
				comment.update('replies', replies => (
					replies.sort((a, b) => getCreatedAt(state, 'replies', a) - getCreatedAt(state, 'replies', b))
				))
			))
		))
);

/**
 * Create the feed store
 * @param  {Object}      [data=null] Initial data
 * @return {Redux Store}             The Redux store for feeds
 */
const createFeedStore = (data = null) => {
	const stateData = sortEvents(
		sortReplies(
			sortComments(
				immutifyFeed(data)
			)
		)
	);

	if (stateData !== null) {
		return createStore(FeedReducer, stateData, middleware);
	}

	return createStore(FeedReducer, middleware);
};

export default createFeedStore;

