import { ChangeEvent, FocusEvent, Ref } from 'react';

export interface SharedInputProps {
	autoComplete?: string;
	autoFocus?: boolean;
	disabled?: boolean;
	error?: string;
	hasError?: boolean;
	id?: string;
	inputMode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
	inputRef?: Ref<HTMLInputElement>;
	label?: string | JSX.Element;
	name?: string;
	note?: string;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	pattern?: string;
	placeholder?: string;
	required?: boolean;
	tooltip?: string;
	value: string;
	width?: number;
	biId?: string;
}

export interface TextFieldProps extends SharedInputProps {
	maxLength?: number;
}

export type NumberFieldProps = SharedInputProps;

export enum ErrorPosition {
	right = 'right',
	bottom = 'bottom',
}
