import { ACA_TYPES } from './constants';

/* Include an ACA Status in the available options if the status key
 is included in the allowed ACA status list */
export const generateAcaStatusSelectItems = (allowedAcaStatuses) => {
	if (allowedAcaStatuses) {
		return allowedAcaStatuses.reduce((selectItems, statusKey) => {
			if (ACA_TYPES[statusKey]) {
				const acaStatus = ACA_TYPES[statusKey];

				const item = {
					text: acaStatus.label,
					value: statusKey,
				};

				selectItems.push(item);
			}

			return selectItems;
		}, []);
	}
	return [];
};

