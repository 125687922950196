import { isEsig } from './esig';
import { isNil } from 'lodash';

export function allFilesFinalized(files) {
	const incomplete = files.find((file) => {
		return isEsig(file) &&
			file.esignatures.completed &&
			file.required &&
			isNil(file.company_file_data)
	});
	
	return isNil(incomplete);
}
