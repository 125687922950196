import React from 'react';

export function RadioButton(props) {
	const {
		id,
		label,
		onChange,
		selectedValue,
		value,
	} = props;

	return (
		<div className="fab-Radio">
			<input
				checked={ selectedValue === value }
				className="fab-Radio__input"
				id={ id }
				onChange={ onChange }
				type="radio"
				value={ value }
			/>
			<label className="fab-Radio__label" htmlFor={ id }>
				{ label }
			</label>
		</div>
	);
}
