import { Envelope16x12, MobilePhone10x16 } from '@bamboohr/grim';
import {ifFeature} from "@bamboohr/utils/lib/feature";
import {IconV2} from "@bamboohr/fabric";

export const INITIAL_STATE = {
	city: {
		error: false,
		value: ''
	},
	email: {
		value: ''
	},
	id: null,
	isProcessing: false,
	name: {
		error: false,
		value: ''
	},
	phone: {
		error: false,
		value: ''
	},
	state: {
		error: false,
		value: ''
	},
	street1: {
		error: false,
		value: ''
	},
	street2: {
		value: '',
	},
	zip: {
		error: false,
		value: ''
	}
};

export const INPUT_FIELD_MAP = {
	'city': {
		placeholder: $.__('City'),
		size: 'long fab-TextInput--width5'
	},
	'email': {
		icon: ifFeature('encore', <IconV2 color="neutral-weak" name="envelope-solid" size={16} />, <Envelope16x12/>),
		placeholder: $.__('Email'),
		size: 'xlong fab-TextInput--width8'
	},
	'phone': {
		icon: ifFeature('encore', <IconV2 color="neutral-weak" name="mobile-screen-button-solid" size={16} />, <MobilePhone10x16/>),
		placeholder: $.__('Phone'),
		size: 'xlong fab-TextInput--width6'
	},
	'street1': {
		placeholder: $.__('Street 1'),
		size: 'xxlong fab-TextInput--width8'
	},
	'street2': {
		placeholder: $.__('Street 2'),
		size: 'xxlong fab-TextInput--width8'
	},
	'zip': {
		placeholder: $.__('ZIP'),
		size: 'xshort fab-TextInput--width3'
	}
};

export const INVALID_ERROR_MESSAGE = $.__('Whoops... No worries. Please fix any missing or incorrect information and try again.');
export const SAVE_ERROR_MESSAGE = $.__(`Uh oh...something went wrong, and the payee wasn't saved. Try it again?`);
