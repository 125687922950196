import { Button, FullScreenModal, ItemType, SheetModal, StandardModal, TextButton, TextField, useForm } from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import { unescape } from 'lodash';
import { ReactElement } from 'react';
import { addItem } from './service';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface AddItemFieldDefinition {
	value: string;
}

interface AddItemField {
	label: string;
	id: string;
}

interface AddItemModalProps {
	endpointUrl?: string;
	field: AddItemField;
	component: typeof FullScreenModal | typeof SheetModal | typeof StandardModal;
	onCancel: () => void;
	onSave: (item: ItemType) => void;
	open: boolean;
	title: string;
}

export function AddItemModal({
	component: Modal,
	endpointUrl = '/ajax/list_insert.php',
	field,
	onCancel,
	onSave,
	open,
	title
}: AddItemModalProps): ReactElement {
	const {
		controlled,
		formState: { errors },
		fields,
		handleSubmit,
		reset,
	} = useForm<AddItemFieldDefinition>([{ name: 'value' }]);

	const onValid = async (fieldDefinition: AddItemFieldDefinition) => {
		try {
			const { value } = fieldDefinition;
			const name = `field_${field.id}`;

			const id: unknown = await addItem(
				endpointUrl,
				field.id,
				name,
				value,
			);

			onSave({ text: value, value: `${ id }` });
		} catch (e) {
			const message = unescape(e?.data?.message) ||  $.__('Oops! Something went wrong. Please try again later.');
			showSlidedown(message, 'error');
		}
	};

	const onInvalid = () => {
		showSlidedown($.__('Whoops... No worries. Please fix any missing or incorrect information and try again.'), 'error');
	};

	const verticalSpacing = Modal.Constraint.Spacing.LARGE;

	return (
		<Modal isOpen={ open } onCloseComplete={ reset } onRequestClose={ onCancel }>
			<Modal.Body
				renderFooter={
					<Modal.Footer
						actions={
							ifFeature(
								'encore',
								[
									<TextButton key="cancel" onClick={ onCancel }>
										{ $.__('Cancel') }
									</TextButton>,
									<Button key="save" onClick={ handleSubmit(onValid, onInvalid) }>
										{ $.__('Save') }
									</Button>,
								],
								[
									<Button key="save" onClick={ handleSubmit(onValid, onInvalid) }>
										{ $.__('Save') }
									</Button>,
									<TextButton key="cancel" onClick={ onCancel }>
										{ $.__('Cancel') }
									</TextButton>
								]
							)
						}
					/>
				}
				renderHeader={ <Modal.Header title={ title } /> }
			>
				<Modal.Constraint
					spacingOverrides={ { bottom: verticalSpacing, top: verticalSpacing }}
				>
					{
						controlled(f => (
							<TextField
								{ ...f }
								label={ field.label }
								status={ errors.value ? 'error' : undefined }
								width={ 7 }
							/>
						), fields.value)
					}
				</Modal.Constraint>
			</Modal.Body>
		</Modal>
	);
}
