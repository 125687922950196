/**
 * I believe this is only used for execupay
 */

const message = $.__('Value must not start with a 0.');

export const noLeadingZeros = (context: DynamicForm.Context, field: DynamicForm.DataProps, params: any) => {
	return (value: string | number | undefined): boolean | string => {
		const isValid = !value || !value.toString().startsWith('0');
		return isValid || message;
	};
};
