import { Radio, MultiSelect } from './types';


export const Multi = (props) => {
	const {
		name,
		label,
		data,
		validationRegistration,
	} = props;

	if (!data) {
		return null;
	}

	return (
		<fieldset className={ `CandidateField CandidateField--${ name }` }>
			{
				data.options && data.options.length <= 5 ?
					<Radio { ...data } label={ label } name={ name } validationRegistration={ validationRegistration } /> :
					<MultiSelect { ...data } label={ label } name={ name } validationRegistration={ validationRegistration } />
			}
		</fieldset>
	);
};
