/***** Begin Imports *****/

import {extend} from 'lodash';
import standardTemplate from './standard.micro.html';

/***** End Imports *****/


const BASE_DATA = {
	title: null,
	content: null,
	textAlign: 'left',
};


/**
 * Returns the HTML for this template
 *
 * @param data
 * @returns {*|string}
 */
export default function compile(data) {
	if (!data.content) {
		throw new Error('Content is required to make a "standard" tooltip');
	}

	data = extend({}, BASE_DATA, data);

	return window.microTemplate(standardTemplate, data);
}
