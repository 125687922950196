import { FunctionComponent } from 'react';
import classNames from 'classnames';

type Props = {
	labelClasses?: string;
	labelProps?: DynamicForm.CustomElement['settings']['labelProps'];
	inputId?: string;
	label: string;
	info?: boolean;
	required?: boolean;
	error?: boolean;
}

const Label: FunctionComponent<Props> = ({
	labelClasses,
	inputId,
	label,
	labelProps,
	info,
	required,
	error,
	children,
}) => {

	// handle if label is undefined or empty string
	// saves from each use having to do `{ label && <Label ... /> }`
	if (!label || label.length === 0) {
		// if label is an empty string, ignore
		return null;
	}

	const classes = labelClasses || classNames(
		'fab-Label',
		{
			'fab-Label--info': info,
			'fab-Label--required': required,
			'fab-Label--error': error,
		}
	);

	return (
		<label className={ classes } htmlFor={ inputId } { ...labelProps }>{ label }{ children }</label>
	);
};

export default Label;
