/**
 * Trims trailing zeros from a string number
 *
 * @param stringNumber
 * @returns {number}
 */
export function trimTrailingZeroes(stringNumber: string | number): number {
	return Number(String(stringNumber).replace(/0+$/, ''));
}

/**
 * Sets the company's decimal format on a number. Returns a string
 *
 * @param trueNumber
 * @returns {string}
 */
export function applyCompanyDecimal(trueNumber: number): string {
	return String(trueNumber).replace('.', window.GLOBAL_DECIMAL_CHAR);
}

/**
 * Converts an index to a letter and continues on after Z with AA. Must start at 0.
 *
 * @param trueNumber index starting at 0
 * @returns {string}
 */
export function indexToLetters(index: number): string {
	const num = index + 1;
	const mod = num % 26;
	let pow = num / 26 | 0;
	const out = mod ? String.fromCharCode(64 + mod) : (pow--, 'Z');
	return pow ? indexToLetters(pow) + out : out;
}
