import { createPortal } from 'react-dom';
import { Component, Fragment } from 'react';
import SimpleModal from 'SimpleModal.mod';
import { parseResponse } from 'Data.util';

export class Modal extends Component {
	_bodyElm = document.createElement('div');
	_footerElm = document.createElement('div');
	_titleElm = document.createElement('div');
	_modalInstance = null;

	static defaultProps = {
		destroyOnHide: false
	};

	_configureOptions() {
		const {
			options = {},
			onClose,
			onCloseBefore,
			onOpen,
			onOpenBefore,
			onAction,
			handleData,
			footer: customFooter,
			title: customTitle
		} = this.props;

		const footer = customFooter ? {
			show: true,
			custom: {
				html: this._footerElement()
			}
		} : options.footer || {};

		if (
			!customFooter &&
			onAction
		) {
			footer.buttons = footer.buttons || {};
			footer.buttons.primary = footer.buttons.primary || {};
			footer.buttons.primary.action = onAction;
		}

		options.title = customTitle
			? this._titleElement().textContent
			: options.title;

		const config = {
			...options,
			callbacks: {
				close: {
					after: () => {
						if (onClose) {
							onClose();
						}
					},
					before: () => {
						if (onCloseBefore) {
							return onCloseBefore();
						}
						return true;
					}
				},
				open: {
					after: () => {
						SimpleModal.setPosition();
						if (onOpen) {
							onOpen();
						}
					},
					before: () => {
						if (onOpenBefore) {
							return onOpenBefore();
						}
						return true;
					}
				}
			},
			html: this._bodyElement(),
			responseToHTML: (json) => {
				const data = parseResponse(json);
				if (handleData) {
					handleData(data);
					return this._bodyElement();
				}
			},
			footer
		};

		return config;
	}

	_bodyElement = () => {
		/*
			For now this just returns the element that the portal is attached to.
			In the future, this function could do things like add custom classes
			to this wrapper before returning it.
		*/
		this._bodyElm.classList.add('js-react-modal-body-wrapper');
		return this._bodyElm;
	};

	_footerElement = () => {
		/*
			For now this just returns the element that the portal is attached to.
			In the future, this function could do things like add custom classes
			to this wrapper before returning it.
		*/
		this._footerElm.classList.add('js-react-modal-footer-wrapper');
		return this._footerElm;
	}

	_titleElement = () => {
		/*
			For now this just returns the element that the portal is attached to.
			In the future, this function could do things like add custom classes
			to this wrapper before returning it.
		*/
		this._titleElm.classList.add('js-react-modal-title-wrapper');
		return this._titleElm;
	}

	_open() {
		const config = this._configureOptions();
		this._modalInstance = SimpleModal.openModal(config);
	}

	_close() {
		const { selector } = this._modalInstance || {};

		if (
			selector &&
			document.querySelector(selector)
		) {
			SimpleModal.closeModal();
		}
	}

	componentDidMount() {
		const { visible } = this.props;

		if (visible) {
			this._open();
		}
	}

	componentDidUpdate(prevProps) {
		const { visible } = this.props;
		const $modal = SimpleModal.getTopModalSelector();

		if ($modal) {
			SimpleModal.setPosition();
		}

		if (!!visible === !!prevProps.visible) {
			return;
		}

		if (visible) {
			this._open();
		} else if ($modal) {
			this._close();
		}
	}

	componentWillUnmount() {
		this._close();
	}

	render() {
		const {
			children,
			destroyOnHide,
			visible
		} = this.props;

		let portalContent = children;

		if (destroyOnHide) {
			portalContent = visible ? children : null;
		}

		return (
			<Fragment>
				{
					createPortal(
						portalContent,
						this._bodyElement()
					)
				}
				{
					this.props.footer && createPortal(
						this.props.footer(),
						this._footerElement()
					)
				}
				{
					this.props.title && createPortal(
						this.props.title(),
						this._titleElement()
					)
				}
			</Fragment>
		);
	}
}
