import moment from 'moment.lib';
import optionsTemplate from './options.micro.html';

const whichDays = {
	0: $.__("Which Sundays?"),
	1: $.__("Which Mondays?"),
	2: $.__("Which Tuesdays?"),
	3: $.__("Which Wednesdays?"),
	4: $.__("Which Thursdays?"),
	5: $.__("Which Fridays?"),
	6: $.__("Which Saturdays?")
};

const $body = $('body');

$body.on('ba:selectChange', '.js-biweekly-select-day', dayChanged);
$body.on('ba:selectChange', '.js-biweekly-which-week', weekChanged);


function getFutureDay(selectedDay) {
	const today = moment().day();
	return (today > selectedDay) ? (7 + parseInt(selectedDay)) : selectedDay;
}

function setWhichWeeks($row, startMoment) {
	const closestPossibleMoment = moment().day(getFutureDay(startMoment.day()));
	const set = {
		first: closestPossibleMoment.clone(),
		second: closestPossibleMoment.clone().add(1, 'week'),
	};
	const diff = closestPossibleMoment.diff(startMoment, 'week');

	let weekSelection = 1;
	if ((diff === 0 && startMoment.format() === set.second.format()) || diff % 2 === 1) {
		weekSelection = 2;
	}

	// This has to be after the weekSelection is figured out
	const weeksOptions = microTemplate(optionsTemplate, set);

	
	$row.find('.js-biweekly-which-week').replaceWith(weeksOptions);
	$($row.find('.js-biweekly-which-week').children()[weekSelection - 1]).attr('selected', true);
	
}

function setLabel($row, dayOfWeek) {
	$row.find('.js-biweekly-which-days')
		.text(whichDays[dayOfWeek]);
}

function setHiddenValue($row, value) {
	$('.js-biweekly-start-input').val(value);
}

function dayChanged(e) {
	const $select = $(e.target);
	const $row = $select.closest('.fieldRow');
	const newDate = moment().day(getFutureDay(e.originalEvent.detail.value));

	setLabel($row, newDate.day());
	setWhichWeeks($row, newDate);
	setHiddenValue($row, newDate.format());
}

function weekChanged(e) {
	const $select = $(e.target);
	const $row = $select.closest('.fieldRow');
	const dateString = $select.find(':selected').attr('data-biweekly-date');

	setHiddenValue($row, dateString);
}

function setFields() {
	$('.js-biweekly-start-input').each((i, input) => {
		const $input = $(input);
		const $row = $input.closest('.fieldRow');
		let startMoment;

		if ($input.val() === '') {
			const defaultDay = $row.find('.js-biweekly-select-day').val();
			startMoment = moment().day(getFutureDay(defaultDay));
		} else {
			startMoment = moment($input.val(), moment.defaultFormat);
		}

		const dayOfWeek = startMoment.day();

		const currentlySelected = $row.find('.js-biweekly-select-day select').val();
		$($row.find('.js-biweekly-select-day').children()[currentlySelected]).attr('selected', false);
		$($row.find('.js-biweekly-select-day').children()[dayOfWeek]).attr('selected', true);		

		setLabel($row, dayOfWeek);
		setWhichWeeks($row, startMoment);
		setHiddenValue($row, startMoment.format());
	});
}

export default {
	setFields
};
