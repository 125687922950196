import { ifFeature } from '@bamboohr/utils/lib/feature';
import CalendarPicker from 'calendar-picker.react';
import { Icon } from '@bamboohr/fabric';
import { bem } from '../../../../constants';
import './styles.styl';

export const SecurityFieldset = (props) => {

	return (
		<fieldset className={ifFeature('encore', bem.elem('securitySection'), 'fab-FormSection')}>
			<legend className={ifFeature('encore', null, 'fab-FormSection__legend')}>
				{ifFeature('encore', null, (
					<Icon name="fab-link-16x16" brand={true}/>
				))}
				{ifFeature('encore', (
					<h4 className={ bem.elem('legendText') }>{$.__('Link Security Settings')}</h4>
				), (
					<span className="fab-FormSection__legendText">{$.__('Link Security Settings')}</span>
				))}
			</legend>
			<div className={ bem.elem('formRow', ['^fab-FormRow']) }>
				<CalendarPicker
					name="expirationDate"
					type="date"
					cssClasses={{
						single: bem.elem('expirationInput'),
					}}
					settings={{
						min: moment().format(),
						max: moment().add(30, 'days').format(),
						start: moment().add(30, 'days').format(),
						label: 'Link Expiration Date',
					}}
				/>
				<span
					className="fab-FormNote">{$.__('We recommend making this as short as possible (30 day max).')}</span>
			</div>
			<div className={bem.elem('formRow', ['^fab-FormRow'])}>
				<div className="fab-Checkbox">
					<input
						className="fab-Checkbox__input"
						id="singleUse"
						name="singleUse"
						type="checkbox"
					/>
					<label className="fab-Checkbox__label"
						   htmlFor="singleUse">{$.__('Only allow this file to be downloaded once.')}</label>
				</div>
			</div>
		</fieldset>
	);

}
