import dotLoader from './dot';
import spinnerLoader from './spinner';

export default function Loader({ blockName, type }) {
	switch (type) {
		case 'dot':
			return dotLoader(blockName);
		default:
			return spinnerLoader(blockName);
	}
}
