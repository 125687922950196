/**
 * Determines if the name of the given feature is found
 * inside the `data-feature-toggles` attribute of the body tag
 *
 * @param {string} featureName Feature being checked for
 * @return {boolean} Whether the feature is enabled
 */
export function isEnabled(featureName: string): boolean {
	const {
		featureToggles = '',
	} = document.body.dataset;

	return !!(
		featureName &&
		typeof featureName === 'string' &&
		featureToggles.split(/\s+/g).includes(featureName)
	);
}

/**
 * Determines if all the feature names passed are enabled
 * Helpful if there are separate BE and FE toggles and both need to be enabled to work
 *
 * @params {string} one or more feature names
 * @return {boolean} Whether the feature set is enabled (all have to return true)
 */
export function isSetEnabled(...featureNames: string[]): boolean {
	return !!featureNames.length && !featureNames.map(feature => isEnabled(feature)).includes(false);
}

export function encoreEnabled(): boolean {
	return (
		document.getElementsByTagName('body')[0]?.dataset?.fabricTheme === 'encore'
	);
}
