import { ReactElement } from 'react';

import { useStyles } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LayoutBox, Wizard } from '@bamboohr/fabric';

interface Props {
	pageNames: Array<string>;
	currentPage: number;
}

export function HeaderNavigation(props: Props): ReactElement {
	const { pageNames, currentPage } = props;

	/* @startCleanup encore */
	const classes = useStyles();
	const navItems = [];
	pageNames.forEach((pageName, ind) => {
		const element = (
			<li className={`${classes.navItem} ${currentPage === ind ? classes.navItemActive : null}`} key={pageName}>
				<div className={`${classes.navNumber} ${currentPage === ind ? classes.navNumberActive : null}`}>{ind + 1}</div>
				<div className={classes.navName}>{pageName}</div>
			</li>
		);
		if (ind < pageNames.length - 1) {
			navItems.push(element);
		}
	});
	/* @endCleanup encore */

	const wizardSteps = {};

	pageNames.forEach((pageName, ind) => {
		const stepNumber = ind + 1;
		const step = { name: pageName };
		if (!wizardSteps[stepNumber] && ind < pageNames.length - 1) {
			wizardSteps[stepNumber] = step;
		}
	});

	return ifFeature(
		'encore',
		<Wizard currentStep={currentPage + 1} steps={wizardSteps}></Wizard>,
		<div className={`NavigationWrapper ${classes.navWrapper}`}>
			<div className={`NHPStepsContainer ${classes.navContainer}`}>
				<ul className={classes.nav}>
					{navItems}
					<li className={classes.navSpacer} />
				</ul>
			</div>
		</div>
	);
}
