import { useState } from 'react';
import { useValidation } from '@utils/validation';
import { UsageContext, ValidationContext } from './context';
import CandidateForm from './form';
import FooterActions from './form/footer-actions';
import './styles.styl';

const Form = ({ fields, usage, actions, formId }) => {
	const [specialErrors, setSpecialErrors] = useState({});
	const validation = useValidation();
	validation.specialErrors = specialErrors;
	validation.setSpecialErrors = (fieldName, newValue) => {
		setSpecialErrors((state) => {
			const newState = { ...state };
			if (newValue === null) {
				delete newState[fieldName];
			} else {
				newState[fieldName] = newValue;
			}
			return newState;
		});
	};

	return (
		<UsageContext.Provider value={ usage }>
			<ValidationContext.Provider value={ validation }>
				<CandidateForm { ...fields } />		
				<FooterActions actions={ actions } fields={ fields } formId={ formId } />
			</ValidationContext.Provider>
		</UsageContext.Provider>
	);
};

export default Form;
