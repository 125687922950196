export const SSNWarnSyncModal = () => {
	const modalOpen = window.BambooHR.Modal.isOpen();

	let globalModalOptions = {};
	const modalOptions = {
		content: $.__('Payroll will not be able to run for this user unless they have a valid Social Security Number.'),
		primaryAction: () => {
			if (modalOpen) {
				window.BambooHR.Modal.setState({ sheetProps: { isOpen: false } }, true);
			} else {
				window.BambooHR.Modal.setState({ isOpen: false });
			}
		},
		primaryActionText: $.__('OK, Got It'),
		alternativeActionText: '',
		primaryActionKey: 'Enter',
		isOpen: true,
		title: $.__('Just So You Know...'),
	};

	if (modalOpen) {
		globalModalOptions = {
			isOpen: true,
			sheetProps: modalOptions,
		};
	} else {
		globalModalOptions = modalOptions;
	}

	window.BambooHR.Modal.setState(globalModalOptions, modalOpen);
};

export const SSNInvalidSyncModal = (selectedPaySchedule) => {
	const modalOpen = window.BambooHR.Modal.isOpen();

	// modal to tell user to fix SSN first
	let globalModalOptions = {};
	const modalOptions = {
		isOpen: true,
		icon: 'fab-money-circle-54x54',
		iconColor: 'danger',
		content: $.__('Before adding this employee to the \'%1\' pay schedule you need to update the employee\'s Social Security Number.', selectedPaySchedule),
		primaryAction: () => {
			if (modalOpen) {
				window.BambooHR.Modal.setState({ sheetProps: { isOpen: false } }, true);
			} else {
				window.BambooHR.Modal.setState({ isOpen: false });
			}
		},
		primaryActionText: $.__('OK, Got It'),
		alternativeAction: null,
		title: $.__('Oops, That Won\'t Work...'),
	};

	if (modalOpen) {
		globalModalOptions = {
			isOpen: true,
			sheetProps: modalOptions,
		};
	} else {
		globalModalOptions = modalOptions;
	}

	window.BambooHR.Modal.setState(globalModalOptions, modalOpen);
};

export const SocialIdInvalidModal = (title, message, resolve) => {
	const modalOpen = window.BambooHR.Modal.isOpen();

	let globalModalOptions = {};
	const modalOptions = {
		content: message,
		onClose: () => {
			if (resolve && typeof resolve === 'function') {
				resolve(false);
			}
		},
		primaryAction: () => {
			if (resolve && typeof resolve === 'function') {
				resolve(true);
			}
			if (modalOpen) {
				window.BambooHR.Modal.setState({ sheetProps: { isOpen: false } }, true);
			} else {
				window.BambooHR.Modal.setState({ isOpen: false });
			}
		},
		primaryActionText: $.__('Yes'),
		iconColor: 'danger',
		primaryActionKey: 'Enter',
		isOpen: true,
		title,
	};

	if (modalOpen) {
		globalModalOptions = {
			isOpen: true,
			sheetProps: modalOptions,
		};
	} else {
		globalModalOptions = modalOptions;
	}

	window.BambooHR.Modal.setState(globalModalOptions, modalOpen);
};
