import { Icon } from '@bamboohr/fabric';

import c from 'classnames';

import './style.styl';

/**
 * Blank State Component
 * @param {Object} props [description]
 *
 * Expected props:
 *
 * {String} className  'myCustomClass'
 * {String} icon       'fab-airplane-15x15'
 * {String} title      'Ready to go? Just select a job opening to see the goods.'
 * {String} subtitle   'We\'ll show you the history of all the applicants for the job opening. Where they got stuck, where they ended up, all of it.'
 * {JSX}    button     <MyCustomButton />
 */
const BlankState = (props) => {
	const {
		className,
		button,
		icon = 'fab-sasquatch-153x192',
		subtitle,
		title = $.__(`There's nothing to see here...`),
	} = props;

	return (
		<div className={ c('ba-BlankState', className) }>
			<div className="ba-BlankState__icon">
				<Icon name={ icon } />
			</div>
			<h3 className="ba-BlankState__title">
				{ title }
			</h3>

			{ subtitle && (
				<p className="ba-BlankState__subtitle">
					{ subtitle }
				</p>
			) }

			{
				button ? (
					<div className="ba-BlankState__button">
						{ button }
					</div>
				) : null
			}
		</div>
	);
};

export default BlankState;
