/***** Begin Imports *****/

import {extend} from 'lodash';
import {convertObjectIntoInlineStylesString} from '../utils';
import standardTemplate from './standard.micro.html';

/***** End Imports *****/


const BASE_DATA = {
	content: null,
	contentClass: null,
	contentCssOverride: {},
	popoverClass: null,
	title: null,
	titleClass: null,
	titleCssOverride: {},
	rawContent: false
};


/**
 * Returns the HTML for this template
 *
 * @param data
 * @returns {*|string}
 */
export default function compile(data) {
	if (!data.content) {
		throw new Error('Content is required to make a "standard" popover');
	}

	data = extend({}, BASE_DATA, data);
	data.titleCssOverride = convertObjectIntoInlineStylesString(data.titleCssOverride);
	data.contentCssOverride = convertObjectIntoInlineStylesString(data.contentCssOverride);

	return window.microTemplate(standardTemplate, data);
}
