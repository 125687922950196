/**
 * Retrieves json value from an element and parses it
 * Usually utilizes application/JSON
 *
 * @param  {string} selector      The selector that the data is pulled from
 * @return {Boolean|Array|Object} JS from the parsed data
 */
export function getJsonData(selector: string, keepNode = false): boolean | Array<any> | object | null {
	let parsedData = null;

	const dataNode = document.querySelector(selector);

	if (dataNode !== null) {
		try {
			parsedData = JSON.parse(dataNode.textContent);
		} catch (e) {
			console.warn(`What?!!! -> You passed in some bad joojoo. ${e}`);
			return false;
		}

		if (!keepNode) {
			dataNode.parentNode.removeChild(dataNode);
		}
	}

	return parsedData;
}
/**
 * Tries to parse JSON from data, returning original object if invalid JSON
 * @function
 * @param  {any} data Data passed in to attempt JSON parse
 * @return {JSON|any} Parsed JSON or original passed in data
 */
export function parseResponse(data: any): any {
	try {
		return JSON.parse(data);
	} catch (e) {
		return data;
	}
}
