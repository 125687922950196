import './base-input-field.styl';

import { NumberFieldProps, TextFieldProps } from './types';

import { Icon } from '@bamboohr/fabric';
import Tooltip from 'tooltip.react';
import classNames from 'classnames';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface BaseInputFieldProps extends TextFieldProps, NumberFieldProps {
	type?: 'text' | 'number';
}

export const BaseInputField = (props: BaseInputFieldProps): JSX.Element => {
	const {
		autoComplete,
		autoFocus = false,
		disabled = false,
		error,
		hasError = false,
		id,
		inputMode = 'text',
		inputRef,
		label,
		maxLength,
		name,
		note = '',
		onBlur,
		onChange,
		pattern,
		placeholder = '',
		required = false,
		tooltip,
		type = 'text',
		value,
		width = 9,
		biId,
	} = props;

	const widthClass = `fab-TextInput--width${width}`;
	const isErrored = hasError || Boolean(error);
	const isNumberField = type === 'number';

	return (
		<div className='fab-FormColumn'>
			{label && (
				<label
					className={classNames(
						'fab-Label',
						{ 'fab-Label--required': required },
						{ 'fab-Label--error': isErrored },
						ifFeature('encore', { 'fab-marginBottom--4': true }, {})
					)}
					htmlFor={id}
				>
					{label}
					{tooltip && (
						<Tooltip
							settings={{
								position: 'right',
								content: tooltip,
							}}
							wrapperClassName='BaseInputField__tooltipIconWrapper'
						>
							<Icon name='fab-question-14x14' />
						</Tooltip>
					)}
				</label>
			)}
			<input
				ref={inputRef}
				autoComplete={autoComplete}
				autoFocus={autoFocus}
				className={classNames(
					'fab-TextInput',
					widthClass,
					{ 'fab-TextInput--error': isErrored },
					{ NumberField__input: isNumberField }
				)}
				data-bi-id={biId}
				data-lpignore='true' // ignore last pass
				disabled={disabled}
				id={id}
				inputMode={inputMode}
				maxLength={maxLength}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				pattern={pattern}
				placeholder={placeholder}
				required={required}
				type={type}
				value={value}
			/>
			{note && <span className='fab-FormNote'>{note}</span>}
			{error && <span className='fab-FormNote fab-FormNote--error'>{error}</span>}
		</div>
	);
};
