import React from 'react';
import {
	isUndefined,
	kebabCase,
	camelCase,
	isPlainObject,
} from 'lodash';

import * as FeatureToggle from 'FeatureToggle.util';

export const DEFAULT = 'basic';

export * from './legacy'
export * from './jade';

import * as subcomponents from './subcomponents';
export { subcomponents };

const _data: WeakMap = new WeakMap();

/**
 * Retrieves a value from the _data WeakMap
 *
 * @private
 * @param {BaTabsTheme} theme
 * @param {string} name
 * @param {any} [defaultValue]
 * @returns {any}
 */
function _val(theme, name, defaultValue) {
	const data = _data.get(theme);

	if (!name) {
		return data;
	}

	return isUndefined(data[name]) ? defaultValue : data[name];
}

/**
 * Retrieves a subcomponent
 *
 * @private
 * @param {BaTabsTheme} theme
 * @param {string} name
 * @returns {React.Component}
 */
function _subcomp(theme, name) {
	return _val(theme, name, subcomponents[name]);
}

/**
 * BaTabsTheme
 */
export class BaTabsTheme {
	/**
	 * The kebab-cased name
	 */
	get name(): string {
		return _val(this, 'name');
	}

	get variants(): Array {
		return _val(this, 'variants', []);
	}

	/**
	 * The default body
	 */
	get body(): React.Node {
		return _val(this, 'body');
	}

	/**
	 * Whether the caret should be shown
	 *
	 * Can also be a React Node that will
	 * be placed in the tab
	 */
	get caret(): boolean | React.Node {
		return _val(this, 'caret');
	}

	/**
	 * An array of parts to hide
	 */
	get hiddenParts(): Array<string> {
		return _val(this, 'hiddenParts');
	}

	/**
	 * Whether to disable navigation by clicking
	 * on the tabs
	 */
	get disableTabClick(): boolean {
		return _val(this, 'disableTabClick');
	}

	/**
	 * An object to pass as extraClasses
	 * to the BEM utility
	 */
	get extraClasses(): Object {
		return _val(this, 'extraClasses');
	}

	/**
	 * Whether use the overflow dropdown to
	 * collapse tabs on resize
	 */
	get collapseTabs(): boolean {
		return _val(this, 'collapseTabs');
	}

	/**
	 * The text to be displayed in the
	 * overflow dropdown
	 */
	get overflowText(): string {
		return _val(this, 'overflowText');
	}

	/**
	 * The default load strategy
	 */
	get load(): string {
		return _val(this, 'load');
	}

	/**
	 * @constructor
	 * @param data
	 */
	constructor(data: Object): void {
		_data.set(this, {
			...data,
			name: kebabCase(data.name),
			hiddenParts: (data.hiddenParts || []).reduce((obj, part) => {
				obj[part] = true;
				return obj;
			}, {}),
			load: camelCase(data.load || null) || 'active',
			overflowText: data.overflowText || $.__('More'),
			collapseTabs: !!data.collapseTabs,
			extraClasses: isPlainObject(data.extraClasses) ? data.extraClasses : {},
			disableTabClick: !!data.disableTabClick,
		});

		Object.defineProperties(this,
			Object.keys(subcomponents)
				.reduce((props, key) => ({
					...props,
					[key]: {
						get: () => _subcomp(this, key),
					},
				}), {})
		);
	}
}
