import {
	ChangeEvent,
	Ref,
} from 'react';
import classNames from 'classnames';

interface TextAreaFieldProps {
	disabled?: boolean;
	error?: string;
	hasError?: boolean;
	id: string;
	inputRef?: Ref<HTMLTextAreaElement>;
	label: string;
	maxLength?: number;
	name?: string;
	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	placeholder?: string;
	required?: boolean;
	value: string;
	width?: number;
	biId?: string;
}

export const TextAreaField = (props: TextAreaFieldProps): JSX.Element => {
	const {
		disabled = false,
		error,
		hasError = false,
		id,
		inputRef,
		label,
		maxLength,
		name,
		onChange,
		placeholder = '',
		required = false,
		value,
		width = 9,
		biId,
	} = props;

	const widthClass = `fab-Textarea--width${ width }`;
	const isErrored = hasError || Boolean(error);

	return (
		<div className="fab-FormColumn">
			{ label && (
				<label
					className={ classNames(
						'fab-Label',
						{ 'fab-Label--required': required },
						{ 'fab-Label--error': isErrored }
					) }
					htmlFor={ id }
				>
					{ label }
				</label>
			) }
			<textarea
				ref={ inputRef }
				data-bi-id={ biId }
				disabled={ disabled }
				className={ classNames(
					'fab-Textarea',
					widthClass,
					{ 'fab-Textarea--error': isErrored }
				) }
				id={ id }
				maxLength={ maxLength }
				name={ name }
				onChange={ onChange }
				placeholder={ placeholder }
				required={ required }
				value={ value }
			/>
			{ error && (
				<span className="fab-FormNote fab-FormNote--error">
					{ error }
				</span>
			) }
		</div>
	);
};
