import { render } from 'base/wrapped-render';

import ForgotClockOutWarning from './forgot-clock-out-warning.react';
import './styles.styl';

export default function (clockOutAction, employeeId, beforeClockOutAction) {
	return new Promise((resolve) => {
		const forgotClockOutWarningDiv = document.createElement('div');
		render(<ForgotClockOutWarning afterClockOut={ resolve } beforeClockOutAction={ beforeClockOutAction } clockOutAction={ clockOutAction } employeeId={ employeeId }/>, forgotClockOutWarningDiv);
	});
}
