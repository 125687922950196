import { FunctionComponent, useEffect } from 'react';
import classnames from 'classnames';
import { hasTrax } from 'BambooHR.util';
import { getField, hasErrors, newEmployeeForm, filterFieldsMemoized } from 'dynamic-form';
import './field-group-one-email-required.styl';

const validationName = 'oneEmailRequiredGroup';

const OneEmailRequiredFieldGroup: FunctionComponent<DynamicForm.FieldGroupElementProps> = ({ props, children, context }) => {
	const { className = '' } = props;
	const {
		validation: { register, setValue, formState: { errors }, getValues, clearErrors },
		controls: { Label },
	} = context;

	const employeeAccessField = getField(context, newEmployeeForm.employeeAccess);
	const emailFields = filterFieldsMemoized(context, { selector: 'props.className', value: 'oneEmailRequired' });
	const emailKeys = Object.keys(emailFields);
	const shouldBeRequired = employeeAccessField?.props?.value === 'enabled' || hasTrax();
	const hasBothEmails = emailKeys.length == 2;
	const isAlreadyRequired = emailKeys.some(key => emailFields[key]?.settings?.validation?.requiredWhenVisible);
	const inputValues = emailKeys.map(key => emailFields[key]?.props?.value || '').join('');
	const hasError = hasErrors(errors, validationName);
	const isRequired = shouldBeRequired && hasBothEmails && !isAlreadyRequired;

	register(validationName, { required: isRequired });

	useEffect(() => {
		setValue(validationName, '');
	}, []);

	useEffect(() => {
		if (!isRequired && hasError) {
			clearErrors(validationName);
		}
	}, [isRequired]);

	useEffect(() => {
		if (getValues(validationName) !== inputValues) {
			setValue(validationName, inputValues, {
				shouldValidate: true,
			});
		}
	}, [inputValues]);

	const classes = classnames('fieldGroup', className, {
		'DynamicForm__OneEmailRequired--error': hasError,
		'DynamicForm__OneEmailRequired--required': isRequired,
	});

	return (
		<div { ...props } className={ classes }>
			{ isRequired && (
				<Label error={ hasError } label={ $.__('Email') } required={ isRequired }>
					<span>&nbsp;{ $.__('(One Email Required)') }</span>
				</Label>
			) }
			<div className="DynamicForm__OneEmailRequired__wrapper">
				{ children }
			</div>
		</div>
	);
};

export default OneEmailRequiredFieldGroup;
