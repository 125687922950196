import React, {
	useState,
} from 'react';

import { ControlledCurrencyPickerMenu } from './controlled-currency-picker-menu';
import { AmountInput } from './amount-input';
import { CurrencyPickerWrapper } from './currency-picker-wrapper';

/**
 * This is an uncontrolled currency picker field, meaning it manages the state of its inputs
 * If you need to manage the state of any inputs, use the building blocks from the controlled directory
 *
 * @param {object} props.defaultValues
 * @param {string} props.defaultValues.currencyCode
 * @param {string} props.defaultValues.currencySymbol
 * @param {string} [props.defaultValues.amount] // Optional default value
 * @param {array} props.currencyCollection
 * @param {object} props.inputNames
 * @param {string} props.inputNames.amount // The name for the amount field
 * @param {string} props.inputNames.currency // The name for the hidden currency field
 * @param {string} [props.note] // Optinoal note to append to the end of the currency picker field
 */
export function CurrencyPickerField(props) {
	const {
		currencyCollection,
		inputNames,
		note,
		defaultValues,
	} = props;
	const [selectedCurrencyItem, setSelectedCurrencyItem] = useState({
		code: defaultValues.currencyCode || '',
		symbol: defaultValues.currencySymbol || '',
		symbolPosition: 0,
	});

	const handleCurrencyCodeSelect = (selectedItem) => {
		const newItem = currencyCollection.find(item => item.code === selectedItem.value);
		if (newItem) {
			setSelectedCurrencyItem(newItem);
		}
	};

	return (
		<CurrencyPickerWrapper
			note={ note }
		>
			<AmountInput
				defaultValue={ defaultValues.amount }
				name={ inputNames.amount }
				selectedCurrencyItem={ selectedCurrencyItem }
			/>
			<ControlledCurrencyPickerMenu
				currencyCollection={ currencyCollection }
				name={ inputNames.currency }
				onCurrencyCodeSelect={ handleCurrencyCodeSelect }
				value={ selectedCurrencyItem.code }
			/>
		</CurrencyPickerWrapper>

	);
}
