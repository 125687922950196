import { CancellationContentProps } from '../../types';
import { FEDERAL_ID_OPTIONS } from '../payroll-cancellation-form-step-1/constants';
import { FormA, FormB } from './components';

export const PayrollCancellationFormStep2 = ({ formValues: currentFormState, ...rest }: CancellationContentProps): JSX.Element => {

	if (currentFormState?.payroll_data?.use_federal_id_after_final_date === FEDERAL_ID_OPTIONS[0].value) {
		return <FormA {...rest} />;
	}

	return <FormB {...rest} />;
}
