import {
	TextField,
} from '@bamboohr/fabric';
import {
	ifFeature,
} from '@utils/feature';
import {
	Component,
	Fragment,
} from 'react';

import { OptionalFieldHelp } from '../optional-field-help.react';
import { numberWithinRange } from '../../utils';

import './optional-text-input.styl';

export class OptionalTextInput extends Component {
	state = {
		value: this.props.data.amount
	};

	render() {
		const {
			id,
			data,
			viewOnly,
			help,
			isUnemploymentField,
		} = this.props;

		const labelClass = 'fab-Label';
		const inputClass = `fab-TextInput fab-TextInput--teenie`;
		const namePrefix = isUnemploymentField ? 'uiState' : 'state';

		return (
			<div className="fieldRow">
				<div className="fieldBox">
					{ ifFeature('encore',
						<TextField
							disabled={ viewOnly }
							id={ `textInput${ id }` }
							label={ data.label }
							name={ `${ namePrefix }[optional][${ id }]` }
							note={ <OptionalFieldHelp helpData={ help } /> }
							onChange={ this._handleInputChange }
							value={ this.state.value }
						/>
						,
						<Fragment>
							<label className={ labelClass } htmlFor={ `textInput${ id }` }>{ data.label }</label>
							<div className="fieldDiv">
								<div className="OptionalTextInput__box">
									<input
										type="text"
										id={ `textInput${ id }` }
										name={ `${ namePrefix }[optional][${ id }]` }
										className={ inputClass }
										value={ this.state.value }
										onChange={ this._handleInputChange }
										disabled={ viewOnly }
									/>
								</div>
								<div className="OptionalTextInput__help">
									<OptionalFieldHelp helpData={ help } />
								</div>
							</div>
						</Fragment>
					) }
				</div>
			</div>
		);
	}

	_handleInputChange = (event) => {
		const { range } = this.props.data;

		if (range && numberWithinRange(event.target.value, range.min, range.max)) {
			this.setState({
				value: event.target.value
			});
		} else if (!range) {
			this.setState({
				value: event.target.value
			});
		}
	};

}
