import {
	FormNote,
	Label,
	LayoutBox,
	Select,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import {
	ifFeature,
} from '@utils/feature';
/* @endCleanup encore */
import {
	Component,
	createRef,
	Fragment,
} from 'react';

/* @startCleanup encore */
import OldSelect from 'select.react';
/* @endCleanup encore */
import { OptionalFieldHelp } from '../optional-field-help.react';
import './optional-select.styl';

export class OptionalSelect extends Component {
	constructor(props) {
		super(props);
		this._helperTextRef = createRef();
		const selectedOption = props.data.options.find(option => option.selected === true);

		this.state = {
			selectedValue: selectedOption ? selectedOption.val : '',
		};
	}

	render() {
		const {
			id,
			data,
			help,
			isUnemploymentField,
			viewOnly,
		} = this.props;
		let {
			selectedValue,
		} = this.state;

		const namePrefix = isUnemploymentField ? 'uiState' : 'state';

		if (!selectedValue) {
			selectedValue = data.options[0].val;
		}

		return (
			<div className="fieldRow">
				<div className="fieldBox">
					{ ifFeature('encore',
						<Fragment>
							<Label htmlFor={ `${ namePrefix }[optional][${ id }]` }>{ data.label }</Label>
							<LayoutBox
								alignItems="center"
								display="flex"
								gap={ 1 }
								name={ `${ namePrefix }[optional][${ id }]` }
								ref={ this._helperTextRef }
							>
								<Select
									isDisabled={ viewOnly }
									items={ this._getSelectItems() }
									name={ `${ namePrefix }[optional][${ id }]` }
									notClearable={ true }
									onChange={ this._handleSelectChange }
									selectedValues={ [selectedValue] }
									width={ 5 }
								/>
								<FormNote
									ref={ this._helperTextRef.current }
								>
									<OptionalFieldHelp helpData={ help } />
								</FormNote>
							</LayoutBox>
						</Fragment>
						,
						<Fragment>
							<label className="fab-Label fab-Label--required" htmlFor={ `select${ id }` }>{ data.label }</label>
							<div className="fieldDiv">
								<OldSelect id={ `select${ id }` } { ...this._getLegacySelectData() } />

								<div className="OptionalSelect__help">
									<OptionalFieldHelp helpData={ help } />
								</div>
							</div>
						</Fragment>
					) }
				</div>
			</div>
		);
	}

	/* @startCleanup encore */
	_getLegacySelectData = () => {
		const {
			data,
			id,
			viewOnly,
			isUnemploymentField,
		} = this.props;
		const {
			selectedValue,
		} = this.state;
		const namePrefix = isUnemploymentField ? 'uiState' : 'state';

		const options = data.options.map((option) => {
			const selectOption = {};
			selectOption.displayText = option.displayText;
			selectOption.selected = option.val === selectedValue;
			selectOption.value = option.val;
			return selectOption;
		});

		// Optional fields are always required, so we will select the first item for them
		if (!selectedValue) {
			options[0].selected = true;
		}

		return {
			data: {
				items: options,
			},
			settings: {
				name: `${ namePrefix }[optional][${ id }]`,
				disabled: viewOnly,
				notClearable: true,
			},
			disabled: viewOnly,
			width: 5,
			onChange: this._handleLegacySelectChange,
			className: 'OptionalSelect xlong',
		};
	};
	/* @endCleanup encore */

	_getSelectItems = () => {
		const {
			data,
		} = this.props;

		const options = data.options.map((option) => {
			return {
				text: option.displayText,
				value: option.val,
			};
		});

		return options;
	};

	/* @startCleanup encore */
	_handleLegacySelectChange = event => this.setState({
		selectedValue: event ? event.value : null,
	});
	/* @endCleanup encore */

	_handleSelectChange = (selectedValues) => {
		const [selectedValue = null] = selectedValues;
		this.setState({
			selectedValue: selectedValue,
		});
	};

}
