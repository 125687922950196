import pathToRegexp from 'path-to-regexp';
import {
	camelCase,
	each,
	isString,
	isUndefined,
	noop,
} from 'lodash';

import {
	createPrivateContext,
} from '@utils/private-context';

import {
	isEnabled,
} from 'FeatureToggle.util';
import {
	queryStringToObject,
	getJsonScriptVar as _getJsonScriptVar,
} from 'BambooHR.util';

import {
	setupEvents,
	removeEvents,
} from './events';

export * from './events';


/** @type {{ [name: string]: Class }} */
const controllers = {};

/** @type {{ [name: string]: InstanceType<Class> }} */
const activeInstances = {};

/** @type {WeakMap<Class, InstanceType<Class>>} */
const _activeInstance = new WeakMap();

/** @type {(ctrl: Class) => { [key: string]: any }} */
const _ = createPrivateContext();


/**
 * ## `@Ctrl` decorator
 *
 * @param {string | string[]} pathname A path or array of paths to match
 * @param {{}} [qs] An object of key/value query string parameters to match
 *
 * @description
 *
 * The `Ctrl` decorator allows us to create very simple
 * controllers for our front-end JS code. By providing
 * a simple pathname to the decorator, we can auto-instantiate
 * our controller when `window.location.pathname` starts with
 * the provided pathname. This means that controllers will
 * "cascade" down from features into pages, etc.
 *
 * In the following code example, the `SomePageCtrl` class will
 * only be instantiated if `window.location.pathname`
 * begins with `"/someFeature/somePage.php"`, but `SomeFeatureCtrl`
 * will be instantiated for any and all pathnames that begin with
 * `"/someFeature"`, including `"/someFeature/somePage.php"`.
 *
 * ```js
 * @Ctrl('/someFeature/somePage.php')
 * class SomePageCtrl {
 *     constructor() {
 *         console.log('this is somePage.php');
 *     }
 * }
 *
 * @Ctrl('/someFeature/**')
 * class SomeFeatureCtrl {
 *     constructor() {
 *         console.log('this is someFeature');
 *     }
 * }
 * ```
 *
 * The `@Ctrl` decorator also accepts a second, optional argument
 * to match query string values:
 *
 * ```js
 * @Ctrl('/someFeature', { subPage: 2 })
 * class SomePage2Ctrl {
 *     constructor() {
 *         console.log('this will match "/someFeature?subPage=2"');
 *     }
 * }
 * ```
 *
 * The controller will only be instantiated when the URI changes,
 * whether that be on page load, or with `window.history.pushState(...)`.
 *
 * Calling `Ctrl.refresh()` will *NOT* re-instantiate the controller,
 * and the constructor will *NOT* be called again.
 *
 * For this, you can add a `setup` and `teardown` static method to
 * your controller class. `setup` will be called on page load and
 * `teardown` will be called whenever you navigate _away_ from a route.
 *
 * `Ctrl.refresh()` will call `teardown` and then _immediately_
 * call `setup`.
 */
function Ctrl(pathname, qs = {}) {
	var eventNS = camelCase(pathname);

	/**
	 * @param {T} ctrl
	 * @template {Class} T
	 */
	const decorate = (ctrl) => {
		if (!ctrl.hasOwnProperty('ID')) {
			Object.defineProperty(ctrl, 'ID', {
				value: ctrl.className.replace(/Ctrl$/, ''),
			});
		}

		if (!ctrl.hasOwnProperty('getJsonScriptVar')) {
			/**
			 * Retrieves data from a JSON script in the DOM
			 *
			 * @param {string} [varName='data']
			 * @param {string} [elemId=ctrl.ID]
			 */
			const getJsonScriptVar = (varName = 'data', elemId = ctrl.ID) => {
				const data = _getJsonScriptVar(varName, elemId);

				if (data && data !== _(ctrl)[varName]) {
					_(ctrl)[varName] = data;
				}

				return _(ctrl)[varName];
			};

			Object.defineProperty(ctrl, 'getJsonScriptVar', {
				value: getJsonScriptVar,
			});
		}

		/**
		 * @param {Event} [e]
		 */
		function instantiate(e) {
			/** @type {(import('path-to-regexp').Key)[]} */
			var keys = [];
			var match = pathToRegexp(pathname, keys)
				.exec(window.location.pathname);
			var currentQs = queryStringToObject();
			var name = ctrl.className;

			var matchesQs = (function() {
				var ret = true;
				each(qs, (val, key) => {
					ret = ret && currentQs[key] == val;
				});
				return ret;
			})();

			var controller;

			if (
				match &&
				matchesQs
			) {
				const _params = keys.reduce((params, { name }, i) => ({
					...params,
					[name]: match[i + 1],
				}), {});

				if (isUndefined(activeInstances[name])) {
					Object.assign(ctrl.prototype, {
						_params,
					});

					if (!ctrl.prototype.hasOwnProperty('_data')) {
						Object.defineProperty(ctrl.prototype, '_data', {
							get: () => ctrl.getJsonScriptVar(),
						});
					}

					if (!ctrl.prototype.hasOwnProperty('_wrapper')) {
						Object.defineProperty(ctrl.prototype, '_wrapper', {
							get: () => document.getElementById(ctrl.ID),
						});
					}

					controller = new ctrl();

					controllers[name] = ctrl;
					ctrl.active = true;
					activeInstances[name] = controller;
					_activeInstance.set(ctrl, controller);

					setupEvents.call(controller, eventNS);
					(ctrl.setup || noop)(controller);
				} else {
					controller = activeInstances[name];

					Object.assign(controller, { _params });

					if (e && e.type == 'refreshState') {
						(ctrl.teardown || noop)(controller);
						(ctrl.setup || noop)(controller);
					}
				}
			} else if (ctrl.active) {
				(ctrl.teardown || noop)(activeInstances[name]);
				ctrl.active = false;
				delete activeInstances[name];
				removeEvents.call(ctrl, eventNS);
			}
		}

		$(window).on('pushState replaceState popState refreshState', instantiate);

		instantiate();
	};

	return decorate;
}

/**
 * @param {string | Class} ctrl
 * @returns {InstanceType<Class>}
 */
Ctrl.getCtrl = function getCtrl(ctrl) {
	if (isString(ctrl)) {
		return activeInstances[ctrl];
	}

	if (!ctrl) {
		return;
	}

	return _activeInstance.get(ctrl);
};


Ctrl.refresh = function refresh() {
	triggerStateEvent('refresh');
};

/**
 * @param {string} key
 * @param {boolean} enabled
 * @param {Parameters<typeof Ctrl>} args
 * @returns {ReturnType<typeof Ctrl>}
 */
Ctrl.Feature = function Feature(key, enabled, ...args) {
	if (
		(enabled && !isEnabled(key)) ||
		(!enabled && isEnabled(key))
	) {
		return noop;
	}

	return Ctrl(...args);
};

/**
 * @param {string} key
 * @param {Parameters<typeof Ctrl>} args
 */
Ctrl.FeatureOn = (key, ...args) => Ctrl.Feature(key, true, ...args);

/**
 * @param {string} key
 * @param {Parameters<typeof Ctrl>} args
 */
Ctrl.FeatureOff = (key, ...args) => Ctrl.Feature(key, false, ...args);

/**
 * @param {Parameters<typeof Ctrl>} args
 */
Ctrl.Jade = (...args) => Ctrl.FeatureOn('jade', ...args);

/**
 * @param {Parameters<typeof Ctrl>} args
 */
Ctrl.Legacy = (...args) => Ctrl.FeatureOff('jade', ...args);

export default Object.freeze(Ctrl);

export const JadeCtrl = Ctrl.Jade;
export const LegacyCtrl = Ctrl.Legacy;

function getUri() {
	return window.location.pathname + window.location.search;
}

var oldUri = getUri();

/**
 * @param {'push'|'replace'|'pop'|'refresh'} type
 * @param {Partial<Parameters<typeof window.history.pushState>>} args
 */
function triggerStateEvent(type, ...args) {
	const [data, title] = args;
	const newUri = getUri();

	if (oldUri !== newUri || type == 'refresh') {
		$(window).trigger(`${ type }State`, [oldUri, newUri, data, title]);
		oldUri = newUri;
	}
}

var _pushState = window.history.pushState;
/**
 * @param {Parameters<typeof window.history.pushState>} args
 */
window.history.pushState = function pushState(...args) {
	_pushState.call(window.history, ...args);

	triggerStateEvent('push', ...args);
};

var _replaceState = window.history.replaceState;
/**
 * @param {Parameters<typeof window.history.replaceState>} args
 */
window.history.replaceState = function replaceState(...args) {
	_replaceState.call(window.history, ...args);

	triggerStateEvent('replace', ...args);
};

$(window).on('popstate', function(e) {
	triggerStateEvent('pop', e.state, document.title);
});

/**
 * @typedef {{ new(): any }} Class
 */
