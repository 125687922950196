import classNames from 'classnames';
import { camelCase, capitalize } from 'lodash';
import TextareaAutosize from 'react-autosize-textarea';

export const TextArea = (props) => {
	const {
		errorState,
		label,
		name,
		data,
		placeholder,
		typeProps = {},
		validationRegistration,
	} = props;

	if (!data) {
		return null;
	}

	return (
		<div className={ `fab-FormColumn CandidateField CandidateField--${ name }` }>
			<label
				className={ `fab-Label ${ data.isRequired ? 'fab-Label--required' : '' } ${ errorState ? 'fab-Label--error' : '' }` }
				htmlFor={ name }
			>
				{ label }
			</label>
			<div
				className={ classNames(`fab-InputWrapper CandidateField__inputWrapper CandidateField__inputWrapper--${ name }`, {
					[`CandidateField__inputWrapper--cq${ capitalize(camelCase(typeProps.cqType)) }`]: typeProps.cqType,
				}) }
			>				<TextareaAutosize
					className={ `fab-Textarea fab-Textarea--width100 autosize ${ errorState ? 'fab-Textarea--error' : '' }` }
					defaultValue={ data.value }
					disabled={ Boolean(data.isDisabled) }
					id={ name }
					innerRef={ validationRegistration }
					name={ name }
					placeholder={ placeholder }
					required={ data.isRequired }
				/>
			</div>
		</div>
	);
};
