import Ajax from '@utils/ajax';
import { AxiosPromise } from 'axios';

import { MfaSettingsData } from './types';

export const ENDPOINT = '/multi_factor_authentication';

export function doGetUserMfaData(id = window.SESSION_USER?.id) {
	return Ajax.get(`${ENDPOINT}/${id}`);
}

export function doPostMfaSettings(data: MfaSettingsData): AxiosPromise {
	return Ajax.post(ENDPOINT, data);
}

export function doUpdateMfaSettings(data: MfaSettingsData): AxiosPromise {
	return Ajax.put(ENDPOINT, data);
}

export function doDisableMFA() {
	return Ajax.delete(ENDPOINT);
}

export function doGetEmployeesList() {
	return Ajax.get(`${ENDPOINT}/users`);
}

export function doResetEmployeeMFALogin(userId) {
	return Ajax.delete(`${ENDPOINT}/${userId}`);
}

export function doGetNewBackupCodes(id) {
	return Ajax.post(`${ENDPOINT}/${id}/backup_codes`);
}

export function validateRememberToken(userId, rememberToken) {
	return Ajax.request({
		headers: {
			rememberToken: `Bearer ${rememberToken}`,
		},
		method: 'post',
		url: `${ENDPOINT}/${userId}/validate_remember_token`,
	});
}

export function getAccountConfiguration(): AxiosPromise {
	return Ajax.get('/multi_factor_authentication/account_configuration');
}
