/* @startCleanup encore */
import { classNameFromObject } from '@utils/dom';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */
import {
	BodyText,
	Flex,
	Headline,
	/* @startCleanup encore */
	Icon,
	/* @endCleanup encore */
	LayoutBox,
} from '@bamboohr/fabric';
import DailyGraph from './daily-graph.react';

export default function SummaryWeek(props) {
	const {
		/* @startCleanup encore */
		fadeGraph,
		fadeWeekTotal,
		/* @endCleanup encore */
		hideWeekends,
		/* @startCleanup encore */
		lightTheme,
		/* @endCleanup encore */
		lastWeekDays,
		thisWeekDays,
		serverTime,
		weekTitleText,
		weekRangeText,
		weekTotal,
	} = props;

	/* @startCleanup encore */
	const dailyGraphClasses = classNameFromObject({
		'js-timesheet-showDailyGraph TimesheetSummary__dailyGraph': true,
		'TimesheetSummary__dailyGraph--fade': fadeGraph,
	});
	const weekTotalClasses = classNameFromObject({
		'TimesheetSummary__weekTotal baseColor': true,
		'TimesheetSummary__weekTotal--fade': fadeWeekTotal,
		'TimesheetSummary__weekTotal--light': lightTheme,
	});
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<Flex flexDirection='column' gap={0.75} textAlign='center'>
			<BodyText size='large' justify='center' weight='bold'>
				{weekTitleText}
			</BodyText>
			<LayoutBox marginBottom={0.75}>
				<Headline color='primary' size='medium'>
					{weekTotal}
				</Headline>
			</LayoutBox>
			<LayoutBox marginBottom={2.25}>
				<BodyText justify='center' color='neutral-strong' size='small'>
					{weekRangeText}
				</BodyText>
			</LayoutBox>
			<div className={dailyGraphClasses}>
				<DailyGraph lastWeek={lastWeekDays} thisWeek={thisWeekDays} serverTime={serverTime} hideWeekends={hideWeekends} />
			</div>
		</Flex>,
		<div className='TimesheetSummary__thisWeek'>
			<div className='TimesheetSummary__title TimesheetSummary__title--week'>{weekTitleText}</div>
			<div className='TimesheetSummary__text'>{weekRangeText}</div>
			<div className={dailyGraphClasses}>
				<DailyGraph lastWeek={lastWeekDays} thisWeek={thisWeekDays} serverTime={serverTime} hideWeekends={hideWeekends} />
			</div>
			<div className={weekTotalClasses}>
				<span className='TimesheetSummary__weekClockIcon'>
					<Icon brand='true' name='fab-clock-20x20' />
				</span>
				{weekTotal}
			</div>
		</div>
	);
}
