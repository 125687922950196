import Transition from 'react-transition-group/Transition';
import { Component } from 'react';

const duration = 1000;

const defaultStyle = {
	transition: `opacity ${ duration }ms linear`,
	opacity: 1,
}

const transitionStyles = {
	exiting: { opacity: 0 },
};

export const ProgressBar = ({ percent }) => (
	<Transition
		in={percent < 100}
		timeout={duration}
		unmountOnExit
	>
		{state => (
			<div
				className="FileListItem__loader"
				style={{
					...defaultStyle,
					...transitionStyles[state],
				}}
			>
				<div className="ProgressBar ProgressBar--feeds">
					<div
						className="ProgressBar__bar baseColorBg"
						style={{ width: `${ percent }%` }}
					>
					</div>
				</div>
			</div>
		)}
	</Transition>
);

