import { Component, createRef } from 'react';
import moment from 'moment';

import { classNameFromObject as co } from '@utils/dom';
import { isEnabled } from 'FeatureToggle.util';
/* @startCleanup encore */
import { Modal } from 'modal-legacy';
import { TextButton as TextButtonLegacy } from '@fabric/button';
import { Select as SelectLegacy } from '@fabric/select';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import './styles.styl';
/* @endCleanup encore */
import { BadgeV2, BodyText, Button, Flex, Icon, LayoutBox, NameValuePair, SelectField, StandardModal, TextArea, TextButton, TextField } from '@bamboohr/fabric';
import {
	formatTime,
	getTimeDuration,
	timeOfDayInputEnforcer,
	convertNormalizedProjectsToDropdownItems,
	getProjectDataFromId,
	getProjectSelectToggleText
} from 'time-tracking/utils';
import * as TimeTrackingService from 'time-tracking/http';
import { Map } from 'map.react';

import TimeSpinner from 'time-tracking/components/time-spinner.react';

const geolocationEnabled = isEnabled('timeTrackingGeolocation');

export default class AddNote extends Component {
	constructor(props) {
		super(props);

		const {
			clock,
			/* @startCleanup encore */
			hours,
			/* @endCleanup encore */
			note,
			today,
			timezone,
			projectData,
		} = props;

		let startTimeMeridiem = null;
		let startTime = null;
		let noteText = '';
		let workedTime = null;

		if (clock && clock.latest) {
			startTimeMeridiem = today.format('A');
			startTime = today.format('h:mm');
			workedTime = getTimeDuration(today, moment().tz(timezone), 'hours');
		}

		if (note) {
			noteText = note;
		}

		this.state = {
			noteText: noteText,
			startTimeMeridiem: startTimeMeridiem,
			startTime: startTime,
			workedTime: workedTime,
			isModalOpen: false,
			isModalProcessing: false,
			projectData,
			showMap: false
		};

		this.timeInputRef = createRef();
	}

	_handleTimeInput = (event) => {
		const { startTimeMeridiem, startTime } = this.state;
		const value = event.target.value;
		this.timeInputRef.current.value = timeOfDayInputEnforcer(value, startTime);
		let workedTime = this._getWorkedTime(this.timeInputRef.current.value, startTimeMeridiem);
		if (workedTime < 0) {
			workedTime = 0.0;
		}

		this.setState({
			startTime: this.timeInputRef.current.value,
			workedTime
		});
	}

	_handleNoteInput = (event) => {
		const value = event.target.value;
		this.setState({
			noteText: value
		});
	}

	_handleProjectChange = (value) => {
		const { projectsWithTasks } = this.props;
		const projectData = getProjectDataFromId(value, projectsWithTasks);

		this.setState({
			projectData
		});
	}

	_getModalData = () => {
		const { noteText, startTimeMeridiem, startTime } = this.state;
		const { clock, today, timezone } = this.props;
		const todayDate = today.format('YYYY-MM-DD');
		const format = 'YYYY-MM-DD h:mm A';
		const selectedTime = moment.tz(`${ todayDate } ${ startTime } ${ startTimeMeridiem }`, format, timezone);
		let timeWasChanged = false;

		if (clock) {
			const originalTime = moment(clock.latest.start).tz(timezone);
			timeWasChanged = !originalTime.isSame(selectedTime);
		}

		const modalData = {
			note: noteText,
			selectedTime,
			timeWasChanged,
			timezone
		};

		return modalData;
	}

	_getWorkedTime = (time, meridiem) => {
		const { today, timezone } = this.props;
		const todayDate = today.format('YYYY-MM-DD');
		const format = 'YYYY-MM-DD h:mm A';
		const selectedTime = moment.tz(`${ todayDate } ${ time } ${ meridiem }`, format, timezone);
		return getTimeDuration(selectedTime, moment().tz(timezone), 'hours');
	}

	_saveNote = () => {
		const { projectData: { projectId, taskId } } = this.state;
		const { afterSave, canEdit, employeeId, totalTime, type } = this.props;
		const noteData = this._getModalData();
		this.setState({ isModalProcessing: true });

		if (type === 'clock') {
			const postData = {
				note: noteData.note,
				projectId,
				taskId
			};

			if (noteData.timeWasChanged && canEdit) {
				postData.start = noteData.selectedTime.format('HH:mm');
				postData.timezone = noteData.timezone;
			}

			TimeTrackingService.saveClockInData(employeeId, postData)
				.then(() => {
					this.setState({ isModalOpen: false });
					afterSave();
				})
				.catch(() => {
					this.setState({ isModalProcessing: false });
				});
		} else if (type === 'single') {
			const postData = {
				employeeId,
				hours: totalTime,
				date: noteData.selectedTime.format('YYYY-MM-DD'),
				note: noteData.note,
				projectId,
				taskId
			};
			TimeTrackingService.saveSingleDailyHours([postData])
				.then(() => {
					this.setState({isModalOpen: false});
					afterSave();
				})
				.catch(() => {
					this.setState({isModalProcessing: false});
				});
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({isModalOpen: true});
		});
	}

	render() {
		const { isModalOpen, isModalProcessing, noteText, showMap, startTimeMeridiem, startTime, workedTime, projectData } = this.state;
		const { canEdit, clock, note, today, type, isViewOnly, projectsWithTasks } = this.props;
		const clockEditPermissions = clock && clock.clockedIn;
		const clockInAddress = clockEditPermissions ? clock?.latest.clockInAddress : false;
		const { clockInLatitude, clockInLongitude, clockInLocationAccuracy } = clock?.latest || {};
		const clockInLocationErrorExists = clock?.latest.clockInLocationAccuracy === -1 || false;

		let title = $.__('Update Time Entry');
		if (type === 'single') {
			title = note ? $.__('Update Note') : $.__('Add Note');
		}

		/* @startCleanup encore */
		const startTimeSelectProps = {
			isClearable: false,
			isDisabled: !canEdit,
			items: [
				{
					text: 'AM',
					value: 'AM'
				},
				{
					text: 'PM',
					value: 'PM'
				}
			],
			selectedValues: [
				startTimeMeridiem
			],
			onSelect: (value) => {
				const { startTime } = this.state;
				let newMeridiem = value;
				let workedTime = this._getWorkedTime(startTime, newMeridiem);
				if (workedTime < 0) {
					workedTime = 0.0;
				}

				this.setState({
					startTimeMeridiem: newMeridiem,
					workedTime
				});
			}
		};
		/* @endCleanup encore */

		/* @startCleanup encore */
		const noteAreaClasses = co({
			'AddNote__noteArea': true,
			'AddNote__noteArea--hasStartTime': clock,
			'fieldRow': true,
			'BhrForms': true
		});
		/* @endCleanup encore */

		let selectedProjectOrTask = null;
		if (projectData && projectData.projectId && projectData.taskId) {
			selectedProjectOrTask = `${ projectData.projectId }-${ projectData.taskId }`;
		} else if (projectData && projectData.projectId) {
			selectedProjectOrTask = projectData.projectId;
		}

		const timeSpinner = (
			<Flex alignItems='center' gap={1} whiteSpace='nowrap'>
				<TimeSpinner />
				<BodyText color='primary'>{formatTime(workedTime)}</BodyText>
			</Flex>
		);

		const projectSelectSettings = {
			items: projectsWithTasks ? convertNormalizedProjectsToDropdownItems(projectsWithTasks) : [],
			onSelect: this._handleProjectChange,
			onClear: () => this._handleProjectChange(null),
			placeholder: projectData.projectName
				? getProjectSelectToggleText(null, projectData.projectName, projectData.taskName)
				: `--${$.__('Select Project/Task')}--`,
			renderToggleContent: (selectedItem) => getProjectSelectToggleText(selectedItem, projectData.projectName, projectData.taskName),
			selectedValues: [selectedProjectOrTask],
			width: 7,
		};

		const showProjectTaskSelect = projectsWithTasks && projectsWithTasks.allIds.length > 0;
		const projectTaskSelect = ifFeature(
			'encore',
			<SelectField label={$.__('Save time to...')} {...projectSelectSettings} notePlacement='inline' />,
			<div className='AddNote__projectSelect'>
				<label className='fab-Label' htmlFor='projectSelect'>
					{$.__('Save time to...')}
				</label>
				<SelectLegacy
					isDisabled={!canEdit && !clockEditPermissions}
					name='projectSelect'
					note='hey'
					notePlacement='inline'
					{...projectSelectSettings}
				/>
			</div>
		);

		const editTimeContent = ifFeature(
			'encore',
			<Flex alignItems='end' gap={1}>
				<TextField
					defaultValue={startTime}
					disabled={!canEdit}
					label={$.__('Start Time')}
					// name='startTime'
					onChange={this._handleTimeInput}
					placeholder='hh:mm'
					ref={this.timeInputRef}
					required
					width={3}
				/>
				<SelectField
					disabled={!canEdit}
					isClearable={false}
					items={[
						{
							text: 'AM',
							value: 'AM'
						},
						{
							text: 'PM',
							value: 'PM'
						}
					]}
					note={showProjectTaskSelect ? null : timeSpinner}
					notePlacement='inline'
					onSelect={(value) => {
						const { startTime } = this.state;
						let newMeridiem = value;
						let workedTime = this._getWorkedTime(startTime, newMeridiem);
						if (workedTime < 0) {
							workedTime = 0.0;
						}
						
						this.setState({
							startTimeMeridiem: newMeridiem,
							workedTime
						});
					}}
					value={[startTimeMeridiem]}
					width={1}
				/>
				<LayoutBox marginLeft={1}>
					{showProjectTaskSelect ? projectTaskSelect : null}
				</LayoutBox>
			</Flex>,
			<div className='AddNote__timeWrapper'>
				<div className='AddNote__timeWrapper__startTime'>
					<label className='fab-Label' htmlFor='startTime'>
						{$.__('Start Time')}
					</label>
					<div className='fab-InputWrapper'>
						<input
							className='AddNote__timeInput fab-TextInput fab-TextInput--width2'
							defaultValue={startTime}
							disabled={!canEdit}
							name='startTime'
							onInput={this._handleTimeInput}
							placeholder='hh:mm'
							ref={this.timeInputRef}
							required={true}
							type='text'
						/>
						<SelectLegacy {...startTimeSelectProps} />
					</div>
				</div>
				{showProjectTaskSelect ? projectTaskSelect : null}
				<div className='AddNote__timeSpinnerWrapper'>
					<div className='AddNote__timeSpinner'>
						<TimeSpinner />
					</div>
					<p>{formatTime(workedTime)}</p>
				</div>
			</div>
		);

		/* @startCleanup encore */
		const textAreaWrapperClasses = co({
			AddNote__textAreaWrapper: true,
			'AddNote__textAreaWrapper--viewOnly': isViewOnly,
			fieldDiv: true,
		});

		const textAreaClasses = co({
			AddNote__textArea: true,
			'fab-Textarea': true,
			'fab-Textarea--width100': true,
		});
		/* @endCleanup encore */

		const locationWarningMessage = ifFeature(
			'encore',
			<BodyText color='warning' icon='triangle-exclamation-solid'>
				{$.__(`There wasn’t a strong signal where they clocked in, so we can’t show it on the map.`)}
			</BodyText>,
			<div className='AddNote__location__warningMessage'>
				<span className='AddNote__location__warningMessage__icon'>
					<Icon name='fab-triangle-exclamation-16x16' />
				</span>
				<p className='AddNote__location__warningMessage__text'>
					{$.__(`There wasn’t a strong signal where they clocked in, so we can’t show it on the map.`)}
				</p>
			</div>
		);

		const content = ifFeature(
			'encore',
			<Flex flexDirection='column' gap={2.5}>
				{clock && editTimeContent}
				{type === 'single' && showProjectTaskSelect ? projectTaskSelect : null}
				{geolocationEnabled && clockInAddress && (
					<Flex alignItems='start' flexDirection='column' gap={1}>
						<NameValuePair
							name={$.__('Clocked In Near')}
							value={clockInLocationErrorExists ? locationWarningMessage : clockInAddress}
						/>
						{ !showMap && !clockInLocationErrorExists && (
							<TextButton
								onClick={() => this.setState({ showMap: true })}
								endIcon='angles-down-regular'
								startIcon='location-dot-solid'
								type='button'
							>
								{$.__('Show Map')}
							</TextButton>
						)}
						{ showMap && !clockInLocationErrorExists && (
							<Map
								markers={[
									{ accuracy: clockInLocationAccuracy, coordinates: { lat: clockInLatitude, lng: clockInLongitude }, label: 'Clock In' },
								]}
								height='291px'
								width='396px'
								zoom={17}
							/>
						)}
					</Flex>
				)}
				<TextArea
					label={$.__('Note')}
					autoFocus
					value={noteText}
					disabled={!canEdit && !clockEditPermissions}
					InputProps={{ maxLength: 200 }}
					onChange={this._handleNoteInput}
					placeholder={$.__("Add note...")}
				/>
			</Flex>,
			<div className='AddNote'>
				{clock && editTimeContent}
				{type === 'single' && showProjectTaskSelect ? projectTaskSelect : null}
				{geolocationEnabled && clockInAddress && (
					<div className='AddNote__location'>
						<span className='fab-Label'>{$.__('Location')}</span>
						<div
							className={co({
								AddNote__address: true,
								'AddNote__address--withWarning': clockInLocationErrorExists,
							})}
						>
							<span className='AddNote__address__label'>{$.__('Clocked In Near')}</span>
							{clockInLocationErrorExists ? locationWarningMessage : clockInAddress}
						</div>

						{!showMap && !clockInLocationErrorExists && (
							<div className='AddNote__location__showMapButton'>
								<TextButtonLegacy
									clickAction={() => this.setState({ showMap: true })}
									iconAfter='fab-double-chevron-down-6x8'
									iconBefore='fab-location-11x14'
									text={$.__('Show Map')}
									type='button'
								/>
							</div>
						)}

						{showMap && !clockInLocationErrorExists && (
							<Map
								markers={[
									{
										accuracy: clockInLocationAccuracy,
										coordinates: { lat: clockInLatitude, lng: clockInLongitude },
										label: 'Clock In',
									},
								]}
								height='291px'
								width='396px'
								zoom={17}
							/>
						)}
					</div>
				)}
				<div className={noteAreaClasses}>
					<div className='fieldBox fullWidth'>
						<label className='fab-Label' htmlFor='note'>
							{$.__('Note')}
						</label>
						<div className={textAreaWrapperClasses}>
							<textarea
								aria-label='Note'
								autoFocus={true}
								className={textAreaClasses}
								defaultValue={noteText}
								disabled={!canEdit && !clockEditPermissions}
								maxLength='200'
								name='note'
								onInput={this._handleNoteInput}
							></textarea>
						</div>
					</div>
				</div>
			</div>
		);

		return ifFeature(
			'encore',
			<StandardModal
				isOpen={isModalOpen}
				isProcessing={isModalProcessing}
				onRequestClose={() => this.setState({ isModalOpen: false })}
			>
				<StandardModal.Body
					renderHeader={<StandardModal.Header title={title} />}
					renderFooter={
						<StandardModal.Footer
							actions={[
								<TextButton onClick={() => this.setState({ isModalOpen: false })} type='button'>
									{$.__('Cancel')}
								</TextButton>,
								...((canEdit && isViewOnly === false) || clockEditPermissions
									? [
											<Button onClick={this._saveNote} type='button'>
												{$.__('Save')}
											</Button>,
									  ]
									: []),
							]}
						/>
					}
				>
					<StandardModal.UpperContent>
						<BadgeV2 icon='calendar-days-regular' size='large' title={today.format('dddd, MMM D')} />
					</StandardModal.UpperContent>
					<StandardModal.Constraint>{content}</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>,
			<Modal
				headerType={'text'}
				header={today.format('dddd, MMM D')}
				icon={ifFeature('encore', 'calendar-days-solid', 'fab-calendar-15x16')}
				isOpen={isModalOpen}
				isProcessing={isModalProcessing}
				onClose={() => this.setState({ isModalOpen: false })}
				primaryAction={(canEdit && isViewOnly === false) || clockEditPermissions ? this._saveNote : null}
				primaryActionText={$.__('Save')}
				title={title}
				type={'small'}
			>
				{content}
			</Modal>
		);
	}
}
