/**
 * Registry for event handlers. Facilitates adding multiple handlers for a
 * single event.
 */

class EventRegistry {
	[eventHandlers: string]: {};

	constructor() {
		this.eventHandlers = {};
	}

	/**
	 * Add an event handler
	 * @param  {string} name    The nae of the event
	 * @param  {function} handler The function to handle the event
	 */
	on(name: string, handler: Function): void {
		if (!this.eventHandlers[name]) {
			this.eventHandlers[name] = [];
		}
		this.eventHandlers[name].push(handler);
	}

	/**
	 * Remove event Handler with matching event name/function
	 * @param  {string} name    The name of the event
	 * @param  {function} handler The function to handle the event
	 */
	off(name: string, handler: Function): void {
		const handlers = this.eventHandlers[name];
		if (!handlers || !handlers.length) {
			return;
		}

		const handlerIndex = handlers.indexOf(handler);
		if (handlerIndex > -1) {
			handlers.splice(handlerIndex, 1);
		}
	}


	/**
	 * Calls event handler whether supplied in options or on instance
	 * @param  {string} eventName The name of the event
	 * @param  {..object|..string} Argument values to pass event handler
	 */
	trigger(name: string): void {
		// eslint-disable-next-line prefer-rest-params
		const args = Array.prototype.slice.call(arguments, 1);
		const handlers = this.eventHandlers[name];
		if (!handlers || !handlers.length) {
			return;
		}

		handlers.forEach((handler) => {
			handler(...args);
		});
	}
}

export default EventRegistry;
