import { Fragment } from 'react';
import { ifFeature } from "@utils/feature";
import { TabNav } from 'tab-nav.react';

import './employee-tab-nav.styl';

function getEmployeeTabs(seeDirectory, seeList, seeOrgChart) {
	let employeeTabs = [];
	if (seeList) {
		employeeTabs.push({
			iconName: ifFeature('encore', 'table-list-regular', 'fab-lines-15x14'),
			key: 'employee-list',
			label: $.__('List'),
			url: '/employees/index.php?pin',
		});
	}
	if (seeDirectory) {
		employeeTabs.push({
			iconName: ifFeature('encore', 'address-book-regular', 'fab-person-on-book-16x16'),
			key: 'employee-directory',
			label: $.__('Directory'),
			url: '/employees/directory.php?pin',
		});
	}
	if (seeOrgChart) {
		employeeTabs.push({
			iconName: ifFeature('encore', 'sitemap-regular', 'fab-org-chart-16x15'),
			key: 'employee-org-chart',
			label: $.__('Org Chart'),
			url: '/employees/orgchart.php?pin',
		});
	}
	return employeeTabs;
}

export function EmployeeTabNav(props) {
	const { activeKey, seeDirectory, seeList, seeOrgChart } = props;
	const employeeTabs = getEmployeeTabs(seeDirectory, seeList, seeOrgChart);
	const activeIndex = employeeTabs.findIndex(tab => tab.key === activeKey);

	return (
		<Fragment>
			{ employeeTabs && employeeTabs.length > 0 ? (
				<div className="EmployeeTabNav">
					<div className="EmployeeTabNav__tabs">
						<TabNav activeIndex={ activeIndex } tabs={ employeeTabs } />
					</div>
				</div>
			) : null }
		</Fragment>
	);
}
