import { isNil } from 'lodash';

export function allSignersComplete(fileObject) {
	const { esignatures } = fileObject;
	return isEsig(fileObject) && esignatures.completed;
}

export function canViewFinalized(fileObject) {
	const {
		company_file_data,
		employee_file_data,
		esignatures
	} = fileObject;

	if (
		isEsig(fileObject) &&
		isNil(employee_file_data) &&
		isNil(company_file_data)
	) {
		return true;
	}

	return isEsig(fileObject) && (
		// Check if can see the finalized document
		(
			typeof employee_file_data === 'object' &&
			!isNil(employee_file_data.id) &&
			employee_file_data.can_see_file
		) ||
		(
			typeof company_file_data === 'object' &&
			!isNil(company_file_data.id) &&
			company_file_data.can_see_file
		)
	)
}

export function hasBeenSent(fileObject) {
	const { workflow_instance_id } = fileObject;
	return isEsig(fileObject) && workflow_instance_id !== undefined;
}

export function isCancelled(fileObject) {
	const { esignatures } = fileObject;
	return isEsig(fileObject) && esignatures.cancelled;
}

export function isCompleteOrEmployeeComplete(fileObject) {
	const { esignatures } = fileObject;
	return isEsig(fileObject) && (esignatures.completed || esignatures.employee_has_signed);
}

export function isEsig(fileObject) {
	const {
		esignatures,
		is_esignature_template
	} = fileObject;
	// is_esignature_template can mean two things:
	// if file is attached to something was it an esignature template at point of set up
	// if file is NOT attached to something is it an esignature template
	// TODO: Rename this property in the backend and frontend
	return !isNil(is_esignature_template) && is_esignature_template === 'yes' && !isNil(esignatures) && esignatures.setup_complete;
}

export function waitingOnSig(fileObject) {
	const {
		esignatures,
		required
	} = fileObject;
	return isEsig(fileObject) && required && esignatures.employee_has_signed && !esignatures.completed;
}
