import { isEqual } from 'lodash';
import { Component } from 'react';

import { prepareShareList } from '../';

import './people-selector.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export class PeopleSelector extends Component {
	set shareListData(shareList) {
		this.setState(() => {
			return { shareList };
		});
	}

	set shareListAddition(value) {
		const { updateList } = this.props;
		const { shareList } = this.state;
		const newList = shareList.concat(value);
		this.shareListData = newList;
		updateList(newList);
	}

	set shareListSubtraction(value) {
		const { updateList } = this.props;
		const { shareList } = this.state;
		const newList = shareList.filter(item => !isEqual(item, value));
		this.shareListData = newList;
		updateList(newList);
	}

	_clean(item) {
		return BambooHR.Utils.htmlentities(item);
	}

	_prepareUserData(userData = []) {
		const [name, _, id, image] = userData;
		return {
			image,
			id: Number(id),
			name: this._clean(name),
		};
	}

	_setupInputs(refElem, settings = {}) {
		const {
			quickSearchDescription = ifFeature('encore',
				$.__('Enter names or groups'),
				$.__('Enter names or groups...'),
			),
			quickSearchEndpoint = '/quicksearch/share',
		} = settings;
		const { updateList } = this.props;


		const peopleSelectorComponent = this;

		const $form = $(refElem.current);
		const $acInput = $form.find('.ac_input');
		const $acList = $form.find('.input-list');
		const $description = $form.find('.PeopleSelector__autocomplete');

		const handler = () => {
			$description.hide().next('.inputs').show();
			$acList.addClass('shown');
			$acInput.focus();

			const handleOnClose = () => {
				$acList.deactivate();
				$form.find('.description').show();
				this.shareListData = [];
				updateList(this.shareListData);
			};

			peopleSelectorComponent.inputHasFocus(true, handleOnClose);

			$acList.removeClass('shown');
			$description.show().next('.inputs').hide();
			$acList.activate();
		};

		$description.bind('click.autoInit.bhr', handler);

		$acList.click(function click() {
			$acInput.focus();
		});

		$acList.autoInputList({
			autocompleteOptions: {
				highlight: BambooHR.Utils.highlightTermInMarkup,
				parse: (data) => {
					const { ownerId } = this.props;
					/**
					 * This was mostly taken from the default parse method
					 * in jquery autocomplete.
					 */
					const parsed = [];
					const rows = data.split('\n');
					for (let i = 0; i < rows.length; i += 1) {
						let row = $.trim(rows[i]);
						if (row) {
							row = row.split('|');
							const parseRow = !ownerId || (ownerId && row[2] !== ownerId);
							if (parseRow) {
								row[0] = BambooHR.Utils.htmldecode(row[0]);
								parsed[parsed.length] = {
									data: row,
									value: row[0],
									result: row[0],
								};
							}
						}
					}
					return parsed;
				},
			},
			autocompleteUrl: quickSearchEndpoint,
			description: quickSearchDescription,
			formatAutoItem: qsWithPhoto,
			isShareModal: true,
			formatTagItem(event, data) {
				if (data) {
					const clean = (item) => {
						return BambooHR.Utils.htmlentities(item);
					};

					const payload = {
						user_group: clean(data[1]),
						user: clean(data[2]),
						userData: peopleSelectorComponent._prepareUserData(data),
					};

					const existingTagInput = $(`.item input[value="${ clean(data[2]) }"]`);
					if (existingTagInput.length) {
						const item = existingTagInput[0].closest('.item');
						const remove = $(item).find('.remove');
						remove.click();
					}

					if (data[1] && data[1] !== 'empty') {
						peopleSelectorComponent.shareListAddition = payload;
						$(`
							<span class="item itemId${ clean(data[2]) }">
								${ clean(data[0]) }
								<input type="hidden" name="ids[${ clean(data[1]) }][]" value="${ clean(data[2]) }" data-user="${ clean(data) }"/>
								<ba-icon class="remove" name="fab-x-8x8" encore-class="remove" encore-name="circle-x-solid" encore-size="12"></ba-icon>
							</span>
						`)
							.insertBefore('.PeopleSelector__form .input-list span.input');
					}

					$(this).val('');

					$('.item .remove').on('click', function onClick() {
						if ($(this).parent().hasClass(`itemId${ data[2] }`)) {
							peopleSelectorComponent.shareListSubtraction = payload;
						}
						$(this).closest('span.item').remove();
						$acInput.focus();
						return false;
					});
				}
			},
		})
			.focus(function focus() {
				$acList.addClass('focus');
			})
			.blur(function blur() {
				$acList.removeClass('focus');
			});
	}

	inputHasFocus = (hasFocus, onCloseCallback = () => { }) => {
		const {
			updateFocus,
		} = this.props;

		updateFocus(hasFocus, onCloseCallback);
	};

	_handleNotify = () => {
		const { updateNotify } = this.props;
		const { notify } = this.state;
		this.setState(() => {
			return {
				notify: !notify,
			};
		});

		if (updateNotify) {
			updateNotify(!notify);
		}
	};

	handleSubmit = (event) => {
		const { onSubmit } = this.props;
		const { notify, shareList } = this.state;
		return onSubmit({
			...prepareShareList(shareList),
			notify,
		}, event);
	};

	state = {
		notify: true,
		shareList: [],
	};

	componentDidMount() {
		const { formRef, settings } = this.props;
		setTimeout(() => {
			this._setupInputs(formRef, settings);
		});
	}

	render() {
		const {
			data,
			formRef,
			renderBottomSlot,
			renderNotifyBottomSlot,
			renderNotifySiblingSlot,
			renderTopSlot,
			settings: { showNotify = true },
		} = this.props;
		const { notify, shareList } = this.state;

		return (
			<div className="PeopleSelector typeahead">
				{ renderTopSlot && renderTopSlot(data) }

				<form ref={ formRef } className="PeopleSelector__form shareForm" name="peopleSelector">

					<label className="fab-Label" htmlFor="add">
						{ $.__('Add People') }
					</label>
					<div className="PeopleSelector__autocomplete input-list inputField fab-Textarea fab-Textarea--width100"></div>
					{ ifFeature('encore',
						<div className='PeopleSelector__notify'>
							{ (showNotify && shareList.length > 0) && (
								<div className="PeopleSelector__notify__item">
									<input
										checked={ notify ? 'checked' : '' }
										className="fab-Checkbox__input"
										id="notify"
										name="notify"
										onChange={ this._handleNotify }
										type="checkbox"
										value="true"
									/>
									<label className="fab-Checkbox__label" htmlFor="notify">
										{ $.__('Notify people via email') }
									</label>
								</div>
							) }
							{ renderNotifySiblingSlot && shareList.length > 0 && (
								<div className="PeopleSelector__notify__item">
									{ renderNotifySiblingSlot(notify) }
								</div>
							) }
						</div>,
						<div className="PeopleSelector__notify">
							<div className="fab-FormRow">
								{ (showNotify && shareList.length > 0) && (
									<span className="fab-FormField">
										<div className="fab-Checkbox">
											<input
												checked={ notify ? 'checked' : '' }
												className="fab-Checkbox__input"
												id="notify"
												name="notify"
												onChange={ this._handleNotify }
												type="checkbox"
												value="true"
											/>
											<label className="fab-Checkbox__label" htmlFor="notify">
												{ $.__('Notify people via email') }
											</label>
										</div>
									</span>
								) }

								{
									renderNotifySiblingSlot && (
										<span className="fab-FormField">
											{ renderNotifySiblingSlot(notify) }
										</span>
									)
								}
							</div>
							{
								renderNotifyBottomSlot && notify && (
									<div> { renderNotifyBottomSlot } </div>
								)
							}
						</div>
					) }
				</form>

				{ renderBottomSlot && renderBottomSlot(data, notify) }
			</div>
		);
	}
}
