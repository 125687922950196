/**
 * Computes whether or not to show the given separator line. If the relevant
 * pay period (previous or next) is empty or weekends are hidden and that is
 * all that was in the relevant period, then the separator should not show.
 * @param showWeekends
 * @param entries
 * @returns {boolean}
 */
export function showSeparator(showWeekends, entries) {
	const bothWeekends = (entries.length <= 2 && entries.every((e) => {
		const day = e.moment.day();
		return day === 0 || day === 6;
	}));

	if (!entries || (!showWeekends && bothWeekends)) {
		return false;
	}

	return !!entries.length;
}

// @startCleanup encore
// The following boolean assignments determine the placement of the pay period separator
export function computeNudges(showWeekends, payPeriodEntries, nextPeriodEntries) {
	let previousMoveUp = null;
	let nextMoveDown = null;
	let nextMoveUp = null;

	if (showWeekends) {
		previousMoveUp = payPeriodEntries[0].isToday;
		nextMoveDown = payPeriodEntries[payPeriodEntries.length - 1].isToday;
		nextMoveUp = nextPeriodEntries.length && nextPeriodEntries[0].isToday;
	} else {
		const payPeriodWithoutWeekends = payPeriodEntries.filter(({moment}) => {
			const day = moment.day();
			return day !== 0 && day !== 6;
		});

		previousMoveUp = (payPeriodWithoutWeekends.length) ? payPeriodWithoutWeekends[0].isToday : false;
		nextMoveDown = (payPeriodWithoutWeekends.length) ? payPeriodWithoutWeekends[payPeriodWithoutWeekends.length - 1].isToday : false;

		const nextWithoutWeekends = nextPeriodEntries.filter(({moment}) => {
			const day = moment.day();
			return day !== 0 && day !== 6;
		});

		nextMoveUp = (nextWithoutWeekends.length) ? nextWithoutWeekends[0].isToday : false;
	}

	return {
		previousMoveUp,
		nextMoveDown,
		nextMoveUp,
	};
}
// @endCleanup encore
