import { Fragment, useEffect, useState } from 'react';
import { render } from 'base/wrapped-render';
import moment from 'moment';
import Ajax from '@utils/ajax';
import { Modal } from 'modal-legacy';
import {
	// @startCleanup encore
	Icon,
	// @endCleanup encore
	IconV2,
	BlankState,
	BadgeV2,
	StandardModal,
	Divider,
	Timeline,
	Flex,
	BodyText,
	LayoutBox
} from '@bamboohr/fabric';
import { Select } from '@fabric/select';
import Tooltip from 'tooltip.react';
// @startCleanup encore
import BlankStateLegacy from 'blank-state.react';
import BadgeComponent from 'badge.react';
// @endCleanup encore
import { TIME_FORMAT } from 'time-tracking/constants';
import { createReadableHistory } from './history-text';
import './styles.styl';
import { formatTime } from '../../utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';

// @startCleanup encore
const iconMapLegacy = {
	added: 'fab-circle-plus-16x16',
	edited: 'fab-pencil-16x16',
	clockedOut: 'fab-clock-up-16x16',
	clockedIn: 'fab-clock-down-16x16',
	deleted: 'fab-trash-can-14x16',
	timesheetSentForApproval: 'fab-paper-airplane-16x16'
};
// @endCleanup encore

const iconMap = {
	added: 'circle-plus-solid',
	edited: 'pencil-solid',
	clockedOut: 'circle-stop-solid',
	clockedIn: 'stopwatch-solid',
	deleted: 'trash-can-solid',
	timesheetSentForApproval: 'paper-plane-solid',
};

export function convertDaysToOptions(days = []) {
	return days.map(({ date, historyEventCount }) => {
		const formattedDate = moment(date, 'YYYY-MM-DD').format('dddd, MMM D');
		const text = `${ formattedDate } (${ $.__n('%1$s event', '%1$s events', historyEventCount) })`;
		return {
			text,
			value: date,
		};
	});
}

export function ModalHeader(
	// @startCleanup encore
	props
	// @endCleanup encore
) {
	// @startCleanup encore
	const {
		days,
		selectedDate,
		setSelectedDate,
	} = props;
	// @endCleanup encore

	const { preferredName, lastName, photoURL, jobTitle } = window.Employee;

	return ifFeature(
		'encore',
		<StandardModal.UpperContent>
			<BadgeV2
				icon={<BadgeV2.Avatar alt={$.__('Profile picture for %1$s', preferredName)} src={photoURL} />}
				size='large'
				subtitle={jobTitle}
				title={`${preferredName} ${lastName}`}
			/>
		</StandardModal.UpperContent>,
		<Fragment>
			<BadgeComponent imageAlt='Image of employee' imageSrc={photoURL} subTitle={jobTitle} title={`${preferredName} ${lastName}`} />
			<div className='ChangeHistoryModal__select'>
				<Select
					isClearable={false}
					items={convertDaysToOptions(days)}
					onSelect={(value) => setSelectedDate(value)}
					selectedValues={[selectedDate]}
					size='large'
					width={8}
				/>
			</div>
			<div className='ChangeHistoryModal__separator' />
		</Fragment>
	);
}

export function ModalContent(props) {
	const {
		historyData,
		timeEarned,
		days,
		setSelectedDate,
		selectedDate
	} = props;

	const historyEventsLength = historyData.changeHistoryEvents?.length ?? 0;
	const dateMoment = moment(historyData.date, 'YYYY-MM-DD');

	return ifFeature(
		'encore',
		<StandardModal.Constraint>
			<Flex flexDirection='column'>
				<Select
					isClearable={false}
					items={convertDaysToOptions(days)}
					onSelect={(value) => setSelectedDate(value)}
					selectedValues={[selectedDate]}
					size='large'
					width={8}
				/>
				<LayoutBox marginTop={3.75}>
					{historyEventsLength > 0 ? (
						<Timeline
							items={[
								{
									id: 1,
									icon: 'calendar-regular',
									isInverted: true,
									primaryText: $.__('Timesheet history for %1$s', dateMoment.format('dddd, MMM D')),
									secondaryText: $.__n('%2$s total time - %1$s event', '%2$s total time - %1$s events', historyEventsLength, timeEarned),
								},
								...historyData.changeHistoryEvents.map((event) => {
									const { clientType, dateTime, timezone, eventType, details } = event;
									const isMobileEvent = clientType === 'android' || clientType === 'ios';
									const eventMoment = moment.tz(dateTime, timezone);
									const isSameDay = dateMoment.isSame(eventMoment, 'day');
									let formattedStamp = eventMoment.format(TIME_FORMAT);
									if (!isSameDay) {
										formattedStamp = $.__('%1$s on %2$s', eventMoment.format(TIME_FORMAT), eventMoment.format('dddd, MMM D'), {
											note: 'e.g. "1:11 PM on Tuesday, Sep 15"',
										});
									}

									return {
										id: eventType + dateTime,
										icon: iconMap[eventType] ?? 'circle-plus-regular',
										primaryText: (
											<Flex flexDirection='column'>
												{createReadableHistory(details).map((readable, i) => {
													return <div key={i}>{readable}</div>;
												})}
											</Flex>
										),
										secondaryText: (
											<Flex flexDirection='column'>
												<BodyText color="neutral-strong">{event.note}</BodyText>
												<BodyText>{event.user}</BodyText>
												<BodyText>
													{formattedStamp}
													{isMobileEvent && mobileTooltip(clientType)}
												</BodyText>
											</Flex>
										),
									};
								}),
							]}
							orientation='vertical'
							size='medium'
						/>
					) : (
						<BlankState icon='clock-rotate-left-regular' title={$.__(`There wasn't any time tracking activity on this day`)} />
					)}
				</LayoutBox>
			</Flex>
		</StandardModal.Constraint>,
		<div className='ChangeHistoryModal__content'>
			{historyEventsLength > 0 && (
				<div className='History'>
					<div className='History__block'>
						<div className='History__icon'>
							<Icon name='fab-calendar-alt-16x16' />
						</div>
						<div>
							<span className='History__header'>{$.__('Timesheet history for %1$s', dateMoment.format('dddd, MMM D'))}</span>
							<div className='History__details'>
								{$.__n('%2$s total time · %1$s event', '%2$s total time · %1$s events', historyEventsLength, timeEarned)}
							</div>
						</div>
					</div>

					{historyData.changeHistoryEvents.map((event) => {
						const { clientType } = event;
						const isMobileEvent = clientType === 'android' || clientType === 'ios';
						const eventMoment = moment.tz(event.dateTime, event.timezone);
						const isSameDay = dateMoment.isSame(eventMoment, 'day');
						let formattedStamp = eventMoment.format(TIME_FORMAT);
						if (!isSameDay) {
							formattedStamp = $.__('%1$s on %2$s', eventMoment.format(TIME_FORMAT), eventMoment.format('dddd, MMM D'), {
								note: 'e.g. "1:11 PM on Tuesday, Sep 15"',
							});
						}

						return (
							<div className='History__block' key={event.eventType + event.dateTime}>
								<div className='History__icon History__icon--outlined'>
									<Icon name={iconMapLegacy[event.eventType] ?? 'fab-circle-plus-16x16'} />
								</div>
								<div>
									{createReadableHistory(event.details).map((readable, i) => {
										return <div key={i}>{readable}</div>;
									})}
									{event.note && <div className='History__note'>{event.note}</div>}
									<div className='History__meta'>{event.user}</div>
									<div className='History__meta'>
										{formattedStamp}
										{isMobileEvent && mobileTooltip(clientType)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}

			{!historyEventsLength > 0 && (
				<BlankStateLegacy
					className='ChangeHistoryModal__blankState'
					icon='fab-clock-reverse-72x66'
					title={$.__(`There wasn't any time tracking activity on this day`)}
				/>
			)}
		</div>
	);
}

function mobileTooltip(clientType) {
	return (
		<span className="ChangeHistoryModal__mobileIcon">
			<Tooltip
				settings={ {
					content: {
						ios: $.__('Via iOS App'),
						android: $.__('Via Android App'),
					}[clientType],
				} }
			>
				{ifFeature('encore', <IconV2 name='mobile-regular' size={16} />, <Icon name="fab-mobile-phone-9x12" />)}
			</Tooltip>
		</span>
	);
}

export function ChangeHistoryModal(props) {
	const {
		days,
		initialDate = days[0]?.date,
	} = props;

	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(initialDate);
	const [historyData, setHistoryData] = useState({});

	const timeEarned = formatTime(days.find(o => o.date === selectedDate)?.hours ?? 0);

	const header = (
		<ModalHeader
			// @startCleanup encore
			days={ days }
			selectedDate={ selectedDate }
			setSelectedDate={ setSelectedDate }
			// @endCleanup encore
		/>
	);

	const content = (
		<ModalContent
			days={ days }
			historyData={ historyData }
			selectedDate={ selectedDate }
			setSelectedDate={ setSelectedDate }
			timeEarned={ timeEarned }
		/>
	);

	useEffect(() => {
		window.setTimeout(() => {
			setIsOpen(true);
		});
	}, []);

	useEffect(() => {
		setIsLoading(true);
		Ajax
			.get(`/time_tracking/employee/${ window.Employee.id }/change_history`, { date: selectedDate })
			.then(({ data }) => {
				setHistoryData(data);
			})
			.catch(() => {
				window.errorFallBack();
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [selectedDate]);

	return (
		<Modal
			alternativeActionText={ null }
			content={ content }
			contentHasPadding={ false }
			header={ header }
			isLoading={ isLoading }
			isOpen={ isOpen }
			onClose={ () => setIsOpen(false) }
			primaryAction={ () => setIsOpen(false) }
			primaryActionText={ $.__('Close') }
			title={ $.__('Timesheet Change History') }
			type="medium"
		/>
	);
}

export default function openChangeHistoryModal(days, initialDate) {
	render(
		<ChangeHistoryModal days={ days } initialDate={ initialDate } />,
		document.createElement('div')
	);
}
