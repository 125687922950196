import {
	isStringNumeric,
} from '../../domain';

export function getAccountNumberError(accountNumber: string): string {
	try {
		if (!accountNumber.trim()) {
			throw new Error('Required');
		}

		if (!isStringNumeric(accountNumber)) {
			throw new Error('Can only contain numbers');
		}

		if (accountNumber.length > 17) {
			throw new Error('Too many digits');
		}

		return null;
	} catch (error) {
		return error.message;
	}
}
