var selectors = {
	body: '.js-simplemodal-body'
};

/**
 * Simple Modal Body Object
 */
var Body = {
	/**
	 * Get the height of the given modal's body
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 *
	 * @returns {Number} The height
	 */
	getHeight($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		return $modal.find(selectors.body).height();
	}
};

export default Body;
