import {
	COUNTRY_ID_AUS,
	COUNTRY_ID_NG,
	COUNTRY_ID_UK,
	COUNTRY_ID_USA,
	COUNTRY_ID_VOID,
	ENTITY_LABEL_COUNTY,
	ENTITY_LABEL_PROVINCE,
	ENTITY_LABEL_STATE,
	POST_LABEL_POSTAL_CODE,
	POST_LABEL_POSTCODE,
	POST_LABEL_ZIP,
} from './constants';

export function getFormattedOptions(items) {
	return items ? Array.from(items).map(item => ({
		...item,
		text: item.displayText || item.name || item.text,
		value: item.value || item.id,
	})) : [];
}

export function getSubEntityLabel(countryId) {
	switch (parseInt(countryId)) {
		case COUNTRY_ID_VOID:
		case COUNTRY_ID_USA:
		case COUNTRY_ID_AUS:
		case COUNTRY_ID_NG:
			return ENTITY_LABEL_STATE;
		case COUNTRY_ID_UK:
			return ENTITY_LABEL_COUNTY;
		default:
			return ENTITY_LABEL_PROVINCE;
	}
}

export function getPostCodeLabel(countryId) {
	switch (parseInt(countryId)) {
		case COUNTRY_ID_VOID:
		case COUNTRY_ID_USA:
			return POST_LABEL_ZIP;
		case COUNTRY_ID_AUS:
		case COUNTRY_ID_UK:
			return POST_LABEL_POSTCODE;
		default:
			return POST_LABEL_POSTAL_CODE;
	}
}
