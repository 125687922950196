import { useEffect, useState } from 'react';
import {
	Button,
	StandardModal,
	TextButton,
	CheckboxGroupItem,
	CheckboxGroupOnChangeParam,
	CheckboxGroup,
	BodyText,
	Flex,
} from '@bamboohr/fabric';
import { stopCancellation, StopCancellationRequestData } from 'in-app-cancellation.mod/services';
import { ProductSelectionNamesMap, CancellationType } from '../sad-to-go-box/types';
// @startCleanup encore
import { useStyles } from './styles';
// @endCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';

export interface StopCancellationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmitSuccess?: () => void;
	cancellationData: Array<{
		feature?: CancellationType;
		pending_cancellation: boolean;
		date_requested: string;
		user_who_requested_cancellation: string;
		cancellation_date?: string;
		cancellation_details?: {
			final_pay_date?: string;
		};
	}>;
}

export const StopCancellationModal = ({ isOpen, onClose, onSubmitSuccess, cancellationData }: StopCancellationModalProps) => {
	// @startCleanup encore
	const classes = useStyles();
	// @endCleanup encore
	const [isProcessing, setIsProcessing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pendingCancellationProducts, setPendingCancellationProducts] = useState([]);
	const [stopCancellationData, setStopCancellationData] = useState<StopCancellationRequestData>();
	const [selectedProducts, setSelectedProducts] = useState<CancellationType[]>([]);

	useEffect(() => {
		if (!cancellationData || !cancellationData.reduce) return;

		const products = cancellationData.reduce((acc, status) => {
			const { feature, pending_cancellation } = status;
			if (feature in ProductSelectionNamesMap && pending_cancellation) {
				acc.push({
					value: feature,
					label: ProductSelectionNamesMap[feature] as string,
				});
			}
			return acc;
		}, [] as CheckboxGroupItem[]);
		setPendingCancellationProducts(products);
	}, [cancellationData]);

	useEffect(() => {
		setStopCancellationData({
			products: selectedProducts,
		});
	}, [selectedProducts]);

	const handleCloseModal = () => {
		onClose();
	};

	const handleChange = (params: CheckboxGroupOnChangeParam, event: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = event;
		const selectedValues = params.value as CancellationType[];
		const hasAccount = pendingCancellationProducts.find((pending) => pending.value == CancellationType.ACCOUNT);
		if (target.value != CancellationType.ACCOUNT) {
			if (!selectedValues.includes(CancellationType.ACCOUNT) && hasAccount) {
				const withAccount = [...selectedValues, CancellationType.ACCOUNT];
				setSelectedProducts(withAccount);
			} else {
				setSelectedProducts(selectedValues);
			}
		} else if (target.value === CancellationType.ACCOUNT && !target.checked) {
			setSelectedProducts([]);
		} else {
			setSelectedProducts(selectedValues);
		}
	};

	const handleSubmit = () => {
		if (!isProcessing) {
			setIsProcessing(true);
			const successMessage = $.__('Cancellation Request was successfully withdrawn.');
			stopCancellation(stopCancellationData)
				.then(() => {
					window.setMessage(successMessage, 'success');
					handleCloseModal();
				})
				.catch(() => {
					window.setMessage(
						$.__('Oops! Looks like we had some trouble submitting your request. Refresh the page and try again!'),
						'error'
					);
				})
				.finally(() => {
					onSubmitSuccess();
					setIsProcessing(false);
				});
		}
	};

	return ifFeature(
		'encore',
		<>
			<StandardModal
				isLoading={isLoading}
				isOpen={isOpen}
				isProcessing={isProcessing}
				onCloseComplete={() => setSelectedProducts([])}
				onRequestClose={handleCloseModal}
			>
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={[
								<Button key='secondary' onClick={handleCloseModal} type='button' variant='outlined'>
									{$.__('Cancel')}
								</Button>,
								<Button key='primary' onClick={handleSubmit} type='button'>
									{$.__('Stop Cancellation')}
								</Button>,
							]}
						/>
					}
					renderHeader={<StandardModal.Header hasCloseButton={true} title={$.__('I’ve Had a Change of Heart')} />}
				>
					<StandardModal.Constraint
						spacingOverrides={{
							bottom: StandardModal.Constraint.Spacing.LARGE,
							top: StandardModal.Constraint.Spacing.LARGE,
						}}
					>
						<Flex flexDirection='column' gap={2}>
							<BodyText>
								{$.__(
									'Stopping this cancellation request will allow your BambooHR service and billing to continue without interruption.'
								)}
							</BodyText>
							<CheckboxGroup
								items={pendingCancellationProducts}
								label={$.__('Select the products for which you would like to stop cancellation.')}
								onChange={handleChange}
								value={selectedProducts}
								className={classes.marginBottom}
							/>
						</Flex>
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
		</>,
		<>
			<StandardModal
				isLoading={isLoading}
				isOpen={isOpen}
				isProcessing={isProcessing}
				onCloseComplete={() => setSelectedProducts([])}
				onRequestClose={handleCloseModal}
			>
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={[
								<Button key='primary' onClick={handleSubmit} type='button'>
									{$.__('Stop Cancellation')}
								</Button>,
								<TextButton key='secondary' onClick={handleCloseModal} type='button'>
									{$.__('Cancel')}
								</TextButton>,
							]}
						/>
					}
					renderHeader={<StandardModal.Header hasCloseButton={true} title={$.__('I’ve Had a Change of Heart')} />}
				>
					<StandardModal.Constraint
						spacingOverrides={{
							bottom: StandardModal.Constraint.Spacing.LARGE,
							top: StandardModal.Constraint.Spacing.LARGE,
						}}
					>
						<p className={classes.stopModalContent}>
							{$.__(
								'Stopping this cancellation request will allow your BambooHR service and billing to continue without interruption.'
							)}
						</p>
						<CheckboxGroup
							items={pendingCancellationProducts}
							label={$.__('Select the products for which you would like to stop cancellation.')}
							onChange={handleChange}
							value={selectedProducts}
							className={classes.marginBottom}
						/>
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>
		</>
	);
};
