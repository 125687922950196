import {
	DELETE_COMMENT_ERROR,
	DELETE_COMMENT_START,
	DELETE_COMMENT_SUCCESS,
} from '../../actions/constants';

export default { DELETE_COMMENT_START: (state, action) => {
	return state.hasIn(['entities', 'comments', 'byId', action.payload])
		? state.setIn(['entities', 'comments', 'byId', action.payload, 'pending'], true)
		: state.setIn(['entities', 'replies', 'byId', action.payload, 'pending'], true);
}, DELETE_COMMENT_ERROR: (state, action) => {
	return state.hasIn(['entities', 'comments', 'byId', action.payload])
		? state.setIn(['entities', 'comments', 'byId', action.payload, 'pending'], false)
		: state.setIn(['entities', 'replies', 'byId', action.payload, 'pending'], false);
}, DELETE_COMMENT_SUCCESS: (state, action) => {
	const deleteEntity = (state, type, id) => (
		state
			.removeIn(['entities', type, 'byId', id])
			.updateIn(
				['entities', type, 'allIds'],
				v => v.delete(id)
			)
	);

	const deleteComment = (id) => {
		const eventId = state.getIn(['entities', 'comments', 'byId', id, 'eventId']);
		return deleteEntity(
			(
				eventId
					? deleteEvent(state, eventId)
					: state
			),
			'comments',
			id
		);
	};
	
	const deleteEvent = (state, eventId) => {
		const eventType = state.getIn(['entities', 'events', 'byId', eventId, 'type']);
		if (eventType === 'move') {
			return state
				.setIn(
					['entities', 'events', 'byId', eventId, 'data', 'commentId']
					, null);
		}
		return deleteEntity(state, 'events', eventId);	
	}
	
	const deleteReply = (id) => {
		const inReplyTo = state.getIn(['entities', 'replies', 'byId', id, 'inReplyTo']);

		return state
			.removeIn(['entities', 'replies', 'byId', id])
			.updateIn(
				['entities', 'comments', 'byId', inReplyTo, 'replies'],
				v => v.delete(id)
			);
	};

	return state.hasIn(['entities', 'comments', 'byId', action.payload])
		? deleteComment(action.payload)
		: deleteReply(action.payload);
}, };
