import { render } from 'base/wrapped-render';
import { getMaxZIndex } from '@utils/dom';
import { BasicSlidedown } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const initializeBambooAlerts = () => {

	// if a page doesn't have the new `js-sessionMessage` element, fall back to the old `sessionMessage` element
	const container = document.getElementById(ifFeature('encore', 'js-sessionMessage', 'sessionMessage')) || document.getElementById('sessionMessage');

	if (container) {
		render(
			<BasicSlidedown
				ref={ (slidedown) => { window.bambooAlert = slidedown; } }
				showOnMount={ false }
			/>,
			container
		);
		window.closeMessage = window.bambooAlert.hideAlert;
		window.setMessage = (...args) => {
			const currentMax = getMaxZIndex();

			if (container.style.zIndex !== `${ currentMax }`) {
				container.style.zIndex = currentMax + 10;
			}
			window.bambooAlert.setAlert(...args);
		};
	}
};

export const displaySessionMessage = (message) => {
	if (message && message.text) {
		window.setTimeout(() => window.setMessage(message.text, message.type), 0);
	}
};
