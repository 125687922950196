import classNames from 'classnames';

import { Icon } from '@bamboohr/fabric';

import './style.styl';

import { convertHTMLEntitiesInString as convertHTMLEntitiesInStringUtil } from 'String.util';

/**
 * BadgeComponent Component
 * @param {Object} props [description]
 *
 * props:
 *
 * {JSX}    imageJSX    <MyComponent />
 * {String} imageAlt    'String describing the image'
 * {String} imageSrc    'Image-source-url.img'
 * {Bool}   isModalHeader 'Default true - if true adds the extra styles needed for use as the modal header'
 * {String} theme       Theme name for CSS tweaks
 * {String} title       'Some Person'
 * {String} subTitle    'A tidbit about the title'
 */
const BadgeComponent = (props) => {
	const {
		children,
		imageJSX,
		imageAlt = '',
		imageSrc = '',
		imageIcon = '',
		isModalHeader = true,
		noPadding = false,
		theme,
		title = '',
		subTitle = '',
	} = props;

	const badgeClassNames = classNames(
		'Badge', {
			'Badge--modalHeader': isModalHeader,
			'Badge--noPadding': noPadding,
		}
	);

	const themeClass = (theme) ? `BadgeContainer--${ theme }` : '';

	const badgeContainerClassNames = classNames('BadgeContainer', themeClass, {
		'BadgeContainer--modalHeader': isModalHeader,
	});
	let image = null;

	if (imageSrc && typeof imageSrc === 'string') {
		image = (
			<img
				alt={ imageAlt }
				className="BadgeImage__image"
				src={ convertHTMLEntitiesInString(imageSrc) }
			/>
		);
	} else if (imageJSX) {
		image = (
			<div>
				{ imageJSX }
			</div>
		);
	} else if (imageIcon && typeof imageIcon === 'string') {
		image = (
			<Icon name={ imageIcon } />
		);
	}

	return (
		<div className={ badgeContainerClassNames }>
			<div className={ badgeClassNames }>
				{ image && (
					<div className="BadgeImage">
						{ image }
					</div>
				) }
				<div className="BadgeText">
					{
						title && (
							<div className="BadgeText__title">{ convertHTMLEntitiesInString(title) }</div>
						)
					}
					{
						subTitle && (
							<div className="BadgeText__subTitle">{ convertHTMLEntitiesInString(subTitle) }</div>
						)
					}
					{ children }
				</div>
			</div>
		</div>
	);
};

export function convertHTMLEntitiesInString(string) {
	// Converted to this to keep tests in place as this function is more secure from XSS
	return convertHTMLEntitiesInStringUtil(string);
}

export default BadgeComponent;
