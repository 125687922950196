import { FunctionComponent } from 'react';
import './employee-access.styl';

const EmployeeAccessDisabled: FunctionComponent<DynamicForm.EmployeeAccessModeDisabledProps> = ({ access, settings, context }) => {
	const {
		controls: { FieldRow },
	} = context;
	const { userGroups, userGroupValue } = settings;
	
	let label = access === 'disabled' ? $.__('Disabled') : $.__('Enabled');
	if (userGroups && Array.isArray(userGroups) && userGroups.length > 1 && userGroupValue) {
		const group = userGroups.find((g) => g.value === userGroupValue);
		if (group) {
			label = group.text;
		}
	}
	return (
		<FieldRow context={context} props={{}} settings={{}}>
			<span className='DynamicForm--employeeAccess__noAccess'>{label}</span>
		</FieldRow>
	);
};

export default EmployeeAccessDisabled;
