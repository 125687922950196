import { getJsonData } from 'Data.util';
import { createLogger } from '@utils/dev-logger';
import { showQueryStringMessage } from './query-string-message';
import { initializeBambooAlerts, displaySessionMessage } from './session-message'; 

const DEV_ALERT_LOGGER = createLogger('Alerts.mod');
const DEFAULT_MESSAGE = 'See Alerts.mod/index.js for more info.'

/*
	We are setting these functions up to allow for a silent failure. If you want to utilize alerts
	in your feature be sure to include <div id="sessionMessage"></div>. This element is included 
	automatically in most page headers.
*/
window.setMessage = () => DEV_ALERT_LOGGER.warn(`setMessage was called before it was initialized. ${ DEFAULT_MESSAGE }`);
window.closeMessage = () => DEV_ALERT_LOGGER.warn(`closeMessage was called before it was initialized. ${ DEFAULT_MESSAGE }`);

document.addEventListener('DOMContentLoaded', () => {
	// Note: Keep the node in the DOM for use by other apps (ie. fe-greenhouse)
	const sessionMessageData = getJsonData('#sessionMessageJSON', true);

	initializeBambooAlerts();
	displaySessionMessage(sessionMessageData);
	showQueryStringMessage();
});


export function showSlidedown(...args) {
	window.setMessage(...args);
}

export function hideSlidedown(...args) {
	window.closeMessage(...args);
}
