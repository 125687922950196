import React, {
	useState,
} from 'react';

import { Modal } from 'modal-legacy';

import { EmploymentStatusModalContent } from './employment-status-modal-content';
import {
	postEmploymentStatus,
	reloadPage,
	wasSaveSuccessful,
} from './utils';

/**
 * @param {array} props.acaStatuses
 * @param {function} props.closeModal
 * @param {object} props.initialValues
 * @param {boolean} props.isEdit
 * @param {boolean} props.isOpen
 * @param {number} props.listId
 * @param {number} props.listValueId
 * @param {boolean} props.showAcaStatus
 * @param {boolean} props.showChangeHistory
 */
export function EmploymentStatusModal(props) {
	const {
		acaStatuses,
		closeModal,
		initialValues,
		isEdit,
		isOpen,
		listId,
		listValueId,
		showAcaStatus,
		showChangeHistory = false,
	} = props;

	const [isProcessing, setIsProcessing] = useState(false);

	const postFormData = (formValues) => {
		postEmploymentStatus(isEdit, listId, listValueId, formValues)
			.then((response) => {
				if (wasSaveSuccessful(response)) {
					reloadPage();
				} else {
					window.setMessage(response.data.error || window.DEFAULT_ERROR_MESSAGE, 'error');
					setIsProcessing(false);
				}
			})
			.catch((err) => {
				setIsProcessing(false);
				window.Rollbar.error('Failed to save employment status', err);
			});
	};

	const handleSave = () => {
		setIsProcessing(true);
	};

	return (
		<Modal
			isOpen={ isOpen }
			isProcessing={ isProcessing }
			onClose={ closeModal }
			primaryAction={ handleSave }
			primaryActionText={ $.__('Save') }
			title={ isEdit ? $.__('Edit Employment Status') : $.__('New Employment Status') }
		>
			<EmploymentStatusModalContent
				acaStatuses={ acaStatuses }
				initialValues={ initialValues }
				isEdit={ isEdit }
				isProcessing={ isProcessing }
				postFormData={ postFormData }
				setIsProcessing={ setIsProcessing }
				showAcaStatus={ showAcaStatus }
				showChangeHistory={ showChangeHistory }
			/>
		</Modal>
	);
}
