function open(errors) {
	openModal(errors);
}

function openModal(errors) {
	window.BambooHR.Modal.setState({
		isOpen: true,
		alternativeAction: null,
		primaryAction() {
			window.BambooHR.Modal.setState({ isOpen: false });
		},
		icon: 'fab-money-circle-54x54',
		iconColor: 'danger',
		headline: $.__('Before including this employee in payroll you need to correct the following information.'),
		dangerousContent: microTemplate($('#includeInPayrollErrorModalTemplate').html(), {'errors': errors}),
		primaryActionText: $.__('OK, Got It'),
		title: $.__("Oops, that won't work...")
	});
}

export default {
	open: open
};
