import { makeStyles } from '@bamboohr/fabric';
import { hasTrax } from 'BambooHR.util';
import {
	Fields, fixValidationRegisterName, getField, getSelectedValue, handleControlledInput, isVisible
} from 'dynamic-form';
import EmploymentStatusModal from 'Employees.mod/Job/EmploymentStatusModal';
import { FunctionComponent, useEffect, useMemo } from 'react';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	note: {
		color: palette.gray[700],
		fontSize: typography?.teenie?.fontSize,
		lineHeight: typography?.teenie?.lineHeight,
		marginTop: spacing(1),
	}
}));

const SelectEmploymentStatusField: FunctionComponent<DynamicForm.SelectElementProps> = (selectProps) => {
	const { props, settings, context } = selectProps;
	const {
		controls: { SelectField },
		setFormData,
		validation: { setValue },
	} = context;

	const selectedEmploymentStatus = getSelectedValue(selectProps);
	const TaxTypeField = getField(context, Fields.employeeTaxType);

	const updateTaxType = (result, forceNewSelection, warningType) => {
		let resultSelectedItem: string;
		const resultItems: DynamicForm.SelectElementItems = result.data.options.map((option: { optionValue: string; optionText: string; selected: boolean; }) => {
			const {
				optionValue,
				optionText,
				selected,
			} = option;

			if (selected) {
				resultSelectedItem = optionValue.toString();
			}

			return { value: optionValue.toString(), text: optionText };
		});

		// Update the items on the Tax Type Field from what we got from the ajax call
		const newTaxTypeField = handleControlledInput('select-items', TaxTypeField?.props?.id, resultItems, context, false) as unknown as DynamicForm.SelectElement;

		const taxTypeFieldElement = document.getElementById(newTaxTypeField?.props?.id);
		if (isVisible(taxTypeFieldElement)) {
			// only update these if the element is visible
			newTaxTypeField.props.condition = 'info';
			// The `handleControlledInput` will automatically set the selected value if still in the list. So, here we override or fill it in if empty.
			if (forceNewSelection || newTaxTypeField.props.selectedValues.length === 0) {
				newTaxTypeField.props.selectedValues = [resultSelectedItem];
			}

			// we are manually overriding the TT -> Update the validation value to match
			const taxTypeValidationName = fixValidationRegisterName(newTaxTypeField?.props?.name);
			setValue(taxTypeValidationName, newTaxTypeField?.props?.selectedValues[0] || '', {
				shouldDirty: true,
				shouldValidate: true,
			});

			newTaxTypeField.settings.note = {
				text: ' ',
				type: warningType,
			};
		}

		setFormData(previousFormData => ({ ...previousFormData, ...{ [newTaxTypeField.props.id]: newTaxTypeField } }));
	};

	const resetTaxType = () => {
		const taxTypeField = getField(context, Fields.employeeTaxType) as DynamicForm.SelectElement;
		if (taxTypeField.settings.note) {
			taxTypeField.settings.note = null;
			taxTypeField.props.condition = null;
			setFormData(previousFormData => ({ ...previousFormData, ...{ [taxTypeField.props.id]: taxTypeField } }));
		}
	};

	const getTaxTypeOptions = () => {
		if (selectedEmploymentStatus) {
			// There is a valid ES Selected -> Get new list of TT
			EmploymentStatusModal.getAllowableEmployeeTaxTypes('0', selectedEmploymentStatus, `select[name="${ TaxTypeField?.props?.name }"]`)
				.then(({ result, forceNewSelection, warningType }) => {
					updateTaxType(result, forceNewSelection, warningType);
				})
				.catch(() => {
					// do nothing - Warning is already being thrown in the EmploymentStatusModal
				});
		} else {
			// The ES was cleared. We can also clear the note.
			setTimeout(() => {
				// make sure this is run after the select clear has updated state (field is now empty!)
				resetTaxType();
			}, 10);
		}
	};

	const isInactiveStatus = useMemo(() => {
		return props.items.some(({ props, value }) => {
			const isInactiveOption = !!(typeof props === 'object' && props['data-inactiveoption']);
			return isInactiveOption && value === selectedEmploymentStatus;
		});
	}, [selectedEmploymentStatus]);

	useEffect(() => {
		if (hasTrax() && TaxTypeField?.props?.id) {
			getTaxTypeOptions();
		}
	}, [selectedEmploymentStatus]);

	const classes = useStyles();

	return (
		<>
			<SelectField { ...selectProps } />
			{
				isInactiveStatus ? (
					<div className={ classes.note }>{ $.__('This employee will not be able to access their BambooHR account while in this employment status.') }</div>
				) : null
			}
		</>
	);
};

export default SelectEmploymentStatusField;
