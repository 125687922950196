const instances = new WeakMap();

export default class Sticky {
	static getInstance(element) {
		return instances.get(element);
	}

	/**
	 * Constructor for Sticky - a module that causes an element
	 * to stick to a fixed location when a condition is met on upon scroll
	 *
	 * Options :
	 *
	 *   'alwaysStick'
	 *     Type: boolean
	 *     Default: false
	 *
	 *     Description: Flag that indicates if the anchor should always be sticky or not
	 *
	 *   'anchor'
	 *     Type: object
	 *     Default: If not set the 'shouldStick' option callback is required
	 *
	 *     Description: DOM node to stick main element to
	 *
	 *   'immediate'
	 *     Type: boolean
	 *     Default: false
	 *
	 *     Description: Flag that indicates if the class should update stickiness upon instantiation
	 *
	 *   'shouldStick'
	 *     Type: function
	 *     Default: Required if 'anchor' is not specified.
	 *
	 *     Description: Callback that should return a boolean indicating if Sticky
	 *     should make the main element sticky.
	 *
	 *    'shouldUnstick'
	 *     Type: function
	 *     Default: Required if 'anchor' is not specified.
	 *
	 *     Description: Callback that should return a boolean indicating if Sticky
	 *     should no longer make the main element sticky.
	 *
	 *   'onBeforeStick'
	 *     Type: function
	 *     Default: Noop
	 *
	 *     Description: Callback that is invoked just before the main element is made sticky
	 *
	 *   'onAfterStick'
	 *     Type: function
	 *     Default: Noop
	 *
	 *     Description: Callback that is invoked just after the main element is made sticky
	 *
	 *   'onBeforeUnstick'
	 *     Type: function
	 *     Default: Noop
	 *
	 *     Description: Callback that is invoked just before the main element is no longer made sticky
	 *
	 *   'onAfterUnstick'
	 *     Type: function
	 *     Default: Noop
	 *
	 *     Description: Callback that is invoked just after the main element is no longer made sticky
	 *
	 * @param  {object} element  Element to make sticky
	 * @param  {object} settings Configuration object
	 * @return {object}          Sticky class instance
	 */
	constructor(element, settings = {}) {
		this._element = element;
		this._settings = { anchor: window, ...settings };
		this._stuck = false;

		this._verifySettings();

		if (!this._settings.alwaysStick) {
			this._wireEvents();
		}

		this._addSpacerElement();

		instances.set(this._element, this);

		if (this._settings.alwaysStick || this._settings.immediate) {
			this._updateStickiness();
		}
	}

	get _elementRect() {
		return this._getRect(this._element);
	}

	get _spacerElementRect() {
		return this._getRect(this._spacerElement);
	}

	get _anchorElementRect() {
		return this._getRect(this._settings.anchor);
	}

	/*----------------------------------
	* Public Methods
	* ----------------------------------*/

	/**
	 * Fixes the element to a set location on the page so that it doesn't move
	 * with its sibling elements
	 */
	stick() {
		this._adjustSpacerHeight();

		this._element.style.position = 'fixed';

		if (this._settings.anchor) {
			this._positionAtAnchor();
		}

		this._stuck = true;

		if (this._watchHeight && !this._watchingHeight) {
			this._watchHeight();
		}
	}

	/**
	 * Allows the element to again move with its sibling elements
	 */
	unStick() {
		this._adjustSpacerHeight();

		this._element.style.position = '';

		this._stuck = false;
		this._watchingHeight = false;
	}

	/**
	 * Removes any bound event handlers along with any DOM elements added by this class
	 */
	destroy() {
		if (this._spacerElement && this._spacerElement.parentNode) {
			this._spacerElement.parentNode.removeChild(this._spacerElement);
		}

		this._unwireEvents();
	}

	_removeSpacerElement() {
		if (this._spacerElement?.parentNode) {
			this._spacerElement.parentNode.removeChild(this._spacerElement);
		}
		this._spacerElement = null;
	}


	/*----------------------------------
	* Private/Protected Methods
	* ----------------------------------*/

	/**
	 * Adds a spacer element to the same window location as the main element.
	 * This prevents subsequent elements from being swallowed when the main element
	 * is position: fixed.
	 */
	_addSpacerElement() {
		const { spacerCSSClass } = this._settings;
		const element = this._element;
		const spacerElement = this._spacerElement = document.createElement('div');

		if (spacerCSSClass) {
			spacerElement.classList.add(spacerCSSClass);
		}

		element.parentNode.insertBefore(spacerElement, element);
		spacerElement.appendChild(element);
	}

	/**
	 * Sets the spacer element's height to the same height as the anchor element
	 */
	_adjustSpacerHeight() {
		if (this._stuck) {
			this._spacerElement.style.height = `${ this._elementRect.height }px`;
		} else {
			this._spacerElement.style.height = '';
		}
	}

	/**
	 * Adds event handlers bound by this instance
	 */
	_wireEvents() {
		this._boundScrollHandler = this._scrollHandler.bind(this);
		window.addEventListener('scroll', this._boundScrollHandler);
	}

	/**
	 * Removes event handlers bound by this instance
	 */
	_unwireEvents() {
		window.removeEventListener('scroll', this._boundScrollHandler);
		this._boundScrollHandler = null;
	}

	/**
	 * Checks if the main element needs to stick to a set location in the window
	 * @return {boolean} True if should stick, else, false.
	 */
	_shouldStick() {
		const { anchor, shouldStick } = this._settings;

		if (typeof shouldStick === 'function') {
			return shouldStick(this._element, this._elementRect);
		}

		if (!anchor) {
			console.warn(`Could not determine when to stick element ${ this._element }`);
			return false;
		}

		return this._spacerElementRect.top < this._anchorElementRect.bottom && this._isFixed(anchor);
	}

	/**
	 * Checks if the main element no longer needs to stick to its current location
	 * @return {boolean} True if should unstick, else, false.
	 */
	_shouldUnstick() {
		const { shouldUnstick } = this._settings;

		if (typeof shouldUnstick === 'function') {
			return shouldUnstick(this._element, this._elementRect);
		}

		return this._elementRect.top <= this._spacerElementRect.top;
	}

	/**
	 * Gets a "rect" object from the node
	 * @param  {object} node The node to get a rect from
	 * @return {object} The "rect" object for the node
	 */
	_getRect(node) {
		if (node === window) {
			return {
				bottom: 0,
				height: 0,
				left: 0,
				right: 0,
				top: 0,
				width: 0,
			};
		}

		return node.getBoundingClientRect();
	}

	/**
	 * Tests if the supplied node is `position: fixed`
	 * @param  {object}  node The node to inspect
	 * @return {boolean} true if the position's value is fixed
	 */
	_isFixed(anchor) {
		if (anchor === window) {
			return true;
		}

		const position = window
			.getComputedStyle(anchor)
			.getPropertyValue('position');

		return position === 'fixed';
	}

	/**
	 * Positions the element at the bottom of an anchor element
	 */
	_positionAtAnchor() {
		this._element.style.top = `${ this._anchorElementRect.bottom }px`;
	}

	/**
	 * Updates the position of the table header based on the position of the element
	 */
	_updateStickiness() {
		const {
			alwaysStick,
			onAfterStick,
			onAfterUnstick,
			onBeforeStick,
			onBeforeUnstick,
		} = this._settings;

		if (alwaysStick || this._shouldStick()) {
			if (typeof onBeforeStick === 'function') {
				onBeforeStick();
			}

			this.stick();

			if (typeof onAfterStick === 'function') {
				onAfterStick();
			}
		} else if (this._shouldUnstick()) {
			if (typeof onBeforeUnstick === 'function') {
				onBeforeUnstick();
			}

			this.unStick();

			if (typeof onAfterUnstick === 'function') {
				onAfterUnstick();
			}
		}

		this._triggerUpdateEvent();
	}

	/**
	 * Triggers a custom DOM event that lets the app know that stickiness was updated for `this._element`
	 */
	_triggerUpdateEvent() {
		this._element.dispatchEvent(new CustomEvent('ba:stickyUpdate', {
			bubbles: true,
			detail: {
				stuck: this._stuck,
			},
		}));
	}

	/**
	 * Verfies the settings object has the correct shape and values
	 */
	_verifySettings() {
		const { anchor, shouldStick } = this._settings;

		if (!this._element) {
			throw new Error('An element to make sticky must be provided.');
		}

		if (!anchor && typeof shouldStick !== 'function') {
			throw new Error('An element anchor option or shouldStick callback option is required to use Sticky.');
		}
	}

	/**
	 * Watches the height of the anchor and adjusts the spacer when the two elements have different heights
	 */
	_watchHeight() {
		if (this._watchingHeight) {
			return;
		}

		if (this._spacerElement.height !== this._anchorElementRect) {
			this._adjustSpacerHeight();
		}

		window.requestAnimationFrame(() => this._watchHeight());
	}

	/*----------------------------------
	* Event Handlers
	* ----------------------------------*/

	/**
	 * Scroll event listener responsible for triggering the stick behavior of
	 * this class
	 */
	_scrollHandler() {
		this._updateStickiness();
	}
}
