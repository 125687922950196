/**
 * The selectors for the footer
 *
 * @type Object
 */
var selectors = {
	footer: '.js-simplemodal-footer',
	footerWrapper: '.js-simplemodal-footer-wrapper'
};

/**
 * Simple Modal Footer Object
 */
var Footer = {
	/**
	 * Hide the header
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 */
	hide($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.footer).hide();
	},

	/**
	 * Show the header
	 *
	 * @param {wrapper} $modal (Optional) The wrapper for the given modal. If omitted, the top most modal will be given the title change (Top meaning highest z-index)
	 */
	show($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.footer).show();
	}
};


import Buttons from './Footer/Buttons';
Footer.Buttons = Buttons;
export {Buttons};

import Text from './Footer/Text';
Footer.Text = Text;
export {Text};


export default Footer;
