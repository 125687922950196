import { Iterable } from 'immutable';
import { each } from 'lodash';
import { getType } from './types';

/**
 * Explicitly set an immutable Iterables children to be a specific Record type
 * @param  {Object}  immutableData          Usually an immutable object created with fromJS
 * @param  {Object}  recordType             A Record model object describing the immutable record
 * @param  {Array}   [entry=null]           The immutable object to set records into ie. ['animals']
 * @param  {Boolean} [numericIndexes=true]  This converts the indexes to use numbers instead of strings
 * @return {Object}                         New updated immutable data
 */
export function setImmutableRecord(immutableData, recordType, entry = null, numericIndexes = true) {
	if (!Iterable.isIterable(immutableData)) {
		throw new Error('immutableData parameter must be an immutable data object.');
	}
	
	const parent = findPointer(immutableData, entry);
	const entryType = getType(parent);

	const records = parent.reduce((carry, value, key) => {
		if (numericIndexes) {
			key = parseInt(key);
		}
		return carry.set(key, new recordType(value));
	}, new entryType());
	
	if (entry) {
		return immutableData.setIn(entry, records);
	}

	return records;
}

/**
 * Set an existing immutable object to an explicitly declared type
 * @param {Object} immutableData  Usually an immutable object created with fromJS
 * @param {Object} [map={}]       An object containing entity mappings {'animal, allIds': OrderedSet }
 */
export function setImmutableTypes(immutableData, map = {}) {
	let updatedData = immutableData;

	each(map, (value, key) => {
		const lookup = key.replace(/\s+/g, '').split(',');
		const lookupValue = updatedData.getIn(lookup);
		updatedData = updatedData.setIn(lookup, new value(lookupValue));
	});

	return updatedData;
}

/**
 * Maps multiple records to an objects children
 * @param  {Object}  immutableData          Usually an immutable object created with fromJS
 * @param  {Object}  [map={}]               An object containing entity to record mappings { 'animal': Animal } or {'animal, byId': Animal }
 * @param  {Boolean} [numberedIndexes=true] This converts the indexes to use numbers instead of strings
 * @return {Object}                         New updated immutable data
 */
export function mapImmutableRecordToChildren(immutableData, map = {}, numberedIndexes = true) {
	let updatedData = immutableData;

	each(map, (value, key) => {
		let lookup = key.replace(/\s+/g, '').split(',');
		updatedData = setImmutableRecord(updatedData, value, lookup, numberedIndexes);
	});

	return updatedData;
}

/**
 * Detect the startPoint based on a passed selector
 * @param  {[type]} data            The data to be evaluated
 * @param  {String} [selector=null] The identifier for the object to point to
 * @return {Object}                 The Immtuable Object pointer
 */
function findPointer(data, selector = null) {
	if (selector !== null) {
		if (selector !== '' && Iterable.isIterable(data.getIn(selector))) {
			return data.getIn(selector);
		}
		throw new Error(`The immutable entry ${ selector } passed either doesn\'t exist or is malformed.`);
	}

	return data;
}
