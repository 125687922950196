import { min, max } from 'd3-array';
import { axisLeft } from 'd3-axis';
import { scaleLinear, scaleBand } from 'd3-scale';
import { select } from 'd3-selection';
import { area, line } from 'd3-shape';
import { defaultsDeep } from 'lodash';
import { colors } from '@bamboohr/fabric/dist/definitions/json/colors.json';

import { mouseOver } from 'Charts.mod/event-functions';

import BaseChart from './base-chart';
import Legend from './shared/legend';

import './line-chart.styl';

const CLASS_NAME = 'LineChart';
const DEFAULT_SETTINGS = {
	chartClass: '',
	container: {
		height: 100,
		margin: {
			bottom: 0,
			left: 0,
			right: 0,
			top: 0,
		},
		width: 214,
	},
	drawDotsAlongLines: true,
	fadedLineOpacity: 0.2,
	hasSelectableColumns: false,
	lineToBringToFrontId: null,
	numberOfYAxisTicks: 4,
	onSelectableAreaClick: null,
	pathStrokeWidth: 4,
	partialValueColor: '#afafaf',
	partialValueLightColor: '#c0c0c0',
	pointColorClassName: 'LineChart--strokeThemeColor',
	pointLightColorClassName: 'LineChart--strokeLightThemeColor',
	pointLightColorEnabled: true,
	pointRadius: 5,
	pointStrokeWidth: 3,
	pointSubtitles: {
		height: 16,
		formatter: null,
	},
	pointTitles: {
		height: 18,
		formatter: null,
	},
	yAxisLabelWidth: 0,
	yDomainRangeMax: 10,
	animate: !window.navigator.webdriver, /** On headless chrome default is false elsewhere it is true */
};
export default class LineChart extends BaseChart {
	constructor(selector, settings = DEFAULT_SETTINGS) {
		super();

		this._selector = selector;
		this._CLASS_NAME = CLASS_NAME;
		this._settings = defaultsDeep({}, settings, DEFAULT_SETTINGS);
		this._barVerticalOffset = this._settings.container.height - this._settings.pointTitles.height - this._settings.pointSubtitles.height;
		this._xTitlesVerticalOffset = this._barVerticalOffset + 1;
		this._xSubtitlesVerticalOffset = this._xTitlesVerticalOffset + this._settings.pointTitles.height;
		this._minPointVerticalOffset = this._barVerticalOffset - 16;

		this._validateRequirements();
		this._createRoot();
		this._configure();
	}

	updateSettings(settings) {
		this._settings = defaultsDeep({}, settings, DEFAULT_SETTINGS);
	}

	draw(data) {
		this._data = data;

		const linesData = data.lines ? data.lines : this._prepLinesData(data);

		const xAxisTitlesPrep = data.xAxisTitles
			? data.xAxisTitles
			: this._prepTitlesData(data, 'title');
		this._xAxisTitlesData = this._settings.pointTitles.formatter
			? this._settings.pointTitles.formatter(xAxisTitlesPrep)
			: xAxisTitlesPrep;

		const xAxisSecondaryTitlesPrep = data.xAxisSecondaryTitles
			? data.xAxisSecondaryTitles
			: this._prepTitlesData(data, 'subtitle');
		const xAxisSecondaryTitlesData = this._settings.pointSubtitles.formatter
			? this._settings.pointSubtitles.formatter(xAxisSecondaryTitlesPrep)
			: xAxisSecondaryTitlesPrep;

		const allYPoints = linesData
			.map(val => val.points.map(val => val?.y))
			.reduce((acc, val) => acc.concat(val));
		const minYPoint = min(allYPoints);
		const maxYPoint = max(allYPoints);
		const minYPadded = Math.floor(minYPoint / 10) * 10;
		const maxYPadded = Math.ceil(maxYPoint / 10) * 10;

		this._xScale = this._getXScale();
		this._xScaleUsingX = (d, index) => this._xScale((data.xAxisPositionMap && d?.x) ? data.xAxisPositionMap.get(d.x) : index);
		const yMin = this._settings.yDomainMin || minYPadded;
		const yMax = this._settings.yDomainMax || maxYPadded;
		this._yScale = this._getYScale(yMin, yMax);
		this._minY = yMin;

		if (this._settings.hasSelectableColumns) this._drawSelectableAreas();
		this._drawLines(linesData);
		this._drawXAxisTitles();
		this._drawXAxisSecondaryTitles(xAxisSecondaryTitlesData);
		this._drawYAxis();
		this._drawLegend(data.legend);
	}

	resize() {
		const svg = this._svg;
		svg.style('width', null);

		const newWidth = svg.node().parentNode.clientWidth;
		svg.style('width', `${ newWidth }px`);

		if (!this.hasAxis()) {
			svg.select(this._getClassList('__divider')).attr('x2', newWidth);
		}

		this.draw(this._data);
	}

	/**
	 * Method that returns an animated version of the provided elements
	 * @param {*} elements elements to animate
	 * @param {*} duration duration of animation in milliseconds
	 * @returns Animated elements
	 */
	_animateElements(elements, duration) {
		if (this._settings.animate) {
			return elements
				.transition()
				.duration(duration);
		}
		return elements;
	}

	_configure() {
		if (!this.hasAxis()) {
			this._bar = this._svg.append('line')
				.style('stroke', '#AFAFAF')
				.style('shape-rendering', 'crispEdges')
				.attr('class', this._buildClassList('__divider'))
				.attr('x1', 0)
				.attr('x2', this._getContainerWidth())
				.attr('y1', this._barVerticalOffset)
				.attr('y2', this._barVerticalOffset);
		}

		this._xTitles = this._svg.append('g')
			.style('font-size', '12px')
			.style('fill', '#adadad')
			.attr('class', this._buildClassList('__titles'))
			.attr('text-anchor', 'middle')
			.attr('transform', `translate(0, ${ this._xTitlesVerticalOffset })`);

		this._xSubtitles = this._svg.append('g')
			.style('font-size', '10px')
			.style('fill', '#AFAFAF')
			.attr('class', this._buildClassList('__subtitles'))
			.attr('text-anchor', 'middle')
			.attr('transform', `translate(0, ${ this._xSubtitlesVerticalOffset })`);

		if (this._settings.legend) {
			const legendContainer = select(this._selector).append('div')
				.attr('class', this._buildClassList('__legend'));
			const settings = { onClick: this._handleLegendClick, ...this._settings.legend };
			this._legend = new Legend(legendContainer.node(), settings);
		}
	}

	_handleLegendClick(d) {
		const toggledActive = !d.active;
		const display = toggledActive ? 'none' : 'initial';
		// Hide or show the element based on the ID
		const id = `#tag${ d.color.slice(1) }_${ d.keyOrder }`;// Todo: need to determine a more reliable id or ideally get from the BE data
		const element = select(id);
		this._animateElements(element, 100)
			.style('display', display);
		// Update whether or not the element is active
		d.active = toggledActive;
	}

	_prepLinesData(data) {
		if (Array.isArray(data)) {
			return [{ points: data }];
		}
		if (!Array.isArray(data) && typeof data === 'object' && data.points) {
			return [data];
		}
	}

	_prepTitlesData(data, prop) {
		if (Array.isArray(data)) {
			return data.map(val => val[prop]);
		}
		if (!Array.isArray(data) && typeof data === 'object' && data.points) {
			return data.points.map(val => val[prop]);
		}
	}

	_getXScale() {
		const data = this._xAxisTitlesData;
		return scaleBand()
			.domain(data.map((d, i) => i))
			.range([this._settings?.yAxisLabelWidth ?? 0, this._getContainerWidth()])
			.round(true)
			.paddingOuter(0.4)
			.paddingInner(1);
	}

	_getYScale(minY, maxY) {
		return scaleLinear()
			.domain([minY, maxY])
			.range([this._minPointVerticalOffset, this._settings.yDomainRangeMax]);
	}

	_getLineColor({ color, displayType, alternateColors }, index) {
		const settings = this._settings;
		if (settings.colors) {
			color = settings.colors[index];
		}
		if (displayType === 'default' || displayType === undefined) return color ? color : '';
		if (displayType === 'faded') return alternateColors ? alternateColors.faded : '';
		return '';
	}

	_getAlternatePointStyles(d) {
		const {
			alternatePointStyleKeys,
			alternatePointStyles
		} = this._settings;
		const alternateKey = alternatePointStyleKeys?.find(k => d[k]);
		return alternatePointStyles && alternatePointStyles[alternateKey];
	}

	_getPointFill(d) {
		const {
			alternatePointStyle,
			alternatePointStyleKey,
		} = this._settings;
		const _pointFill = (
			d[alternatePointStyleKey] &&
			(alternatePointStyle?.pointFill)
		) || this._getAlternatePointStyles(d)?.pointFill || '';
		return _pointFill;
	}

	_getPointRadius(d) {
		const {
			alternateLineStyle,
			alternateLineStyleKey,
			alternatePointStyle,
			alternatePointStyleKey,
			pointRadius,
		} = this._settings;

		const alternateLineStylePointRadius = d && d[alternateLineStyleKey] &&
			alternateLineStyle?.pointRadius;

		const alternatePointStylePointRadius = (d && d[alternatePointStyleKey] &&
			alternatePointStyle?.pointRadius) || this._getAlternatePointStyles(d)?.pointRadius;

		const _pointRadius = alternateLineStylePointRadius ||
			alternatePointStylePointRadius ||
			pointRadius;

		return _pointRadius;
	}

	_getPointStrokeWidth(d) {
		const {
			alternatePointStyle,
			alternatePointStyleKey,
			pointStrokeWidth,
		} = this._settings;
		const _pointStrokeWidth = d[alternatePointStyleKey] && (
			alternatePointStyle.pointStrokeWidth || this._getAlternatePointStyles(d)?.pointStrokeWidth || pointStrokeWidth
		);
		return _pointStrokeWidth;
	}

	_drawLines(linesData) {
		const lineGroups = this._drawLineGroups(linesData);
		this._drawCircleGroups(lineGroups);
	}

	_drawLineGroups(linesData) {
		const svg = this._svg;
		const settings = this._settings;

		const lineGroupUpdate = svg.selectAll(this._getClassList('__lineGroup'))
			.style('display', 'initial')
			.data(linesData, lineData => lineData.id);

		const lineGroupEnter = lineGroupUpdate
			.enter()
			.append('g')
			.attr('class', this._buildClassList('__lineGroup'))
			.attr('id', (d, i) => {
				if (d.id) return d.id;

				const color = this._getLineColor(d, i);
				return color ? `tag${ color.slice(1) }_${ i }` : '';
			});

		const lineGroupMerge = lineGroupUpdate.merge(lineGroupEnter);

		const initialLineGen = line()
			.x((d, i) => this._xScale(i))
			.y(() => this._yScale(this._minY));

		const lineGen = line()
			.defined((d, i) => d !== undefined && d !== null)
			.x(this._xScaleUsingX)
			.y((d, i) => this._yScale(d.y));

		lineGroupEnter.append('path')
			.attr('class', `${ 'LineChart--strokeThemeColor' } ${ this._buildClassList('__line') }`)
			.style('fill', 'none')
			.attr('pointer-events', 'none')
			.style('stroke', (d, i) => this._getLineColor(d, i))
			.style('stroke-width', `${ settings.pathStrokeWidth }px`)
			.attr('d', d => initialLineGen(d.points));

		const lineElements = lineGroupMerge.select(this._getClassList('__line'))
			.style('stroke', (d, i) => this._getLineColor(d, i));
		this._animateElements(lineElements, 300).attr('d', d => lineGen(d.points));

		lineGroupMerge.raise();

		if (settings.lineToBringToFrontId !== null) {
			svg.select(`${ this._getClassList('__lineGroup') }[id="${ settings.lineToBringToFrontId }"]`)
				.raise();
		}

		lineGroupUpdate.exit().remove();

		return {
			lineGroupUpdate,
			lineGroupEnter,
		};
	}

	_drawCircleGroups({ lineGroupEnter, lineGroupUpdate }) {
		const settings = this._settings;

		const circleGroupEnter = lineGroupEnter.append('g')
			.attr('class', `${ settings.pointColorClassName } ${ this._buildClassList('__circleGroup') }`)
			.attr('fill', (d, i) => this._getLineColor(d, i) || '#fff')
			.style('stroke', (d, i) => this._getLineColor(d, i))
			.style('stroke-width', `${ settings.pointStrokeWidth }px`);

		const circleGroupUpdate = lineGroupUpdate.select(this._getClassList('__circleGroup'));

		const circleGroupMerge = circleGroupUpdate.merge(circleGroupEnter);

		circleGroupMerge
			.style('stroke', (d, i) => this._getLineColor(d, i))
			.style('fill', (d, i) => this._getLineColor(d, i));

		this._drawCircles(circleGroupMerge);
	}

	_drawCircles(circleGroupMerge) {
		const settings = this._settings;

		const innerCircleGroupUpdate = circleGroupMerge
			.selectAll(this._getClassList('__innerCircleGroup'))
			.data(d => d.points);

		const innerCircleGroupEnter = innerCircleGroupUpdate
			.enter()
			.append('g')
			.attr('class', this._buildClassList('__innerCircleGroup'));

		innerCircleGroupEnter
			.append('circle')
			.attr('r', (d, i, collection) => {
				if (settings.drawDotsAlongLines) return this._getPointRadius(d);

				if (i === collection.length - 1) return this._getPointRadius(d);
				return 0;
			})
			.attr('data-tip-z-index', 'auto')
			.attr('class', this._buildClassList('__circle'))
			.attr('pointer-events', settings.hasSelectableColumns ? 'none' : 'visiblePainted')
			.attr('cx', this._xScaleUsingX)
			.attr('cy', d => this._yScale(this._minY));

		innerCircleGroupEnter
			.append('circle')
			.attr('r', (d) => {
				if (d?.displayType !== 'hollow') return 0;
				return 3;
			})
			.attr('data-tip-z-index', 'auto')
			.attr('class', this._buildClassList('__innerCircle'))
			.attr('fill', '#fff')
			.attr('stroke', 'none')
			.attr('stroke-width', 0)
			.attr('pointer-events', settings.hasSelectableColumns ? 'none' : 'visiblePainted')
			.attr('cx', this._xScaleUsingX)
			.attr('cy', d => this._yScale(this._minY));

		const innerCircleGroupMerge = innerCircleGroupUpdate.merge(innerCircleGroupEnter);

		innerCircleGroupMerge
			.select(this._getClassList('__circle'))
			.on('mouseenter', function enter(d) {
				const selection = select(this);
				if (d?.displayType === 'partial') {
					selection.style('stroke', settings.partialValueLightColor);
				} else if (settings.pointLightColorEnabled) {
					selection.classed(settings.pointLightColorClassName, true);
					selection.classed(settings.pointColorClassName, false);
				}
			})
			.on('mouseleave', function leave(d) {
				const selection = select(this);
				if (d?.displayType === 'partial') {
					selection.style('stroke', settings.partialValueColor);
				} else if (settings.pointLightColorEnabled) {
					selection.classed(settings.pointLightColorClassName, false);
					selection.classed(settings.pointColorClassName, true);
				}
			})
			.on('mouseover', mouseOver)
			.filter(d => d?.displayType === 'partial')
			.style('stroke', settings.partialValueColor);

		const circleElements = innerCircleGroupMerge
			.select(this._getClassList('__circle'))
			.attr('r', (d, i, collection) => {
				if (settings.drawDotsAlongLines) return this._getPointRadius(d);

				if (i === collection.length - 1) return this._getPointRadius(d);
				return 0;
			});
		this._animateElements(circleElements, 300)
			.attr('visibility', d => (!d ? 'hidden' : ''))
			.attr('cx', this._xScaleUsingX)
			.attr('cy', d => (d ? this._yScale(d.y) : this._yScale(this._minY)));

		const innerCircleElements = innerCircleGroupMerge
			.select(this._getClassList('__innerCircle'))
			.attr('r', (d) => {
				if (d?.displayType !== 'hollow') return 0;
				return 3;
			});
		this._animateElements(innerCircleElements, 300)
			.attr('cx', this._xScaleUsingX)
			.attr('cy', d => (d ? this._yScale(d.y) : this._yScale(this._minY)));

		innerCircleGroupUpdate.exit().remove();
	}

	_drawXAxisTitles() {
		if (this._xAxisTitlesData) {
			const { _data: {
				xAxisSelectedTitle
			} } = this;
			this._xTitles.selectAll(this._getClassList('__title')).remove();
			const xAxisTitles = this._xTitles.selectAll(this._getClassList('__title')).data(this._xAxisTitlesData);
			xAxisTitles.enter()
				.append('text')
				.attr('class', (_, i) => {
					if (i === xAxisSelectedTitle) {
						return `${ this._buildClassList('__title') } ${ this._buildClassList('__title--selected') }`;
					}
					return this._buildClassList('__title');
				})
				.attr('y', 16)
				.merge(xAxisTitles)
				.attr('x', (d, i) => this._xScale(i) + this._xScale.bandwidth() / 2)
				.text(d => d);
		}
	}

	_drawXAxisSecondaryTitles(xAxisSecondaryTitlesData) {
		if (xAxisSecondaryTitlesData) {
			const { _data: {
				xAxisSelectedTitle
			} } = this;
			this._xSubtitles.selectAll(this._getClassList('__subtitle')).remove();
			const xAxisSubtitles = this._xSubtitles.selectAll(this._getClassList('__subtitle')).data(xAxisSecondaryTitlesData);
			xAxisSubtitles.enter()
				.append('text')
				.attr('class', (_, i) => {
					if (i === xAxisSelectedTitle) {
						return `${ this._buildClassList('__subtitle') } ${ this._buildClassList('__subtitle--selected') }`;
					}
					return this._buildClassList('__subtitle');
				})
				.attr('y', 12)
				.merge(xAxisSubtitles)
				.attr('x', (d, i) => this._xScale(i) + this._xScale.bandwidth() / 2)
				.text(d => d);
		}
	}

	_drawYAxis() {
		if (this.hasAxis()) {
			const settings = this._settings;
			const yAxis = axisLeft().scale(this._yScale);
			yAxis
				.tickSizeOuter(0)
				.ticks(settings.numberOfYAxisTicks)
				.tickFormat((d, i, a) => {
					const tick = select(a[i]);

					// Reset all ticks with visibility
					tick.style('opacity', 1);

					return d;
				});

			// If axis currently exists just update it, otherwise create it
			const isAxisEmpty = this._g.select(this._getClassList('__yAxis')).empty();
			if (isAxisEmpty) {
				this._g.append('g')
					.attr('class', this._buildClassList('__yAxis'))
					.call(yAxis);

				this._g.attr('transform', `translate(${ settings.yAxisLabelWidth }, 0)`);

				select(this._getClassList('__yAxis'))
					.attr('opacity', 1)
					.style('opacity', 1);
			} else {
				select(this._getClassList('__yAxis'))
					.attr('opacity', 1)
					.call(yAxis);
			}

			select(this._getClassList('__yAxis')).selectAll('line')
				.attr('x2', () => {
					return this._svg.node().parentNode.clientWidth - settings.yAxisLabelWidth;
				})
				.attr('style', (d, i, a) => {
					if (i === 0) {
						return 'stroke-dasharray: 0';
					}
				})
				.attr('pointer-events', 'none');
		}
	}

	_drawLegend(legendData) {
		if (legendData && this._settings.legend) {
			if (this._settings.colors) {
				legendData.forEach((legendElement, index) => {
					legendElement.color = this._settings.colors[index];
				});
			}

			this._legend.draw(legendData);
		}
	}

	_drawHoverCircles(xPointIndex) {
		const svg = this._svg;
		const { lineToBringToFrontId = null } = this._settings;
		const { xAxisPositionMap, xAxisTitles } = this._data;
		const hoverGroup = svg.selectAll(this._getClassList('__hoverAreaGroup'))
			.filter((d, i) => i === xPointIndex);

		const reverseAxisPositionMap = new Map();
		// Generate reverse of position map if provided, so we can map the index to an x value
		if (xAxisPositionMap) {
			const values = Array.from(xAxisPositionMap.values());
			Array.from(xAxisPositionMap.keys()).forEach((value, i) => {
				reverseAxisPositionMap.set(values[i], value);
			});
		}

		this._data.lines.forEach((line) => {
			// If there is an x value use it else use something else
			const hoverX = reverseAxisPositionMap.get(xPointIndex);
			const relevantPoint = hoverX ? line.points.find(p => p && p.x === hoverX) : line.points[xPointIndex];

			let shouldBeBroughtToFront = false;

			if (lineToBringToFrontId && lineToBringToFrontId === line.id) {
				shouldBeBroughtToFront = true;
			}
			if (relevantPoint && (hoverX === undefined || hoverX === relevantPoint.x)) {
				hoverGroup.append('circle')
					.attr('class', `${ this._buildClassList('__hoverCircle') } ${ shouldBeBroughtToFront && 'front' }`)
					.attr('fill', this._getLineColor(line, xPointIndex))
					.attr('pointer-events', 'none')
					.attr('r', 4)
					.attr('cx', this._xScaleUsingX({ x: hoverX }, xPointIndex))
					.attr('cy', this._yScale(relevantPoint.y));
			}
		});

		if (lineToBringToFrontId) {
			svg.select(`${ this._getClassList(`__hoverCircle`) }.front`)
				.raise();
		}
	}

	_removeHoverCircles() {
		const svg = this._svg;

		svg.selectAll(this._getClassList('__hoverCircle'))
			.remove();
	}

	_drawSelectableAreas() {
		const svg = this._svg;

		const hoverAreaContainer = svg.select(this._getClassList('__hoverAreaContainer'));
		if (!hoverAreaContainer.size()) {
			svg
				.append('g')
				.attr('class', this._buildClassList('__hoverAreaContainer'))
				.lower();
		}

		const areaGroupUpdate = svg
			.select(this._getClassList('__hoverAreaContainer'))
			.selectAll(this._getClassList('__hoverAreaGroup'))
			.style('display', 'initial')
			.data(this._xAxisTitlesData.map((d, i) => i));

		const areaGroupEnter = areaGroupUpdate
			.enter()
			.append('g')
			.attr('class', this._buildClassList('__hoverAreaGroup'))
			.style('cursor', 'pointer')
			.attr('id', (d, i) => {
				return `hover-${ i }`;
			});

		areaGroupEnter
			.append('path')
			.classed(this._buildClassList('__hoverArea'), true)
			.style('fill', 'transparent')
			.attr('d', (d, i) => this._getDForHoverArea(i))
			.on('mouseover', (event) => {
				this._drawHoverCircles(event);
				this._hoverArea = event;
				svg.selectAll(this._getClassList('__hoverArea'))
					.filter((d, i) => i === event)
					.style('fill', colors.gray1);
			})
			.on('mouseleave', (event) => {
				svg.selectAll(this._getClassList('__hoverArea'))
					.filter((d, i) => i === event)
					.style('fill', 'transparent');
				this._hoverArea = undefined;
				this._removeHoverCircles();
			})
			.on('click', (event) => {
				const { onSelectableAreaClick } = this._settings;

				if (onSelectableAreaClick) onSelectableAreaClick(event);
			});

		this._removeHoverCircles();
		if (this._hoverArea) {
			this._drawHoverCircles(this._hoverArea);
		}
		const areaGroupMerge = areaGroupUpdate.merge(areaGroupEnter);

		areaGroupMerge.select(this._getClassList('__hoverArea'))
			.attr('d', (d, i) => this._getDForHoverArea(i));

		areaGroupUpdate
			.exit()
			.remove();
	}

	_getDForHoverArea(i) {
		const areaElem = area()
			.x(d => d.x)
			.y0(d => this._yScale(0))
			.y1(d => d.y);
		const generateArea = (points) => areaElem(points.map(x => ({ x, y: 0 })));
		if (i === 0) {
			// If it is the first period, we will need to set the start x to the axisWidth
			const axisWidth = this._settings?.yAxisLabelWidth ?? 0;
			const halfOfWidth = this._xScale(0) - axisWidth;
			const xPoints = [axisWidth, this._xScale(0) + halfOfWidth];
			return generateArea(xPoints);
		}
		const width = this._xScale(i) - this._xScale(i-1);
		const xPoints = [this._xScale(i) - width / 2, this._xScale(i) + width / 2];
		return generateArea(xPoints);
	}
}
