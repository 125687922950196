/**
 *
 * @param {string} className the element's class name you want the modal add new list item added to
 * @param {function} modalFunction the function to open the modal
 * @param {array} params any parameters the modal's function requires
 * @param {string} responseKey the key name for the response data object that contains the new item name and listValueId
 */
function insertListModal(className, modalFunction, params, responseKey, selectText) {
	let txt = selectText ? selectText : $.__('-- Add New --');
	let list = $(className);
	return list.each(function() {
		if ($(this).hasClass('js-insertableListModal-complete')) {
			return;
		}
		$(this).addClass('js-insertableListModal-complete');
		if (this.options) {
			this.appendChild(new Option(txt, 'new'));
			$(this).trigger('liszt:updated');
		}

		$(this).change(showModal.bind(this, modalFunction, params, responseKey));
	});
}

function showModal(openModal, params, responseKey) {
	if ($(this).val() === 'new') {
		openModal(...params, saveModalData.bind(this, responseKey));
	}
}

function saveModalData(responseKey, data) {
	if (data.ret === 'SAVED') {
		let name = data[responseKey].name;
		let id = data[responseKey].listValueId ? data[responseKey].listValueId : data[responseKey].id;

		// insert new "add" option at the end of the list
		let option = new Option(this.options[this.options.length - 1].text, this.options[this.options.length - 1].value);
		this.add(option, null);

		// replace the old "add" option with the newly added option from the modal so it's above the "add" option
		this.options[this.options.length - 2].text = name;
		this.options[this.options.length - 2].value = id;
		this.selectedIndex = this.options.length - 2;

		$(this).trigger('liszt:updated');
		$(this).change();
	}
}

export default {
	insertListModal
};
