import './styles.styl';
import Popover from 'Popover.mod';


/**
 * Singleton, as it does not need to be dynamic, and creating a popover for
 * every single input on the timesheet is unnecessary.
 */
let errorPopover;


/**
 * Creates a shell for an error popover.
 * @param node DOM node or selector
 * @param errorMessage The message you want in the popover
 * @returns {Popover}
 */
export default function createErrorPopover(node = document.head, errorMessage = '🐼') {
	errorPopover = Popover.create(node, {
		closeX: false,
		html: `<span class="TimeTrackingErrorPopover js-tt-error-text">${ errorMessage }</span>`,
		position: 'right',
		themeClass: 'Balloon--popover-error',
		persistent: true,
		toggleOnClick: false,
		triggerEvent: 'none',
	});

	return errorPopover;
}


/**
 * Updates the errorPopover with a new error message and node if you want to change the anchor
 * @param errorMessage
 * @param node
 */
export function updateErrorPopover(errorMessage, node) {
	errorPopover.balloonContentElement.querySelector('.js-tt-error-text').innerText = errorMessage;
	if (node) {
		errorPopover.setAnchor(node);
	}
}
