import Ajax from '@utils/ajax';
import { AxiosPromise } from 'axios';
import { CancellationType, CancellationRequestData, CancellationStatus, ReasonOptionFeature, ReasonData } from './components/cancellation-modal/types';

export const BASE_ENDPOINT = '/settings/cancellation';
export const ACCOUNT_ENDPOINT = '/settings/account/cancellation';

export function getCancellationFeatureStatus(feature: CancellationType) {
	return Ajax.get<CancellationStatus>(`${BASE_ENDPOINT}/${feature}/status`);
}

export function getCancellationStatusAll() {
	return Ajax.get<CancellationStatus[]>(`${BASE_ENDPOINT}/status_all`);
}

interface GetNextBillingDatesResponse {
	next_billing_dates: string[]; // [ "Y-m-d", "Y-m-d", ...]
}

export function getNextBillingDates(): AxiosPromise<GetNextBillingDatesResponse> {
	return Ajax.get<GetNextBillingDatesResponse>(`${ACCOUNT_ENDPOINT}/next_billing_dates`);
}

interface GetExtraJobOpeningsResponse {
	extraJobPostings: number;
}

export function getExtraJobOpenings() {
	return Ajax.get<GetExtraJobOpeningsResponse>(`${ BASE_ENDPOINT }/extra_job_openings`);
}

interface GetRemainingJobOpeningsResponse {
	remainingJobOpenings: number;
}

export function getRemainingJobOpenings() {
	return Ajax.get<GetRemainingJobOpeningsResponse>(`${ BASE_ENDPOINT }/remaining_job_openings`);
}

export function getCancellationFeatureReasonOptions(
	feature: ReasonOptionFeature | ReasonOptionFeature[]
): Promise<{ data: ReasonData[] }> {
	return getCancellationFeatureReasonOptionsAll().then((resp) => {
		const features = Array.isArray(feature) ? feature : [feature];
		const data = resp.data.filter((item) => features.includes(item.feature));
		return { data };
	});
}

export function getCancellationFeatureReasonOptionsAll(): AxiosPromise<ReasonData[]> {
	return Ajax.get<ReasonData[]>(`${BASE_ENDPOINT}/reason_options_all`);
}

// Submit Cancellation:
interface SubmitCancellationResponse {
	pending_cancellation: boolean;
	user_who_requested_cancellation: string;
	date_requested: string /* 'Y-m-d' */;
}

export function submitCancellation(payload: CancellationRequestData) {
	return Ajax.post<SubmitCancellationResponse>(`${BASE_ENDPOINT}/start`, payload);
}

export interface StopCancellationRequestData {
	products: string[];
}

export function stopCancellation(payload: StopCancellationRequestData) {
	return Ajax.post(`${BASE_ENDPOINT}/stop`, payload);
}
