import * as reducer from '../../utils/reducers';
import addComment from './add-comment';
import addEvent from './add-event';
import deleteComment from './delete-comment';
import deleteEvent from './delete-event';
import editComment from './edit-comment';
import manageMode from './manage-mode';
import { State } from '../../models';

// create reducer from various feature-based updates
export default reducer.create(new State(), {
	...addComment,
	...addEvent,
	...deleteComment,
	...deleteEvent,
	...editComment,
	...manageMode,
});
