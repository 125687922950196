import React from 'react';

/**
 * The hidden submit button allows the "enter" key to be used to submit a form. This is useful for forms that have
 * buttons outside the form that submit the form (e.g. footer)
 * @returns {*}
 */
export default function() {
	return <button type="submit" style={{
		width: 0,
		height: 0,
		overflow: 'hidden',
		padding: 0,
		border: 'none',
		position: 'absolute',
	}} />;
}
