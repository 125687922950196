import {
	isStringNumeric,
} from '../../domain';

export type RoutingNumberValidationState = 'initial' | 'pending' | 'processing' | 'error' | 'success';

export function getRoutingNumberError(routingNumber: string): string {
	try {
		if (!routingNumber.trim()) {
			throw new Error('Required');
		}

		if (!isStringNumeric(routingNumber)) {
			throw new Error('Can only contain numbers');
		}

		if (routingNumber.length !== 9) {
			throw new Error('Must be 9 digits long');
		}

		return null;
	} catch (error) {
		return error.message;
	}
}

export function getRoutingNumberNote(routingNumberStatus: RoutingNumberValidationState, bankName: string): string {
	if (routingNumberStatus === 'processing') {
		return 'Validating...';
	}

	if (routingNumberStatus === 'success') {
		return bankName;
	}

	return '';
}
