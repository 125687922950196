import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { noop } from 'lodash';
import { Modal } from 'modal-legacy';
import { FunctionComponent } from 'react';
import { Flex } from '@bamboohr/fabric';

type Props = {
	handleClose: Function;
	handlePrimaryAction: Function;
};

const DeleteEducationModal: FunctionComponent<Props> = ({
	handleClose = noop,
	handlePrimaryAction = noop,
}) => {

	return (
		<Modal
			icon={ifFeature('encore', "trash-can-regular", "fab-trash-can-43x48")}
			iconColor="danger"
			iconV2Color="error-strong"
			isOpen={ true }
			onClose={ (): void => {
				handleClose();
			} }
			primaryAction={ (): void => {
				handlePrimaryAction();
			} }
			primaryActionText={ $.__('Yes, Delete Education') }
			title={ $.__('Delete Education') }
			headline={ifFeature('encore', $._('Are you sure you want to delete this education record?'))}
			content={ifFeature(
				'encore',
				<Flex justifyContent={'center'}>{$.__(`Don't worry, doing so won't actually make the employee any less educated.`)}</Flex>
			)}
		>
			{ifFeature(
				'encore',
				undefined,
				<div id="editForm">
					<div className="deleteModal">
						<div className="deleteMessage">
							<Message
								strong={ { tag: 'span', className: 'emphasis' } }
								text={ $._('Are you sure you want to **delete this education record**?') }
							/>
						</div>
						<div className="deleteSubMessage">
							{ $.__(`Don't worry, doing so won't actually make the employee any less educated`) }
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default DeleteEducationModal;
