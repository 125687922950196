import { render } from 'base/wrapped-render';
import { useEffect, useState } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { isNil } from 'lodash';
import moment from 'moment';
import Ajax from '@utils/ajax';
import BadgeComponent from 'badge.react';
import { PoMicroFrontend } from 'micro-frontend.react';
import { isEnabled } from 'FeatureToggle.util';
import './styles.styl';

let shouldOpenSheet = false;
let submittingRequest = false;

function getDateFromInputElement($input) {
	return moment($input.val(), moment.defaultFormat).toDate();
}

function getEmployeeHeader() {
	if (window.Employee) {
		return (
			<BadgeComponent
				imageAlt='Image of employee'
				imageSrc={window.Employee.photoURL}
				subTitle={window.Employee.jobTitle}
				title={`${window.Employee.preferredName} ${window.Employee.lastName}`}
			/>
		);
	} else if (window.common) {
		return (
			<BadgeComponent
				imageAlt='Image of employee'
				imageSrc={window.common.profilePictureUrl}
				subTitle={window.common.jobTitle}
				title={`${window.common.firstName} ${window.common.lastName}`}
			/>
		);
	}

	return null;
}

export function getDateInfo(dt) {
	const month = dt.getMonth() + 1;
	const year = dt.getFullYear();
	const day = dt.getDate();
	const dtString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
	for (let i = 0; i < window.requests.length; ++i) {
		if (dtString >= window.requests[i].start && dtString <= window.requests[i].end) {
			return [true, window.requests[i].status];
		}
	}
	return [true, ''];
}

export function onBalanceCloseHandler(dt, saveTotals, elm) {
	if (dt === '' || dt === undefined) {
		return false;
	}
	if ($(elm).is('.pto-calendar-field2')) {
		return false;
	}

	if ($('.js-pto-balances').length) {
		$('#side-col').addClass('loading');
		const loc = `/dashboard/ajax/dialog/pto/calculator.php?endDate=${encodeURIComponent(dt)}`;
		$.ajax({
			url: loc,
			dataType: 'json',
			success(response) {
				if (response) {
					$('#side-col').removeClass('loading');
					if (saveTotals === true) {
						window.ptoTotals = response.totals;
					}

					if (response.data) {
						const momentReturnedDate = moment(response.endDate, moment.defaultFormat);
						const momentNowDate = moment();
						let translatedString = '';

						if (momentNowDate.isSame(momentReturnedDate, 'd')) {
							translatedString = $.__('As of Today');
						} else {
							translatedString = $.__('As of %1$s', momentReturnedDate.format('ll'));
						}

						$('.js-calculate-as-of-date').text(translatedString);
						return buildRequestPageCalculatorTable(response);
					} else if (response.result == 'ok') {
						$('#balanceCalc').val(dt);
						$('#balance-selected-date').text(dt);
						$('#pto-balances').html(response.content);
					} else {
						setMessage($.__('An error occurred while trying to calculate the future balances'), 'error');
					}
				}
			},
			error() {
				setMessage($.__('An error occurred while trying to calculate the future balances'), 'error');
				$('#side-col').removeClass('loading');
			},
		});
	}
}

export function buildRequestPageCalculatorTable(response) {
	const $ptoBalanceContainer = $('#pto-balances');
	$ptoBalanceContainer.html(microTemplate($('#js-template-request-page-balances').html(), response));

	window.BambooHR.Components.Popover.purge();

	// Build popovers if they are in place
	$ptoBalanceContainer.find('.js-balance-list-help-icon').each((i, svg) => {
		const popover = window.BambooHR.Components.Popover.create(svg, {
			content: `${$.__(`This available time off is calculated based on hours worked.`)} ${$.__(
				`Since we don’t know what hours you will be working in the future we can’t calculate your future balance.`
			)} ${$.__(`Instead we are displaying your currently available time off.`)}`,
			title: $.__(`A time machine would be handy right now`),
			placement: 'left',
			contentCssOverride: { 'max-width': '410px', 'font-size': '14px' },
			titleCssOverride: { 'font-size': '20px' },
		})
			.onShow((popoverInstance) => setIconColor(popoverInstance.triggerEl))
			.onHide((popoverInstance) => resetIconColor(popoverInstance.triggerEl));

		$(svg).hover(setIconColor.bind(null, popover.triggerEl), () => {
			if (!popover.showing) {
				resetIconColor(popover.triggerEl);
			}
		});
	});

	function setIconColor(triggerEl) {
		$(triggerEl).attr('fill', window.BambooHR.Utils.getBrandColor());
	}

	function resetIconColor(triggerEl) {
		$(triggerEl).attr('fill', '#b7b7b7');
	}
}

// @TODO REMOVE THIS WHEN THE NEW PTO REQUEST PAGE GOES OUT
export function removeMobilePromotion() {
	$.ajax({
		url: '/time_off/mobile_promotion/remove',
		type: 'PUT',
	});
	$('#js-mobile-promotion').slideUp(200);
}

export function initDatepicker(input) {
	addLegend(input);
	if (input == $('.pto-calendar-field2')[0]) {
		const min = $('.pto-calendar-field1').datepicker('getDate');
		if (!min) {
			$('.pto-calendar-field2').datepicker('option', 'minDate', 0);
		} else {
			$('.pto-calendar-field2').datepicker('option', 'minDate', $('.pto-calendar-field1').datepicker('getDate'));
		}
		if (input.value == '') {
			input.value = $('.pto-calendar-field1').val();
		}
	}
}

export function addLegend(input) {
	if (input == $('.pto-calendar-field1')[0] || input == $('.pto-calendar-field2')[0]) {
		setTimeout(function () {
			$('div#ui-datepicker-div div.ui-datepicker-buttonpane button:first').after(
				'<ul id="pto-legend" style="display: none"><li class="approved"><span></span>Approved</li><li class="requested"><span></span>Requested</li></ul>'
			);
			// $('#pto-legend').fadeIn('slow');
		}, 200);
	}
}

export function validatePtoRequest(form) {
	if (isTenYearsOrMore()) {
		showTenYearWarningModal();
		return false;
	}
	if ($(form).valid()) {
		if (!sumAmounts()) {
			setMessage(window.defaultErrorMessage, 'error');
			return false;
		}

		const startYmd = moment($('#startYmd').val(), moment.defaultFormat);
		const endYmd = moment($('#endYmd').val(), moment.defaultFormat);
		const isEndBeforeStart = endYmd.isBefore(startYmd);
		if (isEndBeforeStart) {
			// the start date must be before the end date
			startDateLessThanEndDate(true);
			setMessage($.__('The start date must be on or before the end date.'), 'error');
			// Note for future fix. This should only happen when the time off master form is embedded into an modal (example is editing a PTO request)

			window.BambooHR.Modal.setState({ isOpen: true, isProcessing: false }, true);

			return false;
		}

		const hours = $('input[name="hours"]').val();
		let hoursInStdForm = hours;
		if (!$.isNumeric(hours)) {
			hoursInStdForm = window.getFloatFromStringJS(hours);
		}
		const type = $('select[name="type"]');
		const selectedType = $(`.js-time-off-type ba-option[value="${type.val()}"]`);
		const ptoData = {
			type: selectedType.text(),
			date: $('#startYmd').val(),
			typeBalance: window.ptoTotals[type.val()],
			requestAmount: hours,
			remainingBalance: window.ptoTotals[type.val()] - hoursInStdForm,
		};

		if (!isNil(window.originalRequest)) {
			window.originalRequest.hours = parseInt(window.originalRequest.hours);
			const sDate = $.datepicker.formatDate('yy-mm-dd', $('#startYmd').val());
			if (
				window.originalRequest.status == 'approved' &&
				sDate >= window.originalRequest.startYmd &&
				window.originalRequest.timeOffTypeId == type.val()
			) {
				ptoData.remainingBalance += parseInt(window.originalRequest.hours);
				ptoData.typeBalance += parseInt(window.originalRequest.hours);
			}
		}
		if (typeof window.confirmBalance === 'undefined') {
			window.confirmBalance = false;
		}

		// window.employeeTimeOffPolicyTypes is a globally defined variable. Declared in request.php
		if (
			window.employeeTimeOffPolicyTypes &&
			window.employeeTimeOffPolicyTypes[type.val()] !== 'unlimited' &&
			ptoData.remainingBalance.toFixed(2) < 0 &&
			window.confirmBalance
		) {
			ptoData.remainingBalance = ptoData.remainingBalance.toFixed(2);
			ptoData.typeBalance = ptoData.typeBalance.toFixed(2);

			if (window.GLOBAL_DECIMAL_CHAR === ',') {
				ptoData.remainingBalance = ptoData.remainingBalance.replace(/\./, ',');
				ptoData.typeBalance = ptoData.typeBalance.replace(/\./, ',');
				ptoData.requestAmount = ptoData.requestAmount.replace(/\./, ',');
			}

			if (typeof Inbox !== 'undefined') {
				// Move on if in the inbox
				return true;
			}
			// Show the negative balance warning modal. This should only show up on the request page
			showNegativeBalanceWarningModal(ptoData);
		} else {
			return true;
		}
	}
	document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
	return false;
}

/** Takes care of opening/closing the negative balance modal when rendered outside React */
function PoNegativeBalanceModalWrap({ onClose, ...props }) {
	const [isOpen, setIsOpen] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);

	useEffect(() => {
		if (!isProcessing) {
			return;
		}
		const $requestForm = $('#request');
		const formData = getFormData($requestForm);
		const postData = $.param(formData);
		const cancelSource = Ajax.createCancelSource();
		Ajax.post('/time_off/requests', postData, { cancelToken: cancelSource.token })
			.then(() => {
				window.location.href = $requestForm.data('doneUrl');
			})
			.catch((error) => {
				setIsProcessing(false);
				if (error.response.data) {
					window.setMessage(error.response.data, 'error');
				} else {
					errorFallBack();
				}
			});

		return () => {
			cancelSource.cancel();
		};
	}, [isProcessing]);

	return (
		<PoMicroFrontend
			isOpen={isOpen}
			isProcessing={isProcessing}
			onAfterClose={() => onClose()}
			onConfirmOrCancel={(confirmed) => {
				if (confirmed) {
					setIsProcessing(true);
				} else {
					setIsOpen(false);
				}
			}}
			route='/time-off/negative-balance-modal'
			{...props}
		/>
	);
}

export function showNegativeBalanceWarningModal(ptoData) {
	const negativeBalanceModalDiv = document.createElement('div');

	document.body.appendChild(negativeBalanceModalDiv);
	const unmountModal = () => unmountComponentAtNode(negativeBalanceModalDiv);
	const balanceData = {
		...ptoData,
		isoDate: moment(ptoData.date, moment.defaultFormat).toISOString(),
	};
	render(<PoNegativeBalanceModalWrap onClose={unmountModal} {...balanceData} />, negativeBalanceModalDiv);
}

function PoEditTimeOffRequestModalWrap({ employee, timeOffRequestId, canCancelRequest, onRequestEditOrCancel, onCloseComplete }) {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<PoMicroFrontend
			canCancelRequest={canCancelRequest}
			employee={employee}
			isOpen={isOpen}
			onCloseComplete={onCloseComplete}
			onRequestClose={(action) => {
				setIsOpen(false);
				if (action === 'edited' || action === 'cancelled') {
					onRequestEditOrCancel();
				}
			}}
			route='/time-off/edit-time-off-modal'
			timeOffRequestId={timeOffRequestId}
		/>
	);
}
export function showEditTimeOffRequestModal(employee, timeOffRequestId, canCancelRequest, finishedUrl) {
	const editTimeOffRequestModalContainer = document.createElement('div');
	// The element needs to be in the DOM for the `MicroFrontend` component to render properly.
	document.body.appendChild(editTimeOffRequestModalContainer);

	const onRequestEditOrCancel = () => {
		if (finishedUrl) {
			window.location = finishedUrl;
		} else {
			window.location.reload();
		}
	}

	render(
		<PoEditTimeOffRequestModalWrap
			canCancelRequest={canCancelRequest}
			employee={employee}
			onCloseComplete={() => unmountComponentAtNode(editTimeOffRequestModalContainer)}
			onRequestEditOrCancel={onRequestEditOrCancel}
			timeOffRequestId={timeOffRequestId}
		/>,
		editTimeOffRequestModalContainer
	);

}

/** Takes care of opening/closing the ten year warning modal when rendered outside React */
function PoTenYearWarningModalWrap({ startDate, endDate, onAfterClose }) {
	const [isOpen, setIsOpen] = useState(true);
	return (
		<PoMicroFrontend
			endDate={endDate}
			isOpen={isOpen}
			onAfterClose={onAfterClose}
			onRequestClose={() => setIsOpen(false)}
			route='/time-off/ten-year-warning-modal'
			startDate={startDate}
		/>
	);
}

export function showTenYearWarningModal() {
	const startDate = moment($('#startYmd').val(), moment.defaultFormat).toISOString();
	const endDate = moment($('#endYmd').val(), moment.defaultFormat).toISOString();

	const tenYearWarningModalContainer = document.createElement('div');
	// The element needs to be in the DOM for the `MicroFrontend` component to render properly.
	document.body.appendChild(tenYearWarningModalContainer);

	const closeSheet = () => {
		$('.js-pto-request-datepicker[calendar-picker]').data('calendarPicker').setRange(startDate, startDate);
		updateDailyTotals();
		unmountComponentAtNode(tenYearWarningModalContainer);
	};

	render(
		<PoTenYearWarningModalWrap endDate={endDate} onAfterClose={closeSheet} startDate={startDate} />,
		tenYearWarningModalContainer
	);
}

export function onPageLoadSetup() {
	const PTO_DATEPICKER_OPTIONS = {
		yearRange: '-70:+20',
		minDate: null,
		duration: 0,
		showAnim: false,
		showOn: 'button',
		beforeShowDay: getDateInfo,
		buttonImage: '/images/color-branded-icons/holiday-cal.png',
		buttonImageOnly: false,
		buttonText: '',
		numberOfMonths: 1,
		showButtonPanel: true,
		//              onChangeMonthYear: function(y,m,o) {addLegend(o.input[0])},
		beforeShow: initDatepicker,
		onClose(dt) {
			onBalanceCloseHandler(dt, true, this);
		},
	};

	const BALANCE_DATEPICKER_OPTIONS = {
		yearRange: '-70:+20',
		minDate: 0,
		duration: 0,
		showAnim: false,
		beforeShow(inp) {
			inp.blur();
			window.trackAction('viewedFutureBalanceWeb', { type: 'button' }, 'view');
		},
		// buttonImage: '/images/color-branded-icons/holiday-cal.png',
		buttonImageOnly: false,
		onClose: onBalanceCloseHandler,
		changeMonth: true,
		changeYear: true,
	};

	if (GLOBAL_DATEPICKER_MASK != undefined) {
		PTO_DATEPICKER_OPTIONS.dateFormat = GLOBAL_DATEPICKER_MASK;
		BALANCE_DATEPICKER_OPTIONS.dateFormat = GLOBAL_DATEPICKER_MASK;
	}

	$('.pto-calendar-field2').focus(function () {
		if (this.value == '') {
			this.value = $('.pto-calendar-field1').val();
			$(this).trigger('change');
		}
	});

	$('.pto-calendar-field1, .pto-calendar-field2')
		.bhrdatepicker(PTO_DATEPICKER_OPTIONS)
		.attr('autocomplete', 'off')
		.inputmask(window.GLOBAL_DATE_MASK);

	$('#request').submit(function (e) {
		e.preventDefault();
		if (validatePtoRequest(this) && !$('#footer-buttons .btn.btnAction').hasClass('disabled')) {
			$('#footer-buttons .btn.btnAction').addClass('disabled processing');
			$(this)[0].submit();
		}
	});

	const $balanceCalc = $('#balanceCalc');
	const bdval = $balanceCalc.val();
	$balanceCalc.on('change', () => {
		const balanceCalcValue = $balanceCalc.val();
		onBalanceCloseHandler(balanceCalcValue, true, this);
	});

	const startDateVal = $('#startYmd').val();
	onBalanceCloseHandler(startDateVal ? startDateVal : bdval, true, this);

	$(document).on('change', '#startYmd', function (e) {
		const bdval = $('#balanceCalc').val();
		const startDateVal = $('#startYmd').val();

		onBalanceCloseHandler(startDateVal ? startDateVal : bdval, true, this);
	});
	$(document).on('change', '#endYmd, #startYmd', function () {
		const start = $('#startYmd').val();
		const $end = $('#endYmd');
		const end = $end.val();
		const employeeId = $('#employeeId').val() || '';
		const requestId = $('#requestId').val() || '';

		if (start !== '' && end !== '') {
			const urlParams = {
				startYmd: start,
				endYmd: end,
				eid: employeeId,
				originalRequestId: requestId,
			};

			$.get(
				'/ajax/employees/pto/duplicate_check.php',
				urlParams,
				function (data) {
					if (data.result === 'error') {
						duplicateTimeOffRequest(true);
					} else if (data.result !== 'ok') {
						if (data.errorType === 'date') {
							duplicateTimeOffRequest(false);
							startDateLessThanEndDate(true);
						}
					} else {
						duplicateTimeOffRequest(false);
						startDateLessThanEndDate(false);
					}
				},
				'json'
			);
		}
		updateDailyTotals();
	});

	// if the timeofftype changes, check the units
	$(document).on('ba:selectChange', '.js-time-off-type', function () {
		// 🤮 when it comes to jade... gotta do what you gotta do...
		setTimeout(updateDailyTotals);
	});

	// sum the mulitple days values as they are changed
	$(document).on('change, keyup', '#multipleDayList', function () {
		sumAmounts();

		// Add an extra validate here so it will actually validate the inputs in inbox
		const $form = $('.js-timeoff-master-form');
		$form.bhrValidate();
	});

	// submit the PTO approval form
	$('#approve').submit(function (e) {});

	const requestPtoFooter = document.querySelector('[data-form-id="requestPtoFooter"]');
	if (requestPtoFooter) {
		requestPtoFooter.addEventListener('click', (event) => {
			const submitRequestBtn = event.target.closest('button[data-action="SiteFooterAction:submit"]');
			if (submitRequestBtn && !submittingRequest && !submitRequestBtn.disabled) {
				submittingRequest = true;
				document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));
				storeEmployeeTimeOffRequest();
			}
		});
	}
}

// deny the approval of a time off request
export function setActionDeny() {
	document.getElementById('action').value = 'deny';
	document.approve.submit();
}

let largeRequestModalOpen = false;

/** Takes care of opening/closing the large request modal when rendered outside React */
function PoLargeRequestModalWrap({ startDate, endDate, onClose }) {
	const [isOpen, setIsOpen] = useState(true);
	return (
		<PoMicroFrontend
			endDate={endDate}
			isOpen={isOpen}
			onClose={(confirmed) => {
				setIsOpen(false);
				// Let transition finish
				setTimeout(() => {
					onClose(confirmed);
				}, 300);
			}}
			route='/time-off/large-request-modal'
			startDate={startDate}
		/>
	);
}

// The ajax request to show the correct form fields for the days specific to the date range and time off type
export function updateDailyTotals() {
	const dayThreshold = 150;
	const startDay = getDateFromInputElement($('#startYmd'));
	const endDay = getDateFromInputElement($('#endYmd'));
	const startDayVal = $('#startYmd').val();
	const endDayVal = $('#endYmd').val();
	const toType = $('#timeOffType').val();
	const timeOffRequestId = $('#requestId').val();
	const employeeId = $('#employeeId').val();
	const days = getNumberOfDays(startDay, endDay);
	if (startDay !== null && days > dayThreshold) {
		if (isTenYearsOrMore()) {
			showTenYearWarningModal();
		} else if (!largeRequestModalOpen) {
			largeRequestModalOpen = true;
			const largeRequestModalContainer = document.createElement('div');
			// The element needs to be in the DOM for the `MicroFrontend` component to render properly.
			document.body.appendChild(largeRequestModalContainer);
			render(
				<PoLargeRequestModalWrap
					endDate={moment(endDayVal, moment.defaultFormat).toISOString()}
					onClose={(confirmed) => {
						if (confirmed) {
							updateFields(startDay, endDay, startDayVal, endDayVal, toType, timeOffRequestId, employeeId, days);
						} else {
							$('.js-pto-request-datepicker[calendar-picker]').data('calendarPicker').setRange(startDayVal, startDayVal);
							updateDailyTotals();
						}
						unmountComponentAtNode(largeRequestModalContainer);
						largeRequestModalOpen = false;
					}}
					startDate={moment(startDayVal, moment.defaultFormat).toISOString()}
				/>,
				largeRequestModalContainer
			);
		}
	} else {
		updateFields(startDay, endDay, startDayVal, endDayVal, toType, timeOffRequestId, employeeId, days);
	}

	function updateFields(startDay, endDay, startDayVal, endDayVal, toType, timeOffRequestId, employeeId, days) {
		if (startDay && days > dayThreshold) {
			$('#multipleDayList').addClass('multiple');
			$('#multipleDayList').html(
				'<div style="height:192px;"><img src="/images/loading-large-for-gray-background.gif" style="margin:auto; display:block; position:relative; height:46px; padding-top:75px;" /></div>'
			);
		}
		document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));
		$.get('/time_off/requests/daily_fields_twig', {
			startDate: startDayVal,
			endDate: endDayVal,
			type: toType,
			eid: employeeId,
			id: timeOffRequestId,
		}).done(function (amountList) {
			$('#multipleDayList').html(amountList);
			if (startDay && days > 1 && startDay < endDay && toType != '') {
				$('#multipleDayList').addClass('ScrollBox');
			} else {
				$('#multipleDayList').removeClass('ScrollBox');
			}
			if (SimpleModal.getModalCount() > 0) {
				SimpleModal.setPosition();
			}
			sumAmounts();
			document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
		});
	}

	function getNumberOfDays(startDay, endDay) {
		return Math.round(Math.abs((new Date(startDay).getTime() - new Date(endDay).getTime()) / (24 * 60 * 60 * 1000))) + 1;
	}
}

// used to sum the multiple day totals
// prevent non numeric entry
// prevent spaces (reset to 0)
export function sumAmounts() {
	let pass = true;
	$('#multipleDayList').each(function () {
		let totalAmount = 0;
		let commaSet = 0;
		$(this)
			.find('input')
			.each(function () {
				if ($(this).attr('type') == 'hidden') {
					window.rawUnits = this.value;
				} else {
					let unitAmount;
					let unitAmountDisplay;
					if (!$.isNumeric(this.value)) {
						this.value = this.value.replace(/[^0-9\.\,-]/g, '');
					}
					if (this.value == '') {
						unitAmount = 0.0;
						unitAmountDisplay = '';
					} else {
						unitAmount = window.getFloatFromStringJS(this.value);
						unitAmountDisplay = unitAmount;
						/**
						 * removed this because it wasn't allowing .5 to be entered (only 0.5)
						 * also added .validateNumber class to the input so number validating is handled by the bhrValidator.
						 */
						/*if (isNaN(unitAmount)) {
								var msg = $.__('Invalid number format:') + " " + this.value;
								addAmountErrorMessage(this, msg);
								unitAmount = 0.00;
								this.value = 0;
							} else {*/
						$(this).siblings('.fieldInfo.error.nonPlugin').remove();
						//}
					}
					if (!checkInputForLimits(this, unitAmountDisplay)) {
						pass = false;
					}
					totalAmount += unitAmount;

					if (this.value.indexOf(',') > -1) {
						commaSet = 1;
					}
				}
			});
		totalAmount = totalAmount.toFixed(2).toString();
		if (commaSet > 0) {
			totalAmount = totalAmount.replace(/\./, ',');
			commaSet = 0;
		}
		$(this).find('#dailyTotalAmount').text(totalAmount);
		$('#singleTotalAmount').val(totalAmount);
	});
	return pass;
}

// check the input field to make sure it has not exceeded the limits
export function checkInputForLimits(element, itemTotal) {
	const fieldToUpdate = $($(element).parent('li'));
	let pass = true;
	if ((window.rawUnits == 'days' && itemTotal > 1) || (window.rawUnits == 'days' && itemTotal < -1)) {
		addAmountErrorMessage(element, $.__('A maximum of 1 day is allowed.'));
		pass = false;
		fieldToUpdate.addClass('error');
	} else if ((window.rawUnits == 'hours' && itemTotal > 24) || (window.rawUnits == 'hours' && itemTotal < -24)) {
		addAmountErrorMessage(element, $.__('A maximum of 24 hours is allowed.'));
		pass = false;
		fieldToUpdate.addClass('error');
	} else {
		fieldToUpdate.removeClass('error disabled');
	}
	if (itemTotal === 0) {
		fieldToUpdate.addClass('disabled');
	}
	return pass;
}

export function duplicateTimeOffRequest(warn) {
	$('#startYmd').closest('.fieldRow').children('.fieldInfo.error').remove();
	if (warn) {
		$('#startYmd').addClass('fab-TextInput--warning');
		$('#startYmdLabel').addClass('fab-Label--warning');

		$('#endYmd').addClass('fab-TextInput--warning');
		$('#endYmdLabel').addClass('fab-Label--warning');

		$('#js-duplicate-entry').show();

		$('#multipleDayList').removeClass('ScrollBox');
	} else {
		$('#startYmd').removeClass('fab-TextInput--warning');
		$('#startYmdLabel').removeClass('fab-Label--warning');

		$('#endYmd').removeClass('fab-TextInput--warning');
		$('#endYmdLabel').removeClass('fab-Label--warning');

		$('#js-duplicate-entry').hide();
	}
}

export function startDateLessThanEndDate(error) {
	$('#js-duplicate-entry').hide();
	if (error) {
		$('#startYmd').addClass('fab-TextInput--error');
		$('#startYmdLabel').addClass('fab-Label--error');

		$('#endYmd').addClass('fab-TextInput--error');
		$('#endYmdLabel').addClass('fab-Label--error');
	} else {
		$('#startYmd').removeClass('fab-TextInput--error');
		$('#startYmdLabel').removeClass('fab-Label--error');

		$('#endYmd').removeClass('fab-TextInput--error');
		$('#endYmdLabel').removeClass('fab-Label--error');
	}
}

export function isTenYearsOrMore() {
	const startDay = moment($('#startYmd').val(), moment.defaultFormat);
	const endDay = moment($('#endYmd').val(), moment.defaultFormat);
	const years = moment.duration(endDay - startDay).years();
	return years >= 10;
}

export function addAmountErrorMessage(element, msg) {
	element = $(element);
	element.parent('li').addClass('error');
	if (element.parent('li').find('.fieldInfo').length > 0) {
		element.parent('li').find('.fieldInfo').text(msg);
		if (!element.parent('li').find('.fieldInfo').hasClass('nonPlugin')) {
			element.parent('li').find('.fieldInfo').addClass('nonPlugin');
		}
	} else {
		element.parent('li').append(`<span class="RequestPTO__amountError fieldInfo fieldInfo-error error nonPlugin">${msg}</span>`);
	}
}

export function savePTOmodal(employeeId, url, postParams, redirectLocation = `/employees/pto/?id=${employeeId}`) {
	if (postParams === undefined) {
		postParams = SimpleModal.getTopModalSelector().find('form').serialize();
	}
	SimpleModal.Footer.Buttons.startProcessing();
	$.post(
		url,
		postParams,
		function (response) {
			if (response.success) {
				if (response.nextURL) {
					window.location.href = response.nextURL;
				} else {
					window.location.href = redirectLocation;
				}
				closeModalDialog();
			} else {
				setMessage(response.error, 'error');
				window.BambooHR.Modal.setState({ isProcessing: false }, true);
			}
		},
		'json'
	).fail(function () {
		errorFallBack();
		window.BambooHR.Modal.setState({ isProcessing: false }, true);
	});
}

export function loadPTOmodal(employeeId, url, getParams, options, redirectLocation) {
	$.get(
		url,
		getParams,
		function (response) {
			//If successful, show the modal
			if (response.success) {
				const newOptions = $.extend(
					true,
					{
						html: response.html,
						title: response.title,
						classes: 'jquery-padding js-PTOmodal',
						footer: {
							show: true,
							buttons: {
								primary: {
									disabled: false,
									show: true,
									text: response.save,
									action() {
										if (window.ASSUMED_USER) {
											window.disabledForPermsTest();
											return;
										}
										const $BhrForms = SimpleModal.getTopModalSelector().find('.BhrForms');
										if (isTenYearsOrMore()) {
											showTenYearWarningModal();
										} else if ($BhrForms.length === 0 || $BhrForms.valid()) {
											url += `?${$.param(getParams)}`;
											savePTOmodal(employeeId, url, undefined, redirectLocation);
										}
									},
								},
							},
						},
						callbacks: {
							open: {
								after() {
									// If there is an existing modal window, check for associated textareas with attribute
									// of maxlength and set the limit counter on them.
									if (SimpleModal.getTopModalSelector().length) {
										const $BhrForms = SimpleModal.getTopModalSelector().find('.BhrForms');
										const textarea = $BhrForms.find('textarea');
										if (typeof textarea.attr('maxlength') !== 'undefined' && textarea.attr('maxlength')) {
											textarea.limitCount(textarea.attr('maxlength'));
										}
									}
								},
							},
						},
					},
					options
				);
				SimpleModal.openModal(newOptions);
			} else {
				//Otherwise show an error message
				setMessage(response.error, 'error');
			}
		},
		'json'
	).fail(errorFallBack);
}

/**
 * Select time for the balance adjustment modal
 *
 * @param {String} value Whether we're selecting "add" or "subtract" in the dropdown
 *
 * @returns {boolean}
 * @internal Doesn't need to be exported as the functionality is all contained here.
 */
function selectTime(value) {
	if (value === 'subtract') {
		selectSubtractTime();
	} else {
		selectAddTime();
	}
	updateInputColor();
	updateNewBalance(value);
	return false;
}

/**
 * User selected "Add" so show/hide appropriate items in the modal
 *
 * @internal Doesn't need to be exported as the functionality is all contained here.
 */
function selectAddTime() {
	$('.js-adjust-modal-action-add').show();
	$('.js-adjust-modal-action-subtract').hide();
	$('.js-adjust-modal-math-row')
		.addClass('AdjustBalanceModalSummary__row--add')
		.removeClass('AdjustBalanceModalSummary__row--subtract');
	$('.js-adjust-modal-input-adjust-type').val('add');
}

/**
 * User selected "Subtract" so show/hide appropriate items in the modal
 *
 * @internal Doesn't need to be exported as the functionality is all contained here.
 */
function selectSubtractTime() {
	$('.js-adjust-modal-action-add').hide();
	$('.js-adjust-modal-action-subtract').show();
	$('.js-adjust-modal-math-row')
		.addClass('AdjustBalanceModalSummary__row--subtract')
		.removeClass('AdjustBalanceModalSummary__row--add');
	$('.js-adjust-modal-input-adjust-type').val('subtract');
}

/**
 * Update the input color if necessary for the max message.
 *
 * @internal Doesn't need to be exported as the functionality is all contained here.
 */
function updateInputColor() {
	const $hours = $('.js-adjust-pto-adjustment-hours');
	const mode = $('.js-adjust-pto-balance').find('.js-adjust-modal-input-adjust-type').val();
	let originalAdjustment = window.getFloatFromStringJS($hours.attr('data-realval'));
	let val = window.getFloatFromStringJS($hours.val());
	const max = window.getFloatFromStringJS($('.js-adjust-max-hours').text());
	const cur = window.getFloatFromStringJS($('.js-adjust-cur-hours').text());

	if (isNaN(originalAdjustment)) {
		originalAdjustment = 0;
	}

	if (isNaN(val)) {
		val = 0;
	}

	const $maxNotice = $('.js-adjust-over-max-notice');

	if (mode === 'add' && max > 0 && cur + val - originalAdjustment > max) {
		$maxNotice.show();
		$hours.css('color', 'red');
	} else {
		$maxNotice.hide();
		$hours.css('color', '#222222');
	}
}

/**
 * Update the balance section to show what the new balance will be.
 *
 * @param {String} operator Whether we're selecting "add" or "subtract" in the dropdown
 *
 * @internal Doesn't need to be exported as the functionality is all contained here.
 */
function updateNewBalance(operator) {
	const $hoursInput = $('.js-adjust-pto-adjustment-hours');
	const currentAmt = window.getFloatFromStringJS($('.js-adjust-cur-hours').html());
	let originalAdjustment = window.getFloatFromStringJS($hoursInput.attr('data-realval'));
	let adjustment = window.getFloatFromStringJS($hoursInput.val());

	//Check operations and valid numbers
	if (operator === 'subtract') {
		adjustment = 0 - adjustment;
	}
	if (originalAdjustment === undefined || isNaN(originalAdjustment)) {
		originalAdjustment = 0.0;
	}

	//Set the new amount for the type
	let newAmt = parseFloat(currentAmt + adjustment - originalAdjustment);
	if (isNaN(newAmt)) {
		newAmt = currentAmt - originalAdjustment;
	}
	$('.js-adjust-total-hours').html(window.formatNumberJS(newAmt, 2));
}

/**
 * Redirect to the request.php page with a URL param so that the form loads with a time off type already in the
 * dropdown (Field: Time off type)
 *
 * @param timeOffTypeId
 */
export function requestTimeOffById(timeOffTypeId) {
	window.location.href = isEnabled('time_off_request_page_refactor') ? `/app/time_off/requests/create?categoryId=${timeOffTypeId}` : `/time_off/requests/create?timeOffTypeId=${timeOffTypeId}`;
}

export function editTimeOff(employeeId, requestId, typeID, canCancel, redirectLocation) {
	let url = '/time_off/requests/edit';
	let $form = null;
	const secondaryAction = () => {
		if (window.ASSUMED_USER) {
			window.disabledForPermsTest();
			return;
		}
		const sheetProps = {
			isOpen: true,
			content: $.__('Are you sure you want to cancel this request?'),
			title: $._('Time Off Request'),
			primaryActionText: $.__('Cancel Request'),
			primaryAction() {
				const url = `/time_off/requests/cancel`;
				sheetProps.isProcessing = true;
				window.BambooHR.Modal.setState(
					{
						isOpen: true,
						isProcessing: true,
						sheetProps,
					},
					true
				);
				Ajax.post(url, $.param({ id: requestId }))
					.then((response) => {
						if (response.data.success) {
							window.location.href = `/employees/pto/?id=${employeeId}`;
						} else {
							window.errorFallBack();
						}
					})
					.catch(() => {
						window.errorFallBack();
					});
			},
			secondaryAction: null,
			onClose() {
				sheetProps.isOpen = false;

				window.BambooHR.Modal.setState(
					{
						isOpen: true,
						isProcessing: false,
						sheetProps,
					},
					true
				);
			},
		};
		window.BambooHR.Modal.setState(
			{
				isOpen: true,
				isProcessing: true,
				sheetProps,
			},
			true
		);
	};

	const params = { id: requestId, employeeId };

	Ajax.get(url, params)
		.then((response) => {
			shouldOpenSheet = true;
			window.BambooHR.Modal.setState({
				content: <div dangerouslySetInnerHTML={{ __html: response.data.html }} id='js-time-off-master-form-modal' />,
				header: getEmployeeHeader(),
				isOpen: true,
				title: $.__('Edit Time Off Request'),
				primaryActionText: $.__('Save'),
				primaryAction() {
					if (window.ASSUMED_USER) {
						window.disabledForPermsTest();
						return;
					}

					if ($form.valid()) {
						window.BambooHR.Modal.setState({ isProcessing: true }, true);
						const formData = getFormData($form);

						Ajax.post((url += `?${$.param(params)}`), $.param(formData))
							.then((response) => {
								if (response.data.success) {
									window.location.href = `/employees/pto/?id=${employeeId}`;
								} else {
									window.errorFallBack();
									window.BambooHR.Modal.setState({ isProcessing: false }, true);
								}
							})
							.catch(() => {
								window.errorFallBack();
								window.BambooHR.Modal.setState({ isProcessing: false }, true);
							});
					}
				},
				secondaryActionText: $.__('Cancel Request'),
				secondaryAction: canCancel !== 'false' ? secondaryAction : null,
				onOpen() {
					$form = $('#js-time-off-master-form-modal .js-timeoff-master-form');
					$form.bhrValidate();
					$('.js-time-off-type').on('ba:selectChange', function () {
						// 🤮 when it comes to jade... gotta do what you gotta do...
						setTimeout(updateDailyTotals);
					});
					$form.find('textarea[maxlength]').limitCount();
				},
				onClose() {
					duplicateTimeOffRequestIdsHandler.removePostFixFromElementIds();
					shouldOpenSheet = false;
				},
			});
		})
		.catch(() => {
			window.errorFallBack();
		});
	return false;
}

const duplicateTimeOffRequestIdsHandler = {
	ids: [], // jQuery result set (ex: $firstForm.find('[id]'))
	postFix: 'TEMP-POSTFIX',
	addPostFixToElementIds() {
		const { postFix } = this;
		const firstForm = $('form#request').first();
		this.ids = firstForm.find('[id]');
		this.ids.each(function () {
			const newId = this.id + postFix;
			const $associatedLabel = $(`label[for="${this.id}"]`);
			$associatedLabel.attr('for', newId);
			this.id = newId;
		});
	},
	removePostFixFromElementIds() {
		const { ids, postFix } = this;
		if (!this.isPostFixAdded()) {
			return;
		}
		ids.each(function () {
			const newId = this.id.replace(postFix, '');
			const $associatedLabel = $(`label[for="${this.id}"]`);
			$associatedLabel.attr('for', newId);
			this.id = newId;
		});
	},
	isPostFixAdded() {
		return $('form#request').find('[id]').first().attr('id').indexOf(this.postFix) > -1;
	},
};

export function handleDuplicatedRequestForm(employeeId, requestId, typeID, canCancel) {
	/**
	 * PLEASE NOTE:
	 * This function is used ONLY because on the time off request page, we now bring up a modal that uses the same
	 * form on the actual page. Since these forms are duplicated, ids are clashing
	 *
	 * The standard function to use when editing time off requests is "editTimeOff" which is used in this function
	 */

	if (duplicateTimeOffRequestIdsHandler.isPostFixAdded()) {
		return;
	}

	duplicateTimeOffRequestIdsHandler.addPostFixToElementIds();
	editTimeOff(employeeId, requestId, typeID, canCancel);
}

export function commentExistsInADenialModal() {
	let elements = ['.denialModal #manager_note', '.denialModal #comment'],
		post = false;

	for (let i = 0; i < elements.length; i++) {
		if ($(elements[i]).length) {
			if ($.trim($(elements[i]).val())) {
				post = true;
			}
		}
	}
	return post;
}

function getFormData(form) {
	const formArray = form.serializeArray();
	const formData = {};
	formArray.forEach((formItem) => {
		const paramName = formItem.name;
		let paramValue = formItem.value;
		if (paramName === 'startYmd') {
			paramValue = $('#startYmd').val();
		} else if (paramName === 'endYmd') {
			paramValue = $('#endYmd').val();
		}

		formData[paramName] = paramValue;
	});

	return formData;
}

/**
 * Save an employee time off request from the request page. storeEmployeeTimeOffRequest() is found in request.html.twig (stickySaveFormOnclick)
 *
 * @returns {undefined}
 */
export function storeEmployeeTimeOffRequest() {
	if (validatePtoRequest('#request')) {
		postEmployeeTimeOffRequest();
	} else {
		submittingRequest = false;
	}
}

/**
 * Post the employee time off request for the request page.
 *
 * @returns {undefined}
 * @internal Doesn't need to be exported as the functionality is all contained here.
 */
function postEmployeeTimeOffRequest() {
	const $requestForm = $('#request');
	const formData = getFormData($requestForm);

	const postData = $.param(formData);

	return Ajax.post('/time_off/requests', postData)
		.then(() => {
			submittingRequest = false;
			window.location.href = $requestForm.data('doneUrl');
		})
		.catch((error) => {
			if (error.response.data) {
				window.setMessage(error.response.data, 'error');
			} else {
				errorFallBack();
			}

			submittingRequest = false;
			window.BambooHR.Modal.setState({ isOpen: true, isProcessing: false }, true);
			document.dispatchEvent(new CustomEvent('SiteFooterActions:toggleProcessing'));
		});
}

// this whole function can be removed when jade goes out
function zeroOutBalanceModal(policyName, onSave) {
	const t10s = {
		header: $.__('This will remove the current balance'),
		noBalance: $.__("Flexible time off types don't have a balance."),
		withCurrentName: $.__(`Setting this accrual policy to "Flexible" will clear this employee's %1$s balance.`, policyName),
	};

	const template = `
		<div class="ZeroOutBalanceModal">
		<ba-icon name="calculator-warning-44x48" class="ZeroOutBalanceModal__icon"></ba-icon>
			<h3 class="baseColor ZeroOutBalanceModal__header">!*= t10s.header *!</h3>
			<p class="ZeroOutBalanceModal__content">
				!*= t10s.noBalance *!
				!*= t10s.withCurrentName *!
			</p>
		</div>
	`;

	const html = microTemplate(template, { policyName, t10s });

	SimpleModal.openModal({
		html,
		title: $.__('Just Checking...'),
		footer: {
			show: true,
			buttons: {
				primary: {
					text: $.__('Clear Balance and Save'),
					action() {
						window.SimpleModal.closeModal();
						onSave();
					},
				},
				cancel: {
					text: $.__('Cancel'),
					action() {
						window.SimpleModal.closeModal();
					},
				},
			},
		},
	});
}
