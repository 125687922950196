/**
 * Get the missing values when comparing two arrays
 * @param  {Array} array1 The first array to compare
 * @param  {Array} array2 The second Array to compare
 *
 * @return {Array}        The results array
 */
export function missing(array1: Array<any>, array2: Array<any>): Array<any> {
	const a = [...array1];
	const b = [...array2];

	array1.forEach((item, index) => {
		const aIndex = a.indexOf(item);
		const bIndex = b.indexOf(item);

		if (bIndex >= 0) {
			a.splice(aIndex, 1);
			b.splice(bIndex, 1);
		}
	});

	return [...a, ...b];
}

/**
 * Compare function useful for Array.sort(). Ignores case.
 * @param  {String} a The first string to compare
 * @param  {String} b The second string to compare
 * @return {Boolean}  -1 if "a" should be below "b", 0 if they are the same, 1 if "a" should be above "b"
 */
export function stringComparer<T extends {name: string}>(a: T, b: T): number {
	const nameA = a.name.toUpperCase();
	const nameB = b.name.toUpperCase();

	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
}

/**
 * Splits an array based upon a passed evaluation
 * Example:
 *      const [passed, didNotPass] = splitOnCondition(someArray, o => o.something);
 * @param array
 * @param condition
 * @returns {[[], []]}
 */
export function splitOnCondition(array: Array<any>, condition: Function): Array<any> {
	const metCondition = [];
	const failedCondition = [];

	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		(condition(item) ? metCondition : failedCondition).push(item);
	}

	return [metCondition, failedCondition];
}
