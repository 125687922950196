import { BodyText, Flex, Label, LayoutBox, Radio } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	useEffect,
	useState,
} from 'react';

import {
	AccountNumberInputField,
	AccountTypeInputField,
	FabInputRadio,
	RoutingNumberInputField,
} from './components';
import {
	getDifferentBankOtherType,
	getInitialTransactionMethodType,
	getTransactionMethodData,
	renderWireMessage,
} from './domain';
import {
	BankAccountType,
	Banks,
	CollectionRefundType,
	TransactionMethodType,
} from './types';

import './transaction-method-selector.styl';

export interface Props {
	banksOnFile: Banks;
	collectionRefundType: CollectionRefundType;
	handleChange: Function;
	hasApprovalDetails?: boolean;
	isDisabled: boolean;
	renderWireInformation?(): string | JSX.Element;
}

export function TransactionMethodSelector(props: Props): JSX.Element {
	const {
		banksOnFile = [],
		collectionRefundType,
		handleChange,
		hasApprovalDetails = true,
		isDisabled,
		renderWireInformation = renderWireMessage,
	} = props;

	const [type, setType] = useState<TransactionMethodType>(() => getInitialTransactionMethodType(banksOnFile, collectionRefundType));
	const [bankId, setBankId] = useState(banksOnFile && banksOnFile.length ? banksOnFile[0].bankId : null);
	const [routingNumber, setRoutingNumber] = useState('');
	const [routingNumberError, setRoutingNumberError] = useState('Required');
	const [accountNumber, setAccountNumber] = useState('');
	const [accountNumberError, setAccountNumberError] = useState('Required');
	const [accountType, setAccountType] = useState<BankAccountType>(null);
	const [accountTypeError, setAccountTypeError] = useState('Required');

	const isExistingBankSelected = type === 'bank';
	const isOtherBankSelected = type === 'other' || type === 'otherNoBank';
	const isWireTransferSelected = type === 'wire';

	useEffect(() => {
		const transactionMethodData = getTransactionMethodData({
			accountNumber,
			accountNumberError,
			accountType,
			accountTypeError,
			bankId,
			routingNumber,
			routingNumberError,
			type,
		});
		handleChange(transactionMethodData);
	}, [
		accountNumber,
		accountNumberError,
		accountType,
		accountTypeError,
		bankId,
		handleChange,
		routingNumber,
		routingNumberError,
		type,
	]);

	return ifFeature('encore',
		<div className="TransactionMethodSelector">
			<form autoComplete="off">
				<Flex flexDirection="column">
					<LayoutBox paddingBottom={1}>
						<Label>
							<BodyText size="small">
								{ collectionRefundType === 'refund' ? (
									$.__('Select Refund Method')
								) : (
									$.__('Select Collection Method')
								) }
							</BodyText>
						</Label>
					</LayoutBox>
					{ hasApprovalDetails && banksOnFile.map(bank => (
						<Radio 
							checked={ isExistingBankSelected && bankId === bank.bankId }
							disabled={ isDisabled }
							id={ `transaction-method-selector-radio-bank-${ bank.bankId }` }
							key={ bank.bankId }
							label={ `${ bank.bankName } ${ bank.maskedAccountNumber }` }
							name="transactionMethod"
							onChange={ (): void => {
								setType('bank');
								setBankId(bank.bankId);
							} }
							value={ bank.bankId }
						/>
					)) }
					{ !hasApprovalDetails && (
							<BodyText size='small'>Failed to load Bank details. Please try again soon.</BodyText>
					) }
					<Radio
						checked={ isOtherBankSelected }
						disabled={ isDisabled }
						id="transaction-method-selector-radio-other-bank"
						label={ $.__('Use Different Bank Account') }
						name="transactionMethod"
						onChange={ (): void => setType(getDifferentBankOtherType(collectionRefundType)) }
						value="otherBank"
					/>
					{ isOtherBankSelected && (
						<LayoutBox marginLeft={3}>
							{ collectionRefundType === 'refund' ? (
								<LayoutBox marginBottom={1}>
									<BodyText size="small">
										{ $.__('Once the refund has been accepted, our tax admin team will reach out to you to collect your bank account information.') }
									</BodyText>
								</LayoutBox>
							) : (
								<>
									<LayoutBox marginBottom={1}>
										<RoutingNumberInputField
											isDisabled={ isDisabled }
											onChange={ (value, error): void => {
												setRoutingNumber(value);
												setRoutingNumberError(error);
											} }
										/>
									</LayoutBox>
									<LayoutBox marginBottom={1}>
										<AccountNumberInputField
											isDisabled={ isDisabled }
											onChange={ (value, error): void => {
												setAccountNumber(value);
												setAccountNumberError(error);
											} }
										/>
									</LayoutBox>
									<LayoutBox marginBottom={1}>
										<AccountTypeInputField
											isDisabled={ isDisabled }
											onChange={ (value, error): void => {
												setAccountType(value);
												setAccountTypeError(error);
											} }
										/>
									</LayoutBox>
								</>
							) }
						</LayoutBox>
					) }
					{ collectionRefundType === 'collection' && (
						<>
							<Radio
								checked={ isWireTransferSelected }
								disabled={ isDisabled }
								id="transaction-method-selector-radio-wire-transfer"
								label={ $.__('Wire Transfer') }
								name="transactionMethod"
								onChange={ (): void => setType('wire') }
								value="wire"
							/>
							{ isWireTransferSelected && (
								<LayoutBox marginLeft={3}>
									{ renderWireInformation() }
								</LayoutBox>
							) }
						</>
					) }
				</Flex>
			</form>
		</div>,
		<div className="TransactionMethodSelector">
			<form autoComplete="off">
				<fieldset className="fab-RadioGroup">
					<legend className="fab-Label TransactionMethodSelector__groupLabel">
						{ collectionRefundType === 'refund' ? (
							$.__('Select Refund Method')
						) : (
							$.__('Select Collection Method')
						) }
					</legend>
					{ hasApprovalDetails && banksOnFile.map(bank => (
						<FabInputRadio
							handleChange={ (e): void => {
								setType('bank');
								setBankId(bank.bankId);
							} }
							id={ `transaction-method-selector-radio-bank-${ bank.bankId }` }
							isChecked={ isExistingBankSelected && bankId === bank.bankId }
							isDisabled={ isDisabled }
							key={ bank.bankId }
							label={ `${ bank.bankName } ${ bank.maskedAccountNumber }` }
							name="transactionMethod"
						/>
					)) }
					{ !hasApprovalDetails && (
						'Failed to load Bank details. Please try again soon.'
					) }
					<FabInputRadio
						handleChange={ (): void => setType(getDifferentBankOtherType(collectionRefundType)) }
						id="transaction-method-selector-radio-other-bank"
						isChecked={ isOtherBankSelected }
						isDisabled={ isDisabled }
						label={ $.__('Use Different Bank Account') }
						name="transactionMethod"
					/>
					{ isOtherBankSelected && (
						<div className="TransactionMethodSelector__otherBank">
							{ collectionRefundType === 'refund' ? (
								<div>
									{ $.__('Once the refund has been accepted, our tax admin team will reach out to you to collect your bank account information.') }
								</div>
							) : (
								<>
									<div className="TransactionMethodSelector__otherBankInputField">
										<RoutingNumberInputField
											isDisabled={ isDisabled }
											onChange={ (value, error): void => {
												setRoutingNumber(value);
												setRoutingNumberError(error);
											} }
										/>
									</div>
									<div className="TransactionMethodSelector__otherBankInputField">
										<AccountNumberInputField
											isDisabled={ isDisabled }
											onChange={ (value, error): void => {
												setAccountNumber(value);
												setAccountNumberError(error);
											} }
										/>
									</div>
									<div className="TransactionMethodSelector__otherBankInputField">
										<AccountTypeInputField
											isDisabled={ isDisabled }
											onChange={ (value, error): void => {
												setAccountType(value);
												setAccountTypeError(error);
											} }
										/>
									</div>
								</>
							) }
						</div>
					) }
					{ collectionRefundType === 'collection' && (
						<>
							<FabInputRadio
								handleChange={ (e): void => setType(e.target.value as TransactionMethodType) }
								id="transaction-method-selector-radio-wire-transfer"
								isChecked={ isWireTransferSelected }
								isDisabled={ isDisabled }
								label={ $.__('Wire Transfer') }
								name="transactionMethod"
								value="wire"
							/>
							{ isWireTransferSelected && (
								<div className="TransactionMethodSelector__wireTransferMessage">
									{ renderWireInformation() }
								</div>
							) }
						</>
					) }
				</fieldset>
			</form>
		</div>
	);
}
