import { isNil } from 'lodash';
import {
	allFilesFinalized,
	convertFileForList,
	getAction,
	getDataObject,
	getDownloadFileId,
	isCompleteOrEmployeeComplete,
	isDisabled,
	isEsig,
	waitingOnSig
} from 'file.util';

export function buildInfoObject(fileObject, parentIsComplete) {
	const {
		employee_file_data,
		company_file_data,
		esignatures,
		ext,
		img_sizes,
		is_current_signer,
		required,
		workflow_instance_id,
		esignature_instance_id,
	} = fileObject;
	let text = ext;
	let className;

	if (isEsig(fileObject)) {
		const {
			can_sign,
			completed,
			has_signed,
			cancelled,
			whose_turn,
			is_current_signer
		} = esignatures;

		switch (true) {
			case cancelled:
				text = 'Request Canceled';
				break;
			case employee_file_data && employee_file_data.id && has_signed:
				text = 'Signed';
				className = 'signed';
				break;
			case company_file_data && company_file_data.id && has_signed:
				text = 'Signed';
				className = 'signed';
				break;
			case esignatures.completed && !has_signed:
				text = 'Signed';
				className = 'signed';
				break;
			case can_sign:
				text = required === 'yes' ? 'Sign Now' : 'Signature Optional';
				className = required === 'yes' ? 'sign' : 'optional';
				break;
			case has_signed:
				text = 'Signed';
				className = 'signed';
				break;
			case whose_turn && whose_turn.length > 0:
				text = 'Signers Pending';
				break;
			case parentIsComplete && !isNil(is_current_signer) && is_current_signer:
				text = 'Chose not to sign';
				break;
			case parentIsComplete && !isNil(is_current_signer) && !is_current_signer:
				text = 'No signature needed';
				break;
			case !parentIsComplete &&
					!isNil(workflow_instance_id) && workflow_instance_id &&
					!isNil(is_current_signer) && is_current_signer:
				text = 'Signature Optional';
				className = 'optional';
				break;
			case !parentIsComplete &&
					!isNil(workflow_instance_id) && workflow_instance_id &&
					!isNil(is_current_signer) && !is_current_signer:
				text = 'Signers Pending';
				break;
			default:
				text = 'Esignature';
				break;
		}
	}
	return className ? {
		className,
		icon: img_sizes.small,
		text
	} : {
		icon: img_sizes.small,
		text
	};
}

export function fileCardConverter(fileObject, isAssumedUser = false, parentIsComplete = false) {
	const {
		ext,
		id,
		name,
		scan_info,
		required,
		can_see_file,
		esignatures,
		employee_file_data,
		company_file_data,
		original_file_name,
		esignature_instance_id
	} = fileObject;

	const isRequired = required && required === 'yes';
	const disabled = isDisabled(fileObject);

	return {
		action: disabled ? '' : getAction(fileObject, isAssumedUser),
		classNames: [],
		data: getDataObject(fileObject),
		disabled,
		ext,
		esignatureInstanceId: esignature_instance_id ? esignature_instance_id : 0,
		fileId: id,
		info: buildInfoObject(fileObject, parentIsComplete),
		isSafe: !scan_info,
		isEsig: isEsig(fileObject),
		name,
		mode: 'display',
		originalName: original_file_name,
		status: '',
		required: isRequired || false,
		theme: 'dark'
	};
}
