import {
	Fragment,
} from 'react';

import { Icon } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export default function DownList({
	label,
	...props
}) {
	const {
		isButton,
		isInput,
		isExcluded,
		levelTypes,
	} = props;

	return (
		<Fragment>
			<ba-toggle-content>
				{ label }
			</ba-toggle-content>
			{ isButton && (
				<ButtonHeader { ...props } />
			) }
			{ levelTypes
				.filter(levelType => (
					levelType.name !== 'all' &&
					!isExcluded(levelType.name)
				))
				.map(levelType => (
					<AccessLevelType
						{ ...props }
						key={ levelType.name }
						levelType={ levelType }
					/>
				)) }
			{ !isExcluded('multiple') && (
				<ItemGroup
					anchor="bottom"
					data-bi-id={'test-id'}
					{ ...props }
				>
					<Item { ...props } multiple>
						{ $.__('Multiple Access Levels...') }
					</Item>
				</ItemGroup>
			) }
			{ isInput && (
				<HiddenInputs { ...props } />
			) }
		</Fragment>
	);
}

function Item(props) {
	const {
		_bem,
		children,
		classes,
		accessLevel,
		isSelected,
		multiple = false,
		preview = false,
		resetPassword = false,
		managePayrollCompanyAccess = false,
	} = props;
	const isChecked = accessLevel && isSelected(accessLevel);
	const className = _bem('item', {
		'^checked': isChecked,
	}, classes);

	let id;
	let dataBiId = '';

	if (multiple) {
		id = 'multiple';
		dataBiId = "multiple-access-levels-action"
	} else if (preview) {
		id = 'preview';
		dataBiId = "preview-user-action"
	} else if (resetPassword) {
		id = 'resetPassword';
		dataBiId = "reset-user-password-action"
	} else if (accessLevel) {
		id = accessLevel.id || 'no_access';
	} else if (managePayrollCompanyAccess) {
		id = 'managePayrollCompanyAccess';
	}

	return (
		<ba-option
			action-only={ !accessLevel || undefined }
			data-access-level-selector-id={ id }
			value={ id }
		>
			<div className={ className } data-bi-id={dataBiId}>
				{ isChecked && (
					<span className={_bem('ico', 'checked')}>
						{ifFeature('encore', <ba-icon encore-name="check-solid" encore-size="16"/>, <Icon name="fab-check-14x14"/>)}
					</span>
				) }
				{ children }
			</div>
		</ba-option>
	);
}

function ItemGroup({
	children,
	label,
	anchor,
}) {
	return (
		<ba-option-group
			anchor={ anchor }
			label={ label }
		>
			{ children }
		</ba-option-group>
	);
}

function AccessLevel(props) {
	const {
		accessLevel,
		isOption,
	} = props;
	const {
		label,
		count,
	} = accessLevel;

	return (
		<Item { ...props }>
			{ label }
			&nbsp;
			<span className="ba-Dropdown__optionDescriptorText">({ count })</span>
		</Item>
	);
}

function AccessLevelType(props) {
	const {
		levelType,
	} = props;
	let {
		levels,
		label,
	} = levelType;

	if (!(
		Array.isArray(levels) &&
		levels.length > 0
	)) {
		levels = [levelType];
		label = null;
	}

	if (Array.isArray(levels)) {
		return (
			<ItemGroup
				{ ...props }
				label={ label }
			>
				{ levels.map(level => (
					<AccessLevel
						{ ...props }
						key={ level.id || level.name }
						accessLevel={ level }
					/>
				)) }
			</ItemGroup>
		);
	}

	return (
		<AccessLevel
			{ ...props }
			accessLevel={ levelType }
		/>
	);
}

function shouldShowManagePayrollCompanyAccessOption(accessLevelId, levelTypes) {
	if (!accessLevelId) {
		return false;
	}

	const flattenedLevels = levelTypes.map(levelType => {
		if (levelType?.levels) {
			return levelType.levels;
		}
		return levelType
	}).flat();

	const currentAccessLevel = flattenedLevels.find(level => level.id === accessLevelId);

	if (!currentAccessLevel) {
		return false;
	}

	return currentAccessLevel.isPayrollPermission;
}

function ButtonHeader(props) {
	const {
		_bem,
		canPreview,
		isExcluded,
		hasEmail,
		firstName,
		levelTypes,
		accessLevelId
	} = props;

	const showPreview = canPreview === true && !isExcluded('preview');
	const showResetPassword = hasEmail && !isExcluded('resetPassword');

	if (!showPreview && !showResetPassword) {
		return null;
	}

	return (
		<ItemGroup
			anchor="top"
			{ ...props }
		>
			{ showPreview && (
				<Item
					{ ...props }
					classes="js-previewAs"
					preview
				>
					<span className={ _bem('ico', 'preview') }>
						<ba-icon name="fab-eye-16x12" encore-name='eye-regular' encore-size="16" />
					</span>
					{ $.__('Preview BambooHR as %1', firstName) }
				</Item>
			) }
			{ showResetPassword && window.SESSION_COMPANY.allowPasswordLogin === true && (
				<Item
					{ ...props }
					classes="js-resetPassword"
					resetPassword
				>
					{ $.__(`Reset User's Password`) }
				</Item>
			) }
			{ (shouldShowManagePayrollCompanyAccessOption(accessLevelId, levelTypes)) && (
				<Item
					{ ...props }
					classes="js-managePayrollCompanyAccess"
					managePayrollCompanyAccess
				>
					{ $.__(`Manage Payroll Access`) }
				</Item>
			) }

		</ItemGroup>
	);
}

function HiddenInputs({
	accessLevelIds,
	inputFieldName,
	noAccessFieldName,
	noAccessValue,
}) {

	return (
		<Fragment>
			{ accessLevelIds.map(levelId => (
				<input
					key={ levelId }
					name={ inputFieldName }
					type="hidden"
					value={ levelId }
				/>
			)) }
			<input
				name={ noAccessFieldName }
				type="hidden"
				value={ noAccessValue }
			/>
		</Fragment>
	);
}
