import {defaultsDeep} from 'lodash';

import BaseChart from './base-chart';
import {drawChart as drawRadialProgressChart, setupChart} from './radial-progress-charts/radial-progress-chart.js';

/******************************************************************************
	Data contract (this.props.data)
	{
		type: 'radial',
		mainBar: {
			'value': 56,
		},
		innerBar: [
			{
				"label": "Detractors",
				"value": 255
			},
			{
				"label": "Neutrals",
				"value": 324
			},
			{
				"label": "Promoters",
				"value": 67
			}
		],
		numberOfPromoters: 67,
		numberOfNeutrals: 324,
		numberOfDetractors: 255,
		ternaryValue: 646,
		ternaryPercent: '50%',
		ternaryIconTheme: `warning` // `no-icon` `warning` a string will be provided and matched to the component to determine which icon to show
	}

	Settings contract (this.props.settings)
	{
		chartClass: 'RadialChart',
		showInnerBar: true,
		container: {
			height: 350,
			width: 375,
			margin: {
				top: 0,
				right: 0,
				bottom: 0,
				left: 0
			}
		},
		backgroundBar: {
			color: '#ccc'
		},
		mainBar: {
			radius: 180,
			width: 40,
			color: '#8DB812'
		},
		innerBar: {
			colors: [
				'#FC722C',
				'#FCBA50',
				'#A5D225'
			]
		},
		primaryText: {
			show: true,
		},
		secondaryText: {
			show: true,
			text: $.__('Your eNPS Score')
		},
		ternaryText: {
			show: true,
			text: $.__('Responses')
		}
	}

******************************************************************************/

const CLASS_NAME = 'RadialProgressChart';
const DEFAULT_SETTINGS = {
	chartClass: '',
	showInnerBar: false,
	innerBar: {
		colors: []
	},
	animate: !window.navigator.webdriver, /** On headless chrome default is false elsewhere it is true */
};

export default class RadialProgressChart extends BaseChart {
	constructor(selector, settings = DEFAULT_SETTINGS) {
		super();

		this._selector = selector;
		this._CLASS_NAME = CLASS_NAME;
		this._settings = defaultsDeep({}, settings, DEFAULT_SETTINGS);

		this._validateRequirements();
		this._createRoot();

		setupChart.call(this);
	}

	/**
	 * Draws each element in the order needed for the chart to display properly
	 * 
	 * @param {object} data Formatted Data ready to be displayed
	 */
	draw(data) {
		drawRadialProgressChart.call(this, data);

		this._dynamicallyAdjustSVGHeight();
	}
}
