import { Flex, TextButton } from '@bamboohr/fabric';
import {
	Message,
} from '@bamboohr/utils/lib/message';

import { IowaStateMessageProps } from '../../types';

export function IowaStateWithholdingMessage(props: IowaStateMessageProps) {
	const { 
		handleToggleClick
	} = props;

	return (
		<Flex alignItems='center' margin='-4px 0 12px'>
			<Message
				link={(text) => (
					<TextButton inline={true} onClick={() => handleToggleClick()} type='button'>
						{text}
					</TextButton>
				)}
				text={ $._('Need to make changes? [Update using the new Iowa State form.]') }
			/>
		</Flex>
	);
}
