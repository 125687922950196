import * as Models from './models';
import { Iterable, Record } from 'immutable';
import Comments from './views/comments/components/comments';

const validExtensionsList = window.GLOBAL_VALID_EXTENSIONS ? Array.from(window.GLOBAL_VALID_EXTENSIONS) : ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'jpg', 'jpeg', 'tif', 'tiff', 'gif', 'png', 'bmp', 'txt', 'rtf', 'zip'];
export const VALID_EXTENSIONS = validExtensionsList.map(ext => '.' + ext);

export const FeedConfig = Record({
	enableAttach: true,
	enableReply: true,
	externalCallback: () => undefined,
	externalRender: () => undefined,
	externalRenderByType: () => undefined,
	inputPosition: 'top',
	parentId: 0,
	parentType: 'applicant_tracking',
	placeholder: $.__('Add a comment...'),
	replyPlaceholder: $.__('Add your reply...'),
	submitEditText: $.__('Save'),
	submitNewText: $.__('Post'),
	readOnly: false,
	rootCommentURL: '/comments',
	rootComponent: Comments,
	rootData: {},
});

let feedConfig = FeedConfig();

// Map to convert data into specific record types
export const RECORD_MAP = {
	'entities, comments, byId': Models.Comment,
	'entities, files, byId': Models.File,
	'entities, replies, byId': Models.Reply,
	'entities, users, byId': Models.User,
	'entities, events, byId': Models.Event
};

// store multiple feed configs, keyed by the parentId property.
let configs = {};

// return a config by parentId, if no id is supplied, retun the first config in the object
export const getConfig = (id) => {
	if (!id) {
		let key;
		for (key in configs) { break; }
		return configs[key];
	}
	return configs[id];
};

/**
 * Set the global feed config variable
 * @param  {Object|Iterable} config A config object or Immutable config record
 */
export function initializeFeedConfig(config) {
	if (Iterable.isIterable(config)) {
		feedConfig = config;
	} else {
		feedConfig = FeedConfig(config);
	}
	configs[feedConfig.parentId] = feedConfig;

	return feedConfig;
}

const COMMENT_REPLIES_WARNING = $.__('Are you sure you want to delete this comment and its replies (including any attached files)?');
const COMMENT_FILES_WARNING = $.__('Are you sure you want to delete this comment and all its files?');
const COMMENT_WARNING = $.__('Are you sure you want to delete this comment?');

/**
 * get the proper message when deleting a comment
 * @param  {object} item       The Comment Object
 */
export const DeleteMessageForComments = (item) => {
	const {replies, files} = item;
	
	if ((replies && replies.size)) {
		return COMMENT_REPLIES_WARNING;
	} else if (files && files.size) {
		return COMMENT_FILES_WARNING;
	}
	return COMMENT_WARNING;
};


