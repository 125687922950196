import React from 'react';
import PropTypes from 'prop-types';

import { getThemeName } from 'get-theme-name.util';
import { GreenhouseIcon } from './icons';
import { MicroFrontend } from './micro-frontend';
import { useResource } from './resources';
import { VersionManager } from './version-manager';
import { getJsonScriptVar } from 'BambooHR.util';

const isDev = JSON.parse(window.localStorage.getItem('bhr_greenhouse_dev')) || false;

const errorMessage = isDev ? (
	<span>
		Please make sure you've cloned the{' '}
		<a href='https://github.com/BambooHR/fe-greenhouse' rel='noopener noreferrer'>
			fe-greenhouse
		</a>{' '}
		repository and run the build.
	</span>
) : (
	'If the problem persists, please contact the Internal Affairs development team.'
);

const config = getJsonScriptVar('config', 'pusher');

export const GreenhouseMicroFrontend = ({ route }) => {
	const name = 'greenhouse';
	const [resource, setResource] = useResource(name);
	const { mode: fabricMode, themeName: fabricThemeName } = getThemeName();

	const payload = {
		fabricMode,
		fabricThemeName,
		globals: {
			PUSHER_AUTH_KEY: config.PUSHER_AUTH_KEY,
			PUSHER_CLUSTER: config.PUSHER_CLUSTER,
		},
		route,
	};

	return (
		<VersionManager icon={<GreenhouseIcon />} onChange={setResource} resource={resource}>
			<MicroFrontend
				body={errorMessage}
				header={isDev ? 'Local Dev Tip' : 'We can help!'}
				host={resource?.url}
				manifest='manifest.json'
				name={name}
				payload={payload}
			/>
		</VersionManager>
	);
};

GreenhouseMicroFrontend.propTypes = {
	route: PropTypes.string,
};
GreenhouseMicroFrontend.defaultProps = {
	route: undefined,
};
