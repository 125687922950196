import { createStyles, makeStyles } from '@bamboohr/fabric';

export const getStyles = () => {
	return makeStyles(({ palette }) => {
		return createStyles({
			infoIcon: {
				position: 'relative' as const,
				'& svg': {
					fill: palette.grey[600],
				},
				'&:hover svg': {
					fill: palette.primary.main,
				},
			},
			popoverContainer: {
				width: '250px',
			},
		});
	});
};
