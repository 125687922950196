import ajax from '@utils/ajax';
import {
	currentEditingEmployeeId,
	validateWithBE,
} from 'dynamic-form';
import { RATE_LIMIT_ERROR } from './common';

export const checkEmailExists = (context: DynamicForm.Context, field: DynamicForm.DataProps, params: any) => {
	const message = $.__('This email is already being used.');

	return async (value): Promise<boolean | string> => {
		if (!value) {
			// don't make a BE request - field isn't filled in yet, let required or regex run
			return true;
		}
		const data = {
			id: currentEditingEmployeeId(),
			userId: field.props['data-userid'] || null, // userid is used/set when converting ATS to Emp. Only used on Email fields because ATS created user in the DB.
			email: value,
			action: 'checkUserEmail',
		};
		const cacheKey = `${ context.formData.memoizeKey }${ currentEditingEmployeeId }${ data.action }${ data.email }`;
		const { isValid, isRateLimited } = await validateWithBE(ajax.post, data, null, cacheKey);
		if (isRateLimited) { return RATE_LIMIT_ERROR; }

		return isValid || message;
	};
};
