import { FunctionComponent, useState, useEffect } from 'react';
import { 
	BodyText,
	Select,
	SelectProps,
} from '@bamboohr/fabric';
import { Fields, handleControlledInput, getField } from 'dynamic-form';
import './employee-access.styl';
import { isEnabled, ifFeature} from '@bamboohr/utils/lib/feature';
import EmployeeAccessDisabled from './employee-access-disabled';
import EmployeeAccessQuickBooks from './employee-access-quickbooks';
import EmployeeAccessRegular from './employee-access-regular';

const EmployeeAccess: FunctionComponent<DynamicForm.EmployeeAccessBlockProps> = ({ props, settings, context }) => {
	const { id, name, value = 'disabled' } = props;
	const { hasEmailFields, permission, userGroups } = settings;
	const {
		controls: { FieldRow },
	} = context;

	const defaultUserGroup = userGroups && Array.isArray(userGroups) ? [userGroups[0].value] : [];

	const [access, setAccess] = useState(window.isQuickBooksIntegration ? 'enabled' : value);
	const [accessSelect, setAccessSelect] = useState(defaultUserGroup);
	const [emptyEmail, setEmptyEmail] = useState(true);

	const workEmail = getField(context, Fields.emailWork);
	const workEmailValue = workEmail?.props?.value;
	const homeEmail = getField(context, Fields.emailHome);
	const homeEmailValue = homeEmail?.props?.value;

	useEffect(() => {
		const workEmailValueTruthy = !!workEmailValue;
		const homeEmailValueTruthy = !!homeEmailValue;
		if (workEmailValueTruthy || homeEmailValueTruthy) {
			setEmptyEmail(false);
		} else {
			setEmptyEmail(true);
		}
	}, [workEmailValue, homeEmailValue]);

	const handleSelectChange = (selectName: string, selectValue: string): void => {
		setAccess(selectValue);
		handleControlledInput('text', id, selectValue, context);
	};

	const handleAccessGroupChange = (newSelection): void => {
		setAccessSelect(newSelection);
	};

	const accessLevelSelectProps: SelectProps = {
		id: 'ess_group_id',
		isClearable: false,
		items: userGroups,
		// @ts-expect-error TODO: fix "label does not exist in SelectProps"
		label: $.__('Access Level'),
		menuWidth: ifFeature('encore', 7),
		name: 'ess_group_id',
		onChange: handleAccessGroupChange,
		selectedValues: accessSelect,
		width: ifFeature('encore', 7, 6),
	};

	const hasMultipleAccessLevels = userGroups && Array.isArray(userGroups) && userGroups.length > 1;
	const accessLevelText = hasMultipleAccessLevels ? $.__('They will be able to login to BambooHR using the access level you choose.') : $.__('They will be able to login to BambooHR using the access level you choose in settings.');

	//View mode functions
	const renderEmailReminderText = (): React.ReactElement|null => {
		if (emptyEmail && access === 'enabled') {
			return (
				<BodyText color={'neutral-weak'} size={'extra-small'}>
					{$.__('To enable access, this employee needs to be active and have a valid work or home email.')}
				</BodyText>
			);
		}
		return null;
	};

	const renderAccessLevelOptions = (): React.ReactElement|null => {
		if (hasMultipleAccessLevels && access === 'enabled') {
			return (
				<FieldRow context={context} props={{}} settings={{}}>
					<div className='fieldBox fab-FormField'>
						<Select {...accessLevelSelectProps} />
					</div>
				</FieldRow>
			);
		}
		return null;
	};

	const renderViewMode = (): React.ReactElement => {
		if (permission !== 'REQUEST/EDIT') {
			return <EmployeeAccessDisabled access={access} context={context} props={props} settings={settings} />;
		} else if (isEnabled('encore') && window.isQuickBooksIntegration) {
			return <EmployeeAccessQuickBooks accessLevelText={accessLevelText} name={name} renderAccessLevelOptions={renderAccessLevelOptions} renderEmailReminderText={renderEmailReminderText} value={access} />
		} else {
			return <EmployeeAccessRegular access={access} accessLevelText={accessLevelText} context={context} emptyEmail={emptyEmail} handleSelectChange={handleSelectChange} hasEmailFields={hasEmailFields} props={props} renderAccessLevelOptions={renderAccessLevelOptions} renderEmailReminderText={renderEmailReminderText} settings={settings} />;
		}
	};

	return (
		<div className='DynamicForm--employeeAccess'>
			{renderViewMode()}
		</div>
	);
};

export default EmployeeAccess;
