import {
	useEffect,
	useRef,
} from 'react';

function generateCents(amount) {
	let cents = amount % 100;
	if (cents < 10) {
		cents = '0' + cents;
	}

	return cents;
}

function addThousandsSeparators(amount, thousandsSeparator) {
	for (let i = 0; i < Math.floor((amount.length - (1 + i)) / 3); i++) {
		amount = amount.substring(0, amount.length - (4 * i + 3)) + thousandsSeparator + amount.substring(amount.length - (4 * i + 3));
	}

	return amount;
}

/**
 * The logic of this function originally sourced from http://bendewey.wordpress.com/2008/11/11/jquery-formatcurrency-plugin/
 *
 * All required props
 * @param {string} amount
 * @param {string} thousandsSeparator
 * @param {string} decimalChar
 * @param {object} currencyData
 * @param {string} currencyData.symbol
 * @param {number} currencyData.symbolPosition
 */
export function formatAmountWithCurrency(amount, currencyData, thousandsSeparator, decimalChar) {
	if (!thousandsSeparator) {
		thousandsSeparator = ',';
	}
	if (!decimalChar) {
		decimalChar = '.';
	}

	function sanitizeNumber(num) {
		const regex = new RegExp('[^-0-9\.\,]+', 'gi'); // Remove $ or any leading characters besides numeric and separators
		let thousandsRegex = '';
		if (thousandsSeparator !== '') {
			thousandsRegex = new RegExp('\\' + thousandsSeparator, 'gi'); // Remove the thousands characters
		}
		// Please check out the above note before changing this regex.
		num = num.replace(currencyData.symbol, '').replace(regex, '').replace(thousandsRegex, '');
		if (decimalChar === num[0]) {
			num = '0' + num;
		}
		return Number(num.replace(',', '.').replace(/^(-?)([^0-9-]+)/, '$1'));
	}

	if (amount === '') {
		return amount;
	}

	amount = sanitizeNumber(amount);
	if (isNaN(amount)) {
		amount = '0';
	}

	const sign = (amount == (amount = Math.abs(amount)));
	amount = Math.floor(amount * 100 + 0.50000000001);
	const cents = generateCents(amount);
	amount = Math.floor(amount / 100).toString();

	amount = addThousandsSeparators(amount, thousandsSeparator);

	const formattedCurrency = (currencyData.symbolPosition) ? ((sign) ? '' : '-') + amount + decimalChar + cents + currencyData.symbol
		: ((sign) ? '' : '-') + currencyData.symbol + amount + decimalChar + cents;

	return formattedCurrency;
}

// Store a variable's value from the previous render
export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}
