import { hasTrax } from 'BambooHR.util';
import { fixCurrencyValue } from 'Currency.util';
import {
	isSelectedPSSyncing,
} from 'dynamic-form';

const message = $.__('Value must be greater than 0');

export const valueGreaterThanZero = (context: DynamicForm.Context, field: DynamicForm.DataProps, params: any) => {
	return (value: string | number | undefined): boolean | string => {
		const isValid = !value || fixCurrencyValue(value) > 0;
		if (params.onlyIfTrax) {
			if (hasTrax() && isSelectedPSSyncing(context)) {
				return isValid;
			}
			return true;
		}

		return isValid || message;
	};
};
