import moment from 'moment.lib';

// Returns a negative number if end date is before the start date, and a positive number if end date is after start date
export function daysBetween(startDate: string, endDate: string): number {

	const mutatedStartDate = getMutatedDate(startDate);
	const mutatedEndDate = getMutatedDate(endDate);

	return mutatedEndDate.diff(mutatedStartDate, 'day');
}
export function yearsBetween(startDate: string, endDate: string): number {

	const mutatedStartDate = getMutatedDate(startDate);
	const mutatedEndDate = getMutatedDate(endDate);

	return mutatedEndDate.diff(mutatedStartDate, 'year');
}

function getMutatedDate(date: string): moment.Moment {
	if (date && moment(date, 'YYYY-MM-DD', true).format('YYYY-MM-DD') !== date) {
		date = moment(date).format('YYYY-MM-DD');
	}
	return moment(date, 'YYYY-MM-DD');
}
