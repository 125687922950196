import './styles.styl';

export const minimal = {
	name: 'minimal',
	extraClasses: {
		tabIcon: () => ('baseFillColor'),
		tabLabel: () => ('baseColor'),
		tab: () => ('baseColorBg-after baseColor baseFillColor'),
	},
};
