import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';

import { ControlledAmountInput } from '../controlled-amount-input';
import { formatAmountWithCurrency, usePrevious } from '../utils';
import { noop } from 'lodash';

/**
 * This component manages the state of its input field
 * If you would like to manage the state of the input field, use the component in the controlled directory
 *
 * @param {string} props.name
 * @param {string} props.defaultValue
 * @param {object} props.selectedCurrencyItem
 * @param {string} props.selectedCurrencyItem.code
 * @param {string} props.selectedCurrencyItem.symbol
 * @param {number} props.selectedCurrencyItem.symbolPosition
 * @param {function} [props.handleValueChange]
 * @param {object} [props.inputProps]
 */
export function AmountInput(props) {
	const {
		defaultValue,
		name,
		selectedCurrencyItem,
		handleValueChange = noop,
		inputProps = {},
	} = props;
	const [value, setValue] = useState(defaultValue ? formatAmountWithCurrency(
		defaultValue,
		selectedCurrencyItem,
		window.GLOBAL_THOUSANDS_SEPARATOR,
		window.GLOBAL_DECIMAL_CHAR
	) : '');
	const previousCurrencyItem = usePrevious(selectedCurrencyItem);

	const updateControlledParent = (newValue) => {
		if (handleValueChange && typeof handleValueChange === 'function') {
			handleValueChange(newValue);
		}
	};

	// Use callback to memoize a version of this callback for use in an effect
	const updateFormattedAmount = useCallback((newValue, newCurrencyItem) => {
		const newFormattedValue = formatAmountWithCurrency(
			newValue,
			newCurrencyItem,
			window.GLOBAL_THOUSANDS_SEPARATOR,
			window.GLOBAL_DECIMAL_CHAR
		);
		setValue(newFormattedValue);
		updateControlledParent(newFormattedValue);
	}, []);

	const handleAmountBlur = (event) => {
		updateFormattedAmount(event.target.value, selectedCurrencyItem);
	};

	const handleAmountChange = (event) => {
		setValue(event.target.value);
	};

	// Format value with the new currency code whenever a new currency code is passed in
	useEffect(() => {
		if (previousCurrencyItem && previousCurrencyItem.code !== selectedCurrencyItem.code) {
			updateFormattedAmount(value, selectedCurrencyItem);
		}
	}, [selectedCurrencyItem, previousCurrencyItem, value, updateFormattedAmount]);

	return (
		<ControlledAmountInput
			inputProps={ inputProps }
			name={ name }
			onAmountBlur={ handleAmountBlur }
			onAmountChange={ handleAmountChange }
			value={ value }
		/>
	);
}
