import { IconButton, IconV2, Popover } from '@bamboohr/fabric';
import React, { useRef, useState } from 'react';

export function FedWithholdingEmployeeExemptionNote() {
	const buttonRef = useRef(null);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	return (
		<div>
			<IconButton
				aria-label={$.__('FedWithholdingEmployeeExemptionNote')}
				color="primary"
				floatingIcon={true}
				icon={<IconV2 name="circle-question-regular" size={12} />}
				noBoundingBox={true}
				onClick={() => setIsPopoverOpen(true)}
				ref={buttonRef}
				size="teenie"
				type="button"
			/>
			<Popover
				anchorEl={buttonRef.current}
				onClose={() => setIsPopoverOpen(false)}
				open={isPopoverOpen}
				placement="right"
				title={$.__(`Tax Exemptions`)}
			>
				{$.__('In rare cases, you may not be required to have federal taxes withheld. Please contact your company’s admin if you need to make changes to your federal tax withholdings.')}
			</Popover>
		</div>
	);
}
