import { Icon, IconV2, makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
	deleteIconContainer: {
		display: 'flex',
	},
}));

type Props = DynamicForm.FieldGroupElementProps & {
	handleRemove: (groupId: string) => void;
}

const EducationGroup: FunctionComponent<Props> = ({ props, settings, handleRemove, children }) => {
	const { canDelete } = settings;
	const { disabled, id } = props;
	const classes = useStyles();

	// user can delete if BE permission, or if it was dynamically added by the FE.
	const canRemove: boolean = canDelete || id.includes('add');

	return (
		<div { ...props }>
			{ canRemove && (
				<button
					className="fab-FloatingIconButton fab-FloatingIconButton--secondary formDelete withIconLabel"
					disabled={ disabled }
					onClick={ (): void => { handleRemove(id); } }
					type="button"
				>
					<span className="fab-FloatingIconButton__icon">
						<div className={`${ifFeature('encore', classes.deleteIconContainer, "")} phoneHide`}>
							{ifFeature('encore', <IconV2 name="trash-can-regular" size={16} />,<Icon name="fab-trash-can-14x16" />)}
						</div>
					</span>
					<span className="fab-FloatingIconButton__icon">
						<div className="hidden phoneOnly--inline-flex">
							{ifFeature('encore', <IconV2 name="trash-can-regular" size={12} />,<Icon name="fab-trash-can-11x13" />)}
						</div>
					</span>
				</button>
			) }
			<div className="fieldGroup divided">
				{ children }
			</div>
		</div>
	);
};

export default EducationGroup;
