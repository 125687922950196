import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { SlideTransition } from 'slide-transition.react';
import { Select } from '@fabric/select';
import { normalizeOptions } from '../../select';
import { getValidationContext, UsageContext } from '../../../../context';

const OTHER = 'other';

export const MultiSelect = (props) => {
	const {
		validationRegistration,
		options: optionProps,
		isRequired,
		isDisabled,
		isValueOther,
		hasOther,
		value: valueProps,
		label,
		name,
	} = props;

	const {
		setSpecialErrors,
		specialErrors,
	} = getValidationContext();

	const otherInputRef = useRef();
	let value = valueProps;
	if (isValueOther) {
		value = OTHER;
	} else if (valueProps) {
		const selectedValue = optionProps.filter((v) => {
			return v.id == valueProps;
		});
		value = selectedValue[0].id;
	}

	const options = [...optionProps];
	if (hasOther === 'yes') {
		options.push({ id: OTHER, option: 'Other' });
	}

	const [selectedValues, setSelectedValues] = useState([value]);
	const [otherValue, setOtherValue] = useState(isValueOther ? valueProps : '');
	const [showOther, setShowOther] = useState(hasOther === 'yes' && isValueOther);

	useEffect(() => {
		if (showOther) {
			setTimeout(() => {
				otherInputRef.current.focus();
			});
		}

	}, [showOther]);

	const _handleValidation = (rule = null, otherRule = null) => {
		setSpecialErrors(`${ name }`, rule);
		setSpecialErrors(`${ name }[${ OTHER }]`, otherRule);
	};

	const _handleSelect = (id, selected) => {
		setSelectedValues(selected);
		_handleValidation();
		setShowOther(selected[0] === OTHER);
	};

	const _handleClear = () => {
		setSelectedValues([]);
		setShowOther(false);
		_handleValidation();
	};

	const _handleOtherOnChange = (event) => {
		const { target } = event;
		const otherRule = target.value ? null : { rule: 'required' };
		_handleValidation(null, otherRule);
		setOtherValue(target.value);
	};

	return (
		<div
			className={ classNames('fab-InputWrapper CandidateField__inputWrapper', {
				CandidateField__multi: showOther,
			}) }
		>
			<div className="fab-FormColumn CandidateField__multiGroupColumn">
					<label
						className={ classNames('fab-Label', {
							'fab-Label--required': isRequired,
							'fab-Label--error': specialErrors[name],
						}) }
						htmlFor={ name }
					>
						{ label }
					</label>
					<div className="CandidateField__multiSelect">
						<Select
							inputRef={ validationRegistration }
							isDisabled={ Boolean(isDisabled) }
							items={ normalizeOptions(options) }
							name={ name }
							onClear={ _handleClear }
							onSelect={ _handleSelect }
							selectedValues={ selectedValues }
							width={ 100 }
						/>
					</div>
				<SlideTransition isOpen={ showOther }>
					<div className="CandidateField__multi">
						<div className="CandidateField__multiOther CandidateField__multiOtherSelect">
							<div className="fab-InputWrapper CandidateField__multiInputWrapper">
								<input
									className={ classNames('fab-TextInput fab-TextInput--width100', {
										'fab-TextInput--error': specialErrors[`${ name }[${ OTHER }]`],
									}) }
									disabled={ !showOther }
									id={ `${ name }[${ OTHER }]` }
									name={ `${ name }[${ OTHER }]` }
									onChange={ e => _handleOtherOnChange(e) }
									placeholder={ $.__('Please Specify') }
									ref={ otherInputRef }
									required={ true }
									type="text"
									value={ otherValue }
								/>
							</div>
						</div>
						{ specialErrors[`${ name }[${ OTHER }]`] && (
							<span className="fab-FormNote fab-FormNote--error">
								{ $.__('Response is required') }
							</span>
						) }
					</div>
				</SlideTransition>
			</div>
		</div>
	);
};
