import Ajax from '@utils/ajax';

import { htmlentities } from 'String.util';
import { Message } from '@bamboohr/utils/lib/message';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './styles.styl';

export function openPasswordResetModal({ firstName, email, userId }) {
	window.BambooHR.Modal.setState({
		content: (
			<div className='PasswordResetModal__message'>
				<Message
					params={[email || $.__('the user')]}
					text={$._('An email with a link to reset their password will be sent to **{1}**')}
				/>
			</div>
		),
		headline: $.__('Reset password for %1$s?', firstName),
		icon: ifFeature('encore', 'triangle-exclamation-regular', 'fab-triangle-exclamation-48x48'),
		iconColor: 'attention',
		iconV2Color: 'warning-medium',
		isHeadless: ifFeature('encore', false, true),
		isOpen: true,
		primaryActionText: $.__('Reset Password'),
		primaryAction() {
			window.BambooHR.Modal.setState(
				{
					isProcessing: true,
				},
				true
			);

			quickActionResetPassword({
				firstName,
				userId,
			})
				.then(() => {
					window.BambooHR.Modal.setState({
						isOpen: false,
					});
				})
				.catch(() => {
					window.BambooHR.Modal.setState(
						{
							isProcessing: false,
						},
						true
					);
				});
		},
		title: ifFeature('encore', $.__('Just Checking...')),
	});
}

export function quickActionResetPassword({ firstName, userId }) {
	const ERR_MSG = $.__('There was an error resetting the password. Please try again.');

	const preppedData = Ajax.prepareData(
		{
			id: userId,
			action: 'password',
		},
		Ajax.prepareDataTypes.FORM_DATA
	);

	return new Promise((resolve, reject) => {
		Ajax.post('/settings/permissions/edit.php', preppedData)
			.then((response) => {
				const data = response.data;

				if (data.success) {
					setMessage($.__('Password reset email was sent to %1$s', htmlentities(firstName)), 'success');
					resolve(data);
				} else {
					const msg = htmlentities(data.message) || ERR_MSG;

					console.error(data);
					setMessage(msg, 'error');
					reject(data);
				}
			})
			.catch((...args) => {
				console.error(...args);
				setMessage(ERR_MSG, 'error');
				reject(...args);
			});
	});
}
