import { arc, pie } from 'd3-shape';
import { select, selectAll } from 'd3-selection';
import { interpolate, interpolateRgb, interpolateRound } from 'd3-interpolate';
import { scaleLinear, scaleOrdinal } from 'd3-scale';
import { easePoly } from 'd3-ease';
import { mouseClick, mouseOver } from 'Charts.mod/event-functions';
import { isEnabled } from 'FeatureToggle.util';

/**
Ideal Default Settings
{
	chartClass: '',
	showInnerBar: false,
	container: {
		flexHeight: false,
		height: 120,
		width: 900,
		margin: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		}
	},
	backgroundBar: {
		color: '#ccc'
	},
	mainBar: {
		color: '#8DB812',
		radius: 200,
		width: 40
	},
	innerBar: {
		colors: ['#fc722c', '#fcba50', '#a5d225']
	},
	primaryText: {
		show: true,
		text: 'Your eNPS Score'
	},
	secondaryText: {
		show: true,
		text: ''
	},
	ternaryText: {
		show: true,
		text: 'Responses'
	}
}

Back-end Data Contract
{
	type: 'radial',
	mainBar: {
		'value': 35,
	},
	ternaryValue: 12,
	ternaryPercent: '15%', // if you want percent provide it as part of the string
	ternaryIconTheme: 'no-icon' // `no-icon` `warning` a string will be provided and matched to the component to determine which icon to show
}
 */

const isValueInDetractors = (value) => {
	return value <= -40;
};

const isValueInNeutrals = (value) => {
	return value > -40 && value <= 0;
};

const isValueInPromoters = (value) => {
	return value > 0;
};

/**
 * If we are in the detractor or neutrals transition to grey
 * Otherwise, transition to green
 *
 * @param {int} value
 * @param {rgb color} currentColor
 */
const interpolateTextColors = (value, currentColor) => {
	if (isValueInDetractors(value) || isValueInNeutrals(value)) {
		return interpolateRgb(currentColor, GREY_COLOR);
	}

	if (isValueInPromoters(value)) {
		return interpolateRgb(currentColor, PROMOTER_COLOR);
	}
};

/**
 * Gets the primary ring fill color for the radial progress chart on the eNPS report
 * @param {*} value 
 * @returns 
 */
const getPrimaryRingFillColor = (value) => {
	if (isValueInDetractors(value)) {
		return DETRACTOR_COLOR;
	}

	if (isValueInNeutrals(value)) {
		return NEUTRUAL_COLOR;
	}

	if (isValueInPromoters(value)) {
		return PROMOTER_COLOR;
	}
}

/**
 * Transitions the colors based on the range to the red - orange - green
 *
 * @param {int} value
 * @param {rgb color} currentColor
 */
const interpolateColors = (value, currentColor) => {
	return interpolateRgb(currentColor, getPrimaryRingFillColor(value));
};

function arcPieTween(a) {
	const i = interpolate(this._current, a);
	this._current = i(0);

	return function(t) {
		return updateInnerArc(i(t));
	};
}


// Returns a tween for a transition’s 'd' attribute, transitioning any selected
// arcs from their current angle to the specified new angle.
const arcTween = (newAngle) => {

	// The function passed to attrTween is invoked for each selected element when
	// the transition starts, and for each element returns the interpolator to use
	// over the course of transition. This function is thus responsible for
	// determining the starting angle of the transition (which is pulled from the
	// element’s bound datum, d.endAngle), and the ending angle (simply the
	// newAngle argument to the enclosing function).
	return function(d) {

		// To interpolate between the two angles, we use the default d3.interpolate.
		// (Internally, this maps to d3.interpolate, since both of the
		// arguments to d3.interpolate are numbers.) The returned function takes a
		// single argument t and returns a number between the starting angle and the
		// ending angle. When t = 0, it returns d.endAngle; when t = 1, it returns
		// newAngle; and for 0 < t < 1 it returns an angle in-between.
		const interp = interpolate(d.endAngle, newAngle);

		// The return value of the attrTween is also a function: the function that
		// we want to run for each tick of the transition. Because we used
		// attrTween('d'), the return value of this last function will be set to the
		// 'd' attribute at every tick. (It’s also possible to use transition.tween
		// to run arbitrary code for every tick, say if you want to set multiple
		// attributes from a single function.) The argument t ranges from 0, at the
		// start of the transition, to 1, at the end.
		return function(t) {

			// Calculate the current arc angle based on the transition time, t. Since
			// the t for the transition and the t for the interpolate both range from
			// 0 to 1, we can pass t directly to the interpolator.
			//
			// Note that the interpolated angle is written into the element’s bound
			// data object! This is important: it means that if the transition were
			// interrupted, the data bound to the element would still be consistent
			// with its appearance. Whenever we start a new arc transition, the
			// correct starting angle can be inferred from the data.
			d.endAngle = interp(t);

			// Lastly, compute the arc path given the updated data! In effect, this
			// transition uses data-space interpolation: the data is interpolated
			// (that is, the end angle) rather than the path string itself.
			// Interpolating the angles in polar coordinates, rather than the raw path
			// string, produces valid intermediate arcs during the transition.
			return arcTemplate(d);
		};
	};
};

const { PI } = Math;
const START_ANGLE = -0.8 * PI;
const END_ANGLE = 0.8 * PI;

const ICON_WARNING = 'warning';

const PROMOTER_COLOR = '#8db812';
const NEUTRUAL_COLOR = '#f19d11';
const DETRACTOR_COLOR = '#db6021';
const GREY_COLOR = '#555';

let arcTemplate;
let updateInnerArc;

export function setupChart() {
	const settings = this.getSettings();

	// Via settings
	const { radius, width, color } = settings.mainBar;
	const { color: backgroundColor } = settings.backgroundBar;
	const { text: secondaryTextString } = settings.secondaryText;
	const { text: ternaryTextString } = settings.ternaryText;

	arcTemplate = arc()
		.innerRadius(radius)
		.outerRadius(radius - width)
		.startAngle(START_ANGLE);

	this._arcContainer = this._svg.append('g')
		.attr('class', 'arc-info')
		.attr('transform', 'translate(166, 170)');

	this._arcContainer.append('path')
		.datum({ endAngle: END_ANGLE })
		.attr('d', arcTemplate)
		.attr('fill', backgroundColor)
		.attr('class', this._buildClassList('__backgroundRing'))
		.attr('transform', 'translate(20, 20)');

	this._arcContainer.append('path')
		.attr('fill', color)
		.attr('class', this._buildClassList('__primaryRing'))
		.attr('transform', 'translate(20, 20)')
		.on('mouseover', mouseOver);

	// Draw inner ring if enabled
	if (settings.showInnerBar) {
		const innerRadiusValue = radius - 30;
		const outterRadiusValue = radius - 40;

		const innerArc = arc()
			.innerRadius(innerRadiusValue)
			.outerRadius(outterRadiusValue)
			.startAngle(START_ANGLE);

		// Draw the gray background
		this._arcContainer.append('path')
			.datum({ endAngle: END_ANGLE })
			.attr('d', innerArc)
			.attr('fill', backgroundColor)
			.attr('class', this._buildClassList('__backgroundRing'))
			.attr('transform', 'translate(20, 20)');
	}

	this._innerInfoContainer = this._svg.append('g')
		.attr('class', 'inner-info')
		.attr('transform', 'translate(166, 170)');

	this._innerInfoContainer.append('text')
		.attr('class', `${ this._buildClassList('__secondaryText') } baseFillColor`)
		.attr('text-anchor', 'middle')
		.attr('x', 20)
		.attr('y', 30)
		.text(secondaryTextString);

	this._innerInfoContainer.append('line')
		.attr('class', this._buildClassList('__zeroMarkLine'))
		.attr('x1', 20)
		.attr('y1', -160)
		.attr('x2', 20)
		.attr('y2', -117)
		.attr('stroke-width', 1)
		.attr('stroke', 'white');

	this._innerInfoContainer.append('text')
		.attr('class', this._buildClassList('__zeroMarkNumber'))
		.attr('text-anchor', 'middle')
		.attr('x', 20)
		.attr('y', -108)
		.attr('dy', '.5rem')
		.text('0');

	this._innerInfoContainer.append('text')
		.attr('class', this._buildClassList('__rangeNumber'))
		.attr('text-anchor', 'middle')
		.attr('x', -50)
		.attr('y', 150)
		.attr('dy', '.5rem')
		.text('-100');

	this._innerInfoContainer.append('text')
		.attr('class', this._buildClassList('__rangeNumber'))
		.attr('text-anchor', 'middle')
		.attr('x', 90)
		.attr('y', 150)
		.attr('dy', '.5rem')
		.text('+100');
}

export function drawChart(data) {
	const settings = this.getSettings();
	const svg = this._svg;

	// Via settings
	const { radius } = settings.mainBar;
	const { colors: innerBarColors } = settings.innerBar;
	const { text: ternaryTextString } = settings.ternaryText;

	// BE Data
	const { value: barValue } = data.mainBar;
	const { innerBar: innerBarData } = data;

	// These could probably become dynamically passed via the settings at some point
	const minValue = -100;
	const maxValue = 100;

	const arcScale = scaleLinear()
		.range([START_ANGLE, END_ANGLE])
		.domain([minValue, maxValue]) // these might end up being dynamic
		.clamp(true); // prevents any values that fall outside of the domain to be within

	const scaledValue = arcScale(barValue);

	// inner-info section
	const primarySelection = this._innerInfoContainer.selectAll(`.${ this._CLASS_NAME }__primaryText`).data([data]);
	const ternarySelection = this._innerInfoContainer.selectAll(`.${ this._CLASS_NAME }__ternaryText`).data([data]);
	const iconSelection = this._innerInfoContainer.selectAll(`.${ this._CLASS_NAME }__icon`).data([data]);

	// EXIT
	primarySelection.exit().remove();
	ternarySelection.exit().remove();
	iconSelection.exit().remove();

	// UPDATE
	this._animateElements(primarySelection, elements => elements
		.transition()
		.duration(1500)
		.styleTween('fill', function(d) {
			return interpolateTextColors(barValue, this.style.fill);
		})
		.tween('text', function(d) {
			const node = this;

			const currentValue = +node.textContent;
			const newValue = +d.mainBar.value;

			const i = interpolateRound(currentValue, newValue);

			return function(t) {
				node.textContent = i(t);
			};
		})
	);

	this._animateElements(ternarySelection, elements => elements
		.transition()
		.duration(1000)
	)
		.attr('x', (d) => {
			if (d.ternaryIconTheme === ICON_WARNING) {
				return 34;
			}

			return 20;
		})
		.text((d) => {
			return `${ d.ternaryValue } ${ ternaryTextString } (${ d.ternaryPercent })`;
		});
	
	this._animateElements(iconSelection, elements => elements
		.transition()
		.duration(1000)
	)
		.style('opacity', (d) => {
			if (d.ternaryIconTheme === ICON_WARNING) {
				return 1;
			}

			return 0;
		});
		
	// ENTER
	primarySelection
		.enter()
		.append('text')
		.attr('class', this._buildClassList('__primaryText'))
		.attr('text-anchor', 'middle')
		.attr('x', 20)
		.attr('y', -10)
		.style('fill', (d) => {
			if (isValueInPromoters(d.mainBar.value)) {
				return PROMOTER_COLOR;
			}

			return GREY_COLOR;
		})
		.text((d) => {
			return d.mainBar.value;
		});

	ternarySelection
		.enter()
		.append('text')
		.attr('class', () => `${ this._buildClassList('__ternaryText') } ${ this._buildClassList('__ternaryText--link') }`)
		.attr('text-anchor', 'middle')
		.attr('x', (d) => {
			if (d.ternaryIconTheme == 'warning') {
				return 34;
			}

			return 20;
		})
		.attr('y', 57)
		.text((d) => {
			return `${ d.ternaryValue } ${ ternaryTextString } (${ d.ternaryPercent })`;
		})
		.on('click', mouseClick)
		.on('mouseover', mouseOver);

	iconSelection
		.enter()
		.append('svg:image')
		.attr('class', this._buildClassList('__icon'))
		.attr('x', isEnabled('enpsSentimentScoreTrend') ? -75 : -50)
		.attr('y', 40)
		.attr('width', 20)
		.attr('height', 24)
		.style('opacity', (d) => {
			if (d.ternaryIconTheme == 'warning') {
				return 1;
			}

			return 0;
		})
		.attr('xlink:href', `/images/bicons/warning-icon-lrg.png`);

	const primaryRingUpdate = svg.select(this._getClassList('__primaryRing'))
		.each((d) => { this._currentEndAngle = d ? d.endAngle : START_ANGLE; })
		.datum({ endAngle: settings.animate ? this._currentEndAngle : scaledValue, data })
		.attr('d', arcTemplate);

	const fillPrimaryRing = (d) => {

		// Handle inner bar if enabled
		if (settings.showInnerBar) {
			const innerRadiusValue = radius - 30;
			const outterRadiusValue = radius - 40;

			const innerColors = scaleOrdinal()
				.domain([1, 2, 3])
				.range(innerBarColors);

			updateInnerArc = arc()
				.outerRadius(innerRadiusValue)
				.innerRadius(outterRadiusValue);

			const innerPie = pie()
				.startAngle(-0.8 * PI)
				.endAngle(d.endAngle)
				.sort(null)
				.value(d => d.value);

			// JOIN
			const slices = this._arcContainer.selectAll(`.${ this._CLASS_NAME }__innerSlice`)
				.data(innerPie(innerBarData), function(d) { return d.data.label; });

			// ENTER
			const slicesEnter = slices.enter().append('path')
				.attr('class', this._buildClassList('__innerSlice'))
				.attr('transform', 'translate(20,20)')
				.on('mouseover', mouseOver)
				.each(function(d) { this._current = d; });

			// UPDATE
			this._animateElements(slices, (elements) => elements
				.transition()
				.ease(easePoly)
				.duration(500)
				.attrTween('d', arcPieTween)
			);

			// EXIT
			slices
				.exit()
				.remove();

			// MERGE
			slices
				.merge(slicesEnter)
				.attr('fill', function(d) { return innerColors(d.data.order); })
				.attr('d', updateInnerArc);
		}
	};
	
	const primaryRing = this._animateElements(primaryRingUpdate, (elements) => elements
				.transition()
				.ease(easePoly)
				.duration(500)
				.attrTween('fill', function(d) {
					const $that = select(this);
					const currentColor = $that.style('fill');

					return interpolateColors(barValue, currentColor);
				})
				.attrTween('d', arcTween(scaledValue))
				.on('end', fillPrimaryRing)
		);
	
	if (!settings.animate) {
		fillPrimaryRing(primaryRing
			.attr('fill', getPrimaryRingFillColor(barValue))
			.datum()
		);
	}
		
}
