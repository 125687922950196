import * as type from './constants';
import { immutifyFeed, sortEvents } from '../store/index';
import { getCommentById } from '../store/selectors';
import { getConfig } from '../constants';

export const editComment = (id, text, attachIds, deleteIds) => (dispatch, getState) => {
	dispatch({
		type: type.EDIT_COMMENT_START,
		payload: id,
	});

	const FEED_CONFIG = getConfig(getState().id);
	$.post(`${ FEED_CONFIG.rootCommentURL }/${ FEED_CONFIG.parentType }/${ FEED_CONFIG.parentId }/${ id }`, {text, attachIds, deleteIds}).then(
		(response) => {
			const feed = immutifyFeed(response);
			dispatch({
				type: type.EDIT_COMMENT_SUCCESS,
				payload: {
					feed,
					id
				},
			});
			FEED_CONFIG.externalCallback({type: 'edit', data: getCommentById(feed,id)});
		},
		(err) => {
			if (err) {
				window.Rollbar.error('FEEDS: Error when editing comment', err);
			}
			dispatch({
				type: type.EDIT_COMMENT_ERROR,
				payload: id,
				error: true,
			});
		}
	);
};

export const deleteComment = id => (dispatch, getState) => {
	dispatch({
		type: type.DELETE_COMMENT_START,
		payload: id,
	});
	const FEED_CONFIG = getConfig(getState().id);
	$.ajax({
		url: `${ FEED_CONFIG.rootCommentURL }/${ FEED_CONFIG.parentType }/${ FEED_CONFIG.parentId }/${ id }`,
		type: 'DELETE'
	}).then(
		(response) => {
			dispatch({
				type: type.DELETE_COMMENT_SUCCESS,
				payload: id,
			});
			if (Array.isArray(response) && !response.length) {
				response = {
					type: 'delete',
					data: {id},
					state: getState(),
				};
			}
			FEED_CONFIG.externalCallback(response);
		},
		(err) => {
			if (err?.status == 404) {
				// apparently it was already removed, so let's call that "success" here
				dispatch({
					type: type.DELETE_COMMENT_SUCCESS,
					payload: id,
				});
				FEED_CONFIG.externalCallback({
					type: 'delete',
					data: { id },
					state: getState(),
				});
				return;
			}

			if (err) {
				window.Rollbar.error('FEEDS: Error when deleting comment', err);
			}
			dispatch({
				type: type.DELETE_COMMENT_ERROR,
				payload: id,
				error: true,
			});
		}
	);
};

export const deleteEvent = id => (dispatch, getState) => {
	dispatch({
		type: type.DELETE_EVENT_START,
		payload: id,
	});
	const FEED_CONFIG = getConfig(getState().id);
	const event = getState().getIn(['entities', 'events', 'byId', id]);
	$.ajax({
		data: {
			id: event.getRemoteId(),
			type: event.type
		},
		type: 'DELETE',
		url: `/events/${ FEED_CONFIG.parentType }/${ FEED_CONFIG.parentId }`
	}).then(
		(response) => {
			dispatch({
				type: type.DELETE_EVENT_SUCCESS,
				payload: id,
			});
			const data = {
				...response,
				state: getState(),
			}
			FEED_CONFIG.externalCallback(data);
		},
		(err) => {
			if (err) {
				window.Rollbar.error('FEEDS: Error when deleting event', err);
			}
			dispatch({
				type: type.DELETE_EVENT_ERROR,
				payload: id,
				error: true,
			});
		}
	);
};

export const addComment = feed => (dispatch, getState) => {
	const FEED_CONFIG = getConfig(getState().id);
	dispatch({
		type: type.ADD_COMMENT,
		payload: immutifyFeed(feed),
	});
	FEED_CONFIG.externalCallback({type: 'addComment', feed: immutifyFeed(feed), state: getState()});
};

export const addEvent = feed => (dispatch, getState) => {
	const FEED_CONFIG = getConfig(getState().id);

	dispatch({
		type: type.ADD_EVENT,
		payload: immutifyFeed(feed),
	});
	FEED_CONFIG.externalCallback({type: 'addEvent', feed: immutifyFeed(feed), state: getState()});
};

export const beginEditing = id => (dispatch) => {
	dispatch({
		type: type.EDITING_BEGIN,
		payload: id,
	});
};

export const cancelEditing = id => (dispatch) => {
	dispatch({
		type: type.EDITING_CANCEL,
		payload: id,
	});
};
