import {
	ReactElement,
	useEffect,
} from 'react';
import { FirstDayInformation, Props } from 'NHPTemplates.mod/Form/components/first-day-information';
import { updateFormState } from 'NHPTemplates.mod/Form/utils';
import { useNewHirePacketForm, ACTION_TYPES } from 'NHPTemplates.mod/Form/providers/new-hire-packet-form/new-hire-packet-form-provider';

export function FirstDayInformationContainer(props: Props): ReactElement {
	const {
		hireDate,
		selectedManagerOptions,
	} = props;

	const {
		state,
		dispatch = (payload: any) => { return payload; },
	} = useNewHirePacketForm() || {};

	const {
		errorFields,
		infoHighlightFields,
	} = state || {};

	useEffect(() => {
		if (Array.isArray(selectedManagerOptions) && selectedManagerOptions.length > 0) {
			dispatch({
				payload: {
					hireDate,
					selectedManagerId: selectedManagerOptions[0],
				},
				type: ACTION_TYPES.SET_INITIAL
			});
		}

	}, [hireDate, selectedManagerOptions]);

	return <FirstDayInformation
		{ ...props }
		errorField={ errorFields }
		infoHighlightFields={ infoHighlightFields }
		storeUpdater={ updateFormState }
		updateHireDate={ (hireDate: string) => {
			dispatch({
				payload: hireDate,
				type: ACTION_TYPES.UPDATE_HIRE_DATE,
			});
		} }
		updateSelectedManager={ (managerId: string) => {
			dispatch({
				payload: managerId,
				type: ACTION_TYPES.UPDATE_MANAGER_ID,
			});
		} }
	/>;
}
