import moment from 'moment.lib';
import warningTemplate from './shared/templates/warning';
import {CONTACT_HR_ADMIN, CLOCK_DISABLED_FUTURE_ENTRY} from '../constants';

export default (attemptedAction, dateInfo) => {
	let timeString = '';
	let modalTitle = '';
	let paragraphs = [];

	if (dateInfo?.today && dateInfo?.datetime) {
		const todayMoment = moment.tz(dateInfo.today.date, dateInfo.today.timezone);
		timeString = moment.tz(dateInfo.datetime.date, dateInfo.datetime.timezone).calendar(todayMoment);
	}

	if (attemptedAction === 'clockOut') {
		modalTitle = $.__('Clocked Out');
		paragraphs = [
			$.__('You were clocked out elsewhere%1$s.', timeString ? ` <b class="fontSemibold">${ timeString }</b>` : ''),
			CONTACT_HR_ADMIN,
		];
	} else if (attemptedAction === 'clockIn') {
		modalTitle = $.__('Clocked In');
		paragraphs = [
			$.__('You were clocked in elsewhere%1$s.', timeString ? ` <b class="fontSemibold">${ timeString }</b>` : ''),
		];
	} else if (attemptedAction === 'clockInFutureEntry') {
		modalTitle = $.__(`You can't be clocked in right now`);
		paragraphs = [
			CLOCK_DISABLED_FUTURE_ENTRY,
		];
	} else if (attemptedAction === 'alreadyApproved') {
		modalTitle = $.__('Timesheet already approved');
		paragraphs = [
			$.__(`You can't be clocked in because your timesheet has already been approved.`),
			CONTACT_HR_ADMIN,
		];
	}

	const content = microTemplate(warningTemplate, {
		jadeIconName: 'fab-timesheet-exclamation-84x57',
		iconName: 'triangle-exclamation-solid',
		paragraphs,
	});
	const primaryActionText = $.__('Close');

	window.BambooHR.Modal.setState({
		isOpen: true,
		content: (
			<div dangerouslySetInnerHTML={ { __html: content } } />
		),
		alternativeAction: null,
		primaryActionText,
		primaryAction() {
			if (attemptedAction === 'alreadyApproved') {
				window.location.reload(true);
			} else {
				window.BambooHR.Modal.setState({isOpen: false});
			}
		},
		title: modalTitle
	});
};
