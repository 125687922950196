import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from 'recoil';

import { getThemeName } from 'get-theme-name.util';
import { PoIcon } from './icons';
import { MicroFrontend } from './micro-frontend';
import { useResource } from './resources';
import { VersionManager } from './version-manager';
import { isEnabled } from 'FeatureToggle.util';
import { getSnapEngageClient } from '../../global/chat';
// eslint-disable-next-line no-restricted-imports
import { getLocale, getFormatTypeFromGlobalDateMask } from '../../_utils/date-picker.util';
import { MainTechnicalDebt } from './main-technical-debt';
import { uniqueId } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { clearDemoData } from '../../global/base/company-data';

// These will be lazy-loaded later
const noLoadJSFromManifest = ['pdf.worker.js', 'salesforce-chat.js', 'e-signature.mobile.js', 'enrollment.mobile.js'];

export const PoMicroFrontend = ({ customClassNamePrefix, route, ...rest }) => {
	const name = 'po';

	const [resource, setResource] = useResource(name);
	const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
	const { mode: fabricMode, themeName: fabricThemeName } = getThemeName();

	const payload = {
		customClassNamePrefix: useRef(uniqueId(customClassNamePrefix && `${customClassNamePrefix}-`)).current,
		fabricMode,
		fabricThemeName,
		globals: {
			assumedUser: window.ASSUMED_USER,
			company: {
				...window.SESSION_COMPANY,
				globalCompanyCountryId: window.GLOBAL_COMPANY_COUNTRY_ID,
				isTrial: global.GLOBAL_IS_TRIAL === 'true'
			},
			user: window.SESSION_USER,
			defaultCurrencyCode: window.GLOBAL_CURRENCY_CODE,
			defaultCurrencySymbol: window.GLOBAL_CURRENCY_SYMBOL,
			csrfToken: window.CSRF_TOKEN,
			Files: {
				deleteEmployeeFile: window.deleteEmployeeFile,
				shareEmployeeFile: window.shareEmployeeFile,
				emailFile: window.emailFile,
			},
			getCsrfToken: () => window.CSRF_TOKEN,
			showHelpWidget: window.showHelpWidget,
			isFeatureEnabled: isEnabled,
			getSnapEngageClient,
			dateFnsDefaultFormat: window.dateFnsDefaultFormat,
			datePickerProviderFormatType: getFormatTypeFromGlobalDateMask(),
			datePickerProviderLocale: getLocale(),
			MainTechnicalDebt,
			decimalChar: window.GLOBAL_DECIMAL_CHAR,
			thousandsSeparator: window.GLOBAL_THOUSANDS_SEPARATOR,
			isSupportAdmin: window.IS_SUPPORT_ADMIN,
			hasTrax: !!window.companyHasTrax || !!window.hasTrax || false,
			isPayrollSynced: window.Employee && window.Employee.payrollSynced,
			isAssumedUser: window.GLOBAL_IS_ASSUMED_USER,
			numberRegex: window.GLOBAL_NUMBER_REGEX,
			host: resource?.url,
			clearDemoData: clearDemoData,
		},
		route,
		RecoilBridge,
		updateEmployeeInformation: window.BambooHR.HistoryTable?.updateEmployeeInformation,
		updateEmployeeGearMenu: window.BambooHR.HistoryTable?.updateEmployeeGearMenu,
		openMissingLocationInformation: window.BambooHR.HistoryTable?.openMissingLocationInformation,
		updateHireDates: window.updateHireDates,
		registerRateChange: window.registerRateChange,
		...rest,
	};

	return (
		<VersionManager icon={<PoIcon />} onChange={setResource} resource={resource}>
			<MicroFrontend
				host={resource?.url}
				manifest='manifest.json'
				name={name}
				noLoadJSFromManifest={noLoadJSFromManifest}
				payload={payload}
			/>
		</VersionManager>
	);
};

PoMicroFrontend.propTypes = {
	route: PropTypes.string,
};

PoMicroFrontend.defaultProps = {
	route: undefined,
};
