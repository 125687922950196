var Job = {}

import RehireModal from 'Employees.mod/Job/RehireModal';
Job.RehireModal = RehireModal;
export {RehireModal};

import EmploymentStatusModal from 'Employees.mod/Job/EmploymentStatusModal';
Job.EmploymentStatusModal = EmploymentStatusModal;
export {EmploymentStatusModal};

import CompensationForm from 'Employees.mod/Job/CompensationForm';
Job.CompensationForm = CompensationForm;
export {CompensationForm};

export default Job;
