import { sortBy } from 'lodash';
import moment from 'moment.lib';
import { isEnabled } from 'FeatureToggle.util';
import Ajax from '@utils/ajax';
import { applyCompanyDecimal, trimTrailingZeroes } from 'Number.util';
import Tooltip from 'Tooltip.mod';
import { fragFromHtml } from '@utils/dom';
import {
	formatTime,
	sumOvertimeHours,
	getPaidHolidayText,
	getWorkWeekStartsOnObject
} from 'time-tracking/utils';
import EmployeeTimesheet from 'time-tracking/components/timesheet.react';
import normalizeData from 'time-tracking/components/timesheet.react/store/store-bootstrap';
import { JADE_TIME_OFF_ICONS } from 'time-tracking/constants';
import html from './details.micro.html';
import { TimesheetDetailsModalContent } from './timesheet-details-modal-content';
import './styles.styl';
/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */

const newTimesheetDetailsModalEnabled = isEnabled('newTimesheetDetailsModal');

function prepareFragment(timesheetId, json) {
	const {canViewEmployeeTimesheet, employeePhotoUrl, employeeId, employeeName, timesheet} = json;
	let totalTime = 0;
	let totalOvertime = 0;
	let totalTimeOff = 0;
	let totalHoliday = 0;

	const entries = sortBy(timesheet.dailyDetails, 'date')
		.map((entry) => {
			const when = moment(entry.date);
			entry.dayOfWeek = when.format('ddd');
			entry.dayDate = when.format('MMM D');

			entry.extras = [
				...prepareTimeOffEntries(entry.timeOff),
				...prepareHolidayEntries(entry.holidays),
			];

			totalTime += entry.hours + entry.timeOffHours + entry.paidHolidayHours;
			totalOvertime += entry.overtimeHours;
			totalTimeOff += entry.timeOffHours;
			totalHoliday += entry.holidayHours + entry.paidHolidayHours;
			return entry;
		});

	const overtimeSummary = sumOvertimeHours(entries);

	const dateSpan = `${ entries[0].dayDate }–${ entries[entries.length - 1].dayDate }`;

	const workWeekStartsOn = getWorkWeekStartsOnObject(entries);
	const workWeekWarningMessage = $.__n('The work week start day has changed. There is %1$s day where overtime cannot be calculated.', 'The work week start day has changed. There are %1$s days where overtime cannot be calculated.', workWeekStartsOn.count);

	const resultHtml = window.microTemplate(html, {
		entries,
		employeeName,
		employeePhotoUrl,
		dateSpan,
		totalTime,
		totalOvertime,
		totalTimeOff,
		totalHoliday,
		overtimeSummary,
		formatTime,
		timeSheetLink: (canViewEmployeeTimesheet) ? `/employees/timesheet/?id=${ employeeId }&et_id=${ timesheetId }` : '',
		workWeekStartsOn,
		workWeekWarningMessage
	});

	const fragment = fragFromHtml(resultHtml);

	const notes = Array.from(fragment.querySelectorAll('.js-time-details-modal-note'));
	notes.forEach((note) => {
		const index = note.getAttribute('data-tooltip-index');
		const content = entries[index].note;
		const title = formatTime(entries[index].hours || 0);
		Tooltip.createStandard(note, {
			title,
			content,
			position: 'right',
			tailOnBalloon: '12px'
		});
	});

	return fragment;
}

function prepareTimeOffEntries(timeOff) {
	return timeOff.map((request) => {
		const unit = request.unit === 'hours' ? $.__('hours') : $.__('days');
		request.info = `${ request.amount } ${ unit } ${ request.type }`;
		request.iconName = (request.icon ? JADE_TIME_OFF_ICONS[request.icon] : 'fab-briefcase-clock-14x12');
		return request;
	});
}

function prepareHolidayEntries(holidays) {
	return holidays.map((holiday) => {
		const multiplier = applyCompanyDecimal(trimTrailingZeroes(holiday.multiplier));
		holiday.info = `${ holiday.name } ${ holiday.multiplier ? `(${ $.__('pays') } ${ multiplier }x)` : '' }`;
		if (isNaN(multiplier)) {
			holiday.info = getPaidHolidayText(holiday);
		}
		holiday.iconName = 'fab-fireworks-16x16';
		return holiday;
	});
}

export default function (timesheetId) {
	const title = $.__('Timesheet Detail');
	const url = `/timesheet/${ timesheetId }`;

	Ajax.get(url)
		.then((response) => {
			const initialState = normalizeData(response.data);
			window.BambooHR.Modal.setState({
				isOpen: true,
				dangerousContent: newTimesheetDetailsModalEnabled === false ? prepareFragment(timesheetId, response.data) : null,
				content: newTimesheetDetailsModalEnabled
					? ifFeature('encore', <TimesheetDetailsModalContent initialState={initialState} />, <EmployeeTimesheet initialState={ initialState } isViewOnly={ true } showHeader={ false } showSheetPadDays={ false } />)
					: null,
				headline: title,
				icon: ifFeature('encore', undefined, 'fab-timesheet-29x22'),
				primaryActionText: $.__('Close'),
				primaryAction() {
					window.BambooHR.Modal.setState({isOpen: false});
				},
				alternativeAction: null,
				contentHasPadding: false,
				type: 'fullScreen',
				onOpen() {
					// Doing this in prepareFragment() didn't work for Jade, I think because of the way Modal strips out stuff from the content, so we are doing tooltips again here.
					const workWeekWarningIcons = Array.from(document.querySelectorAll('.js-time-details-work-week-warning-icon'));
					workWeekWarningIcons.forEach((icon) => {
						const content = $.__('Overtime cannot be calculated for this day.');
						Tooltip.createStandard(icon, {
							content,
							position: 'right'
						});
					});
				}
			});
		});
}
