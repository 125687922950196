import Ajax from '@utils/ajax';

const MODAL = window.BambooHR.Modal;

/**
 *   Opens up a modal with fields to gather information for location lists
 *
 *
 * @param {int} locationId		- required for edit, if null we assume we are adding
 * @param {type} editData		- an object with values to be passed in to the modal to populate the fields. Not needed if locationId exists.
 * @param {type} callback		- the function to call once this succeeds.
 * @returns void
 */
function showModal(locationId, editData, showEditRadioButtons, saveCallback, cancelCallback, options = {}) {
	// set vars
	const action = $.isNumeric(locationId) || $.isNumeric(editData['location[listValueId]']) ? 'Update' : 'Add';
	showEditRadioButtons = (typeof showEditRadioButtons === 'undefined' || showEditRadioButtons === 'undefined') ? true : showEditRadioButtons;
	const data = { locationId, action, listValueId: editData['location[listValueId]'], showEditRadioButtons };
	if (options && options.zenpayroll) {
		data.zenpayroll = options.zenpayroll;
	}
	let method = 'post';
	let url = '/location';
	if (action === 'Update') {
		method = 'put';
		url += `/${ locationId || `lv/${ editData['location[listValueId]'] }` }`;
	}

	const isSheet = options.hasOwnProperty('isSheet') && options.isSheet;

	const updateModalState = ((isSheet, state, persist = true) => {
		if (isSheet) {
			MODAL.setState({
				sheetProps: state,
			}, persist);
		} else {
			MODAL.setState(state, persist);
		}
	});

	const onClose = function() {
		if (typeof cancelCallback === 'function') {
			cancelCallback(data);
		}
	};

	const onOpen = function() {
		const $form = $('#location_form');
		const $focusField = $form.find('input[type=text]:eq(0)');

		// Focus on first input field upon opening modal
		$focusField.attr('onfocus', 'this.value = this.value').focus();

		// Update each field with the zen payroll data or current location data (looks for location in the name attribute)
		// TODO: Update for Jade
		if (editData && typeof editData !== 'undefined') {
			$.each(editData, function(key, value) {
				if (key.indexOf('location') > -1 && value && value !== '') {
					const jObject = $form.find(`:input[name="${ key }"], ba-select[name="${ key }"]`);
					jObject.val(value);

					// Update the chosen list
					if (jObject.prop('tagName') === 'SELECT') {
						jObject.trigger('liszt:updated');
					// Or hide the label
					} else {
						jObject.siblings('label').hide();
					}
				}
			});
		}

		// Mask the phone number for zen payroll
		$('.zen_phone').mask('(999)999-9999');

		$form.initializeElements();
		$form.bhrValidate();

		function showHideAddressInfo() {
			if ($('#remoteLocation').is(':checked')) {
				$('.js-remote-toggle').hide();
			} else {
				$('.js-remote-toggle').show();
				$form.initializeElements();
			}
		}

		showHideAddressInfo();
		$('#remoteLocation').change(function() {
			showHideAddressInfo();
		});

		createCheckboxTooltip();
	};

	const primaryAction = function() {
		updateModalState(isSheet, { isProcessing: true });

		const $form = $('#location_form');
		if (!$form.valid()) {
			updateModalState(isSheet, { isProcessing: false });
			return;
		}

		const paramObj = {};
		$.each($form.serializeArray(), function(_, keyVal) {
			if (paramObj.hasOwnProperty(keyVal.name)) {
				paramObj[keyVal.name] = $.makeArray(paramObj[keyVal.name]);
				paramObj[keyVal.name].push(keyVal.value);
			} else {
				paramObj[keyVal.name] = keyVal.value;
			}
		});

		$.ajax({
			url,
			type: method,
			method,
			dataType: 'json',
			data: paramObj,
			success(data) {
				if (typeof saveCallback === 'function') {
					updateModalState(isSheet, { isOpen: false });
					saveCallback(data);
				}
				window.closeMessage();
			},
			error(response) {
				if (response.responseText) {
					window.setMessage(response.responseText, 'error');
					updateModalState(isSheet, { isProcessing: false });
				} else {
					window.errorFallBack();
				}
			},
		});

	};

	Ajax.get('/ajax/dialog/location.php', data)
		.then((res) => {
			if (typeof res.data === 'object') {
				MODAL.setState({
					...(isSheet ? {
						sheetProps: {
							isOpen: false,
						},
					} : {
						isOpen: false,
					}),
				}, isSheet);
				window.setMessage(res.data.error || window.DEFAULT_ERROR_MESSAGE, 'error');
				return;
			}

			const modalProps = {
				biId: `employee-field-${ action === 'Update' ? $.__('save') : $.__('add') }-location`,
				dangerousContent: res.data,
				isOpen: true,
				isProcessing: false,
				onClose,
				onOpen,
				primaryAction,
				primaryActionText: action === 'Update' ? $.__('Save') : $.__('Add'),
				title: action === 'Update' ? $.__('Edit Location') : $.__('Add a New Location'),
			};

			if (isSheet) {
				MODAL.setState({
					sheetProps: {
						...modalProps,
						type: 'mediumSheet',
					},
				}, true);
			} else {
				MODAL.setState({
					...modalProps,
				});
			}
		});
}

function createCheckboxTooltip() {
	const $fieldRow = $('.js-remote-checkbox');
	const $checkbox = $fieldRow.find('input#remoteLocation');
	if ($checkbox.is(':disabled')) {
		window.BambooHR.Popover.create($fieldRow[0], {
			closeX: false,
			html: `<div style="max-width: 320px; padding: 8px 12px; background-color: #646464; font-size: 12px; color: #ffffff; text-align: left; line-height: 1.2">${
				$.__('This location is used by at least one job opening, and job openings require an address.') } ${
				$.__('You cannot change this location to remote until you adjust the location on the job opening(s).')
			}</div>`,
			push: 12,
			tailBackground: '#646464',
			tailOnAnchor: 8,
			tailOnBalloon: 20,
			triggerEvent: 'hover',
		});
	}
}

export default {
	showModal,
};
