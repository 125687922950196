import {
	addClass,
	removeClass,
} from 'BambooHR.util';

const OK_CLASSES = 'ok';
export const PASSWORD_MAX_LENGTH = 128;
export const PASSWORD_MAX_LENGTH_MSG = $.__('Whoops, try to keep it under 128 characters.');
export const PASSWORD_MAX_LENGTH_MSG_CLASS_NAME = 'max-length-error-msg';
export const PASSWORD_MAX_LENGTH_ERROR_CLASS_NAME = 'error--maxLength';

export function checkRequirements(namesOfValidInputs: Array<string>): boolean {
	const numberOfRequirements = 5;
	let numberOfPassedRequirements = 0;

	const selectors = {
		length: '#pass-length',
		number: '#pass-number',
		upper: '#pass-upper',
		lower: '#pass-lower',
		match: '.validCheck',
	};

	for (let i = 0; i < namesOfValidInputs.length; i++) {
		switch (namesOfValidInputs[i]) {
			case 'length':
			case 'number':
			case 'upper':
			case 'lower':
				$(selectors[namesOfValidInputs[i]]).addClass(OK_CLASSES);
				numberOfPassedRequirements++;
				break;
			case 'match':
				$('.validCheck').each(function() {
					addClass(this, 'valid');
				});
				numberOfPassedRequirements++;
				break;
		}
	}

	return numberOfPassedRequirements === numberOfRequirements;
}

export function updateRequirements(ok: Array<string>): void {
	$('.validCheck').each(function() {
		removeClass(this, 'valid');
	});

	$('.js-passwordRequirements li').removeClass(OK_CLASSES);
	setResetPasswordButtonStatus(checkRequirements(ok));
}

function setResetPasswordButtonStatus(enabled: boolean): void {
	const submitButton = document.querySelector('.js-ChangePasswordForm__submitButton, .js-createAccountBtn');
	(submitButton as HTMLInputElement).disabled = !enabled;
}

function validatePasswordMaxLength(e, noMsg) {
	const $input = $(e.target);
	const $row = $input.closest('.fab-FormRow');
	let $errorEl = $row.find(`.${ PASSWORD_MAX_LENGTH_MSG_CLASS_NAME }`);

	if ($input.val().length === PASSWORD_MAX_LENGTH) {
		if (!noMsg && !$errorEl.length) {
			$errorEl = $('<span />', {
				class: `fab-Label fab-Label--error ${ PASSWORD_MAX_LENGTH_MSG_CLASS_NAME }`,
				text: PASSWORD_MAX_LENGTH_MSG,
			});
			$row.prepend($errorEl);
		}
		$row.addClass(PASSWORD_MAX_LENGTH_ERROR_CLASS_NAME);
	} else {
		$errorEl.remove();
		$row.removeClass(PASSWORD_MAX_LENGTH_ERROR_CLASS_NAME);
	}
}

export function setupPasswordMaxLenghtValidation($p1, $p2) {
	$p1.on('keyup', validatePasswordMaxLength);
	$p2.on('keyup', e => validatePasswordMaxLength(e, true));
}
