import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classnames from 'classnames';
import { FunctionComponent } from 'react';
import Tooltip from 'tooltip.react';
import './pending.styl';

const PendingRequest: FunctionComponent<
	| DynamicForm.TextElementProps
	| DynamicForm.CheckboxElementProps
	| DynamicForm.SelectElementProps
	| DynamicForm.TextareaProps
> = ({ props, settings }) => {
	const { pending } = settings;
	const { className = '', type } = props;

	if (!pending) {
		return null;
	}

	const classes = classnames({
		DynamicForm__pending: true,
		'DynamicForm__pending--container': type === 'checkbox',
	});

	// TODO: This url doesn't work... It appears the url has changed. Fix this later -> not important for now. Just link to Inbox for now.
	// URL creation stolen from `addTooltipsToDisabledRequestFields()`
	// const url = `/inbox/details.php?id=${ pending }&name=information_update&type=${ className.includes('notice') ? 'inbox' : 'sent' }`;
	const url = `/inbox/details.php?id=${ pending }`;

	return (
		<a className={ classes } href={ url }>
			<div className="DynamicForm__pending--icon">
				<Tooltip
					settings={ {
						template: {
							name: 'tooltip-standard',
							data: {
								title: $.__('Pending Approval'),
								content: $.__('The latest change to this field is waiting for approval. Click to go to the original request.'),
								textAlign: 'center',
							},
						},
					} }
				>
					{ifFeature(
						'encore',
						<IconV2 color='neutral-weak' name="clock-solid" size={ 16 } />,
						<Icon name="fab-pencil-check-16x16" />
					)}
				</Tooltip>
			</div>
		</a>
	);
};

export default PendingRequest;
