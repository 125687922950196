import { Icon, IconV2, FileIcon } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getIconNameFromFileName, getFileTypeFromExt } from 'BambooHR.util';
import { Map, Record } from 'immutable';
import { noop } from 'lodash';
import { Component } from 'react';
import { ProgressBar } from './progress-bar';

import './style.styl';

const PREVIEW_ERROR = $.__('Unable to preview the requested file. If the issue persists, please contact support.');

export const TemporaryFile = Record(
	{
		id: null,
		name: null,
		progress: 0,
		remoteId: null,
		xhr: null,
	},
	'TemporaryFile'
);

/**
 * FileList Component
 * @param       {array}    files   Collection of files
 * @param       {function} onClick Function for click on remove
 * @constructor
 */
class FileList extends Component {
	static defaultProps = {
		files: Map(),
		removeOnClick: noop,
		showLoader: false,
		showRemoveIcon: true,
	};

	render() {
		const listItems = this.props.files.valueSeq().map((file) => {
			let itemClasses = 'FileListItem js-fileListItem';

			if (this.props.showLoader && this.isTemporary(file) && file.progress < 100) {
				itemClasses += ' FileListItem--loading';
			}

			return (
				<li className={itemClasses} id={file.id} key={file.id}>
					<div className='FileListItem__info FileListItem__info--widthAuto'>
						<div className='FileListItem__icon'>
							{ifFeature(
								'encore',
								<FileIcon name={file.name} size={'teenie'} type={getFileTypeFromExt(file.name)} />,
								<Icon name={getIconNameFromFileName(file.name)} />
							)}
						</div>
						<span className='FileListItem__name truncate'>{this.getFileNameFromStatus(file)}</span>
						<div
							className={this.props.showRemoveIcon ? 'FileListItem__remove' : 'hidden'}
							onClick={() => {
								this.props.removeOnClick(file.id);
							}}
							role='button'
							tabIndex='0'
						>
							{ifFeature('encore', <IconV2 name='circle-xmark-solid' size='16' />, <Icon name='fab-circle-x-14x14' />)}
						</div>
					</div>
					<ProgressBar percent={file.progress} />
				</li>
			);
		});

		return this.props.files.size ? <ul className='FileList'>{listItems}</ul> : null;
	}

	/**
	 * Returns correct implimentation of file name, depending on temporary status
	 * @param  {object} file The file object
	 * @return {string|html} Either the string name of the file, or link html
	 */
	getFileNameFromStatus(file) {
		if (!this.isTemporary(file)) {
			return (
				<a className='js-FileListLink' data-file_id={file.id} data-filemode={file.fileType} onClick={(e) => this.previewFile(file)}>
					{file.name}
				</a>
			);
		}

		return file.name;
	}

	/**
	 * Is the file a temporary upload?
	 * @param  {object}  file The file object
	 * @return {Boolean}      Temporary status (if no employeeId, it is temporary)
	 */
	isTemporary(file) {
		return file._name && file._name === 'TemporaryFile';
	}

	/**
	 * Preview a file
	 * @param  {object} file The file object
	 */
	previewFile(file) {
		const { mostRecentEmployeeFileDataId, id, name } = file;

		try {
			window.previewFile(mostRecentEmployeeFileDataId, id, name, false, '.js-FileListLink');
		} catch (e) {
			window.setMessage(PREVIEW_ERROR, 'error');
			window.Rollbar.error('previewFile errored when called.', { ...file });
		}
	}
}

export default FileList;
