module.exports=function anonymous(obj,microTemplate
) {
var p=[],print=function(){p.push.apply(p,arguments);};with(obj){p.push(''); var alignMap = {  left: 'TooltipStandard--textLeft',  right: 'TooltipStandard--textRight',  center: 'TooltipStandard--textCenter' };  var tooltipClasses = 'TooltipStandard ' + alignMap[textAlign]; 
p.push('  <div class="',window.microTemplate.encodeHtml(tooltipClasses),'">   '); if (title) { 
p.push('   <p class="TooltipStandard__title">',window.microTemplate.encodeHtml(title),'</p>  '); } 
p.push('   '); if (Array.isArray(content)) { 
p.push('   <ul>    '); content.forEach(function (item) { 
p.push('     <li>',window.microTemplate.encodeHtml(item),'</li>    '); }); 
p.push('   </ul>  '); } else { 
p.push('   ',window.microTemplate.encodeHtml(content),'  '); } 
p.push(' </div> ');}return p.join('');
}