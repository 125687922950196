import Avatar from '../components/item/avatar';
import { connect } from 'react-redux';
import { getUserById } from '../../../store/selectors';

const mapStateToProps = (state, ownProps) => {
	const user = getUserById(state, ownProps.id) || {};
	return {
		src: user.avatar || null,
	}
};

export default connect(
	mapStateToProps,
	{}
)(Avatar);

