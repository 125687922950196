import {
	createLogger,
} from '@utils/dev-logger';

import { render } from 'base/wrapped-render';

import {
	showModal,
	closeModal,
} from 'show-modal.mod';
import { getJsonData } from 'Data.util';

import {
	AddItemModal,
} from './add-item-modal.react';
import {
	AddEmploymentStatusModal,
} from './add-employment-status-modal.react';

let $div = null;

function renderModal(reactRoot, props = {}) {
	const _handleClose = cb => (
		(...args) => {
			cb(...args);
			renderModal(reactRoot, {...props, isOpen: false });
		}
	);

	const {
		isOpen = true,
		onCancel,
		onSave,
	} = props;

	render(
		<AddItemModal
			{ ...props }
			isOpen={ isOpen }
			onCancel={
				_handleClose(onCancel)
			}
			onSave={
				_handleClose(onSave)
			}
		/>,
		reactRoot
	);
}

function renderCustomModal(modalKey, props) {
	const {
		fieldId,
		fieldName,
		onCancel,
		onSave,
		settings,
	} = props;

	const _handleClose = cb => (
		(...args) => {
			cb(...args);
			closeModal();
		}
	);

	if (modalKey === 'employmentStatus') {
		let showAcaStatus = false;
		let acaStatuses = [];
		if (settings) {
			showAcaStatus = Boolean(settings.showAcaStatus);
			acaStatuses = settings.acaStatuses;
		} else {
			// For legacy pages
			const employmentStatusModalData = getJsonData('#js-employmentStatusModalData', true);
			if (employmentStatusModalData) {
				showAcaStatus = Boolean(employmentStatusModalData.showAcaStatus);
				acaStatuses = employmentStatusModalData.acaStatuses;
			}
		}

		showModal(<AddEmploymentStatusModal
			acaStatuses={ acaStatuses }
			fieldId={ fieldId }
			fieldName={ fieldName }
			onClose={
				_handleClose(onCancel)
			}
			onSave={
				_handleClose(onSave)
			}
			showAcaStatus={ showAcaStatus }
		/>);
	}
}

$(document).on('ba:selectAddItem', (e) => {
	const {
		originalEvent,
		target,
	} = e;

	const {
		detail,
	} = originalEvent;

	const $target = $(target);

	const fieldId = $target.attr('data-add-item-field-id');
	const label = $target.attr('data-add-item-label');
	const skipDefault = $target.attr('data-skip-default-modal');
	const name = $target.attr('name') || $target.attr('data-name');

	if (originalEvent.defaultPrevented || !fieldId || skipDefault) {
		const logger = createLogger('AddItem.mod');

		if (!fieldId) {
			logger.warn('`<ba-select>` is missing the required "data-add-item-field-id" attribute. Prevent default on "ba:selectAddItem" to remove this warning.');
		}

		if (!name) {
			logger.warn('`<ba-select>` is missing a required value in the "name" attribute.');
		}

		return;
	}

	const customModalKey = $target.attr('data-custom-modal-key');
	if (customModalKey) {
		const props = {
			fieldId,
			label,
			onCancel: () => detail.reject(),
			onSave: (text, value) => {
				detail.resolve([{
					selected: true,
					text,
					value,
				}]);
			},
			fieldName: name,
			settings: detail.settings,
		};

		renderCustomModal(customModalKey, props);

		return;
	}

	if (!$div || !$div.length) {
		$div = $('<div>').appendTo(document.body);
	}

	const props = {
		fieldId,
		label,
		onCancel: () => detail.reject(),
		onSave: (text, value) => {
			detail.resolve([{
				selected: true,
				text,
				value,
			}]);
		},
		name,
		url: $target.attr('data-add-item-url'),
	};

	renderModal($div[0], props);
});

export { AddItemModal } from './add-item-modal';
