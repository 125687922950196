import {
	connect,
} from 'react-redux';

import CommentList from '../components/comment-list';
import {
	getAllCommentIds,
} from '../../../store/selectors';

const mapStateToProps = state => ({
	ids: getAllCommentIds(state)
});

export default connect(
	mapStateToProps,
	{}
)(CommentList);
