import { customQuestionInputTypes } from '../../constants';
import { getUsageContext, getValidationContext } from '../context';
import InputComponentTypes from './types';
export { CandidateVeteranStatus } from './veteran-status';


export const CandidateField = (props) => {
	const {
		cqType,
		data,
		label,
		name,
		rules,
		showErrorMessage,
		type,
		typeProps = {},
	} = props;

	const usage = getUsageContext();
	const {
		errors,
		register,
	} = getValidationContext();

	const inputType = cqType ? customQuestionInputTypes[cqType] : type;
	const InputComponent = InputComponentTypes[inputType];

	if (InputComponent === undefined) {
		return null;
	}

	const isRequired = calculateIsRequired(usage, name, data ? data.isRequired : false);
	const validationRules = {
		...rules,
		required: isRequired,
	};

	if (cqType) {
		typeProps.cqType = cqType;
	}

	return (
		<InputComponent
			data={ data ? { ...data, isRequired } : null }
			errorState={ errors[name] }
			label={ label }
			name={ name }
			rules={ rules }
			showErrorMessage={ showErrorMessage }
			typeProps={ typeProps }
			validationRegistration={ (rules || isRequired) ? register(validationRules) : null }
		/>
	);
};

export const CandidateFieldRow = (props) => {
	const { shouldShow, children } = props;

	if (!shouldShow) {
		return null;
	}

	return (
		<div className="fab-FormRow CandidateForm__row">
			{ children }
		</div>
	);
};

export const CandidateFieldSection = (props) => {
	const { shouldShow, children } = props;

	if (!shouldShow) {
		return null;
	}
	return (
		<fieldset className="fab-FormSection CandidateForm__section">
			{ children }
		</fieldset>
	);
};

export function calculateIsRequired(usage, fieldName, isRequired) {
	if (usage === 'add' || usage === 'edit') {
		return (fieldName === 'firstName' || fieldName === 'lastName');
	}

	return isRequired;
}
