import { useState, useEffect } from 'react';
import { getInstalledApps } from 'InstalledApps.util';

/**
 * Determines if a given app is enabled for a company
 * @param  {string} appName   Name of an app
 * @return {bool}
 */
export const useIsAppInstalled = (appName: string): boolean => {
	const [isInstalled, setIsInstalled] = useState(false);

	useEffect(() => {
		const checkAppInstalled = async () => {
			const installedApps = await getInstalledApps();
			setIsInstalled(installedApps.includes(appName));
		};

		checkAppInstalled();
	}, [appName]);

	return isInstalled;
};