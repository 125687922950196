import React, { Component, Fragment } from 'react';
import { Button } from '@fabric/button';
import { Icon, IconButton, IconV2, Nub, TextField, LayoutBox } from '@bamboohr/fabric';
import { Select as ReactSelect } from '@fabric/select';
import './style.styl';
import { ifFeature, isEnabled } from '@bamboohr/utils/lib/feature';
import ListItem from './list-item';
import Select from 'select.react';
import Tooltip from 'tooltip.react';
import { classNameFromObject as co } from '@utils/dom';

/**
 * React component version of the employee bulk selector. Filters and employee arrays should use the same object format/data as the other employee selector
 *
 * All props required
 * @prop {array} employeeFilters - an array containing employee info based filters e.g. Department, Location, Job Title
 * @prop {array} employeeList - an array containing all employees for a company
 * @prop {function} onListchange - a function that will be called when selecting or deselecting employees.
 * Has 1 parameter which is an array of the currently selected employees. You should take this array and update your selected employees state item.
 * This allows you to always have access to the list of selected employees.
 * @prop {string} rowTextRight - the key of the property from the employee object to display as the secondary text on the right side of the employee row
 * @prop {array} selectedEmployeeList - an array of selected employees.
 * Should be from your components state and start as an empty array unless you want to start with already selected employees.
 *
 * For an example of how this component is used, look at time-off/bulk-assign.react/index.js
 */
export class EmployeeSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			employees: props.employeeList,
			filteredEmployees: props.employeeList,
			availableEmployees: [],
			selectedEmployees: [],
			firstSelectedFilter: {},
			secondSelectedFilter: {},
			showAddButton: false,
			showSecondFilter: false,
			searchFocused: false,
			searchValue: '',
		};
	}

	_handleAvailableClick = (employee) => {
		const availableEmployees = this.state.availableEmployees.slice(0);
		const employeeIndex = availableEmployees.indexOf(employee);

		if (employeeIndex === -1) {
			availableEmployees.push(employee);
			this.setState({ availableEmployees: availableEmployees });
		} else {
			availableEmployees.splice(employeeIndex, 1);
			this.setState({ availableEmployees: availableEmployees });
		}
	};

	_handleSelectedClick = (employee) => {
		const selectedEmployees = this.state.selectedEmployees.slice(0);
		const employeeIndex = selectedEmployees.indexOf(employee);

		if (employeeIndex === -1) {
			selectedEmployees.push(employee);
			this.setState({ selectedEmployees: selectedEmployees });
		} else {
			selectedEmployees.splice(employeeIndex, 1);
			this.setState({ selectedEmployees: selectedEmployees });
		}
	};

	_handleAllEmployeesSelect = () => {
		const selectedEmployeeList = this.props.selectedEmployeeList.slice(0);
		let employees = this.state.employees.slice(0);

		if (employees.length > 0) {
			employees = selectedEmployeeList.concat(employees);
			this.props.onListChange(employees);
			this.setState({
				employees: [],
				availableEmployees: [],
				selectedEmployees: [],
			});
		}
	};

	_handleEmployeesSelect = () => {
		let availableEmployees = this.state.availableEmployees.slice(0);
		if (availableEmployees.length > 0) {
			const employees = this.state.employees.slice(0);
			const newEmployeeList = employees.filter((employee) => {
				return availableEmployees.indexOf(employee) === -1;
			});
			const newSelectedEmployeeList = this.props.selectedEmployeeList.concat(availableEmployees);

			this.props.onListChange(newSelectedEmployeeList);
			this.setState({
				employees: newEmployeeList,
				availableEmployees: [],
			});
		}
	};

	_handleAllEmployeesDeselect = () => {
		const employees = this.state.employees.slice(0);
		let selectedEmployeeList = this.props.selectedEmployeeList.slice(0);

		if (selectedEmployeeList.length > 0) {
			selectedEmployeeList = employees.concat(selectedEmployeeList);
			this.props.onListChange([]);
			this.setState({
				employees: selectedEmployeeList,
				availableEmployees: [],
				selectedEmployees: [],
			});
		}
	};

	_handleEmployeesDeselect = () => {
		const selectedEmployees = this.state.selectedEmployees.slice(0);
		if (selectedEmployees.length > 0) {
			const selectedEmployeeList = this.props.selectedEmployeeList.slice(0);
			const newSelectedEmployeeList = selectedEmployeeList.filter((employee) => {
				return selectedEmployees.indexOf(employee) === -1;
			});
			const newEmployeeList = this.state.employees.concat(selectedEmployees);

			this.props.onListChange(newSelectedEmployeeList);
			this.setState({
				employees: newEmployeeList,
				selectedEmployees: [],
			});
		}
	};

	_handleAddSecondFilter = () => {
		this.setState((prevState) => ({
			showAddButton: false,
			showSecondFilter: !prevState.showSecondFilter,
		}));
	};

	_formatEmployeeFilters = () => {
		const employeeFilters = this.props.employeeFilters.slice(0);
		let items = [];
		employeeFilters.forEach((value, index) => {
			let filter = { displayText: value.name, key: value.key };
			let options = [];
			if (value.options) {
				for (let i in value.options) {
					let option = {
						displayText: value.options[i],
						value: i,
					};
					if (this.state.firstSelectedFilter[i]) {
						option.selected = true;
					}
					options.push(option);
				}
				filter.items = options;
			}
			items.push(filter);
		});
		return items;
	};

	_onFilterSelect = (item, details) => {
		const newEmployeeList = this.props.employeeList.filter((employee) => {
			return this.props.selectedEmployeeList.indexOf(employee) === -1;
		});

		if (item === undefined) {
			this.setState({
				firstSelectedFilter: {},
				secondSelectedFilter: {},
				employees: newEmployeeList,
				filteredEmployees: newEmployeeList,
				showAddButton: false,
				showSecondFilter: false,
			});
		} else {
			const id = item.value;
			const key = details.parentItem.key;
			let filteredEmployees = [];
			for (let i in newEmployeeList) {
				if (newEmployeeList[i][key] === id) {
					filteredEmployees.push(newEmployeeList[i]);
				}
			}
			this.setState({
				firstSelectedFilter: { [item.value]: { displayText: item.displayText, key: details.parentItem.key } },
				secondSelectedFilter: {},
				employees: filteredEmployees,
				filteredEmployees: filteredEmployees,
				showAddButton: true,
				showSecondFilter: false,
			});
		}
	};

	_populateFilterSelect = () => {
		const filters = {
			items: this._formatEmployeeFilters(),
		};
		const settings = {
			placeholder: $.__('All Employees'),
			grow: true,
			showSearch: 'never',
		};
		return {
			className: 'EmployeeSelectorReact__filters__select',
			data: filters,
			settings: settings,
			onChange: this._onFilterSelect,
		};
	};

	_formatSecondEmployeeFilters = () => {
		const employeeFilters = this.props.employeeFilters.slice(0);
		const firstFilter = this.state.firstSelectedFilter;
		let items = [];
		employeeFilters.forEach((value, index) => {
			if (value.key !== firstFilter[Object.keys(firstFilter)[0]].key) {
				let filter = { displayText: value.name, key: value.key };
				let options = [];
				if (value.options) {
					for (let i in value.options) {
						let option = {
							displayText: value.options[i],
							value: i,
						};
						// eslint-disable-next-line max-depth
						if (this.state.secondSelectedFilter[i]) {
							option.selected = true;
						}
						options.push(option);
					}
					filter.items = options;
				}
				items.push(filter);
			}
		});
		return items;
	};

	_onSecondFilterSelect = (item, details) => {
		const newEmployeeList = this.props.employeeList.filter((employee) => {
			return this.props.selectedEmployeeList.indexOf(employee) === -1;
		});
		const firstFilter = this.state.firstSelectedFilter;
		const key = firstFilter[Object.keys(firstFilter)[0]].key;
		const id = Object.keys(firstFilter)[0];
		let filteredEmployees = [];
		for (let i in newEmployeeList) {
			if (newEmployeeList[i][key] === id) {
				filteredEmployees.push(newEmployeeList[i]);
			}
		}

		if (item === undefined) {
			this.setState({
				secondSelectedFilter: {},
				employees: filteredEmployees,
				filteredEmployees: filteredEmployees,
				showAddButton: true,
				showSecondFilter: false,
			});
		} else {
			const id = item.value;
			const key = details.parentItem.key;
			let secondFilteredEmployees = [];
			for (let i in filteredEmployees) {
				if (filteredEmployees[i][key] === id) {
					secondFilteredEmployees.push(filteredEmployees[i]);
				}
			}
			this.setState((prevState) => ({
				secondSelectedFilter: { [item.value]: { displayText: item.displayText, key: details.parentItem.key } },
				employees: secondFilteredEmployees,
				filteredEmployees: secondFilteredEmployees,
			}));
		}
	};

	_populateSecondFilterSelect = () => {
		const filters = {
			items: this._formatSecondEmployeeFilters(),
		};
		const settings = {
			grow: true,
			showSearch: 'never',
		};

		return {
			className: 'EmployeeSelectorReact__filters__select',
			data: filters,
			settings: settings,
			onChange: this._onSecondFilterSelect,
		};
	};

	_sortEmployees = (employees) => {
		const sortedEmployees = employees.sort((a, b) => {
			return a.lastName.localeCompare(b.lastName);
		});
		return sortedEmployees;
	};

	_handleSearch = (event) => {
		const { filteredEmployees } = this.state;
		const { selectedEmployeeList } = this.props;
		const employees = filteredEmployees.filter((employee) => {
			return selectedEmployeeList.indexOf(employee) === -1;
		});
		const search = event.target.value.toLowerCase();
		const searchedEmployees = employees.filter((employee) => {
			if (
				employee.firstName.toLowerCase().indexOf(search) === 0 ||
				employee.lastName.toLowerCase().indexOf(search) === 0 ||
				employee.displayName.toLowerCase().indexOf(search) === 0
			) {
				return true;
			}

			return false;
		});

		this.setState({
			searchValue: event.target.value,
			employees: searchedEmployees,
		});
	};

	_handleSearchFocus = () => {
		this.setState({ searchFocused: true });
	};

	_handleSearchBlur = () => {
		const { searchValue } = this.state;
		if (searchValue === '') {
			this.setState({ searchFocused: false });
		}
	};

	render() {
		const { employees, searchFocused, searchValue, showAddButton, showSecondFilter } = this.state;
		const { rowTextRight, selectedEmployeeList } = this.props;
		const employeeList = this._sortEmployees(employees.slice(0));
		const employeeListItems = employeeList.map((employee) => (
			<ListItem
				displayText={employee.displayName}
				displayTextRight={employee[rowTextRight]}
				item={employee}
				key={employee.id}
				onItemClick={this._handleAvailableClick}
			/>
		));
		const selectedEmployees = this._sortEmployees(selectedEmployeeList.slice(0));
		const selectedEmployeeListItems = selectedEmployees.map((employee) => (
			<ListItem
				displayText={employee.displayName}
				displayTextRight={employee[rowTextRight]}
				item={employee}
				key={employee.id}
				onItemClick={this._handleSelectedClick}
			/>
		));

		return (
			<div className='EmployeeSelectorReact'>
				<div className='EmployeeSelectorReact__container'>
					<p className='EmployeeSelectorReact__title'>{$.__('Available Employees')}</p>
					<div className='EmployeeSelectorReact__wrapper'>
						<div className='EmployeeSelectorReact__filters'>
							<Select {...this._populateFilterSelect()} />
							{showAddButton ? (
								<div
									className='EmployeeSelectorReact__link--add baseBorderColor--hoverBefore childHover'
									onClick={this._handleAddSecondFilter}
								>
									<span className='EmployeeSelectorReact__link--addIco childHoverBackgroundColor--before childHoverBackgroundColor--after'></span>
								</div>
							) : (
								<div className='EmployeeSelectorReact__filters__plus'>{showSecondFilter && <span>+</span>}</div>
							)}
							{showSecondFilter && <Select {...this._populateSecondFilterSelect()} />}
						</div>
						<div className='EmployeeSelectorReact__searchContainer'>
							<input
								className='EmployeeSelectorReact__search'
								onBlur={this._handleSearchBlur}
								onChange={this._handleSearch}
								onFocus={this._handleSearchFocus}
								placeholder={$.__('Search...')}
								type='text'
								value={searchValue}
							/>
							<div className={co({ EmployeeSelectorReact__searchIcon: true, baseFillColor: searchFocused })}>
								<Icon name='magnifying-glass-16x16' />
							</div>
						</div>
						<ul className='EmployeeSelectorReact__list'>{employeeListItems}</ul>
					</div>
				</div>
				<div className='EmployeeSelectorReact__buttons'>
					<div className='EmployeeSelectorReact__btn' onClick={this._handleAllEmployeesSelect}></div>
					<div className='EmployeeSelectorReact__btn EmployeeSelectorReact__btn--one' onClick={this._handleEmployeesSelect}></div>
					<div
						className='EmployeeSelectorReact__btn EmployeeSelectorReact__btn--left EmployeeSelectorReact__btn--one'
						onClick={this._handleEmployeesDeselect}
					></div>
					<div
						className='EmployeeSelectorReact__btn EmployeeSelectorReact__btn--left'
						onClick={this._handleAllEmployeesDeselect}
					></div>
				</div>
				<div className='EmployeeSelectorReact__container'>
					<p className='EmployeeSelectorReact__title'>{$.__('Selected Employees')}</p>
					{selectedEmployeeListItems.length === 0 ? (
						<div className='EmployeeSelectorReact__wrapper EmployeeSelectorReact__wrapper--empty' id='js-empty-list'>
							<img alt='Add Employees' src='/images/settings/new_benefit/plex-blank-state.png'></img>
							<p>{$.__(`You haven't added any employees yet.`)}</p>
						</div>
					) : (
						<div className='EmployeeSelectorReact__wrapper' id='js-selected-employee-list'>
							<ul className='EmployeeSelectorReact__list--selected'>{selectedEmployeeListItems}</ul>
						</div>
					)}
				</div>
			</div>
		);
	}
}

// Use this when jadeEnabled
export class TheNewEmployeeSelector extends Component {
	constructor(props) {
		super(props);

		let { employees, isRowSelected, sort } = this.props;

		this.leftSideUsedLast = false;
		this.lastRowKeyUsed = null;

		employees = employees.sort(sort);
		const [selected, unselected] = splitOnCondition(employees, isRowSelected);

		this.state = {
			availableEmployees: unselected,
			selectedEmployees: selected,
			firstFilter: null,
			secondFilter: null,
			showSecondFilter: false,
			searchFocused: false,
			searchValue: '',
			leftHighlightedKeys: {},
			rightHighlightedKeys: {},
		};
	}

	static defaultProps = {
		isRowDisabled: (o) => o.disabled,
		isRowSelected: (o) => o.selected,
		mainContent: (o) => o.lastNameFirstName,
		rowKey: (o) => o.id,
		rowDisabledTooltip: () => null,
		secondaryContent: () => null,
		secondaryContentIcon: () => null,
		showSearch: true,
		sort: (a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName),
	};

	_filterAndPlace = () => {
		let { employees, rowKey, isRowSelected, sort } = this.props;
		const { leftHighlightedKeys } = this.state;

		employees = employees.sort(sort);
		const [selected, unselected] = splitOnCondition(employees, isRowSelected);

		const newLeftHighlightedKeys = {};

		const filtered = unselected.filter(this._isNotFiltered);
		filtered.forEach((employee) => {
			const key = rowKey(employee);
			if (leftHighlightedKeys[key]) {
				newLeftHighlightedKeys[key] = true;
			}
		});

		this.setState({
			availableEmployees: filtered,
			selectedEmployees: selected,
			leftHighlightedKeys: newLeftHighlightedKeys,
		});
	};

	_sortFilters = (a, b) => {
		if (a.text && b.text) {
			const nameA = a.text.toUpperCase();
			const nameB = b.text.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
		}
		return 0;
	};

	_createFirstFilterSettings = () => {
		const { filters } = this.props;
		const { firstFilter, showSecondFilter } = this.state;

		const widthWithoutSecondFilter = isEnabled('encore') ? 100 : 6;

		const settings = {
			onClear: this._handleFirstFilterCleared,
			onSelect: this._handleFirstFilterSelect,
			placeholder: $.__('All Employees'),
			showSearch: 'never',
			size: 'small',
			width: showSecondFilter ? 4 : widthWithoutSecondFilter,
		};

		if (firstFilter) {
			settings.items = firstFilter.items;
			settings.selectedValues = firstFilter.selectedValues;
		} else {
			settings.items = filters.map((filter) => {
				const { key, name, options } = filter;
				return {
					key,
					text: name,
					items: Object.keys(filter.options)
						.reduce((arr, key) => {
							arr.push({
								text: options[key],
								value: key,
							});
							return arr;
						}, [])
						.sort(this._sortFilters),
				};
			});
		}
		return settings;
	};

	_createSecondFilterSettings = () => {
		const { filters } = this.props;
		const { firstFilter, secondFilter } = this.state;

		const settings = {
			onClear: this._handleSecondFilterCleared,
			onSelect: this._handleSecondFilterSelect,
			placeholder: $.__('-- Select --'),
			showSearch: 'never',
			size: 'small',
			width: 4,
		};

		if (secondFilter) {
			settings.items = secondFilter.items;
			settings.selectedValues = secondFilter.selectedValues;
		} else {
			settings.items = filters
				.filter((filter) => filter.key !== firstFilter.parentKey)
				.map((filter) => {
					const { key, name, options } = filter;
					return {
						key,
						text: name,
						items: Object.keys(filter.options)
							.reduce((arr, key) => {
								arr.push({
									text: options[key],
									value: key,
								});
								return arr;
							}, [])
							.sort(this._sortFilters),
					};
				});
		}

		return settings;
	};

	_createRowMeta = (row) => {
		const { rowKey, mainContent, secondaryContent, secondaryContentIcon, isRowDisabled, rowDisabledTooltip } = this.props;

		return {
			key: rowKey(row),
			main: mainContent(row),
			secondary: secondaryContent(row),
			secondaryIcon: secondaryContentIcon(row),
			isDisabled: isRowDisabled(row),
			disabledTooltipContent: rowDisabledTooltip(row),
		};
	};

	_isNotFiltered = (row) => {
		const { firstFilter, secondFilter, searchValue } = this.state;
		const value = searchValue.toLowerCase().trim();

		const matchesFirstFilter = firstFilter ? String(row[firstFilter.parentKey]) === firstFilter.selectedValues[0] : true;
		if (!matchesFirstFilter) {
			return false;
		}

		const matchesSecondFilter = secondFilter ? String(row[secondFilter.parentKey]) === secondFilter.selectedValues[0] : true;
		if (!matchesSecondFilter) {
			return false;
		}

		const matchesSearchInput =
			row.firstName.toLowerCase().indexOf(value) === 0 ||
			row.lastName.toLowerCase().indexOf(value) === 0 ||
			row.displayName.toLowerCase().indexOf(value) === 0 ||
			row.lastNameFirstName.toLowerCase().indexOf(value) === 0;
		if (!matchesSearchInput) {
			return false;
		}

		return true;
	};

	_handleFirstFilterSelect = (newValue, notSureWhatThisIsFor, details) => {
		const { firstFilter } = this.state;

		this.setState(
			{
				firstFilter: {
					parentKey: details.parentItem ? details.parentItem.key : firstFilter.parentKey,
					items: details.parentItem ? details.parentItem.items : firstFilter.items,
					selectedValues: [newValue],
				},
			},
			this._filterAndPlace
		);
	};

	_handleFirstFilterCleared = () => {
		const { secondFilter } = this.state;
		const firstFilter = secondFilter ? secondFilter : null;

		this.setState(
			{
				firstFilter,
				secondFilter: null,
				showSecondFilter: false,
			},
			this._filterAndPlace
		);
	};

	_handleSecondFilterSelect = (newValue, notSureWhatThisIsFor, details) => {
		const { secondFilter } = this.state;

		this.setState(
			{
				secondFilter: {
					parentKey: details.parentItem ? details.parentItem.key : secondFilter.parentKey,
					items: details.parentItem ? details.parentItem.items : secondFilter.items,
					selectedValues: [newValue],
				},
			},
			this._filterAndPlace
		);
	};

	_handleSecondFilterCleared = () => {
		this.setState(
			{
				secondFilter: null,
				showSecondFilter: false,
			},
			this._filterAndPlace
		);
	};

	_handleAddFilter = () => {
		this.setState({
			showSecondFilter: true,
		});
	};

	_handleSearchFocus = () => {
		this.setState({
			searchFocused: true,
		});
	};

	_handleSearchBlur = () => {
		this.setState({
			searchFocused: false,
		});
	};

	_handleSearchChange = (event) => {
		this.setState(
			{
				searchValue: event.target.value,
			},
			this._filterAndPlace
		);
	};

	_handleRowClick = (event, leftSide, rowKey, isDisabled, isHighlighted) => {
		const { rowKey: makeKey, isRowDisabled } = this.props;
		const { availableEmployees, selectedEmployees } = this.state;

		if (isDisabled) {
			return;
		}

		const { leftHighlightedKeys, rightHighlightedKeys } = this.state;
		let highlightedKeys = leftSide ? leftHighlightedKeys : rightHighlightedKeys;
		let employees = leftSide ? availableEmployees : selectedEmployees;

		if (event.shiftKey && this.lastRowKeyUsed && this.lastRowKeyUsed !== rowKey && this.leftSideUsedLast === leftSide) {
			const changed = [];
			let pushing = false;

			for (let i = 0; i < employees.length; i++) {
				const key = makeKey(employees[i]);
				const disabled = isRowDisabled(employees[i]);

				if (!disabled && pushing) {
					changed.push(key);
				}

				if (!disabled && (key === rowKey || key === this.lastRowKeyUsed)) {
					// eslint-disable-next-line max-depth
					if (pushing) {
						break;
					}
					changed.push(key);
					pushing = true;
				}
			}

			changed.forEach((change) => {
				highlightedKeys[change] = !isHighlighted;
			});
		} else if (event.metaKey || event.altKey || event.ctrlKey) {
			highlightedKeys[rowKey] = !isHighlighted;
		} else {
			highlightedKeys = { [rowKey]: true };
		}

		this.lastRowKeyUsed = rowKey;
		this.leftSideUsedLast = leftSide;

		this.setState({
			[leftSide ? 'leftHighlightedKeys' : 'rightHighlightedKeys']: highlightedKeys,
		});
	};

	_onClickAddAll = () => {
		const { onSelect, isRowDisabled } = this.props;
		const { availableEmployees, leftHighlightedKeys } = this.state;
		const records = availableEmployees.filter((row) => !isRowDisabled(row));

		if (records.length === 0) {
			return;
		}

		onSelect(records);
		this.setState({
			leftHighlightedKeys: {},
			rightHighlightedKeys: leftHighlightedKeys,
		});
	};

	_onClickAddHighlighted = () => {
		const { onSelect, isRowDisabled, rowKey } = this.props;
		const { availableEmployees, leftHighlightedKeys } = this.state;
		const records = availableEmployees.filter((row) => !isRowDisabled(row) && leftHighlightedKeys[rowKey(row)]);

		if (records.length === 0) {
			return;
		}

		onSelect(records);
		this.setState({
			leftHighlightedKeys: {},
			rightHighlightedKeys: leftHighlightedKeys,
		});
	};

	_onClickRemoveHighlighted = () => {
		const { onDeselect, isRowDisabled, rowKey } = this.props;
		const { selectedEmployees, rightHighlightedKeys } = this.state;
		const records = selectedEmployees.filter((row) => !isRowDisabled(row) && rightHighlightedKeys[rowKey(row)]);

		if (records.length === 0) {
			return;
		}

		onDeselect(records);
		this.setState({
			rightHighlightedKeys: {},
			leftHighlightedKeys: rightHighlightedKeys,
		});
	};

	_onClickRemoveAll = () => {
		const { onDeselect, isRowDisabled } = this.props;
		const { rightHighlightedKeys, selectedEmployees } = this.state;
		const records = selectedEmployees.filter((row) => !isRowDisabled(row));

		if (records.length === 0) {
			return;
		}

		onDeselect(records);
		this.setState({
			rightHighlightedKeys: {},
			leftHighlightedKeys: rightHighlightedKeys,
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { employees } = this.props;

		if (employees !== prevProps.employees) {
			this._filterAndPlace();
		}
	}

	render() {
		let { filters = [], rowDisabledTooltip, showSearch = true } = this.props;

		const {
			availableEmployees,
			selectedEmployees,
			leftHighlightedKeys,
			rightHighlightedKeys,
			searchFocused,
			searchValue,
			firstFilter,
			showSecondFilter,
		} = this.state;

		return (
			<div className='EmployeeSelectorReact'>
				<div className='EmployeeSelectorReact__container'>
					<div className='EmployeeSelectorReact__title'>{$.__('Available Employees')}</div>
					<div className='EmployeeSelectorReact__wrapper'>
						{filters.length > 0 && (
							<div className='EmployeeSelectorReact__selects'>
								<ReactSelect {...this._createFirstFilterSettings()} />
								{!!firstFilter && !showSecondFilter && (
									<div className='EmployeeSelectorReact__addSelectIcon' onClick={this._handleAddFilter}>
										{ifFeature('encore', <IconV2 name='circle-plus-regular' size={14} />, <Icon name='fab-circle-plus-14x14' />)}
									</div>
								)}
								{showSecondFilter && (
									<Fragment>
										<div className='EmployeeSelectorReact__plusSelectIcon'>+</div>
										<ReactSelect {...this._createSecondFilterSettings()} />
									</Fragment>
								)}
							</div>
						)}

						{showSearch && (
							<div className='EmployeeSelectorReact__searchContainer'>
								{ifFeature(
									'encore',
									<LayoutBox paddingTop={filters.length > 0 ? '0px' : '16px'}>
										<TextField
											onBlur={this._handleSearchBlur}
											onChange={this._handleSearchChange}
											onFocus={this._handleSearchFocus}
											placeholder={$.__('Search...')}
											value={searchValue}
											size={'small'}
											InputProps={{
												startAdornment: (
													<Nub position={'start'}>
														<IconV2 name='magnifying-glass-solid' size={16} color={'neutral-weak'} />
													</Nub>
												),
											}}
										/>
									</LayoutBox>,
									<input
										className='EmployeeSelectorReact__searchInput'
										onBlur={this._handleSearchBlur}
										onChange={this._handleSearchChange}
										onFocus={this._handleSearchFocus}
										placeholder={$.__('Search...')}
										type='text'
										value={searchValue}
									/>
								)}
								{ifFeature(
									'encore',
									'',
									<div className='EmployeeSelectorReact__searchInputIcon'>
										<Icon brand={searchFocused} name='fab-mag-glass-16x16' />
									</div>
								)}
							</div>
						)}

						{(firstFilter || searchValue) && availableEmployees.length === 0 && (
							<div className='EmployeeSelectorReact__noFilterMatchBlankState'>{$.__('No employees match this filter.')}</div>
						)}

						<div className='EmployeeSelectorReact__listContainer'>
							{availableEmployees.length > 0 && (
								<ul className='EmployeeSelectorReact__list'>
									{availableEmployees.map((row) => {
										const { key, main, secondary, secondaryIcon, isDisabled, disabledTooltipContent } = this._createRowMeta(row);
										const isHighlighted = leftHighlightedKeys[key];

										return (
											<ConditionalWrap
												condition={isDisabled && disabledTooltipContent}
												key={key}
												wrap={(children) => <Tooltip settings={{ content: disabledTooltipContent }}> {children} </Tooltip>}
											>
												<li
													className={co('EmployeeSelectorReact__listItem', {
														'EmployeeSelectorReact__listItem--disabled': isDisabled,
														'EmployeeSelectorReact__listItem--highlighted': isHighlighted,
													})}
													onClick={(event) => this._handleRowClick(event, true, key, isDisabled, isHighlighted)}
												>
													<div className='EmployeeSelectorReact__listItem__mainContent'>{main}</div>
													{secondary && (
														<span
															className={co('EmployeeSelectorReact__listItem__textRight', {
																'EmployeeSelectorReact__listItem__textRight--highlighted': isHighlighted,
															})}
														>
															{secondaryIcon && (
																<span
																	className={co('EmployeeSelectorReact__listItem__textRightIcon', {
																		'EmployeeSelectorReact__listItem__textRightIcon--highlighted': isHighlighted,
																	})}
																>
																	{ifFeature(
																		'encore',
																		<IconV2 color='neutral-medium' name={secondaryIcon} size={12} />,
																		<Icon name={secondaryIcon} />
																	)}
																</span>
															)}
															{secondary}
														</span>
													)}
												</li>
											</ConditionalWrap>
										);
									})}
								</ul>
							)}
						</div>
					</div>
				</div>

				<div className='EmployeeSelectorReact__buttons'>
					<div className='EmployeeSelectorReact__btn'>
						{ifFeature(
							'encore',
							<IconButton
								ariaLabel={$.__('All Employees')}
								icon={<IconV2 color={'neutral-x-strong'} name={'chevrons-right-regular'} size={16} />}
								onClick={this._onClickAddAll}
								size='small'
								type='button'
								variant='outlined'
							/>,
							<Button
								clickAction={this._onClickAddAll}
								icon='fab-double-chevron-right-14x11'
								outline={true}
								secondary={true}
								size='teenie'
								type='button'
								width={1}
							/>
						)}
					</div>
					<div className='EmployeeSelectorReact__btn'>
						{ifFeature(
							'encore',
							<IconButton
								ariaLabel={$.__('Select employee')}
								icon={<IconV2 color={'neutral-x-strong'} name={'chevron-right-regular'} size={16} />}
								onClick={this._onClickAddHighlighted}
								size='small'
								type='button'
								variant='outlined'
							/>,
							<Button
								clickAction={this._onClickAddHighlighted}
								icon='fab-chevron-right-7x11'
								outline={true}
								secondary={true}
								size='teenie'
								type='button'
								width={1}
							/>
						)}
					</div>
					<div className='EmployeeSelectorReact__btn'>
						{ifFeature(
							'encore',
							<IconButton
								ariaLabel={$.__('Remove employee')}
								icon={<IconV2 color={'neutral-x-strong'} name={'chevron-left-regular'} size={16} />}
								onClick={this._onClickRemoveHighlighted}
								size='small'
								type='button'
								variant='outlined'
							/>,
							<Button
								clickAction={this._onClickRemoveHighlighted}
								icon='fab-chevron-left-7x11'
								outline={true}
								secondary={true}
								size='teenie'
								type='button'
								width={1}
							/>
						)}
					</div>
					<div className='EmployeeSelectorReact__btn'>
						{ifFeature(
							'encore',
							<IconButton
								ariaLabel={$.__('Remove all employees')}
								icon={<IconV2 color={'neutral-x-strong'} name={'chevrons-left-regular'} size={16} />}
								onClick={this._onClickRemoveAll}
								size='small'
								type='button'
								variant='outlined'
							/>,
							<Button
								clickAction={this._onClickRemoveAll}
								icon='fab-double-chevron-left-14x11'
								outline={true}
								secondary={true}
								size='teenie'
								type='button'
								width={1}
							/>
						)}
					</div>
				</div>

				<div className='EmployeeSelectorReact__container'>
					<p className='EmployeeSelectorReact__title'>{$.__('Selected Employees')}</p>
					<div className='EmployeeSelectorReact__wrapper'>
						{selectedEmployees.length > 0 && (
							<div className='EmployeeSelectorReact__listContainer'>
								<ul className='EmployeeSelectorReact__list'>
									{selectedEmployees.map((row) => {
										const { key, main, secondary, isDisabled } = this._createRowMeta(row);
										const isHighlighted = rightHighlightedKeys[key];

										return (
											<li
												className={co('EmployeeSelectorReact__listItem', {
													'EmployeeSelectorReact__listItem--disabled': isDisabled,
													'EmployeeSelectorReact__listItem--highlighted': isHighlighted,
												})}
												key={key}
												onClick={(event) => this._handleRowClick(event, false, key, isDisabled, isHighlighted)}
											>
												<div className='EmployeeSelectorReact__listItem__mainContent'>{main}</div>
												{secondary && (
													<span
														className={co('EmployeeSelectorReact__listItem__textRight', {
															'EmployeeSelectorReact__listItem__textRight--highlighted': isHighlighted,
														})}
													>
														{' '}
														{secondary}
													</span>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						)}

						{selectedEmployees.length === 0 && (
							<div className='EmployeeSelectorReact__rightBlankState'>
								<div className='EmployeeSelectorReact__rightBlankStateIcon'>
									{ifFeature(
										'encore',
										<IconV2 color={'neutral-medium'} name='circle-user-regular' size={60} />,
										<Icon name='fab-person-lined-72x72' />
									)}
								</div>
								<p className='EmployeeSelectorReact__rightBlankState__text'>{$.__(`You haven't added any employees yet.`)}</p>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

function ConditionalWrap({ condition, wrap, children }) {
	return condition ? wrap(children) : children;
}

function splitOnCondition(array, condition) {
	const metCondition = [];
	const failedCondition = [];

	for (let i = 0; i < array.length; i++) {
		const item = array[i];
		(condition(item) ? metCondition : failedCondition).push(item);
	}

	return [metCondition, failedCondition];
}
