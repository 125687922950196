import React from 'react';
import { Icon } from '@bamboohr/fabric';

import './styles.styl';

const CLASS_NAME = 'EmployeesList';

function EmployeesList(props) {
	const {
		employees,
		title,
	} = props;
	return (
		<div className={ CLASS_NAME }>
			<div className={ `${ CLASS_NAME }__header` }>
				<Icon name="fab-people-20x16" />
				<span className={ `${ CLASS_NAME }__header--title` }>
					{ title }
				</span>
			</div>
			<ul className={ `${ CLASS_NAME }__list` }>
				{
					employees.map((employeeData, i) => {
						const {
							name,
							imageUrl,
						} = employeeData;
						const key = `${ name }-${ i }`;

						return (
							<li key={ key }>
								<img
									alt="Employee"
									src={ imageUrl }
								/>
								<span>{ name }</span>
							</li>
						);
					})
				}
			</ul>
		</div>
	);
}

export default EmployeesList;
