import {
	Banks,
	BankAccountType,
	CollectionRefundType,
	TransactionMethodData,
	TransactionMethodErrors,
	TransactionMethodType,
	TransactionMethodTypeOther,
} from './types';

interface GetTransactionMethodData {
	accountNumber: string;
	accountNumberError: string;
	accountType: BankAccountType;
	accountTypeError: string;
	bankId: number;
	routingNumber: string;
	routingNumberError: string;
	type: TransactionMethodType;
}

interface GetTransactionMethodErrorsData {
	accountNumberError: string;
	accountTypeError: string;
	routingNumberError: string;
	type: TransactionMethodType;
}

export function getDifferentBankOtherType(collectionRefundType: CollectionRefundType): TransactionMethodTypeOther {
	return collectionRefundType === 'collection' ? 'other' : 'otherNoBank';
}

export function getInitialTransactionMethodType(banksOnFile: Banks, collectionRefundType: CollectionRefundType): TransactionMethodType {
	if (banksOnFile && banksOnFile.length) {
		return 'bank';
	}

	return getDifferentBankOtherType(collectionRefundType);
}

export function getTransactionMethodData(data: GetTransactionMethodData): TransactionMethodData {
	const {
		accountNumber,
		accountNumberError,
		accountType,
		accountTypeError,
		bankId,
		routingNumber,
		routingNumberError,
		type,
	} = data;

	const transactionMethodData: TransactionMethodData = {
		bankType: type,
	};

	transactionMethodData.errors = getTransactionMethodErrors({
		accountNumberError,
		accountTypeError,
		routingNumberError,
		type,
	});

	if (type === 'bank') {
		transactionMethodData.bankId = bankId;
	} else if (type === 'other') {
		transactionMethodData.accountNumber = accountNumber;
		transactionMethodData.accountType = accountType;
		transactionMethodData.routingNumber = routingNumber;
	}

	return transactionMethodData;
}

export function getTransactionMethodErrors(data: GetTransactionMethodErrorsData): TransactionMethodErrors {
	const {
		accountNumberError,
		accountTypeError,
		routingNumberError,
		type,
	} = data;

	let errors = null;

	if (type === 'other') {
		if (accountNumberError || accountTypeError || routingNumberError) {
			errors = {};

			if (accountNumberError) {
				errors.accountNumber = accountNumberError;
			}

			if (accountTypeError) {
				errors.accountType = accountTypeError;
			}

			if (routingNumberError) {
				errors.routingNumber = routingNumberError;
			}
		}
	}

	return errors;
}

export function isLocationGreenhouse(): boolean {
	return window.location.pathname.split('/')[1] === 'greenhouse';
}

export function isStringNumeric(value: string): boolean {
	return (/^\d+?$/).test(value);
}

export function renderWireMessage(): string {
	return $.__('You commit to wiring the above amount within 2-3 business days. Our wire information will be provided after your approval.');
}
