import Reply from '../../containers/reply';
import './styles.styl';

const ReplyList = ({ ids, timezones }) => (
	<ul className="FeedListReplyList">
		{ids.map(id => (
			<Reply
				id={id}
				key={`reply${ id }`}
				timezones={timezones}
			/>
		))}
	</ul>
);

export default ReplyList;

