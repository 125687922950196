(function ($) {
	$.extend({
		tablesorterPager: new function () {
			function updatePageDisplay(c) {
				var s = $(c.cssPageDisplay, c.container).val((c.page + 1) + c.seperator + c.totalPages).trigger('change');
				updatePages(c);
			}

			function setPageSize(table, size) {
				var c = table.config;
				c.size = size;
				c.totalPages = Math.ceil(c.totalRows / c.size);
				c.pagerPositionSet = false;
				moveToPage(table);
				fixPosition(table);
			}

			function fixPosition(table) {
				var c = table.config;
				var o;
				if (!c.pagerPositionSet && c.positionFixed) {
					c = table.config;
					o = $(table);
					if (o.offset) {
						c.container.css({
							top: o.offset().top + o.height() + 'px'
							//, position: 'absolute'
						});
					}
					c.pagerPositionSet = true;
				}
			}

			function moveToFirstPage(table) {
				var c = table.config;
				c.page = 0;
				moveToPage(table);
			}

			function moveToLastPage(table) {
				var c = table.config;
				c.page = (c.totalPages - 1);
				moveToPage(table);
			}

			function moveToNextPage(table) {
				var c = table.config;
				c.page++;
				if (c.page >= (c.totalPages - 1)) {
					c.page = (c.totalPages - 1);
				}
				moveToPage(table);
			}

			function moveToPrevPage(table) {
				var c = table.config;
				c.page--;
				if (c.page <= 0) {
					c.page = 0;
				}
				moveToPage(table);
			}

			function moveToPage(table) {
				var c = table.config;
				if (c.page < 0 || c.page > (c.totalPages - 1)) {
					c.page = 0;
				}
				renderTable(table, c.rowsCopy);
			}

			function renderTable(table, rows) {
				var c = table.config;
				var l = rows.length;
				var s = (c.page * c.size);
				var e = (s + c.size);
				if (e > rows.length) {
					e = rows.length;
				}
				var tableBody = $(table.tBodies[0]);
				// clear the table body
				$.tablesorter.clearTableBody(table);
				for (var i = s; i < e; i++) {
					//tableBody.append(rows[i]);
					var o = rows[i];
					var l = o.length;
					for (var j = 0; j < l; j++) {
						tableBody[0].appendChild(o[j]);
					}
				}
				fixPosition(table, tableBody);
				$(table).trigger("applyWidgets");
				if (c.page >= c.totalPages) {
					moveToLastPage(table);
				}
				$('.prev,.next', c.container).removeClass('inactive');
				if (c.page == 0) {
					$('.prev', c.container).addClass('inactive');
				}
				if (c.page == c.totalPages - 1) {
					$('.next', c.container).addClass('inactive');
				}
				updatePageDisplay(c);
			}

			function updatePageDesc(c) {
				var pagestring = $('.pagedisplay', c.container).val();
				var currentPage = c.page;
				$('a.pagenumber', c.container).removeClass('selected');
				$('a.pagenumber[data-page-number="' + currentPage + '"]', c.container).addClass('selected');
				var totalPages = parseInt(pagestring.substring(pagestring.indexOf('/') + 1));
				var currentSize = c.size;
				var currentPos = (currentPage * currentSize);
				var totalRows = c.totalRows;
				$('.pagedesc', c.container).html((currentPos + 1) + ' - ' + (currentPos + currentSize > totalRows ? totalRows : currentPos + currentSize) + ' of ' + totalRows);
			}

			function updatePages(c) {
				var pagestring = $('.pagedisplay', c.container).val();
				if (pagestring) {
					var totalPages = parseInt(pagestring.substring(pagestring.indexOf('/') + 1));
					$('.pages', c.container).remove();
					if (totalPages > 1) {
						$('.prev', c.container).before('<span class="pages">&nbsp;&nbsp;</span>');
						for (var i = 0; i < totalPages; i++) {
							var link = $('<a href="#" class="pagenumber" data-page-number="' + i + '">' + (i + 1) + '</a>');
							$('.pages', c.container).append(link);
							$('.pages', c.container).append('&nbsp;&nbsp;');
						}
					} else {
						$('.prev, .next, .pipe', c.container).hide();
					}
					updatePageDesc(c);
					$('.pagedisplay', c.container).change(function () { updatePageDesc(c); });
				}
			}
			this.appendFunc = function (table, rows) {
				var c = table.config;
				c.rowsCopy = rows;
				c.totalRows = rows.length;
				c.totalPages = Math.ceil(c.totalRows / c.size);
				renderTable(table, rows);
			};
			this.defaults = {
				size: 10,
				offset: 0,
				page: 0,
				totalRows: 0,
				totalPages: 0,
				container: null,
				cssNext: '.next',
				cssPrev: '.prev',
				cssFirst: '.first',
				cssLast: '.last',
				cssPageDisplay: '.pagedisplay',
				cssPageSize: '.pagesize',
				cssPageNumber: '.pagenumber',
				dataPageNumber: 'data-page-number',
				seperator: "/",
				positionFixed: true,
				appender: this.appendFunc
			};
			this.construct = function(settings) {
				return this.each(function () {
					let config = $.extend(this.config, $.tablesorterPager.defaults, settings);
					config.size = parseInt($(".pagesize", pager).val());
					var table = this,
						pager = config.container;
					$(this).trigger("appendCache");
					$(config.cssFirst, pager).click(function () {
						moveToFirstPage(table);
						return false;
					});
					$(config.cssNext, pager).click(function () {
						moveToNextPage(table);
						return false;
					});
					$(config.cssPrev, pager).click(function () {
						moveToPrevPage(table);
						return false;
					});
					$(config.cssLast, pager).click(function () {
						moveToLastPage(table);
						return false;
					});
					$(pager).on('click', config.cssPageNumber, function () {
						table.config.page = parseInt($(this).attr(config.dataPageNumber));
						moveToPage(table);
						return false;
					});
					$(config.cssPageSize, pager).change(function () {
						setPageSize(table, parseInt($(this).val()));
						return false;
					});
					$(table).bind('removeRow', function (event, row) {
						//find the row in the cache, remove it from there,
						//then remove it from the dom.
						let i;
						for (i in table.config.rowsCopy) {
							if ($(row).is(table.config.rowsCopy[i])) { table.config.rowsCopy.splice(i, 1); }
						}
						$(row).remove();
						table.config.totalRows = table.config.rowsCopy.length;
						table.config.totalPages = Math.ceil(table.config.totalRows / table.config.size);
						renderTable(table, table.config.rowsCopy);
					});
					// Note: This will need to be updated if we ever need to
					// support multiple pagers on the same page.
					$.tablesorterPager.config = config;
				});
			};
		}
	});
	// extend plugin scope
	$.fn.extend({
		tablesorterPager: $.tablesorterPager.construct
	});
})(jQuery);
