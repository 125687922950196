import moment from 'moment';
import { findOne } from 'dynamic-form';

const message = $.__('Beginning date must be before end date.');

export const validateDateFrom_to = (context: DynamicForm.Context, field: DynamicForm.Data, params: any) => {
	return (value: string): boolean | string => {
		const {
			props,
		} = field;
		if (value === '') {
			return true;
		}

		const calendarId = props['data-calendar-id'] || null;
		const fromField = findOne(context, { selector: 'props.data-calendar-id', value: calendarId, match: 'exact' });

		if (!fromField) {
			return false;
		}

		const toDate = moment(value);
		const fromDate = moment(fromField.props.value.toString());

		const isValid = (
			toDate.isValid() &&
			fromDate.isValid() &&
			toDate.isAfter(fromDate, 'day')
		);

		return isValid || message;
	};
};
