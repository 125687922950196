import { createStyles, makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ spacing }) => {
	return createStyles({
		marginBottom: {
			marginBottom: spacing(4),
		},
		stopModalContent: {
			marginBottom: spacing(2),
		},
	});
});
