import React from 'react';

import { Select } from '@fabric/select';

import { generateAcaStatusSelectItems } from './utils';
import { ACA_TYPES } from './constants';

import './aca-status-select.styl';
import { BodyText, Label, LayoutBox } from '@bamboohr/fabric';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';

/**
 * @param {array} props.allowedAcaStatuses
 * @param {boolean|null} props.hasError
 * @param {string|null} props.id
 * @param {function} props.onSelect
 * @param {function|null} props.onChange
 * @param {string|null} props.selectedValue
 * @param {boolean} props.showLabel
 */
export function AcaStatusSelect(props) {
	const {
		allowedAcaStatuses,
		hasError = false,
		id,
		onSelect,
		onChange,
		selectedValue,
		showLabel = false,
	} = props;

	const selectedAcaStatus = selectedValue ? ACA_TYPES[selectedValue] : null;

	return (
		<React.Fragment>
			{ showLabel && (
			<LayoutBox component='div' marginBottom={0.25}>
				<Label
					component="span"
					required={true}
					status={hasError ? 'error' : undefined}
				>
					{ $.__('ACA Status') }
				</Label>
			</LayoutBox>
			) }
			<Select
				condition={ hasError ? 'error' : '' }
				id={ id }
				isClearable={ false }
				items={ generateAcaStatusSelectItems(allowedAcaStatuses) }
				onChange={ onChange }
				onSelect={ onSelect }
				selectedValues={ [selectedValue] }
				width="9"
			/>
			{ selectedAcaStatus && (
				<LayoutBox marginTop={1}>
					<BodyText color="neutral-weak" component="p" id='selected-aca-status-description' size='small' weight='regular'>
						<Message
							link={ExternalLink(`${selectedAcaStatus.url}`)}
							text={ `${selectedAcaStatus.description } ${selectedAcaStatus.url ? '[See here for more details.]' : ''}` }
						/>
					</BodyText>
				</LayoutBox>
			)}
		</React.Fragment>
	);
}
