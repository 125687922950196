import React from 'react';

import {
	Message,
} from '@bamboohr/utils/lib/message';

import './wire-transfer-info.styl';

export function WireTransferInfo(): JSX.Element {
	return (
		<table className="WireTransferInfo">
			<tbody>
				<tr>
					<td>{ $.__('Bank:') }</td>
					<td>Wells Fargo</td>
				</tr>
				<tr>
					<td>{ $.__('Routing Number:') }</td>
					<td>121000248</td>
				</tr>
				<tr>
					<td>{ $.__('Account Number:') }</td>
					<td>3711853659</td>
				</tr>
				<tr>
					<td>{ $.__('Bank Address:') }</td>
					<td>420 Montgomery, San Francisco, CA 94104</td>
				</tr>
				<tr>
					<td>{ $.__('Beneficiary Name:') }</td>
					<td>BambooHR Payroll</td>
				</tr>
				<tr>
					<td>{ $.__('Beneficiary Address:') }</td>
					<td>740 Alfred Nobel Dr., Hercules, CA 94547</td>
				</tr>
				<tr>
					<td>
						<Message
							params={ ['CHIPS'] }
							text={ $._('{1} Participant ID:') }
						/>
					</td>
					<td>ABA 0509</td>
				</tr>
			</tbody>
		</table>
	);
}
