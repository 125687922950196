import { useState, useMemo, ReactElement, useRef, useEffect } from 'react';
import { getIconNameFromFileName } from 'BambooHR.util';
import moment from 'moment.lib';
import classNames from 'classnames';
import { FileDetails, SelectedFiles } from '../../types';
import useStyles from './file-list-item.styles';
import { Checkbox, FileIcon, Flex, Icon, RequiredToggle, Tooltip } from '@bamboohr/fabric';
import FileCard from 'file-card.mod';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface Props {
	file: FileDetails;
	handleFileSelection: (file: SelectedFiles, checked: boolean) => void
	handleToggleRequired: (fileId: string, checked: boolean) => void
	editFileProperty: (fileId: string, property: string, value: string) => void
	tooltipScrollOffset: number
}

export function FileListItem(props: Props): ReactElement {
	const tooltipAnchor = useRef(null);
	const { file, handleFileSelection, handleToggleRequired, tooltipScrollOffset, editFileProperty } = props;

	const styles = useStyles({ offset: tooltipScrollOffset });
	const [isSelected, setIsSelected] = useState(file.meta.checked || false);
	const [isRequired, setIsRequired] = useState(file.meta.required === 'yes');
	
	const hasEsignatures = useMemo(() => {
		return file.esignature_template_id !== null &&
		file.has_esignatures &&
		file.esignatures.has_fields;
	}, [file.esignature_template_id, file.has_esignatures, file.esignatures.has_fields]);

	// The fileIdString is split up as `{fileId}-{isEsigTemplate}-{isRequired}`
	// This only applies to selected files, we should not change the id of the original files
	const fileIdString = useMemo(() => {
		const isEsig = hasEsignatures ? 'yes' : 'no'
		const isEsigRequired = isRequired ? 'yes' : 'no'

		return `${file.id}-${isEsig}-${isEsigRequired}`
	}, [hasEsignatures, isRequired]);

	const handleSelectionChange = () => {
		if (!file?.meta?.disabled) {
			handleFileSelection(
				{
					...FileCard.fileCardFromFile(file),
					required: isRequired ? 'yes' : 'no',
				},
				!isSelected,
			);
			setIsSelected(!isSelected);
		}
	};

	const handleRequiredChange = () => {
		handleToggleRequired(file.id, !isRequired);
		setIsRequired(!isRequired);
	};

	useEffect(() => {
		if(isSelected){
			editFileProperty(file.id, 'id', fileIdString);
		}
	}, [fileIdString, isSelected])


	const iconName = (file.img_sizes.medium === 'esignature.png')
		? 'fab-file-esig-20x24'
		: getIconNameFromFileName(file.original_file_name, '20x24', false);

	const rowClasses = classNames(styles.fileListItem, {
		[styles.active]: isSelected,
		[styles.disabled]: file.meta.disabled,
		'show-esignature-template': file.esignature_template_id && file.esignatures && file.esignatures.has_fields,
	});

	const fileCreated = moment(file.created).format(moment.convert.jQuery(window.GLOBAL_DATEPICKER_MASK));
	let createdStr;

	if (file.created_str) {
		createdStr = file.created_str;
	} else if (file.created_user_id > 0) {
		createdStr = $.__('Created %1$s by %2$s', fileCreated, file.created_user);
	} else {
		createdStr = $.__('Created %1$s', fileCreated);
	}

	const selectionCheckboxId = `check${ file.id }-${ hasEsignatures ? 'yes' : 'no' }-${ isRequired }`;
	const requiredCheckboxId = `${ file.id }-toggle-${ isRequired }`;
	return (
		<>
			<li>
				{ifFeature(
					'encore',
					<div
						className={ rowClasses }
						onKeyDown={ () => null }
						role="button"
						tabIndex={ 0 }
					>
						<Flex flex={1} maxWidth={'calc(100% - 54px)'} onClick={handleSelectionChange}>
							<div className={ classNames(styles.check) }>
								<Checkbox
									checked={ isSelected }
									disabled={ file.meta.disabled }
									id={ selectionCheckboxId } 
									onChange={(p, e) => {
											e.preventDefault();
											e.stopPropagation();
										}}
									required={ isRequired }
									value=''
								/>								
							</div>
							<div className={ classNames(styles.icon) }>
								<FileIcon
									filename={file.original_file_name}
									size={'small'}
									type={hasEsignatures ? 'esig' : undefined}
								/>
							</div>
							<div className={ classNames(styles.info) }>
								<span className={ classNames(styles.infoLine, styles.infoTitle, 'truncate') }>{ file.name }</span>
								<span className="hidden">{ file.can_see_file }</span>
								<span className={ classNames(styles.infoLine, styles.infoInfo) }>
									{ `${ createdStr } (${ window.BambooHR.Utils.humanFileSize(file.size) })` }
								</span>
							</div>
						</Flex>
						{ hasEsignatures && (
							<div className={ classNames(styles.actions) }>
								<Tooltip
									content={ $.__('Click to make signature %1', isRequired ? 'optional' : 'required') }
									title={ $.__('Signature %1', isRequired ? 'required' : 'optional') }
								>
									<RequiredToggle
										id={ requiredCheckboxId }
										isChecked={isRequired}
										onChange={handleRequiredChange}
									/>	
								</Tooltip>
							</div>
						)}
					</div>,
					<div
						className={ rowClasses }
						onClick={(e) => {
							const target = e.target as HTMLElement;
							if (!target.classList.contains('bhrToggle__input') && !target.classList.contains('bhrToggle__label')) {
								handleSelectionChange();
							}
						}}
						onKeyDown={ () => null }
						role="button"
						tabIndex={ 0 }
					>
						<div className={ classNames(styles.check) }>
							<div className="fab-Checkbox">
								<input
									checked={ isSelected }
									className="fab-Checkbox__input"
									data-can-see={ file.can_see_file }
									data-ext={ (hasEsignatures) ? $.__('Esignature') : file.ext }
									data-file-id={ file.id }
									data-icon={ file.img_sizes.small }
									data-name={ file.name }
									data-original-name={ file.original_file_name }
									data-required={ isRequired }
									disabled={ file.meta.disabled }
									id={ selectionCheckboxId }
									name=""
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
									}}
									readOnly={ true }
									type="checkbox"
								/>
								<span className="fab-Checkbox__box"></span>
								<label
									className="fab-Checkbox__label"
									data-tip-content={ file.meta.disabled ? file.meta.disabledText : '' }
									htmlFor={ selectionCheckboxId }
								>
								</label>
							</div>
						</div>
						<div className={ classNames(styles.icon) }>
							<Icon name={iconName}/>
						</div>
						<div className={ classNames(styles.info) }>
							<span className={ classNames(styles.infoLine, styles.infoTitle, 'truncate') }>{ file.name }</span>
							<span className="hidden">{ file.can_see_file }</span>
							<span className={ classNames(styles.infoLine, styles.infoInfo) }>
								{ `${ createdStr } (${ window.BambooHR.Utils.humanFileSize(file.size) })` }
							</span>
						</div>
						{ (hasEsignatures) && (
							<div className={ classNames(styles.actions) }>
								<Tooltip
									classes={ { root: styles.tooltip, tooltip: styles.tooltipPosition } }
									content={ $.__('Click to make signature %1', isRequired ? 'optional' : 'required') }
									title={ $.__('Signature %1', isRequired ? 'required' : 'optional') }
								>
									<span
										className="bhrTooltip"
										data-required-action={ file.id }
										ref={ tooltipAnchor }
									>
										<label
											className="bhrToggle bhrToggle--require"
											htmlFor={ requiredCheckboxId }
										>
											<input
												checked={ isRequired }
												className="bhrToggle__input"
												data-file-id={ file.id }
												id={ requiredCheckboxId }
												onChange={ handleRequiredChange }
												type="checkbox"
											/>
											<span className="bhrToggle__label"></span>
											<span className="bhrToggle__handle"></span>
										</label>
									</span>
								</Tooltip>
							</div>
						) }
					</div>
				)}
			</li>
		</>
	);

}
