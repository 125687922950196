import './styles.styl';

export const basic = {
	name: 'basic',
	hiddenParts: ['secondaryText'],
	extraClasses: {
		tabIcon: ({active}) => ({
			baseFillColor: active,
		}),
	},
};
