import { FunctionComponent } from 'react';

import { RadioGroup } from '@bamboohr/fabric';

const TaxDocumentDelivery: FunctionComponent = () => {
	return (
		<RadioGroup
			ariaLabel={$.__('Tax Document Delivery')}
			items={[
				{
					id: 'paperless',
					label: $.__(`Paperless - I prefer to download and print my year end tax documents (W2 or 1099) by logging into BambooHR.`),
					value: 'false',
				},
				{
					id: 'paper',
					label: $.__(`Paper - I'd like to have a paper copy of my year end tax documents (W2 or 1099) mailed to my home address.`),
					value: 'true',
				},
			]}
			name='paperTaxDocuments'
			onChange={() => {
				return 1;
			}}
			value='false'
		/>
	);
};

export default TaxDocumentDelivery;
