import { FC } from 'react';
import { BlankState, Button, makeStyles } from '@bamboohr/fabric';
import { EMERGENCY_CONTACT_BLANK_TITLE, ADD_CONTACT, EmergencyBlankStateProps } from './constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/* @startCleanup encore */
const useBlankStateStyles = makeStyles((theme) => ({
	icon: {
		fill: theme.palette.gray[600],
	},
	root: {
		marginTop: '72px',
	},
	subtitle: {
		color: theme.palette.gray[600],
	},
	title: {
		color: theme.palette.gray[600],
	},
}));
/* @endCleanup encore */

export const EmergencyBlankDefault: FC<EmergencyBlankStateProps> = ({ onClick, canAdd }) => {
	/* @startCleanup encore */
	const blankStateClasses = useBlankStateStyles();
	/* @endCleanup encore */
	return (
		<BlankState
			/* @startCleanup encore */
			classes={blankStateClasses}
			/* @endCleanup encore */
			icon={ifFeature('encore', 'bs-doc-missing-field', 'fab-ambulance-120x100')}
			subtitle=''
			title={EMERGENCY_CONTACT_BLANK_TITLE}
			actions={ifFeature(
				'encore',
				canAdd && [
					<Button
						color='secondary'
						data-bi-id='employees-contacts-add-contact'
						onClick={onClick}
						startIcon='plus-regular'
						type='button'
					>
						{ADD_CONTACT}
					</Button>,
				]
			)}
		>
			{/* @startCleanup encore */}
			{ifFeature(
				'encore',
				undefined,
				canAdd && (
					<Button
						data-bi-id='employees-contacts-add-contact'
						onClick={onClick}
						outline={true}
						startIcon='fab-circle-plus-16x16'
						text={`${ADD_CONTACT}`}
						type='button'
					/>
				)
			)}
			{/* @endCleanup encore */}
		</BlankState>
	);
};
