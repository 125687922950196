import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import ReplyCreator from '../../containers/reply-creator';
import Item from '../item';
import ReplyList from '../reply-list';

import './styles.styl';

const Comment = (props) => (
	<li className={`FeedListItem ${props.item.isPinned ? 'FeedListItem--pinned' : ''}`}>
		{/* This feature doesn't appear to be used currently, it's broken in Jade, so any use in Encore will need UX to review.  */}
		{props.item.isPinned && (
				<span className='FeedListItem__pinnedIcon'>
					{ifFeature(
						'encore',
						<IconV2 name='thumbtack-solid' size={16} color={'primary-strong'} />,
						<Icon brand={true} name='fab-thumbtack-16x24' />
					)}
				</span>
			) &&
			console.warn('This feature requires UX review.')}
		<Item {...props} />
		{props.item.eventId && props.config.externalRender(props.item.eventId)}
		{props.config.enableReply && <ReplyList ids={props.item.replies} timezones={props.timezones} />}
		{!props.config.readOnly && props.config.enableReply && <ReplyCreator inReplyTo={props.item.id} />}
	</li>
);

export default Comment;
