/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import './styles.styl';
import { sanitizeDangerousContent } from './utils';

export const ConfirmationContent = function ConfirmationContent(props) {
	const {
		aggressiveConfirmationText,
		dangerousContent,
		handleAggressiveConfirmationInput,
		normalizedContent,
	} = props;

	return (
		<Fragment>
			{ (dangerousContent || normalizedContent) && (
				<div
					className={ 'legacyModal__note' }
					dangerouslySetInnerHTML={ dangerousContent ? {__html: sanitizeDangerousContent(dangerousContent)} : null }
				>
					{ (normalizedContent && !dangerousContent) ? normalizedContent : null }
				</div>
			) }
			<div
				className={ 'legacyModal__delete' }
				dangerouslySetInnerHTML={ typeof aggressiveConfirmationText === 'string' ? {__html: aggressiveConfirmationText} : null }
			>
				{ typeof aggressiveConfirmationText === 'string' ? null : aggressiveConfirmationText }
			</div>
			<input className="fab-TextInput fab-TextInput--width4" onChange={ handleAggressiveConfirmationInput } />
		</Fragment>
	);
}
