import { cloneDeep } from 'lodash';

import { Question, Recipients } from 'NHPTemplates.mod/Form/Interfaces';

import { markFormAsChanged } from 'Form.util';

export interface TemplateData {
	arriveByTime?: Array<string>;
	selectedContactOptions?: number | string;
	id?: number;
	location?: string;
	name?: string;
	otherInstructions?: string;
	gtkyQuestions?: Array<Question>;
	gtkyRecipients?: Array<Recipients>;
	sendGetToKnowYouEmail?: boolean | 'yes' | 'no';
}

export interface TemplateSaveData {
	arriveByTime: string;
	contactEmployeeId: number | string;
	id: number;
	location: string;
	name: string;
	otherInstructions: string;
	gtkyQuestions: Array<Question>;
	gtkyRecipients: Array<Recipients>;
	sendGetToKnowYouEmail: boolean;
}

export interface NewHirePacketSaveData extends TemplateSaveData{
	hireDate: string;
	managerId: string;
}

export class BaseFormData {
	selectedArrivalTime: Array<string>;

	selectedContactOptions: Array<number|string>;

	id: number;

	location: string;

	name: string;

	otherInstructions: string;

	gtkyQuestions: Array<Question>;

	gtkyRecipients: Array<Recipients>;

	sendGetToKnowYouEmail: boolean;

	// TODO: Remove during NHP Tasks cleanup
	hireDate: string;

	selectedManagerOptions: Array<string>;
	// end remove

	constructor(initialFormData: TemplateData) {
		const {
			arriveByTime = ['08:00:00'],
			selectedContactOptions = null,
			id,
			location = '',
			name,
			otherInstructions = '',
			gtkyQuestions = [],
			gtkyRecipients = [],
			sendGetToKnowYouEmail = false,
		} = initialFormData;

		this.selectedArrivalTime = arriveByTime;
		this.selectedContactOptions = [selectedContactOptions];
		this.id = id;
		this.location = location;
		this.name = name;
		this.otherInstructions = otherInstructions;
		this.gtkyQuestions = gtkyQuestions;
		this.gtkyRecipients = gtkyRecipients;
		this.sendGetToKnowYouEmail = sendGetToKnowYouEmail === true || sendGetToKnowYouEmail === 'yes';
		// TODO: Remove during NHP Tasks cleanup
		this.selectedManagerOptions = [];
	}

	assignQuestionSortOrderByIndex(): Array<Question> {
		return this.gtkyQuestions.map((question, index): Question => {
			question.sortOrder = index;
			return question;
		});
	}

	updateData(updatedData: TemplateData, isInitialData = false): void {
		if (!isInitialData) {
			markFormAsChanged();
		}
		// @ts-ignore
		const updatedHireDate = updatedData.hireDate && (updatedData.hireDate != this.hireDate);

		const clonedData = cloneDeep(updatedData);
		const keys = Object.keys(clonedData);

		for (let i = 0; i < keys.length; i++) {
			this[keys[i]] = clonedData[keys[i]];
		}

		if (updatedHireDate) {
			document.dispatchEvent(new Event('nhpForm:formUpdate'));
		}
	}
}
