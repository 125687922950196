import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Modal } from 'modal-legacy';
const justChecking = $.__('Just Checking...');

export const deleteModal = (props, toggleModal) => {
	const {
		item,
		actions,
		title,
		message,
		visible
	} = props;

	const options = {
		type: 'small',
		title: justChecking,
		icon: ifFeature('encore', 'trash-can-regular', 'fab-trash-can-43x48'),
		iconColor: ifFeature('encore', undefined, 'danger'),
		iconV2Color: ifFeature('encore', 'error-strong'),
		headline: title,
		onClose: () => { toggleModal(false); },
		primaryAction: () => { actions.deleteComment(item.id); },
		primaryActionText: $.__('Yes, Delete Comment'),
		content: message
	};

	return (
		<div>
			<Modal {...options} isOpen={visible} />
		</div>
	);
};
