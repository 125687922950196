import { validateEmail } from 'BambooHR.util';
import { PoMicroFrontend } from 'micro-frontend.react';
import { render } from 'base/wrapped-render';
import ReactDOM from 'react-dom';
import Ajax from '@utils/ajax';
import { emailModal } from '../selector/templates';

export const openManageAccessModal = (userId, employeeId, onSuccess) => {
    const rootElement = document.createElement('div');
    document.body.appendChild(rootElement);
    render(
        <PoMicroFrontend
            employeeId={employeeId}
            onClose={(changesSavedSuccessfully: boolean) => {
                if (changesSavedSuccessfully && typeof onSuccess === 'function') {
                    onSuccess();
                }
                ReactDOM.unmountComponentAtNode(rootElement);
                document.body.removeChild(rootElement);
            }}
            route="/controls/access-levels-assignment-modal"
            userId={userId}
        />,
        rootElement
    );
};

/*
 * more generic version of requireEmail from AccessLevelSelector
 */
export const requireEmail = ({
     firstName,
     userId,
     employeeId,
     onClose,
}) => {
    const content = emailModal({user: {firstName}, hasEmployee: !!employeeId});
    const handleClose = (emailWasSaved) => {
        if (typeof onClose === 'function') {
            onClose(emailWasSaved);
        }
        window.BambooHR.Modal.setState({isOpen: false});
    }

    window.BambooHR.Modal.setState({
        alternativeAction: () => handleClose(false),
        dangerousContent: content,
        isOpen: true,
        primaryAction: () => {
            const $form = $('#access-levels-require-email-form');
            const $fields = $form.find('input[type="text"]');
            const valid = $fields.toArray().some((field: HTMLInputElement) => validateEmail(field.value));

            if (!valid) {
                $form.find('label').addClass('fab-Label--error');
                window.setMessage($.__('Please enter a valid email address'), 'error');
                return;
            }

            $form.find('label').removeClass('fab-Label--error');
            window.BambooHR.Modal.setState({isProcessing: true}, true);
            Ajax.post(
                employeeId ? `/employees/updateEmails/${ employeeId }` : `/settings/users/edit/${ userId }`,
                $form.serialize()
            ).then(({ data }) => {
                if (!data.success) {
                    const {
                        error,
                        errorMessage
                    } = data;
                    window.BambooHR.Modal.setState({isProcessing: false}, true);
                    window.setMessage(errorMessage, 'error');
                    console.error(error);
                    return;
                }

                window.setMessage($.__('Email for %s saved successfully', firstName), 'success');
                handleClose(true);
            });
        },
        primaryActionText: $.__('Save'),
        title: $.__('Add Email for %s', firstName),
    });
};
