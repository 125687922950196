import moment from 'moment.lib';
import i18next from 'i18next.lib';
import {
	deburr
} from 'lodash';

let Inputmask = window.Inputmask;
let _locale = moment.localeData();
let _months = _locale.monthsShort();

i18next.on('languageChanged', (lang) => {
	_locale = moment.localeData();
	_months = _locale.monthsShort();
});


Inputmask.extendDefaults({
	showMaskOnHover: false,
	colorMask: true
});


function cleanStr(str) {
	return deburr(String(str).trim().toLowerCase());
}

function monthExists(str) {
	str = cleanStr(str);

	return (
		_months.filter(month => cleanStr(month).substring(0, str.length) === str)
			.length > 0
	);
}

function buildStr(buffer, chrs, pos) {
	if (Array.isArray(buffer)) {
		buffer = buffer.join('');
	}

	return [
		buffer.slice(0, pos),
		chrs,
		buffer.slice(pos)
	].join('');
}

Inputmask.extendAliases({
	'mm/dd/yy': {
		mask: 'm/d/99',
		placeholder: 'mm/dd/yy'
	},
	'dd/mm/yy': {
		mask: 'd/m/99',
		placeholder: 'dd/mm/yy'
	},
	'yy-mm-dd': {
		mask: '99-m-d',
		placeholder: 'yy-mm-dd'
	},
	'dd MMM YYYY': {
		mask: 'd M{+} y',
		placeholder: 'ddyyyyyyyyyyy',
		greedy: false,
		separator: ' ',
		skipOptionalPartCharacter: ' ',
		keepStatic: true,
		isComplete(buffer, opts) {
			return moment(buffer.join(''), 'DD MMM YYYY', true).isValid();
		},
		definitions: {
			M: {
				cardinality: 1,
				placeholder: 'mon',
				validator(chrs, maskset, pos) {
					if (chrs === ' ') {
						return false;
					}

					let str = buildStr(maskset.buffer, chrs, pos);

					let month = str.slice('dd '.length, -(' yyyy'.length));

					if (month.length == 4) {
						month = month.replace(/mon$/, '');
					}


					return monthExists(month);
				}
			}
		}
	},
	'MMM YYYY': {
		mask: 'M{+} y',
		placeholder: 'yyyyyyyyyyy',
		greedy: false,
		separator: ' ',
		skipOptionalPartCharacter: ' ',
		keepStatic: true,
		isComplete(buffer, opts) {
			return moment(buffer.join(''), 'MMM YYYY', true).isValid();
		},
		definitions: {
			M: {
				cardinality: 1,
				placeholder: 'mon',
				validator(chrs, maskset, pos) {
					if (chrs === ' ') {
						return false;
					}

					let str = buildStr(maskset.buffer, chrs, pos);

					let month = str.slice(0, -(' yyyy'.length));

					if (month.length == 4) {
						month = month.replace(/mon$/, '');
					}

					return monthExists(month);
				}
			}
		}
	}
});
