import { Icon, IconV2 } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import './emergency-contact-group.styl';

type Props = DynamicForm.FieldGroupElementProps & {
	handleRemove: (groupId: string) => void;
};

const EmergencyContactGroup: FunctionComponent<Props> = ({ props, settings, handleRemove, children }) => {
	const { canDelete = true } = settings;
	const { id, disabled } = props;

	// user can delete if BE permission, or if it was dynamically added by the FE.
	const canRemove: boolean = canDelete || id.includes('add');

	return (
		<div {...props}>
			<fieldset>
				{canRemove && (
					<button
						className='Contact__formDelete fab-FloatingIconButton fab-FloatingIconButton--secondary formDelete withIconLabel'
						disabled={disabled}
						onClick={(): void => {
							handleRemove(id);
						}}
						type='button'
					>
						<span className='fab-FloatingIconButton__icon'>
							<div className={classNames("phoneHide", {
								"deleteIconContainer": ifFeature('encore', true, false),
							})}>
								{ifFeature('encore', <IconV2 name='trash-can-regular' size={16} />, <Icon name='fab-trash-can-14x16' />)}
							</div>
						</span>
						<span className='fab-FloatingIconButton__icon'>
							<div className='hidden phoneOnly--inline-flex'>
								{ifFeature('encore', <IconV2 name='trash-can-regular' size={12} />, <Icon name='fab-trash-can-11x13' />)}
							</div>
						</span>
					</button>
				)}
				<input className='hidden' id={`id-0_${id}`} name={`id[${id}]`} type='hidden' value={id} />
				{children}
			</fieldset>
		</div>
	);
};

export default EmergencyContactGroup;
