import { NewHirePacketFormData } from './packetFormData';
import { NHPTemplateFormData } from './templateFormData';
import { PacketData, TemplateData } from './Interfaces';

export enum FORM_TYPES {
	PACKET = 'NEW_HIRE_PACKET',
	TEMPLATE = 'NHP_Template'
}

type FormData = NewHirePacketFormData | NHPTemplateFormData;

export function createStore(
	type = FORM_TYPES.TEMPLATE,
	initialFormData: PacketData | TemplateData
): FormData {
	switch (type) {
		case FORM_TYPES.PACKET:
			return new NewHirePacketFormData(initialFormData as unknown as PacketData);
		case FORM_TYPES.TEMPLATE:
		default:
			return new NHPTemplateFormData(initialFormData);
	}
}
