import './styles.styl';

export function Agreement(props) {
	const {
		isAccepted,
		onChange,
	} = props;

	return (
		<div className="DataProcessingAgreeementAgreement">
			<label className="fab-Checkbox">
				<input
					checked={ isAccepted }
					className="fab-Checkbox__input"
					onChange={ event => onChange(event.target.checked) }
					type="checkbox"
				/>
				<span className="fab-Checkbox__label">
					{ $.__('I agree to the Data Processing Agreement') }
				</span>
			</label>

			<div className="DataProcessingAgreeementAgreement__summary">
				<p>
					{ $.__(`By accepting the Data Processing Agreement (“DPA”), I confirm that I am duly authorized to represent my company and accept the DPA on its behalf and that my company has employees in the European Union, EEA, the United Kingdom or Switzerland.`) }
					&nbsp;
					<a
						className="DataProcessingAgreeementAgreement__link"
						href="https://www.bamboohr.com/data-processing-agreement/"
						rel="noopener noreferrer"
						target="_blank"
					>
						{ $.__('Read Full DPA') }
					</a>
				</p>
			</div>
		</div>
	);
}
