import { ReactElement } from 'react';
import { cloneDeep } from 'lodash';

import { Button, Flex, LayoutBox } from '@bamboohr/fabric/';

import { GtkyQuestion } from '../gtky-question';
import { useDragAndDrop } from 'drag-and-drop';

import { Question } from 'NHPTemplates.mod/Form/Interfaces';

import './styles.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface Props {
	customQuestions: { [key: string]: Question };
	updateQuestionsHandler: (questions: Array<Question>) => void;
}

export function GtkyQuestionList(props: Props): ReactElement {
	const { customQuestions } = props;

	const [questions, setQuestions, draggingQuestion, questionMethods] =
		useDragAndDrop(Object.values(customQuestions));

	if (questions[0].id === undefined) {
		setQuestions(
			questions.map((question, index): Question => {
				question.id = index;
				return question;
			}),
		);
	}

	function addQuestion(): void {
		const newQuestions = cloneDeep(questions);
		const highestId = Math.max(...newQuestions.map((e) => +e.id));
		const question: Question = {
			archived: '',
			id: highestId + 1,
			is_required: '',
			question: '',
			sort_order: `${highestId + 1}`,
		};

		newQuestions.push(question);

		updateQuestions(newQuestions);
	}

	function handleDeleteQuestion(position: number): void {
		const newQuestions = cloneDeep(questions);

		newQuestions.splice(position, 1);
		updateQuestions(newQuestions);
	}

	function handleOnDragEnd(): void {
		questionMethods.handleDragEnd();
		props.updateQuestionsHandler(questions);
	}
	function handleOnDragOver(event, questionId): void {
		questionMethods.handleDragOver(event, questionId);
	}
	function handleOnDragStart(event, questionId): void {
		questionMethods.handleDragStart(event, questionId);
		event.dataTransfer.setDragImage(
			event.target,
			event.nativeEvent.offsetX || 0,
			event.nativeEvent.offsetY || 0,
		);
	}

	function handleUpdateQuestion(
		index: number,
		question: string,
		required: '' | 'no' | 'yes',
	): void {
		const newQuestions = cloneDeep(questions);

		newQuestions[index].question = question;
		newQuestions[index].required = required === 'yes';
		newQuestions[index].sortOrder = index;
		updateQuestions(newQuestions);
	}

	function renderQuestions(
		question: Question,
		position: number,
		count: number,
	): ReactElement {
		return (
			<GtkyQuestion
				canDelete={count > 1}
				focusInput={
					count > 1 && position + 1 === count && question.question === ''
				}
				key={`cqWrapper-${question.id}${question.sort_order}`}
				onDelete={handleDeleteQuestion}
				onDragEnd={handleOnDragEnd}
				onDragOver={handleOnDragOver}
				onDragStart={handleOnDragStart}
				onUpdate={handleUpdateQuestion}
				position={position}
				questionData={question}
			/>
		);
	}

	function updateQuestions(questions: Array<Question>): void {
		setQuestions(questions);
		props.updateQuestionsHandler(questions);
	}

	return ifFeature(
		'encore',
		<LayoutBox>
			<Flex flexDirection="column" gap={1.75}>
				{questions.map((question, index) => {
					return renderQuestions(question, index, questions.length);
				})}
			</Flex>
			{questions.length < 9 && (
				<LayoutBox marginTop={1.5}>
					<Button
						color="secondary"
						onClick={addQuestion}
						size="medium"
						startIcon="circle-plus-regular"
						type="button"
						variant="contained"
					>
						{$.__('Add Question')}
					</Button>
				</LayoutBox>
			)}
		</LayoutBox>,
		<>
			<div className="fab-FormRow QuestionsList">
				<div className="QuestionsList__questions" id="customQuestionsWrapper">
					<p className="QuestionsList__questionsNote">
						{$.__('Get to Know You Questions')}
					</p>
					{questions.map((question, index) => {
						return renderQuestions(question, index, questions.length);
					})}
				</div>
			</div>
			{questions.length < 9 && (
				<div className="fab-FormRow fab-FormRow--tight QuestionsList__addQuestion">
					<Button
						clickAction={addQuestion}
						outline={true}
						size="small"
						text={$.__('Add Question')}
						type="button"
					/>
				</div>
			)}
		</>,
	);
}
