import { Dropdown, DropdownTypes, IconV2 } from '@bamboohr/fabric';
/* @startCleanup encore */
import { Gear14x13 } from '@bamboohr/grim';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { getItems } from './utils';
import { CancellationDropdownProps } from './types';

export const CancellationDropdown = ({ cancellationData, handleOnSelect, title, biId }: CancellationDropdownProps) => {
	/* @startCleanup encore */
	const jade = (
		<Dropdown
			biId={biId}
			buttonSettings={{
				ariaLabel: $.__('Cancellation'),
				color: 'secondary',
				startIcon: <Gear14x13 />,
				variant: 'outlined',
				size: 'teenie',
			}}
			items={getItems(cancellationData, title)}
			onSelect={handleOnSelect}
		/>
	);
	/* @endCleanup encore */

    return ifFeature(
        'encore',
        <Dropdown
            ButtonProps={{
                ariaLabel: $.__('Cancellation'),
                color: 'secondary',
                icon: 'gear-regular',
                size: 'small',
                variant: 'outlined',
            }}
            items={getItems(cancellationData, title)}
            onSelect={handleOnSelect}
            type={DropdownTypes.ICON}
        />,
        jade,
    );
};
