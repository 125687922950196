/**
 * Primary Button Selectors
 *
 * @type Object
 */
var selectors = {
	button: '.js-simplemodal-cancel'
};

/**
 * Simple Modal Footer Buttons Cancel Object
 */
var Cancel = {
	/**
	 * Clear the button's action
	 *
	 * @param {wrapper} $modal (Optional) The modal to get the attribute for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	clearAction($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).off('click');
	},

	/**
	 * Clear the key code for performing this button's click action
	 *
	 * @param {wrapper} $modal (Optional) The modal to get the attribute for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	clearPerformActionOnKeyCode($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$(document).off('keyup' + selectors.button + $modal.selector);
	},

	/**
	 * Trigger the click event for the button
	 *
	 * @param {wrapper} $modal (Optional) The modal to get the attribute for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	click($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		if (this.isEnabled($modal)) {
			var $button = $modal.find(selectors.button);
			if ($button.length > 0 && $button.is(':visible')) {
				$button.click();
			}
		}
	},

	/**
	 * Disable the button
	 *
	 * @param {wrapper} $modal (Optional) The modal to get the attribute for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	disable($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).attr('disabled', 'disabled').prop('disabled', true);
	},

	/**
	 * Enable the button
	 *
	 * @param {wrapper} $modal (Optional) The modal to get the attribute for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	enable($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).removeAttr('disabled').prop('disabled', false);
	},

	/**
	 * Get an attribute on the button
	 *
	 * @param {string}  attrName The attribute name to get
	 * @param {wrapper} $modal   (Optional) The modal to get the attribute for (if not passed, default is the top-most modal)
	 *
	 * @returns {mixed}
	 */
	getAttr(attrName, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		return $modal.find(selectors.button).attr(attrName);
	},

	/**
	 * Get the text for the button
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	getText($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		return $modal.find(selectors.button).text();
	},

	/**
	 * Hide the button
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	hide($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).hide();
	},

	/**
	 * Check if the button is disabled
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {Boolean}
	 */
	isDisabled($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		var $button = $modal.find(selectors.button);
		if ($button.length > 0 && $button.is(':visible')) {
			return $button.is(':disabled');
		}
		return true;
	},

	/**
	 * Check if the button is enabled
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {Boolean}
	 */
	isEnabled($modal) {
		return !this.isDisabled($modal);
	},

	/**
	 * Set an attribute on the button
	 *
	 * @param {string}  attrName The attribute name to set
	 * @param {mixed}   value    The value for the attribute
	 * @param {wrapper} $modal   (Optional) The modal to get the attribute for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	setAttr(attrName, value, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).attr(attrName, value);
	},

	/**
	 * Set the text for the button
	 *
	 * @param {string}  text   The text you want to set
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	setText(text, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).text(text);
	},

	/**
	 * Show the button
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	show($modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).show();
	},

	/**
	 * Update the button's action
	 *
	 * @param {Function} fn     The function to execute on click
	 * @param {wrapper}  $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	updateAction(fn, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		this.clearAction($modal);
		$modal.find(selectors.button).on('click', fn);
	},

	/**
	 * Update the key code for performing this button's click action
	 *
	 * @param {int}     keyCode The key code to perform the button action with
	 * @param {wrapper} $modal  (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	updatePerformActionOnKeyCode(keyCode, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		this.clearPerformActionOnKeyCode($modal);
		$(document).on('keyup' + selectors.button + $modal.selector, function(e) {
			if (e.which === keyCode) {
				e.stopPropagation(); //Stop propagation only in case the key is alphanumeric.
				this.click($modal);
			}
		});
	},

	/**
	 * add class to button
	 *
	 * @param {string}     css class to be added
	 * @param {wrapper} $modal  (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	addClass(cssClass, $modal) {
		$modal = window.SimpleModal._verifyModalObject($modal);
		$modal.find(selectors.button).addClass(cssClass);
	}
};


export default Cancel;
