import { format, isValid } from '@utils/datetime';

export function NoFacadeComponent({ dateString, name }: { dateString: string; name: string }) {
	// Need to add the time stamp so that date won't convert the passed in date to the local timezone
	const currentDate = new Date(`${dateString}T00:00:00`);

	return (
		<input
			name={name}
			type='hidden'
			value={isValid(currentDate) ? format(currentDate, window.dateFnsDefaultFormat) : ''}
		/>
	);
}
