import { LayoutBox, Section } from '@bamboohr/fabric';
import { type ReactNode } from 'react';

import './section-styled-box.styl';

interface SectionStyledBoxProps {
	children: ReactNode;
	hidden?: boolean;
	usesSectionComponent?: boolean;
}

export function SectionStyledBox(props: SectionStyledBoxProps) {
	const { children, hidden = false, usesSectionComponent = false } = props;

	if (usesSectionComponent) {
		return (
			<LayoutBox hidden={hidden}>
				<Section>{children}</Section>
			</LayoutBox>
		);
	}

	return (
		<div className="SectionStyledBox">
			<LayoutBox paddingTop={2}>{children}</LayoutBox>
		</div>
	);
}
