import { Label, TextField } from '@bamboohr/fabric';
import React, { FocusEvent, ReactElement, useState } from 'react';

import { formatUsdInputValue, validateHelpData } from './optional-usd-input.domain';
import { useStyles } from './optional-usd-input.styles';
import { OptionalUsdInputProps } from './optional-usd-input.types';
import { QuestionPopover } from '../question-popover/question-popover';

export function OptionalUsdInput(props: OptionalUsdInputProps): ReactElement {
	const { data, help, id, isExemption, viewOnly } = props;

	const styles = useStyles();
	const [value, setValue] = useState(formatUsdInputValue(data.amount || ''));

	const hasHelp = validateHelpData(help);
	const isDisabled = !!viewOnly;

	return (
		<div className="fieldRow">
			<div className={styles.labelContainer}>
				<Label disabled={isDisabled} htmlFor={id}>{data.label}</Label>
				{hasHelp && (
					<div className={styles.questionContainer}>
						<QuestionPopover
							content={help.text}
							title={help.title}
						/>
					</div>
				)}
			</div>
			<TextField
				disabled={isDisabled}
				id={id}
				name={`state[optional][${id}]`}
				note={data.note}
				onBlur={handleBlur}
				onChange={(e) => setValue(e.target.value)}
				placeholder={isDisabled && isExemption ? 'NA' : undefined}
				size="medium"
				value={isDisabled && isExemption ? formatUsdInputValue(''): value}
				width={5}
			/>
		</div>
	)

	function handleBlur(e: FocusEvent<HTMLInputElement>) {
		const inputValue = e.target.value;

		if (!inputValue.trim()) {
			setValue('');
			return;
		}

		const parsedValue = window.getFloatFromStringJS(inputValue.replace('$', '').replace('%', ''));

		const min = data.range?.min === undefined ? undefined : Number(data.range.min);
		const max = data.range?.max === undefined ? undefined : Number(data.range.max);
		
		setValue(formatUsdInputValue(parsedValue.toString(), min, max));
	}
}
