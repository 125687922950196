import { isEnabled } from 'FeatureToggle.util';
import { isWidthBelowSupportedMinWidth } from 'BambooHR.util';
import { ifFeature } from "@bamboohr/utils/lib/feature";

(function ($, moment) {

	const JADE_ENABLED = isEnabled('jade');

	var Util = {
		/**
		 * Returns true if the tick is between minTick and maxTick.
		 * If no minTick is specified it will return true if tick is before maxTick
		 * If no maxTick is specified it will return true if tick is after minTick
		 * @param  {object}  tick    Tick that is being tested
		 * @param  {object}  minTick Earliest allowed tick
		 * @param  {object}  maxTick Latest allowed tick
		 * @param  {object}  period  Period to compare
		 * @return {boolean}         True if tick is inside range
		 */
		isInRange: function(tick, minTick, maxTick, period) {
			if (minTick && tick.isBefore(minTick, period) || maxTick && tick.isAfter(maxTick, period)) {
				return false;
			}
			return true;
		},

		/**
		 * Does a value equality check on two objects
		 * @param  {object} object1 An object to compare
		 * @param  {object} object2 Another object to compare
		 * @return {boolean}        True when both objects contain the exact same values
		 */
		isEqual: function(object1, object2) {
			return JSON.stringify(object1) === JSON.stringify(object2);
		},

		/**
		 * Converts kebab cased string to camel cased
		 * @param  {string} kebabText Kebab cased string
		 * @return {string}           Camel cased string
		 */
		kebabToCamel: function(kebabText) {
			return kebabText.split('-')
				.reduce(function(memo, text) {
					if (!text.length) {
						return memo;
					}
					return (!memo.length) ? text : memo + text[0].toUpperCase() + text.substr(1);
				}, '');
		},

		/**
		 * Creates a dropdown containing year options that fall between the startTick
		 * and the end tick. Also selects the option representing the currentTick value.
		 * @param  {object} startTick   Moment object representing a start point in time
		 * @param  {object} endTick     Moment object representing an end point in time
		 * @param  {object} currentTick Moment object representing the option that should be selected
		 * @return {object}             jQuery wrapped select element
		 */
		createYearDropdown: function(startTick, endTick, currentTick) {
			var markup = '<select>';

			var options = [];
			var optionAttributes;

			var tick = moment(startTick);

			if (currentTick) {
				tick = tick.month(currentTick.month());
				if (startTick.isAfter(tick, 'month')) {
					tick = tick.add(1, 'years');
				}
			}

			while (tick.isSameOrBefore(endTick, 'month')) {
				optionAttributes = ['value="' + tick.year() + '"'];

				if (currentTick && (tick.year() === currentTick.year())) {
					optionAttributes.push('selected');
				}

				options.push('<option ' + optionAttributes.join(' ') + '>' + tick.year() + '</option>');
				tick = tick.add(1, 'years');
			}

			markup += options.join('');

			markup += '</select>';

			return $(markup);
		},

		/**
		 * Creates a dropdown containing month options that fall between the startTick
		 * and the end tick. Also selects the option representing the currentTick value.
		 * @param  {object} startTick   Moment object representing a start point in time
		 * @param  {object} endTick     Moment object representing an end point in time
		 * @param  {object} currentTick Moment object representing the option that should be selected
		 * @return {object}             jQuery wrapped select element
		 */
		createMonthDropdown: function(startTick, endTick, currentTick) {
			var months = moment.monthsShort();

			var markup = '<select>';

			var options = [];
			var optionAttributes;

			var monthTick = moment(currentTick).startOf('year');
			var endOfYear = moment(currentTick).endOf('year');

			for (var i = 0; i < months.length; i++) {
				monthTick = monthTick.month(i);

				if (
					monthTick.isBefore(startTick, 'month') ||
					monthTick.isAfter(endOfYear, 'month') ||
					monthTick.isAfter(endTick, 'month')
				) {
					continue;
				}

				optionAttributes = ['value="' + i + '"'];

				if (i === currentTick.month()) {
					optionAttributes.push('selected');
				}

				options.push('<option ' + optionAttributes.join(' ') + '>' + months[i] + '</option>');
			}

			markup += options.join('');

			markup += '</select>';

			return $(markup);
		},

		/**
		 * Only call the function if calls have stopped for more than the specified duration
		 * @param  {function} fn       The function to call
		 * @param  {number}   duration Amount of time to wait between function calls
		 */
		debounce: function(fn, duration) {
			return function() {
				var args = arguments;
				var timeout;
				if (timeout) {
					window.clearTimeout(timeout);
				}
				setTimeout(function() {
					fn.apply(null, args);
				}, duration);
			};
		},

		/**
		 * Determine if a tick lands on a weekend
		 * @param tick
		 * @returns {boolean}
		 */
		isWeekend: function (tick) {
			const weekDay = tick.day();
			return weekDay === 0 || weekDay === 6;
		},

		/**
		 * Returns a promise that is resolved when the browser has repainted.
		 * @returns {object}
		 */
		waitForRender() {
			return new Promise((resolve) => {
				window.requestAnimationFrame(() => {
					window.requestAnimationFrame(() => {
						resolve();
					});
				});
			});
		}
	};

	/**
	 * Registry for event handlers. Facilitates adding multiple handlers for a
	 * single event.
	 *
	 * @constructor
	 */
	function EventRegistry() {
		this.eventHandlers = {};
	}

	EventRegistry.prototype = {
		/**
		 * Add an event handler
		 * @param  {string} name    The nae of the event
		 * @param  {function} handler The function to handle the event
		 */
		on: function(name, handler) {
			if (!this.eventHandlers[name]) {
				this.eventHandlers[name] = [];
			}
			this.eventHandlers[name].push(handler);
		},

		/**
		 * Remove event Handler with matching event name/function
		 * @param  {string} name    The name of the event
		 * @param  {function} handler The function to handle the event
		 */
		off: function(name, handler) {
			var handlers = this.eventHandlers[name];
			if (!handlers || !handlers.length) {
				return;
			}

			var handlerIndex = handlers.indexOf(handler);
			if (handlerIndex > -1) {
				handlers.splice(handlerIndex, 1);
			}
		},

		/**
		 * Calls event handler whether supplied in options or on instance
		 * @param  {string} eventName The name of the event
		 * @param  {..object|..string} Argument values to pass event handler
		 */
		trigger: function(name) {
			var handlers = this.eventHandlers[name];
			if (!handlers || !handlers.length) {
				return;
			}

			var args = Array.prototype.slice.call(arguments, 1);
			handlers.forEach(function(handler) {
				handler.apply(this, args);
			}.bind(this));
		}
	};



	/**
	 * Allows CalendarPicker to be used as a jQuery plugin
	 * @param  {object} options Options that will be passed to CalendarPicker
	 * @return {object}         The reference to "this"
	 */
	$.fn.calendarPicker = function(options) {
		return this.each(function() {
			var $this = $(this);
			if ($this.data('calendarPicker') || this.__calendarPicker) {
				return;
			}

			var calendarPicker = new CalendarPicker(this, options);

			// Expose the API
			$this.data('calendarPicker', calendarPicker);

			this.__calendarPicker = calendarPicker;
		});
	};

	var PICKER_TYPES = {
		DATE: 'date',
		DATE_RANGE: 'date-range',
		MONTH: 'month',
		MONTH_RANGE: 'month-range'
	};

	var SELECTION_MODES = {
		SINGLE: 'single',
		RANGE: 'range'
	};

	var SELECTING_STATES = {
		START: 'start',
		END: 'end'
	};

	var PERIODS = {
		DAY: 'day',
		MONTH: 'month'
	};

	var ISO_8601_FORMAT = 'YYYY-MM-DD';

	var CALENDAR_PICKER_ATTRIBUTE_OPTIONS = [
		'format',
		'max',
		'min',
		'start',
		'end',
		'future-only',
		'now-and-future-only',
		'past-only',
		'now-and-past-only',
		'toggle-on-focus',
		'quick-nav'
	];

	var CSS_INPUT_FOCUS = 'CalendarPicker__input--focus';

	var DEFAULT_CALENDAR_PICKER_OPTIONS = {
		dualGrid: false,
		max: moment().add(50, 'years'),
		min: moment().subtract(100, 'years'),
		noFacade: false,
		period: PERIODS.DAY,
		quickNav: ifFeature('encore', 'enabled', 'auto'),
		selectionMode: SELECTION_MODES.SINGLE,
		toggleOnFocus: false,
		disableWeekends: false,
		disableDays: [],
	};

	/**
	 * Binds a calendar to input fields. The Calendar Picker can support selection
	 * of a single point in time or a range of time. It also supports select day
	 * or month periods.
	 *
	 *
	 * OPTIONS:
	 *
	 *  NOTE: See DEFAULT_CALENDAR_PICKER_OPTIONS for deaults to these options
	 *
	 *  dualGrid - [boolean] - Uses two grids instead of one to show more options to users
	 *
	 *  end - [Date | Moment | ISO 6801 String] - Sets the default end selection
	 *
	 *  format - [string] - Moment date format to place into input when a selection is made
	 *
	 *  futureOnly - [boolean] - Restricts selections to be in the future
	 *
	 *  max - [Date | Moment | ISO 6801 String] - Prevents selection past this moment in time in
	 *
	 *  min - [Date | Moment | ISO 6801 String] - Prevents selection past this moment in time in
	 *
	 *  nowAndFutureOnly - [boolean] - Restricts selections to be in the present or in the future
	 *
	 *  nowAndPastOnly - [boolean] - Restricts selections to be in the present or in the past
	 *
	 *  pastOnly - [boolean] - Restricts selections to be in the past
	 *
	 *  period - ['day', 'month'] - Determines if selections should be days or months
	 *
	 *  quickNav - ['enabled', 'disabled', 'auto'] - Hides quickNav when disabled. When set to 'auto' shows quickNav on when not in 'dualGrid' mode
	 *
	 *  selectionMode - ['single', 'range'] - Determines if users can select a single moment or time or a timespan
	 *
	 *  start - [Date | Moment | ISO 6801 String] - Sets the default start selection
	 *
	 *  toggleOnFocus - [boolean] - If enabled, shows the calendar popover when an input is focused
	 *
	 *
	 * NOTE: The variable name 'tick' always represents a moment object
	 *
	 * @param {string|object} anchorSelector Element or element selector to bind to
	 * @param {objects} options        Configuration options
	 */
	function CalendarPicker(anchorSelector, options) {
		this.$anchor = $(anchorSelector).addClass('CalendarPicker');
		this.toggleButtons = [];
		this.isMobileDevice = isWidthBelowSupportedMinWidth();

		this._wireOptions(options);

		if (!this.options.noFacade) {
			this._wireHiddenInputs();
		}

		if (this.options.toggleButton) {
			this._addToggleButtons();
		}

		this._wireInternalEvents();
		this._wireExternalEvents();

		this._originalStart = this.options.start;
		this._originalEnd = this.options.end;

		this._popoverVisible = false;

		this.setRange(this._originalStart, this._originalEnd);

		this.enableDisableButtons();
	}

	/**
	 * Exposes a way to set all the options for this instance of CalendarPicker
	 * @param  {object} options Initial options object
	 */
	CalendarPicker.prototype.setOptions = function(options) {
		this._wireOptions(options);

		this._originalStart = this.options.start;
		this._originalEnd = this.options.end;

		if (this.gridManager) {
			this.gridManager.setOptions(this._getGridManagerOptions());
		}

		this._updateGridManager();

		this.setRange(this._originalStart, this._originalEnd);

		this.enableDisableButtons();
	}

	/**
	 * Enables or disables the icon button (calendar icon) if a prop change occurs
	 * that enables or disables it
	 */
	CalendarPicker.prototype.enableDisableButtons = function() {
		const { disabled = false } = this.options;
	// @startCleanup encore
		if (ifFeature('encore', true)) {
	// @endCleanup encore
			if (this.$start) {
				this.$start.siblings().children('button').prop('disabled', this.$start.prop('disabled'));
			}
			if (this.$end) {
				this.$end.siblings().children('button').prop('disabled', this.$end.prop('disabled'));
			}
	// @startCleanup encore
		  } else {
			if (this.$start) {
				this.$start.siblings('button').attr('disabled', disabled);
			}
			if (this.$end) {
				this.$end.siblings('button').attr('disabled', disabled);
			}
		  }
	// @endCleanup encore
	};

	/**
	 * Sets all the options for this instance of CalendarPicker
	 * @param  {object} options Initial options object
	 */
	CalendarPicker.prototype._wireOptions = function(options = {}) {
		this.options = this.options || {};
		this.options = $.extend(true, {}, DEFAULT_CALENDAR_PICKER_OPTIONS, this.options, options, {});
		this.options = $.extend(true, {}, this.options, this._getOptionsFromPresets(this.$anchor), this._getOptionsFromAttributes(this.$anchor));

		this.options.format = this.options.format || moment.defaultFormat;

		if (this.options.min) {
			this.options.min = this._normalizeToTick(this.options.min);
		}

		if (this.options.max) {
			this.options.max = this._normalizeToTick(this.options.max);
		}

		this._setInputs(this.options.startInput, this.options.endInput);

		if (this.options.noFacade) {
			this.options.start = this._getTickFromInput(this.$start);

			if (SELECTION_MODES.RANGE === this.options.selectionMode) {
				this.options.end = this._getTickFromInput(this.$end);
			}
		}

		return options;
	};

	/**
	 * Gets options for presets. Presets are specified via the calendar-picker attribute
	 * Example: <input calendar-picker="month">
	 * @param  {object} $element CalendarPicker element
	 * @return {object}          Options created from presets
	 */
	CalendarPicker.prototype._getOptionsFromPresets = function($element) {
		var options = {};
		var pickerType = $element.attr('calendar-picker');

		if (PICKER_TYPES.DATE_RANGE === pickerType || PICKER_TYPES.MONTH_RANGE === pickerType) {
			options.selectionMode = SELECTION_MODES.RANGE;
			options.dualGrid = true;
		}

		if (PICKER_TYPES.DATE_RANGE === pickerType) {
			options.period = PERIODS.DAY;
		} else if (PICKER_TYPES.MONTH_RANGE === pickerType || PICKER_TYPES.MONTH === pickerType) {
			options.period = PERIODS.MONTH;
			options.format = 'MMM YYYY';
			// We don't want to show the date select inputs on the "month" picker
			ifFeature('encore', () => options.quickNav = 'disabled', () => {})()
		}

		return options;
	};

	/**
	 * Gets options that are defined in the markup via attributes
	 * @param  {object} $element CalendarPicker element
	 * @return {object}          Options obtained via attributes
	 */
	CalendarPicker.prototype._getOptionsFromAttributes = function($element) {
		var options = {};
		var optionsAttribute = $element.attr('calendar-picker-options');

		CALENDAR_PICKER_ATTRIBUTE_OPTIONS.forEach(function(attributeName) {
			var attributeValue = null;
			var optionKey = null;
			var isBooleanValue = false;

			if($element[0].hasAttribute(attributeName)) {
				attributeValue = $element[0].getAttribute(attributeName);
				optionKey = Util.kebabToCamel(attributeName);
				isBooleanValue = attributeValue === 'true' || attributeValue === 'false';

				if (attributeValue && !isBooleanValue) {
					options[optionKey] = attributeValue;
				} else if(attributeValue && isBooleanValue) {
					options[optionKey] = attributeValue === 'true';
				} else {
					options[optionKey] = true;
				}
			}
		});

		if (optionsAttribute) {
			optionsAttribute = optionsAttribute.replace(/\'/g, '\"');
			options = $.extend(true, {}, options, JSON.parse(optionsAttribute));
		}

		if (options.futureOnly) {
			options.min = moment().add(1, this.options.period);
		}
		if (options.nowAndFutureOnly) {
			options.min = moment();
		}

		if (options.pastOnly) {
			options.max = moment().subtract(1, this.options.period);
		}
		if (options.nowAndPastOnly) {
			options.max = moment();
		}

		return options;
	};

	/**
	 * Attaches input elements to this instance.
	 * @param {string|object} startSelector Selector string or element representing start
	 * @param {string|object} endSelector   Selector string or element representing end
	 */
	CalendarPicker.prototype._setInputs = function(startSelector, endSelector) {
		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			this.$start = $(startSelector);
			this.$end = $(endSelector);

			if (!this.$start.length && this.$anchor.find('[range-start]').length){
				this.$start = this.$anchor.find('[range-start]');
			}

			if (!this.$end.length && this.$anchor.find('[range-end]').length) {
				this.$end = this.$anchor.find('[range-end]');
			} else {
				throw new Error('Could not find end input for CalendarPicker');
			}
		} else if (this.$anchor.is(':input') || this.$anchor.is('ba-select')) {
			this.$start = this.$anchor;
		}

		if (!this.$start || !this.$start.length) {
			throw new Error('Could not find input for CalendarPicker');
		}

		this._getInputs()
			.addClass('CalendarPicker__input');
	};

	/**
	 * Returns a jQuery object containing the visible inputs for this calendar picker
	 * @return {object} jQuery object containing the inputs
	 */
	CalendarPicker.prototype._getInputs = function() {
		var jQueryObject = $().add(this.$start);

		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			jQueryObject = jQueryObject.add(this.$end);
		}

		return jQueryObject;
	};

	/**
	 * Adds hidden inputs to the form in place of the original input fields.
	 * This allows us to submit dates in a standard format and still show the
	 * preferred date format in the visible fields.
	 */
	CalendarPicker.prototype._wireHiddenInputs = function() {
		var facadePostfix = '__cpFacade';
		var startName, endName;

		startName = this.$start.attr('name');

		this.$hiddenStart = $('<input type="hidden">')
			.attr('name', this.$start.attr('name'))
			.insertAfter(this.$start);
		this.$start.attr('name', startName + facadePostfix);

		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			endName = this.$end.attr('name');

			this.$hiddenEnd = $('<input type="hidden">')
				.attr('name', endName)
				.insertAfter(this.$end);
			this.$end.attr('name', endName + facadePostfix);
		}
	};

	/**
	 * Adds a toggle button to one or both of the inputs depending on selectionMode.
	 */
	CalendarPicker.prototype._addToggleButtons = function() {
		var toggleButton = this.options.toggleButton;
		var $toggleButton = (typeof toggleButton === 'boolean') ? $('<button type="button">') : $(toggleButton);

		$toggleButton.addClass('CalendarPicker__toggleButton js-CalendarPicker__toggleButton')
			.attr('tabindex', '-1').attr('data-no-jquery-aria-required', true).attr('aria-label', $.__('Open Calendar Picker'));

		this._addToggleButton(this.$start, $toggleButton);

		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			this._addToggleButton(this.$end, $toggleButton);
		}
	};

	/**
	 * Wraps an input and places the toggle button next to the input
	 * @param {object} $input        Input to wrap
	 * @param {object} $toggleButton Toggle button to place
	 */
	CalendarPicker.prototype._addToggleButton = function($input, $toggleButton) {
		if (!JADE_ENABLED) {
			$input.wrap('<div class="CalendarPicker__inputWrapper js-CalendarPicker__inputWrapper">');
		}

		const $clonedToggleButton = $toggleButton.clone();

		this.toggleButtons = [...this.toggleButtons, $clonedToggleButton[0]];

		$clonedToggleButton.appendTo($input.parent())
			.on('click.calendarPicker', function(e) {
				if ($input.is(':disabled')) {
					return;
				}

				if (!this.options.toggleOnFocus) {
					this._activatePopover($input).then(() => $input.focus());
				} else {
					$input.focus();
				}
			}.bind(this));
	};

	/**
	 * Creates Event Registry for instance. Hooks up event handlers that were
	 * passed to the consructor during initialization.
	 */
	CalendarPicker.prototype._wireExternalEvents = function() {
		this.events = new EventRegistry();

		// Add event handlers passed in on the options
		if (this.options.events) {
			Object.keys(this.options.events).forEach(
				function(key) {
					this.events.on(key, this.options.events[key]);
				}.bind(this)
			);
		}
	};

	/**
	 * Adds event listeners to DOM events and updates the internal state
	 * when appropriate
	 */
	CalendarPicker.prototype._wireInternalEvents = function() {
		var $inputs = $().add(this.$start);
		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			$inputs = $inputs.add(this.$end);
		}

		$inputs.on('input.calendarPicker', this._handleInput.bind(this));

		$inputs
			.on('focus.calendarPicker',
				function(e) {
					var $input = $(e.currentTarget);

					if (this._hidePopoverTimeout) {
						window.clearTimeout(this._hidePopoverTimeout);
						delete this._hidePopoverTimeout;
					}

					if (this.options.toggleOnFocus) {
						this._activatePopover($input);
					} else {
						this._setSelectingFromInput($input);

						if (this.$popover && this.$popover.is(':visible')) {
							this._positionPopover(this._popoverVisible);
						}
					}
				}.bind(this)
			)
			.on('keydown.calendarPicker',
				function(e) {
					// Escape or tab key was pressed
					if (e.which === 27 || e.which === 9) {
						this._hidePopover();
					}
				}.bind(this)
			);

		$(ifFeature('encore', '#js-GlobalScrollContainer', window)).on('resize.calendarPicker, scroll.calendarPicker',
			function() {
				if (this.$popover && this.$popover.is(':visible')) {
					this._positionPopover();
				}
			}.bind(this)
		);

		if ($('#fabricModalContent').length) {
			$('#fabricModalContent').on('resize.calendarPicker, scroll.calendarPicker', function() {
				if (this.$popover && this.$popover.is(':visible')) {
					this._positionPopover();
				}
			}.bind(this)
			);
		}

		$(window).on('resize',
			function() {
				if (this.$popover && isWidthBelowSupportedMinWidth()) {
					this.isMobileDevice = true;
					this.$popover.css({
						'position': 'absolute',
						'left': `${ this._horizontalPopoverPosition().left }px`,
						'top': `${ this.$anchor.offset().top + this.$anchor[0].getBoundingClientRect().height }px`,
						'transform': '',
					});
				} else if (this.$popover) {
					this.isMobileDevice = false;
					this.$popover.css({
						'position': 'fixed',
						'left': `0`,
						'top': `0`,
					});
					this._positionPopover();
				}
			}.bind(this)
		);
	};

	/**
	 * Event handler that is called when a click event is triggered on the document
	 * @param  {object} e The event object
	 */
	CalendarPicker.prototype._documentClickHandler = function(e) {
		const target = e.target;
		const popover = this.$popover && this.$popover[0];

		const clickIsFromToggleButton = this.toggleButtons.some(toggleButton => toggleButton === target || $.contains(toggleButton, target));
		const clickIsFromPopover = popover && $.contains(popover, target);
		const clickIsFromInput = (this.$start && this.$start[0] === target) || (this.$end && this.$end[0] === target);
		const isInDocument = $.contains(document, target);

		if (
			clickIsFromInput ||
			clickIsFromToggleButton ||
			clickIsFromPopover ||
			!isInDocument
		) {
			return;
		}
		this._hidePopover();
	};

	/**
	 * Event handler for when an either start or end input fields receive input
	 */
	CalendarPicker.prototype._handleInput = function(e) {
		var $input = $(e.currentTarget);
		var tick = this._getTickFromInput($input);

		var start = $input.is(this.$start) ? tick : this.start;
		var end = $input.is(this.$end) ? tick : this.end;

		if (tick && tick.isValid()) {
			this._setRange(start, end);
		}

		this._validateRange(start, end);
	};

	/**
	 * Converts strings/dates/moments into a moment object
	 * @param  {object|string} date   Date to normalize
	 * @param  {boolean} silent If true it won't throw an error if an invalid date argument is specified
	 */
	CalendarPicker.prototype._normalizeToTick = function(date, silent) {
		if (!date) {
			return null;
		}

		var tick = moment.isMoment(date) ? date : moment(date, this.options.format, true);

		// Always accept ISO format
		if (!tick.isValid() && typeof date === 'string') {
			tick = moment(date, moment.ISO_8601, true).startOf('day');
		}

		if (!tick.isValid() && !silent) {
			console.error('An invalid date was supplied to CalendarPicker');
			tick = null;
		}
		return tick;
	};

	/**
	 * Creates a popover containing the Grid Manager that matches the period
	 * specified in the options.
	 */
	CalendarPicker.prototype._createPopover = function($input) {
		this.$popover = $('<div class="CalendarPicker__popover js-CalendarPicker__popover">');

		this.$popover.css({
			'left': 0,
			'position': 'fixed',
			'top': 0,
			'visibility': 'hidden',
			'z-index': 10000,
		});

		this.$caret = $('<div class="CalendarPicker__caret">').appendTo(this.$popover);
		if (JADE_ENABLED) {
			this.$caret[0].innerHTML = `
				<svg width="26" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 15">
					<path d="M 0 12 L 12 0 L 24 12 Z" fill="currentColor" stroke="none"/>
					<path d="M 1 15 L 12 4 L 23 15 Z" stroke="none"/>
					<path d="M 1 15 L 12 4.5 L 23 15" fill="none"/>
				</svg>
			`;
		}

		this.gridManager = this._createGridManager();
		this.gridManager.$element.appendTo(this.$popover);

		if (this.isMobileDevice) {
			// Doing this negates the need for repositioning the calendar to keep it near the input on mobile
			this.$popover.css({
				'position': 'absolute',
				'left': `${ $input[0].getBoundingClientRect().left }px`,
				'top': `${ $input.offset().top + $input[0].getBoundingClientRect().height }px`,
			});
		}

		this.$popover.appendTo(document.body);

		this._positionPopover();
	};

	/**
	 * Shows the popover and updates the selecting state
	 * for that the specified input.
	 * @param  {object} $input The input that is showing the popover
	 */
	CalendarPicker.prototype._activatePopover = function($input) {
		if (!this.$popover) {
			this._createPopover($input);
		}

		this._setSelectingFromInput($input);

		if (!this._popoverVisible) {
			return this._showPopover();
		} else {
			return Util.waitForRender().then(() => this._positionPopover());
		}
	};

	/**
	 * Shows the popover.
	 */
	CalendarPicker.prototype._showPopover = function() {
		this.events.trigger('onBeforeShow', this);

		this.$popover.show();

		this._getCurrentlySelectingInput().addClass(CSS_INPUT_FOCUS);

		this._boundDocumentClickHandler = this._documentClickHandler.bind(this);
		$(document).on('click.calendarPicker', this._boundDocumentClickHandler);

		return Util.waitForRender().then(() => {

			this._positionPopover();

			this._popoverVisible = true;
			this.$popover.css('visibility', 'visible');

			this.events.trigger('onAfterShow', this);
		});
	};

	/**
	 * Hides the popover after a short delay
	 */
	CalendarPicker.prototype._hidePopover = function() {
		this._hidePopoverTimeout = setTimeout(this._hidePopoverImmediately.bind(this), 150);
	};

	/**
	 * Hides the popover immediately
	 */
	CalendarPicker.prototype._hidePopoverImmediately = function() {
		this.events.trigger('onBeforeHide');

		if (this.$popover) {
			this.$popover
				.css('visibility', 'hidden')
				.hide();
		}

		this._popoverVisible = false;

		this.$start.removeClass(CSS_INPUT_FOCUS);

		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			this.$end.removeClass(CSS_INPUT_FOCUS);
		}

		$(document).off('click.calendarPicker', this._boundDocumentClickHandler);

		this.events.trigger('onAfterHide');
	};

	CalendarPicker.prototype._getPopoverOffsets = function() {
		const fromDateOffset = ifFeature('encore', 
			this._getCurrentlySelectingInput().siblings('.CalendarPicker__toggleButton')[0].getBoundingClientRect(), 
			this.$start[0].getBoundingClientRect()
		);

		const lastDateOffset = ifFeature('encore',
			fromDateOffset,
			this.options.selectionMode === 'range' ? this.$end[0].getBoundingClientRect() : fromDateOffset
		);

		return {
			fromDateOffset,
			lastDateOffset,
		}
	};
	
	/**
	 * Positions the popover relative to the inputs
	 */
	CalendarPicker.prototype._positionPopover = function(animateCaret) {
		if (this.isMobileDevice) {
			// Some mobile devices report position of elements weird (iDevices) when browser elements are open (virtual keyboard)
			// so we don't want to do this repositioning on mobile so we can keep the input from being covered
			return;
		}
		var newOffset = {};

		const {
			horizontalAnchor,
			left
		} = this._horizontalPopoverPosition();

		const {
			verticalAnchor,
			top,
		} = this._verticalPopoverPosition();

		newOffset.left = left;
		newOffset.top = top;

		this.events.trigger('onBeforePosition', this.$popover, newOffset);

		this.$popover
			.attr('horizontal-anchor', horizontalAnchor)
			.attr('vertical-anchor', verticalAnchor)
			.css({
				transform: 'translate3d(' + Math.ceil(newOffset.left) + 'px, ' + Math.ceil(newOffset.top) + 'px, 0)',
			});

		this._positionCaret(animateCaret);

		this.events.trigger('onAfterPosition', this.$popover, newOffset);
	};

	CalendarPicker.prototype._horizontalPopoverPosition = function() {
		let horizontalAnchor, left;
		let popoverWidth = this.$popover.outerWidth(true);

		const {
			fromDateOffset,
			lastDateOffset,
		} = this._getPopoverOffsets();

		// Handle horizontal collisions
		let rightSpace = window.innerWidth - fromDateOffset.left;
		if (rightSpace < popoverWidth && fromDateOffset.right > popoverWidth) {
			left = lastDateOffset.right - popoverWidth;
			horizontalAnchor = 'right';
		} else {
			left = fromDateOffset.left;
			horizontalAnchor = 'left';
		}

		return {
			left,
			horizontalAnchor,
		}
	};

	CalendarPicker.prototype._verticalPopoverPosition = function() {
		let popoverHeight = this.$popover.outerHeight(true);
		const spacing = ifFeature('encore', 10, 0); // space between the anchor and the popover

		const {
			fromDateOffset,
			lastDateOffset,
		} = this._getPopoverOffsets();

		let selectedInputOffset = (SELECTING_STATES.END === this.selecting) ? lastDateOffset : fromDateOffset;
		let verticalAnchor, top;

		// Handle vertical collisions
		let bottomSpace = window.innerHeight - selectedInputOffset.bottom;

		if (bottomSpace < popoverHeight + spacing && selectedInputOffset.top > popoverHeight + spacing) {
			top = selectedInputOffset.top - popoverHeight -spacing;
			verticalAnchor = 'bottom';

			this.$popover.addClass('CalendarPicker__popover--topAnchored');
			this.$caret.addClass('CalendarPicker__caret--topAnchored');
		} else {
			top = selectedInputOffset.bottom + spacing;
			verticalAnchor = 'top';

			this.$popover.removeClass('CalendarPicker__popover--topAnchored');
			this.$caret.removeClass('CalendarPicker__caret--topAnchored');
		}

		return {
			top,
			verticalAnchor,
		}
	};

	/**
	 * Moves the popover caret to the point to the active input
	 * @param  {boolean} animate Whether or not the arrow should animate into position
	 */
	CalendarPicker.prototype._positionCaret = function(animate) {
		var $input = (SELECTING_STATES.END === this.selecting) ? this.$end : this.$start;
		var inputOffset = $input.offset();

		var popoverOffset = this.$popover.offset();
		var spacing = 15;

		var halfCaretWidth = this.$caret.outerWidth() / 2;

		var caretHorizontalOffset = inputOffset.left;
		if (this.$popover.attr('horizontal-anchor') === 'left' || SELECTING_STATES.START === this.selecting) {
			caretHorizontalOffset += spacing;
		} else {
			caretHorizontalOffset += $input.outerWidth() - spacing;
		}

		var newLeftOffset = caretHorizontalOffset - popoverOffset.left - halfCaretWidth;
		if (animate) {
			this.$caret.animate({"left": newLeftOffset});
		} else {
			this.$caret.css({"left": newLeftOffset});
		}
	};

	/**
	 * Gets options for GridManager
	 * @return {object} GridManager options
	 */
	CalendarPicker.prototype._getGridManagerOptions = function() {
		return {
			autoSync: false,
			dualGrid: this.options.dualGrid,
			min: this.options.min,
			max: this.options.max,
			quickNav: this.options.quickNav,
			selectionMode: this.options.selectionMode,
			disableWeekends: this.options.disableWeekends,
			disableDays: this.options.disableDays,
		};
	}

	/**
	 * Create a GridManager that represents the desired period and listens to
	 * select events for that manager.
	 * @return {object} GridManager instance
	 */
	CalendarPicker.prototype._createGridManager = function() {
		var gridManagerOptions = this._getGridManagerOptions();

		var gridManager;
		if (PERIODS.MONTH === this.options.period) {
			gridManager = new YearGridManager(gridManagerOptions);
			// add a class specific to the month view so we can style it specifically
			gridManager.$element.addClass('CalendarGridManager__monthView')
		} else {
			gridManager = new MonthGridManager(gridManagerOptions);
		}

		gridManager.events.on('select', this._selectHandler.bind(this));
		gridManager.events.on('periodChange', function() {
			this._positionPopover();
			this.events.trigger('onPeriodChange', this);
		}.bind(this));
		gridManager.events.on('render', function() {
			this.events.trigger('onRender', this);
		}.bind(this));

		return gridManager;
	};

	/**
	 * Event handler for when a selection has been made via GridManager
	 * @param  {string} selecting String representing the boundary currently being selected
	 * @param  {object} start     Start of range
	 * @param  {object} end       End of range
	 */
	CalendarPicker.prototype._selectHandler = function(selecting, start, end) {
		this._setRange(start, end);

		if (this.selecting === selecting) {
			selecting = null;
		}

		this._setSelecting(selecting);
	};

	/**
	 * Updates the state of the GridManager and rerenders it
	 */
	CalendarPicker.prototype._updateGridManager = function() {
		var periodTick = this._getPeriodTick();

		if (!this.gridManager) {
			return;
		}

		if (PERIODS.DAY === this.options.period) {
			this.gridManager.setMonth(periodTick.month(), periodTick.year());
		} else if (PERIODS.MONTH === this.options.period) {
			this.gridManager.setYear(periodTick.year());
		}

		this.gridManager.updateSelection(this.selecting, this.start, this.end);
		this.gridManager.render();
	};

	/**
	 * Causes focus/blur based on the new selecting state
	 * @param {string} selecting String representing selecting states 'start' or 'end'
	 */
	CalendarPicker.prototype._setSelecting = function(selecting) {
		var $oldSelectingInput = this._getSelectingInput(this.selecting);

		this.selecting = selecting;

		this._updateInputFocusClasses();

		// Just toggle off if already active
		if (!this.selecting) {
			$oldSelectingInput.blur();
			this._hidePopoverImmediately();
			return;
		}

		if (SELECTING_STATES.START === this.selecting) {
			if (SELECTION_MODES.RANGE === this.options.selectionMode) {
				this.$end.blur();
			}

			if (!this.$start.val().trim() && SELECTION_MODES.RANGE === this.options.selectionMode && !this.$start.is(':focus')) {
				this.$start.focus();
			} else {
				this._hidePopoverImmediately();
			}
		} else if (SELECTION_MODES.RANGE === this.options.selectionMode && SELECTING_STATES.END === this.selecting) {
			this.$start.blur();
			if (!this.$end.val().trim() && SELECTION_MODES.RANGE === this.options.selectionMode && !this.$end.is(':focus')) {
				this.$end.focus();
			} else {
				this._hidePopoverImmediately();
			}
		}

	};

	/**
	 * Sets selecting state according the the input that is supplied
	 * @param {object} $input The input element that is currently active
	 */
	CalendarPicker.prototype._setSelectingFromInput = function($input) {
		this.selecting = (SELECTION_MODES.RANGE === this.options.selectionMode && $input.is(this.$end)) ? SELECTING_STATES.END : SELECTING_STATES.START;

		this._updateInputFocusClasses();

		this._updateGridManager();
	};

	/**
	 * Removes the CSS_INPUT_FOCUS class from all inputs and adds the CSS_INPUT_FOCUS
	 * class to the currently selecting input if the popover is visible.
	 */
	CalendarPicker.prototype._updateInputFocusClasses = function() {
		var $selectingInput;

		this._getInputs().removeClass(CSS_INPUT_FOCUS);

		if (this._popoverVisible) {
			$selectingInput = this._getCurrentlySelectingInput();
			$selectingInput.addClass(CSS_INPUT_FOCUS);
		}
	};

	/**
	 * Gets the input for a selecting state.
	 * @param  {string} selecting Selecting state name 'start' or 'end'
	 * @return {object}           Input element
	 */
	CalendarPicker.prototype._getSelectingInput = function(selecting) {
		return (SELECTION_MODES.RANGE === this.options.selectionMode && SELECTING_STATES.END === selecting) ? this.$end : this.$start;
	};

	/**
	 * Returns the currently selected input
	 * @return {object} jQuery object containing element
	 */
	CalendarPicker.prototype._getCurrentlySelectingInput = function() {
		return this._getSelectingInput(this.selecting);
	};

	/**
	* Updates the values of the inputs
	* @param  {string} boundary The input boundary that is being set
	* @param  {object} tick     Tick that represents boundary
	* @param  {object} silent   Will not invoke change handler if true
	*/
	CalendarPicker.prototype._updateInput = function(boundary, tick, silent) {
		var $input = boundary === 'end' ? this.$end : this.$start;
		var $hiddenInput = null;
		var oldValue = $input.val();
		if (!this.options.noFacade) {
			$hiddenInput = (boundary === 'end') ? this.$hiddenEnd : this.$hiddenStart;
			$hiddenInput.val(tick ? moment(tick).format(ISO_8601_FORMAT) : '');
		}

		if (tick) {
			$input.val(tick.format(this.options.format));
		} else if ($input.val().trim()) {
			$input.val('');
		}

		if (!silent && $input.val() !== oldValue) {
			$input.trigger('change');
		}
	};

	/**
	 * Method for setting the date range programmatically
	 * @param {string|object} start String/Moment/Date representing start
	 * @param {string|object} end   String/Moment/Date representing end
	 */
	CalendarPicker.prototype.setRange = function(start, end) {
		start = this._normalizeToTick(start);
		end = this._normalizeToTick(end);

		var errors = this._validateRange(start, end);
		if (errors.length) {
			console.error('Invalid range specified', errors);
		}
		this._setRange(start, end, true);
	};

	/**
	 * Actually sets the range internally and will clear mismatched range boundaries
	 * @param {object} start Tick representing start of range
	 * @param {object} end   Tick representing end of range
	 * @param {boolean} silent Prevents change event from triggering
	 */
	CalendarPicker.prototype._setRange = function(start, end, silent) {
		if (start && start.isSame(this.start) && end && end.isSame(this.end)) {
			return;
		}

		// Is value mismatched with other range boundary?
		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			if (SELECTING_STATES.START === this.selecting && end && end.isBefore(start)) {
				end = null;
				// @startCleanup encore
				ifFeature('encore', () => {}, () => this._flashInput(this.$end))();
				// @endCleanup encore
			} else if (SELECTING_STATES.END === this.selecting && start && start.isAfter(end)) {
				start = null;
				// @startCleanup encore
				ifFeature('encore', () => {}, () => this._flashInput(this.$start))();
				// @endCleanup encore
			}
		}

		if (this.start !== start) {
			start = (start) ? moment(start).startOf(this.options.period) : start;

			this.start = start;
			this._updateInput(SELECTING_STATES.START, this.start, silent);
		}

		if (SELECTION_MODES.RANGE === this.options.selectionMode && this.end !== end) {
			end = (end) ? moment(end).endOf(this.options.period) : end;

			this.end = end;
			this._updateInput(SELECTING_STATES.END, this.end, silent);
		}

		if (!silent) {
			this.events.trigger('select', this.start, this.end);
			this.$anchor.trigger('calendarPicker:rangeSet', this.getValue());
		}
		this._updateGridManager();
	};

	/**
	 * Retreives a tick from the value contained inside the input
	 * @param  {object} $input Input to retrieve tick from
	 * @return {object}        Tick
	 */
	CalendarPicker.prototype._getTickFromInput = function($input) {
		return this._normalizeToTick($input.val(), true);
	};

	/**
	 * Gets a range of ticks from the input values
	 * @param {object} Range of ticks that reflect the input values
	 */
	CalendarPicker.prototype._getRangeFromInputs = function() {
		var range = {start: this._getTickFromInput(this.$start)};
		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			range.end = this._getTickFromInput(this.$end);
		}
		return range;
	};

	/**
	 * Checks if the range is valid
	 * @param  {object} start Tick representing start of range
	 * @param  {object} end   Tick representing end of range
	 * @return {object}       Array of errors
	 */
	CalendarPicker.prototype._validateRange = function(start, end) {
		var errors = [];

		// Are values a valid dates?
		if (start && !start.isValid() || end && !end.isValid()) {
			errors.push({code: 'INVALID_ARGUMENT', message: 'An invalid argument was supplied.'});
		}

		// Are values inside the set min/max bounds?
		if (start && !this._isInRange(start) || end && !this._isInRange(end)) {
			errors.push({code: 'OUT_OF_BOUNDS', message: 'An argument is outside of the min/max bounds'});
		}

		return errors;
	};

	/**
	 * Determines which period tick the GridManager should show.
	 * @return {object} Period tick
	 */
	CalendarPicker.prototype._getPeriodTick = function() {
		var max = this.options.max;
		var min = this.options.min;

		var now = moment();

		var periodTick;
		var parentPeriod = (PERIODS.DAY === this.options.period) ? 'month' : 'year';

		var lastGridPeriodTick = false;
		var hasSameParentPeriod = this.start && this.start.isSame(this.end, parentPeriod);
		if (this.start && (SELECTING_STATES.START === this.selecting || hasSameParentPeriod || !this.end)) {
			periodTick = this.start;
		} else if (this.end && (SELECTING_STATES.END === this.selecting && !hasSameParentPeriod || !this.start)) {
			periodTick = this.end;
			lastGridPeriodTick = true;
		}

		if (!periodTick) {
			if (this._isInRange(now)) {
				periodTick = now;
			} else if (min && min.isAfter(now)){
				periodTick = min;
			} else if (max && max.isBefore(now)) {
				periodTick = max;
				lastGridPeriodTick = true;
			} else {
				periodTick = now;
			}
		}

		periodTick = moment(periodTick).startOf(parentPeriod);

		// Show more earlier ticks by setting the period in the right-most grid if in dualGrid mode
		if (this.options.dualGrid && lastGridPeriodTick) {
			periodTick = moment(periodTick).subtract(1, parentPeriod);
		}

		return periodTick;
	};

	/**
	 * Checks to see if the tick is inside the allowed range
	 * @param  {object}  tick Tick to test
	 * @return {boolean}      True if it is in range. False if not.
	 */
	CalendarPicker.prototype._isInRange = function(tick) {
		return Util.isInRange(tick, this.options.min, this.options.max, this.options.period);
	};

	/**
	 * Returns a list of errors for the current state of the Calendar Picker
	 * @return {object} Array of errors
	 */
	CalendarPicker.prototype.validate = function() {
		var range = this._getRangeFromInputs();
		var errors = this._validateRange(range.start, range.end);

		return errors;
	};

	/**
	 * Validates a specific input that is part of the Calendar Picker
	 * @param  {object} input Input to validate
	 * @return {object}       Array of errors
	 */
	CalendarPicker.prototype.validateInput = function(input) {
		const {disableWeekends, disableDays} = this.options;
		var errors = [];
		var $input = $(input);

		if (SELECTION_MODES.RANGE === this.options.selectionMode && !$.contains(this.$anchor.get(0), $input.get(0)) ||
			SELECTION_MODES.RANGE !== this.options.selectionMode && !this.$anchor.is($input)) {
			throw new Error('The input supplied for validation isn\'t part of the Calendar Picker');
		}

		var tick = this._normalizeToTick($input.val(), true);
		if (tick) {
			if (!tick.isValid()) {
				errors.push({code: 'INVALID_ARGUMENT', message: 'This input contains an invalid value'});
			}
			if (!this._isInRange(tick)) {
				errors.push({code: 'OUT_OF_BOUNDS', message: 'This input is outside of the min/max bounds'});
			}
			if (disableWeekends && Util.isWeekend(tick)) {
				errors.push({code: 'NO_WEEKENDS', message: 'This input cannot select a day on a weekend.'});
			}
			if (disableDays.indexOf(tick.format('YYYY-MM-DD')) > -1) {
				errors.push({code: 'DISABLED_DATE', message: 'This input cannot select a disabled date.'});
			}
		}
		return errors;
	};

	/**
	 * Returns a boolean that indicates if the current state of the Calendar Picker
	 * is valid
	 * @return {boolean} True if Calendar Picker is valid
	 */
	CalendarPicker.prototype.isValid = function(input) {
		var errors = (input) ? this.validateInput(input) : this.validate();
		return !errors.length;
	};

	CalendarPicker.prototype.isDirty = function() {
		var range = this._getRangeFromInputs();
		return (range.start !== this._originalStart || range.end !== this._originalEnd);
	};

	/**
	 * Flashes an input yellow to indicate it was changed programmatically
	 * @param  {object} $input jQuery selector for an input
	 */
	CalendarPicker.prototype._flashInput = function($input) {
		var oldColorStyleProperty = $input[0].style.color;
		var oldColor = $input.css("backgroundColor");
		$input.css({backgroundColor: '#FDFFD7'});

		setTimeout(function() {
			$input.animate({backgroundColor: oldColor}, {
				complete: function() {
					$input.css({backgroundColor: oldColorStyleProperty});
				},
				duration: 200
			});
		}, 200);
	};

	/**
	 * Retrieves the current value representing the CalenderPicker's selection
	 * @param  {string=} format String used to format the selection
	 * @return {string|object}  The formatted value
	 */
	CalendarPicker.prototype.getValue = function(format) {
		format = format || ISO_8601_FORMAT;

		if (SELECTION_MODES.RANGE === this.options.selectionMode) {
			return {
				start: this.start ? this.start.format(format) : undefined,
				end: this.end ? this.end.format(format) : undefined
			};
		}

		return this.start ? this.start.format(format) : undefined;
	};

	/**
	 * Retrieves the current value representing the CalenderPicker's selection
	 * The value will be formatted using the format specified in CalendarPickers' options
	 * @return {string|object}  The formatted value
	 */
	CalendarPicker.prototype.getFormattedValue = function() {
		return this.getValue(this.options.format);
	};

	/**
	 * An abstract class that shares common functionality for CalendarGrids
	 * @param {number} minRows    The minimum number of rows to display (adjusts if not enough space is provided)
	 * @param {number} minColumns The mnimum number of columns to display
	 */
	function AbstractCalendarGrid(minRows, minColumns) {
		this.rows = minRows;
		this.columns = minColumns;

		this.selecting = SELECTING_STATES.START;
		this.selectionMode = SELECTION_MODES.SINGLE;
		this.displayingTick = moment();
		this.data = [];

		this.events = new EventRegistry();

		this.$element = $('<div class="CalendarGrid">');
	}

	/**
	 * Sets options for the AbstractCalendarGrid and rerenders
	 * @param {object} options
	 */
	AbstractCalendarGrid.prototype.setOptions = function(options) {
		this.options = { ...this.options, ...options };
		this.render();
	}

	/**
	 * Wires up the events for this grid
	 */
	AbstractCalendarGrid.prototype._wireEvents = function() {
		this.$element.off('.calendarGrid');

		this.$element.on('hover.calendarGrid', '[data-cell-value]:not([data-cell-disabled])',
			function(e) {
				var tick = this._getTickFromCell(e.currentTarget);
				if (!tick) {
					return;
				}

				this.events.trigger('cellHover', tick);

				if (SELECTION_MODES.SINGLE !== this.selectionMode) {
					this.highlightToTick(tick);
				}
			}.bind(this)
		);

		this.$element.on('click.calendarGrid', '[data-cell-value]:not([data-cell-disabled])',
			function(e) {
				var tick = this._getTickFromCell(e.currentTarget);
				var selecting = this.selecting;

				e.stopPropagation();
				e.preventDefault();

				if (SELECTION_MODES.RANGE === this.selectionMode) {
					this._setRangeBoundary(tick, this.selecting);
				} else {
					this.start = tick;
				}

				if (SELECTION_MODES.RANGE === this.selectionMode) {
					selecting = (SELECTING_STATES.START === this.selecting) ? 'end' : 'start';

				}

				this.selecting = selecting;
				this.events.trigger('cellSelect', selecting, this.start, this.end);

				this.render();
			}.bind(this)
		);

		this.$element.on('selectstart', function(e) {
			e.preventDefault();
		});
	};

	AbstractCalendarGrid.prototype._setRangeBoundary = function(tick, selecting) {
		if (SELECTING_STATES.START === this.selecting) {
			this.start = tick;
		} else if (SELECTING_STATES.END === this.selecting) {
			this.end = tick;
		}
	};

	AbstractCalendarGrid.prototype._getTickFromCell = function(cell) {
		if (cell.hasAttribute('data-cell-value')) {
			return moment(cell.getAttribute('data-cell-value'));
		}
	};

	AbstractCalendarGrid.prototype._getCellFromTick = function(tick) {
		var isoString = tick.toISOString();
		return this.$element.find('[data-cell-value="' + isoString + '"]');
	};

	AbstractCalendarGrid.prototype._isInRange = function(tick) {
		if (!this.start || !this.end) {
			return false;
		}
		return this.start.isSameOrBefore(tick) && this.end.isSameOrAfter(tick);
	};

	/**
	 * Highlights the cells spanning from the start date to the hovered date if selecting
	 * an end date. Highlights the cells spanning from the end date to the hovered date
	 * if selecting a start date.
	 * @param  {object} hoveredTick [description]
	 */
	AbstractCalendarGrid.prototype.highlightToTick = function(hoveredTick) {
		if (SELECTING_STATES.START === this.selecting && this.start && this.end && this.end.isAfter(hoveredTick)) {
			this._getCellFromTick(this.start).removeClass('CalendarGrid__cell--selected');
		} else if (SELECTING_STATES.END === this.selecting && this.end && this.start && this.start.isBefore(hoveredTick)) {
			this._getCellFromTick(this.end).removeClass('CalendarGrid__cell--selected');
		} else if (this.start && this.end){
			this.resetHighlight();
			return;
		}

		this.$element.find('[data-cell-value]')
			.each(
				function(index, element) {
					var cellTick = this._getTickFromCell(element);

					var shouldHighlightBefore = this.end && this.end.isSameOrAfter(hoveredTick) && this.end.isSameOrAfter(cellTick) && cellTick.isSameOrAfter(hoveredTick);
					shouldHighlightBefore = shouldHighlightBefore && SELECTING_STATES.START === this.selecting;

					var shouldHighlightAfter = this.start && this.start.isSameOrBefore(cellTick) && this.start.isSameOrBefore(cellTick) && cellTick.isSameOrBefore(hoveredTick);
					shouldHighlightAfter = shouldHighlightAfter && SELECTING_STATES.END === this.selecting;

					var inRange = shouldHighlightBefore || shouldHighlightAfter;
					if (inRange) {
						$(element).addClass('CalendarGrid__cell--inRange');
					} else {
						$(element).removeClass('CalendarGrid__cell--inRange');
					}

					this._toggleEdgeClasses(element, inRange);

				}.bind(this)
			);
	};

	/**
	 * Adds CalendarGrid__cell--inRangeLeading if the cell is the first range cell in a row
	 * Adds CalendarGrid__cell--inRangeTrailing if the cell is the last range cell in a row
	 * @param  {object} element Cell element
	 */
	AbstractCalendarGrid.prototype._toggleEdgeClasses = function(element, inRange) {
		var $element = $(element);
		var $prev = $element.prev();
		var $next = $element.next();

		if (!inRange) {
			$element.removeClass('CalendarGrid__cell--inRangeLeading CalendarGrid__cell--inRangeTrailing');
			return;
		}

		if (!$prev.length || $prev.is('[data-cell-empty]')) {
			$element.addClass('CalendarGrid__cell--inRangeLeading');
		} else if (!$next.length || $next.is('[date-cell-empty]')) {
			$element.addClass('CalendarGrid__cell--inRangeTrailing');
		}
	};

	/**
	 * Resets grid highlights to represent the range of currently selected
	 * start and end ticks. It will abort the reset if the event handler for onResetHighlight
	 * returns a false value.
	 */
	AbstractCalendarGrid.prototype.resetHighlight = function() {
		if (typeof this.onResetHighlight === 'function' && this.onResetHighlight() === false) {
			return;
		}

		this.$element.find('[data-cell-value]')
			.each(
				function(index, element) {
					var cellTick = this._getTickFromCell(element);
					var inRange = this._isInRange(cellTick);
					$(element).toggleClass('CalendarGrid__cell--inRange', inRange);

					this._toggleEdgeClasses(element, inRange);

					if (this.start && this.start.isSame(cellTick) || this.end && this.end.isSame(cellTick)) {
						$(element).addClass('CalendarGrid__cell--selected');
					}
				}.bind(this)
			);
	};

	/**
	 * Used to determine which classes should be applied to a cell based on the
	 * cell's tick and the start/end ticks
	 * @param  {object} tick Tick representing the value of a cell
	 * @return {string}      Space separated class names
	 */
	AbstractCalendarGrid.prototype._getCellClasses = function(cell) {
		var tick = cell.tick;
		var cellClasses = ['CalendarGrid__cell', 'CalendarGrid__cell--withValue'];

		if (this.start && this.start.isSame(tick) || this.end && this.end.isSame(tick, 'day')) {
			cellClasses.push('CalendarGrid__cell--selected');
		}

		var shouldHighlight = SELECTION_MODES.RANGE === this.selectionMode;
		shouldHighlight = shouldHighlight && this.start && this.start.isSameOrBefore(tick);
		shouldHighlight = shouldHighlight && this.end && this.end.isSameOrAfter(tick);

		if (shouldHighlight) {
			cellClasses.push('CalendarGrid__cell--inRange');
		}

		if (cell.isNow) {
			cellClasses.push('CalendarGrid__cell--now');
		}

		if (cell.isDisabled) {
			cellClasses.push('CalendarGrid__cell--disabled');
		}

		return cellClasses.join(' ');
	};

	/**
	 * Adds additional rows to this grid if the data does not fit inside the specified
	 * grid size
	 */
	AbstractCalendarGrid.prototype._adjustRowsForData = function(rows) {
		while (rows * this.columns < this.data.length) {
			rows++;
		}
		return rows;
	};

	/**
	 * Renders this grid with the specified data associated with each cell
	 */
	AbstractCalendarGrid.prototype.render = function() {
		if (!this.rows) {
			throw new Error('The number of rows must be specified to render a CalendarGrid');
		}

		if (!this.columns) {
			throw new Error('The number of columns must be specified to render a CalendarGrid');
		}

		var cell;
		var rows = this._adjustRowsForData(this.rows);
		var cellIndex = 0;

		var html = '';
		html += '<div class="CalendarGrid__body">';
		for (var i = 0; i < rows; i++) {
			html += '<div class="CalendarGrid__row">';

			for (var j = 0; j < this.columns; j++) {
				cell = this.data[cellIndex];
				cellIndex ++;

				if (!cell || !cell.tick) {
					html += '<div class="CalendarGrid__cell CalendarGrid__cell--empty" data-cell-empty></div>';
					continue;
				}

				html += '<div class="' + this._getCellClasses(cell) + '" data-cell-value="' + cell.tick.toISOString() + '"' + (cell.isDisabled ? 'data-cell-disabled' : '') + '>';
				html += cell.text;
				html += '</div>';
			}

			html += '</div>';
		}
		html += '</div>';

		this.$element.html(html);

		this._wireEvents();

		return this.$element;
	};



	/**
	 * Shows a grid of months for the selected year
	 * @param {number} year The default year to display
	 * or
	 * @param {object} options The options for this grid
	 */
	function YearGrid() {
		var options;
		var year = moment().year();

		if (typeof arguments[0] === 'object') {
			options = arguments[0];
		} else if (typeof arguments[1] === 'object') {
			options = arguments[1];
		} else if (typeof arguments[0] === 'number') {
			year = arguments[0];
		}

		AbstractCalendarGrid.call(this, 3, 4);

		this.options = $.extend(true, {}, options);

		this.displayingTick = moment().startOf('year').year(year);

		this.data = this._generateGridData();
	}

	YearGrid.prototype = Object.create(AbstractCalendarGrid.prototype);

	YearGrid.prototype._getDisplayTick = function(year) {
		year = (typeof year === 'number') ? year : moment().year();
		return moment().startOf('year').year(year);
	};

	YearGrid.prototype.setYear = function(year) {
		if (typeof year !== 'number') {
			throw new Error('Invalid year argument');
		}

		this.displayingTick = this._getDisplayTick(year);
		this.data = this._generateGridData();
	};

	YearGrid.prototype._generateGridData = function() {
		var startOfYear = moment(this.displayingTick);
		var endOfYear = moment(this.displayingTick).endOf('year');
		var now = moment();

		var min = this.options.min;
		var max = this.options.max;

		var data = [];
		var tick = startOfYear;
		while (tick.isBefore(endOfYear)) {
			data.push({
				isDisabled: min && min.isAfter(tick, 'month') || max && max.isBefore(tick, 'month'),
				isNow: now.isSame(tick, 'month'),
				text: tick.format('MMM'),
				tick: tick
			});
			tick = moment(tick).add(1, 'months');
		}

		return data;
	};

	/**
	 * Shows a grid of days for the selected month
	 * @param {number} year The default year to display
	 * @param {number} month The default month to display
	 * @param {object} options The configuration options
	 * or
	 * @param {object} options The configuration options
	 */
	function MonthGrid() {
		var month = (typeof arguments[0] === 'number') ? arguments[0] : moment().month();
		var year = (typeof arguments[1] === 'number') ? arguments[1] : moment().year();
		var options = (arguments.length === 3 && typeof arguments[2] === 'object') ? arguments[2] : arguments[0];

		AbstractCalendarGrid.call(this, 4, 7);

		this.options = $.extend(true, {}, options);

		this.setMonth(month, year);

		this.data = this._generateGridData();
	}

	MonthGrid.prototype = Object.create(AbstractCalendarGrid.prototype);

	MonthGrid.prototype._getDisplayingTick = function(month, year) {
		year = (typeof year === 'number') ? year : moment().year();
		month = (typeof month === 'number') ? month : moment().month();

		return moment(new Date(year, month));
	};

	MonthGrid.prototype.setMonth = function(month, year) {
		if (typeof month !== 'number') {
			throw new Error('Invalid year argument');
		}
		this.displayingTick = this._getDisplayingTick(month, year);
		this.data = this._generateGridData();
	};

	MonthGrid.prototype._generateGridData = function() {
		var startOfMonth = moment(this.displayingTick).startOf('month');
		var endOfMonth = moment(this.displayingTick).endOf('month');
		var now = moment();
		const {min, max, disableWeekends, disableDays} = this.options;

		var data = new Array(startOfMonth.weekday());
		var tick = startOfMonth;
		while (tick.isBefore(endOfMonth)) {
			const minDisabled = min && min.isAfter(tick, 'day');
			const maxDisabled = max && max.isBefore(tick, 'day');
			const weekendDisabled = disableWeekends && Util.isWeekend(tick);
			const dayDisabled = disableDays.indexOf(tick.format('YYYY-MM-DD')) > -1;

			data.push({
				isDisabled: minDisabled || maxDisabled || weekendDisabled || dayDisabled,
				isNow: now.isSame(tick, 'day'),
				text: tick.format('D'),
				tick: tick
			});

			tick = moment(tick).add(1, 'days');
		}
		return data;
	};

	MonthGrid.prototype.renderDaysOfWeek = function() {
		var startOfWeek = moment().startOf('week');
		var endOfWeek = moment().endOf('week');

		var tick = startOfWeek;
		var html = '';
		html += '<div class="CalendarGrid__daysOfWeek">';
		while (tick < endOfWeek) {
			html += '<div class="CalendarGrid__dayOfWeek">' + ifFeature('encore', tick.format('dd').substr(0).charAt(0), tick.format('dd').substr(0)) + '</div>';
			tick.add(1, 'days');
		}
		html += '</div>';

		return $(html);
	};

	MonthGrid.prototype.render = function() {
		var $element = AbstractCalendarGrid.prototype.render.call(this);

		var $head = $('<div class="CalendarGrid__header">');
		$head.append(this.renderDaysOfWeek);

		$element.prepend($head);
		return $element;
	};

	/**
	 * An abstract class for grid managers that encapsulates common behavior
	 * for tying multiple grids together and changing their display periods.
	 *
	 * @constructor
	 *
	 * @param {object} options Configuration options for the grid manager
	 */
	function AbstractCalendarGridManager(options) {
		this.options = $.extend(true, {}, this._getDefaultOptions(), options);

		this.events = new EventRegistry();

		this._previousViewState = {};

		this.$element = $('<div class="CalendarGridManager">');
	}

	AbstractCalendarGridManager.prototype.setOptions = function(options) {
		this.options = $.extend(true, {}, this._getDefaultOptions(), this.options, options);

		var gridOptions = {
			min: options.min,
			max: options.max,
			disableWeekends: this.options.disableWeekends,
			disableDays: this.options.disableDays,
		};

		if (this.firstGrid) {
			this.firstGrid.setOptions(gridOptions);
		}

		if (this.lastGrid) {
			this.lastGrid.setOptions(gridOptions);
		}

		this.render();
	}


	AbstractCalendarGridManager.prototype.getMin = function(options) {
		options = options || this.options;

		let min = moment(options.min);

		if (min.isAfter(this.start)) {
			min = this.start;
		}

		return min;
	};

	AbstractCalendarGridManager.prototype.getMax = function(options) {
		options = options || this.options;

		let max = moment(options.max);

		if (max.isBefore(this.end) && options.selectionMode === SELECTION_MODES.RANGE) {
			max = this.end;
		} else if (max.isBefore(this.start)) {
			max = this.start;
		}

		return max;
	};

	AbstractCalendarGridManager.prototype._getDefaultOptions = function() {
		return {
			autoSync: true,
			display: 'month',
			dualGrid: false,
			end: null,
			max: moment().add(20, 'years'),
			min: moment().subtract(100, 'years'),
			selectionMode: SELECTION_MODES.SINGLE,
			start: moment(),
			disableWeekends: false,
			disableDays: [],
		};
	}

	AbstractCalendarGridManager.prototype._wireGrids = function() {
		this.firstGrid.selectionMode = this.options.selectionMode;
		if (this.options.dualGrid) {
			this.lastGrid.selectionMode = this.options.selectionMode;
		}
	};

	AbstractCalendarGridManager.prototype._wireEvents = function() {
		this.$element.off('.calendarGridManager');

		this.$element.on('mouseleave.calendarGridManager',
			function() {
				this.firstGrid.resetHighlight();
				if (this.lastGrid) {
					this.lastGrid.resetHighlight();
				}
			}.bind(this)
		);

		this.$element.on('click.calendarGridManager', '[data-prev-button]',
			function() {
				if (this._isPreviousDisabled()) {
					return;
				}

				this.previous();
				this.render();
				this.events.trigger('periodChange');
			}.bind(this)
		);

		this.$element.on('click.calendarGridManager', '[data-next-button]',
			function() {
				if (this._isNextDisabled()) {
					return;
				}

				this.next();
				this.render();
				this.events.trigger('periodChange');
			}.bind(this)
		);

		this.$element.on('selectstart', function(e) {
			e.preventDefault();
		});

		bindGridEvents.call(this);

		function bindGridEvents() {
			this.firstGrid.events.off('cellSelect', this._boundSelectHandler);
			if (this.options.dualGrid) {
				this.lastGrid.events.off('cellSelect', this._boundSelectHandler);
			}

			this._boundSelectHandler = this._selectHandler.bind(this);

			this.firstGrid.events.on('cellSelect', this._boundSelectHandler);

			if (this.options.dualGrid) {
				this.lastGrid.events.on('cellSelect', this._boundSelectHandler);

				this.firstGrid.events.off('cellHover', this._boundHoverHandler);
				this.lastGrid.events.off('cellHover', this._boundHoverHandler);

				this._boundHoverHandler = this._hoverHandler.bind(this);

				this.firstGrid.events.on('cellHover', this._boundHoverHandler);
				this.lastGrid.events.on('cellHover', this._boundHoverHandler);
			}
		}
	};

	AbstractCalendarGridManager.prototype._selectHandler = function(selecting, start, end) {
		this.events.trigger('select', selecting, start, end);

		if (this.options.autoSync) {
			this.updateSelection(selecting, start, end);
			this.render();
		}
	};

	/**
	 * Determines if a re-render is necessary based on whether or not the viewState
	 * has changed since the last render
	 * @return {boolean} True if the view requires a render
	 */
	AbstractCalendarGridManager.prototype._needsRender = function() {
		var currentViewState = {
			displayTick: this.displayTick,
			end: this.end,
			selecting: this.selecting,
			start: this.start,
		};

		return !Util.isEqual(this._previousViewState, currentViewState);
	};

	AbstractCalendarGridManager.prototype._isDisplayTickChanged = function() {
		return this._previousState.displayTick !== this.displayTick;
	};

	AbstractCalendarGridManager.prototype._hoverHandler = function(tick) {
		this.firstGrid.highlightToTick(tick);
		this.lastGrid.highlightToTick(tick);
	};

	AbstractCalendarGridManager.prototype._getGridHeaderContent = function(grid) {
		return '<div class="CalendarGridManager__gridTitle">' + grid.displayingTick.format(this.titleFormat) + '</div>';
	};

	AbstractCalendarGridManager.prototype._isNextDisabled = function() {
		return false;
	};

	AbstractCalendarGridManager.prototype._isPreviousDisabled = function() {
		return false;
	};

	AbstractCalendarGridManager.prototype._renderGrid = function(grid, directionControls) {
		var headerClasses = [''];
		var previousButtonClasses = ['CalendarGridManager__previousButton'];
		var nextButtonClasses = ['CalendarGridManager__nextButton'];

		var $grid = null;
		var html = '';

		if (directionControls === 'previous') {
			headerClasses.push('CalendarGridManager__gridHeader--paddedRight');
		} else if (directionControls === 'next') {
			headerClasses.push('CalendarGridManager__gridHeader--paddedLeft');
		}

		if (this._isPreviousDisabled()) {
			previousButtonClasses.push('CalendarGridManager__previousButton--disabled');
		}

		if (this._isNextDisabled()) {
			nextButtonClasses.push('CalendarGridManager__nextButton--disabled');
		}

		html += '<div class="CalendarGridManager__grid">';
		html += '	<div class="CalendarGridManager__gridHeader' + headerClasses.join(' ') + '">';

		if (directionControls === 'both' || directionControls === 'previous') {
			ifFeature('encore', () => {
				html += '		<button class="CalendarGridManager__button" data-prev-button>';
				html += '			<ba-icon encore-name="caret-left-solid" encore-color="neutral-extra-strong" class="' + previousButtonClasses.join(' ') + '"></ba-icon>'
				html += '		</button>'
			}, () => {
				html += '	<div class="' + previousButtonClasses.join(' ') + '" data-prev-button></div>';
			})();
		}

		html += '		<div class="CalendarGridManager__gridHeaderContent js-CalendarGridManager__gridHeaderContent"></div>';

		if (directionControls === 'both' || directionControls === 'next') {
			
			ifFeature('encore', () => {
				html += '		<button class="CalendarGridManager__button" data-next-button>';
				html += '			<ba-icon encore-name="caret-right-solid" encore-color="neutral-extra-strong" class="' + previousButtonClasses.join(' ') + '" ></ba-icon>';
				html += '		</button>'
			}, () => {
				html += '	<div class="' + nextButtonClasses.join(' ') + '" data-next-button></div>';
			})();
		}

		html += '	</div>';
		html += '</div>';

		$grid = $(html).append(grid.render());

		$grid.find('.CalendarGridManager__gridHeaderContent')
			.append(this._getGridHeaderContent(grid));

		return $grid;
	};

	/**
	 * Syncs the selection state of grids contained inside of the popover
	 * @param  {string} selecting Which boundary is being currently selected
	 * @param  {string} start     The start tick
	 * @param  {string} end       The end tick
	 */
	AbstractCalendarGridManager.prototype.updateSelection = function(selecting, start, end) {
		this.start = start;
		this.end = end;
		this.selecting = selecting;

		this.firstGrid.start = this.start;
		this.firstGrid.end = this.end;
		this.firstGrid.selecting = this.selecting;

		if (this.options.dualGrid) {
			this.lastGrid.start = this.start;
			this.lastGrid.end = this.end;
			this.lastGrid.selecting = this.selecting;
		}
	};

	AbstractCalendarGridManager.prototype.next = function() {
		throw new Error('This method should be implemented by a child class');
	};

	AbstractCalendarGridManager.prototype.previous = function() {
		throw new Error('This method should be implemented by a child class');
	};

	AbstractCalendarGridManager.prototype.render = function() {
		if (!this._needsRender()) {
			return;
		}

		this._previousViewState = {
			displayTick: this.displayTick,
			end: this.end,
			selecting: this.selecting,
			start: this.start
		};

		this.$element.empty();

		var $grids =  $('<div class="CalendarGridManager__grids">').appendTo(this.$element);
		var $firstGrid = this._renderGrid(this.firstGrid, (this.options.dualGrid) ? 'previous' : 'both');
		$firstGrid.appendTo($grids);

		var $lastGrid;
		if (this.options.dualGrid) {
			$lastGrid = this._renderGrid(this.lastGrid, 'next');
			$lastGrid.appendTo($grids);
		}

		this._wireEvents();

		this.events.trigger('render');

		return this.$element;
	};



	/**
	 * A container that renders one or two years at a time
	 *
	 * @constructor
	 * @extends AbstractCalendarGridManager
	 *
	 * @param {number} year    The first year
	 * @param {object} options Options to display how months are selected in the year,
	 * or @param {object} options Options to display how months are selected in the year
	 */
	function YearGridManager() {
		var year = (typeof arguments[0] === 'number') ? arguments[0] : moment().year();
		var options = (arguments.length === 2) ? arguments[1] : arguments[0];

		AbstractCalendarGridManager.call(this, options);

		this.titleFormat = 'YYYY';

		var gridOptions = {
			min: options.min,
			max: options.max
		};

		this.firstGrid = this.lastGrid = new YearGrid(year, gridOptions);
		if (this.options.dualGrid) {
			this.lastGrid = new YearGrid(year, gridOptions);
		}
		this._wireGrids();

		this.setYear(year);
	}

	YearGridManager.prototype = Object.create(AbstractCalendarGridManager.prototype);

	YearGridManager.prototype._getDisplayTick = function(year) {
		year = (typeof year === 'number') ? year : moment().year();
		return moment().startOf('year').year(year);
	};

	YearGridManager.prototype._isPreviousDisabled = function() {
		return moment(this.firstGrid.displayingTick).isSameOrBefore(this.getMin(), 'year');
	};

	YearGridManager.prototype._isNextDisabled = function() {
		return moment(this.lastGrid.displayingTick).isSameOrAfter(this.getMax(), 'year');
	};

	YearGridManager.prototype._getGridHeaderContent = function(grid) {
		var $yearDropdown;
		var disableDropdown = false;
		var displayingTick = grid.displayingTick;
		var max = this.getMax();
		var min = this.getMin();

		if (this.options.quickNav === 'disabled' || this.options.quickNav === 'auto' && this.options.dualGrid) {
			return AbstractCalendarGridManager.prototype._getGridHeaderContent.call(this, grid);
		}

		// Disables dropdown and makes it so that the dropdown shows the correct year
		if (displayingTick.isAfter(max, 'year')) {
			max.add(1, 'year');
			disableDropdown = true;
		}

		// Disables dropdown and makes it so that the dropdown shows the correct year
		if (displayingTick.isBefore(min, 'year')) {
			min.subtract(1, 'year');
			disableDropdown = true;
		}

		var $yearDropdown = Util.createYearDropdown(min, max, displayingTick);
		$yearDropdown.prop('disabled', disableDropdown);
		$yearDropdown.on('change', function(e) {
			var year = parseInt($(e.target).val(), 10);

			if (grid !== this.firstGrid && grid === this.lastGrid) {
				year--;
			}

			this.setYear(year);
			this.render();

			this.events.trigger('periodChange');
		}.bind(this));

		return $yearDropdown.addClass('CalendarGridManager__gridHeaderDropdown');
	};

	YearGridManager.prototype.setYear = function(year) {
		this.displayTick = this._getDisplayTick(year);
		this.firstGrid.setYear(year);

		var secondGridDisplayTick = moment(this.displayTick).add(1, 'years');
		if (this.options.dualGrid) {
			this.lastGrid.setYear(secondGridDisplayTick.year());
		}
	};

	YearGridManager.prototype.previous = function() {
		var newTick = moment(this.displayTick).subtract(1, 'year');
		this.setYear(newTick.year());
	};

	YearGridManager.prototype.next = function() {
		var newTick = moment(this.displayTick).add(1, 'year');
		this.setYear(newTick.year());
	};

	/**
	 * A container that renders one or two months at a time
	 *
	 * @constructor
	 * @extends AbstractCalendarGridManager
	 *
	 * @param {number} month   The first month
	 * @param {number} year    The first year
	 * @param {object} options Options to display how days are selected in the month,
	 */
	function MonthGridManager() {
		var month = (typeof arguments[0] === 'number') ? arguments[0] : moment().month();
		var year = (typeof arguments[1] === 'number') ? arguments[1] : moment().year();
		var options = (arguments.length === 3 && typeof arguments[2] === 'object') ? arguments[2] : arguments[0];
		var gridOptions = {
			min: options.min,
			max: options.max,
			disableWeekends: options.disableWeekends,
			disableDays: options.disableDays,
		};

		AbstractCalendarGridManager.call(this, options);

		this.titleFormat = 'MMMM YYYY';

		this.firstGrid = this.lastGrid = new MonthGrid(month, year, gridOptions);
		if (this.options.dualGrid) {
			this.lastGrid = new MonthGrid(month, year, gridOptions);
		}
		this._wireGrids();

		this.setMonth(month, year);
	}

	MonthGridManager.prototype = Object.create(AbstractCalendarGridManager.prototype);

	MonthGridManager.prototype._getDisplayTick = function(month, year) {
		year = (typeof year === 'number') ? year : moment().year();
		month = (typeof month === 'number') ? month : moment().month();

		return moment(new Date(year, month));
	};

	MonthGridManager.prototype._isPreviousDisabled = function() {
		return moment(this.firstGrid.displayingTick).isSameOrBefore(this.getMin(), 'month');
	};

	MonthGridManager.prototype._isNextDisabled = function() {
		return moment(this.lastGrid.displayingTick).isSameOrAfter(this.getMax(), 'month');
	};

	MonthGridManager.prototype._getGridHeaderContent = function(grid) {
		var $monthDropdown, $yearDropdown;
		var disableDropdowns = false;
		var displayingTick = grid.displayingTick;

		var max = this.getMax();
		var min = this.getMin();

		if (this.options.quickNav === 'disabled' || this.options.quickNav === 'auto' && this.options.dualGrid) {
			return AbstractCalendarGridManager.prototype._getGridHeaderContent.call(this, grid);
		}

		// Disables dropdown and makes it so that the dropdowns show the correct month/year
		if (displayingTick.isAfter(max, 'month')) {
			max = max.add(1, 'months');
			disableDropdowns = true;
		}

		// Disables dropdown and makes it so that the dropdowns show the correct month/year
		if (displayingTick.isBefore(min, 'month')) {
			min = min.subtract(1, 'months');
			disableDropdowns = true;
		}

		$yearDropdown = Util.createYearDropdown(min, max, displayingTick);
		$yearDropdown.prop('disabled', disableDropdowns);
		$yearDropdown.on('change', function(e) {
			var year = parseInt($(e.target).val(), 10);
			var month = displayingTick.month();

			if (grid !== this.firstGrid && grid === this.lastGrid) {
				month = displayingTick.subtract(1, 'months').month();
			}

			this.setMonth(month, year);

			this.render();

			this.events.trigger('periodChange');
		}.bind(this));

		$monthDropdown = Util.createMonthDropdown(min, max, displayingTick);
		$monthDropdown.prop('disabled', disableDropdowns);
		$monthDropdown.on('change', function(e) {
			var month = parseInt($(e.target).val(), 10);
			var monthTick = moment().month(month).year(displayingTick.year());

			if (grid !== this.firstGrid && grid === this.lastGrid) {
				monthTick.subtract(1, 'months');
			}

			this.setMonth(monthTick.month(), monthTick.year());
			this.render();

			this.events.trigger('periodChange');
		}.bind(this));

		return $().add($monthDropdown).add($yearDropdown).addClass('CalendarGridManager__gridHeaderDropdown');
	};

	MonthGridManager.prototype.setMonth = function(month, year) {
		this.displayTick = this._getDisplayTick(month, year);
		this.firstGrid.setMonth(this.displayTick.month(), this.displayTick.year());

		var secondGridDisplayTick = moment(this.displayTick).add(1, 'months');
		if (this.options.dualGrid) {
			this.lastGrid.setMonth(secondGridDisplayTick.month(), secondGridDisplayTick.year());
		}
	};

	MonthGridManager.prototype.previous = function() {
		var newTick = moment(this.displayTick).subtract(1, 'months');
		this.setMonth(newTick.month(), newTick.year());
	};

	MonthGridManager.prototype.next = function() {
		var newTick = moment(this.displayTick).add(1, 'months');
		this.setMonth(newTick.month(), newTick.year());
	};

	return CalendarPicker;
})($, moment);
