import { sanitize } from '@bamboohr/utils/lib/dom';

const SANITIZE_OPTIONS = {
	ADD_ATTR: ['brand', 'name', 'esig', 'size', 'encore-color', 'encore-class', 'encore-name', 'encore-size'], // all the ba-icon and ba-file-icon attrs
};

const SINGLE_SUBMITTABLE_INPUT_QUERY = 'input:not([type="hidden"]), textarea, .fab-Select, select';

export function sanitizeDangerousContent(dangerousContent) {
	return sanitize(dangerousContent, SANITIZE_OPTIONS);
}

/**
 * Whether or not supplied element's children match the supplied query only once
 * @param   {HTMLElement}   element
 * @param   {string}        query
 * @returns {boolean}
 */
export function elementContainsSingleQueryMatch(element, query) {
	if (!element || !(element instanceof HTMLElement)) {
		return false;
	}
	return element.querySelectorAll(String(query)).length === 1;
}

/**
 * Whether or not supplied element contains a single child that matches against the
 * query list of "submittable" input elements, for fabric and/or modal purposes
 * @param   {HTMLElement}  element
 * @returns {boolean}
 */
export function elementContainsSingleSubmittableInput(element) {
	if (!element || !(element instanceof HTMLElement)) {
		return false;
	}
	return elementContainsSingleQueryMatch(element, SINGLE_SUBMITTABLE_INPUT_QUERY);
}

export function resetBodyStyles() {
	document.body.style.position = '';
	document.body.style.top = '';
	window.scrollTo(0, scrollY);
}
