import {
	useState,
} from 'react';
import classnames from 'classnames';

import { makeStyles } from '@bamboohr/fabric';
import {
	Select,
} from '@fabric/select';

const useStyles = makeStyles(({ palette, typography}) => ({
	optionNote: {
		fontSize: typography?.teenie?.fontSize,
	},
	optionNoteNotActive: {
		color: palette.gray[700],
	}
}))

export default function EmployeeAccessLevelSelect({
	employeeAccessLevels = [],
	employeeAccessLevel,
	onChange,
}) {
	const styles = useStyles();
	const [selectedValue, setSelectedValue] = useState(employeeAccessLevel);
	
	const createOptionContent = (option, optionState) => {
		if (option.value === 'full') {
			return (
				<div>
					<div>{option.text}</div>
					<div className={classnames(styles.optionNote, { [styles.optionNoteNotActive]: !optionState.isActive})}>
						{ $.__('Allows them to see the same info about themselves as they can see about others.') }
					</div>
				</div>
			);
		}

		return option.text;
	}

	return (
		<Select
			id="AccessLevelsSettingsModal__employeeAccessLevel"
			isClearable={ false }
			items={ employeeAccessLevels }
			onChange={ ([value]) => {
				setSelectedValue(value);

				if (typeof onChange === 'function') {
					onChange(value);
				}
			} }
			selectedValues={ [selectedValue] }
			width={ 7 }
			renderOptionContent={createOptionContent}
		/>
	);
}
