/**********************************************************
 * Imports
 *********************************************************/
import {
	extend,
	isFunction,
	noop
} from 'lodash';
import { ifFeature, isEnabled } from '@bamboohr/utils/lib/feature';
import {
	fragFromHtml,
	getMaxZIndex,
} from '@utils/dom';

import {position} from './_positioner';

import jadeBalloonHTML from './_jade-balloon.html';
import encoreBalloonHTML from './_encore-balloon.html';
import GlobalBalloonTemplates from './_global-templates/global-templates';


/**********************************************************
 * State and Base Configs
 *********************************************************/

/**
 * Holds all the current Balloon instances.
 * Allows us to do operations on all Balloons at once (e.g. Balloons.each(...))
 *
 * @type {Array}
 */
let allBalloonInstances = [];


/**
 * We only add events to the window when a balloon is showing. And we only want to add
 * events if the events are currently not added. There is the possibility of multiple
 * balloons being open at once. Therefore, we must keep track of if events have been
 * added or not
 *
 * @type {boolean}
 */
let windowEventsAdded = false;


/**********************************************************
 * Event Management
 *********************************************************/

/**
 * Called when show() or hide() is called by the balloon instances
 * It checks to see if events should be added, or removed
 */
function attachOrRemoveWindowEvents() {
	const openBalloons = allBalloonInstances.filter(balloon => balloon.showing);
	if (openBalloons.length && !windowEventsAdded) {
		addWindowEvents();
	} else if (!openBalloons.length && windowEventsAdded) {
		removeWindowEvents();
	}
}


/**
 * Adds the event listeners to the window needs to re-position, close, etc
 */
function addWindowEvents() {
	window.addEventListener('click', windowClick);

	windowEventsAdded = true;
}


/**
 * Removes the event listeners from the window needed to re-position, close, etc
 */
function removeWindowEvents() {
	window.removeEventListener('click', windowClick);

	windowEventsAdded = false;
}


/**
 * Only active when at least one balloon is open
 * Checks to see if the click came from a balloon anchor, or balloon element and closes
 * all other balloons not associated with that click
 */
function windowClick(event) {
	Balloon.each((balloon) => {
		if (document.body.contains(event.target) && !balloon.destroyed && !balloon.balloonElement.contains(event.target) && !balloon.anchor.contains(event.target) && !balloon.settings.persistent) {
			balloon.hide();
		}
	});
}


/**********************************************************
 * Utils
 *********************************************************/

/**
 * Checks the `destroyed` property of the balloon instance and throws an error if true.
 * Used at the top of instance methods such as `show`, `hide`, and `position`.
 *
 * @param  {Balloon} instance
 * @return {undefined}
 */
function errorIfDestroyed(instance) {
	if (instance.destroyed) {
		throw new Error('This balloon has already been destroyed.');
	}
}


/**
 * Checks that a usable element was passed in, or if the argument is a string
 * it will be used as a selector to attempt a query, in either case an element
 * is returned, or if no element is found an error is thrown.
 *
 * @param {element|string} anchorOrSelector
 * @return {element}
 */
function normalizeAnchor(anchorOrSelector) {
	const anchor = (anchorOrSelector.nodeType && anchorOrSelector.nodeType === 1) ? anchorOrSelector : document.querySelector(anchorOrSelector);

	if (!anchor) {
		throw new TypeError('A DOM element, or valid selector must be used as the anchor for a Balloon');
	}

	return anchor;
}


/**********************************************************
 * Balloon Class
 *********************************************************/

export default class Balloon {
	constructor(anchor, settings) {
		this.anchor = anchor;
		this.settings = extend({
			afterCreation: noop,
			afterDestroy: noop,
			closeX: false,
			correctBodyPosition: true,
			delay: 0,
			fadeIn: 200,
			fadeOut: 200,
			html: null,
			invertOnCollision: false,
			onClose: noop,
			onHide: noop,
			onShow: noop,
			persistent: false,
			position: 'top',
			push: 0,
			showImmediately: false,
			state: {},
			suppressed: false,
			tail: false,
			tailSize: 0,
			tailBackground: null,
			tailBorder: null,
			tailOnAnchor: '50%',
			flipTailOnAnchor: false,
			tailOnBalloon: '50%',
			minTailOnBalloon: 0,
			flipTailOnBalloon: false,
			template: null,
			themeClass: 'Balloon',
			toggleOnClick: true,
			triggerEvent: 'click',
			noPadding: false,
		}, settings);

		this.eventCallbacks = {
			afterCreation: this.settings.afterCreation,
			afterDestroy: this.settings.afterDestroy,
			close: [this.settings.onClose],
			hide: [this.settings.onHide],
			show: [this.settings.onShow],
		};

		// Bound handlers for use in add/remove EventListeners
		this._boundClickEventHandler = this._clickEventHandler.bind(this);
		this._boundMouseEnterEventHandler = this._mouseEnterEventHandler.bind(this);
		this._boundMouseLeaveEventHandler = this._mouseLeaveEventHandler.bind(this);

		this.create();
	}

	create() {
		allBalloonInstances.push(this);

		this.state = this.settings.state;
		this.balloonElement = null;
		this.created = false;
		this.destroyed = false;
		this.showing = false;
		this._showTimer = null;
		this._positionLock = {};

		this._createBalloon();

		if (this.settings.showImmediately) {
			this.show();
		}

		this._setAnchorElementEvents();

		return this;
	}

	/**
	 * Shows the balloon. The balloon element is only created upon showing for the first time.
	 *
	 * @returns {Balloon}
	 */
	show() {
		errorIfDestroyed(this);

		if (this.showing) {
			return this;
		}

		if (!this.created) {
			this._setupCloseButton();
			// @startCleanup encore
			if (!isEnabled('encore')) {
				this._setTailStyles();
			}
			// @endCleanup encore

			document.body.appendChild(this.balloonElement);

			this.created = true;

			this.eventCallbacks.afterCreation(this);
			this.anchor.dispatchEvent(new CustomEvent('ba:balloonAfterCreation', {bubbles: true}));
		}

		if (!this.destroyed) {
			this.balloonElement.style.display = 'block';
			/**
			 * Normally, when you try to transition an element that was display: none,
			 * it will not work because the draw is batched by the browser.
			 * But in this case, a redraw on the element occurrs inside of getMaxZIndex()
			 * which allows the element to transition
			 */
			Object.assign(this.balloonElement.style, {
				transitionDuration: `${this.settings.fadeIn  }ms`,
				transitionDelay: `${this.settings.delay  }ms`,
				zIndex: getMaxZIndex() + 10,
				opacity: '1'
			});

			this.showing = true;

			this._position();

			this.eventCallbacks.show.forEach(callback => callback(this));
			this.anchor.dispatchEvent(new CustomEvent('ba:balloonShow', {bubbles: true}));

			attachOrRemoveWindowEvents();
		}

		return this;
	}

	/**
	 * Hides the Balloon
	 *
	 * @returns {Balloon}
	 */
	hide() {
		errorIfDestroyed(this);

		if (!this.showing) {
			return this;
		}

		const currentOpacity = window.getComputedStyle(this.balloonElement).opacity;

		if (this.settings.fadeOut && currentOpacity !== '0') {
			Object.assign(this.balloonElement.style, {
				opacity: '0',
				transitionDuration: `${this.settings.fadeOut  }ms`,
				transitionDelay: '',
				zIndex: 'auto',
			});
		} else {
			Object.assign(this.balloonElement.style, {
				opacity: '0',
				display: ''
			});

			this._unPosition();
		}

		this.showing = false;

		this.eventCallbacks.hide.forEach(callback => callback(this));
		this.anchor.dispatchEvent(new CustomEvent('ba:balloonHide', {bubbles: true}));

		attachOrRemoveWindowEvents();

		return this;
	}

	/**
	 * Hides the Balloon if showing, shows if hidden
	 *
	 * @returns {Balloon}
	 */
	toggleBalloon() {
		if (this.showing) {
			this.hide();
		} else {
			this.show();
		}
		return this;
	}

	/**
	 * Deprecated in favor of a private position method (_position()).
	 * If you need to reset the _positionLock, use .resetPositionLock()
	 *
	 * @deprecated
	 * @returns {Balloon}
	 */
	position() {
		window.Rollbar.info('The deprecated Balloon position() method was called.');
		return this;
	}

	/**
	 * Forces a reset on the _positionLock whether the Balloon is open or not
	 *
	 * @returns {Balloon}
	 */
	resetPositionLock() {
		this._positionLock = {};
		return this;
	}

	/**
	 * Destroys a balloon. Removes the Balloon instance from allBalloonInstances and returns undefined
	 *
	 * @returns {undefined}
	 */
	destroy() {
		errorIfDestroyed(this);

		if (this.created && !this.destroyed) {
			this.destroyed = true;
			this.balloonElement.parentNode.removeChild(this.balloonElement);
			this.balloonElement = null;

			this.eventCallbacks.afterDestroy(this);
			this.anchor.dispatchEvent(new CustomEvent('ba:balloonAfterDestroy', {bubbles: true}));
		}

		this._removeAnchorElementEvents();
		allBalloonInstances = allBalloonInstances.filter(instance => instance !== this);

		attachOrRemoveWindowEvents();
	}

	/**
	 * Some Balloons should only be able to open one time oer page view. When a balloon is suppressed,
	 * it can not be opened unless you programmatically call .show() on the instance
	 *
	 * @returns {Balloon}
	 */
	suppress() {
		this.hide();
		this.settings.suppressed = true;
		return this;
	}

	/**
	 * Stops a Balloon from being suppressed.
	 *
	 * @returns {Balloon}
	 */
	release() {
		this.settings.suppressed = false;
		return this;
	}


	/****************************
	 * Event driven methods
	 ****************************/

	/**
	 * Used to add callbacks that need to be fired when the Balloon is shown
	 *
	 * @param callback
	 * @returns {Balloon}
	 */
	onShow(callback) {
		this._addEventCallback('show', callback);
		return this;
	}

	/**
	 * Used to remove callbacks that fire when a Balloon is shown
	 *
	 * @param callback
	 * @returns {Balloon}
	 */
	offShow(callback) {
		this._removeEventCallback('show', callback);
		return this;
	}

	/**
	 * Used to add callbacks that need to be fired when the Balloon is hidden
	 *
	 * @param callback
	 * @returns {Balloon}
	 */
	onHide(callback) {
		this._addEventCallback('hide', callback);
		return this;
	}

	/**
	 * Used to remove callbacks that fire when a Balloon is hidden
	 *
	 * @param callback
	 * @returns {Balloon}
	 */
	offHide(callback) {
		this._removeEventCallback('hide', callback);
		return this;
	}

	/**
	 * Used to add callbacks that need to be fired specifically when the
	 * Balloon's close X button is clicked
	 *
	 * @param callback
	 * @returns {Balloon}
	 */
	onClose(callback) {
		this._addEventCallback('close', callback);
		return this;
	}

	/**
	 * Used to remove callbacks that fire when the close X button is clicked
	 *
	 * @param callback
	 * @returns {Balloon}
	 */
	offClose(callback) {
		this._removeEventCallback('close', callback);
		return this;
	}

	/**
	 * Update the anchor for the Balloon
	 *
	 * @param anchorOrSelector
	 * @returns {Balloon}
	 */
	setAnchor(anchorOrSelector) {
		this._removeAnchorElementEvents();
		this.anchor = normalizeAnchor(anchorOrSelector);
		this._setAnchorElementEvents();
		return this;
	}

	/**
	 * Update the colors of the balloon tail, border or fill
	 * An object is passed with same key names as you would set in the original config
	 * OR, you can pass a string that will set both
	 *
	 * @param stringOrObj
	 * @returns {Balloon}
	 */
	setTailColors(stringOrObj) {
		if (typeof stringOrObj === 'string') {
			this.settings.tailBackground = stringOrObj;
			this.settings.tailBorder = stringOrObj;
		} else if (typeof stringOrObj === 'object') {
			this.settings.tailBackground = stringOrObj.tailBackground || this.settings.tailBackground;
			this.settings.tailBorder = stringOrObj.tailBorder || this.settings.tailBorder;
		}
		// @startCleanup encore
		if (!isEnabled('encore')) {
			this._setTailStyles();
		}
		// @endCleanup encore
		return this;
	}


	/****************************
	 * Pseudo private methods
	 ****************************/

	/**
	 * Calls to a positioning utility for positioning the Balloon element relative
	 * to the associated anchor element. This is private because it recursively calls itself
	 * as long as the balloon is open, which means no developer will ever need to call this.
	 *
	 * @param resetLock {boolean}
	 * @private
	 */
	_position(resetLock = true) {
		errorIfDestroyed(this);

		if (this.created && this.showing) {
			if (resetLock) {
				this._positionLock = {};
			}

			// Positions the balloon body, and tail (wanted to break the code out)
			position.call(this);

			window.requestAnimationFrame(() => {
				if (!this.destroyed) {
					this._position(false);
				}
			});
		}
	}

	/**
	 * Reset the top and left inline styles so a fresh position calculation will be made on show
	 *
	 * @private
	 */
	_unPosition() {
		this.balloonElement.style.top = '';
		this.balloonElement.style.left = '';
	}

	/**
	 * Sets the events needed on the anchor element for opening the balloon
	 *
	 * @private
	 */
	_setAnchorElementEvents() {
		if (this.settings.triggerEvent === 'none') {
			return;
		}

		if (this.settings.triggerEvent === 'hover') {
			this.anchor.addEventListener('mouseenter', this._boundMouseEnterEventHandler);
			this.anchor.addEventListener('mouseleave', this._boundMouseLeaveEventHandler);
		} else {
			this.anchor.addEventListener('click', this._boundClickEventHandler);
		}
	}

	/**
	 * Remove the events from the anchor element
	 *
	 * @private
	 */
	_removeAnchorElementEvents() {
		if (this.settings.triggerEvent === 'none') {
			return;
		}

		if (this.settings.triggerEvent === 'hover') {
			this.anchor.removeEventListener('mouseenter', this._boundMouseEnterEventHandler);
			this.anchor.removeEventListener('mouseleave', this._boundMouseLeaveEventHandler);
		} else {
			this.anchor.removeEventListener('click', this._boundClickEventHandler);
		}
	}

	/**
	 * Show the balloon when mouse enters the anchor element and the triggerEvent is set to "hover"
	 * This needs to be bound to the instance (done in the constructor) so that the event can be removed
	 *
	 * @private
	 */
	_mouseEnterEventHandler() {
		if (!this.settings.suppressed) {
			this.show();
		}
	}

	/**
	 * Hide the balloon when mouse leaves the anchor element and the triggerEvent is set to "hover"
	 * This needs to be bound to the instance (done in the constructor) so that the event can be removed
	 *
	 * @private
	 */
	_mouseLeaveEventHandler() {
		this.hide();
	}

	/**
	 * Show the balloon when the anchor element is clicked and the triggerEvent is set to "click"
	 *
	 * If `toggleOnClick` is active, it will call show/hide respectively
	 *
	 * This needs to be bound to the instance (done in the constructor) so that the event can be removed
	 *
	 * @private
	 */
	_clickEventHandler() {
		if (this.settings.suppressed) {
			return;
		}

		if (this.settings.toggleOnClick) {
			this.toggleBalloon();
		} else {
			this.show();
		}
	}

	/**
	 * Creates the DOM for the Balloon. Priority is given to HTML, then to templates
	 *
	 * @private
	 */
	_createBalloon() {
		this.balloonElement = fragFromHtml(ifFeature('encore', encoreBalloonHTML, jadeBalloonHTML), {
			ADD_ATTR: ['encore-class', 'encore-name', 'encore-size'],
		}).querySelector('.js-balloon');

		let html = '';
		let template;

		if (this.settings.html) {
			html = this.settings.html;
		} else if (this.settings.template) {
			template = GlobalBalloonTemplates[this.settings.template.name];
			html = template(this.settings.template.data);
		} else {
			throw new Error('HTML, or a template must be used as content.');
		}

		this.balloonElement.style.transitionDuration = `${this.settings.fadeIn  }ms`;
		this.balloonElement.addEventListener('transitionend', (event) => {
			if (event.target === this.balloonElement && !this.showing) {
				this.balloonElement.style.display = '';
				this._unPosition();
			}
		});

		this.balloonElement.classList.add(this.settings.themeClass);

		this.balloonContentElement = this.balloonElement.querySelector('.js-balloon-content');
		if (this.settings.noPadding) {
			this.balloonContentElement.classList.add('Balloon__content--noPadding');
		}
		this.balloonContentElement.appendChild(fragFromHtml(html));
	}

	_handleOnClose = () => {
		this.hide();
		this.eventCallbacks.close.forEach(callback => callback(this));
	}

	/**
	 * Determines if the "close" button (X) should be used in the balloon. If it is used,
	 * attach an event to it that closes the balloon when clicked
	 *
	 * @private
	 */
	_setupCloseButton() {
		const closeButton = this.balloonElement.querySelector('.js-balloon-close-x');

		if (this.settings.closeX) {
			closeButton.addEventListener('click', () => {
				this._handleOnClose();
			});
		} else {
			closeButton.style.display = 'none';
		}
	}

	/**
	 * @startCleanup encore
	 * Custom colors are supported on the Balloon "tail". Sets the tail border and background-color
	 * if supplied in the settings.
	 *
	 * @private
	 */
	_setTailStyles() {
		const balloonTail = this.balloonElement.querySelector('.js-balloon-tail');
		const styles = {};

		const squared = Math.pow(this.settings.tailSize, 2);
		const hypotenuse = styles.width = Math.floor(Math.sqrt(squared + squared));

		styles.height = styles.width = `${hypotenuse + 1  }px`;
		styles.display = (this.settings.tail) ? '' : 'none';

		if (this.settings.tailBackground) {
			styles.backgroundColor = this.settings.tailBackground;
		}

		if (this.settings.tailBorder) {
			styles.borderColor = this.settings.tailBorder;
		}

		Object.assign(balloonTail.style, styles);
	}
	/**
	 * @endCleanup encore
	 */

	/**
	 * Used to add events to the balloon for when certain "actions' occur.
	 *
	 * @param event
	 * @param callback
	 * @private
	 */
	_addEventCallback(event, callback) {
		if (isFunction(callback)) {
			this.eventCallbacks[event].push(callback);
		}
	}

	/**
	 * Used to remove events on the balloon for when certain "actions' occur.
	 *
	 * @param event
	 * @param callback
	 * @private
	 */
	_removeEventCallback(event, callback) {
		this.eventCallbacks[event] = this.eventCallbacks[event]
			.filter(storedCallback => storedCallback !== callback);
	}

	/**
	 * The static method for creating a new Balloon instance.
	 *
	 * @param anchorOrSelector
	 * @param settings
	 * @returns {Balloon}
	 */
	static create(anchorOrSelector, settings) {
		const anchor = normalizeAnchor(anchorOrSelector);

		// "this" is the class (not instance) of whatever class called this static method
		return new this(anchor, settings);
	}

	/**
	 * Loops over each Balloon instance invoking the given callback with each instance of the calling class.
	 * @param {function} callback Function called with each balloon instance
	 */
	static each(callback) {
		allBalloonInstances.forEach((balloon, ...args) => {
			// "this" is the class (not instance) of whatever class called this static method
			if (balloon instanceof this) {
				callback(balloon, ...args);
			}
		});
	}

	/**
	 * Checks to see if the supplied element is an anchor for a Balloon instance.
	 * @param  {object}  element Element to check
	 * @return {boolean}         True or False if it contains a Balloon instance
	 */
	static hasInstance(element) {
		// "this" is the class (not instance) of whatever class called this static method
		return allBalloonInstances.some(balloon => (balloon instanceof this && element === balloon.anchor));
	}

	/**
	 * Gets the Balloon instance, if any, for which the element or one of its children is an anchor.
	 * @param  {element} element    Element to check
	 * @return {Balloon|undefined}  Balloon instance inside of element
	 */
	static getInstance(element) {
		return allBalloonInstances
			.slice()
			.reverse()
			.find(instance => (
				instance instanceof this &&
				(instance.balloonElement.contains(element) || instance.anchor.contains(element))
			)
			);
	}
}
