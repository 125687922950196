import { FC } from 'react';
import {
	BlankState,
	Button,
	makeStyles,
} from '@bamboohr/fabric';
import {
	EMERGENCY_CONTACT_BLANK_TITLE,
	ADD_CONTACT,
	EmergencyBlankStateProps,
} from './constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const EmergencyBlankNewEmployee: FC<EmergencyBlankStateProps> = ({ onClick, canAdd }) => {
	return (
		<BlankState
			icon={ifFeature('encore', "bs-doc-missing-field", "fab-ambulance-120x100")}
			subtitle={ EMERGENCY_CONTACT_BLANK_TITLE }
			title=""
			actions={ifFeature(
				'encore',
				canAdd && [
					<Button
						color='secondary'
						data-bi-id='employees-contacts-add-contact'
						onClick={onClick}
						startIcon='plus-regular'
						type='button'
					>
						{ADD_CONTACT}
					</Button>,
				]
			)}
		>
			{ ifFeature(
				'encore',
				undefined,
				canAdd && (
					<Button
						data-bi-id="employees-contacts-add-contact"
						onClick={ onClick }
						outline={ true }
						text={ `+ ${ ADD_CONTACT }` }
						type="button"
					/>
				)
			)}
		</BlankState>
	);
};
