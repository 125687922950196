import { FullScreenModal } from '@bamboohr/fabric';
import EmployeeTimesheet from 'time-tracking/components/timesheet.react';
import { getFocusedSheetDateSpanText } from 'time-tracking/utils';

export interface TimesheetDetailsModalContentProps {
	initialState: {
		focusedTimesheet: {
			start: string;
			end: string;
		}
	};
}

export function TimesheetDetailsModalContent({ initialState }: TimesheetDetailsModalContentProps) {
	const { focusedTimesheet } = initialState;
	const title = getFocusedSheetDateSpanText(focusedTimesheet.start, focusedTimesheet.end);

	return (
		<>
			<FullScreenModal.StandardHeadline icon='calendar-solid' text={title} />
			<FullScreenModal.Constraint>
				<EmployeeTimesheet
					initialState={initialState}
					isViewOnly={true}
					showHeader={false}
					showSheetPadDays={false}
					showTimesheetDateRangeHeader={false}
				/>
			</FullScreenModal.Constraint>
		</>
	);
}
