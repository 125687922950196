import { Fields, newEmployeeForm, getField, getSelectedValue } from 'dynamic-form';

/**
 * Based on the window Employee object (not PS Field)
 * @returns boolean
 */
export function isPayrollSynced(): boolean {
	return window.Employee && window.Employee.payrollSynced;
}

/**
 * Returns boolean if PaySchedule is in the form and has a syncing PS.
 * Otherwise it returns false if PS isn't in the form and can't determine syncing.
 *
 * @param       context
 * @returns     boolean | null
 */
export function hasSyncingPaySchedule(context: DynamicForm.Context): boolean {
	let hasSyncingPS = false;
	const PSField = getField(context, Fields.paySchedule) as DynamicForm.SelectElement;
	if (PSField) {
		// must have a pay schedule that is syncing
		hasSyncingPS = PSField.props.items.some(item => item.props && item.props['data-syncing-pay-schedule']);
	}

	return hasSyncingPS;
}

/**
 * Returns a boolean of whether the Pay Schedule is Syncing or not.
 * Handles the Payroll Checkbox field for New Employee with fallback to PS Field
 * Uses PS Field if found in form and falls back to window value
 *
 * @param context
 * @retuns boolean
 */
export function isSelectedPSSyncing(context: DynamicForm.Context): boolean {
	// if payroll checkbox is on the form, that overrides PSField, but only if true. - New Employee Form
	const PayrollCheckboxField = getField(context, newEmployeeForm.payrollCheckbox) as DynamicForm.CheckboxElement;
	if (PayrollCheckboxField) {
		// only return if true -> otherwise fallback to PS Field if available.
		if (PayrollCheckboxField.props.checked) {
			return true;
		}
	}

	const PSField = getField(context, Fields.paySchedule) as DynamicForm.SelectElement;
	if (!PSField) {
		// Pay Schedule Field is not in the form -> Fallback to window value
		return isPayrollSynced();
	}

	const {
		items,
	} = PSField.props;

	const selectedValue = getSelectedValue(PSField);
	const selectedItem = items.find(item => item.value === selectedValue);
	if (selectedItem) {
		return selectedItem.props && selectedItem.props['data-syncing-pay-schedule'];
	}

	return false;
}

/**
 * Returns a Pay Schedule ListValueId if one can be found
 *
 * @param context DynamicForm.Context
 * @returns string | null | undefined
 */
export function getPayScheduleListValueId(context: DynamicForm.Context): string | null {
	const PSField = getField(context, Fields.paySchedule) as DynamicForm.SelectElement;
	let PSValue;
	const PayrollCheckboxField = getField(context, newEmployeeForm.payrollCheckbox) as DynamicForm.CheckboxElement;

	if (PSField) {
		PSValue = getSelectedValue(PSField) as string | undefined;
	}

	// if the payroll checkbox Field is checked, but there is no PS selected, just return any id that matches the syncing state
	if (PayrollCheckboxField && PSField && !PSValue) {
		const isChecked = !!PayrollCheckboxField.props.checked;
		const matchingItem = PSField.props.items.find(item => item.props && item.props['data-syncing-pay-schedule'] === isChecked);
		if (matchingItem) {
			return matchingItem.value;
		}
	}

	return PSValue;
}
