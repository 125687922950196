import { Button, IconV2, StandardModal, TextButton } from '@bamboohr/fabric';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { noop } from 'lodash';
import { Modal } from 'modal-legacy';
import { FunctionComponent } from 'react';

type Props = {
	handleClose: Function;
	handlePrimaryAction: Function;
	data: {
		contactName: string;
		contactRelationship?: string;
	};
};

const DeleteEmergencyContactModal: FunctionComponent<Props> = ({ handleClose = noop, handlePrimaryAction = noop, data }) => {
	const encoreModal = (
		<StandardModal isOpen={true} onRequestClose={(): void => handleClose()}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton key='secondary' onClick={(): void => handleClose()} type='button'>
								{$.__('Keep Contact')}
							</TextButton>,
							<Button key='primary' onClick={(): void => handlePrimaryAction()} type='button'>
								{$.__('Delete Contact')}
							</Button>,
						]}
					/>
				}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon='trash-can-regular'
						iconColor='error-strong'
						text={$.__('Are you sure you want to delete this contact?')}
					/>
					<div className='Contact__modalContent'>
						<div className='Contact__modalName'>{data.contactName}</div>
						<div className='Contact__modalRelationship'>{data.contactRelationship}</div>
					</div>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);

	const jadeModal = (
		<Modal
			alternativeAction={null}
			headline={$.__('Are you sure you want to delete this contact?')}
			icon='fab-trash-can-43x48'
			iconColor='danger'
			isOpen={true}
			onClose={(): void => {
				handleClose();
			}}
			primaryAction={(): void => {
				handlePrimaryAction();
			}}
			primaryActionText={$.__('Delete Contact')}
			secondaryAction={(): void => {
				handleClose();
			}}
			secondaryActionText={$.__('Keep Contact')}
			title={$.__('Just Checking...')}
		>
			<div className='Contact__modalContent'>
				<div className='Contact__modalName'>{data.contactName}</div>
				<div className='Contact__modalRelationship'>{data.contactRelationship}</div>
			</div>
		</Modal>
	);
	return ifFeature('encore', encoreModal, jadeModal);
};

export default DeleteEmergencyContactModal;
