import { flow, values } from 'lodash';
import { keyBy, map, sortBy } from 'lodash/fp';
import moment from 'moment.lib';
import { getWorkWeekStartsOnObject } from 'time-tracking/utils';

export function getIsOwnTimesheet() {
	const isPreviewingAsUser = !!window.ASSUMED_USER;
	if (isPreviewingAsUser) {
		return window.ASSUMED_USER.employeeId === window.Employee.id;
	}
	return window.Employee && window.SESSION_USER.employeeId === window.Employee.id;
}

export function getIsApprovalShowing(sheet, serverTime, timesheetType, isLatestSheet, isOwnSheet) {
	// This "if" exists to avoid removing the UI to clock in/out
	// regardless of if the timesheet is approvable according to the approvable date
	if (timesheetType === 'clock' && isLatestSheet && isOwnSheet && sheet.status !== 'approved') {
		return false;
	}

	return (sheet && moment.unix(sheet.approvableDate).isBefore(serverTime));
}

export function sortDays(daysObject) {
	return flow(
		values,
		sortBy('date')
	)(daysObject);
}

export function makeWeekMetaObject(weekObject) {
	const sorted = sortDays(weekObject);
	return {
		start: sorted[0].date,
		end: sorted[sorted.length - 1].date,
	};
}

export function makeTimesheetMetaObject(timesheetObject) {
	if (!timesheetObject) {
		return {
			id: -1,
			type: 'none',
		};
	}

	const { id, holidayPayType, showEditActions } = timesheetObject;
	let { type, dailyDetails } = timesheetObject;

	if (type !== 'single' && type !== 'hour') {
		// Clock type should be "multiple" (clocking is just an add-on to multiple)
		// However, this becomes "multiple" AFTER "clock" is used to calculate isApprovalShowing
		type = 'multiple';
	}

	dailyDetails = sortDays(dailyDetails);

	return {
		id,
		type,
		start: dailyDetails[0].date,
		end: dailyDetails[dailyDetails.length - 1].date,
		holidayPayType,
		workWeekStartsOn: getWorkWeekStartsOnObject(dailyDetails),
		showEditActions,
	};
}

export function addDataToDays(daysObject, serverTime, employeeTimezone) {
	return flow(
		map((day) => {
			day.moment = moment.tz(day.date, employeeTimezone);
			day.isWeekend = (day.moment.day() === 0 || day.moment.day() === 6);
			day.isToday = day.moment.isSame(serverTime, 'day');
			day.isFuture = day.moment.isAfter(serverTime, 'day');
			day.userEdit = null;
			return day;
		}),
		keyBy('date')
	)(daysObject);
}
