import {
	Iterable,
	List,
	Map,
	OrderedMap,
	OrderedSet,
	Set,
	Stack
} from 'immutable';

/**
 * Returns an immutable type from an immutable data object
 * @param  {Object} data The immutable onject to check
 * @return {Object}      The immutable type
 */
export function getType(data) {
	if (!Iterable.isIterable(data)) {
		throw new Error('The data parameter must be an immutable data object.');
	}

	if (List.isList(data)) {
		return List;
	} else if (Stack.isStack(data)) {
		return Stack;
	} else if (OrderedMap.isOrderedMap(data)) {
		return OrderedMap;
	
	} else if (OrderedSet.isOrderedSet(data)) {
		return OrderedSet;
	} else if (Set.isSet(data)) {
		return Set;
	}

	return Map;
}
