import axios from 'axios';

export const getInstalledApps = async () => {
	try {
		const response = await axios.get('/settings/account/installed_apps');
		return response.data.installedApps;
	} catch (error) {
		console.error('Error fetching installed apps:', error);
		return [];
	}
};
