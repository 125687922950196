import './styles.styl';
import {round} from 'lodash';
import {Component} from 'react';
import {BarChart} from 'Charts.mod';
import {getBrandColor} from 'BambooHR.util';
import colors from '@bamboohr/fabric/dist/definitions/json/colors.json';
import themes from '@bamboohr/fabric/dist/definitions/json/theme-colors.json';

const brandTheme = themes.themeColors.find(color => color.name === getBrandColor());
const BRAND_COLOR = brandTheme.base;
const LAST_WEEK_COLOR = colors.colors.gray4;
const TITLE_COLOR = colors.colors.gray5;
const OVERTIME_COLOR = colors.colors.attention;

export default class DailyGraph extends Component {
	drawChart = (node) => {
		const chartWidth = this.props.hideWeekends ? 115 : 170;

		if (!this.chartRef) {
			this.chartRef = node;
		}

		this.chart = new BarChart(node, {
			className: 'TimesheetDailyGraph',
			height: 70,
			width: chartWidth,
			paddingTop: 0,
			bars: {
				classNameModifier: 'hide',
				mouseEvents: {
					mouseenter: function() {
						const index = this.parentNode.getAttribute('data-index'); // this === rect.ba-BarChart__bar (d3-wrapped)
						const title = node.querySelectorAll('.ba-BarChart__title')[index];
						const fillColor = this.getAttribute('fill');

						if (fillColor.indexOf(OVERTIME_COLOR.replace('#', '')) === -1) {
							title.classList.add('TimesheetDailyGraph__title--hover', 'baseFillColor');
						} else {
							title.classList.add('TimesheetDailyGraph__title--hover', 'TimesheetDailyGraph__title--overtime');
						}
					},
					mouseleave: function() {
						const index = this.parentNode.getAttribute('data-index'); // this === rect.ba-BarChart__bar (d3-wrapped)
						const title = node.querySelectorAll('.ba-BarChart__title')[index];
						const fillColor = this.getAttribute('fill');

						if (fillColor.indexOf(OVERTIME_COLOR.replace('#', '')) === -1) {
							title.classList.remove('TimesheetDailyGraph__title--hover', 'baseFillColor');
						} else {
							title.classList.remove('TimesheetDailyGraph__title--hover', 'TimesheetDailyGraph__title--overtime');
						}
					}
				},
			},
			barTitles: {
				classNameModifier: 'today',
				padding: 2,
			},
			barValues: {
				show: false,
			},
			responsive: false,
		});

		this.chart.draw(formatChartData(this.props.lastWeek, this.props.thisWeek, this.props.serverTime, this.props.hideWeekends));
	};

	componentDidUpdate() {
		this.drawChart(this.chartRef);
	}

	render() {
		return <div ref={this.drawChart}/>
	}
}


/**
 * Formats an array of daily time entry objects into a two-dimensional array usable by BarChart
 *
 * @param {Array} lastWeek
 * @param {Array} thisWeek
 * @param {object} serverTime
 * @returns {Array}
 */
export function formatChartData(lastWeek, thisWeek, serverTime, hideWeekends) {
	// ignore hours from last week's days of the week that are before today's day of the week
	lastWeek = lastWeek.map((day) => {
		if (day.moment.day() < serverTime.day()) {
			return {...day, hours: 0};
		}
		return day;
	});

	return Array.from({length: 7})
		.map((weekday, i) => {
			const lastWeekEntry = lastWeek[i];
			const thisWeekEntry = thisWeek[i];
			const isToday = thisWeekEntry.moment.isSame(serverTime, 'day');
			const isOvertime = thisWeekEntry.overtimeHours;
			const lastWeekHours = round(lastWeekEntry.hours, 2) || 0;
			const thisWeekHours = round(thisWeekEntry.hours, 2) || 0;
			let titleColor = TITLE_COLOR;

			if (isToday && isOvertime) {
				titleColor = OVERTIME_COLOR;
			} else if (isToday && !isOvertime) {
				titleColor = BRAND_COLOR;
			}

			return [
				{
					color: LAST_WEEK_COLOR,
					modifyBar: lastWeekEntry.moment.day() <= serverTime.day(),
					title: lastWeekEntry.moment.format('dddd')[0],
					tooltip: $.__('Last %s', lastWeekEntry.moment.format('dddd, MMMM D'), {note: 'e.g. Last Thursday, October 5'}),
					tooltipLead: $.__('%s Hours', lastWeekHours, {note: 'Number of hours'}),
					value: lastWeekHours,
				},
				{
					color: isOvertime ? OVERTIME_COLOR : BRAND_COLOR,
					displayType: isToday ? 'partial' : 'solid',
					modifyTitle: isToday,
					titleColor: titleColor,
					title: thisWeekEntry.moment.format('dddd')[0],
					tooltip: `${ isToday ? $.__('Today') : thisWeekEntry.moment.format('dddd') }, ${ thisWeekEntry.moment.format('MMMM D') }`, // Thursday, October 5 || Today, October 11
					tooltipLead: $.__('%s Hours', thisWeekHours, {note: 'Number of hours'}),
					value: thisWeekHours,
				},
			];
		})
		.filter((day, i) => {
			const isWeekend = thisWeek[i].isWeekend;

			return !hideWeekends || (hideWeekends && !isWeekend);
		});
}
