import {Fragment, useState, useEffect} from 'react';
import moment from 'moment';
import Select from 'select.react';
import Ajax from '@utils/ajax';
import { isEnabled } from 'FeatureToggle.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './styles.styl';

const futureSchedulingEnabled = isEnabled('timeTrackingFutureScheduling');

export default function(employee, saveCallback, errorCallback, setSessionMessage = false) {
	const title = $.__('Time Tracking Start Date');
	const primaryActionText = $.__('Save');

	let payScheduleId;
	let paySchedules = [];

	Ajax.get(`/time_tracking/employee/${ employee.id }/schedule_dates`)
		.then((response) => {
			const canEnableNow = response.data.canEnableNow;
			payScheduleId = response.data.paySchedules.allIds[0];
			const dates = response.data.paySchedules.byId[payScheduleId].dates;
			const hireDate = response.data.paySchedules.byId[payScheduleId].hireDate;
			paySchedules = dates.map((date) => {
				const momentDate = moment(date);
				return {
					date: date,
					display: momentDate.format('MMM D'),
				};
			});

			const overlayVisible = window.BambooHR.Modal.isOpen();

			let value = futureSchedulingEnabled ? '' : 'immediately';
			let selectItems = [];

			if (futureSchedulingEnabled) {
				if (canEnableNow) {
					selectItems.push({ displayText: $.__('Immediately'), value: 'immediately' });
				}

				if (hireDate) {
					const momentHireDate = moment(hireDate);
					selectItems.push({
						text: $.__('Hire Date'),
						type: 'group',
						items: [
							{
								displayText: momentHireDate.format('MMM D'),
								value: hireDate
							}
						]
					})
				}

				const payScheduleItems = [{
					text: $.__('Next Pay Periods'),
					type: 'group',
					items: [
						...paySchedules.map(schedule => ({
							displayText: schedule.display,
							value: schedule.date,
						})),
					]
				}];

				selectItems = [...selectItems, ...payScheduleItems];
			} else {
				selectItems = [
					{ displayText: $.__('Immediately'), value: 'immediately' },
					...paySchedules.map(schedule => ({
						displayText: schedule.display,
						value: schedule.date,
					})),
				];
			}

			const modalState = {
				isOpen: true,
				title,
				icon: ifFeature('encore', 'stopwatch-solid', 'fab-calendar-stopwatch-54x54'),
				headline: $.__('When do you want time tracking to start?'),
				content: (
					<Fragment>
						<div className="EnableTimeTracking__forWho" key="text">
							{ $.__('Select when %1$s will start tracking time.', employee.preferredName) }
						</div>
						<div className="EnableTimeTracking__date-select" key="select">
							<StatefulSelect
								effect={ (newVal) => {
									value = newVal;
								} }
								items={ selectItems }
								value={ value }
							/>
						</div>
					</Fragment>
				),
				primaryActionText,
				primaryAction() {
					if (overlayVisible) {
						window.BambooHR.Modal.setState({
							sheetProps: { isOpen: false },
							isProcessing: true
						}, true);
					} else {
						window.BambooHR.Modal.setState({
							isProcessing: true
						}, true);
					}

					enableEmployee(value, employee.id, saveCallback, errorCallback, setSessionMessage, payScheduleId);
				},
				alternativeAction() {
					window.BambooHR.Modal.setState({ isOpen: false });
				},
				onClose() {
					window.BambooHR.Modal.setState({ isOpen: false });
				}
			};
			if (overlayVisible) {
				window.BambooHR.Modal.setState({
					sheetProps: modalState
				}, true);
			} else {
				window.BambooHR.Modal.setState({...modalState});
			}
		})
		.catch((error) => {
			errorCallback(error.response.data.error);
		});
}

function enableEmployee(selectedStartDate, employeeId, saveCallback, errorCallback, setSessionMessage, payScheduleId) {
	let url = '/time_tracking/employees/reschedule';
	let postData = {
		employeeIds: [employeeId],
		...(selectedStartDate === 'immediately'
			? {}
			: {
					paySchedules: {
						[payScheduleId]: selectedStartDate,
					},
				}),
	}

	postData.setSessionMessage = setSessionMessage;

	return Ajax.post(url, postData)
		.then((response) => {
			window.BambooHR.Modal.setState({isOpen: false});
			saveCallback(response.data, selectedStartDate);
		})
		.catch((error) => {
			window.BambooHR.Modal.setState({isProcessing: false}, true);
			errorCallback(error.response.data.error);
		});
}

function StatefulSelect({ items, value, effect = () => {} }) {
	const [currentValue, setValue] = useState(value);
	const selectSettings = {
		notClearable: true,
		width: 5,
	};
	const selectData = { items };

	selectData.items.forEach((item) => {
		if (item.items) {
			item.items.forEach((nestedItem) => {
				nestedItem.selected = nestedItem.value === currentValue;
			});
		}
		item.selected = item.value === currentValue;
	});

	useEffect(() => {
		effect(currentValue);
	});

	return (
		<Select
			data={ selectData }
			onChange={ (selectedItem) => {
				setValue(selectedItem.value);
			} }
			placeholder={ `– ${ $.__('Select Date') } –` }
			settings={ selectSettings }
		/>
	);
}
