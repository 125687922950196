import React, { useEffect, useState } from 'react';
import {
	Button,
	CardSize,
	CardContentSpacing,
	CheckboxGroup,
	CheckboxGroupItem,
	CheckboxGroupOnChangeParam,
	LayoutBox,
	// @startCleanup encore
	makeStyles,
	// @endCleanup encore
	StandardModal,
	TextButton,
} from '@bamboohr/fabric';
import { CancellationType, CancellationStatus } from 'in-app-cancellation.mod';
import { biId, ProductSelectionNamesMap } from './constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @startCleanup encore
const useStyles = makeStyles(({ spacing }) => ({
	form: {
		marginTop: spacing(3),
		marginBottom: spacing(3),
	},
}));
// @endCleanup encore

interface CancelProductSelectionModalProps {
	cancellationStatuses: CancellationStatus[];
	isOpen: boolean;
	onNext: (products: CancellationType[]) => void;
	onClose: () => void;
}

export const CancelProductSelectionModal = ({
	cancellationStatuses,
	isOpen,
	onNext,
	onClose,
}: CancelProductSelectionModalProps): JSX.Element => {
	const [selectedProducts, setSelectedProducts] = useState<CancellationType[]>([]);
	const [cancellableProducts, setCancellableProducts] = useState<CheckboxGroupItem[]>([]);
	const [showDiscountCheckbox, setShowDiscountCheckbox] = useState<boolean>(false);
	const [acknowledge, setAcknowledge] = useState<boolean>(false);
	// @startCleanup encore
	const classes = useStyles();
	// @endCleanup encore

	useEffect(() => {
		if (!cancellationStatuses || !cancellationStatuses.reduce) return;

		const products = cancellationStatuses.reduce((acc, status) => {
			const { feature, pending_cancellation } = status;
			if (feature in ProductSelectionNamesMap && !pending_cancellation) {
				acc.push({
					value: feature,
					label: ProductSelectionNamesMap[feature] as string,
				});
			}
			return acc;
		}, [] as CheckboxGroupItem[]);
		setCancellableProducts(products);
	}, [cancellationStatuses]);

	const handleChange = (params: CheckboxGroupOnChangeParam, event: React.ChangeEvent<HTMLInputElement>) => {
		const { target } = event;
		const selectedValues = params.value as CancellationType[];
		// Mark all products as selected if the user selects the "account" option
		if (target.value === CancellationType.ACCOUNT && target.checked) {
			const allCancellableProducts = cancellableProducts.map((item) => item.value) as CancellationType[];
			setSelectedProducts(allCancellableProducts);
			// Unmark account when not all products are selected
		} else if (selectedProducts.length === cancellableProducts.length && !target.checked) {
			const selectedWithoutAccount = selectedValues.filter((product) => product !== CancellationType.ACCOUNT);
			setSelectedProducts(selectedWithoutAccount);
			// normal behavior
		} else {
			setSelectedProducts(selectedValues);
		}
	};

	return ifFeature(
		'encore',
		<StandardModal isOpen={isOpen} onCloseComplete={() => setSelectedProducts([])} onRequestClose={onClose}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton data-bi-id={`${biId}-cancel-button`} key={`${biId}-cancel-button`} onClick={onClose} type='button'>
								{$.__('Cancel')}
							</TextButton>,
							<Button
								data-bi-id={`${biId}-next-button`}
								disabled={selectedProducts.length === 0}
								key={`${biId}-next-button`}
								onClick={() => onNext(selectedProducts)}
								type='button'
							>
								{$.__('Next')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={$.__('Cancel BambooHR Products')} />}
				size={CardSize.MEDIUM}
			>
				<StandardModal.Constraint spacingOverrides={{ top: CardContentSpacing.LARGE }}>
					{
						<LayoutBox marginBottom={2} marginTop={1.5}>
							<form>
								<CheckboxGroup
									items={cancellableProducts}
									label={$.__('What BambooHR products do you want to cancel?')}
									onChange={handleChange}
									value={selectedProducts}
								/>
							</form>
						</LayoutBox>
					}
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>,
		<StandardModal isOpen={isOpen} onCloseComplete={() => setSelectedProducts([])} onRequestClose={onClose}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<Button
								data-bi-id={`${biId}-next-button`}
								disabled={selectedProducts.length === 0}
								key={`${biId}-next-button`}
								onClick={() => onNext(selectedProducts)}
								type='button'
							>
								{$.__('Next')}
							</Button>,
							<TextButton data-bi-id={`${biId}-cancel-button`} key={`${biId}-cancel-button`} onClick={onClose} type='button'>
								{$.__('Cancel')}
							</TextButton>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={$.__('Cancel BambooHR Products')} />}
				size={CardSize.MEDIUM}
			>
				<StandardModal.Constraint spacingOverrides={{ top: CardContentSpacing.LARGE }}>
					{
						<form className={classes.form}>
							<CheckboxGroup
								items={cancellableProducts}
								label={$.__('What BambooHR products do you want to cancel?')}
								onChange={handleChange}
								value={selectedProducts}
							/>
						</form>
					}
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
