import ItemErrorBoundary from '../../error-boundary';
import { AuthorName } from './author-name';

const Name = ({ user }) => {
	return (
		<ItemErrorBoundary>
			<AuthorName author={ user } />
		</ItemErrorBoundary>
	);
};

export default Name;

