const USD_CURRENCY_NUMBER_FORMAT = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export function formatUsdInputValue(inputValue: string, min?: number, max?: number): string {
	// remove any non-numeric characters (i.e characters that aren't digits, decimal points, or negative signs).
	inputValue = inputValue.replace(/[^\d.-]/g, '');
	// remove any negative signs that aren't the first character.
	inputValue = inputValue.replace(/-/g, (match, offset) => {
		return offset === 0 ? match : '';
	});
	// remove any decimal points beyond the first one encountered.
	let isFirstDecimal = true;
	inputValue = inputValue.replace(/\./g, (match) => {
		if (!isFirstDecimal) {
			return '';
		}

		isFirstDecimal = false;
		return match;
	});

	if (inputValue === '') {
		return inputValue;
	}
	
	let number = Number(inputValue);

	if (Number.isNaN(number)) {
		return '';
	}

	if (min !== undefined && number < min) {
		number = min;
	}
	if (max !== undefined && number > max) {
		number = max;
	}

	return USD_CURRENCY_NUMBER_FORMAT.format(number);
}

export function validateHelpData(help) {
	return help && help.title !== null && help.text !== null;
}
