import {Component} from 'react';
import {isEqual} from 'lodash';
import {default as TooltipModule} from 'Tooltip.mod';

/**
 * <Tooltip settings={{
 *		template: {
 *			name: "tooltip-standard",
 *			data: {
 *				title: "title",
 *				component: "component"
 *			}
 *		},
 *		position: "top",
 *		push: 8,
 *		triggerEvent: "hover",
 *		...//all other balloon settings are available
 *	}}>...</Tooltip>
 * @type Tooltip
 */
class Tooltip extends Component {

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.settings, this.props.settings)) {
			this._tooltip.destroy();
			this._createTooltip();
		}
	}

	componentDidMount() {
		this._createTooltip();
	}

	componentWillUnmount() {
		this._tooltip.destroy();
	}

	render() {
		const {children, wrapperClassName} = this.props;

		return <span className={wrapperClassName} ref={(elem) => { this._tooltipElement = elem }}>{children}</span>;
	}

	_createTooltip() {
		if (this.props.settings.content || this.props.settings.title) {
			this._tooltip = TooltipModule.createStandard(this._tooltipElement, this.props.settings);
		} else {
			const settings = Object.assign({
				template: {
					name: 'tooltip-standard'
				}
			}, this.props.settings);
			this._tooltip = TooltipModule.create(this._tooltipElement, settings);
		}
	}
}

export default Tooltip;
