import classNames from 'classnames';
import {
	noop,
} from 'lodash';
import React from 'react';

export interface Props {
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	error?: string;
	id: string;
	isDisabled?: boolean;
	isRequired?: boolean;
	label?: string;
	note?: string;
	placeholder?: string;
	size?: 'teenie' | 'small' | 'medium' | 'large' | 'biggie';
	value?: string;
	width?: 'width1' | 'width2' | 'width3' | 'width4' | 'width5' | 'width6' | 'width7' | 'width8' | 'width9' | 'width10';
}

export function FabInputTextField(props: Props): JSX.Element {
	const {
		error,
		handleBlur = noop,
		handleChange = noop,
		id,
		isDisabled,
		isRequired,
		label,
		note,
		placeholder,
		size = 'medium',
		value,
		width,
	} = props;

	return (
		<div>
			{ !!label && (
				<label
					className={ classNames('fab-Label', {
						'fab-Label--error': error,
						'fab-Label--required': isRequired,
					}) }
					htmlFor={ id }
				>
					{ label }
				</label>
			) }
			<div className="fab-InputWrapper">
				<input
					className={ classNames('fab-TextInput', {
						'fab-Label--error': error,
						[`fab-TextInput--${ size }`]: size !== 'medium',
						[`fab-TextInput--${ width }`]: !!width,
					}) }
					disabled={ isDisabled }
					id={ id }
					onBlur={ handleBlur }
					onChange={ handleChange }
					placeholder={ placeholder }
					type="text"
					value={ value }
				/>
			</div>
			{ !!note && (
				<span className="fab-FormNote">
					{ note }
				</span>
			) }
		</div>
	);
}
