import React from 'react';
import PropTypes from 'prop-types';
import {
	range,
} from 'lodash';

import BEM from '@utils/dom/bem';

import './step-indicator.styl';

const bem = (...args) => BEM.createClassName('StepIndicator', ...args);

/**
 * Display a step indicator
 * This is typically used in a modal title
 *
 * @param {object} props
 * @param {number} props.currentStep
 * @param {number} props.totalStepCount
 * @returns {JSX.Element}
 */
export function StepIndicator(props) {
	const {
		currentStep,
		hasSeparator = true,
		totalStepCount,
	} = props;

	if (currentStep > totalStepCount) {
		console.warn('The current step must be less than the total step count');
	}

	return (
		<div className={ bem('wrapper', {
			hasSeparator,
		}) }
		>
			<span className={ bem('message') }>{ $.__('Step %1 of %2', currentStep, totalStepCount) }</span>
			<div className={ bem('stepWrapper') }>
				{ range(1, totalStepCount + 1).map(step => (
					<span
						key={ step }
						className={ bem('step', {
							active: step === currentStep,
						}) }
					>
					</span>
				)) }
			</div>
		</div>
	);
}

StepIndicator.propTypes = {
	currentStep: PropTypes.number.isRequired,
	totalStepCount: PropTypes.number.isRequired,
};
