/**
 * Abstraction for submitting a form via ajax. Serializes form, submits it via jQuery,
 * and applies status attributes to the form.
 *
 * @param  {jQuery Selector} $form       [description]
 * @param  {object} options = {type: 'json'} [description]
 */
export function ajaxSubmit($form: JQuery, options: JQueryAjaxSettings = { type: 'json' }): JQueryPromise<any> {
	$form = $($form);

	if ($form.attr('submitting') == 'true') {
		return;
	}
	const formElement = $form[0];
	const action = $form.attr('action');
	const params = serializeForm(formElement);
	params.CSRFToken = window.CSRF_TOKEN;

	$form.attr('submitting', 'true');
	setTimeout(() => {
		$form.removeAttr('submitting');
	}, 3000);
	return $.post(action, params, null, options.type).promise();
}

export function serializeForm(form: HTMLElement, options = { excludeHidden: false }): any {
	const formFields = Array.prototype.slice.call(form.querySelectorAll('input, textarea, select'));
	return formFields.reduce((accumulator, element) => {
		let value;
		if (!element.name || (element.type === 'hidden' && options.excludeHidden)) {
			return accumulator;
		}

		if (element.type === 'checkbox' || element.type === 'radio') {
			if (element.checked) {
				value = element.value;
			}
		} else if (element.type === 'select-one') {
			value = (element.selectedIndex >= 0) ? element.options[element.selectedIndex].value : null;
		} else if (element.type === 'select-multiple') {
			value = [].slice.call(element.options)
				.reduce((accumulator, option) => {
					if (option.selected) {
						accumulator.push(option.value);
					}
					return accumulator;
				}, []);
		} else {
			value = element.value;
		}

		/*
			This avoids setting the property value to undefined we only want
			to add the property when the element contains a value
		 */
		if (value !== undefined) {
			accumulator[element.name] = value;
		}

		return accumulator;
	}, {});
}

export function populateForm(form: HTMLElement, data: any): void {
	const formFields = Array.prototype.slice.call(form.querySelectorAll('input, textarea, select'));
	formFields.forEach((element) => {
		if (!element.name || !data[element.name] || element.type === 'button') {
			return;
		}

		if (element.type === 'checkbox' || element.type === 'radio') {
			element.checked = data[element.name];
		} else if (element.type === 'select-one') {
			[].slice.call(element.options)
				.forEach((option) => {
					if (option.value == data[element.name]) {
						option.selected = true;
						// Stupid Chosen
						$(element).trigger('liszt:updated');
					}
				});
		} else if (element.type === 'select-multiple') {
			[].slice.call(element.options)
				.forEach((option) => {
					const value = data[element.name];
					if (value.indexOf(option.value) > -1) {
						option.selected = true;
						// Stupid Chosen
						$(element).trigger('liszt:updated');
					}
				});
		} else {
			element.value = data[element.name];
		}
	});
}

export function highlightErrorFields(fields: Array<string>, formSelector: string, jadeEnabled = true): void {
	$.each(fields, function (index, item) {
		if (jadeEnabled) {
			$(formSelector).find('input[name*=' + item + ']').addClass('fab-TextInput--error');
			$(formSelector).find('ba-select.js-' + item).attr('error', 'true');
			$(formSelector).find('ba-select#' + item).attr('error', 'true');
		} else {
			$(formSelector).find('select[name=' + item + ']').closest('.fieldBox').addClass('error');
			$(formSelector).find('input[name*=' + item + ']').closest('.fieldBox').addClass('error');
		}
	});
}

interface PayRollDetails {
	employeeId: number;
	payScheduleId?: number;
}

export function getPowerEditPayRollDetails($element: JQuery): PayRollDetails {
	if ($element.closest('.js-PowerEditIndividual').length > 0) {
		const $row = $element.closest('.fab-Table__row');
		const $paySchedule = $row.find('.js-pay-schedule ba-option:selected');
		const payScheduleId = ($paySchedule.length > 0) ? Number.parseInt(($paySchedule[0] as HTMLInputElement).value) : null;
		const employeeId = $row.find('.js-employeeName').data('employeeid');

		return {
			employeeId,
			payScheduleId,
		};
	}

	return {
		employeeId: Number.parseInt($('.js-PowerEditGroup').data('employee-id-in-syncing-pay-schedule')),
	};
}

export function getFormPayRollDetails($element: JQuery, excludePowerEdit = false): PayRollDetails {
	const onPowerEdit = $element.closest('.js-PowerEditForm').length > 0;
	const {
		employeeId = '0',
		isOnboardingUser = false,
	} = window.SESSION_USER;

	if (onPowerEdit && !excludePowerEdit) {
		return getPowerEditPayRollDetails($element);
	}

	if (isOnboardingUser) {
		return {
			employeeId: Number.parseInt(employeeId),
			payScheduleId: null,
		};
	}

	const {
		Employee = { id: undefined },
	} = window;
	const $selectedPaySchedule = $('.js-paySchedule ba-option:selected');
	const payScheduleId = ($selectedPaySchedule.length > 0) ? Number.parseInt(($selectedPaySchedule[0] as HTMLInputElement).value) : null;

	return {
		employeeId: Employee.id,
		payScheduleId,
	};
}

export function footerStartProcessing(): void {
	document.dispatchEvent(new CustomEvent('SiteFooterActions:startProcessing'));
}

export function footerEndProcessing(): void {
	document.dispatchEvent(new CustomEvent('SiteFooterActions:endProcessing'));
}

export function markFormAsChanged(): void {
	// @ts-ignore
	window.formChanged = true;
}

export default {
	ajaxSubmit,
	getFormPayRollDetails,
	getPowerEditPayRollDetails,
	highlightErrorFields,
	populateForm,
	serializeForm,
};
