import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	times,
} from 'lodash';

import {
	BEM,
} from '@utils/dom';

import {
	DIGIT_MARGIN,
	DIGIT_OUTER_WIDTH,
	DIGIT_WIDTH,
	MARKER_HEIGHT,
	MASK_ID,
} from '../constants';
import {
	getMiddleIndex,
	getMarkerPosition,
} from '../utils';

import './styles.styl';

const bem = new BEM('OneTimeCodeIndicator');

export function Indicator(props) {
	const {
		count,
		currentIndex,
		isInvalid,
		shouldChunk,
	} = props;

	const middleIndex = useRef(getMiddleIndex(count));
	const [caretX, updateCaretX] = useState(0);

	useEffect(() => {
		const x = getMarkerPosition(middleIndex.current, currentIndex, shouldChunk);
		updateCaretX(x);
	}, [currentIndex]);

	const svgValues = useMemo(() => {
		const masks = times(count, i => (
			getMarkerPosition(middleIndex.current, i, shouldChunk)
		));
		const offset = (shouldChunk && count > 1) ?
			DIGIT_MARGIN : -DIGIT_MARGIN;
		return {
			masks,
			viewBoxWidth: (DIGIT_OUTER_WIDTH * count) + offset,
		};
	}, []);

	return (
		<div className={ bem.elem('wrapper', { 'error': isInvalid }) }>
			<svg viewBox={ `0 0 ${ svgValues.viewBoxWidth } ${ MARKER_HEIGHT }` }>

				<mask id={ MASK_ID }>
					{
						times(count, i => (
							<rect
								fill="white"
								key={ `otc-marker-${ i }` }
								height={ MARKER_HEIGHT }
								width={ DIGIT_WIDTH }
								x={ svgValues.masks[i] }
								y="0"
							/>
						))
					}
				</mask>

				<g mask={ `url(#${ MASK_ID })` }>
					<rect
						className={ bem.elem('markerBg') }
						height={ MARKER_HEIGHT }
						width="100%"
						x="0"
						y="0"
					/>
					<rect
						className={ bem.elem('caret') }
						height={ MARKER_HEIGHT }
						width={ DIGIT_WIDTH }
						x={ caretX }
						y="0"
					/>
				</g>

			</svg>
		</div>
	);
}
