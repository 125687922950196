import { FunctionComponent, ReactElement, useEffect } from 'react';

import { PoMicroFrontend } from 'micro-frontend.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { openHistoryModal } from 'HistoryTable.mod';

interface QuickbooksIntegrationProps {
    isQuickBooksOnlinePayrollEnabled: boolean;
}

type CustomElementWithQuickbooksProps = DynamicForm.CustomElement & QuickbooksIntegrationProps;

function renderHtmlTable(html): ReactElement {
	return <div className='Quickform' dangerouslySetInnerHTML={{ __html: html }} />;
}

function renderPoTable(tableJson: Record<string, unknown>, isQuickBooksOnlinePayrollEnabled: boolean): ReactElement {
	return (
		<div style={ifFeature('encore', {}, { float: 'none', clear: 'both', marginTop: '40px' })}>
			<PoMicroFrontend
				customClassNamePrefix={tableJson.tableType}
				employee={window.Employee}
				openHistoryModal={openHistoryModal}
				route='/employees/widget-history-table'
				tableComponentJson={{...tableJson, isQuickBooksOnlinePayrollEnabled}}
			/>
		</div>
	);
}

function shouldRenderHtmlTable(props, settings): boolean {
	const renderAsHtml = {
		tableDirectReports: 'direct reports',
	};

	return !props.componentJson || renderAsHtml[settings.token];
}

const FallbackDiv: FunctionComponent<CustomElementWithQuickbooksProps> = ({ type, children, props, settings, isQuickBooksOnlinePayrollEnabled = false }) => {
	const { 'aria-label': ariaLabel = '', id = '' } = props || {};
	const isDev = window.BambooHR.env.dev;
	const {
		// @ts-ignore - This is only until we have a chance to convert tables into JSON
		componentHtml,
		// @ts-ignore - This is for use until we swap over to DynamicFormFactory
		componentJson,
	} = props;

	// only run once!
	useEffect(() => {
		// Two known components not supported by Dynamic Forms are: tables, direct_reports. We want to know about any others!
		// See https://bamboohr.atlassian.net/browse/ROG-1048
		if (!componentHtml && !type.includes('table') && !type.includes('direct_reports')) {
			// We know that tables need updating but for the time being we are going to bypass with HTML from the BE
			console.error('Dynamic Form: Missing template for: ', { type, label: ariaLabel, fieldId: id });
		}
	}, []);

	const isHtmlTable = shouldRenderHtmlTable(props, settings);

	if (componentJson) {
		componentJson.label = settings.label;
	}

	return (
		<div className='DynamicForm__Table'>
			{isDev && !componentHtml && <div style={{ color: 'red' }}>Tried to Render type: {type}, but template doesn't exist</div>}
			{children}
			{isHtmlTable && componentHtml && renderHtmlTable(componentHtml)}
			{!isHtmlTable && componentJson && renderPoTable(componentJson, isQuickBooksOnlinePayrollEnabled)}
		</div>
	);
};

export default FallbackDiv;
