import {
	getSelectedItem,
	isSelectedPSSyncing,
} from 'dynamic-form';

export const invalidPayrollOption = (context: DynamicForm.Context, field: DynamicForm.DataProps, params: any) => {
	return (value): boolean => {
		if (isSelectedPSSyncing(context) && field && Object.prototype.hasOwnProperty.call(field.props, 'selectedValues')) {
			const item = getSelectedItem(field as DynamicForm.SelectElement);
			if (item) {
				if (item?.props?.['data-invalid-payroll-option']) {
					return false;
				}
			}
		}
		return true;
	};
};
