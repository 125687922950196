import { Component } from 'react';
import { Modal } from 'modal-legacy';
import { TextField } from '@bamboohr/fabric';
import Select from 'select.react';
import Ajax from '@utils/ajax';
import { merge, cloneDeep } from 'lodash';
import './payee.styl';
import {
	INITIAL_STATE,
	INPUT_FIELD_MAP,
	INVALID_ERROR_MESSAGE,
	SAVE_ERROR_MESSAGE,
} from './constants';
import {
	validatePayeeForm,
	getFieldBoxClasses,
	prepPayeeSaveData,
	prepPayeeEditData,
	showPayeeValidationError,
	showPayeeValidationSubscript
} from './utils';


/**
 * PayeeModal component
 *
 * Expected Props:
 *
 * {Boolean}  visible        Determines whether or not to show the modal
 * {String}   type           'edit' or 'add' only (logic defaults to 'add')
 * {Object}   payeeData      If type === 'edit', data object to populate state & pre-populate form
 * {Function} onSaveSuccess  Function to be called upon successfully saving payee; new Payee data is passed as an argument
 * {Function} onClose        Function to be called whenever the modal is closed (e.g., could be used to set parent state.visible to false)
 */
export class PayeeModal extends Component {
	constructor(props) {
		super(props);
		const {type, payeeData} = this.props;
		let initialState = cloneDeep(INITIAL_STATE);

		if (type === 'edit' && payeeData) {
			const payee = prepPayeeEditData(payeeData);
			if (payee.success) {
				initialState = merge(initialState, payee.data);
			}
		}

		const validationData = {
			showNameSubscript: false,
			showAddressSubscript: false,
			subscriptContent: null
		}
		initialState = merge(initialState, validationData);

		this._originalState = cloneDeep(initialState);
		this.state = initialState;
	}

	_resetState = () => this.setState(this._originalState);

	_closeModal = () => {
		const {onClose} = this.props;
		this._resetState();
		onClose();
	}

	_getStateSelectData = () => {
		let allStates;
		let selectedState;
		if ((this.props.type !== 'edit') && !this.props.isFromSettingsPage) {
			allStates = this.props.allStates;
			if(this.props.selectedState && this.props.selectedState.length > 0) {
				selectedState = this.props.selectedState[0];
			}
		} else {
			allStates = this.state.allStates;
			selectedState = this.state.state.value;
		}

		const options = allStates?.map((currentState) => {
			return {
				displayText: currentState.name,
				value: currentState.id,
				selected: currentState.id === selectedState
			};
		});

		return {
			data: {
				items: options,
			},
			settings: {
				placeholder: 'State',
			},
			className: 'xshort',
			onChange: this._handleStateSelectChange,
		};
	};

	// State setter methods
	setShowNameSubscript = (value) => {
		this.setState({showNameSubscript: value});
	}

	setShowAddressSubscript = (value) => {
		this.setState({showAddressSubscript: value});
	}

	setSubscriptContent = (value) => {
		this.setState({subscriptContent: value});
	}

	setNameError = (value) => {
		const nameObject = cloneDeep(this.state.name);
		nameObject.error = value;
		this.setState({name: nameObject})
	}

	setAddressError = (value) => {
		const addressObject = cloneDeep(this.state.street1);
		addressObject.error = value;
		this.setState({street1: addressObject})
	}

	// AJAX call methods
	_getAllStateData = () => {
		Ajax.get('/ajax/get_states?country=1')
			.then((response) => {
				if (response.status === 200 && response.data && response.data.data) {
					this.setState({allStates: response.data.data});
				}
			});
	};

	_savePayee = (payee, method) => {
		const {
			onSaveSuccess,
			onClose,
			type,
		} = this.props;
		Ajax.request({
			method,
			url: '/payroll/non_benefit_deductions/payee',
			data: payee
		})
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					if (response.data.errorOrigin) {
						showPayeeValidationError(response.data, payee)
						showPayeeValidationSubscript(
							this.setShowNameSubscript,
							this.setNameError,
							this.setShowAddressSubscript,
							this.setAddressError,
							this.setSubscriptContent,
							this.props.setSelectedPayee,
							this._closeModal,
							this.props.customPayees,
							this.props.setSelectedState,
							this.props.setIsPayeeFromValidator,
							type,
							response.data
						);
						this.setState({
							isProcessing: false,
						})
					} else {
						if (typeof onSaveSuccess === 'function') {
							// Pass returned Payee object back to parent component
							onSaveSuccess(response.data);
						}
						if (type === 'edit') {
							// Update original state for subsequent edits
							this._originalState = this.state;
						}
						this._resetState();
						onClose();
					}
				} else {
					window.setMessage(SAVE_ERROR_MESSAGE, 'error');
					this.setState({
						isProcessing: false,
					})
				}
			})
			.catch(() => {
				window.setMessage(SAVE_ERROR_MESSAGE, 'error');
				this.setState({
					isProcessing: false,
				})
			})
	};

	// Event Handlers
	_handleStateSelectChange = event => {
		if ((this.props.type !== 'edit') && (!this.props.isFromSettingsPage)) {
			const {setSelectedState} = this.props;
			const state = event ? [event.value] : [];
			setSelectedState(state);
		}
		this.setState({
			state: {
				value: event ? event.value : '',
				error: false
			}
		});
	}

	_handleInputChange = (event, type) => {
		const newState = {};
		newState[type] = {
			value: event ? event.target.value : '',
			error: false
		};
		this.setState(newState);
	};

	_handleSaveClick = () => {
		this.setShowAddressSubscript(false);
		this.setAddressError(false);
		this.setShowNameSubscript(false);
		this.setNameError(false);
		this.setSubscriptContent(null);

		const validation = validatePayeeForm(this.state);
		const {type} = this.props;

		if (validation.success) {
			const {employeeId} = this.props;
			const payee = prepPayeeSaveData(this.state, employeeId);
			if (payee.success) {
				const method = type === 'edit' ? 'put' : 'post';
				this._savePayee(payee.data, method);
			} else {
				window.setMessage(INVALID_ERROR_MESSAGE, 'error');
				this.setState({
					isProcessing: false,
				})
			}
		} else {
			window.setMessage(INVALID_ERROR_MESSAGE, 'error');
			this.setState(validation.errorData);
		}
	};

	// Render Methods
	_renderInputFieldBox = (type, required) => {
		const field = INPUT_FIELD_MAP[type];
		const fieldData = this.state[type];

		return (
			<div className={ getFieldBoxClasses(required, fieldData.error) }>
				<div className="fab-InputWrapper">
					<input
						className={ `fab-TextInput ${ field.icon ? 'fab-TextInput--withNub' : '' } ${ field.size } ${ fieldData.error ? 'fab-TextInput--error' : '' }` }
						onChange={ event => this._handleInputChange(event, type) }
						placeholder={ field.placeholder }
						type="text"
						value={ fieldData.value }
					/>
					{ field.icon &&
						<span className="fab-TextInputNub">
							<span className="fab-TextInputNub__icon">
								{ field.icon }
							</span>
						</span>
					}
				</div>
			</div>
		);
	};

	_renderValidationInputFieldBox = (type, required) => {
		const field = INPUT_FIELD_MAP[type];
		const fieldData = this.state[type];

		return (
			<TextField
				onChange={ event => this._handleInputChange(event, type) }
				placeholder={ field.placeholder }
				value={ fieldData.value }
			/>
		)
	}

	componentDidMount() {
		if (((this.props.type === 'edit') && this.props.isFromSettingsPage)){
			this._getAllStateData();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.selectedState !== this.props.selectedState && this.props.selectedState.length > 0){
			const newState = {
				value: this.props.selectedState[0],
				error: false,
			}
			this._originalState.state = newState;
			this.setState({
				state: newState,
			})
		}
	}

	render() {
		const {
			name,
			state,
			isProcessing
		} = this.state;

		const {
			isFromSettingsPage,
			visible,
			onClose,
			type
		} = this.props;

		let allStates;
		let showNameSubscript;
		let showAddressSubscript;
		let subscriptContent;
		if(type !== 'edit' && !isFromSettingsPage) {
			allStates = this.props.allStates;
		} else {
			allStates = this.state.allStates;
		}
		showNameSubscript = this.state.showNameSubscript;
		showAddressSubscript = this.state.showAddressSubscript;
		subscriptContent = this.state.subscriptContent;

		return (
			<Modal
				isOpen={visible}
				isProcessing={ isProcessing }
				onClose={() => {
					this._resetState();
					onClose();
				}}
				primaryAction={() => {this.setState({ isProcessing: true }, this._handleSaveClick)}}
				primaryActionText={$.__('Save')}
				title={type === 'edit' ? $.__('Edit Payee') : $.__('Add Payee')}
			>
				<form className="BhrForms Payee__form BhrForms--modal-override fab-Form">
					<fieldset>
						<div className="fieldRow fab-FormRow">
							<div className={ getFieldBoxClasses(true, name.error) }>
								<label
									className={ `fab-Label fab-Label--required ${ name.error ? 'fab-Label--error' : '' }` }
									htmlFor="name"
								>
									Payee Name
								</label>
								<input
									className={ `xlong fab-TextInput fab-TextInput--width8 ${ name.error ? 'fab-TextInput--error' : '' }` }
									id="name"
									onChange={ event => this._handleInputChange(event, 'name') }
									type="text"
									value={ name.value }
								/>
							</div>
						</div>
						{
							showNameSubscript && <div className='subscript'>{ subscriptContent }</div>
						}

						<div className="fieldGroup required" id="address">
							<label className={`fab-Label fab-Label--required ${ this.state.street1.error ? 'fab-Label--error' : ''}` } htmlFor="address">Address</label>
							<div className="fieldRow fab-FormRow">
								{ this._renderInputFieldBox('street1', false) }
							</div>
							{
								showAddressSubscript && <div className='subscript'>{ subscriptContent }</div>
							}
							<div className="fieldRow fab-FormRow">
								{ this._renderInputFieldBox('street2', false) }
							</div>
							<div className="fieldRow fab-FormRow">
								{ this._renderInputFieldBox('city', false) }
								<div className={ getFieldBoxClasses(false, state.error) }>
									{ allStates && <Select { ...this._getStateSelectData() } /> }
								</div>
								{ this._renderInputFieldBox('zip', false) }
							</div>
						</div>

						<div className="fieldGroup" id="contactInfoPhone">
							<label className="fab-Label fab-Label--required" htmlFor="contactInfoPhone">{ $.__('Phone') }</label>
							<div className="fieldRow fab-FormRow">
								{ this._renderInputFieldBox('phone', true) }
							</div>
						</div>

						<div className="fieldGroup" id="contactInfoEmail">
							<label className="fab-Label" htmlFor="contactInfoEmail">{ $.__('Email') }</label>
							<div className="fieldRow fab-FormRow">
								{ this._renderInputFieldBox('email', false) }
							</div>
						</div>
					</fieldset>
				</form>
			</Modal>
		);
	}

}
