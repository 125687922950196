import './styles.styl';
import CommentCreator from '../../containers/comment-creator';
import CommentList from '../../containers/comment-list';
import { getConfig } from '../../../../constants';

const Comments = (props) => (
	<div className={`feedOrder ${getConfig().inputPosition === 'bottom' ? 'feedOrder--bottom' : ''}`}>
		{!getConfig(props?.feedId).readOnly && <CommentCreator />}
		<CommentList {...props} />
	</div>
);

export default Comments;
