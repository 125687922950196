import { createLogger } from '@bamboohr/utils/lib/dev-logger';
import { getMaxZIndex } from '@bamboohr/utils/lib/dom';
import classNames from 'classnames';
import { Loader } from '@bamboohr/fabric';
import React, { Fragment, PureComponent } from 'react';
import ReactModal from 'react-modal';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	HEADER_TYPES,
	MODAL_CLOSE_DURATION,
	TYPES,
} from './constants.json';
import { CustomModal } from './custom-modal';
import { ModalMain } from './modal-main';
import { ModalTitle } from './modal-title';
import { elementContainsSingleSubmittableInput, resetBodyStyles } from './utils';

import './styles.styl';

const modalLogger = createLogger('<Modal>');
const noop = Function.prototype;
const AGGRESSIVE_CONFIRMATION_PASSPHRASE = 'Delete';
let { scrollY } = window;

export const openModalsStack = []; // 0: instance of open modal; 1: instance of open sheet

function someOtherModalIsOpen(instance) {
	return Boolean(openModalsStack[0] && openModalsStack[0] !== instance);
}

function updateStackOnModalClose(instance) {
	if (instance.props.type.indexOf('Sheet') > -1 || openModalsStack[1] === instance) {
		openModalsStack.length = 1;
	} else if (openModalsStack[0] === instance) {
		openModalsStack.length = 0;
	}
}

function restoreBodyStyling() {
	if (!openModalsStack[0]) {
		resetBodyStyles();
	}
}

export const defaultProps = {
	actionNote: '',
	additionalAction: null,
	additionalActionButtonType: 'text',
	additionalActionText: '',
	aggressiveConfirmationPassphrase: AGGRESSIVE_CONFIRMATION_PASSPHRASE,
	aggressiveConfirmationText: window.jQuery ? $.__(
		'Type "%1$s%2$s%3$s" to continue',
		`<span class="legacyModal__bold">`,
		AGGRESSIVE_CONFIRMATION_PASSPHRASE,
		'</span>',
	) : (
		<Fragment>Type &ldquo;<span className="legacyModal__bold">{ AGGRESSIVE_CONFIRMATION_PASSPHRASE }</span>&rdquo; to continue</Fragment>
	),
	alternativeAction: noop,
	alternativeActionText: (window.jQuery ? $.__('Cancel') : 'Cancel'),
	biId: '',
	content: '',
	contentHasMaxHeight: true,
	contentHasPadding: true,
	dangerousContent: '',
	footer: '',
	hasCloseButton: true,
	header: '',
	headerType: 'stacked',
	headline: '',
	hideActions: false,
	icon: '',
	iconColor: 'theme',
	iconV2Color: null,
	isHeadless: false,
	isLoading: false,
	isMobileFriendly: false,
	isMobileFriendlySheetFullScreen: false,
	isOpen: false,
	isProcessing: false,
	onClose: noop,
	onOpen: noop,
	onlyPrintModal: false,
	overrides: {},
	primaryAction: noop,
	primaryActionText: (window.jQuery ? $.__('OK') : 'OK'),
	primaryActionKey: '',
	secondaryAction: null,
	secondaryActionText: '',
	sheetProps: null,
	title: '',
	type: 'small',
};

export class Modal extends PureComponent {
	static setAppElement(element) {
		if (element) {
			ReactModal.setAppElement(element);
		}
	}

	static defaultProps = defaultProps;

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!nextProps.isOpen && prevState.isAggressivelyConfirmed) {
			return {isAggressivelyConfirmed: false};
		}

		return null;
	}

	_handleClose = () => {
		const { onClose, onlyPrintModal } = this.props;
		this._removeEventListeners();

		if (onlyPrintModal) {
			this._removePrintStylesForBackground();
		}

		if (typeof onClose === 'function') {
			onClose();
		}
	};

	_handleOpen = () => {
		const { onOpen, onlyPrintModal } = this.props;
		this._addEventListeners();

		if (onlyPrintModal) {
			this._hideBackgroundForPrint();
		}

		document.body.style.position = 'fixed';
		document.body.style.top = `-${ scrollY }px`;
		setTimeout(() => {
			// make sure browser is all caught up before interacting with feature
			this._focusInput();
			if (typeof onOpen === 'function') {
				onOpen();
			}
		});
	};

	_handleFormSubmit = (event) => {
		// don't allow implicit form submission, but instead encourage explicit use of primaryAction
		event.preventDefault();
	};

	_handleKeyDown = (event) => {
		const { primaryAction, primaryActionKey, type } = this.props;
		const { isAggressivelyConfirmed } = this.state;

		if (typeof primaryAction !== 'function') {
			return;
		}

		if (event.key === primaryActionKey && type !== 'aggressiveConfirmation') {
			primaryAction();
		} else if (event.key === 'Enter' && (event.target.nodeName === 'INPUT' || event.target.nodeName === 'SELECT')) {
			// allow Enter key to perform primaryAction for content with single input
			if (elementContainsSingleSubmittableInput(this._contentRef)) {
				if (type !== 'aggressiveConfirmation') {
					primaryAction();
				} else if (isAggressivelyConfirmed) {
					primaryAction();
				}
			}
		}
	};

	_handleAggressiveConfirmationInput = (event) => {
		const { aggressiveConfirmationPassphrase } = this.props;
		const value = event.target.value.toLowerCase();
		const passphrase = String(aggressiveConfirmationPassphrase).toLowerCase();

		this.setState(() => ({
			isAggressivelyConfirmed: value === passphrase,
		}));
	};

	_hideBackgroundForPrint = () => {
		const style = document.createElement('style');
		style.id = 'hideBackgroundForPrint';
		style.innerHTML = `@media print { body:has([role="dialog"]) > * { display: none; } body > div:has([role="dialog"]) { display: block; } }`;
		document.head.appendChild(style);
	}

	_removePrintStylesForBackground = () => {
		const style = document.getElementById('hideBackgroundForPrint');
		if (style) {
			style.remove();
		}
	}

	_focusInput = () => {
		const { overrides } = this.props;

		if (overrides.shouldFocusAfterRender === false) {
			return;
		}

		const inputElement = this._contentRef.querySelector('input:not([type="hidden"]), textarea, .fab-Select [role="button"], select');

		if (inputElement) {
			inputElement.focus();
		}
	};

	_addEventListeners = () => {
		if (this._contentRef) {
			this._contentRef.addEventListener('submit', this._handleFormSubmit);
			this._contentRef.addEventListener('keydown', this._handleKeyDown);
		}
	};

	_removeEventListeners = () => {
		if (this._contentRef) {
			this._contentRef.removeEventListener('submit', this._handleFormSubmit)
			this._contentRef.removeEventListener('keydown', this._handleKeyDown);
		}
	};

	state = {isAggressivelyConfirmed: false};

	_contentRef = null;

	componentDidMount() {
		const { headerType, type } = this.props;
		if (HEADER_TYPES.indexOf(headerType) === -1) {
			modalLogger.warn('Supplied header type is not recognized!');
		}
		if (TYPES.indexOf(type) === -1) {
			modalLogger.warn('Supplied modal type is not recognized!');
		}
	}

	getSnapshotBeforeUpdate() {
		const { isOpen } = this.props;

		/**
		 * update stack with whatever is currently open
		 * this side effect needs to happen before render
		 */
		if (isOpen) {
			if (!openModalsStack[0]) {
				openModalsStack[0] = this;
			} else if (openModalsStack[0] !== this && !openModalsStack[1]) {
				openModalsStack[1] = this;
			}
		}

		return null;
	}

	componentDidUpdate(previousProps) {
		const { isOpen, type } = this.props;

		// did open
		if (!previousProps.isOpen && isOpen) {
			const isSheet = (type.indexOf('Sheet') > -1 || someOtherModalIsOpen(this));
			if (!isSheet) {
				({ scrollY } = window);
			}
		}

		// did close
		if (previousProps.isOpen && !isOpen) {
			updateStackOnModalClose(this);
			restoreBodyStyling();
		}
	}

	componentWillUnmount() {
		updateStackOnModalClose(this);
		restoreBodyStyling();
		this._removeEventListeners();
	}

	render() {
		const {
			actionNote,
			additionalAction,
			additionalActionButtonType,
			additionalActionText,
			aggressiveConfirmationText,
			alternativeAction,
			alternativeActionText,
			biId,
			children,
			content,
			contentHasMaxHeight,
			contentHasPadding,
			dangerousContent,
			footer,
			hasCloseButton,
			header,
			headerType,
			headline,
			hideActions,
			icon,
			iconColor,
			iconV2Color,
			isHeadless,
			isLoading,
			isMobileFriendly,
			isMobileFriendlySheetFullScreen,
			isOpen,
			isProcessing,
			onClose,
			overrides,
			primaryAction,
			primaryActionText,
			secondaryAction,
			secondaryActionText,
			sheetProps,
			title,
		} = this.props;

		const { mobileFriendlyHeader = (isMobileFriendly || isMobileFriendlySheetFullScreen) ? title : null } = this.props;
		let { type } = this.props;

		const {
			isAggressivelyConfirmed,
		} = this.state;

		const isConfirmation = (type === 'aggressiveConfirmation');
		let isSheet = type.indexOf('Sheet') > -1;
		if (!isSheet && someOtherModalIsOpen(this)) {
			// force sheet styling if another modal is already open
			isSheet = true;
			type = 'smallSheet';
		}

		const modalHeader = (header || headline);
		const iconName = (isConfirmation && !icon ? ifFeature('encore', 'trash-can-regular', 'fab-trash-can-43x48')  : icon);
		const willOpen = Boolean(isOpen);
		this.currentZIndex = (!this.isCurrentlyOpen && willOpen ? getMaxZIndex() + 10 : this.currentZIndex);
		this.isCurrentlyOpen = willOpen;

		const reactModalProps = {
			aria: {
				labelledby: (modalHeader && typeof modalHeader !== 'object') ? 'fabricModalHeadline' : 'fabricModalTitle',
				describedby: 'fabricModalContent',
			},
			ariaHideApp: true,
			bodyOpenClassName: 'fabricModalOpen',
			className: classNames('legacyModal__modal', {
				[`legacyModal__modal--${ type }`]: !!type,
				[`legacyModal__modal--${ type }--mobileFriendly`]: !!type && !!isMobileFriendly,
				[`legacyModal__modal--${ type }--mobileFriendlyFullScreen`]: !!type && !!isMobileFriendly && !!isMobileFriendlySheetFullScreen,
				[`legacyModal__modal--aggressiveConfirmation`]: isConfirmation,
				[`legacyModal__modal--headless`]: isHeadless,
				[`legacyModal__modal--loading`]: isLoading,
				[`legacyModal__modal--sheet`]: isSheet,
			}),
			closeTimeoutMS: MODAL_CLOSE_DURATION,
			contentRef: (node) => { this._contentRef = node },
			htmlOpenClassName: 'fabricModalOpen',
			isOpen: willOpen,
			onAfterOpen: this._handleOpen,
			onRequestClose: this._handleClose,
			overlayClassName: classNames('legacyModal__overlay', (isSheet && 'legacyModal__overlay--sheet')),
			portalClassName: 'legacyModal__portal',
			shouldCloseOnOverlayClick: false,
			shouldCloseOnEsc: !isProcessing,
			style: {
				overlay: {
					zIndex: this.currentZIndex,
				},
			},
			...overrides
		};

		if (type === 'custom') {
			return (
				<CustomModal
					{ ...{
						content,
						dangerousContent,
						handleClose: this._handleClose,
						hasCloseButton,
						isLoading,
						isOpen,
						isProcessing,
						reactModalProps,
					} }
				>
					{ children }
				</CustomModal>
			);
		}

		return (
			<ReactModal { ...reactModalProps }>
				{ isOpen && isLoading && (
					<div className='legacyModal__modalLoader'>
						<Loader />
					</div>
				) }
				<ModalTitle
					{ ...{
						handleClose: this._handleClose,
						hasCloseButton,
						isHeadless,
						isLoading,
						isMobileFriendly,
						isMobileFriendlySheetFullScreen,
						isProcessing,
						isSheet,
						title,
						type,
					} }
				/>
				<ModalMain
					{ ...{
						actionNote,
						additionalAction,
						additionalActionButtonType,
						additionalActionText,
						aggressiveConfirmationText,
						alternativeAction,
						alternativeActionText,
						biId,
						content,
						contentHasMaxHeight,
						contentHasPadding,
						dangerousContent,
						footer,
						handleAggressiveConfirmationInput: this._handleAggressiveConfirmationInput,
						header,
						headerType,
						headline,
						hideActions,
						iconColor,
						iconV2Color,
						iconName,
						isAggressivelyConfirmed,
						isConfirmation,
						isLoading,
						isMobileFriendly,
						isProcessing,
						isSheet,
						mobileFriendlyHeader,
						onClose,
						primaryAction,
						primaryActionText,
						secondaryAction,
						secondaryActionText,
						type,
					} }
				>
					{ children }
				</ModalMain>
				{ !isSheet && sheetProps && <Modal { ...sheetProps } sheetProps={ null } type={ sheetProps.type || 'smallSheet' } /> }
			</ReactModal>
		);
	}
}
