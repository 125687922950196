import { useEffect, useState } from 'react';
import { DatePicker, DatePickerProps } from '@bamboohr/fabric';
import { NoFacadeComponent } from './no-facade-component';

import { convertDateToIsoFormatString } from './utils';

type SingleDateComponentProps = {
	name: string;
	value?: string;
	noFacade?: boolean;
} & DatePickerProps

export function SingleDateComponent(props: SingleDateComponentProps) {
	const { id, name, value = '', noFacade, ...rest } = props;

	const [selectedDate, setSelectedDate] = useState(convertDateToIsoFormatString(value));
	const [status, setStatus] = useState('')

	const updateStatus = (event: CustomEvent) => {
		if(event.detail.id === id){
			setStatus(event.detail.status)
		}
	}

	useEffect(() => {
		window.addEventListener('updateDatePickerStatus', updateStatus)
		return () => {
		  window.removeEventListener('updateDatePickerStatus', updateStatus)
		}
	}, [])

	return (
		<>
			<DatePicker
				{...rest}
				id={id}
				onChange={({ value: newSelectedDate }) => {
					setSelectedDate(newSelectedDate);
				}}
				status={status as DatePickerProps['status']}
				value={selectedDate}
			/>
			{noFacade ? (
					<NoFacadeComponent dateString={selectedDate} name={name} />
			) : (
				''
			)}
		</>
	);
}
