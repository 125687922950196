import { memo } from 'react';

export const PoIcon = memo(() => {
	return (
		<svg height="14.028" width="16" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.101 8.05h-2.21a.532.532 0 00-.444.827l1.105 1.666c.047.07.11.127.182.167v.989a1.295 1.295 0 01-.676-.352.266.266 0 00-.376.376c.352.352.82.546 1.318.546.498 0 .966-.194 1.318-.546a.266.266 0 00-.376-.376 1.294 1.294 0 01-.677.352v-.994a.533.533 0 00.174-.163l1.106-1.665A.532.532 0 009.1 8.05z"/>
			<path d="M14.877.396c-1.024-.647-2.289-.484-2.824.364a1.534 1.534 0 00-.199.527C10.742.507 9.43.015 8 .015c-1.429 0-2.742.493-3.855 1.272A1.519 1.519 0 003.947.76C3.411-.088 2.147-.25 1.123.396c-1.024.647-1.42 1.86-.884 2.707.32.506.9.761 1.531.754C1.072 5.025.667 6.323.667 7.553c0 3.57 3.29 6.475 7.333 6.475s7.333-2.904 7.333-6.475c0-1.23-.405-2.528-1.103-3.696.632.008 1.212-.248 1.531-.754.536-.848.14-2.06-.884-2.707zm-.608 7.157c0 2.984-2.812 5.411-6.27 5.411-3.456 0-6.27-2.427-6.27-5.41C1.73 4.62 4.528 1.078 8 1.078c3.474 0 6.27 3.541 6.27 6.474z"/>
			<path d="M6.817 7.176a1.314 1.314 0 00-.16-1.142L5.463 4.269a1.311 1.311 0 00-2.392.86l.203 2.121a1.313 1.313 0 001.75 1.11l.99-.357c.381-.137.678-.442.803-.827zm-1.18.214a.586.586 0 110-1.171.586.586 0 010 1.171zM10.519 4.27L9.325 6.033a1.312 1.312 0 00.643 1.969l.99.356a1.313 1.313 0 001.75-1.109l.203-2.12a1.311 1.311 0 00-2.392-.86zm-.156 3.12a.586.586 0 110-1.171.586.586 0 010 1.171z"/>
		</svg>
	);
});
