/**
 * If a string is longer than maxLength we truncate and add ...
 *
 * @param {int} maxLength
 * @param {string} text
 *
 * @returns {string} text
 */
export function truncateLabel(maxLength, text) {
	if (maxLength && text.length > maxLength) {
		return `${ text.substring(0, maxLength - 3) }...`;
	}

	return text;
}
