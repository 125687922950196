import {
	isEqual,
} from 'lodash';
import {
	Component,
	createRef,
} from 'react';

import {
	Modal,
} from 'modal-legacy';

import {
	AccessList,
} from './access-list';
import {
	PeopleSelector,
} from './people-selector';

import './share-modal.styl';
import { LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const prepareShareList = (shareList = []) => {
	const user = shareList.filter(item => item.user_group === 'user').map(item => item.user);
	const user_group = shareList.filter(item => item.user_group === 'user_group').map(item => item.user);
	const userData = shareList.map(item => item.userData);

	return {
		ids: {
			user,
			user_group,
		},
		userData,
	};
};

/**
 * The ShareModal component was created to facilitate a consistant style and behavoir for sharable items within the BambooHR app.
 * This react component was pulled out of the Reporting silo and turned into a global shared component. Though, not perfect, it
 * provides the ability to share different things within the app such as; performance goals, candidate applications, reports and hopefully much more.
 *
 * Properties:
 * {object}			data							- Data for things like filters for example, that need to be displayed in the modal
 * {boolean}		includeOwner					- Provides a way to include the owner as a person who can be shared with
 * {boolean}		isLoading						- Show a loading indicator when the modal first opens
 * {boolean}		noAccessListReset				- Provides a way to NOT reset the access list in some use cases
 * {function}		onClose							- Function that is invoked when the modal is closed
 * {function}		onRemove						- Function that is invoked when the a person is unshared
 * {function}		onSubmit						- Function that is invoked when the a person(s) is shared
 * {function}		onUpdate						- Function that is invoked to update the list of shared people
 * {function | jsx}	renderAccessListRightSlot		- Provides a way to render an additional component in the slot next to the remove button for each shared person
 * {function | jsx}	renderPeopleSelectorBottomSlot	- Provides a way to render an additional component in the slot below the people selector section
 * {function | jsx}	renderPeopleSelectorTopSlot		- Provides a way to render an additional component in the slot above the people selector section
 * {function | jsx} renderWarning					- Provides a way to render a warning underneath the access list and above the people selecter sections
 * {object}			settings						- Settings config object (see below)
 * {string}			subTitle						- Modal sub title
 * {string}			title							- Modal main title
 * {boolean}		visible							- Sets if the modal is visible or not
 *
 * Import:
 * import { ShareModal } from 'share-modal.react';
 *
 * Settings:
 * const settings = {
 * 		closeModalAfterSubmit: false,
 * 		ownerText: $.__('(You)'),						// Text next to the owner's name
 * 		quickSearchDescription: $.__('Enter names ...'),
 * 		quickSearchEndpoint: '/quicksearch/share/goal',
 * 		shareButtonText: $.__('Share'),
 * 		showMessage: true,
 * 		showNotify: false,								// Hides the checkbox to notify employees of a change
 * 		showRemoveButton: accessList.length > 1,		// Used to show the `X` if there is more than 1 employee in the list
 * 		successMessage: $.__('You have successfully shared the goal!'),
 * 		trackingAction: 'share-goal'					// Used for analytics
 * };
 *
 * JSX:
 * <ShareModal
 * 		data={ { accessList: [] } }
 * 		includeOwner={ true }
 * 		isLoading={ true }
 * 		noAccessListReset={ true }
 * 		onClose={ () => {} }
 * 		onRemove={ () => {} }
 * 		onSubmit={ () => {} }
 * 		onUpdate={ () => {} }
 * 		renderAccessListRightSlot={ filters => <FiltersTooltip filters={ filters } /> }
 * 		renderPeopleSelectorBottomSlot={ filters => <FiltersMessage filters={ filters } /> }
 * 		renderPeopleSelectorTopSlot={ message => <DisclaimerMessage message={ message } /> }
 * 		renderWarning={ message => <DisclaimerMessage message={ message } />}
 * 		settings={ settings }
 * 		subTitle={ <Message text={ $._('Who can view?') } /> }
 * 		title={ <Message text={ $.__('Share this Report') } /> }
 * 		visible={ true }
 * />
 */
export class ShareModal extends Component {

	get accessListData() {
		const { data: { accessList } } = this.props;

		return accessList;
	}

	get isOpen() {
		const { visible } = this.props;

		return visible;
	}

	get isProcessing() {
		const { isProcessing } = this.props;

		return isProcessing;
	}

	set accessListData(accessList) {
		const { onAccessListUpdate } = this.props;
		if (onAccessListUpdate) {
			onAccessListUpdate(accessList);
		}

		this.setState(() => {
			return { accessList };
		});
	}

	set isOpen(visible) {
		this.setState(() => {
			return { isOpen: visible };
		});
	}

	set isProcessing(isProcessing) {
		this.setState(() => {
			return { isProcessing };
		});
	}

	set accessListAddition(value) {
		const { accessList } = this.state;
		this.accessListData = accessList.concat(value);
	}

	set accessListSubtraction(value) {
		const { accessList } = this.state;
		this.accessListData = accessList.filter(item => !isEqual(item, value));
	}

	_getModalConfig({ classes, visible, title, onClose }) {
		return {
			onClose,
			options: {
				classes,
				footer: {
					buttons: {
						cancel: {
							show: false,
						},
						primary: {
							text: $.__('Done'),
						},
					},
				},
				preventSubmit: true,
				title,
			},
			visible,
		};
	}

	_getPrimaryAction() {
		const {
			onClose,
			settings,
		} = this.props;
		const {
			hasChanges,
			notify,
			rawAccessList,
		} = this.state;
		const {
			closeModalAfterSubmit = true,
			remainOpenOnError = false,
		} = settings;

		if (hasChanges) {
			if (rawAccessList) {
				this.isProcessing = true;
				this._handleSubmit({ ...prepareShareList(rawAccessList), notify }).then(() => {
					this.isProcessing = false;
					this.setState({ hasFocus: false });
					this._handleAlternativeAction();
					const hasAccessLevelErrors = false;
					if (closeModalAfterSubmit || (remainOpenOnError && hasAccessLevelErrors)) {
						onClose();
					}
				}).catch(() => {
					this.isProcessing = false;
				});
			} else {
				return this._handleUpdateFocus(false);
			}
			return;
		}
		return onClose();
	}

	_getPrimaryActionText() {
		const { settings } = this.props;
		const { hasChanges } = this.state;
		const {
			shareButtonText = $.__('Share'),
		} = settings;

		if (hasChanges) {
			return shareButtonText;
		}
		return $.__('Done');
	}

	_handleRemove = (data) => {
		const { onRemove } = this.props;

		this.isProcessing = true;
		return onRemove(data)
			.then(
				() => {
					// Update the view to remove the person or group
					this.accessListSubtraction = data;
					this.isProcessing = false;
				},
				(error) => {
					window.setMessage(error, 'info');
					console.error(error);
					this.isProcessing = false;

					return Promise.reject('Failed to submit');
				}
			);
	}

	_handleSubmit = (data, event) => {
		if (event) {
			event.preventDefault();
		}

		const {
			onSubmit,
			onUpdate,
			settings: {
				showMessage = true,
				successMessage = $.__('You have successfully shared this page!'),
			},
		} = this.props;

		return onSubmit(data)
			.then(
				(response) => {
					// Update the view to add the person(s)/group(s)
					onUpdate()
						.then(
							(response) => {
								if (response.data) {
									this.accessListData = response.data.data.accessList;
								}
							},
							(error) => {
								window.setMessage(error, 'info');
								console.error(error);

								return Promise.reject('Failed to get shareModal data');
							}
						);
					// Show success message
					if (showMessage) {
						const message = response && response.data && response.data.msg ?
							response.data.msg :
							successMessage;
						window.setMessage(message, 'success');
					}
				},
				(error) => {
					window.setMessage(error, 'info');
					console.error(error);

					return Promise.reject('Failed to submit');
				}
			);
	}

	_handleAlternativeAction = () => {
		const {
			onCancel,
		} = this.props;
		const {
			onCloseCallback,
		} = this.state;

		if (typeof onCancel === 'function') {
			onCancel();
		}
		onCloseCallback();
		$('.PeopleSelector__autocomplete .item').remove();
		$('.input-list').addClass('inactive').blur();
		this._handleUpdateFocus(false);
	}

	_handleUpdateFocus = (toggle = false, onCloseCallback = () => {}) => {
		this.setState({ hasFocus: toggle, onCloseCallback });
	}

	_handleUpdateList = (updatedList = []) => {
		const { onRawListChange, setShareList } = this.props;
		if (setShareList) {
			setShareList(updatedList);
		}
		if (onRawListChange) {
			onRawListChange(updatedList);
		}

		this.setState({ rawAccessList: updatedList, hasChanges: updatedList.length > 0 });
	};

	_handleUpdateNotify = (updatedNotify) => {
		this.setState({ notify: updatedNotify });
	}

	_findOwnerId = () => {
		const { accessList } = this.state;
		const owner = accessList && accessList.find(a => a.type === 'Owner');
		return owner && owner.id;
	}

	_renderWarning = () => {
		const { renderWarning } = this.props;

		if (renderWarning) {
			return renderWarning();
		}
	}

	_formRef = createRef();

	state = {
		accessList: this.accessListData,
		hasFocus: false,
		hasChanges: false,
		isOpen: this.isOpen,
		notify: true,
		isProcessing: this.isProcessing,
	};

	componentDidUpdate(prevProps, prevState) {
		const {
			data: {
				accessList,
			},
			visible,
		} = this.props;

		if (!isEqual(visible, prevProps.visible)) {
			this.isOpen = visible;
		}

		if (!isEqual(accessList, prevProps.data.accessList)) {
			this.accessListData = accessList;
		}
	}

	render() {
		const {
			biId,
			data: {
				filters,
			},
			isLoading,
			onClose,
			onAccessListClick,
			renderAccessListBottomSlot,
			renderAccessListRightSlot,
			renderPeopleSelectorBottomSlot,
			renderPeopleSelectorNotifyBottomSlot,
			renderPeopleSelectorNotifySiblingSlot,
			renderPeopleSelectorTopSlot,
			renderWarning,
			settings,
			sheetProps,
			subTitle,
			title,
			includeOwner,
		} = this.props;

		const {
			accessList,
			hasFocus,
			hasChanges,
			isOpen,
			isProcessing,
		} = this.state;

		const ownerId = !includeOwner && this._findOwnerId();

		const content = (
			<div className="ShareModal">
				<AccessList
					data={ accessList }
					isProcessing={ isProcessing }
					onClick={ onAccessListClick }
					onRemove={ this._handleRemove }
					renderBottomSlot={ renderAccessListBottomSlot }
					renderRightSlot={ renderAccessListRightSlot }
					settings={ settings }
					subTitle={ subTitle }
				/>
				{ this._renderWarning() }
				<PeopleSelector
					data={ filters }
					formRef={ this._formRef }
					onSubmit={ this._handleSubmit }
					ownerId={ ownerId }
					renderBottomSlot={ renderPeopleSelectorBottomSlot }
					renderNotifyBottomSlot={ renderPeopleSelectorNotifyBottomSlot }
					renderNotifySiblingSlot={ renderPeopleSelectorNotifySiblingSlot }
					renderTopSlot={ renderPeopleSelectorTopSlot }
					settings={ settings }
					updateFocus={ (hasFocus, onCloseCallback = () => {}) => this._handleUpdateFocus(hasFocus, onCloseCallback) }
					updateList={ updatedList => this._handleUpdateList(updatedList) }
					updateNotify={ updatedNotify => this._handleUpdateNotify(updatedNotify) }
				/>
			</div>
		);

		const options = {
			alternativeAction: hasChanges ? this._handleAlternativeAction : null,
			biId: `${biId ? `${biId}-` : ''}share-modal-${ hasChanges ? 'add' : 'done' }`,
			content,
			isLoading,
			isOpen,
			isProcessing,
			onClose,
			sheetProps,
			contentHasPadding: ifFeature('encore', true, false),
			primaryAction: event => this._getPrimaryAction(event),
			primaryActionText: this._getPrimaryActionText(),
			title,
			type: ifFeature('encore', 'medium', undefined)
		};

		return (
			<Modal { ...options } />
		);
	}
}
