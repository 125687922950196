import Avatar from '../item/avatar';
import Editor from '../editor';
import { PureComponent } from 'react';
import { noop } from 'lodash';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './styles.styl';

class ItemCreator extends PureComponent {

	static defaultProps = {
		editing: false,
		config: noop,
		focusOnMount: false,
		pending: false,
		onFocus: noop,
		onReset: noop,
		onSubmit: noop,
		onError: noop,
		onSuccess: noop,
	};

	handleFocus = (e, text, tempFiles) => (
		this.props.onFocus(e, text, tempFiles)
	);

	handleReset = (e, text, tempFiles) => (
		this.props.onReset(e, text, tempFiles)
	);

	create = (e, text, tempFiles, textarea) => {
		const {
			config,
			onSubmit,
			inReplyTo,
			addComment,
			onSuccess,
			onError
		} = this.props;
		e.preventDefault();

		onSubmit(e, text, tempFiles);
		$.post(`${ config.rootCommentURL }/${ config.parentType }/${ config.parentId }`, {
			text,
			attachIds: tempFiles.valueSeq().map(v => v.remoteId).toArray(),
			inReplyTo: inReplyTo,
		}).then(
			(feed) => {
				addComment(feed);
				onSuccess(e, text, tempFiles);
				textarea.value = '';
			},
			() => onError(e, text, tempFiles)
		);
	};

	render() {
		const currentUser = window.SESSION_USER || {};

		return (
			<div className="FeedInput">
				<div className="FeedInput__flexwrap">
					<Avatar size={ifFeature('encore', '32x32', '38x38')} src={ currentUser.avatarTiny } />

					<Editor
						config={ this.props.config }
						editing={ this.props.editing }
						focusOnMount={ this.props.focusOnMount }
						onFocus={ this.handleFocus }
						onReset={ this.handleReset }
						onSubmit={ this.create }
						pending={ this.props.pending }
					/>
				</div>
			</div>
		);
	}
}

export default ItemCreator;

