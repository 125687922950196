import { ADD_COMMENT } from '../../actions/constants';

export default { ADD_COMMENT: (state, action) => {
	state = state
		.mergeDeep(action.payload)
		.set('id', state.id);

	const reply = action.payload.getIn(['entities', 'replies', 'byId']).first();

	if (reply) {
		state = state.updateIn(
			['entities', 'comments', 'byId', reply.inReplyTo, 'replies'],
			v => v.add(reply.id)
		);
	} 

	return state;
} };
