let clipPathCount = 0;

export function clipPath(images, clipPath): void {

	for (let i = 0; i < images.length; i++) {

		clipPathCount++;

		// get IMG attributes
		const maskPath = clipPath;
		const maskSrc = $(images[i]).attr('src');
		const maskWidth = $(images[i]).outerWidth();
		const maskHeight = $(images[i]).outerHeight();
		const maskAlt = $(images[i]).attr('alt');

		// build SVG from our IMG attributes & path coords.

		const svg = $(`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="svgMask" width="${
			maskWidth }" height="${
			maskHeight }" viewBox="0 0 ${
			maskWidth } ${
			maskHeight }"><defs><clipPath id="maskID${ clipPathCount }">${
			maskPath }</clipPath>  </defs><desc>${
			maskAlt }</desc><image clip-path="url(#maskID${ clipPathCount })" width="${
			maskWidth }" height="${
			maskHeight }" xlink:href="${
			maskSrc }" /></svg>`);

		// swap original IMG with SVG
		$(images[i]).replaceWith(svg);
	}
}
