import classNames from 'classnames';
import { Select } from '@fabric/select';
import { useRef, useState } from 'react';
import { getUsageContext, getValidationContext } from '../../context';
import { normalizeOptions } from '../types/select';

export const CandidateVeteranStatus = (props) => {
	const {
		data,
		label,
		name,
	} = props;

	const usage = getUsageContext();
	const {
		setSpecialErrors,
		specialErrors,
	} = getValidationContext();
	const isVeteranOnce = useRef(false);
	const [isVeteran, setIsVeteran] = useState(false);
	const [selectedValues, setSelectedValues] = useState([]);

	const [declineAnswerOption, notVeteranOption, ...optionItems] = normalizeOptions(data.options);
	const hasRadioError = (!isVeteran && !!specialErrors[name]);
	const hasSelectError = !!specialErrors[name];
	const isRequired = (usage !== 'add');

	const _handleRadioChange = (event) => {
		const { id } = event.target;

		if (id === 'isVeteran') {
			setIsVeteran(true);

			if (isVeteranOnce.current && !selectedValues.length) {
				setSpecialErrors(name, { rule: 'required' });
			}

			isVeteranOnce.current = true;
		} else {
			isVeteranOnce.current = false;
			setIsVeteran(false);
			setSelectedValues([]);
			setSpecialErrors(name, null);
		}
	};

	const _handleSelect = (id, selected) => {
		setSelectedValues(selected);
		if (selected.length) {
			setSpecialErrors(name, null);
		} else {
			setSpecialErrors(name, { rule: 'required' });
		}
	};

	const _handleClear = () => {
		setSelectedValues([]);
		setSpecialErrors(name, { rule: 'required' });
	};


	return (
		<div className="fab-FormColumn CandidateField CandidateField--veteranStatusId">
			<fieldset className="fab-FormRow--tight fab-RadioGroup" onChange={ _handleRadioChange }>
				<legend className={ classNames('fab-RadioGroup__legend', {
					'fab-RadioGroup__legend--required': isRequired,
					'fab-RadioGroup__legend--error': hasRadioError,
				}) }
				>
					{ label }
				</legend>
				<div className="fab-Radio">
					<input
						className={ `fab-Radio__input ${ hasRadioError ? 'fab-Radio__input--error' : '' }` }
						disabled={ Boolean(data.isDisabled) }
						id="declineToAnswer"
						name={ `${ name }[]` }
						required={ isRequired }
						type="radio"
						value={ declineAnswerOption ? declineAnswerOption.value : '' }
					/>
					<label
						className={ classNames('fab-Radio__label', {
							'fab-Radio__label--required': isRequired,
							'fab-Radio__label--error': hasRadioError,
						}) }
						htmlFor="declineToAnswer"
					>
						{ declineAnswerOption ? declineAnswerOption.text : $.__('Decline to Answer') }
					</label>
				</div>
				<div className="fab-Radio">
					<input
						className={ `fab-Radio__input ${ hasRadioError ? 'fab-Radio__input--error' : '' }` }
						disabled={ data.isDisabled ? data.isDisabled : false }
						id="notVeteran"
						name={ `${ name }[]` }
						required={ isRequired }
						type="radio"
						value={ notVeteranOption ? notVeteranOption.value : '' }
					/>
					<label
						className={ classNames('fab-Radio__label', {
							'fab-Radio__label--required': isRequired,
							'fab-Radio__label--error': hasRadioError,
						}) }
						htmlFor="notVeteran"
					>
						{ notVeteranOption ? notVeteranOption.text : $.__('Not a Veteran') }
					</label>
				</div>
				<div className="fab-Radio">
					<input
						className="fab-Radio__input"
						disabled={ data.isDisabled ? data.isDisabled : false }
						id="isVeteran"
						name={ `${ name }[]` }
						required={ isRequired }
						type="radio"
						value=""
					/>
					<label
						className={ classNames('fab-Radio__label', {
							'fab-Radio__label--required': isRequired,
							'fab-Radio__label--error': hasRadioError,
						}) }
						htmlFor="isVeteran"
					>
						{ $.__('Veteran') }
					</label>
				</div>
			</fieldset>
			<div className={ `fab-FormRow--tight CandidateField CandidateField--veteranStatuses ${ (isVeteran) ? 'CandidateField--veteranStatusesShown' : '' }` }>
				<label
					className={ classNames('fab-Label', {
						'fab-Label--required': isRequired,
						'fab-Label--error': hasSelectError,
					}) }
					htmlFor="veteranStatuses"
				>
					{ label }
				</label>
				<div className="fab-InputWrapper CandidateField__inputWrapper">
					<Select
						canSelectMultiple={ true }
						isRequired={ isRequired }
						items={ optionItems }
						name={ `${ name }[]` }
						onClear={ _handleClear }
						onDeselect={ _handleSelect }
						onSelect={ _handleSelect }
						selectedValues={ selectedValues }
						width={ 100 }
					/>
				</div>
			</div>
		</div>
	);
};
