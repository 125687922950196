import { useEffect, useState } from 'react';
import { Button, CardContentSpacing, makeStyles, SheetModal, TextButton, IconV2 } from '@bamboohr/fabric';
// @startCleanup encore
import { TriangleExclamation54x54 } from '@bamboohr/grim';
// @endCleanup encore
import getCompanyInfo from 'getCompanyInfo.service';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface ConfirmationSheetProps {
	isOpen: boolean;
	isProcessing?: boolean;
	onRequestClose: () => void;
	onSubmit: () => void;
	productName?: string;
}

// @startCleanup encore
const useStyles = makeStyles(({ palette }) => ({
	icon: {
		fill: palette.warning.main,
	},
}));
// @endCleanup encore

export const ConfirmationSheet = ({ isOpen, isProcessing, onRequestClose, onSubmit, productName }: ConfirmationSheetProps) => {
	const [companyName, setCompanyName] = useState('');
	// @startCleanup encore
	const classes = useStyles();
	// @endCleanup encore

	useEffect(() => {
		getCompanyInfo().then((info) => {
			setCompanyName(info?.name || '');
		});
	}, []);

	const biId = 'in-app-cancellation-confirmation-sheet';
	const headline = productName
		? $.__('Are you sure you want to cancel %s for %s?', productName, companyName)
		: $.__('Are you sure you want to submit this cancellation request for %s?', companyName);
	return ifFeature(
		'encore',
		<SheetModal isOpen={isOpen} isProcessing={isProcessing} onRequestClose={onRequestClose}>
			<SheetModal.Body
				renderFooter={
					<SheetModal.Footer
						actions={[
							<TextButton data-bi-id={`${biId}-cancel-button`} key={`${biId}-cancel-button`} onClick={onRequestClose} type='button'>
								{$.__('Cancel')}
							</TextButton>,
							<Button data-bi-id={`${biId}-submit-button`} key={`${biId}-sheet-submit-button`} onClick={onSubmit} type='button'>
								{$.__('Submit Cancellation Request')}
							</Button>,
						]}
					/>
				}
				renderHeader={<SheetModal.Header title={$.__('Just Checking...')} />}
			>
				<SheetModal.Constraint spacingOverrides={{ top: CardContentSpacing.LARGE, bottom: CardContentSpacing.LARGE }}>
					<SheetModal.HeroHeadline
						icon='triangle-exclamation-solid'
						text={headline}
						subtext={$.__('After submitting you will receive an email with instructions for exporting your data.')}
					/>
				</SheetModal.Constraint>
			</SheetModal.Body>
		</SheetModal>,
		<SheetModal isOpen={isOpen} isProcessing={isProcessing} onRequestClose={onRequestClose}>
			<SheetModal.Body
				renderFooter={
					<SheetModal.Footer
						actions={[
							<Button data-bi-id={`${biId}-submit-button`} key={`${biId}-sheet-submit-button`} onClick={onSubmit} type='button'>
								{$.__('Submit Cancellation Request')}
							</Button>,
							<TextButton data-bi-id={`${biId}-cancel-button`} key={`${biId}-cancel-button`} onClick={onRequestClose} type='button'>
								{$.__('Cancel')}
							</TextButton>,
						]}
					/>
				}
				renderHeader={<SheetModal.Header title={$.__('Just Checking...')} />}
			>
				<SheetModal.Constraint spacingOverrides={{ top: CardContentSpacing.LARGE, bottom: CardContentSpacing.LARGE }}>
					<SheetModal.HeroHeadline
						icon={<TriangleExclamation54x54 className={classes.icon} />}
						text={headline}
						subtext={$.__('After submitting you will receive an email with instructions for exporting your data.')}
					/>
				</SheetModal.Constraint>
			</SheetModal.Body>
		</SheetModal>
	);
};
