import {
	EDITING_BEGIN,
	EDITING_CANCEL,
} from '../../actions/constants';

export default { EDITING_BEGIN: (state, action) => {
	return state.hasIn(['entities', 'comments', 'byId', action.payload])
		? state.setIn(['entities', 'comments', 'byId', action.payload, 'editing'], true)
		: state.setIn(['entities', 'replies', 'byId', action.payload, 'editing'], true);
}, EDITING_CANCEL: (state, action) => {
	return state.hasIn(['entities', 'comments', 'byId', action.payload])
		? state.setIn(['entities', 'comments', 'byId', action.payload, 'editing'], false)
		: state.setIn(['entities', 'replies', 'byId', action.payload, 'editing'], false);
}, };
