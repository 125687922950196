import { PureComponent } from 'react';

const creator = Wrapped => (
	class extends PureComponent {
		static displayName = `creator(${
			Wrapped.displayName ||
			Wrapped.name ||
			'Component'
		})`;

		state = {
			editing: false,
			pending: false,
		};

		actions = {
			beginEditing: () => this.setState({ editing: true }),
			cancelEditing: () => this.setState({ editing: false }),
			beginSaving: () => this.setState({ pending: true }),
			handleError: () => this.setState({ pending: false }),
			handleSuccess: () => this.setState({
				editing: false,
				pending: false,
			}),
		};

		render() {
			return <Wrapped
				{...this.state}
				{...this.props}
				{...this.actions}
			/>;
		}
	}
);

export default creator;

