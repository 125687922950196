var Employees = {};

import Files from './Files';
Employees.Files = Files;
export {Files};

import Job from './Job';
Employees.Job = Job;
export {Job};

import Payroll from './Payroll';
Employees.Payroll = Payroll;
export {Payroll};

export default Employees;
