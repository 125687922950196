import { sleep } from 'Promise.util';
import {
	filterFields,
	isVisible,
} from 'dynamic-form';

const message = $.__('Please select one Primary Contact');

export const onePrimaryContact = (context: DynamicForm.Context, field: DynamicForm.Data, params: any) => {
	return async (value: string): Promise<boolean | string> => {
		// is the element visible?
		const el = document.getElementById(field.props.id);
		if (!isVisible(el)) {
			return true;
		}
		const { validation: { setError, clearErrors } } = context;

		// sleep so React state is updated before we check all PrimaryContact Fields
		await sleep(100);

		const primaryContactFields = filterFields(context, { selector: 'props.className', value: 'primaryContact' }, { selector: 'key', value: ':idx:', match: 'notIncludes' });
		const primaryContatctChecked = Object.keys(primaryContactFields).filter(key => !!primaryContactFields[key]?.props?.checked);

		if (primaryContatctChecked.length > 1) {
			primaryContatctChecked.forEach((key) => {
				const name = primaryContactFields[key]?.props?.name;
				if (name) {
					setError(name, { type: 'onePrimaryContact', message });
				}
			});
			return message;
		}

		// if the checkbox ends up having more error states attached to it, this will have to be re-visited
		clearErrors(Object.keys(primaryContactFields).map(key => primaryContactFields[key]?.props?.name));

		return true;
	};
};
