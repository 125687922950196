import { isEmpty } from 'lodash';
import { useCallback, useState, useEffect } from 'react';
import Ajax from '@utils/ajax';

import { DEFAULT_OPTIONS } from './constants';
import {
	getFormattedOptions,
	getPostCodeLabel,
	getSubEntityLabel,
} from './utils';

/**
 * Data-fetching util hook for address form controls, e.g.
 * job application, location settings, EE profile.
 * @param options
 * @returns {{
 *      countryId: Number|String,
 *      postCodeLabel: String,
 *      subEntityLabel: String,
 *      subEntityOptions: Array,
 *      switchCountry: Function,
 * }}
 */
export function useCountrySwitcher(options) {
	options = { ...DEFAULT_OPTIONS, ...options };
	const {
		initialCountryId,
		initialPostCodeLabel,
		initialSubEntityLabel,
		initialSubEntityOptions,
	} = options;

	const [state, setState] = useState({
		countryId: initialCountryId,
		postCodeLabel: initialCountryId ? getPostCodeLabel(initialCountryId) : initialPostCodeLabel,
		subEntityLabel: initialCountryId ? getSubEntityLabel(initialCountryId) : initialSubEntityLabel,
		subEntityOptions: getFormattedOptions(initialSubEntityOptions),
	});

	const successHandler = useCallback(createSuccessHandler(setState), []);
	const switchCountry = useCallback(createSwitchCountry(options, successHandler), [options]);

	useEffect(() => {
		if (isEmpty(options.initialSubEntityOptions)) {
			switchCountry(options.initialCountryId);
		}
	}, []);

	return {
		countryId: state.countryId,
		postCodeLabel: state.postCodeLabel,
		subEntityLabel: state.subEntityLabel,
		subEntityOptions: state.subEntityOptions,
		switchCountry,
	};
}

export function createSwitchCountry({ endpoint }, successHandler) {
	return (newCountryId) => {
		return Ajax.get(`${ endpoint }${ newCountryId }`)
			.then((response) => {
				successHandler(newCountryId, response.data ? response.data.data : []);
			})
			.catch((error) => {
				if (error?.response?.status >= 500) {
					window.Rollbar.error(`Could not fetch state data for country id ${ newCountryId }, with status code ${ error?.response?.status }`, error);
				}
			});
	};
}

export function createSuccessHandler(callback) {
	return (newCountryId, newOptions) => {
		callback({
			countryId: newCountryId,
			postCodeLabel: getPostCodeLabel(newCountryId),
			subEntityLabel: getSubEntityLabel(newCountryId),
			subEntityOptions: getFormattedOptions(newOptions),
		});
	};
}
