import {Component} from 'react';
import {empty} from '@utils/dom';
import {isEqual} from 'lodash';
import {SliceChart} from 'Charts.mod';

export default class Slice extends Component {

	componentDidMount() {
		const {data, settings} = this.props;

		this._slice = new SliceChart(this._element, settings);
		this._slice.draw(data);
	}

	componentDidUpdate() {
		const {data, settings} = this.props;
		empty(this._element);
		this._slice.draw(data);
	}

	shouldComponentUpdate(nextProps) {
		return !isEqual(this.props.data, nextProps.data);
	}

	render() {
		return (
			<div ref={element => (this._element = element)}></div>
		);
	}

}
