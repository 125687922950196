/* eslint-disable react/no-danger */
import classNames from 'classnames';
import React from 'react';
import { ConfirmationContent } from './confirmation-content';
import './styles.styl';
import { sanitizeDangerousContent } from './utils';

export const ModalContent = function ModalContent(props) {
	const {
		aggressiveConfirmationText,
		normalizedContent,
		contentHasMaxHeight,
		contentHasPadding,
		dangerousContent,
		handleAggressiveConfirmationInput,
		isConfirmation,
	} = props;

	const childContent = function childContent() {
		if (isConfirmation) {
			return (
				<ConfirmationContent
					{...{
						aggressiveConfirmationText,
						dangerousContent,
						handleAggressiveConfirmationInput,
						normalizedContent,
					}}
				/>
			);
		} else if (!dangerousContent) {
			return normalizedContent;
		}

		return null;
	};

	return (
		<div
			className={classNames('legacyModal__content', {
				'legacyModal__content--noPadding': !contentHasPadding,
				'legacyModal__content--noMaxHeight': !contentHasMaxHeight,
			})}
			dangerouslySetInnerHTML={!isConfirmation && dangerousContent ? { __html: sanitizeDangerousContent(dangerousContent) } : null}
			id='fabricModalContent'
		>
			{childContent()}
		</div>
	);
};
