import { ReactElement } from 'react';
import {
	makeStyles,
	createStyles,
	IconV2,
	BodyText,
	Headline,
	Flex,
	useTheme,
	StyledBox,
	LayoutBox,
	Avatar,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isEmpty } from 'lodash';

interface Props {
	closeModal: () => Promise<void>;
	headerAction: () => void;
	renderActionButtons: (actions: Array<() => any>) => ReactElement;
	email?: string;
	subtitle: string;
	name: string;
	// @startCleanup encore
	imageJsx: ReactElement;
	// @endCleanup encore
	imageUrl: string;
}
const styles = makeStyles(({ palette, typography }) => {
	return createStyles({
		/* @startCleanup encore */
		mainHeader: {
			backgroundColor: palette.grey[1000],
			padding: '26px 50px',
			display: 'flex',
			alignItems: 'center',
		},
		headerIcon: {
			width: 'auto',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		headerTitles: {
			width: 'auto',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'stretch',
			paddingLeft: '15px',
		},
		title: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			'& h1': {
				color: 'white',
				fontWeight: typography.fontWeightSemibold,
				fontSize: '40px',
				marginTop: '5px',
			},
		},
		buttons: {
			width: 'auto',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginLeft: 'auto',
		},
		/* @endCleanup encore */
		subTitle: {
			flexGrow: 1,
			fontSize: typography.fabricFontSize('small'),
			display: 'flex',
			flexDirection: 'column',
			color: palette.grey[600],
			'& p': {
				position: 'relative',
				top: '5px',
			},
			'&__email': {},
		},
	});
});

/**
 * The NHP endpoint currently returns images/photo_person<dimensions> as an image URL, but other implementations for
 * employee data return initials and there are references to `initials` as empty state images in the NHP code. Unless
 * the backend is updated to return null when fetching NHP details, detecting one of the two fallback images can tell us
 * if we do not have a real employee photo.
 *
 * If either of these are returned, we can render our fallback icon.
 *
 * @see app/BambooHR/Silo/NewHirePacket/Shared/NewHirePacketInfoFieldTypeSet.php for backend fallback field
 */
const IMAGE_FALLBACK_URLS = [
	'images/photo_person',
	'initials',
];

export function PreviewerHeader(props: Props): ReactElement {
	const {
		closeModal,
		name = '',
		// @startCleanup encore
		imageJsx,
		// @endCleanup encore
		imageUrl = '',
		email = '',
		subtitle,
		headerAction,
		renderActionButtons = (actions: Array<() => any>) => {
			return null;
		},
	} = props;

	// If no image url is passed, or if we have one of the backend-defined employee fallback images, replace with the
	// user-regular icon
	const renderFallbackImage = isEmpty(imageUrl) || IMAGE_FALLBACK_URLS.some((urlFragment) => imageUrl.includes(urlFragment));

	/* @startCleanup encore */
	const classes = styles();
	/* @endCleanup encore */

	const headerClick = () => {
		headerAction();
		closeModal();
	};

	return (
		<>
			{ifFeature(
				'encore',
				<div className='PreviewerHeader'>
					<StyledBox backgroundColor={'neutral-extra-extra-strong'} padding='26px 50px'>
						<Flex display='flex' alignItems='center'>
							{
								renderFallbackImage
								? <IconV2 color='neutral-forced-white' name={'user-regular'} size={60} />
								: <Avatar alt={$.__('Image of employee %1', name)} size={64} src={imageUrl} />
							}
							<Flex
								width='auto'
								height='100%'
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='stretch'
								paddingLeft='15px'
							>
								<BodyText color='neutral-inverted' size='small'>
									{subtitle}
								</BodyText>
								<Headline color='neutral-inverted' size='large'>
									{name}
								</Headline>
								{email && (
									<Flex flexGrow={1} flexDirection={'column'}>
										<div className={`${classes.subTitle}__email`}>
											<BodyText color='neutral-inverted' size='small'>
												{email}
											</BodyText>
										</div>
									</Flex>
								)}
							</Flex>
							<Flex width='auto' height='100%' display='flex' alignItems='center' justifyContent='center' marginLeft='auto'>
								{renderActionButtons([closeModal, headerClick])}
							</Flex>
						</Flex>
					</StyledBox>
				</div>,
				<div className='PreviewerHeader'>
					<div className={classes.mainHeader}>
						<>
							<div className={classes.headerIcon}>{imageJsx}</div>
							<div className={classes.headerTitles}>
								<div className={classes.subTitle}>
									<p>{subtitle}</p>
								</div>
								<div className={classes.title}>
									<h1>{name}</h1>
								</div>
								{email && (
									<div className={`${classes.subTitle} ${classes.subTitle}__email`}>
										<p>{email}</p>
									</div>
								)}
							</div>
						</>
						<div className={classes.buttons}>{renderActionButtons([closeModal, headerClick])}</div>
					</div>
				</div>
			)}
		</>
	);
}
