import classNames from 'classnames';
import { useState } from 'react';
import { Flex, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const YesNo = (props) => {
	const { errorState, label, name, data, validationRegistration } = props;

	if (!data) {
		return null;
	}

	const [selectedValue, setSelectedValue] = useState(data.value);

	return (
		<fieldset className={`CandidateField CandidateField--${name}`}>
			<legend
				className={classNames(`fab-RadioGroup__legend`, {
					'fab-RadioGroup__legend--error': errorState,
					'fab-RadioGroup__legend--required': data.isRequired,
				})}
			>
				{label}
			</legend>
			<div className='fab-InputWrapper CandidateField__inputWrapper CandidateField__inputWrapper--cqYesNo'>
				<Flex flexDirection={ifFeature('encore', 'column')} gap={ifFeature('encore', 1, 2)}>
					<LayoutBox marginBottom={ifFeature('encore', 1)}>
						<div className={ifFeature('encore', 'fab-Radio', 'fab-FormColumn fab-Radio')}>
							<input
								ref={validationRegistration} // only need it on this one
								checked={selectedValue === 'yes'}
								className='fab-Radio__input'
								disabled={Boolean(data.isDisabled)}
								id={`${name}--yes`}
								name={name}
								onChange={() => setSelectedValue('yes')}
								required={data.isRequired}
								type='radio'
								value='yes'
							/>
							<label className='fab-Radio__label' htmlFor={`${name}--yes`}>
								{$.__(`Yes`)}
							</label>
						</div>
					</LayoutBox>
					<div className={ifFeature('encore', 'fab-Radio', 'fab-FormColumn fab-Radio')}>
						<input
							checked={selectedValue === 'no'}
							className='fab-Radio__input'
							disabled={Boolean(data.isDisabled)}
							id={`${name}--no`}
							name={name}
							onChange={() => setSelectedValue('no')}
							required={data.isRequired}
							type='radio'
							value='no'
						/>
						<label className='fab-Radio__label' htmlFor={`${name}--no`}>
							{$.__(`No`)}
						</label>
					</div>
				</Flex>
			</div>
		</fieldset>
	);
};
