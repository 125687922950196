import {
	Modal,
} from 'modal-legacy';
import {
	useState,
} from 'react';

import {
	showSlidedown,
} from 'Alerts.mod';
import {
	Message,
} from '@bamboohr/utils/lib/message';

import {
	Agreement,
} from './agreement.react';
import {
	Headline,
} from './headline.react';
import {
	DPA_HEADLINE_ICON,
} from '../constants';
import {
	acceptDataProcessingAgreement,
} from '../service';

export function RequestedDpaModal(props) {
	const {
		agreement,
		isOpen,
		onClose = () => {},
	} = props;

	const [isAccepted, setIsAccepted] = useState(false);

	const handlePrimaryAction = async () => {
		try {
			await acceptDataProcessingAgreement(agreement.id);

			onClose();

			showSlidedown($.__('Another win! Thanks for accepting our Data Processing Agreement.'), 'success');
		} catch (e) {
			console.error(e);

			showSlidedown($.__('Huh? Something went wrong while accepting the Data Processing Agreement. Please contact support.'), 'error');
		}
	};

	return (
		<Modal
			alternativeAction={ null }
			hasCloseButton={ false }
			isOpen={ isOpen }
			primaryAction={ isAccepted ? handlePrimaryAction : null }
			primaryActionText={ $.__('Save') }
		>
			<Headline
				icon={ DPA_HEADLINE_ICON }
			>
				<Message
					text={ $.__('Someone At Your Company Requested Our\n Data Processing Agreement.') }
				/>
			</Headline>

			<div style={ { marginTop: '24px' } }>
				<Agreement
					isAccepted={ isAccepted }
					onChange={ _isAccepted => setIsAccepted(_isAccepted) }
				/>
			</div>
		</Modal>
	);
}
