import { Select } from '@bamboohr/fabric';
import NoteIcon from 'time-tracking/components/note-icon.react';
import {
	convertNormalizedProjectsToDropdownItems,
	getProjectSelectToggleText
} from 'time-tracking/utils';

import { useStyles } from './entry-project-selector.styles';

export function EntryProjectSelector(props) {
	const styles = useStyles();
	const {
		canEdit,
		id,
		onAddNote,
		onProjectChange,
		projectData,
		projectTaskSelectStyles,
		projectsWithTasks,
		showNoteInput
	} = props;

	let selectedProjectOrTask = null;
	if (projectData && projectData.projectId && projectData.taskId) {
		selectedProjectOrTask = `${ projectData.projectId }-${ projectData.taskId }`;
	} else if (projectData && projectData.projectId) {
		selectedProjectOrTask = projectData.projectId;
	}

	const projectSelectSettings = {
		items: convertNormalizedProjectsToDropdownItems(projectsWithTasks),
		onSelect: value => onProjectChange(id, value),
		onClear: () => onProjectChange(id, null),
		placeholder: `--${ $.__('Select Project/Task') }--`,
		renderToggleContent: selectedValue => getProjectSelectToggleText(selectedValue, projectData.projectName, projectData.taskName),
		selectedValues: [selectedProjectOrTask],
		width: 7,
	};

	return (
		<div className={ styles.wrapper }>
			{ projectsWithTasks.allIds.length > 0 && (
				<span className={ [styles.projectTaskSelect, projectTaskSelectStyles].join(' ') }>
					<label className="fab-Label" htmlFor="projectSelect">{ $.__('Save time to...') }</label>
					<Select isDisabled={ !canEdit } name="projectSelect" { ...projectSelectSettings } />
				</span>
			) }

			{ (showNoteInput || canEdit) && (
				<NoteIcon
					customCSS={ { 'AddEditEntry__noteIconWrapper': true } }
					disabled={ !canEdit || showNoteInput }
					hasNote={ showNoteInput }
					onNoteIconClick={ () => onAddNote(id) }
				/>
			) }
		</div>
	);
}
