import {
	getMaxZIndex,
} from '@utils/dom';

import {
	executeFunction,
	setupWrappers
} from 'BambooHR.util';

import {
	Modal,
} from 'legacy-modules/Modals.mod';

import velocity from 'velocity-animate';

/**
 * Simple Modal is used to create a modal that is light-weight and extremely fast
 *
 * Visit simple_modal.md for detailed information
 *
 * @type Object
 */
var SimpleModal = {
	/**
	 * Close a simple modal
	 *
	 * @param {string} type The type of close action to perform
	 *
	 * @returns {undefined}
	 */
	closeModal(closeOptions) {
		if (this.getModalCount() < 1) {
			return false;
		}

		closeOptions = this._mergeCloseOptions(closeOptions);
		var options = this._getTopModalOptions();

		$(document).trigger('simplemodal:close:before');
		var closeBeforeReturnVal = executeFunction(options.callbacks.close.before);
		if (closeBeforeReturnVal === false) {
			return false;
		}
		var $modal = $(this.getTopModalSelector());

		//Clear all button handlers
		if (options.footer.show === true && options.footer.custom.html === '' && options.footer.custom.url === '') {
			this.Footer.Buttons.clearAllEventHandlers($modal);
		}

		// Check for closeOptions actions
		if (closeOptions.type === 'fast') {
			$modal.remove();

			// Make sure I can scroll again :)
			if (this._openModals.length === 1) {
				Modal.disableNoScroll();
			}
		} else if (this._openModals.length > 1 && !options.nostack) {
			$modal.find(this._selectors.wrapper).velocity(
				"slideUp", {
					complete() {
						$modal.remove();
					},
					duration: 250
				}
			);
		} else {
			// Check for animation
			if (options.animation) {
				$modal.velocity('fadeOut', {
					complete(elements) { $modal.remove(); },
					duration: 100, easing: [0.25, 0.5, 0.5, 0.9]
				});
			} else {
				$modal.remove();
			}

			// Make sure I can scroll again :)
			if (this._openModals.length === 1) {
				Modal.disableNoScroll();
			}
		}
		this._openModals.pop();

		if (!closeOptions.skipCloseCallback) {
			executeFunction(options.callbacks.close.after);
			$(document).trigger('simplemodal:close:after');
		}
	},

	/**
	 * Initialize the Simple Modal. Currently is called on page load automatically in this file.
	 *
	 * @returns {undefined}
	 */
	init() {
		this.$ = setupWrappers(this.$);
		this._enableButtons();
		this._setupListeners();

		window.SimpleModal = this;
	},

	/**
	 * Open a simple modal and load data from the given url
	 *
	 * @param {Object} options A JavaScript Object with options for the simple modal
	 *
	 * Current options (short):
	 *	{
	 *		animation: boolean //Enable snazzy animation
	 *		callbacks: object  //Callbacks for the modal
	 *		classes:   string  //Any classes to add to the modal
	 *		data:      object  //Data to send with request
	 *		footer:    object  //Options for the footer. If this object is ommitted, the footer will not be shown.
	 *		html:      string  //Html to load instead of using a url
	 *		title:     string  //The title for the modal
	 *		url:       string  //The url to get data from for the modal
	 *	}
	 *
	 * Current options (long):
	 *	{
	 *		body: {
	 *			classes: string  // Any classes to add to the modal body
	 *		},
	 *		callbacks: {     //Callbacks for the modal
	 *			close: {
	 *				after() { return true; },
	 *				before() { return true; }   //Return false to cancel the closeModal call
	 *			},
	 *			open: {
	 *				after() { return true; },
	 *				before() { return true; }   //Return false to cancel the openModal call
	 *			}
	 *		}
	 *		classes: string  //Any classes to add to the modal
	 *		closeOnEscape: true // Allows the modal to close when the escape key is pressed
	 *		data:    object  //Data to send with request
	 *		footer: {		 //Options for the footer. If this object is ommitted, the footer will not be shown.
	 *			show: false, //required to show the footer (must be set to boolean true)
	 *			buttons: {
	 *				primary: {
	 *					disabled: false,
	 *					show: true,
	 *					text: $.__('Save'),
	 *					action() {
	 *						$modal.find('form').submit();
	 *					},
	 *					performActionOnKeyCode: -1
	 *				},
	 *				secondary: {
	 *					disabled: false,
	 *					show: false,
	 *					text: '',
	 *					action() {},
	 *					performActionOnKeyCode: -1
	 *				},
	 *				cancel: {
	 * 					disabled: false,
	 *					show: true,
	 *					text: $.__('Cancel'),
	 *					action() {
	 *						SimpleModal.closeModal();
	 *					},
	 *					performActionOnKeyCode: -1
	 *				}
	 *			},
	 *			custom: {
	 *				url: '',
	 *				html: ''
	 *			},
	 *			text: {
	 *				show: false,
	 *				html: '',
	 *				text: ''
	 *			}
	 *		}
	 *		title: string     //The title for the modal (DEPRECATED - accepted for backwards compatibility)
	 *		header: {		  //Contains pieces of the header including the title
	 *			show: true,
	 *			title: {
	 *				show: true,
	 *				text: ''
	 *			}
	 *		}
	 *		html: string      //Html to load instead of using a url
	 *		url: string       //The url to get data from for the modal
	 *		method: 'get'     //The request type/method. Only used when a URL is present. ('get' or 'post')
	 *	}
	 *
	 * @returns {wrapper|boolean} The jQuery wrapped modal opened or false if the modal could not be opened
	 */
	openModal(options) {
		options = this._mergeOptions(options);

		//Make sure the same modal doesn't accidentally get opened twice by double click, multiple enter press, etc.
		if (this._openModals.length > 0 && JSON.stringify(this._openModals[this._openModals.length - 1].options) === JSON.stringify(options)) {
			return false;
		}

		//Make sure a url or html is given
		if (options.url === '' && options.html === '') {
			console.warn("Could not find a url to load from or html to show.");
			return false;
		}

		//Run the before open callback
		$(document).trigger('simplemodal:open:before');
		var openBeforeReturnVal = executeFunction(options.callbacks.open.before);

		//If the result is false, stop opening the modal (strictly boolean false because it may return undefined)
		if (openBeforeReturnVal === false) {
			return false;
		}

		var that = this,
			$modalHtml = this.$.html.clone(),
			modalClass = this._selectors.prefix + this._openModals.length,
			modalSelector = '.' + modalClass;

		if (typeof options.classes === 'undefined') {
			options.classes = '';
		}

		if (typeof options.data === 'undefined') {
			options.data = {};
		}

		$modalHtml.addClass(options.classes + ' ' + modalClass);

		if (options.body && options.body.classes) {
			$modalHtml.find(this._selectors.body).addClass(options.body.classes);
		}

		//Append the html to the body or existing modal
		this._appendModal($modalHtml);

		if (!this._openModals.length) {
			Modal.enableNoScroll();
		}

		//Add animation class if animation is specified
		if (options.animation) {
			$(this._selectors.wrapper).addClass('SimpleModal__wrapper--animate');
		}

		//Set the jQuery modal object for future use
		var $modal = $(modalSelector);

		//Set the title
		this.Header.Title.setTitle(options.header.title.text || options.title, $modal);

		//Hide the header if necessary
		if (options.header.show === false) {
			$modal.addClass('SimpleModal--noHeader');
			this.Header.hide($modal);
		}

		//Hide the title if necessary
		if (options.header.title.show === false) {
			this.Header.Title.hide($modal);
		}

		//Hide the close button if necessary
		if (options.header.hideCloseButton) {
			this.Header.hideCloseButton($modal);
		}

		//Set the z-index of the modal container
		this._setZIndex($modal);

		//Set the background div's z-index
		this._setModalBgZIndex($modal);

		//Set the wrapper div's z-index
		this._setModalZIndex($modal);

		//Set the loader div's z-index
		this._setLoaderZIndex($modal);

		//Add the modal selector to the new array
		this._openModals.push({$: $modal, options: options});

		//Get the data from the requested url
		if (options.url) {
			$.ajax({
				url: options.url,
				data: options.data,
				type: options.method,
				success(response) {

					// If you supply a function for the option responseToHTML,
					// you can use the response to build custom HTML
					// For example, you could convert a JSON request to a react app or microtemplate
					if (options.responseToHTML) {
						that._loadHtml($modal, options.responseToHTML(response), options);
						return;
					}

					//If there was an error, close the modal and show a message
					if (response === 'ERROR' || (typeof response === 'object' && !response.success)) {
						if (response.error) {
							setMessage(response.error, 'error');
						} else {
							setMessage($.__("There was an error processing your request"), 'error');
						}
						that.closeModal();
						return;
					}
					var html;
					if (typeof response === 'object') {
						html = response.html;
					} else {
						html = response;
					}
					that._loadHtml($modal, html, options);
				},
				error: function(response) {
					if (response.responseText) {
						setMessage(response.responseText, 'error');
					} else {
						errorFallBack();
					}
					that.closeModal();
				}
			});
		} else {
			this._loadHtml($modal, options.html, options);
		}
		return $modal;
	},

	/**
	 * Returns the top-most (highest z-index) or most recently placed modal's wrapper
	 *
	 * @returns {wrapper}
	 */
	getTopModalSelector() {
		if (this._openModals.length > 0) {
			return this._openModals[this._openModals.length - 1].$;
		}
		return '';
	},

	/**
	 * This object contains jQuery wrapped selectors. The button that can be found on any given page
	 * and the the HTML for the modal itself.
	 *
	 * @type Object
	 */
	$: {
		button: '.js-simplemodal',
		html: `<div class="SimpleModal js-simplemodal-container">
					<div class="SimpleModal__bg js-simplemodal-bg"></div>
					<div class="SimpleModal__loader js-simplemodal-loader">
						<div class="dotLoader">
							<div class="bounce1"></div>
							<div class="bounce2"></div>
							<div class="bounce3"></div>
						</div>
						${ $.__('Loading...') }
					</div>
					<div class="SimpleModal__wrapper js-simplemodal-wrapper">
						<div class="SimpleModal__header js-simplemodal-header clearfix">
							<span class="SimpleModal__header-title truncate js-simplemodal-title"></span>
							<span class="SimpleModal__header-close js-simplemodal-close">
								<svg width="11px" height="11px" viewBox="0 0 11 11">
									<g stroke-width="1" sketch:type="MSPage">
										<path d="M6.71884008,5.19230161 L9.99010931,1.92103238 C10.4698785,1.44126315 10.5190324,0.705224691 10.0991478,0.28545546 C9.67937854,-0.134429155 8.94334008,-0.0852753088 8.46357085,0.394493922 L5.19230161,3.66576315 L1.92103238,0.394493922 C1.44126315,-0.0852753088 0.705224691,-0.134429155 0.28545546,0.28545546 C-0.134429155,0.705224691 -0.0852753088,1.44126315 0.394493922,1.92103238 L3.66576315,5.19230161 L0.394493922,8.46357085 C-0.0852753088,8.94334008 -0.134429155,9.67937854 0.28545546,10.0991478 C0.705224691,10.5190324 1.44126315,10.4698785 1.92103238,9.99010931 L5.19230161,6.71884008 L8.46357085,9.99010931 C8.94334008,10.4698785 9.67937854,10.5190324 10.0991478,10.0991478 C10.5190324,9.67937854 10.4698785,8.94334008 9.99010931,8.46357085 L6.71884008,5.19230161" class="modalXpath"></path>
									</g>
								</svg>
							</span>
						</div>
						<div class="SimpleModal__body js-simplemodal-body" style="visibility: hidden;"></div>
						<div class="SimpleModal__footer-wrapper js-simplemodal-footer-wrapper" style="display: none;">
							<div class="SimpleModal__footer js-simplemodal-footer clearfix">
								<button class="btn btnAction SimpleModal__footer-button-primary js-simplemodal-button-primary"></button>
								<button class="btn SimpleModal__footer-button-secondary js-simplemodal-button-secondary" style="display: none;"></button>
								<a class="SimpleModal__footer-cancel js-simplemodal-cancel"></a>
								<span class="SimpleModal__footer-text js-simplemodal-footer-text" style="display: none;"></span>
							</div>
						</div>
					</div>
				</div>`
	},

	/**
	 * The selectors for the simple modal
	 *
	 * Because the HTML is built on the fly for the modal, the selectors cannot be run through the
	 * utility function to setup jQuery wrappers. They must be run through the jQuery selector when
	 * you need to use them.
	 *
	 * @type Object
	 */
	_selectors: {
		prefix: 'js-simplemodal-',
		openModalButton: '.js-simplemodal',

		background: '.js-simplemodal-bg',
		body: '.js-simplemodal-body',
		buttonPrimary: '.js-simplemodal-button-primary',
		buttonSecondary: '.js-simplemodal-button-secondary',
		cancel: '.js-simplemodal-cancel',
		close: '.js-simplemodal-close',
		container: '.js-simplemodal-container',
		footer: '.js-simplemodal-footer',
		footerWrapper: '.js-simplemodal-footer-wrapper',
		header: '.js-simplemodal-header',
		docHtml: 'html',
		loader: '.js-simplemodal-loader',
		title: '.js-simplemodal-title',
		wrapper: '.js-simplemodal-wrapper'
	},

	/**
	 * The closeOptions object with default settings
	 *
	 * @type Object
	 */
	_closeOptions: {
		type: '',
		skipCloseCallback: false
	},

	/**
	 * The options object with default settings
	 *
	 * @type Object
	 */
	_options: {
		animation: false,
		body: {
			classes: ''
		},
		callbacks: {
			close: {
				after() { return true; },
				before() { return true; }
			},
			open: {
				after() { return true; },
				before() { return true; }
			}
		},
		classes: '',
		closeOnEscape: true,
		data: {},
		footer: {
			show: true,
			buttons: {
				primary: {
					disabled: false,
					show: true,
					text: $.__('Save'),
					action($modal) {
						$modal = SimpleModal._verifyModalObject($modal);
						$modal.find('form').submit();
					},
					performActionOnKeyCode: -1
				},
				secondary: {
					disabled: false,
					show: false,
					text: '',
					action() {},
					performActionOnKeyCode: -1
				},
				cancel: {
					disabled: false,
					show: true,
					text: $.__('Cancel'),
					action() {
						SimpleModal.closeModal();
					},
					performActionOnKeyCode: -1
				}
			},
			custom: {
				url: '',
				html: ''
			},
			text: {
				show: false,
				html: '',
				text: ''
			}
		},
		nostack: true,
		title: '', //Alias for header.title.text
		header: {
			hideCloseButton: false,
			show: true,
			title: {
				show: true,
				text: ''
			}
		},
		html: '',
		url: '',
		method: 'get',
		responseToHTML: null,
		deleteModal: {
			active: false,
			selector: ''
		},
		preventSubmit: false
	},

	/**
	 * Array with modals that are currently open - keeps track of the selector string for the modal
	 *
	 * @type Array
	 */
	_openModals: [],

	/**
	 * Append the modal html to the appropriate location. The first modal is appended to the body while stacked modals
	 * are appended to top-most existing modal's wrapper div.
	 *
	 * @param {String|wrapper} modalHtml The html as a string or jQuery wrapper to append to the DOM
	 *
	 * @returns {undefined}
	 */
	_appendModal($modalHtml) {
		var that = this,
			options = this._getTopModalOptions();

		$('body').append($modalHtml);

		var $background = $modalHtml.children(this._selectors.background),
			$loader = $modalHtml.children(this._selectors.loader);

		if (options.animation) {
			$background.velocity('fadeIn', { duration: 300 });
			$loader.velocity('fadeIn', { delay: 100, duration: 300 });
		} else {
			$background.css('opacity', '1');
			$loader.addClass('show');
		}

		if (this._openModals.length > 0) {
			$modalHtml.addClass('SimpleModal--stacked');
			$modalHtml.find('.SimpleModal__footer > .btn').addClass('btnSmall');
		}
	},

	/**
	 * Get all data-* attributes and place them in an object
	 *
	 * @param {wrapper} $object The jQuery wrapped button that triggered the modal call
	 *
	 * @returns {Object} object created from any and all footer-* attributes
	 */
	_getDataAttributes($object) {
		return {
			animation: $object.data('animation'),
			body: {
				classes: $object.data('body-classes')
			},
			callbacks: {
				close: {
					after: $object.data('callbacks-close-after'),
					before: $object.data('callbacks-close-before')
				},
				open: {
					after: $object.data('callbacks-open-after'),
					before: $object.data('callbacks-open-before')
				}
			},
			classes: $object.data('classes'),
			closeOnEscape: $object.data('close-on-escape'),
			data: $object.data('data'),
			footer: {
				show: $object.data('footer-show'),
				buttons: {
					primary: {
						disabled: $object.data('footer-button-primary-disabled'),
						show: $object.data('footer-button-primary-show'),
						text: $object.data('footer-button-primary-text'),
						action: $object.data('footer-button-primary-action'),
						performActionOnKeyCode: $object.data('footer-button-primary-perform-action-on-key-code')
					},
					secondary: {
						disabled: $object.data('footer-button-secondary-disabled'),
						show: $object.data('footer-button-secondary-show'),
						text: $object.data('footer-button-secondary-text'),
						action: $object.data('footer-button-secondary-action'),
						performActionOnKeyCode: $object.data('footer-button-secondary-perform-action-on-key-code')
					},
					cancel: {
						disabled: $object.data('footer-button-cancel-disabled'),
						show: $object.data('footer-button-cancel-show'),
						text: $object.data('footer-button-cancel-text'),
						action: $object.data('footer-button-cancel-action'),
						performActionOnKeyCode: $object.data('footer-button-cancel-perform-action-on-key-code')
					}
				},
				custom: {
					url: $object.data('footer-custom-url'),
					html: $object.data('footer-custom-html')
				},
				text: {
					show: $object.data('footer-text-show'),
					html: $object.data('footer-text-html'),
					text: $object.data('footer-text-text')
				}
			},
			nostack: $object.data('nostack'),
			title: $object.data('title'),
			header: {
				show: $object.data('header-show'),
				title: {
					show: $object.data('header-title-show'),
					text: $object.data('header-title-text')
				}
			},
			html: $object.data('html'),
			url: $object.data('url'),
			method: $object.data('method'),
			deleteModal: {
				active: $object.data('delete-modal-active'),
				selector: $object.data('delete-modal-selector')
			},
			preventSubmit: $object.data('prevent-submit')
		};
	},

	/**
	 * How many modals are open?
	 *
	 * @return number
	 */
	getModalCount() {
		return this._openModals.length;
	},

	/**
	 * Returns the top-most (highest z-index) or most recently placed modal's options
	 *
	 * @returns {String}
	 */
	_getTopModalOptions() {
		if (this._openModals.length > 0) {
			return this._openModals[this._openModals.length - 1].options;
		}
		return '';
	},

	/**
	 * Run the setup for a delete modal given a selector
	 *
	 * @param {String}  selector The jQuery selector for the input
	 * @param {wrapper} $modal   The jQuery wrapper for the modal
	 *
	 * @private
	 */
	_deleteModalSetup(selector, $modal) {
		var $input = $modal.find(selector);
		if ($input.length > 0) {
			$input.focus();
			$input.on('keyup', function() {
				if (this.value.toUpperCase() === 'DELETE') {
					SimpleModal.Footer.Buttons.Primary.enable();
				} else {
					SimpleModal.Footer.Buttons.Primary.disable();
				}
			});
		}
	},

	/**
	 * Enable the modal opening buttons (AKA add listeners)
	 *
	 * @returns {undefined}
	 */
	_enableButtons() {
		var that = this;
		$('body').on('click', this._selectors.openModalButton, function(e) {
			var $this = $(this);
			that.openModal(that._getDataAttributes($this));
		});
	},

	/**
	 * Hide the footer
	 *
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_hideFooter($modal) {
		$modal = this._verifyModalObject($modal);
		var $footer = $modal.find(this._selectors.footerWrapper);
		var $header = $modal.find(this._selectors.header);
		var height = 0;
		if ($header.is(':visible')) {
			height += $header.outerHeight();
		}
		this._setBodyAndLoaderHeight(height, $modal);
		this.Footer.hide($modal);
	},

	/**
	 * Load html into the modal (this may come from an ajax get call or from passed html)
	 *
	 * @param {wrapper} $modal  The jQuery wrapper for the modal
	 * @param {String}  html    The html to add to the modal
	 * @param {Object}  options The options
	 *
	 * @returns {undefined}
	 */
	_loadHtml($modal, html, options) {
		// jQuery.html also handles fragments which is in use in the app. Any changes need to be compatible with fragments.
		$modal.find(this._selectors.body).html(html);
		//Setup or hide the footer (default hidden)
		this._setFooter(options.footer, $modal);
		this._waitForResourcesToLoad(options, $modal);
	},

	/**
	 * Merge the default options with the user passed options
	 *
	 * @param {Object} options The options passed
	 *
	 * @return Object The merged object
	 * @private
	 */
	_mergeOptions(options) {
		options = $.extend(true, {}, this._options, options);
		if (options.deleteModal.active) {
			options.footer.buttons.primary.disabled = true;
		}
		return options;
	},

	/**
	 * Create the closeOptions object with the user passed options
	 *
	 * @param {Object} {string} options The options passed
	 *
	 * @return Object The merged object with a set .type attribute
	 */
	_mergeCloseOptions(options) {
		if (typeof options === 'string') {
			this._closeOptions.type = options;
		}

		return $.extend(true, {}, this._closeOptions, options);
	},

	/**
	 * Set the body and loader height to account for any headers and/or footers
	 *
	 * @param {float}   height The height to subtract from the height of the loader/body (AKA the height of any footers/headers)
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_setBodyAndLoaderHeight(height, $modal) {
		$modal = this._verifyModalObject($modal);
		$modal.find(this._selectors.body).css('height', 'calc(100% - ' + height + 'px)');
	},

	/**
	 * Setup the modal footer
	 *
	 * @param {Object}  footer The footer object with what to show/hide, actions, etc.
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_setFooter(footer, $modal) {
		var that = this;
		$modal = this._verifyModalObject($modal);

		//Footer settings passed, show the footer and setup the necessary items
		if (footer.show === true) {
			var $footer = $modal.find(this._selectors.footerWrapper);
			var $header = $modal.find(this._selectors.header);
			var height = $footer.outerHeight();
			if ($header.is(':visible')) {
				height += $header.outerHeight();
			}

			//Custom Footer html
			if (footer.custom.html !== '') {
				$footer.html(footer.custom.html);
				//Reset the body and loader height here because the one below uses an ajax call
				this._setBodyAndLoaderHeight(that._getHeaderAndFooterHeight($modal), $modal);
			} else if (footer.custom.url !== '') { //Custom Footer Url
				$footer.empty();
				//Get the data from the requested url
				$.get(footer.custom.url, function(html) {
					$footer.html(html);
					//Reset the body and loader height here because this one uses an ajax call
					that._setBodyAndLoaderHeight(that._getHeaderAndFooterHeight($modal), $modal);
				});
			} else { //Standard footer and buttons
				this._setFooterButtons(footer.buttons, $modal);
				this._setFooterText(footer.text, $modal);
				this._setBodyAndLoaderHeight(that._getHeaderAndFooterHeight($modal), $modal);
			}

			//Show the footer after the contents have been setup
			$footer.show();
		} else { //Otherwise hide the footer
			$modal.addClass('SimpleModal--noFooter');
			this._hideFooter($modal);
		}
	},

	_getHeaderAndFooterHeight: function($modal) {
		var $footer = $modal.find(this._selectors.footerWrapper);
		var $header = $modal.find(this._selectors.header);
		var height = $footer.outerHeight();
		if ($header.is(':visible')) {
			height += $header.outerHeight();
		}
		return height;
	},

	/**
	 * Set footer buttons up with show/hide, actions, and text
	 *
	 * @param {Object}  buttons The buttons object with data to update the buttons with
	 * @param {wrapper} $modal  The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_setFooterButtons(buttons, $modal) {
		var that = this;
		$modal = this._verifyModalObject($modal);

		//Primary
		_setFooterButton(buttons.primary, this.Footer.Buttons.Primary, $modal);

		//Secondary
		_setFooterButton(buttons.secondary, this.Footer.Buttons.Secondary, $modal);

		//Cancel
		_setFooterButton(buttons.cancel, this.Footer.Buttons.Cancel, $modal);

		/**
		 * Set a given footer button
		 *
		 * @param {Object}  options The object with the given button options
		 * @param {String}  button  The given button object for simple modal (SimpleModal.Footer.Buttons.[Primary|Secondary|Cancel])
		 * @param {wrapper} $modal  The jQuery wrapper for the modal
		 *
		 * @returns {undefined}
		 */
		function _setFooterButton(options, button, $modal) {
			$modal = that._verifyModalObject($modal);

			//Show or hide the button
			if (options.show === true) {
				button.show($modal);
			} else {
				button.hide($modal);
			}

			// Disable button if option is specified
			if (options.disabled === true) {
				button.disable($modal);
			}

			//Set button text
			button.setText(options.text, $modal);

			//Set button class
			button.addClass(options.classes, $modal);

			//Set the button action
			button.updateAction(function() {
				options.action($modal);
			}, $modal);

			//Check for a key code to perform the action with
			var keyCode = parseInt(options.performActionOnKeyCode);
			if (keyCode > -1) {
				button.updatePerformActionOnKeyCode(keyCode, $modal);
			}
		}
	},

	/**
	 * Set the footer text if given
	 *
	 * @param {String}  text   The footer.text object to set footer text with
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_setFooterText(text, $modal) {
		if (text.html !== '') {
			this.Footer.Text.setHtml(text.html, $modal);
		}
		if (text.text !== '') {
			this.Footer.Text.setText(text.text, $modal);
		}
		if (text.show) {
			this.Footer.Text.show($modal);
		}
	},

	/**
	 * Set the background overlay z-index for a given modal
	 *
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_setModalBgZIndex($modal) {
		$modal = this._verifyModalObject($modal);
		var $background = $modal.find(this._selectors.background);
		this._setZIndex($background);
	},

	/**
	 * Set the top and left position of a modal
	 *
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	setPosition($modal) {
		$modal = this._verifyModalObject($modal);
		var $title = $modal.find(this._selectors.title),
			clientWidth = $modal[0].clientWidth,
			clientHeight = $modal[0].clientHeight,
			windowWidth = window.innerWidth,
			windowHeight = window.innerHeight;
		if (typeof $modal === 'undefined' || $modal.length === 0) {
			return;
		}

		// Provide max-width for title
		$title.css('max-width', clientWidth - 60);

		if (!$modal.hasClass('full-screen')) {
			if (!$modal.hasClass('full-width')) {
				if (this._openModals[this._openModals.length - 1].originalWidth > (windowWidth - 100)) {
					$modal.css({'width': (windowWidth - 100) + 'px'});
				} else {
					$modal.css({'width': 'auto'});
				}
			}

			$modal.css({
				'left': 'calc(50% - ' + Math.floor(($modal[0].clientWidth) / 2) + 'px)',
				'height': '' //Resets the height if set below during window resizing
			});

			if (!$modal.hasClass('full-height')) {
				//If the modal fits in the window, use the calc method
				if ((windowHeight * 0.4 - 50) > (clientHeight / 2)) {
					$modal.css({'top': 'calc(40% - ' + Math.floor(clientHeight / 2) + 'px)'});
				} else { //Otherwise set the top at 50px
					$modal.css({'top': '50px'});

					//If the height of the modal doesn't fit in the window, shrink the modal
					// eslint-disable-next-line max-depth
					if ($modal.height() > (windowHeight - 100)) {
						$modal.css({'height': windowHeight - 100 + 'px'});
					}
				}
			}
		}

		// Stacked modals
		if ($modal.hasClass('SimpleModal--stacked')) {
			var offset = 0;
			var heightOffset = 0;
			var $baseModal = this._openModals[0].$;
			if (!$baseModal.find(this._selectors.header).is(':visible')) {
				offset = -32;
			} else {
				heightOffset = -32;
			}
			var top = parseInt($baseModal.css('top')) + 36 + offset;
			$modal.css({ 'top': top + 'px' });

			//If the height of the modal doesn't fit in the window, shrink the modal
			if (!$modal.hasClass('full-screen') && $modal.height() > (windowHeight - 100 + heightOffset)) {
				$modal.css({ 'height': (windowHeight - 100 + heightOffset) + 'px' });
			}
		}
	},

	/**
	 * Set the modal loader z-index for a given modal
	 *
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_setLoaderZIndex($modal) {
		$modal = this._verifyModalObject($modal);
		var $Wrapper = $modal.find(this._selectors.loader);
		this._setZIndex($Wrapper);
	},

	/**
	 * Set the modal overlay z-index for a given modal
	 *
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_setModalZIndex($modal) {
		$modal = this._verifyModalObject($modal);
		var $Wrapper = $modal.find(this._selectors.wrapper);
		this._setZIndex($Wrapper);
	},

	/**
	 * Set the z-index for a given selector
	 *
	 * @param {String} selector The selector to update the z-index for
	 *
	 * @returns {undefined}
	 */
	_setZIndex(selector) {
		var zIndex = getMaxZIndex();
		$(selector).css('zIndex', parseInt(zIndex) + 10);
	},

	/**
	 * Setup the event listeners for simple modal
	 *
	 * @returns {undefined}
	 */
	_setupListeners() {
		$(window).resize(() => {
			this._openModals.forEach((openModal) => {
				var $modal = openModal.$;
				this.setPosition($modal);
				this._setBodyAndLoaderHeight(this._getHeaderAndFooterHeight($modal), $modal);
			});
		});
		this._watchForClose();
	},

	/**
	 * Show the content for the modal
	 *
	 * @param {Object}  options The function to call on modal load
	 * @param {wrapper} $modal  The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_showContent(options, $modal) {
		var that = this,
			$container = $(this._selectors.container),
			$body = $modal.find(this._selectors.body),
			$loader = $(this._selectors.loader),
			$footerWrapper = $(this._selectors.footerWrapper);

		if ($modal.hasClass('SimpleModal--stacked')) {
			$modal.find(that._selectors.wrapper).velocity(
				"slideDown",
				{
					duration: 250,
					begin: function() {
						$modal.addClass('no-overflow');
					},
					complete: function() {
						$modal.removeClass('no-overflow');
					}
				}
			);
		}
		show();

		/**
		 * Show
		 *
		 * @returns {undefined}
		 */
		function show() {
			if (options.animation) {
				$modal.find(that._selectors.wrapper).addClass('show');
			} else {
				$modal.find(that._selectors.wrapper).css('opacity', '1');
			}
		}

		// let the browser catch up
		setTimeout(function() {
			executeFunction(options.callbacks.open.after);
		}, 0);

		//If the modal is a delete modal, run the delete modal setup.
		if (options.deleteModal.active && options.deleteModal.selector !== '') {
			this._deleteModalSetup(options.deleteModal.selector, $modal);
		}

		//If the modal should prevent form submissions, setup a listener
		if (options.preventSubmit) {
			$modal.find('form').on('submit', function(event) {
				event.preventDefault();
			});
		}

		// For DOM elements that need to be initialized.
		$modal.initializeElements();

		$container.removeClass('updating');

		$body.css({'visibility': 'visible'});
		$body.velocity({opacity: 1}, {
			complete() {
				$container.removeClass('updating');
			},
			duration: 350});
		$footerWrapper.velocity({opacity: 1}, {duration: 350});

		$loader.removeClass('show');
		this._openModals[this._openModals.length - 1].originalWidth = $modal.width();
		this.setPosition($modal);
		$(document).trigger('simplemodal:open:after');
	},

	/**
	 * Verify that the $modal object passed is an object and not undefined. If undefined, it grabs the top-most modal object
	 *
	 * @param {wrapper} $modal The jQuery wrapper for the modal
	 *
	 * @returns {wrapper}
	 */
	_verifyModalObject($modal) {
		if (typeof $modal === 'undefined') {
			$modal = $(this.getTopModalSelector());
		}
		return $modal;
	},

	/**
	 * Wait for the footer to load and then show the content
	 *
	 * @param {object}  options The options
	 * @param {wrapper} $modal  The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 * @private
	 */
	_waitForFooterToLoad(options, $modal) {
		var that = this;
		if (options.footer.custom.url !== '' && $modal.find(that._selectors.footerWrapper).html().length === 0) {
			var interval = setInterval(function () {
				if ($modal.find(that._selectors.footerWrapper).html().length > 0) {
					clearInterval(interval);
					that._showContent(options, $modal);
				}
			}, 1000);
		} else {
			that._showContent(options, $modal);
		}
	},

	/**
	 * Wait for resources to load and then check the footer
	 *
	 * @param {object}  options The options
	 * @param {wrapper} $modal  The jQuery wrapper for the modal
	 *
	 * @returns {undefined}
	 */
	_waitForResourcesToLoad(options, $modal) {
		$modal = this._verifyModalObject($modal);
		var $resources = $modal.find(this._selectors.body + ' img[src], ' + this._selectors.body + ' link[type="text/css"]');
		var that = this,
			length = $resources.length,
			counter = 0;
		if (length > 0) {
			$resources.each(function() {
				// stuff each image/css link into an image tag
				var tmp = document.createElement('img');
				if (this.src) {
					tmp.src = this.src;
				}
				if (this.href) {
					tmp.src = this.href;
				}
				// after an image has loaded
				// or a css document has errored (tried to stuff in img)
				tmp.onload = tmp.onerror = function() {
					++counter;
					if (counter === length) {
						that._waitForFooterToLoad(options, $modal);
					}
				};
			});
		} else {
			that._waitForFooterToLoad(options, $modal);
		}
	},

	/**
	 * Watch for modal close (AKA add listeners)
	 *
	 * @returns {undefined}
	 */
	_watchForClose() {
		var that = this;

		$('body').on('click', this._selectors.close, function() {
			that.closeModal();
		});

		$(document).on('keydown', function(e) {
			let options = that._getTopModalOptions();
			if (options.closeOnEscape && (e.which === 27 || e.keyCode === 27)) {
				that.closeModal();
			}
		});
	},

	/**
	 * Update the toplevel model with new content
	 *
	 * @param {Object} options A JavaScript Object with options for the simple modal
	 *
	 * @returns {undefined}
	 */
	updateModal(options) {
		options = this._mergeOptions(options);

		//Update the options in the "updated" modal
		this._openModals[this._openModals.length - 1].options = options;

		var that = this,
			$container = $(this._selectors.container),
			$body = $(this._selectors.body),
			$footerWrapper = $(this._selectors.footerWrapper),
			$loader = $(this._selectors.loader),
			$modal = $(this.getTopModalSelector());

		if (typeof window.event !== 'undefined' && window.event.altKey) { $modal.addClass('panda');	} // Shhhh....

		//Run the before open callback
		$(document).trigger('simplemodal:open:before');
		var openBeforeReturnVal = executeFunction(options.callbacks.open.before);

		//If the result is false, stop opening the modal (strictly boolean false because it may return undefined)
		if (openBeforeReturnVal === false) {
			return false;
		}

		$body.velocity({opacity: 0}, {
			begin() {
				$container.addClass('updating');
				$loader.addClass('show');
			},
			complete() {
				//Get the data from the requested url
				if (options.url) {
					$.get(options.url, function(html) {
						that._loadHtml($modal, html, options);
					});
				} else {
					that._loadHtml($modal, options.html, options);
				}
			},
			duration: 350
		});

		$footerWrapper.velocity({opacity: 0}, {duration: 350});
	}
};


import Body from 'SimpleModal.mod/Body';
SimpleModal.Body = Body;
export {Body};

import Footer from 'SimpleModal.mod/Footer';
SimpleModal.Footer = Footer;
export {Footer};

import Header from 'SimpleModal.mod/Header';
SimpleModal.Header = Header;
export {Header};


SimpleModal.init();


export default SimpleModal;
