import {
	noop,
} from 'lodash';
import {
	useEffect,
	useState,
} from 'react';

import {
	TextField as OldTextField,
} from 'form-fields.react';

import { TextField } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import {
	getRoutingNumberNote,
} from './routing-number-input-field.domain';
import {
	useRoutingNumberValidator,
} from './routing-number-input-field.hooks';

export interface RoutingNumberInputFieldProps {
	isDisabled: boolean;
	onChange: (routingNumber: string, error: string) => void;
}

export function RoutingNumberInputField(props: RoutingNumberInputFieldProps): JSX.Element {
	const {
		isDisabled,
		onChange = noop,
	} = props;

	const [routingNumber, setRoutingNumber] = useState('');
	const [canShowError, setCanShowError] = useState(false);

	const {
		data,
		error,
		status,
		validateRoutingNumber,
	} = useRoutingNumberValidator();

	useEffect(() => {
		onChange(routingNumber, error);
	}, [routingNumber, error]);

	const routingNumberStateIsComplete = status === 'error' || status === 'success';
	const shouldShowRoutingNumberError = routingNumberStateIsComplete && canShowError && !!error;

	return ifFeature('encore', 
		<TextField
			disabled={ isDisabled }
			id="collections-and-refunds-routing-number-input-field"
			label={ $.__('Routing Number') }
			note={ shouldShowRoutingNumberError && error ? error : getRoutingNumberNote(status, data.bankName) }
			onBlur={ (e): void => {
				validateRoutingNumber(e.target.value);
				setCanShowError(true);
			} }
			onChange={ (e): void => {
				setRoutingNumber(e.target.value);
				validateRoutingNumber(e.target.value, 1000);
				setCanShowError(true);
			} }
			required={ true }
			status={ shouldShowRoutingNumberError ? 'error' : undefined}
			value={ routingNumber }
			width={ 8 }
		/>,
		<OldTextField
			autoComplete="disable-auto-complete"
			disabled={ isDisabled }
			error={ shouldShowRoutingNumberError && error }
			hasError={ shouldShowRoutingNumberError }
			id="collections-and-refunds-routing-number-input-field"
			label={ $.__('Routing Number') }
			note={ getRoutingNumberNote(status, data.bankName) }
			onBlur={ (e): void => {
				validateRoutingNumber(e.target.value);
				setCanShowError(true);
			} }
			onChange={ (e): void => {
				setRoutingNumber(e.target.value);
				validateRoutingNumber(e.target.value, 1000);
				setCanShowError(true);
			} }
			required={ true }
			value={ routingNumber }
			width={ 8 }
		/>
	);
}
