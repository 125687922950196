import { ifFeature } from '@bamboohr/utils/lib/feature';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { area, line } from 'd3-shape';
import { timeDay } from 'd3-time';
import { defaultsDeep } from 'lodash';

import BaseChart from '../base-chart';
import './detailed-multi-line-area-chart.styl';

const CLASS_NAME = 'DetailedMultiLineAreaChart';
const DEFAULT_SETTINGS = {
	chartClass: '',
	container: {
		height: 433,
		width: 560,
		margin: {
			top: 27,
			right: 20,
			bottom: 10,
			left: 20,
		},
	},
	xAxisTickCount: timeDay.every(1),
	xAxisTickFormat: 'MMM YYYY',
	yAxisLabel: null,
	yAxisLabelXOffset: 0,
	yAxisMin: -100,
	yAxisMax: 100,
	pathStrokeWidth: 3,
};

export default class DetailedMultiLineAreaChart extends BaseChart {
	constructor(selector, settings = DEFAULT_SETTINGS, onMouseOver, onFocus) {
		super();

		this._selector = selector;
		this._CLASS_NAME = CLASS_NAME;
		this._settings = defaultsDeep({}, settings, DEFAULT_SETTINGS);
		this._onMouseOver = onMouseOver;
		this._onFocus = onFocus;

		this._validateRequirements();
		this._createRoot();
	}

	_minYDrawnValue = 0;

	_destroyPopover;

	_data;

	draw(data) {
		this._data = data;
		const { areas, xAxisExtraInfo } = data;

		this._areas = areas;
		this._xAxisExtraInfo = xAxisExtraInfo;

		this._allXPoints = areas
			.map(area => area.points.map(point => point.x))
			.reduce((acc, pointX) => acc.concat(pointX))
			.slice(0, this._areas[0].points.length).map(date => this._resetTimeValues(date));

		const { yAxisMin, yAxisMax } = this._settings;

		this._minYDrawnValue = yAxisMin;

		this._xScale = this._getXScale(this._allXPoints);
		this._yScale = this._getYScale(yAxisMin, yAxisMax);

		this._svg
			.style('overflow', 'visible');

		this._drawYAxis();
		this._drawXAxis();
		this._drawAreas();
	}

	_getXScale(data) {
		const { container: { margin } } = this._settings;
		return scaleLinear()
			.domain([0, data.length - 1])
			.range([margin.left, this._getContainerWidth() - margin.right]);
	}

	_getYScale(minY, maxY) {
		const { container: { height, margin } } = this._settings;

		return scaleLinear()
			.domain([minY, maxY]).nice()
			.range([height - margin.bottom, margin.top]);
	}

	_drawAreas() {
		this._drawAreaGroups();
		this._drawDivider();
		this._drawLineGroups();
		this._drawMouseOverAreas();
	}

	_drawDivider() {
		const dividerUpdate = this._svg
			.selectAll(this._getClassList('dividerLine'))
			.data([this._allXPoints]);

		const dividerEnter = dividerUpdate
			.enter()
			.append('line')
			.attr('class', this._buildClassList('dividerLine'))
			.attr('y1', this._yScale(0))
			.attr('y2', this._yScale(0))
			.style('stroke', 'white')
			.style('stroke-width', 3);

		const dividerMerge = dividerUpdate.merge(dividerEnter);

		dividerMerge
			.attr('x1', d => this._xScale(0))
			.attr('x2', d => this._xScale(d.length - 1));

		dividerUpdate
			.exit()
			.remove();
	}

	_drawXAxis() {
		const { container: { height }, xAxisTickFormat } = this._settings;
		const svg = this._svg;

		const resetXValues = this._allXPoints;

		this._xScale.domain([0, this._allXPoints.length - 1]);

		const xAxis = axisBottom(this._xScale)
			.tickValues([0, 1, 2, 3])
			.tickFormat(d => moment(resetXValues[d]).format(xAxisTickFormat));

		// If axis currently exists just update it, otherwise create it
		const isAxisEmpty = svg
			.select(this._getClassList('__xAxis'))
			.empty();

		if (isAxisEmpty) {
			svg.append('g')
				.attr('class', this._buildClassList('__xAxis'))
				.attr('transform', `translate(0, ${ height })`)
				.call(xAxis);
		} else {
			svg.select(this._getClassList('__xAxis'))
				.call(xAxis);
		}

		svg.select(this._getClassList('__xAxis'))
			.attr('font-family', `'Lato', sans-serif`);

		svg.select(this._getClassList('__xAxis'))
			.select('.domain')
			.remove();

		svg.select(this._getClassList('__xAxis'))
			.selectAll('.tick line')
			.remove();

		svg.select(this._getClassList('__xAxis'))
			.selectAll('.tick text')
			.attr('class', 'yAxisLabel__label');
	}

	_drawYAxis() {
		const { container: { margin, width }, yAxisLabel, yAxisLabelXOffset } = this._settings;
		const svg = this._svg;

		const yAxis = axisLeft(this._yScale);

		// If axis currently exists just update it, otherwise create it
		const isAxisEmpty = svg
			.select(this._getClassList('__yAxis'))
			.empty();

		if (isAxisEmpty) {
			svg.append('g')
				.attr('class', this._buildClassList('__yAxis'))
				.call(yAxis);
		} else {
			svg.select(this._getClassList('__yAxis'))
				.call(yAxis);
		}

		svg.select(this._getClassList('__yAxis'))
			.attr('font-family', `'Lato', sans-serif`);

		if (yAxisLabel) {
			svg.select(this._getClassList('__yAxis'))
				.append('text')
				.text(yAxisLabel)
				.attr('class', 'yAxisLabel')
				.attr('x', yAxisLabelXOffset);
		}

		svg.select(this._getClassList('__yAxis'))
			.selectAll('line')
			.attr('x2', width)
			.attr('stroke-dasharray', '3 5')
			.style('stroke', '#adadad');

		svg.select(this._getClassList('__yAxis'))
			.select('.domain')
			.remove();

		svg.select(this._getClassList('__yAxis'))
			.selectAll('.tick text')
			.attr('class', 'yAxisLabel');

		const allYTicks = svg.select(this._getClassList('__yAxis'))
			.selectAll('.tick');

		const zeroTickCSSIndex = (allYTicks.size() + 1) / 2;

		const zeroTick = svg.select(this._getClassList('__yAxis'))
			.select(`.tick:nth-child(${ zeroTickCSSIndex })`);

		zeroTick.select('line')
			.remove();

		zeroTick.append('line')
			.attr('x2', margin.left)
			.style('stroke', '#e0e0e0');

		zeroTick.append('line')
			.attr('x1', width - margin.right)
			.attr('x2', width)
			.style('stroke', '#e0e0e0');
	}

	_drawAreaGroups() {
		const svg = this._svg;

		const areaGroupUpdate = svg
			.selectAll(this._getClassList('__areaGroup'))
			.style('display', 'initial')
			.data(this._areas);

		const areaGroupEnter = areaGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__areaGroup') }`)
			.attr('id', (d, i) => `area-${ i }`);

		const areaGroupMerge = areaGroupUpdate.merge(areaGroupEnter);

		const areaElem = area()
			.x((d, i) => this._xScale(i))
			.y0(d => this._yScale(0))
			.y1(d => this._yScale(d.y));

		areaGroupEnter
			.append('path')
			.attr('class', `AreaChart--themeFill ${ this._buildClassList('__area') }`)
			.style('fill', (d, i) => this._getAreaColor(i));

		areaGroupMerge
			.select(this._getClassList('__area'))
			.attr('d', d => areaElem(d.points));

		areaGroupUpdate
			.exit()
			.remove();
	}

	_getAreaColor(areaIndex) {
		const { colors } = this._settings;
		switch (areaIndex) {
			case 0:
				return colors.likes.promoters;
			case 1:
				return colors.likes.neutrals;
			case 2:
				return colors.likes.detractors;
			case 3:
				return colors.dislikes.detractors;
			case 4:
				return colors.dislikes.neutrals;
			case 5:
				return colors.dislikes.promoters;
			default:
				return '';
		}
	}

	_drawMouseOverAreas() {
		const svg = this._svg;
		const textColor = ifFeature('encore', getComputedStyle(document.body)?.getPropertyValue('--gray6'), '#686868');
		const areaGroupUpdate = svg
			.selectAll(this._getClassList('__hoverAreaGroup'))
			.style('display', 'initial')
			.data([this._areas[0], this._areas[3]]);
		const areaGroupEnter = areaGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__hoverAreaGroup') }`)
			.attr('id', (d, i) => {
				return `hover${ i }`;
			});

		const areaElem = area()
			.x(d => d.x)
			.y0(d => this._yScale(0))
			.y1(d => d.y);

		const pathsUpdate = areaGroupEnter
			.selectAll(this._getClassList('__hoverArea'))
			.data(d => d.points);

		const pathsEnter = pathsUpdate
			.enter()
			.append('path')
			.classed(this._buildClassList('__hoverArea'), true)
			.style('fill', 'transparent');

		const pathsMerge = pathsUpdate.merge(pathsEnter);

		pathsMerge
			.attr('d', (d, i) => {
				const currentArea = this._areas.find(a => a.points.includes(d));
				const isLikesArea = this._areas.indexOf(currentArea) === 0;
				const xPoints = [];
				const scaledX0 = currentArea.points[i - 1] ?
					this._xScale(i - 1) :
					null;
				const scaledX1 = this._xScale(i);
				const scaledX2 = currentArea.points[i + 1] ?
					this._xScale(i + 1) :
					null;
				if (scaledX0 !== null) {
					xPoints.push((scaledX1 + scaledX0) / 2);
				}
				xPoints.push(scaledX1);
				if (scaledX2 !== null) {
					xPoints.push((scaledX2 + scaledX1) / 2);
				}
				const y1Points = [];
				const scaledY0 = currentArea.points[i - 1] ?
					this._yScale(currentArea.points[i - 1].y) :
					null;
				const scaledY1 = this._yScale(d.y);
				const scaledY2 = currentArea.points[i + 1] ?
					this._yScale(currentArea.points[i + 1].y) :
					null;
				if (scaledY0 !== null) {
					if (scaledY1 > scaledY0) {
						y1Points.push((scaledY1 + scaledY0) / 2);
					} else {
						y1Points.push((scaledY0 + scaledY1) / 2);
					}
				}
				y1Points.push(scaledY1);
				if (scaledY2 !== null) {
					if (scaledY2 > scaledY1) {
						y1Points.push((scaledY2 + scaledY1) / 2);
					} else {
						y1Points.push((scaledY1 + scaledY2) / 2);
					}
				}
				return areaElem(xPoints.map((x, i) => ({ x, y: y1Points[i] })));
			})
			.on('mouseover', (event) => {
				const currentArea = this._areas.find(a => a.points.includes(event));
				const isLikesArea = this._areas.indexOf(currentArea) === 0;
				const pointIndex = currentArea.points.indexOf(event);
				const areaIndex = this._areas.indexOf(currentArea);
				const secondPointY = Math.abs(this._areas[areaIndex + 1].points[pointIndex].y);
				const thirdPointY = Math.abs(this._areas[areaIndex + 2].points[pointIndex].y);
				const countTotal = Math.abs(event.y);

				this._addHoverGroup(event, currentArea);
				const popoverTarget = this._svg.select('#primaryHoverPoint');
				this._destroyPopover = this._onMouseOver({
					detail: {
						data: {
							count: countTotal,
							isPositive: isLikesArea,
							promoters: Math.abs(isLikesArea ? countTotal - secondPointY : thirdPointY),
							neutrals: Math.abs(secondPointY - thirdPointY),
							detractors: Math.abs(isLikesArea ? thirdPointY : countTotal - secondPointY),
						},
					},
					target: popoverTarget.node(),
				});

				svg.select(`${ this._getClassList('__xAxis') } .tick:nth-child(${ pointIndex + 1 }) text`)
					.classed('focusedAreaLabel', true)
					.style('font-weight', 'bold')
					.style('fill', '#555');
			})
			.on('mouseleave', (event) => {
				this._removeHoverGroup(event);
				this._destroyPopover();
				svg.select('.focusedAreaLabel')
					.classed('focusedAreaLabel', false)
					.style('font-weight', 'normal')
					.style('fill', textColor);
			});

		pathsUpdate
			.exit()
			.remove();

		areaGroupUpdate
			.exit()
			.remove();
	}

	_addHoverGroup(point, currentArea) {
		const svg = this._svg;
		const pointIndex = currentArea.points.indexOf(point);
		const areaIndex = this._areas.indexOf(currentArea);
		const secondPoint = this._areas[areaIndex + 1].points[pointIndex];
		const thirdPoint = this._areas[areaIndex + 2].points[pointIndex];
		const isInTopAreas = this._areas.indexOf(currentArea) === 0;
		const { colors } = this._settings;

		const lineElem = line()
			.x(d => this._xScale(pointIndex))
			.y(d => this._yScale(d.y));

		const hoverGroup = svg.append('g')
			.attr('id', 'hoverGroup')
			.style('pointer-events', 'none');
		hoverGroup
			.append('path')
			.style('fill', 'none')
			.style('stroke', 'black')
			.style('stroke-width', '2px')
			.style('opacity', '0.15')
			.attr('d', lineElem([{ x: point.x, y: 0 }, { x: point.x, y: point.y }]));
		hoverGroup
			.append('circle')
			.attr('id', 'primaryHoverPoint')
			.attr('r', 6)
			.attr('cx', this._xScale(pointIndex))
			.attr('cy', this._yScale(point.y))
			.style('fill', isInTopAreas ? colors.likes.hoverPoint : colors.dislikes.hoverPoint);
		hoverGroup
			.append('circle')
			.attr('r', 3)
			.attr('cx', this._xScale(pointIndex))
			.attr('cy', this._yScale(point.y))
			.style('fill', '#fff');
		hoverGroup
			.append('circle')
			.attr('r', 3)
			.attr('cx', this._xScale(pointIndex))
			.attr('cy', this._yScale(secondPoint.y))
			.style('fill', isInTopAreas ? colors.likes.hoverPoint : colors.dislikes.hoverPoint);
		hoverGroup
			.append('circle')
			.attr('r', 3)
			.attr('cx', this._xScale(pointIndex))
			.attr('cy', this._yScale(thirdPoint.y))
			.style('fill', isInTopAreas ? colors.likes.hoverPoint : colors.dislikes.hoverPoint);
	}

	_removeHoverGroup() {
		this._svg
			.select(`#hoverGroup`)
			.remove();
	}

	_drawLineGroups() {
		const svg = this._svg;
		const settings = this._settings;

		const lineGroupUpdate = svg
			.selectAll(this._getClassList('__lineGroup'))
			.style('display', 'initial')
			.data([
				this._areas[1],
				this._areas[2],
				this._areas[4],
				this._areas[5],
				this._areas[0],
				this._areas[3],
			]);

		const lineGroupEnter = lineGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__lineGroup') }`)
			.attr('id', (d, i) => `line-${ i }`);

		const lineGroupMerge = lineGroupUpdate.merge(lineGroupEnter);

		const lineElem = line()
			.x((d, i) => this._xScale(i))
			.y(d => this._yScale(d.y));

		lineGroupEnter
			.append('path')
			.attr('class', this._buildClassList('__line'))
			.style('fill', 'none')
			.style('stroke', (d, i) => this._getLineColors(i))
			.style('stroke-width', (d, i) => {
				if (i === 4 || i === 5) {
					return `${ settings.pathStrokeWidth }px`;
				}
				return '1px';
			});

		this._addLineGroupCircles();

		lineGroupMerge
			.select(this._getClassList('__line'))
			.attr('d', d => lineElem(d.points));

		lineGroupUpdate
			.exit()
			.remove();
	}

	_addLineGroupCircles() {
		const svg = this._svg;
		const { colors } = this._settings;

		const topArea = this._areas[0];
		const topAreaFirstPoint = topArea.points[0];
		const topAreaLastPoint = topArea.points.find((point, index, points) => index === points.length - 1);

		const bottomArea = this._areas[3];
		const bottomAreaFirstPoint = bottomArea.points[0];
		const bottomAreaLastPoint = bottomArea.points.find((point, index, points) => index === points.length - 1);

		const topLine = svg
			.select('#line-0');

		const topLineCirclesUpdate = topLine
			.selectAll('circle')
			.data([topAreaFirstPoint, topAreaLastPoint]);

		const topLineCirclesEnter = topLineCirclesUpdate
			.enter()
			.append('circle')
			.attr('r', 4)
			.style('fill', colors.likes.line);

		const topLineCirclesMerge = topLineCirclesUpdate.merge(topLineCirclesEnter);

		topLineCirclesMerge
			.attr('cx', (d, i) => this._xScale(i === 0 ? i : topArea.points.length - 1))
			.attr('cy', d => this._yScale(d.y));

		topLineCirclesUpdate
			.exit()
			.remove();

		const bottomLine = svg
			.select('#line-3');

		const bottomLineCirclesUpdate = bottomLine
			.selectAll('circle')
			.data([bottomAreaFirstPoint, bottomAreaLastPoint]);

		const bottomLineCirclesEnter = bottomLineCirclesUpdate
			.enter()
			.append('circle')
			.attr('r', 4)
			.style('fill', colors.dislikes.line);

		const bottomLineCirclesMerge = bottomLineCirclesUpdate.merge(bottomLineCirclesEnter);

		bottomLineCirclesMerge
			.attr('cx', (d, i) => this._xScale(i === 0 ? i : bottomArea.points.length - 1))
			.attr('cy', d => this._yScale(d.y));

		bottomLineCirclesUpdate
			.exit()
			.remove();
	}

	_getLineColors(lineIndex) {
		const { colors } = this._settings;
		switch (lineIndex) {
			case 4:
				return colors.likes.line;
			case 5:
				return colors.dislikes.line;
			default:
				return 'rgba(255, 255, 255, 0.4)';
		}
	}

	_resetTimeValues(date) {
		const dateObj = new Date(date);
		return dateObj.setHours(0, 0, 0, 0);
	}

	resize() {
		select(this._selector).select('div').remove();
		this._createRoot();
		this.draw(this._data);
	}
}
