import moment from 'moment.lib';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import Ajax from '@utils/ajax';
import { getFormData } from 'BambooHR.util';
import microTemplate from 'MicroTemplate.mod';
import template from './Rehire/template.micro.html';


function open(employeeId) {
	const rehireDataPromise = $.getJSON(`/employees/ajax/rehire/${  employeeId}`);

	rehireDataPromise.then(function(model) {
		model.employeeId = employeeId;
		model.terminationDate = moment(model.terminationDate);

		openModal(model);
	});
}

function openModal(model) {
	const { employeeName, employeeId } = model;
	const content = microTemplate(template, {
		model,
		generateOptions,
		getCanAddAttributes,
		getSpecialClasses,
	});

	function updateTaxTypeOptionsByEmployeeId() {
		updateTaxTypeOptions(employeeId)
	}

	window.BambooHR.Modal.setState({
		isOpen: true,
		content: (
			<div dangerouslySetInnerHTML={ { __html: content } } />
		),
		title: $.__('Rehire Employee'),
		onOpen() {
			const $form = $('.js-rehire-modal-form');
			$form.find('.js-effectiveDate').attr('data-mindate', model.terminationDate.toISOString());
			$form.bhrValidate();
			window.BambooHR.VirtualPageView.modal('rehireEmployee');
			const employmentStatusSelect = document.querySelector('.js-employmentStatusSelect');
			if (employmentStatusSelect) {
				employmentStatusSelect.addEventListener('change', updateTaxTypeOptionsByEmployeeId);
			}
		},
		onClose() {
			const employmentStatusSelect = document.querySelector('.js-employmentStatusSelect');
			if (employmentStatusSelect) {
				employmentStatusSelect.removeEventListener('change', updateTaxTypeOptionsByEmployeeId);
			}
		},
		primaryActionText: $.__('Save'),
		primaryAction() {
			const $form = $('.js-rehire-modal-form');

			if (!$form.valid()) {
				return;
			}

			const _data = {
				...getFormData('.js-rehire-modal-form'),
				employeeId
			};

			const formData = Ajax.prepareData(
				_data,
				Ajax.prepareDataTypes.FORM_DATA
			);

			Ajax.post('/employees/ajax/rehire', formData).then(({ data }) => {
				if (data.status !== 'OK') {
					let error_message = $.__('Whoops... There was a problem rehiring %1%2%3!', '<strong>', model.employeeName, '</strong>');
					if (data.syncErrors !== '') {
						error_message = data.syncErrors;
					}
					window.setMessage(error_message, 'error');
					return;
				}
				window.BambooHR.Modal.setState({ isOpen: false });
				window.location.reload();
				window.setMessage($.__('Success!'), 'success');
			});
		},
		icon: ifFeature('encore', "id-card-solid", 'fab-badge-id-32x28'),
		headerType: 'text',
		headline: employeeName
	});
}

function getCanAddAttributes(field) {
	if (field.meta && field.meta.insertable) {
		return 'can-add=true';
	}
	return null;
}

function getSpecialClasses(field, defaultClasses) {
	defaultClasses = defaultClasses || [];

	if (field.meta && field.meta.insertable) {
		defaultClasses.push('insertable-list');
	}
	return defaultClasses.join(' ');
}

function generateOptions(field, required) {
	let optionsTemplate;

	if (!field.options) {
		return;
	}

	optionsTemplate = `
		!* field.options.forEach(function(option) { *!
			<ba-option value="!*= option.value *!" !*= (option.value === field.value) ? 'selected' : '' *!>
				!*= option.content *!
			</ba-option>
		!* }); *!
	`;

	return microTemplate(optionsTemplate, {field, required});
}

function updateTaxTypeOptions(employeeId) {
	const taxTypeValue = document.querySelector('[name="employmentStatusId"]').value;

	if (!taxTypeValue) {
		return
	}

	Ajax.get(`/ajax/employee_status_history_popup/get_allowable_employee_tax_types/0/${ taxTypeValue }?employeeId=${employeeId}`).then((result) => {
		const $targetSelect = $('[name="taxEmployeeTypeId"]');
		const currentSelectionValue = $targetSelect.find('select').length
			? parseInt($targetSelect.find('select').val())
			: parseInt($('select[name="taxEmployeeTypeId"]').val());

		$targetSelect.empty();

		result.data.options.forEach((option) => {
			const {
				optionValue,
				optionText,
				selected,
			} = option;

			let selectedOption = selected;

			if (currentSelectionValue === optionValue) {
				selectedOption = true;
			} else {
				selectedOption = false;
			}

			$targetSelect.append(`<ba-option value="${ optionValue }" ${selectedOption ? 'selected' : ''}>${ optionText }</ba-option>`);
		});
	});
}

export default {
	open
};
