import { FunctionComponent } from 'react';
import classnames from 'classnames';

import { ColoredIcon } from 'colored-icon';

import './static.styl';
import { InPageMessaging, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const StaticTemplate: FunctionComponent<DynamicForm.StaticTemplateProps> = ({
	props,
	settings,
	context,
}) => {
	const createTemplate = (): React.ReactElement => {
		const { template, value } = settings;
		const { controls: { FieldSet } } = context;
		const { className } = props;
		if (template === 'dash') {
			const classes = classnames('formDash', 'DynamicForm--static__dash', className);
			return (
				<span { ...props } className={ classes }>
					–
				</span>
			);
		} if (template === 'payrollSectionHeader') {
			const classes = classnames('payroll-section-header', 'DynamicForm--static__payrollInformation', className);
			return ifFeature(
				'encore',
				<LayoutBox marginTop={4}>
					<InPageMessaging
						body={$.__("Anything not completed here will be sent to your company's payroll admins to fill out.")}
						header={$.__("Not sure about some of the payroll info below? That's okay, just fill out as much as you know.")}
						icon='circle-question-solid'
						type='info'
					/>
				</LayoutBox>,
				<FieldSet context={ context } props={ { ...props, className: classes } } settings={ settings }>
					<ColoredIcon classes="DynamicForm--static__payrollInformationIcon" fabricColorName="info" iconName="fab-question-16x16" />
					<span className="DynamicForm--static__payrollInformationText">Not sure about some of the payroll info below? That’s okay, just fill out as much as you know.</span> Anything not completed here will be sent to your company's payroll admins to fill out.
				</FieldSet>
			);
		}
		return null;
	};

	return createTemplate();
};

export default StaticTemplate;
