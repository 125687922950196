// @startCleanup encore
import { createStyles, makeStyles } from '@bamboohr/fabric';

export const useStyles = makeStyles(({ spacing }) => {
	return createStyles({
		liItem: {
			listStyle: 'disc',
			marginLeft: spacing(2),
		},
		bottomPadding: {
			marginBottom: spacing(3),
		},
		dropDown: {
			height: '100%',
		},
		bold: {
			fontWeight: 'bold',
		},
	});
});
// @endCleanup encore
