import getCompanyInfo from 'getCompanyInfo.service';
import { useEffect, useState } from 'react';


interface UseCompanyName {
	companyName: string;
	loading: boolean;
}

export function useCompanyName(): UseCompanyName {
	const [loading, setLoading] = useState(true);
	const [companyName, setCompanyName] = useState<string>();

	useEffect(() => {
		setLoading(true);

		getCompanyInfo().then(info => {
			setCompanyName((info?.name || '') as string);
			setLoading(false);
		});
	}, []);

	return {
		companyName,
		loading,
	};
}
