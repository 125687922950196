import moment from 'moment.lib';
import {chunk} from 'lodash';

export function prepareModifierDataForDom(frequency, modifier) {
	if (frequency === 'daily') {
		return [];
	}

	if (frequency === 'bi-weekly') {
		return moment(modifier, 'YYYY-MM-DD').format();
	}

	if (frequency === 'semi-monthly') {
		return modifier.split(' ');
	}

	return modifier
		.split(' ')
		.map(day => day.split('/').reverse())
		.reduce((a, b) => a.concat(b), []);
}

export function createModifierFromDomData(frequency, data) {
	if (frequency === 'daily') {
		return null;
	}

	if (frequency === 'bi-weekly') {
		return moment(data, moment.defaultFormat).format('YYYY-MM-DD');
	}

	if (frequency === 'semi-monthly') {
		return data.join(' ');
	}

	if (data.length > 1) {
		return chunk(data, 2)
			.map(set => set.reverse().join('/'))
			.join(' ');
	}
	return data[0];
}
