import { useState } from 'react';
import { DatePicker } from '@bamboohr/fabric';
import { isMatch } from '@bamboohr/utils/lib/datetime';
import { getValidationContext } from '../../context';
import { getFormatTypeFromGlobalDateMask } from 'date-picker.util';

export const Date = (props) => {
	const { errorState, label, name, data, validationRegistration } = props;
	const { specialErrors } = getValidationContext();

	if (!data) {
		return null;
	}

	const [selectedDate, setSelectedDate] = useState(data.value);

	return (
		<div className={`fab-FormColumn CandidateField CandidateField--${name}`}>
			<div className={`fab-InputWrapper CandidateField__inputWrapper CandidateField__inputWrapper--${name}`}>
				<DatePicker
					label={label}
					enableFlip={true}
					disabled={Boolean(data.isDisabled)}
					id='dateAvailable'
					inputProps={{ name: 'dateAvailable' }}
					onChange={({ value }) => {
						setSelectedDate(value);
					}}
					required={data.isRequired}
					status={specialErrors[name] ? 'error' : 'default'}
					value={selectedDate}
				/>
			</div>
		</div>
	);
};

export function checkDateValidity(name, isRequired, value, setSpecialErrors) {
	if (value) {
		if (isMatch(value, getFormatTypeFromGlobalDateMask())) {
			setSpecialErrors(name, null);
			return true;
		}
		setSpecialErrors(name, { rule: 'date' });
		return false;
	}

	if (isRequired) {
		setSpecialErrors(name, { rule: 'required' });
		return false;
	}

	return true;
}
