import { PureComponent } from 'react';

export default class ItemErrorBoundary extends PureComponent {
	state = {
		error: null,
		info: null,
	};

	componentDidCatch(error, info) {
		this.setState({
			error: error,
			info: info,
		});
		console.error(error);
	}

	render() {
		if (!this.state.error) {
			return this.props.children;
		}
		return null;
	}
}
