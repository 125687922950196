import { useEffect, useState } from 'react';
import { getMismatchData } from '../services';
import { StatusMismatchData, StatusMismatchEmployee, StatusMismatchEmploymentStatusFields } from '../types';

export function useMismatchData(employeeId: string, open: boolean): StatusMismatchData & { loading: boolean } {
	const [loading, setLoading] = useState(true);
	const [employee, setEmployee] = useState<StatusMismatchEmployee>();
	const [fields, setFields] = useState<StatusMismatchEmploymentStatusFields>();
	const [hasGusto, setHasGusto] = useState<boolean>(false);
	const [showFinalPayDate, setShowFinalPayDate] = useState<boolean>(false);

	useEffect(() => {
		if (open) {
			setLoading(true);

			getMismatchData(employeeId).then(({ hasGusto, employee, fields, showFinalPayDate }) => {
				setEmployee(employee);
				setFields(fields);
				setHasGusto(hasGusto);
				setShowFinalPayDate(showFinalPayDate);

				setLoading(false);
			})
		}
	}, [employeeId, open]);

	return {
		employee,
		fields,
		hasGusto,
		showFinalPayDate,
		loading,
	};
}
