// @startCleanup encore
import html from './clocked-out-error.micro.html';
import './styles.styl';
// @endCleanup encore
import { v4 as uuid } from 'uuid';
import { isEnabled } from '@bamboohr/utils/lib/feature';
import {INCORRECT_TIME_ENTRIES_MSG} from 'time-tracking/constants';
import { render } from 'base/wrapped-render';
import { ClockedOutErrorModal } from './clocked-out-error-modal';

export default function (generalError, callback) {
	const modalRootId = uuid();

	const onClose = () => {
		callback();
		if (generalError) {
			window.setMessage(INCORRECT_TIME_ENTRIES_MSG, 'error');
		}
		document.body.removeChild(document.getElementById(modalRootId));
	}

	// @startCleanup encore
	if (isEnabled('encore'))  {
	// @endCleanup encore
		const modalRoot = document.createElement('div');
		modalRoot.setAttribute('id', modalRootId);
		render(<ClockedOutErrorModal onClose={onClose} />, document.body.appendChild(modalRoot));
	// @startCleanup encore
	} else {
		window.SimpleModal.openModal({
			callbacks: {
				close: {
					after() {
						if (generalError) {
							window.setMessage(INCORRECT_TIME_ENTRIES_MSG, 'error');
						}
					},
				},
			},
			html: window.microTemplate(html, {}),
			title: $.__('Whoa, something changed'),
			footer: {
				buttons: {
					primary: {
						text: $.__('Got it'),
						action: () => {
							window.SimpleModal.closeModal();
							callback();
						},
					},
					cancel: {
						show: false,
					},
				},
			},
		})
	}
	// @endCleanup encore
}
