import { emailRegex } from '@utils/validation/rules';
import moment from 'moment';
import { Fields } from 'dynamic-form';

export const SSNPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
export const SINPattern = /^[0-9]{3}-[0-9]{3}-[0-9]{3}$/;
export const NINPattern = /^([0-9a-z]{2}\s?){4}\s?[0-9a-z]{1}$/i;
export const NATIONALPattern = /^[0-9a-z]{6,16}$/i;
export const DigitPattern = /^\d+$/;
export const URLPattern = /^((https?|s?ftp):\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
export const employeeNumberPattern = new RegExp(window.GLOBAL_NUMBER_REGEX.replace('(?!0[0-9])', ''));
export const passportPattern = /^[A-Z0-9]{1,20}$/i;

export const validationClassMap = {
	[Fields.ssn]: {
		validateSocialSecurity: {
			check_field: 'ssn',
			action: 'checkSSN',
			message: window.SESSION_USER && window.SESSION_USER.isAdmin
				? $.__('Oops, this is not a valid SSN or it is a duplicate of another SSN in your account.')
				: $.__('Oops! Double check the numbers you entered. If everything is correct please contact your administrator.'),
		},
		pattern: SSNPattern,
	},
	[Fields.sin]: {
		validateSocialId: {
			action: 'checkSIN',
			check_field: 'sin',
			message: $.__('There is already an employee with this SIN in the system.'),
		},
		pattern: SINPattern,
	},
	[Fields.nin]: {
		validateSocialId: {
			check_field: 'nin',
			action: 'checkNIN',
			message: $.__('Please fix this field.'),
		},
		pattern: NINPattern,
	},
	[Fields.nationalId]: {
		validateSocialId: {
			check_field: 'national_id',
			friendly_name: 'National ID',
			action: 'checkNationalID',
			message: $.__('Please fix this field.'),
		},
		pattern: NATIONALPattern,
	},
	[Fields.employeeNumber]: {
		uniqueEmployeeNumber: true,
	},
	[Fields.paySchedule]: {
		validatePaySchedule: true,
	},
	[Fields.payType]: {
		invalidPayrollOption: true,
	},
	[Fields.paidPer]: {
		invalidPayrollOption: true,
		validatePayRatePaidPer: true,
		validatePaidPerTrax: true,
	},
	[Fields.payRate]: {
		validatePayRatePaidPer: true,
		currencyTypeMatch: { onlyIfTrax: true, currency: 'USD' },
	},
	CalendarPicker: {
		baseDateValidation: {
			from: moment().subtract({ year: 100, days: 1 }),
			to: moment().add(50, 'y'),
		},
	},
	validateBirthDate: {
		validateDateBetween: {
			from: '1900-01-01',
			to: moment(),
		},
	},
	validateDateField: {
		validateDateField: true,
	},
	validateHireDate: {
		validateDateBetween: {
			from: '1900-01-01',
			to: moment().add(3, 'y'),
		},
	},
	validateSmallDate: {
		validateDateBetween: {
			from: '1900-01-01',
			to: '2079-06-06',
		},
	},
	validateToDate: {
		validateDateFrom_to: true,
	},
	validateZipTrax: {
		validateUSZipTrax: true,
	},
	checkEmailExists: {
		checkEmailExists: true,
		pattern: {
			value: emailRegex,
			message: $.__('Invalid email address.'),
		},
	},
	validateEmail: {
		pattern: {
			value: emailRegex,
			message: $.__('Invalid email address.'),
		},
	},
	validateUrl: {
		pattern: {
			value: URLPattern,
			message: $.__('Please enter a valid URL.'),
		},
	},
	validateNumber: {
		pattern: {
			value: new RegExp(window.GLOBAL_NUMBER_REGEX),
			message: $.__('Please enter a valid number or remove any leading zeros.'),
		},
	},
	validateEmployeeNumber: {
		pattern: {
			value: employeeNumberPattern,
			message: $.__('Please enter a valid number.'),
		},
	},
	/* This is for old execupay, not BambooHR Payroll */
	validatePayrollEmployeeNumber: {
		pattern: DigitPattern,
		maxLength: 6,
		noLeadingZeros: true,
	},
	creditCardLast4Digits: {
		pattern: DigitPattern,
		maxLength: 4,
	},
	validateOvertimeRateTrax: {
		valueGreaterThanZero: { onlyIfTrax: true },
		currencyTypeMatch: { onlyIfTrax: true, currency: 'USD' },
	},
	validatePassportNumber: {
		pattern: passportPattern,
		maxLength: 20,
	},
	validateCCNumber: {
		minLength: 13,
		maxLength: 16,
	},
	primaryContact: {
		onePrimaryContact: true,
	},
};
