import {
	useState,
} from 'react';
import { noop } from 'lodash';

import {
	BEM as Bem,
} from '@utils/dom';
import {
	Modal,
} from 'modal-legacy';
import {
	showSlidedown,
} from 'Alerts.mod';

import {
	Message,
} from '@bamboohr/utils/lib/message';
import {
	showModal,
	closeModal,
} from 'show-modal.mod';

import {
	doResetEmployeeMFALogin,
} from '../../service';
import {
	hideManageNavMfaOptions,
} from '../../manage-nav-mfa-options';

import './styles.styl';

const bem = new Bem('MfaResetModal');

export function MfaResetModal(props) {
	const {
		userId,
		name,
		onReset = noop,
		isResettingOwnMfa,
	} = props;

	const [isProcessing, setIsProcessing] = useState(false);

	return (
		<Modal
			content={
				<p className={ bem.elem('copy') }>
					{ name ?
						(
							<Message
								text={ $.__('The next time {1} logs in, they will be directed to setup an authentication app and be able to scan the barcode.\n\n**Would you like to proceed?**') }
								params={ [name] }
							/>
						) : (
							<Message
								text={ $.__('The next time you log in, you will be directed to setup an authentication app and be able to scan the barcode.\n\n**Would you like to proceed?**') }
							/>
						)
					}
				</p>
			}
			headline={ name ?
				(
					<Message
						text={ $.__("Reset {1}'s 2-Step Login") }
						params={ [name] }
					/>
				) : $.__('Reset My 2-Step Login')
			}
			icon="fab-lock-45x54"
			iconColor="theme"
			isOpen={ true }
			isProcessing={ isProcessing }
			onClose={ () => closeModal() }
			primaryAction={ () => {
				setIsProcessing(true);
				doResetEmployeeMFALogin(userId)
					.then(() => {
						onReset();
						setIsProcessing(false);
						const successMsg = name ?
							$.__('2-Step Login has been reset for %1$s', name) :
							$.__('2-Step Login has been reset.');
						showSlidedown(successMsg, 'success');
						// If user is resetting their own MFA login, we should hide the MFA related options in the manage-nav.
						if (userId === window.SESSION_USER?.id || isResettingOwnMfa) {
							hideManageNavMfaOptions();
						}
						closeModal();
					})
					.catch(() => {
						setIsProcessing(false);
						showSlidedown($.__('Uh-oh. Something went wrong, please try again!'), 'error');
					});
			} }
			primaryActionText={ $.__('Yes, Reset') }
			title={ $.__('Just making sure...') }
		/>
	);
}

export const showMfaResetModal = props => showModal(
	<MfaResetModal {...props} />
);
