import { Icon, IconV2 } from '@bamboohr/fabric';
import { formatTime } from 'time-tracking/utils';
import { EntryProjectTaskNote } from 'time-tracking/components/entry-project-task-note.react';
import { useStyles } from './hour-entries.styles';
import { isEnabled } from 'FeatureToggle.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const ttLocationUpdatesEnabled = isEnabled('ttLocationUpdates');

export function HourEntries(props) {
	const {
		canEdit,
		hourEntries,
		isViewOnly,
		onEditClick,
		renderProjectAndTask,
		type
	} = props;
	const styles = useStyles({canEdit, isViewOnly, ttLocationUpdatesEnabled});

	return hourEntries.map((hourEntry) => {
		const {
			hours,
			id,
			note,
			projectName,
			taskName,
		} = hourEntry;
		const projectTaskAndOrNoteExists = projectName || taskName || note;
		const entryClick = (isViewOnly && !ttLocationUpdatesEnabled) ? null : () => onEditClick(id, 'edit');

		return (
			<div
				className={ styles.root }
				data-bi-id="time-tracking-hour-entry"
				key={ id }
				onClick={ entryClick }
			>
				<span className={ styles.hours }>{ formatTime(hours) }</span>

				{ projectTaskAndOrNoteExists && (
					<>
						{ (projectName || taskName) && (
							<span className={ styles.arrow }>
								{ifFeature('encore', <IconV2 name='arrow-right-regular' size={10} />, <Icon name='fab-arrow-right-6x5' />)}
							</span>
						) }

						<EntryProjectTaskNote
							isViewOnly={ isViewOnly }
							note={ note }
							projectName={ projectName }
							renderProjectAndTask={ renderProjectAndTask }
							taskName={ taskName }
							type={ type }
						/>
					</>
				) }

				{ canEdit && (
					<span className={ styles.editIcon } id="entry-edit-icon">
						{ifFeature('encore', <IconV2 color='neutral-extra-strong' name='pen-solid' size={12} />, <Icon name="fab-pencil-12x12" />)}
					</span>
				) }
			</div>
		);
	});
}
