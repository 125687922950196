import {
	Dropdown as FabricDropdown,
} from '@fabric/dropdown';

import {
	convertOldItemToNew,
	placementOldToNew,
} from 'shared/utils';

export function Dropdown(props) {
	const {
		data,
		fabricSettings,
		onHide,
		onSelect,
		onShow,
		settings = {}
	} = props;

	if (fabricSettings) {
		return (
			<FabricDropdown
				{ ...fabricSettings }
			/>
		);
	}

	const {
		anchor,
		disabled,
		listPosition = '',
	} = settings;

	const convertedSettings = {
		anchor,
		isDisabled: disabled,
		onSelect: (value, { item }) => onSelect(value, item),
		onOpen: onShow,
		onClose: onHide,
		showSearch: 'auto',
		placement: placementOldToNew(listPosition)
	};

	return (
		<FabricDropdown
			{ ...convertedSettings }
			buttonSettings={ {
				icon: data.icon ? data.icon.name : '',
				text: data.displayText ? data.displayText : ''
			} }
			items={ data.items.map(convertOldItemToNew) }
		/>
	);
}

export default Dropdown;
