import config from 'micro-frontend.config';

const resolveDevVersion = (version) => {
	return version === 'dev' ? 'prod' : version;
};

export const getProductionUrl = (name, version) => {
	// Allow full url pasting for odi versions
	if (version && version.startsWith('https://')) {
		return version;
	}

	const _version = version || config.versions[name] || '';

	return `${config.url}/${name}/${resolveDevVersion(_version)}`;
};
