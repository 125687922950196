export default {
	url: 'https://staticfe.bamboohr.com',
	versions: {
		greenhouse: window.ENV,
		po: window.ENV,
		developerportal: window.ENV,
	},
	odiManifestEndpoints: {
		greenhouse: '/greenhouse/api/manifests',
		po: '/dev/micro-fe/manifests/po',
		feMain: '/dev/micro-fe/manifests/fe-main'
	},
};
