import Ajax from '@utils/ajax';
import {
	useEffect,
	useState,
} from 'react';

import config from 'micro-frontend.config';
import { showSlidedown } from 'Alerts.mod';

export const useOdiResources = (name = '') => {
	const [resources, setResources] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(
		() => {
			const odiUrl = config?.odiManifestEndpoints[name];

			setIsLoading(true);

			if (!odiUrl) {
				if (resources) {
					setResources([]);
				}

				setIsLoading(false);
				return;
			}

			Ajax.get(odiUrl)
				.then(
					({ data: _resources = [] } = {}) => {
						setResources(_resources);
						setIsLoading(false);
					},
					() => {
						showSlidedown('Something went wrong loading ODI resources.', 'error');
						setIsLoading(false);
					}
				);
		}, [name]
	);

	return [resources, isLoading];
};
