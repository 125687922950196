import FileList from 'file-list.react';
import { connect } from 'react-redux';
import { getFilesByParentId } from '../../../store/selectors';
import { deleteFile } from '../../../actions';

const mapStateToProps = (state, ownProps) => ({
	files: getFilesByParentId(state, ownProps.id).toList(),
});

export default connect(
	mapStateToProps,
	{
		removeOnClick: deleteFile,
	}
)(FileList);

