import { HelpPopover } from '../help-popover.react';

export function OptionalFieldHelp(props) {
	const {
		helpData,
	} = props;

	if (!helpData || !helpData.text) {
		return null;
	}
	return <LegacyOptionalFieldHelp { ...props } /> // TODO: We need to update Backend to send us a string that does not have the HTML set inside and we will handle it with the message then with the link separately itself.
}

export function LegacyOptionalFieldHelp(props) {
	const {
		helpData,
	} = props;

	return <HelpPopover content={ helpData.text } />;
}
