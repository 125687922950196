export default class StopWatch {
	constructor(accuracy = 100) {
		this.accuracy = accuracy;
		this.ms = 0;
		this.running = false;

		this._startTime = null;
		this._timer = null;
		this._watchers = [];
	}

	_requestTick(interval) {
		this._timer = setTimeout(() => {
			this._tick();
		}, interval);
	}

	_tick() {
		const now = new Date().getTime();
		this.ms = now - this._startTime;

		const fullIntervals = Math.floor(this.ms / this.accuracy);
		const intervalTotal = fullIntervals * this.accuracy;
		const differenceBetweenTotalIntervalsAndCurrentTime = this.ms - intervalTotal;
		const nextInterval = this.accuracy - differenceBetweenTotalIntervalsAndCurrentTime;

		this._watchers.forEach((watcher) => {
			const timesPassed = Math.floor(this.ms / watcher.amount);
			if (timesPassed !== watcher._count) {
				watcher._count = timesPassed;
				watcher.cb(this.ms);
			}
		});

		this._requestTick(nextInterval);
	}

	_addWatcher(amount, cb = Function.prototype) {
		let _count = Math.floor(this.ms / amount);
		this._watchers.push({
			amount,
			cb,
			_count,
		});
	}

	start(startingMs = 0) {
		if (this.running) {
			return this;
		}

		this._watchers.forEach((watcher) => {
			watcher._count = Math.floor(startingMs / watcher.amount);
		});

		this._startTime = new Date().getTime() - startingMs;
		this._requestTick(this.accuracy);
		this.running = true;

		return this;
	}

	stop() {
		if (!this.running) {
			return this;
		}

		clearTimeout(this._timer);
		this._watchers.forEach((watcher) => {
			watcher._count = 0;
		});

		this.running = false;

		return this;
	}

	/**
	 * Used to run a callback every "ms" of your choosing
	 * For example: timer.every(1000, () => {})
	 *     will run the callback every 1 second.
	 *     This callback NOT run 1 second from the time the callback is added,
	 *     but will happen on the 1000 mark in the timer
	 *     (eg at 1000, then 2000, then 3000, etc, but never on values like 5500)
	 * @param onEveryMs
	 * @param cb
	 * @returns {StopWatch}
	 */
	every(onEveryMs, cb) {
		this._addWatcher(onEveryMs, cb);
		return this;
	}

	everySecond(cb) {
		this._addWatcher(1000, cb);
		return this;
	}

	everyMinute(cb) {
		this._addWatcher(60000, cb);
		return this;
	}
}
