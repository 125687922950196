import {
	findParent,
	getField,
	getSelectedValue,
	Fields,
} from 'dynamic-form';
import { ReactElement } from 'react';

export function SelectPayRateField(currencyProps: DynamicForm.TextElementProps): ReactElement {
	const { props, settings, context } = currencyProps;
	const {
		controls: { CurrencyField },
	} = context;

	const payTypeField = getField(context, Fields.payType) as DynamicForm.SelectElement;
	const payTypeValue = getSelectedValue(payTypeField);

	const parentField = findParent(context, props.id);
	const payRateGroupElement = parentField ? document.querySelector(`#${parentField.props.id}`) : undefined;

	if (payRateGroupElement) {
		payRateGroupElement.classList.remove('hidden');
	}

	if (payTypeValue && payTypeValue !== 'Salary' && payTypeValue !== 'Hourly') {
		if (payRateGroupElement) {
			payRateGroupElement.classList.add('hidden');
		}
		return null;
	}

	const note = payTypeValue === 'Hourly' ? {
		type: 'edit',
		text: $.__('per hour.'),
	} as DynamicForm.Note : settings.note

	return (
		<CurrencyField
			context={ context }
			props={ {
				...props,
				readOnly: !payTypeValue ? true : false,
			} }
			settings={ {
				...settings,
				note,
			} }
		/>
	);
}
