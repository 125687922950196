import { isEqual } from 'lodash';
import { createRef, Component } from 'react';

import { RadialProgressChart } from 'Charts.mod';
import { MOUSE_CLICK, MOUSE_OVER, MOUSE_LEAVE } from 'Charts.mod/event-names';

export default class RadialProgressChartComponent extends Component {

	/**
	 * Establish event listeners dispatched from BaseChart
	 */
	_setupListeners() {
		const { onMouseClick, onMouseOver, onMouseLeave } = this.props;

		if (typeof onMouseClick === 'function') {
			this._radialChartElem.current.addEventListener(MOUSE_CLICK, (event) => {
				onMouseClick(event);
			});
		}

		if (typeof onMouseOver === 'function') {
			this._radialChartElem.current.addEventListener(MOUSE_OVER, (event) => {
				onMouseOver(event);
			});
		}
	
		if (typeof onMouseLeave === 'function') {
			this._radialChartElem.current.addEventListener(MOUSE_LEAVE, (event) => {
				onMouseLeave(event);
			});
		}
	}

	_chart = null;
	_radialChartElem = createRef();

	/**
	 * Renders the radialChart
	 */
	componentDidMount() {
		let {data, settings} = this.props;

		this._chart = new RadialProgressChart(this._radialChartElem.current, settings);
		this._chart.draw(data);

		this._setupListeners();
	}

	shouldComponentUpdate(nextProps) {
		const { data } = this.props;
		return !isEqual(data, nextProps.data);
	}

	/**
	 * Updates and rerenders the radialChart
	 */
	componentDidUpdate() {
		const { data } = this.props;

		this._chart.draw(data);
	}

	/**
	 * Renders the widget
	 */
	render() {
		return <div ref={ this._radialChartElem }></div>;
	}
}
