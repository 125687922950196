import React, { useEffect, useState } from 'react';
import { Button, CardContentSpacing, Checkbox, Loader, makeStyles, StandardModal, TextButton } from '@bamboohr/fabric';
import { Megaphone26x28 } from '@bamboohr/grim';
import Ajax from '@utils/ajax';
import { acceptDataProcessingAgreement, postponeUpdateDataProcessingAgreement } from '../../service';
import { showSlidedown } from 'Alerts.mod';

const useStyles = makeStyles((theme) => ({
	checkboxLine: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
	},
	label: {
		color: theme.palette.grey[800],
	},
	icon: {
		width: 50,
		height: 54,
	},
	link: {
		marginLeft: theme.spacing(1),
	},
	confirmationdetails: {
		fontSize: theme.typography.fabricFontSize('small'),
		color: theme.palette.grey[800],
		paddingBottom: theme.spacing(1.5),
	},
}));

const defaultMessage = $.__(
	'Based on a recent changes in the E.U. courts, we have updated our Data Processing Agreement. For companies with employees in certain countries, we recommend agreeing to our updated version.'
);

export function UpdatedDPAModal({ agreement, isOpen, onClose = () => {} }) {
	const [message, setMessage] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const classes = useStyles();

	useEffect(() => {
		if (isOpen) {
			Ajax.get('/data_processing_agreement_message')
				.then((response) => {
					setMessage(response.data.message || defaultMessage);
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [isOpen]);

	const handleSave = async () => {
		if (isChecked) {
			try {
				await acceptDataProcessingAgreement(agreement.id);
				onClose();
				showSlidedown($.__('Another win! Thanks for accepting our Data Processing Agreement.'), 'success');
			} catch (e) {
				console.error(e);
				showSlidedown(
					$.__('Huh? Something went wrong while accepting the Data Processing Agreement. Please contact support.'),
					'error'
				);
			}
		}
	};

	const handleClose = async () => {
		try {
			await postponeUpdateDataProcessingAgreement(agreement.id);
		} catch (error) {
			console.error(error);
		}
		onClose();
	};

	return (
		<StandardModal isOpen={isOpen} onRequestClose={handleClose}>
			{isLoading ? (
				<Loader />
			) : (
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={[
								<Button disabled={!isChecked} key='close-preview' onClick={handleSave} type='button'>
									{$.__('Save')}
								</Button>,
								<TextButton key='cancel-preview' onClick={handleClose} type='button'>
									{$.__('Cancel')}
								</TextButton>,
							]}
						/>
					}
					renderHeader={<StandardModal.Header title={$.__('Updated Data Processing Agreement')} />}
				>
					<StandardModal.Constraint spacingOverrides={{ top: CardContentSpacing.LARGE }}>
						<StandardModal.HeroHeadline
							hasSeparator={true}
							// eslint-disable-next-line react/forbid-component-props
							icon={<Megaphone26x28 className={classes.icon} />}
							subtext={message}
							text={$.__('Hear Ye, Hear Ye!')}
						/>
						<div>
							<div className={classes.checkboxLine}>
								<Checkbox
									checked={isChecked}
									classes={{ labelContent: classes.label }}
									label={$.__('I agree to the Data Processing Agreement.')}
									onChange={(param, event) => {
										setIsChecked(event.target.checked);
									}}
								/>
								{/*  eslint-disable-next-line react/forbid-component-props */}
								<TextButton className={classes.link} href='https://www.bamboohr.com/data-processing-agreement/' target='_blank'>
									{$.__('Read full DPA')}
								</TextButton>
							</div>
							<p className={classes.confirmationdetails}>
								{$.__(
									'By accepting the Data Processing Agreement (“DPA”), I confirm that I am duly authorized to represent my company and accept the DPA on its behalf and that my company has employees in the European Union, EEA, the United Kingdom or Switzerland.'
								)}
							</p>
						</div>
					</StandardModal.Constraint>
				</StandardModal.Body>
			)}
		</StandardModal>
	);
}
