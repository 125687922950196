import {
	BodyText,
	Flex,
	Headline,
	LayoutBox,
	Link,
	Svg,
} from '@bamboohr/fabric';
import { Fragment, ReactElement } from 'react';

type Props = {
	companyName: string;
	isVisible?: boolean;
};

export function UsesBambooHR({ companyName, isVisible }: Props): ReactElement {
	const homeUrl = `${window.location.protocol}//${window.location.hostname}`;

	if (!isVisible) {
		return <Fragment></Fragment>;
	}

	return (
		<Flex alignItems={'center'} flexDirection={'column'} marginTop={'40px'}>
			<LayoutBox marginBottom={'16px'}>
				<Svg
					color="primary-strong"
					height={'36px'}
					image="bamboohr-logo"
					width={'238px'}
				/>
			</LayoutBox>
			<Headline size="small">{$.__(`%1 uses BambooHR`, companyName)}</Headline>
			<BodyText size="medium" color="neutral-extra-strong">
				{$.__(`You can access your company's HR site at:`)}
			</BodyText>
			<Link size="medium" href="#" rel="noopener noreferrer" target="_blank">
				{homeUrl}
			</Link>
			<BodyText size="extra-extra-small">
				{$.__(`(You may want to bookmark this baby.)`)}
			</BodyText>
		</Flex>
	);
}

export default UsesBambooHR;
