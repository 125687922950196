import { Button, Loader, ProgressiveLoader, StandardModal, Table, TableColumn } from '@bamboohr/fabric';
import { EmployeeListRes, EmployeeResult } from './types';
import { useEffect, useRef, useState } from 'react';

import Ajax from '@utils/ajax';
import { AxiosResponse } from 'axios';
import { buildColumns } from './utils';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import useStyles from './employee-list-modal.style';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	subtitle: string;
	listId?: string;
	listValueId?: string;
	employeeIds?: number[];
}

export const EmployeeListModal = (props: Props): JSX.Element => {
	const { isOpen, onClose, title, listId, listValueId, employeeIds, subtitle } = props;

	const style = useStyles();

	const [isLoading, setIsLoading] = useState(true);
	const [columns, setColumns] = useState<TableColumn<EmployeeResult>[]>([]);
	const [data, setData] = useState([]);
	const [totalEmployees, setTotalEmployees] = useState(0);
	const [hasMoreEmployees, setHasMoreEmployees] = useState(false);
	const [visableEmployees, setVisableEmployees] = useState(0);
	const [nextPage, setNextPage] = useState(0);
	const loadingNewRowsRef = useRef(false);
	const payGroupType = 'pay_group';

	/*
        Weird internal logic is needed because the modal is being rendered standalone inside of twig files.  This allows us to preserve the modal closing animation.
    */
	const [internalIsOpen, setInternalIsOpen] = useState(true);

	useEffect(() => {
		setInternalIsOpen(isOpen);
	}, [isOpen]);

	const handleClose = () => {
		setInternalIsOpen(false);
	};

	function resetForm() {
		onClose();
		setColumns([]);
		setData([]);
	}
	/* End weird stuff */

	useEffect(() => {
		setHasMoreEmployees(totalEmployees > visableEmployees);
	}, [totalEmployees, visableEmployees]);

	const handleListResponse = (res: AxiosResponse<EmployeeListRes>) => {
		const { columns: columnsRes, employeeResults, totalNumberOfEmployees, pagination } = res.data;
		setColumns(buildColumns(columnsRes));
		setData(data.concat(employeeResults));
		setTotalEmployees(totalNumberOfEmployees);
		setVisableEmployees(employeeResults.length + visableEmployees);
		setNextPage(pagination.next);
		loadingNewRowsRef.current = false;
	};

	const showMoreEmployees = () => {
		if (loadingNewRowsRef.current) {
			return;
		}
		loadingNewRowsRef.current = true;
		if (listId && listValueId) {
			Ajax.post('/employees/list/filter/list_value', {
				list_id: listId === payGroupType ? payGroupType : +listId,
				list_value_id: +listValueId,
				page: nextPage,
			})
				.then(handleListResponse)
				.catch(() => {
					window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else if (employeeIds?.length) {
			Ajax.post('/employees/list/filter/employee_ids', {
				employee_ids: employeeIds,
				page: nextPage,
			})
				.then(handleListResponse)
				.catch(() => {
					window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const getInfo = () => {
		setIsLoading(true);
		if (listId && listValueId) {
			Ajax.post('/employees/list/filter/list_value', {
				list_id: listId === payGroupType ? payGroupType : +listId,
				list_value_id: +listValueId,
			})
				.then(handleListResponse)
				.catch(() => {
					window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else if (employeeIds?.length) {
			Ajax.post('/employees/list/filter/employee_ids', { employee_ids: employeeIds })
				.then(handleListResponse)
				.catch(() => {
					window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
			handleClose();
		}
	};

	return (
		<StandardModal isOpen={isOpen && internalIsOpen} onOpenComplete={getInfo} onRequestClose={handleClose} onCloseComplete={resetForm}>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<Button key='button' onClick={handleClose} type='button'>
								{$.__('Done')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header hasCloseButton={true} title={title} />}
			>
				<StandardModal.StandardHeadline
					// @ts-expect-error
					icon={ifFeature('encore', 'users-solid', 'fab-employees-18x15')}
					text={`${subtitle} ${totalEmployees ? `(${totalEmployees})` : ''}`}
				/>
				<div className={style.modalBody} id='employee_list_modal_body'>
					{isLoading ? (
						<Loader />
					) : (
						<>
							<Table caption={`${title} - ${subtitle}`} columns={columns} rowKey={(e: EmployeeResult) => e.id.value} rows={data} />
							{hasMoreEmployees && (
								<ProgressiveLoader
									hasMore={hasMoreEmployees}
									loadMore={showMoreEmployees}
									loader={<Loader small={true} />}
									preloadUntilScroll={true}
									wrapper='#employee_list_modal_body'
								/>
							)}
						</>
					)}
				</div>
			</StandardModal.Body>
		</StandardModal>
	);
};
