import { Fragment } from 'react';
import HiddenSubmitButton from 'hidden-submit-button.react';
import PayPeriodSeparator, { PayPeriodIndicator, showSeparator as showPayPeriodSeparator } from '../pay-period-separator.react';
// @startCleanup encore
import { computeNudges } from '../pay-period-separator.react/utils';
// @endCleanup encore
import Slat from '../slat.react';
import { forgotToClockOut } from 'time-tracking/utils';
import { isEnabled } from 'FeatureToggle.util';
import { Section, LayoutBox, Flex, Divider } from '@bamboohr/fabric';
import {consume} from '../../store/context';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getFocusedSheetDateSpanText } from '../../../../utils';

function Sheet(props) {
	const {
		clock,
		workflow: {userCanEdit},
		focusedTimesheet,
		timesheetOptions,
		saveTimesheet,
		getPadStartDays,
		getFocusedDays,
		getPadEndDays,
		showSheetPadDays,
		isViewOnly,
		showTimesheetDateRangeHeader = ifFeature('encore', true),
	} = props;

	const onSubmit = (e) => {
		e.preventDefault();
		saveTimesheet();
	};

	const padStartDays = getPadStartDays();
	const focusedDays = getFocusedDays();
	const padEndDays = getPadEndDays();

	const forgotClockOut = clock ? forgotToClockOut(clock.latest) : false;

	const padSlatProps = {
		slatDisabled: true,
		type: focusedTimesheet.type,
		forgotClockOut
	};

	const focusedSlatProps = {
		slatDisabled: false,
		type: focusedTimesheet.type,
		canEdit: isEnabled('ttLocationUpdates') ? userCanEdit : userCanEdit && !isViewOnly,
		forgotClockOut
	};

	// @startCleanup encore
	// Separator display logic now lives in this component so we don't need to create/pass these props to a 'smart' component
	const separatorProps = {
		showWeekends: timesheetOptions.showWeekends,
		previousPeriodEntries: padStartDays,
		nextPeriodEntries: padEndDays,
		nudges: computeNudges(timesheetOptions.showWeekends, focusedDays, padEndDays)
	};
	// @endCleanup encore

	const formJade = (
		<form onSubmit={onSubmit}>
			{showSheetPadDays && (
				<Fragment>
					{padStartDays.map((day) => (
						<Slat key={day.date} {...day} {...padSlatProps} />
					))}

					<PayPeriodSeparator side='top' {...separatorProps} />
				</Fragment>
			)}

			{focusedDays.map((day) => <Slat key={day.date} {...day} {...focusedSlatProps} />)}

			{showSheetPadDays && (
				<Fragment>
					<PayPeriodSeparator side='bottom' {...separatorProps} />

					{padEndDays.map((day) => (
						<Slat key={day.date} {...day} {...padSlatProps} />
					))}
				</Fragment>
			)}

			<HiddenSubmitButton />
		</form>
	);


	/**
	 * Returns a standard 'neutral-weak' divider for standard focused day separators
	 * Returns a standard 'neutral-medium' to separate current pay period from previous period if it's the first day in the period
	 * Returns no divider if it's the first day in the period and we aren't showing the time sheet date range header (i.e. Payroll Hours Report Modal in Encore)
	 */
	const getFocusedTimesheetSlatDivider = (isFirstDayInPeriod) => {
		if (isFirstDayInPeriod && showTimesheetDateRangeHeader) {
			/* Darker first divider to separate current pay period from previous period */
			return (
				<Divider color={showSheetPadDays && padStartDays.length ? 'neutral-medium' : 'neutral-extra-weak'} />
			);
		} else if (!isFirstDayInPeriod) {
			return <Divider color={showSheetPadDays && padStartDays.length ? 'neutral-medium' : 'neutral-extra-weak'} />;
		}
		return null;
	}

	const form = (
		<form onSubmit={onSubmit}>
			<Flex flexDirection='column'>
				{showSheetPadDays &&
					padStartDays.map((day) => (
						<Flex flexDirection='column' key={day.date}>
							<Divider color='neutral-extra-weak' />
							<Slat {...day} {...padSlatProps} />
						</Flex>
					))}
				{focusedDays.map((day, index) => (
					<Flex flexDirection='column' key={day.date}>
						{getFocusedTimesheetSlatDivider(index === 0)}
						<Slat
							{...day}
							{...focusedSlatProps}
							topNote={
								index === 0 &&
								showPayPeriodSeparator(timesheetOptions.showWeekends, padStartDays) && <PayPeriodIndicator type='begins' />
							}
						/>
					</Flex>
				))}
				{showSheetPadDays &&
					padEndDays.map((day, index) => (
						<Flex flexDirection='column' key={day.date}>
							{/* Darker first divider to separate future pay period from current period */}
							<Divider color={index === 0 ? 'neutral-medium' : 'neutral-extra-weak'} />
							<Slat
								{...day}
								{...padSlatProps}
								topNote={
									index === 0 &&
									showPayPeriodSeparator(timesheetOptions.showWeekends, padEndDays) && <PayPeriodIndicator type='ends' />
								}
							/>
						</Flex>
					))}
			</Flex>

			<HiddenSubmitButton />
		</form>
	);

	return ifFeature(
		'encore',
		<div className='TimesheetEntries'>
			<Section>
				{showTimesheetDateRangeHeader ? (
					<LayoutBox marginBottom={3}>
						<Section.Header
							icon='calendar-solid'
							size='medium'
							title={getFocusedSheetDateSpanText(focusedTimesheet.start, focusedTimesheet.end)}
						/>
					</LayoutBox>
				) : null}
				{form}
			</Section>
		</div>,
		<div className='TimesheetEntries'>{formJade}</div>
	);
}

export default consume([
	// Properties
	'workflow',
	'focusedTimesheet',
	'timesheetOptions',
	'clock',
	'showSheetPadDays',
	'isViewOnly',
	'showTimesheetDateRangeHeader',
	//Actions
	'saveTimesheet',
	// Queries
	'getPadStartDays',
	'getFocusedDays',
	'getPadEndDays',
], Sheet);
