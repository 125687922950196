import {extend} from 'lodash';

import Balloon from 'Balloon.mod/Balloon.mod';

export default class Popover extends Balloon {
	constructor(anchor, options) {
		options = extend({
			themeClass: 'Balloon--popover',
			closeX: true,
			push: 18,
			tail: true,
			tailSize: 11,
			minTailOnBalloon: 12,
		}, options);

		super(anchor, options);
	}
}
