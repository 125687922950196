const GLOBAL_DATE_MASK_TO_FORMAT_TYPE_MAP = {
	'dd MMM YYYY': 'dd MMM yyyy',
	'dd/mm/yyyy': 'dd/MM/yyyy',
	'mm/dd/yyyy': 'MM/dd/yyyy',
	'yyyy-mm-dd': 'yyyy-MM-dd',
};

export function getFormatTypeFromGlobalDateMask(): string {
	return GLOBAL_DATE_MASK_TO_FORMAT_TYPE_MAP[window.GLOBAL_DATE_MASK];
}

export function getLocale(): string {
	return document.querySelector('html')?.getAttribute('lang');
}
