import { max } from 'd3-array';
import { scaleLinear, scaleOrdinal } from 'd3-scale';

import { truncateLabel } from '../shared/chart-functions';
import { mouseEnter, mouseOver, mouseLeave, mouseClick } from 'Charts.mod/event-functions';

/**
 * Example left labels settings object
{
	chartClass: 'MyNamespacedName',
	needsAxis: false,
	container: {
		flexHeight: false,
		height: 136,
		width: 230,
		margin: {
			top: 0,
			right: 10,
			bottom: 0,
			left: 10
		} 
	},
	labelText: {
		fill: '#777',
		width: 80,
		maxLabelLength: 30, // Represents the max chars a label can contain before we trim with ...
		alignmentLeft: true
	},
	valueText: {
		color: '#777',
		spacing: 3,
		valueEdgePadding: 3
	},
	bar: {
		colors: ['#ccc'], // Grey
		cursor = 'pointer',
		height: 18,
		padding: 8, 
		formatTextFunction: null
	}
}
 */

/**
 * Creates the left aligned labels bar chart
 *
 * @param {object} data Formatted Data array containing objects
 */

export function drawChart(data) {
	const { bars: barsData } = data;

	const settings = this.getSettings();
	const { container } = settings;
	const { top, right, left } = container.margin;
	const { width: labelWidth, fill: labelFill } = settings.labelText;
	const { spacing: valueSpace, valueEdgePadding } = settings.valueText;
	const { padding: barPadding, height: barHeight, colors, cursor = 'default' } = settings.bar;

	const maxResult = max(barsData.map((i) => parseInt(i.value)));
	const longestValueInPixels = this._convertValueLengthToPixelSpace(maxResult);

	const width = this._getContainerWidth() - left - right;
	const widthAdjustedForText = width - longestValueInPixels;
	const chartContainerWidth = widthAdjustedForText - valueEdgePadding - settings.labelText.width;

	const xScale = scaleLinear()
		.domain([0, maxResult])
		.range([0, chartContainerWidth - right]);

	const zScale = scaleOrdinal().domain(barsData).range(colors);

	const groupsUpdate = this._svg.selectAll(`g${this._getClassList('__chartContainer')}`).data(barsData);

	const groupsEnter = groupsUpdate
		.enter()
		.append('g')
		.attr('class', this._buildClassList('__chartContainer'))
		.attr('transform', (d, i) => {
			const x = top + settings.labelText.width;
			const y = i * (barHeight + barPadding) + barPadding;
			return `translate(${x}, ${y})`;
		});

	const labelsUpdate = this._g.selectAll(this._getClassList('__label')).data(barsData);

	// Update
	groupsUpdate
		.select(this._getClassList('__bar'))
		.transition()
		.duration(1000)
		.attr('width', (d) => xScale(d.value));

	groupsUpdate
		.select(this._getClassList('__value'))
		.transition()
		.duration(1000)
		.style('opacity', 0)
		.attr('x', function (d) {
			const { width } = this.getBBox();
			return Math.max(width, xScale(d.value));
		})
		.transition()
		.duration(500)
		.style('opacity', 1)
		.text((d) => d.value);

	labelsUpdate
		.transition()
		.duration(1000)
		.text((d) => {
			const value = d.title;
			const maxLength = this.getSettings().labelText.maxLabelLength;

			return truncateLabel(maxLength, value);
		});

	// Exit
	groupsUpdate.exit().transition().duration(1000).style('opacity', 0).remove();

	labelsUpdate.exit().transition().duration(1000).style('opacity', 0).remove();

	// Enter
	groupsEnter
		.append('rect')
		.attr('class', this._buildClassList('__bar'))
		.attr('cursor', cursor)
		.attr('fill', (d) => zScale(d))
		.attr('width', (d) => xScale(d.value))
		.attr('height', barHeight)
		.attr('transform', 'translate(0, 0)')
		.on('mouseenter', mouseEnter)
		.on('click', mouseClick)
		.on('mouseover', mouseOver)
		.on('mouseleave', mouseLeave);

	groupsEnter
		.append('text')
		.attr('class', this._buildClassList('__value'))
		.attr('y', barHeight / 2)
		.attr('dx', valueSpace)
		.attr('dy', '.35em')
		.attr('x', function (d) {
			const { width } = this.getBBox();
			return Math.max(width, xScale(d.value));
		})
		.style('fill', (d) => zScale(d))
		.text((d) => {
			if (typeof this._settings.bar.formatTextFunction === 'function') {
				return this._settings.bar.formatTextFunction(d.value);
			}

			return d.value;
		});

	labelsUpdate
		.enter()
		.append('text')
		.attr('class', this._buildClassList('__label'))
		.attr('y', function (d, i) {
			return i * (barHeight + barPadding) + barPadding;
		})
		.attr('x', 0)
		.attr('dx', valueSpace)
		.attr('dy', '0.9em')
		.attr('fill', labelFill)
		.attr('width', labelWidth)
		.text((d) => {
			const value = d.title;
			const maxLength = this.getSettings().labelText.maxLabelLength;

			return truncateLabel(maxLength, value);
		});
}
