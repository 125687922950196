import { useState } from 'react';
import { LayoutBox, makeStyles, SelectField, TextArea } from '@bamboohr/fabric';
import { CancellationContentProps, CancellationFormData, PayrollFormData, ReasonOptionFeature } from '../../../../types';
import { ModalContentWrapper } from '../../../modal-content-wrapper';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface Step2aFormValues {
	new_processing_method: PayrollFormData['new_processing_method'];
	new_processing_method_details: PayrollFormData['new_processing_method_details'];
	reason: CancellationFormData['reason'];
	reason_details: CancellationFormData['reason_details'];
}
// @startCleanup encore
const useStyles = makeStyles(({ spacing }) => ({
	marginBottom: {
		marginBottom: spacing(2),
	},
	fullWidth: {
		width: '100%',
	},
	textarea: {
		'& textarea': {
			boxSizing: 'border-box',
			minHeight: 54,
			resize: 'none',
		},
	},
}));
// @endCleanup encore

export const FormA = ({ onNext, reasons, ...rest }: CancellationContentProps): JSX.Element => {
	const [formValues, setFormValues] = useState<Step2aFormValues>({
		new_processing_method: null,
		new_processing_method_details: null,
		reason: null,
		reason_details: null,
	});
	// @startCleanup encore
	const classes = useStyles();
	// @endCleanup encore

	const mergeFormValues = (values: Partial<Step2aFormValues>) => {
		setFormValues({ ...formValues, ...values });
	};

	const handleOnNext = () => {
		const { reason, reason_details, ...restFormValues } = formValues;
		onNext({ reason, reason_details, payroll_data: restFormValues });
	};

	const processingMethodDetailsRequired = formValues.new_processing_method?.toLowerCase() === 'other';
	const disableNextButton =
		!formValues.new_processing_method ||
		(processingMethodDetailsRequired && !formValues.new_processing_method_details) ||
		!formValues.reason ||
		!formValues.reason_details;

	return ifFeature(
		'encore',
		<ModalContentWrapper {...rest} disablePrimaryButton={disableNextButton} onNext={handleOnNext}>
			<LayoutBox marginBottom={2}>
				<SelectField
					id='new_processing_method'
					isClearable={false}
					items={reasons.payroll_new_processing_methods || []}
					label={$.__('What is your new payroll processing method?')}
					onChange={(e) => mergeFormValues({ new_processing_method: e.target.value[0] })}
					required={true}
					showSearch='never'
					value={[formValues.new_processing_method || '']}
					width={6}
				/>
			</LayoutBox>
			<LayoutBox marginBottom={2}>
				<TextArea
					id='new_processing_method_details'
					label={$.__('Please provide any additonal details here')}
					minRows={3}
					onChange={(e) => mergeFormValues({ new_processing_method_details: e.target.value })}
					required={processingMethodDetailsRequired}
					value={formValues.new_processing_method_details || ''}
					width={100}
				/>
			</LayoutBox>
			<LayoutBox marginBottom={2}>
				<SelectField
					id='reason'
					isClearable={false}
					items={reasons.payroll || []}
					label={$.__('Reason for cancelling payroll account')}
					onChange={(e) => mergeFormValues({ reason: e.target.value[0] })}
					required={true}
					showSearch='never'
					value={[formValues.reason || '']}
					width={100}
				/>
			</LayoutBox>
			<LayoutBox marginBottom={2}>
				<TextArea
					id='reason_details'
					label={$.__('Please provide any additonal details here')}
					minRows={3}
					onChange={(e) => mergeFormValues({ reason_details: e.target.value })}
					required={true}
					value={formValues.reason_details || ''}
					width={100}
				/>
			</LayoutBox>
		</ModalContentWrapper>,
		<ModalContentWrapper {...rest} disablePrimaryButton={disableNextButton} onNext={handleOnNext}>
			<div className={classes.marginBottom}>
				<SelectField
					id='new_processing_method'
					isClearable={false}
					items={reasons.payroll_new_processing_methods || []}
					label={$.__('What is your new payroll processing method?')}
					onChange={(e) => mergeFormValues({ new_processing_method: e.target.value[0] })}
					required={true}
					showSearch='never'
					value={[formValues.new_processing_method || '']}
					width={6}
				/>
			</div>
			<div className={classes.marginBottom}>
				<TextArea
					className={classes.textarea}
					id='new_processing_method_details'
					label={$.__('Please provide any additonal details here')}
					onChange={(e) => mergeFormValues({ new_processing_method_details: e.target.value })}
					required={processingMethodDetailsRequired}
					value={formValues.new_processing_method_details || ''}
					width={100}
				/>
			</div>
			<div className={classes.marginBottom}>
				<SelectField
					className={classes.fullWidth}
					id='reason'
					isClearable={false}
					items={reasons.payroll || []}
					label={$.__('Reason for cancelling payroll account')}
					onChange={(e) => mergeFormValues({ reason: e.target.value[0] })}
					required={true}
					showSearch='never'
					value={[formValues.reason || '']}
					width={100}
				/>
			</div>
			<div className={classes.marginBottom}>
				<TextArea
					className={classes.textarea}
					id='reason_details'
					label={$.__('Please provide any additonal details here')}
					onChange={(e) => mergeFormValues({ reason_details: e.target.value })}
					required={true}
					value={formValues.reason_details || ''}
					width={100}
				/>
			</div>
		</ModalContentWrapper>
	);
};
