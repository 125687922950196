import { select, mouse } from 'd3-selection';
import Popover from 'Popover.mod';
import Tooltip from 'Tooltip.mod';

/**
 * These are d3 event functions to help you easily imcorporate some of the more basic
 * event driven functionality.
 *
 * These are the functions that are called from your eventListener
 */

/**
 * Ideally used with a `mouseenter` or `mouseover` event
 * to expand the bars vertically while remaining in the same position
 * Use `barShrink` as your `mouseleave` to return back to the original size
 *
 * @param {} e
 */
export function barGrow(e) {
	const box = select(e.target).node().getBBox();

	select(e.target)
		.attr('height', d => box.height + 4)
		.attr('y', d => box.y - 2);
}

/**
 * Ideally used with a `mouseleave` event
 * to shrink the bars back vertically while remaining in the same position.
 *
 * @param {} e
 */
export function barShrink(e) {
	const box = select(e.target).node().getBBox();

	select(e.target)
		.attr('height', d => box.height - 4)
		.attr('y', d => box.y + 2);
}

/**
 * Ideally used with a `mouseenter`, `mouseover` or 'click' event
 * to diplay a popover with the included html string for display
 *
 * You'll build your html string with your data from inside your eventListener function
 *
 * @param {} e
 * @param string html
 */
export function popOver(e, html, settings = {}) {
	let placePopoverX;
	const mousePositionX = mouse(e.target)[0];
	const targetStartPositionX = e.target.getAttribute('x') || e.target.getAttribute('cx');
	const { push = 15 } = settings;

	// Stacked charts have a continued x starting position, so we need to compensate for it.
	placePopoverX = targetStartPositionX ? mousePositionX - targetStartPositionX : mousePositionX;

	Popover.create(e.target, {
		showImmediately: true,
		closeX: false,
		push,
		tailSize: 9,
		triggerEvent: 'hover',
		onShow(instance) {
			instance.balloonElement.style.pointerEvents = 'none';
			window.addEventListener('scroll', function _listener() {
				if (!instance.destroyed) {
					instance.hide();
				}
				window.removeEventListener('scroll', _listener, true);
			}, true);
		},
		onHide(instance) {
			if (instance && !instance.destroyed) {
				instance.destroy();
			}
		},
		tailOnAnchor: placePopoverX,
		html,
	});
}

/**
 * This popover is meant to be used on d3 charts that need the popover to snap to an html/svg element rather than the mouse event
 *
 * @param {*} html the html to render for the popover
 * @param {*} target The target
 * @param {*} position where to affix the balloon tail options: 'top' | 'bottom' | 'left' | 'right'
 */
export function snappyPopover(html, target, position) {
	const settings = {};
	const { push = 15 } = settings;
	let popoverInstance;

	Popover.create(target, {
		showImmediately: true,
		closeX: false,
		push,
		position,
		tailSize: 9,
		triggerEvent: 'hover',
		onShow(instance) {
			popoverInstance = instance;
			instance.balloonElement.style.pointerEvents = 'none';
			window.addEventListener('scroll', function _listener() {
				if (!instance.destroyed) {
					instance.hide();
				}
				window.removeEventListener('scroll', _listener, true);
			}, true);
		},
		onHide(instance) {
			if (instance && !instance.destroyed) {
				instance.destroy();
			}
		},
		html,
	});

	return () => {
		if (popoverInstance && !popoverInstance.destroyed) {
			popoverInstance.destroy();
		}
	};
}

export function toolTip(element, settings) {
	Tooltip.create(element, settings);
}
