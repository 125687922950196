import classnames from 'classnames';
import { startCase, uniqueId } from 'lodash';
import { forwardRef, Fragment } from 'react';

export const ProductionVersionPicker = forwardRef(({ errors, name, onChange, value }, ref) => {
	const id = uniqueId('production-version-picker');

	return (
		<Fragment>
			<label
				className={
					classnames(
						'fab-Label',
						'fab-Label--required',
						{ 'fab-Label--error': errors.customVersion }
					)
				}
				htmlFor={ id }
			>
				Paste a production version of { startCase(name) }
			</label>
			<input
				ref={ ref }
				className={ classnames('fab-TextInput', 'fab-TextInput--width8', { 'fab-TextInput--error': errors.customVersion}) }
				id={ id }
				name="customVersion"
				onChange={ event => onChange(event.currentTarget.value) }
				required={ true }
				type="text"
				value={ value }
			/>
		</Fragment>
	);
});
