import { FunctionComponent, useState, useEffect } from 'react';
import { 
	BodyText,
	Divider,
	Flex,
	Headline,
	IconV2,
	LayoutBox,
	StyledBox,
} from '@bamboohr/fabric';
import './employee-access.styl';

const EmployeeAccessQuickBooks: FunctionComponent<DynamicForm.EmployeeAccessQuickBooksProps> = ({ accessLevelText, name, renderAccessLevelOptions, renderEmailReminderText, value }) => {
	return (
		<>
			<Headline size={'extra-small'}>
				{$._(`This new employee will have access to:`)}
			</Headline>
			<input type='hidden' name={name} value={value} />
			<Flex 
				marginTop={'16px'}
				width={'100%'}
			>
				<Flex alignItems={'start'} flex={1}>
					<StyledBox backgroundColor='neutral-medium' borderRadius='medium' marginRight={2} padding={1.5}>
						<Flex alignItems={'center'}>
							<IconV2 color='primary-strong' name='address-card-regular' size={24} />
						</Flex>
					</StyledBox>
					<LayoutBox>
						<BodyText color={'neutral-strong'} size={'large'} weight={'medium'}>
							{$.__('BambooHR')}
						</BodyText>
						<LayoutBox margin={'2px 0px 2px 0px'}>
							<BodyText color={'neutral-strong'} size={'extra-small'}>
								{accessLevelText}
							</BodyText>
						</LayoutBox>
						{renderAccessLevelOptions()}
						{renderEmailReminderText()}
					</LayoutBox>
				</Flex>
				<Divider color={'neutral-weak'} margin={'0px 24px'} orientation='vertical' />
				<Flex alignItems={'start'} flex={1}>
					<StyledBox backgroundColor='neutral-medium' borderRadius='medium' marginRight={2} padding={1.5}>
						<Flex alignItems={'center'}>
							<IconV2 color='primary-strong' name='circle-dollar-regular' size={24} />
						</Flex>
					</StyledBox>
					<LayoutBox>
						<BodyText color={'neutral-strong'} size={'large'} weight={'medium'}>
							{$.__('QuickBooks Workforce')}
						</BodyText>
						<LayoutBox marginTop={'2px'}>
							<BodyText color={'neutral-strong'} size={'extra-small'}>
								{$.__('They will use QuickBooks Workforce to access their paychecks and tax documents.')}
							</BodyText>
						</LayoutBox>
					</LayoutBox>
				</Flex>
			</Flex>
		</>
	);
};

export default EmployeeAccessQuickBooks;
