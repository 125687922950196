import {
	createStyles,
	makeStyles,
} from '@bamboohr/fabric/';

export const useStyles = makeStyles(({ palette, typography }) => {
	return createStyles({
		fileCard: () => ({
			marginRight: '16px',
			marginBottom: '10px',
		}),
		pendingWorkflow: {
			color: palette.gray[1000],
			fontWeight: typography.fontWeightRegular,
		}
	})
});
