import { Content } from './components';
import { Modal } from 'modal-legacy';
import { showModal, closeModal } from 'show-modal.mod';
import { useRef, useState } from 'react';

const CreateModal = (props) => {
	const contentRef = useRef(null);
	const [isProcessing, setProcessing] = useState(false);

	return (
		<Modal
			contentHasMaxHeight={ false }
			contentHasPadding={ false }
			headerType={ null }
			isOpen={ true }
			onClose={ () => closeModal() }
			primaryAction={ () => contentRef.current.submit() }
			primaryActionText={ $.__('Send') }
			title={ $.__('Email Secure Download Link') }
			isProcessing={ isProcessing }
		>
			<Content
				{...props}
				ref={ contentRef }
				setProcessing={ setProcessing }
			/>
		</Modal>
	);

};

export const create = props => showModal(
	<CreateModal { ...props } />
);
