import {
	clone,
	find,
	isFunction,
	isPlainObject,
	isString,
	noop,
	union,
	kebabCase,
} from 'lodash';

import { ifFeature } from '@bamboohr/utils/lib/feature';

import {
	callFunctionQueue,
	validateEmail,
} from 'BambooHR.util';
import ajax from '@utils/ajax';
import {
	PayrollAdmin,
} from 'access-levels.mod';

import {
	openPasswordResetModal,
} from 'Employees.mod/password-reset';

import * as tmpl from './templates';
import { showAdminAccessLevelChangeModal } from './components/admin-access-level-change-modal.react';


import './styles.styl';


const ATTR_NAME = 'ba-access-level-selector';
/** @typedef {'input'|'button'|'li'|'ba-select'|'ba-option'} WrapperType */
/** @type {WrapperType[]} */
const NODE_TYPES = ['input', 'button', 'li', 'ba-select', 'ba-option'];
const INIT_SELECTOR = NODE_TYPES.map(node => `${ node }[${ ATTR_NAME }]`).join(', ');

const GROUP_LIST_ENDPOINT = '/settings/access_levels/group_list';
const USER_INFO_ENDPOINT = '/settings/access_levels/user_info';
const MULTI_MODAL_URL = '/ajax/employees/multiple_group_selection.php';
const SAVE_ENDPOINT = '/settings/access_levels/save_employees_access_level';
const PREVIEW_URL = '/settings/access_levels/preview_as_user/%1';

const TIMEOUT = 10;
const BATCH_USERS_MAX = 500;

/** @type {WeakMap<AccessLevelSelector, JQuery>} */
const _wrapper = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, JQuery>} */
const _downList = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, JQuery>} */
const _inputWrapper = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, JQuery>} */
const _labelSpan = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, WrapperType>} */
const _type = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, string>} */
const _userId = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, string>} */
const _employeeId = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, string>} */
const _accessLevelId = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, Partial<User>>} */
const _user = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, string>} */
const _icon = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, string>} */
const _placeholder = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, string[]>} */
const _exclude = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, Partial<User>>} */
const _originalUser = new WeakMap();
/** @type {WeakMap<AccessLevelSelector, boolean>} */
const _isSetup = new WeakMap();

/** @type {AccessLevelSelector[]} */
let _instances = [];

let _fetchUserTimeout;
/** @type {((user: User) => void)[]} */
const _fetchUserCallbackQueue = [];

let _fetchLevelsTimeout;
/** @type {(() => void)[]} */
const _fetchLevelsCallbackQueue = [];

/** @type {string[]} */
const _userIds = [];
/** @type {string[]} */
const _employeeIds = [];
/** @type {{ users: { [id: string]: (user: User) => void}, employees: { [id: string]: (user: User) => void}}} */
const _userCallbacks = {
	users: {},
	employees: {},
};

/** @type {AccessLevel[]} */
let _accessLevels;
/** @type {{ [id: string]: AccessLevel }} */
const _accessLevelMap = {};


/**
 * Register events
 *
 * @param {AccessLevelSelector} comp
 *
 * @returns {AccessLevelSelector}
 */
function setupEvents(comp) {
	if (_isSetup.get(comp)) {
		return comp;
	}

	comp.off('.AccessLevelSelector');

	_isSetup.set(comp, true);

	comp
		.on('hover.AccessLevelSelector', null, comp, () => onHover(comp))
		.on('click.AccessLevelSelector', 'li[data-id]', comp, function() {
			const levelId = $(this).attr('data-id');
			onLevelSelect(levelId, comp);
		});

	comp.$wrapper.closest('.BhrForms')
		.on('reset.AccessLevelSelector', () => {
			comp.reset();
		});

	comp.$wrapper.closest('ba-dropdown, ba-select')
		.on('ba:dropdownSelect ba:selectChange', ({ originalEvent: { type, target, detail } }) => {
			let id;

			if (type === 'ba:dropdownSelect') {
				id = detail.item.item.dataAccessLevelSelectorId;
			} else if (type === 'ba:selectChange') {
				id = $(target).find(`ba-option[value="${ detail.value }"]`)
					.data('accessLevelSelectorId');
			}

			if (id) {
				onLevelSelect(id, comp);
			}
		});

	return comp;
}

/**
 * Prepends the given arguments with important data,
 * and triggers the event on `$wrapper`
 *
 * @param {AccessLevelSelector} comp
 * @param {string} type the type of event to fire
 * @param {any[]} args any number of extra arguments
 *
 * @returns {AccessLevelSelector}
 */
function trigger(comp, type, ...args) {
	let newArgs = [];
	switch (type) {
		case 'change':
			newArgs = [
				comp.user.accessLevelIds,
				comp.user,
			];
			break;
	}

	newArgs.push(comp);

	setTimeout(() => {
		comp.$wrapper.trigger(
			`AccessLevelSelector:${ type }`,
			newArgs.concat(args)
		);
	}, 0);

	return comp;
}

/**
 * Redirects to the "Preview As..." URL for the current user
 *
 * @param {AccessLevelSelector} comp
 */
export function previewAs(comp) {
	if (comp.userId !== null) {
		window.location.href = window.sprintf(PREVIEW_URL, [comp.userId]);
	}
}

/**
 * Opens the modal for resetting password
 *
 * @param {AccessLevelSelector} comp
 */
function resetPassword(comp) {
	if (
		isPlainObject(comp.user) &&
		comp.user.userId
	) {
		openPasswordResetModal(comp.user);
	}
}

/**
 * The event handler for when the component is hovered
 *
 * @param {AccessLevelSelector} comp
 */
function onHover(comp) {
	if (
		comp.hasEmployee &&
		comp.hasUser &&
		!comp.hasUserData
	) {
		comp.fetch(() => {
			comp.refresh();
		});
	}
}

/**
 * The event handler for when a dropdown option is clicked
 *
 * @param {string} levelId
 * @param {AccessLevelSelector} comp
 */
function onLevelSelect(levelId, comp) {
	const isAdmin = window.SESSION_USER && window.SESSION_USER.isAdmin;
	const { employeeId, userId } = comp.user;
	const { label } = comp.tmplData;
	levelId = levelId || 'no_access';

	if (
		levelId === 'no_access' ||
		typeof _accessLevelMap[levelId] !== 'undefined'
	) {
		if (isAdmin && employeeId === window.SESSION_USER.employeeId) {
			return showAdminAccessLevelChangeModal({ clickAction: () => comp.save(levelId) });
		}
		return comp.save(levelId);
	}

	switch (levelId) {
		case 'multiple': {
			openMultiModal(comp);
			break;
		}
		case 'preview': {
			previewAs(comp);
			break;
		}
		case 'resetPassword': {
			resetPassword(comp);
			break;
		}
		case 'managePayrollCompanyAccess': {
			const modalProps = {
				employeeId,
				userId,
				accessLevelName: label,
			}
			trigger(comp, 'openManagePayrollCompanyAccessModal', modalProps)
			break;
		}
		default: {
			Rollbar.warn(`[AccessLevelSelector] invalid levelId: "${ levelId }" (${ typeof levelId }) `);
			break;
		}
	}
}

/**
 * Opens the multiple access levels modal
 *
 * @param {AccessLevelSelector} comp
 */
function openMultiModal(comp) {
	const {
		userId,
		employeeId,
	} = comp;
	const payrollAdmin = _accessLevelMap['payroll-admin']

	ajax.get(MULTI_MODAL_URL, {
		userId,
		employeeId,
		header: 'employee',
	}).then((response) => {
		if (response.status === 200) {
			const { data } = response;
			const { user: { employeeName } } = comp;

			window.BambooHR.Modal.setState({
				biId: 'multiple-access-levels',
				isOpen: true,
				title: $.__('Multiple Access Levels'),
				primaryAction() {
					const selected = document.querySelectorAll('#multiple-permissions input[type="checkbox"][name="groups"]:checked');
					const seletedValues = [...selected].map(input => input.value);
					comp.save(seletedValues).then(() => {
						window.BambooHR.Modal.setState({ isOpen: false });
					});

				},
				icon: ifFeature('encore', 'user-lock-regular', 'fab-lock-person-18x18'),
				headline: employeeName,
				headerType: 'text',
				primaryActionText: $.__('Save'),
				dangerousContent: data
			});
		}

	});
}

/**
 * Shows an error message when trying to
 * update a user/employee without an email
 *
 * @param {AccessLevelSelector} comp
 * @param {string[]} accessLevelIds
 * @param {JQuery.Deferred} [defer]
 */
function requireEmail(comp, accessLevelIds, defer) {
	const {
		employeeId,
		hasEmployee,
		userId,
		user: {
			firstName,
		},
	} = comp;
	/** @type {string} */
	let levelName = (accessLevelIds.length > 1) ? $.__('%1$s Access Levels', accessLevelIds.length) : (_accessLevelMap[accessLevelIds[0]] || {}).label;
	/** @type {JQuery} */
	let $form;
	/** @type {JQuery} */
	let $fields;
	let postURL = `/employees/updateEmails/${ employeeId }`;

	if (!hasEmployee) {
		postURL = `/settings/users/edit/${ userId }`;
	}

	const content = tmpl.emailModal(comp);
	const title = $.__('Add %1$s to %2$s', firstName, levelName);

	const primaryAction = (() => {
		const $form = $('#access-levels-require-email-form');

		ajax.post(
			postURL,
			$form.serialize()
		).then(function({ data }) {

			if (!data.success) {
				const {
					error,
					errorMessage
				} = data;

				setMessage(errorMessage, 'error');
				console.error(error);
				return;
			}

			comp.user.hasEmail = true;

			window.BambooHR.Modal.setState({ isOpen: false });

			comp.save(accessLevelIds, defer)
				.done(() => {
					trigger(comp, 'changeEmail');
				});
		});
	});

	const onOpen = (($modal) => {
		const $form = $('#access-levels-require-email-form');
		$fields = $form.find('input[type="text"]');

		$fields.on('keyup paste change blur focus', function() {
			var values = $fields.toArray().map(field => field.value);

			if (values.map(validateEmail).includes(true)) {
				window.BambooHR.Modal.setState({ primaryAction }, true);
			} else {
				window.BambooHR.Modal.setState({ primaryAction: null }, true);
			}
		});
	});

	// Show the modal
	window.BambooHR.Modal.setState({
		dangerousContent: content,
		isOpen: true,
		onOpen,
		primaryAction: null,
		primaryActionText: $.__('Save'),
		title,
	});
}

/**
 * @param {Partial<User>} user
 */
function requirePayrollAdminESS(user) {
	return PayrollAdmin.showSettingsModal(user);
}

/**
 * ### `AccessLevelSelector`
 *
 * A legacy component for viewing/setting/selecting
 * the access levels of a user/employee
 */
export default class AccessLevelSelector {
	/**
	 * Gets the access level list and info from the server,
	 * assigns it to local variables, and then calls an optional callback
	 *
	 * @param {Function} [cb] an optional callback
	 *
	 * @returns {typeof AccessLevelSelector}
	 */
	static fetchLevels(cb) {
		if (isFunction(cb)) {
			_fetchLevelsCallbackQueue.push(cb);
		}

		clearTimeout(_fetchLevelsTimeout);
		_fetchLevelsTimeout = setTimeout(() => {
			$.getJSON(GROUP_LIST_ENDPOINT)
				.done((result) => {
					_accessLevels = result.levelTypes;
					_accessLevels.forEach((levelType) => {
						if (Array.isArray(levelType.levels)) {
							levelType.levels.forEach((level) => {
								level.type = levelType.name;
								_accessLevelMap[level.id] = level;
								_accessLevelMap[level.name] = level;
							});
						} else if (levelType.id) {
							_accessLevelMap[levelType.id] = levelType;
							_accessLevelMap[levelType.name] = levelType;
						}

						delete _accessLevelMap.undefined;
					});

					callFunctionQueue(_fetchLevelsCallbackQueue);
					_fetchLevelsCallbackQueue.length = 0;
				});
		}, TIMEOUT);

		return AccessLevelSelector;
	}

	/**
	 * Batches up requested user ids, and makes a single
	 * request to the server to get relevant user data,
	 * sets local vars, and then calls each callback,
	 * passing the requested user as an argument.
	 *
	 * If `id` is given, the user object will come from
	 * the server. Otherwise, it will create an empty user
	 * object, and immediately pass it to the callback
	 *
	 * @param {string|null} id the id of the user for which to request data
	 * @param {(user: Partial<User>) => void} [cb] optional callback – will receive requested user as only argument
	 * @param {(users: Partial<User>[]) => void} [done] optional callback after all user callbacks have been called – receives list of requested users as only argument
	 * @param {boolean} [isEmployee=false]
	 *
	 * @returns {typeof AccessLevelSelector}
	 */
	static fetchUser(id, cb, done, isEmployee = false) {
		if (!isEmployee && id === null) {
			(cb || noop)({
				accessLevelIds: [],
				noAccess: false,
			});

			return AccessLevelSelector;
		}

		if ((_employeeIds.length + _userIds.length) >= BATCH_USERS_MAX) {
			(cb || noop)({});

			return AccessLevelSelector;
		}

		let cbKey = isEmployee ? 'employees' : 'users';

		_userCallbacks[cbKey] = _userCallbacks[cbKey] || {};
		_userCallbacks[cbKey][id] = cb;
		(isEmployee ? _employeeIds : _userIds).push(id);

		clearTimeout(_fetchUserTimeout);
		_fetchUserTimeout = setTimeout(() => {
			$.post(USER_INFO_ENDPOINT, {
				userIds: _userIds,
				employeeIds: _employeeIds,
			})
				.done(({
					success,
					errorMessage,
					error,

					users,
				}) => {
					if (!success) {
						setMessage(errorMessage, 'error');
						console.error(error);
						return;
					}

					users.forEach((user) => {
						(
							_userCallbacks.users[user.userId] ||
							_userCallbacks.employees[user.employeeId] ||
							noop
						)(user);
					});

					callFunctionQueue(_fetchUserCallbackQueue, null, users);
					_fetchUserCallbackQueue.length = 0;

					(done || noop)(users);
				});

			_userIds.length = 0;
			_employeeIds.length = 0;
		}, TIMEOUT);

		return AccessLevelSelector;
	}

	/**
	 * Batches up requests for employees using `AccessLevelSelector.fetchUser()`
	 *
	 * @param {string|null} employeeId
	 * @param {(user: User) => void} [cb]
	 * @param {(users: User[]) => void} [done]
	 *
	 * @returns {typeof AccessLevelSelector}
	 */
	static fetchEmployee(employeeId, cb, done) {
		return AccessLevelSelector.fetchUser(employeeId, cb, done, true);
	}

	/**
	 * Hides all instances that haven't loaded yet
	 *
	 * @returns {typeof AccessLevelSelector}
	 */
	static hideNotLoaded() {
		AccessLevelSelector.$elements.css('visibility', 'hidden');

		return AccessLevelSelector;
	}

	/**
	 * Loop through each active instance
	 *
	 * @param {Parameters<JQuery['each']>} args
	 *
	 * @returns {typeof AccessLevelSelector}
	 */
	static each(...args) {
		AccessLevelSelector.$elements.each(...args);

		return AccessLevelSelector;
	}

	/**
	 * Prepares for instances to be created
	 *
	 * Calls:
	 * 1. `AccessLevelSelector.hideNotLoaded()`
	 * 2. `AccessLevelSelector.refresh()`
	 *
	 * @returns {typeof AccessLevelSelector}
	 */
	static init() {
		AccessLevelSelector
			.hideNotLoaded()
			.refresh();

		return AccessLevelSelector;
	}

	/**
	 * Refreshes all previously initialized instances, and
	 * initializes all uninitialized instances
	 *
	 * @returns {typeof AccessLevelSelector}
	 */
	static refresh() {
		$(() => {
			if (!AccessLevelSelector.$elements.length) {
				return;
			}

			AccessLevelSelector.hideNotLoaded();

			AccessLevelSelector.fetchLevels(() => {
				_instances = _instances.filter((instance) => {
					if (
						instance &&
						instance.$wrapper &&
						instance.$wrapper.closest(document.documentElement).length > 0
					) {
						instance.refresh();
						return true;
					}
				});

				AccessLevelSelector.each(function() {
					new AccessLevelSelector(this);
				});
			});
		});

		return AccessLevelSelector;
	}

	/**
	 * All `.AccessLevelSelector` elements on the page
	 *
	 * @type {JQuery}
	 */
	static get $elements() {
		return $(INIT_SELECTOR);
	}

	/**
	 * The `.AccessLevelSelector` jQuery element
	 *
	 * @type {JQuery}
	 */
	get $wrapper() {
		return _wrapper.get(this);
	}

	/**
	 * The `.down-list` jQuery element
	 *
	 * @type {JQuery}
	 */
	get $downList() {
		return _downList.get(this);
	}

	/**
	 * The jQuery element that will contain the hidden inputs
	 * to pass on the current value in forms
	 *
	 * @type {JQuery}
	 */
	get $inputWrapper() {
		return _inputWrapper.get(this);
	}

	/**
	 * The jQuery element that will contain the label
	 *
	 * This element won't exist for input type components,
	 * so $label will be an empty jQuery object
	 *
	 * @type {JQuery}
	 */
	get $label() {
		return _labelSpan.get(this);
	}

	/**
	 * The component type: 'button' or 'input'
	 *
	 * Set with a `data-user-id` attribute
	 *
	 * @type {WrapperType}
	 */
	get type() {
		return _type.get(this);
	}

	/**
	 * Whether the target is a `<button>`
	 *
	 * @type {boolean}
	 */
	get isButton() {
		return this.type === 'button';
	}

	/**
	 * Whether the target is an `<input>`
	 *
	 * @type {boolean}
	 */
	get isInput() {
		return this.type === 'input';
	}

	/**
	 * Whether the target is an `<li>`
	 *
	 * @type {boolean}
	 */
	get isDropdown() {
		return this.type === 'li';
	}

	/**
	 * Whether the target is a `<ba-option>`
	 *
	 * @type {boolean}
	*/
	get isOption() {
		return this.type === 'ba-option';
	}

	/**
	 * The id for this component's optional user
	 *
	 * @type {string|null}
	 */
	get userId() {
		return _userId.get(this);
	}

	/**
	 * The id for this component's optional access level
	 *
	 * @type {string|null}
	 */
	 get accessLevelId() {
		return _accessLevelId.get(this);
	}

	/**
	 * The id for this component's optional employee
	 *
	 * @type {string|null}
	 */
	get employeeId() {
		return _employeeId.get(this);
	}

	/**
	 * The user object. This comes from
	 * `AccessLevelSelector.fetchUser()`
	 *
	 * @type {Partial<User>}
	 */
	get user() {
		let user = _user.get(this);

		if (!user) {
			user = {};
			_user.set(this, user);
		}

		return user;
	}

	/**
	 * Whether or not there's a user
	 *
	 * @type {boolean}
	 */
	get hasUser() {
		return !!this.userId;
	}

	/**
	 * Whether or not there's an employeeId
	 *
	 * @type {boolean}
	 */
	get hasEmployee() {
		return !!this.employeeId;
	}

	/**
	 * Whether or not the user data has been loaded from the server
	 *
	 * @type {boolean}
	 */
	get hasUserData() {
		return !!(
			this.user.userId || this.user.employeeId
		);
	}

	/**
	 * The icon name to show for button type components
	 *
	 * Set with a `data-icon` attribute
	 *
	 * Defaults to `'permissions-lock-white'`
	 *
	 * @type {string}
	 */
	get icon() {
		return _icon.get(this) || ifFeature('encore', 'user-lock-regular', 'fab-lock-person-18x18');
	}

	/**
	 * The label that will show for input type components
	 *
	 * @type {string}
	 */
	get label() {
		if (this.user.noAccess) {
			return $.__('No Access');
		}

		let levelIds = this.user.accessLevelIds || [];
		let count = levelIds.length;
		let label;
		let levelId;

		switch (count) {
			case 0:
				label = _placeholder.get(this);
				break;
			case 1:
				levelId = levelIds[0];
				label = (_accessLevelMap[levelId] || {}).label;
				break;
			default:
				label = $.__('%1 Access Levels', count);
				break;
		}

		return label;
	}

	/**
	 * An array of access level ids to exclude from the list
	 *
	 * Special ids:
	 * - `'preview'` excludes the "Preview As..." option
	 * - `'multiple'` excludes the multiple option
	 *
	 * @type {string[]}
	 */
	get exclude() {
		return _exclude.get(this);
	}

	/**
	 * The data object that will be passed to all templates
	 *
	 * @type {any}
	 */
	get tmplData() {
		var _this = this;

		var inputFieldName = 'accessLevelIds';
		var noAccessFieldName = 'noAccess';

		if (this.userId) {
			inputFieldName += `[${ this.userId }]`;
			noAccessFieldName += `[${ this.userId }]`;
		}

		return {
			accessLevelId: this.accessLevelId,
			label: this.label,
			levelTypes: _accessLevels,
			fieldType: this.type,
			isButton: this.isButton,
			isInput: this.isInput,
			isDropdown: this.isDropdown,
			isOption: this.isOption,
			icon: this.icon,
			firstName: this.user.firstName,
			hasEmail: this.user.hasEmail,
			canPreview: !!this.user.canPreview,
			accessLevelIds: this.user.accessLevelIds,
			inputFieldName: inputFieldName + '[]',
			noAccessValue: JSON.stringify(this.user.noAccess),
			noAccessFieldName: noAccessFieldName,
			isSelected(level) {
				if (!level) {
					return false;
				}

				let hasAccessLevel = _this.user.accessLevelIds.some(id => String(id) === String(level.id));

				return (
					(
						hasAccessLevel &&
						_this.user.noAccess !== true
					) ||
					(
						_this.user.noAccess == true &&
						level.name === 'no_access'
					)
				);
			},
			isExcluded(level) {
				if (isString(level)) {
					level = {type: level};
				}

				let levelType = level.type || level.name;

				return (
					!_this.hasEmployee && levelType == 'employee' ||
					_this.exclude.includes(level.type) ||
					_this.exclude.includes(level.id) ||
					_this.exclude.includes(level.name)
				);
			},
		};
	}

	/**
	 * AccessLevelSelector
	 *
	 * @param {HTMLElement} elem;
	 */
	constructor(elem) {
		var $elem = $(elem);

		/** @type {WrapperType} */
		var type = elem.nodeName.toLowerCase();
		_type.set(this, type);

		var icon = $elem.attr('data-icon');
		_icon.set(this, icon);

		var placeholder = $elem.attr('data-placeholder') || `–${ $.__('None Selected') }–`;
		_placeholder.set(this, placeholder);

		var exclude = ($elem.attr('data-exclude') || '')
			.trim()
			.split(/\s*,\s*/g);
		_exclude.set(this, exclude);

		var userId = $elem.attr('data-user-id') || null;
		userId = isString(userId) && userId.trim() === 'null' ? null : userId;
		_userId.set(this, userId);

		var employeeId = $elem.attr('data-employee-id') || null;
		employeeId = isString(employeeId) && employeeId.trim() === 'null' ? null : employeeId;
		_employeeId.set(this, employeeId);

		var accessLevelId = $elem.attr('data-access-level-id') || null;
		_accessLevelId.set(this, accessLevelId);

		this.fetch(
			(user) => {
				if (user.isOwner) {
					$elem.remove();
					return;
				}

				_exclude.set(this, union(this.exclude || [], user.exclude || []));

				var $wrapper = $(tmpl.wrapper({ ...this.tmplData, $elem }));
				_wrapper.set(this, $wrapper);

				Object.entries(elem.dataset)
					.forEach(([key, val]) => {
						$wrapper.attr(kebabCase(`data ${ key }`), val);
					});

				let $downList;

				if ($wrapper.is('ba-dropdown, ba-select')) {
					$downList = $wrapper;
				} else {
					$downList = $wrapper.find('.js-AccessLevelSelector__down-list');
				}

				_downList.set(this, $downList);

				var $inputWrapper = $wrapper.find('.js-AccessLevelSelector__inputWrapper');
				_inputWrapper.set(this, $inputWrapper);

				var $labelSpan = $wrapper.find('.js-AccessLevelSelector__label');
				_labelSpan.set(this, $labelSpan);

				$wrapper.data('AccessLevelSelector', this);

				if (this.isDropdown) {
					$elem.html($wrapper)
						.addClass('hasSublist')
						.css('visibility', '')
						.data('AccessLevelSelector', this);
				} else {
					$elem.replaceWith($wrapper);
				}

				setupEvents(this);


				this.refresh();

				trigger(this, 'init');
			}
		);


		_instances.push(this);
	}

	/**
	 * @param {[string, string, AccessLevelSelector, (e: JQuery.Event, ...data: any[]) => any]} args
	 *
	 * @returns {AccessLevelSelector}
	 */
	on(...args) {
		this.$wrapper.on(...args);

		return this;
	}

	/**
	 * @param {[string]} args
	 *
	 * @returns {AccessLevelSelector}
	 */
	off(...args) {
		this.$wrapper.off(...args);

		return this;
	}

	/**
	 * Reset the component to the original values
	 *
	 * @returns {AccessLevelSelector}
	 */
	reset() {
		this.save(null);

		trigger(this, 'reset');

		return this;
	}

	/**
	 * @param {(user: User) => void} cb
	 * @param {(users: User[]) => void} [done]
	 *
	 * @returns {AccessLevelSelector}
	 */
	fetch(cb, done) {
		let isEmployee = this.hasEmployee && !this.hasUser;
		let id = isEmployee ? this.employeeId : this.userId;

		AccessLevelSelector.fetchUser(id, (user) => {
			_user.set(this, user);
			_originalUser.set(this, clone(user));

			(cb || noop)(user);
		}, done, isEmployee);

		return this;
	}

	/**
	 * Refresh the data, and re-render the component
	 *
	 * @returns {AccessLevelSelector}
	 */
	refresh() {
		const {
			tmplData,
		} = this;

		this.$downList
			.html(tmpl.downList(tmplData));

		this.$inputWrapper
			.html(tmpl.inputWrapper(tmplData));

		this.$label.text(this.label);

		trigger(this, 'refresh');

		return this;
	}

	/**
	 * Takes the given array of ids,
	 * and saves it as the value for the component
	 *
	 * @param {string|string[]|null} newAccessLevelIds the array of access level ids, or `NULL` to reset
	 * @param {JQuery.Deferred} [defer]
	 *
	 * @returns {JQuery.Promise}
	 */
	save(newAccessLevelIds, defer) {
		/** @type {string[]} */
		let accessLevelIds = [];
		let noAccess = (newAccessLevelIds === 'no_access');
		let d = defer || $.Deferred();
		const payrollAdmin = _accessLevelMap['payroll-admin'];

		if (Array.isArray(newAccessLevelIds)) {
			accessLevelIds = newAccessLevelIds.map(id => String(id));
		} else if (newAccessLevelIds === 'no_access') {
			accessLevelIds = this.user.accessLevelIds || [];
			noAccess = true;
		} else if (newAccessLevelIds === null) {
			/** @type {Partial<User>} */
			let originalUser = _originalUser.get(this) || {};

			accessLevelIds = originalUser.accessLevelIds || [];
			noAccess = !!originalUser.noAccess;
		} else {
			accessLevelIds = [newAccessLevelIds];
		}

		if (
			payrollAdmin &&
			!noAccess &&
			accessLevelIds.includes(payrollAdmin.id) &&
			!payrollAdmin.defaultESS &&
			payrollAdmin.selfAccessLevel=='none' &&
			this.user &&
			this.user.firstName
		) {
			requirePayrollAdminESS(this.user)
				.then(({ employeeAccessLevel }) => {
					payrollAdmin.defaultESS = employeeAccessLevel;
					payrollAdmin.selfAccessLevel = 'NotNone';
					this.save(accessLevelIds, d);
				});

			return d.promise();
		}

		if (this.isInput) {
			this.user.accessLevelIds = accessLevelIds;
			this.user.noAccess = noAccess;

			this.refresh();

			trigger(this, 'change');

			d.resolve(this.user);
		} else if (this.userId || this.employeeId) {
			if (!this.user.hasEmail) {
				requireEmail(this, accessLevelIds, d);

				return d.promise();
			}

			let data = {
				userIds: [this.userId],
				employeeIds: [this.employeeId],
				editType: 'change',
				accessLevelIds,
				noAccess,
			};

			$.post(SAVE_ENDPOINT, data)
				.done((result) => {
					if (!result.success) {
						setMessage(result.errorMessage, 'error');
						console.error(result.error);
						d.reject(result.error);
						return;
					}

					let newUser = (
						result.users[this.userId] ||
						find(result.users, {employeeId: this.employeeId}) ||
						{}
					);

					_userId.set(this, newUser.userId || this.userId);
					this.user.userId = this.userId;
					this.user.accessLevelIds = newUser.accessLevelIds || accessLevelIds;
					this.user.noAccess = newUser.noAccess || noAccess;
					this.user.canPreview = newUser.canPreview;

					this.refresh();

					trigger(this, 'change');

					d.resolve(this.user);
				});
		}

		return d.promise();
	}
}

$(AccessLevelSelector.init);

$(window).on('pushState replaceState popState refreshState', AccessLevelSelector.init);

/**
 * @typedef User
 * @property {string} userId
 * @property {string} [employeeId]
 * @property {string} firstName
 * @property {boolean} hasEmail
 * @property {string[]} accessLevelIds
 * @property {boolean} noAccess
 * @property {boolean} canPreview
 * @property {boolean} isOwner
 * @property {string[]} exclude
 */

/**
 * @typedef AccessLevel
 * @property {string} id
 * @property {string} name
 * @property {string} type
 * @property {string} label
 * @property {AccessLevel[]} [levels]
 * @property {string} [defaultESS]
 */
