import { ReactElement, useState } from 'react';
import { ProgressiveLoader, BlankState } from '@bamboohr/fabric';
import { FileListItem } from '../file-list-item';
import { Message } from '@bamboohr/utils/lib/message';
import { closeModal } from 'show-modal.mod';
import { mergeFileData } from './file-list-controller';
import { FileDetails, SelectedFiles, FilesResponse } from '../../types';
import { buildFilesEndpoint } from '../../utils';
import classNames from 'classnames';
import useStyles from './file-list.styles';

interface Props {
	sectionId: number | string;
	sectionName: string;
	selectedFiles?: Array<SelectedFiles>;
	handleFileSelection: (file: SelectedFiles, checked: boolean) => void
	handleToggleRequired: (fileId: string, checked: boolean) => void
	editFileProperty: (fileId: string, property: string, value: string) => void
	tooltipScrollOffset: number
	filesEndpoint: string
}

export function FileList(props: Props): ReactElement {
	const styles = useStyles();

	const limit = 25;

	const {
		sectionId,
		selectedFiles,
		handleFileSelection,
		handleToggleRequired,
		tooltipScrollOffset,
		filesEndpoint,
		editFileProperty
	} = props;

	const [offset, setOffset] = useState(0);
	const [hasMoreFiles, setHasMoreFiles] = useState(true);
	const [files, setFiles] = useState<FileDetails[]>([]);
	const [loadingFiles, setLoadingFiles] = useState(true);

	function renderListItems(): Array<ReactElement> {

		const mergedFiles = mergeFileData(files, selectedFiles);

		const determineMeta = (file: FileDetails) => {
			if (file.meta === undefined) {
				return { disabled: false };
			}
			return file.meta;


		};

		return mergedFiles.map((file): ReactElement => {
			return (
				<FileListItem
					file={ { ...file, meta: determineMeta(file) } }
					handleFileSelection={ handleFileSelection }
					handleToggleRequired={ handleToggleRequired }
					key={ `${ file.file_section_id }_${ file.id }` }
					tooltipScrollOffset={ tooltipScrollOffset }
					editFileProperty={editFileProperty}
				/>
			);
		});
	}

	function renderBlankState(styles): ReactElement {
		const {
			sectionName: name,
		} = props;

		let empty = (
			<Message
				em={ { tag: 'div' } }
				params={ [name] }
				text={ $._('The "{1}" *Folder is Empty*') }
			/>
		);
		let link = false;
		const text = $.__('Upload your Company Files here');
		const url = '/files/';

		if (sectionId === 'all') {
			empty = <Message text={ $._('You Currently Have no Company Files in BambooHR') } />;
			link = true;
		}

		return (
			<div className={ classNames(styles.blankStateWrapper) }>
				<BlankState
					icon="fab-file-question-mark-60x72"
					subtitle={ link ? (
						<a
							className="blankState__link"
							href={ url }
							onClick={ closeModal }
							rel="noopener noreferrer"
							target="_blank"
						>
							{ text }
						</a>
					) : null }
					title={ empty }
				/>
			</div>

		);
	}

	return (
		<>
			<div className={ classNames(styles.fileListContentWrapper) }>
				<ul className={ classNames({[styles.scrollableFileList]: !!files.length}) } id='js-FileList'>
					{files.length > 0 && renderListItems() }
				</ul>
				{ files.length === 0 && !loadingFiles && renderBlankState(styles) }
				<ProgressiveLoader
					endpoint={ buildFilesEndpoint(filesEndpoint, sectionId, offset, limit) }
					hasMore={ hasMoreFiles }
					onFetch={ ({data: {files: { records, totalCount }}}: {data: FilesResponse, total: number}): void => {
						const newOffset = offset + limit;

						const filesWithMeta = window.sharedFilesManager._mergeFileData(records);

						setOffset(newOffset);
						setHasMoreFiles(newOffset < totalCount);
						setFiles([...files].concat(filesWithMeta));
						setLoadingFiles(false);
					} }
					pageSize={ limit }
					preload={ {
						startsAt: 0,
						limit,
					} }
					preloadUntilScroll={true}
					wrapper="#js-FileList"
				/>
			</div>
		</>
	);
}
