import { sanitize } from '@utils/dom';
import { canUseLocalStorage } from '@utils/localstorage';

export const showQueryStringMessage = () => {
	/**
	 * The following allows us to show an alert message
	 * on page load, by using some special query string params
	 *
	 */
	const url = new URL(window.location.href);

	let LSMsg;
	let LSMsgType;
	let LSMsgTimeout;
	if (canUseLocalStorage()) {
		LSMsg = localStorage.getItem('__message__');
		LSMsgType = localStorage.getItem('__messageType__');
		LSMsgTimeout = parseInt(localStorage.getItem('__messageTimeout__') || null);
		localStorage.removeItem('__message__');
		localStorage.removeItem('__messageType__');
		localStorage.removeItem('__messageTimeout__');
	}

	let msg = url.searchParams.get('__message__') || LSMsg;

	if (msg) {
		const msgType = url.searchParams.get('__messageType__') || LSMsgType;
		const msgTimeout = parseInt(url.searchParams.get('__messageTimeout__') || LSMsgTimeout);

		// clear out the special params
		url.searchParams.delete('__message__');
		url.searchParams.delete('__messageType__');
		url.searchParams.delete('__messageTimeout__');

		// use `replaceState()` to remove the special params from the browser URL
		window.history.replaceState({}, '', url.href);

		window.setTimeout(() => {
			// escape any injected html
			msg = sanitize(msg, {
				ALLOWED_ATTR: ['class', 'href'],
				FORBID_TAGS: ['style'],
			});

			window.setMessage(msg, msgType);

			// close the message after a timeout, if the param is a number
			if (!isNaN(msgTimeout)) {
				window.sessionMessageTimeout = window.setTimeout(() => {
					window.closeMessage();
				}, msgTimeout);
			}
		}, 0);
	}

	$(document).on('click', '.messageCloseWrapper', function(e) {
		e.preventDefault();
		window.closeMessage();
	});
};
