import { FunctionComponent } from 'react';
import { getFieldRowError } from 'dynamic-form';
import './field-row.styl';

const FieldRow: FunctionComponent<DynamicForm.FieldRowElementProps> = ({ props, settings, context, children }) => {
	const { className = '', id } = props;
	const {
		controls: { Label },
		validation: { formState: { errors } },
	} = context;
	const { label } = settings;
	const isRequired = className.includes('required') || className.includes('requiredWhenVisible');

	// run every time or messages don't get updated for all children - `getFieldRowError` is optimized to only loop over `field` children, so relatively inexpensive
	const rowError = Object.keys(errors).length ? getFieldRowError(context, errors, id) : null;

	return (
		<div { ...props } className={ `fieldRow DynamicForm__FieldRow ${ className }` }>
			<Label label={ label } required={ isRequired } />
			{ children }
			{ !!rowError && (
				<span className="fab-FormNote fab-FormNote--error">{ rowError.message }</span>
			) }
		</div>
	);
};

export default FieldRow;
