import { FunctionComponent } from 'react';

const GenderPronouns: FunctionComponent<DynamicForm.SelectElementProps> = ({ props, settings, context }) => {
	const {
		items,
	} = props;
	const {
		controls: { SelectField },
	} = context;

	const itemsWithAnchor = [
		{
			items: items.filter(item => item.value !== props['data-prefer-not-to-say-id']),
			type: 'group',
			value: 1,
		},
		{
			anchor: 'bottom',
			items: items.filter(item => item.value === props['data-prefer-not-to-say-id']),
			type: 'group',
			value: 2,
		}
	];

	const selectChildProps: typeof props = {
		...props,
		// @ts-ignore
		items: itemsWithAnchor,
	};

	return (
		<SelectField
			context={ context }
			props={ selectChildProps }
			settings={ settings }
		/>
	);
};

export default GenderPronouns;
