/**
 * Button Selectors
 *
 * @type Object
 */
var selectors = {
	primary: '.js-simplemodal-button-primary',
	secondary: '.js-simplemodal-button-secondary',
	cancel: '.js-simplemodal-cancel'
};

/**
 * Simple Modal Footer Buttons Object
 */
var Buttons = {
	/**
	 * Clear all button event handlers
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	clearAllEventHandlers($modal) {
		this.clearAllActions($modal);
		this.clearAllPerformActionsOnKeyCodes($modal);
	},

	/**
	 * Clear all button click event handlers
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	clearAllActions($modal) {
		window.SimpleModal.Footer.Buttons.Primary.clearAction($modal);
		window.SimpleModal.Footer.Buttons.Secondary.clearAction($modal);
		window.SimpleModal.Footer.Buttons.Cancel.clearAction($modal);
	},

	/**
	 * Clear all key code event handlers that trigger a button click
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	clearAllPerformActionsOnKeyCodes($modal) {
		window.SimpleModal.Footer.Buttons.Primary.clearPerformActionOnKeyCode($modal);
		window.SimpleModal.Footer.Buttons.Secondary.clearPerformActionOnKeyCode($modal);
		window.SimpleModal.Footer.Buttons.Cancel.clearPerformActionOnKeyCode($modal);
	},

	/**
	 * Hide all buttons
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	hide($modal) {
		window.SimpleModal.Footer.Buttons.Primary.hide($modal);
		window.SimpleModal.Footer.Buttons.Secondary.hide($modal);
		window.SimpleModal.Footer.Buttons.Cancel.hide($modal);
	},

	/**
	 * Show all buttons
	 *
	 * @param {wrapper} $modal (Optional) The modal to set the text for (if not passed, default is the top-most modal)
	 *
	 * @returns {undefined}
	 */
	show($modal) {
		window.SimpleModal.Footer.Buttons.Primary.show($modal);
		window.SimpleModal.Footer.Buttons.Secondary.show($modal);
		window.SimpleModal.Footer.Buttons.Cancel.show($modal);
	},

	/**
	 * Show the processing state for buttons for a given modal (or defaulted to the top-most modal)
	 *
	 * @param {wrapper} $modal (optional) The jQuery wrapper modal object to update
	 *
	 * @return {undefined}
	 */
	startProcessing($modal) {
		window.SimpleModal.Footer.Buttons.Primary.startProcessing($modal);
		window.SimpleModal.Footer.Buttons.Secondary.startProcessing($modal);
	},

	/**
	 * Show the processing state for buttons for a given modal (or defaulted to the top-most modal)
	 *
	 * @param {wrapper} $modal (optional) The jQuery wrapper modal object to update
	 *
	 * @return {undefined}
	 */
	stopProcessing($modal) {
		window.SimpleModal.Footer.Buttons.Primary.stopProcessing($modal);
		window.SimpleModal.Footer.Buttons.Secondary.stopProcessing($modal);
	}
};


import Cancel from './Buttons/Cancel';
Buttons.Cancel = Cancel;
export {Cancel};

import Primary from './Buttons/Primary';
Buttons.Primary = Primary;
export {Primary};

import Secondary from './Buttons/Secondary';
Buttons.Secondary = Secondary;
export {Secondary};


export default Buttons;
