import {
	DELETE_EVENT_ERROR,
	DELETE_EVENT_START,
	DELETE_EVENT_SUCCESS,
} from '../../actions/constants';

export default { DELETE_EVENT_START: (state, action) => {
	return state.setIn(['entities', 'events', 'byId', action.payload, 'pending'], true);
}, DELETE_EVENT_ERROR: (state, action) => {
	return state.setIn(['entities', 'events', 'byId', action.payload, 'pending'], false);
}, DELETE_EVENT_SUCCESS: (state, action) => {
	const event = state.getIn(['entities', 'events', 'byId', action.payload]);
	const deleteEntity = (state, type, id) => (
		state
			.removeIn(['entities', type, 'byId', id])
			.updateIn(
				['entities', type, 'allIds'],
				v => v.delete(id)
			)
	);

	return deleteEntity(
		(
			event.getCommentId()
				? deleteEntity(state, 'comments', event.getCommentId())
				: state
		),
		'events',
		action.payload
	);
}, };
