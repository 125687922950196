class Cancelable {
	constructor() {
		this.instance = null;
	}

	on(obj) {
		this.cancel();

		this.instance = obj;

		obj.promise.then(() => {
			this.instance = null;
		});

		return obj;
	}

	cancel() {
		if (this.instance) {
			this.instance.cancel();
			this.instance = null;
		}
	}
}

export function cancelableRequestObject($promise, ...$requests) {
	return {
		promise: $promise,
		request: $requests.reduce((acc, req) => acc.concat(req), []),
		cancel: function () {
			this.request.forEach(req => req.abort());
		}
	}
}

export default Cancelable;
