import {
	getField,
	getSelectedValue,
	Fields,
} from 'dynamic-form';

/**
 * Validates Pay Rate and Pay Per. They are both needed if one has a value.
 *
 * @param context
 * @param field
 * @param params
 */
export const validatePayRatePaidPer = (context: DynamicForm.Context, field: DynamicForm.DataProps, params: any) => {
	return (value: string | undefined): boolean => {

		const paidPerField = getField(context, Fields.paidPer) as DynamicForm.SelectElement | null;
		const payRateField = getField(context, Fields.payRate) as DynamicForm.TextElement | null;
		const payTypeField = getField(context, Fields.payType) as DynamicForm.SelectElement | null;

		const payType = payTypeField ? getSelectedValue(payTypeField) : '';
		const payRate = payRateField?.props?.value;
		const paidPer = paidPerField ? getSelectedValue(paidPerField) : false;

		// This field is always good if it has a value
		return (
			!!value ||
			(!payRate && !paidPer) ||
			(payType === 'Hourly' && !!payRate)
		);
	};
};
