window.EmployeeComments = {
	remove: function(element) {
		element.fadeOut('fast', function() {
			$.when($(this).remove()).then(function() {
				if (typeof calculateHeight === 'function') {
					window.calculateHeight();
				}
			});
		});
	},
	getCommentId: function(elementId) {
		let id = elementId.split('-');
		return id[1];
	},
	deleteNewFiles: function(textBox) {
		textBox.siblings(".files").find(".newFile").each(function() {
			let id = window.EmployeeComments.getCommentId($(this).attr('id'));
			if (window.deleteTempFile(id)) {
				$(this).remove();
			}
		});
	},
	buildCommentFileUploader: function(commentId,linkHTML,widgetId,selector,isReply) {
		let config = {};
		config.onFallbackSuccess = function(data, elm) {
			if (isReply == 'true') {
				$('#thread-' + commentId + ' .add-reply .files').append(elm);
			} else {
				$('#comment-' + commentId).find('.files').append(elm);
			}
		};

		config.removeFile = function(fp) {
			if (fp.attr('id') != '') {
				let fid = window.EmployeeComments.getCommentId(fp.attr('id'));
				if (window.deleteTempFile(fid)) {
					return true;
				}
			} else {
				return true;
			}
			return false;
		};
		config.setDragOverValues = function(wrapper) {
			wrapper.addClass('dragOver').find('.links-msg').text($.__('Drop to upload')).show();
		};
		config.linkHTML = linkHTML;
		config.uploaderId = commentId;
		$(selector).fileUploader(config);
	},
};
