import React from 'react';

/**
 * This component requires a parent to manage the state of its input
 *
 * @prop {name} props.name
 * @prop {function} props.handleAmountChange
 * @prop {function} props.handleAmountBlur
 * @prop {string} props.value
 * @prop {object} [props.inputProps]
 */
export function ControlledAmountInput(props) {
	const {
		name,
		onAmountChange,
		onAmountBlur,
		value,
		inputProps = {}, // QF React will dynamically need to override everything but value
	} = props;

	// Makes it so large currency values don't go behind the button (BANDAID FIX!)
	// set as an inline style because QF React blows away most of the props (including className)
	// since we aren't doing css modules it is difficult to keep consistent
	// hard coded for now because this should be fixed on the Fabric Side (can easily be removed or overridden)
	// Average width of Currency Codes is about 35-40px. Add 5px of extra padding
	const inlineStyles = {
		paddingRight: '45px',
	};

	return (
		<input
			className="required requiredWhenVisible CurrencyPicker__input fab-TextInput fab-TextInput--width4"
			name={ name }
			onBlur={ onAmountBlur }
			onChange={ onAmountChange }
			style={ inlineStyles }
			type="text"
			{ ...inputProps }
			value={ value }
		/>
	);
}
