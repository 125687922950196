import './styles.styl';

import {
	flow,
	last,
	pick,
} from 'lodash';
import {
	map,
	keyBy,
	random,
} from 'lodash/fp';
import { Component } from 'react';

import Nudge from 'nudge';
import { isEnabled } from 'FeatureToggle.util';
import CountUp from 'CountUp.util';
import {
	saveSingleDailyHours,
	refreshTimesheet,
	updateViewOption,
	approveTimesheet,
	clockIn,
	clockOut,
	getClockStatus,
} from 'time-tracking/http';
import {
	INVALID_FORMAT,
	OVER_24,
	parseTime,
	calculateTimeEarned,
	getDaysFromMap,
	clockStatesDiffer,
} from 'time-tracking/utils';
import {
	SAVE_SUCCESS_MSG,
	BLANK_STATE_DETAILS,
} from 'time-tracking/constants';

import normalizeData from './store/store-bootstrap';
import { Provider } from './store/context';

import BlankState from 'blank-state.react';
import Header from './components/header.react';
import Sheet from './components/sheet.react';
import Summary from './components/summary.react';
import ClearNoteWarningModal from 'time-tracking/modals/clear-note-warning';

import { setupDomOnPageLoad, toggleViewOption } from './utils/dom';

const timeTrackingWebSocketsEnabled = isEnabled('timeTrackingWebSockets');
let shouldPollEveryMinuteForClockStatus = !timeTrackingWebSocketsEnabled; // set to false when featureToggle is removed
let watchSocketClockEvent = true;

export default class EmployeeTimesheet extends Component {
	constructor(props) {
		super(props);

		const { days, serverTime, clock, focusedTimesheet } = props.initialState;
		const todayIsInView = serverTime ? (!!days[serverTime.format('YYYY-MM-DD')]) || false : false;

		if (clock && todayIsInView) {
			// Instead of having everyone polling clock status at the same time,
			// give this user a random time during the minute to poll consistently going forward
			const timeOutForPolling = random(5000, 55000);

			// Adjust serverTime when the clock is enabled for loss of time (page load time)
			serverTime.add(Math.ceil(window.performance.now()), 'ms');

			this.earnedTimer = null;
			this.earnedTimerOffset = 0;
			this.serverTimer = new CountUp()
				.everyMinute((ms) => {
					this.actions.updateServerTime(ms);
					if (shouldPollEveryMinuteForClockStatus) {
						setTimeout(this.actions.pollClockStatus, timeOutForPolling);
					}
				})
				.start(serverTime.valueOf());

			if (timeTrackingWebSocketsEnabled) {
				const onClockInOrOut = () => {
					if (watchSocketClockEvent) {
						this.actions.pollClockStatus(true);
					}
					watchSocketClockEvent = true;
				};
				Nudge
					.subscribe({
						channel: 'timeTracking',
						subjectId: focusedTimesheet.id,
						events: {
							clockIn: onClockInOrOut,
							clockOut: onClockInOrOut,
						}
					})
					.catch(() => {
						shouldPollEveryMinuteForClockStatus = true;
					});
			}
		}

		this.state = {
			...props.initialState,
			...this.actions,
			...this.queries,
			saveTimesheetPending: false,
			isViewOnly: props.isViewOnly,
			showHeader: props.showHeader,
			showSheetPadDays: props.showSheetPadDays,
			showClearNoteWarningModal: false,
			clearNoteWarningModalProcessing: false,
			showTimesheetDateRangeHeader: props.showTimesheetDateRangeHeader,
		};
	}

	_saveTimesheetFail = () => {
		this.setState({ saveTimesheetPending: false, showClearNoteWarningModal: false, clearNoteWarningModalProcessing: false });
	};

	_saveTimesheetSuccess = () => {
		this.actions.refreshTimesheet()
			.then(() => {
				document.dispatchEvent(new CustomEvent('SiteFooter:close'));
				this.setState({ showClearNoteWarningModal: false, clearNoteWarningModalProcessing: false });
				window.setMessage(SAVE_SUCCESS_MSG, 'success');
			})
			.catch(this._saveTimesheetFail);
	};

	_handleFooterAction = () => {
		this.actions.saveTimesheet();
	};

	_handleFooterCancel = () => {
		this.actions.resetSingleEntryUserEdits();
	};

	actions = {
		changeViewOption: (option, newValue) => {
			const {timesheetOptions} = this.state;
			updateViewOption(option, newValue);
			toggleViewOption(option);
			this.setState({timesheetOptions: {...timesheetOptions, [option]: newValue}});
		},
		editSingleEntrySlat: (date, hours) => {
			let {days} = this.state;
			days = {...days};
			days[date] = {
				...days[date],
				userEdit: hours,
			};
			this.setState({days});
		},
		resetSingleEntryUserEdits: () => {
			const {days} = this.state;
			const newFocusedDaysMap = flow(
				map((day) => {
					day.userEdit = null;
					return day;
				}),
				keyBy('date'),
			)(this.queries.getFocusedDays());

			this.setState({
				days: {
					...days,
					...newFocusedDaysMap,
				}
			});
		},
		saveTimesheet: () => {
			if (window.ASSUMED_USER) {
				window.disabledForPermsTest();
				return;
			}

			if (this.queries.areChangedEntriesValid()) {
				const entriesWithClearedTimeAndNotesCount = this.queries.getEntriesWithClearedTimeAndNotesCount();
				if (entriesWithClearedTimeAndNotesCount > 0) {
					this.setState({ showClearNoteWarningModal: true });
				} else {
					this.actions.submitTimesheetChanges();
				}
			}
		},
		submitTimesheetChanges: () => {
			const changeEntriesPayload = this.queries.getChangedEntries()
				.map(day => ({ date: day.date, hours: parseTime(day.userEdit), employeeId: window.Employee.id }));

			this.setState({ saveTimesheetPending: true, clearNoteWarningModalProcessing: true });
			if (changeEntriesPayload.length) {
				saveSingleDailyHours(changeEntriesPayload)
					.then(this._saveTimesheetSuccess)
					.catch(this._saveTimesheetFail);
			} else {
				// If there are no changed entries, we still need to refresh
				this._saveTimesheetSuccess();
			}
		},
		refreshTimesheet: () => {
			const {focusedTimesheet, clock} = this.state;
			return refreshTimesheet(focusedTimesheet.id)
				.then((response) => {
					const timesheetData = response.data;
					const newState = normalizeData(timesheetData);

					// If they refreshTimesheet and find they are clocked in from another location
					if (newState.clock && !clock.clockedIn && newState.clock.clockedIn) {
						// Reset the offset in case they used this value previously to clock in/out
						this.earnedTimerOffset = 0;
					}

					this.setState(newState);
				});
		},
		approveTimesheet: (id, hoursLastChangedAt) => {
			if (this.queries.getChangedEntries().length) {
				return Promise.reject(null);
			}

			return approveTimesheet(id, hoursLastChangedAt, this.actions.refreshTimesheet)
				.then(this.actions.refreshTimesheet);
		},
		clockIn: (employeeId, projectId, taskId) => {
			watchSocketClockEvent = false;
			return clockIn(employeeId, projectId, taskId)
				.then(() => {
					const {employee, serverTime, days, clock} = this.state;

					const mockClockEntry = {
						timezone: employee.timezone,
						start: moment.tz(serverTime.clone(), employee.timezone).format('YYYY-MM-DD HH:mm:ss'),
						end: null,
						hours: null,
						id: -1,
					};
					const newClock = Object.assign({}, clock, {clockedIn: true, latest: mockClockEntry});
					const today = {...this.queries.getToday()};

					today.clockEntries.push(mockClockEntry);
					days[today.date] = today;

					this.earnedTimerOffset = moment.tz(this.serverTimer.ms, employee.timezone)
						.diff(serverTime.clone().startOf('minute'));

					this.setState({clock: newClock, days}, this.actions.refreshTimesheet);
				})
				.catch(this.actions.refreshTimesheet);
		},
		clockOut: (employeeId) => {
			watchSocketClockEvent = false;
			clearTimeout(this.earnedTimer);
			return clockOut(employeeId)
				.then(this.actions.refreshTimesheet)
				.catch(this.actions.refreshTimesheet);
		},
		updateServerTime: (ms) => {
			const {employee, serverTime, clock} = this.state;
			const newServerTime = moment.tz(ms, employee.timezone);

			if (!newServerTime.isSame(serverTime, 'day')) {
				window.location.reload(true);
			}

			this.setState({serverTime: newServerTime}, () => {
				if (clock && clock.clockedIn) {
					this.earnedTimer = setTimeout(this.actions.updateEarnedTime, this.earnedTimerOffset);
				}
			});
		},
		updateEarnedTime: () => {
			const {clock, days, serverTime} = this.state;
			// Just in case we end up in here after being clocked out but the setTimeout is still running
			if (clock && !clock.clockedIn) {
				return;
			}

			const newClock = {...clock};
			const newDays = {...days};
			const today = this.queries.getToday();
			const lastEntry = last(today.clockEntries);
			const lastClockInMoment = moment.tz(lastEntry.start, lastEntry.timezone);

			newClock.earned = calculateTimeEarned(lastClockInMoment, serverTime);
			today.hours = today.clockEntries.reduce((a, b) => a + b.hours, newClock.earned);
			newDays[today.date] = today;

			this.setState({clock: newClock, days: newDays});
		},
		pollClockStatus: (forcePolling = false) => {
			if (document.hidden && !forcePolling) {
				return;
			}
			const {employee: {id}, clock: {clockedIn, latest}} = this.state;
			getClockStatus(id)
				.then(({isClockedIn, clockTime}) => {
					const needsRefresh = clockStatesDiffer(clockedIn, latest, isClockedIn, clockTime);

					if (needsRefresh) {
						this.actions.refreshTimesheet();
					}
				});
		},
	};

	queries = {
		getPadStartDays: () => {
			const {padStartTimesheet, days} = this.state;
			return getTimesheetDays(padStartTimesheet, days);
		},
		getFocusedDays: () => {
			const {focusedTimesheet, days} = this.state;
			return getTimesheetDays(focusedTimesheet, days);
		},
		getPadEndDays: () => {
			const {padEndTimesheet, days} = this.state;
			return getTimesheetDays(padEndTimesheet, days);
		},
		getLastWeekDays: () => {
			const {lastWeek: {start, end}, days} = this.state;
			return getDaysFromMap(start, end, days);
		},
		getThisWeekDays: () => {
			const {thisWeek: {start, end}, days} = this.state;
			return getDaysFromMap(start, end, days);
		},
		getChangedEntries: () => {
			return this.queries.getFocusedDays()
				.filter(day => day.userEdit !== null)
				.filter(day => parseTime(day.userEdit) !== day.hours);
		},
		areChangedEntriesValid: () => {
			return !this.queries.getChangedEntries()
				.map(({userEdit}) => parseTime(userEdit))
				.filter(parsedTime => (parsedTime === OVER_24 || parsedTime === INVALID_FORMAT))
				.length;
		},
		getEntriesWithClearedTimeAndNotesCount: () => {
			return this.queries.getChangedEntries()
				.filter(({ note, userEdit }) => {
					const parsedTime = parseTime(userEdit);
					if (note && parsedTime === null) {
						return true;
					}

					return false;
				})
				.length;
		},
		getToday: () => {
			const {days, serverTime} = this.state;
			return days[serverTime.format('YYYY-MM-DD')] || null;
		},
	};

	componentDidMount() {
		const timesheetFooter = document.querySelector('[data-form-id="timesheetFooter"]');
		if (timesheetFooter) {
			timesheetFooter.addEventListener('click', (event) => {
				if (event.target.closest('button[data-action="SiteFooterAction:submit"]')) {
					this._handleFooterAction();
				} else if (event.target.closest('button[data-action="SiteFooterAction:cancel"]')) {
					this._handleFooterCancel();
				}
			});
		}

		setupDomOnPageLoad(pick(this.state, ['isApprovalShowing', 'isOwnTimesheet', 'timesheetOptions', 'getChangedEntries']));
	}

	render() {
		const { clearNoteWarningModalProcessing, focusedTimesheet, showClearNoteWarningModal } = this.state;
		const isBlankState = (focusedTimesheet.type === 'none');

		return (
			<Provider value={ this.state }>
				<div className="TimesheetTab">
					<Header />
					<div className="TimesheetContent js-timesheet-content">
						{ isBlankState ? <BlankState { ...BLANK_STATE_DETAILS } /> : <Sheet /> }
						{ !isBlankState && <Summary /> }
					</div>
				</div>

				<ClearNoteWarningModal
					getChangedEntries={ this.queries.getChangedEntries }
					isOpen={ showClearNoteWarningModal }
					isProcessing={ clearNoteWarningModalProcessing }
					onClose={ () => this.setState({ showClearNoteWarningModal: false }) }
					primaryAction={ this.actions.submitTimesheetChanges }
				/>
			</Provider>
		);
	}
}

function getTimesheetDays(timesheet, days) {
	if (timesheet === null) {
		return [];
	}

	return getDaysFromMap(timesheet.start, timesheet.end, days);
}
