import Ajax from '@utils/ajax';

import {
	extendURL,
} from '@utils/url';

export function addItem(url, fieldId, name, value) {
	const data = Ajax.prepareData(
		{
			action: 'new-section',
			ajax: 1,
			field: name,
			name: value,
		},
		Ajax.prepareDataTypes.FORM_DATA,
	);

	url = extendURL({
		fieldId,
	}, url);

	return Ajax.post(url, data).then((response = {}) => {
		const {
			data,
		} = response;

		return data.ret === 'SUCCESS' || data.response === 'SUCCESS' ? data.id || data.data.id : Promise.reject({ data });
	});
}
