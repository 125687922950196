export function canShowIowaStateMessage(locations, currentLocation) {
	if (currentLocation === undefined || currentLocation === null || currentLocation === '') {
		return false;
	}

	if (invalidLocationData(locations)) {
		return false;
	}

	const locationInfo = getLocationInfo(locations, currentLocation);

	if (locationInfo !== undefined) {
		return locationInfo.isNewStateTaxView === false && locationInfo.supportsNewStateTaxView === true;
	} 

	return false;
}

export function getLocationInfo(locations, currentLocation) {
	return locations.byId ? locations.byId[currentLocation] : undefined;
}

export function invalidLocationData(locations) {
	return locations === null || locations === undefined;
}

export function shouldUseNewIowaForm(locations, selectedLocation) {
	if (invalidLocationData(locations)) {
		return false;
	}

	if (selectedLocation === null || selectedLocation === undefined) {
		return false;
	}

	const locationInfo = getLocationInfo(locations, selectedLocation);
	return locationInfo ? locationInfo.isNewStateTaxView === true : false;
}
