import {
	Modal,
} from 'modal-legacy';
import {
	useState,
} from 'react';

import {
	showSlidedown,
} from 'Alerts.mod';

import {
	ConditionalHeadline,
} from './components/conditional-headline.react';
import {
	ConditionalAgreement,
} from './components/conditional-agreement.react';
import {
	ACCEPTED_SUCCESS_MESSAGE,
	DECLINED_SUCCESS_MESSAGE,
	MODAL_TITLE,
} from './constants';
import {
	COMPLETE_LATER_SUCCESS_MESSAGE,
} from '../../constants';
import {
	acceptDataProcessingAgreement,
	declineDataProcessingAgreement,
	completeDataProcessingAgreementLater,
} from '../../service';

export function ConditionalDpaModal(props) {
	const {
		agreement = {},
		hasCloseButton = false,
		hasCompleteLaterButton = true,
		isOpen,
		onClose,
	} = props;

	const [hasEmployees, setHasEmployees] = useState(false);
	const [isAccepted, setIsAccepted] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);


	const canUsePrimaryAction = !hasEmployees || isAccepted;

	const _handlePrimaryAction = async () => {
		setIsProcessing(true);
		try {
			if (isAccepted) {
				await acceptDataProcessingAgreement(agreement.id);
			} else {
				await declineDataProcessingAgreement(agreement.id);
			}
			setIsProcessing(false);
			showSlidedown(isAccepted ? ACCEPTED_SUCCESS_MESSAGE : DECLINED_SUCCESS_MESSAGE, 'success');
			onClose();
		} catch (e) {
			setIsProcessing(false);
			showSlidedown($.__(`Oops. Something went wrong. Please try again.`), 'error');
		}
	};

	const _handleSecondaryAction = async () => {
		setIsProcessing(true);
		try {
			await completeDataProcessingAgreementLater(agreement.id);

			showSlidedown(COMPLETE_LATER_SUCCESS_MESSAGE, 'success');
			setIsProcessing(false);
			onClose();
		} catch (e) {
			setIsProcessing(false);
			showSlidedown($.__(`Oops. Something went wrong. Please try again.`), 'error');
		}
	};

	return (
		<Modal
			alternativeAction={ null }
			contentHasMaxHeight={ false }
			hasCloseButton={ hasCloseButton }
			isOpen={ isOpen }
			isProcessing={ isProcessing }
			onClose={ onClose }
			primaryAction={ canUsePrimaryAction ? _handlePrimaryAction : null }
			primaryActionText={ $.__('Done') }
			secondaryAction={ hasCompleteLaterButton ? _handleSecondaryAction : undefined }
			secondaryActionText={ hasCompleteLaterButton ? $.__('Complete Later') : undefined }
			title={ MODAL_TITLE }
		>
			<ConditionalHeadline />

			<ConditionalAgreement
				hasEmployees={ hasEmployees }
				isAccepted={ isAccepted }
				onHasEmployeesChange={ setHasEmployees }
				onIsAcceptedChange={ setIsAccepted }
			/>
		</Modal>
	);
}
