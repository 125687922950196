import { MultiStepModal } from '@bamboohr/fabric';
import { ReactElement, useEffect, useState } from 'react';
import { useCompanyName } from './hooks/use-company-name';
import { useMismatchData } from './hooks/use-mismatch-data';
import { endEmployment, setEmployeeActive } from './services';
import { EmployedStep } from './steps/employed-step';
import { EndEmploymentStep } from './steps/end-employment-step';
import { UpdateStatusStep } from './steps/update-status-step';
import { StatusMismatchModalProps } from './types';

export function StatusMismatchModal({ employeeId, onCancel, onSave, open }: StatusMismatchModalProps): ReactElement {
	const [currentStep, setCurrentStep] = useState(0);
	const [employed, setEmployed] = useState(true);
	const [processing, setProcessing] = useState(false);

	const {
		fields,
		employee,
		hasGusto,
		loading: loadingMismatchData,
		showFinalPayDate,
	} = useMismatchData(employeeId, open);

	const {
		companyName,
		loading: loadingCompanyName
	} = useCompanyName();

	useEffect(() => {
		if (open) {
			setCurrentStep(0);
			setEmployed(true);
			setProcessing(false);
		}
	}, [open]);

	const getSteps = (): Array<ReactElement> => {
		return [
			<EmployedStep
				companyName={ companyName }
				employed={ employed }
				employee={ employee }
				key="step1"
				onCancel={ onCancel }
				onChange={ _employed => setEmployed(_employed) }
				onNext={() => setCurrentStep(1)}
			/>,
			employed ? (
				<UpdateStatusStep
					employee={ employee }
					key="updateStatusStep"
					onCancel={ onCancel }
					onNext={
						async () => {
							setProcessing(true);

							try {
								await setEmployeeActive(employee.id);
								setMessage($.__('Got it. The status for %1 is updated.', employee.preferredName), 'success');
								onSave();
							} catch (e) {
								setMessage($.__('Oops. Something went wrong. Please try again.'), 'error');
								setProcessing(false);
							}
						}
					}
					onPrevious={ () => setCurrentStep(0) }
				/>
			) : (
				<EndEmploymentStep
					employee={ employee }
					fields={ fields }
					hasGusto={ hasGusto }
					key="endEmploymentStep"
					onCancel={ onCancel }
					onNext={
						async (data) => {
							setProcessing(true);

							try {
								await endEmployment(data);
								setMessage($.__(`Got it. %1's end of employment information has been updated.`, employee.preferredName), 'success');
								onSave();
							} catch (e) {
								setMessage($.__('Oops. Something went wrong. Please try again.'), 'error');
								setProcessing(false);
							}
						}
					}
					onPrevious={ () => setCurrentStep(0) }
					showFinalPayDate={ showFinalPayDate }
				/>
			)
		];
	};

	const loading = loadingCompanyName || loadingMismatchData;

	return (
		<MultiStepModal
			currentStep={ currentStep }
			isLoading={ loading }
			isOpen={ open }
			isProcessing={ processing }
			onRequestClose={ onCancel }
			steps={ !open || loading ? [] : getSteps() }
			title={ employee ? $.__('Update %1 %2', employee.preferredName, employee.lastName) : '' }
		/>
	);
}
