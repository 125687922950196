import { Button, IconV2, Stack, StandardModal, TextButton, useTheme } from '@bamboohr/fabric';
import { noop } from 'lodash';
import { FunctionComponent } from 'react';
/* @startCleanup encore */
import { TrashCan43x48 } from '@bamboohr/grim';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';

type Props = {
	handleClose: Function;
	handlePrimaryAction: Function;
	data: {
		contactName: string;
		contactRelationship?: string;
	};
};

const DeleteDependentModal: FunctionComponent<Props> = ({ handleClose = noop, handlePrimaryAction = noop, data }) => {
	const { palette } = useTheme();

	return (
		<StandardModal isOpen={true} onRequestClose={() => handleClose()}>
			<StandardModal.Body
				renderFooter={() => (
					<StandardModal.Footer
						actions={ifFeature(
							'encore',
							[
								<TextButton key='cancel' onClick={() => handleClose()}>
									{$.__('Cancel')}
								</TextButton>,
								<Button key='confirm' onClick={() => handlePrimaryAction()}>
									{$.__('Yes, Delete Dependent')}
								</Button>,
							],
							[
								<Button key='confirm' onClick={() => handlePrimaryAction()}>
									{$.__('Yes, Delete Dependent')}
								</Button>,
								<TextButton key='cancel' onClick={() => handleClose()}>
									{$.__('Cancel')}
								</TextButton>,
							]
						)}
					/>
				)}
				renderHeader={() => <StandardModal.Header title={$.__('Delete Dependent')} />}
			>
				<StandardModal.Constraint>
					<Stack spacing={2}>
						<StandardModal.HeroHeadline
							icon={ ifFeature(
								'encore',
								'trash-can-regular',
								<TrashCan43x48 fill={palette.error.main} />)
							}
							iconColor='error-strong'
							text={$.__('Are you sure you want to delete this dependent?')}
						/>
						<div className='Contact__modalContent'>
							<div className='Contact__modalName'>{data.contactName}</div>
							<div className='Contact__modalRelationship'>{data.contactRelationship}</div>
						</div>
					</Stack>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};

export default DeleteDependentModal;
