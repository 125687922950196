import { add, formatISO, parse, sub } from '@utils/datetime';

export function convertDateToIsoFormatString(dateString: unknown): string {
	if ((typeof dateString !== 'string' && typeof dateString !== 'number') || !dateString ) {
		return '';
	}
	const isoDateRegex = /\d\d\d\d-\d\d-\d\d/g;
	return dateString.toString().match(isoDateRegex) ? dateString.toString() : formatISO(parse(dateString.toString(), window.dateFnsDefaultFormat, new Date()), { representation: 'date' });
}

export function getValueFromSpecialAttribute(specialProp: string) {
	switch (specialProp) {
		case 'future-only':
			return formatISO(add(new Date(), { days: 1 }), { representation: 'date' });
		case 'past-only':
			return formatISO(sub(new Date(), { days: 1 }), { representation: 'date' });
		default:
			return formatISO(new Date(), { representation: 'date' });
	}
}

export function convertBoolJQDatePickerOption(name: string): string | undefined {
	const specialConversion = {
		'future-only': 'minDate',
		'now-and-future-only': 'minDate',
		'now-and-past-only': 'maxDate',
		'past-only': 'maxDate',
	};

	return specialConversion[name];
}

export function convertJQDatePickerOption(name: string): string | undefined {
	const excludedAttributes = {
		'calendar-picker': true,
		'dual-grid': true,
		'toggle-on-focus': true,
		type: true,
		'quick-nav': true,
	};

	const attributesToConvert = {
		end: 'endValue',
		max: 'maxDate',
		min: 'minDate',
		start: 'startValue',
	};

	if (excludedAttributes[name]) {
		return;
	}

	if (attributesToConvert[name]) {
		return attributesToConvert[name];
	}

	if (name === 'data-width') {
		return 'width';
	}

	if (name === 'no-facade') {
		return 'noFacade';
	}

	return name;
}

export function convertAttributesAsReactPropsObject(element: HTMLElement) {
	const attributeObject = {};
	const attributeNames = element.getAttributeNames();

	attributeNames.forEach((name) => {
		let key = convertBoolJQDatePickerOption(name);
		if (key) {
			attributeObject[key] = getValueFromSpecialAttribute(name);
		}

		key = convertJQDatePickerOption(name);

		if (key === 'noFacade') {
			attributeObject[key] = true;
			return;
		}

		if (key) {
			attributeObject[key] = element.getAttribute(name);
		}
	});

	return attributeObject;
}
