import { FunctionComponent, useEffect, useRef } from 'react';
import { Select } from '@bamboohr/fabric';
import { hasTrax } from 'BambooHR.util';
import {
	getClassBasedValidation,
	hasErrors,
	isRequired,
	handleControlledInput,
	handleAddSelectItem,
	isSelectedPSSyncing,
	fixValidationRegisterName,
} from 'dynamic-form';

const SelectField: FunctionComponent<DynamicForm.SelectElementProps> = (selectFieldProps) => {
	const {
		props,
		settings,
		context,
	} = selectFieldProps;
	const { id, disabled, isObfuscated, className, style, name, condition = null, items, selectedValues, canSelectMultiple = false, onChange } = props;
	const {
		fieldBoxClasses,
		label,
		type,
		note = false,
	} = settings;
	const {
		controls: { Label, Note, FieldBox, FieldDiv, PendingRequest },
		validation: { formState: { errors }, trigger, register, setValue },
	} = context;
	const SelectWrapperRef = useRef(null);
	const isFieldRequired = isRequired(context, selectFieldProps);
	const validationName = fixValidationRegisterName(name);
	const hasError = hasErrors(errors, validationName);
	register(validationName, { ...getClassBasedValidation(context, selectFieldProps), required: isFieldRequired });

	// QF sometimes doesn't always handle value for selects correctly
	// Manually check and fix selectedValues
	// Right now, QF doesn't support multiple select... so this is fine for now
	let fixedSelectedValues: string[] = [];
	if (Array.isArray(selectedValues)) fixedSelectedValues = selectedValues;
	if (typeof selectedValues === 'string') fixedSelectedValues = [selectedValues];

	useEffect(() => {
		setValue(validationName, fixedSelectedValues[0] || '');
	}, []);

	const onAdd = (): Promise<DynamicForm.addSelectItem[] | void> => {
		// employment status works differently! If changed, test adding employment status! PER-1514
		// Maintain current implementation and trigger 'ba:selectAddItem'

		const element = SelectWrapperRef.current;
		let resolve: (value?: DynamicForm.addSelectItem[]) => void;
		let reject: { (): void; (reason?: any): void };
		const _returnPromise = new Promise<DynamicForm.addSelectItem[]>((_resolve, _reject) => {
			resolve = _resolve;
			reject = _reject;
		});

		if (!element) {
			console.error('Dynamic Form: Select Element: Unable to get current element ref');
			reject();
			return _returnPromise;
		}

		element.dispatchEvent(new CustomEvent('ba:selectAddItem', {
			bubbles: true,
			detail: {
				resolve,
				reject,
				settings,
			},
		}));
		return _returnPromise.then((newItems = []) => {
			handleAddSelectItem(id, newItems[0], context);

			if (newItems[0]?.selected && newItems[0]?.value) {
				setValue(validationName, newItems[0].value || '', {
					shouldDirty: true,
					shouldValidate: true,
				});
			}

		});
	};

	const filteredItems = items.filter((item) => {
		if (
			hasTrax() &&
			item?.props &&
			typeof item.props === 'object' &&
			Object.prototype.hasOwnProperty.call(item.props, 'data-invalid-payroll-option') &&
			isSelectedPSSyncing(context)
		) {
			return !item.props['data-invalid-payroll-option'];
		}
		return true;
	});

	const handleChange = (newValue: string[]): void => {
		handleControlledInput('select', id, canSelectMultiple ? newValue : newValue[0] || '', context);
		// manually validate on change because a blur isn't triggered
		setValue(validationName, newValue[0] || '', {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const dataProps = {};
	Object.keys(props).forEach((key) => {
		if (key.startsWith('data-')) {
			dataProps[key] = props[key];
		}
	});

	return (
		<FieldBox fieldBoxClasses={ fieldBoxClasses }>
			<Label error={ hasError } info={ condition === 'info' } inputId={ id } label={ label } required={ isFieldRequired } />
			<FieldDiv isObfuscated={ isObfuscated } type={ type }>
				<PendingRequest context={ context } props={ props } settings={ settings } />
				<div
					className={ className }
					ref={ SelectWrapperRef }
					{ ...dataProps }
					data-name={ name }

					style={ style }
				>
					{ /* @ts-ignore */ }
					<Select
						{ ...props }
						condition={ hasError ? 'error' : condition }
						isDisabled={ disabled || window.previewUser }
						items={ filteredItems }
						name={ canSelectMultiple ? `${ name }[]` : name }
						onAdd={ props['can-add'] ? onAdd : null }
						// @ts-ignore
						onChange={ onChange ? (item) => { onChange(item); trigger(name); } : handleChange }
						selectedValues={ fixedSelectedValues }
						showSearch="auto"
					/>
				</div>
			</FieldDiv>
			{ note && <Note { ...note } context={ context } /> }
		</FieldBox>
	);
};

export default SelectField;
